import { Variant } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { InputText } from './InputText'

export interface InputNumberProps extends Omit<Variant<typeof InputText>, 'component'> {}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(function InputNumber(
  { id, value, ...rest },
  ref
) {
  return <InputText id={id} ref={ref} type="number" value={value} {...rest} />
})

InputNumber.displayName = 'InputNumber'
