// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouch } from './GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinear } from './GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinear'
import {
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData
 */
export interface GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData
   */
  linear: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData {
  return GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: GetDisplayCampaignsParamDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
