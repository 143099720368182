// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClick } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClick'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClickFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClickToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClick'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClick } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClick'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClickFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClickFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClickToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClick'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick
 */
export interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClick}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick
   */
  brandPageClick?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick
   */
  urlClick?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClick}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick
   */
  recipeClick?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick {
  return ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickToJSON(
        value.urlClick
      ),
    recipe_click:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
