// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayCampaignsResponseMeta } from './DisplayCampaignsResponseMeta'
import {
  DisplayCampaignsResponseMetaFromJSON,
  DisplayCampaignsResponseMetaFromJSONTyped,
  DisplayCampaignsResponseMetaToJSON,
} from './DisplayCampaignsResponseMeta'
import type { GetDisplayCampaignsParamData } from './GetDisplayCampaignsParamData'
import {
  GetDisplayCampaignsParamDataFromJSON,
  GetDisplayCampaignsParamDataFromJSONTyped,
  GetDisplayCampaignsParamDataToJSON,
} from './GetDisplayCampaignsParamData'

/**
 *
 * @export
 * @interface DisplayCampaignsResponse
 */
export interface DisplayCampaignsResponse {
  /**
   * Display Campaign collection
   * @type {Array<GetDisplayCampaignsParamData>}
   * @memberof DisplayCampaignsResponse
   */
  data: Array<GetDisplayCampaignsParamData>
  /**
   *
   * @type {DisplayCampaignsResponseMeta}
   * @memberof DisplayCampaignsResponse
   */
  meta: DisplayCampaignsResponseMeta
}

/**
 * Check if a given object implements the DisplayCampaignsResponse interface.
 */
export function instanceOfDisplayCampaignsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function DisplayCampaignsResponseFromJSON(json: any): DisplayCampaignsResponse {
  return DisplayCampaignsResponseFromJSONTyped(json, false)
}

export function DisplayCampaignsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayCampaignsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(GetDisplayCampaignsParamDataFromJSON),
    meta: DisplayCampaignsResponseMetaFromJSON(json['meta']),
  }
}

export function DisplayCampaignsResponseToJSON(value?: DisplayCampaignsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(GetDisplayCampaignsParamDataToJSON),
    meta: DisplayCampaignsResponseMetaToJSON(value.meta),
  }
}
