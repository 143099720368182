// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesCreativeProperties
 */
export interface EnableDisplayAdGroupResponseDataAttributesCreativeProperties {
  /**
   * Promoted Aisles tagline
   * @type {string}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeProperties
   */
  tagline: string
  /**
   * UVC hash
   * @type {{ [key: string]: any; }}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeProperties
   */
  uvcFields?: { [key: string]: any }
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesCreativeProperties interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesCreativeProperties(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'tagline' in value

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesCreativeProperties {
  return EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSONTyped(json, false)
}

export function EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesCreativeProperties {
  if (json === undefined || json === null) {
    return json
  }
  return {
    tagline: json['tagline'],
    uvcFields: !exists(json, 'uvc_fields') ? undefined : json['uvc_fields'],
  }
}

export function EnableDisplayAdGroupResponseDataAttributesCreativePropertiesToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesCreativeProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    tagline: value.tagline,
    uvc_fields: value.uvcFields,
  }
}
