// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppeals } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppeals'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppealsFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppealsToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppeals'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshot } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshot'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
 */
export interface PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  type: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  reviewState?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  entitySnapshot: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  decisionSnapshot: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppeals}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates
   */
  appeals: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesTypeEnum =
  typeof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesTypeEnum[keyof typeof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates {
  return PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot:
      PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
        json['entity_snapshot']
      ),
    decisionSnapshot:
      PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppealsFromJSON(
      json['appeals']
    ),
  }
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesToJSON(
  value?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot:
      PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotToJSON(
        value.entitySnapshot
      ),
    decision_snapshot:
      PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
        value.decisionSnapshot
      ),
    appeals: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesAppealsToJSON(
      value.appeals
    ),
  }
}
