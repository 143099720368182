// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes } from './ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes'
import {
  ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSON,
  ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped,
  ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesToJSON,
} from './ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes'

/**
 *
 * @export
 * @interface ApiDisplayCampaignsControllerDuplicateAsyncInput
 */
export interface ApiDisplayCampaignsControllerDuplicateAsyncInput {
  /**
   * The page requesting the campaign to be copied
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerDuplicateAsyncInput
   */
  pageName?: ApiDisplayCampaignsControllerDuplicateAsyncInputPageNameEnum
  /**
   *
   * @type {ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes}
   * @memberof ApiDisplayCampaignsControllerDuplicateAsyncInput
   */
  overrideAttributes?: ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes
}

/**
 * @export
 */
export const ApiDisplayCampaignsControllerDuplicateAsyncInputPageNameEnum = {
  Detail: 'campaign_detail',
  Dashboard: 'campaign_dashboard',
} as const
export type ApiDisplayCampaignsControllerDuplicateAsyncInputPageNameEnum =
  typeof ApiDisplayCampaignsControllerDuplicateAsyncInputPageNameEnum[keyof typeof ApiDisplayCampaignsControllerDuplicateAsyncInputPageNameEnum]

/**
 * Check if a given object implements the ApiDisplayCampaignsControllerDuplicateAsyncInput interface.
 */
export function instanceOfApiDisplayCampaignsControllerDuplicateAsyncInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputFromJSON(
  json: any
): ApiDisplayCampaignsControllerDuplicateAsyncInput {
  return ApiDisplayCampaignsControllerDuplicateAsyncInputFromJSONTyped(json, false)
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayCampaignsControllerDuplicateAsyncInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pageName: !exists(json, 'page_name') ? undefined : json['page_name'],
    overrideAttributes: !exists(json, 'override_attributes')
      ? undefined
      : ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSON(
          json['override_attributes']
        ),
  }
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputToJSON(
  value?: ApiDisplayCampaignsControllerDuplicateAsyncInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    page_name: value.pageName,
    override_attributes: ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesToJSON(
      value.overrideAttributes
    ),
  }
}
