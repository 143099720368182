// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBrandPageBlocks } from './PutBrandPagesIdParamBrandPageBlocks'
import {
  PutBrandPagesIdParamBrandPageBlocksFromJSON,
  PutBrandPagesIdParamBrandPageBlocksFromJSONTyped,
  PutBrandPagesIdParamBrandPageBlocksToJSON,
} from './PutBrandPagesIdParamBrandPageBlocks'

/**
 *
 * @export
 * @interface ApiBrandPagesControllerUpdateInputBrandPage
 */
export interface ApiBrandPagesControllerUpdateInputBrandPage {
  /**
   *
   * @type {string}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  type: ApiBrandPagesControllerUpdateInputBrandPageTypeEnum
  /**
   * A descriptive name brand page, visible to advertiser only.
   * @type {string}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  name: string
  /**
   * Toggle to enable or disable the brand page.
   * @type {boolean}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  enabled: boolean
  /**
   * What level the brand page represents.
   * @type {string}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  level: ApiBrandPagesControllerUpdateInputBrandPageLevelEnum
  /**
   * What purpose brand page represents, must be one of EVERGREEN, SEASONAL. Depending on purpose, page hierarchy may require additional inputs.
   * @type {string}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  purpose: ApiBrandPagesControllerUpdateInputBrandPagePurposeEnum
  /**
   * Input of brand names desired for the brand page in increasing specificity i.e. ['Brand', 'Sub-Brand', 'Custom theme/group']. Will be used to generate the path of the brand page.
   * @type {Array<string>}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  pageHierarchy: Array<string>
  /**
   * Array of page blocks and their content.
   * @type {Array<PutBrandPagesIdParamBrandPageBlocks>}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  blocks: Array<PutBrandPagesIdParamBrandPageBlocks>
  /**
   * Array of Ads Taxonomy Entity IDs that are linked to this brand page
   * @type {Array<number>}
   * @memberof ApiBrandPagesControllerUpdateInputBrandPage
   */
  taxonomyIds?: Array<number>
}

/**
 * @export
 */
export const ApiBrandPagesControllerUpdateInputBrandPageTypeEnum = {
  EvergreenV1: 'evergreen.v1',
  ProductGroupEvergreenV1: 'product_group_evergreen.v1',
} as const
export type ApiBrandPagesControllerUpdateInputBrandPageTypeEnum =
  typeof ApiBrandPagesControllerUpdateInputBrandPageTypeEnum[keyof typeof ApiBrandPagesControllerUpdateInputBrandPageTypeEnum]

/**
 * @export
 */
export const ApiBrandPagesControllerUpdateInputBrandPageLevelEnum = {
  Brand: 'BRAND',
  Parent: 'PARENT',
  SubBrand: 'SUB_BRAND',
} as const
export type ApiBrandPagesControllerUpdateInputBrandPageLevelEnum =
  typeof ApiBrandPagesControllerUpdateInputBrandPageLevelEnum[keyof typeof ApiBrandPagesControllerUpdateInputBrandPageLevelEnum]

/**
 * @export
 */
export const ApiBrandPagesControllerUpdateInputBrandPagePurposeEnum = {
  Evergreen: 'EVERGREEN',
  Seasonal: 'SEASONAL',
} as const
export type ApiBrandPagesControllerUpdateInputBrandPagePurposeEnum =
  typeof ApiBrandPagesControllerUpdateInputBrandPagePurposeEnum[keyof typeof ApiBrandPagesControllerUpdateInputBrandPagePurposeEnum]

/**
 * Check if a given object implements the ApiBrandPagesControllerUpdateInputBrandPage interface.
 */
export function instanceOfApiBrandPagesControllerUpdateInputBrandPage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'level' in value
  isInstance = isInstance && 'purpose' in value
  isInstance = isInstance && 'pageHierarchy' in value
  isInstance = isInstance && 'blocks' in value

  return isInstance
}

export function ApiBrandPagesControllerUpdateInputBrandPageFromJSON(
  json: any
): ApiBrandPagesControllerUpdateInputBrandPage {
  return ApiBrandPagesControllerUpdateInputBrandPageFromJSONTyped(json, false)
}

export function ApiBrandPagesControllerUpdateInputBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiBrandPagesControllerUpdateInputBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    name: json['name'],
    enabled: json['enabled'],
    level: json['level'],
    purpose: json['purpose'],
    pageHierarchy: json['page_hierarchy'],
    blocks: (json['blocks'] as Array<any>).map(PutBrandPagesIdParamBrandPageBlocksFromJSON),
    taxonomyIds: !exists(json, 'taxonomy_ids') ? undefined : json['taxonomy_ids'],
  }
}

export function ApiBrandPagesControllerUpdateInputBrandPageToJSON(
  value?: ApiBrandPagesControllerUpdateInputBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    name: value.name,
    enabled: value.enabled,
    level: value.level,
    purpose: value.purpose,
    page_hierarchy: value.pageHierarchy,
    blocks: (value.blocks as Array<any>).map(PutBrandPagesIdParamBrandPageBlocksToJSON),
    taxonomy_ids: value.taxonomyIds,
  }
}
