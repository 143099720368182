// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdGroupKeywordsResponseDataAttributes } from './PostAdGroupKeywordsResponseDataAttributes'
import {
  PostAdGroupKeywordsResponseDataAttributesFromJSON,
  PostAdGroupKeywordsResponseDataAttributesFromJSONTyped,
  PostAdGroupKeywordsResponseDataAttributesToJSON,
} from './PostAdGroupKeywordsResponseDataAttributes'

/**
 *
 * @export
 * @interface PostAdGroupKeywordsResponseData
 */
export interface PostAdGroupKeywordsResponseData {
  /**
   * The id of the ad group containing the keywords.
   * @type {string}
   * @memberof PostAdGroupKeywordsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostAdGroupKeywordsResponseData
   */
  type: PostAdGroupKeywordsResponseDataTypeEnum
  /**
   *
   * @type {PostAdGroupKeywordsResponseDataAttributes}
   * @memberof PostAdGroupKeywordsResponseData
   */
  attributes: PostAdGroupKeywordsResponseDataAttributes
}

/**
 * @export
 */
export const PostAdGroupKeywordsResponseDataTypeEnum = {
  Keyword: 'keyword',
} as const
export type PostAdGroupKeywordsResponseDataTypeEnum =
  typeof PostAdGroupKeywordsResponseDataTypeEnum[keyof typeof PostAdGroupKeywordsResponseDataTypeEnum]

/**
 * Check if a given object implements the PostAdGroupKeywordsResponseData interface.
 */
export function instanceOfPostAdGroupKeywordsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function PostAdGroupKeywordsResponseDataFromJSON(
  json: any
): PostAdGroupKeywordsResponseData {
  return PostAdGroupKeywordsResponseDataFromJSONTyped(json, false)
}

export function PostAdGroupKeywordsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdGroupKeywordsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: PostAdGroupKeywordsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function PostAdGroupKeywordsResponseDataToJSON(
  value?: PostAdGroupKeywordsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: PostAdGroupKeywordsResponseDataAttributesToJSON(value.attributes),
  }
}
