// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { TaskResponseDataAttributes } from './TaskResponseDataAttributes'
import {
  TaskResponseDataAttributesFromJSON,
  TaskResponseDataAttributesFromJSONTyped,
  TaskResponseDataAttributesToJSON,
} from './TaskResponseDataAttributes'

/**
 *
 * @export
 * @interface TaskResponseData
 */
export interface TaskResponseData {
  /**
   * Task Id
   * @type {string}
   * @memberof TaskResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TaskResponseData
   */
  type: TaskResponseDataTypeEnum
  /**
   *
   * @type {TaskResponseDataAttributes}
   * @memberof TaskResponseData
   */
  attributes: TaskResponseDataAttributes
}

/**
 * @export
 */
export const TaskResponseDataTypeEnum = {
  Task: 'task',
} as const
export type TaskResponseDataTypeEnum =
  typeof TaskResponseDataTypeEnum[keyof typeof TaskResponseDataTypeEnum]

/**
 * Check if a given object implements the TaskResponseData interface.
 */
export function instanceOfTaskResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function TaskResponseDataFromJSON(json: any): TaskResponseData {
  return TaskResponseDataFromJSONTyped(json, false)
}

export function TaskResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: TaskResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function TaskResponseDataToJSON(value?: TaskResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: TaskResponseDataAttributesToJSON(value.attributes),
  }
}
