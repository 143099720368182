// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage {
  /**
   * Title of the page.
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  title: string
  /**
   * Generated, unique id of the page.
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  id: string
  /**
   * Version of the page.
   * @type {number}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  version: number
  /**
   * Slug of the page.
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  slug: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  type: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageTypeEnum
  /**
   * Array of page blocks and their content.
   * @type {Array<PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks>}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
   */
  blocks: Array<PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks>
}

/**
 * @export
 */
export const PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageTypeEnum = {
  DisplayLandingPageV1: 'display_landing_page.v1',
} as const
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageTypeEnum =
  typeof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageTypeEnum[keyof typeof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageTypeEnum]

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'version' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'blocks' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    id: json['id'],
    version: json['version'],
    slug: json['slug'],
    type: json['type'],
    blocks: (json['blocks'] as Array<any>).map(
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON
    ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    id: value.id,
    version: value.version,
    slug: value.slug,
    type: value.type,
    blocks: (value.blocks as Array<any>).map(
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON
    ),
  }
}
