// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetBrandPagesParamAssetFieldDecisions,
  instanceOfGetBrandPagesParamAssetFieldDecisions,
  GetBrandPagesParamAssetFieldDecisionsFromJSON,
  GetBrandPagesParamAssetFieldDecisionsFromJSONTyped,
  GetBrandPagesParamAssetFieldDecisionsToJSON,
} from './GetBrandPagesParamAssetFieldDecisions'
import {
  GetBrandPagesParamBrandPageBlockFieldDecisions,
  instanceOfGetBrandPagesParamBrandPageBlockFieldDecisions,
  GetBrandPagesParamBrandPageBlockFieldDecisionsFromJSON,
  GetBrandPagesParamBrandPageBlockFieldDecisionsFromJSONTyped,
  GetBrandPagesParamBrandPageBlockFieldDecisionsToJSON,
} from './GetBrandPagesParamBrandPageBlockFieldDecisions'
import {
  GetBrandPagesParamBrandPageFieldDecisions,
  instanceOfGetBrandPagesParamBrandPageFieldDecisions,
  GetBrandPagesParamBrandPageFieldDecisionsFromJSON,
  GetBrandPagesParamBrandPageFieldDecisionsFromJSONTyped,
  GetBrandPagesParamBrandPageFieldDecisionsToJSON,
} from './GetBrandPagesParamBrandPageFieldDecisions'
import {
  GetBrandPagesParamCreativeFieldDecisions,
  instanceOfGetBrandPagesParamCreativeFieldDecisions,
  GetBrandPagesParamCreativeFieldDecisionsFromJSON,
  GetBrandPagesParamCreativeFieldDecisionsFromJSONTyped,
  GetBrandPagesParamCreativeFieldDecisionsToJSON,
} from './GetBrandPagesParamCreativeFieldDecisions'
import {
  GetBrandPagesParamDisplayAdGroupFieldDecisions,
  instanceOfGetBrandPagesParamDisplayAdGroupFieldDecisions,
  GetBrandPagesParamDisplayAdGroupFieldDecisionsFromJSON,
  GetBrandPagesParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  GetBrandPagesParamDisplayAdGroupFieldDecisionsToJSON,
} from './GetBrandPagesParamDisplayAdGroupFieldDecisions'
import {
  GetBrandPagesParamProductAssetFieldDecisions,
  instanceOfGetBrandPagesParamProductAssetFieldDecisions,
  GetBrandPagesParamProductAssetFieldDecisionsFromJSON,
  GetBrandPagesParamProductAssetFieldDecisionsFromJSONTyped,
  GetBrandPagesParamProductAssetFieldDecisionsToJSON,
} from './GetBrandPagesParamProductAssetFieldDecisions'

/**
 * @type GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =
  | ({ type: 'ASSET' } & GetBrandPagesParamAssetFieldDecisions)
  | ({ type: 'BRAND_PAGE' } & GetBrandPagesParamBrandPageFieldDecisions)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetBrandPagesParamBrandPageBlockFieldDecisions)
  | ({ type: 'CREATIVE' } & GetBrandPagesParamCreativeFieldDecisions)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetBrandPagesParamDisplayAdGroupFieldDecisions)
  | ({ type: 'PRODUCT_ASSET' } & GetBrandPagesParamProductAssetFieldDecisions)

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return { ...GetBrandPagesParamAssetFieldDecisionsFromJSONTyped(json, true), type: 'ASSET' }
    case 'BRAND_PAGE':
      return {
        ...GetBrandPagesParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetBrandPagesParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetBrandPagesParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetBrandPagesParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetBrandPagesParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetBrandPagesParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return GetBrandPagesParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetBrandPagesParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return GetBrandPagesParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetBrandPagesParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return GetBrandPagesParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
