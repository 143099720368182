import { useEffect } from 'react'
import { usePropRef } from 'common/usePropRef'
import { generateScriptElement } from '../htmlScripts.hooks'

const RE_CAPTCHA_SCRIPT_SRC = 'https://www.google.com/recaptcha/enterprise.js'

export const useReCaptchaScript = (onLoad: () => void) => {
  const onLoadRef = usePropRef(onLoad)

  useEffect(() => {
    const script = generateScriptElement(RE_CAPTCHA_SCRIPT_SRC, onLoadRef.current)

    document.body.appendChild(script)

    return () => {
      // If script is in the dom, we remove it
      if (script.parentElement) {
        document.body.removeChild(script)
      }
    }
  }, [onLoadRef])
}
