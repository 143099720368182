import moment from 'moment'
import { ChangeEvent } from 'react'
import { formatDate, useIntl } from 'common'
import {
  CurrencyInputField,
  DateInputField,
  DateInputFieldProps,
  TextInputField,
  TextInputFieldProps,
  TextAreaInputField,
} from 'components/molecules/InputFields'
import { InputApprovalInfo } from 'components/molecules/InputFields/InputApprovalInfo'
import { InputFieldContainerProps } from 'components/molecules/InputFields/InputFieldContainer'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import { ApprovalInfo } from 'pages/DisplayProduct/types'
import { TextAreaProps } from '../molecules/TextArea'

// eslint-disable-next-line no-restricted-syntax
export enum FormFieldType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  DATE = 'date',
  TEXTAREA = 'textarea',
}

interface FormFieldProps
  extends Omit<
    InputFieldContainerProps,
    // passing name is not allowed to make things consistent. Pass `id` and it will be copied over to the `name` field
    'type' | 'label' | 'hint' | 'name' | 'info' | 'infoDescription'
  > {
  type?:
    | TextInputFieldProps['type']
    | FormFieldType.NUMBER
    | FormFieldType.DATE
    | FormFieldType.TEXTAREA
  label: MessageIdType
  hint?: MessageIdType
  info?: MessageIdType
  infoDescription?: MessageIdType
  approvalInfo?: ApprovalInfo
  onDateChange?: DateInputFieldProps['onChange']
  /*
    Ignore formik's touched value when displaying errors.
    Used as a temporary solution for BPs dynamic block validation.
  */
  ignoreTouched?: boolean
  // This is only used for textareas
  rows?: TextAreaProps['rows']
}

/** @deprecated use Input field components directly */
export default function FormField({
  id,
  type,
  label,
  hint,
  info,
  infoDescription,
  approvalInfo,
  ignoreTouched,
  ...rest
}: FormFieldProps) {
  const [field, meta, { setValue, setTouched }] = useFormikField(id)
  const intl = useIntl()

  const { touched, error, initialError, initialValue } = meta
  const touchConsideredError = touched || ignoreTouched ? error : undefined
  const errorString =
    initialValue && initialValue === field.value && initialError
      ? initialError
      : touchConsideredError
  const props = {
    id,
    name: id,
    value: field.value,
    'data-testid': `${id}-field`,
    defaultValue: field.value?.toString() || '',
    label: intl.formatMessage({ id: label }),
    hint: hint ? intl.formatMessage({ id: hint }) : undefined,
    error: errorString,
    info: info ? intl.formatMessage({ id: info }) : undefined,
    infoDescription: infoDescription ? intl.formatMessage({ id: infoDescription }) : undefined,
    onBlur: () => setTouched(true),
    additionalInfo:
      approvalInfo && meta.initialValue === meta.value ? (
        <InputApprovalInfo approvalInfo={approvalInfo} compact={rest.compact} />
      ) : undefined,
    ...rest,
  }

  if (type === FormFieldType.NUMBER) {
    props.onBlur = () => {
      // We calling setValue(Number()) in onBlur to enable decimal support.
      // Calling setValue(Number()) in onChange would not work because Number("15.") will
      // evaluate to 15, so users would not be able to type a decimal number.
      setValue(Number(field.value))
      setTouched(true)
    }
    return (
      <CurrencyInputField
        {...props}
        onChange={(value: string | undefined) => {
          setValue(value || 0)
        }}
      />
    )
  }

  if (type === FormFieldType.DATE) {
    const restrictedDate = new Date()

    return (
      <DateInputField
        {...props}
        value={field.value && moment(field.value).toDate()}
        onChange={(date: Date) => {
          setValue(formatDate(date))
          props.onDateChange?.(date)
        }}
        dayPickerProps={{
          disabledDays: { before: restrictedDate },
        }}
      />
    )
  }

  if (type === FormFieldType.TEXTAREA) {
    return (
      <TextAreaInputField
        {...props}
        onChange={text => {
          setValue(text)
        }}
        onBlur={() => setTouched(true)}
      />
    )
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event)
    props.onChange?.(event)
  }

  return <TextInputField {...props} type={type} onChange={onChange} />
}
