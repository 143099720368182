// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandName } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandName'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandNameFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandNameFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandNameToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandName'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnit } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnit'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnitFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnitFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnitToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnit'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategory } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategory'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategoryFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategoryFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategoryToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategory'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalTheme } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalTheme'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalThemeFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalThemeFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalThemeToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalTheme'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandName } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandName'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandNameFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandNameFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandNameToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandName'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrands } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrands'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrandsFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrandsFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrandsToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrands'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
 */
export interface ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage {
  /**
   * List of labels for keywords
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalTheme>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  seasonalTheme: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalTheme>
  /**
   * List of labels for custom category
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategory>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  customCategory: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategory>
  /**
   * List of labels for business unit
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnit>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  businessUnit: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnit>
  /**
   * List of labels for brand name
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandName>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  brandName: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandName>
  /**
   * List of labels for sub brand name
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandName>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  subBrandName: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandName>
  /**
   * List of labels for taxonomy brands
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrands>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
   */
  taxonomyBrands: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrands>
}

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'seasonalTheme' in value
  isInstance = isInstance && 'customCategory' in value
  isInstance = isInstance && 'businessUnit' in value
  isInstance = isInstance && 'brandName' in value
  isInstance = isInstance && 'subBrandName' in value
  isInstance = isInstance && 'taxonomyBrands' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage {
  return ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSONTyped(json, false)
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    seasonalTheme: (json['seasonal_theme'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalThemeFromJSON
    ),
    customCategory: (json['custom_category'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategoryFromJSON
    ),
    businessUnit: (json['business_unit'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnitFromJSON
    ),
    brandName: (json['brand_name'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandNameFromJSON
    ),
    subBrandName: (json['sub_brand_name'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandNameFromJSON
    ),
    taxonomyBrands: (json['taxonomy_brands'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrandsFromJSON
    ),
  }
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageToJSON(
  value?: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    seasonal_theme: (value.seasonalTheme as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSeasonalThemeToJSON
    ),
    custom_category: (value.customCategory as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageCustomCategoryToJSON
    ),
    business_unit: (value.businessUnit as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBusinessUnitToJSON
    ),
    brand_name: (value.brandName as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBrandNameToJSON
    ),
    sub_brand_name: (value.subBrandName as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageSubBrandNameToJSON
    ),
    taxonomy_brands: (value.taxonomyBrands as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageTaxonomyBrandsToJSON
    ),
  }
}
