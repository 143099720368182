import { useCallback, useEffect, useState } from 'react'
import { captureException } from 'common/sentry'
import { useAuthContext, AuthCtx } from 'context'
import { AdUsersFeatureHintsApi as FeatureHintsApi } from 'service/apis/AdUsersFeatureHintsApi'
import {
  isFeatureHintDismissed,
  isHelpCenterTooltipDismissed,
  setFeatureHintDismissed,
  setHelpCenterTooltipDismissed,
} from './localStorage'

export const KNOWLEDGE_OWL_TOOLTIP_NAME = 'header.helpCenter'

export const dismissHintWithAuthContext = async (authContext: AuthCtx, name: string) => {
  const featureHint = authContext?.user?.featureHints?.find(hint => hint.attributes.name === name)
  if (!featureHint) return

  try {
    await FeatureHintsApi.patchFeatureHintsIdDismiss({ id: featureHint.id })
  } catch (err) {
    captureException('DismissFeatureHint: Dismiss feature hint call failed', err)
    return
  }

  await authContext.refresh?.()

  /**
   * To ensure it's backwards compatible,
   * we update what's in local storage to match the BE.
   */
  if (name === KNOWLEDGE_OWL_TOOLTIP_NAME) {
    setHelpCenterTooltipDismissed()
  } else {
    setFeatureHintDismissed(name)
  }
}

export default function useFeatureHint(name: string, showOnItemDismissed?: string) {
  const authContext = useAuthContext()
  const [valid, setValid] = useState(false)
  const dismissHint = useCallback(
    async () => dismissHintWithAuthContext(authContext, name),
    [authContext, name]
  )

  useEffect(() => {
    const checkValidity = async () => {
      let validHint = !!authContext?.user?.featureHints?.some(hint => hint.attributes.name === name)

      if (showOnItemDismissed) {
        validHint =
          validHint &&
          !authContext?.user?.featureHints?.some(
            hint => hint.attributes.name === showOnItemDismissed
          )
      }

      /**
       * To ensure it's backwards compatible,
       * we update the BE to match what's in local storage.
       */
      const localStoragedDismissed =
        name === KNOWLEDGE_OWL_TOOLTIP_NAME
          ? isHelpCenterTooltipDismissed()
          : isFeatureHintDismissed(name)
      if (localStoragedDismissed && validHint) {
        await dismissHint()
        setValid(false)
        return
      }

      setValid(validHint)
    }

    checkValidity()
  }, [authContext, dismissHint, name, showOnItemDismissed])

  return {
    validHint: valid,
    dismissHint,
  }
}
