import { canUseDOM } from 'exenv'
import { isValidElement } from 'react'
import { useHistory } from 'react-router-dom'
import { isUserAuthorized, IsUserAuthorizedParams } from 'common/auth'
import { FlipperIdType } from 'common/types'
import { isFlipperEnabled, useAuthContext } from 'context'
import { useCapabilitiesContext } from 'context/capabilities'

export interface RestrictedProps extends IsUserAuthorizedParams {
  children: JSX.Element
  loading?: JSX.Element | null
  fallback?: React.ElementType | React.ComponentType<React.PropsWithChildren<unknown>> | null
  redirectIfNotLoggedIn?: boolean
  redirectWithFlipper?: FlipperIdType
  disableRedirectWithFlipper?: FlipperIdType
  redirectProps?: Record<string, unknown>
  redirectTo?: React.ElementType | React.ComponentType<React.PropsWithChildren<unknown>> | null
}

export default function Restricted({
  children,
  loading = null,
  fallback = null,
  capabilities,
  featureCapabilities,
  disableByAccountTypes,
  disable,
  flipper,
  disableFlipper,
  redirectIfNotLoggedIn = false,
  redirectWithFlipper,
  disableRedirectWithFlipper,
  redirectTo,
  redirectProps,
}: RestrictedProps) {
  const history = useHistory()
  const context = useAuthContext()
  const capabilitiesContext = useCapabilitiesContext()

  // show loading state if SSR or still pending
  if (!canUseDOM || !context.checked) {
    return loading
  }

  if (redirectIfNotLoggedIn && !context.authed) {
    history.push('/login')
  }

  if (
    !isUserAuthorized(context, capabilitiesContext, {
      capabilities,
      featureCapabilities,
      disableByAccountTypes,
      disable,
      flipper,
      disableFlipper,
    })
  ) {
    // `fallback` can be null, so just return that if it is
    if (!fallback) return null

    // If `fallback` is an element we render as is
    if (isValidElement(fallback)) return fallback

    // `fallback` is a component type. We rename it to Fallback to follow component naming conventions
    const Fallback = fallback
    return <Fallback />
  }

  const redirect = redirectWithFlipper && isFlipperEnabled(context, redirectWithFlipper)
  const disableRedirect =
    disableRedirectWithFlipper && isFlipperEnabled(context, disableRedirectWithFlipper)

  // Redirect with flipper enabled and disableRedirectWithFlipper not enabled
  if (redirect && !disableRedirect) {
    if (!redirectTo) return null
    const Redirect = redirectTo
    return <Redirect {...redirectProps} />
  }

  // All checks have passed, render `children`
  return children
}
