import { BrandPageFormFields } from 'pages/DisplayProduct/common/components/BrandPage/brandPage.types'
import {
  fromPrototypeAdGroup,
  toPrototypeAdGroup,
} from 'pages/DisplayProduct/common/components/BrandPage/brandPage.utils'
import {
  PutPrototypeCampaignsIdRequest,
  PostPrototypeCampaignsRequest,
} from 'service/openapi/__codegen__/apis/PrototypeCampaignsApi'
import { ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage as PrototypeCampaignAdGroupBrandPage } from 'service/openapi/__codegen__/models/ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage'
import { GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets } from 'service/openapi/__codegen__/models/GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets'
import { PostPrototypeCampaignsParamCampaignAdGroupAssets } from 'service/openapi/__codegen__/models/PostPrototypeCampaignsParamCampaignAdGroupAssets'
import { PrototypeCampaignResponseDataAttributes } from 'service/openapi/__codegen__/models/PrototypeCampaignResponseDataAttributes'
import { PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeTypeEnum } from 'service/openapi/__codegen__/models/PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative'
import {
  getSchemaProperties,
  getSchemaAssets,
  getSchemaProductPickerAction,
} from '../formatSchemaAccessors'
import { FormAsset, FormatSchemaType, PrototypeCampaignFormData } from '../types'
import { generateFormSchema } from './validation'

const toEmptyFormAsset = (assetMachineName: string | undefined): FormAsset => {
  return {
    id: '',
    machineName: assetMachineName,
    urls: {
      previewUrl: '',
      uploadedUri: '',
    },
    altText: '',
  }
}

const toPrototypeCampaignAsset = (
  asset: FormAsset
): PostPrototypeCampaignsParamCampaignAdGroupAssets => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    id: asset.id,
    type: asset.machineName,
    previewUrl: asset.urls.previewUrl,
    uploadedUri: asset.urls.uploadedUri,
    altText: asset.altText,
  } as PostPrototypeCampaignsParamCampaignAdGroupAssets
}

const fromPrototypeCampaignAsset = (
  asset: GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets | undefined
): FormAsset => {
  return {
    id: asset?.id,
    machineName: asset?.type,
    urls: {
      previewUrl: asset?.previewUrl,
      uploadedUri: asset?.uploadedUri,
    },
    altText: asset?.altText,
  }
}

export const buildCreatePrototypeCampaignRequest = (
  values: PrototypeCampaignFormData,
  creativeSchema: FormatSchemaType | undefined,
  campaignId?: string,
  adGroupId?: string
  // eslint-disable-next-line max-params
): PostPrototypeCampaignsRequest => {
  // Dynamically generate properties in request based on creativeSchema
  const schemaProperties = getSchemaProperties(creativeSchema)
  const requestProperties: Record<string, unknown> = {}
  if (schemaProperties) {
    Object.entries(schemaProperties).forEach(([propertyName]) => {
      requestProperties[propertyName] = values[propertyName]
    })
  }

  // Dynamically generate assets in request based on creativeSchema
  const schemaAssets = getSchemaAssets(creativeSchema)
  let requestAssets: PostPrototypeCampaignsParamCampaignAdGroupAssets[] = []
  if (schemaAssets) {
    requestAssets = Object.entries(schemaAssets).map(([assetIdentifier]) => {
      return toPrototypeCampaignAsset(values[assetIdentifier] as FormAsset)
    })
  }
  // Filter out assets that were not uploaded
  requestAssets = requestAssets.filter(asset => asset.uploadedUri)

  // Dynamically generate creative actions, for now this can only be an item grid
  const requestActions: Record<string, unknown> = {}
  if (getSchemaProductPickerAction(creativeSchema)) {
    requestActions.mainItemGrid = { itemGrid: values.productIds }
  }

  const brandPage: PrototypeCampaignAdGroupBrandPage | undefined =
    schemaProperties?.hasBrandPageClick
      ? toPrototypeAdGroup({
          ...values.brandPage,
          highlightedProductIds: values.productIds,
        } as BrandPageFormFields)
      : undefined

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    body: {
      campaign: {
        name: values.name,
        startsAt: values.startsAt,
        endsAt: values.endsAt,
        adGroup: {
          id: adGroupId,
          campaignId,
          name: values.name,
          creative: {
            id: values.creativeId,
            type: values.creativeType,
            properties: requestProperties,
            actions: requestActions,
          },
          assets: requestAssets,
          brandPage: brandPage
            ? {
                ...brandPage,
                id: values.brandPage?.id,
              }
            : undefined,
          placementType: values.placementType || 'storefront_interleaved.v1',
          targetingStrategy: values.targetingStrategy || {},
        },
      },
    },
  } as PostPrototypeCampaignsRequest
}

export const buildUpdatePrototypeCampaignRequest = (
  campaignId: string,
  adGroupId: string | undefined,
  values: PrototypeCampaignFormData,
  creativeSchema: FormatSchemaType | undefined
  // eslint-disable-next-line max-params
): PutPrototypeCampaignsIdRequest => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    ...buildCreatePrototypeCampaignRequest(values, creativeSchema, campaignId, adGroupId),
    id: campaignId,
  } as PutPrototypeCampaignsIdRequest
}

export const getInitialFormValuesForNewCampaign = (
  creativeSchema: FormatSchemaType
): PrototypeCampaignFormData => {
  const initialValues: PrototypeCampaignFormData = {
    creativeType:
      creativeSchema.machine_name as PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeTypeEnum,
  }

  // Add empty properties to the values so they appear touched and can be validated
  const schemaProperties = getSchemaProperties(creativeSchema)
  if (schemaProperties) {
    Object.entries(schemaProperties).forEach(([propertyName]) => {
      initialValues[propertyName] = ''
    })
  }

  // Instantiate the empty asset objects in the values
  const schemaAssets = getSchemaAssets(creativeSchema)
  if (schemaAssets) {
    Object.entries(schemaAssets).forEach(([assetIdentifier, assetObject]) => {
      initialValues[assetIdentifier] = toEmptyFormAsset(assetObject.asset_type)
    })
  }

  const formSchema = generateFormSchema(creativeSchema)

  return formSchema.cast(initialValues)
}

export const getInitialFormValuesForExistingCampaign = (
  creativeSchema: FormatSchemaType | undefined,
  existingPrototypeCampaign: PrototypeCampaignResponseDataAttributes
): PrototypeCampaignFormData => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const initialValues = {
    name: existingPrototypeCampaign.name,
    startsAt: existingPrototypeCampaign.startsAt,
    endsAt: existingPrototypeCampaign.endsAt,
    creativeType: existingPrototypeCampaign.adGroup?.attributes.creative.type,
    creativeId: existingPrototypeCampaign.adGroup?.attributes.creative.id,
    productIds:
      existingPrototypeCampaign.adGroup?.attributes.creative.actions?.mainItemGrid?.itemGrid,
    brandPage: fromPrototypeAdGroup(existingPrototypeCampaign.adGroup?.attributes.brandPage),
    placementType: existingPrototypeCampaign.adGroup?.attributes.placementType,
    targetingStrategy: existingPrototypeCampaign.adGroup?.attributes.targetingStrategy || {},
  } as PrototypeCampaignFormData

  // Get all property values from the existing campaign
  const schemaProperties = getSchemaProperties(creativeSchema)
  if (schemaProperties) {
    Object.entries(schemaProperties).forEach(([propertyName]) => {
      initialValues[propertyName] =
        existingPrototypeCampaign.adGroup?.attributes.creative.properties?.[propertyName]
    })
  }

  // Build list of assets from the existing campaign
  const schemaAssets = getSchemaAssets(creativeSchema)
  if (schemaAssets) {
    Object.entries(schemaAssets).forEach(([assetIdentifier, assetObject]) => {
      const existingAsset = existingPrototypeCampaign.adGroup?.attributes.assets.find(
        asset => asset.type.toString() === assetObject?.asset_type.toString()
      )
      if (existingAsset) {
        initialValues[assetIdentifier] = fromPrototypeCampaignAsset(existingAsset)
      } else {
        initialValues[assetIdentifier] = toEmptyFormAsset(assetObject.asset_type)
      }
    })
  }

  return initialValues
}
