// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop } from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import {
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSONTyped,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON,
} from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import type { GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile } from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile'
import {
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSONTyped,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON,
} from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
 */
export interface GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  /**
   *
   * @type {GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
   */
  desktop?: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop
  /**
   *
   * @type {GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
   */
  mobile?: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile
}

/**
 * Check if a given object implements the GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues interface.
 */
export function instanceOfGetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON(
  json: any
): GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  return GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
    json,
    false
  )
}

export function GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: !exists(json, 'desktop')
      ? undefined
      : GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON(
          json['desktop']
        ),
    mobile: !exists(json, 'mobile')
      ? undefined
      : GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON(
          json['mobile']
        ),
  }
}

export function GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON(
  value?: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON(
      value.desktop
    ),
    mobile: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON(
      value.mobile
    ),
  }
}
