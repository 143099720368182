// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchy } from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchy'
import {
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchyFromJSON,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchyToJSON,
} from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchy'
import type { PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValues } from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValues'
import {
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValues'
import type { PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitle } from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitle'
import {
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitleFromJSON,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitleFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitleToJSON,
} from './PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBrandPageFieldDecisions
 */
export interface PutDisplayCampaignsIdParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitle}
   * @memberof PutDisplayCampaignsIdParamBrandPageFieldDecisions
   */
  title?: PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchy>}
   * @memberof PutDisplayCampaignsIdParamBrandPageFieldDecisions
   */
  hierarchy?: Array<PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof PutDisplayCampaignsIdParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBrandPageFieldDecisions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBrandPageFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamBrandPageFieldDecisions {
  return PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function PutDisplayCampaignsIdParamBrandPageFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: PutDisplayCampaignsIdParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            PutDisplayCampaignsIdParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(
            value.taxonomy,
            PutDisplayCampaignsIdParamBrandPageFieldDecisionsTaxonomyValuesToJSON
          ),
  }
}
