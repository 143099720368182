// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AdminBrandPagesResponse } from '../models/AdminBrandPagesResponse'
import {
  AdminBrandPagesResponseFromJSON,
  AdminBrandPagesResponseToJSON,
} from '../models/AdminBrandPagesResponse'
import type { ApiAdminBrandPagesControllerLabelInput } from '../models/ApiAdminBrandPagesControllerLabelInput'
import {
  ApiAdminBrandPagesControllerLabelInputFromJSON,
  ApiAdminBrandPagesControllerLabelInputToJSON,
} from '../models/ApiAdminBrandPagesControllerLabelInput'
import type { ApplicableLabelsAdminBrandPageResponse } from '../models/ApplicableLabelsAdminBrandPageResponse'
import {
  ApplicableLabelsAdminBrandPageResponseFromJSON,
  ApplicableLabelsAdminBrandPageResponseToJSON,
} from '../models/ApplicableLabelsAdminBrandPageResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { LabelAdminBrandPagesResponse } from '../models/LabelAdminBrandPagesResponse'
import {
  LabelAdminBrandPagesResponseFromJSON,
  LabelAdminBrandPagesResponseToJSON,
} from '../models/LabelAdminBrandPagesResponse'
import type { StandaloneBrandPageResponse } from '../models/StandaloneBrandPageResponse'
import {
  StandaloneBrandPageResponseFromJSON,
  StandaloneBrandPageResponseToJSON,
} from '../models/StandaloneBrandPageResponse'

export interface GetAdminBrandPagesRequest {
  searchCriteriaStatus?: Array<GetAdminBrandPagesSearchCriteriaStatusEnum>
  searchCriteriaAdminUiState?: Array<GetAdminBrandPagesSearchCriteriaAdminUiStateEnum>
  searchCriteriaQuery?: string
  searchCriteriaIncludeTestBrandPages?: boolean
  sortCriteriaField?: GetAdminBrandPagesSortCriteriaFieldEnum
  sortCriteriaDirection?: GetAdminBrandPagesSortCriteriaDirectionEnum
  pageCriteriaPage?: number
  pageCriteriaItemsPerPage?: number
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdminBrandPagesIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdminBrandPagesIdApplicableLabelsRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostAdminBrandPagesIdLabelRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdminBrandPagesControllerLabelInput
}

/**
 *
 */
export class AdminBrandPagesApi extends runtime.BaseAPI {
  /**
   *
   * Get brand pages
   */
  async getAdminBrandPagesRaw(
    requestParameters: GetAdminBrandPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdminBrandPagesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.searchCriteriaStatus) {
      queryParameters['search_criteria[status]'] = requestParameters.searchCriteriaStatus.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.searchCriteriaAdminUiState) {
      queryParameters['search_criteria[admin_ui_state]'] =
        requestParameters.searchCriteriaAdminUiState.join(runtime.COLLECTION_FORMATS['csv'])
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    if (requestParameters.searchCriteriaIncludeTestBrandPages !== undefined) {
      queryParameters['search_criteria[include_test_brand_pages]'] =
        requestParameters.searchCriteriaIncludeTestBrandPages
    }

    if (requestParameters.sortCriteriaField !== undefined) {
      queryParameters['sort_criteria[field]'] = requestParameters.sortCriteriaField
    }

    if (requestParameters.sortCriteriaDirection !== undefined) {
      queryParameters['sort_criteria[direction]'] = requestParameters.sortCriteriaDirection
    }

    if (requestParameters.pageCriteriaPage !== undefined) {
      queryParameters['page_criteria[page]'] = requestParameters.pageCriteriaPage
    }

    if (requestParameters.pageCriteriaItemsPerPage !== undefined) {
      queryParameters['page_criteria[items_per_page]'] = requestParameters.pageCriteriaItemsPerPage
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/admin/brand_pages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AdminBrandPagesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get brand pages
   */
  async getAdminBrandPages(
    requestParameters: GetAdminBrandPagesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdminBrandPagesResponse> {
    const response = await this.getAdminBrandPagesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Get the Brand Page for the given id.
   */
  async getAdminBrandPagesIdRaw(
    requestParameters: GetAdminBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPageResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdminBrandPagesId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/admin/brand_pages/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get the Brand Page for the given id.
   */
  async getAdminBrandPagesId(
    requestParameters: GetAdminBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPageResponse> {
    const response = await this.getAdminBrandPagesIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * List brand page applicable labels
   */
  async getAdminBrandPagesIdApplicableLabelsRaw(
    requestParameters: GetAdminBrandPagesIdApplicableLabelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApplicableLabelsAdminBrandPageResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdminBrandPagesIdApplicableLabels.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/admin/brand_pages/{id}/applicable_labels`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ApplicableLabelsAdminBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * List brand page applicable labels
   */
  async getAdminBrandPagesIdApplicableLabels(
    requestParameters: GetAdminBrandPagesIdApplicableLabelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApplicableLabelsAdminBrandPageResponse> {
    const response = await this.getAdminBrandPagesIdApplicableLabelsRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   *
   * Label a brand page and its entities
   */
  async postAdminBrandPagesIdLabelRaw(
    requestParameters: PostAdminBrandPagesIdLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LabelAdminBrandPagesResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postAdminBrandPagesIdLabel.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/admin/brand_pages/{id}/label`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdminBrandPagesControllerLabelInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      LabelAdminBrandPagesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Label a brand page and its entities
   */
  async postAdminBrandPagesIdLabel(
    requestParameters: PostAdminBrandPagesIdLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LabelAdminBrandPagesResponse> {
    const response = await this.postAdminBrandPagesIdLabelRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetAdminBrandPagesSearchCriteriaStatusEnum = {
  Archived: 'ARCHIVED',
  Draft: 'DRAFT',
  Editable: 'EDITABLE',
  InReview: 'IN_REVIEW',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Serving: 'SERVING',
  Stale: 'STALE',
  Unknown: 'UNKNOWN',
} as const
export type GetAdminBrandPagesSearchCriteriaStatusEnum =
  typeof GetAdminBrandPagesSearchCriteriaStatusEnum[keyof typeof GetAdminBrandPagesSearchCriteriaStatusEnum]
/**
 * @export
 */
export const GetAdminBrandPagesSearchCriteriaAdminUiStateEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetAdminBrandPagesSearchCriteriaAdminUiStateEnum =
  typeof GetAdminBrandPagesSearchCriteriaAdminUiStateEnum[keyof typeof GetAdminBrandPagesSearchCriteriaAdminUiStateEnum]
/**
 * @export
 */
export const GetAdminBrandPagesSortCriteriaFieldEnum = {
  Name: 'name',
  AdminUiState: 'admin_ui_state',
  Level: 'level',
  Slug: 'slug',
} as const
export type GetAdminBrandPagesSortCriteriaFieldEnum =
  typeof GetAdminBrandPagesSortCriteriaFieldEnum[keyof typeof GetAdminBrandPagesSortCriteriaFieldEnum]
/**
 * @export
 */
export const GetAdminBrandPagesSortCriteriaDirectionEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const
export type GetAdminBrandPagesSortCriteriaDirectionEnum =
  typeof GetAdminBrandPagesSortCriteriaDirectionEnum[keyof typeof GetAdminBrandPagesSortCriteriaDirectionEnum]
