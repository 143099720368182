import { Base, combineStyles, spacing, useTheme } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'

const useStyles = () => {
  const theme = useTheme()

  return {
    root: {
      display: 'flex',
      '& + &': {
        marginTop: spacing.s24,
      },
    },
    label: {
      flex: 1,
      textAlign: 'right',
      paddingRight: spacing.s12,
      ...theme.typography.bodyMedium1,
    },
    content: {
      flex: 4,
      ...theme.typography.bodyMedium2,
    },
  } as const
}

export interface DataRowProps extends Base<typeof useStyles> {
  testId?: string
  labelId?: MessageIdType
  label?: React.ReactNode
  children?: React.ReactNode
}

export default function DataRow({ testId, labelId, label, children, styles }: DataRowProps) {
  const css = combineStyles(useStyles(), styles)

  return (
    <div data-testid={testId || labelId} css={css.root}>
      <div css={css.label}>{labelId ? <FormattedMessage id={labelId} /> : label || null}</div>
      <div css={css.content}>{children}</div>
    </div>
  )
}
