// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdGroupKeywordsBulkResponseData } from './AdGroupKeywordsBulkResponseData'
import {
  AdGroupKeywordsBulkResponseDataFromJSON,
  AdGroupKeywordsBulkResponseDataFromJSONTyped,
  AdGroupKeywordsBulkResponseDataToJSON,
} from './AdGroupKeywordsBulkResponseData'
import type { AdGroupKeywordsBulkResponseMeta } from './AdGroupKeywordsBulkResponseMeta'
import {
  AdGroupKeywordsBulkResponseMetaFromJSON,
  AdGroupKeywordsBulkResponseMetaFromJSONTyped,
  AdGroupKeywordsBulkResponseMetaToJSON,
} from './AdGroupKeywordsBulkResponseMeta'

/**
 *
 * @export
 * @interface AdGroupKeywordsBulkResponse
 */
export interface AdGroupKeywordsBulkResponse {
  /**
   *
   * @type {AdGroupKeywordsBulkResponseData}
   * @memberof AdGroupKeywordsBulkResponse
   */
  data: AdGroupKeywordsBulkResponseData
  /**
   *
   * @type {AdGroupKeywordsBulkResponseMeta}
   * @memberof AdGroupKeywordsBulkResponse
   */
  meta: AdGroupKeywordsBulkResponseMeta
}

/**
 * Check if a given object implements the AdGroupKeywordsBulkResponse interface.
 */
export function instanceOfAdGroupKeywordsBulkResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AdGroupKeywordsBulkResponseFromJSON(json: any): AdGroupKeywordsBulkResponse {
  return AdGroupKeywordsBulkResponseFromJSONTyped(json, false)
}

export function AdGroupKeywordsBulkResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupKeywordsBulkResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: AdGroupKeywordsBulkResponseDataFromJSON(json['data']),
    meta: AdGroupKeywordsBulkResponseMetaFromJSON(json['meta']),
  }
}

export function AdGroupKeywordsBulkResponseToJSON(value?: AdGroupKeywordsBulkResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: AdGroupKeywordsBulkResponseDataToJSON(value.data),
    meta: AdGroupKeywordsBulkResponseMetaToJSON(value.meta),
  }
}
