// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText
 */
export interface GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText {
  /**
   * Id of the label
   * @type {string}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText
   */
  id: string
  /**
   * Version of the label
   * @type {number}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText
   */
  version: number
}

/**
 * Check if a given object implements the GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText interface.
 */
export function instanceOfGetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'version' in value

  return isInstance
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSON(
  json: any
): GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText {
  return GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSONTyped(
    json,
    false
  )
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: json['version'],
  }
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextToJSON(
  value?: GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
  }
}
