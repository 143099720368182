import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'

export const MAX_PRODUCTS_FOR_PROMOTED_AISLE = 40
export const MIN_PRODUCTS_FOR_PROMOTED_AISLE = 3
export const MAX_PRODUCT_GROUPS_FOR_SHOPPABLE = 20
export const MIN_PRODUCT_GROUPS_FOR_SHOPPABLE = 1
export const MIN_CHARACTERS_FOR_ALT_TEXT = 3
export const MIN_KEYWORDS_FOR_BANNER = 1
export const MAX_KEYWORDS_FOR_BANNER = 12000
export const MIN_PRODUCTS_FOR_BANNER = 3
export const MAX_PRODUCTS_FOR_BANNER = 20
export const MAX_CHARACTERS_FOR_TAGLINE = 29
export const MIN_PRODUCTS_FOR_BRAND_PAGE_PREVIEW = 3
export const MIN_BRAND_PAGE_TITLE_LENGTH = 3
export const MAX_BRAND_PAGE_TITLE_LENGTH = 30
export const MIN_TARGETED_PRODUCT_GROUP_IDS = 1
export const MIN_TARGETED_PRODUCT_IDS = 3
export const MAX_TARGETED_PRODUCT_IDS = 250
export const MAX_TARGETED_PRODUCT_IDS_INCREASED = 500
export const COLLECTIONS_MIN = 2
export const COLLECTIONS_MAX = 5
export const COLLECTION_TITLE_CHAR_MAX = 25
export const COLLECTION_TITLE_CHAR_MIN = 3
export const COLLECTION_SEARCH_TERM_CHAR_MAX = 9999
export const COLLECTION_SEARCH_TERM_CHAR_MIN = 3
export const COLLECTION_PRODUCT_MIN = 3
export const COLLECTION_PRODUCT_MAX = 30
export const SPONSORED_COLLECTION_MAX = 4
export const SPONSORED_COLLECTION_MIN = 1
export const NON_SPONSORED_COLLECTION_MAX = 4
export const NON_SPONSORED_COLLECTION_MIN = 1
export const AD_GROUP_NAME_FIELD_NAME = 'name'
export const DEFAULT_BID_FIELD_NAME = 'defaultBid'
export const CREATIVE_TYPE = 'creativeType'
export const PRODUCT_FIELD_NAME = 'productIds'
export const PRODUCT_GROUP_FIELD_NAME = 'productGroupIds'
export const BRAND_PAGE_FIELD_NAME = 'brandPage'
export const BRAND_PAGE_TITLE_FIELD_NAME = 'brandPage.title'
export const BRAND_PAGE_ADDITIONAL_PRODUCTS_FIELD_NAME = 'brandPage.additionalProductIds'
export const BRAND_PAGE_HIGHLIGHTED_PRODUCTS_FIELD_NAME = 'brandPage.highlightedProductIds'
export const BRAND_PAGE_HERO_BANNER_FIELD_NAME = 'brandPage.heroBanner'
export const BRAND_PAGE_HERO_BANNER_ALT_FIELD_NAME = `${BRAND_PAGE_HERO_BANNER_FIELD_NAME}.alt`
export const BRAND_PAGE_HERO_BANNER_MOBILE_URI_FIELD_NAME = `${BRAND_PAGE_HERO_BANNER_FIELD_NAME}.mobile.urls`
export const BRAND_PAGE_HERO_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME = `${BRAND_PAGE_HERO_BANNER_MOBILE_URI_FIELD_NAME}.uploadedUri`
export const BRAND_PAGE_HERO_BANNER_DESKTOP_URI_FIELD_NAME = `${BRAND_PAGE_HERO_BANNER_FIELD_NAME}.desktop.urls`
export const BRAND_PAGE_ID_FIELD_NAME = 'brandPageId'
export const BUYER_LICENSE_TARGETING_FIELD_NAME = 'targetingStrategy.userTargeting.buyerLicense'
export const URL_CLICK_URL_FIELD_NAME = 'urlClickUrl'
export const URL_CLICK_URL_LABEL = 'URL'
export const BRAND_PAGE_HERO_BANNER_DESKTOP_UPLOADED_URI_FIELD_NAME = `${BRAND_PAGE_HERO_BANNER_DESKTOP_URI_FIELD_NAME}.uploadedUri`
export const CREATIVE_BANNER_ALT_FIELD_NAME = 'creative.alt'
export const CREATIVE_BANNER_MOBILE_FIELD_NAME = 'creative.topImageMobile.urls'
export const CREATIVE_BANNER_MOBILE_ALT_FIELD_NAME = 'creative.topImageMobile.alt'
export const CREATIVE_BANNER_MOBILE_SQUARE_FIELD_NAME = 'creative.squareImageMobile.urls'
export const CREATIVE_BANNER_MOBILE_SQUARE_ALT_FIELD_NAME = 'creative.squareImageMobile.alt'
export const CREATIVE_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME = `${CREATIVE_BANNER_MOBILE_FIELD_NAME}.uploadedUri`
export const CREATIVE_BANNER_WEB_FIELD_NAME = 'creative.topImageWeb.urls'
export const CREATIVE_BANNER_WEB_ALT_FIELD_NAME = 'creative.topImageWeb.alt'
export const CREATIVE_BANNER_WEB_UPLOADED_URI_FIELD_NAME = `${CREATIVE_BANNER_WEB_FIELD_NAME}.uploadedUri`
export const CREATIVE_BRAND_LOGO_FIELD_NAME = 'brandLogo'
export const CREATIVE_BRAND_LOGO_URI_FIELD_NAME = `creative.${CREATIVE_BRAND_LOGO_FIELD_NAME}.urls`
export const CREATIVE_BRAND_LOGO_UPLOADED_URI_FIELD_NAME = `${CREATIVE_BRAND_LOGO_URI_FIELD_NAME}.uploadedUri`
export const CREATIVE_BRAND_LOGO_ALT_FIELD_NAME = `creative.${CREATIVE_BRAND_LOGO_FIELD_NAME}.alt`
export const CREATIVE_HERO_IMAGE_FIELD_NAME = 'heroImage'
export const CREATIVE_HERO_IMAGE_URI_FIELD_NAME = `creative.${CREATIVE_HERO_IMAGE_FIELD_NAME}.urls`
export const CREATIVE_HERO_IMAGE_UPLOADED_URI_FIELD_NAME = `${CREATIVE_HERO_IMAGE_URI_FIELD_NAME}.uploadedUri`
export const CREATIVE_HERO_IMAGE_ALT_FIELD_NAME = `creative.${CREATIVE_HERO_IMAGE_FIELD_NAME}.alt`
export const CREATIVE_THUMBNAIL_IMAGE_FIELD_NAME = 'thumbnail'
export const CREATIVE_THUMBNAIL_IMAGE_URI_FIELD_NAME = `creative.${CREATIVE_THUMBNAIL_IMAGE_FIELD_NAME}.urls`
export const CREATIVE_THUMBNAIL_IMAGE_UPLOADED_URI_FIELD_NAME = `${CREATIVE_THUMBNAIL_IMAGE_URI_FIELD_NAME}.uploadedUri`
export const CREATIVE_THUMBNAIL_IMAGE_ALT_FIELD_NAME = `creative.${CREATIVE_THUMBNAIL_IMAGE_FIELD_NAME}.alt`
export const CREATIVE_UVC_IMAGE_WEB_FIELD_NAME = 'creative.uvcImageWeb.urls'
export const CREATIVE_UVC_IMAGE_MOBILE_FIELD_NAME = 'creative.uvcImageMobile.urls'
export const CREATIVE_VIDEO_FIELD_NAME = 'video'
export const CREATIVE_VIDEO_URI_FIELD_NAME = `creative.${CREATIVE_VIDEO_FIELD_NAME}.urls`
export const CREATIVE_VIDEO_UPLOADED_URI_FIELD_NAME = `creative.${CREATIVE_VIDEO_URI_FIELD_NAME}.alt`
export const GEOGRAPHY_TARGETING_FIELD_NAME = 'targetingStrategy.userTargeting.geography'
export const PLACEMENT_FIELD_NAME = 'placementType'
export const SKB_KEYWORD_TARGETING_FIELD_NAME = 'targetingStrategy.keywords'
export const SKB_KEYWORD_TARGETING_MATCH_TYPE_FIELD_NAME = 'targetingStrategy.keywordMatchType'
export const TAGLINE_FIELD_NAME = 'tagline'
export const UVC_FIELD_NAME = 'uvcFields'
export const TARGETING_TYPE_FIELD_NAME = 'targetingStrategy.targetingType'
export const TARGETING_SHORTCUT_FIELD_NAME = 'targetingStrategy.targetingShortcut'
export const TARGETING_SHORTCUT_USER_TARGETING = 'TARGETING_SHORTCUT_CUSTOM'
export const TARGETING_SHORTCUT_KEYWORD_TARGETING = 'TARGETING_SHORTCUT_NON_AUDIENCE_TARGETING'
export const TRACKED_PRODUCT_IDS_FIELD_NAME = 'trackedProductIds'
export const TRACKED_PRODUCT_GROUP_IDS_FIELD_NAME = 'trackedProductGroupIds'
export const RESERVATION_SCHEDULES_FIELD_NAME = 'reservationSchedules'
export const PROVI_URL_PREFIX = 'https://app.provi.com/'
export const MIN_PRODUCTS_FOR_SHOPPABLE = (singleUpc?: boolean): number => (singleUpc ? 1 : 3)
export const RECIPE_ID_FIELD_NAME = 'recipeId'
export const COLLECTIONS_FIELD_NAME = 'collections'

export const GET_PRODUCT_FIELD_NAME = (creativeType: string) =>
  isPromotedAisle(creativeType) ? PRODUCT_FIELD_NAME : BRAND_PAGE_HIGHLIGHTED_PRODUCTS_FIELD_NAME

export const GET_ASSET_UPLOADED_URI_FIELD_NAME = (fieldNamePrefix: string) =>
  `${fieldNamePrefix}.uploadedUri`

// eslint-disable-next-line no-restricted-syntax
export enum SkbAdGroupSectionIds {
  FORMAT = 'format',
  PLACEMENT = 'placement',
  BID = 'bid',
  TARGETING = 'targeting',
  PRODUCTS = 'products',
  CREATIVE = 'creative',
  LANDING_PAGE = 'landingPage',
  RELATED_UPCS = 'relatedUpcs',
  SELECT_LANDING_PAGE = 'selectLandingPage',
  COLLECTIONS = 'collections',
  RECIPE = 'recipe-id',
}

// eslint-disable-next-line no-restricted-syntax
export enum TargetingType {
  KEYWORD_TARGETING = 'keywordtargeting',
  USER_TARGETING = 'usertargeting',
  TARGETING_SHORTCUT = 'targetingshortcut',
}

export function isPromotedAisle(creativeType: string) {
  return (
    [
      CreativeTypeEnum.PromotedAisleV1,
      CreativeTypeEnum.PromotedAisleV2,
      CreativeTypeEnum.PromotedAisleV3,
      CreativeTypeEnum.PromotedAisleV4,
    ] as string[]
  ).includes(creativeType)
}

export const GridOrderEnums = {
  Fixed: 'FIXED',
  Dynamic: 'DYNAMIC',
} as const

export type InspirationType = 'occasion' | 'recipe'
