// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdUsersSessionsControllerCreateInput
 */
export interface ApiAdUsersSessionsControllerCreateInput {
  /**
   * User Email
   * @type {string}
   * @memberof ApiAdUsersSessionsControllerCreateInput
   */
  email: string
  /**
   * Password
   * @type {string}
   * @memberof ApiAdUsersSessionsControllerCreateInput
   */
  password: string
  /**
   * Remember me
   * @type {boolean}
   * @memberof ApiAdUsersSessionsControllerCreateInput
   */
  rememberMe?: boolean
  /**
   * reCAPTCHA token
   * @type {string}
   * @memberof ApiAdUsersSessionsControllerCreateInput
   */
  reCaptchaToken?: string
}

/**
 * Check if a given object implements the ApiAdUsersSessionsControllerCreateInput interface.
 */
export function instanceOfApiAdUsersSessionsControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'email' in value
  isInstance = isInstance && 'password' in value

  return isInstance
}

export function ApiAdUsersSessionsControllerCreateInputFromJSON(
  json: any
): ApiAdUsersSessionsControllerCreateInput {
  return ApiAdUsersSessionsControllerCreateInputFromJSONTyped(json, false)
}

export function ApiAdUsersSessionsControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdUsersSessionsControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    email: json['email'],
    password: json['password'],
    rememberMe: !exists(json, 'remember_me') ? undefined : json['remember_me'],
    reCaptchaToken: !exists(json, 're_captcha_token') ? undefined : json['re_captcha_token'],
  }
}

export function ApiAdUsersSessionsControllerCreateInputToJSON(
  value?: ApiAdUsersSessionsControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    email: value.email,
    password: value.password,
    remember_me: value.rememberMe,
    re_captcha_token: value.reCaptchaToken,
  }
}
