import { uniqBy } from 'lodash'
import { IntlShape } from 'react-intl'
import { MessageIdType } from 'locales/types'
import { PutDisplayCampaignsIdParamDataAttributesAdGroups } from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamDataAttributesAdGroups'
import { ApprovalInfoMap, Decision, RejectionLabel } from '../types'

export const APPROVED_DECISION = 'APPROVED'
export const APPROVED_WITH_RESTRICTION_DECISION = 'APPROVED_WITH_RESTRICTION'
export const REJECTED_DECISION = 'REJECTED'
export const ISSUE_ICON_TESTID = 'issueIcon'
export const INFO_ICON_TESTID = 'infoIcon'

export const isAdGroupRejected = (adGroup: PutDisplayCampaignsIdParamDataAttributesAdGroups) => {
  return adGroup.attributes.status === 'REJECTED'
}

// eslint-disable-next-line max-params
export function getFieldValuesWithDecision<T>(
  approvalInfoMapping: ApprovalInfoMap,
  values: T[],
  decision: Decision,
  fetchFieldValue: (_: T) => string
) {
  return values.filter(value => {
    return approvalInfoMapping[fetchFieldValue(value)]?.decision === decision
  })
}

export const getUniqueLabelStrings = (labels: RejectionLabel[], intl: IntlShape) => {
  const uniqueLabels = uniqBy(labels, (label: RejectionLabel) =>
    intl.formatMessage({
      id: `integrity.rejectionLabels.${label?.label_id}` as MessageIdType,
    })
  )

  return uniqueLabels
    .map((label: RejectionLabel) =>
      intl.formatMessage({
        id: `integrity.rejectionLabels.${label?.label_id}` as MessageIdType,
      })
    )
    .join(', ')
}
