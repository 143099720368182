// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetBrandPagesParamBrandPageBlockSnapshot,
  instanceOfGetBrandPagesParamBrandPageBlockSnapshot,
  GetBrandPagesParamBrandPageBlockSnapshotFromJSON,
  GetBrandPagesParamBrandPageBlockSnapshotFromJSONTyped,
  GetBrandPagesParamBrandPageBlockSnapshotToJSON,
} from './GetBrandPagesParamBrandPageBlockSnapshot'
import {
  GetBrandPagesParamBrandPageSnapshot,
  instanceOfGetBrandPagesParamBrandPageSnapshot,
  GetBrandPagesParamBrandPageSnapshotFromJSON,
  GetBrandPagesParamBrandPageSnapshotFromJSONTyped,
  GetBrandPagesParamBrandPageSnapshotToJSON,
} from './GetBrandPagesParamBrandPageSnapshot'
import {
  GetBrandPagesParamCreativeSnapshot,
  instanceOfGetBrandPagesParamCreativeSnapshot,
  GetBrandPagesParamCreativeSnapshotFromJSON,
  GetBrandPagesParamCreativeSnapshotFromJSONTyped,
  GetBrandPagesParamCreativeSnapshotToJSON,
} from './GetBrandPagesParamCreativeSnapshot'
import {
  GetBrandPagesParamDisplayAdGroupSnapshot,
  instanceOfGetBrandPagesParamDisplayAdGroupSnapshot,
  GetBrandPagesParamDisplayAdGroupSnapshotFromJSON,
  GetBrandPagesParamDisplayAdGroupSnapshotFromJSONTyped,
  GetBrandPagesParamDisplayAdGroupSnapshotToJSON,
} from './GetBrandPagesParamDisplayAdGroupSnapshot'
import {
  GetBrandPagesParamDisplayAssetSnapshot,
  instanceOfGetBrandPagesParamDisplayAssetSnapshot,
  GetBrandPagesParamDisplayAssetSnapshotFromJSON,
  GetBrandPagesParamDisplayAssetSnapshotFromJSONTyped,
  GetBrandPagesParamDisplayAssetSnapshotToJSON,
} from './GetBrandPagesParamDisplayAssetSnapshot'
import {
  GetBrandPagesParamDisplayProductAssetSnapshot,
  instanceOfGetBrandPagesParamDisplayProductAssetSnapshot,
  GetBrandPagesParamDisplayProductAssetSnapshotFromJSON,
  GetBrandPagesParamDisplayProductAssetSnapshotFromJSONTyped,
  GetBrandPagesParamDisplayProductAssetSnapshotToJSON,
} from './GetBrandPagesParamDisplayProductAssetSnapshot'

/**
 * @type GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & GetBrandPagesParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & GetBrandPagesParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetBrandPagesParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & GetBrandPagesParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetBrandPagesParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & GetBrandPagesParamDisplayProductAssetSnapshot)

export function GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot {
  return GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return { ...GetBrandPagesParamDisplayAssetSnapshotFromJSONTyped(json, true), type: 'ASSET' }
    case 'BRAND_PAGE':
      return { ...GetBrandPagesParamBrandPageSnapshotFromJSONTyped(json, true), type: 'BRAND_PAGE' }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetBrandPagesParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return { ...GetBrandPagesParamCreativeSnapshotFromJSONTyped(json, true), type: 'CREATIVE' }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetBrandPagesParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetBrandPagesParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetBrandPagesParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return GetBrandPagesParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetBrandPagesParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return GetBrandPagesParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetBrandPagesParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return GetBrandPagesParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
