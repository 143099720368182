export const DELIMITER = '.'
export const NAMESPACE_DELIMITER = '::'
/**
 * maintain a enum of possible actions on a resource.
 * This is also defined on the backend under 'role_permission',
 * but due to it not being actively used as part of an API
 * response, we don't have a codegen counterpart to it
 */
// eslint-disable-next-line no-restricted-syntax
export enum Actions {
  CREATE = 'create',
  DELETE = 'delete',
  LIST = 'list',
  MANAGE = 'manage',
  READ = 'read',
  UPDATE = 'update',
}

// eslint-disable-next-line no-restricted-syntax
export enum Namespaces {
  ADMIN = 'admin',
}

// eslint-disable-next-line no-restricted-syntax
export enum Resources {
  ACCOUNT = 'account',
  ACCOUNT_CREDIT = 'accountcredit',
  ACCOUNT_CREDITS_USAGE = 'accountcreditsusage',
  ACCOUNT_DOCUMENT = 'accountdocument',
  ACCOUNT_DOCUMENT_COUPON = 'accountdocumentcoupon',
  ACCOUNT_DOCUMENT_DELIVERY_PROMOTION = 'accountdocumentdp',
  ACCOUNT_DOCUMENT_HERO = 'accountdocumenthero',
  ACCOUNT_PRODUCT = 'accountproduct',
  ACCOUNT_TIER = 'accounttier',
  AD_GROUP = 'adgroup',
  AD_GROUP_BID_SKU = 'adgroupbidsku',
  AD_GROUP_BID_SKU_DRAFT = 'adgroupbidskudraft',
  AD_GROUP_DRAFT = 'adgroupdraft',
  AD_GROUP_KEYWORD = 'adgroupkeyword',
  AD_GROUP_KEYWORD_DRAFT = 'adgroupkeyworddraft',
  ADHOC_PUBLISHED_ADS_TAXONOMY_PRODUCT = 'adhocpublishedadstaxonomyproduct',
  ADMIN_AD_GROUP = 'admin::adgroup',
  ADMIN_INTEGRITY = 'admin::integrity',
  ADMIN_NEXUS_AD_GROUP = 'admin::nexusadgroup',
  ADVERTISER = 'advertiser',
  ADS_TAXONOMY_ENTITY_HIERARCHY = 'adstaxonomyentityhierarchy',
  ADS_TAXONOMY_ENTITY_LABEL = 'adstaxonomyentitylabel',
  ADS_TAXONOMY_REPORTING_ENTITY = 'adstaxonomyreportingentity',
  AGENCY = 'agency',
  API_ACCOUNT = 'apiaccount',
  BRAND_PAGE = 'brandpage',
  BRAND_PROFILE = 'brandprofile',
  CAMPAIGN = 'campaign',
  CAMPAIGN_DRAFT = 'campaigndraft',
  CARD = 'creditcard',
  CLAIM_YOUR_PRODUCTS_FEATURE_REQUEST = 'claimyourproductfeaturerequest',
  COUPON = 'coupon',
  COUPON_DRAFT = 'coupondraft',
  DEBUG_TOOL = 'debugtool',
  DISPLAY_CAMPAIGN = 'displaycampaign',
  DISPLAY_AD_GROUP = 'displayadgroup',
  EMAIL_AD_GROUP = 'emailadgroup',
  EMAIL_CAMPAIGN = 'emailcampaign',
  EXTERNAL_USER = 'externaladuser',
  EXPERIMENTAL_CAMPAIGN = 'experimentalcampaign',
  INTEGRITY_T2_OPERATION = 'integrityt2operation',
  INTEGRITY = 'admin::integrity',
  INVOICE = 'creditcardinvoice',
  LIFT_TEST = 'lifttest',
  MANUAL_ADS_TAXONOMY_UPLOAD = 'manualadstaxonomyupload',
  MANUAL_ADS_TAXONOMY_PRODUCT = 'manualadstaxonomyproduct',
  NEXUS_AD_GROUP = 'nexusadgroup',
  NEXUS_BRAND_PAGE = 'nexusbrandpage',
  OAUTH_APPLICATION = 'oauthapplication',
  PRODUCT_MAPPING_REQUEST = 'productmappingrequest',
  PRODUCT_UPDATE_REQUEST = 'productupdaterequest',
  PRODUCT_UPDATE_REQUEST_IMAGE = 'productupdaterequestimage',
  PARTNER_TASKS = 'partnertasks',
  REPORT = 'report',
  REPORTING_CENTER_VIEW = 'reportingcenterview',
  RESERVATION_AD_GROUP = 'reservationadgroup',
  RESERVATION_CAMPAIGN = 'reservationcampaign',
  REVIEW_SAMPLE = 'reviewsample',
  ROLE = 'role',
  SELL_SHEET = 'sellsheet',
  SYNDICATION_OPT_OUT = 'syndicationoptout',
  SYSTEM_ALERT = 'systemalert',
  USER = 'aduser',
  WAITLIST = 'waitlist',
  BRAND_PAGE_DRAFT = 'brandpagedraft',
  CHANGE_HISTORY = 'changehistory',
  SDS = 'sds',
}

export const ALL_RESOURCES = '*'

export const makePolicyIdentifier = (
  resource: Resources | typeof ALL_RESOURCES,
  action: Actions,
  namespace?: Namespaces
) => {
  const policyIdentifier = `${resource}${DELIMITER}${action}`
  if (namespace) {
    return `${namespace}${NAMESPACE_DELIMITER}${policyIdentifier}`
  }
  return policyIdentifier
}

export const CAMPAIGN_WRITE_CAPABILITIES = [
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.UPDATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.DELETE),
]

export const READ_ALL_DRAFT_CAMPAIGN_WRITE_CAPABILITIES = [
  makePolicyIdentifier(ALL_RESOURCES, Actions.READ),
  makePolicyIdentifier(ALL_RESOURCES, Actions.LIST),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.UPDATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.DELETE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.UPDATE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.DELETE),
]

export const READ_ALL_CAPABILITIES = [
  makePolicyIdentifier(ALL_RESOURCES, Actions.READ),
  makePolicyIdentifier(ALL_RESOURCES, Actions.LIST),
]

export const DRAFT_CAMPAIGN_WRITE_CAPABILITIES = [
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.UPDATE),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.DELETE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.UPDATE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.DELETE),
]

export const DISPLAY_CAMPAIGN_WRITE_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.CREATE),
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.UPDATE),
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.DELETE),
]

export const DISPLAY_CAMPAIGN_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.LIST),
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.READ),
]

export const CAMPAIGN_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.LIST),
  makePolicyIdentifier(Resources.CAMPAIGN, Actions.READ),
]

export const VIEW_ONLY_OR_WRITE_DISPLAY_CAMPAIGN_CAPABILITIES = [
  ...DISPLAY_CAMPAIGN_WRITE_CAPABILITIES,
  ...DISPLAY_CAMPAIGN_VIEW_CAPABILITIES,
]

export const DISPLAY_CAMPAIGN_MANAGE_CAPABILITY = [
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.MANAGE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.UPDATE),
]

export const DISPLAY_CAMPAIGN_CREATE_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_CAMPAIGN, Actions.CREATE),
  makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, Actions.CREATE),
]

export const DISPLAY_AD_GROUP_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.LIST),
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.READ),
]

export const DISPLAY_AD_GROUP_WRITE_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.CREATE),
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.UPDATE),
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.DELETE),
  makePolicyIdentifier(Resources.AD_GROUP_DRAFT, Actions.MANAGE),
]

export const DISPLAY_AD_GROUP_CREATE_CAPABILITIES = [
  makePolicyIdentifier(Resources.DISPLAY_AD_GROUP, Actions.CREATE),
  makePolicyIdentifier(Resources.AD_GROUP_DRAFT, Actions.MANAGE),
]

export const VIEW_ONLY_OR_WRITE_DISPLAY_AD_GROUP_CAPABILITIES = [
  ...DISPLAY_AD_GROUP_WRITE_CAPABILITIES,
  ...DISPLAY_AD_GROUP_VIEW_CAPABILITIES,
]

export const BRAND_PAGE_WRITE_CAPABILITIES = [
  makePolicyIdentifier(Resources.BRAND_PAGE, Actions.CREATE),
  makePolicyIdentifier(Resources.BRAND_PAGE, Actions.UPDATE),
  makePolicyIdentifier(Resources.BRAND_PAGE, Actions.DELETE),
]

export const BRAND_PAGE_DRAFT_CAPABILITIES = [
  makePolicyIdentifier(Resources.BRAND_PAGE_DRAFT, Actions.MANAGE),
]

export const BRAND_PAGE_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.BRAND_PAGE, Actions.LIST),
  makePolicyIdentifier(Resources.BRAND_PAGE, Actions.READ),
]

export const ACCOUNT_DOCUMENT_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.ACCOUNT_DOCUMENT, Actions.LIST),
  makePolicyIdentifier(Resources.ACCOUNT_DOCUMENT_COUPON, Actions.LIST),
  makePolicyIdentifier(Resources.ACCOUNT_DOCUMENT_DELIVERY_PROMOTION, Actions.LIST),
  makePolicyIdentifier(Resources.ACCOUNT_DOCUMENT_HERO, Actions.LIST),
  makePolicyIdentifier(Resources.PARTNER_TASKS, Actions.LIST),
]

export const PRODUCT_LIBRARY_CAPABILITIES = [
  makePolicyIdentifier(Resources.ACCOUNT_PRODUCT, Actions.READ),
  makePolicyIdentifier(Resources.PRODUCT_MAPPING_REQUEST, Actions.CREATE),
  makePolicyIdentifier(Resources.PRODUCT_UPDATE_REQUEST, Actions.CREATE),
  makePolicyIdentifier(Resources.PRODUCT_UPDATE_REQUEST_IMAGE, Actions.READ),
]

export const PROMOTIONS_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.COUPON, Actions.LIST),
  makePolicyIdentifier(Resources.COUPON, Actions.READ),
]

export const PARTNER_TASKS_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.PARTNER_TASKS, Actions.LIST),
  makePolicyIdentifier(Resources.PARTNER_TASKS, Actions.READ),
]

export const ALL_CAMPAIGN_VIEW_CAPABILITIES = [
  ...DISPLAY_CAMPAIGN_VIEW_CAPABILITIES,
  ...CAMPAIGN_VIEW_CAPABILITIES,
]

export const DEBUG_TOOL_CAPABILITIES = [
  makePolicyIdentifier(Resources.DEBUG_TOOL, Actions.MANAGE, Namespaces.ADMIN),
]

export const LIFT_TEST_CAPABILITIES = [
  makePolicyIdentifier(Resources.LIFT_TEST, Actions.MANAGE, Namespaces.ADMIN),
]

export const INTERNAL_CAMPAIGN_MANAGE_CAPABILITIES = [
  makePolicyIdentifier(Resources.EXPERIMENTAL_CAMPAIGN, Actions.MANAGE),
  makePolicyIdentifier(Resources.RESERVATION_AD_GROUP, Actions.MANAGE),
  makePolicyIdentifier(Resources.RESERVATION_CAMPAIGN, Actions.MANAGE),
]

export const EXPERIMENTAL_CAMPAIGN_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.EXPERIMENTAL_CAMPAIGN, Actions.LIST),
]

export const REPORT_EXPORT_CAPABILITIES = [makePolicyIdentifier(Resources.REPORT, Actions.READ)]

export const BRAND_PROFILE_LIST_CAPABILITY = makePolicyIdentifier(
  Resources.BRAND_PROFILE,
  Actions.LIST
)
export const BRAND_PROFILE_READ_CAPABILITY = makePolicyIdentifier(
  Resources.BRAND_PROFILE,
  Actions.READ
)
export const BRAND_PROFILE_MANAGE_CAPABILITY = makePolicyIdentifier(
  Resources.BRAND_PROFILE,
  Actions.MANAGE
)

export const BRAND_PROFILE_VIEW_CAPABILITIES = [
  BRAND_PROFILE_LIST_CAPABILITY,
  BRAND_PROFILE_READ_CAPABILITY,
]

export const SELL_SHEET_LIST_CAPABILITY = makePolicyIdentifier(Resources.SELL_SHEET, Actions.LIST)
export const SELL_SHEET_READ_CAPABILITY = makePolicyIdentifier(Resources.SELL_SHEET, Actions.READ)
export const SELL_SHEET_MANAGE_CAPABILITY = makePolicyIdentifier(
  Resources.SELL_SHEET,
  Actions.MANAGE
)

export const SELL_SHEET_VIEW_CAPABILITIES = [SELL_SHEET_LIST_CAPABILITY, SELL_SHEET_READ_CAPABILITY]

export const BRAND_EXPLORER_VIEW_CAPABILITIES = [
  ...BRAND_PROFILE_VIEW_CAPABILITIES,
  ...SELL_SHEET_VIEW_CAPABILITIES,
]

export const CLAIM_YOUR_PRODUCTS_FEATURE_REQUEST_MANAGE_CAPABILITY = makePolicyIdentifier(
  Resources.CLAIM_YOUR_PRODUCTS_FEATURE_REQUEST,
  Actions.MANAGE,
  Namespaces.ADMIN
)

export const CHANGE_HISTORY_LIST_CAPABILITY = makePolicyIdentifier(
  Resources.CHANGE_HISTORY,
  Actions.LIST
)

export const SDS_VIEW_CAPABILITIES = [
  makePolicyIdentifier(Resources.SDS, Actions.READ),
  makePolicyIdentifier(Resources.SDS, Actions.LIST),
]
