// This is the only place we use the untyped FormattedMessage to wrap it to a typed version
// eslint-disable-next-line no-restricted-imports
import { ComponentProps } from 'react'
import { FormattedMessage as UntypedFormattedMessage } from 'react-intl'
import { BuildFormattingParams, buildFormatting } from 'common/intlUtils'
import { MessageIdType } from '../locales/types'

export interface FormattedMessageProps
  extends BuildFormattingParams,
    Omit<ComponentProps<typeof UntypedFormattedMessage>, 'id'> {
  id: MessageIdType
  dataTestId?: string
}

const FormattedMessage = ({ dataTestId, ...props }: FormattedMessageProps) => {
  const formatting = buildFormatting(props)

  return (
    <UntypedFormattedMessage
      {...props}
      values={{ ...formatting, ...props.values }}
      data-testid={dataTestId}
    />
  )
}

export default FormattedMessage
