// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplay,
  instanceOfPostVideoCampaignsIdSubmitParamBlockHeroBannerDisplay,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayFromJSON,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayToJSON,
} from './PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplay'
import {
  PostVideoCampaignsIdSubmitParamBlockItemGridDisplay,
  instanceOfPostVideoCampaignsIdSubmitParamBlockItemGridDisplay,
  PostVideoCampaignsIdSubmitParamBlockItemGridDisplayFromJSON,
  PostVideoCampaignsIdSubmitParamBlockItemGridDisplayFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBlockItemGridDisplayToJSON,
} from './PostVideoCampaignsIdSubmitParamBlockItemGridDisplay'

/**
 * @type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks
 *
 * @export
 */
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks =
  | ({
      discriminator: 'block_hero_banner.v1'
    } & PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & PostVideoCampaignsIdSubmitParamBlockItemGridDisplay)

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PostVideoCampaignsIdSubmitParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PostVideoCampaignsIdSubmitParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
