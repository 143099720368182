import { Color, InformationIcon } from '@instacart/ids-core'
import { ReactNode, FunctionComponent, useEffect } from 'react'
import {
  Hovercard,
  HovercardAnchor,
  useHovercardState,
} from 'components/ids-ads/molecules/tooltips/Hovercard'

declare type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start'

type IconProps = {
  color?: Color
  customIcon?: JSX.Element
}

type TooltipProps = {
  tooltipContent: ReactNode
  placement?: Placement
  onTooltipVisible?: () => void
}

type Props = TooltipProps & IconProps

const InfoSVGIcon: FunctionComponent<React.PropsWithChildren<IconProps>> = ({ color }) => {
  return <InformationIcon size={18} color={color} />
}

const InfoTooltip: FunctionComponent<React.PropsWithChildren<Props>> = ({
  tooltipContent,
  placement,
  onTooltipVisible,
  customIcon,
  ...rest
}) => {
  const state = useHovercardState({ placement })
  useEffect(() => {
    if (state.visible && onTooltipVisible) {
      onTooltipVisible()
    }
  }, [state.visible, onTooltipVisible])

  return (
    <>
      <HovercardAnchor state={state}>{customIcon || <InfoSVGIcon {...rest} />}</HovercardAnchor>
      <Hovercard styles={{ content: { textTransform: 'none' } }} state={state}>
        {tooltipContent}
      </Hovercard>
    </>
  )
}

export default InfoTooltip
