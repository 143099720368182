// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues } from './PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues'
import {
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageTaxonomy
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageTaxonomy {
  /**
   * Map of labels applied
   * @type {{ [key: string]: PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues; }}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageTaxonomy
   */
  labelMap: { [key: string]: PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues }
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageTaxonomy
   */
  componentType: PostAdminBrandPagesIdLabelParamBrandPageTaxonomyComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageTaxonomyComponentTypeEnum = {
  TaxonomyBrands: 'TAXONOMY_BRANDS',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageTaxonomyComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageTaxonomyComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageTaxonomyComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageTaxonomy interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageTaxonomy(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'labelMap' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageTaxonomy {
  return PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageTaxonomy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labelMap: mapValues(
      json['label_map'],
      PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageTaxonomy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    label_map: mapValues(
      value.labelMap,
      PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesToJSON
    ),
    component_type: value.componentType,
  }
}
