// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamProductAssetAltText } from './PostAdminBrandPagesIdLabelParamProductAssetAltText'
import {
  PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSON,
  PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSONTyped,
  PostAdminBrandPagesIdLabelParamProductAssetAltTextToJSON,
} from './PostAdminBrandPagesIdLabelParamProductAssetAltText'
import type { PostAdminBrandPagesIdLabelParamProductAssetImage } from './PostAdminBrandPagesIdLabelParamProductAssetImage'
import {
  PostAdminBrandPagesIdLabelParamProductAssetImageFromJSON,
  PostAdminBrandPagesIdLabelParamProductAssetImageFromJSONTyped,
  PostAdminBrandPagesIdLabelParamProductAssetImageToJSON,
} from './PostAdminBrandPagesIdLabelParamProductAssetImage'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamProductAsset
 */
export interface PostAdminBrandPagesIdLabelParamProductAsset {
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamProductAsset
   */
  type: PostAdminBrandPagesIdLabelParamProductAssetTypeEnum
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamProductAsset
   */
  discriminator: string
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamProductAssetAltText}
   * @memberof PostAdminBrandPagesIdLabelParamProductAsset
   */
  altText: PostAdminBrandPagesIdLabelParamProductAssetAltText
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamProductAssetImage}
   * @memberof PostAdminBrandPagesIdLabelParamProductAsset
   */
  image: PostAdminBrandPagesIdLabelParamProductAssetImage
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamProductAssetTypeEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PostAdminBrandPagesIdLabelParamProductAssetTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamProductAssetTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamProductAssetTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamProductAsset interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamProductAsset(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'altText' in value
  isInstance = isInstance && 'image' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamProductAssetFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamProductAsset {
  return PostAdminBrandPagesIdLabelParamProductAssetFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamProductAssetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamProductAsset {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSON(json['alt_text']),
    image: PostAdminBrandPagesIdLabelParamProductAssetImageFromJSON(json['image']),
  }
}

export function PostAdminBrandPagesIdLabelParamProductAssetToJSON(
  value?: PostAdminBrandPagesIdLabelParamProductAsset | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PostAdminBrandPagesIdLabelParamProductAssetAltTextToJSON(value.altText),
    image: PostAdminBrandPagesIdLabelParamProductAssetImageToJSON(value.image),
  }
}
