import { CardElementComponent } from '@stripe/react-stripe-js'
import { AddressData } from 'common/address/form'
import { ADS_API_PATH } from 'common/constants'
import useIntl from 'common/useIntl'
import { useAuthContext, isRetailerAccount, hasAgencySet, AuthCtx } from 'context'
import { MessageIdType } from 'locales/types'
import {
  ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum as InvoiceOptions,
  ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum as PaymentTermsDays,
  ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum as PaymentOption,
} from 'service/openapi/__codegen__/models/ApiAdminAccountsControllerCreateInputAccount'
import Client from './client'

export { InvoiceOptions, PaymentTermsDays, PaymentOption }

export {
  GetAccountPaymentResponseDataAttributesNextPaymentOption as NextPaymentOption,
  GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum as PaymentTypes,
} from 'service/openapi/__codegen__/models/GetAccountPaymentResponseDataAttributesNextPaymentOption'

export { GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation as CreditCardPaymentData } from 'service/openapi/__codegen__/models/GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation'

// eslint-disable-next-line no-restricted-syntax
export enum BillPayers {
  ADVERTISER = 'advertiser',
  AGENCY = 'agency',
  RETAILER = 'retailer',
}

export const InvoiceOptionsLabels = {
  [InvoiceOptions.Portal]: 'common.payment.portal',
  [InvoiceOptions.Email]: 'common.email',
}

interface BillPayersLabelsProps {
  [key: string]: MessageIdType
}

export const DefaultBillPayersLabels: BillPayersLabelsProps = {
  [BillPayers.ADVERTISER]: 'common.payment.advertiser',
  [BillPayers.AGENCY]: 'common.agency',
}

export const AdvertiserOnlyBillPayerLabels: BillPayersLabelsProps = {
  [BillPayers.ADVERTISER]: 'common.payment.advertiser',
}

export const RetailerBillPayersLabels: BillPayersLabelsProps = {
  [BillPayers.RETAILER]: 'common.payment.retailer',
}

export const getBillPayerLabel = (authContext: AuthCtx) => {
  if (isRetailerAccount(authContext)) {
    return RetailerBillPayersLabels
  }
  return hasAgencySet(authContext) ? DefaultBillPayersLabels : AdvertiserOnlyBillPayerLabels
}

export const BillPayersLabels = { ...DefaultBillPayersLabels, ...RetailerBillPayersLabels }

export const useBillPayerOptions = () => {
  const authContext = useAuthContext()
  const { formatMessage } = useIntl()
  const billPayersLabels = getBillPayerLabel(authContext)

  return Object.keys(billPayersLabels).map(k => {
    const value = k as keyof BillPayersLabelsProps
    const label = formatMessage({ id: billPayersLabels[value] })
    return { label, value }
  })
}

interface BillingFormErrors {
  addressError: boolean
  cityError: boolean
  stateError: boolean
  countryError: boolean
  zipError: boolean
}

export interface StripeData {
  stripeToken: string
  addressZip: string | null
  expMonth: number
  expYear: number
  lastFour: string
}

export type ValidateFormValues = {
  nameOnCard: string
  phoneNumber: string
  addressLine1: string
  addressCity: string
  addressCountry: string
  addressState: string
  addressZip: string
}

export interface BillingFormProps {
  errors?: BillingFormErrors
  addressData?: AddressData
  showZipCode: boolean
  isDisabled?: boolean
}

export interface UseCreditCardProps {
  CardElement: CardElementComponent
  creditCardError?: string
  validateCreditCard: () => Promise<StripeData | null>
}

export interface UseBillingFormPayload {
  billingFormErrors: BillingFormErrors
  resetErrorStates: () => void
  formSuccess: boolean
  validateBillingAddressForm: (values: ValidateFormValues, validateZip: boolean) => Promise<boolean>
}

export interface CreditCardData {
  nameOnCard: string
  lastFour: undefined | string
  expMonth: number
  expYear: number
  phoneNumber: string
  addressLine1: string
  addressLine2?: string
  addressCity: string
  addressCountry: string
  addressState: string
  addressZip: string | null
  stripeToken?: string | undefined
  id?: string
}

export interface CreditCardResponse {
  data: [
    {
      id: string
      type: string
      attributes: {
        lastFour: string
        expMonth: number
        expYear: number
        nameOnCard: string
        phoneNumber: string
        label: string
        active: boolean
        addressLine1: string
        addressCity: string
        addressCountry: string
        addressState: string
        addressZip: string
      }
    }
  ]
  meta: {
    status: number
    request_uuid: string
  }
}

export interface SetCreditCardResponse {
  data: {
    attributes: CreditCardData
  }
}

class BillingClientClass extends Client {
  constructor() {
    super()
    this.ADS_API_PATH = ADS_API_PATH
  }

  getPaymentInformation = (accountId: string): Promise<PaymentResponse> => {
    return this.performRequest({
      method: 'get',
      path: `/accounts/${accountId}/payment`,
    })
  }

  setCreditCardInformation = (data: CreditCardData): Promise<SetCreditCardResponse> => {
    return this.performRequest({
      method: 'POST',
      path: `/credit_cards?default=true`,
      data,
    })
  }

  updateCreditCardInformation = (
    creditCardId: string,
    data: AddressData
  ): Promise<SetCreditCardResponse> => {
    return this.performRequest({
      method: 'PUT',
      path: `/credit_cards/${creditCardId}`,
      data,
    })
  }

  getCreditCardInformation = (): Promise<CreditCardResponse> => {
    return this.performRequest({
      method: 'GET',
      path: `/credit_cards?default=true`,
    })
  }

  updateCorpAddress = (accountId: string, corporateAddress: AddressData) => {
    return this.performRequest({
      method: 'PUT',
      path: `/accounts/${accountId}/corporate_address`,
      data: {
        corporateAddress,
      },
    })
  }
}

/* A singleton is exported to prevent multiple instances from existing
 */
export const BillingClient = new BillingClientClass()
