// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributes } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributes'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributesFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributesFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributesToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributes'

/**
 *
 * @export
 * @interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct
 */
export interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct {
  /**
   * Alcohol State Permission Id
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct
   */
  type: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductTypeEnum
  /**
   *
   * @type {GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributes}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct
   */
  attributes: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributes
}

/**
 * @export
 */
export const GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductTypeEnum = {
  AlcoholStatePermission: 'alcohol_state_permission',
} as const
export type GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductTypeEnum =
  typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductTypeEnum[keyof typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductTypeEnum]

/**
 * Check if a given object implements the GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct interface.
 */
export function instanceOfGetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSON(
  json: any
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct {
  return GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSONTyped(
    json,
    false
  )
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes:
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributesFromJSON(
        json['attributes']
      ),
  }
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductToJSON(
  value?: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes:
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductAttributesToJSON(
        value.attributes
      ),
  }
}
