// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiDisplayCampaignsControllerCreateInputCampaign
 */
export interface ApiDisplayCampaignsControllerCreateInputCampaign {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active.
   * @type {boolean}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  enabled: boolean
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  budget: number
  /**
   * Send paced, asap for this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  pacingType: ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  startsAt: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Omit to automatically stop the campaign when the campaign’s budget has been spent.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  endsAt?: string
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  goalFormat: string
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  billPayer?: ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum
  /**
   * Send consideration, awareness, sales for this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  objective?: ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum
  /**
   * If you want to include a memo line on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  memo?: string
  /**
   * If you want to include a purchase order number on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  purchaseOrder?: string
  /**
   * For reference only. Specifies how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  paymentType?: ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerCreateInputCampaign
   */
  goal?: string
}

/**
 * @export
 */
export const ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum = {
  Paced: 'paced',
  Asap: 'asap',
} as const
export type ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum =
  typeof ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum[keyof typeof ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum =
  typeof ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum[keyof typeof ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum = {
  Consideration: 'consideration',
  Awareness: 'awareness',
  Sales: 'sales',
} as const
export type ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum =
  typeof ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum[keyof typeof ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum =
  typeof ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum[keyof typeof ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum]

/**
 * Check if a given object implements the ApiDisplayCampaignsControllerCreateInputCampaign interface.
 */
export function instanceOfApiDisplayCampaignsControllerCreateInputCampaign(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'pacingType' in value
  isInstance = isInstance && 'startsAt' in value
  isInstance = isInstance && 'goalFormat' in value

  return isInstance
}

export function ApiDisplayCampaignsControllerCreateInputCampaignFromJSON(
  json: any
): ApiDisplayCampaignsControllerCreateInputCampaign {
  return ApiDisplayCampaignsControllerCreateInputCampaignFromJSONTyped(json, false)
}

export function ApiDisplayCampaignsControllerCreateInputCampaignFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayCampaignsControllerCreateInputCampaign {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    budget: json['budget'],
    pacingType: json['pacing_type'],
    startsAt: json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
    goalFormat: json['goal_format'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    goal: !exists(json, 'goal') ? undefined : json['goal'],
  }
}

export function ApiDisplayCampaignsControllerCreateInputCampaignToJSON(
  value?: ApiDisplayCampaignsControllerCreateInputCampaign | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    budget: value.budget,
    pacing_type: value.pacingType,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
    goal_format: value.goalFormat,
    bill_payer: value.billPayer,
    objective: value.objective,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    goal: value.goal,
  }
}
