import { EventPageTypeValues } from 'service/types'

export const AD_GROUP_PAGE_HEADER_NEW_NAV_TEST_ID = 'ad-group-page-header-new-nav'
export const AD_GROUP_TABS_NEW_NAV_TEST_ID = 'ad-group-tab-new-nav'

// eslint-disable-next-line no-restricted-syntax
export enum AdGroupTabName {
  KEYWORDS = 'ad_group_keywords',
  PRODUCTS = 'ad_group_products',
}

export const EventPageMap = new Map([
  [AdGroupTabName.KEYWORDS, EventPageTypeValues.KEYWORD_DETAIL],
  [AdGroupTabName.PRODUCTS, EventPageTypeValues.AD_GROUP_DETAIL],
])
