// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltText } from './PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltText'
import {
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltTextFromJSON,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltTextToJSON,
} from './PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltText'
import type { PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImage } from './PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImage'
import {
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImageFromJSON,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImageFromJSONTyped,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImageToJSON,
} from './PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions
 */
export interface PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions
   */
  discriminator: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltText}
   * @memberof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions
   */
  altText?: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImage}
   * @memberof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions
   */
  image?: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions {
  return PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsToJSON(
  value?: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
