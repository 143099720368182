// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions
 */
export interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [
      key: string
    ]: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  products?: {
    [key: string]: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues
  }
  /**
   *
   * @type {PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  content?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPutReservationAdGroupsIdParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions {
  return PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(value.content),
  }
}
