// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AccountsSearchResponse } from '../models/AccountsSearchResponse'
import {
  AccountsSearchResponseFromJSON,
  AccountsSearchResponseToJSON,
} from '../models/AccountsSearchResponse'
import type { ApiAccountsControllerUpdateCorpAddressInput } from '../models/ApiAccountsControllerUpdateCorpAddressInput'
import {
  ApiAccountsControllerUpdateCorpAddressInputFromJSON,
  ApiAccountsControllerUpdateCorpAddressInputToJSON,
} from '../models/ApiAccountsControllerUpdateCorpAddressInput'
import type { ApiAccountsControllerUpdateInput } from '../models/ApiAccountsControllerUpdateInput'
import {
  ApiAccountsControllerUpdateInputFromJSON,
  ApiAccountsControllerUpdateInputToJSON,
} from '../models/ApiAccountsControllerUpdateInput'
import type { ApiAccountsControllerUpdatePaymentInfoInput } from '../models/ApiAccountsControllerUpdatePaymentInfoInput'
import {
  ApiAccountsControllerUpdatePaymentInfoInputFromJSON,
  ApiAccountsControllerUpdatePaymentInfoInputToJSON,
} from '../models/ApiAccountsControllerUpdatePaymentInfoInput'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetAccountAnalyticsResponse } from '../models/GetAccountAnalyticsResponse'
import {
  GetAccountAnalyticsResponseFromJSON,
  GetAccountAnalyticsResponseToJSON,
} from '../models/GetAccountAnalyticsResponse'
import type { GetAccountPaymentResponse } from '../models/GetAccountPaymentResponse'
import {
  GetAccountPaymentResponseFromJSON,
  GetAccountPaymentResponseToJSON,
} from '../models/GetAccountPaymentResponse'
import type { GetAccountResponse } from '../models/GetAccountResponse'
import { GetAccountResponseFromJSON, GetAccountResponseToJSON } from '../models/GetAccountResponse'
import type { GetAccountsResponse } from '../models/GetAccountsResponse'
import {
  GetAccountsResponseFromJSON,
  GetAccountsResponseToJSON,
} from '../models/GetAccountsResponse'
import type { GetRetailersResponse } from '../models/GetRetailersResponse'
import {
  GetRetailersResponseFromJSON,
  GetRetailersResponseToJSON,
} from '../models/GetRetailersResponse'
import type { PutAccountPaymentResponse } from '../models/PutAccountPaymentResponse'
import {
  PutAccountPaymentResponseFromJSON,
  PutAccountPaymentResponseToJSON,
} from '../models/PutAccountPaymentResponse'

export interface GetAccountRequest {
  includeAccountBusinessEntity?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAccountDocumentRequest {
  filename: GetAccountDocumentFilenameEnum
}

export interface GetAccountsRequest {
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAccountsIdRequest {
  id: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAccountsIdAnalyticsRequest {
  metric: GetAccountsIdAnalyticsMetricEnum
  id: string
  status?: GetAccountsIdAnalyticsStatusEnum
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  query?: string
}

export interface GetAccountsIdPaymentRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAccountsIdRetailersRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAccountsSearchRequest {
  query?: string
  page?: string
  pageSize?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PutAccountsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAccountsControllerUpdateInput
}

export interface PutAccountsIdCorporateAddressRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAccountsControllerUpdateCorpAddressInput
}

export interface PutAccountsIdPaymentRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAccountsControllerUpdatePaymentInfoInput
}

/**
 *
 */
export class AccountsApi extends runtime.BaseAPI {
  /**
   * Use this method to return the account details associated with the OAuth application you used to authenticate to Instacart Ads API.
   * Retrieve account details
   */
  async getAccountRaw(
    requestParameters: GetAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountResponse>> {
    const queryParameters: any = {}

    if (requestParameters.includeAccountBusinessEntity !== undefined) {
      queryParameters['include_account_business_entity'] =
        requestParameters.includeAccountBusinessEntity
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/account`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetAccountResponseFromJSON(jsonValue))
  }

  /**
   * Use this method to return the account details associated with the OAuth application you used to authenticate to Instacart Ads API.
   * Retrieve account details
   */
  async getAccount(
    requestParameters: GetAccountRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountResponse> {
    const response = await this.getAccountRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to return a document specific to this account (i.e terms and conditions document)
   * Retrieve Account document
   */
  async getAccountDocumentRaw(
    requestParameters: GetAccountDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.filename === null || requestParameters.filename === undefined) {
      throw new runtime.RequiredError(
        'filename',
        'Required parameter requestParameters.filename was null or undefined when calling getAccountDocument.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.filename !== undefined) {
      queryParameters['filename'] = requestParameters.filename
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/account/document`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Use this method to return a document specific to this account (i.e terms and conditions document)
   * Retrieve Account document
   */
  async getAccountDocument(
    requestParameters: GetAccountDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.getAccountDocumentRaw(requestParameters, initOverrides)
  }

  /**
   * This method will return a list of test accounts.
   * Returns all test accounts
   */
  async getAccountTestAccountsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/account/test_accounts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAccountsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return a list of test accounts.
   * Returns all test accounts
   */
  async getAccountTestAccounts(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountsResponse> {
    const response = await this.getAccountTestAccountsRaw(initOverrides)
    return await response.value()
  }

  /**
   * This method will return a list of all accounts that are accessible to the current user.
   * Returns all accessible accounts for the current user
   */
  async getAccountsRaw(
    requestParameters: GetAccountsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAccountsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return a list of all accounts that are accessible to the current user.
   * Returns all accessible accounts for the current user
   */
  async getAccounts(
    requestParameters: GetAccountsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountsResponse> {
    const response = await this.getAccountsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return account details for a given account ID. The given account must be accessible to the current user.
   * Returns account details for a given account ID
   */
  async getAccountsIdRaw(
    requestParameters: GetAccountsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAccountsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetAccountResponseFromJSON(jsonValue))
  }

  /**
   * This method will return account details for a given account ID. The given account must be accessible to the current user.
   * Returns account details for a given account ID
   */
  async getAccountsId(
    requestParameters: GetAccountsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountResponse> {
    const response = await this.getAccountsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return detailed analytics information on a given Account. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for all campaigns within the given Account
   */
  async getAccountsIdAnalyticsRaw(
    requestParameters: GetAccountsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountAnalyticsResponse>> {
    if (requestParameters.metric === null || requestParameters.metric === undefined) {
      throw new runtime.RequiredError(
        'metric',
        'Required parameter requestParameters.metric was null or undefined when calling getAccountsIdAnalytics.'
      )
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAccountsIdAnalytics.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.metric !== undefined) {
      queryParameters['metric'] = requestParameters.metric
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/accounts/{id}/analytics`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAccountAnalyticsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return detailed analytics information on a given Account. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for all campaigns within the given Account
   */
  async getAccountsIdAnalytics(
    requestParameters: GetAccountsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountAnalyticsResponse> {
    const response = await this.getAccountsIdAnalyticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return account-level payment information for a given account ID.
   * Returns account-level payment information
   */
  async getAccountsIdPaymentRaw(
    requestParameters: GetAccountsIdPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountPaymentResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAccountsIdPayment.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}/payment`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAccountPaymentResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return account-level payment information for a given account ID.
   * Returns account-level payment information
   */
  async getAccountsIdPayment(
    requestParameters: GetAccountsIdPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountPaymentResponse> {
    const response = await this.getAccountsIdPaymentRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return account-level retailer information for a given account ID.
   * Returns account-level retailer information
   */
  async getAccountsIdRetailersRaw(
    requestParameters: GetAccountsIdRetailersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetRetailersResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAccountsIdRetailers.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}/retailers`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetRetailersResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return account-level retailer information for a given account ID.
   * Returns account-level retailer information
   */
  async getAccountsIdRetailers(
    requestParameters: GetAccountsIdRetailersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetRetailersResponse> {
    const response = await this.getAccountsIdRetailersRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return a list of all accounts that are accessible to the current user given a search query.
   * Search for accounts by search term
   */
  async getAccountsSearchRaw(
    requestParameters: GetAccountsSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AccountsSearchResponse>> {
    const queryParameters: any = {}

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['page_size'] = requestParameters.pageSize
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AccountsSearchResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return a list of all accounts that are accessible to the current user given a search query.
   * Search for accounts by search term
   */
  async getAccountsSearch(
    requestParameters: GetAccountsSearchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AccountsSearchResponse> {
    const response = await this.getAccountsSearchRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will update account-level parameters for a given account ID.
   * Updates account attributes
   */
  async putAccountsIdRaw(
    requestParameters: PutAccountsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAccountsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAccountsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetAccountResponseFromJSON(jsonValue))
  }

  /**
   * This method will update account-level parameters for a given account ID.
   * Updates account attributes
   */
  async putAccountsId(
    requestParameters: PutAccountsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountResponse> {
    const response = await this.putAccountsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will update account-level corporate taxable address for a given account ID.
   * Updates account-level corporate taxable address
   */
  async putAccountsIdCorporateAddressRaw(
    requestParameters: PutAccountsIdCorporateAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAccountResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAccountsIdCorporateAddress.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}/corporate_address`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAccountsControllerUpdateCorpAddressInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetAccountResponseFromJSON(jsonValue))
  }

  /**
   * This method will update account-level corporate taxable address for a given account ID.
   * Updates account-level corporate taxable address
   */
  async putAccountsIdCorporateAddress(
    requestParameters: PutAccountsIdCorporateAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAccountResponse> {
    const response = await this.putAccountsIdCorporateAddressRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will update account-level payment information for a given account ID.
   * Updates account-level payment information
   */
  async putAccountsIdPaymentRaw(
    requestParameters: PutAccountsIdPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PutAccountPaymentResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAccountsIdPayment.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/accounts/{id}/payment`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAccountsControllerUpdatePaymentInfoInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PutAccountPaymentResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will update account-level payment information for a given account ID.
   * Updates account-level payment information
   */
  async putAccountsIdPayment(
    requestParameters: PutAccountsIdPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PutAccountPaymentResponse> {
    const response = await this.putAccountsIdPaymentRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetAccountDocumentFilenameEnum = {
  TermsAndConditions: 'terms_and_conditions',
  DisplayMsa: 'display_msa',
  DisplayAdditionalTerms: 'display_additional_terms',
  AdvertiserMsa: 'advertiser_msa',
  AgencyMsa: 'agency_msa',
  BrandPagesAdditionalTerms: 'brand_pages_additional_terms',
  CreditsAdditionalTerms: 'credits_additional_terms',
  VideoDisplayAdditionalTerms: 'video_display_additional_terms',
  EmailAdditionalTerms: 'email_additional_terms',
  PromotionsAdditionalTerms: 'promotions_additional_terms',
  PromotionsAdditionalTermsAlcohol: 'promotions_additional_terms_alcohol',
  ProductLibraryAdditionalTerms: 'product_library_additional_terms',
  SellSheetsAdditionalTerms: 'sell_sheets_additional_terms',
  SellSheetsPrivacyPolicy: 'sell_sheets_privacy_policy',
  InspirationAdditionalTerms: 'inspiration_additional_terms',
} as const
export type GetAccountDocumentFilenameEnum =
  typeof GetAccountDocumentFilenameEnum[keyof typeof GetAccountDocumentFilenameEnum]
/**
 * @export
 */
export const GetAccountsIdAnalyticsMetricEnum = {
  Spend: 'spend',
  Clicks: 'clicks',
  Impressions: 'impressions',
  Ctr: 'ctr',
  Roas: 'roas',
  NtbRoas: 'ntb_roas',
  NtbSpend: 'ntb_spend',
  AttributedSales: 'attributed_sales',
  NtbAttributedSales: 'ntb_attributed_sales',
  AttributedQuantities: 'attributed_quantities',
} as const
export type GetAccountsIdAnalyticsMetricEnum =
  typeof GetAccountsIdAnalyticsMetricEnum[keyof typeof GetAccountsIdAnalyticsMetricEnum]
/**
 * @export
 */
export const GetAccountsIdAnalyticsStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Draft: 'draft',
  Ended: 'ended',
  IncompleteAdGroup: 'incomplete_ad_group',
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
} as const
export type GetAccountsIdAnalyticsStatusEnum =
  typeof GetAccountsIdAnalyticsStatusEnum[keyof typeof GetAccountsIdAnalyticsStatusEnum]
