// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch } from './GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear } from './GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
 */
export interface GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
   */
  linear: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  return GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: GetAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
