// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiPrototypeCampaignsControllerUpdateInputCampaign } from './ApiPrototypeCampaignsControllerUpdateInputCampaign'
import {
  ApiPrototypeCampaignsControllerUpdateInputCampaignFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputCampaignFromJSONTyped,
  ApiPrototypeCampaignsControllerUpdateInputCampaignToJSON,
} from './ApiPrototypeCampaignsControllerUpdateInputCampaign'

/**
 *
 * @export
 * @interface ApiPrototypeCampaignsControllerUpdateInput
 */
export interface ApiPrototypeCampaignsControllerUpdateInput {
  /**
   *
   * @type {ApiPrototypeCampaignsControllerUpdateInputCampaign}
   * @memberof ApiPrototypeCampaignsControllerUpdateInput
   */
  campaign: ApiPrototypeCampaignsControllerUpdateInputCampaign
}

/**
 * Check if a given object implements the ApiPrototypeCampaignsControllerUpdateInput interface.
 */
export function instanceOfApiPrototypeCampaignsControllerUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaign' in value

  return isInstance
}

export function ApiPrototypeCampaignsControllerUpdateInputFromJSON(
  json: any
): ApiPrototypeCampaignsControllerUpdateInput {
  return ApiPrototypeCampaignsControllerUpdateInputFromJSONTyped(json, false)
}

export function ApiPrototypeCampaignsControllerUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiPrototypeCampaignsControllerUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaign: ApiPrototypeCampaignsControllerUpdateInputCampaignFromJSON(json['campaign']),
  }
}

export function ApiPrototypeCampaignsControllerUpdateInputToJSON(
  value?: ApiPrototypeCampaignsControllerUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaign: ApiPrototypeCampaignsControllerUpdateInputCampaignToJSON(value.campaign),
  }
}
