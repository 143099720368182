// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick } from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid } from './ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGrid } from './ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid } from './ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributesCreativeActions
 */
export interface ReservationAdGroupResponseDataAttributesCreativeActions {
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGrid}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActions
   */
  mainItemGrid?: ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActions
   */
  mainProductGroupGrid?: ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActions
   */
  mainCollectionGrid?: ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActions
   */
  bannerClick?: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributesCreativeActions interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributesCreativeActions {
  return ReservationAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsToJSON(
  value?: ReservationAdGroupResponseDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: ReservationAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
