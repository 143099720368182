import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { FC } from 'react'

const useStyles = () => {
  const theme = useTheme()

  return {
    bar: css`
      border-radius: 8px;
    `,
    barBase: css`
      fill: ${theme.colors.systemGrayscale20};
    `,
  }
}

export interface ProgressBarColor {
  color: string
  progress: number
}

/**
 *  Amount filled is determined by (progress - min) / (max - min)
 *  Color is determined by the first color whose progress is greater than or equal to the amount filled
 *  @min defaults to 0
 *  @max defaults to 1
 *  @colors defaults to [green, 1], [yellow, 0.5], [red, 0]
 */
interface ProgressBarProps {
  progress: number
  max?: number
  min?: number
  colors?: ProgressBarColor[]
}

const ProgressBar: FC<React.PropsWithChildren<ProgressBarProps>> = ({
  progress,
  max = 1,
  min = 0,
  colors: colorsProp = [
    {
      color: '#DE3534',
      progress: 0,
    },
    {
      color: '#FF8E1D',
      progress: 0.4,
    },
    {
      color: '#0AAD0A',
      progress: 0.75,
    },
  ],
}) => {
  const styles = useStyles()
  const filled = ((progress - min) / (max - min)) * 40
  const sortedColors = colorsProp.sort((a, b) => b.progress - a.progress)
  const color =
    sortedColors.find(searchColor => progress >= searchColor.progress)?.color ?? colorsProp[0].color
  return (
    <svg width="40" height="8" viewBox="0 0 40 8" fill="none">
      <rect width="40" height="8" rx="4" css={styles.barBase} />
      <rect width={filled} height="8" rx="4" css={styles.bar} fill={color} />
    </svg>
  )
}

export default ProgressBar
