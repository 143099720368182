import { FC, useEffect, useState } from 'react'
import { ClientError, getApiErrorMessages, getApiErrorCode } from '../common/utils'
import Header from '../landing/components/Header'
import Maintenance from '../pages/Maintenance'
import { HealthCheckApi } from '../service/apis/HealthCheckApi'

interface MaintenanceBoundaryProps {
  children: React.ReactNode
}

const MaintenanceBoundary: FC<React.PropsWithChildren<MaintenanceBoundaryProps>> = ({
  children,
}) => {
  const [maintenanceModeText, setMaintenanceModeText] = useState<string>('')

  useEffect(() => {
    async function performHealthCheck() {
      try {
        await HealthCheckApi.getHealthCheck()
      } catch (err) {
        if (err instanceof ClientError && getApiErrorCode(err) === 503000) {
          const errors = getApiErrorMessages(err)
          setMaintenanceModeText(errors[0][0])
        }
      }
    }

    performHealthCheck()
  }, [])

  if (maintenanceModeText) {
    return (
      <>
        <Header loginButtonVisible={false} heroVisible />
        <Maintenance text={maintenanceModeText} />
      </>
    )
  }

  return <>{children}</>
}

export default MaintenanceBoundary
