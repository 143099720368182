import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import withTheme, { WithThemeProps } from 'common/ids/withTheme'
import { Modal, useModalState } from 'components/ids-ads'
import { DataRow } from 'components/molecules'
import FieldValueRejectionReasons from 'pages/DisplayProduct/common/components/FieldValueRejectionReasons'
import { ApprovalInfoMap, Decision, RejectionLabel } from 'pages/DisplayProduct/types'

export const IMAGE_SIZE_DEFAULT = 172
export const IMAGE_SIZE_EXPANDED = 500
const BORDER_WIDTH = 1

export const Image = withTheme(
  styled.img(
    ({
      theme,
      width = IMAGE_SIZE_DEFAULT,
      height = IMAGE_SIZE_DEFAULT,
    }: WithThemeProps & { width?: number; height?: number }) =>
      css({
        cursor: 'pointer',
        display: 'block',
        boxSizing: 'border-box',
        width,
        height,
        borderRadius: theme.radius.r12,
        border: `${BORDER_WIDTH}px solid ${theme.colors.systemGrayscale30}`,
        objectFit: 'contain',
      })
  )
)

export interface ImageDisplayProps extends ComponentProps<typeof Image> {
  label: string
  styles?: {
    expanded?: SerializedStyles | SerializedStyles[]
    unexpanded?: SerializedStyles | SerializedStyles[]
  }
  fullPage?: boolean
  expandedImageDimensions?: { width?: number; height?: number }
  productDisplayAttributes?: {
    name: string
    size: string
    unitCount: string
    upc: string
    productId: string
    rejectionDecision?: string
    rejectionLabels?: RejectionLabel[]
    approvalInfo?: ApprovalInfoMap
  }
}

export default function ImageDisplay({
  label,
  productDisplayAttributes,
  styles,
  fullPage,
  ...props
}: ImageDisplayProps) {
  const modalState = useModalState()

  return (
    <>
      <Image
        {...props}
        css={styles?.unexpanded}
        alt={label}
        role="button"
        onClick={modalState.show}
      />
      <Modal
        modalState={modalState}
        variant={fullPage ? 'full-page' : undefined}
        width={fullPage ? '100%' : 'auto'}
        title={{ message: label }}
        onClose={modalState.hide}
      >
        <Image
          {...props}
          alt={label}
          css={styles?.expanded}
          width={IMAGE_SIZE_EXPANDED}
          height={IMAGE_SIZE_EXPANDED}
        />
        {productDisplayAttributes && (
          <>
            <DataRow labelId="pages.admin.reviewRequestProducts.productName">
              {productDisplayAttributes.name}
            </DataRow>
            <DataRow labelId="pages.admin.reviewRequestProducts.size">
              {productDisplayAttributes.size}
            </DataRow>
            <DataRow labelId="pages.admin.reviewRequestProducts.unitCount">
              {productDisplayAttributes.unitCount}
            </DataRow>
            <DataRow labelId="pages.admin.reviewRequestProducts.upc">
              {productDisplayAttributes.upc}
            </DataRow>

            {productDisplayAttributes?.rejectionLabels &&
              productDisplayAttributes.rejectionDecision && (
                <DataRow>
                  <FieldValueRejectionReasons
                    approvalInfo={productDisplayAttributes.approvalInfo}
                    values={[productDisplayAttributes.productId]}
                    decision={productDisplayAttributes.rejectionDecision as Decision}
                  />
                </DataRow>
              )}
          </>
        )}
      </Modal>
    </>
  )
}
