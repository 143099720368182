// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostProductImagesResponseDataAttributes
 */
export interface PostProductImagesResponseDataAttributes {
  /**
   * product id
   * @type {number}
   * @memberof PostProductImagesResponseDataAttributes
   */
  productId: number
  /**
   *
   * @type {string}
   * @memberof PostProductImagesResponseDataAttributes
   */
  productScanCode: string
  /**
   * If set there's a product update pending for this item, so data may change once the change is accepted
   * @type {boolean}
   * @memberof PostProductImagesResponseDataAttributes
   */
  pendingProductUpdate: boolean
  /**
   * If set there's a product mapping pending for this item, so data may change once the change is accepted
   * @type {boolean}
   * @memberof PostProductImagesResponseDataAttributes
   */
  pendingLibraryAdd: boolean
  /**
   * If set the product is availalble in the product library
   * @type {boolean}
   * @memberof PostProductImagesResponseDataAttributes
   */
  inLibrary: boolean
  /**
   * Current image state for the product
   * @type {string}
   * @memberof PostProductImagesResponseDataAttributes
   */
  imageState: PostProductImagesResponseDataAttributesImageStateEnum
}

/**
 * @export
 */
export const PostProductImagesResponseDataAttributesImageStateEnum = {
  Unknown: 'unknown',
  Full: 'full',
  High: 'high',
  Low: 'low',
  None: 'none',
} as const
export type PostProductImagesResponseDataAttributesImageStateEnum =
  typeof PostProductImagesResponseDataAttributesImageStateEnum[keyof typeof PostProductImagesResponseDataAttributesImageStateEnum]

/**
 * Check if a given object implements the PostProductImagesResponseDataAttributes interface.
 */
export function instanceOfPostProductImagesResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'productId' in value
  isInstance = isInstance && 'productScanCode' in value
  isInstance = isInstance && 'pendingProductUpdate' in value
  isInstance = isInstance && 'pendingLibraryAdd' in value
  isInstance = isInstance && 'inLibrary' in value
  isInstance = isInstance && 'imageState' in value

  return isInstance
}

export function PostProductImagesResponseDataAttributesFromJSON(
  json: any
): PostProductImagesResponseDataAttributes {
  return PostProductImagesResponseDataAttributesFromJSONTyped(json, false)
}

export function PostProductImagesResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostProductImagesResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productId: json['product_id'],
    productScanCode: json['product_scan_code'],
    pendingProductUpdate: json['pending_product_update'],
    pendingLibraryAdd: json['pending_library_add'],
    inLibrary: json['in_library'],
    imageState: json['image_state'],
  }
}

export function PostProductImagesResponseDataAttributesToJSON(
  value?: PostProductImagesResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_id: value.productId,
    product_scan_code: value.productScanCode,
    pending_product_update: value.pendingProductUpdate,
    pending_library_add: value.pendingLibraryAdd,
    in_library: value.inLibrary,
    image_state: value.imageState,
  }
}
