import { spacing, useTheme, Variant } from '@instacart/ids-core'
import { CellBase } from '@instacart/ids-tooling'
import { mergeStyles, StylesConfig } from 'react-select'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import toPx from 'common/toPx'
import { DefaultOptionType, Select, SelectProps } from 'components/ids-ads/molecules'

const DROPDOWN_ICON_SIZE = 16

export interface SelectCellProps<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
> extends SelectProps<OptionType, IsMulti, GroupType> {
  error?: boolean
  cellProps?: Omit<Variant<typeof CellBase>, 'gutterPx'>
}

function useStyles<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({ error }: { error?: boolean }): StylesConfig<OptionType, IsMulti, GroupType> {
  const theme = useTheme()

  return {
    container: base => ({
      ...base,
      width: '100%',
    }),
    control: (base, { isFocused, isDisabled }) => ({
      ...base,
      width: '100%',
      boxSizing: 'border-box',
      cursor: isDisabled ? undefined : 'pointer',
      borderRadius: 0,
      padding: toPx`${spacing.s4} 0`,
      transition: 'none',
      '&, &:hover': {
        borderWidth: 0,
        borderColor: error ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale70,
        borderTopWidth: (() => {
          if (isFocused) return 2
          if (error) return 1
          return 0
        })(),
        boxShadow:
          error || isFocused ? 'none' : `inset 0px 1px 0px ${theme.colors.systemGrayscale10}`,
        paddingTop: (() => {
          if (isFocused) return spacing.s4 - 2
          if (error) return spacing.s4 - 1
          return spacing.s4
        })(),
        background: (() => {
          if (isDisabled) return theme.colors.systemGrayscale10
          if (error) return theme.colors.systemDetrimentalLight
          return theme.colors.systemGrayscale00
        })(),
      },
    }),
    dropdownIndicator: (base, { isDisabled }) => ({
      ...base,
      marginRight: spacing.s24,
      svg: {
        width: DROPDOWN_ICON_SIZE,
        height: DROPDOWN_ICON_SIZE,
        fill: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
      },
    }),
    clearIndicator: base => ({ ...base, marginLeft: 0, paddingLeft: 0 }),
    valueContainer: base => ({
      ...base,
      padding: toPx`${spacing.s4} ${spacing.s4} ${spacing.s4} 0`,
    }),
    placeholder: (base, { isDisabled }) => ({
      ...base,
      ...theme.typography.bodyMedium2,
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale50,
    }),
    singleValue: (base, { isDisabled }) => ({
      ...base,
      ...theme.typography.bodyMedium2,
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
    }),
    multiValue: (base, { isDisabled }) => ({
      ...base,
      ...theme.typography.bodyMedium2,
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
    }),
    input: base => ({
      ...base,
      '&, & input': {
        ...theme.typography.bodyMedium2,
      },
    }),
  }
}

export function SelectCell<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({ error, cellProps = {}, ...props }: SelectCellProps<OptionType, IsMulti, GroupType>) {
  const styles = useStyles<OptionType, IsMulti, GroupType>({ error })

  return (
    <CellBase
      gutterPx={0}
      styles={{ content: { padding: 0, position: 'relative' } }}
      {...cellProps}
    >
      <Select {...props} styles={props.styles ? mergeStyles(styles, props.styles) : styles} />
    </CellBase>
  )
}
