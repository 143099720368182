// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReservationAdGroupResponseDataAttributes } from './ReservationAdGroupResponseDataAttributes'
import {
  ReservationAdGroupResponseDataAttributesFromJSON,
  ReservationAdGroupResponseDataAttributesFromJSONTyped,
  ReservationAdGroupResponseDataAttributesToJSON,
} from './ReservationAdGroupResponseDataAttributes'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseData
 */
export interface ReservationAdGroupResponseData {
  /**
   * Reservation ad group ID
   * @type {string}
   * @memberof ReservationAdGroupResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ReservationAdGroupResponseData
   */
  type: ReservationAdGroupResponseDataTypeEnum
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributes}
   * @memberof ReservationAdGroupResponseData
   */
  attributes: ReservationAdGroupResponseDataAttributes
}

/**
 * @export
 */
export const ReservationAdGroupResponseDataTypeEnum = {
  ReservationAdGroup: 'reservation_ad_group',
} as const
export type ReservationAdGroupResponseDataTypeEnum =
  typeof ReservationAdGroupResponseDataTypeEnum[keyof typeof ReservationAdGroupResponseDataTypeEnum]

/**
 * Check if a given object implements the ReservationAdGroupResponseData interface.
 */
export function instanceOfReservationAdGroupResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function ReservationAdGroupResponseDataFromJSON(json: any): ReservationAdGroupResponseData {
  return ReservationAdGroupResponseDataFromJSONTyped(json, false)
}

export function ReservationAdGroupResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: ReservationAdGroupResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function ReservationAdGroupResponseDataToJSON(
  value?: ReservationAdGroupResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: ReservationAdGroupResponseDataAttributesToJSON(value.attributes),
  }
}
