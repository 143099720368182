import { css } from '@emotion/react'
import { CloseIcon } from '@instacart/ids-core'
import { DropdownMenu, MenuItem } from 'ic-snacks' // eslint-disable-line no-restricted-imports
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import useIntl from 'common/useIntl'
import Checkbox from 'components/CheckboxV2'
import FormattedMessage from 'components/FormattedMessage'
import {
  FilterDropdownButton,
  FilterDropdownButtonProps,
} from 'components/ids-ads/molecules/buttons/FilterDropdownButton'
import { MessageIdType } from 'locales/types'
import { BorderlessButton } from './ids-ads/molecules/buttons/BorderlessButton'

export type Option<T> = {
  value: T
} & (
  | {
      labelId: MessageIdType
      label?: never
    }
  | {
      label: string | React.ReactNode
      labelId?: never
    }
)

const styles = {
  selectOption: css`
    padding: 8px;
  `,
  clearButtonContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
}

export interface FilterSelectProps<T> {
  options: Option<T>[]
  value: T[]
  onChange(value: T[]): void
  isMultiSelect?: boolean
  showClear?: boolean
  placeholderId: MessageIdType
  disabled?: boolean
  width?: number | string
  buttonStyles?: FilterDropdownButtonProps['styleOverrides']
  menuPosition?: 'bottom' | 'top'
  testId?: string
  includeAllOption?: boolean
}

const radiumStyles: Record<string, CSSProperties> = {
  menuItem: {
    paddingTop: '0.25rem',
    paddingRight: '1rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.5rem',
  },
}

/** @deprecated Use Select from 'components/ids-ads' instead */
export default function FilterSelect<T extends string | number | boolean>({
  testId,
  options,
  value,
  onChange,
  isMultiSelect = true,
  showClear = true,
  placeholderId,
  disabled = false,
  width,
  buttonStyles,
  menuPosition = 'bottom',
  includeAllOption = false,
}: FilterSelectProps<T>) {
  const intl = useIntl()
  const placeholder = intl.formatMessage({ id: placeholderId })

  const [open, setOpen] = useState(false)

  const getOptionLabel = useCallback(
    (option: Option<T>) =>
      (option.label || (option.labelId && intl.formatMessage({ id: option.labelId }))) as string,
    [intl]
  )

  const isSelected = useCallback((option: Option<T>) => value.includes(option.value), [value])

  const optionsWithSelected: (Option<T> & { selected: boolean })[] = useMemo(() => {
    const allOption = { labelId: 'common.all' as MessageIdType, value: 'all' as T }
    const opts = [...(includeAllOption ? [allOption] : []), ...options]
    return opts.map(option => ({
      ...option,
      selected: isSelected(option),
    }))
  }, [options, isSelected, includeAllOption])

  const handleSelect = (option: Option<T>) => {
    if (includeAllOption && option.value === 'all') return onChange([])
    if (!isMultiSelect) return onChange([option.value])

    const selected = isSelected(option)

    if (selected) {
      onChange(value.filter(val => val !== option.value))
    } else {
      onChange([...value, option.value])
    }
  }

  const handleClear = () => {
    setOpen(false)
    onChange([])
  }

  const selectedOptionDisplay = optionsWithSelected
    .filter(option => option.selected)
    .map((v, i) => {
      const label = getOptionLabel(v)

      return (
        <span key={`${label}:${v.value}`}>
          {i !== 0 && ', '}
          {label}
        </span>
      )
    })

  return (
    <>
      <DropdownMenu
        menuContainerStyle={{
          width: 'auto',
          ...(menuPosition === 'top' ? { bottom: 36 } : {}),
        }}
        triggerElement={
          <FilterDropdownButton
            data-testid={`${testId ? `${testId}-` : ''}filter-button`}
            placeholder={placeholder}
            title={placeholder}
            onClick={e => {
              e?.preventDefault()
            }}
            disabled={disabled}
            open={disabled ? false : open}
            width={width}
            styleOverrides={buttonStyles}
          >
            {selectedOptionDisplay}
          </FilterDropdownButton>
        }
        open={disabled ? false : open}
        onRequestChange={setOpen}
        onSelect={(_, option) => handleSelect(option as Option<T>)}
      >
        {optionsWithSelected.map(option => {
          return (
            <MenuItem
              key={`${option.value}:${option.selected}`}
              data-testid={`${option.value}:${option.selected}`}
              value={option.value}
              // label is marked as required but is ignored if we
              // use children
              label=""
              style={radiumStyles.menuItem}
            >
              {!isMultiSelect && (
                <div css={styles.selectOption} data-testid={`single-select-option-${option.value}`}>
                  {getOptionLabel(option)}
                </div>
              )}
              {isMultiSelect && (
                <Checkbox id={`${option.value}`} name={`${option.value}`} checked={option.selected}>
                  {getOptionLabel(option)}
                </Checkbox>
              )}
            </MenuItem>
          )
        })}
        {showClear && value.length !== 0 && (
          <div css={styles.clearButtonContainer} data-testid="filter-clear-button">
            <BorderlessButton
              icon={CloseIcon}
              iconSize={12}
              iconPosition="left"
              onClick={e => {
                e.preventDefault()
                handleClear?.()
              }}
              compact
            >
              <FormattedMessage id="common.clear" />
            </BorderlessButton>
          </div>
        )}
      </DropdownMenu>
    </>
  )
}
