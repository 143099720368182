// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamProductAssetImageLabels } from './PostAdminBrandPagesIdLabelParamProductAssetImageLabels'
import {
  PostAdminBrandPagesIdLabelParamProductAssetImageLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamProductAssetImageLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamProductAssetImageLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamProductAssetImageLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamProductAssetImage
 */
export interface PostAdminBrandPagesIdLabelParamProductAssetImage {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamProductAssetImageLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamProductAssetImage
   */
  labels: Array<PostAdminBrandPagesIdLabelParamProductAssetImageLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamProductAssetImage
   */
  componentType: PostAdminBrandPagesIdLabelParamProductAssetImageComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamProductAssetImageComponentTypeEnum = {
  ProductAssetImage: 'PRODUCT_ASSET_IMAGE',
} as const
export type PostAdminBrandPagesIdLabelParamProductAssetImageComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamProductAssetImageComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamProductAssetImageComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamProductAssetImage interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamProductAssetImage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamProductAssetImageFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamProductAssetImage {
  return PostAdminBrandPagesIdLabelParamProductAssetImageFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamProductAssetImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamProductAssetImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamProductAssetImageLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamProductAssetImageToJSON(
  value?: PostAdminBrandPagesIdLabelParamProductAssetImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamProductAssetImageLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
