import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'context'
import { PaymentStatuses } from 'service/types'

const createPaymentStatusHook = (paymentStatus: PaymentStatuses) => () => {
  const { user } = useContext(AuthContext)
  const currentAccountPaymentStatus = user?.currentAccount?.paymentStatus
  const [doesPaymentStatusMatch, setDoesPaymentStatusMatch] = useState(false)

  useEffect(() => {
    setDoesPaymentStatusMatch(currentAccountPaymentStatus === paymentStatus)
  }, [currentAccountPaymentStatus])

  return doesPaymentStatusMatch
}

export const useIsPaymentStatusActive = createPaymentStatusHook(PaymentStatuses.ACTIVE)
export const useIsPaymentStatusInactive = createPaymentStatusHook(PaymentStatuses.INACTIVE)
export const useIsPaymentStatusRequiresPayment = createPaymentStatusHook(
  PaymentStatuses.REQUIRES_PAYMENT
)
