// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { ReservationSchemasFormatSchemasResponse } from '../models/ReservationSchemasFormatSchemasResponse'
import {
  ReservationSchemasFormatSchemasResponseFromJSON,
  ReservationSchemasFormatSchemasResponseToJSON,
} from '../models/ReservationSchemasFormatSchemasResponse'
import type { SchemaFormatSchemasResponse } from '../models/SchemaFormatSchemasResponse'
import {
  SchemaFormatSchemasResponseFromJSON,
  SchemaFormatSchemasResponseToJSON,
} from '../models/SchemaFormatSchemasResponse'
import type { SchemasFormatSchemasResponse } from '../models/SchemasFormatSchemasResponse'
import {
  SchemasFormatSchemasResponseFromJSON,
  SchemasFormatSchemasResponseToJSON,
} from '../models/SchemasFormatSchemasResponse'

export interface GetFormatSchemasReservationRequest {
  scopeRestriction?: GetFormatSchemasReservationScopeRestrictionEnum
  xApiKey?: string
  xSecretKey?: string
}

export interface GetFormatSchemasTypeRequest {
  type: GetFormatSchemasTypeTypeEnum
  prototypesOnly?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetFormatSchemasTypeSchemaRequest {
  machineName: string
  type: GetFormatSchemasTypeSchemaTypeEnum
  includeAssetDetails?: boolean
  includePlacementDetails?: boolean
  xApiKey?: string
  xSecretKey?: string
}

/**
 *
 */
export class FormatSchemasApi extends runtime.BaseAPI {
  /**
   * Retrieve reservation creatives & placements
   * Retrieve reservation creatives & placements
   */
  async getFormatSchemasReservationRaw(
    requestParameters: GetFormatSchemasReservationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReservationSchemasFormatSchemasResponse>> {
    const queryParameters: any = {}

    if (requestParameters.scopeRestriction !== undefined) {
      queryParameters['scope_restriction'] = requestParameters.scopeRestriction
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/format_schemas/reservation`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReservationSchemasFormatSchemasResponseFromJSON(jsonValue)
    )
  }

  /**
   * Retrieve reservation creatives & placements
   * Retrieve reservation creatives & placements
   */
  async getFormatSchemasReservation(
    requestParameters: GetFormatSchemasReservationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReservationSchemasFormatSchemasResponse> {
    const response = await this.getFormatSchemasReservationRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns available prototype display creative schemas from codebase.
   * Retrieve a list of available Format schema by type
   */
  async getFormatSchemasTypeRaw(
    requestParameters: GetFormatSchemasTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SchemasFormatSchemasResponse>> {
    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling getFormatSchemasType.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.prototypesOnly !== undefined) {
      queryParameters['prototypes_only'] = requestParameters.prototypesOnly
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/format_schemas/{type}`.replace(
          `{${'type'}}`,
          encodeURIComponent(String(requestParameters.type))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      SchemasFormatSchemasResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns available prototype display creative schemas from codebase.
   * Retrieve a list of available Format schema by type
   */
  async getFormatSchemasType(
    requestParameters: GetFormatSchemasTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SchemasFormatSchemasResponse> {
    const response = await this.getFormatSchemasTypeRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns prototype display schemas from codebase by machine name.
   * Retrieve a prototype creative schema by machine name
   */
  async getFormatSchemasTypeSchemaRaw(
    requestParameters: GetFormatSchemasTypeSchemaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SchemaFormatSchemasResponse>> {
    if (requestParameters.machineName === null || requestParameters.machineName === undefined) {
      throw new runtime.RequiredError(
        'machineName',
        'Required parameter requestParameters.machineName was null or undefined when calling getFormatSchemasTypeSchema.'
      )
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling getFormatSchemasTypeSchema.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.machineName !== undefined) {
      queryParameters['machine_name'] = requestParameters.machineName
    }

    if (requestParameters.includeAssetDetails !== undefined) {
      queryParameters['include_asset_details'] = requestParameters.includeAssetDetails
    }

    if (requestParameters.includePlacementDetails !== undefined) {
      queryParameters['include_placement_details'] = requestParameters.includePlacementDetails
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/format_schemas/{type}/schema`.replace(
          `{${'type'}}`,
          encodeURIComponent(String(requestParameters.type))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      SchemaFormatSchemasResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns prototype display schemas from codebase by machine name.
   * Retrieve a prototype creative schema by machine name
   */
  async getFormatSchemasTypeSchema(
    requestParameters: GetFormatSchemasTypeSchemaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SchemaFormatSchemasResponse> {
    const response = await this.getFormatSchemasTypeSchemaRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetFormatSchemasReservationScopeRestrictionEnum = {
  InHouse: 'IN_HOUSE',
  NoInHouse: 'NO_IN_HOUSE',
  RestrictionUnknown: 'RESTRICTION_UNKNOWN',
} as const
export type GetFormatSchemasReservationScopeRestrictionEnum =
  typeof GetFormatSchemasReservationScopeRestrictionEnum[keyof typeof GetFormatSchemasReservationScopeRestrictionEnum]
/**
 * @export
 */
export const GetFormatSchemasTypeTypeEnum = {
  Assets: 'assets',
  BrandPages: 'brand_pages',
  Creatives: 'creatives',
  Emails: 'emails',
} as const
export type GetFormatSchemasTypeTypeEnum =
  typeof GetFormatSchemasTypeTypeEnum[keyof typeof GetFormatSchemasTypeTypeEnum]
/**
 * @export
 */
export const GetFormatSchemasTypeSchemaTypeEnum = {
  Assets: 'assets',
  BrandPages: 'brand_pages',
  Creatives: 'creatives',
  Emails: 'emails',
} as const
export type GetFormatSchemasTypeSchemaTypeEnum =
  typeof GetFormatSchemasTypeSchemaTypeEnum[keyof typeof GetFormatSchemasTypeSchemaTypeEnum]
