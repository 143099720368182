import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { ForwardedRef, forwardRef, AnchorHTMLAttributes } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import {
  CommonButtonProps,
  UseStylesInternal,
  UseStylesOpts,
  defaultIconSize,
  resolveColor,
} from '.'
import { ButtonContent } from './ButtonContent'
import { useButtonStyles } from './makeButton'
import { TooltipAnchor } from './TooltipAnchor'
import { TooltipLink } from './TooltipLink'

function useLinkButtonStyles(opts: UseStylesOpts & UseStylesInternal) {
  const theme = useTheme()

  const { button, content, focusRing, hover, active, disabled } = useButtonStyles(opts)

  return css({
    position: 'relative',
    userSelect: 'none',
    ...(button as object),
    ...(content as object),
    '&:hover, &:focus, &:active, &:visited': {
      color: resolveColor(theme, opts.textColor),
    },
    '&:hover': hover,
    '&:active': active,
    '&.disabled': {
      cursor: 'default',
      ...(disabled as object),
    },
    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible::after': {
      border: `2px solid ${theme.colors.systemGrayscale70}`,
      content: '""',
      display: 'block',
      position: 'absolute',
      ...(focusRing as object),
    },
  })
}

interface CommonProps extends CommonButtonProps {
  disabled?: boolean
}

export type LinkButtonProps = LinkProps & CommonProps

export function makeLinkButton(displayName: string, opts: UseStylesOpts) {
  const Component = (
    {
      children,
      compact,
      loading,
      href,
      onClick,
      className,
      icon,
      iconSize = defaultIconSize(compact),
      iconPosition = 'left',
      openIndicator,
      open,
      tooltip,
      ...props
    }: LinkButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const disabled = props.disabled || loading
    const iconOnly = icon && !children
    const styles = useLinkButtonStyles({
      ...opts,
      compact,
      loading,
      hasIcon: !!icon,
      iconOnly,
      iconSize,
      iconPosition,
      openIndicator,
    })

    const finalProps = {
      ...props,
      className: classNames({ disabled }, className),
      href: disabled ? undefined : href,
      css: styles,
      ref,
    }

    const content = (
      <ButtonContent
        icon={icon}
        iconSize={iconSize}
        iconPosition={iconPosition}
        loading={loading}
        open={open}
        openIndicator={openIndicator}
        textColor={opts.textColor}
      >
        {children}
      </ButtonContent>
    )

    if (tooltip) {
      return (
        <TooltipLink
          onClick={disabled ? e => e.preventDefault() : onClick}
          tooltip={tooltip}
          {...finalProps}
        >
          {content}
        </TooltipLink>
      )
    }
    return (
      <Link onClick={disabled ? e => e.preventDefault() : onClick} {...finalProps}>
        {content}
      </Link>
    )
  }

  const ForwardedComponent = forwardRef<HTMLAnchorElement, LinkButtonProps>(Component)

  ForwardedComponent.displayName = displayName

  return ForwardedComponent
}

export type AnchorButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps

export function makeAnchorButton(displayName: string, opts: UseStylesOpts) {
  const Component = (
    {
      children,
      compact,
      loading,
      href,
      onClick,
      className,
      icon,
      iconSize = defaultIconSize(compact),
      iconPosition = 'left',
      openIndicator,
      open,
      tooltip,
      ...props
    }: AnchorButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const disabled = props.disabled || loading
    const iconOnly = icon && !children
    const styles = useLinkButtonStyles({
      ...opts,
      compact,
      loading,
      hasIcon: !!icon,
      iconOnly,
      iconSize,
      iconPosition,
      openIndicator,
    })

    const finalProps = {
      ...props,
      className: classNames({ disabled }, className),
      href: disabled ? undefined : href,
      onClick: disabled ? undefined : onClick,
      css: styles,
      ref,
    }

    const content = (
      <ButtonContent
        icon={icon}
        iconSize={iconSize}
        iconPosition={iconPosition}
        loading={loading}
        open={open}
        openIndicator={openIndicator}
        textColor={opts.textColor}
      >
        {children}
      </ButtonContent>
    )

    if (tooltip) {
      return (
        <TooltipAnchor tooltip={tooltip} {...finalProps}>
          {content}
        </TooltipAnchor>
      )
    }

    return <a {...finalProps}>{content}</a>
  }

  const ForwardedComponent = forwardRef<HTMLAnchorElement, AnchorButtonProps>(Component)

  ForwardedComponent.displayName = displayName

  return ForwardedComponent
}
