import { useCallback, useState } from 'react'

export function useBoolean(initialValue: boolean): [boolean, () => void, () => void] {
  const [value, setValue] = useState(initialValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])

  return [value, setTrue, setFalse]
}
