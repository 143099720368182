// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerProviDisplayCampaignsInputDateRange } from './ApiReportsControllerProviDisplayCampaignsInputDateRange'
import {
  ApiReportsControllerProviDisplayCampaignsInputDateRangeFromJSON,
  ApiReportsControllerProviDisplayCampaignsInputDateRangeFromJSONTyped,
  ApiReportsControllerProviDisplayCampaignsInputDateRangeToJSON,
} from './ApiReportsControllerProviDisplayCampaignsInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerProviDisplayCampaignsInput
 */
export interface ApiReportsControllerProviDisplayCampaignsInput {
  /**
   *
   * @type {ApiReportsControllerProviDisplayCampaignsInputDateRange}
   * @memberof ApiReportsControllerProviDisplayCampaignsInput
   */
  dateRange?: ApiReportsControllerProviDisplayCampaignsInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayCampaignsInput
   */
  segment?: ApiReportsControllerProviDisplayCampaignsInputSegmentEnum
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayCampaignsInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayCampaignsInput
   */
  name?: string
}

/**
 * @export
 */
export const ApiReportsControllerProviDisplayCampaignsInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerProviDisplayCampaignsInputSegmentEnum =
  typeof ApiReportsControllerProviDisplayCampaignsInputSegmentEnum[keyof typeof ApiReportsControllerProviDisplayCampaignsInputSegmentEnum]

/**
 * Check if a given object implements the ApiReportsControllerProviDisplayCampaignsInput interface.
 */
export function instanceOfApiReportsControllerProviDisplayCampaignsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerProviDisplayCampaignsInputFromJSON(
  json: any
): ApiReportsControllerProviDisplayCampaignsInput {
  return ApiReportsControllerProviDisplayCampaignsInputFromJSONTyped(json, false)
}

export function ApiReportsControllerProviDisplayCampaignsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerProviDisplayCampaignsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerProviDisplayCampaignsInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function ApiReportsControllerProviDisplayCampaignsInputToJSON(
  value?: ApiReportsControllerProviDisplayCampaignsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerProviDisplayCampaignsInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    attribution_model: value.attributionModel,
    name: value.name,
  }
}
