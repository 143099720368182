import { useBrandPageReviewContext } from 'pages/Admin/BrandPages/BrandPageLabelReview/BrandPageReviewContext'
import { useBrandPageContext } from 'pages/BrandPage/BrandPageContext'
import { useRoutePaths } from 'pages/DisplayProduct/displayAndEmail.routes'
import { useDisplayContext } from 'pages/DisplayProduct/DisplayContext'
import { usePrototypeCampaignsContext } from 'pages/PrototypeCampaigns/Form/PrototypeCampaignsContext'

export default function useCorrectContext() {
  const paths = useRoutePaths()

  const isBrandPageReview = `${paths.BASE_PATH}`.includes('admin/brand_pages')
  if (isBrandPageReview) return useBrandPageReviewContext
  const isPrototypeCampaign = `${paths.BASE_PATH}`.includes('prototype')
  if (isPrototypeCampaign) return usePrototypeCampaignsContext
  const isBrandPage = `${paths.BASE_PATH}`.includes('brand_pages')
  if (isBrandPage) return useBrandPageContext
  return useDisplayContext
}
