import { FieldHookConfig, useField, useFormikContext } from 'formik'
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik/dist/types'
import { useMemo } from 'react'

/**
 * This is a temporary replacement for Formik useField hook. built-in `useField` hook causes component to always
 * re-render due to `setValue` method's reference is changing. This hook is fixing that issue by using `setFieldValue`
 * which does not cause re-render
 * see: https://github.com/formium/formik/issues/2268
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFormikField<Value = any>(
  propsOrFieldName: string | FieldHookConfig<Value>
): [FieldInputProps<Value>, FieldMetaProps<Value>, FieldHelperProps<Value>] {
  const [field, meta] = useField(propsOrFieldName)
  const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext()
  const helpers = useMemo(
    () => ({
      setValue: (value: Value, shouldValidate?: boolean) =>
        setFieldValue(field.name, value, shouldValidate),
      setTouched: (value: boolean, shouldValidate?: boolean) =>
        setFieldTouched(field.name, value, shouldValidate),
      setError: (value: Value | string | undefined) =>
        setFieldError(field.name, (value || '') as string),
    }),
    [setFieldTouched, setFieldValue, setFieldError, field.name]
  )

  return [field, meta, helpers]
}
