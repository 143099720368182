// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesCreativeActions
 */
export interface EnableDisplayAdGroupResponseDataAttributesCreativeActions {
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainItemGrid?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainProductGroupGrid?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainCollectionGrid?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActions
   */
  bannerClick?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesCreativeActions interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesCreativeActions {
  return EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      EnableDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
