// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockHeroBannerAssetsDesktop } from './GetBrandPagesParamBlockHeroBannerAssetsDesktop'
import {
  GetBrandPagesParamBlockHeroBannerAssetsDesktopFromJSON,
  GetBrandPagesParamBlockHeroBannerAssetsDesktopFromJSONTyped,
  GetBrandPagesParamBlockHeroBannerAssetsDesktopToJSON,
} from './GetBrandPagesParamBlockHeroBannerAssetsDesktop'
import type { GetBrandPagesParamBlockHeroBannerAssetsMobile } from './GetBrandPagesParamBlockHeroBannerAssetsMobile'
import {
  GetBrandPagesParamBlockHeroBannerAssetsMobileFromJSON,
  GetBrandPagesParamBlockHeroBannerAssetsMobileFromJSONTyped,
  GetBrandPagesParamBlockHeroBannerAssetsMobileToJSON,
} from './GetBrandPagesParamBlockHeroBannerAssetsMobile'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockHeroBannerAssets
 */
export interface GetBrandPagesParamBlockHeroBannerAssets {
  /**
   *
   * @type {GetBrandPagesParamBlockHeroBannerAssetsDesktop}
   * @memberof GetBrandPagesParamBlockHeroBannerAssets
   */
  desktop: GetBrandPagesParamBlockHeroBannerAssetsDesktop
  /**
   *
   * @type {GetBrandPagesParamBlockHeroBannerAssetsMobile}
   * @memberof GetBrandPagesParamBlockHeroBannerAssets
   */
  mobile: GetBrandPagesParamBlockHeroBannerAssetsMobile
}

/**
 * Check if a given object implements the GetBrandPagesParamBlockHeroBannerAssets interface.
 */
export function instanceOfGetBrandPagesParamBlockHeroBannerAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function GetBrandPagesParamBlockHeroBannerAssetsFromJSON(
  json: any
): GetBrandPagesParamBlockHeroBannerAssets {
  return GetBrandPagesParamBlockHeroBannerAssetsFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockHeroBannerAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockHeroBannerAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: GetBrandPagesParamBlockHeroBannerAssetsDesktopFromJSON(json['desktop']),
    mobile: GetBrandPagesParamBlockHeroBannerAssetsMobileFromJSON(json['mobile']),
  }
}

export function GetBrandPagesParamBlockHeroBannerAssetsToJSON(
  value?: GetBrandPagesParamBlockHeroBannerAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: GetBrandPagesParamBlockHeroBannerAssetsDesktopToJSON(value.desktop),
    mobile: GetBrandPagesParamBlockHeroBannerAssetsMobileToJSON(value.mobile),
  }
}
