import { css } from '@emotion/react'
import { Text, CloseIcon, LoadingLockupTextRow, Theme, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get, last, capitalize } from 'lodash'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'
import { TargetingRuleContext } from '../hooks'

export interface TargetedElementsLineProps {
  entities: {
    name: string
    parentDisplayName?: string
  }[]
  errorMessageId: MessageIdType
  fieldName: string
  removeElementByName: (name: string) => void
  isLoading?: boolean
}

const targetedElementDivStyle = (isReadOnly: boolean, theme: Theme) =>
  css({
    border: `1px solid ${theme.colors.systemSuccessDark}`,
    borderRadius: 14,
    display: 'flex',
    margin: '0px 4px',
    paddingLeft: 7,
    paddingRight: isReadOnly ? 7 : 2,
    paddingBottom: 1,
    alignItems: 'center',
    background: theme.colors.systemSuccessLight,
  })

const flexContainerStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
})

const entityContainerStyle = css({
  display: 'flex',
  alignContent: 'center',
  paddingBottom: 2,
})

const closeIconStyle = css({
  cursor: 'pointer',
  margin: 4,
})

const targetedElementTextStyle = (theme: Theme) =>
  css({
    ...theme.typography.bodySmall1,
    color: theme.colors.systemSuccessDark,
  })

const errorTextStyle = (theme: Theme) => ({
  color: theme.colors.systemDetrimentalRegular,
  paddingTop: 1,
})

export default function TargetedElementsLine({
  entities,
  errorMessageId,
  fieldName,
  isLoading = false,
  removeElementByName,
}: TargetedElementsLineProps) {
  const theme = useTheme()

  const { errors, touched } = useFormikContext()
  const { isReadOnly } = useContext(TargetingRuleContext)

  if (isLoading) {
    return <LoadingLockupTextRow styles={{ container: { marginBottom: 0 } }} />
  }

  const showError = get(errors, fieldName) && get(touched, fieldName)

  return (
    <div css={flexContainerStyle}>
      {showError && !entities.length && (
        <Text typography="bodySmall2" style={errorTextStyle(theme)}>
          <FormattedMessage id={errorMessageId} />
        </Text>
      )}
      {entities.map(({ name, parentDisplayName }) => (
        <div css={entityContainerStyle} key={name}>
          <div css={targetedElementDivStyle(isReadOnly, theme)}>
            <div css={targetedElementTextStyle(theme)}>
              {parentDisplayName ? `${capitalize(parentDisplayName)}: ` : ''}
              {parentDisplayName ? capitalize(last(name.split('.')) || name) : name}
            </div>
            {!isReadOnly && (
              <CloseIcon
                size={14}
                css={closeIconStyle}
                color="systemSuccessDark"
                onClick={() => removeElementByName(name)}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
