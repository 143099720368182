// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  linear: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  lastTouch?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON(
        json['linear']
      ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON(
        value.linear
      ),
    last_touch:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON(
        value.lastTouch
      ),
  }
}
