// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordResponseData } from './GetAdGroupKeywordResponseData'
import {
  GetAdGroupKeywordResponseDataFromJSON,
  GetAdGroupKeywordResponseDataFromJSONTyped,
  GetAdGroupKeywordResponseDataToJSON,
} from './GetAdGroupKeywordResponseData'
import type { GetAdGroupKeywordResponseMeta } from './GetAdGroupKeywordResponseMeta'
import {
  GetAdGroupKeywordResponseMetaFromJSON,
  GetAdGroupKeywordResponseMetaFromJSONTyped,
  GetAdGroupKeywordResponseMetaToJSON,
} from './GetAdGroupKeywordResponseMeta'

/**
 *
 * @export
 * @interface GetAdGroupKeywordResponse
 */
export interface GetAdGroupKeywordResponse {
  /**
   *
   * @type {GetAdGroupKeywordResponseData}
   * @memberof GetAdGroupKeywordResponse
   */
  data: GetAdGroupKeywordResponseData
  /**
   *
   * @type {GetAdGroupKeywordResponseMeta}
   * @memberof GetAdGroupKeywordResponse
   */
  meta: GetAdGroupKeywordResponseMeta
}

/**
 * Check if a given object implements the GetAdGroupKeywordResponse interface.
 */
export function instanceOfGetAdGroupKeywordResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetAdGroupKeywordResponseFromJSON(json: any): GetAdGroupKeywordResponse {
  return GetAdGroupKeywordResponseFromJSONTyped(json, false)
}

export function GetAdGroupKeywordResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetAdGroupKeywordResponseDataFromJSON(json['data']),
    meta: GetAdGroupKeywordResponseMetaFromJSON(json['meta']),
  }
}

export function GetAdGroupKeywordResponseToJSON(value?: GetAdGroupKeywordResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetAdGroupKeywordResponseDataToJSON(value.data),
    meta: GetAdGroupKeywordResponseMetaToJSON(value.meta),
  }
}
