import { InputBase, Variant, useTheme } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { adsInputStyles } from './adsInputStyles'

type HTMLTextInputType = 'text' | 'email' | 'password' | 'url' | 'number'

export interface InputTextProps extends Variant<typeof InputBase> {
  type?: HTMLTextInputType
  compact?: boolean
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(
  { compact, ...props },
  ref
) {
  const theme = useTheme()
  const styles = adsInputStyles({ theme, compact })
  return <InputBase styles={styles} ref={ref} {...props} />
})

InputText.displayName = 'InputText'
