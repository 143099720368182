import { css } from '@emotion/react'
import { useTheme, spacing, Text } from '@instacart/ids-core'
import { KeyboardEvent, ChangeEvent, useEffect, useState, useRef, useCallback } from 'react'
import { useIntl } from 'common'
import Loader from 'components/AssetManager/Loader'
import { InputSearch, InputSearchProps } from 'components/ids-ads'
import useDebounce from 'hooks/useDebounce'
import { PaginationWithPageSize } from 'hooks/usePagination'
import { MessageIdType } from 'locales/types'
import { GetProductsSearchClassifiedTypeEnum } from 'service/openapi/__codegen__/apis/ProductsApi'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import FormattedMessage from '../FormattedMessage'
import { TablePagination } from '../organisms/TableV3/components/TablePagination/TablePagination'
import { ProductLabelV2 as ProductLabel } from './ProductLabel'
import { ProductSearchItemsTypes } from './types'

const ENTER_KEY = 'Enter'
const ARROW_UP = 'ArrowUp'
const ARROW_DOWN = 'ArrowDown'

export interface ProductSearchTableProps extends Pick<InputSearchProps, 'invalid'> {
  productOptions: GetProductsResponseData[]
  onProductClick: (product: GetProductsResponseData, isProductNewlySelected?: boolean) => void
  onSearch: (newQuery: string) => void
  productLimitReached?: boolean
  loading: boolean
  itemsType?: ProductSearchItemsTypes
  error?: string
  disabled?: boolean
  placeholder?: MessageIdType
  onBlurOrDropdownClose?: () => void
  selectedProductIds: (string | undefined)[]
  newSelectedProductsIds: string[]
  compact?: boolean
  classifiedType?: GetProductsSearchClassifiedTypeEnum
  setPage: (value: number) => void
  pagination: PaginationWithPageSize
}

const useStyles = () => {
  const theme = useTheme()
  return {
    table: css({
      width: '100%',
      paddingBottom: 10,
    }),
    wrapper: css({
      height: '100%',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
    }),
    productSearchTable: css({
      height: 'calc(100% - 80px)',
      overflow: 'auto',
      marginTop: spacing.s16,
    }),
    searchDrawerMessages: css({
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: theme.colors.systemGrayscale50,
      '&>div': {
        width: 300,
      },
    }),
  }
}

const ProductSearchTable = ({
  productOptions,
  placeholder,
  onProductClick,
  onSearch,
  invalid,
  disabled,
  itemsType = 'product',
  loading,
  error,
  newSelectedProductsIds = [],
  selectedProductIds: existingProductIds = [],
  setPage,
  pagination,
}: ProductSearchTableProps) => {
  const { formatMessage } = useIntl()
  const { table, wrapper, searchDrawerMessages, productSearchTable } = useStyles()
  const [prebounceQuery, setPrebounceQuery] = useState('')
  const debouncedQuery = useDebounce(prebounceQuery, 250)
  const [options, setOptions] = useState<JSX.Element[]>([])
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1)
  const [interacted, setInteracted] = useState<boolean>(false)
  const placeholderText = formatMessage({
    id: placeholder || `components.${itemsType}Search.placeholder.default`,
  })

  const onNext = () => {
    setPage?.(pagination?.currentPage + 1)
  }

  const onPrevious = () => {
    setPage?.(pagination?.currentPage - 1)
  }

  const onPageSelect = (page: number) => {
    setPage?.(page)
  }

  useEffect(() => {
    onSearch(debouncedQuery)
  }, [debouncedQuery, onSearch])

  const bodyRef = useRef<HTMLTableSectionElement>(null)

  useEffect(() => {
    if (focusedOptionIndex > -1 && bodyRef.current) {
      const pots = bodyRef.current.querySelectorAll(['[role=button]'].join(','))
      const el = pots[focusedOptionIndex] as HTMLElement
      el.focus()
    }
  }, [focusedOptionIndex])

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setOptions([])
    }

    setPage?.(1)
    setPrebounceQuery(e.target.value)
  }

  const handleInitialClick = () => {
    if (options.length) {
      setFocusedOptionIndex(-1)
    }
    if (!interacted) {
      setInteracted(true)
    }
  }

  const handleInputArrowDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const { key } = e
    if (!options.length) return
    if (key === 'ArrowDown') {
      e.preventDefault()
      setFocusedOptionIndex(0)
    }
  }

  const handleOptionClickToCallback = useCallback(
    (productId: string) => {
      const isProductNewlySelected = newSelectedProductsIds.includes(productId)
      const isProductPreexisting = existingProductIds.includes(productId)
      const foundProduct = productOptions.find(product => product.id === productId)

      if (foundProduct && !isProductPreexisting) {
        onProductClick(foundProduct, isProductNewlySelected)
      }
    },
    [productOptions, onProductClick, newSelectedProductsIds, existingProductIds]
  )

  useEffect(() => {
    if (productOptions.length && interacted) {
      const handleOptionKeyDown = (clickHandler: () => void) => (e: KeyboardEvent) => {
        if (e.key === ARROW_UP) {
          e.preventDefault()
          const atTop = focusedOptionIndex === 0 || focusedOptionIndex === -1
          if (atTop && inputRef.current) {
            inputRef.current.focus()
            setFocusedOptionIndex(-1)
          } else {
            setFocusedOptionIndex(focusedOptionIndex - 1)
          }
        } else if (e.key === ARROW_DOWN) {
          e.preventDefault()
          const atBottom = focusedOptionIndex + 1 === productOptions.length
          if (atBottom) {
            setFocusedOptionIndex(0)
          } else {
            setFocusedOptionIndex(focusedOptionIndex + 1)
          }
        } else if (e.key === ENTER_KEY) {
          clickHandler()
        }
      }

      setOptions(
        productOptions.map(product => (
          <ProductLabel
            product={product}
            onClick={handleOptionClickToCallback}
            isPreExisting={existingProductIds.includes(product.id)}
            isSelected={newSelectedProductsIds.includes(product.id)}
            onKeyDown={handleOptionKeyDown(() => handleOptionClickToCallback(product.id))}
          />
        ))
      )
    } else {
      setOptions([])
    }
  }, [
    interacted,
    productOptions,
    existingProductIds,
    focusedOptionIndex,
    newSelectedProductsIds,
    handleOptionClickToCallback,
  ])

  const showTable = !!options.length && !loading

  return (
    <div css={wrapper} ref={containerRef}>
      <InputSearch
        id="product-input-search"
        data-testid="product-input-search"
        ref={inputRef}
        onChange={onInputChange}
        value={prebounceQuery}
        placeholder={placeholderText}
        autoComplete="off"
        invalid={invalid}
        disabled={disabled}
        onClick={handleInitialClick}
        onKeyDown={handleInputArrowDown}
      />
      <div css={productSearchTable}>
        {!showTable && (
          <div css={searchDrawerMessages}>
            {loading && <Loader />}
            {!loading && !interacted && (
              <div>
                <Text color="systemGrayscale50" typography="subtitleLarge">
                  <FormattedMessage id={`components.${itemsType}SearchTable.initial.title`} />
                </Text>
                <Text color="systemGrayscale50" typography="bodyMedium2">
                  <FormattedMessage id={`components.${itemsType}SearchTable.initial.description`} />
                </Text>
              </div>
            )}
            {!loading && interacted && !error && (
              <div>
                <Text color="systemGrayscale50" typography="subtitleLarge">
                  <FormattedMessage
                    id={`components.${itemsType}SearchTable.noProductsFound.title`}
                  />
                </Text>
                <Text color="systemGrayscale50" typography="bodyMedium2">
                  <FormattedMessage
                    id={`components.${itemsType}SearchTable.noProductsFound.description`}
                  />
                </Text>
              </div>
            )}
            {interacted && error && (
              <div>
                <FormattedMessage
                  id={`pages.adGroupEdit.${itemsType}SearchField.error.defaultError`}
                  values={{ err: error }}
                />
              </div>
            )}
          </div>
        )}
        {showTable && (
          <div css={table}>
            <table css={table}>
              <tbody ref={bodyRef}>{options}</tbody>
            </table>
          </div>
        )}
      </div>
      {!!setPage && showTable && (
        <TablePagination
          currentPage={pagination?.currentPage}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalCount}
          itemsPerPage={pagination?.pageSize}
          onNextPage={onNext}
          onPreviousPage={onPrevious}
          onSetPage={onPageSelect}
        />
      )}
    </div>
  )
}

export default ProductSearchTable
