// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockHeroBannerAssets } from './GetBrandPagesParamBlockHeroBannerAssets'
import {
  GetBrandPagesParamBlockHeroBannerAssetsFromJSON,
  GetBrandPagesParamBlockHeroBannerAssetsFromJSONTyped,
  GetBrandPagesParamBlockHeroBannerAssetsToJSON,
} from './GetBrandPagesParamBlockHeroBannerAssets'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockHeroBanner
 */
export interface GetBrandPagesParamBlockHeroBanner {
  /**
   * id of the block
   * @type {string}
   * @memberof GetBrandPagesParamBlockHeroBanner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockHeroBanner
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockHeroBanner
   */
  type: GetBrandPagesParamBlockHeroBannerTypeEnum
  /**
   *
   * @type {GetBrandPagesParamBlockHeroBannerAssets}
   * @memberof GetBrandPagesParamBlockHeroBanner
   */
  assets: GetBrandPagesParamBlockHeroBannerAssets
}

/**
 * @export
 */
export const GetBrandPagesParamBlockHeroBannerTypeEnum = {
  HeroBannerEvergreenV1: 'hero_banner.evergreen.v1',
} as const
export type GetBrandPagesParamBlockHeroBannerTypeEnum =
  typeof GetBrandPagesParamBlockHeroBannerTypeEnum[keyof typeof GetBrandPagesParamBlockHeroBannerTypeEnum]

/**
 * Check if a given object implements the GetBrandPagesParamBlockHeroBanner interface.
 */
export function instanceOfGetBrandPagesParamBlockHeroBanner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function GetBrandPagesParamBlockHeroBannerFromJSON(
  json: any
): GetBrandPagesParamBlockHeroBanner {
  return GetBrandPagesParamBlockHeroBannerFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockHeroBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockHeroBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: GetBrandPagesParamBlockHeroBannerAssetsFromJSON(json['assets']),
  }
}

export function GetBrandPagesParamBlockHeroBannerToJSON(
  value?: GetBrandPagesParamBlockHeroBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: GetBrandPagesParamBlockHeroBannerAssetsToJSON(value.assets),
  }
}
