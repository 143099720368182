// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch } from './PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinear } from './PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData
 */
export interface PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData
   */
  linear: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfPutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData {
  return PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
