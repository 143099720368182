import { css } from '@emotion/react'
import { spacing, useTheme, CloseIcon } from '@instacart/ids-core'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { useIntl } from 'common'
import toPx from 'common/toPx'
import ButtonRow from 'components/molecules/ButtonRow'
import IconButtonInline from 'components/molecules/IconButtonInline'
import { BannerActionButtons } from './components'
import {
  bannerTypeToColor,
  bannerTypeToBackgroundColor,
  TRANSITION_TIME_MS,
  DEFAULT_ICON_SIZE,
  bannerTypeToIcon,
  bannerTypeToIconColor,
  BannerNotificationProps,
  BannerType,
  IconPosition,
  useBannerNotification,
} from './utils'

function useStyles({
  bannerType,
  iconPosition,
  noBorder,
}: {
  bannerType: BannerType
  iconPosition: IconPosition
  noBorder: boolean
}) {
  const theme = useTheme()
  return {
    root: css({
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.colors[bannerTypeToColor[bannerType]],
      background: noBorder ? 'inherit' : theme.colors[bannerTypeToBackgroundColor[bannerType]],
      ...theme.typography.bodyMedium1,
      '&.exit': {
        opacity: 1,
      },
      '&.exit-active': {
        opacity: 0,
        transform: `scale(0.9)`,
        transition: `opacity ${TRANSITION_TIME_MS}ms, transform ${TRANSITION_TIME_MS}ms`,
      },
      '&.system': {
        padding: toPx`${spacing.s12} ${spacing.s24}`,
        margin: 0,
        borderRadius: 'none',
      },
      '&.contextual': {
        padding: spacing.s12,
        margin: noBorder ? 0 : toPx`0 0 ${spacing.s24}`,
        borderRadius: theme.radius.r8,
      },
      '&.contextual-marginless': {
        padding: spacing.s12,
        borderRadius: theme.radius.r8,
      },
    }),
    content: css({
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing.s24,
      a: {
        color: theme.colors[bannerTypeToColor[bannerType]],
        textDecoration: 'underline',
        '&:hover, &:visited': {
          color: 'inherit',
        },
      },
    }),
    icon: css({
      display: 'flex',
      alignSelf: iconPosition === 'middle' ? 'center' : 'flex-start',
      marginRight: spacing.s8,
    }),
    message: css({
      ...theme.typography.bodyMedium2,
    }),
    button: css({
      border: 'none',
    }),
    currentPage: css({
      color: theme.colors.systemGrayscale70,
    }),
  }
}

export function BannerNotification({
  type,
  message,
  actionButtons,
  actionButtonsProps,
  iconOverride,
  iconPosition = 'middle',
  variant = 'contextual',
  testId,
  dismissId,
  dismiss,
  className,
  noBorder = false,
  ...props
}: BannerNotificationProps) {
  const styles = useStyles({ bannerType: type, iconPosition, noBorder })
  const { formatMessage } = useIntl()
  const { isVisible, dismissBanner } = useBannerNotification(dismissId)

  const Icon = bannerTypeToIcon[type]
  const iconColor = bannerTypeToIconColor[type]

  return (
    <CSSTransition in={isVisible} timeout={TRANSITION_TIME_MS} unmountOnExit>
      <div
        css={styles.root}
        data-testid={testId || 'banner-notification'}
        className={classNames(variant, className)}
        {...props}
      >
        <div css={styles.content}>
          <div css={styles.icon}>
            {iconOverride || <Icon size={DEFAULT_ICON_SIZE} color={iconColor} css={styles.icon} />}
          </div>
          <div>
            <div>{message.header}</div>
            <div css={styles.message}>{message.message}</div>
          </div>
        </div>
        <ButtonRow compact>
          {actionButtonsProps ? <BannerActionButtons {...actionButtonsProps} /> : actionButtons}
          {(dismissId || dismiss) && (
            <IconButtonInline
              icon={CloseIcon}
              color={iconColor}
              size={DEFAULT_ICON_SIZE}
              accessibleLabel={formatMessage({ id: 'common.close' })}
              onClick={dismiss || dismissBanner}
              data-testid="banner-dismiss"
            />
          )}
        </ButtonRow>
      </div>
    </CSSTransition>
  )
}
