import { useRouteMatch } from 'react-router-dom'
import { CampaignTypeEnum } from 'types/campaigns'

export interface CampaignEditRouteMatchParams {
  campaignId: string
}

export interface RoutePaths {
  BASE_PATH: string
  CAMPAIGN_ID_BASE: string
  CAMPAIGN_CREATE: string
  CAMPAIGN_EDIT: string
  AD_GROUP_ID_BASE: string
  AD_GROUP_CREATE: string
  AD_GROUP_EDIT: string
  CAMPAIGN_REVIEW: string
  CAMPAIGNS_DASHBOARD: string
}

export function useCampaignId() {
  const { CAMPAIGN_ID_BASE } = useRoutePaths()
  const campaignId =
    useRouteMatch<CampaignEditRouteMatchParams>(CAMPAIGN_ID_BASE)?.params.campaignId

  return campaignId !== 'new' ? campaignId : undefined
}

/** @deprecated Pass campaign specific styles/logic in as props instead */
export function useCampaignType() {
  return CampaignTypeEnum.Display
}

export function useIsVideoAdGroup() {
  const { BASE_PATH } = useRoutePaths()
  return BASE_PATH.includes('video')
}

export function useRoutePaths(): RoutePaths {
  const { path } = useRouteMatch()

  const campaignIdBasePath = `${path}/campaign/:campaignId`
  const adGroupIdBasePath = `${campaignIdBasePath}/ad_group/:adGroupId`
  return {
    BASE_PATH: path,
    CAMPAIGN_ID_BASE: campaignIdBasePath,
    CAMPAIGN_CREATE: `${path}/campaign/new`,
    CAMPAIGN_EDIT: `${campaignIdBasePath}/edit`,
    AD_GROUP_ID_BASE: adGroupIdBasePath,
    AD_GROUP_CREATE: `${campaignIdBasePath}/ad_group/new/:index`,
    AD_GROUP_EDIT: `${adGroupIdBasePath}/edit`,
    CAMPAIGN_REVIEW: `${campaignIdBasePath}/review`,
    CAMPAIGNS_DASHBOARD: `${path}/dashboard`,
  }
}
