import { css } from '@emotion/react'
import { CloseIcon, spacing, useTheme, elevation } from '@instacart/ids-core'
import { useEffect } from 'react'
import { useIntl } from 'common'
import toPx from 'common/toPx'
import { usePropRef } from 'common/usePropRef'
import FormattedMessage from 'components/FormattedMessage'
import ButtonRow from 'components/molecules/ButtonRow'
import IconButtonInline from 'components/molecules/IconButtonInline'
import { Notification } from 'context/notifications'
import { ToastActionButtons } from './components/ToastActionButtons'
import { DEFAULT_ICON_SIZE, notificationTypeToColor, notificationTypeToIcon } from './utils'

export interface ToastProps extends Omit<Notification, 'id'> {
  onClear(): void
}

function useStyles() {
  const theme = useTheme()
  return {
    root: css({
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.colors.systemGrayscale70,
      background: theme.colors.systemGrayscale00,
      padding: toPx`${spacing.s12 - 1} ${spacing.s12}`,
      borderRadius: theme.radius.r8,
      ...theme.typography.bodyMedium2,
      ...elevation.high.shadow,
    }),
    content: css({
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing.s24,
      a: {
        color: theme.colors.systemGrayscale70,
        textDecoration: 'underline',
        '&:hover, &:visited': {
          color: theme.colors.systemGrayscale70,
        },
      },
    }),
    icon: css({
      display: 'flex',
      marginRight: spacing.s12,
    }),
    buttons: css({
      margin: toPx`${-2} 0`,
    }),
  }
}

export default function Toast({
  persistent,
  closeDelay,
  type,
  message,
  messageId,
  messageValues,
  actionButtons,
  actionButtonsProps,
  iconOverride,
  testId,
  ...props
}: ToastProps) {
  const styles = useStyles()
  const { formatMessage } = useIntl()

  const Icon = notificationTypeToIcon[type]
  const iconColor = notificationTypeToColor[type]

  const onClear = usePropRef(props.onClear)

  useEffect(() => {
    if (persistent) return

    const timeout = setTimeout(onClear.current, closeDelay)

    return () => clearTimeout(timeout)
  }, [persistent, closeDelay, onClear])

  return (
    <div css={styles.root} role="alert" data-testid={testId || `toast-${type}`}>
      <div css={styles.content}>
        <div css={styles.icon}>
          {iconOverride || <Icon size={DEFAULT_ICON_SIZE} color={iconColor} />}
        </div>
        <div>
          {messageId ? <FormattedMessage id={messageId} values={messageValues} /> : message}
        </div>
      </div>
      <ButtonRow compact css={styles.buttons}>
        {actionButtonsProps ? <ToastActionButtons {...actionButtonsProps} /> : actionButtons}
        <IconButtonInline
          icon={CloseIcon}
          color="systemGrayscale70"
          size={DEFAULT_ICON_SIZE}
          accessibleLabel={formatMessage({ id: 'common.close' })}
          onClick={onClear.current}
        />
      </ButtonRow>
    </div>
  )
}
