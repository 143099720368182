// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifier } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifier'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifierFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifierFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifierToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifier'

/**
 *
 * @export
 * @interface GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions
 */
export interface GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions {
  /**
   *
   * @type {GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifier}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions
   */
  identifier: GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifier
  /**
   * description of the label
   * @type {string}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions
   */
  description: string
  /**
   * enforcement type of the label
   * @type {string}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions
   */
  enforcementType: string
  /**
   * group name of the label
   * @type {string}
   * @memberof GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions
   */
  group: string
}

/**
 * Check if a given object implements the GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions interface.
 */
export function instanceOfGetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'identifier' in value
  isInstance = isInstance && 'description' in value
  isInstance = isInstance && 'enforcementType' in value
  isInstance = isInstance && 'group' in value

  return isInstance
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSON(
  json: any
): GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions {
  return GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSONTyped(
    json,
    false
  )
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    identifier:
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifierFromJSON(
        json['identifier']
      ),
    description: json['description'],
    enforcementType: json['enforcement_type'],
    group: json['group'],
  }
}

export function GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsToJSON(
  value?: GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    identifier:
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsIdentifierToJSON(
        value.identifier
      ),
    description: value.description,
    enforcement_type: value.enforcementType,
    group: value.group,
  }
}
