// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AppealDisplayAdGroupsResponseMeta } from './AppealDisplayAdGroupsResponseMeta'
import {
  AppealDisplayAdGroupsResponseMetaFromJSON,
  AppealDisplayAdGroupsResponseMetaFromJSONTyped,
  AppealDisplayAdGroupsResponseMetaToJSON,
} from './AppealDisplayAdGroupsResponseMeta'

/**
 *
 * @export
 * @interface AppealDisplayAdGroupsResponse
 */
export interface AppealDisplayAdGroupsResponse {
  /**
   * N/A
   * @type {{ [key: string]: any; }}
   * @memberof AppealDisplayAdGroupsResponse
   */
  data: { [key: string]: any }
  /**
   *
   * @type {AppealDisplayAdGroupsResponseMeta}
   * @memberof AppealDisplayAdGroupsResponse
   */
  meta: AppealDisplayAdGroupsResponseMeta
}

/**
 * Check if a given object implements the AppealDisplayAdGroupsResponse interface.
 */
export function instanceOfAppealDisplayAdGroupsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AppealDisplayAdGroupsResponseFromJSON(json: any): AppealDisplayAdGroupsResponse {
  return AppealDisplayAdGroupsResponseFromJSONTyped(json, false)
}

export function AppealDisplayAdGroupsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppealDisplayAdGroupsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: json['data'],
    meta: AppealDisplayAdGroupsResponseMetaFromJSON(json['meta']),
  }
}

export function AppealDisplayAdGroupsResponseToJSON(
  value?: AppealDisplayAdGroupsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data,
    meta: AppealDisplayAdGroupsResponseMetaToJSON(value.meta),
  }
}
