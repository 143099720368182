import { ApprovedIcon, Color, InformationIcon, IssueIcon, SVGIconProps } from '@instacart/ids-core'
import { ComponentType } from 'react'
import { WarningIcon } from 'components/atoms'
import { NotificationType } from 'context/notifications'

export const DEFAULT_ICON_SIZE = 24

export const DEFAULT_NOTIFICATION_WIDTH = '35rem'

export const notificationTypeToColor: Record<NotificationType, Color> = {
  [NotificationType.INFO]: 'brandHighlightRegular',
  [NotificationType.SUCCESS]: 'systemSuccessRegular',
  [NotificationType.WARNING]: 'brandExpressRegular',
  [NotificationType.ERROR]: 'systemDetrimentalRegular',
}

export const notificationTypeToIcon: Record<
  NotificationType,
  ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
> = {
  [NotificationType.INFO]: InformationIcon,
  [NotificationType.SUCCESS]: ApprovedIcon,
  [NotificationType.WARNING]: WarningIcon,
  [NotificationType.ERROR]: IssueIcon,
}
