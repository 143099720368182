import { MutableRefObject, useEffect } from 'react'
import { usePropRef } from 'common/usePropRef'

const ESC_KEY = 'Escape'

type IgnoredRefs = MutableRefObject<HTMLElement | null | undefined>[]

interface UseModalCloseHandlersArgs {
  ref: MutableRefObject<HTMLElement | null | undefined>
  closeModal?: () => void
  ignoredRefs?: IgnoredRefs
}

const refNotIgnored = (event: MouseEvent | TouchEvent, ignoredRefs: IgnoredRefs) =>
  ignoredRefs.every(
    ref => !(event.target === ref.current || ref.current?.contains(event.target as Node))
  )

export function useModalCloseHandlers({ ref, ignoredRefs, ...args }: UseModalCloseHandlersArgs) {
  const closeModal = usePropRef(args.closeModal)

  useEffect(() => {
    if (!args.closeModal) return

    const clickListener = (event: MouseEvent | TouchEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (!ignoredRefs || (ignoredRefs && refNotIgnored(event, ignoredRefs)))
      ) {
        return (closeModal as MutableRefObject<() => void>).current()
      }
    }

    const keyUpListener = (event: KeyboardEvent) => {
      if (ref.current && event.key === ESC_KEY) {
        return (closeModal as MutableRefObject<() => void>).current()
      }
    }

    document.addEventListener('mousedown', clickListener)
    document.addEventListener('touchstart', clickListener)
    document.addEventListener('keyup', keyUpListener)

    return () => {
      document.removeEventListener('mousedown', clickListener)
      document.removeEventListener('touchstart', clickListener)
      document.removeEventListener('keyup', keyUpListener)
    }
  }, [ref, closeModal, ignoredRefs, args.closeModal])
}
