import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { Form, Formik } from 'formik'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { captureException } from 'common'
import { mailtoLinkFormatter } from 'common/intlUtils'
import { ReCaptchaAction } from 'common/reCaptcha/reCaptcha.hooks'
import toPx from 'common/toPx'
import useIntl from 'common/useIntl'
import { showReCaptcha } from 'common/utils'
import Checkbox from 'components/CheckboxV2'
import FormattedMessage from 'components/FormattedMessage'
import { PrimaryButton, TertiaryButton } from 'components/ids-ads'
import AccountLockedError from 'components/Login/components/AccountLockedError'
import {
  useCreateSession,
  useCreateSessionWithMultiFactor,
  useHandleRedirect,
  useSso,
} from 'components/Login/utils/hooks'
import {
  ACCOUNT_LOCKED,
  ACCOUNT_UNCONFIRMED,
  USER_UNCONFIRMED,
  CREDENTIALS,
  getLoginInitialValues,
  INVALID_CREDENTIALS,
  LoginError,
  LoginFormValues,
  LoginStep,
  MULTI_FACTOR_AUTH,
  useLoginValidator,
} from 'components/Login/utils/types'
import { BannerNotification, BannerType } from 'components/molecules/BannerNotifications'
import ButtonRow from 'components/molecules/ButtonRow'
import { InputError } from 'components/molecules/InputFields'
import { MultiFactorAuthVerification } from 'components/MultiFactorAuth/components/MultiFactorAuthVerification'
import {
  MfaFactor,
  MultiFactorAuthAction,
} from 'components/MultiFactorAuth/utils/multiFactorAuth.types'
import FormField, { FormFieldType } from 'components/organisms/FormField'
import { ReCaptchaCheckBox } from 'components/ReCaptchaCheckBox'
import { useFormikField } from 'hooks/useFormikField'
import { CARE_EMAIL } from 'landing/utils/constants'

const useStyles = () => {
  const theme = useTheme()

  return {
    contact: {
      margin: toPx`${spacing.s24} 0`,
    },
    bodyText: {
      ...theme.typography.bodyMedium2,
      fontWeight: 600,
    },
    buttonRow: css({ justifyContent: 'space-between' }),
    errorMessage: css({ paddingBottom: spacing.s12 }),
    fieldContainer: css({ width: 400 }),
    reCaptcha: css({ paddingTop: spacing.s24 }),
    rememberMe: css({ marginTop: spacing.s8 }),
  }
}

const EmailField = ({
  invalid,
  clearLoginError,
  onBlur,
}: {
  invalid: boolean
  clearLoginError: () => void
  onBlur: (value: string) => void
}) => {
  const styles = useStyles()
  const { genericFormatMessage } = useIntl()
  const [, , { setTouched }] = useFormikField('email')

  const onChange = () => {
    clearLoginError()
  }

  const handleBlur = (value: string) => {
    onBlur(value)
    setTouched(true)
  }

  return (
    <div css={styles.fieldContainer}>
      <FormField
        type={FormFieldType.TEXT}
        label="common.emailAddress"
        placeholder={genericFormatMessage('components.login.email.hint')}
        id="email"
        onChange={onChange}
        onBlur={e => handleBlur(e.target.value)}
        invalid={invalid}
        autoFocus
        data-testid="email-field"
      />
    </div>
  )
}

const PasswordField = ({
  invalid,
  disabled,
  clearLoginError,
}: {
  invalid: boolean
  disabled: boolean
  clearLoginError: () => void
}) => {
  const styles = useStyles()
  const { genericFormatMessage } = useIntl()

  const onChange = () => {
    clearLoginError()
  }

  return (
    <>
      <div css={styles.fieldContainer}>
        <FormField
          type={FormFieldType.PASSWORD}
          label="common.password"
          placeholder={genericFormatMessage('components.login.password.hint')}
          id="password"
          onChange={onChange}
          disabled={disabled}
          invalid={invalid}
          data-testid="password-field"
        />
      </div>
      {invalid && (
        <div css={styles.errorMessage}>
          <InputError
            inputId="password"
            error={genericFormatMessage('components.login.invalidCredentials')}
          />
        </div>
      )}
    </>
  )
}

const RememberMeField = ({ disabled }: { disabled: boolean }) => {
  const styles = useStyles()
  const [{ value: fieldValue }, , { setValue }] = useFormikField<boolean>({
    name: 'rememberMe',
  })

  return (
    <div css={styles.rememberMe}>
      <Checkbox
        id="rememberMe"
        checked={fieldValue}
        disabled={disabled}
        onChange={({ target: { checked } }) => setValue(checked)}
        data-testid="remember-me-field"
      >
        <FormattedMessage id="common.rememberMe" />
      </Checkbox>
    </div>
  )
}

export interface LoginFormProps {
  loginStep: LoginStep
  setLoginStep: Dispatch<SetStateAction<LoginStep>>
  redirectUrl?: string
  redirectToWindow?: boolean
}

const LoginForm = ({ redirectUrl, redirectToWindow, loginStep, setLoginStep }: LoginFormProps) => {
  const styles = useStyles()

  const [reCaptchaToken, setReCaptchaToken] = useState('')
  const [resetReCaptcha, setResetReCaptcha] = useState<() => void>(() => () => null)
  const displayReCaptcha = showReCaptcha()

  const [mfaFactor, setMfaFactor] = useState<MfaFactor>()
  const [loginError, setLoginError] = useState<LoginError>()

  const invalidCredentials = loginError === INVALID_CREDENTIALS
  const accountLocked = loginError === ACCOUNT_LOCKED
  const accountUnconfirmed = loginError === ACCOUNT_UNCONFIRMED
  const userUnconfirmed = loginError === USER_UNCONFIRMED

  const handleRedirect = useHandleRedirect(redirectUrl, redirectToWindow)
  const { isSsoRequired, loginWithSso, attemptLoginWithSso } = useSso(redirectToWindow)

  const handleResetReCaptcha = useCallback(() => {
    resetReCaptcha()
    setReCaptchaToken('')
  }, [resetReCaptcha])

  const renderCredentialsStep = useCallback(() => {
    setMfaFactor(undefined)
    setLoginStep(CREDENTIALS)
  }, [setLoginStep])

  const renderMfaStep = useCallback(
    (currentMfaFactor: MfaFactor) => {
      setMfaFactor(currentMfaFactor)
      setLoginStep(MULTI_FACTOR_AUTH)
    },
    [setLoginStep]
  )

  const onSuccess = useCallback(
    (currentMfaFactor?: MfaFactor) => {
      if (currentMfaFactor) {
        renderMfaStep(currentMfaFactor)
        return
      }

      handleRedirect()
    },
    [handleRedirect, renderMfaStep]
  )

  const { createSession, loading } = useCreateSession({
    onSuccess,
    onError: handleResetReCaptcha,
    setLoginError,
  })

  const createSessionWithMultiFactor = useCreateSessionWithMultiFactor()

  const handleSubmit = async ({ email, password, rememberMe }: LoginFormValues) => {
    if (isSsoRequired(email)) {
      loginWithSso()
      return
    }
    await createSession({ email, password, rememberMe, reCaptchaToken })
  }

  const clearLoginError = () => {
    setLoginError(undefined)
  }

  return (
    <Formik<LoginFormValues>
      validate={useLoginValidator(isSsoRequired)}
      initialValues={getLoginInitialValues()}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <div data-testid="login-form">
          {loginStep === CREDENTIALS && (
            <Form>
              {(userUnconfirmed || accountUnconfirmed) && (
                <BannerNotification
                  type={BannerType.ERROR}
                  message={{
                    header: userUnconfirmed ? (
                      <FormattedMessage id="components.login.unconfirmedError.user.header" />
                    ) : (
                      <FormattedMessage id="components.login.unconfirmedError.account.header" />
                    ),
                    message: userUnconfirmed ? (
                      <FormattedMessage id="components.login.unconfirmedError.user.message" />
                    ) : (
                      <FormattedMessage id="components.login.unconfirmedError.account.message" />
                    ),
                  }}
                />
              )}
              <div>
                <>
                  <EmailField
                    invalid={invalidCredentials}
                    clearLoginError={clearLoginError}
                    onBlur={attemptLoginWithSso}
                  />
                  <PasswordField
                    invalid={invalidCredentials}
                    disabled={
                      accountLocked ||
                      accountUnconfirmed ||
                      userUnconfirmed ||
                      isSsoRequired(values.email)
                    }
                    clearLoginError={clearLoginError}
                  />
                  <RememberMeField
                    disabled={
                      accountLocked ||
                      accountUnconfirmed ||
                      userUnconfirmed ||
                      isSsoRequired(values.email)
                    }
                  />

                  {displayReCaptcha && (
                    <div css={styles.reCaptcha}>
                      <ReCaptchaCheckBox
                        action={ReCaptchaAction.ADS_LOGIN}
                        onChange={setReCaptchaToken}
                        setResetHandler={fn => setResetReCaptcha(() => fn)}
                        onError={() => {
                          captureException(`Recaptcha failed loading for user ${values.email}`)
                        }}
                      />
                    </div>
                  )}

                  {accountLocked && <AccountLockedError />}

                  {!accountLocked && (
                    <>
                      <div css={styles.contact}>
                        <FormattedMessage
                          id="common.logIn.contactCare"
                          values={{
                            email: (
                              <a href={mailtoLinkFormatter(CARE_EMAIL)} tabIndex={-1}>
                                {CARE_EMAIL}
                              </a>
                            ),
                          }}
                        />
                      </div>

                      <ButtonRow css={styles.buttonRow}>
                        <TertiaryButton
                          type="button"
                          onClick={() => {
                            window.location.assign('/forgot')
                          }}
                          data-testid="reset-password-button"
                        >
                          <FormattedMessage id="components.login.resetPassword" />
                        </TertiaryButton>
                        <PrimaryButton
                          type="submit"
                          disabled={
                            (displayReCaptcha && !reCaptchaToken && !isSsoRequired(values.email)) ||
                            loginError !== undefined
                          }
                          loading={loading}
                          data-testid="login-form-submit-button"
                        >
                          <FormattedMessage id="common.logIn" />
                        </PrimaryButton>
                      </ButtonRow>
                    </>
                  )}
                </>
              </div>
            </Form>
          )}

          {mfaFactor && loginStep === MULTI_FACTOR_AUTH && (
            <MultiFactorAuthVerification
              action={MultiFactorAuthAction.Login}
              mfaFactor={mfaFactor}
              onSubmit={async multiFactorAuthAttempt => {
                await createSessionWithMultiFactor(multiFactorAuthAttempt, values.rememberMe)
              }}
              onSuccess={onSuccess}
              onBack={renderCredentialsStep}
            />
          )}
        </div>
      )}
    </Formik>
  )
}

export default LoginForm
