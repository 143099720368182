// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CoreIngredientsResponseData } from './CoreIngredientsResponseData'
import {
  CoreIngredientsResponseDataFromJSON,
  CoreIngredientsResponseDataFromJSONTyped,
  CoreIngredientsResponseDataToJSON,
} from './CoreIngredientsResponseData'
import type { CoreIngredientsResponseMeta } from './CoreIngredientsResponseMeta'
import {
  CoreIngredientsResponseMetaFromJSON,
  CoreIngredientsResponseMetaFromJSONTyped,
  CoreIngredientsResponseMetaToJSON,
} from './CoreIngredientsResponseMeta'

/**
 *
 * @export
 * @interface CoreIngredientsResponse
 */
export interface CoreIngredientsResponse {
  /**
   *
   * @type {Array<CoreIngredientsResponseData>}
   * @memberof CoreIngredientsResponse
   */
  data: Array<CoreIngredientsResponseData>
  /**
   *
   * @type {CoreIngredientsResponseMeta}
   * @memberof CoreIngredientsResponse
   */
  meta: CoreIngredientsResponseMeta
}

/**
 * Check if a given object implements the CoreIngredientsResponse interface.
 */
export function instanceOfCoreIngredientsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function CoreIngredientsResponseFromJSON(json: any): CoreIngredientsResponse {
  return CoreIngredientsResponseFromJSONTyped(json, false)
}

export function CoreIngredientsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CoreIngredientsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(CoreIngredientsResponseDataFromJSON),
    meta: CoreIngredientsResponseMetaFromJSON(json['meta']),
  }
}

export function CoreIngredientsResponseToJSON(value?: CoreIngredientsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(CoreIngredientsResponseDataToJSON),
    meta: CoreIngredientsResponseMetaToJSON(value.meta),
  }
}
