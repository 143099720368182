import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useIntl } from 'common'
import ifCapabilities from 'common/ifCapabilities'
import toPx from 'common/toPx'
import FeatureHintTooltip, { FeatureHintTooltipProps } from 'components/FeatureHintTooltip'
import { Badge, DefinitionText } from 'components/ids-ads'
import { GenericMessageDescriptor, MessageIdType } from 'locales/types'

function useStyles() {
  const theme = useTheme()
  return {
    tab: css({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemGrayscale50,
      padding: toPx`${spacing.s8} ${spacing.s40} ${spacing.s8 + 2}`,
      borderRadius: toPx`${theme.radius.r8} ${theme.radius.r8} 0 0`,
      outline: 'none',
      '&:visited': {
        color: 'inherit',
      },
      '&:hover': {
        color: 'inherit',
        backgroundColor: theme.colors.systemGrayscale10,
      },
      '&:active': {
        backgroundColor: theme.colors.systemGrayscale20,
      },
      '&.active': {
        ...theme.typography.bodyMedium1,
        color: theme.colors.systemGrayscale70,
        '&::after': {
          content: '" "',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -1,
          background: theme.colors.systemGrayscale70,
          height: 2,
        },
      },
      '&.focus-visible': {
        borderRadius: toPx`${theme.radius.r12} ${theme.radius.r12} ${theme.radius.r4} ${theme.radius.r4}`,
        outline: `2px solid ${theme.colors.systemGrayscale70}`,
        outlineOffset: 2,
      },
      '&.disabled': {
        pointerEvents: 'none',
        color: theme.colors.systemGrayscale30,
      },
    }),
    badge: css({
      margin: toPx`${1} ${-spacing.s8} ${1} ${6}`,
    }),
  }
}

export interface TabHeaderProps extends NavLinkProps {
  tabKey: string
  labelId: MessageIdType
  disabled?: boolean
  disabledTooltip?: MessageIdType
  capabilities?: string | string[]
  onActive?: (key: string) => void
  featureHintTooltipProps?: FeatureHintTooltipProps
  badgeMessage?: GenericMessageDescriptor
}

export function TabHeader({
  tabKey,
  labelId,
  disabled,
  disabledTooltip,
  capabilities,
  onActive,
  featureHintTooltipProps,
  badgeMessage,
  ...props
}: TabHeaderProps) {
  const styles = useStyles()
  const intl = useIntl()
  const label = intl.formatMessage({ id: labelId })
  const tooltipLabel = disabledTooltip && intl.formatMessage({ id: disabledTooltip })

  const renderFeatureHintTooltip = (element: JSX.Element) => {
    return featureHintTooltipProps ? (
      <FeatureHintTooltip {...featureHintTooltipProps}>{element}</FeatureHintTooltip>
    ) : (
      element
    )
  }

  return ifCapabilities({
    capabilities,
  })(
    renderFeatureHintTooltip(
      <DefinitionText
        hovercardStateProps={{
          visible: disabled && disabledTooltip ? undefined : false,
          placement: 'bottom',
        }}
        styles={{ anchor: { textDecoration: 'none', cursor: 'default' } }}
        definitionContent={<>{tooltipLabel}</>}
      >
        <NavLink
          css={styles.tab}
          className={classNames({ disabled })}
          data-testid={`tab-header-target-${tabKey}`}
          isActive={match => {
            const pathMatch = !!match
            if (pathMatch && onActive) {
              onActive(tabKey)
            }
            return pathMatch
          }}
          {...props}
        >
          {label}
          {badgeMessage && <Badge message={badgeMessage} css={styles.badge} />}
        </NavLink>
      </DefinitionText>
    )
  )
}
