// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockProductCollectionContent } from './PostBrandPagesIdStatusParamBlockProductCollectionContent'
import {
  PostBrandPagesIdStatusParamBlockProductCollectionContentFromJSON,
  PostBrandPagesIdStatusParamBlockProductCollectionContentFromJSONTyped,
  PostBrandPagesIdStatusParamBlockProductCollectionContentToJSON,
} from './PostBrandPagesIdStatusParamBlockProductCollectionContent'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockProductCollection
 */
export interface PostBrandPagesIdStatusParamBlockProductCollection {
  /**
   * id of the block
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockProductCollection
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockProductCollection
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockProductCollection
   */
  type: PostBrandPagesIdStatusParamBlockProductCollectionTypeEnum
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockProductCollectionContent}
   * @memberof PostBrandPagesIdStatusParamBlockProductCollection
   */
  content: PostBrandPagesIdStatusParamBlockProductCollectionContent
  /**
   * Unordered collection of products for display
   * @type {Array<string>}
   * @memberof PostBrandPagesIdStatusParamBlockProductCollection
   */
  productIds?: Array<string>
}

/**
 * @export
 */
export const PostBrandPagesIdStatusParamBlockProductCollectionTypeEnum = {
  ProductCollectionEvergreenV1: 'product_collection.evergreen.v1',
} as const
export type PostBrandPagesIdStatusParamBlockProductCollectionTypeEnum =
  typeof PostBrandPagesIdStatusParamBlockProductCollectionTypeEnum[keyof typeof PostBrandPagesIdStatusParamBlockProductCollectionTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockProductCollection interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockProductCollection(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockProductCollectionFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockProductCollection {
  return PostBrandPagesIdStatusParamBlockProductCollectionFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBlockProductCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockProductCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: PostBrandPagesIdStatusParamBlockProductCollectionContentFromJSON(json['content']),
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
  }
}

export function PostBrandPagesIdStatusParamBlockProductCollectionToJSON(
  value?: PostBrandPagesIdStatusParamBlockProductCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: PostBrandPagesIdStatusParamBlockProductCollectionContentToJSON(value.content),
    product_ids: value.productIds,
  }
}
