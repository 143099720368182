// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner'

/**
 *
 * @export
 * @interface GetAdminAccountsResponseDataAttributesAlcoholStatePermissions
 */
export interface GetAdminAccountsResponseDataAttributesAlcoholStatePermissions {
  /**
   * Featured product state permissions
   * @type {Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct>}
   * @memberof GetAdminAccountsResponseDataAttributesAlcoholStatePermissions
   */
  featuredProduct: Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProduct>
  /**
   * Hero banner state permissions
   * @type {Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner>}
   * @memberof GetAdminAccountsResponseDataAttributesAlcoholStatePermissions
   */
  heroBanner: Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner>
  /**
   * Coupon state permissions
   * @type {Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon>}
   * @memberof GetAdminAccountsResponseDataAttributesAlcoholStatePermissions
   */
  coupon: Array<GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon>
}

/**
 * Check if a given object implements the GetAdminAccountsResponseDataAttributesAlcoholStatePermissions interface.
 */
export function instanceOfGetAdminAccountsResponseDataAttributesAlcoholStatePermissions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'featuredProduct' in value
  isInstance = isInstance && 'heroBanner' in value
  isInstance = isInstance && 'coupon' in value

  return isInstance
}

export function GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSON(
  json: any
): GetAdminAccountsResponseDataAttributesAlcoholStatePermissions {
  return GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSONTyped(json, false)
}

export function GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsResponseDataAttributesAlcoholStatePermissions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    featuredProduct: (json['featured_product'] as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductFromJSON
    ),
    heroBanner: (json['hero_banner'] as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSON
    ),
    coupon: (json['coupon'] as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSON
    ),
  }
}

export function GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsToJSON(
  value?: GetAdminAccountsResponseDataAttributesAlcoholStatePermissions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    featured_product: (value.featuredProduct as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsFeaturedProductToJSON
    ),
    hero_banner: (value.heroBanner as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerToJSON
    ),
    coupon: (value.coupon as Array<any>).map(
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponToJSON
    ),
  }
}
