// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamAssetFieldDecisionsAltText } from './GetDisplayCampaignsParamAssetFieldDecisionsAltText'
import {
  GetDisplayCampaignsParamAssetFieldDecisionsAltTextFromJSON,
  GetDisplayCampaignsParamAssetFieldDecisionsAltTextFromJSONTyped,
  GetDisplayCampaignsParamAssetFieldDecisionsAltTextToJSON,
} from './GetDisplayCampaignsParamAssetFieldDecisionsAltText'
import type { GetDisplayCampaignsParamAssetFieldDecisionsImage } from './GetDisplayCampaignsParamAssetFieldDecisionsImage'
import {
  GetDisplayCampaignsParamAssetFieldDecisionsImageFromJSON,
  GetDisplayCampaignsParamAssetFieldDecisionsImageFromJSONTyped,
  GetDisplayCampaignsParamAssetFieldDecisionsImageToJSON,
} from './GetDisplayCampaignsParamAssetFieldDecisionsImage'
import type { GetDisplayCampaignsParamAssetFieldDecisionsVideo } from './GetDisplayCampaignsParamAssetFieldDecisionsVideo'
import {
  GetDisplayCampaignsParamAssetFieldDecisionsVideoFromJSON,
  GetDisplayCampaignsParamAssetFieldDecisionsVideoFromJSONTyped,
  GetDisplayCampaignsParamAssetFieldDecisionsVideoToJSON,
} from './GetDisplayCampaignsParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamAssetFieldDecisions
 */
export interface GetDisplayCampaignsParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetDisplayCampaignsParamAssetFieldDecisionsAltText}
   * @memberof GetDisplayCampaignsParamAssetFieldDecisions
   */
  altText?: GetDisplayCampaignsParamAssetFieldDecisionsAltText
  /**
   *
   * @type {GetDisplayCampaignsParamAssetFieldDecisionsImage}
   * @memberof GetDisplayCampaignsParamAssetFieldDecisions
   */
  image?: GetDisplayCampaignsParamAssetFieldDecisionsImage
  /**
   *
   * @type {GetDisplayCampaignsParamAssetFieldDecisionsVideo}
   * @memberof GetDisplayCampaignsParamAssetFieldDecisions
   */
  video?: GetDisplayCampaignsParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamAssetFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsParamAssetFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsParamAssetFieldDecisions {
  return GetDisplayCampaignsParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetDisplayCampaignsParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetDisplayCampaignsParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayCampaignsParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function GetDisplayCampaignsParamAssetFieldDecisionsToJSON(
  value?: GetDisplayCampaignsParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: GetDisplayCampaignsParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetDisplayCampaignsParamAssetFieldDecisionsImageToJSON(value.image),
    video: GetDisplayCampaignsParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
