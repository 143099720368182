// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
 */
export interface ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid {
  /**
   * Product Group IDs
   * @type {Array<string>}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
   */
  productGroupGrid: Array<string>
  /**
   * Grid order
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
   */
  gridOrder?: ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridGridOrderEnum
}

/**
 * @export
 */
export const ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridGridOrderEnum =
  {
    Fixed: 'FIXED',
    Dynamic: 'DYNAMIC',
  } as const
export type ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridGridOrderEnum =
  typeof ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridGridOrderEnum[keyof typeof ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridGridOrderEnum]

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'productGroupGrid' in value

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid {
  return ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped(
    json,
    false
  )
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productGroupGrid: json['product_group_grid'],
    gridOrder: !exists(json, 'grid_order') ? undefined : json['grid_order'],
  }
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON(
  value?: ReservationAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_group_grid: value.productGroupGrid,
    grid_order: value.gridOrder,
  }
}
