import { ReactNode, ReactText, RefObject } from 'react'
import { Keyword, UpsertAdGroupKeywordsResponse } from 'service/types'
import { MessageIdType } from '../locales/types'
import * as FLIPPER_LIST from './flippers'

// similar to keyof but for values
export type ValueOf<T> = T[keyof T]

/**
 * Construct a type with the properties of `T` where the properties in `K` are made optional.
 */
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type FlipperIdType = ValueOf<typeof FLIPPER_LIST>

// eslint-disable-next-line no-restricted-syntax
export enum SortState {
  DEFAULT = 'DEFAULT',
  ASC = 'ASC',
  DESC = 'DESC',
}

export type InsightsDateLabel =
  | 'Last week'
  | 'Last month'
  | 'Last quarter'
  | 'Last year'
  | 'Last 12 months'
  | 'Month to date'
  | 'Quarter to date'
  | 'Year to date'
  | 'Custom'

export type DateLabel =
  | 'Lifetime'
  | 'Last 24 months'
  | 'Last 12 months'
  | 'Last 3 months'
  | 'Last 30 days'
  | 'Last 7 days'
  | 'Since yesterday'
  | 'Custom'

export const DATE_LABEL_OPTIONS: DateLabel[] = [
  'Lifetime',
  'Since yesterday',
  'Last 7 days',
  'Last 30 days',
  'Last 3 months',
  'Last 12 months',
  'Custom',
]

export interface SelectedDays {
  from?: Date
  to?: Date
}

export interface TimeFrame {
  selectedLabel: DateLabel | string
  dateRange: SelectedDays
}

export interface Filters {
  timeframe: TimeFrame
  paginate: {
    currentPage: number
    totalCount: number
    totalPages: number
    offset: number
  }
  search: {
    query: string
  }
  sortState: {
    [key: string]: SortState
  }
}

export interface PageProps {
  filters: Filters
  onFilterChange: (filters: Partial<Filters>) => void
  setNavigateEventName: (name: string, navigateEventOpts?: Record<string, unknown>) => void
}

export type ApiStatus = 'initial' | 'inflight' | 'success' | 'error'

export type StatusType = 'red' | 'yellow' | 'green' | 'low_quality' | false

// Handle Update
export interface HandleUpdateParams {
  keyword: Keyword
  attribute: keyof Keyword['attributes']
  updatedValue?: string | number | boolean
  applyToAll: boolean
  inputFieldRef?: RefObject<InputFieldRef>
}

export type HandleUpdateType = (
  params: HandleUpdateParams
) => Promise<void | UpsertAdGroupKeywordsResponse>

export interface ConfirmBidModalData {
  keywords: Keyword[]
  updatedBidValue: number
  titleId: MessageIdType
  messageId: MessageIdType
  messageValues: Record<string, React.ReactNode | ((chunks: ReactNode[]) => JSX.Element)>
  confirmButtonMessageId: MessageIdType
  adGroupId?: string
}

export type InputFieldRef = {
  FormComponent: { input: HTMLInputElement }
}

export interface LocationState {
  origin?: string
  renderOverlayOnLoad?: boolean
  submittedProductIds?: Set<string>
}

// Picks keys from Base where the type extends Type
// For example, to pick all strings from MyObject:
// PickByType<MyObject, string>
export type PickByType<Base, Type> = Pick<
  Base,
  { [K in keyof Base]-?: NonNullable<Base[K]> extends Type ? K : never }[keyof Base]
>

// A Promise's resolve function
export type ResolveFn = <T>(value?: T | PromiseLike<T>) => void

// Chart data coordinate values
export type ChartDataValue = Record<'x' | 'y', ReactText>

// eslint-disable-next-line no-restricted-syntax
export enum TabKeys {
  OVERVIEW = 'overview',
  KEYWORDS = 'keywords',
  PRODUCTS = 'products',
  PRODUCT_LINES = 'product_lines',
  EMAIL = 'email',
  RECOMMENDATIONS = 'recommendations',
  DIAGNOSIS = 'diagnosis',
  LANDING = 'landing',
}
