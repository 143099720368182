// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountPaymentResponseDataAttributes } from './GetAccountPaymentResponseDataAttributes'
import {
  GetAccountPaymentResponseDataAttributesFromJSON,
  GetAccountPaymentResponseDataAttributesFromJSONTyped,
  GetAccountPaymentResponseDataAttributesToJSON,
} from './GetAccountPaymentResponseDataAttributes'

/**
 *
 * @export
 * @interface GetAccountPaymentResponseData
 */
export interface GetAccountPaymentResponseData {
  /**
   * Account ID
   * @type {string}
   * @memberof GetAccountPaymentResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAccountPaymentResponseData
   */
  type: GetAccountPaymentResponseDataTypeEnum
  /**
   *
   * @type {GetAccountPaymentResponseDataAttributes}
   * @memberof GetAccountPaymentResponseData
   */
  attributes: GetAccountPaymentResponseDataAttributes
}

/**
 * @export
 */
export const GetAccountPaymentResponseDataTypeEnum = {
  Account: 'account',
} as const
export type GetAccountPaymentResponseDataTypeEnum =
  typeof GetAccountPaymentResponseDataTypeEnum[keyof typeof GetAccountPaymentResponseDataTypeEnum]

/**
 * Check if a given object implements the GetAccountPaymentResponseData interface.
 */
export function instanceOfGetAccountPaymentResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAccountPaymentResponseDataFromJSON(json: any): GetAccountPaymentResponseData {
  return GetAccountPaymentResponseDataFromJSONTyped(json, false)
}

export function GetAccountPaymentResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountPaymentResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAccountPaymentResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetAccountPaymentResponseDataToJSON(
  value?: GetAccountPaymentResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAccountPaymentResponseDataAttributesToJSON(value.attributes),
  }
}
