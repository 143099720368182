import { css } from '@emotion/react'
import {
  CheckboxBase,
  CheckboxBaseProps,
  CheckboxBaseAriaProps,
  useTheme,
} from '@instacart/ids-core'

export type CheckboxProps = Omit<CheckboxBaseProps, 'isValid' | 'serverError' | 'hasErrors'> &
  CheckboxBaseAriaProps

const useStyles = ({ disabled }: { disabled?: boolean }) => {
  const theme = useTheme()
  return {
    wrapper: css({
      display: 'flex',
      alignItems: 'center',
    }),
    label: css({
      ...theme.typography.bodyMedium2,
      color: disabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
      flex: 1,
    }),
  }
}

const Checkbox = ({ children, checked, ...rest }: CheckboxProps) => {
  const styles = useStyles({ disabled: rest.disabled })
  return (
    <div css={styles.wrapper} data-testid={`checkbox-checked-${checked}`}>
      <CheckboxBase {...rest} checked={checked} />
      {children && (
        <label htmlFor={rest.id} css={styles.label}>
          {children}
        </label>
      )}
    </div>
  )
}

export default Checkbox
