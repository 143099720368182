import { useCallback, useMemo, useState } from 'react'

export interface Pagination {
  totalPages: number
  currentPage: number
  totalCount: number
  offset: number
  count: number
}

export interface PaginationWithPageSize extends Pagination {
  pageSize: number
}

interface CollectionResponse<T> {
  data: T[]
  meta: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

/**
 * Extract the paging related params out of a BE response. The pagination component
 * tracks the offset on FE, so this hook also tracks offset.
 *
 * @param response a collection response
 */
export default function usePagination<T>(response: CollectionResponse<T> | null) {
  const [pageSize, setPageSize] = useState(0)
  const extractPagination = useCallback(
    (collection?: CollectionResponse<T> | null) => {
      const totalPages = collection?.meta?.totalPages || 0
      const currentPage = collection?.meta?.currentPage || 1
      const totalCount = collection?.meta?.totalCount || 0
      const count = collection?.data?.length || 0
      if (currentPage === 1) {
        setPageSize(count)
      }
      // if current page is 1, page size doesn't matter.
      // otherwise, page size would have been set before
      const offset = (currentPage - 1) * pageSize + 1

      const pagination: PaginationWithPageSize = {
        totalPages,
        currentPage,
        totalCount,
        offset,
        count,
        pageSize,
      }
      return pagination
    },
    [pageSize]
  )

  return useMemo(() => extractPagination(response), [extractPagination, response])
}
