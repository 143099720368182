import {
  CheckboxSelectedIcon,
  CheckboxUnselectedIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ConfirmIcon,
  spacing,
} from '@instacart/ids-core'
import { Children, useMemo } from 'react'
import {
  components,
  IndicatorProps,
  MenuProps,
  OptionProps,
  ValueContainerProps,
} from 'react-select'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import FormattedMessage from 'components/FormattedMessage'
import { TertiaryButton } from 'components/ids-ads/molecules/buttons'
import { CommonSelectProps, DefaultOptionType } from './types'

export function DropdownIndicator<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  selectProps: { menuIsOpen },
  getStyles,
  ...props
}: IndicatorProps<OptionType, IsMulti, GroupType>) {
  return (
    <div css={getStyles('dropdownIndicator', props)}>
      <ChevronUpIcon
        size={16}
        style={{ display: menuIsOpen ? 'block' : 'none' }}
        color={props.isDisabled ? 'systemGrayscale30' : 'systemGrayscale70'}
      />
      <ChevronDownIcon
        size={16}
        style={{ display: !menuIsOpen ? 'block' : 'none' }}
        color={props.isDisabled ? 'systemGrayscale30' : 'systemGrayscale70'}
      />
    </div>
  )
}

export function Option<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({ children, ...props }: OptionProps<OptionType, IsMulti, GroupType>) {
  type InnerProps = OptionProps<OptionType, IsMulti, GroupType>['innerProps']

  const dataTestValue = props?.data?.value ? `-${props.data.value}` : ''
  // This allows us to target the options using data-testid
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const innerProps = {
    ...props.innerProps,
    'data-testid': `select-option${dataTestValue}`,
  } as InnerProps

  return (
    <components.Option {...props} innerProps={innerProps}>
      {props.isMulti &&
        (props.isSelected ? (
          <CheckboxSelectedIcon color="brandPrimaryRegular" />
        ) : (
          <CheckboxUnselectedIcon color="systemGrayscale50" />
        ))}
      {children}
      {!props.isMulti && props.isSelected && <ConfirmIcon color="brandPrimaryRegular" />}
    </components.Option>
  )
}

function ValueContainer<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({ selectProps, children, ...props }: ValueContainerProps<OptionType, IsMulti, GroupType>) {
  const childrenArray = Children.toArray(children)
  const values = childrenArray.slice(0, childrenArray.length - 1)
  const input = childrenArray[childrenArray.length - 1]

  const valuesDisplay =
    selectProps.value && values.length > 1
      ? selectProps.value
          .map((o: OptionType) => selectProps.getOptionLabel?.(o) || o.label)
          .join(', ')
      : values

  return (
    <components.ValueContainer selectProps={selectProps} {...props}>
      <div
        css={{
          flex: '0 1 auto',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        data-testid={
          selectProps['data-testid']
            ? `${selectProps?.['data-testid']}-value-container`
            : `value-container-${selectProps.isMulti ? 'multi' : 'single'}`
        }
      >
        {valuesDisplay}
      </div>
      {selectProps.isMulti && selectProps.value && values.length > 0 && selectProps.inputValue && (
        <span>{',\u00A0'}</span>
      )}
      {input}
    </components.ValueContainer>
  )
}

type MultiSelectButtonsExtraProps = Pick<CommonSelectProps, 'allowSelectAll' | 'allowClear'>

function MultiSelectButtons<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  allowSelectAll,
  allowClear,
  ...props
}: Omit<MenuProps<OptionType, IsMulti, GroupType>, 'children'> & MultiSelectButtonsExtraProps) {
  return (
    <div
      css={{
        display: 'flex',
        gap: spacing.s8,
        justifyContent: 'flex-end',
        padding: spacing.s8,
      }}
    >
      {allowSelectAll && props.options?.length > 0 && (
        <TertiaryButton
          compact
          onClick={() =>
            // This works as expected but the types in react-select don't seem to match the behaviour
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            props.selectProps.onChange?.(props.options, {
              action: 'select-option',
              option: null,
              removedValue: null,
            })
          }
        >
          <FormattedMessage id="common.selectAll" />
        </TertiaryButton>
      )}
      {allowClear && props.options?.length > 0 && (
        <TertiaryButton compact onClick={props.clearValue}>
          <FormattedMessage id="common.clear" />
        </TertiaryButton>
      )}
    </div>
  )
}

export function Menu<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  children,
  allowSelectAll,
  allowClear,
  ...props
}: MenuProps<OptionType, IsMulti, GroupType> & MultiSelectButtonsExtraProps) {
  return (
    <components.Menu {...props}>
      <>
        {children}
        {props.isMulti && (allowSelectAll || allowClear) && (
          <MultiSelectButtons allowSelectAll={allowSelectAll} allowClear={allowClear} {...props} />
        )}
      </>
    </components.Menu>
  )
}

const getMenuComponent = ({ allowSelectAll, allowClear }: MultiSelectButtonsExtraProps) =>
  function WrappedMenu<
    OptionType extends OptionTypeBase = DefaultOptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
  >(props: MenuProps<OptionType, IsMulti, GroupType>) {
    return <Menu allowSelectAll={allowSelectAll} allowClear={allowClear} {...props} />
  }

export const useSelectComponentOverrides = ({
  allowSelectAll = true,
  allowClear = true,
}: MultiSelectButtonsExtraProps) =>
  useMemo(
    () => ({
      DropdownIndicator,
      Menu: getMenuComponent({ allowSelectAll, allowClear }),
      Option,
      ValueContainer,
      // We don't want these components to render at all
      ClearIndicator: () => null,
      IndicatorSeparator: () => null,
      MultiValueRemove: () => null,
    }),
    [allowSelectAll, allowClear]
  )
