// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AdGroupKeywordsSuggestedBidsResponseDataAttributes
 */
export interface AdGroupKeywordsSuggestedBidsResponseDataAttributes {
  /**
   * Mapping of Keywords to their Suggested Bids
   * @type {{ [key: string]: any; }}
   * @memberof AdGroupKeywordsSuggestedBidsResponseDataAttributes
   */
  suggestedBids: { [key: string]: any }
  /**
   * Mapping of Keywords to their Uncompetitive Bid values
   * @type {{ [key: string]: any; }}
   * @memberof AdGroupKeywordsSuggestedBidsResponseDataAttributes
   */
  uncompetitiveBids: { [key: string]: any }
  /**
   * Mapping of Keywords to their Confident Bid values
   * @type {{ [key: string]: any; }}
   * @memberof AdGroupKeywordsSuggestedBidsResponseDataAttributes
   */
  confidentBids: { [key: string]: any }
  /**
   * Bid trace info
   * @type {Array<string>}
   * @memberof AdGroupKeywordsSuggestedBidsResponseDataAttributes
   */
  traceInfo?: Array<string>
}

/**
 * Check if a given object implements the AdGroupKeywordsSuggestedBidsResponseDataAttributes interface.
 */
export function instanceOfAdGroupKeywordsSuggestedBidsResponseDataAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'suggestedBids' in value
  isInstance = isInstance && 'uncompetitiveBids' in value
  isInstance = isInstance && 'confidentBids' in value

  return isInstance
}

export function AdGroupKeywordsSuggestedBidsResponseDataAttributesFromJSON(
  json: any
): AdGroupKeywordsSuggestedBidsResponseDataAttributes {
  return AdGroupKeywordsSuggestedBidsResponseDataAttributesFromJSONTyped(json, false)
}

export function AdGroupKeywordsSuggestedBidsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupKeywordsSuggestedBidsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    suggestedBids: json['suggested_bids'],
    uncompetitiveBids: json['uncompetitive_bids'],
    confidentBids: json['confident_bids'],
    traceInfo: !exists(json, 'trace_info') ? undefined : json['trace_info'],
  }
}

export function AdGroupKeywordsSuggestedBidsResponseDataAttributesToJSON(
  value?: AdGroupKeywordsSuggestedBidsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    suggested_bids: value.suggestedBids,
    uncompetitive_bids: value.uncompetitiveBids,
    confident_bids: value.confidentBids,
    trace_info: value.traceInfo,
  }
}
