// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BrandPageAggregatesResponseDataAttributesAnalytics
 */
export interface BrandPageAggregatesResponseDataAttributesAnalytics {
  /**
   * Number of Views
   * @type {number}
   * @memberof BrandPageAggregatesResponseDataAttributesAnalytics
   */
  pageViews?: number
  /**
   * Number of Item Detail Page Views
   * @type {number}
   * @memberof BrandPageAggregatesResponseDataAttributesAnalytics
   */
  itemDetailPageViews?: number
  /**
   * Amount of Sales
   * @type {number}
   * @memberof BrandPageAggregatesResponseDataAttributesAnalytics
   */
  sales?: number
  /**
   * Number of add to carts
   * @type {number}
   * @memberof BrandPageAggregatesResponseDataAttributesAnalytics
   */
  addToCarts?: number
}

/**
 * Check if a given object implements the BrandPageAggregatesResponseDataAttributesAnalytics interface.
 */
export function instanceOfBrandPageAggregatesResponseDataAttributesAnalytics(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function BrandPageAggregatesResponseDataAttributesAnalyticsFromJSON(
  json: any
): BrandPageAggregatesResponseDataAttributesAnalytics {
  return BrandPageAggregatesResponseDataAttributesAnalyticsFromJSONTyped(json, false)
}

export function BrandPageAggregatesResponseDataAttributesAnalyticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BrandPageAggregatesResponseDataAttributesAnalytics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pageViews: !exists(json, 'page_views') ? undefined : json['page_views'],
    itemDetailPageViews: !exists(json, 'item_detail_page_views')
      ? undefined
      : json['item_detail_page_views'],
    sales: !exists(json, 'sales') ? undefined : json['sales'],
    addToCarts: !exists(json, 'add_to_carts') ? undefined : json['add_to_carts'],
  }
}

export function BrandPageAggregatesResponseDataAttributesAnalyticsToJSON(
  value?: BrandPageAggregatesResponseDataAttributesAnalytics | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    page_views: value.pageViews,
    item_detail_page_views: value.itemDetailPageViews,
    sales: value.sales,
    add_to_carts: value.addToCarts,
  }
}
