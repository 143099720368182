import Analytics from 'common/analytics/Analytics'
import { captureException } from 'common/sentry'
import { KnowledgeOwlWidgetClient } from 'service/legacy-client/knowledgeOwlWidget'
import { ClientError, getElementByClass, getElementById } from '../utils'
import { addKnowledgeOwlAnimationStyle } from './knowledgeOwlStyleOverride'
import {
  KO_WIDGET_OPEN_EXTERNAL_ELEMENT,
  FULL_SITE_URL,
  WIDGET_WRAPPER_ID,
  TRIGGER_LINK_ID,
  KO_WINDOW_PROP,
  CONTACT_FORM_CONTAINER_ELEMENT,
  KO_WIDGET_BACK_ELEMENT,
  KO_WIDGET_CONTACT_US_ELEMENT,
  KO_WIDGET_SEARCH_CONTAINER_ELEMENT,
  KO_WIDGET_SEARCH_ELEMENT,
  KO_WIDGET_SEARCH_ARTICLE_LIST_ELEMENT,
  CONTACT_FORM_CONTAINER_APP,
  KO_WINDOW,
} from './knowledgeOwlWidget.constants'
import KoVars, { WindowKnowledgeOwl } from './knowledgeOwlWidget.types'

const configureAllArticlesButton = () => {
  // Set the url for the All Articles button
  const viewFullSiteButton = getElementByClass(KO_WIDGET_OPEN_EXTERNAL_ELEMENT)
  const viewFullSiteButtonAnchor = viewFullSiteButton?.children[0] as HTMLAnchorElement
  if (viewFullSiteButtonAnchor && viewFullSiteButtonAnchor.href !== FULL_SITE_URL) {
    viewFullSiteButtonAnchor.href = FULL_SITE_URL
  }
}

// Adapted from KnowledgeOwl Embed Code snippet
const initializeKnowledgeOwl = async () => {
  if (KoVars.initialized) {
    const koWrapper = getElementById(WIDGET_WRAPPER_ID)
    const koTrigger = getElementById(TRIGGER_LINK_ID)
    koWrapper?.parentElement?.removeChild(koWrapper)
    koTrigger?.parentElement?.removeChild(koTrigger)
  } else {
    try {
      const response = await KnowledgeOwlWidgetClient.getConfig()
      KoVars.config = response.data.attributes
    } catch (e) {
      const error = e as ClientError
      if (error && error.message) {
        captureException('Error loading KnowledgeOwl configuration.', error)
      }
      return
    }
  }

  if (KoVars.config) {
    const windowKO = window as unknown as WindowKnowledgeOwl
    let searchTimeoutId: number | null = null

    windowKO[KO_WINDOW_PROP] = windowKO[KO_WINDOW_PROP] || []
    windowKO[KO_WINDOW_PROP].push(['_setProject', KoVars.config.projectId])
    /** Oauth Token * */
    windowKO[KO_WINDOW_PROP].push(['_setToken', KoVars.config.oauthToken])
    /** Optional Custom Variables * */
    // _ko16_p.push(['_setCustomVar', 'name', 'value']);

    // Hide search container depending on contact form presence
    windowKO.addEventListener('click', () => {
      const contactForm = getElementById(CONTACT_FORM_CONTAINER_ELEMENT) as HTMLDivElement
      const searchElement = getElementById(KO_WIDGET_SEARCH_ELEMENT)
      const currentSearchCntr = getElementByClass(KO_WIDGET_SEARCH_CONTAINER_ELEMENT)

      if (contactForm && currentSearchCntr?.parentElement) {
        KoVars.searchCntr = currentSearchCntr
        KoVars.searchSiblingEl = KoVars.searchCntr?.previousSibling as HTMLDivElement
        currentSearchCntr.parentElement.removeChild(currentSearchCntr)
      }

      // Set a debounce on search keyup – when the user stops typing,
      // after a reasonable delay, track the value of the input
      searchElement?.addEventListener('keyup', event => {
        const { value } = event?.target as HTMLInputElement
        const articlesElement = getElementById(KO_WIDGET_SEARCH_ARTICLE_LIST_ELEMENT)

        if (searchTimeoutId && !value) {
          return window.clearTimeout(searchTimeoutId)
        }

        if (searchTimeoutId) {
          window.clearTimeout(searchTimeoutId)
        }

        searchTimeoutId = window.setTimeout(() => {
          Analytics.track('help_centre_widget_detail.search', {
            term: value,
            hasResults: articlesElement && articlesElement.childElementCount > 0,
          })
          searchTimeoutId = null
        }, 2000)
      })

      configureAllArticlesButton()
    })

    // Add the trigger element to the DOM, so that the click event can be attached.
    // We will proxy clicks to this element from the nav
    const koTrigger = document.createElement('div')
    koTrigger.setAttribute('data-testid', 'ko-trigger')
    koTrigger.id = TRIGGER_LINK_ID
    document.body.appendChild(koTrigger)

    const ko = document.createElement('script')
    ko.type = 'text/javascript'
    ko.async = true
    ko.src = `//${KoVars.config.domain}/javascript/ko-index?__pc=${KoVars.config.projectId}`
    document.head.appendChild(ko)

    KoVars.initialized = true
  }
}

// Check that the widget wrapper has been added to the DOM, which indicates the script has successfully loaded.
// We only warn if it is not present, as the widget wrapper ID could change.
const verifyKnowledgeOwlLoaded = () => {
  const el = getElementById(WIDGET_WRAPPER_ID)
  if (!el) {
    captureException(
      'KnowledgeOwl widget wrapper element not found. May be a problem loading KnowledgeOwl.'
    )
  }
}

const toggleElementVisibility = (visible: boolean, element: Element | null) => {
  element?.setAttribute('style', `display: ${visible ? 'block' : 'none'} !important`)
}

const toggleFooterVisibility = (visible: boolean) => {
  const contactUsElement = getElementByClass(KO_WIDGET_CONTACT_US_ELEMENT)
  const openExternalElement = getElementByClass(KO_WIDGET_OPEN_EXTERNAL_ELEMENT)

  toggleElementVisibility(visible, contactUsElement)
  toggleElementVisibility(visible, openExternalElement)
}

const handleBackButtonOnClick = () => {
  const contactFormButton = getElementById(CONTACT_FORM_CONTAINER_ELEMENT) as HTMLDivElement
  contactFormButton?.parentElement?.removeChild(contactFormButton)
  // Add search bar back in
  KoVars.searchSiblingEl?.parentNode?.insertBefore(
    KoVars.searchCntr as HTMLDivElement,
    KoVars.searchSiblingEl.nextElementSibling
  )
  // Expose the "Contact Us" / "All Articles" footer again
  toggleFooterVisibility(true)
}

const handleOnClickBackFromForm = (cleanupCb?: () => void) => {
  const element = getElementByClass(KO_WIDGET_BACK_ELEMENT)
  if (cleanupCb) {
    cleanupCb()
    element?.click()
  }
  handleBackButtonOnClick()
  toggleFooterVisibility(true)
}

const handleOpenContactUsForm = () => {
  const existingContactUsFormElement = getElementById(CONTACT_FORM_CONTAINER_APP)

  if (existingContactUsFormElement) return

  // Hide the "Contact Us" / "All Articles" footer when we're in Contact Us form
  toggleFooterVisibility(false)
}

const showKnowledgeOwl = () => {
  verifyKnowledgeOwlLoaded()

  addKnowledgeOwlAnimationStyle()

  const el = getElementById(TRIGGER_LINK_ID)
  if (el) {
    Analytics.track('help_centre_widget_detail.click')
    el.click()
  } else {
    captureException('KnowledgeOwl trigger element is missing.')
  }
}

const openKnowledgeOwlArticle = (href: string) => {
  const windowKO = window as unknown as WindowKnowledgeOwl
  const ko = windowKO[KO_WINDOW]
  if (ko && href) {
    ko.openArticle(href)
    addKnowledgeOwlAnimationStyle()
  }
}

const knowledgeOwl = {
  initializeKnowledgeOwl,
  showKnowledgeOwl,
  handleOnClickBackFromForm,
  handleOpenContactUsForm,
  openKnowledgeOwlArticle,
}

export default knowledgeOwl
