import { css } from '@emotion/react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import { CategoriesViewContext, TargetingSegmentContext } from '../hooks'
import TargetedElementsLine from './TargetedElementsLine'

const categoriesContainerStyle = css({
  display: 'flex',
  alignContent: 'center',
})

export default function TargetedCategoriesLine() {
  const { values, setFieldValue } = useFormikContext()

  const {
    categoryTree: { nodeById },
  } = useContext(CategoriesViewContext)
  const { fieldsPrefix } = useContext(TargetingSegmentContext)

  const categoriesFieldName = `${fieldsPrefix}categories`
  const selectedCategories: string[] = get(values, categoriesFieldName, [])

  return (
    <div css={categoriesContainerStyle}>
      <TargetedElementsLine
        entities={selectedCategories.map(category => ({
          name: category,
          parentDisplayName: nodeById[category]?.parent?.displayName,
        }))}
        errorMessageId="pages.displayProduct.common.targetingRule.segment.categories.validation"
        fieldName={categoriesFieldName}
        removeElementByName={categoryToRemove =>
          setFieldValue(
            categoriesFieldName,
            selectedCategories.filter(category => category !== categoryToRemove)
          )
        }
      />
    </div>
  )
}
