// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StandaloneBrandPageResponseData } from './StandaloneBrandPageResponseData'
import {
  StandaloneBrandPageResponseDataFromJSON,
  StandaloneBrandPageResponseDataFromJSONTyped,
  StandaloneBrandPageResponseDataToJSON,
} from './StandaloneBrandPageResponseData'
import type { StandaloneBrandPageResponseMeta } from './StandaloneBrandPageResponseMeta'
import {
  StandaloneBrandPageResponseMetaFromJSON,
  StandaloneBrandPageResponseMetaFromJSONTyped,
  StandaloneBrandPageResponseMetaToJSON,
} from './StandaloneBrandPageResponseMeta'

/**
 *
 * @export
 * @interface StandaloneBrandPageResponse
 */
export interface StandaloneBrandPageResponse {
  /**
   *
   * @type {StandaloneBrandPageResponseData}
   * @memberof StandaloneBrandPageResponse
   */
  data: StandaloneBrandPageResponseData
  /**
   *
   * @type {StandaloneBrandPageResponseMeta}
   * @memberof StandaloneBrandPageResponse
   */
  meta: StandaloneBrandPageResponseMeta
}

/**
 * Check if a given object implements the StandaloneBrandPageResponse interface.
 */
export function instanceOfStandaloneBrandPageResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function StandaloneBrandPageResponseFromJSON(json: any): StandaloneBrandPageResponse {
  return StandaloneBrandPageResponseFromJSONTyped(json, false)
}

export function StandaloneBrandPageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StandaloneBrandPageResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: StandaloneBrandPageResponseDataFromJSON(json['data']),
    meta: StandaloneBrandPageResponseMetaFromJSON(json['meta']),
  }
}

export function StandaloneBrandPageResponseToJSON(value?: StandaloneBrandPageResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: StandaloneBrandPageResponseDataToJSON(value.data),
    meta: StandaloneBrandPageResponseMetaToJSON(value.meta),
  }
}
