import { css } from '@emotion/react'
import useIntl from 'common/useIntl'
import { convertMinsToTimestamp } from 'common/utils'
import Card, { CardProps } from 'components/Card'
import FeatureHintTooltip from 'components/FeatureHintTooltip'
import FormattedDollar from 'components/FormattedDollar'
import FormattedMessage from 'components/FormattedMessage'
import FormattedMultiplier from 'components/FormattedMultiplier'
import FormattedNumber from 'components/FormattedNumber'
import FormattedPercentage from 'components/FormattedPercentage'
import { Tooltip } from 'components/molecules'
import { ChartCardData } from 'service/shared'

interface ChartCardsProps<T> {
  loading: boolean
  chartCardData: ChartCardData
  onCardClick?: (unit: T) => () => void
  chartUnit: T
  lineColor: string
  className?: string
  CardComponent?: React.ComponentType<React.PropsWithChildren<CardProps>>
}

const cardsCss = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
})

const ChartCards = <T extends string>({
  loading,
  chartCardData,
  chartUnit,
  onCardClick,
  lineColor,
  className,
  CardComponent = Card,
}: ChartCardsProps<T>) => {
  const intl = useIntl()

  const recordIsEmpty = !Object.values(chartCardData).reduce((acc, current) => {
    const { value } = current
    return acc + value
  }, 0)

  const formatValue = (type: string | undefined, value: number) => {
    switch (type) {
      case 'dollar':
        return <FormattedDollar>{value}</FormattedDollar>
      case 'number':
        return <FormattedNumber>{value}</FormattedNumber>
      case 'multiplier':
        return <FormattedMultiplier>{value}</FormattedMultiplier>
      case 'percent':
        return <FormattedPercentage>{value}</FormattedPercentage>
      case 'minutes':
        return <>{convertMinsToTimestamp(value)}</>
      default:
        return <FormattedMessage id="common.na" />
    }
  }
  const cardsAsList = Array.isArray(chartCardData)
    ? chartCardData
    : Object.keys(chartCardData).map(key => ({
        ...chartCardData[key],
        key,
      }))

  return (
    <div css={cardsCss} className={className} data-testid="chart-cards">
      {cardsAsList.map(({ key, type, value, i18n, isBeta, tooltip, featureToolTip }) => {
        const chartCardBody = (
          <Tooltip
            renderTooltip={() => (tooltip ? <FormattedMessage {...tooltip} /> : null)}
            variant="dark"
            disabled={!tooltip}
          >
            <CardComponent
              key={key}
              isBeta={isBeta}
              title={intl.formatMessage({ id: i18n })}
              highlightColor={lineColor}
              highlight={!recordIsEmpty && chartUnit === key}
              onClick={!onCardClick || recordIsEmpty ? undefined : onCardClick(key as T)}
              loading={loading}
            >
              {recordIsEmpty ? <FormattedMessage id="common.na" /> : formatValue(type, value)}
            </CardComponent>
          </Tooltip>
        )
        return featureToolTip ? (
          <FeatureHintTooltip
            placement="right"
            feature={featureToolTip.values?.featureName as string}
            id={featureToolTip.values?.rakeTaskId as string}
            message={featureToolTip.id}
            knowledgeOwlLink={featureToolTip.values?.learnMoreLink as string}
          >
            {chartCardBody}
          </FeatureHintTooltip>
        ) : (
          chartCardBody
        )
      })}
    </div>
  )
}

export default ChartCards
