/* eslint-disable no-restricted-syntax */
export enum MetricEnum {
  BasketPenetration = 'basket_penetration',
  CustomerPenetration = 'customer_penetration',
  DollarVolume = 'dollar_volume',
  DollarVolumeShare = 'dollar_volume_share',
  DollarVolumeYago = 'dollar_volume_yago',
  DollarVolumeYagoChange = 'dollar_volume_yago_change',
  Units = 'units',
  UnitsYago = 'units_yago',
  UnitsYagoChange = 'units_yago_change',
}

export enum DateRangeTypeEnum {
  Absolute = 'absolute',
  Relative = 'relative',
}

export enum TimespanEnum {
  Day = 'day',
  Week = 'week',
  WeekSun = 'week_sun',
  Month = 'month',
}

export enum WeekStartEnum {
  Monday = 'week',
  Sunday = 'week_sun',
}

export enum DimensionEnum {
  EntityLevel2Name = 'entity_level_2_name',
  EntityBrandName = 'entity_brand_name',
  Department = 'department',
  SuperCategory = 'super_category',
  Category = 'category',
  SubCategory = 'sub_category',
  Channel = 'channel',
  ClassifiedCode = 'classified_code',
}

export enum SortByEnum {
  ReportDate = 'report_date',
  DollarVolume = 'dollar_volume',
  Units = 'units',
  EntityLevel2Name = 'entity_level_2_name',
  EntityBrandName = 'entity_brand_name',
  Department = 'department',
  SuperCategory = 'super_category',
  Category = 'category',
  SubCategory = 'sub_category',
  Channel = 'channel',
  DollarVolumeShare = 'dollar_volume_share',
  DollarVolumeYago = 'dollar_volume_yago',
  UnitsYago = 'units_yago',
  DollarVolumeYagoChange = 'dollar_volume_yago_change',
  UnitsYagoChange = 'units_yago_change',
  CustomerPenetration = 'customer_penetration',
  BasketPenetration = 'basket_penetration',
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export enum DebugEnum {
  True = 'true',
}

export enum ReportTypeEnum {
  ReportingCenterIcipSales = 'reporting_center_icip_sales',
  InsightsAdvanced = 'insights_precomputed',
}

export enum SalesDataResponseDataHeadersTypeEnum {
  Boolean = 'boolean',
  Float = 'float',
  Integer = 'integer',
  String = 'string',
}

export enum SalesDataResponseDataHeadersFormatEnum {
  Default = 'default',
  Date = 'date',
  Dollar = 'dollar',
  Percent = 'percent',
}
