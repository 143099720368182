// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostAdGroupKeywordsParamKeywords
 */
export interface PostAdGroupKeywordsParamKeywords {
  /**
   * The keyword.
   * @type {string}
   * @memberof PostAdGroupKeywordsParamKeywords
   */
  keyword: string
  /**
   * Specify 'exact'. This field determines the type of keyword match but currently only exact is supported.
   * @type {string}
   * @memberof PostAdGroupKeywordsParamKeywords
   */
  matchingType: string
  /**
   * Specify your CPC bid for the keyword in dollars.
   * @type {number}
   * @memberof PostAdGroupKeywordsParamKeywords
   */
  cpcBid: number
}

/**
 * Check if a given object implements the PostAdGroupKeywordsParamKeywords interface.
 */
export function instanceOfPostAdGroupKeywordsParamKeywords(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keyword' in value
  isInstance = isInstance && 'matchingType' in value
  isInstance = isInstance && 'cpcBid' in value

  return isInstance
}

export function PostAdGroupKeywordsParamKeywordsFromJSON(
  json: any
): PostAdGroupKeywordsParamKeywords {
  return PostAdGroupKeywordsParamKeywordsFromJSONTyped(json, false)
}

export function PostAdGroupKeywordsParamKeywordsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdGroupKeywordsParamKeywords {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keyword: json['keyword'],
    matchingType: json['matching_type'],
    cpcBid: json['cpc_bid'],
  }
}

export function PostAdGroupKeywordsParamKeywordsToJSON(
  value?: PostAdGroupKeywordsParamKeywords | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keyword: value.keyword,
    matching_type: value.matchingType,
    cpc_bid: value.cpcBid,
  }
}
