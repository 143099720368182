import { useTheme } from '@instacart/ids-core'
import { get } from 'lodash'
import { useIntl } from 'common'
import {
  getUniqueLabelStrings,
  APPROVED_WITH_RESTRICTION_DECISION,
} from 'pages/DisplayProduct/Review/utils'
import { FieldApprovalInfo } from 'pages/DisplayProduct/types'

function useStyles({ compact }: { compact?: boolean }) {
  const theme = useTheme()

  return {
    restrictedLabel: {
      ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.brandHighlightRegular,
    },
  }
}

export interface InputApprovalInfoProps {
  approvalInfo: FieldApprovalInfo
  compact?: boolean
}

export function InputApprovalInfo({ approvalInfo, compact }: InputApprovalInfoProps) {
  const intl = useIntl()
  const styles = useStyles({ compact })

  const isApprovedWithRestriction =
    get(approvalInfo, ['decision']) === APPROVED_WITH_RESTRICTION_DECISION

  const restrictedLabels = isApprovedWithRestriction ? get(approvalInfo, ['labels']) || [] : []
  const restrictedLabelStrings = getUniqueLabelStrings(restrictedLabels, intl)

  if (!restrictedLabelStrings) return null

  return <div css={styles.restrictedLabel}>{restrictedLabelStrings}</div>
}
