import { getElementById } from '../utils'
import { KO_ANIMATION_STYLE } from './knowledgeOwlWidget.constants'

const getOverrideStyle = (headerHeight: number) => `
  @keyframes _widget-open {
    0% {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(48px, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes _widget-open {
    0% {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(48px, 0, 0);
      opacity: 1;
    }
  }
  @keyframes _widget-closed {
    0% {
      transform: translate3d(48px, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes _widget-closed {
    0% {
      transform: translate3d(48px, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
  }
  ._ko16-widget-wrapper {
    left: 0;
    top: ${headerHeight}px;
    z-index: 100;
  }
`

export const addKnowledgeOwlAnimationStyle = () => {
  const el = getElementById(KO_ANIMATION_STYLE)
  const header = getElementById('header-wrapper')
  if (!el) {
    const style = document.createElement('style')
    style.setAttribute('data-testid', 'ko-style-override')
    style.id = KO_ANIMATION_STYLE
    style.appendChild(document.createTextNode(getOverrideStyle(header?.offsetHeight || 0)))
    document.getElementsByTagName('head')[0].appendChild(style)
  }
}
