import { CSSProperties } from 'react'
import { Position } from '../common'

export interface DefaultOptionType {
  label: string
  value: string
  isDisabled?: boolean
}

export type SelectStyleVariant = 'secondary' | 'tertiary' | 'legacy'

export interface CommonSelectProps {
  position?: Position
  variant?: SelectStyleVariant
  compact?: boolean
  block?: boolean
  maxWidth?: CSSProperties['maxWidth']
  allowSelectAll?: boolean
  allowClear?: boolean
}
