import isEmpty from 'lodash/isEmpty'
import { removeLeadingString } from 'pages/AdGroupEdit/V2/adGroupEdit.utils'
import {
  DisplayAdGroupBackendData,
  DisplayAdGroupResponseDataWithType,
  DisplayAdGroupTypeEnum,
} from 'pages/DisplayProduct/DisplayAdGroup/displayAdGroup.types'
import {
  ReservationAdGroupBackendData,
  ReservationAdGroupWizardData,
} from 'pages/ReservationCampaigns/types'
import { ReservationAdGroupResponseDataTypeEnum } from 'service/openapi/__codegen__/models/ReservationAdGroupResponseData'
import {
  SmartAdGroupResponseData,
  SmartAdGroupResponseDataTypeEnum,
} from 'service/openapi/__codegen__/models/SmartAdGroupResponseData'
import { BannerFieldFormValue, BannerFieldPayloadValue } from './types'

export function isBannerFieldEmpty({ alt, mobile, desktop }: BannerFieldFormValue): boolean {
  const isAltEmpty = !alt
  const isMobileEmpty = isEmpty(mobile) || !mobile.urls.uploadedUri
  const isDesktopEmpty = isEmpty(desktop) || !desktop.urls.uploadedUri
  return isAltEmpty && isDesktopEmpty && isMobileEmpty
}

export function bannerFieldToPayload(
  { alt, mobile, desktop }: BannerFieldFormValue,
  skipErrors = false
): BannerFieldPayloadValue | never {
  if (!skipErrors && alt.length < 1) {
    throw new Error('ALT text is required.')
  }

  if (!skipErrors && !mobile.urls.uploadedUri) {
    throw new Error('A mobile banner URI is required.')
  }

  if (!skipErrors && !desktop.urls.uploadedUri) {
    throw new Error('A desktop banner URI is required.')
  }

  return {
    mobile: {
      id: mobile.id,
      altText: alt,
      previewUrl: mobile.urls.previewUrl || '',
      uploadedUri: mobile.urls.uploadedUri || '',
    },
    desktop: {
      id: desktop.id,
      altText: alt,
      previewUrl: desktop.urls.previewUrl || '',
      uploadedUri: desktop.urls.uploadedUri || '',
    },
  }
}

export function getProductTitle(productName: string, brandName: string): string {
  const strippedProductName = removeLeadingString(productName, brandName) || productName
  return strippedProductName
}

export function goalFormatToRoutePrefix(goalFormat: string): string | undefined {
  switch (goalFormat) {
    case 'GOAL_FORMAT_RESERVATION':
      return 'reservation'
    case 'GOAL_FORMAT_SHOPPABLE_VIDEO':
      return 'video'
    case 'GOAL_FORMAT_SHOPPABLE_VIDEO_RECIPE':
      return 'video'
    case 'GOAL_FORMAT_SHOPPABLE_VIDEO_URL_CLICK':
      return 'video'
    default:
      return undefined
  }
}

export type AdGroupWizardData = Partial<DisplayAdGroupResponseDataWithType> & {
  index?: string
}

export function isDisplayAdGroup(
  adGroup?:
    | DisplayAdGroupBackendData
    | AdGroupWizardData
    | ReservationAdGroupBackendData
    | ReservationAdGroupWizardData
    | SmartAdGroupResponseData
): adGroup is DisplayAdGroupBackendData | Partial<DisplayAdGroupBackendData> {
  return adGroup?.type === DisplayAdGroupTypeEnum.DisplayAdGroup
}

export function isReservationAdGroup(
  adGroup?:
    | DisplayAdGroupBackendData
    | AdGroupWizardData
    | ReservationAdGroupBackendData
    | ReservationAdGroupWizardData
    | SmartAdGroupResponseData
): adGroup is ReservationAdGroupBackendData | Partial<ReservationAdGroupBackendData> {
  return adGroup?.type === ReservationAdGroupResponseDataTypeEnum.ReservationAdGroup
}

export function isSmartAdGroup(
  adGroup?:
    | DisplayAdGroupBackendData
    | AdGroupWizardData
    | ReservationAdGroupBackendData
    | ReservationAdGroupWizardData
    | SmartAdGroupResponseData
): adGroup is SmartAdGroupResponseData {
  return (
    adGroup?.type === SmartAdGroupResponseDataTypeEnum.SmartAdGroup &&
    'id' in adGroup &&
    'attributes' in adGroup
  )
}
