import { css } from '@emotion/react'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import Head from 'components/Head'
import Page from 'components/Page'

function useStyles() {
  return {
    errorMessage: css({
      textAlign: 'center',
    }),
    h1: css({
      fontSize: '40px',
      fontWeight: 'normal',
      margin: '20% 0 50px 0',
    }),
    p: css({
      fontSize: '18px',
      fontWeight: 'normal',
    }),
  }
}

type MaintenanceProps = {
  text: string
}

const Maintenance = ({ text }: MaintenanceProps) => {
  const intl = useIntl()
  const styles = useStyles()

  return (
    <Page>
      <Head.Title>{intl.formatMessage({ id: 'pages.maintenance.h1' })}</Head.Title>

      <div css={styles.errorMessage}>
        <h1 css={styles.h1}>
          <FormattedMessage id="pages.maintenance.h1" />
        </h1>
        <p css={styles.p}>{text}</p>
      </div>
    </Page>
  )
}

export default Maintenance
