// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamAssetFieldDecisionsAltText } from './PutUniversalAdGroupsIdParamAssetFieldDecisionsAltText'
import {
  PutUniversalAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsAltTextFromJSONTyped,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsAltTextToJSON,
} from './PutUniversalAdGroupsIdParamAssetFieldDecisionsAltText'
import type { PutUniversalAdGroupsIdParamAssetFieldDecisionsImage } from './PutUniversalAdGroupsIdParamAssetFieldDecisionsImage'
import {
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSON,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageToJSON,
} from './PutUniversalAdGroupsIdParamAssetFieldDecisionsImage'
import type { PutUniversalAdGroupsIdParamAssetFieldDecisionsVideo } from './PutUniversalAdGroupsIdParamAssetFieldDecisionsVideo'
import {
  PutUniversalAdGroupsIdParamAssetFieldDecisionsVideoFromJSON,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsVideoFromJSONTyped,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsVideoToJSON,
} from './PutUniversalAdGroupsIdParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamAssetFieldDecisions
 */
export interface PutUniversalAdGroupsIdParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutUniversalAdGroupsIdParamAssetFieldDecisionsAltText}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisions
   */
  altText?: PutUniversalAdGroupsIdParamAssetFieldDecisionsAltText
  /**
   *
   * @type {PutUniversalAdGroupsIdParamAssetFieldDecisionsImage}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisions
   */
  image?: PutUniversalAdGroupsIdParamAssetFieldDecisionsImage
  /**
   *
   * @type {PutUniversalAdGroupsIdParamAssetFieldDecisionsVideo}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisions
   */
  video?: PutUniversalAdGroupsIdParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamAssetFieldDecisions interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsFromJSON(
  json: any
): PutUniversalAdGroupsIdParamAssetFieldDecisions {
  return PutUniversalAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutUniversalAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : PutUniversalAdGroupsIdParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsToJSON(
  value?: PutUniversalAdGroupsIdParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: PutUniversalAdGroupsIdParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutUniversalAdGroupsIdParamAssetFieldDecisionsImageToJSON(value.image),
    video: PutUniversalAdGroupsIdParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
