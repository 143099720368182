import { useCallback, useMemo } from 'react'
import { getApiErrorMessages } from 'common/utils'
import { useAuthContext, isProviAccount } from 'context/auth'
import usePagination from 'hooks/usePagination'
import { CampaignProductsApi } from 'service/apis/CampaignProductsApi'
import { ProductsApi } from 'service/apis/ProductsApi'
import {
  GetProductsSearchClassifiedTypeEnum,
  GetProductsSearchRequest,
} from 'service/openapi/__codegen__/apis/ProductsApi'
import { GetCampaignProductIdsResponse } from 'service/openapi/__codegen__/models/GetCampaignProductIdsResponse'
import { GetProductsResponse } from 'service/openapi/__codegen__/models/GetProductsResponse'
import { ProductsSearchAvailableResponse } from 'service/openapi/__codegen__/models/ProductsSearchAvailableResponse'
import { ProductsSearchResponse } from 'service/openapi/__codegen__/models/ProductsSearchResponse'
import { Product } from 'service/types'
import { useApiCaller, useApiFn } from './base'

type UseGetProductsParams = {
  upcs?: string[]
  isImpulse?: boolean
  /** restricts product UPC search to only return products in account’s taxonomy */
  inLibrary?: boolean
  includeBrandMappingStatus?: boolean
}

type UseGetProductsOptions = {
  afterSuccess?: (upcList: string[], products: Product[]) => void
  afterFailure?: (upcList: string[]) => void
}

export const useGetProducts = (params: UseGetProductsParams, options: UseGetProductsOptions) => {
  const { upcs, isImpulse, inLibrary, includeBrandMappingStatus } = params
  const { afterSuccess: afterSuccessRaw, afterFailure: afterFailureRaw } = options

  const getProducts = useCallback(async () => {
    if (!upcs || upcs.length === 0) return null

    return ProductsApi.postProductsUpcs({
      body: { upcs, isImpulse, inLibrary, includeBrandMappingStatus },
    })
  }, [upcs, isImpulse, inLibrary, includeBrandMappingStatus])

  const transformResponse = (res: GetProductsResponse | null) => res?.data || []

  const afterSuccess = useCallback(
    (res: GetProductsResponse | null) => {
      afterSuccessRaw?.(upcs || [], transformResponse(res))
    },
    [afterSuccessRaw, upcs]
  )

  const afterFailure = useCallback(() => {
    afterFailureRaw?.(upcs || [])
  }, [afterFailureRaw, upcs])

  const { loading, error, response } = useApiFn(getProducts, { afterSuccess, afterFailure })

  const metaErrors = response?.meta?.errors as { [key: string]: string }
  const apiError = error && getApiErrorMessages(error).join('\n')

  return useMemo(
    () => ({
      loading,
      products: transformResponse(response),
      error: apiError || metaErrors?.upcs || metaErrors?.skus || '',
    }),
    [loading, response, apiError, metaErrors]
  )
}

type UseGetProductsByProductIdParams = {
  productIds?: string[]
}

export const useGetProductsByProductId = (params: UseGetProductsByProductIdParams) => {
  const { productIds } = params

  const getProductsByProductId = useCallback(async () => {
    if (!productIds || productIds.length === 0) return null

    return ProductsApi.postProductsProductIds({ body: { productIds } })
  }, [productIds])

  const { loading, error, response } = useApiFn(getProductsByProductId)

  const metaErrors = response?.meta?.errors as { [key: string]: string }
  const apiError = error && getApiErrorMessages(error).join('\n')

  return useMemo(
    () => ({
      loading,
      products: response?.data,
      error: apiError || metaErrors?.productIds || '',
    }),
    [loading, response, apiError, metaErrors]
  )
}

type UseGetProductsByIdentifierParams = {
  identifiers?: string[]
}

export const useGetProductsByIdentifier = (params: UseGetProductsByIdentifierParams) => {
  const { identifiers } = params
  const getProductsByGroupId = isProviAccount(useAuthContext())

  const getProductsByIdentifier = useCallback(async () => {
    if (!identifiers || identifiers.length === 0) return null

    if (getProductsByGroupId) {
      return ProductsApi.postProductsGroupIds({ body: { groupIds: identifiers } })
    }
    return ProductsApi.postProductsProductIds({ body: { productIds: identifiers } })
  }, [identifiers, getProductsByGroupId])

  const { loading, error, response } = useApiFn(getProductsByIdentifier)

  const metaErrors = response?.meta?.errors as { [key: string]: string }
  const apiError = error && getApiErrorMessages(error).join('\n')

  return useMemo(
    () => ({
      loading,
      products: response?.data,
      error: apiError || metaErrors?.groupIds || metaErrors?.productIds || '',
    }),
    [loading, response, apiError, metaErrors]
  )
}

interface UseGetProductsSearchParams
  extends Pick<GetProductsSearchRequest, 'classifiedType' | 'commodityScope' | 'inLibrary'> {
  query: string
  page?: number
  classifiedType?: GetProductsSearchClassifiedTypeEnum
  isImpulse?: boolean
  isPluSearch?: boolean
  includeBrandMappingStatus?: boolean
  noSearchOnEmptyQuery?: boolean
}

type UseGetProductsSearchOptions = {
  analyticsCb?: (query: string, response: ProductsSearchResponse | null) => void
}

export const useGetProductsSearch = (
  params: UseGetProductsSearchParams,
  options: UseGetProductsSearchOptions = {}
) => {
  const {
    query,
    page,
    isImpulse,
    isPluSearch,
    classifiedType,
    commodityScope,
    inLibrary,
    includeBrandMappingStatus,
    noSearchOnEmptyQuery = false,
  } = params
  const { analyticsCb } = options

  const getProductsSearch = useCallback(() => {
    if (noSearchOnEmptyQuery && !query) {
      return Promise.resolve(null)
    }
    return ProductsApi.getProductsSearch({
      query: query || undefined,
      page: String(page || 1),
      isImpulse,
      isPluSearch,
      classifiedType,
      commodityScope,
      inLibrary,
      includeBrandMappingStatus,
    })
  }, [
    query,
    page,
    isImpulse,
    isPluSearch,
    classifiedType,
    commodityScope,
    inLibrary,
    includeBrandMappingStatus,
    noSearchOnEmptyQuery,
  ])

  const afterSuccess = useCallback(
    (res: ProductsSearchResponse | null) => {
      if (analyticsCb) {
        analyticsCb(query, res)
      }
    },
    [analyticsCb, query]
  )

  const { loading, error, response } = useApiFn(getProductsSearch, {
    afterSuccess,
  })
  const pagination = usePagination(response)

  return useMemo(
    () => ({
      loading,
      products: response?.data || [],
      pagination,
      error,
    }),
    [loading, response, pagination, error]
  )
}

export const useGetProductsByReportingEntity = (
  adsTaxonomyReportingEntityId: string,
  page: number
) => {
  const getProductsByReportingEntity = useCallback(async () => {
    if (!adsTaxonomyReportingEntityId) return null

    return ProductsApi.getProductsReportingEntitiesProducts({
      adsTaxonomyReportingEntityId,
      page: String(page || 1),
    })
  }, [adsTaxonomyReportingEntityId, page])

  const { loading, error, response } = useApiFn(getProductsByReportingEntity)
  const pagination = usePagination(response)
  return useMemo(
    () => ({
      loading,
      products: response?.data || [],
      pagination,
      error,
    }),
    [loading, response, pagination, error]
  )
}

type UseGetProductsSearchAvailableOptions = {
  afterSuccess?: (available: boolean | undefined) => void
}

export const useGetProductsSearchAvailable = (
  hasChecked?: boolean | undefined,
  options?: UseGetProductsSearchAvailableOptions
) => {
  const getProductsSearchAvailable = useCallback(async () => {
    if (typeof hasChecked === 'boolean') return null
    return ProductsApi.getProductsSearchAvailable({})
  }, [hasChecked])

  const parseResponse = (response: ProductsSearchAvailableResponse | null) => {
    if (typeof response?.available === 'boolean') {
      return response.available
    }
    return undefined
  }

  const afterSuccess = (response: ProductsSearchAvailableResponse | null) => {
    const available = parseResponse(response)
    options?.afterSuccess?.(available)
  }

  const { loading, error, response } = useApiFn(getProductsSearchAvailable, { afterSuccess })

  return useMemo(
    () => ({
      loading,
      error,
      available: parseResponse(response),
    }),
    [loading, error, response]
  )
}

export const useCampaignProducts = (campaignUuid: string, dedupeProducts: boolean) => {
  const fn = useCallback(
    () => CampaignProductsApi.getCampaignProductsIds({ campaignId: campaignUuid, dedupeProducts }),
    [campaignUuid, dedupeProducts]
  )

  const [callApi, { loading, error, response }] = useApiCaller<GetCampaignProductIdsResponse>()

  const getProducts = useCallback(() => {
    callApi(() => fn())
  }, [callApi, fn])

  return {
    error,
    loading,
    products: response?.data,
    getProducts,
  }
}
