// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReview } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReview'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState
 */
export interface PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReview}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState
   */
  finalReview?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSON(
  json: any
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState {
  return PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
    json,
    false
  )
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateToJSON(
  value?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review:
      PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON(
        value.finalReview
      ),
  }
}
