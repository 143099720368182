import { Variant, PercentIcon } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { IconInputBase } from './IconInputBase'

export interface InputPercentProps extends Omit<Variant<typeof IconInputBase>, 'component'> {}

export const InputPercent = forwardRef<HTMLInputElement, InputPercentProps>(function InputPercent(
  { id, value, ...rest },
  ref
) {
  return (
    <IconInputBase
      data-testid="input-percent"
      id={id}
      ref={ref}
      type="number"
      component={<PercentIcon data-testid="percent-icon" />}
      value={rest.readOnly ? `%${value}` : value}
      {...rest}
    />
  )
})

InputPercent.displayName = 'InputPercent'
