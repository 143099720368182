// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributes } from './ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributes'
import {
  ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSON,
  ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped,
  ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributesToJSON,
} from './ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributes'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerDuplicateAsyncInput
 */
export interface ApiDisplayAdGroupsControllerDuplicateAsyncInput {
  /**
   * The page requesting the ad group to be copied
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerDuplicateAsyncInput
   */
  pageName?: ApiDisplayAdGroupsControllerDuplicateAsyncInputPageNameEnum
  /**
   *
   * @type {ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributes}
   * @memberof ApiDisplayAdGroupsControllerDuplicateAsyncInput
   */
  overrideAttributes?: ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributes
}

/**
 * @export
 */
export const ApiDisplayAdGroupsControllerDuplicateAsyncInputPageNameEnum = {
  AdGroupDetail: 'ad_group_detail',
  CampaignDetail: 'campaign_detail',
} as const
export type ApiDisplayAdGroupsControllerDuplicateAsyncInputPageNameEnum =
  typeof ApiDisplayAdGroupsControllerDuplicateAsyncInputPageNameEnum[keyof typeof ApiDisplayAdGroupsControllerDuplicateAsyncInputPageNameEnum]

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerDuplicateAsyncInput interface.
 */
export function instanceOfApiDisplayAdGroupsControllerDuplicateAsyncInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerDuplicateAsyncInputFromJSON(
  json: any
): ApiDisplayAdGroupsControllerDuplicateAsyncInput {
  return ApiDisplayAdGroupsControllerDuplicateAsyncInputFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerDuplicateAsyncInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerDuplicateAsyncInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pageName: !exists(json, 'page_name') ? undefined : json['page_name'],
    overrideAttributes: !exists(json, 'override_attributes')
      ? undefined
      : ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSON(
          json['override_attributes']
        ),
  }
}

export function ApiDisplayAdGroupsControllerDuplicateAsyncInputToJSON(
  value?: ApiDisplayAdGroupsControllerDuplicateAsyncInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    page_name: value.pageName,
    override_attributes: ApiDisplayAdGroupsControllerDuplicateAsyncInputOverrideAttributesToJSON(
      value.overrideAttributes
    ),
  }
}
