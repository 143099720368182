// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideo } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideo'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesCreativeAssets
 */
export interface EnableDisplayAdGroupResponseDataAttributesCreativeAssets {
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageWeb?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageWeb?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageMobile?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageMobile?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  squareImageMobile?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  heroImage?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  brandLogo?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideo}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  video?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  thumbnail?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageWeb?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageMobile?: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesCreativeAssets interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesCreativeAssets {
  return EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON(
      value.imageWeb
    ),
    image_mobile: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile:
      EnableDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON(
      value.heroImage
    ),
    brand_logo: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON(
      value.brandLogo
    ),
    video: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON(
      value.thumbnail
    ),
    uvc_image_web: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON(
      value.uvcImageWeb
    ),
    uvc_image_mobile: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON(
      value.uvcImageMobile
    ),
  }
}
