import { css } from '@emotion/react'
import { LoadingGenericBase, useTheme, Theme } from '@instacart/ids-core'
import { some, filter, uniqueId } from 'lodash'
import { useMemo, useRef } from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  TooltipProps,
  DotProps,
} from 'recharts'

const defaultXKey = 'x'
const defaultYKey = 'y'
const pendingYKey = 'pending'

interface Data {
  x: string | number
  y: string | number
}

interface HighlightedData extends Data {
  fill: string
}

interface Axis<Key> {
  key?: Key
  label?: string
  labelDx?: number
  labelDy?: number
  labelFill?: string
  formatTick?: (value: number) => string
}

export interface SimpleChartProps {
  data: Data[]
  lineColor?: string
  highlightedData?: HighlightedData[]
  xAxis?: Axis<typeof defaultXKey>
  yAxis?: Axis<typeof defaultYKey>
  loading?: boolean
  lineId?: string
  border?: boolean
  fill?: boolean
  padded?: boolean
  width?: number | string
  height?: number | string
  customTooltipContent?: (tooltipProps: TooltipProps) => JSX.Element | null
  onMouseMove?: (event: OnMouseMoveProps) => void
  onMouseLeave?: () => void
}

export interface OnMouseMoveProps {
  activeTooltipIndex?: number
}

interface CustomizedDotProps extends DotProps {
  payload: Data[]
  highlightedData?: HighlightedData[]
}

function useStyles({ padded, theme }: { padded: boolean; theme: Theme }) {
  return {
    root: css({
      background: '#fff',
      borderRadius: '0 0 6px 6px',
      padding: padded ? 30 : undefined,
    }),
    bordered: css({ border: `1px solid ${theme.colors.systemGrayscale20}` }),
  }
}

function Circle({
  cx,
  cy,
  fill,
}: {
  cx: number | undefined
  cy: number | undefined
  fill: string
}) {
  return <circle cx={cx} cy={cy} r={7} fill={fill} data-testid="highlighted-data-point" />
}

/*
  This supports use case where we create either a 2 or 3 color circle when we
  have 2 or 3 highlighted data points with same coordinates for bid landscape project.
  If more than 3 points exist, we default to creating a 3 color circle for now.
*/
function CustomizedDot({ cx, cy, highlightedData, ...rest }: CustomizedDotProps) {
  if (!some(highlightedData, rest.payload)) {
    return null
  }

  const pointsToHighlight = filter(highlightedData, rest.payload) as HighlightedData[]
  const gradientId = uniqueId('gradient-')

  if (pointsToHighlight.length > 2) {
    const colorOne = css({
      stopColor: pointsToHighlight[0].fill,
    })
    const colorTwo = css({
      stopColor: pointsToHighlight[1].fill,
    })
    const colorThree = css({
      stopColor: pointsToHighlight[2].fill,
    })

    return (
      <>
        <linearGradient id={gradientId} data-testid="highlighted-data-point-gradient">
          <stop css={colorOne} offset="0%" />
          <stop css={colorOne} offset="33.33%" />
          <stop css={colorTwo} offset="33.33%" />
          <stop css={colorTwo} offset="66.66%" />
          <stop css={colorThree} offset="66.66%" />
          <stop css={colorThree} offset="100%" />
        </linearGradient>
        <Circle cx={cx} cy={cy} fill={`url(#${gradientId})`} />
      </>
    )
  }

  if (pointsToHighlight.length === 2) {
    const colorOne = css({
      stopColor: pointsToHighlight[0].fill,
    })
    const colorTwo = css({
      stopColor: pointsToHighlight[1].fill,
    })

    return (
      <>
        <linearGradient id={gradientId} data-testid="highlighted-data-point-gradient">
          <stop css={colorOne} offset="0%" />
          <stop css={colorOne} offset="50%" />
          <stop css={colorTwo} offset="50%" />
          <stop css={colorTwo} offset="100%" />
        </linearGradient>
        <Circle cx={cx} cy={cy} fill={`url(#${gradientId})`} />
      </>
    )
  }

  return <Circle cx={cx} cy={cy} fill={pointsToHighlight[0].fill} />
}

export function SimpleChart({
  data,
  lineColor,
  highlightedData,
  xAxis,
  yAxis,
  loading,
  lineId = 'chart-line',
  border = true,
  fill = true,
  padded = true,
  width = '100%',
  height = 360,
  customTooltipContent,
  onMouseMove,
  onMouseLeave,
}: SimpleChartProps) {
  const theme = useTheme()
  const styles = useStyles({ padded, theme })
  const containerRef = useRef<HTMLDivElement | null>(null)

  const lineColorToUse = lineColor || theme.colors.brandPromotionalRegular

  const uniqueIdPrefix = useMemo(() => uniqueId(lineId), [lineId])

  return (
    <div css={[styles.root, border && styles.bordered]} ref={containerRef}>
      <ResponsiveContainer width={width} height={height}>
        {loading ? (
          <LoadingGenericBase />
        ) : (
          <AreaChart
            data={data}
            margin={padded ? { top: 10, right: 30, left: 0, bottom: 0 } : undefined}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            <defs>
              <linearGradient id={uniqueIdPrefix} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={lineColorToUse} stopOpacity={0.25} />
                <stop offset="100%" stopColor={lineColorToUse} stopOpacity={0} />
              </linearGradient>
              <linearGradient id={`${uniqueIdPrefix}-no-fill`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#fff" stopOpacity={0} />
                <stop offset="100%" stopColor="fff" stopOpacity={0} />
              </linearGradient>
              <linearGradient id={`${uniqueIdPrefix}-pending`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#BDBDBD" stopOpacity={0.25} />
                <stop offset="100%" stopColor="#BDBDBD" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={xAxis?.key || defaultXKey}
              axisLine={false}
              type="number"
              domain={['dataMin', 'dataMax']}
              tickSize={8}
              tickMargin={4}
              tick={{ fontSize: 14 }}
              height={40}
              tickFormatter={xAxis?.formatTick}
              label={
                xAxis?.label && {
                  value: xAxis.label,
                  dx: xAxis.labelDx,
                  dy: xAxis.labelDy,
                  fill: xAxis.labelFill,
                  position: 'insideBottom',
                  fontWeight: 600,
                  fontSize: 14,
                }
              }
            />
            <YAxis
              dataKey={yAxis?.key || defaultYKey}
              axisLine={false}
              tickSize={0}
              tickMargin={4}
              tick={{ fontSize: 14 }}
              tickFormatter={yAxis?.formatTick}
              label={
                yAxis?.label && {
                  value: yAxis.label,
                  angle: -90,
                  dx: yAxis.labelDx,
                  dy: yAxis.labelDy,
                  fill: yAxis.labelFill,
                  position: 'insideLeft',
                  fontWeight: 600,
                  fontSize: 14,
                }
              }
            />
            <CartesianGrid vertical={false} stroke={theme.colors.systemGrayscale20} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name) => {
                const yAxisValue = yAxis?.formatTick ? yAxis?.formatTick(value as number) : value
                const yAxisLabel = yAxis?.label || name
                return [yAxisValue, yAxisLabel]
              }}
              content={customTooltipContent}
            />
            <Area
              type="linear"
              dataKey={yAxis?.key || defaultYKey}
              stroke={lineColorToUse}
              strokeWidth={2}
              fillOpacity={1}
              fill={fill ? `url(#${uniqueIdPrefix})` : `url(#${uniqueIdPrefix}-no-fill)`}
              isAnimationActive={false}
              dot={areaData => <CustomizedDot {...areaData} highlightedData={highlightedData} />}
            />
            <Area
              type="linear"
              dataKey={pendingYKey}
              stroke={lineColorToUse}
              fillOpacity={1}
              fill={`url(#${uniqueIdPrefix}-pending)`}
              strokeDasharray="3 3"
              strokeWidth={2}
              isAnimationActive={false}
            />
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  )
}
