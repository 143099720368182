// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktop } from './PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import {
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON,
} from './PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import type { PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobile } from './PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobile'
import {
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON,
} from './PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobile'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues
 */
export interface PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues {
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktop}
   * @memberof PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues
   */
  desktop?: PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktop
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobile}
   * @memberof PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues
   */
  mobile?: PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobile
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues {
  return PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
    json,
    false
  )
}

export function PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: !exists(json, 'desktop')
      ? undefined
      : PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON(
          json['desktop']
        ),
    mobile: !exists(json, 'mobile')
      ? undefined
      : PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON(
          json['mobile']
        ),
  }
}

export function PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesToJSON(
  value?: PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop:
      PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON(
        value.desktop
      ),
    mobile:
      PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON(
        value.mobile
      ),
  }
}
