import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'

interface ChartHeaderProps {
  children: React.ReactNode
  border?: boolean
  subtitle?: string
  title?: string
}
const useStyles = () => {
  const theme = useTheme()

  return {
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.systemGrayscale00,
      borderBottom: 'none',
      borderRadius: '6px 6px 0 0',
    }),

    headerBorder: css({ border: `1px solid ${theme.colors.systemGrayscale20}` }),

    titlesWrapper: css({
      display: 'flex',
      flexDirection: 'column',
      ...theme.typography.bodyLarge1,
    }),

    title: css({
      color: theme.colors.systemGrayscale70,
      fontSize: 24,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    }),

    subtitle: css({
      color: theme.colors.systemGrayscale50,
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      ...theme.typography.bodySmall2,
    }),
  }
}
const ChartHeader = ({ title, subtitle, children, border = false }: ChartHeaderProps) => {
  const styles = useStyles()

  return (
    <>
      <div css={[styles.header, border && styles.headerBorder]}>
        <div css={styles.titlesWrapper}>
          {title && <div css={styles.title}>{title}</div>}
          {subtitle && <div css={styles.subtitle}>{subtitle}</div>}
        </div>
        {children}
      </div>
    </>
  )
}

export default ChartHeader
