// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountPaymentResponseData } from './GetAccountPaymentResponseData'
import {
  GetAccountPaymentResponseDataFromJSON,
  GetAccountPaymentResponseDataFromJSONTyped,
  GetAccountPaymentResponseDataToJSON,
} from './GetAccountPaymentResponseData'
import type { GetAccountPaymentResponseMeta } from './GetAccountPaymentResponseMeta'
import {
  GetAccountPaymentResponseMetaFromJSON,
  GetAccountPaymentResponseMetaFromJSONTyped,
  GetAccountPaymentResponseMetaToJSON,
} from './GetAccountPaymentResponseMeta'

/**
 *
 * @export
 * @interface GetAccountPaymentResponse
 */
export interface GetAccountPaymentResponse {
  /**
   *
   * @type {GetAccountPaymentResponseData}
   * @memberof GetAccountPaymentResponse
   */
  data: GetAccountPaymentResponseData
  /**
   *
   * @type {GetAccountPaymentResponseMeta}
   * @memberof GetAccountPaymentResponse
   */
  meta: GetAccountPaymentResponseMeta
}

/**
 * Check if a given object implements the GetAccountPaymentResponse interface.
 */
export function instanceOfGetAccountPaymentResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetAccountPaymentResponseFromJSON(json: any): GetAccountPaymentResponse {
  return GetAccountPaymentResponseFromJSONTyped(json, false)
}

export function GetAccountPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountPaymentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetAccountPaymentResponseDataFromJSON(json['data']),
    meta: GetAccountPaymentResponseMetaFromJSON(json['meta']),
  }
}

export function GetAccountPaymentResponseToJSON(value?: GetAccountPaymentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetAccountPaymentResponseDataToJSON(value.data),
    meta: GetAccountPaymentResponseMetaToJSON(value.meta),
  }
}
