// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageHierarchy } from './PostAdminBrandPagesIdLabelParamBrandPageHierarchy'
import {
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageHierarchy'
import type { PostAdminBrandPagesIdLabelParamBrandPageTaxonomy } from './PostAdminBrandPagesIdLabelParamBrandPageTaxonomy'
import {
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageTaxonomyToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageTaxonomy'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPage
 */
export interface PostAdminBrandPagesIdLabelParamBrandPage {
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPage
   */
  type: PostAdminBrandPagesIdLabelParamBrandPageTypeEnum
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPage
   */
  discriminator: string
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageHierarchy}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPage
   */
  hierarchy: PostAdminBrandPagesIdLabelParamBrandPageHierarchy
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageTaxonomy}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPage
   */
  taxonomy?: PostAdminBrandPagesIdLabelParamBrandPageTaxonomy
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageTypeEnum = {
  BrandPage: 'BRAND_PAGE',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPage interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'hierarchy' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPage {
  return PostAdminBrandPagesIdLabelParamBrandPageFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    hierarchy: PostAdminBrandPagesIdLabelParamBrandPageHierarchyFromJSON(json['hierarchy']),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : PostAdminBrandPagesIdLabelParamBrandPageTaxonomyFromJSON(json['taxonomy']),
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    hierarchy: PostAdminBrandPagesIdLabelParamBrandPageHierarchyToJSON(value.hierarchy),
    taxonomy: PostAdminBrandPagesIdLabelParamBrandPageTaxonomyToJSON(value.taxonomy),
  }
}
