// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge } from './GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge'
import {
  GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSON,
  GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSONTyped,
  GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeToJSON,
} from './GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge'

/**
 *
 * @export
 * @interface GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation
 */
export interface GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation {
  /**
   * Number of cents representing the credit card invoice threshold
   * @type {number}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation
   */
  accountThresholdCents: number
  /**
   *
   * @type {GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation
   */
  lastAccountCharge: GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge
  /**
   * Date of next invoice
   * @type {string}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation
   */
  nextAccountChargeDate: string
}

/**
 * Check if a given object implements the GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation interface.
 */
export function instanceOfGetAccountPaymentResponseDataAttributesCreditCardPaymentInformation(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'accountThresholdCents' in value
  isInstance = isInstance && 'lastAccountCharge' in value
  isInstance = isInstance && 'nextAccountChargeDate' in value

  return isInstance
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSON(
  json: any
): GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation {
  return GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSONTyped(
    json,
    false
  )
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    accountThresholdCents: json['account_threshold_cents'],
    lastAccountCharge:
      GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSON(
        json['last_account_charge']
      ),
    nextAccountChargeDate: json['next_account_charge_date'],
  }
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationToJSON(
  value?: GetAccountPaymentResponseDataAttributesCreditCardPaymentInformation | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    account_threshold_cents: value.accountThresholdCents,
    last_account_charge:
      GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeToJSON(
        value.lastAccountCharge
      ),
    next_account_charge_date: value.nextAccountChargeDate,
  }
}
