// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppeals } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppeals'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppealsToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppeals'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamDataAttributesEditorialStates
 */
export interface PutUniversalAdGroupsIdParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  type: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  reviewState?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  entitySnapshot: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  decisionSnapshot: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppeals}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStates
   */
  appeals: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const PutUniversalAdGroupsIdParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutUniversalAdGroupsIdParamDataAttributesEditorialStatesTypeEnum =
  typeof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesTypeEnum[keyof typeof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamDataAttributesEditorialStates interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSON(
  json: any
): PutUniversalAdGroupsIdParamDataAttributesEditorialStates {
  return PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot:
      PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSON(
      json['appeals']
    ),
  }
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesToJSON(
  value?: PutUniversalAdGroupsIdParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot:
      PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
        value.decisionSnapshot
      ),
    appeals: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
