// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
 */
export interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative {
  /**
   * The id of the creative. Only needed for update
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
   */
  id?: string
  /**
   * The type of placement the creative will be placed in
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
   */
  type: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeTypeEnum
  /**
   * All properties required by creative type
   * @type {{ [key: string]: any; }}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
   */
  properties?: { [key: string]: any }
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
   */
  actions?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions
}

/**
 * @export
 */
export const GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeTypeEnum = {
  CaperImageBannerV1: 'caper_image_banner.v1',
  CaperSpotlightAdPrototypeV1: 'caper_spotlight_ad.prototype.v1',
  EmailV1: 'email.v1',
  ImageBannerV2: 'image_banner.v2',
  ImageBannerV3: 'image_banner.v3',
  MnmVideoPrototypeV1: 'mnm.video.prototype.v1',
  OccasionV1: 'occasion.v1',
  PromotedAisleV1: 'promoted_aisle.v1',
  PromotedAisleV2: 'promoted_aisle.v2',
  PromotedAisleV3: 'promoted_aisle.v3',
  PromotedAisleV4: 'promoted_aisle.v4',
  SearchBannerV1: 'search_banner.v1',
  ShoppableVideoV1: 'shoppable_video.v1',
  ShoppableVideoV2: 'shoppable_video.v2',
  ShoppableVideoV3: 'shoppable_video.v3',
  SmartV1: 'smart.v1',
  SponsoredRecipeV1: 'sponsored_recipe.v1',
  SponsoredRecipeVideoV1: 'sponsored_recipe_video.v1',
  UvcBannerV1: 'uvc_banner.v1',
} as const
export type GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeTypeEnum =
  typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeTypeEnum[keyof typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSONTyped(json, false)
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    properties: !exists(json, 'properties') ? undefined : json['properties'],
    actions: !exists(json, 'actions')
      ? undefined
      : GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSON(
          json['actions']
        ),
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    properties: value.properties,
    actions: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsToJSON(
      value.actions
    ),
  }
}
