export const getPSTTimeOffset = () => {
  const localDate = new Date('2021-01-01')
  const pstTime = new Date(
    localDate.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
  )
  // This produces -3600000 in MST, aka 1 hr
  return pstTime.getTime() - localDate.getTime()
}
