// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput
 */
export interface ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput {
  /**
   * Multi-Factor Auth type
   * @type {string}
   * @memberof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput
   */
  mfaType: ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum
  /**
   * Action requiring MFA validation
   * @type {string}
   * @memberof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput
   */
  mfaAction?: ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaActionEnum
}

/**
 * @export
 */
export const ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum = {
  Email: 'email',
} as const
export type ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum =
  typeof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum[keyof typeof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum]

/**
 * @export
 */
export const ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaActionEnum = {
  Login: 'login',
  Setup: 'setup',
  RestrictedAction: 'restricted_action',
} as const
export type ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaActionEnum =
  typeof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaActionEnum[keyof typeof ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaActionEnum]

/**
 * Check if a given object implements the ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput interface.
 */
export function instanceOfApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'mfaType' in value

  return isInstance
}

export function ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputFromJSON(
  json: any
): ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput {
  return ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputFromJSONTyped(json, false)
}

export function ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mfaType: json['mfa_type'],
    mfaAction: !exists(json, 'mfa_action') ? undefined : json['mfa_action'],
  }
}

export function ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputToJSON(
  value?: ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mfa_type: value.mfaType,
    mfa_action: value.mfaAction,
  }
}
