// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentText } from './GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentText'
import {
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentText'
import type { GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitle } from './GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitle'
import {
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent
 */
export interface GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent
   */
  title?: GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentText}
   * @memberof GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent
   */
  text?: GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfGetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent {
  return GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: GetDisplayCampaignsParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
