import { css, SerializedStyles } from '@emotion/react'
import { spacing } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import { PrimaryButtonProps, PrimaryButton, SecondaryButton } from 'components/ids-ads/deprecated'
import { ButtonRow } from 'components/molecules'
import { MessageIdType } from 'locales/types'

const styles = {
  buttonRow: css({
    marginTop: spacing.s12,
  }),
}

export interface ModalButtonsProps {
  onClose?(): void
  primaryButtonProps?: PrimaryButtonProps
  primaryLabelId?: MessageIdType
  primaryLabelValues?: Record<string, React.ReactNode>
  primaryButtonStyles?: SerializedStyles
  primaryTestId?: string
  secondaryLabelId?: MessageIdType
  secondaryTestId?: string
}

/** @deprecated Use ModalButtons from 'components/ids-ads' instead */
export default function ModalButtons({
  onClose,
  primaryButtonProps,
  primaryLabelId = 'common.submit',
  primaryLabelValues = {},
  primaryButtonStyles = css({}),
  primaryTestId = 'submit-button',
  secondaryLabelId = 'common.cancel',
  secondaryTestId = 'cancel-button',
}: ModalButtonsProps) {
  return (
    <ButtonRow right css={styles.buttonRow}>
      <SecondaryButton type="button" onClick={onClose} data-testid={secondaryTestId}>
        <FormattedMessage id={secondaryLabelId} />
      </SecondaryButton>
      <PrimaryButton
        type="button"
        {...primaryButtonProps}
        css={primaryButtonStyles}
        data-testid={primaryTestId}
      >
        <FormattedMessage id={primaryLabelId} values={primaryLabelValues} />
      </PrimaryButton>
    </ButtonRow>
  )
}
