import { css } from '@emotion/react'
import { InputBaseProps, spacing, StylesOf, useTheme } from '@instacart/ids-core'
import 'focus-visible'
import { Field, InputWrapper } from '@instacart/ids-tooling'
import { ReactNode } from 'react'
import { FormattedMessageProps } from 'components/FormattedMessage'
import { InputTextProps, Label } from 'components/ids-ads'
import { TextAreaProps } from '../TextArea'
import { InfoIcon } from './InfoIcon'
import { InputDateProps } from './InputDate'
import { InputError } from './InputError'
import { InputHint } from './InputHint'

export type InputFieldProps<T> = T & {
  label: string
  hint?: string
  error?: string
  info?: string | FormattedMessageProps
  infoDescription?: string
  isOptional?: boolean
  additionalInfo?: ReactNode
  hideLabel?: boolean
  compact?: boolean
  fullWidth?: boolean
  readOnly?: boolean
  testId?: string
}

export type InputPropType = InputDateProps | InputTextProps | TextAreaProps

export type InputFieldContainerProps<T extends InputPropType = InputDateProps> = Omit<
  InputFieldProps<T>,
  'id' | 'value'
> & {
  id: string
  value?: InputBaseProps['value'] | Date
}

const useStyles = ({
  hideLabel,
  compact,
  fullWidth,
}: Pick<InputFieldContainerProps, 'hideLabel' | 'compact' | 'fullWidth'>) => {
  const theme = useTheme()

  const inputWrapper: StylesOf<typeof InputWrapper> = {
    container: {
      display: 'block',
      marginTop: compact ? 2 : spacing.s4,
    },
  }

  return {
    labelContainer: css({
      display: 'flex',
      gap: compact ? 2 : spacing.s4,
      alignItems: 'center',
      margin: 0,
    }),
    field: css({
      display: 'block',
      width: fullWidth ? '100%' : undefined,
      label: {
        ...(compact ? theme.typography.bodySmall1 : theme.typography.bodyMedium1),
        display: hideLabel ? 'none' : 'block',
        width: 'auto',
        height: 'auto',
        margin: 0,
        textAlign: 'left',
      },
    }),
    errorContainer: css({
      marginTop: compact ? 2 : spacing.s4,
    }),
    inputWrapper,
  }
}

export function InputFieldContainer<T extends InputPropType>({
  isOptional,
  label,
  hint,
  id,
  info,
  infoDescription,
  error,
  children,
  additionalInfo,
  hideLabel,
  testId,
  compact,
  fullWidth,
}: InputFieldContainerProps<T>) {
  const styles = useStyles({ hideLabel, compact, fullWidth })

  return (
    <Field css={styles.field} data-testid={testId}>
      <div css={styles.labelContainer}>
        <Label htmlFor={id} isOptional={isOptional}>
          {label}
        </Label>
        {info && infoDescription && (
          <InfoIcon compact={compact} info={info} infoDescription={infoDescription} />
        )}
      </div>
      {hint && <InputHint compact={compact} inputId={id} hint={hint} />}
      <InputWrapper styles={styles.inputWrapper}>
        {children}
        {(error || additionalInfo) && (
          <div css={styles.errorContainer}>
            {error && <InputError compact={compact} inputId={id} error={error} />}
            {additionalInfo}
          </div>
        )}
      </InputWrapper>
    </Field>
  )
}
