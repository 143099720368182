// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGrid } from './PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGrid'
import {
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSON,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSONTyped,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGridToJSON,
} from './PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGrid'

/**
 *
 * @export
 * @interface PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions
 */
export interface PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions {
  /**
   *
   * @type {PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGrid}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions
   */
  mainItemGrid?: PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGrid
}

/**
 * Check if a given object implements the PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions interface.
 */
export function instanceOfPrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsFromJSON(
  json: any
): PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions {
  return PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
  }
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsToJSON(
  value?: PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
  }
}
