import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { ContentCell, CellText, CellImageLarge } from '@instacart/ids-tooling'
import { KeyboardEvent, ReactNode, Fragment } from 'react'
import { useIntl } from 'common'
import { constants } from 'common/constants'
import { captureException } from 'common/sentry'
import Checkbox from 'components/CheckboxV2'
import { HovercardTooltip } from 'components/ids-ads/molecules/tooltips'
import { removeLeadingString } from 'pages/AdGroupEdit/V2/adGroupEdit.utils'
import { MissingBrandHierarchyWarning } from 'pages/Products/components/MissingBrandHierarchyWarning'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { GetProductsResponseDataAttributes } from 'types/products'
import FormattedMessage from '../FormattedMessage'
import { ProductSearchItemsTypes } from './types'

interface ProductLabelProps
  extends Pick<
    GetProductsResponseDataAttributes,
    | 'classifiedType'
    | 'productImageUrl'
    | 'brandName'
    | 'productName'
    | 'productSize'
    | 'upc'
    | 'isMappedToBrand'
  > {
  id: GetProductsResponseData['id']
  // eslint-disable-next-line react/no-unused-prop-types
  itemType?: ProductSearchItemsTypes
}

const useStyles = () => {
  const theme = useTheme()
  return {
    productCell: css({
      verticalAlign: 'middle',
      '> div': { paddingLeft: '16px' },
    }),
    sizeCell: css({
      verticalAlign: 'middle',
      width: '70px',
    }),
    upcCell: css({
      verticalAlign: 'middle',
      width: '117px',
    }),
    statusCell: css({
      display: 'flex',
      alignContent: 'center',
      gap: spacing.s4,
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-end',
    }),
    addedCell: css({
      ...theme.typography.bodySmall1,
    }),
    selectedCell: css({
      ...theme.typography.bodySmall1,
      color: theme.colors.systemSuccessRegular,
      '& > svg': {
        fill: theme.colors.systemSuccessRegular,
        width: 14,
        height: 14,
      },
    }),
    infoCell: css({
      display: 'flex',
      gap: spacing.s8,
      color: theme.colors.systemGrayscale50,
      '& > span > span': {
        fontWeight: 600,
      },
    }),
  }
}

const ProductLabel = ({
  id,
  productImageUrl,
  brandName,
  productName,
  productSize,
  upc,
  itemType,
}: ProductLabelProps) => {
  const { productCell, sizeCell, upcCell } = useStyles()
  const { formatMessage } = useIntl()
  const selected = formatMessage({
    id: 'pages.displayProduct.common.productField.selected',
  })
  return (
    <Fragment key={id}>
      <ContentCell css={productCell}>
        <CellImageLarge
          src={productImageUrl || constants.images.placeholder}
          alt="img"
          onError={err => captureException('Failed to load product image', err)}
        />
        <CellText>
          <strong css={{ fontWeight: 600 }}>{brandName}</strong> {productName}
        </CellText>
      </ContentCell>
      {itemType !== 'productLine' && (
        <>
          <ContentCell css={sizeCell}>
            <CellText>{productSize}</CellText>
          </ContentCell>
          <ContentCell css={upcCell}>
            <CellText>{upc}</CellText>
          </ContentCell>
        </>
      )}
      <ContentCell css={upcCell}>
        <CellText className="selected">{selected}</CellText>
      </ContentCell>
    </Fragment>
  )
}

export interface ProductLabelPropsV2 {
  product: GetProductsResponseData
  itemType?: ProductSearchItemsTypes
  isSelected: boolean
  isPreExisting: boolean
  onClick: (propductId: string) => void
  onKeyDown: (e: KeyboardEvent) => void
  tooltipEnabled?: boolean
  tooltipContent?: ReactNode
}

const useStylesV2 = (isPreExisting: boolean, isSelected: boolean) => {
  const theme = useTheme()

  const backgroundColor = isSelected
    ? theme.colors.systemGrayscale10
    : isPreExisting
    ? theme.colors.systemGrayscale00
    : theme.colors.brandHighlightLight

  return {
    row: css({
      width: '100%',
      outline: 'none',
      backgroundColor: isSelected ? theme.colors.systemGrayscale10 : theme.colors.systemGrayscale00,
      '&:hover': {
        cursor: isPreExisting ? 'no-drop' : 'pointer',
        backgroundColor,
      },
      '&.focused, &:focus': {
        backgroundColor,
      },
    }),
    productCell: css({
      maxWidth: '100%',
      verticalAlign: 'middle',
      backgroundColor: 'inherit',
      opacity: isPreExisting ? 0.5 : 1,
    }),
    infoCell: css({
      display: 'flex',
      gap: spacing.s8,
      color: theme.colors.systemGrayscale50,
      '& > span > span': {
        fontWeight: 600,
      },
    }),
  }
}

export const ProductLabelV2 = ({
  product,
  itemType = 'product',
  isSelected,
  isPreExisting,
  onClick,
  onKeyDown,
  tooltipEnabled = false,
  tooltipContent,
}: ProductLabelPropsV2) => {
  const { row, productCell, infoCell } = useStylesV2(isPreExisting, isSelected)
  const {
    classifiedType = 'universal',
    productImageUrl,
    brandName,
    productName,
    productSize,
    isMappedToBrand,
    upc,
  } = product.attributes
  const isUniversalProduct = classifiedType === 'universal'
  const cleanedProductName = removeLeadingString(productName, brandName) || productName

  return (
    <tr
      css={row}
      role="button"
      tabIndex={-1}
      key={product.id}
      onKeyDown={onKeyDown}
      data-selected={isSelected}
      onClick={() => onClick(product.id)}
      data-testid="product-search-option"
    >
      <HovercardTooltip
        arrow
        hovercardStateProps={{
          gutter: -12, // Move the tooltip closer to the cursor. Better UI
        }}
        disabled={!tooltipEnabled}
        tooltipContent={tooltipContent}
        style={{ display: 'block' }}
      >
        <ContentCell css={productCell}>
          <Checkbox
            disabled={isPreExisting}
            id={`product-${product.id}`}
            checked={isSelected || isPreExisting}
            styles={{ container: { marginLeft: 12, marginRight: 4 } }}
          />
          <CellImageLarge
            src={productImageUrl || constants.images.placeholder}
            alt="img"
            onError={err => captureException('Failed to load product image', err)}
          />
          <div>
            <div>
              <CellText>
                <strong css={{ fontWeight: 600 }}>
                  {!isUniversalProduct && brandName} {cleanedProductName}
                </strong>
              </CellText>
            </div>
            <div css={infoCell}>
              {!isUniversalProduct && itemType === 'product' && (
                <CellText>
                  <FormattedMessage id="pages.adGroupEdit.productsTable.multipleRetailers" />
                </CellText>
              )}
              {isUniversalProduct && (
                <>
                  <CellText>
                    <FormattedMessage id="common.size" />
                    {': '}
                    <span>{productSize}</span>
                  </CellText>
                  <CellText>
                    <FormattedMessage id="common.upc" />
                    {': '}
                    {upc}
                  </CellText>
                </>
              )}
            </div>
            {isMappedToBrand === false && <MissingBrandHierarchyWarning />}
          </div>
        </ContentCell>
      </HovercardTooltip>
    </tr>
  )
}

export default ProductLabel
