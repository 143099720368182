import { Variant, DateIcon } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { IconInputBase } from 'components/ids-ads'

export interface InputDateProps
  extends Omit<Variant<typeof IconInputBase>, 'component' | 'color'> {}

// TODO - update function name when InputDate and validation added to @ids-tooling
// https://instacart.atlassian.net/browse/ADS-12626
export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(function InputDate(
  { id, ...rest },
  ref
) {
  return (
    <IconInputBase
      id={id}
      ref={ref}
      type="text"
      component={<DateIcon data-testid="date-icon" />}
      {...rest}
    />
  )
})

InputDate.displayName = 'InputDate'
