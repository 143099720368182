// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabels } from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabels'
import {
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabelsFromJSON,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabelsFromJSONTyped,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabelsToJSON,
} from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabels'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage
 */
export interface PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage
   */
  decision?: PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabels>}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage
   */
  labels?: Array<PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabels>
}

/**
 * @export
 */
export const PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageDecisionEnum =
  typeof PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageDecisionEnum[keyof typeof PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageDecisionEnum]

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage interface.
 */
export function instanceOfPutDisplayCampaignsIdParamProductAssetFieldDecisionsImage(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSON(
  json: any
): PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage {
  return PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabelsFromJSON
        ),
  }
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageToJSON(
  value?: PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageLabelsToJSON
          ),
  }
}
