// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  brandPageClick?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  urlClick?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  recipeClick?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON(
        value.urlClick
      ),
    recipe_click:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
