import { Variant, DollarIcon } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { IconInputBase } from './IconInputBase'

export interface InputCurrencyProps extends Omit<Variant<typeof IconInputBase>, 'component'> {}

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  function InputCurrency({ id, value, ...rest }, ref) {
    return (
      <IconInputBase
        id={id}
        ref={ref}
        type="number"
        component={<DollarIcon data-testid="dollar-icon" />}
        value={rest.readOnly ? `$${value}` : value}
        {...rest}
      />
    )
  }
)

InputCurrency.displayName = 'InputCurrency'
