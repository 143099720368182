import { FormatSchemaType, AssetSchemaType, ItemGridType } from './types'

export const getSchemaProperties = (
  schema: FormatSchemaType | undefined
): Record<string, unknown> | undefined => {
  return schema?.properties_schema?.properties
}

export const getSchemaPlacements = (
  schema: FormatSchemaType | undefined
): Record<string, unknown> | undefined => {
  return schema?.placements
}

export const getSchemaAssets = (
  schema: FormatSchemaType | undefined
): Record<string, AssetSchemaType> | undefined => {
  return schema?.assets
}

export const getSchemaProductPickerAction = (
  schema: FormatSchemaType | undefined
): ItemGridType | undefined => {
  return schema?.actions?.main_item_grid?.item_grid
}

export const getSchemaBrandPageAction = (
  schema: FormatSchemaType | undefined
): Record<string, unknown> | undefined => {
  return schema?.actions?.banner_click?.brand_page_click
}

export const getPlacementTargetingTypes = (schema: FormatSchemaType | undefined): string[] => {
  if (!schema?.placements || (schema?.placements && Object.keys(schema.placements).length === 0))
    return ['']
  const placements = schema.placements as Record<
    string,
    { chained_execution: { executions: { targeting_type: string }[] } }
  >
  let targetingTypes: string[] = []
  const placementNames = Object.keys(schema.placements)
  placementNames.forEach(placementName => {
    ;(
      placements[placementName].chained_execution.executions as unknown as {
        targeting_types: string[]
      }[]
    ).forEach(execution => {
      targetingTypes = targetingTypes.concat(execution.targeting_types)
    })
  })
  return targetingTypes
}
