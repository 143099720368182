import { spacing } from '@instacart/ids-core'
import { createContext, useContext } from 'react'
import { MenuInitialState, MenuStateReturn, useMenuState } from 'reakit'
import { Position } from '../common'

export interface DropdownMenuInitialState extends MenuInitialState {
  position?: Position
  open?: MenuInitialState['visible']
}

export interface DropdownMenuState extends MenuStateReturn {
  open: MenuStateReturn['visible']
}

const positionToPlacement: Record<Position, MenuInitialState['placement']> = {
  left: 'bottom-start',
  right: 'bottom-end',
}

export function useDropdownMenuState({
  gutter = spacing.s8,
  position = 'left',
  open = false,
  visible = open,
  ...menuInitialState
}: DropdownMenuInitialState = {}): DropdownMenuState {
  const menuState = useMenuState({
    gutter,
    placement: positionToPlacement[position],
    visible,
    ...menuInitialState,
  })
  return {
    ...menuState,
    open: menuState.visible,
  }
}

export const DropdownMenuContext = createContext<DropdownMenuState | null>(null)

export const useDropdownMenuContext = () => useContext(DropdownMenuContext)
