// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerProviDisplayAdGroupsInputDateRange } from './ApiReportsControllerProviDisplayAdGroupsInputDateRange'
import {
  ApiReportsControllerProviDisplayAdGroupsInputDateRangeFromJSON,
  ApiReportsControllerProviDisplayAdGroupsInputDateRangeFromJSONTyped,
  ApiReportsControllerProviDisplayAdGroupsInputDateRangeToJSON,
} from './ApiReportsControllerProviDisplayAdGroupsInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerProviDisplayAdGroupsInput
 */
export interface ApiReportsControllerProviDisplayAdGroupsInput {
  /**
   * Send this parameter with the ID of a specific campaign to narrow the data returned on your report to just data from that specific campaign. The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayAdGroupsInput
   */
  campaignId?: string
  /**
   *
   * @type {ApiReportsControllerProviDisplayAdGroupsInputDateRange}
   * @memberof ApiReportsControllerProviDisplayAdGroupsInput
   */
  dateRange?: ApiReportsControllerProviDisplayAdGroupsInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayAdGroupsInput
   */
  segment?: ApiReportsControllerProviDisplayAdGroupsInputSegmentEnum
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayAdGroupsInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayAdGroupsInput
   */
  name?: string
}

/**
 * @export
 */
export const ApiReportsControllerProviDisplayAdGroupsInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerProviDisplayAdGroupsInputSegmentEnum =
  typeof ApiReportsControllerProviDisplayAdGroupsInputSegmentEnum[keyof typeof ApiReportsControllerProviDisplayAdGroupsInputSegmentEnum]

/**
 * Check if a given object implements the ApiReportsControllerProviDisplayAdGroupsInput interface.
 */
export function instanceOfApiReportsControllerProviDisplayAdGroupsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerProviDisplayAdGroupsInputFromJSON(
  json: any
): ApiReportsControllerProviDisplayAdGroupsInput {
  return ApiReportsControllerProviDisplayAdGroupsInputFromJSONTyped(json, false)
}

export function ApiReportsControllerProviDisplayAdGroupsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerProviDisplayAdGroupsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerProviDisplayAdGroupsInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function ApiReportsControllerProviDisplayAdGroupsInputToJSON(
  value?: ApiReportsControllerProviDisplayAdGroupsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaign_id: value.campaignId,
    date_range: ApiReportsControllerProviDisplayAdGroupsInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    attribution_model: value.attributionModel,
    name: value.name,
  }
}
