// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInput
 */
export interface ApiDisplayAdGroupsControllerCreateInput {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup}
   * @memberof ApiDisplayAdGroupsControllerCreateInput
   */
  displayAdGroup: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
  /**
   * Specify whether or not to validate all the fields of an ad group. Used for saving an ad group without all the fields.
   * @type {boolean}
   * @memberof ApiDisplayAdGroupsControllerCreateInput
   */
  skipValidation?: boolean
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInput interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'displayAdGroup' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInput {
  return ApiDisplayAdGroupsControllerCreateInputFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    displayAdGroup: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSON(
      json['display_ad_group']
    ),
    skipValidation: !exists(json, 'skip_validation') ? undefined : json['skip_validation'],
  }
}

export function ApiDisplayAdGroupsControllerCreateInputToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    display_ad_group: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupToJSON(
      value.displayAdGroup
    ),
    skip_validation: value.skipValidation,
  }
}
