// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRange } from './ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRange'
import {
  ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRangeFromJSON,
  ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRangeFromJSONTyped,
  ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRangeToJSON,
} from './ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerImpressionAndClicksSponsoredProductInput
 */
export interface ApiReportsControllerImpressionAndClicksSponsoredProductInput {
  /**
   *
   * @type {ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRange}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  dateRange?: ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRange
  /**
   * Optionally slice by platform for the report. No platform breakdown by default.
   * @type {boolean}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  includePlatform?: boolean
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  name?: string
  /**
   * Optionally, provide a list of campaigns to generate the report for. If empty or not provided, generates for all campaigns.
   * @type {Array<string>}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  campaignUuids?: Array<string>
  /**
   * Optionallly, specify if campaigns with no data should be included. Default is included.
   * @type {boolean}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  includeNoDataCampaigns?: boolean
  /**
   * Specify export type of report, either CSV or PDF. Default is CSV.
   * @type {string}
   * @memberof ApiReportsControllerImpressionAndClicksSponsoredProductInput
   */
  exportFiletype?: ApiReportsControllerImpressionAndClicksSponsoredProductInputExportFiletypeEnum
}

/**
 * @export
 */
export const ApiReportsControllerImpressionAndClicksSponsoredProductInputExportFiletypeEnum = {
  Csv: 'csv',
  Pdf: 'pdf',
} as const
export type ApiReportsControllerImpressionAndClicksSponsoredProductInputExportFiletypeEnum =
  typeof ApiReportsControllerImpressionAndClicksSponsoredProductInputExportFiletypeEnum[keyof typeof ApiReportsControllerImpressionAndClicksSponsoredProductInputExportFiletypeEnum]

/**
 * Check if a given object implements the ApiReportsControllerImpressionAndClicksSponsoredProductInput interface.
 */
export function instanceOfApiReportsControllerImpressionAndClicksSponsoredProductInput(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerImpressionAndClicksSponsoredProductInputFromJSON(
  json: any
): ApiReportsControllerImpressionAndClicksSponsoredProductInput {
  return ApiReportsControllerImpressionAndClicksSponsoredProductInputFromJSONTyped(json, false)
}

export function ApiReportsControllerImpressionAndClicksSponsoredProductInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerImpressionAndClicksSponsoredProductInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRangeFromJSON(
          json['date_range']
        ),
    includePlatform: !exists(json, 'include_platform') ? undefined : json['include_platform'],
    name: !exists(json, 'name') ? undefined : json['name'],
    campaignUuids: !exists(json, 'campaign_uuids') ? undefined : json['campaign_uuids'],
    includeNoDataCampaigns: !exists(json, 'include_no_data_campaigns')
      ? undefined
      : json['include_no_data_campaigns'],
    exportFiletype: !exists(json, 'export_filetype') ? undefined : json['export_filetype'],
  }
}

export function ApiReportsControllerImpressionAndClicksSponsoredProductInputToJSON(
  value?: ApiReportsControllerImpressionAndClicksSponsoredProductInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerImpressionAndClicksSponsoredProductInputDateRangeToJSON(
      value.dateRange
    ),
    include_platform: value.includePlatform,
    name: value.name,
    campaign_uuids: value.campaignUuids,
    include_no_data_campaigns: value.includeNoDataCampaigns,
    export_filetype: value.exportFiletype,
  }
}
