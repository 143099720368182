import { useIntl } from 'common'
import { useModalState } from 'components/ids-ads'
import { BannerNotification } from './BannerNotification'
import { GroupedBannerDetailsDrawer } from './components'
import {
  GroupedBannerNotificationProps,
  BannerType,
  BannerNotificationProps,
  bannerTypeToMessage,
  useGroupedBannerNotifications,
  useBannerMap,
} from './utils'

export function GroupedBannerNotification({
  type,
  groupedBanners,
}: GroupedBannerNotificationProps) {
  const modalState = useModalState()
  const { formatMessage } = useIntl()

  const { banners, dismissBanner, dismissAll } = useGroupedBannerNotifications(groupedBanners)
  const GroupedBanner = () => {
    if (banners.length === 0) return null
    if (banners.length === 1) return <BannerNotification {...banners[0]} />

    return (
      <BannerNotification
        type={type}
        message={{
          message: formatMessage(
            {
              id: bannerTypeToMessage[type],
            },
            {
              numOfBanners: banners.length,
            }
          ),
        }}
        actionButtonsProps={{
          primaryButtonLabel: 'bannerNotification.grouped.viewAll',
          primaryButtonProps: {
            onClick: modalState.show,
            'data-testid': `grouped-banner-${type}-view-all`,
          },
        }}
        testId={`grouped-banner-${type}`}
      />
    )
  }

  return (
    <>
      <GroupedBanner />
      <GroupedBannerDetailsDrawer
        modalState={modalState}
        groupedBannerDetails={{ type, groupedBanners: banners }}
        dismissBanner={dismissBanner}
        dismissAll={dismissAll}
      />
    </>
  )
}

export function GroupedBannerNotifications({ banners }: { banners: BannerNotificationProps[] }) {
  const bannerMap = useBannerMap(banners)

  if (!bannerMap) return null

  const notifications = Object.keys(bannerMap).map(key => {
    const bannerType = key as BannerType
    if (bannerMap[bannerType].length > 0) {
      return (
        <GroupedBannerNotification
          key={key}
          type={bannerType}
          groupedBanners={bannerMap[bannerType]}
        />
      )
    }
    return null
  })
  return <>{notifications}</>
}
