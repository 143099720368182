import { LoadingLockupIconRow } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { isProviAccount, useAuthContext } from 'context'
import { useApiFn } from 'hooks/api/base'
import useIsInitialRender from 'hooks/useIsInitialRender'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { TargetingOptionsResponseFromJSON } from 'service/openapi/__codegen__/models/TargetingOptionsResponse'
import { getTargetingSegmentsFieldName, SegmentType } from './constants'
import { TargetingRuleContext, useIsTargetingV2Enabled } from './hooks'
import { TargetingFormData } from './types'
import TargetingRuleV1 from './V1/TargetingRule'
import TargetingRuleV2 from './V2/TargetingRule'

export interface TargetingRuleWrapperProps {
  isReadOnly?: boolean
  isReviewing?: boolean
  creativeType?: CreativeTypeEnum
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetingOptionsCallback: () => Promise<any>
}

/*
  This wrapper component:
  - Fetches and prepares data that are not provided by the main form page through props (i.e. APIs specific to targeting).
  - Detects which targeting version to render.
  - Hence, it isolates the targeting components from the different data sources.
*/
export default function TargetingRuleWrapper({
  isReadOnly = false,
  isReviewing = false,
  creativeType,
  targetingOptionsCallback,
}: TargetingRuleWrapperProps) {
  const isProvi = isProviAccount(useAuthContext())
  const { loading, response } = useApiFn(targetingOptionsCallback, { throwError: true })
  const responseJson = TargetingOptionsResponseFromJSON(response || {})

  const {
    setFieldValue,
    values: { isLegacyTargetingRule },
  } = useFormikContext<TargetingFormData>()

  const isInitialRender = useIsInitialRender()

  const isTargetingV2Enabled = useIsTargetingV2Enabled(creativeType)
  const shouldShowTargetingV2 = isTargetingV2Enabled && !isLegacyTargetingRule
  const shouldShowTargetingV1InReadOnlyMode = isTargetingV2Enabled && isLegacyTargetingRule

  useEffect(() => {
    /*
    Clear up targeting data whenever targeting version is changed.
    i.e. when user switches between 2 creative types that differs in their manual targeting support.
    The isReviewing/isReadOnly are just in case checks.
    */
    if (!isReviewing && !isReadOnly && !isInitialRender) {
      setFieldValue(getTargetingSegmentsFieldName(SegmentType.PURCHASE), [])
      setFieldValue(getTargetingSegmentsFieldName(SegmentType.INTEREST), [])
      setFieldValue('includeNewCustomers', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowTargetingV2])

  if (loading) {
    return <LoadingLockupIconRow />
  }

  return (
    <TargetingRuleContext.Provider
      value={{
        isReadOnly: isReadOnly || shouldShowTargetingV1InReadOnlyMode,
        isReviewing,
        categories: responseJson?.data?.attributes?.categoriesList || [],
        brands: responseJson?.data?.attributes?.brandsList?.brands || [],
        locations: responseJson?.data?.attributes?.geosList?.geos || [],
        isProductsTargetingAllowed: shouldShowTargetingV2 && !isProvi,
      }}
    >
      {shouldShowTargetingV2 ? <TargetingRuleV2 /> : <TargetingRuleV1 />}
    </TargetingRuleContext.Provider>
  )
}
