// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ShowReportResponseData } from './ShowReportResponseData'
import {
  ShowReportResponseDataFromJSON,
  ShowReportResponseDataFromJSONTyped,
  ShowReportResponseDataToJSON,
} from './ShowReportResponseData'
import type { ShowReportResponseMeta } from './ShowReportResponseMeta'
import {
  ShowReportResponseMetaFromJSON,
  ShowReportResponseMetaFromJSONTyped,
  ShowReportResponseMetaToJSON,
} from './ShowReportResponseMeta'

/**
 *
 * @export
 * @interface ShowReportResponse
 */
export interface ShowReportResponse {
  /**
   *
   * @type {ShowReportResponseData}
   * @memberof ShowReportResponse
   */
  data: ShowReportResponseData
  /**
   *
   * @type {ShowReportResponseMeta}
   * @memberof ShowReportResponse
   */
  meta: ShowReportResponseMeta
}

/**
 * Check if a given object implements the ShowReportResponse interface.
 */
export function instanceOfShowReportResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function ShowReportResponseFromJSON(json: any): ShowReportResponse {
  return ShowReportResponseFromJSONTyped(json, false)
}

export function ShowReportResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowReportResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: ShowReportResponseDataFromJSON(json['data']),
    meta: ShowReportResponseMetaFromJSON(json['meta']),
  }
}

export function ShowReportResponseToJSON(value?: ShowReportResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ShowReportResponseDataToJSON(value.data),
    meta: ShowReportResponseMetaToJSON(value.meta),
  }
}
