// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks } from './PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks'
import {
  PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSON,
  PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSONTyped,
  PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksToJSON,
} from './PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage
 */
export interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage {
  /**
   * Title of the page.
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage
   */
  type: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum
  /**
   * Array of page blocks and their content.
   * @type {Array<PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks>}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage
   */
  blocks: Array<PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks>
}

/**
 * @export
 */
export const ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum = {
  DisplayLandingPageV1: 'display_landing_page.v1',
} as const
export type ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum =
  typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum[keyof typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum]

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'blocks' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage {
  return ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    type: json['type'],
    blocks: (json['blocks'] as Array<any>).map(
      PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSON
    ),
  }
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    type: value.type,
    blocks: (value.blocks as Array<any>).map(
      PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksToJSON
    ),
  }
}
