import { css } from '@emotion/react'
import { Text } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import BrandsView from 'components/organisms/TargetingRule/components/BrandsView'
import CategoriesView from 'components/organisms/TargetingRule/components/CategoriesView'
import CustomDropdown from 'components/organisms/TargetingRule/components/CustomDropdown'
import ProductsView from 'components/organisms/TargetingRule/components/ProductsView'
import {
  getTargetingSegmentsFieldName,
  SegmentType,
  shortcutSubjectOptions,
  Subject,
  Condition,
  timelineOptions,
} from 'components/organisms/TargetingRule/constants'
import {
  TargetingRuleContext,
  TargetingSegmentContext,
} from 'components/organisms/TargetingRule/hooks'
import { TargetingFormData } from 'components/organisms/TargetingRule/types'
import { isProviAccount, useAuthContext } from 'context'

const labelTextStyle = {
  paddingTop: 4,
  marginRight: 4,
  marginLeft: 4,
  display: 'block',
}

const segmentDivStyle = css({
  display: 'flex',
  alignContent: 'center',
})

const segmentChildDivStyle = css({
  paddingTop: 16,
  display: 'flex',
  alignContent: 'center',
})
const segmentContentDivStyle = css({ width: '95%' })
export const lapsedBuyerShortcutName = 'TARGETING_SHORTCUT_LAPSED_BUYER'

const LapsedBuyer = () => {
  const { brands } = useContext(TargetingRuleContext)
  const isProvi = isProviAccount(useAuthContext())
  const segmentType = SegmentType.PURCHASE
  const segmentsFieldName = getTargetingSegmentsFieldName(segmentType)
  const { values, setFieldValue } = useFormikContext<TargetingFormData>()
  const subject = get(values, `purchaseSegments[0].subject`) || Subject.BRANDS

  const [subjectOptions, setSubjectOptions] = useState([])
  useEffect(() => {
    setSubjectOptions(get(values.purchaseSegments[0], `${subject.toLowerCase()}`))
  }, [values.purchaseSegments, subject, setSubjectOptions])

  useEffect(() => {
    setFieldValue('purchaseSegments[0].subject', subject)
    setFieldValue('purchaseSegments[1].subject', subject)
    setFieldValue('purchaseSegments[1].condition', Condition.NEGATIVE)
    setFieldValue(`purchaseSegments[1].${subject.toLowerCase()}`, subjectOptions)
  }, [setFieldValue, subject, subjectOptions])

  return (
    <>
      <div css={segmentDivStyle}>
        <div css={segmentContentDivStyle}>
          <div css={segmentChildDivStyle}>
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.first.inputsPrefix" />
            </Text>
            <Text typography="subtitleMedium" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.condition.purchase.positive" />
            </Text>
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.purchased.subjects.label" />
            </Text>
            <CustomDropdown
              fieldName="purchaseSegments[0].subject"
              options={shortcutSubjectOptions({
                isCategoriesAllowed: true,
                isBrandsTargetingAllowed: !!brands.length,
                isProductsTargetingAllowed: !isProvi,
              })}
            />
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.timeline.label" />
            </Text>
            <CustomDropdown fieldName="purchaseSegments[0].timeline" options={timelineOptions} />
          </div>
          <div css={segmentChildDivStyle}>
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.second.inputsPrefix" />
            </Text>
            <Text typography="subtitleMedium" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.condition.purchase.negative" />
            </Text>
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.purchased.subjects.label" />
            </Text>
            <Text typography="bodyMedium2" css={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.timeline.label" />
            </Text>
            <CustomDropdown fieldName="purchaseSegments[1].timeline" options={timelineOptions} />
          </div>
          <div css={segmentChildDivStyle}>
            <TargetingSegmentContext.Provider
              value={{
                segmentType,
                fieldsPrefix: `${segmentsFieldName}[0].`,
              }}
            >
              {subject === Subject.BRANDS && <BrandsView />}
              {subject === Subject.CATEGORIES && <CategoriesView />}
              {subject === Subject.UPCS && <ProductsView />}
            </TargetingSegmentContext.Provider>
          </div>
        </div>
      </div>
    </>
  )
}

export default LapsedBuyer
