// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop } from './PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop'
import {
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop'
import type { PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile } from './PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile'
import {
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets
 */
export interface PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets
   */
  desktop: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets
   */
  mobile: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfPostPrototypeCampaignsParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  return PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSON(
      json['desktop']
    ),
    mobile: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSON(json['mobile']),
  }
}

export function PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsToJSON(
  value?: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopToJSON(value.desktop),
    mobile: PostPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
