import { css } from '@emotion/react'
import { LoadingLockupTextBase, Theme, useTheme } from '@instacart/ids-core'
import { FC } from 'react'
import FormattedMessage from 'components/FormattedMessage'

export interface CardProps {
  title: string
  children: JSX.Element
  subtext?: string
  difference?: 'positive' | 'negative'
  highlight?: boolean
  highlightColor?: string
  onClick?: () => void
  loading?: boolean
  isBeta?: boolean
}

const textLoadingBoxStyle = {
  container: {
    marginTop: '8px',
    height: '18px',
  },
}

const getStyles = (theme: Theme) => ({
  card: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  indicatorPositive: css`
    color: ${theme.colors.brandPrimaryRegular};
  `,
  indicatorNegative: css`
    color: ${theme.colors.systemDetrimentalRegular};
  `,
  text: css`
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    padding-top: 8px;
    color: ${theme.colors.systemGrayscale70};
  `,
  title: css`
    color: ${theme.colors.systemGrayscale70};
    font-size: 14px;
  `,
  beta: css`
    color: ${theme.colors.brandHighlightRegular};
    border: 1px solid ${theme.colors.brandHighlightRegular};
    border-radius: 4px;
    padding: 4px;
    text-transform: uppercase;
    font-size: 12px;
    float: right;
  `,
})

const Card: FC<React.PropsWithChildren<CardProps>> = props => {
  const theme = useTheme()
  const {
    loading,
    title,
    children,
    subtext,
    difference,
    highlight,
    onClick,
    isBeta,
    highlightColor = theme.colors.brandPrimaryRegular,
  } = props

  const styles = getStyles(theme)

  const cardWrapperStyle = css`
    cursor: ${onClick ? 'pointer' : 'default'};
    background-color: ${theme.colors.systemGrayscale00};
    border: 2px solid ${theme.colors.systemGrayscale00};
    border-radius: 6px;
    display: inline-block;
    padding: 10px 20px;
    margin-left: 20px;

    &:hover {
      border: 2px solid ${theme.colors.systemGrayscale20};
    }
  `
  const cardWrapperHighlightStyle = css`
    ${cardWrapperStyle}
    border: 2px solid ${highlightColor};
  `
  const supStyle = css`
    background: transparent url(/images/icons/${difference}.svg) no-repeat center center;
    display: inline-block;
    height: 7px;
    margin: 0 4px;
    width: 10px;
  `

  return (
    <div css={[cardWrapperStyle, highlight && cardWrapperHighlightStyle]} onClick={onClick}>
      <div css={styles.card}>
        <span css={styles.title}>{title}</span>
        {loading ? (
          <LoadingLockupTextBase styles={textLoadingBoxStyle} />
        ) : (
          <span css={styles.text}>
            {children}
            {isBeta && (
              <span css={styles.beta}>
                <FormattedMessage id="common.beta" />
              </span>
            )}
          </span>
        )}
        {difference && (
          <div>
            <sup css={supStyle} />
            <span
              css={[
                difference === 'positive' && styles.indicatorPositive,
                difference === 'negative' && styles.indicatorNegative,
              ]}
            >
              {subtext}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card
