import { Variant, Text } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { IconInputBase, IconInputBaseProps } from './IconInputBase'

export interface PrefixedInputProps
  extends Omit<Variant<typeof IconInputBase>, 'component' | 'alignRight' | 'compact'> {
  prefix: string
  prefixPadding: number
}

export const PrefixedInput = forwardRef<HTMLInputElement, PrefixedInputProps>(
  function PrefixedInput({ id, value, prefix, prefixPadding, ...rest }, ref) {
    const inputStyles: IconInputBaseProps['inputStyles'] = {
      input: {
        paddingLeft: prefixPadding,
      },
    }

    return (
      <IconInputBase
        id={id}
        ref={ref}
        type="text"
        component={
          <Text typography="bodyMedium1" data-testid="prefix-text">
            {prefix}
          </Text>
        }
        value={rest.readOnly ? `${prefix}${value}` : value}
        inputStyles={inputStyles}
        {...rest}
      />
    )
  }
)

PrefixedInput.displayName = 'PrefixedInput'
