// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TaskResponseMeta
 */
export interface TaskResponseMeta {
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof TaskResponseMeta
   */
  status: number
  /**
   * Request UUID
   * @type {string}
   * @memberof TaskResponseMeta
   */
  requestUuid: string
  /**
   * Cross-site request forgery token
   * @type {string}
   * @memberof TaskResponseMeta
   */
  csrfToken?: string
}

/**
 * Check if a given object implements the TaskResponseMeta interface.
 */
export function instanceOfTaskResponseMeta(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'requestUuid' in value

  return isInstance
}

export function TaskResponseMetaFromJSON(json: any): TaskResponseMeta {
  return TaskResponseMetaFromJSONTyped(json, false)
}

export function TaskResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskResponseMeta {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    requestUuid: json['request_uuid'],
    csrfToken: !exists(json, 'csrf_token') ? undefined : json['csrf_token'],
  }
}

export function TaskResponseMetaToJSON(value?: TaskResponseMeta | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    request_uuid: value.requestUuid,
    csrf_token: value.csrfToken,
  }
}
