// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImage } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImage'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImageFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImageFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImageToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImage'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImage } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImage'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImageFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImageFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImageToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImage'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImage } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImage'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImageFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImageFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImageToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImage'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImage } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImage'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImageFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImageFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImageToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImage'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImage } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImage'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImageFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImageFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImageToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImage'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
 */
export interface ApplicableLabelsAdminBrandPageResponseDataAttributesAsset {
  /**
   * List of labels for logo image
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImage>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  logoImage: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImage>
  /**
   * List of labels for logo image alt text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  logoAltText: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltText>
  /**
   * List of labels for hero image
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImage>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  heroImage: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImage>
  /**
   * List of labels for hero image alt text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  heroAltText: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltText>
  /**
   * List of labels for banner image
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImage>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  inPageBannerImage: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImage>
  /**
   * List of labels for banner image alt text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  inPageBannerAltText: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltText>
  /**
   * List of labels for image and text section image
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImage>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  imageAndTextImage: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImage>
  /**
   * List of labels for image and text section image alt text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  imageAndTextAltText: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltText>
  /**
   * List of labels for product asset image
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImage>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  productAssetImage: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImage>
  /**
   * List of labels for product asset image alt text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
   */
  productAssetAltText: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltText>
}

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponseDataAttributesAsset interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponseDataAttributesAsset(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'logoImage' in value
  isInstance = isInstance && 'logoAltText' in value
  isInstance = isInstance && 'heroImage' in value
  isInstance = isInstance && 'heroAltText' in value
  isInstance = isInstance && 'inPageBannerImage' in value
  isInstance = isInstance && 'inPageBannerAltText' in value
  isInstance = isInstance && 'imageAndTextImage' in value
  isInstance = isInstance && 'imageAndTextAltText' in value
  isInstance = isInstance && 'productAssetImage' in value
  isInstance = isInstance && 'productAssetAltText' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponseDataAttributesAsset {
  return ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSONTyped(json, false)
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponseDataAttributesAsset {
  if (json === undefined || json === null) {
    return json
  }
  return {
    logoImage: (json['logo_image'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImageFromJSON
    ),
    logoAltText: (json['logo_alt_text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltTextFromJSON
    ),
    heroImage: (json['hero_image'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImageFromJSON
    ),
    heroAltText: (json['hero_alt_text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltTextFromJSON
    ),
    inPageBannerImage: (json['in_page_banner_image'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImageFromJSON
    ),
    inPageBannerAltText: (json['in_page_banner_alt_text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltTextFromJSON
    ),
    imageAndTextImage: (json['image_and_text_image'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImageFromJSON
    ),
    imageAndTextAltText: (json['image_and_text_alt_text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltTextFromJSON
    ),
    productAssetImage: (json['product_asset_image'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImageFromJSON
    ),
    productAssetAltText: (json['product_asset_alt_text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextFromJSON
    ),
  }
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesAssetToJSON(
  value?: ApplicableLabelsAdminBrandPageResponseDataAttributesAsset | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    logo_image: (value.logoImage as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoImageToJSON
    ),
    logo_alt_text: (value.logoAltText as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetLogoAltTextToJSON
    ),
    hero_image: (value.heroImage as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroImageToJSON
    ),
    hero_alt_text: (value.heroAltText as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetHeroAltTextToJSON
    ),
    in_page_banner_image: (value.inPageBannerImage as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerImageToJSON
    ),
    in_page_banner_alt_text: (value.inPageBannerAltText as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetInPageBannerAltTextToJSON
    ),
    image_and_text_image: (value.imageAndTextImage as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextImageToJSON
    ),
    image_and_text_alt_text: (value.imageAndTextAltText as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetImageAndTextAltTextToJSON
    ),
    product_asset_image: (value.productAssetImage as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetImageToJSON
    ),
    product_asset_alt_text: (value.productAssetAltText as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesAssetProductAssetAltTextToJSON
    ),
  }
}
