// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamDataAttributesAdGroup } from './GetPrototypeCampaignsParamDataAttributesAdGroup'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroup'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributes
 */
export interface GetPrototypeCampaignsParamDataAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  name: string
  /**
   * Send display for this field.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  campaignType: GetPrototypeCampaignsParamDataAttributesCampaignTypeEnum
  /**
   * The status of the campaign
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  status: GetPrototypeCampaignsParamDataAttributesStatusEnum
  /**
   * The campaign's start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  startsAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  endsAt: string
  /**
   * ID of the account that is linked to this campaign
   * @type {number}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  accountId: number
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroup}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  adGroup?: GetPrototypeCampaignsParamDataAttributesAdGroup
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributes
   */
  scheduledEndAtDate: string
}

/**
 * @export
 */
export const GetPrototypeCampaignsParamDataAttributesCampaignTypeEnum = {
  Display: 'display',
} as const
export type GetPrototypeCampaignsParamDataAttributesCampaignTypeEnum =
  typeof GetPrototypeCampaignsParamDataAttributesCampaignTypeEnum[keyof typeof GetPrototypeCampaignsParamDataAttributesCampaignTypeEnum]

/**
 * @export
 */
export const GetPrototypeCampaignsParamDataAttributesStatusEnum = {
  Active: 'active',
  Ended: 'ended',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Unknown: 'unknown',
} as const
export type GetPrototypeCampaignsParamDataAttributesStatusEnum =
  typeof GetPrototypeCampaignsParamDataAttributesStatusEnum[keyof typeof GetPrototypeCampaignsParamDataAttributesStatusEnum]

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributes interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'startsAt' in value
  isInstance = isInstance && 'endsAt' in value
  isInstance = isInstance && 'accountId' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributes {
  return GetPrototypeCampaignsParamDataAttributesFromJSONTyped(json, false)
}

export function GetPrototypeCampaignsParamDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    campaignType: json['campaign_type'],
    status: json['status'],
    startsAt: json['starts_at'],
    endsAt: json['ends_at'],
    accountId: json['account_id'],
    adGroup: !exists(json, 'ad_group')
      ? undefined
      : GetPrototypeCampaignsParamDataAttributesAdGroupFromJSON(json['ad_group']),
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
  }
}

export function GetPrototypeCampaignsParamDataAttributesToJSON(
  value?: GetPrototypeCampaignsParamDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    campaign_type: value.campaignType,
    status: value.status,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
    account_id: value.accountId,
    ad_group: GetPrototypeCampaignsParamDataAttributesAdGroupToJSON(value.adGroup),
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
  }
}
