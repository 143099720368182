// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBlockSectionBannerAssets } from './PutBrandPagesIdParamBlockSectionBannerAssets'
import {
  PutBrandPagesIdParamBlockSectionBannerAssetsFromJSON,
  PutBrandPagesIdParamBlockSectionBannerAssetsFromJSONTyped,
  PutBrandPagesIdParamBlockSectionBannerAssetsToJSON,
} from './PutBrandPagesIdParamBlockSectionBannerAssets'

/**
 *
 * @export
 * @interface PutBrandPagesIdParamBlockSectionBanner
 */
export interface PutBrandPagesIdParamBlockSectionBanner {
  /**
   * id of the block, required for updates
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSectionBanner
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSectionBanner
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSectionBanner
   */
  type: PutBrandPagesIdParamBlockSectionBannerTypeEnum
  /**
   *
   * @type {PutBrandPagesIdParamBlockSectionBannerAssets}
   * @memberof PutBrandPagesIdParamBlockSectionBanner
   */
  assets: PutBrandPagesIdParamBlockSectionBannerAssets
}

/**
 * @export
 */
export const PutBrandPagesIdParamBlockSectionBannerTypeEnum = {
  SectionBannerEvergreenV1: 'section_banner.evergreen.v1',
} as const
export type PutBrandPagesIdParamBlockSectionBannerTypeEnum =
  typeof PutBrandPagesIdParamBlockSectionBannerTypeEnum[keyof typeof PutBrandPagesIdParamBlockSectionBannerTypeEnum]

/**
 * Check if a given object implements the PutBrandPagesIdParamBlockSectionBanner interface.
 */
export function instanceOfPutBrandPagesIdParamBlockSectionBanner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PutBrandPagesIdParamBlockSectionBannerFromJSON(
  json: any
): PutBrandPagesIdParamBlockSectionBanner {
  return PutBrandPagesIdParamBlockSectionBannerFromJSONTyped(json, false)
}

export function PutBrandPagesIdParamBlockSectionBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBlockSectionBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PutBrandPagesIdParamBlockSectionBannerAssetsFromJSON(json['assets']),
  }
}

export function PutBrandPagesIdParamBlockSectionBannerToJSON(
  value?: PutBrandPagesIdParamBlockSectionBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PutBrandPagesIdParamBlockSectionBannerAssetsToJSON(value.assets),
  }
}
