import * as React from 'react'
import { ReactNode, useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { ButtonProps } from 'components/ids-ads/molecules/buttons/utils/makeButton'
import { LinkButtonProps } from 'components/ids-ads/molecules/buttons/utils/makeLinkButton'
import { GenericMessageDescriptor, MessageIdType } from 'locales/types'

const DEFAULT_CLOSE_DELAY = 8600

const nextNotificationId = (() => {
  let id = 0
  return () => {
    id += 1
    return id
  }
})()

// eslint-disable-next-line no-restricted-syntax
export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface ToastActionButtonsProps {
  primaryButtonLabel?: GenericMessageDescriptor
  primaryButtonProps?: ButtonProps
  primaryLinkButtonProps?: LinkButtonProps
  secondaryButtonLabel?: GenericMessageDescriptor
  secondaryButtonProps?: ButtonProps
  secondaryLinkButtonProps?: LinkButtonProps
}

export interface Notification {
  id: number
  type: NotificationType
  closeDelay: number
  message?: ReactNode
  messageId?: MessageIdType
  messageValues?: React.ComponentProps<typeof FormattedMessage>['values']
  actionButtons?: React.ReactNode
  actionButtonsProps?: ToastActionButtonsProps
  iconOverride?: ReactNode
  persistent?: boolean
  onClear?(): void
  testId?: string
}

export type BuildNotificationOptions = Partial<Omit<Notification, 'id'>>

export function buildNotification({
  type = NotificationType.INFO,
  closeDelay = DEFAULT_CLOSE_DELAY,
  ...options
}: BuildNotificationOptions) {
  return {
    id: nextNotificationId(),
    type,
    closeDelay,
    ...options,
  }
}

export interface Notifier {
  sendNotification(options: BuildNotificationOptions): number
  clearNotification(id: number): void
  clearAll(): void
}

function notImplemented(description: string) {
  return () => {
    throw new Error(
      `${description} is not implemented. Maybe this component is not rendered under a NotificationsProvider?`
    )
  }
}

export const NotifierContext = React.createContext<Notifier>({
  sendNotification: notImplemented('sendNotification'),
  clearNotification: notImplemented('clearNotification'),
  clearAll: notImplemented('clearAll'),
})

export const useNotifier = () => useContext(NotifierContext)
