import { createContext, useContext } from 'react'

export interface DebuggingCtx {
  isDebuggingOn: boolean
  setIsDebuggingOn: (debugging: boolean) => void
}

const initialDebuggingContext: DebuggingCtx = {
  isDebuggingOn: false,
  setIsDebuggingOn: () => {},
}

export const DebuggingContext = createContext<DebuggingCtx>(initialDebuggingContext)
DebuggingContext.displayName = 'DebuggingContext'

export const useDebuggingContext = () => useContext(DebuggingContext)
