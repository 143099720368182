// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AdGroupKeywordsBulkResponseDataAttributes
 */
export interface AdGroupKeywordsBulkResponseDataAttributes {
  /**
   * UUID of Ad Group Keyword
   * @type {string}
   * @memberof AdGroupKeywordsBulkResponseDataAttributes
   */
  id: string
  /**
   * The keyword.
   * @type {string}
   * @memberof AdGroupKeywordsBulkResponseDataAttributes
   */
  keyword: string
  /**
   * CPC bid for the keyword in dollars.
   * @type {number}
   * @memberof AdGroupKeywordsBulkResponseDataAttributes
   */
  cpcBid: number
  /**
   * The type of match Instacart supports for the keyword. We currently only support exact matches so this currently will only return "exact".
   * @type {string}
   * @memberof AdGroupKeywordsBulkResponseDataAttributes
   */
  matchingType: string
  /**
   * Ad Group Keyword Status
   * @type {string}
   * @memberof AdGroupKeywordsBulkResponseDataAttributes
   */
  adGroupKeywordStatus?: string
}

/**
 * Check if a given object implements the AdGroupKeywordsBulkResponseDataAttributes interface.
 */
export function instanceOfAdGroupKeywordsBulkResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'keyword' in value
  isInstance = isInstance && 'cpcBid' in value
  isInstance = isInstance && 'matchingType' in value

  return isInstance
}

export function AdGroupKeywordsBulkResponseDataAttributesFromJSON(
  json: any
): AdGroupKeywordsBulkResponseDataAttributes {
  return AdGroupKeywordsBulkResponseDataAttributesFromJSONTyped(json, false)
}

export function AdGroupKeywordsBulkResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupKeywordsBulkResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    keyword: json['keyword'],
    cpcBid: json['cpc_bid'],
    matchingType: json['matching_type'],
    adGroupKeywordStatus: !exists(json, 'ad_group_keyword_status')
      ? undefined
      : json['ad_group_keyword_status'],
  }
}

export function AdGroupKeywordsBulkResponseDataAttributesToJSON(
  value?: AdGroupKeywordsBulkResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    keyword: value.keyword,
    cpc_bid: value.cpcBid,
    matching_type: value.matchingType,
    ad_group_keyword_status: value.adGroupKeywordStatus,
  }
}
