import { Dispatch, MutableRefObject, ReactText, SetStateAction, useEffect, useState } from 'react'
import { InputFieldRef } from 'common/types'

export interface ShouldRevertFieldState {
  shouldRevertField: boolean
  setShouldRevertField: Dispatch<SetStateAction<boolean>>
}

export interface UseRevertFieldProps {
  // the previous value the input field should be reverted to
  previousValue: string
  // reference to the input field to be reverted
  field: MutableRefObject<InputFieldRef | null>
  // (optional) getter and setter for the shouldRevertField state
  shouldRevertFieldState?: ShouldRevertFieldState
  // function to perform any formatting on the value for comparison and its final reverted value
  formatValue?: (val: ReactText) => string
}

// Hook for reverting a supplied input field to its previous value
export const useRevertField = (props: UseRevertFieldProps) => {
  const { previousValue, field, shouldRevertFieldState, formatValue } = props
  const [shouldRevertFieldInternal, setShouldRevertFieldInternal] = useState<boolean>(false)
  let shouldRevertField: boolean = shouldRevertFieldInternal
  let setShouldRevertField: Dispatch<SetStateAction<boolean>> = setShouldRevertFieldInternal

  // optionally allow using any revert props passed in
  if (shouldRevertFieldState) {
    shouldRevertField = shouldRevertFieldState?.shouldRevertField
    setShouldRevertField = shouldRevertFieldState?.setShouldRevertField
  }

  useEffect(() => {
    if (!!shouldRevertField && setShouldRevertField && field?.current) {
      // Ensure the value has that correct formatting for comparison
      const val = formatValue ? formatValue(previousValue) : previousValue

      if (field?.current && field.current.FormComponent.input.value !== val) {
        field.current.FormComponent.input.value = val
        setShouldRevertField(false)
      }
    }
  }, [shouldRevertField, setShouldRevertField, field, previousValue, formatValue])

  return {
    setShouldRevertField,
  }
}
