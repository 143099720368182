import { OptionsIcon } from '@instacart/ids-core'
import { ReactNode } from 'react'
import { BorderlessButton, DropdownMenu } from 'components/ids-ads'

const MIN_WIDTH = 100

export interface EllipsisDropdownMenuProps {
  id: string
  children: ReactNode
  inTableColumn?: boolean
}

export function EllipsisDropdownMenu({
  id,
  children,
  inTableColumn = false,
}: EllipsisDropdownMenuProps) {
  return (
    <DropdownMenu
      innerCss={{
        minWidth: MIN_WIDTH,
        // CellBase has margin-left: 10px !important applied to all its direct
        // children, so unfortunately we need to override it with another !important
        marginLeft: inTableColumn ? '0 !important' : undefined,
      }}
      trigger={
        <BorderlessButton
          icon={OptionsIcon}
          compact={inTableColumn}
          data-testid={`options-menu-${id}`}
        />
      }
      dropdownMenuInitialState={
        inTableColumn
          ? {
              unstable_fixed: true,
              unstable_flip: true,
            }
          : undefined
      }
    >
      {children}
    </DropdownMenu>
  )
}
