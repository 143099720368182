import { useEffect, useState } from 'react'
import { isBannerNotificationDismissed, setBannerNotificationDismissed } from 'common/localStorage'
import { BannerNotificationProps, BannerType } from './banner.types'

const shouldShowBanner = (dismissId?: string) =>
  !(dismissId && isBannerNotificationDismissed(dismissId))

const dismissBannerWithId = (dismissId?: string) => {
  if (dismissId) {
    setBannerNotificationDismissed(dismissId)
  }
}

export const shouldShowDismissAll = (banners: BannerNotificationProps[]) =>
  banners.find(banner => banner.dismissId)

export const useBannerNotification = (dismissId?: string) => {
  const [isVisible, setIsVisible] = useState(shouldShowBanner(dismissId))

  const dismissBanner = () => {
    dismissBannerWithId(dismissId)
    setIsVisible(false)
  }

  return { isVisible, dismissBanner }
}

export const useGroupedBannerNotifications = (groupedBanners: BannerNotificationProps[]) => {
  const [banners, setBanners] = useState(groupedBanners)

  const dismissBanner = (dismissId?: string) => {
    dismissBannerWithId(dismissId)
    setBanners(banners.filter(banner => banner.dismissId !== dismissId))
  }

  const dismissAll = () => {
    banners.forEach(banner => dismissBannerWithId(banner.dismissId))
    setBanners(banners.filter(banner => !banner.dismissId))
  }

  useEffect(() => {
    setBanners(groupedBanners)
  }, [groupedBanners])

  return { banners, dismissBanner, dismissAll }
}

export const useBannerMap = (banners: BannerNotificationProps[]) => {
  const [bannerMap, setBannerMap] = useState<Record<BannerType, BannerNotificationProps[]>>()

  useEffect(() => {
    const map: Record<BannerType, BannerNotificationProps[]> = {
      [BannerType.ERROR]: [],
      [BannerType.WARNING]: [],
      [BannerType.INFO]: [],
      [BannerType.NEW]: [],
    }
    banners.forEach(banner => {
      if (shouldShowBanner(banner.dismissId)) map[banner.type].push(banner)
    })
    setBannerMap(map)
  }, [banners])

  return bannerMap
}
