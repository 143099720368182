import { CSSObject } from '@emotion/react'
import { useTheme, Base, combineStyles, Theme, spacing } from '@instacart/ids-core'
import classNames from 'classnames'
import { FunctionComponent } from 'react'
import toPx from 'common/toPx'

const getStyles = (theme: Theme, margin?: CSSObject['margin']) =>
  ({
    root: {
      border: 'none',
      borderTop: `1px ${theme.colors.systemGrayscale20} solid`,
      margin: margin ?? '8px 0',
      '&.increasedVerticalMargin': {
        margin: '12px 0',
      },
    },
    background: {
      background: 'white',
    },
  } as const)

export interface CardDividerProps extends Base<typeof getStyles> {
  increasedVerticalMargin?: boolean
  margin?: CSSObject['margin']
}

export const CardDivider: FunctionComponent<React.PropsWithChildren<CardDividerProps>> = ({
  styles,
  margin,
  increasedVerticalMargin,
}) => {
  const theme = useTheme()
  const { root } = combineStyles(getStyles(theme, margin), styles)

  return <hr css={root} className={classNames({ increasedVerticalMargin })} />
}

export const CardDividerMarginless = () => {
  const theme = useTheme()
  const { root, background } = getStyles(theme, toPx`0 ${spacing.s24} 0 ${spacing.s24}`)

  return (
    <div css={background}>
      <hr css={root} />
    </div>
  )
}
