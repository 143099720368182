// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBlockSectionBannerAssetsDesktop } from './PostBrandPagesParamBlockSectionBannerAssetsDesktop'
import {
  PostBrandPagesParamBlockSectionBannerAssetsDesktopFromJSON,
  PostBrandPagesParamBlockSectionBannerAssetsDesktopFromJSONTyped,
  PostBrandPagesParamBlockSectionBannerAssetsDesktopToJSON,
} from './PostBrandPagesParamBlockSectionBannerAssetsDesktop'
import type { PostBrandPagesParamBlockSectionBannerAssetsMobile } from './PostBrandPagesParamBlockSectionBannerAssetsMobile'
import {
  PostBrandPagesParamBlockSectionBannerAssetsMobileFromJSON,
  PostBrandPagesParamBlockSectionBannerAssetsMobileFromJSONTyped,
  PostBrandPagesParamBlockSectionBannerAssetsMobileToJSON,
} from './PostBrandPagesParamBlockSectionBannerAssetsMobile'

/**
 *
 * @export
 * @interface PostBrandPagesParamBlockSectionBannerAssets
 */
export interface PostBrandPagesParamBlockSectionBannerAssets {
  /**
   *
   * @type {PostBrandPagesParamBlockSectionBannerAssetsDesktop}
   * @memberof PostBrandPagesParamBlockSectionBannerAssets
   */
  desktop: PostBrandPagesParamBlockSectionBannerAssetsDesktop
  /**
   *
   * @type {PostBrandPagesParamBlockSectionBannerAssetsMobile}
   * @memberof PostBrandPagesParamBlockSectionBannerAssets
   */
  mobile: PostBrandPagesParamBlockSectionBannerAssetsMobile
}

/**
 * Check if a given object implements the PostBrandPagesParamBlockSectionBannerAssets interface.
 */
export function instanceOfPostBrandPagesParamBlockSectionBannerAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PostBrandPagesParamBlockSectionBannerAssetsFromJSON(
  json: any
): PostBrandPagesParamBlockSectionBannerAssets {
  return PostBrandPagesParamBlockSectionBannerAssetsFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockSectionBannerAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockSectionBannerAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostBrandPagesParamBlockSectionBannerAssetsDesktopFromJSON(json['desktop']),
    mobile: PostBrandPagesParamBlockSectionBannerAssetsMobileFromJSON(json['mobile']),
  }
}

export function PostBrandPagesParamBlockSectionBannerAssetsToJSON(
  value?: PostBrandPagesParamBlockSectionBannerAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostBrandPagesParamBlockSectionBannerAssetsDesktopToJSON(value.desktop),
    mobile: PostBrandPagesParamBlockSectionBannerAssetsMobileToJSON(value.mobile),
  }
}
