import {
  setCurrentAccountId as setLocalStorageCurrentAccountId,
  getCurrentAccountId as getLocalStorageCurrentAccountId,
  clearCurrentAccountId as clearLocalStorageCurrentAccountId,
} from 'common/localStorage'
import {
  setCurrentAccountId as setSessionCurrentAccountId,
  getCurrentAccountId as getSessionCurrentAccountId,
  clearCurrentAccountId as clearSessionCurrentAccountId,
} from 'common/sessionStorage'

export const setCurrentAccountId = (id: number | string) => {
  setSessionCurrentAccountId(id)
  setLocalStorageCurrentAccountId(id)
}

// If we have an account selected in the session, then we use that account, otherwise we default to the one stored in localStorage
// This is to support multi-tab viewing of different accounts
export const getCurrentAccountId = () => {
  return getSessionCurrentAccountId() || getLocalStorageCurrentAccountId()
}

export const clearCurrentAccountId = () => {
  clearSessionCurrentAccountId()
  clearLocalStorageCurrentAccountId()
}
