import { CSSObject } from '@emotion/react'
import { merge } from 'lodash'
import { ReactNode, useEffect } from 'react'
import {
  Hovercard,
  HovercardAnchor,
  HovercardArrow,
  HovercardAnchorProps,
  HovercardStateProps,
  useHovercardState,
} from './Hovercard'

const useStyles = () => {
  return {
    anchor: {
      '&, &:hover': {
        color: 'inherit',
      },
    },
  }
}

export interface HovercardTooltipProps extends Omit<HovercardAnchorProps, 'state'> {
  children: JSX.Element
  tooltipContent: ReactNode
  arrow?: boolean
  disabled?: boolean
  hovercardStateProps?: HovercardStateProps
  onShow?: () => void
  styles?: Partial<Record<'anchor' | 'tooltip' | 'content', CSSObject>>
  testId?: string
}

export function HovercardTooltip({
  children,
  tooltipContent,
  arrow,
  onShow,
  disabled,
  hovercardStateProps = {},
  styles = {},
  testId = 'hovercard-tooltip',
  ...props
}: HovercardTooltipProps) {
  const stylesTest = useStyles()
  const { anchor: anchorBaseStyles, ...hovercardBaseStyles } = stylesTest
  const { anchor: anchorOverrideStyles, ...hovercardOverrideStyles } = styles
  const hovercardStyles = merge(hovercardBaseStyles, hovercardOverrideStyles)
  const anchorStyles = merge(anchorBaseStyles, anchorOverrideStyles)

  const state = useHovercardState({ timeout: 250, hideTimeout: 0, ...hovercardStateProps })
  const { visible } = state

  useEffect(() => {
    if (onShow && visible && !disabled) {
      onShow()
    }
  }, [disabled, visible, onShow])

  if (disabled) {
    return children
  }

  return (
    <>
      <HovercardAnchor css={anchorStyles} {...props} state={state} data-testid={`${testId}-anchor`}>
        {children}
      </HovercardAnchor>
      <Hovercard state={state} styles={hovercardStyles} data-testid={`${testId}-content`}>
        {arrow && <HovercardArrow data-testid={`${testId}-arrow`} />}
        {tooltipContent}
      </Hovercard>
    </>
  )
}
