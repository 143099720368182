import { FormikErrors, getIn } from 'formik'
import { compact } from 'lodash'
import { useContext, createContext, useEffect, useState, ReactNode } from 'react'
import { BrandPageSchema } from 'common/common-definitions/brandPage'
import {
  ClientError,
  containsFieldError,
  FIELD_ERROR_ALREADY_EXISTS,
  isNestedObjectEmpty,
} from 'common/utils'
import { NotificationType, useNotifier } from 'context'
import { useGetProductIdentifier } from 'hooks/useGetProductIdentifier'
import { toCreateBrandPageRequest, formatForFields } from 'pages/BrandPage/hooks/brandPage.api'
import { BrandPagesApi } from 'service/apis/BrandPagesApi'
import { PutBrandPagesIdRequest } from 'service/openapi/__codegen__/apis/BrandPagesApi'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { StandaloneBrandPageResponse } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponse'
import { StandaloneBrandPageResponseData } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseData'
import { BlockAssetTypeEnum, BrandPageFormData, BrandPageFormErrorData } from './brandPage.types'
import {
  FIELD_ERROR_TAXONOMY_COLLIDES_WITH_EXISTING_BRAND_PAGE,
  PAGE_HIERARCHY_VALIDATION_ERROR_FIELD_KEY,
  TAXONOMY_IDS_VALIDATION_ERROR_FIELD_KEY,
} from './constants'
import { removeBlock } from './utils'

type BrandPageFormSetErrors = (errors: FormikErrors<BrandPageFormData>) => void

type BrandPageFormOnSubmit = {
  formValues: BrandPageFormData
  setErrors: BrandPageFormSetErrors
  skipValidation?: boolean
}

export type SelectedBlock = {
  index: number
  type: BlockAssetTypeEnum
}

export type ProductCache = {
  [key: string]: GetProductsResponseData
}

export type BlockErrorOptional = ({ [key: string]: string } | null)[]

type BrandPageContextState = {
  brandPage: BrandPageFormData | undefined
  brandPageSchema: BrandPageSchema | undefined
  setBrandPageSchema: (schema?: BrandPageSchema) => void
  selectedBlock?: SelectedBlock
  setSelectedBlock: (block?: SelectedBlock) => void
  infoDismissed: boolean
  setInfoDismissed: (infoDismissed: boolean) => void
  loadingSelectedBlock: boolean
  setLoadingSelectedBlock: (loadingSelectedBlock: boolean) => void
  showNewSection: boolean
  setShowNewSection: (showNewSection: boolean) => void
  newBlockType?: BlockAssetTypeEnum
  setNewBlockType: (newBlockType?: BlockAssetTypeEnum) => void
  productCache: ProductCache
  handleAddtoProductCache: (products: GetProductsResponseData[]) => void
  setUpdateSelectedBlock: (updateSelectedBlock?: SelectedBlock) => void
  saveBrandPage: (
    params: BrandPageFormOnSubmit,
    id?: string
  ) => Promise<StandaloneBrandPageResponseData | undefined>
  submitForReview: (id: string) => Promise<StandaloneBrandPageResponseData | undefined>
  getBrandPage: (id: string) => Promise<void>
  setSubmitForReviewAfterSave: (submitForReview: boolean) => void
  pageHierarchyAlreadyExistsError: boolean
  setPageHierarchyAlreadyExistsError: (exists: boolean) => void
  blockErrors: BlockErrorOptional
  setBlockErrors: (blockErrors: BlockErrorOptional) => void
  formErrors?: FormikErrors<BrandPageFormErrorData>
  setFormErrors: (formErrors: FormikErrors<BrandPageFormErrorData>) => void
  submitForReviewAfterSave: boolean
  resolvedRejections: string[]
  handleSetResolvedRejections: (block: string, remove?: boolean) => void
  skipValidation?: boolean
  setSkipValidation: (skipValidation: boolean) => void
  brandAlreadyLinkedError: boolean
  setBrandAlreadyLinkedError: (brandAlreadyLinkedError: boolean) => void
  setCurrentAdGroupName: undefined
  expandBrandPageSections: undefined
  setExpandBrandPageSections: undefined
  brandPageResponse: StandaloneBrandPageResponseData | undefined
}

export function filterBlockErrors(block: { [key: string]: string }) {
  const copy = { ...block }
  const productAssets = getIn(block, 'productAssets')
  const emptyProductAssets = Array.isArray(productAssets)
  if (productAssets && emptyProductAssets) {
    delete copy.productAssets
  }
  return copy
}

function getFilteredErrors(errors?: BlockErrorOptional) {
  const filteredErrors = errors
    ? errors.map(errBlock => {
        if (errBlock) {
          const newErrBlock = filterBlockErrors(errBlock)
          const errBlockLength = Object.keys(newErrBlock).length
          const blockHasErrors = errBlockLength > 0 && !isNestedObjectEmpty(newErrBlock)
          return blockHasErrors ? newErrBlock : null
        }
        return null
      })
    : []
  return compact(filteredErrors).length > 0 ? filteredErrors : []
}

export const BrandPageContext = createContext<BrandPageContextState | undefined>(undefined)

export const useBrandPageContext = () => {
  const contextState = useContext(BrandPageContext)
  if (!contextState) {
    throw new Error('useBrandPageContext should be used in BrandPageContextProvider component')
  }
  return contextState
}

interface BrandPageContextProviderProps {
  children: ReactNode
}

function BrandPageContextProvider({ children }: BrandPageContextProviderProps) {
  const [selectedBlock, setSelectedBlock] = useState<SelectedBlock | undefined>()
  const [infoDismissed, setInfoDismissed] = useState(false)
  const [brandPage, setBrandPage] = useState<BrandPageFormData | undefined>(undefined)
  const [brandPageSchema, setBrandPageSchema] = useState<BrandPageSchema | undefined>(undefined)
  const notifier = useNotifier()
  const [showNewSection, setShowNewSection] = useState(false)
  const [loadingSelectedBlock, setLoadingSelectedBlock] = useState(false)
  const [submitForReviewAfterSave, setSubmitForReviewAfterSave] = useState(false)
  const [newBlockType, setNewBlockType] = useState<BlockAssetTypeEnum>()
  const [productCache, setProductCache] = useState<ProductCache>({})
  const [updateSelectedBlock, setUpdateSelectedBlock] = useState<SelectedBlock>()
  const [pageHierarchyAlreadyExistsError, setPageHierarchyAlreadyExistsError] =
    useState<boolean>(false)
  const [brandAlreadyLinkedError, setBrandAlreadyLinkedError] = useState(false)
  const [blockErrors, setBlockErrors] = useState<BlockErrorOptional>([])
  const [formErrors, setFormErrors] = useState<FormikErrors<BrandPageFormErrorData>>()
  const [resolvedRejections, setResolvedRejections] = useState<string[]>([])
  const [skipValidation, setSkipValidation] = useState<boolean>()
  const [brandPageResponse, setBrandPageResponse] = useState<
    StandaloneBrandPageResponseData | undefined
  >(undefined)

  const getProductIdentifier = useGetProductIdentifier()

  useEffect(() => {
    if (updateSelectedBlock) {
      setSelectedBlock(updateSelectedBlock)
      setUpdateSelectedBlock(undefined)
    }
  }, [updateSelectedBlock, selectedBlock])

  async function callApi<Response extends StandaloneBrandPageResponse>(
    fn: () => Promise<Response>
  ): Promise<Response['data'] | undefined> {
    try {
      const apiResponse = await fn()
      return apiResponse.data
    } catch (err) {
      let handledError = false

      const pageHierarchyAlreadyExists = containsFieldError(
        err as ClientError,
        PAGE_HIERARCHY_VALIDATION_ERROR_FIELD_KEY,
        FIELD_ERROR_ALREADY_EXISTS
      )

      if (pageHierarchyAlreadyExists) {
        setPageHierarchyAlreadyExistsError(true)
        handledError = true
      }

      const brandAlreadyLinked = containsFieldError(
        err as ClientError,
        TAXONOMY_IDS_VALIDATION_ERROR_FIELD_KEY,
        FIELD_ERROR_TAXONOMY_COLLIDES_WITH_EXISTING_BRAND_PAGE
      )

      if (brandAlreadyLinked) {
        setBrandAlreadyLinkedError(true)
        handledError = true
      }

      if (!handledError) {
        notifier.sendNotification({
          messageId: 'common.error.generic',
          type: NotificationType.ERROR,
        })
      }
      return undefined
    }
  }

  useEffect(() => {
    const errorBlocks = getIn(formErrors, 'blocks')
    setBlockErrors(getFilteredErrors(errorBlocks))
  }, [formErrors])

  function handleSetResolvedRejections(block: string, remove?: boolean) {
    const exists = resolvedRejections.includes(block)
    if (remove && exists) {
      const blockIndex = resolvedRejections.indexOf(block)
      const newResolvedRejections = removeBlock(blockIndex, resolvedRejections)
      setResolvedRejections(newResolvedRejections)
    } else if (!exists && !remove) {
      const newResolvedRejections = resolvedRejections.slice().concat(block)
      setResolvedRejections(newResolvedRejections)
    }
  }

  async function saveBrandPage(params: BrandPageFormOnSubmit, id?: string) {
    return callApi(() => {
      if (id) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const updateRequest = {
          ...toCreateBrandPageRequest(params.formValues, skipValidation),
          id,
        } as PutBrandPagesIdRequest
        return BrandPagesApi.putBrandPagesId(updateRequest)
      }

      const request = toCreateBrandPageRequest(params.formValues, skipValidation)
      return BrandPagesApi.postBrandPages(request)
    })
  }

  async function submitForReview(id: string) {
    return callApi(() => {
      return BrandPagesApi.postBrandPagesIdSubmit({
        id,
      }) as unknown as Promise<StandaloneBrandPageResponse>
    })
  }

  async function getBrandPage(id: string) {
    const response = await callApi(() => BrandPagesApi.getBrandPagesId({ id }))
    const formattedForFields = formatForFields(response) as BrandPageFormData
    setBrandPage(formattedForFields)
    const firstBlockType = getIn(formattedForFields, 'blocks[0].type')
    setSelectedBlock({ index: 0, type: firstBlockType })
    setBrandPageResponse(response)
  }

  useEffect(() => {
    if (selectedBlock) {
      setShowNewSection(false)
    }
  }, [selectedBlock])

  const handleAddtoProductCache = (products: GetProductsResponseData[]) => {
    const newProducts: ProductCache = products.reduce(
      (agg, r) => ({
        ...agg,
        [getProductIdentifier(r) as string]: r,
      }),
      {}
    )

    setProductCache({
      ...productCache,
      ...newProducts,
    })
  }

  const value: BrandPageContextState = {
    brandPage,
    brandPageSchema,
    setBrandPageSchema,
    infoDismissed,
    setInfoDismissed,
    selectedBlock,
    setSelectedBlock,
    loadingSelectedBlock,
    setLoadingSelectedBlock,
    showNewSection,
    setShowNewSection,
    newBlockType,
    setNewBlockType,
    productCache,
    handleAddtoProductCache,
    setUpdateSelectedBlock,
    saveBrandPage,
    getBrandPage,
    submitForReview,
    setSubmitForReviewAfterSave,
    submitForReviewAfterSave,
    pageHierarchyAlreadyExistsError,
    setPageHierarchyAlreadyExistsError,
    setBlockErrors,
    blockErrors,
    formErrors,
    setFormErrors,
    resolvedRejections,
    handleSetResolvedRejections,
    skipValidation,
    setSkipValidation,
    brandAlreadyLinkedError,
    setBrandAlreadyLinkedError,
    setCurrentAdGroupName: undefined,
    expandBrandPageSections: undefined,
    setExpandBrandPageSections: undefined,
    brandPageResponse,
  }
  return <BrandPageContext.Provider value={value}>{children}</BrandPageContext.Provider>
}
export default BrandPageContextProvider
