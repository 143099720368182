// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAccountPaymentResponseDataAttributesNextPaymentOption
 */
export interface GetAccountPaymentResponseDataAttributesNextPaymentOption {
  /**
   * Future Account invoicing type, must be one of: instacart_invoicing, credit_card
   * @type {string}
   * @memberof GetAccountPaymentResponseDataAttributesNextPaymentOption
   */
  paymentOption: GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum
  /**
   * Date future payment_option will become active
   * @type {string}
   * @memberof GetAccountPaymentResponseDataAttributesNextPaymentOption
   */
  startsAt: string
}

/**
 * @export
 */
export const GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum = {
  InstacartInvoicing: 'instacart_invoicing',
  CreditCard: 'credit_card',
} as const
export type GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum =
  typeof GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum[keyof typeof GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum]

/**
 * Check if a given object implements the GetAccountPaymentResponseDataAttributesNextPaymentOption interface.
 */
export function instanceOfGetAccountPaymentResponseDataAttributesNextPaymentOption(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'paymentOption' in value
  isInstance = isInstance && 'startsAt' in value

  return isInstance
}

export function GetAccountPaymentResponseDataAttributesNextPaymentOptionFromJSON(
  json: any
): GetAccountPaymentResponseDataAttributesNextPaymentOption {
  return GetAccountPaymentResponseDataAttributesNextPaymentOptionFromJSONTyped(json, false)
}

export function GetAccountPaymentResponseDataAttributesNextPaymentOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountPaymentResponseDataAttributesNextPaymentOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    paymentOption: json['payment_option'],
    startsAt: json['starts_at'],
  }
}

export function GetAccountPaymentResponseDataAttributesNextPaymentOptionToJSON(
  value?: GetAccountPaymentResponseDataAttributesNextPaymentOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    payment_option: value.paymentOption,
    starts_at: value.startsAt,
  }
}
