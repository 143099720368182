// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainItemGrid?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainProductGroupGrid?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainCollectionGrid?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  bannerClick?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
    main_product_group_grid:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
        value.bannerClick
      ),
  }
}
