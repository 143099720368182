// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetUsersSettingsMultiFactorAuthParamDataMfaFactors
 */
export interface GetUsersSettingsMultiFactorAuthParamDataMfaFactors {
  /**
   * MFA Factor Type
   * @type {string}
   * @memberof GetUsersSettingsMultiFactorAuthParamDataMfaFactors
   */
  mfaType: GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum
}

/**
 * @export
 */
export const GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum = {
  Authenticator: 'authenticator',
  Email: 'email',
} as const
export type GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum =
  typeof GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum[keyof typeof GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum]

/**
 * Check if a given object implements the GetUsersSettingsMultiFactorAuthParamDataMfaFactors interface.
 */
export function instanceOfGetUsersSettingsMultiFactorAuthParamDataMfaFactors(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'mfaType' in value

  return isInstance
}

export function GetUsersSettingsMultiFactorAuthParamDataMfaFactorsFromJSON(
  json: any
): GetUsersSettingsMultiFactorAuthParamDataMfaFactors {
  return GetUsersSettingsMultiFactorAuthParamDataMfaFactorsFromJSONTyped(json, false)
}

export function GetUsersSettingsMultiFactorAuthParamDataMfaFactorsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetUsersSettingsMultiFactorAuthParamDataMfaFactors {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mfaType: json['mfa_type'],
  }
}

export function GetUsersSettingsMultiFactorAuthParamDataMfaFactorsToJSON(
  value?: GetUsersSettingsMultiFactorAuthParamDataMfaFactors | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mfa_type: value.mfaType,
  }
}
