// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutUniversalAdGroupsIdParamBrandPageBlockSnapshot,
  instanceOfPutUniversalAdGroupsIdParamBrandPageBlockSnapshot,
  PutUniversalAdGroupsIdParamBrandPageBlockSnapshotFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockSnapshot'
import {
  PutUniversalAdGroupsIdParamBrandPageSnapshot,
  instanceOfPutUniversalAdGroupsIdParamBrandPageSnapshot,
  PutUniversalAdGroupsIdParamBrandPageSnapshotFromJSON,
  PutUniversalAdGroupsIdParamBrandPageSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageSnapshot'
import {
  PutUniversalAdGroupsIdParamCreativeSnapshot,
  instanceOfPutUniversalAdGroupsIdParamCreativeSnapshot,
  PutUniversalAdGroupsIdParamCreativeSnapshotFromJSON,
  PutUniversalAdGroupsIdParamCreativeSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamCreativeSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamCreativeSnapshot'
import {
  PutUniversalAdGroupsIdParamDisplayAdGroupSnapshot,
  instanceOfPutUniversalAdGroupsIdParamDisplayAdGroupSnapshot,
  PutUniversalAdGroupsIdParamDisplayAdGroupSnapshotFromJSON,
  PutUniversalAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamDisplayAdGroupSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamDisplayAdGroupSnapshot'
import {
  PutUniversalAdGroupsIdParamDisplayAssetSnapshot,
  instanceOfPutUniversalAdGroupsIdParamDisplayAssetSnapshot,
  PutUniversalAdGroupsIdParamDisplayAssetSnapshotFromJSON,
  PutUniversalAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamDisplayAssetSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamDisplayAssetSnapshot'
import {
  PutUniversalAdGroupsIdParamDisplayProductAssetSnapshot,
  instanceOfPutUniversalAdGroupsIdParamDisplayProductAssetSnapshot,
  PutUniversalAdGroupsIdParamDisplayProductAssetSnapshotFromJSON,
  PutUniversalAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped,
  PutUniversalAdGroupsIdParamDisplayProductAssetSnapshotToJSON,
} from './PutUniversalAdGroupsIdParamDisplayProductAssetSnapshot'

/**
 * @type PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & PutUniversalAdGroupsIdParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & PutUniversalAdGroupsIdParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PutUniversalAdGroupsIdParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & PutUniversalAdGroupsIdParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & PutUniversalAdGroupsIdParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & PutUniversalAdGroupsIdParamDisplayProductAssetSnapshot)

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  return PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutUniversalAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutUniversalAdGroupsIdParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutUniversalAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutUniversalAdGroupsIdParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutUniversalAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutUniversalAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutUniversalAdGroupsIdParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PutUniversalAdGroupsIdParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutUniversalAdGroupsIdParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PutUniversalAdGroupsIdParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutUniversalAdGroupsIdParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PutUniversalAdGroupsIdParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PutUniversalAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
