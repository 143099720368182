// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignProductIdsResponseDataAttributes } from './GetCampaignProductIdsResponseDataAttributes'
import {
  GetCampaignProductIdsResponseDataAttributesFromJSON,
  GetCampaignProductIdsResponseDataAttributesFromJSONTyped,
  GetCampaignProductIdsResponseDataAttributesToJSON,
} from './GetCampaignProductIdsResponseDataAttributes'

/**
 *
 * @export
 * @interface GetCampaignProductIdsResponseData
 */
export interface GetCampaignProductIdsResponseData {
  /**
   * Ad Group Product ID
   * @type {string}
   * @memberof GetCampaignProductIdsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetCampaignProductIdsResponseData
   */
  type: GetCampaignProductIdsResponseDataTypeEnum
  /**
   *
   * @type {GetCampaignProductIdsResponseDataAttributes}
   * @memberof GetCampaignProductIdsResponseData
   */
  attributes: GetCampaignProductIdsResponseDataAttributes
}

/**
 * @export
 */
export const GetCampaignProductIdsResponseDataTypeEnum = {
  AdGroupProductId: 'ad_group_product_id',
} as const
export type GetCampaignProductIdsResponseDataTypeEnum =
  typeof GetCampaignProductIdsResponseDataTypeEnum[keyof typeof GetCampaignProductIdsResponseDataTypeEnum]

/**
 * Check if a given object implements the GetCampaignProductIdsResponseData interface.
 */
export function instanceOfGetCampaignProductIdsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetCampaignProductIdsResponseDataFromJSON(
  json: any
): GetCampaignProductIdsResponseData {
  return GetCampaignProductIdsResponseDataFromJSONTyped(json, false)
}

export function GetCampaignProductIdsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignProductIdsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetCampaignProductIdsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetCampaignProductIdsResponseDataToJSON(
  value?: GetCampaignProductIdsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetCampaignProductIdsResponseDataAttributesToJSON(value.attributes),
  }
}
