// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGrid } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGrid'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGridToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGrid'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions
 */
export interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions {
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGrid}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions
   */
  mainItemGrid?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGrid
}

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
  }
}
