// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordResponseDataAttributes } from './GetAdGroupKeywordResponseDataAttributes'
import {
  GetAdGroupKeywordResponseDataAttributesFromJSON,
  GetAdGroupKeywordResponseDataAttributesFromJSONTyped,
  GetAdGroupKeywordResponseDataAttributesToJSON,
} from './GetAdGroupKeywordResponseDataAttributes'

/**
 *
 * @export
 * @interface GetAdGroupKeywordResponseData
 */
export interface GetAdGroupKeywordResponseData {
  /**
   * The id of the ad group containing the keywords.
   * @type {string}
   * @memberof GetAdGroupKeywordResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdGroupKeywordResponseData
   */
  type: GetAdGroupKeywordResponseDataTypeEnum
  /**
   *
   * @type {GetAdGroupKeywordResponseDataAttributes}
   * @memberof GetAdGroupKeywordResponseData
   */
  attributes: GetAdGroupKeywordResponseDataAttributes
}

/**
 * @export
 */
export const GetAdGroupKeywordResponseDataTypeEnum = {
  Keyword: 'keyword',
} as const
export type GetAdGroupKeywordResponseDataTypeEnum =
  typeof GetAdGroupKeywordResponseDataTypeEnum[keyof typeof GetAdGroupKeywordResponseDataTypeEnum]

/**
 * Check if a given object implements the GetAdGroupKeywordResponseData interface.
 */
export function instanceOfGetAdGroupKeywordResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdGroupKeywordResponseDataFromJSON(json: any): GetAdGroupKeywordResponseData {
  return GetAdGroupKeywordResponseDataFromJSONTyped(json, false)
}

export function GetAdGroupKeywordResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAdGroupKeywordResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetAdGroupKeywordResponseDataToJSON(
  value?: GetAdGroupKeywordResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAdGroupKeywordResponseDataAttributesToJSON(value.attributes),
  }
}
