import { UseButtonStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

export { ButtonProps as SecondaryButtonProps } from './utils/makeButton'

const styles: UseButtonStylesOpts = {
  normalBackground: 'systemGrayscale00',
  borderColor: 'systemGrayscale70',
  textColor: 'systemGrayscale70',
  hoverBackground: 'systemGrayscale10',
  activeBackground: 'systemGrayscale20',
  disabledBackground: 'systemGrayscale00',
  disabledBorderColor: 'systemGrayscale30',
  disabledTextColor: 'systemGrayscale30',
}

/** @deprecated Use SecondaryButton from 'components/ids-ads' instead */
export const SecondaryButton = makeButton('SecondaryButton', styles)
/** @deprecated Use SecondaryLinkButton from 'components/ids-ads' instead */
export const SecondaryLinkButton = makeLinkButton('SecondaryLinkButton', styles)
/** @deprecated Use SecondaryAnchorButton from 'components/ids-ads' instead */
export const SecondaryAnchorButton = makeAnchorButton('SecondaryAnchorButton', styles)
