// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdminAccountsResponseDataAttributesCorporateAddress
 */
export interface GetAdminAccountsResponseDataAttributesCorporateAddress {
  /**
   * Phone number
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  phoneNumber?: string
  /**
   * Address line 1
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressLine1: string
  /**
   * Address line 2
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressLine2?: string
  /**
   * City
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressCity: string
  /**
   * State
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressState: string
  /**
   * Country
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressCountry: string
  /**
   * Zip code
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributesCorporateAddress
   */
  addressZip: string
}

/**
 * Check if a given object implements the GetAdminAccountsResponseDataAttributesCorporateAddress interface.
 */
export function instanceOfGetAdminAccountsResponseDataAttributesCorporateAddress(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'addressLine1' in value
  isInstance = isInstance && 'addressCity' in value
  isInstance = isInstance && 'addressState' in value
  isInstance = isInstance && 'addressCountry' in value
  isInstance = isInstance && 'addressZip' in value

  return isInstance
}

export function GetAdminAccountsResponseDataAttributesCorporateAddressFromJSON(
  json: any
): GetAdminAccountsResponseDataAttributesCorporateAddress {
  return GetAdminAccountsResponseDataAttributesCorporateAddressFromJSONTyped(json, false)
}

export function GetAdminAccountsResponseDataAttributesCorporateAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsResponseDataAttributesCorporateAddress {
  if (json === undefined || json === null) {
    return json
  }
  return {
    phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
    addressLine1: json['address_line1'],
    addressLine2: !exists(json, 'address_line2') ? undefined : json['address_line2'],
    addressCity: json['address_city'],
    addressState: json['address_state'],
    addressCountry: json['address_country'],
    addressZip: json['address_zip'],
  }
}

export function GetAdminAccountsResponseDataAttributesCorporateAddressToJSON(
  value?: GetAdminAccountsResponseDataAttributesCorporateAddress | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    phone_number: value.phoneNumber,
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    address_city: value.addressCity,
    address_state: value.addressState,
    address_country: value.addressCountry,
    address_zip: value.addressZip,
  }
}
