// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentText } from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentText'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentText'
import type { PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitle } from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent
 */
export interface PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent
   */
  title?: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent
   */
  text?: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent {
  return PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
