// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseData } from './EnableDisplayAdGroupResponseData'
import {
  EnableDisplayAdGroupResponseDataFromJSON,
  EnableDisplayAdGroupResponseDataFromJSONTyped,
  EnableDisplayAdGroupResponseDataToJSON,
} from './EnableDisplayAdGroupResponseData'
import type { EnableDisplayAdGroupResponseMeta } from './EnableDisplayAdGroupResponseMeta'
import {
  EnableDisplayAdGroupResponseMetaFromJSON,
  EnableDisplayAdGroupResponseMetaFromJSONTyped,
  EnableDisplayAdGroupResponseMetaToJSON,
} from './EnableDisplayAdGroupResponseMeta'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponse
 */
export interface EnableDisplayAdGroupResponse {
  /**
   *
   * @type {EnableDisplayAdGroupResponseData}
   * @memberof EnableDisplayAdGroupResponse
   */
  data: EnableDisplayAdGroupResponseData
  /**
   *
   * @type {EnableDisplayAdGroupResponseMeta}
   * @memberof EnableDisplayAdGroupResponse
   */
  meta: EnableDisplayAdGroupResponseMeta
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponse interface.
 */
export function instanceOfEnableDisplayAdGroupResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function EnableDisplayAdGroupResponseFromJSON(json: any): EnableDisplayAdGroupResponse {
  return EnableDisplayAdGroupResponseFromJSONTyped(json, false)
}

export function EnableDisplayAdGroupResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: EnableDisplayAdGroupResponseDataFromJSON(json['data']),
    meta: EnableDisplayAdGroupResponseMetaFromJSON(json['meta']),
  }
}

export function EnableDisplayAdGroupResponseToJSON(
  value?: EnableDisplayAdGroupResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: EnableDisplayAdGroupResponseDataToJSON(value.data),
    meta: EnableDisplayAdGroupResponseMetaToJSON(value.meta),
  }
}
