import { css } from '@emotion/react'
import { ArrowLeftIcon, spacing, useTheme } from '@instacart/ids-core'
import { ReactNode } from 'react'
import toPx from 'common/toPx'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { ButtonRow, StatusPill } from 'components/molecules'
import { useLayoutContext } from 'context'
import { GenericMessageDescriptor, MessageIdType } from 'locales/types'

export interface DataItem {
  labelId: MessageIdType
  value: string
  valueTestId?: string
}

export interface PageToolbarProps {
  titleId?: MessageIdType
  actions?: ReactNode
  titleControls?: ReactNode
  dataItems?: DataItem[]
  title?: string
  subtitle?: GenericMessageDescriptor
  backId?: MessageIdType
  onBack?: () => void
  status?: string
}

function useStyles() {
  const theme = useTheme()

  return {
    root: css({
      background: theme.colors.systemGrayscale00,
      padding: toPx`0 ${spacing.s24}`,
    }),
    leftSection: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    leftSectionTitle: css({
      display: 'flex',
      alignItems: 'center',
    }),
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: toPx`${spacing.s12} 0`,
    }),
    title: css({
      ...theme.typography.subtitleLarge,
    }),
    subtitle: css({
      ...theme.typography.bodyMedium2,
    }),
    titleControls: css({
      marginLeft: spacing.s16,
    }),
    dataItems: css({
      display: 'flex',
      padding: toPx`${spacing.s12} 0`,
      borderTop: `1px solid ${theme.colors.systemGrayscale20}`,
    }),
    dataItem: css({
      ...theme.typography.bodySmall2,
      color: theme.colors.systemGrayscale70,
      marginLeft: spacing.s24,
      '&:first-of-type': {
        marginLeft: 0,
      },
      '> dt': {
        color: theme.colors.systemGrayscale50,
        display: 'inline',
      },
      '> dd': {
        display: 'inline',
      },
    }),
    back: css({
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '> a': {
        color: theme.colors.systemGrayscale70,
      },
      '> h2': {
        paddingLeft: spacing.s4,
        ...theme.typography.bodySmall1,
      },
    }),
    status: css({
      marginLeft: spacing.s12,
    }),
  }
}

export default function PageToolbar({
  titleId,
  actions,
  titleControls,
  dataItems,
  title,
  subtitle,
  backId,
  onBack,
  status,
}: PageToolbarProps) {
  const styles = useStyles()
  const intl = useIntl()
  useLayoutContext().useNoLayoutPadding()

  const backContent =
    backId && onBack ? (
      <div css={styles.back} onClick={onBack} onKeyDown={onBack} role="button" tabIndex={0}>
        <ArrowLeftIcon size={16} />
        <h2>{intl.formatMessage({ id: backId })}</h2>
      </div>
    ) : null

  return (
    <div css={styles.root}>
      <header data-testid="page-toolbar" css={styles.header}>
        <div css={styles.leftSection}>
          {backContent}
          <div css={styles.leftSectionTitle}>
            <h1 data-testid="page-toolbar-title" css={styles.title}>
              {title || (titleId && <FormattedMessage id={titleId} />)}
            </h1>
            {status && (
              <div css={styles.status}>
                <StatusPill status={status} />
              </div>
            )}
            {titleControls && <div css={styles.titleControls}>{titleControls}</div>}
          </div>
          {subtitle && (
            <div>
              <h2 css={styles.subtitle}>{intl.genericFormatMessage(subtitle)}</h2>
            </div>
          )}
        </div>
        <ButtonRow wide>{actions}</ButtonRow>
      </header>
      {!!dataItems?.length && (
        <dl css={styles.dataItems}>
          {dataItems.map(({ labelId, value, valueTestId }) => (
            <div key={labelId} css={styles.dataItem}>
              <dt>{`${intl.formatMessage({ id: labelId })}: `}</dt>
              <dd data-testid={valueTestId}>{value}</dd>
            </div>
          ))}
        </dl>
      )}
    </div>
  )
}
