import { css } from '@emotion/react'
import { FC } from 'react'
import { breakFormattingHelper } from 'common/intlUtils'
import FormattedMessage from 'components/FormattedMessage'
import InfoTooltip from 'components/InfoTooltip'
import { MessageIdType } from 'locales/types'

interface TableColumnLabelProps {
  labelId: MessageIdType
  tooltipMessageId?: MessageIdType
  tooltipHeight?: string
}

const styles = {
  tableColumnLabelContainer: css`
    display: flex;
    align-items: center;
  `,
  label: css`
    display: inline-block;
    margin-right: 4px;
  `,
  tooltip: css`
    text-transform: none !important;
  `,
}

const TableColumnLabel: FC<React.PropsWithChildren<TableColumnLabelProps>> = ({
  labelId,
  tooltipMessageId,
}) => {
  return (
    <div css={styles.tableColumnLabelContainer}>
      <div css={styles.label}>
        <FormattedMessage id={labelId} />
      </div>
      {tooltipMessageId && (
        <div css={styles.tooltip}>
          <InfoTooltip
            color="systemGrayscale50"
            tooltipContent={
              <FormattedMessage id={tooltipMessageId} values={{ ...breakFormattingHelper }} />
            }
          />
        </div>
      )}
    </div>
  )
}

export default TableColumnLabel
