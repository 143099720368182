// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabels } from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabels'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageBlockContentText
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageBlockContentText {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContentText
   */
  labels: Array<PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContentText
   */
  componentType: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextComponentTypeEnum = {
  BlockText: 'BLOCK_TEXT',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageBlockContentText interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlockContentText(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageBlockContentText {
  return PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageBlockContentText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageBlockContentText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
