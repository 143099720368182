// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsParamAssetFieldDecisions,
  instanceOfGetDisplayCampaignsParamAssetFieldDecisions,
  GetDisplayCampaignsParamAssetFieldDecisionsFromJSON,
  GetDisplayCampaignsParamAssetFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamAssetFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamAssetFieldDecisions'
import {
  GetDisplayCampaignsParamBrandPageBlockFieldDecisions,
  instanceOfGetDisplayCampaignsParamBrandPageBlockFieldDecisions,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsFromJSON,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamBrandPageBlockFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamBrandPageBlockFieldDecisions'
import {
  GetDisplayCampaignsParamBrandPageFieldDecisions,
  instanceOfGetDisplayCampaignsParamBrandPageFieldDecisions,
  GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSON,
  GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamBrandPageFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamBrandPageFieldDecisions'
import {
  GetDisplayCampaignsParamCreativeFieldDecisions,
  instanceOfGetDisplayCampaignsParamCreativeFieldDecisions,
  GetDisplayCampaignsParamCreativeFieldDecisionsFromJSON,
  GetDisplayCampaignsParamCreativeFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamCreativeFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamCreativeFieldDecisions'
import {
  GetDisplayCampaignsParamDisplayAdGroupFieldDecisions,
  instanceOfGetDisplayCampaignsParamDisplayAdGroupFieldDecisions,
  GetDisplayCampaignsParamDisplayAdGroupFieldDecisionsFromJSON,
  GetDisplayCampaignsParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamDisplayAdGroupFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamDisplayAdGroupFieldDecisions'
import {
  GetDisplayCampaignsParamProductAssetFieldDecisions,
  instanceOfGetDisplayCampaignsParamProductAssetFieldDecisions,
  GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSON,
  GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsParamProductAssetFieldDecisionsToJSON,
} from './GetDisplayCampaignsParamProductAssetFieldDecisions'

/**
 * @type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & GetDisplayCampaignsParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & GetDisplayCampaignsParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayCampaignsParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & GetDisplayCampaignsParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayCampaignsParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & GetDisplayCampaignsParamProductAssetFieldDecisions)

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayCampaignsParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayCampaignsParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayCampaignsParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayCampaignsParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayCampaignsParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayCampaignsParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayCampaignsParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return GetDisplayCampaignsParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayCampaignsParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayCampaignsParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
