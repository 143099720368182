import { getIn } from 'formik'
import { replace } from 'lodash'
import moment from 'moment'
import { ADS_API_PATH } from 'common/constants'
import { TimeFrame } from 'common/types'
import { formatDate } from 'common/utils-moment'
import { ExportDropdownOption } from 'components/Reporting/components/ExportDropdownSection'
import { AdGroupTabName } from 'pages/AdGroup/adGroup.constants'
import { ReportsApi } from 'service/apis/ReportsApi'
import { TasksApi } from 'service/apis/TasksApi'
import OpenAPIReportClient from 'service/openapi-client/report'
import { ApiReportsControllerAdGroupsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerAdGroupsInput'
import { ApiReportsControllerBrandPagesInput } from 'service/openapi/__codegen__/models/ApiReportsControllerBrandPagesInput'
import { ApiReportsControllerCampaignsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerCampaignsInput'
import { ApiReportsControllerDisplayAdGroupsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerDisplayAdGroupsInput'
import { ApiReportsControllerDisplayCampaignsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerDisplayCampaignsInput'
import { ApiReportsControllerDisplayKeywordsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerDisplayKeywordsInput'
import { ApiReportsControllerImpressionAndClicksDisplayProductInput } from 'service/openapi/__codegen__/models/ApiReportsControllerImpressionAndClicksDisplayProductInput'
import { ApiReportsControllerImpressionAndClicksSponsoredProductInput } from 'service/openapi/__codegen__/models/ApiReportsControllerImpressionAndClicksSponsoredProductInput'
import { ApiReportsControllerKeywordsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerKeywordsInput'
import { ApiReportsControllerProductsInput } from 'service/openapi/__codegen__/models/ApiReportsControllerProductsInput'
import { ApiReportsControllerSearchTermDisplayProductInput } from 'service/openapi/__codegen__/models/ApiReportsControllerSearchTermDisplayProductInput'
import { PostReportResponse } from 'service/openapi/__codegen__/models/PostReportResponse'
import { TaskInProgressResponseDataAttributesTaskTypeEnum as TaskTypeEnum } from 'service/openapi/__codegen__/models/TaskInProgressResponseDataAttributes'
import { TaskResponse } from 'service/openapi/__codegen__/models/TaskResponse'
import { ImpressionAndClicksReportOptions } from './components/ImpressionAndClicksModal/types'
import {
  DisplayAggregatedReport,
  DisplayDailyReport,
  DisplayImpressionAndClicksReport,
  DisplaySearchTermsReport,
  ExportCampaignType,
  ExportDataDropdownOptionsProps,
  FeaturedProductAggregatedReport,
  FeaturedProductDailyReport,
  PageLevel,
  Report,
  ReportOptions,
  ReportStatus,
  SPImpressionAndClicksReport,
} from './types'

export const FILE_NAME_DATE_FORMAT = 'YYYYMMDD'

export const ReportClient = new OpenAPIReportClient()

export function dateQueryString(date?: Date) {
  return date?.toISOString()?.slice(0, 10)
}

export const removeTimezoneFromDate = (date?: Date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10)
    : undefined

export function dateRangeParams(timeFrame: TimeFrame) {
  return {
    searchCriteriaDateRangeStartDate: dateQueryString(timeFrame?.dateRange?.from),
    searchCriteriaDateRangeEndDate: dateQueryString(timeFrame?.dateRange?.to),
  }
}

export function splitSort(sortBy?: string): [string?, ('asc' | 'desc')?] {
  if (!sortBy) return []
  const ind = sortBy.lastIndexOf('_')
  return [sortBy.slice(0, ind), sortBy.slice(ind + 1) as 'asc' | 'desc']
}

export function analyticsDateRangeParams(timeFrame?: TimeFrame) {
  if (!timeFrame?.dateRange?.from) return {}

  return {
    dateRangeStartDate: dateQueryString(timeFrame?.dateRange?.from),
    dateRangeEndDate: dateQueryString(timeFrame?.dateRange?.to),
  }
}

export function exportDataDropdownAggregatedOptions(
  props: ExportDataDropdownOptionsProps
): ExportDropdownOption[] {
  const { pageLevel, intl, campaignType = ExportCampaignType.DISPLAY, tabName } = props

  if (campaignType === ExportCampaignType.FEATURED_PRODUCT) {
    const keywordsLabelId = 'exports.allKeywords'
    const keywordsValue = FeaturedProductAggregatedReport.KEYWORD

    if (tabName) {
      return [
        ...(tabName === AdGroupTabName.KEYWORDS
          ? [
              {
                label: intl.formatMessage({
                  id: keywordsLabelId,
                }),
                value: keywordsValue,
                hasAttributionOptions: true,
              },
            ]
          : []),
        ...(tabName === AdGroupTabName.PRODUCTS
          ? [
              {
                label: intl.formatMessage({
                  id: 'exports.allProducts',
                }),
                value: FeaturedProductAggregatedReport.PRODUCT,
                hasAttributionOptions: true,
              },
            ]
          : []),
      ]
    }

    return [
      ...(pageLevel === PageLevel.DASHBOARD
        ? [
            {
              label: intl.formatMessage({ id: 'exports.allCampaigns' }),
              value: FeaturedProductAggregatedReport.CAMPAIGN,
              hasAttributionOptions: true,
            },
          ]
        : []),
      ...(pageLevel === PageLevel.DASHBOARD || pageLevel === PageLevel.CAMPAIGN
        ? [
            {
              label: intl.formatMessage({ id: 'exports.allAdgroups' }),
              value: FeaturedProductAggregatedReport.AD_GROUP,
              hasAttributionOptions: true,
            },
          ]
        : []),
      {
        label: intl.formatMessage({
          id: keywordsLabelId,
        }),
        value: keywordsValue,
        hasAttributionOptions: true,
      },
      {
        label: intl.formatMessage({ id: 'exports.allProducts' }),
        value: FeaturedProductAggregatedReport.PRODUCT,
        hasAttributionOptions: true,
      },
    ]
  }

  return [
    ...(pageLevel === PageLevel.CAMPAIGN
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allCampaigns' }),
            value: DisplayAggregatedReport.CAMPAIGN,
            hasAttributionOptions: true,
          },
        ]
      : []),
    ...(pageLevel === PageLevel.CAMPAIGN || pageLevel === PageLevel.AD_GROUP
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allAdgroups' }),
            value: DisplayAggregatedReport.AD_GROUP,
            hasAttributionOptions: true,
          },
        ]
      : [
          {
            label: intl.formatMessage({ id: 'common.adGroup' }),
            value: DisplayAggregatedReport.AD_GROUP,
            hasAttributionOptions: true,
          },
        ]),
    ...(pageLevel === PageLevel.CAMPAIGN || pageLevel === PageLevel.AD_GROUP
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allKeywords' }),
            value: DisplayAggregatedReport.KEYWORD,
            hasAttributionOptions: true,
          },
        ]
      : [
          {
            label: intl.formatMessage({ id: 'common.keywords' }),
            value: DisplayAggregatedReport.KEYWORD,
            hasAttributionOptions: true,
          },
        ]),
  ]
}

export function exportDataDropdownDailyOptions(
  props: ExportDataDropdownOptionsProps
): ExportDropdownOption[] {
  const { pageLevel, intl, campaignType = ExportCampaignType.DISPLAY, tabName } = props

  if (campaignType === ExportCampaignType.FEATURED_PRODUCT) {
    const keywordsLabelId = 'exports.allKeywords'
    const keywordsValue = FeaturedProductDailyReport.KEYWORD

    if (tabName) {
      return [
        ...(tabName === AdGroupTabName.KEYWORDS
          ? [
              {
                label: intl.formatMessage({
                  id: keywordsLabelId,
                }),
                value: keywordsValue,
                hasAttributionOptions: true,
              },
            ]
          : []),
        ...(tabName === AdGroupTabName.PRODUCTS
          ? [
              {
                label: intl.formatMessage({
                  id: 'exports.allProducts',
                }),
                value: FeaturedProductDailyReport.PRODUCT,
                hasAttributionOptions: true,
              },
            ]
          : []),
      ]
    }

    return [
      ...(pageLevel === PageLevel.DASHBOARD
        ? [
            {
              label: intl.formatMessage({ id: 'exports.allCampaigns' }),
              value: FeaturedProductDailyReport.CAMPAIGN,
              hasAttributionOptions: true,
            },
          ]
        : []),
      ...(pageLevel === PageLevel.DASHBOARD || pageLevel === PageLevel.CAMPAIGN
        ? [
            {
              label: intl.formatMessage({ id: 'exports.allAdgroups' }),
              value: FeaturedProductDailyReport.AD_GROUP,
              hasAttributionOptions: true,
            },
          ]
        : []),
      {
        label: intl.formatMessage({
          id: keywordsLabelId,
        }),
        value: keywordsValue,
        hasAttributionOptions: true,
      },
      {
        label: intl.formatMessage({ id: 'exports.allProducts' }),
        value: FeaturedProductDailyReport.PRODUCT,
        hasAttributionOptions: true,
      },
    ]
  }

  return [
    ...(pageLevel === PageLevel.CAMPAIGN
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allCampaigns' }),
            value: DisplayDailyReport.CAMPAIGN,
            hasAttributionOptions: true,
          },
        ]
      : []),
    ...(pageLevel === PageLevel.CAMPAIGN || pageLevel === PageLevel.AD_GROUP
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allAdgroups' }),
            value: DisplayDailyReport.AD_GROUP,
            hasAttributionOptions: true,
          },
        ]
      : [
          {
            label: intl.formatMessage({ id: 'common.adGroup' }),
            value: DisplayDailyReport.AD_GROUP,
            hasAttributionOptions: true,
          },
        ]),
    ...(pageLevel === PageLevel.CAMPAIGN || pageLevel === PageLevel.AD_GROUP
      ? [
          {
            label: intl.formatMessage({ id: 'exports.allKeywords' }),
            value: DisplayDailyReport.KEYWORD,
            hasAttributionOptions: true,
          },
        ]
      : [
          {
            label: intl.formatMessage({ id: 'common.keywords' }),
            value: DisplayDailyReport.KEYWORD,
            hasAttributionOptions: true,
          },
        ]),
  ]
}

export function exportDataDropdownOtherOptions(
  props: ExportDataDropdownOptionsProps
): ExportDropdownOption[] {
  const {
    pageLevel,
    campaignType = ExportCampaignType.DISPLAY,
    intl,
    isMRCReportingEnabled,
    isBroadMatchReportingEnabled,
  } = props

  if (
    (pageLevel === PageLevel.CAMPAIGN || pageLevel === PageLevel.DASHBOARD) &&
    campaignType === ExportCampaignType.FEATURED_PRODUCT &&
    isMRCReportingEnabled
  ) {
    return [
      {
        label: intl.formatMessage({ id: 'exports.impressionAndClicks' }),
        value: SPImpressionAndClicksReport.CAMPAIGN,
      },
    ]
  }
  if (pageLevel === PageLevel.CAMPAIGN && campaignType === ExportCampaignType.DISPLAY) {
    const options = []

    if (isMRCReportingEnabled) {
      options.push({
        label: intl.formatMessage({ id: 'exports.impressionAndClicks' }),
        value: DisplayImpressionAndClicksReport.CAMPAIGN,
      })
    }

    if (isBroadMatchReportingEnabled) {
      options.push({
        label: intl.formatMessage({ id: 'exports.searchTerms' }),
        value: DisplaySearchTermsReport.CAMPAIGN,
        hasAttributionOptions: true,
      })
    }

    return options
  }

  return []
}

const formatReportRange = (startDate?: string, endDate?: string) => {
  if (!startDate && !endDate) {
    const today = moment().format(FILE_NAME_DATE_FORMAT)
    return `lifetime_${today}`
  }

  const formattedStartDate = formatDate(startDate, FILE_NAME_DATE_FORMAT)
  const formattedEndDate = formatDate(endDate, FILE_NAME_DATE_FORMAT)
  return `${formattedStartDate}-${formattedEndDate}`
}

/* eslint-disable-next-line max-params */
export function generateReportName(
  reportType: Report,
  timeFrame?: TimeFrame,
  campaignName?: string,
  adGroupName?: string,
  attributionType?: string
) {
  const dateRange = timeFrame ? analyticsDateRangeParams(timeFrame) : undefined
  return generateReportNameGeneric({
    reportName: reportType,
    startDate: dateRange?.dateRangeStartDate,
    endDate: dateRange?.dateRangeEndDate,
    campaignName,
    adGroupName,
    attributionType,
  })
}

export function generateReportNameGeneric({
  reportName,
  startDate,
  endDate,
  campaignName,
  adGroupName,
  attributionType,
}: {
  reportName: string
  startDate?: string
  endDate?: string
  campaignName?: string
  adGroupName?: string
  attributionType?: string
}) {
  // attributionType will only be surfaced post MTA launch, this line will keep the code compatible pre-MTA
  let reportAttribution = attributionType?.replace('_', '-') // to prevent breaking on last_touch
  reportAttribution = reportAttribution ? `_${reportAttribution}` : ''
  const reportRange = formatReportRange(startDate, endDate)
  const campaignText = campaignName ? `${campaignName}_` : ''
  const adGroupText = adGroupName ? `${adGroupName}_` : ''
  const allPrefix = !campaignName && !adGroupName ? 'all' : ''

  return `${campaignText}${adGroupText}${allPrefix}${reportName}${reportAttribution}_${reportRange}`
}

export function mapReportTypeToString(reportType: Report): string {
  switch (reportType) {
    case FeaturedProductDailyReport.KEYWORD:
    case FeaturedProductAggregatedReport.KEYWORD:
    case FeaturedProductAggregatedReport.TOP_100_KEYWORD:
      return 'keywords'
    case FeaturedProductDailyReport.PRODUCT:
    case FeaturedProductAggregatedReport.PRODUCT:
      return 'products'
    case FeaturedProductAggregatedReport.AD_GROUP:
    case FeaturedProductDailyReport.AD_GROUP:
      return 'ad_groups'
    case FeaturedProductAggregatedReport.CAMPAIGN:
    case FeaturedProductDailyReport.CAMPAIGN:
      return 'campaigns'
    default:
      return reportType as string
  }
}

export interface ReportResponse {
  reportId: string
  hasMetricObscured: boolean
}

export const requestReport = async (
  reportType: Report,
  reportOptions: ReportOptions
): Promise<ReportResponse> => {
  let reportRequest: PostReportResponse
  return new Promise<ReportResponse>(async (resolve, reject) => {
    try {
      switch (reportType) {
        case DisplayAggregatedReport.CAMPAIGN:
        case DisplayDailyReport.CAMPAIGN:
          reportRequest = await ReportsApi.postReportsDisplayCampaigns({
            body: reportOptions as ApiReportsControllerDisplayCampaignsInput,
          })
          break
        case DisplayAggregatedReport.AD_GROUP:
        case DisplayDailyReport.AD_GROUP:
          reportRequest = await ReportsApi.postReportsDisplayAdGroups({
            body: reportOptions as ApiReportsControllerDisplayAdGroupsInput,
          })
          break
        case DisplayAggregatedReport.KEYWORD:
        case DisplayDailyReport.KEYWORD:
          reportRequest = await ReportsApi.postReportsDisplayKeywords({
            body: reportOptions as ApiReportsControllerDisplayKeywordsInput,
          })
          break
        case DisplayDailyReport.BRAND_PAGE:
        case DisplayDailyReport.BRAND_PAGE_TAG:
        case DisplayAggregatedReport.BRAND_PAGE:
        case DisplayAggregatedReport.BRAND_PAGE_TAG:
          reportRequest = await ReportsApi.postReportsBrandPages({
            body: reportOptions as ApiReportsControllerBrandPagesInput,
          })
          break
        case FeaturedProductAggregatedReport.CAMPAIGN:
        case FeaturedProductDailyReport.CAMPAIGN:
          reportRequest = await ReportsApi.postReportsCampaigns({
            body: reportOptions as ApiReportsControllerCampaignsInput,
          })
          break
        case FeaturedProductAggregatedReport.AD_GROUP:
        case FeaturedProductDailyReport.AD_GROUP:
          reportRequest = await ReportsApi.postReportsAdGroups({
            body: reportOptions as ApiReportsControllerAdGroupsInput,
          })
          break
        case FeaturedProductDailyReport.KEYWORD:
        case FeaturedProductAggregatedReport.KEYWORD:
        case FeaturedProductAggregatedReport.TOP_100_KEYWORD:
          reportRequest = await ReportsApi.postReportsKeywords({
            body: reportOptions as ApiReportsControllerKeywordsInput,
          })
          break
        case FeaturedProductDailyReport.PRODUCT:
        case FeaturedProductAggregatedReport.PRODUCT:
          reportRequest = await ReportsApi.postReportsProducts({
            body: reportOptions as ApiReportsControllerProductsInput,
          })
          break
        case SPImpressionAndClicksReport.CAMPAIGN: {
          const startDate: Date = getIn(reportOptions, 'dateRange.startDate')
          const endDate: Date = getIn(reportOptions, 'dateRange.endDate')

          const body: ApiReportsControllerImpressionAndClicksSponsoredProductInput = {
            ...reportOptions,
            dateRange: {
              startDate: removeTimezoneFromDate(startDate),
              endDate: removeTimezoneFromDate(endDate),
            },
          }
          return (reportRequest = await ReportsApi.postReportsImpressionAndClicksSponsoredProduct({
            body,
          }))
        }
        case DisplayImpressionAndClicksReport.CAMPAIGN:
          reportRequest = await ReportsApi.postReportsImpressionAndClicksDisplayProduct({
            body: reportOptions as ApiReportsControllerImpressionAndClicksDisplayProductInput,
          })
          break
        case DisplaySearchTermsReport.CAMPAIGN:
          reportRequest = await ReportsApi.postReportsSearchTermDisplayProduct({
            body: reportOptions as ApiReportsControllerSearchTermDisplayProductInput,
          })
          break
      }
      resolve({
        reportId: reportRequest.data.id,
        hasMetricObscured: !!reportRequest.data.attributes.hasObscuredMetrics,
      })
    } catch (err) {
      return reject(err)
    }
  })
}

export const waitForReport = async (reportId: string): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const pollInterval = setInterval(async () => {
        const result = await ReportsApi.getReportsId({ id: reportId })
        if (result.meta.status === 200) {
          clearInterval(pollInterval)
          if (result.data.attributes.status === 'failed') {
            resolve(ReportStatus.FAILED)
          } else if (result.data.attributes.status === 'completed') {
            resolve(ReportStatus.COMPLETED)
          }
        } else if (result.meta.status >= 400) {
          resolve(ReportStatus.FAILED)
        }
      }, 1000)
    } catch (err) {
      return reject(err)
    }
  })
}

export const downloadReport = (reportId: string) => {
  return ReportClient.downloadURI(reportId)
}

export const getTaskIdFromTaskUrl = (taskUrl?: string): string | undefined => {
  return taskUrl && replace(replace(taskUrl, '/api/tasks/', ''), `/${ADS_API_PATH}/tasks/`, '')
}

export const getDuplicatedLink = (result: TaskResponse, isVideo = false): string | null => {
  const { attributes } = result.data
  if (!attributes) return null

  const campaignType = attributes.payload?.request_params?.campaign_type
  const newCampaignUuid = attributes.payload?.result?.new_campaign_uuid
  const newAdGroupUuid = attributes.payload?.result?.new_ad_group_uuid
  const targetCampaignUuid = attributes.payload?.result?.target_campaign_uuid

  const type = attributes.taskType
  switch (type) {
    case TaskTypeEnum.DuplicateAdGroup:
      if (campaignType === 'featured_product') {
        return `/campaign/${targetCampaignUuid}/ad_group/${newAdGroupUuid}`
      }
      return `/display/campaign/${targetCampaignUuid}/ad_group/${newAdGroupUuid}`
    case TaskTypeEnum.DuplicateCampaign:
      if (campaignType === 'featured_product') {
        return `/campaign/${newCampaignUuid}`
      }

      if (isVideo) return `/video/campaign/${newCampaignUuid}`

      return `/display/campaign/${newCampaignUuid}`
  }
  return null
}

export interface DuplicationJobResult {
  status: string
  alreadyExistsInPrevious?: boolean
  redirectLink?: string | null
}

export const waitForDuplicationJob = async (
  taskId: string,
  isVideo = false
): Promise<DuplicationJobResult> => {
  return new Promise<DuplicationJobResult>(async (resolve, reject) => {
    try {
      const pollInterval = setInterval(async () => {
        const result = await TasksApi.getTasksId({ id: taskId })

        if (result.meta.status === 200) {
          if (result.data.attributes.taskStatus === 'failed') {
            clearInterval(pollInterval)
            if (result.data.attributes.error?.message === 'already_exists_in_previous') {
              resolve({
                status: 'failed',
                alreadyExistsInPrevious: true,
              })
            } else {
              resolve({ status: 'failed', alreadyExistsInPrevious: false })
            }
          } else if (result.data.attributes.taskStatus === 'completed') {
            clearInterval(pollInterval)
            resolve({
              status: 'completed',
              redirectLink: getDuplicatedLink(result, isVideo),
            })
          }
        } else if (result.meta.status >= 400) {
          clearInterval(pollInterval)
          resolve({ status: 'failed' })
        }
      }, 1000)
    } catch (err) {
      return reject(err)
    }
  })
}

export const formatExportDateRange = (
  impressionAndClickOptions: ImpressionAndClicksReportOptions
) => {
  const {
    dateRange: { startDate, endDate },
  } = impressionAndClickOptions

  return {
    ...impressionAndClickOptions,
    dateRange: {
      startDate: removeTimezoneFromDate(startDate),
      endDate: removeTimezoneFromDate(endDate),
    },
  }
}
