// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountsIdRetailersParamDataAttributesRetailers } from './GetAccountsIdRetailersParamDataAttributesRetailers'
import {
  GetAccountsIdRetailersParamDataAttributesRetailersFromJSON,
  GetAccountsIdRetailersParamDataAttributesRetailersFromJSONTyped,
  GetAccountsIdRetailersParamDataAttributesRetailersToJSON,
} from './GetAccountsIdRetailersParamDataAttributesRetailers'

/**
 *
 * @export
 * @interface GetRetailersResponseDataAttributes
 */
export interface GetRetailersResponseDataAttributes {
  /**
   * Retailers linked to this account if the account is a retailer account
   * @type {Array<GetAccountsIdRetailersParamDataAttributesRetailers>}
   * @memberof GetRetailersResponseDataAttributes
   */
  retailers?: Array<GetAccountsIdRetailersParamDataAttributesRetailers>
}

/**
 * Check if a given object implements the GetRetailersResponseDataAttributes interface.
 */
export function instanceOfGetRetailersResponseDataAttributes(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function GetRetailersResponseDataAttributesFromJSON(
  json: any
): GetRetailersResponseDataAttributes {
  return GetRetailersResponseDataAttributesFromJSONTyped(json, false)
}

export function GetRetailersResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetRetailersResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    retailers: !exists(json, 'retailers')
      ? undefined
      : (json['retailers'] as Array<any>).map(
          GetAccountsIdRetailersParamDataAttributesRetailersFromJSON
        ),
  }
}

export function GetRetailersResponseDataAttributesToJSON(
  value?: GetRetailersResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    retailers:
      value.retailers === undefined
        ? undefined
        : (value.retailers as Array<any>).map(
            GetAccountsIdRetailersParamDataAttributesRetailersToJSON
          ),
  }
}
