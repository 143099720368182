import { UseButtonStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

export { ButtonProps as PrimaryButtonProps } from './utils/makeButton'

const styles: UseButtonStylesOpts = {
  normalBackground: 'systemSuccessRegular',
  borderColor: 'systemSuccessDark',
  textColor: 'systemGrayscale00',
  hoverBackground: 'systemSuccessDark',
  activeBackground: 'brandPrimaryExtraDark',
  disabledBackground: 'systemGrayscale30',
  disabledBorderColor: 'systemGrayscale30',
}

/** @deprecated Use PrimaryButton from 'components/ids-ads' instead */
export const PrimaryButton = makeButton('PrimaryButton', styles)
/** @deprecated Use PrimaryLinkButton from 'components/ids-ads' instead */
export const PrimaryLinkButton = makeLinkButton('PrimaryLinkButton', styles)
/** @deprecated Use PrimaryAnchorButton from 'components/ids-ads' instead */
export const PrimaryAnchorButton = makeAnchorButton('PrimaryAnchorButton', styles)
