// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
 */
export interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage {
  /**
   * Title of the page.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  title: string
  /**
   * Generated, unique id of the page.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  id: string
  /**
   * Version of the page.
   * @type {number}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  version: number
  /**
   * Slug of the page.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  slug: string
  /**
   *
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  type: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageTypeEnum
  /**
   * Array of page blocks and their content.
   * @type {Array<GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks>}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
   */
  blocks: Array<GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks>
}

/**
 * @export
 */
export const GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageTypeEnum = {
  DisplayLandingPageV1: 'display_landing_page.v1',
} as const
export type GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageTypeEnum =
  typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageTypeEnum[keyof typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageTypeEnum]

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'version' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'blocks' in value

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSONTyped(
    json,
    false
  )
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    id: json['id'],
    version: json['version'],
    slug: json['slug'],
    type: json['type'],
    blocks: (json['blocks'] as Array<any>).map(
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSON
    ),
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    id: value.id,
    version: value.version,
    slug: value.slug,
    type: value.type,
    blocks: (value.blocks as Array<any>).map(
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksToJSON
    ),
  }
}
