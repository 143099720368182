// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostBrandPagesParamBlockImageAndTextContent
 */
export interface PostBrandPagesParamBlockImageAndTextContent {
  /**
   * Short title/blurb for the block.
   * @type {string}
   * @memberof PostBrandPagesParamBlockImageAndTextContent
   */
  title: string
  /**
   * Paragraph(s) of text below the title.
   * @type {string}
   * @memberof PostBrandPagesParamBlockImageAndTextContent
   */
  text: string
}

/**
 * Check if a given object implements the PostBrandPagesParamBlockImageAndTextContent interface.
 */
export function instanceOfPostBrandPagesParamBlockImageAndTextContent(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'text' in value

  return isInstance
}

export function PostBrandPagesParamBlockImageAndTextContentFromJSON(
  json: any
): PostBrandPagesParamBlockImageAndTextContent {
  return PostBrandPagesParamBlockImageAndTextContentFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockImageAndTextContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockImageAndTextContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    text: json['text'],
  }
}

export function PostBrandPagesParamBlockImageAndTextContentToJSON(
  value?: PostBrandPagesParamBlockImageAndTextContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    text: value.text,
  }
}
