// TODO: Contribute this back to @instacart/ids-tooling
// https://github.com/instacart/instacart-design-system-web/blob/master/packages/tooling/src/atoms/Input/LabelBase/LabelBase.tsx
import { Theme, useTheme, Base, combineStyles } from '@instacart/ids-core'
import { ComponentPropsWithoutRef } from 'react'

export const DEFAULT_OPTIONAL_TEXT = 'optional'

const getStyles = (theme: Theme) =>
  ({
    label: {
      color: theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium1,
    },
    content: {},
    optional: {
      ...theme.typography.bodyMedium2,
      // Using CSS to add this decoration so that downstream users can override it
      '&::before': {
        content: "' - '",
      },
    },
    disabled: {
      color: theme.colors.systemGrayscale30,
    },
  } as const)

export interface LabelBaseProps extends ComponentPropsWithoutRef<'label'>, Base<typeof getStyles> {
  disabled?: boolean
  isOptional?: boolean
  optionalText?: string
  children: string
  // Intentionally requiring htmlFor
  htmlFor: string
}

export const LabelBase = ({
  styles,
  children,
  isOptional = false,
  optionalText = DEFAULT_OPTIONAL_TEXT,
  htmlFor,
  disabled,
  ...rest
}: LabelBaseProps) => {
  const theme = useTheme()
  const {
    label,
    content,
    optional,
    disabled: disabledStyles,
  } = combineStyles(getStyles(theme), styles)

  const css = [
    label,
    {
      '&.disabled': disabledStyles,
    },
  ]

  return (
    <label
      css={css}
      htmlFor={htmlFor}
      className={disabled ? 'disabled' : undefined}
      data-testid="label-base"
      {...rest}
    >
      <span css={content}>
        {children}
        {isOptional ? <span css={optional}>{optionalText}</span> : null}
      </span>
    </label>
  )
}
