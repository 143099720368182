// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContent } from './PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContent'
import {
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContentFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContentFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContentToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContent'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot
 */
export interface PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot {
  /**
   *
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot
   */
  type: string
  /**
   * Brand Page block highlight product ids
   * @type {Array<string>}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot
   */
  highlightedProductIds?: Array<string>
  /**
   * Brand Page block product ids
   * @type {Array<string>}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot
   */
  productIds?: Array<string>
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContent}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot
   */
  content?: PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContent
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot {
  return PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSONTyped(json, false)
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProductIds: !exists(json, 'highlighted_product_ids')
      ? undefined
      : json['highlighted_product_ids'],
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
    content: !exists(json, 'content')
      ? undefined
      : PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContentFromJSON(json['content']),
  }
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotToJSON(
  value?: PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_product_ids: value.highlightedProductIds,
    product_ids: value.productIds,
    content: PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotContentToJSON(value.content),
  }
}
