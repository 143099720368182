// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupKeywordsControllerUpdateInputKeyword } from './ApiAdGroupKeywordsControllerUpdateInputKeyword'
import {
  ApiAdGroupKeywordsControllerUpdateInputKeywordFromJSON,
  ApiAdGroupKeywordsControllerUpdateInputKeywordFromJSONTyped,
  ApiAdGroupKeywordsControllerUpdateInputKeywordToJSON,
} from './ApiAdGroupKeywordsControllerUpdateInputKeyword'

/**
 *
 * @export
 * @interface ApiAdGroupKeywordsControllerUpdateInput
 */
export interface ApiAdGroupKeywordsControllerUpdateInput {
  /**
   *
   * @type {ApiAdGroupKeywordsControllerUpdateInputKeyword}
   * @memberof ApiAdGroupKeywordsControllerUpdateInput
   */
  keyword: ApiAdGroupKeywordsControllerUpdateInputKeyword
}

/**
 * Check if a given object implements the ApiAdGroupKeywordsControllerUpdateInput interface.
 */
export function instanceOfApiAdGroupKeywordsControllerUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keyword' in value

  return isInstance
}

export function ApiAdGroupKeywordsControllerUpdateInputFromJSON(
  json: any
): ApiAdGroupKeywordsControllerUpdateInput {
  return ApiAdGroupKeywordsControllerUpdateInputFromJSONTyped(json, false)
}

export function ApiAdGroupKeywordsControllerUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupKeywordsControllerUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keyword: ApiAdGroupKeywordsControllerUpdateInputKeywordFromJSON(json['keyword']),
  }
}

export function ApiAdGroupKeywordsControllerUpdateInputToJSON(
  value?: ApiAdGroupKeywordsControllerUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keyword: ApiAdGroupKeywordsControllerUpdateInputKeywordToJSON(value.keyword),
  }
}
