// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ProductsSuggestedBidResponseDataAttributes
 */
export interface ProductsSuggestedBidResponseDataAttributes {
  /**
   * Suggested Bid
   * @type {number}
   * @memberof ProductsSuggestedBidResponseDataAttributes
   */
  suggestedBid: number
  /**
   * Uncompetitive Bid
   * @type {number}
   * @memberof ProductsSuggestedBidResponseDataAttributes
   */
  uncompetitiveBid: number
  /**
   * Minimum Bid
   * @type {number}
   * @memberof ProductsSuggestedBidResponseDataAttributes
   */
  minBid: number
  /**
   * If the bid is confident
   * @type {boolean}
   * @memberof ProductsSuggestedBidResponseDataAttributes
   */
  isConfidentBid: boolean
  /**
   * Bid trace info
   * @type {Array<string>}
   * @memberof ProductsSuggestedBidResponseDataAttributes
   */
  traceInfo?: Array<string>
}

/**
 * Check if a given object implements the ProductsSuggestedBidResponseDataAttributes interface.
 */
export function instanceOfProductsSuggestedBidResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'suggestedBid' in value
  isInstance = isInstance && 'uncompetitiveBid' in value
  isInstance = isInstance && 'minBid' in value
  isInstance = isInstance && 'isConfidentBid' in value

  return isInstance
}

export function ProductsSuggestedBidResponseDataAttributesFromJSON(
  json: any
): ProductsSuggestedBidResponseDataAttributes {
  return ProductsSuggestedBidResponseDataAttributesFromJSONTyped(json, false)
}

export function ProductsSuggestedBidResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductsSuggestedBidResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    suggestedBid: json['suggested_bid'],
    uncompetitiveBid: json['uncompetitive_bid'],
    minBid: json['min_bid'],
    isConfidentBid: json['is_confident_bid'],
    traceInfo: !exists(json, 'trace_info') ? undefined : json['trace_info'],
  }
}

export function ProductsSuggestedBidResponseDataAttributesToJSON(
  value?: ProductsSuggestedBidResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    suggested_bid: value.suggestedBid,
    uncompetitive_bid: value.uncompetitiveBid,
    min_bid: value.minBid,
    is_confident_bid: value.isConfidentBid,
    trace_info: value.traceInfo,
  }
}
