// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamAssetFieldDecisionsAltText } from './GetVideoAdGroupsParamAssetFieldDecisionsAltText'
import {
  GetVideoAdGroupsParamAssetFieldDecisionsAltTextFromJSON,
  GetVideoAdGroupsParamAssetFieldDecisionsAltTextFromJSONTyped,
  GetVideoAdGroupsParamAssetFieldDecisionsAltTextToJSON,
} from './GetVideoAdGroupsParamAssetFieldDecisionsAltText'
import type { GetVideoAdGroupsParamAssetFieldDecisionsImage } from './GetVideoAdGroupsParamAssetFieldDecisionsImage'
import {
  GetVideoAdGroupsParamAssetFieldDecisionsImageFromJSON,
  GetVideoAdGroupsParamAssetFieldDecisionsImageFromJSONTyped,
  GetVideoAdGroupsParamAssetFieldDecisionsImageToJSON,
} from './GetVideoAdGroupsParamAssetFieldDecisionsImage'
import type { GetVideoAdGroupsParamAssetFieldDecisionsVideo } from './GetVideoAdGroupsParamAssetFieldDecisionsVideo'
import {
  GetVideoAdGroupsParamAssetFieldDecisionsVideoFromJSON,
  GetVideoAdGroupsParamAssetFieldDecisionsVideoFromJSONTyped,
  GetVideoAdGroupsParamAssetFieldDecisionsVideoToJSON,
} from './GetVideoAdGroupsParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamAssetFieldDecisions
 */
export interface GetVideoAdGroupsParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetVideoAdGroupsParamAssetFieldDecisionsAltText}
   * @memberof GetVideoAdGroupsParamAssetFieldDecisions
   */
  altText?: GetVideoAdGroupsParamAssetFieldDecisionsAltText
  /**
   *
   * @type {GetVideoAdGroupsParamAssetFieldDecisionsImage}
   * @memberof GetVideoAdGroupsParamAssetFieldDecisions
   */
  image?: GetVideoAdGroupsParamAssetFieldDecisionsImage
  /**
   *
   * @type {GetVideoAdGroupsParamAssetFieldDecisionsVideo}
   * @memberof GetVideoAdGroupsParamAssetFieldDecisions
   */
  video?: GetVideoAdGroupsParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamAssetFieldDecisions interface.
 */
export function instanceOfGetVideoAdGroupsParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetVideoAdGroupsParamAssetFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamAssetFieldDecisions {
  return GetVideoAdGroupsParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetVideoAdGroupsParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetVideoAdGroupsParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : GetVideoAdGroupsParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function GetVideoAdGroupsParamAssetFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: GetVideoAdGroupsParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetVideoAdGroupsParamAssetFieldDecisionsImageToJSON(value.image),
    video: GetVideoAdGroupsParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
