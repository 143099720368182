module.exports.teams = {
  ADS_FORMATS: 'ads_formats',
  ADS_INSIGHTS_EXPERIENCE: 'ads_platform_insights_experience',
  ADS_KEYWORD_TARGETING_AND_RETRIVAL: 'ads_keyword_targeting_and_retrieval',
  ADS_MERCHANDISING_FORMATS: 'ads_merchandising_formats',
  ADS_QUALITY: 'ads_quality',
  ADS_REPORTING_EXPERIENCE: 'ads_reporting_experience',
  ADS_SAVINGS_FORMATS: 'ads_savings_formats',
  ADVERTISER_PLATFORM_BRAND_ENABLEMENT: 'ads_platform_brand_enablement',
  ADVERTISER_PLATFORM_CAMPAIGN_MANAGEMENT: 'ads_campaign_management',
  ADVERTISER_PLATFORM_CORE: 'ads_platform_core',
}
