import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useHeaderHeight } from 'components/Header/header.utils'

export interface LayoutContextData {
  // move page content rightwards
  pageShiftRight: boolean
  useRightShiftPage(): void
  // sub-sidebar hidden
  subSidebarHidden: boolean
  useHideSubSidebar(): void
  // Brand hierarchy subside Bar
  toggleBrandHierarchySubSideBar(): void
  hideBrandHierarchySubSideBar(): void
  useBrandHierarchySubSideBar: boolean
  productsSearchEntityId: string
  useProductsSearchEntityId(targetEntityId: string): void
  // scrolling
  activeElementId: string
  setActiveElementId(el: string): void
  setSubSidebarHidden(subSidebarHidden: boolean): void
  layoutPadding: boolean
  useNoLayoutPadding(): void
  sidebarLocked: boolean
  lockSidebar(state: boolean): void
}

function notImplemented(description: string) {
  return () => {
    throw new Error(
      `${description} is not implemented. Maybe this component is not rendered under a LayoutProvider?`
    )
  }
}

export const LayoutContext = createContext<LayoutContextData>({
  pageShiftRight: false,
  useRightShiftPage: notImplemented('useRightShiftPage'),
  subSidebarHidden: false,
  useHideSubSidebar: notImplemented('useHideSubSidebar'),
  toggleBrandHierarchySubSideBar: notImplemented('toggleBrandHierarchySubSideBar'),
  hideBrandHierarchySubSideBar: notImplemented('hideBrandHierarchySubSideBar,'),
  useBrandHierarchySubSideBar: false,
  productsSearchEntityId: '',
  useProductsSearchEntityId: notImplemented('useProductsSearchEntityId'),
  activeElementId: '',
  setActiveElementId: notImplemented('setActiveElementId'),
  setSubSidebarHidden: notImplemented('setSubSidebarHidden'),
  layoutPadding: true,
  useNoLayoutPadding: notImplemented('useNoLayoutPadding'),
  sidebarLocked: false,
  lockSidebar: notImplemented('lockSidebar'),
})

export const useLayoutContext = () => useContext(LayoutContext)

export function LayoutProvider({ children }: { children: ReactNode }) {
  const headerHeight = useHeaderHeight()
  const [pageShiftRight, setPageShiftRight] = useState(false)
  const [subSidebarHidden, setSubSidebarHidden] = useState(false)
  const [activeElementId, setActiveElementId] = useState('')
  const [useBrandHierarchySubSideBar, setUseBrandHierarchySubSideBar] = useState(false)
  const [productsSearchEntityId, setProductsSearchEntityId] = useState('')
  const [layoutPadding, setLayoutPadding] = useState(true)
  const [sidebarLocked, setSidebarLocked] = useState(false)

  const useRightShiftPage = () => {
    useEffect(() => {
      setPageShiftRight(true)
      return () => setPageShiftRight(false)
    })
  }

  const useHideSubSidebar = () => {
    useEffect(() => {
      setSubSidebarHidden(true)
      return () => setSubSidebarHidden(false)
    })
  }

  const toggleBrandHierarchySubSideBar = () => {
    setActiveElementId('')
    setProductsSearchEntityId('')
    setUseBrandHierarchySubSideBar(prevUseBrandHierarchySidebar => !prevUseBrandHierarchySidebar)
  }

  const hideBrandHierarchySubSideBar = () => {
    setActiveElementId('')
    setProductsSearchEntityId('')
    setUseBrandHierarchySubSideBar(false)
  }

  const useProductsSearchEntityId = (targetEntityId: string) => {
    setProductsSearchEntityId(targetEntityId)
  }

  const useNoLayoutPadding = () => {
    useEffect(() => {
      setLayoutPadding(false)
      return () => setLayoutPadding(true)
    })
  }

  const lockSidebar = (state: boolean) => {
    setSidebarLocked(state)
  }

  useEffect(() => {
    function scrollToSection() {
      if (!activeElementId) return

      const element = document.getElementById(activeElementId)

      if (element) {
        const scrollPosition = element.offsetTop - headerHeight - 120
        window.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' })
      }
    }

    scrollToSection()
  }, [activeElementId, headerHeight])

  const value: LayoutContextData = {
    pageShiftRight,
    useRightShiftPage,
    subSidebarHidden,
    useHideSubSidebar,
    toggleBrandHierarchySubSideBar,
    hideBrandHierarchySubSideBar,
    useBrandHierarchySubSideBar,
    productsSearchEntityId,
    useProductsSearchEntityId,
    activeElementId,
    setActiveElementId,
    setSubSidebarHidden,
    layoutPadding,
    useNoLayoutPadding,
    sidebarLocked,
    lockSidebar,
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}
