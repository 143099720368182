import { object, string } from 'common/validation'
import { BannerFieldFormValue } from '../../utils/types'

export interface BrandPageFormFields {
  id?: string
  title: string
  highlightedProductIds: string[]
  additionalProductIds: string[]
  heroBanner: BannerFieldFormValue
  blockTypeToIdMap: { [key: string]: string }
}

export const brandPageAssetsSchema = object({
  id: string().default(''),
  urls: object({
    previewUrl: string().default(''),
    uploadedUri: string().default(''),
  }),
})
