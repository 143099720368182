/**
 * Generates a reference to a script element for dynamic inclusion, as well
 * as for teardown on un-mount
 * @param scriptSrc the src of the script which should be included
 * @param onLoad a function which will be called after load
 */
export const generateScriptElement = (scriptSrc: string, onLoad: () => void = () => null) => {
  const script = document.createElement('script')

  script.src = scriptSrc
  script.async = true
  script.defer = true
  script.onload = onLoad

  return script
}
