// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
 */
export interface GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo {
  /**
   * Number of won auctions
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
   */
  wonAuctions: number
  /**
   * Number of eligible auctions
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
   */
  eligibleAuctions: number
  /**
   * Number of filtered out auctions
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
   */
  filteredOutAuctions: number
  /**
   * Average winning bid
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
   */
  avgWinningBid: number
}

/**
 * Check if a given object implements the GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo interface.
 */
export function instanceOfGetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'wonAuctions' in value
  isInstance = isInstance && 'eligibleAuctions' in value
  isInstance = isInstance && 'filteredOutAuctions' in value
  isInstance = isInstance && 'avgWinningBid' in value

  return isInstance
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSON(
  json: any
): GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo {
  return GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSONTyped(json, false)
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wonAuctions: json['won_auctions'],
    eligibleAuctions: json['eligible_auctions'],
    filteredOutAuctions: json['filtered_out_auctions'],
    avgWinningBid: json['avg_winning_bid'],
  }
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoToJSON(
  value?: GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    won_auctions: value.wonAuctions,
    eligible_auctions: value.eligibleAuctions,
    filtered_out_auctions: value.filteredOutAuctions,
    avg_winning_bid: value.avgWinningBid,
  }
}
