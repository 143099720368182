import { css } from '@emotion/react'
import { layers } from '@instacart/ids-core'
import omit from 'lodash/omit'
import {
  MouseEvent,
  Component,
  FC,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useRef,
  useState,
} from 'react'
import ClickOutside from 'react-click-outside'
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker'
import ReactDayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { DATE_DISPLAY_FORMAT, DATE_PICKER_DISPLAY_FORMAT } from 'common/utils-moment'
import { DateRangePickerStyles } from 'components/DateRangePicker/DateRangePickerStyles'
// TODO: InputDate to be moved to @ids-tooling
// https://instacart.atlassian.net/browse/ADS-12626
import { InputDate, InputDateProps } from './InputDate'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'
import { hintId } from './utils'

export interface DateInputFieldProps
  extends Omit<InputFieldProps<InputDateProps>, 'value' | 'onChange'> {
  value?: Date
  onChange: (newDate: Date) => void
  dayPickerProps?: DayPickerProps
}

type ReactDayPickerComponentProp = DayPickerInputProps['component']
type ReactDayPickerOverlayComponentProp = DayPickerInputProps['overlayComponent']

interface CustomDayPickerInputProps {
  innerRef: ForwardedRef<HTMLInputElement>
  setCloseOverlay: (value: boolean) => void
  inputProps: DateInputFieldProps
  renderProps: ReactDayPickerComponentProp
}

interface CustomDayPickerOverlayProps {
  renderProps: ReactDayPickerComponentProp
  closeOverlay: boolean
  overlayClickOutside: (ev: MouseEvent<HTMLElement>) => void
}

const dayPickerInput = css({
  '.DayPickerInput': {
    width: '100%',
    lineHeight: '18px',
  },
  '.DayPickerInput-Overlay': {
    zIndex: layers.l2,
  },
})

class CustomDayPickerInputComponent extends Component<CustomDayPickerInputProps> {
  focus() {
    const ref = this.props.innerRef as MutableRefObject<HTMLDivElement>
    ref.current.focus()
  }

  render() {
    const { id, error, ...rest } = this.props.inputProps
    const validInputProps = omit(rest, ['onChange', 'dayPickerProps'])

    return (
      <InputDate
        id={id}
        invalid={!!error}
        instructionInputTextId={hintId(id)}
        {...validInputProps}
        {...this.props.renderProps}
        ref={this.props.innerRef}
        onFocus={() => this.props.setCloseOverlay(false)}
      />
    )
  }
}

// Custom ClickOutside overlay to handle known issue with closing Date Picker
// https://github.com/gpbl/react-day-picker/issues/723
const CustomDatePickerOverlay = ({
  renderProps,
  closeOverlay,
  overlayClickOutside,
}: CustomDayPickerOverlayProps) => {
  const { classNames, children, ...props } = renderProps

  if (closeOverlay) {
    return null
  }

  const validPropsForWrapper = omit(props, ['input', 'selectedDay'])

  return (
    <ClickOutside
      onClickOutside={overlayClickOutside}
      className={classNames.overlayWrapper}
      {...validPropsForWrapper}
    >
      <div className={classNames.overlay} data-testid="date-input-field-overlay">
        {children}
      </div>
    </ClickOutside>
  )
}

const DayPickerInput = (inputProps: DateInputFieldProps) => {
  const [closeOverlay, setCloseOverlay] = useState(false)

  const inputDateRef = useRef<HTMLInputElement>(null)
  const overlayClickOutside = () => {
    if (document.activeElement !== inputDateRef.current) {
      setCloseOverlay(true)
    }
  }

  const { value, onChange, dayPickerProps } = inputProps

  const handleDateChange = async (newDate: Date) => {
    await onChange(newDate)

    // keep focus on input when input is being typed
    inputDateRef?.current?.focus()
  }

  return (
    <div css={dayPickerInput}>
      <DateRangePickerStyles />
      <ReactDayPickerInput
        value={value}
        placeholder={DATE_PICKER_DISPLAY_FORMAT}
        format={DATE_PICKER_DISPLAY_FORMAT}
        formatDate={MomentLocaleUtils.formatDate}
        parseDate={dateStr =>
          MomentLocaleUtils.parseDate(dateStr.replace(/ /g, ''), DATE_DISPLAY_FORMAT)
        }
        onDayChange={handleDateChange}
        dayPickerProps={dayPickerProps}
        inputProps={{ ref: inputDateRef }}
        overlayComponent={
          inputProps.readOnly
            ? () => null
            : (renderProps: ReactDayPickerComponentProp) => (
                <CustomDatePickerOverlay
                  renderProps={renderProps}
                  closeOverlay={closeOverlay}
                  overlayClickOutside={overlayClickOutside}
                />
              )
        }
        component={forwardRef<HTMLInputElement>(
          (renderProps: ReactDayPickerOverlayComponentProp, ref) => (
            <CustomDayPickerInputComponent
              innerRef={ref}
              setCloseOverlay={setCloseOverlay}
              inputProps={inputProps}
              renderProps={renderProps}
            />
          )
        )}
      />
    </div>
  )
}
// Currently, @instacart/ids-tooling throws a composition error when rendering <DayPickerInput /> within <InputWrapper />
// because it only allows InputText|InputCurrency|InputPercent components. Issue originates here:
// https://github.com/instacart/instacart-design-system-web/blob/master/packages/tooling/src/molecules/Input/InputWrapper/validations.tsx#L25
DayPickerInput.displayName = 'InputText'

export const DateInputField: FC<React.PropsWithChildren<DateInputFieldProps>> = ({
  id,
  value,
  onChange,
  error,
  ...rest
}) => (
  <InputFieldContainer id={id} error={error} {...rest}>
    <DayPickerInput
      id={id}
      onChange={onChange}
      invalid={!!error}
      instructionInputTextId={hintId(id)}
      value={value}
      {...rest}
    />
  </InputFieldContainer>
)

DateInputField.displayName = 'DateInputField'
