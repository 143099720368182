// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerProviDisplayProductLinesInputDateRange } from './ApiReportsControllerProviDisplayProductLinesInputDateRange'
import {
  ApiReportsControllerProviDisplayProductLinesInputDateRangeFromJSON,
  ApiReportsControllerProviDisplayProductLinesInputDateRangeFromJSONTyped,
  ApiReportsControllerProviDisplayProductLinesInputDateRangeToJSON,
} from './ApiReportsControllerProviDisplayProductLinesInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerProviDisplayProductLinesInput
 */
export interface ApiReportsControllerProviDisplayProductLinesInput {
  /**
   * Send this parameter with the ID of a specific ad group to narrow the data returned on your report to just data from that specific ad group.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  adGroupId?: string
  /**
   * Send this parameter with the ID of a specific campaign to narrow the data returned on your report to just data from that specific campaign. The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  campaignId?: string
  /**
   *
   * @type {ApiReportsControllerProviDisplayProductLinesInputDateRange}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  dateRange?: ApiReportsControllerProviDisplayProductLinesInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  segment?: ApiReportsControllerProviDisplayProductLinesInputSegmentEnum
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerProviDisplayProductLinesInput
   */
  name?: string
}

/**
 * @export
 */
export const ApiReportsControllerProviDisplayProductLinesInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerProviDisplayProductLinesInputSegmentEnum =
  typeof ApiReportsControllerProviDisplayProductLinesInputSegmentEnum[keyof typeof ApiReportsControllerProviDisplayProductLinesInputSegmentEnum]

/**
 * Check if a given object implements the ApiReportsControllerProviDisplayProductLinesInput interface.
 */
export function instanceOfApiReportsControllerProviDisplayProductLinesInput(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerProviDisplayProductLinesInputFromJSON(
  json: any
): ApiReportsControllerProviDisplayProductLinesInput {
  return ApiReportsControllerProviDisplayProductLinesInputFromJSONTyped(json, false)
}

export function ApiReportsControllerProviDisplayProductLinesInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerProviDisplayProductLinesInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroupId: !exists(json, 'ad_group_id') ? undefined : json['ad_group_id'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerProviDisplayProductLinesInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function ApiReportsControllerProviDisplayProductLinesInputToJSON(
  value?: ApiReportsControllerProviDisplayProductLinesInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group_id: value.adGroupId,
    campaign_id: value.campaignId,
    date_range: ApiReportsControllerProviDisplayProductLinesInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    attribution_model: value.attributionModel,
    name: value.name,
  }
}
