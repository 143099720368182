import { layers, spacing } from '@instacart/ids-core'
import { CSSProperties } from 'react'
import { toPx } from 'common'
import { getImageUrl } from 'landing/utils/helpers'

const styles: { [key: string]: CSSProperties } = {
  footer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: layers.l1,
    float: 'right',
    padding: toPx`${spacing.s32} ${spacing.s48}`,
  },
}

const UnauthenticatedFooter = () => (
  <div style={styles.footer}>
    <img
      src={getImageUrl('/images/unauthenticated/shopping_bag.svg')}
      alt="Instacart shopping bag"
    />
  </div>
)

export default UnauthenticatedFooter
