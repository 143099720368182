import styled from '@emotion/styled'
import { spacing } from '@instacart/ids-core'

const ColumnLayout = styled.div({
  margin: '0 auto',
  maxWidth: 1152,
  display: 'flex',
  '& + &': {
    marginTop: spacing.s12,
  },
})

export const ContentColumn = styled.div({
  flex: 1,
})

export const AsideColumn = styled.div({
  width: 360,
  flex: `0 0 auto`,
  marginLeft: spacing.s32,
})

export default ColumnLayout
