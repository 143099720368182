import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { PrimaryButton, SecondaryButton } from '@instacart/ids-tooling'
import FormattedMessage from 'components/FormattedMessage'
import StickyHeader from 'components/Header/StickyHeader'
import PageToolbar from '../PageToolbar'

const useStyles = () => {
  const theme = useTheme()
  return {
    secondaryButton: css({
      minWidth: 60,
      background: theme.colors.systemGrayscale00,
    }),
    submit: { minWidth: 60 },
  }
}

export interface SetupHeaderProps {
  title: string
  onSave: (exit: boolean) => void
  onExit: () => void
  isRequestInFlight: boolean
}

export default function SetupHeader({
  title,
  onSave,
  onExit,
  isRequestInFlight,
}: SetupHeaderProps) {
  const styles = useStyles()
  return (
    <StickyHeader>
      <PageToolbar
        title={title}
        actions={
          <>
            <SecondaryButton
              type="button"
              onClick={onExit}
              disabled={isRequestInFlight}
              css={styles.secondaryButton}
            >
              <FormattedMessage id="pages.emailCampaign.setup.actions.discard" />
            </SecondaryButton>
            <SecondaryButton
              type="button"
              onClick={() => {
                onSave(true)
              }}
              disabled={isRequestInFlight}
              css={styles.secondaryButton}
              data-testid="email-ad-group-save-exit-button"
            >
              <FormattedMessage id="pages.emailCampaign.setup.actions.saveAndExit" />
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              onClick={() => {
                onSave(false)
              }}
              disabled={isRequestInFlight}
              style={styles.submit}
              data-testid="email-ad-group-next-button"
            >
              <FormattedMessage id="pages.emailCampaign.setup.actions.next" />
            </PrimaryButton>
          </>
        }
      />
    </StickyHeader>
  )
}
