import { css } from '@emotion/react'
import { spacing, Text } from '@instacart/ids-core'
import { ReactNode, CSSProperties } from 'react'
import { getBreakpoint as mq } from 'common/breakpoints'
import { boldTextFormattingHelper } from 'common/intlUtils'
import toPx from 'common/toPx'
import { StatusType } from 'common/types'
import { formatDollar } from 'common/utils'
import FormattedMessage from 'components/FormattedMessage'
import FormNotification, { FormNotificationProps } from 'components/Forms/FormNotification'
import { unmask } from 'components/Forms/NumericField'
import { MessageIdType } from 'locales/types'
import { BidStrengthEnum } from 'service/types'

interface StatusProps {
  defaultBid: string
  suggestedBid?: string
  minBid?: number
  status: StatusType
  show: boolean
  style?: CSSProperties
}

const unknownStatus = () => {
  return (
    <Text typography="bodySmall2">
      <FormattedMessage id="adGroup.keywordTable.suggestedBid.unknown.statusNotification" />
    </Text>
  )
}

const minBidStatus = (minBid: number, bidToShow: string) => {
  const minBidToShow = formatDollar(minBid)
  return (
    <Text typography="bodySmall2">
      <FormattedMessage
        id="adGroup.editForm.defaultBid.statusNotification.minimum"
        values={{ ...boldTextFormattingHelper, bid: bidToShow, minBid: minBidToShow }}
      />
    </Text>
  )
}

const uncompetitiveStatus = (bidToShow: string) => (
  <Text typography="bodySmall2">
    <FormattedMessage
      id="adGroup.editForm.defaultBid.statusNotification.uncompetitive"
      values={{ ...boldTextFormattingHelper, bid: bidToShow }}
    />
  </Text>
)

const statusMessageIds: { [key: string]: MessageIdType } = {
  red: 'adGroup.editForm.defaultBid.statusNotification.red',
  yellow: 'adGroup.editForm.defaultBid.statusNotification.yellow',
  green: 'adGroup.editForm.defaultBid.statusNotification.green',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  low_quality: 'adGroup.editForm.defaultBid.statusNotification.lowQuality',
}

const statusMessageRecommendation = (
  status: StatusType,
  bidToShow: string,
  suggestedBid?: string
) => {
  if (!status) return null
  const unknownBidStrengthMessageId = 'adGroup.editForm.defaultBid.statusNotification.unknown'

  return (
    <Text typography="bodySmall2">
      <FormattedMessage
        id={statusMessageIds[status] || unknownBidStrengthMessageId}
        values={{ ...boldTextFormattingHelper, bid: bidToShow, suggestedBid }}
      />
    </Text>
  )
}

const mapStatusTypeToStatusNotificationStatusValue = (
  statusType: StatusType
): FormNotificationProps['status'] => {
  switch (statusType) {
    case BidStrengthEnum.Green:
      return 'green'
    case BidStrengthEnum.Yellow:
      return 'yellow'
    case BidStrengthEnum.Red:
      return 'red'
    default:
      // used for both low_quality and unconfident bids
      return 'gray'
  }
}

const statusWrapCss = css({
  width: 400,
  padding: toPx`0 ${spacing.s16}`,
  height: '100%',
  alignSelf: 'center',
  // override FormHelper animation
  '.wrap': {
    animation: 'none !important',
  },
  [mq(600)]: {
    padding: toPx`${spacing.s16} 0`,
  },
})

export default function StatusNotification({
  defaultBid,
  suggestedBid,
  status,
  show,
  style,
  minBid,
}: StatusProps) {
  if (!show) {
    return null
  }

  let bidMessage: ReactNode

  if (!status) {
    bidMessage = unknownStatus()
  } else {
    const bidToShow = formatDollar(unmask(defaultBid))

    const suggestedBidToShow = suggestedBid
      ? formatDollar(unmask(suggestedBid.toString()))
      : suggestedBid

    if (minBid) {
      bidMessage = minBidStatus(minBid, bidToShow)
    } else if (suggestedBidToShow) {
      bidMessage = statusMessageRecommendation(status, bidToShow, suggestedBidToShow)
    } else {
      bidMessage = uncompetitiveStatus(bidToShow)
    }
  }

  return (
    <div css={statusWrapCss} style={style} data-testid="bid-status-notification">
      <FormNotification status={mapStatusTypeToStatusNotificationStatusValue(status)}>
        {bidMessage}
      </FormNotification>
    </div>
  )
}
