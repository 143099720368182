import { Color, InformationIcon, IssueIcon, SparkleIcon, SVGIconProps } from '@instacart/ids-core'
import { ComponentType } from 'react'
import { WarningIcon } from 'components/atoms'
import { MessageIdType } from 'locales/types'
import { BannerType } from './banner.types'

export const DEFAULT_ICON_SIZE = 24

export const TRANSITION_TIME_MS = 250

export const bannerTypeToColor: Record<BannerType, Color> = {
  [BannerType.INFO]: 'brandHighlightDark',
  [BannerType.WARNING]: 'systemGrayscale70',
  [BannerType.ERROR]: 'systemDetrimentalDark',
  [BannerType.NEW]: 'brandHighlightDark',
}

export const bannerTypeToBackgroundColor: Record<BannerType, Color> = {
  [BannerType.INFO]: 'brandHighlightLight',
  [BannerType.WARNING]: 'brandLoyaltyLight',
  [BannerType.ERROR]: 'systemDetrimentalLight',
  [BannerType.NEW]: 'brandHighlightLight',
}

export const bannerTypeToIconColor: Record<BannerType, Color> = {
  [BannerType.INFO]: 'brandHighlightDark',
  [BannerType.WARNING]: 'brandExpressRegular',
  [BannerType.ERROR]: 'systemDetrimentalDark',
  [BannerType.NEW]: 'brandHighlightDark',
}

export const bannerTypeToIcon: Record<
  BannerType,
  ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
> = {
  [BannerType.INFO]: InformationIcon,
  [BannerType.WARNING]: WarningIcon,
  [BannerType.ERROR]: IssueIcon,
  [BannerType.NEW]: SparkleIcon,
}

export const bannerTypeToMessage: Record<BannerType, MessageIdType> = {
  [BannerType.INFO]: 'bannerNotification.grouped.message.info',
  [BannerType.WARNING]: 'bannerNotification.grouped.message.warning',
  [BannerType.ERROR]: 'bannerNotification.grouped.message.error',
  [BannerType.NEW]: 'bannerNotification.grouped.message.new',
}

export const bannerTypeToTitle: Record<BannerType, MessageIdType> = {
  [BannerType.INFO]: 'bannerNotification.grouped.drawer.title.info',
  [BannerType.WARNING]: 'bannerNotification.grouped.drawer.title.warning',
  [BannerType.ERROR]: 'bannerNotification.grouped.drawer.title.error',
  [BannerType.NEW]: 'bannerNotification.grouped.drawer.title.new',
}

export * from './banner.types'
export * from './banner.utils'
