import { css } from '@emotion/react'
import { IssueIcon, spacing, Theme, useTheme } from '@instacart/ids-core'
import { ReactNode, ChangeEvent, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { externalLinkFormatter } from 'common/intlUtils'
import { captureException } from 'common/sentry'
import { ClientError, getApiErrorMessagesHash } from 'common/utils'
import UploaderButton from 'components/organisms/UploaderButton/UploaderButton'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import { displayAssetRequestClient } from 'service/displayAssetUpload'
import {
  DisplayAssetResponseDataAttributesTypeEnum as TypeEnum,
  DisplayAssetResponseDataAttributesMediumEnum as MediumEnum,
} from 'service/openapi/__codegen__/models/DisplayAssetResponseDataAttributes'

export interface FileUploaderProps {
  /* formik field id based on asset type */
  formikId: string
  /* displays the title for the banner asset type */
  title: MessageIdType
  /* displays the description for the banner asset type */
  description: MessageIdType
  /* the asset type */
  type: TypeEnum
  /* Medium of the file to be uploaded  */
  medium: MediumEnum
  /* list of file formats for input to accept */
  acceptedFormats: string
  /* Key for accessing asset preview url, default to previewUrl */
  previewUrlKey?: string
  /* Key for accessing asset uploaded uri, default to uploadedUri */
  uploadedUriKey?: string
  /* Key for accessing asset uploaded file name, default to uploadedFileName */
  uploadedFileNameKey?: string
  /* ID to recognise asset upload failures in sentry */
  sentryId?: string
  /* set the context loading */
  setContextLoading?: (loading: boolean) => void
  /* Optional additional info */
  additionalInfo?: ReactNode
}

const useStyles = (theme: Theme) => {
  return {
    input: css({
      display: 'none',
    }),
    title: css({
      color: theme.colors.systemGrayscale70,
      ...theme.typography.subtitleLarge,
    }),
    uploaded: css({
      color: theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium1,
    }),
    fileName: css({
      color: theme.colors.brandHighlightDark,
      ...theme.typography.bodyMedium1,
      '&:hover, &:visited': {
        color: theme.colors.brandHighlightDark,
      },
    }),
    description: css({
      color: theme.colors.systemGrayscale50,
      ...theme.typography.bodyMedium2,
    }),
    errorContainer: css({
      display: 'flex',
    }),
    errorText: css({
      marginLeft: spacing.s8,
    }),
    errorMessage: css({
      ...theme.typography.bodyMedium1,
      color: theme.colors.systemDetrimentalRegular,
    }),
  }
}

const FileUploader = ({
  formikId,
  title,
  description,
  type,
  medium,
  acceptedFormats,
  previewUrlKey = 'previewUrl',
  uploadedUriKey = 'uploadedUri',
  uploadedFileNameKey = 'uploadedFileName',
  sentryId,
  setContextLoading = () => null,
  additionalInfo,
}: FileUploaderProps) => {
  const theme = useTheme()
  const styles = useStyles(theme)
  const [, meta, { setValue, setError }] = useFormikField(formikId)
  const { error, initialValue, touched, initialError, value } = meta
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasApiError, setHasApiError] = useState(false)
  const uploadInput = useRef<HTMLInputElement>(null)

  const onAddFile = async (file: File, fileType: TypeEnum, mediumType: MediumEnum) => {
    const formData = new FormData()
    formData.append('file', file, file.name.toLowerCase())
    formData.append('medium', mediumType)
    formData.append('type', fileType)

    const response = await displayAssetRequestClient.uploadDisplayAsset(formData)
    return response?.data.attributes
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorString = typeof error === 'string' ? error : error?.[uploadedUriKey]

  const uploadFile =
    (fileType: TypeEnum, mediumType: MediumEnum) =>
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (inputValue === event.currentTarget.value || loading) return
      const [file] = event.currentTarget.files || []

      if (!file) {
        return
      }
      setInputValue(event.currentTarget.value)

      try {
        setLoading(true)
        setContextLoading(true)
        const attributes = await onAddFile(file, fileType, mediumType)

        setValue({
          ...value,
          [previewUrlKey]: attributes.previewUrl,
          [uploadedUriKey]: attributes.uploadedUri,
          [uploadedFileNameKey]: file.name,
        })

        if (errorString) {
          setError(undefined)
        }
        setHasApiError(false)
      } catch (err) {
        const apiErrors = getApiErrorMessagesHash(err as ClientError)
        const errorMessage = apiErrors[Object.keys(apiErrors)[0]][0]

        if (sentryId) {
          captureException(
            `FileUploader: Error uploading file ${file.name}: ${errorMessage} (${sentryId})`
          )
        }

        setError(errorMessage)
        setHasApiError(true)
      }

      setContextLoading(false)
      setLoading(false)
    }

  const removeFile = () => {
    setValue({
      ...value,
      [previewUrlKey]: '',
      [uploadedUriKey]: '',
      [uploadedFileNameKey]: '',
    })

    setInputValue('')
    if (errorString) {
      setError(undefined)
    }
  }

  const showError = (!!initialError && value === initialValue) || touched || hasApiError
  const fileUrl = value?.[previewUrlKey]
  const fileUploadedName = value?.[uploadedFileNameKey]
  return (
    <>
      <input
        accept={acceptedFormats}
        className="file"
        data-testid="file-uploader-input"
        value={inputValue}
        onChange={uploadFile(type, medium)}
        ref={uploadInput}
        css={styles.input}
        type="file"
      />

      <span css={styles.title}>
        <FormattedMessage id={title} />
      </span>

      {additionalInfo}

      {fileUploadedName && (
        <span css={styles.uploaded}>
          <FormattedMessage
            id="components.fileUploader.uploaded"
            values={{
              fileName: fileUploadedName,
              link: externalLinkFormatter(fileUrl, {}, styles.fileName),
            }}
          />
        </span>
      )}

      <p css={styles.description}>
        <FormattedMessage id={description} />
      </p>
      {errorString && showError && (
        <div css={styles.errorContainer}>
          <IssueIcon color="systemDetrimentalRegular" size={24} />
          <div css={styles.errorText}>
            <p>
              <span css={styles.errorMessage}>{errorString}</span>
            </p>
          </div>
        </div>
      )}
      <UploaderButton
        onUpload={() => uploadInput?.current?.click()}
        onRemove={removeFile}
        loading={loading}
        fileUrl={fileUrl}
      />
    </>
  )
}

export default FileUploader
