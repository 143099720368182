// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
 */
export interface PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase {
  /**
   * Categories to target
   * @type {Array<string>}
   * @memberof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
   */
  categories?: Array<string>
  /**
   * Brand IDs to target
   * @type {Array<string>}
   * @memberof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
   */
  brandIds?: Array<string>
  /**
   * UPCs to target
   * @type {Array<string>}
   * @memberof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
   */
  upcs?: Array<string>
  /**
   * Lookback window to target, ex: 30d
   * @type {string}
   * @memberof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
   */
  lookbackWindow: PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseLookbackWindowEnum
  /**
   * Clause flag, e.g. include or exclude
   * @type {string}
   * @memberof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase
   */
  clause: PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseClauseEnum
}

/**
 * @export
 */
export const PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseLookbackWindowEnum =
  {
    _30d: '30d',
    _60d: '60d',
    _90d: '90d',
    _120d: '120d',
    _150d: '150d',
    _180d: '180d',
  } as const
export type PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseLookbackWindowEnum =
  typeof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseLookbackWindowEnum[keyof typeof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseLookbackWindowEnum]

/**
 * @export
 */
export const PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseClauseEnum =
  {
    Include: 'include',
    Exclude: 'exclude',
  } as const
export type PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseClauseEnum =
  typeof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseClauseEnum[keyof typeof PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseClauseEnum]

/**
 * Check if a given object implements the PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase interface.
 */
export function instanceOfPutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'lookbackWindow' in value
  isInstance = isInstance && 'clause' in value

  return isInstance
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseFromJSON(
  json: any
): PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase {
  return PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase {
  if (json === undefined || json === null) {
    return json
  }
  return {
    categories: !exists(json, 'categories') ? undefined : json['categories'],
    brandIds: !exists(json, 'brand_ids') ? undefined : json['brand_ids'],
    upcs: !exists(json, 'upcs') ? undefined : json['upcs'],
    lookbackWindow: json['lookback_window'],
    clause: json['clause'],
  }
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchaseToJSON(
  value?: PutDisplayAdGroupsIdParamDisplayAdGroupTargetingStrategyUserTargetingPurchase | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    categories: value.categories,
    brand_ids: value.brandIds,
    upcs: value.upcs,
    lookback_window: value.lookbackWindow,
    clause: value.clause,
  }
}
