import { InputBase, Variant } from '@instacart/ids-core'
import { useMemo } from 'react'
import useIntl from 'common/useIntl'
import { Select, SelectProps } from 'components/ids-ads'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'

export type SelectOption<T> = {
  value: T
  meta?: {
    [key: string]: unknown
  }
} & (
  | {
      labelId: MessageIdType
      label?: never
    }
  | {
      label: string
      labelId?: never
    }
)

export type SelectInputFieldProps<T> = Variant<typeof InputBase> &
  Omit<InputFieldProps<SelectProps<SelectOption<T>>>, 'options'> & {
    options: SelectOption<T>[]
    onOptionSelected?: (opt: SelectOption<T> | null) => void
    ignoreTouched?: boolean
  }

function SelectInput<T>(props: SelectProps<SelectOption<T>>) {
  return <Select block {...props} />
}
SelectInput.displayName = 'InputText'

export function SelectInputField<T>({
  id,
  label: labelId,
  info,
  infoDescription,
  options,
  placeholderId,
  disabled = false,
  closeMenuOnSelect,
  formatOptionLabel,
  variant = 'tertiary',
  onOptionSelected,
  isMulti,
  allowSelectAll,
  allowClear,
  onInputChange,
  ignoreTouched,
  ...rest
}: SelectInputFieldProps<T>) {
  const [{ value }, { touched, error }, { setValue }] = useFormikField<T | null>(id)
  const { formatMessage } = useIntl()
  const label = formatMessage({ id: labelId as MessageIdType })
  const infoProps = {
    info: typeof info === 'string' ? formatMessage({ id: info as MessageIdType }) : info,
    infoDescription: infoDescription
      ? formatMessage({ id: infoDescription as MessageIdType })
      : undefined,
  }

  const errorString = ignoreTouched ? error : touched ? error : undefined

  const formattedSelectOptions = useMemo(
    () =>
      options.map(option => ({
        label: (option.label
          ? option.label
          : option.labelId && formatMessage({ id: option.labelId })) as string,
        value: option.value,
        meta: option.meta,
      })),
    [formatMessage, options]
  )
  const selectedOptionSingle = formattedSelectOptions?.find(option => option.value === value)
  const selectedOption = Array.isArray(value)
    ? formattedSelectOptions.filter(option => value?.includes(option.value))
    : selectedOptionSingle

  const handleOnChange = (opt: SelectOption<T> | null) => {
    setValue(opt?.value || null)
  }

  return (
    <InputFieldContainer {...infoProps} id={id} label={label} error={errorString} {...rest}>
      <SelectInput<T>
        options={formattedSelectOptions}
        defaultValue={selectedOption || undefined}
        placeholder={placeholderId && formatMessage({ id: placeholderId })}
        isDisabled={disabled}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={onOptionSelected || handleOnChange}
        aria-label={rest['aria-label'] || label}
        formatOptionLabel={formatOptionLabel}
        variant={variant}
        value={selectedOption || null}
        key={id}
        isMulti={isMulti}
        allowSelectAll={allowSelectAll}
        allowClear={allowClear}
        data-testid={`${rest.testId || id}-select`}
        onInputChange={onInputChange}
      />
    </InputFieldContainer>
  )
}

SelectInputField.displayName = 'SelectInputField'
