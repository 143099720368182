// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeProperties } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeProperties'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativePropertiesToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeProperties'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative {
  /**
   * The id of the creative. Only needed for update
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  id?: string
  /**
   * The type of placement the creative will be placed in
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  type: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeTypeEnum
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeProperties}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  properties?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeProperties
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  actions?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
  /**
   * Product IDs that are used for view through attribution
   * @type {Array<string>}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  trackedProductIds?: Array<string>
  /**
   * Read only field indicating whether creative is using new view through attribution
   * @type {boolean}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  useNewAttribution?: boolean
  /**
   * Product Group IDs that are used for view through attribution based on product group
   * @type {Array<string>}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  trackedProductGroupIds?: Array<string>
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative
   */
  assets: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
}

/**
 * @export
 */
export const PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeTypeEnum = {
  CaperImageBannerV1: 'caper_image_banner.v1',
  CaperSpotlightAdPrototypeV1: 'caper_spotlight_ad.prototype.v1',
  EmailV1: 'email.v1',
  ImageBannerV2: 'image_banner.v2',
  ImageBannerV3: 'image_banner.v3',
  MnmVideoPrototypeV1: 'mnm.video.prototype.v1',
  OccasionV1: 'occasion.v1',
  PromotedAisleV1: 'promoted_aisle.v1',
  PromotedAisleV2: 'promoted_aisle.v2',
  PromotedAisleV3: 'promoted_aisle.v3',
  PromotedAisleV4: 'promoted_aisle.v4',
  SearchBannerV1: 'search_banner.v1',
  ShoppableVideoV1: 'shoppable_video.v1',
  ShoppableVideoV2: 'shoppable_video.v2',
  ShoppableVideoV3: 'shoppable_video.v3',
  SmartV1: 'smart.v1',
  SponsoredRecipeV1: 'sponsored_recipe.v1',
  SponsoredRecipeVideoV1: 'sponsored_recipe_video.v1',
  UvcBannerV1: 'uvc_banner.v1',
} as const
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeTypeEnum =
  typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeTypeEnum[keyof typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    properties: !exists(json, 'properties')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSON(
          json['properties']
        ),
    actions: !exists(json, 'actions')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON(
          json['actions']
        ),
    trackedProductIds: !exists(json, 'tracked_product_ids')
      ? undefined
      : json['tracked_product_ids'],
    useNewAttribution: !exists(json, 'use_new_attribution')
      ? undefined
      : json['use_new_attribution'],
    trackedProductGroupIds: !exists(json, 'tracked_product_group_ids')
      ? undefined
      : json['tracked_product_group_ids'],
    assets: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
      json['assets']
    ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    properties:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativePropertiesToJSON(
        value.properties
      ),
    actions: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsToJSON(
      value.actions
    ),
    tracked_product_ids: value.trackedProductIds,
    use_new_attribution: value.useNewAttribution,
    tracked_product_group_ids: value.trackedProductGroupIds,
    assets: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
      value.assets
    ),
  }
}
