// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltText } from './PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltText'
import {
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSON,
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltTextToJSON,
} from './PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltText'
import type { PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImage } from './PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImage'
import {
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImageFromJSON,
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImageFromJSONTyped,
  PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImageToJSON,
} from './PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamProductAssetFieldDecisions
 */
export interface PutUniversalAdGroupsIdParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamProductAssetFieldDecisions
   */
  discriminator: PutUniversalAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltText}
   * @memberof PutUniversalAdGroupsIdParamProductAssetFieldDecisions
   */
  altText?: PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImage}
   * @memberof PutUniversalAdGroupsIdParamProductAssetFieldDecisions
   */
  image?: PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const PutUniversalAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutUniversalAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof PutUniversalAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof PutUniversalAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamProductAssetFieldDecisions interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function PutUniversalAdGroupsIdParamProductAssetFieldDecisionsFromJSON(
  json: any
): PutUniversalAdGroupsIdParamProductAssetFieldDecisions {
  return PutUniversalAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function PutUniversalAdGroupsIdParamProductAssetFieldDecisionsToJSON(
  value?: PutUniversalAdGroupsIdParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PutUniversalAdGroupsIdParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutUniversalAdGroupsIdParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
