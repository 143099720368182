// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssets } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssets'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssetsFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssetsFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssetsToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssets'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative'
import type { GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy } from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy'
import {
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSON,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSONTyped,
  GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyToJSON,
} from './GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
 */
export interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributes {
  /**
   * id of the ad group, required for update
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  id?: string
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  name: string
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  campaignId?: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  placementType: string
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  creative: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreative
  /**
   * Assets for the creative
   * @type {Array<GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssets>}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  assets: Array<GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssets>
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  targetingStrategy: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  lockKey?: string
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  updatedAt?: string
  /**
   *
   * @type {GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributes
   */
  brandPage?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPage
}

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributesAdGroupAttributes interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributesAdGroupAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'assets' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributes {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesFromJSONTyped(json, false)
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    placementType: json['placement_type'],
    creative: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeFromJSON(
      json['creative']
    ),
    assets: (json['assets'] as Array<any>).map(
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssetsFromJSON
    ),
    targetingStrategy:
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesCreativeToJSON(
      value.creative
    ),
    assets: (value.assets as Array<any>).map(
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesAssetsToJSON
    ),
    targeting_strategy:
      GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    lock_key: value.lockKey,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    brand_page: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageToJSON(
      value.brandPage
    ),
  }
}
