// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamAssetFieldDecisionsAltText } from './PostBrandPagesIdStatusParamAssetFieldDecisionsAltText'
import {
  PostBrandPagesIdStatusParamAssetFieldDecisionsAltTextFromJSON,
  PostBrandPagesIdStatusParamAssetFieldDecisionsAltTextFromJSONTyped,
  PostBrandPagesIdStatusParamAssetFieldDecisionsAltTextToJSON,
} from './PostBrandPagesIdStatusParamAssetFieldDecisionsAltText'
import type { PostBrandPagesIdStatusParamAssetFieldDecisionsImage } from './PostBrandPagesIdStatusParamAssetFieldDecisionsImage'
import {
  PostBrandPagesIdStatusParamAssetFieldDecisionsImageFromJSON,
  PostBrandPagesIdStatusParamAssetFieldDecisionsImageFromJSONTyped,
  PostBrandPagesIdStatusParamAssetFieldDecisionsImageToJSON,
} from './PostBrandPagesIdStatusParamAssetFieldDecisionsImage'
import type { PostBrandPagesIdStatusParamAssetFieldDecisionsVideo } from './PostBrandPagesIdStatusParamAssetFieldDecisionsVideo'
import {
  PostBrandPagesIdStatusParamAssetFieldDecisionsVideoFromJSON,
  PostBrandPagesIdStatusParamAssetFieldDecisionsVideoFromJSONTyped,
  PostBrandPagesIdStatusParamAssetFieldDecisionsVideoToJSON,
} from './PostBrandPagesIdStatusParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamAssetFieldDecisions
 */
export interface PostBrandPagesIdStatusParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {PostBrandPagesIdStatusParamAssetFieldDecisionsAltText}
   * @memberof PostBrandPagesIdStatusParamAssetFieldDecisions
   */
  altText?: PostBrandPagesIdStatusParamAssetFieldDecisionsAltText
  /**
   *
   * @type {PostBrandPagesIdStatusParamAssetFieldDecisionsImage}
   * @memberof PostBrandPagesIdStatusParamAssetFieldDecisions
   */
  image?: PostBrandPagesIdStatusParamAssetFieldDecisionsImage
  /**
   *
   * @type {PostBrandPagesIdStatusParamAssetFieldDecisionsVideo}
   * @memberof PostBrandPagesIdStatusParamAssetFieldDecisions
   */
  video?: PostBrandPagesIdStatusParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamAssetFieldDecisions interface.
 */
export function instanceOfPostBrandPagesIdStatusParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSON(
  json: any
): PostBrandPagesIdStatusParamAssetFieldDecisions {
  return PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PostBrandPagesIdStatusParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PostBrandPagesIdStatusParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : PostBrandPagesIdStatusParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function PostBrandPagesIdStatusParamAssetFieldDecisionsToJSON(
  value?: PostBrandPagesIdStatusParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: PostBrandPagesIdStatusParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PostBrandPagesIdStatusParamAssetFieldDecisionsImageToJSON(value.image),
    video: PostBrandPagesIdStatusParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
