import { css } from '@emotion/react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import ProductSearchWrapper from 'components/ProductSearch/ProductSearchWrapper'
import { GetProductsSearchClassifiedTypeEnum } from 'service/openapi/__codegen__/apis/ProductsApi'
import { useGetProducts } from '../../../../hooks/api/products'
import { TargetingRuleContext, TargetingSegmentContext } from '../hooks'
import { Product } from '../types'
import TargetedElementsLine from './TargetedElementsLine'

const useStyles = () => ({
  productSearchWrapper: css({ marginTop: 16, width: '100%' }),

  productsContainerStyle: css({
    width: '100%',
  }),
})

export default function ProductsView() {
  const styles = useStyles()

  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)

  const [selectedProducts, setSelectedProducts] = useState<Product[] | undefined>(undefined)

  const { fieldsPrefix } = useContext(TargetingSegmentContext)
  const fieldName = `${fieldsPrefix}upcs`

  const { values, setFieldValue } = useFormikContext()
  const selectedUpcs: string[] = get(values, fieldName, [])

  const { loading, products } = useGetProducts(
    {
      // We want to call the API only on the first render.
      upcs: selectedProducts ? undefined : selectedUpcs,
    },
    {}
  )

  if (!selectedProducts && !loading) {
    setSelectedProducts(products)
  }

  useEffect(() => {
    if (selectedProducts) {
      setFieldValue(
        fieldName,
        selectedProducts.map(product => product.attributes.upc)
      )
    }
  }, [selectedProducts, setFieldValue, fieldName])

  const MemoizedTargetedElementsLine = useMemo(() => {
    return (
      <TargetedElementsLine
        entities={
          selectedProducts?.map(product => ({
            name: product.attributes.productName,
          })) || []
        }
        errorMessageId="pages.displayProduct.common.targetingRule.segment.products.validation"
        fieldName={fieldName}
        isLoading={loading}
        removeElementByName={productNameToRemove => {
          const newSelectedProducts = selectedProducts?.filter(
            product => product.attributes.productName !== productNameToRemove
          )
          setSelectedProducts(newSelectedProducts)
        }}
      />
    )
  }, [selectedProducts, setSelectedProducts, fieldName, loading])

  const MemoizedProductSearchWrapper = useMemo(() => {
    return (
      <ProductSearchWrapper
        onProductClick={product => {
          const newSelectedProducts = selectedProducts?.concat([product])
          setSelectedProducts(newSelectedProducts)
        }}
        disabled={loading}
        selectedProductIds={selectedProducts?.map(product => product.id) || []}
        compact
        classifiedType={GetProductsSearchClassifiedTypeEnum.Universal}
      />
    )
  }, [setSelectedProducts, loading, selectedProducts])

  return (
    <div css={styles.productsContainerStyle}>
      {MemoizedTargetedElementsLine}
      {!isReadOnly && !isReviewing && !loading && (
        <div css={styles.productSearchWrapper}>{MemoizedProductSearchWrapper}</div>
      )}
    </div>
  )
}
