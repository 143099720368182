import { Restricted, RestrictedProps } from 'components/Auth'

export type IfCapabilitiesArgs = Pick<
  RestrictedProps,
  'capabilities' | 'featureCapabilities' | 'disableByAccountTypes' | 'flipper'
>

export default function ifCapabilities({
  capabilities,
  featureCapabilities,
  disableByAccountTypes,
  flipper,
}: IfCapabilitiesArgs) {
  if (capabilities || featureCapabilities || disableByAccountTypes || flipper) {
    return (element: JSX.Element) => (
      <Restricted
        capabilities={capabilities}
        featureCapabilities={featureCapabilities}
        disableByAccountTypes={disableByAccountTypes}
        flipper={flipper}
      >
        {element}
      </Restricted>
    )
  }

  return (element: JSX.Element) => element
}
