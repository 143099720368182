// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageWeb?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageWeb?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageMobile?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageMobile?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  squareImageMobile?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  heroImage?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  brandLogo?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  video?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  thumbnail?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  uvcImageWeb?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  uvcImageMobile?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    image_web:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON(
        value.imageWeb
      ),
    image_mobile:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    top_image_mobile:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    square_image_mobile:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON(
        value.heroImage
      ),
    brand_logo:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON(
        value.brandLogo
      ),
    video: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON(
        value.thumbnail
      ),
    uvc_image_web:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebToJSON(
        value.uvcImageWeb
      ),
    uvc_image_mobile:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileToJSON(
        value.uvcImageMobile
      ),
  }
}
