import Analytics from 'common/analytics/Analytics'
import getDefaultFontSize from 'common/analytics/utils/getDefaultFontSize'
import { AuthResponseWithCapabilities } from 'service/auth'

export function setAnalyticsParams(authResponse: AuthResponseWithCapabilities) {
  if (!authResponse?.data) return

  const { userId, userUuid, userType, sessionId, currentAccount } = authResponse.data.attributes

  Analytics.identify(String(userId), {
    userType,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    screenWidth: window.screen?.width,
    screenHeight: window.screen?.height,
    defaultFontSize: getDefaultFontSize(),
    ahoyVisitId: window.__ADS?.ahoy?.getVisitId(),
  })

  Analytics.setCommonParams({
    sessionId,
    accountId: currentAccount?.id || null,
    accountUuid: currentAccount?.uuid || null,
  })

  Analytics.setUserParams({ userId, userUuid, userType })
}
