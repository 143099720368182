import { css, PropsOf } from '@emotion/react'
import classNames from 'classnames'
import { useRef, useState } from 'react'

export interface ScrollShadowContainerProps extends PropsOf<'div'> {
  height: string | number
}

const SHADOW_COLOR = 'rgba(0, 0, 0, 0.16)'

function useStyles({ height }: Pick<ScrollShadowContainerProps, 'height'>) {
  return {
    root: css({
      position: 'relative',
      overflow: 'hidden',
      maxHeight: height,
    }),
    scrollBox: css({
      overflow: 'auto',
      maxHeight: height,
    }),
    shadow: css({
      position: 'absolute',
      pointerEvents: 'none',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
      transition: 'opacity 0.1s ease-out',
      '&.visible': {
        opacity: 1,
      },
    }),
    shadowTop: css({
      boxShadow: `0 16px 16px -16px ${SHADOW_COLOR} inset`,
    }),
    shadowBottom: css({
      boxShadow: `0 -16px 16px -16px ${SHADOW_COLOR} inset`,
    }),
  }
}

export default function ScrollShadowContainer({
  height,
  children,
  ...props
}: ScrollShadowContainerProps) {
  const styles = useStyles({ height })
  const [showTop, setShowTop] = useState(true)
  const [showBottom, setShowBottom] = useState(true)

  const isScrolling = useRef(false)
  const scrollContent = useRef<HTMLDivElement | null>(null)

  // There are pure CSS approaches to this problem, but they do not have adequate
  // browser support.
  const updateScrollPosition = (scrollBox: HTMLDivElement | null) => {
    if (isScrolling.current || !scrollBox) return

    window.requestAnimationFrame(() => {
      if (scrollBox.scrollTop > 0) {
        setShowTop(true)
      } else {
        setShowTop(false)
      }

      const maxScroll = (scrollContent.current?.offsetHeight || 0) - scrollBox.offsetHeight
      if (scrollBox.scrollTop < maxScroll) {
        setShowBottom(true)
      } else {
        setShowBottom(false)
      }

      isScrolling.current = false
    })

    isScrolling.current = true
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    updateScrollPosition(event.currentTarget)
  }

  return (
    <div css={styles.root} {...props}>
      <div css={styles.scrollBox} onScroll={handleScroll} ref={updateScrollPosition}>
        <div ref={scrollContent}>{children}</div>
      </div>
      <div
        css={[styles.shadow, styles.shadowTop]}
        className={classNames({ visible: showTop })}
        aria-hidden
      />
      <div
        css={[styles.shadow, styles.shadowBottom]}
        className={classNames({ visible: showBottom })}
        aria-hidden
      />
    </div>
  )
}
