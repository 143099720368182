// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValues } from './PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTagline } from './PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTagline'
import {
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTaglineFromJSON,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTaglineFromJSONTyped,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTaglineToJSON,
} from './PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamCreativeFieldDecisions
 */
export interface PostVideoCampaignsIdSubmitParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof PostVideoCampaignsIdSubmitParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTagline}
   * @memberof PostVideoCampaignsIdSubmitParamCreativeFieldDecisions
   */
  tagline?: PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamCreativeFieldDecisions interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamCreativeFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamCreativeFieldDecisions {
  return PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsToJSON(
  value?: PostVideoCampaignsIdSubmitParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
