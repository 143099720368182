import { flatten, get, isEqual } from 'lodash'
import { array, number, object, string } from 'common/validation'
import { buyMyBrandNotMyProductShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/BuyMyBrandNotMyProduct'
import { buyMyCategoryNotMyBrandShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/BuyMyCategoryNotMyBrand'
import { interactWithXNotBuyBrandShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/InteractWithXNotBuyBrand'
import { lapsedBuyerShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/LapsedBuyer'
import { loyalBuyerShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/LoyalBuyerShortcut'
import { newToCategoryOrBrandShortcutName } from 'pages/DisplayProduct/DisplayAdGroup/components/TargetingRule/NewToCategoryOrBrand'
import {
  TARGETING_SHORTCUT_FIELD_NAME,
  TARGETING_TYPE_FIELD_NAME,
  TargetingType,
} from 'pages/DisplayProduct/DisplayAdGroup/constants'
import {
  Condition,
  DEFAULT_TARGETING_SEGMENT,
  getTargetingSegmentsFieldName,
  MAXIMUM_NUMBER_OF_SEGMENTS,
  Operator,
  SECONDS_PER_DAY,
  SegmentType,
  Subject,
  TARGETING_LOCATIONS_FIELD_NAME,
} from './constants'
import {
  TargetingElement,
  TargetingFormData,
  TargetingRuleType,
  TargetingSegmentType,
  UserTargetingRuleType,
} from './types'

const targetingSegmentSchema = object({
  key: number().default(1),
  condition: string().default(Condition.POSITIVE),
  subject: string().default(Subject.CATEGORIES),
  timeline: string().default('30'),
  categories: array()
    .of(string())
    .when('subject', {
      is: Subject.CATEGORIES,
      then: array().of(string()).min(1),
    })
    .default([]),
  brands: array()
    .of(string())
    .when('subject', {
      is: Subject.BRANDS,
      then: array().of(string()).min(1),
    })
    .default([]),
  upcs: array()
    .of(string())
    .when('subject', {
      is: Subject.UPCS,
      then: array().of(string()).min(1),
    })
    .default([]),
})

export const emailTargetingFormSchema = object({
  [getTargetingSegmentsFieldName(SegmentType.PURCHASE)]: array()
    .of(targetingSegmentSchema)
    .max(MAXIMUM_NUMBER_OF_SEGMENTS),
  [getTargetingSegmentsFieldName(SegmentType.INTEREST)]: array()
    .of(targetingSegmentSchema)
    .max(MAXIMUM_NUMBER_OF_SEGMENTS),
  [TARGETING_LOCATIONS_FIELD_NAME]: array().of(string()).default([]),
})

export const displayTargetingFormSchema = object({
  [getTargetingSegmentsFieldName(SegmentType.PURCHASE)]: array()
    .when(TARGETING_TYPE_FIELD_NAME, {
      is: TargetingType.USER_TARGETING,
      then: array().of(targetingSegmentSchema),
    })
    .when(TARGETING_SHORTCUT_FIELD_NAME, {
      is: (value: string) =>
        [
          newToCategoryOrBrandShortcutName,
          buyMyBrandNotMyProductShortcutName,
          buyMyCategoryNotMyBrandShortcutName,
          lapsedBuyerShortcutName,
          loyalBuyerShortcutName,
          interactWithXNotBuyBrandShortcutName,
        ].includes(value),
      then: array().of(targetingSegmentSchema).min(1),
    })
    .max(MAXIMUM_NUMBER_OF_SEGMENTS),
  [getTargetingSegmentsFieldName(SegmentType.INTEREST)]: array()
    .when(TARGETING_TYPE_FIELD_NAME, {
      is: TargetingType.USER_TARGETING,
      then: array().of(targetingSegmentSchema),
    })
    .when(TARGETING_SHORTCUT_FIELD_NAME, {
      is: (value: string) => [interactWithXNotBuyBrandShortcutName].includes(value),
      then: array().of(targetingSegmentSchema).min(1),
    })
    .max(MAXIMUM_NUMBER_OF_SEGMENTS),
  [TARGETING_LOCATIONS_FIELD_NAME]: array().of(string()).default([]),
})

/* eslint-disable @typescript-eslint/naming-convention */
export function getTargetingElementFromFormSegmentData({
  segment,
  isPurchaseSegment,
}: {
  segment: TargetingSegmentType
  isPurchaseSegment: boolean
}) {
  const timeWindow = `${Number(segment.timeline) * SECONDS_PER_DAY}s`
  return {
    not: segment.condition === Condition.NEGATIVE,
    ...(segment.subject === Subject.CATEGORIES
      ? {
          [isPurchaseSegment ? 'category' : 'category_interest']: {
            categories: segment.categories,
            time_window: timeWindow,
          },
        }
      : {}),
    ...(segment.subject === Subject.BRANDS
      ? {
          [isPurchaseSegment ? 'brand_id' : 'brand_id_interest']: {
            brand_ids: segment.brands.map(brandId => Number(brandId)),
            time_window: timeWindow,
          },
        }
      : {}),
    ...(segment.subject === Subject.UPCS
      ? {
          [isPurchaseSegment ? 'upc' : 'upc_interest']: {
            upcs: segment.upcs.map(upc => upc.padStart(14, '0')),
            time_window: timeWindow,
          },
        }
      : {}),
  }
}

export function getUserTargetingElementFromFormSegmentData({
  segment,
}: {
  segment: TargetingSegmentType
}) {
  return {
    ...(segment.subject === Subject.CATEGORIES
      ? {
          categories: segment.categories,
          clause: segment.condition === Condition.POSITIVE ? 'include' : 'exclude',
          lookbackWindow: `${segment.timeline}d`,
        }
      : {}),
    ...(segment.subject === Subject.BRANDS
      ? {
          brandIds: segment.brands,
          clause: segment.condition === Condition.POSITIVE ? 'include' : 'exclude',
          lookbackWindow: `${segment.timeline}d`,
        }
      : {}),
    ...(segment.subject === Subject.UPCS
      ? {
          upcs: segment.upcs,
          clause: segment.condition === Condition.POSITIVE ? 'include' : 'exclude',
          lookbackWindow: `${segment.timeline}d`,
        }
      : {}),
  }
}

export function getFormSegmentFromTargetingElement(
  element: Omit<TargetingElement, 'new_to_instacart' | 'geo'>
) {
  const source:
    | {
        categories?: string[]
        upcs?: string[]
        brand_ids?: number[]
        time_window: string
      }
    | undefined =
    element.category ||
    element.category_interest ||
    element.brand_id ||
    element.brand_id_interest ||
    element.upc ||
    element.upc_interest

  return (
    source && {
      isPurchaseSegment: !!(element.category || element.brand_id || element.upc),
      segment: {
        subject: source.categories?.length
          ? Subject.CATEGORIES
          : source.brand_ids?.length
          ? Subject.BRANDS
          : Subject.UPCS,
        categories: source.categories || [],
        upcs: source.upcs || [],
        brands: source.brand_ids?.map(brandId => String(brandId)) || [],
        condition: element.not ? Condition.NEGATIVE : Condition.POSITIVE,
        timeline: String(Number((source.time_window as string).slice(0, -1)) / SECONDS_PER_DAY),
      },
    }
  )
}

export function getLocationsElementFromLocationsFormData(locations: string[]) {
  return locations.length
    ? {
        geo: {
          geo: locations.map(location => ({ country_code: 'US', state_code: location })),
        },
      }
    : undefined
}

export function getLocationsFormDataFromTargetingElements(elements: TargetingElement[]) {
  return (
    elements.find(element => !!element?.geo?.geo?.length)?.geo?.geo?.map(geo => geo.state_code) ||
    []
  )
}

export function getNewCustomersFormDataFromTargetingElements(elements: TargetingElement[]) {
  return !!elements.find(element => isEqual(element?.new_to_instacart, {}))
}

export function getNewCustomersElementFromFormData(includeNewCustomers: boolean) {
  return includeNewCustomers ? { new_to_instacart: {} } : undefined
}

export function isTargetingRuleLegacy({ conjunctions }: Pick<TargetingRuleType, 'conjunctions'>) {
  return (
    conjunctions.length > 1 &&
    conjunctions.every(
      conjunction =>
        conjunction.elements?.length === 1 &&
        (conjunction.elements[0].category || conjunction.elements[0].brand_id)
    )
  )
}
/* eslint-enable @typescript-eslint/naming-convention */

function getSegmentsFormDataFromTargetingElements(elements: TargetingElement[]) {
  const purchaseSegments: TargetingSegmentType[] = []
  const interestSegments: TargetingSegmentType[] = []

  elements.forEach(element => {
    const formSegment = getFormSegmentFromTargetingElement(element)
    if (!formSegment) {
      return
    }

    if (formSegment.isPurchaseSegment) {
      purchaseSegments.push({
        ...formSegment.segment,
        key: purchaseSegments.length + 1,
      })
    } else {
      interestSegments.push({
        ...formSegment.segment,
        key: interestSegments.length + 1,
      })
    }
  })

  return { purchaseSegments, interestSegments }
}

function getOperatorFormDataFromTargetingRule(targetingRule: TargetingRuleType) {
  return targetingRule.conjunctions.length > 1 ? Operator.OR : Operator.AND
}

export function getUserTargetingInitialValues(
  existingTargetingRule?: TargetingRuleType,
  hasDefaultSegment?: boolean
): TargetingFormData {
  if (!existingTargetingRule?.conjunctions?.length) {
    return {
      purchaseSegments:
        hasDefaultSegment && !existingTargetingRule ? [DEFAULT_TARGETING_SEGMENT] : [],
      interestSegments: [],
      areTargetingRulesBlank() {
        return (
          !this.purchaseSegments.length &&
          !this.interestSegments.length &&
          !this.includeNewCustomers
        )
      },
      locations: [],
      operator: Operator.AND,
      includeNewCustomers: false,
      isLegacyTargetingRule: false,
    }
  }

  const elements = flatten(
    existingTargetingRule.conjunctions.map(conjunction => conjunction?.elements || [])
  )

  const { purchaseSegments, interestSegments } = getSegmentsFormDataFromTargetingElements(elements)

  return {
    purchaseSegments,
    interestSegments,
    areTargetingRulesBlank() {
      return (
        !this.purchaseSegments.length && !this.interestSegments.length && !this.includeNewCustomers
      )
    },
    locations: getLocationsFormDataFromTargetingElements(elements),
    operator: getOperatorFormDataFromTargetingRule(existingTargetingRule),
    includeNewCustomers: getNewCustomersFormDataFromTargetingElements(elements),
    isLegacyTargetingRule: isTargetingRuleLegacy(existingTargetingRule),
  }
}

export function getTargetingRuleFromFormData({
  formData,
  isTargetingV2Enabled,
  skipValidation = false,
}: {
  formData: TargetingFormData
  isTargetingV2Enabled: boolean
  skipValidation?: boolean
}): TargetingRuleType | undefined {
  if (!skipValidation && !validateTargetingRule(formData)) {
    return undefined
  }

  if (formData.operator === Operator.AND) {
    return getIntersectionTargetingRuleFromFormData(formData)
  }

  if (isTargetingV2Enabled && !formData.isLegacyTargetingRule) {
    return getV2UnionTargetingRuleFromFormData(formData)
  }

  return getV1UnionTargetingRuleFromFormData(formData)
}

function getV1UnionTargetingRuleFromFormData({
  locations,
  purchaseSegments,
}: TargetingFormData): TargetingRuleType {
  const locationElement = getLocationsElementFromLocationsFormData(locations)

  return {
    conjunctions: purchaseSegments.map(segment => ({
      elements: [
        getTargetingElementFromFormSegmentData({ segment, isPurchaseSegment: true }),
        ...(locationElement ? [locationElement] : []),
      ],
    })),
  }
}

function getV2UnionTargetingRuleFromFormData({
  purchaseSegments,
  interestSegments,
  includeNewCustomers,
}: Omit<TargetingFormData, 'operator' | 'locations' | 'isLegacyTargetingRule'>): TargetingRuleType {
  const newCustomersElement = getNewCustomersElementFromFormData(includeNewCustomers)

  return {
    conjunctions: [
      {
        elements: newCustomersElement ? [newCustomersElement] : [],
      },
      {
        elements: purchaseSegments.map(segment =>
          getTargetingElementFromFormSegmentData({ segment, isPurchaseSegment: true })
        ),
      },
      {
        elements: interestSegments.map(segment =>
          getTargetingElementFromFormSegmentData({ segment, isPurchaseSegment: false })
        ),
      },
    ].filter(conjunction => !!conjunction.elements.length),
  }
}

export function getUserTargetingRuleFromFormData({
  purchaseSegments,
  interestSegments,
  includeNewCustomers,
}: Omit<TargetingFormData, 'operator' | 'locations' | 'isLegacyTargetingRule'>):
  | UserTargetingRuleType
  | undefined {
  if (validateTargetingRule({ purchaseSegments, interestSegments })) {
    const result: UserTargetingRuleType = {
      purchase: purchaseSegments.map(segment =>
        getUserTargetingElementFromFormSegmentData({ segment })
      ),
      interest: interestSegments.map(segment =>
        getUserTargetingElementFromFormSegmentData({ segment })
      ),
      newToInstacart: !!includeNewCustomers,
    }
    return result
  }
  return undefined
}

function getIntersectionTargetingRuleFromFormData({
  locations,
  purchaseSegments,
  interestSegments,
  includeNewCustomers,
}: Omit<TargetingFormData, 'operator' | 'isLegacyTargetingRule'>): TargetingRuleType {
  const newCustomersElement = getNewCustomersElementFromFormData(includeNewCustomers)
  const locationElement = getLocationsElementFromLocationsFormData(locations)

  return {
    conjunctions: [
      {
        elements: [
          ...purchaseSegments.map(segment =>
            getTargetingElementFromFormSegmentData({ segment, isPurchaseSegment: true })
          ),
          ...interestSegments.map(segment =>
            getTargetingElementFromFormSegmentData({ segment, isPurchaseSegment: false })
          ),
          ...(locationElement ? [locationElement] : []),
          ...(newCustomersElement ? [newCustomersElement] : []),
        ],
      },
    ],
  }
}

export function validateTargetingRule({
  purchaseSegments,
  interestSegments,
}: {
  purchaseSegments: TargetingSegmentType[]
  interestSegments: TargetingSegmentType[]
}): boolean {
  return purchaseSegments
    .concat(interestSegments)
    .every(segment => !!get(segment, segment.subject.toLowerCase()).length)
}

export function filterEmptyRule(targetingRule: TargetingRuleType | undefined) {
  const filtered = targetingRule?.conjunctions.map(c => {
    return {
      elements: c.elements?.filter(e => {
        if (e.brand_id?.brand_ids) return e.brand_id.brand_ids.length !== 0
        if (e.brand_id_interest?.brand_ids) return e.brand_id_interest.brand_ids.length !== 0
        if (e.category?.categories) return e.category.categories.filter(elem => elem).length !== 0
        if (e.category_interest?.categories)
          return e.category_interest.categories.filter(elem => elem).length !== 0
        if (e.upc?.upcs) return e.upc.upcs.filter(elem => elem).length !== 0
        if (e.upc_interest?.upcs) return e.upc_interest.upcs.filter(elem => elem).length !== 0
        return true
      }),
    }
  })
  return {
    conjunctions: filtered,
  }
}
