// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesEditorialStatesAppeals } from './GetVideoAdGroupsParamDataAttributesEditorialStatesAppeals'
import {
  GetVideoAdGroupsParamDataAttributesEditorialStatesAppealsFromJSON,
  GetVideoAdGroupsParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesEditorialStatesAppealsToJSON,
} from './GetVideoAdGroupsParamDataAttributesEditorialStatesAppeals'
import type { GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot } from './GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot'
import type { GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot } from './GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot'
import {
  GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot'
import type { GetVideoAdGroupsParamDataAttributesEditorialStatesReviewState } from './GetVideoAdGroupsParamDataAttributesEditorialStatesReviewState'
import {
  GetVideoAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSON,
  GetVideoAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesEditorialStatesReviewStateToJSON,
} from './GetVideoAdGroupsParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesEditorialStates
 */
export interface GetVideoAdGroupsParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  type: GetVideoAdGroupsParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesEditorialStatesReviewState}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  reviewState?: GetVideoAdGroupsParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  entitySnapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  decisionSnapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesEditorialStatesAppeals}
   * @memberof GetVideoAdGroupsParamDataAttributesEditorialStates
   */
  appeals: GetVideoAdGroupsParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const GetVideoAdGroupsParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetVideoAdGroupsParamDataAttributesEditorialStatesTypeEnum =
  typeof GetVideoAdGroupsParamDataAttributesEditorialStatesTypeEnum[keyof typeof GetVideoAdGroupsParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesEditorialStates interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesEditorialStates {
  return GetVideoAdGroupsParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSON(json['review_state']),
    entitySnapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
      json['decision_snapshot']
    ),
    appeals: GetVideoAdGroupsParamDataAttributesEditorialStatesAppealsFromJSON(json['appeals']),
  }
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesToJSON(
  value?: GetVideoAdGroupsParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: GetVideoAdGroupsParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot: GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
      value.decisionSnapshot
    ),
    appeals: GetVideoAdGroupsParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
