// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayCampaignsIdParamBrandPageBlockSnapshot,
  instanceOfPutDisplayCampaignsIdParamBrandPageBlockSnapshot,
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockSnapshot'
import {
  PutDisplayCampaignsIdParamBrandPageSnapshot,
  instanceOfPutDisplayCampaignsIdParamBrandPageSnapshot,
  PutDisplayCampaignsIdParamBrandPageSnapshotFromJSON,
  PutDisplayCampaignsIdParamBrandPageSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageSnapshotToJSON,
} from './PutDisplayCampaignsIdParamBrandPageSnapshot'
import {
  PutDisplayCampaignsIdParamCreativeSnapshot,
  instanceOfPutDisplayCampaignsIdParamCreativeSnapshot,
  PutDisplayCampaignsIdParamCreativeSnapshotFromJSON,
  PutDisplayCampaignsIdParamCreativeSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamCreativeSnapshotToJSON,
} from './PutDisplayCampaignsIdParamCreativeSnapshot'
import {
  PutDisplayCampaignsIdParamDisplayAdGroupSnapshot,
  instanceOfPutDisplayCampaignsIdParamDisplayAdGroupSnapshot,
  PutDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSON,
  PutDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamDisplayAdGroupSnapshotToJSON,
} from './PutDisplayCampaignsIdParamDisplayAdGroupSnapshot'
import {
  PutDisplayCampaignsIdParamDisplayAssetSnapshot,
  instanceOfPutDisplayCampaignsIdParamDisplayAssetSnapshot,
  PutDisplayCampaignsIdParamDisplayAssetSnapshotFromJSON,
  PutDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamDisplayAssetSnapshotToJSON,
} from './PutDisplayCampaignsIdParamDisplayAssetSnapshot'
import {
  PutDisplayCampaignsIdParamDisplayProductAssetSnapshot,
  instanceOfPutDisplayCampaignsIdParamDisplayProductAssetSnapshot,
  PutDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSON,
  PutDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSONTyped,
  PutDisplayCampaignsIdParamDisplayProductAssetSnapshotToJSON,
} from './PutDisplayCampaignsIdParamDisplayProductAssetSnapshot'

/**
 * @type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot =

    | ({ type: 'ASSET' } & PutDisplayCampaignsIdParamDisplayAssetSnapshot)
    | ({ type: 'BRAND_PAGE' } & PutDisplayCampaignsIdParamBrandPageSnapshot)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PutDisplayCampaignsIdParamBrandPageBlockSnapshot)
    | ({ type: 'CREATIVE' } & PutDisplayCampaignsIdParamCreativeSnapshot)
    | ({ type: 'DISPLAY_AD_GROUP' } & PutDisplayCampaignsIdParamDisplayAdGroupSnapshot)
    | ({ type: 'PRODUCT_ASSET' } & PutDisplayCampaignsIdParamDisplayProductAssetSnapshot)

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutDisplayCampaignsIdParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutDisplayCampaignsIdParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutDisplayCampaignsIdParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PutDisplayCampaignsIdParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutDisplayCampaignsIdParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PutDisplayCampaignsIdParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutDisplayCampaignsIdParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PutDisplayCampaignsIdParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
