import { mergeStyles } from 'react-select'
import BaseAsyncSelect, { Props as BaseAsyncSelectProps } from 'react-select/async'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import { useSelectComponentOverrides } from './components'
import { DefaultOptionType, CommonSelectProps } from './types'
import { useStyles } from './useStyles'

export type AsyncSelectProps<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
> = BaseAsyncSelectProps<OptionType, IsMulti, GroupType> & CommonSelectProps

export function AsyncSelect<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  position = 'left',
  variant = 'secondary',
  compact,
  block,
  closeMenuOnSelect = false,
  components,
  maxWidth,
  allowSelectAll = true,
  ...props
}: AsyncSelectProps<OptionType, IsMulti, GroupType>) {
  const styles = useStyles<OptionType, IsMulti, GroupType>({
    position,
    variant,
    compact,
    block,
    maxWidth,
  })

  return (
    <BaseAsyncSelect
      hideSelectedOptions={false}
      components={{
        ...useSelectComponentOverrides({ allowSelectAll }),
        ...components,
      }}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultOptions
      {...props}
      styles={props.styles ? mergeStyles(styles, props.styles) : styles}
    />
  )
}
