// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamAssetAltText } from './PostAdminBrandPagesIdLabelParamAssetAltText'
import {
  PostAdminBrandPagesIdLabelParamAssetAltTextFromJSON,
  PostAdminBrandPagesIdLabelParamAssetAltTextFromJSONTyped,
  PostAdminBrandPagesIdLabelParamAssetAltTextToJSON,
} from './PostAdminBrandPagesIdLabelParamAssetAltText'
import type { PostAdminBrandPagesIdLabelParamAssetImage } from './PostAdminBrandPagesIdLabelParamAssetImage'
import {
  PostAdminBrandPagesIdLabelParamAssetImageFromJSON,
  PostAdminBrandPagesIdLabelParamAssetImageFromJSONTyped,
  PostAdminBrandPagesIdLabelParamAssetImageToJSON,
} from './PostAdminBrandPagesIdLabelParamAssetImage'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamAsset
 */
export interface PostAdminBrandPagesIdLabelParamAsset {
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamAsset
   */
  type: PostAdminBrandPagesIdLabelParamAssetTypeEnum
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamAsset
   */
  discriminator: string
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamAssetAltText}
   * @memberof PostAdminBrandPagesIdLabelParamAsset
   */
  altText: PostAdminBrandPagesIdLabelParamAssetAltText
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamAssetImage}
   * @memberof PostAdminBrandPagesIdLabelParamAsset
   */
  image: PostAdminBrandPagesIdLabelParamAssetImage
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamAssetTypeEnum = {
  Asset: 'ASSET',
} as const
export type PostAdminBrandPagesIdLabelParamAssetTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamAssetTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamAssetTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamAsset interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamAsset(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'altText' in value
  isInstance = isInstance && 'image' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamAssetFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamAsset {
  return PostAdminBrandPagesIdLabelParamAssetFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamAssetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamAsset {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: PostAdminBrandPagesIdLabelParamAssetAltTextFromJSON(json['alt_text']),
    image: PostAdminBrandPagesIdLabelParamAssetImageFromJSON(json['image']),
  }
}

export function PostAdminBrandPagesIdLabelParamAssetToJSON(
  value?: PostAdminBrandPagesIdLabelParamAsset | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PostAdminBrandPagesIdLabelParamAssetAltTextToJSON(value.altText),
    image: PostAdminBrandPagesIdLabelParamAssetImageToJSON(value.image),
  }
}
