// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent } from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import type { PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues } from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions
 */
export interface PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [key: string]: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  products?: { [key: string]: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues }
  /**
   *
   * @type {PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  content?: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions {
  return PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON(
  value?: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(value.content),
  }
}
