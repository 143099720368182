// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiAdUsersSessionsControllerCreateInput } from '../models/ApiAdUsersSessionsControllerCreateInput'
import {
  ApiAdUsersSessionsControllerCreateInputFromJSON,
  ApiAdUsersSessionsControllerCreateInputToJSON,
} from '../models/ApiAdUsersSessionsControllerCreateInput'
import type { ApiAdUsersSessionsControllerCreateWithMfaInput } from '../models/ApiAdUsersSessionsControllerCreateWithMfaInput'
import {
  ApiAdUsersSessionsControllerCreateWithMfaInputFromJSON,
  ApiAdUsersSessionsControllerCreateWithMfaInputToJSON,
} from '../models/ApiAdUsersSessionsControllerCreateWithMfaInput'
import type { ApiAdUsersSessionsControllerGenerateAndSendOtpInput } from '../models/ApiAdUsersSessionsControllerGenerateAndSendOtpInput'
import {
  ApiAdUsersSessionsControllerGenerateAndSendOtpInputFromJSON,
  ApiAdUsersSessionsControllerGenerateAndSendOtpInputToJSON,
} from '../models/ApiAdUsersSessionsControllerGenerateAndSendOtpInput'
import type { AuthCreateLegacyResponse } from '../models/AuthCreateLegacyResponse'
import {
  AuthCreateLegacyResponseFromJSON,
  AuthCreateLegacyResponseToJSON,
} from '../models/AuthCreateLegacyResponse'
import type { AuthCreateResponse } from '../models/AuthCreateResponse'
import { AuthCreateResponseFromJSON, AuthCreateResponseToJSON } from '../models/AuthCreateResponse'
import type { AuthLogoutResponse } from '../models/AuthLogoutResponse'
import { AuthLogoutResponseFromJSON, AuthLogoutResponseToJSON } from '../models/AuthLogoutResponse'
import type { AuthShowCapabilitiesResponse } from '../models/AuthShowCapabilitiesResponse'
import {
  AuthShowCapabilitiesResponseFromJSON,
  AuthShowCapabilitiesResponseToJSON,
} from '../models/AuthShowCapabilitiesResponse'
import type { AuthShowResponse } from '../models/AuthShowResponse'
import { AuthShowResponseFromJSON, AuthShowResponseToJSON } from '../models/AuthShowResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { SuccessResponse } from '../models/SuccessResponse'
import { SuccessResponseFromJSON, SuccessResponseToJSON } from '../models/SuccessResponse'

export interface PostAuthRequest {
  clientTimezone?: string
  body?: ApiAdUsersSessionsControllerCreateInput
}

export interface PostAuthMultiFactorRequest {
  body?: ApiAdUsersSessionsControllerCreateWithMfaInput
}

export interface PostAuthMultiFactorGenerateAndSendOtpRequest {
  xApiKey?: string
  xSecretKey?: string
  clientTimezone?: string
  body?: ApiAdUsersSessionsControllerGenerateAndSendOtpInput
}

/**
 *
 */
export class AdUsersSessionsApi extends runtime.BaseAPI {
  /**
   * This method will log you out of the current session on Instacart Ads platform.
   * Logs out the user
   */
  async deleteAuthRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthLogoutResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthLogoutResponseFromJSON(jsonValue))
  }

  /**
   * This method will log you out of the current session on Instacart Ads platform.
   * Logs out the user
   */
  async deleteAuth(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthLogoutResponse> {
    const response = await this.deleteAuthRaw(initOverrides)
    return await response.value()
  }

  /**
   * This method will return your logged in user session information.
   * Returns the logged in user session information
   */
  async getAuthRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthShowResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthShowResponseFromJSON(jsonValue))
  }

  /**
   * This method will return your logged in user session information.
   * Returns the logged in user session information
   */
  async getAuth(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthShowResponse> {
    const response = await this.getAuthRaw(initOverrides)
    return await response.value()
  }

  /**
   * This method will return your logged in user session information.
   * Returns the logged in user\'s capabilities based on their roles and permissions
   */
  async getAuthCapabilitiesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthShowCapabilitiesResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth/capabilities`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuthShowCapabilitiesResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return your logged in user session information.
   * Returns the logged in user\'s capabilities based on their roles and permissions
   */
  async getAuthCapabilities(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthShowCapabilitiesResponse> {
    const response = await this.getAuthCapabilitiesRaw(initOverrides)
    return await response.value()
  }

  /**
   * This method will log you out of the current session on Instacart Ads platform.
   * Logs out the user
   */
  async getAuthLogoutRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthLogoutResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth/logout`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthLogoutResponseFromJSON(jsonValue))
  }

  /**
   * This method will log you out of the current session on Instacart Ads platform.
   * Logs out the user
   */
  async getAuthLogout(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthLogoutResponse> {
    const response = await this.getAuthLogoutRaw(initOverrides)
    return await response.value()
  }

  /**
   * This method will log you into Instacart Ads platform and establish a session.
   * Logs in the user
   */
  async postAuthRaw(
    requestParameters: PostAuthRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthCreateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      requestParameters.clientTimezone !== undefined &&
      requestParameters.clientTimezone !== null
    ) {
      headerParameters['Client-Timezone'] = String(requestParameters.clientTimezone)
    }

    const response = await this.request(
      {
        path: `/auth`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersSessionsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthCreateResponseFromJSON(jsonValue))
  }

  /**
   * This method will log you into Instacart Ads platform and establish a session.
   * Logs in the user
   */
  async postAuth(
    requestParameters: PostAuthRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthCreateResponse> {
    const response = await this.postAuthRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will log you into Instacart Ads platform and establish a session.
   * Validates multi-factor authentication and logs in the user
   */
  async postAuthMultiFactorRaw(
    requestParameters: PostAuthMultiFactorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthCreateLegacyResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/auth/multi_factor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersSessionsControllerCreateWithMfaInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuthCreateLegacyResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will log you into Instacart Ads platform and establish a session.
   * Validates multi-factor authentication and logs in the user
   */
  async postAuthMultiFactor(
    requestParameters: PostAuthMultiFactorRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthCreateLegacyResponse> {
    const response = await this.postAuthMultiFactorRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to generate and send a one-time password to the user\'s configured destination.
   * Generate and send MFA one-time password
   */
  async postAuthMultiFactorGenerateAndSendOtpRaw(
    requestParameters: PostAuthMultiFactorGenerateAndSendOtpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    if (
      requestParameters.clientTimezone !== undefined &&
      requestParameters.clientTimezone !== null
    ) {
      headerParameters['Client-Timezone'] = String(requestParameters.clientTimezone)
    }

    const response = await this.request(
      {
        path: `/auth/multi_factor/generate_and_send_otp`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersSessionsControllerGenerateAndSendOtpInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => SuccessResponseFromJSON(jsonValue))
  }

  /**
   * Use this method to generate and send a one-time password to the user\'s configured destination.
   * Generate and send MFA one-time password
   */
  async postAuthMultiFactorGenerateAndSendOtp(
    requestParameters: PostAuthMultiFactorGenerateAndSendOtpRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SuccessResponse> {
    const response = await this.postAuthMultiFactorGenerateAndSendOtpRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
