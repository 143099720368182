import { css, keyframes } from '@emotion/react'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'

function useStyles() {
  const animation = keyframes`{
    0% {
      opacity: 0.5;
    }
    
    33% {
      opacity: 1;
    }
    
    66% {
      opacity: 0.5;
    }
    
    100% {
      opacity: 0.5;
    }
  }`

  return css({
    '&.circle': {
      animationName: animation,
      animationFillMode: 'backwards',
      animationDuration: '600ms',
      animationIterationCount: 'infinite',
      backgroundColor: 'black',
      display: 'inline-block',
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      margin: '0 4px',
    },

    '&.circle:nth-of-type(2)': {
      animationDelay: '200ms',
    },

    '&.circle:nth-of-type(3)': {
      animationDelay: '400ms',
    },
  })
}

export default function Loader({ loadingMessageId }: { loadingMessageId?: MessageIdType }) {
  const styles = useStyles()

  return (
    <span>
      <span css={styles} className="circle" />
      <span css={styles} className="circle" />
      <span css={styles} className="circle" />
      {loadingMessageId && (
        <div>
          <br />
          <FormattedMessage id={loadingMessageId} />
        </div>
      )}
    </span>
  )
}
