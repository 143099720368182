import { css } from '@emotion/react'
import { Text, elevation, CloseIcon, Theme, useTheme } from '@instacart/ids-core'
import { FC, useMemo } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { Tooltip, TooltipReference, useTooltipState } from 'components/ids-ads'
import { MessageIdType } from 'locales/types'
import StatusNotification from 'pages/AdGroupEdit/components/StatusNotification'
import { BidStrengthEnum } from 'service/types'
import ProgressBar from './ProgressBar'

export type BidStrengthStatusValues =
  | BidStrengthEnum['Green']
  | BidStrengthEnum['Yellow']
  | BidStrengthEnum['Red']
  | BidStrengthEnum['LowQuality']
  | false

export interface BidStrengthProps {
  suggestedBid: number
  uncompetitiveBid: number
  cpcBid: number
  defaultBid: number
  adGroupDefaultBid?: number
  isConfidentBid?: boolean | undefined
  type?: 'bubble' | 'text' | 'bar'
  bidStrength?: BidStrengthStatusValues
  showTooltip?: boolean
}

interface BidScoreProps {
  isConfidentBid: boolean | undefined
  resolvedBid: number
  suggestedBid: number | undefined
  uncompetitiveBid: number | undefined
  bidStrength?: BidStrengthStatusValues
}

const useStyles = (theme: Theme) => {
  return {
    bidStrength: css`
      display: inline;
    `,
    bidStrengthTooltipContent: css({
      background: 'none',
      padding: 0,
      ...elevation.low.shadow,
    }),
    bidStrengthTooltipWrapper: css`
      display: inline;
    `,
    indicator: css`
      height: 10px;
      margin-right: 4px;
      overflow: visible;
      width: 10px;
    `,
    bubble: css`
      fill: ${theme.colors.systemGrayscale20};

      .bid-strength-status-green & {
        fill: #e8f8e2;
        stroke: ${theme.colors.brandPrimaryRegular};
      }

      .bid-strength-status-yellow svg:nth-child(-n + 2) & {
        fill: #fff7d4;
        stroke: #f3cb73;
      }

      .bid-strength-status-red svg:first-child & {
        fill: #fbdce5;
        stroke: ${theme.colors.systemDetrimentalRegular};
      }
    `,
    text: css`
      display: inline;
    `,
  }
}

export const bidScore = ({
  isConfidentBid,
  resolvedBid,
  suggestedBid,
  uncompetitiveBid,
  bidStrength,
}: BidScoreProps): BidStrengthStatusValues | false => {
  // Backend may not return a bid strength when it is not a confident bid
  if (isConfidentBid !== undefined && !isConfidentBid) {
    return false
  }

  if (bidStrength) {
    return bidStrength
  }

  // This calculation is only used by type === 'text'
  // i.e the BidStrength component on AdGroupCpcOverview Page.
  // TODO: migrate this calculation to backend for consistency.
  if (suggestedBid !== undefined) {
    if (resolvedBid >= suggestedBid) {
      return BidStrengthEnum.Green
    }
    if (
      uncompetitiveBid !== undefined &&
      uncompetitiveBid < resolvedBid &&
      resolvedBid < suggestedBid
    ) {
      return BidStrengthEnum.Yellow
    }
  }

  return BidStrengthEnum.Red
}

const LowQualityIndicator = () => {
  return (
    <span data-testid="icon-low-quality">
      <CloseIcon size={18} color="systemGrayscale70" />
    </span>
  )
}

const BidStrength: FC<React.PropsWithChildren<BidStrengthProps>> = props => {
  const theme = useTheme()
  const {
    type = 'bubble',
    suggestedBid,
    uncompetitiveBid,
    cpcBid: cpc,
    defaultBid,
    adGroupDefaultBid,
    isConfidentBid,
    bidStrength,
    showTooltip,
  } = props
  const resolvedBid = cpc || defaultBid || adGroupDefaultBid || 0
  const styles = useStyles(theme)

  const getStatusClassName = (status: BidStrengthStatusValues): string => {
    if (!status || status === BidStrengthEnum.LowQuality) {
      return ''
    }
    return `bid-strength-status-${status}`
  }

  const bidStrengthTextMessageId = (status: BidStrengthStatusValues): MessageIdType => {
    if (!status || status === BidStrengthEnum.LowQuality) {
      return 'common.na'
    }
    return `bidStrength.${status}`
  }

  const status = bidScore({
    isConfidentBid,
    resolvedBid,
    suggestedBid,
    uncompetitiveBid,
    bidStrength,
  })
  const tooltip = useTooltipState()

  const barProgress = useMemo(() => {
    if (status === BidStrengthEnum.Green) {
      return 0.75
    }
    if (status === BidStrengthEnum.Yellow) {
      return 0.425
    }
    if (status === BidStrengthEnum.Red) {
      return 0.25
    }
    return 0
  }, [status])

  return (
    <>
      <TooltipReference {...tooltip} css={styles.bidStrengthTooltipWrapper}>
        <div
          css={styles.bidStrength}
          className={getStatusClassName(status)}
          data-testid="bid-strength-wrapper"
          data-bidstrength={status}
        >
          {type === 'bubble' &&
            (status === 'low_quality' ? (
              <LowQualityIndicator />
            ) : (
              <>
                <svg height="10" width="10" css={styles.indicator}>
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke={theme.colors.systemGrayscale30}
                    strokeWidth="1"
                    css={styles.bubble}
                  />
                </svg>
                <svg height="10" width="10" css={styles.indicator}>
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke={theme.colors.systemGrayscale30}
                    strokeWidth="1"
                    css={styles.bubble}
                  />
                </svg>
                <svg height="10" width="10" css={styles.indicator}>
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke={theme.colors.systemGrayscale30}
                    strokeWidth="1"
                    css={styles.bubble}
                  />
                </svg>
              </>
            ))}
          {type === 'bar' &&
            (status === 'low_quality' ? (
              <LowQualityIndicator />
            ) : (
              <ProgressBar progress={barProgress} />
            ))}
          {type === 'text' && (
            <Text typography="bodyMedium2" css={styles.text}>
              <span>
                <FormattedMessage id={bidStrengthTextMessageId(status)} />
              </span>
            </Text>
          )}
        </div>
      </TooltipReference>
      {showTooltip && (
        <Tooltip
          {...tooltip}
          css={styles.bidStrengthTooltipContent}
          styles={{ tooltip: { maxWidth: 'none' } }}
        >
          <StatusNotification
            defaultBid={resolvedBid.toString()}
            suggestedBid={String(suggestedBid)}
            status={status}
            show
            style={{ padding: 0 }}
          />
        </Tooltip>
      )}
    </>
  )
}

export default BidStrength
