// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch } from './DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear } from './DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface DisplayAdGroupResponseDataAttributesAnalyticsAttributionData
 */
export interface DisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof DisplayAdGroupResponseDataAttributesAnalyticsAttributionData
   */
  linear: DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof DisplayAdGroupResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the DisplayAdGroupResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfDisplayAdGroupResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): DisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  return DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: DisplayAdGroupResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: DisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
