import { ProductEx, ProductsTableGroupKeys } from 'pages/AdGroupEdit/V2/adGroupEdit.types'
import { GetAdGroupProductsResponseDataTypeEnum } from 'service/openapi/__codegen__/models/GetAdGroupProductsResponseData'
import { Product } from 'service/types'

const UPC_REGEX = /[0-9]{8,}/g

const productIdFunc = (p: Product) => `${p.attributes.productId}-${p.attributes.upc}`

export const convertToProductEx: (products: Product[]) => ProductEx[] = (products: Product[]) =>
  products.map((p, index) => {
    const isFirstProduct = index === 0
    const pex: ProductEx = {
      ...p,
      enabled: true,
      existing: false,
      selected: false,
      isFirstProduct,
      productUpdateSubmitted: false,
    }

    if (p.type === GetAdGroupProductsResponseDataTypeEnum.AdGroupProduct) {
      pex.enabled = p.attributes.enabled
      pex.existing = true
    }

    return pex
  })

export const markSelectedAndExistingProducts = (
  products: ProductEx[],
  selectedProducts: ProductEx[],
  existingProducts: ProductEx[]
) => {
  const selectedKeys = new Set(selectedProducts.map(productIdFunc))
  const existingKeys = new Set(existingProducts.map(productIdFunc))

  return products.map(p => {
    const transformedId = productIdFunc(p)

    return {
      ...p,
      selected: selectedKeys.has(transformedId),
      existing: existingKeys.has(transformedId),
    }
  })
}

export const getEnabledProductIds = (products: ProductEx[]) =>
  products.filter(product => product.enabled).map(product => product.attributes.productId)

export const removeLeadingString = (text: string | null, lead: string | null) => {
  if (!text) return text

  const re = new RegExp(`^${lead}`)
  return text.replace(re, '').trim()
}

export const flattenProductsToUpcArray = (products: Product | Product[]) => {
  const toArray = Array.isArray(products) ? products : [products]
  return toArray.map(product => product.attributes.upc)
}

export const groupProductsTableRows = (rowsToRender: ProductEx[]) => {
  // Manually creating a collection for each grouping to control order the groupings appear
  const selected: ProductEx[] = []
  const active: ProductEx[] = []
  const paused: ProductEx[] = []

  rowsToRender.forEach(row => {
    if (row.existing) {
      if (row.enabled) {
        return active.push(row)
      }
      return paused.push(row)
    }

    return selected.push(row)
  })

  return {
    [ProductsTableGroupKeys.SELECTED]: selected,
    [ProductsTableGroupKeys.ACTIVE]: active,
    [ProductsTableGroupKeys.PAUSED]: paused,
  }
}

export const getEmptyStateMessage = (searchTerm: string) => {
  const hasSearchTerm = !!searchTerm
  if (!hasSearchTerm) {
    return 'pages.adGroupEdit.productsTable.emptyState.default'
  }
  if (searchTerm.match(UPC_REGEX)) {
    return 'pages.adGroupEdit.productsTable.emptyState.empty.searchByUPC'
  }
  return 'pages.adGroupEdit.productsTable.emptyState.empty'
}
