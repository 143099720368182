import { useEffect, useRef } from 'react'

// Returns true on the initial render of the component and false for subsequent renders.
// This is useful for skipping an effect on the initial render of a component.
export default function useIsInitialRender() {
  const initial = useRef(true)
  useEffect(() => {
    initial.current = false
  }, [])
  return initial.current
}
