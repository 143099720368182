// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogo } from './ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImage } from './ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobile } from './ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsImageWeb } from './ReservationAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile } from './ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnail } from './ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobile } from './ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWeb } from './ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile } from './ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb } from './ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import type { ReservationAdGroupResponseDataAttributesCreativeAssetsVideo } from './ReservationAdGroupResponseDataAttributesCreativeAssetsVideo'
import {
  ReservationAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeAssetsVideoFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeAssetsVideoToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributesCreativeAssets
 */
export interface ReservationAdGroupResponseDataAttributesCreativeAssets {
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWeb}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  topImageWeb?: ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsImageWeb}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  imageWeb?: ReservationAdGroupResponseDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobile}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  imageMobile?: ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobile}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  topImageMobile?: ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  squareImageMobile?: ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImage}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  heroImage?: ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogo}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  brandLogo?: ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsVideo}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  video?: ReservationAdGroupResponseDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnail}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  thumbnail?: ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageWeb?: ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageMobile?: ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributesCreativeAssets interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesCreativeAssetsFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributesCreativeAssets {
  return ReservationAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function ReservationAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON(json['hero_image']),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON(json['brand_logo']),
    video: !exists(json, 'video')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function ReservationAdGroupResponseDataAttributesCreativeAssetsToJSON(
  value?: ReservationAdGroupResponseDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: ReservationAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON(value.imageWeb),
    image_mobile: ReservationAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: ReservationAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile:
      ReservationAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image: ReservationAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON(
      value.heroImage
    ),
    brand_logo: ReservationAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON(
      value.brandLogo
    ),
    video: ReservationAdGroupResponseDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: ReservationAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON(
      value.thumbnail
    ),
    uvc_image_web: ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON(
      value.uvcImageWeb
    ),
    uvc_image_mobile: ReservationAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON(
      value.uvcImageMobile
    ),
  }
}
