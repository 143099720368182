import { createContext, useContext } from 'react'
import { FlipperIdType } from 'common/types'
import { MfaFactor } from 'components/MultiFactorAuth/utils/multiFactorAuth.types'
import { UserType } from 'service/admin/user'
import { AdUser, SupportedCountryId } from 'service/auth'
import {
  GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes,
  GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum as ExternalMarketplaces,
} from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'
import { GetAuthParamDataAttributesFeatureHints } from 'service/openapi/__codegen__/models/GetAuthParamDataAttributesFeatureHints'
import { GetAuthParamDataAttributesFeatureReminders } from 'service/openapi/__codegen__/models/GetAuthParamDataAttributesFeatureReminders'

type SharedUserValues = Pick<
  AdUser,
  'currentAccount' | 'email' | 'firstName' | 'lastName' | 'userAccounts' | 'singleSignOnEnabled'
>

export interface AuthCtx {
  csrf: null | string
  user:
    | null
    | ({
        type: string
        flippers: string[]
        mfaFactors: MfaFactor[]
        capabilities: Record<string, boolean>
        activeVariants: Record<string, string>
        featureHints: GetAuthParamDataAttributesFeatureHints[]
        featureReminders: GetAuthParamDataAttributesFeatureReminders[]
      } & SharedUserValues)
  authed: boolean
  checked: boolean
  refresh?(): Promise<void>
}

/*
  example of a logged-in session context:
  {
    authed: true,
    user: {
      currentAccount: {
        id: 123,
        uuid: 'cd8c2d08-596e-4776-8871-68x38x14109x',
        name: 'Acme',
        paymentStatus: 'active',
        nextPaymentOption: null
      },
      email: 'tim.marshall@instacart.com',
      firstName: 'Tim',
      lastName: 'Marshall',
      type: 'admin',
      flipper: ["self_service_ui_example"],
      capabilities: {
        can_manage_account_users: true,
        can_manage_admin_users: false,
        can_manage_accounts: true,
        can_manage_advertisers: true,
        can_manage_agencies: true,
        can_manage_campaign: true,
        can_manage_billing: true
      },
      featureHints: [
        {
          id: "1",
          type: "feature_hint",
          attributes: {
            name: "header.helpCenter"
          }
        },
        {
          id: "2",
          type: "feature_hint",
          attributes: {
            name: "pages.adGroup.actions.title"
          }
        }
      ]
    },
    checked: true,
    csrf: 'F4zmmxrAHKEtTKUCvj/K/XWkS1BwANuwMCgtdYq6JGuDMNtV20xsTju4ezEFIR4iQTU6zsgeAoMiEf6AMrPqXg=='
  }
*/

export const initialAuthContext: AuthCtx = {
  authed: false,
  user: null,
  csrf: null,
  checked: false,
}

export const AuthContext = createContext(initialAuthContext)
AuthContext.displayName = 'AuthContext'

export const useAuthContext = () => useContext(AuthContext)

export function isFlipperEnabled(authContext: AuthCtx, flipper: FlipperIdType) {
  return !!authContext?.user?.flippers.includes(flipper)
}

export function isInstacartEmployee(user?: AuthCtx['user']): boolean {
  return user?.type === UserType.InstacartEmployee
}

export function isAdvertiserOrAgencyUser(user?: AuthCtx['user']): boolean {
  return user?.type === UserType.AdvertiserUser || user?.type === UserType.AgencyUser
}

export function hasNoAccessToAccounts(user?: AuthCtx['user']): boolean {
  // employees have full access by way of a special val in RBAC (-1) not returned in userAccounts
  if (isInstacartEmployee(user)) {
    return false
  }
  return user?.userAccounts === 0
}

export function isDefaultAccount(authContext: AuthCtx) {
  const accountType = authContext?.user?.currentAccount?.accountType
  return accountType === AccountTypes.Default
}

export function isRetailerAccount(authContext: AuthCtx) {
  const accountType = authContext?.user?.currentAccount?.accountType
  return accountType === AccountTypes.Retailer || accountType === AccountTypes.RetailerRsd
}

export function isRetailerRsdAccount(authContext: AuthCtx) {
  const accountType = authContext?.user?.currentAccount?.accountType
  return accountType === AccountTypes.RetailerRsd
}

export function isAdvertiserRtdAccount(authContext: AuthCtx) {
  const accountType = authContext?.user?.currentAccount?.accountType
  return accountType === AccountTypes.AdvertiserRtd
}

export function isProviAccount(authContext: AuthCtx) {
  const externalMarketplace = authContext?.user?.currentAccount?.externalMarketplace
  return externalMarketplace === ExternalMarketplaces.Provi
}

export function getExchangeName(authContext: AuthCtx) {
  const exchangeName = authContext?.user?.currentAccount?.exchangeName
  if (exchangeName === undefined) {
    throw new Error(
      'This account is incorrectly configured. Exchange name is not set. Contact instacart for assistance.'
    )
  }
  return exchangeName
}

export function isExternalMarketplaceAccount(authContext: AuthCtx) {
  return Boolean(authContext?.user?.currentAccount?.externalMarketplace)
}

export function hasAnyAccountTypes(authContext: AuthCtx, accountTypes: AccountTypes[]) {
  const accountType = authContext?.user?.currentAccount?.accountType
  return accountTypes.some(type => type === accountType)
}

export function isSingleSignOnEnabled(authContext: AuthCtx) {
  return !!authContext?.user?.singleSignOnEnabled
}

export function isInsightsPortalEnabled(authContext: AuthCtx) {
  return authContext?.user?.currentAccount?.canAccessInsightsPortal
}

export function isCustomerAndBasketSharePageEnabled(authContext: AuthCtx) {
  return authContext?.user?.currentAccount?.canAccessCustomerAndBasketShare
}

export function hasAgencySet(authContext: AuthCtx) {
  return !!authContext?.user?.currentAccount?.agencyId
}

export function isCanadianAccount(authContext: AuthCtx) {
  return authContext?.user?.currentAccount?.countryId === SupportedCountryId.CANADA
}
