import { ReactNode } from 'react'
import useIntl from 'common/useIntl'
import {
  Modal,
  ModalProps,
  ModalButtons,
  ModalContent,
  ModalState,
  ButtonProps,
} from 'components/ids-ads'
import { GenericMessageDescriptor, GenericExtendedMessageDescriptor } from 'locales/types'

export interface TwoButtonModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
  modalState: ModalState
  isDetrimental?: boolean
  onPrimaryClick: () => void
  modalTitleId: GenericMessageDescriptor
  modalContentId: GenericExtendedMessageDescriptor
  modalPrimaryCtaId?: GenericMessageDescriptor
  modalPrimaryCtaProps?: ButtonProps
  modalPrimaryCtaTestId?: string
  modalSecondaryCtaProps?: ButtonProps
  modalSecondaryCtaId?: GenericMessageDescriptor
  modalSecondaryTestId?: string
  modalButtons?: ReactNode
  children?: JSX.Element
}

export function TwoButtonModal(props: TwoButtonModalProps) {
  const {
    modalState,
    onClose,
    onPrimaryClick,
    modalButtons,
    modalTitleId,
    modalPrimaryCtaId,
    modalPrimaryCtaProps,
    modalPrimaryCtaTestId,
    modalSecondaryCtaProps,
    modalSecondaryCtaId,
    modalSecondaryTestId,
    modalContentId,
    isDetrimental,
    children,
  } = props
  const intl = useIntl()

  return (
    <Modal title={modalTitleId} allowClose={false} hideCloseIcon {...props} modalState={modalState}>
      <ModalContent>
        <div css={{ whiteSpace: 'pre-line' }}>{intl.genericFormatMessage(modalContentId)}</div>
      </ModalContent>
      {modalButtons ?? (
        <ModalButtons
          onClose={onClose}
          secondaryButtonProps={modalSecondaryCtaProps}
          secondaryButtonLabel={modalSecondaryCtaId}
          secondaryTestId={modalSecondaryTestId}
          primaryButtonProps={{ onClick: onPrimaryClick, ...modalPrimaryCtaProps }}
          primaryButtonLabel={modalPrimaryCtaId ?? 'common.confirm'}
          primaryTestId={modalPrimaryCtaTestId}
          isDetrimental={isDetrimental}
        />
      )}
      {children}
    </Modal>
  )
}
