import moment from 'moment'
import { DateUtils, RangeModifier } from 'react-day-picker'
import { SelectedDays } from 'common/types'
import { TimespanEnum, WeekStartEnum } from 'pages/Insight/common.enums'

export const DATE_LABEL_CUSTOM = 'Custom'

/**
 * Returns a date range that is a maximum of 14 days, depending on whether the
 * `selectedDay` falls within or outside of the `currentRange` range.
 * @param selectedDay - the day picked on the date picker calendar
 * @param currentRange - currently selected days on the date picker calendar
 */
export const getDateRangeForDailyPeriod = (
  selectedDay: Date,
  currentRange: RangeModifier
): SelectedDays => {
  if (moment(selectedDay).diff(moment(currentRange.from), 'days') > 13) {
    return {
      from: moment(selectedDay).subtract(13, 'days').toDate(),
      to: selectedDay,
    }
  }

  const range = DateUtils.addDayToRange(selectedDay, currentRange)

  if (
    !DateUtils.isDayInRange(range.to, {
      from: range.from,
      to: moment(range.from).add(13, 'days').toDate(),
    })
  ) {
    range.to = moment(range.from).add(13, 'days').toDate()
  }

  return {
    from: range.from,
    to: range.to,
  }
}

export const getSelectedDays = (
  selectedLabel: string,
  timespan: TimespanEnum,
  selectedDays: SelectedDays
): SelectedDays => {
  if (timespan === TimespanEnum.Day) {
    if (
      selectedLabel !== DATE_LABEL_CUSTOM ||
      moment(selectedDays.to).diff(moment(selectedDays.from), 'days') > 13 ||
      (selectedDays.to && selectedDays.to >= moment().subtract(4, 'day').toDate())
    ) {
      return { from: undefined, to: undefined }
    }
  }

  return selectedDays
}

export const disableApplyButton = (
  selectedDays: SelectedDays,
  timespan: TimespanEnum,
  weekStart: WeekStartEnum
) => {
  if (timespan === TimespanEnum.Week || timespan === TimespanEnum.WeekSun) {
    if (
      (weekStart === WeekStartEnum.Sunday && moment(selectedDays.to).day() === 1) ||
      (weekStart === WeekStartEnum.Monday && moment(selectedDays.to).day() === 2)
    ) {
      if (moment().date() - moment(selectedDays.to).date() === 0) return true
    }
  }

  if (timespan === TimespanEnum.Month) {
    if (moment(selectedDays.to).date() === 2) return true
  }

  if (selectedDays.from && selectedDays.to) {
    const { from, to } = selectedDays
    const isSameDay =
      from.getDate() === to.getDate() &&
      from.getMonth() === to.getMonth() &&
      from.getFullYear() === to.getFullYear()
    const isLast4Days = selectedDays.to > moment().subtract(4, 'day').toDate()

    if (isSameDay && isLast4Days) return true
  }

  return !selectedDays.from
}
