// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiProductsControllerIndexGroupIdsInput
 */
export interface ApiProductsControllerIndexGroupIdsInput {
  /**
   * An array of group_ids you want to search for
   * @type {Array<string>}
   * @memberof ApiProductsControllerIndexGroupIdsInput
   */
  groupIds?: Array<string>
}

/**
 * Check if a given object implements the ApiProductsControllerIndexGroupIdsInput interface.
 */
export function instanceOfApiProductsControllerIndexGroupIdsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiProductsControllerIndexGroupIdsInputFromJSON(
  json: any
): ApiProductsControllerIndexGroupIdsInput {
  return ApiProductsControllerIndexGroupIdsInputFromJSONTyped(json, false)
}

export function ApiProductsControllerIndexGroupIdsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiProductsControllerIndexGroupIdsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    groupIds: !exists(json, 'group_ids') ? undefined : json['group_ids'],
  }
}

export function ApiProductsControllerIndexGroupIdsInputToJSON(
  value?: ApiProductsControllerIndexGroupIdsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    group_ids: value.groupIds,
  }
}
