import { css } from '@emotion/react'
import { spacing, Text } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext, useEffect } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import BrandsView from 'components/organisms/TargetingRule/components/BrandsView'
import CustomDropdown from 'components/organisms/TargetingRule/components/CustomDropdown'
import ProductsView from 'components/organisms/TargetingRule/components/ProductsView'
import {
  getTargetingSegmentsFieldName,
  SegmentType,
  shortcutSubjectOptions,
  Subject,
  Condition,
  timelineOptions,
} from 'components/organisms/TargetingRule/constants'
import {
  TargetingRuleContext,
  TargetingSegmentContext,
} from 'components/organisms/TargetingRule/hooks'
import { TargetingFormData } from 'components/organisms/TargetingRule/types'

const styles = {
  labelText: css({
    paddingTop: spacing.s4,
    marginRight: spacing.s4,
    marginLeft: spacing.s4,
    display: 'block',
  }),
  segmentDiv: css({
    display: 'flex',
    alignContent: 'center',
  }),
  segmentChildDiv: css({
    paddingTop: spacing.s16,
    display: 'flex',
    alignContent: 'center',
  }),
  segmentContentDiv: css({
    width: '95%',
  }),
}

export const buyMyBrandNotMyProductShortcutName = 'TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT'

const BuyMyBrandNotMyProduct = () => {
  const { brands } = useContext(TargetingRuleContext)
  const segmentType = SegmentType.PURCHASE
  const segmentsFieldName = getTargetingSegmentsFieldName(segmentType)
  const { values, setFieldValue } = useFormikContext<TargetingFormData>()
  const timeline = get(values, `purchaseSegments[0].timeline`) || 30
  useEffect(() => {
    setFieldValue('purchaseSegments[0].subject', Subject.BRANDS)
    setFieldValue('purchaseSegments[1].subject', Subject.UPCS)
    setFieldValue('purchaseSegments[1].condition', Condition.NEGATIVE)
    setFieldValue('purchaseSegments[1].timeline', timeline)
  }, [setFieldValue, timeline])
  return (
    <>
      <div css={styles.segmentDiv}>
        <div css={styles.segmentContentDiv}>
          <div css={styles.segmentChildDiv}>
            <Text typography="bodyMedium2" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.first.inputsPrefix" />
            </Text>
            <Text typography="subtitleMedium" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.condition.purchase.positive" />
            </Text>
            <Text typography="bodyMedium2" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.purchased.subjects.label" />
            </Text>
            <CustomDropdown
              fieldName="purchaseSegments[0].subject"
              options={shortcutSubjectOptions({
                isCategoriesAllowed: false,
                isBrandsTargetingAllowed: !!brands.length,
                isProductsTargetingAllowed: false,
              })}
            />
          </div>
          <div css={styles.segmentChildDiv}>
            <TargetingSegmentContext.Provider
              value={{
                segmentType,
                fieldsPrefix: `${segmentsFieldName}[0].`,
              }}
            >
              <BrandsView />
            </TargetingSegmentContext.Provider>
          </div>
          <div css={styles.segmentChildDiv}>
            <Text typography="bodyMedium2" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.second.inputsPrefix" />
            </Text>
            <Text typography="subtitleMedium" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.condition.purchase.negative" />
            </Text>
            <Text typography="bodyMedium2" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.purchased.subjects.label" />
            </Text>
            <CustomDropdown
              fieldName="purchaseSegments[1].subject"
              options={shortcutSubjectOptions({
                isCategoriesAllowed: false,
                isBrandsTargetingAllowed: false,
                isProductsTargetingAllowed: true,
              })}
            />
            <Text typography="bodyMedium2" css={{ ...styles.labelText, marginLeft: undefined }}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.timeline.label" />
            </Text>
            <CustomDropdown fieldName="purchaseSegments[0].timeline" options={timelineOptions} />
          </div>
          <div css={styles.segmentChildDiv}>
            <TargetingSegmentContext.Provider
              value={{
                segmentType,
                fieldsPrefix: `${segmentsFieldName}[1].`,
              }}
            >
              <ProductsView />
            </TargetingSegmentContext.Provider>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyMyBrandNotMyProduct
