import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { MouseEvent, KeyboardEvent, FC } from 'react'
import useIntl from 'common/useIntl'
import { GenericMessageDescriptor } from '../locales/types'

export interface SecondaryRadioCardProps {
  title: string
  isSelected: boolean
  isDisabled?: boolean
  subtext: string
  name: string
  value: string
  pillText?: GenericMessageDescriptor
  onClick: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void
}
const useStyles = (isDisabled?: boolean) => {
  const theme = useTheme()
  return {
    title: css({
      color: theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium1,
    }),
    text: css({
      color: theme.colors.systemGrayscale50,
      ...theme.typography.bodyMedium2,
    }),
    cardWrapper: css({
      backgroundColor: theme.colors.systemGrayscale00,
      borderRadius: 4,
      position: 'relative',
      maxWidth: '100%',
      padding: '24px 15px 15px 53px',
      marginBottom: 16,
      boxSizing: 'border-box',
      '&:focus': {
        outline: 0,
      },
    }),
    pill: css({
      marginLeft: '5px',
      borderRadius: '16px',
      background: theme.colors.systemSuccessLight,
      color: theme.colors.systemSuccessDark,
      padding: '3px 7px',
      ...theme.typography.labelMedium,
    }),
    label: css({
      cursor: isDisabled ? 'default' : 'pointer',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      display: 'flex',
      '&:before': {
        width: 20,
        height: 20,
        content: '""',
        border: `1px solid ${theme.colors.systemGrayscale50}`,
        backgroundColor: theme.colors.systemGrayscale00,
        borderRadius: '50%',
        margin: '0px 15px 15px 0px',
      },
      '&:after': {
        width: 14,
        height: 14,
        content: '""',
        backgroundColor: theme.colors.systemSuccessRegular,
        display: 'none',
        borderRadius: '50%',
        position: 'absolute',
        top: 4,
        left: 4,
      },
      '&:focus': { outline: 0 },
      span: {
        marginTop: 15,
      },
    }),
    input: css({
      display: 'none',
      '&:checked ~ label': {
        '&:before': {
          border: `1px solid ${theme.colors.systemSuccessRegular}`,
        },
        '&:after': {
          display: 'initial',
        },
      },
    }),
    isDisabled: css({
      filter: isDisabled ? 'opacity(40%)' : 'inherit',
    }),
  }
}

const SecondaryRadioCard: FC<React.PropsWithChildren<SecondaryRadioCardProps>> = props => {
  const { isSelected, isDisabled, title, subtext, name, onClick, value, pillText } = props
  const intl = useIntl()

  const styles = useStyles(isDisabled)
  return (
    <div
      css={[styles.cardWrapper, styles.isDisabled]}
      onClick={e => (isDisabled ? null : onClick(e))}
      onKeyPress={e => (isDisabled ? null : onClick(e))}
      role="button"
      tabIndex={0}
    >
      <input
        id={value}
        type="radio"
        name={name}
        value={value}
        checked={isSelected}
        css={styles.input}
        data-testid={`select-${value}`}
        disabled={isDisabled}
      />
      <label css={styles.label} htmlFor={value}>
        <div>
          <div css={styles.title}>
            {title}
            {pillText && (
              <span css={styles.pill} data-testid={`pill-text-${value}`}>
                {intl.genericFormatMessage(pillText)}
              </span>
            )}
          </div>
          <div css={styles.text}>{subtext}</div>
        </div>
      </label>
    </div>
  )
}
export default SecondaryRadioCard
