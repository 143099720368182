// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargeting } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargeting'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargetingFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargetingFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargetingToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargeting'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
 */
export interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy {
  /**
   * Targeted keywords
   * @type {Array<string>}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
   */
  keywords?: Array<string>
  /**
   * Specify how broadly you would like the keywords to be matched against search terms. Default is broad if not provided.
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
   */
  keywordMatchType?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyKeywordMatchTypeEnum
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargeting}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
   */
  userTargeting?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargeting
  /**
   * Targeting rule
   * @type {{ [key: string]: any; }}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
   */
  targetingRule?: { [key: string]: any }
  /**
   * Targeting shortcut, need to pass both this field and user_targeting
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
   */
  targetingShortcut?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyTargetingShortcutEnum
}

/**
 * @export
 */
export const ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyKeywordMatchTypeEnum =
  {
    Exact: 'exact',
    Narrow: 'narrow',
    Broad: 'broad',
  } as const
export type ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyKeywordMatchTypeEnum =
  typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyKeywordMatchTypeEnum[keyof typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyKeywordMatchTypeEnum]

/**
 * @export
 */
export const ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyTargetingShortcutEnum =
  {
    NewToInstacart: 'TARGETING_SHORTCUT_NEW_TO_INSTACART',
    NewToCategoryOrBrand: 'TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND',
    BuyMyCategoryNotMyBrand: 'TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND',
    BuyMyBrandNotMyProduct: 'TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT',
    InteractWithXNotBuyBrand: 'TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND',
    LapsedBuyer: 'TARGETING_SHORTCUT_LAPSED_BUYER',
    LoyalBuyer: 'TARGETING_SHORTCUT_LOYAL_BUYER',
    Unknown: 'TARGETING_SHORTCUT_UNKNOWN',
    None: 'TARGETING_SHORTCUT_NONE',
    Custom: 'TARGETING_SHORTCUT_CUSTOM',
    DraftAdgroup: 'TARGETING_SHORTCUT_DRAFT_ADGROUP',
    NonAudienceTargeting: 'TARGETING_SHORTCUT_NON_AUDIENCE_TARGETING',
  } as const
export type ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyTargetingShortcutEnum =
  typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyTargetingShortcutEnum[keyof typeof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyTargetingShortcutEnum]

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy {
  return ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keywords: !exists(json, 'keywords') ? undefined : json['keywords'],
    keywordMatchType: !exists(json, 'keyword_match_type') ? undefined : json['keyword_match_type'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargetingFromJSON(
          json['user_targeting']
        ),
    targetingRule: !exists(json, 'targeting_rule') ? undefined : json['targeting_rule'],
    targetingShortcut: !exists(json, 'targeting_shortcut') ? undefined : json['targeting_shortcut'],
  }
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keywords: value.keywords,
    keyword_match_type: value.keywordMatchType,
    user_targeting:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyUserTargetingToJSON(
        value.userTargeting
      ),
    targeting_rule: value.targetingRule,
    targeting_shortcut: value.targetingShortcut,
  }
}
