// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutAdGroupKeywordResponseDataAttributesBidRecs
 */
export interface PutAdGroupKeywordResponseDataAttributesBidRecs {
  /**
   * The suggested bid amount in dollars that Instacart has calculated. If we do not have enough information to calculate a suggested bid, this will return null.
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  suggestedBid?: number
  /**
   * The bid strength Instacart has calculated, either green for strong, yellow for medium, and red for weak. A bid is considered strong if it is competitive the majority of the time across all eligible placements, when considering the bid you entered and each product's conversion probability. You can adjust your bid to be more competitive at any time. If we do not have enough information to calculate a suggested bid, this will return 'unknown'.
   * @type {string}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  bidStrength?: PutAdGroupKeywordResponseDataAttributesBidRecsBidStrengthEnum
  /**
   * The minimum bid amount in dollars. If your ad group has a CPC set to beneath this amount, the bids will fail and the ad group's products won't be sponsored.
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  minBid?: number
  /**
   * Bid trace info
   * @type {Array<string>}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  traceInfo?: Array<string>
  /**
   * Uncompetitive Bid
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  uncompetitiveBid?: number
  /**
   * If the bid is confident
   * @type {boolean}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  isConfidentBid?: boolean
  /**
   * Bid Rec Type(ML/DS)
   * @type {string}
   * @memberof PutAdGroupKeywordResponseDataAttributesBidRecs
   */
  bidRecType?: string
}

/**
 * @export
 */
export const PutAdGroupKeywordResponseDataAttributesBidRecsBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type PutAdGroupKeywordResponseDataAttributesBidRecsBidStrengthEnum =
  typeof PutAdGroupKeywordResponseDataAttributesBidRecsBidStrengthEnum[keyof typeof PutAdGroupKeywordResponseDataAttributesBidRecsBidStrengthEnum]

/**
 * Check if a given object implements the PutAdGroupKeywordResponseDataAttributesBidRecs interface.
 */
export function instanceOfPutAdGroupKeywordResponseDataAttributesBidRecs(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PutAdGroupKeywordResponseDataAttributesBidRecsFromJSON(
  json: any
): PutAdGroupKeywordResponseDataAttributesBidRecs {
  return PutAdGroupKeywordResponseDataAttributesBidRecsFromJSONTyped(json, false)
}

export function PutAdGroupKeywordResponseDataAttributesBidRecsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAdGroupKeywordResponseDataAttributesBidRecs {
  if (json === undefined || json === null) {
    return json
  }
  return {
    suggestedBid: !exists(json, 'suggested_bid') ? undefined : json['suggested_bid'],
    bidStrength: !exists(json, 'bid_strength') ? undefined : json['bid_strength'],
    minBid: !exists(json, 'min_bid') ? undefined : json['min_bid'],
    traceInfo: !exists(json, 'trace_info') ? undefined : json['trace_info'],
    uncompetitiveBid: !exists(json, 'uncompetitive_bid') ? undefined : json['uncompetitive_bid'],
    isConfidentBid: !exists(json, 'is_confident_bid') ? undefined : json['is_confident_bid'],
    bidRecType: !exists(json, 'bid_rec_type') ? undefined : json['bid_rec_type'],
  }
}

export function PutAdGroupKeywordResponseDataAttributesBidRecsToJSON(
  value?: PutAdGroupKeywordResponseDataAttributesBidRecs | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    suggested_bid: value.suggestedBid,
    bid_strength: value.bidStrength,
    min_bid: value.minBid,
    trace_info: value.traceInfo,
    uncompetitive_bid: value.uncompetitiveBid,
    is_confident_bid: value.isConfidentBid,
    bid_rec_type: value.bidRecType,
  }
}
