// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesAnalyticsParamDataPoint } from './GetBrandPagesAnalyticsParamDataPoint'
import {
  GetBrandPagesAnalyticsParamDataPointFromJSON,
  GetBrandPagesAnalyticsParamDataPointFromJSONTyped,
  GetBrandPagesAnalyticsParamDataPointToJSON,
} from './GetBrandPagesAnalyticsParamDataPoint'
import type { GetBrandPagesAnalyticsResponseDataAttributesSeries } from './GetBrandPagesAnalyticsResponseDataAttributesSeries'
import {
  GetBrandPagesAnalyticsResponseDataAttributesSeriesFromJSON,
  GetBrandPagesAnalyticsResponseDataAttributesSeriesFromJSONTyped,
  GetBrandPagesAnalyticsResponseDataAttributesSeriesToJSON,
} from './GetBrandPagesAnalyticsResponseDataAttributesSeries'

/**
 *
 * @export
 * @interface GetBrandPagesAnalyticsResponseDataAttributes
 */
export interface GetBrandPagesAnalyticsResponseDataAttributes {
  /**
   * Chart metric
   * @type {string}
   * @memberof GetBrandPagesAnalyticsResponseDataAttributes
   */
  metric: string
  /**
   *
   * @type {Array<GetBrandPagesAnalyticsResponseDataAttributesSeries>}
   * @memberof GetBrandPagesAnalyticsResponseDataAttributes
   */
  series: Array<GetBrandPagesAnalyticsResponseDataAttributesSeries>
  /**
   * Chart series data
   * @type {Array<Array<GetBrandPagesAnalyticsParamDataPoint>>}
   * @memberof GetBrandPagesAnalyticsResponseDataAttributes
   */
  data: Array<Array<GetBrandPagesAnalyticsParamDataPoint>>
}

/**
 * Check if a given object implements the GetBrandPagesAnalyticsResponseDataAttributes interface.
 */
export function instanceOfGetBrandPagesAnalyticsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'metric' in value
  isInstance = isInstance && 'series' in value
  isInstance = isInstance && 'data' in value

  return isInstance
}

export function GetBrandPagesAnalyticsResponseDataAttributesFromJSON(
  json: any
): GetBrandPagesAnalyticsResponseDataAttributes {
  return GetBrandPagesAnalyticsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetBrandPagesAnalyticsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesAnalyticsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metric: json['metric'],
    series: (json['series'] as Array<any>).map(
      GetBrandPagesAnalyticsResponseDataAttributesSeriesFromJSON
    ),
    data: json['data'],
  }
}

export function GetBrandPagesAnalyticsResponseDataAttributesToJSON(
  value?: GetBrandPagesAnalyticsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metric: value.metric,
    series: (value.series as Array<any>).map(
      GetBrandPagesAnalyticsResponseDataAttributesSeriesToJSON
    ),
    data: value.data,
  }
}
