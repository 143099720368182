import { combineStyles, spacing } from '@instacart/ids-core'
import {
  Tooltip as BaseTooltip,
  TooltipInitialState,
  TooltipProps as BaseTooltipProps,
  useTooltipState as useBaseTooltipState,
} from 'reakit'
import { useTooltipStyles } from 'components/ids-ads/molecules/tooltips/utils/hooks'
import {
  TOOLTIP_TRANSITION_TIME_MS,
  TooltipStyles,
} from 'components/ids-ads/molecules/tooltips/utils/types'
import { TooltipContent } from './TooltipContent'

export { TooltipReference, TooltipInitialState } from 'reakit'

export const useTooltipState = ({
  animated = TOOLTIP_TRANSITION_TIME_MS,
  gutter = spacing.s8,
  placement = 'top',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  unstable_preventOverflow = true,
  ...rest
}: TooltipInitialState = {}) =>
  useBaseTooltipState({
    animated,
    gutter,
    placement,
    unstable_preventOverflow,
    ...rest,
  })

export type TooltipProps = BaseTooltipProps & TooltipStyles

export function Tooltip({ styles, children, ...props }: TooltipProps) {
  const baseStyles = useTooltipStyles()
  const { tooltip, content } = combineStyles(baseStyles, styles)

  return (
    <BaseTooltip css={tooltip} {...props}>
      <TooltipContent styles={content}>{children}</TooltipContent>
    </BaseTooltip>
  )
}
