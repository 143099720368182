import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { createContext, useContext } from 'react'
import { AccountFeatures } from 'common/accountFeatures'
import { FLIPPER_ENABLE_MANUAL_TARGETING } from 'common/flippers'
import useFlipper from 'common/useFlipper'
import { AuthCtx, isFlipperEnabled, isProviAccount, useAuthContext } from 'context'
import useCapabilities from 'hooks/useCapabilities'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { SegmentType, TargetingShortcutsDescriptionEnum } from './constants'
import { Brand, Location, Category } from './types'
import {
  CategoryTree,
  CategoryTreeNode,
  getCheckboxState,
  getNewSelectedCategoriesOnCheckboxClick,
} from './utils'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export type TargetingRuleContextType = {
  isReadOnly: boolean
  isReviewing: boolean
  isProductsTargetingAllowed: boolean
  categories: Category[]
  brands: Brand[]
  locations: Location[]
}
export const TargetingRuleContext = createContext<TargetingRuleContextType>(undefined!)

type TargetingSegmentContextType = {
  fieldsPrefix: string
  segmentType: SegmentType
}
export const TargetingSegmentContext = createContext<TargetingSegmentContextType>(undefined!)

type CategoriesViewContextType = {
  categoryTree: CategoryTree
  highlightedL2Node?: CategoryTreeNode
  setHighlightedL2Node: (node: CategoryTreeNode) => void
  searchText: string
}
export const CategoriesViewContext = createContext<CategoriesViewContextType>(undefined!)

/* eslint-enable @typescript-eslint/no-non-null-assertion */

export function useIsTargetingV2Enabled(creativeType?: CreativeTypeEnum) {
  return (
    useFlipper(FLIPPER_ENABLE_MANUAL_TARGETING) &&
    (creativeType === CreativeTypeEnum.SearchBannerV1 ||
      creativeType === CreativeTypeEnum.ShoppableVideoV1 ||
      creativeType === CreativeTypeEnum.ShoppableVideoV2 ||
      creativeType === CreativeTypeEnum.ShoppableVideoV3 ||
      creativeType === CreativeTypeEnum.PromotedAisleV1 ||
      creativeType === CreativeTypeEnum.PromotedAisleV3 ||
      creativeType === CreativeTypeEnum.PromotedAisleV4 ||
      creativeType === CreativeTypeEnum.ImageBannerV3 ||
      creativeType === CreativeTypeEnum.OccasionV1 ||
      creativeType === CreativeTypeEnum.SponsoredRecipeV1 ||
      creativeType === CreativeTypeEnum.SponsoredRecipeVideoV1)
  )
}

export function useAvailableTargetingShortcuts() {
  const isProvi = isProviAccount(useAuthContext())
  const showNewToInstacartUserTargeting = useCapabilities({
    featureCapabilities: AccountFeatures.NEW_TO_INSTACART_USER_TARGETING,
  })
  const showInteractionUserTargeting = useCapabilities({
    featureCapabilities: AccountFeatures.INTERACTION_USER_TARGETING,
  })

  // This is the complete list of shortcuts, in the expected UI ordering
  const availableShortcuts: Partial<
    Record<keyof typeof TargetingShortcutsDescriptionEnum, string>
  > = {
    TARGETING_SHORTCUT_NEW_TO_INSTACART: 'New to Instacart',
    TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND: 'New to category or brand',
    TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND: 'Buys X category, not my brand',
    TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND: 'Interacts but doesn’t buy',
    TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT: 'Buys my brands, excluding specific UPCs',
    TARGETING_SHORTCUT_LAPSED_BUYER: 'Lapsed buyer',
    TARGETING_SHORTCUT_LOYAL_BUYER: 'Loyal buyers',
  }

  if (!showNewToInstacartUserTargeting) {
    delete availableShortcuts.TARGETING_SHORTCUT_NEW_TO_INSTACART
  }

  if (!showInteractionUserTargeting) {
    delete availableShortcuts.TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND
  }

  if (isProvi) {
    delete availableShortcuts.TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT
  }

  return availableShortcuts
}

export const useCategoryTargetingCheckbox = (node: CategoryTreeNode, isReadOnly: boolean) => {
  const { fieldsPrefix } = useContext(TargetingSegmentContext)
  const categoriesFieldName = `${fieldsPrefix}categories`
  const { values, setFieldValue } = useFormikContext()
  const selectedCategories: string[] = get(values, categoriesFieldName, [])
  const checkboxState = getCheckboxState(node, selectedCategories)

  const handleCheckboxClick = () => {
    if (!isReadOnly) {
      const updatedCategories = getNewSelectedCategoriesOnCheckboxClick(
        checkboxState,
        node,
        selectedCategories
      )
      setFieldValue(categoriesFieldName, updatedCategories)
    }
  }

  return { checkboxState, handleCheckboxClick }
}

/*
  The following function isn't a hook and is used only in pages/DisplayProduct/DisplayAdGroup/hooks/adGroup.api.ts.
  It's put here just to keep the logic consistent with the hook above until we remove the flipper.
  We can't use the hook in DisplayContext, as there's bad coupling between API calls and hooks.
*/
export function isTargetingV2Enabled(authContext: AuthCtx, creativeType?: CreativeTypeEnum) {
  return (
    isFlipperEnabled(authContext, FLIPPER_ENABLE_MANUAL_TARGETING) &&
    creativeType === CreativeTypeEnum.SearchBannerV1
  )
}
