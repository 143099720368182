// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupAnalyticsResponseData } from './GetDisplayAdGroupAnalyticsResponseData'
import {
  GetDisplayAdGroupAnalyticsResponseDataFromJSON,
  GetDisplayAdGroupAnalyticsResponseDataFromJSONTyped,
  GetDisplayAdGroupAnalyticsResponseDataToJSON,
} from './GetDisplayAdGroupAnalyticsResponseData'
import type { GetDisplayAdGroupAnalyticsResponseMeta } from './GetDisplayAdGroupAnalyticsResponseMeta'
import {
  GetDisplayAdGroupAnalyticsResponseMetaFromJSON,
  GetDisplayAdGroupAnalyticsResponseMetaFromJSONTyped,
  GetDisplayAdGroupAnalyticsResponseMetaToJSON,
} from './GetDisplayAdGroupAnalyticsResponseMeta'

/**
 *
 * @export
 * @interface GetDisplayAdGroupAnalyticsResponse
 */
export interface GetDisplayAdGroupAnalyticsResponse {
  /**
   *
   * @type {GetDisplayAdGroupAnalyticsResponseData}
   * @memberof GetDisplayAdGroupAnalyticsResponse
   */
  data: GetDisplayAdGroupAnalyticsResponseData
  /**
   *
   * @type {GetDisplayAdGroupAnalyticsResponseMeta}
   * @memberof GetDisplayAdGroupAnalyticsResponse
   */
  meta: GetDisplayAdGroupAnalyticsResponseMeta
}

/**
 * Check if a given object implements the GetDisplayAdGroupAnalyticsResponse interface.
 */
export function instanceOfGetDisplayAdGroupAnalyticsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetDisplayAdGroupAnalyticsResponseFromJSON(
  json: any
): GetDisplayAdGroupAnalyticsResponse {
  return GetDisplayAdGroupAnalyticsResponseFromJSONTyped(json, false)
}

export function GetDisplayAdGroupAnalyticsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupAnalyticsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetDisplayAdGroupAnalyticsResponseDataFromJSON(json['data']),
    meta: GetDisplayAdGroupAnalyticsResponseMetaFromJSON(json['meta']),
  }
}

export function GetDisplayAdGroupAnalyticsResponseToJSON(
  value?: GetDisplayAdGroupAnalyticsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetDisplayAdGroupAnalyticsResponseDataToJSON(value.data),
    meta: GetDisplayAdGroupAnalyticsResponseMetaToJSON(value.meta),
  }
}
