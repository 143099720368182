// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PrototypeCampaignResponseDataAttributesAdGroup } from './PrototypeCampaignResponseDataAttributesAdGroup'
import {
  PrototypeCampaignResponseDataAttributesAdGroupFromJSON,
  PrototypeCampaignResponseDataAttributesAdGroupFromJSONTyped,
  PrototypeCampaignResponseDataAttributesAdGroupToJSON,
} from './PrototypeCampaignResponseDataAttributesAdGroup'

/**
 *
 * @export
 * @interface PrototypeCampaignResponseDataAttributes
 */
export interface PrototypeCampaignResponseDataAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  name: string
  /**
   * Send display for this field.
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  campaignType: PrototypeCampaignResponseDataAttributesCampaignTypeEnum
  /**
   * The status of the campaign
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  status: PrototypeCampaignResponseDataAttributesStatusEnum
  /**
   * The campaign's start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  startsAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  endsAt: string
  /**
   * ID of the account that is linked to this campaign
   * @type {number}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  accountId: number
  /**
   *
   * @type {PrototypeCampaignResponseDataAttributesAdGroup}
   * @memberof PrototypeCampaignResponseDataAttributes
   */
  adGroup?: PrototypeCampaignResponseDataAttributesAdGroup
}

/**
 * @export
 */
export const PrototypeCampaignResponseDataAttributesCampaignTypeEnum = {
  Display: 'display',
} as const
export type PrototypeCampaignResponseDataAttributesCampaignTypeEnum =
  typeof PrototypeCampaignResponseDataAttributesCampaignTypeEnum[keyof typeof PrototypeCampaignResponseDataAttributesCampaignTypeEnum]

/**
 * @export
 */
export const PrototypeCampaignResponseDataAttributesStatusEnum = {
  Active: 'active',
  Ended: 'ended',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Unknown: 'unknown',
} as const
export type PrototypeCampaignResponseDataAttributesStatusEnum =
  typeof PrototypeCampaignResponseDataAttributesStatusEnum[keyof typeof PrototypeCampaignResponseDataAttributesStatusEnum]

/**
 * Check if a given object implements the PrototypeCampaignResponseDataAttributes interface.
 */
export function instanceOfPrototypeCampaignResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'startsAt' in value
  isInstance = isInstance && 'endsAt' in value
  isInstance = isInstance && 'accountId' in value

  return isInstance
}

export function PrototypeCampaignResponseDataAttributesFromJSON(
  json: any
): PrototypeCampaignResponseDataAttributes {
  return PrototypeCampaignResponseDataAttributesFromJSONTyped(json, false)
}

export function PrototypeCampaignResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PrototypeCampaignResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    campaignType: json['campaign_type'],
    status: json['status'],
    startsAt: json['starts_at'],
    endsAt: json['ends_at'],
    accountId: json['account_id'],
    adGroup: !exists(json, 'ad_group')
      ? undefined
      : PrototypeCampaignResponseDataAttributesAdGroupFromJSON(json['ad_group']),
  }
}

export function PrototypeCampaignResponseDataAttributesToJSON(
  value?: PrototypeCampaignResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    campaign_type: value.campaignType,
    status: value.status,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
    account_id: value.accountId,
    ad_group: PrototypeCampaignResponseDataAttributesAdGroupToJSON(value.adGroup),
  }
}
