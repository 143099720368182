// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminBrandPagesResponseMeta } from './AdminBrandPagesResponseMeta'
import {
  AdminBrandPagesResponseMetaFromJSON,
  AdminBrandPagesResponseMetaFromJSONTyped,
  AdminBrandPagesResponseMetaToJSON,
} from './AdminBrandPagesResponseMeta'
import type { GetAdminBrandPagesParamData } from './GetAdminBrandPagesParamData'
import {
  GetAdminBrandPagesParamDataFromJSON,
  GetAdminBrandPagesParamDataFromJSONTyped,
  GetAdminBrandPagesParamDataToJSON,
} from './GetAdminBrandPagesParamData'

/**
 *
 * @export
 * @interface AdminBrandPagesResponse
 */
export interface AdminBrandPagesResponse {
  /**
   * Brand pages collection
   * @type {Array<GetAdminBrandPagesParamData>}
   * @memberof AdminBrandPagesResponse
   */
  data: Array<GetAdminBrandPagesParamData>
  /**
   *
   * @type {AdminBrandPagesResponseMeta}
   * @memberof AdminBrandPagesResponse
   */
  meta: AdminBrandPagesResponseMeta
}

/**
 * Check if a given object implements the AdminBrandPagesResponse interface.
 */
export function instanceOfAdminBrandPagesResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AdminBrandPagesResponseFromJSON(json: any): AdminBrandPagesResponse {
  return AdminBrandPagesResponseFromJSONTyped(json, false)
}

export function AdminBrandPagesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminBrandPagesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(GetAdminBrandPagesParamDataFromJSON),
    meta: AdminBrandPagesResponseMetaFromJSON(json['meta']),
  }
}

export function AdminBrandPagesResponseToJSON(value?: AdminBrandPagesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(GetAdminBrandPagesParamDataToJSON),
    meta: AdminBrandPagesResponseMetaToJSON(value.meta),
  }
}
