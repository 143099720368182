// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SmartAdGroupResponseDataAttributesCreativeActions } from './SmartAdGroupResponseDataAttributesCreativeActions'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActions'
import type { SmartAdGroupResponseDataAttributesCreativeAssets } from './SmartAdGroupResponseDataAttributesCreativeAssets'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssets'
import type { SmartAdGroupResponseDataAttributesCreativeProperties } from './SmartAdGroupResponseDataAttributesCreativeProperties'
import {
  SmartAdGroupResponseDataAttributesCreativePropertiesFromJSON,
  SmartAdGroupResponseDataAttributesCreativePropertiesFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativePropertiesToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeProperties'

/**
 *
 * @export
 * @interface SmartAdGroupResponseDataAttributesCreative
 */
export interface SmartAdGroupResponseDataAttributesCreative {
  /**
   * The id of the creative
   * @type {string}
   * @memberof SmartAdGroupResponseDataAttributesCreative
   */
  id: string
  /**
   * The creative definition
   * @type {string}
   * @memberof SmartAdGroupResponseDataAttributesCreative
   */
  type: SmartAdGroupResponseDataAttributesCreativeTypeEnum
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssets}
   * @memberof SmartAdGroupResponseDataAttributesCreative
   */
  assets: SmartAdGroupResponseDataAttributesCreativeAssets
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeProperties}
   * @memberof SmartAdGroupResponseDataAttributesCreative
   */
  properties?: SmartAdGroupResponseDataAttributesCreativeProperties
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActions}
   * @memberof SmartAdGroupResponseDataAttributesCreative
   */
  actions?: SmartAdGroupResponseDataAttributesCreativeActions
}

/**
 * @export
 */
export const SmartAdGroupResponseDataAttributesCreativeTypeEnum = {
  SmartV1: 'smart.v1',
} as const
export type SmartAdGroupResponseDataAttributesCreativeTypeEnum =
  typeof SmartAdGroupResponseDataAttributesCreativeTypeEnum[keyof typeof SmartAdGroupResponseDataAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the SmartAdGroupResponseDataAttributesCreative interface.
 */
export function instanceOfSmartAdGroupResponseDataAttributesCreative(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function SmartAdGroupResponseDataAttributesCreativeFromJSON(
  json: any
): SmartAdGroupResponseDataAttributesCreative {
  return SmartAdGroupResponseDataAttributesCreativeFromJSONTyped(json, false)
}

export function SmartAdGroupResponseDataAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartAdGroupResponseDataAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    assets: SmartAdGroupResponseDataAttributesCreativeAssetsFromJSON(json['assets']),
    properties: !exists(json, 'properties')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativePropertiesFromJSON(json['properties']),
    actions: !exists(json, 'actions')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsFromJSON(json['actions']),
  }
}

export function SmartAdGroupResponseDataAttributesCreativeToJSON(
  value?: SmartAdGroupResponseDataAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    assets: SmartAdGroupResponseDataAttributesCreativeAssetsToJSON(value.assets),
    properties: SmartAdGroupResponseDataAttributesCreativePropertiesToJSON(value.properties),
    actions: SmartAdGroupResponseDataAttributesCreativeActionsToJSON(value.actions),
  }
}
