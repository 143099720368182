// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltText } from './PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltText'
import {
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSON,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltTextToJSON,
} from './PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltText'
import type { PutReservationAdGroupsIdParamProductAssetFieldDecisionsImage } from './PutReservationAdGroupsIdParamProductAssetFieldDecisionsImage'
import {
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsImageFromJSON,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsImageFromJSONTyped,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsImageToJSON,
} from './PutReservationAdGroupsIdParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamProductAssetFieldDecisions
 */
export interface PutReservationAdGroupsIdParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamProductAssetFieldDecisions
   */
  discriminator: PutReservationAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltText}
   * @memberof PutReservationAdGroupsIdParamProductAssetFieldDecisions
   */
  altText?: PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {PutReservationAdGroupsIdParamProductAssetFieldDecisionsImage}
   * @memberof PutReservationAdGroupsIdParamProductAssetFieldDecisions
   */
  image?: PutReservationAdGroupsIdParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const PutReservationAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutReservationAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof PutReservationAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof PutReservationAdGroupsIdParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamProductAssetFieldDecisions interface.
 */
export function instanceOfPutReservationAdGroupsIdParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamProductAssetFieldDecisions {
  return PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutReservationAdGroupsIdParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function PutReservationAdGroupsIdParamProductAssetFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PutReservationAdGroupsIdParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutReservationAdGroupsIdParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
