import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { BannerNotification, BannerType } from 'components/molecules/BannerNotifications'
import { PrimaryAnchorButton } from '../../ids-ads'

const buttonStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: '0.5rem',
}

const styles = {
  wrapper: css({
    marginTop: '1rem',
  }),
  errorInner: css({
    display: 'flex',
  }),
  errorIcon: css({
    flex: '0 0 auto',
    margin: '0 0.5rem',
  }),
  button: buttonStyles,
}

export default function AccountLockedError() {
  return (
    <div css={styles.wrapper} data-testid="account-locked-error">
      <BannerNotification
        type={BannerType.WARNING}
        message={{
          message: <FormattedMessage id="pages.login.lockedAccountError" />,
        }}
      />
      <PrimaryAnchorButton style={styles.button} href="/unlock">
        <FormattedMessage id="pages.updatePassword.reset.title" />
      </PrimaryAnchorButton>
    </div>
  )
}
