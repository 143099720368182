// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBlockSectionBannerAssets } from './PostBrandPagesParamBlockSectionBannerAssets'
import {
  PostBrandPagesParamBlockSectionBannerAssetsFromJSON,
  PostBrandPagesParamBlockSectionBannerAssetsFromJSONTyped,
  PostBrandPagesParamBlockSectionBannerAssetsToJSON,
} from './PostBrandPagesParamBlockSectionBannerAssets'

/**
 *
 * @export
 * @interface PostBrandPagesParamBlockSectionBanner
 */
export interface PostBrandPagesParamBlockSectionBanner {
  /**
   * id of the block, required for updates
   * @type {string}
   * @memberof PostBrandPagesParamBlockSectionBanner
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesParamBlockSectionBanner
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesParamBlockSectionBanner
   */
  type: PostBrandPagesParamBlockSectionBannerTypeEnum
  /**
   *
   * @type {PostBrandPagesParamBlockSectionBannerAssets}
   * @memberof PostBrandPagesParamBlockSectionBanner
   */
  assets: PostBrandPagesParamBlockSectionBannerAssets
}

/**
 * @export
 */
export const PostBrandPagesParamBlockSectionBannerTypeEnum = {
  SectionBannerEvergreenV1: 'section_banner.evergreen.v1',
} as const
export type PostBrandPagesParamBlockSectionBannerTypeEnum =
  typeof PostBrandPagesParamBlockSectionBannerTypeEnum[keyof typeof PostBrandPagesParamBlockSectionBannerTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesParamBlockSectionBanner interface.
 */
export function instanceOfPostBrandPagesParamBlockSectionBanner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PostBrandPagesParamBlockSectionBannerFromJSON(
  json: any
): PostBrandPagesParamBlockSectionBanner {
  return PostBrandPagesParamBlockSectionBannerFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockSectionBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockSectionBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PostBrandPagesParamBlockSectionBannerAssetsFromJSON(json['assets']),
  }
}

export function PostBrandPagesParamBlockSectionBannerToJSON(
  value?: PostBrandPagesParamBlockSectionBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PostBrandPagesParamBlockSectionBannerAssetsToJSON(value.assets),
  }
}
