// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetDisplayCampaignAnalyticsResponseDataAttributesSeries
 */
export interface GetDisplayCampaignAnalyticsResponseDataAttributesSeries {
  /**
   * Chart object type
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  type: GetDisplayCampaignAnalyticsResponseDataAttributesSeriesTypeEnum
  /**
   * Campaign ID
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  id: string
  /**
   * Chart x-axis label
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  x: string
  /**
   * Chart y-axis label
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  y: string
  /**
   * Chart start date
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  startDate: string
  /**
   * Chart end date
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributesSeries
   */
  endDate: string
}

/**
 * @export
 */
export const GetDisplayCampaignAnalyticsResponseDataAttributesSeriesTypeEnum = {
  DisplayCampaign: 'display_campaign',
} as const
export type GetDisplayCampaignAnalyticsResponseDataAttributesSeriesTypeEnum =
  typeof GetDisplayCampaignAnalyticsResponseDataAttributesSeriesTypeEnum[keyof typeof GetDisplayCampaignAnalyticsResponseDataAttributesSeriesTypeEnum]

/**
 * Check if a given object implements the GetDisplayCampaignAnalyticsResponseDataAttributesSeries interface.
 */
export function instanceOfGetDisplayCampaignAnalyticsResponseDataAttributesSeries(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'x' in value
  isInstance = isInstance && 'y' in value
  isInstance = isInstance && 'startDate' in value
  isInstance = isInstance && 'endDate' in value

  return isInstance
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSON(
  json: any
): GetDisplayCampaignAnalyticsResponseDataAttributesSeries {
  return GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSONTyped(json, false)
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignAnalyticsResponseDataAttributesSeries {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    id: json['id'],
    x: json['x'],
    y: json['y'],
    startDate: json['start_date'],
    endDate: json['end_date'],
  }
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesSeriesToJSON(
  value?: GetDisplayCampaignAnalyticsResponseDataAttributesSeries | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    id: value.id,
    x: value.x,
    y: value.y,
    start_date: value.startDate,
    end_date: value.endDate,
  }
}
