// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AuthCreateResponseData } from './AuthCreateResponseData'
import {
  AuthCreateResponseDataFromJSON,
  AuthCreateResponseDataFromJSONTyped,
  AuthCreateResponseDataToJSON,
} from './AuthCreateResponseData'
import type { AuthCreateResponseMeta } from './AuthCreateResponseMeta'
import {
  AuthCreateResponseMetaFromJSON,
  AuthCreateResponseMetaFromJSONTyped,
  AuthCreateResponseMetaToJSON,
} from './AuthCreateResponseMeta'

/**
 *
 * @export
 * @interface AuthCreateResponse
 */
export interface AuthCreateResponse {
  /**
   *
   * @type {AuthCreateResponseData}
   * @memberof AuthCreateResponse
   */
  data: AuthCreateResponseData
  /**
   *
   * @type {AuthCreateResponseMeta}
   * @memberof AuthCreateResponse
   */
  meta: AuthCreateResponseMeta
}

/**
 * Check if a given object implements the AuthCreateResponse interface.
 */
export function instanceOfAuthCreateResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AuthCreateResponseFromJSON(json: any): AuthCreateResponse {
  return AuthCreateResponseFromJSONTyped(json, false)
}

export function AuthCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthCreateResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: AuthCreateResponseDataFromJSON(json['data']),
    meta: AuthCreateResponseMetaFromJSON(json['meta']),
  }
}

export function AuthCreateResponseToJSON(value?: AuthCreateResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: AuthCreateResponseDataToJSON(value.data),
    meta: AuthCreateResponseMetaToJSON(value.meta),
  }
}
