import { MessageIdType } from 'locales/types'

export const SECONDS_PER_DAY = 86400

// eslint-disable-next-line no-restricted-syntax
export enum Condition {
  POSITIVE = 'POSITIVE', // e.g. customer have
  NEGATIVE = 'NEGATIVE', // e.g. customer have not
}

// eslint-disable-next-line no-restricted-syntax
export enum SegmentType {
  PURCHASE = 'PURCHASE',
  INTEREST = 'INTEREST',
}

// eslint-disable-next-line no-restricted-syntax
export enum Subject {
  CATEGORIES = 'CATEGORIES',
  BRANDS = 'BRANDS',
  UPCS = 'upcs',
}

// eslint-disable-next-line no-restricted-syntax
export enum Operator {
  OR = 'OR',
  AND = 'AND',
}

export const DEFAULT_TARGETING_SEGMENT = {
  key: 1,
  condition: Condition.POSITIVE,
  subject: Subject.CATEGORIES,
  timeline: '30',
  categories: [],
  brands: [],
  upcs: [],
}

export const DEFAULT_TARGETING_SEGMENT_NEGATIVE = {
  key: 1,
  condition: Condition.NEGATIVE,
  subject: Subject.CATEGORIES,
  timeline: '30',
  categories: [],
  brands: [],
  upcs: [],
}

export const DEFAULT_TARGETING_SEGMENT_LAPSED_BUYER = {
  key: 1,
  condition: Condition.POSITIVE,
  subject: Subject.BRANDS,
  timeline: '30',
  categories: [],
  brands: [],
  upcs: [],
}

// eslint-disable-next-line no-restricted-syntax
export enum TargetingShortcutsEnum {
  TARGETING_SHORTCUT_NEW_TO_INSTACART = 'New to Instacart',
  TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND = 'New to category or brand',
  TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND = 'Buys X category, not my brand',
  TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND = 'Interacts but doesn’t buy',
  TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT = 'Buys my brands, excluding specific UPCs',
  TARGETING_SHORTCUT_LAPSED_BUYER = 'Lapsed buyer',
  TARGETING_SHORTCUT_LOYAL_BUYER = 'Loyal buyers',
}

// eslint-disable-next-line no-restricted-syntax
export enum TargetingShortcutsDescriptionEnum {
  TARGETING_SHORTCUT_LOYAL_BUYER = 'Deepen brand loyalty by encouraging repeat purchases from recent customers.',
  TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND = 'Reach customers who have never purchased from your brand or chosen categories.',
  TARGETING_SHORTCUT_LAPSED_BUYER = "Re-engage customers who haven't purchased your brand or category in X days.",
  TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND = 'Get your brand in front of customers buying similar items from competitors.',
  TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND = 'Target customers who interact via search, clicking an item details page, or adding to cart.',
  TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT = 'Introduce a new product or upsell other items an existing customer might like.',
  TARGETING_SHORTCUT_NEW_TO_INSTACART = 'Target any customer adding to their first few carts.',
}

export const SWITCH_TARGETING_TYPE_MODAL_TEST_ID = 'switch-targeting-type-modal'

export const conditionOptions = (segmentType: SegmentType) => [
  {
    labelId:
      `pages.displayProduct.common.targetingRule.segment.condition.${segmentType.toLowerCase()}.positive` as MessageIdType,
    value: Condition.POSITIVE,
  },
  {
    labelId:
      `pages.displayProduct.common.targetingRule.segment.condition.${segmentType.toLowerCase()}.negative` as MessageIdType,
    value: Condition.NEGATIVE,
  },
]

type ShortcutSubjectOptionsParams = {
  isCategoriesAllowed: boolean
  isBrandsTargetingAllowed: boolean
  isProductsTargetingAllowed: boolean
}

export const shortcutSubjectOptions = ({
  isCategoriesAllowed,
  isBrandsTargetingAllowed,
  isProductsTargetingAllowed,
}: ShortcutSubjectOptionsParams) =>
  [
    isCategoriesAllowed && {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.categories',
      value: Subject.CATEGORIES,
    },
    isBrandsTargetingAllowed && {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.brands',
      value: Subject.BRANDS,
    },
    isProductsTargetingAllowed && {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.upcs',
      value: Subject.UPCS,
    },
  ].filter(option => !!option) as { labelId: MessageIdType; value: Subject }[]

export const subjectOptions = (
  // e.g. some advertisers don't have brands.
  isBrandsTargetingAllowed: boolean,
  // This is temporarily behind a flipper.
  isProductsTargetingAllowed: boolean
) =>
  [
    {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.categories',
      value: Subject.CATEGORIES,
    },
    isBrandsTargetingAllowed && {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.brands',
      value: Subject.BRANDS,
    },
    isProductsTargetingAllowed && {
      labelId: 'pages.displayProduct.common.targetingRule.segment.subject.upcs',
      value: Subject.UPCS,
    },
  ].filter(option => !!option) as { labelId: MessageIdType; value: Subject }[]

export const timelineOptions = [30, 60, 90, 120, 150, 180].map(days => ({
  labelId:
    `pages.displayProduct.common.targetingRule.segment.timeline.${days}Days` as MessageIdType,
  value: String(days),
}))

export const operatorOptions = [
  {
    labelId: 'pages.displayProduct.common.targetingRule.operator.and' as MessageIdType,
    value: Operator.AND,
  },
  {
    labelId: 'pages.displayProduct.common.targetingRule.operator.or' as MessageIdType,
    value: Operator.OR,
  },
]

export const MAXIMUM_NUMBER_OF_SEGMENTS = 5
export const TARGETING_LOCATIONS_FIELD_NAME = 'locations'
export const TARGETING_OPERATOR_FIELD_NAME = 'operator'

export const getTargetingSegmentsFieldName = (segmentType: SegmentType) =>
  `${segmentType.toLowerCase()}Segments`

export const getTargetingSegmentCategoriesFieldName = (
  segmentType: SegmentType,
  index: number
): string => `${getTargetingSegmentsFieldName(segmentType)}[${index}].categories`

export const getTargetingSegmentBrandsFieldName = (
  segmentType: SegmentType,
  index: number
): string => `${getTargetingSegmentsFieldName(segmentType)}[${index}].brands`

export const getTargetingSegmentUPCsFieldName = (segmentType: SegmentType, index: number): string =>
  `${getTargetingSegmentsFieldName(segmentType)}[${index}].upcs`
