import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { Condition, Operator, Subject } from './constants'

export interface Category {
  categoryName: string
  subcategories?: Category[]
}

export interface Brand {
  brandName: string
  displayName: string
  brandId: string
  brandProductCount: string
}

export type Product = GetProductsResponseData

export interface Location {
  state_code: string
}

export type TargetingSegmentType = {
  key: number
  condition: Condition
  subject: Subject
  timeline: string
  categories: string[]
  brands: string[]
  upcs: string[]
}

export type TargetingFormData = {
  purchaseSegments: TargetingSegmentType[]
  interestSegments: TargetingSegmentType[]
  areTargetingRulesBlank: () => boolean
  locations: string[]
  operator: Operator
  includeNewCustomers: boolean
  isLegacyTargetingRule: boolean
}

export type TargetingShortcutFormData = {
  purchaseSegments: TargetingSegmentType[]
  interestSegments: TargetingSegmentType[]
  locations: string[]
  operator: Operator
  includeNewCustomers: boolean
  isLegacyTargetingRule: boolean
  targetingStrategy: object
}

export interface TargetingElement {
  category?: {
    categories: string[]
    time_window: string
  }
  brand_id?: {
    brand_ids: number[]
    time_window: string
  }
  upc?: {
    upcs: string[]
    time_window: string
  }
  category_interest?: {
    categories: string[]
    time_window: string
  }
  brand_id_interest?: {
    brand_ids: number[]
    time_window: string
  }
  upc_interest?: {
    upcs: string[]
    time_window: string
  }
  // This is either empty object or undefined
  new_to_instacart?: Record<string, never>
  not?: boolean
  geo?: {
    geo: {
      state_code: string
      country_code: string
    }[]
  }
}

export interface TargetingRuleType {
  conjunctions: {
    elements?: TargetingElement[]
  }[]
}
export interface UserTargetingRuleElement {
  categories?: string[]
  upcs?: string[]
  brandIds?: string[]
  lookbackWindow?: string
  clause?: string
}

export interface UserTargetingRuleType {
  purchase: UserTargetingRuleElement[]
  interest: UserTargetingRuleElement[]
  newToInstacart: boolean
  targetingEveryone?: boolean
}
