import { elevation, layers, spacing, useTheme } from '@instacart/ids-core'
import { StylesConfig } from 'react-select'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import toPx from 'common/toPx'
import { SYSTEM_GRAYSCALE_25 } from 'components/ids-ads/atoms'
import { MIN_WIDTH } from '../common'
import { CommonSelectProps, DefaultOptionType } from './types'

export function useStyles<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  position = 'left',
  variant = 'secondary',
  maxWidth = '100%',
  compact,
  block,
}: CommonSelectProps): StylesConfig<OptionType, IsMulti, GroupType> {
  const theme = useTheme()

  const font = compact ? theme.typography.bodySmall1 : theme.typography.bodyMedium1

  return {
    container: base => ({
      ...base,
      display: block ? 'block' : 'inline-block',
      '&, &:focus-visible': {
        outline: 'none',
      },
      maxWidth,
    }),
    control: (base, { isFocused, isDisabled }) => ({
      ...base,
      display: block ? 'flex' : 'inline-flex',
      minWidth: 100,
      maxWidth: '100%',
      background: (() => {
        if (isDisabled) return theme.colors.systemGrayscale10
        if (variant === 'secondary') return theme.colors.systemGrayscale20
        return theme.colors.systemGrayscale00
      })(),
      borderRadius: compact ? 6 : variant === 'legacy' ? theme.radius.r4 : theme.radius.r8,
      boxShadow: 'none',
      outline: 'none',
      minHeight: 'auto',
      height: variant === 'legacy' ? 56 : undefined,
      ...(isFocused
        ? {
            '&::after': {
              border: `2px solid ${theme.colors.systemGrayscale70}`,
              content: '""',
              display: 'block',
              position: 'absolute',
              top: -5,
              bottom: -5,
              left: -5,
              right: -5,
              borderRadius: compact ? 10 : theme.radius.r12,
            },
          }
        : {}),
      '&, &:hover': {
        border: `1px solid ${
          variant === 'secondary' ? 'transparent' : theme.colors.systemGrayscale30
        }`,
      },
      '&:hover': {
        background: variant === 'secondary' ? SYSTEM_GRAYSCALE_25 : theme.colors.systemGrayscale10,
      },
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 0,
      lineHeight: 0,
      marginRight: compact ? spacing.s8 : spacing.s12,
    }),
    clearIndicator: base => ({
      ...base,
      lineHeight: 0,
      order: -1,
      padding: spacing.s4,
      marginLeft: spacing.s4,
      cursor: 'pointer',
    }),
    valueContainer: base => ({
      ...base,
      ...font,
      padding: toPx`${(compact ? spacing.s4 : spacing.s8) - 1} ${
        compact ? spacing.s12 : spacing.s16
      } ${(compact ? spacing.s4 : spacing.s8) + 1}`,
      flexWrap: 'nowrap',
    }),
    placeholder: (base, { isDisabled }) => ({
      ...base,
      ...font,
      position: 'static',
      transform: 'none',
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale50,
      margin: 0,
    }),
    singleValue: (base, { isDisabled }) => ({
      ...base,
      ...font,
      position: 'static',
      transform: 'none',
      maxWidth: 'max-content',
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
      margin: 0,
    }),
    multiValue: (base, { isDisabled }) => ({
      ...base,
      ...font,
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
      background: 'none',
      margin: 0,
      padding: 0,
    }),
    multiValueLabel: () => ({
      '*:not(:first-of-type) > &::before': {
        content: '", "',
        whiteSpace: 'pre',
      },
    }),
    input: (base, inputProps) => ({
      ...base,
      // The defined types for InputProps in @types/react-select incorrectly exclude the value property,
      // which we need here to detect if the user has started typing in the input
      position: (inputProps as { value?: string }).value ? 'static' : 'absolute',
      left: compact ? spacing.s12 : spacing.s16,
      margin: 0,
      padding: 0,
      // Safari decides to make this container a few pixels taller without this
      height: font.lineHeight,
      '&, & input': {
        ...font,
        color: theme.colors.systemGrayscale70,
      },
    }),
    menu: base => ({
      ...base,
      ...elevation.low.shadow,
      margin: toPx`${spacing.s8} 0`,
      borderRadius: theme.radius.r12,
      width: block ? '100%' : 'auto',
      minWidth: MIN_WIDTH,
      right: position === 'right' ? 0 : undefined,
      zIndex: layers.l2,
    }),
    menuList: base => ({
      ...base,
      padding: compact ? spacing.s4 : spacing.s8,
    }),
    noOptionsMessage: base => ({
      ...base,
      ...theme.typography.bodyMedium1,
      color: theme.colors.systemGrayscale50,
      textAlign: 'left',
    }),
    loadingMessage: base => ({
      ...base,
      ...theme.typography.bodyMedium1,
      color: theme.colors.systemGrayscale50,
      textAlign: 'left',
    }),
    option: (base, { isFocused, isMulti, isSelected, isDisabled }) => ({
      ...base,
      ...(compact ? theme.typography.bodySmall1 : theme.typography.bodyMedium1),
      color: isDisabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
      cursor: isDisabled ? 'initial' : 'pointer',
      padding:
        isMulti || isSelected || compact ? toPx`${spacing.s8 - 1} ${spacing.s8}` : spacing.s8,
      borderRadius: theme.radius.r8,
      textAlign: 'left',
      background:
        isFocused && !isDisabled ? theme.colors.systemGrayscale10 : theme.colors.systemGrayscale00,
      '&:active': {
        background: theme.colors.systemGrayscale20,
      },
      '&:hover': {
        background: isDisabled ? theme.colors.systemGrayscale00 : theme.colors.systemGrayscale10,
      },
      display: 'flex',
      alignItems: 'center',
      gap: spacing.s8,
      justifyContent: isMulti ? 'flex-start' : 'space-between',
      svg: {
        width: compact ? 18 : 24,
        height: compact ? 18 : 24,
      },
    }),
    groupHeading: base => ({
      ...base,
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemGrayscale70,
      margin: 0,
      padding: spacing.s8,
      textTransform: 'initial',
    }),
    group: base => ({
      ...base,
      padding: 0,
    }),
  }
}
