import Analytics from 'common/analytics/Analytics'
import { DateLabel, DATE_LABEL_OPTIONS } from 'common/types'

type SelectionType =
  | 'lifetime'
  | 'since_yesterday'
  | 'last_7_days'
  | 'last_30_days'
  | 'last_3_months'
  | 'last_year'
  | 'last_2_years'
  | 'custom'

const isDateLabel = (dateLabel: DateLabel | string): dateLabel is DateLabel =>
  (DATE_LABEL_OPTIONS as string[]).includes(dateLabel)
export const getSelectionType = (dateLabel: DateLabel | string): SelectionType => {
  if (!isDateLabel(dateLabel)) {
    return 'custom'
  }

  /*
   * The intention of this switch statement is explicitly maintain the contractual integrity
   * between DateLabel and SelectionType.
   * Adding an additional DateLabel without handling the case as a SelectionType will throw
   * a TS error.
   */
  switch (dateLabel) {
    case 'Lifetime':
      return 'lifetime'
    case 'Since yesterday':
      return 'since_yesterday'
    case 'Last 7 days':
      return 'last_7_days'
    case 'Last 30 days':
      return 'last_30_days'
    case 'Last 3 months':
      return 'last_3_months'
    case 'Last 12 months':
      return 'last_year'
    case 'Last 24 months':
      return 'last_2_years'
    case 'Custom':
      return 'custom'
  }
}

export interface ExportInformation {
  eventName: string
  timeframe: string
  reportType: string
  reportGranularity: string
  uuid: string
}

export const trackExportEvent = (exportInformation: ExportInformation) => {
  const selectionType = getSelectionType(exportInformation.timeframe)
  Analytics.track(exportInformation.eventName, {
    selectionType,
    reportType: exportInformation.reportType,
    reportGranularity: exportInformation.reportGranularity,
    reportUuid: exportInformation.uuid,
  })
}
