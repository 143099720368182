// TODO: Contribute this back to @instacart/ids-tooling
// https://github.com/instacart/instacart-design-system-web/blob/master/packages/tooling/src/molecules/Input/Label/Label.stories.tsx
import { Variant } from '@instacart/ids-core'
import { LabelBase } from '../../atoms/inputs/LabelBase'

export interface LabelProps extends Variant<typeof LabelBase> {}

export const Label = (props: LabelProps) => {
  return <LabelBase {...props} />
}

Label.displayName = 'Label'
