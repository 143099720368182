/* eslint-disable max-lines */
import { canUseDOM } from 'exenv'
import { isEmpty, snakeCase, startCase, compact, flatten } from 'lodash'
import moment from 'moment'
import Qs from 'qs'
import { IntlShape } from 'react-intl'
import { safeGetLocalStorage } from 'common/localStorage'
import { unmask } from 'components/Forms/NumericField'
import { ColumnDefinition, RowBase, TitleTooltipProps } from 'components/organisms/TableV3/types'
import {
  GenericExtendedMessageDescriptor,
  GenericMessageDescriptor,
  MessageIdType,
} from 'locales/types'
import { breakFormattingHelper, externalLinkFormatter } from './intlUtils'
import { MRC_LINK } from './mrc/constants'

declare global {
  interface Window {
    STORYBOOK_ENV: any
    Cypress: any
    __ADS: any
    delighted: any
  }
}

export interface ResponseMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  errors?: {
    [param: string]: string[]
  }
  error_code?: number
}
export interface ResponseErrorMeta {
  errors: {
    [param: string]: string[]
  }
  error_code?: number
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export class ClientError extends Error {
  response?: {
    data?: {
      meta: ResponseMeta | ResponseErrorMeta
    }
  }

  constructor(errors: { [param: string]: string[] }, errorCode?: number) {
    super(`Error: ${JSON.stringify(errors)}`)
    this.response = {
      data: {
        meta: {
          errors,
          error_code: errorCode,
        },
      },
    }
  }
}

export interface ValidationErrorDetail {
  machine_name: string
  description: string
}

export interface FieldError {
  [param: string]: string
}

interface ServerError {
  [param: string]: string
}

export const FIELD_ERROR_ALREADY_EXISTS = 'already_exists'

/*
  Allows you to curry a base function

  e.g.:

  function x(a, b, c) { return a + b + c }
  // x(1, 2, 3) === 6
  const y = curry(x, 2)
  // y(3, 4) === 9
  const z = curry(x, 3, 4, 5)
  // z() === 12
*/
export function curry(fn: Function, ...curryArgs: any[]) {
  return function (this: any, ...args: any[]) {
    // keeping the 'this' of this function, which will be useful for prototype methods
    return fn.call(this, ...curryArgs, ...args)
  }
}

/*
  Find the first element in an array with any given property, or null if not found.

  Example:
  const options = [ {id: '1', value: 'value1'}, {id: '2', value: 'value2'} ]
  findBy('value', 'value1', options) // returns {id: '1', value: 'value1'}
 */
export function findBy<T extends { [key: string]: string }>(
  field: string,
  value: string | null | undefined,
  array: T[]
): T | null {
  if (!value) {
    return null
  }
  return array.find(a => value === a[field]) || null
}

/*
  Find the first element in an array with the given `id` property, or null if not found.

  Example:
  const options = [ {id: '1', value: 'value1'}, {id: '2', value: 'value2'} ]
  findById('1', options) // returns {id: '1', value: 'value1'}
 */
export function findById<T extends { id: string }>(
  id: string | null | undefined,
  array: T[]
): T | null {
  return findBy('id', id, array)
}

export function getBidStatus(
  enteredBid: string,
  suggestedBid: number,
  uncompetitiveBid: number,
  minBid: number
) {
  const unmaskedBid = unmask(enteredBid)

  if (unmaskedBid <= 0) return false

  if (unmaskedBid < minBid) return 'red'

  if (unmaskedBid < uncompetitiveBid) return 'red'

  if (unmaskedBid < suggestedBid) return 'yellow'

  if (unmaskedBid >= suggestedBid) return 'green'

  return false
}

export function formatDollar(num: number, precision = 2) {
  const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })
  const dollarFormatted = dollarFormatter.format(num)
  return dollarFormatted
}

export function forceRefresh(path = '/home') {
  // we normally would wrap the component with react-router-dom's withRouter,
  // and then call props.history.push(<path>)
  // but this may not refresh page content
  // so, to ensure a full refresh on logout, we will force a full redirect
  window.location.assign(path)
}

export function getEnv(env: string) {
  // eslint-disable-next-line no-underscore-dangle
  return canUseDOM ? window.__ADS.env[env] : process.env[env]
}

export function getEnvOrLocalStorageBool(varName: string) {
  return getEnv(varName) === 'true' || safeGetLocalStorage(varName) === 'true'
}

export function isDev() {
  return getEnv('NODE_ENV') === 'development'
}

export function isStaging() {
  return getEnv('NODE_ENV') === 'staging'
}

export function isProd() {
  return getEnv('NODE_ENV') === 'production'
}

export function isCypressEnvironment() {
  return typeof window !== 'undefined' && window.Cypress
}

export function isWebpackDevServer() {
  return getEnv('WEBPACK_DEV_SERVER') === 'true'
}

export function isJestEnvironment() {
  return typeof process !== 'undefined' && process.env.JEST_WORKER_ID !== undefined
}

export function showReCaptcha() {
  return (
    isProd() ||
    (isStaging() && !isCypressEnvironment()) ||
    localStorage.getItem('ADS_FORCE_RECAPTCHA') === 'true'
  )
}

export function messageIdExists(id: string, intl: IntlShape) {
  return !!intl.messages[id]
}

export function genericMessageDescriptorExists(
  descriptor: GenericMessageDescriptor | GenericExtendedMessageDescriptor,
  intl: IntlShape
) {
  if (typeof descriptor === 'string') {
    return messageIdExists(descriptor, intl)
  }
  if ('id' in descriptor) {
    const { id } = descriptor
    return messageIdExists(id, intl)
  }
  return !!descriptor.message
}

const genericErrorMessage = 'Unknown error occurred. Please refresh the page and try again.'
export function getApiErrorMessagesHash(err: ClientError, defaultErrMsg = genericErrorMessage) {
  return err?.response?.data?.meta?.errors || { error: [defaultErrMsg] }
}

// IE11 has no support for Object.values() . Use this instead.
export const objValues = <ValueType>(obj: { [key: string]: ValueType }) =>
  obj ? Object.keys(obj).map(key => obj[key]) : []

export function getApiErrorMessagesWithType(err: ClientError, defaultErrMsg = genericErrorMessage) {
  return getApiErrorMessagesHash(err, defaultErrMsg)
}

export function getApiErrorMessages(err: ClientError, defaultErrMsg = genericErrorMessage) {
  return objValues(getApiErrorMessagesWithType(err, defaultErrMsg))
}

export function getApiErrorMessagesForParams(
  err: ClientError,
  params: string[] = [],
  separator = '. ',
  errorMapping: Record<string, string> = {} // A mapping between server error keys and client error keys
) {
  const errorHash = getApiErrorMessagesHash(err)
  const paramErrors: FieldError = {}
  const otherErrors: string[] = []

  Object.keys(errorHash).forEach((key: string) => {
    const camelCaseKey = snakeToCamelCase(key)
    const paramErr = errorHash[key]
    const errMsg = isArrayOrObject(paramErr)
      ? getObjectValues(paramErr).join(separator)
      : String(paramErr)

    if (errorMapping[key]) {
      paramErrors[errorMapping[key]] = errMsg
    } else if (params.includes(camelCaseKey)) {
      paramErrors[camelCaseKey] = errMsg
    } else {
      otherErrors.push(errMsg)
    }
  })
  return { paramErrors, otherErrors: otherErrors.join(separator) }
}

export function getNexusApiErrorMessagesForParams(
  intl: IntlShape,
  err?: ClientError,
  // errors from err that corresponds to any params
  // would be filtered out and returned as paramErrors
  params: string[] = [],
  separator = ' ',
  // when machine_name is too vague and a useful description is available
  useDescription = false
) {
  if (!err) return { paramErrors: {}, otherErrors: '' }
  const errorHash = getApiErrorMessagesHash(err)
  const paramErrors: FieldError = {}
  const otherErrors: string[] = []
  const serverErrorAllowlist = ['Invalid budget amount: budget cannot be lower than budget spend']

  const globalErrors: ValidationErrorDetail[] =
    errorHash?.global as unknown as ValidationErrorDetail[]
  globalErrors?.forEach((error: ValidationErrorDetail) => {
    otherErrors.push(error.description)
  })

  const fieldErrors = errorHash?.fields as unknown as FieldError
  Object.entries(fieldErrors || {}).forEach(([key, value]) => {
    // Sometimes the backend returns a dot-separated string as the key
    // pull out the last segment as the key
    const paramSegments = key.split('.')
    const paramKey = paramSegments.pop() || paramSegments[0]
    const camelCaseKey = snakeToCamelCase(paramKey)
    const paramErrs = value as unknown as ValidationErrorDetail[]

    const paramErrsFormatted: string[] = paramErrs.map(paramErr => {
      // machine_name would match those in
      // https://github.com/instacart/carrot/blob/master/ads/formats-definitions/validation/validation.go
      const messageIdType = `common.error.validation.${paramErr.machine_name}` as MessageIdType
      if (useDescription || !intl.messages[messageIdType]) {
        return paramErr.description
      }
      return `${intl.formatMessage(
        { id: messageIdType },
        { field: capitalize(snakeToWords(paramKey)) }
      )}`
    })

    if (params.includes(camelCaseKey)) {
      paramErrors[camelCaseKey] = paramErrsFormatted.join(separator).concat(separator).trim()
    } else {
      otherErrors.push(...paramErrsFormatted)
    }
  })

  const serverErrors = errorHash?.server as unknown as ServerError
  Object.values(serverErrors || {}).forEach(error => {
    if (serverErrorAllowlist.indexOf(error) >= 0) {
      otherErrors.push(error)
    }
  })

  // User errors generated by RBAC permission checks
  const userErrors = errorHash?.user as unknown as string[]
  if (userErrors) {
    otherErrors.push(...userErrors)
  }

  return { paramErrors, otherErrors: otherErrors.join(separator).concat(separator).trim() }
}

export function getApiErrorByKey(err: ClientError | undefined, key: string, separator = '. ') {
  if (!err) return undefined

  const errorHash = getApiErrorMessagesHash(err)
  const errorKey = camelToSnakeCase(key)
  const rawError = errorHash[errorKey]

  if (rawError === undefined) {
    return undefined
  }

  return Array.isArray(rawError) ? rawError.join(separator) : String(rawError)
}

export function getApiErrorCode(err: ClientError) {
  return err?.response?.data?.meta?.error_code
}

/**
 * Returns if the given `ClientError` contains the field error referenced by `fieldKey` and `errorKey`.
 */
export function containsFieldError(err: ClientError, fieldKey: string, errorKey: string) {
  const errorHash = getApiErrorMessagesHash(err)
  const fieldErrors = errorHash?.fields as unknown as FieldError
  const keyedFieldErrors = fieldErrors[
    camelToSnakeCase(fieldKey)
  ] as unknown as ValidationErrorDetail[]
  if (!keyedFieldErrors) return false
  return keyedFieldErrors.some(validationErrorDetail => {
    return validationErrorDetail.machine_name === errorKey
  })
}

/**
 * Returns the same object, but with `null` and `undefined` values filtered out. It also
 * removes keys with empty string values when `filterEmpty` is `true`.
 *
 * @param {*} obj Object to compact.
 * @param {*} filterEmpty Filter empty strings?
 */
export function compactObject(obj = {}, filterEmpty = true) {
  return Object.entries(obj).reduce((memo, [key, value]) => {
    if (value == null || typeof value === 'undefined') {
      return memo
    }
    if (filterEmpty && value === '') {
      return memo
    }
    return { ...memo, [key]: value }
  }, {})
}

export function numItemsPerPage({
  totalCount,
  totalPages,
}: {
  totalCount: number
  totalPages: number
}) {
  const itemsPerPageRatio = totalPages === 0 ? 0 : totalCount / totalPages
  return Math.ceil(itemsPerPageRatio / 10) * 10
}

export function numItemsInCurrentPage({ currentPage, totalCount, totalPages }: ResponseMeta) {
  const itemsPerPage = numItemsPerPage({ totalCount, totalPages })
  return currentPage === totalPages ? totalCount % itemsPerPage : itemsPerPage
}

export function getCurrentOffset(
  { currentPage, totalCount, totalPages }: ResponseMeta,
  perPage?: number
) {
  const itemsPerPage = perPage || numItemsPerPage({ totalCount, totalPages })
  const firstRowOffset = totalCount > 0 ? 1 : 0
  return currentPage > 0 ? (currentPage - 1) * itemsPerPage + firstRowOffset : firstRowOffset
}

const upperLetterMatch = /[A-Z]+/g
export function camelToSnakeCase(str: string) {
  if (typeof str !== 'string') {
    return str
  }

  return str
    .replace(upperLetterMatch, (match, indx, baseString) => {
      const replacement =
        match.length === 1
          ? match
          : match.length + indx === baseString.length
          ? match
          : `${match.substring(0, match.length - 1)}_${match.substring(match.length - 1)}`

      return (indx === 0 ? '' : '_') + replacement
    })
    .toLowerCase()
}

const underscoreMatch = /_+[a-z]/g
export function snakeToCamelCase(str: string) {
  if (typeof str !== 'string') {
    return str
  }

  // expecting lower_snake_cased names form postgres
  return str.replace(underscoreMatch, match => {
    return match.substring(match.length - 1).toUpperCase()
  })
}

function snakeToWords(str: string) {
  return str.replace(underscoreMatch, match => {
    return ` ${match.substring(match.length - 1)}`
  })
}

function keepCase(str: string) {
  return str
}

function isArrayOrObject(input: any) {
  if (!input) {
    return false
  }
  return Array.isArray(input) || (typeof input === 'object' && input.constructor === Object)
}

export const rawObjAccess = Symbol('raw object access')
function cased(
  literalKeyArg: Function,
  expectedKeyArg: Function,
  fallback: Function,
  inputArg: any,
  forcedFallback = false
): any {
  if (!isArrayOrObject(inputArg)) {
    if (typeof inputArg === 'string') {
      return expectedKeyArg(inputArg)
    }
  }

  if (window.Proxy === undefined || Reflect === undefined || forcedFallback === true) {
    return fallback(inputArg)
  }

  const input = inputArg[rawObjAccess] || inputArg
  input[rawObjAccess] = inputArg

  const isArray = Array.isArray(input)
  const literalKey = isArray ? keepCase : literalKeyArg
  const expectedKey = isArray ? keepCase : expectedKeyArg

  return new Proxy(input, {
    get: (target, key) => {
      if (key === rawObjAccess) {
        return input
      }

      const keyUsed = literalKey(key)

      // eslint-disable-next-line no-prototype-builtins
      if (!target.hasOwnProperty(keyUsed)) {
        return Reflect.get(target, key)
      }

      const result = Reflect.get(target, keyUsed)

      if (isArrayOrObject(result)) {
        return cased(
          literalKeyArg,
          expectedKeyArg,
          fallback,
          result[rawObjAccess] || result,
          forcedFallback
        )
      }

      return result
    },

    set: (target, key, value) => {
      Reflect.set(target, literalKey(key), value)
      return true
    },

    has: (target, key) => {
      return Reflect.has(target, literalKey(key))
    },

    getOwnPropertyDescriptor: (target, key) => {
      return Reflect.getOwnPropertyDescriptor(target, literalKey(key))
    },

    deleteProperty: (target, key) => {
      Reflect.deleteProperty(target, literalKey(key))
      return true
    },

    ownKeys: target => {
      return Reflect.ownKeys(target).map(key => expectedKey(key))
    },

    defineProperty: (target, key, descriptor) => {
      return Reflect.defineProperty(target, literalKey(key), descriptor)
    },
  })
}

export const camelCased = curry(
  cased,
  camelToSnakeCase,
  snakeToCamelCase,
  function camelCasedMutation(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(cell => (isArrayOrObject(cell) ? camelCasedMutation(cell) : cell))
    }

    return Object.keys(obj).reduce((result: { [key: string]: any }, key: string) => {
      // eslint-disable-next-line no-param-reassign
      result[snakeToCamelCase(key)] = isArrayOrObject(obj[key])
        ? camelCasedMutation(obj[key])
        : obj[key]
      return result
    }, {})
  }
)
export const snakeCased = curry(
  cased,
  snakeToCamelCase,
  camelToSnakeCase,
  function snakeCasedMutation(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(cell => (isArrayOrObject(cell) ? snakeCasedMutation(cell) : cell))
    }

    return Object.keys(obj).reduce((result: { [key: string]: any }, key: string) => {
      // eslint-disable-next-line no-param-reassign
      result[camelToSnakeCase(key)] = isArrayOrObject(obj[key])
        ? snakeCasedMutation(obj[key])
        : obj[key]
      return result
    }, {})
  }
)

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  return keys.reduce((ret, key) => {
    ret[key] = obj[key]
    return ret
  }, {} as Partial<Pick<T, K>>) as Pick<T, K>
}

export function mapValues<T extends object, TResult>(
  obj: T,
  callback: (val: T[keyof T], key: keyof T, obj: T) => TResult
) {
  const result = {} as { [K in keyof T]: TResult }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = callback(obj[key], key, obj)
    }
  }
  return result
}

export function capitalize(str: string) {
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`
}

export function validURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

// Type guard for FormData
export const isFormData = (variable: unknown): variable is FormData =>
  (variable as FormData)?.entries !== undefined

// Type guard for URLSearchParams
export const isURLSearchParams = (variable: unknown): variable is URLSearchParams =>
  (variable as URLSearchParams)?.entries !== undefined

const HTTPS_PREFIX = 'https://'

export function appendHttpsProtocolToUrl(url: string): string {
  if (url.indexOf('http') !== 0) {
    return `${HTTPS_PREFIX}${url}`
  }

  return url
}

/**
 * Converts a timestamp in the form "hh:mm:ss" to minutes past midnight
 */
export const convertTimestampToMins = (timestamp: string) => {
  const [hour, min] = timestamp.split(':').map(Number)
  return hour * 60 + min
}

/**
 * Converts the number of minues past midnight to a "hh:mm:ss" timestamp
 */
export const convertMinsToTimestamp = (minutes: number) => {
  // Make sure minutes is a round number
  const rounded = Math.round(minutes)
  const pad = (num: number) => String(num).padStart(2, '0')
  return `${pad(Math.floor(rounded / 60))}:${pad(rounded % 60)} PT`
}

export const ApiErrorCodes = {
  AccountLocked: 401001,
  Unauthenticated: 401002,
  InvalidCredentials: 401004,
  UnconfirmedUser: 401005,
  InvalidMfaAttempt: 403001,
  UnconfirmedAccount: 403002,
  NotFound: 404000,
  RateLimited: 421000,
} as const

/**
 * Returns the query string value specified by the search parameter
 */
export function parseQueryString(url: string) {
  const [, queryString = ''] = url.split('?')
  return Qs.parse(queryString)
}

/**
 * Returns array of path segments for the current window location
 */
export function getWindowLocationSegments(): string[] {
  if (typeof window === 'undefined' || !window.location) return []

  return window.location.pathname.split('/').filter(chunk => chunk.length > 0)
}

// Returns the current path, including search query and fragment
export function getCurrentPath(): string {
  return `${window.location.pathname}${window.location.search}${window.location.hash}`
}

export function getElementByClass<T extends HTMLElement = HTMLDivElement>(
  className: string
): T | null {
  return document.querySelector<T>(`.${className}`)
}

export function getElementById(id: string) {
  return document.getElementById(id)
}

export function daysBetween(startDate?: string, endDate?: string) {
  const start = moment(startDate)
  const end = moment(endDate)
  if (startDate && endDate) {
    return Math.round(moment.duration(end.diff(start)).asDays())
  } else if (startDate && !endDate) {
    return Math.round(moment.duration(moment().diff(start)).asDays())
  }
  return 0
}

/**
 * Returns a flattened array of values from a nested object
 */
export function getObjectValues<T>(obj: T): string[] {
  return (
    obj && typeof obj === 'object' ? Object.values(obj).map(getObjectValues).flat() : [obj]
  ) as string[]
}

export const isObjectValueEmpty = (
  value: string | number | Array<unknown> | Record<string | number | symbol, unknown>
) => {
  return !value || (typeof value === 'object' && Object.keys(value).length === 0)
}

export const isNotNull = <T>(x: T): x is NonNullable<T> => !!x

export function pluck<T, K extends keyof T>(objs: T[], key: K): T[K][] {
  return objs.map(obj => obj[key])
}

// Escapes characters in `id` that would cause problems when used in a query selector
export function safeElementId(id: string) {
  return CSS.escape(id)
}

// Checks if metric is a valid NTB metric
export const isNtbMetric = (metric: string) => {
  const ntbMetrics = [
    'ntb_attributed_sales',
    'ntb_attributed_quantities',
    'percent_ntb_attributed_sales',
    'ntb_direct_sales',
    'ntb_halo_sales',
    'percent_ntb_direct_sales',
    'percent_ntb_halo_sales',
  ]
  return ntbMetrics.includes(metric)
}

export interface FilterState {
  timeFrame: {
    dateRange: {
      from?: Date
      to?: Date
    }
  }
}

function exportTooltipHelper<Row extends RowBase>({
  column,
  exportIntlId,
  metricTypeCheck,
  metricName,
}: {
  column: ColumnDefinition<Row>
  exportIntlId: MessageIdType
  metricTypeCheck: Function
  metricName: string
}) {
  const snakeCasedMetricName = snakeCase(metricName)
  const exportTooltip = [{ tooltip: { id: exportIntlId } }]

  if (metricTypeCheck(snakeCasedMetricName)) {
    column.headerTooltips = [
      ...(column.headerTooltips || []),
      ...exportTooltip,
    ] as TitleTooltipProps[]
  }

  return column
}

function mrcTooltipHelper<Row extends RowBase>({
  column,
  metricTypeCheck,
  metricName,
}: {
  column: ColumnDefinition<Row>
  metricTypeCheck: Function
  metricName: string
}) {
  const snakeCasedMetricName = snakeCase(metricName)
  const mrcTooltip = [
    {
      tooltip: {
        id: `common.tooltip.mrc`,
        values: { link: externalLinkFormatter(MRC_LINK), ...breakFormattingHelper },
      },
    },
  ]

  if (metricTypeCheck(snakeCasedMetricName)) {
    column.headerTooltips = [...(column.headerTooltips || []), ...mrcTooltip] as TitleTooltipProps[]
  }

  return column
}

// Common function for tooltip
export function columnTooltipHelper<Row extends RowBase>({
  metricName,
  column,
  metricTypeCheck,
}: {
  metricName: string
  column: ColumnDefinition<Row>
  metricTypeCheck: Function
}) {
  const snakeCasedMetricName = snakeCase(metricName)
  const formattedMetricName = startCase(metricName)

  if (metricTypeCheck(snakeCasedMetricName)) {
    column.headerTooltips = [
      ...(column.headerTooltips || []),
      {
        tooltip: {
          id: `common.${snakeCasedMetricName}.tooltip`,
          values: { formattedMetricName },
        },
      },
    ] as TitleTooltipProps[]
  }

  return column
}

export function ntbColumnTooltip<Row extends RowBase>(
  metricName: string,
  filterState: FilterState,
  column: ColumnDefinition<Row>
) {
  const snakeCasedMetricName = snakeCase(metricName)
  const ntbLaunchDate = new Date(process.env.NTB_LAUNCH_DATE || '2022-08-01')

  if (
    isNtbMetric(snakeCasedMetricName) &&
    (isEmpty(filterState.timeFrame.dateRange) ||
      (filterState.timeFrame.dateRange.to && filterState.timeFrame.dateRange.to > ntbLaunchDate))
  ) {
    column.headerTooltips = [
      {
        tooltip: {
          id: `common.ntbWarning.tooltip`,
          values: { snakeCasedMetricName: startCase(metricName) },
        },
      },
    ]
    column.warningTooltip = true
  }

  return columnTooltipHelper({ metricName, column, metricTypeCheck: isNtbMetric })
}

export function ctrColumnTooltip<Row extends RowBase>(
  metricName: string,
  column: ColumnDefinition<Row>,
  isMrc?: boolean
) {
  const isCtrMetric = (metric: string) => metric.includes('ctr')
  const columnCommon = columnTooltipHelper({ metricName, column, metricTypeCheck: isCtrMetric })
  return isMrc
    ? mrcTooltipHelper({ column, metricTypeCheck: isCtrMetric, metricName })
    : columnCommon
}

export function impressionColumnTooltip<Row extends RowBase>(
  metricName: string,
  column: ColumnDefinition<Row>,
  isMrc?: boolean,
  isEnabledForAccounts?: boolean
) {
  const exportIntlId = 'common.impressions.tooltip.export'
  const isImpressionMetric = (metric: string) => metric.includes('impressions')
  const columnCommon = columnTooltipHelper({
    metricName,
    column,
    metricTypeCheck: isImpressionMetric,
  })
  const columnWithExport = isEnabledForAccounts
    ? exportTooltipHelper({ column, exportIntlId, metricName, metricTypeCheck: isImpressionMetric })
    : columnCommon
  return isMrc
    ? mrcTooltipHelper({ column, metricTypeCheck: isImpressionMetric, metricName })
    : columnWithExport
}

export function clickColumnTooltip<Row extends RowBase>(
  metricName: string,
  column: ColumnDefinition<Row>,
  isMrc?: boolean,
  isEnabledForAccounts?: boolean
) {
  const isClickMetric = (metric: string) => metric.includes('click')
  const exportIntlId = 'common.clicks.tooltip'
  const columnWithExport = isEnabledForAccounts
    ? exportTooltipHelper({ column, exportIntlId, metricName, metricTypeCheck: isClickMetric })
    : column
  return isMrc
    ? mrcTooltipHelper({ column, metricTypeCheck: isClickMetric, metricName })
    : columnWithExport
}

/**
 * Util for checking at both compile time and runtime that all variants of an enum are covered
 *
 * for example this fails to compile, and would also throw an error at runtime if it did
 *
 * ```typescript
 * enum SomeEnum { A, B, C }
 *
 * const a: SomeEnum = SomeEnum.A;
 *
 * switch (a) {
 *   case SomeEnum.A:
 *      break
 *   case SomeEnum.B:
 *      break;
 *   default:
 *      assertNever(a) // SomeEnum.C is not assignable to type never
 * }
 * ```
 *
 * for more details see: https://stackoverflow.com/a/39419171
 */
export function assertNever(value: never): never
export function assertNever(value: never, throws: true): never
export function assertNever(value: never, throws: false): void

export function assertNever(value: never, throws = true): void | never {
  if (throws) {
    throw new Error(`unexpected never value got: ${value}`)
  }
}

export const uc = (s: string) => s.toUpperCase()

/**
 * Returns true if all values from an array of nested objects are empty.
 * Empty values include false, null, 0, "", undefined, and NaN.
 */
export function areNestedObjectsEmpty<T>(objects: T[]) {
  return (
    flatten(
      objects.map(object => {
        return compact(getObjectValues(object))
      })
    ).length === 0
  )
}

/**
 * Returns true if the values from a nested object are empty.
 * Empty values include false, null, 0, "", undefined, and NaN.
 */
export function isNestedObjectEmpty<T>(object: T) {
  return flatten(compact(getObjectValues(object))).length === 0
}

/**
 * Converts a number of bytes to megabytes (MB)
 * @param {number} bytes - the value in bytes
 * @param {number} precision - the number of decimal places to round to
 * @returns {number} the value in MB
 */
export const convertBytesToMB = (bytes: number, precision = 2): number => {
  return Math.round((bytes / 1000000) * 10 ** precision) / 10 ** precision
}

/**
 * Returns lower case values for an array of strings
 * @param {string[]} array - the array of strings
 * @returns {string[]} - the array of strings in lower case
 */
export const toLowerCaseArray = (array: string[]): string[] => {
  return array.map(item => item.toLowerCase())
}

/**
 * Returns true if the value is within the range of the target value
 * @param {number} value - the value to check
 * @param {number} targetValue - the target value
 * @param {number} range - the range to check
 * @returns {boolean} - true if the value is within the range of the target value
 */
export const isValueInRange = ({
  value,
  targetValue,
  range,
}: {
  value: number
  targetValue: number
  range: number
}): boolean => {
  return Math.abs(value - targetValue) <= range
}
