import { css } from '@emotion/react'
import { useTheme, spacing } from '@instacart/ids-core'
import { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TabHeader, TabHeaderProps } from './components/TabHeader'

export interface TabsProps {
  children: JSX.Element
  tabHeaders: TabHeaderProps[]
  defaultTab?: string
  onActiveTab?: (key: string) => void
}

function useStyles() {
  const theme = useTheme()
  return css({
    display: 'flex',
    backgroundColor: theme.colors.systemGrayscale00,
    borderBottom: `1px solid ${theme.colors.systemGrayscale20}`,
    marginBottom: spacing.s16,
    borderRadius: '12px 12px 0px 0px',
    gap: spacing.s8,
  })
}

const Tabs: FC<React.PropsWithChildren<TabsProps>> = ({
  children,
  tabHeaders,
  defaultTab,
  onActiveTab,
}) => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!tabHeaders.length) return

    const getBasePath = () => {
      const tabPath = tabHeaders[0].to as string
      return tabPath.substring(0, tabPath.lastIndexOf('/'))
    }

    const getDefaultPath = () => {
      let defaultPath = tabHeaders[0].to
      if (defaultTab) {
        const tabHeader = tabHeaders.find(th => th.tabKey === defaultTab)
        if (tabHeader) {
          defaultPath = tabHeader.to
        }
      }
      return defaultPath
    }

    if (location.pathname === getBasePath()) {
      return history.replace(getDefaultPath() as string)
    }
  }, [defaultTab, history, location.pathname, tabHeaders])

  return (
    <>
      <div css={styles} data-testid="tabs-headers">
        {tabHeaders.map(header => {
          const { tabKey } = header
          return (
            <div key={tabKey} data-testid={`tab-header-${tabKey}`}>
              <TabHeader {...header} onActive={onActiveTab} />
            </div>
          )
        })}
      </div>
      <div>{children}</div>
    </>
  )
}

export default Tabs
