// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockImageAndTextAssets } from './PostBrandPagesIdStatusParamBlockImageAndTextAssets'
import {
  PostBrandPagesIdStatusParamBlockImageAndTextAssetsFromJSON,
  PostBrandPagesIdStatusParamBlockImageAndTextAssetsFromJSONTyped,
  PostBrandPagesIdStatusParamBlockImageAndTextAssetsToJSON,
} from './PostBrandPagesIdStatusParamBlockImageAndTextAssets'
import type { PostBrandPagesIdStatusParamBlockImageAndTextContent } from './PostBrandPagesIdStatusParamBlockImageAndTextContent'
import {
  PostBrandPagesIdStatusParamBlockImageAndTextContentFromJSON,
  PostBrandPagesIdStatusParamBlockImageAndTextContentFromJSONTyped,
  PostBrandPagesIdStatusParamBlockImageAndTextContentToJSON,
} from './PostBrandPagesIdStatusParamBlockImageAndTextContent'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockImageAndText
 */
export interface PostBrandPagesIdStatusParamBlockImageAndText {
  /**
   * id of the block
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockImageAndText
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockImageAndText
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockImageAndText
   */
  type: PostBrandPagesIdStatusParamBlockImageAndTextTypeEnum
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockImageAndTextContent}
   * @memberof PostBrandPagesIdStatusParamBlockImageAndText
   */
  content: PostBrandPagesIdStatusParamBlockImageAndTextContent
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockImageAndTextAssets}
   * @memberof PostBrandPagesIdStatusParamBlockImageAndText
   */
  assets: PostBrandPagesIdStatusParamBlockImageAndTextAssets
}

/**
 * @export
 */
export const PostBrandPagesIdStatusParamBlockImageAndTextTypeEnum = {
  ImageAndTextEvergreenV1: 'image_and_text.evergreen.v1',
} as const
export type PostBrandPagesIdStatusParamBlockImageAndTextTypeEnum =
  typeof PostBrandPagesIdStatusParamBlockImageAndTextTypeEnum[keyof typeof PostBrandPagesIdStatusParamBlockImageAndTextTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockImageAndText interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockImageAndText(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockImageAndTextFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockImageAndText {
  return PostBrandPagesIdStatusParamBlockImageAndTextFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBlockImageAndTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockImageAndText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: PostBrandPagesIdStatusParamBlockImageAndTextContentFromJSON(json['content']),
    assets: PostBrandPagesIdStatusParamBlockImageAndTextAssetsFromJSON(json['assets']),
  }
}

export function PostBrandPagesIdStatusParamBlockImageAndTextToJSON(
  value?: PostBrandPagesIdStatusParamBlockImageAndText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: PostBrandPagesIdStatusParamBlockImageAndTextContentToJSON(value.content),
    assets: PostBrandPagesIdStatusParamBlockImageAndTextAssetsToJSON(value.assets),
  }
}
