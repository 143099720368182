import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'

export const UnstyledButton = styled.button({
  border: 'none',
  margin: 0,
  padding: 0,
  overflow: 'visible',

  background: 'transparent',

  color: 'inherit',
  font: 'inherit',

  lineHeight: 'normal',

  WebkitFontSmoothing: 'inherit',
  MozOsxFontSmoothing: 'inherit',

  WebkitAppearance: 'none',

  '&::-moz-focus-inner': {
    border: 0,
    padding: 0,
  },
})

export type UnstyledButtonProps = PropsOf<typeof UnstyledButton>
