import { SyntheticEvent, useState } from 'react'

// eslint-disable-next-line no-restricted-syntax
export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export function useScrollDirection(clearTimeout?: number) {
  let scrollTimeout: number
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | undefined>(undefined)
  const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    // Reset scroll direction when scrolling stops
    window.clearTimeout(scrollTimeout)
    scrollTimeout = window.setTimeout(() => {
      setScrollDirection(undefined)
    }, clearTimeout || 3000)

    const newScrollTop = event.currentTarget.scrollTop
    if (newScrollTop > scrollTop) {
      setScrollDirection(ScrollDirection.DOWN)
    } else if (newScrollTop < scrollTop) {
      setScrollDirection(ScrollDirection.UP)
    }
    setScrollTop(event.currentTarget.scrollTop)
  }
  return [scrollDirection, { onScroll }]
}
