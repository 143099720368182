// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SchemaFormatSchemasResponseData } from './SchemaFormatSchemasResponseData'
import {
  SchemaFormatSchemasResponseDataFromJSON,
  SchemaFormatSchemasResponseDataFromJSONTyped,
  SchemaFormatSchemasResponseDataToJSON,
} from './SchemaFormatSchemasResponseData'

/**
 *
 * @export
 * @interface SchemaFormatSchemasResponse
 */
export interface SchemaFormatSchemasResponse {
  /**
   *
   * @type {SchemaFormatSchemasResponseData}
   * @memberof SchemaFormatSchemasResponse
   */
  data?: SchemaFormatSchemasResponseData
}

/**
 * Check if a given object implements the SchemaFormatSchemasResponse interface.
 */
export function instanceOfSchemaFormatSchemasResponse(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function SchemaFormatSchemasResponseFromJSON(json: any): SchemaFormatSchemasResponse {
  return SchemaFormatSchemasResponseFromJSONTyped(json, false)
}

export function SchemaFormatSchemasResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SchemaFormatSchemasResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data') ? undefined : SchemaFormatSchemasResponseDataFromJSON(json['data']),
  }
}

export function SchemaFormatSchemasResponseToJSON(value?: SchemaFormatSchemasResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: SchemaFormatSchemasResponseDataToJSON(value.data),
  }
}
