// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetBrandPagesParamBlockProductCollectionContent
 */
export interface GetBrandPagesParamBlockProductCollectionContent {
  /**
   * Short title/blurb for the block.
   * @type {string}
   * @memberof GetBrandPagesParamBlockProductCollectionContent
   */
  title: string
}

/**
 * Check if a given object implements the GetBrandPagesParamBlockProductCollectionContent interface.
 */
export function instanceOfGetBrandPagesParamBlockProductCollectionContent(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value

  return isInstance
}

export function GetBrandPagesParamBlockProductCollectionContentFromJSON(
  json: any
): GetBrandPagesParamBlockProductCollectionContent {
  return GetBrandPagesParamBlockProductCollectionContentFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockProductCollectionContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockProductCollectionContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
  }
}

export function GetBrandPagesParamBlockProductCollectionContentToJSON(
  value?: GetBrandPagesParamBlockProductCollectionContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
  }
}
