import { css } from '@emotion/react'
import { Text, useTheme, ChevronDownIcon, ChevronUpIcon } from '@instacart/ids-core'
import { useState, ReactNode, useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'
import { TargetingRuleContext } from '../../hooks'

const useStyles = (isReviewing: boolean) => {
  const theme = useTheme()
  return {
    targetingSectionLayoutStyle: css(
      isReviewing
        ? {
            marginTop: 12,
            '&:first-of-type': {
              marginTop: 1,
            },
          }
        : {
            marginTop: 12,
            borderRadius: 8,
            background: theme.colors.systemGrayscale10,
            padding: 12,
          }
    ),
    headerContainerStyle: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: isReviewing ? undefined : 'pointer',
    }),
    sectionContentStyle: css({
      marginTop: isReviewing ? 4 : 12,
      width: '100%',
    }),
  }
}

interface TargetingSectionLayoutProps {
  titleMessageId: MessageIdType
  renderCollapsed: () => ReactNode
  renderExpanded: () => ReactNode
  onExpanded: () => void
  testId: string
}

export default function TargetingSectionLayout({
  titleMessageId,
  renderCollapsed,
  renderExpanded,
  onExpanded,
  testId,
}: TargetingSectionLayoutProps) {
  const { isReviewing } = useContext(TargetingRuleContext)

  const { targetingSectionLayoutStyle, headerContainerStyle, sectionContentStyle } =
    useStyles(isReviewing)

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  const handleClick = () => {
    if (isCollapsed) {
      onExpanded()
    }
    setIsCollapsed(!isCollapsed)
  }

  const IconToShow = isCollapsed ? ChevronDownIcon : ChevronUpIcon

  return (
    <div css={targetingSectionLayoutStyle} data-testid={testId}>
      <div
        css={headerContainerStyle}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
      >
        <Text typography="bodyMedium1">
          <FormattedMessage id={titleMessageId} />
        </Text>
        {!isReviewing && <IconToShow size={20} color="systemGrayscale30" />}
      </div>
      <div css={sectionContentStyle}>
        {isCollapsed || isReviewing ? renderCollapsed() : renderExpanded()}
      </div>
    </div>
  )
}
