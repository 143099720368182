// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktop } from './PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktop'
import {
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktop'
import type { PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobile } from './PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobile'
import {
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets
 */
export interface PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets
   */
  desktop: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets
   */
  mobile: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets {
  return PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktopFromJSON(
      json['desktop']
    ),
    mobile: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobileFromJSON(
      json['mobile']
    ),
  }
}

export function PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsToJSON(
  value?: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsDesktopToJSON(
      value.desktop
    ),
    mobile: PostVideoCampaignsIdSubmitParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
