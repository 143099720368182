// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAccountsControllerUpdateCorpAddressInputCorporateAddress } from './ApiAccountsControllerUpdateCorpAddressInputCorporateAddress'
import {
  ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSON,
  ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSONTyped,
  ApiAccountsControllerUpdateCorpAddressInputCorporateAddressToJSON,
} from './ApiAccountsControllerUpdateCorpAddressInputCorporateAddress'

/**
 *
 * @export
 * @interface ApiAccountsControllerUpdateCorpAddressInput
 */
export interface ApiAccountsControllerUpdateCorpAddressInput {
  /**
   *
   * @type {ApiAccountsControllerUpdateCorpAddressInputCorporateAddress}
   * @memberof ApiAccountsControllerUpdateCorpAddressInput
   */
  corporateAddress?: ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
}

/**
 * Check if a given object implements the ApiAccountsControllerUpdateCorpAddressInput interface.
 */
export function instanceOfApiAccountsControllerUpdateCorpAddressInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAccountsControllerUpdateCorpAddressInputFromJSON(
  json: any
): ApiAccountsControllerUpdateCorpAddressInput {
  return ApiAccountsControllerUpdateCorpAddressInputFromJSONTyped(json, false)
}

export function ApiAccountsControllerUpdateCorpAddressInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAccountsControllerUpdateCorpAddressInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    corporateAddress: !exists(json, 'corporate_address')
      ? undefined
      : ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSON(
          json['corporate_address']
        ),
  }
}

export function ApiAccountsControllerUpdateCorpAddressInputToJSON(
  value?: ApiAccountsControllerUpdateCorpAddressInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    corporate_address: ApiAccountsControllerUpdateCorpAddressInputCorporateAddressToJSON(
      value.corporateAddress
    ),
  }
}
