import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'
import { EventEmitter2 } from 'eventemitter2'
import { canUseDOM } from 'exenv'
import Qs from 'qs'
import { clearCurrentAccountId, getCurrentAccountId } from 'common/currentAccountHelper'
import { ApiErrorCodes, camelCased, snakeCased } from 'common/utils'
import {
  CampaignClientInterface,
  AccountClientInterface,
  AdGroupClientInterface,
  AdGroupProductsClientInterface,
  AdGroupKeywordsClientInterface,
} from 'service/types'

declare let __ADS: any

export interface ClientRequestConfig extends AxiosRequestConfig {
  path?: string
  csrf?: string
}

export interface ClientRequestHeaders {
  [key: string]: string
}

interface PerformRequestOptions {
  headers?: ClientRequestHeaders
  skipRequestCasing?: boolean
  skipResponseCasing?: boolean
  retries?: number // number of retries allowed
}

type ClientInterface =
  | CampaignClientInterface
  | AdGroupClientInterface
  | AdGroupProductsClientInterface
  | AccountClientInterface
  | AdGroupKeywordsClientInterface

export interface ClientCache<T extends ClientInterface> {
  current?: T
  openAPIEnabled?: boolean
}

export type PageError = {
  errors: Record<string, string[]>
  status: number
}

const requiredInstanceCache = Symbol('required instance')

const eventEmitter = new EventEmitter2()

/* A lightweight client to encapsulate logic for making requests
 * and capturing errors in a unified way. The Client class should not be used directly
 * but instead should be extended.
 */
class Client {
  // ADS_API_URL is the base URL for the ads-backend service
  ADS_API_URL: string | null

  // ADS_API_PATH is the path portion of the URL, this should be overridden in the child class
  ADS_API_PATH: string | null

  // if true, any failed requests will trigger a page-level exception
  SUCCESS_REQUIRED: boolean

  // axios is the shared Axios instance to be used for all HTTP requests and URI creation
  axios: AxiosInstance

  // csrfToken is the CSRF token used for non-GET requests and is set/unset via the static methods on the Client
  static csrfToken: string

  static throwPageError(errors: PageError['errors'], status: PageError['status']) {
    eventEmitter.emit('PageError', { errors, status })
  }

  static addPageErrorListener(callback: (pageError: PageError) => void) {
    eventEmitter.on('PageError', callback)
  }

  static removePageErrorListener(callback: (pageError: PageError) => void) {
    eventEmitter.off('PageError', callback)
  }

  static setCSRFToken = (csrfToken: string) => {
    Client.csrfToken = csrfToken
  }

  static unsetCSRFToken = () => {
    Client.csrfToken = ''
  }

  // return true if a (required) request should *not* trigger a page-level exception
  // ignoring 'unused param' errors from typescript
  // @ts-ignore
  static pageExceptionSkip = (err: any, config: ClientRequestConfig) => {
    return false
  }

  constructor(successRequired = true) {
    this.ADS_API_URL = canUseDOM ? __ADS.env.ADS_API_BASE_URL : null
    this.ADS_API_PATH = null
    this.SUCCESS_REQUIRED = successRequired

    this.axios = axios.create({
      paramsSerializer: {
        serialize: params => {
          const snakeCasedParams = snakeCased(params)
          const sanitizedParams = Object.keys(snakeCasedParams).reduce((sanitized, paramKey) => {
            if (snakeCasedParams[paramKey]) {
              return {
                [paramKey]: snakeCasedParams[paramKey],
                ...sanitized,
              }
            }
            return sanitized
          }, {})
          return Qs.stringify(sanitizedParams, { arrayFormat: 'brackets' })
        },
      },
    })
  }

  get required(): this {
    const Klass: any = this.constructor

    if (Klass[requiredInstanceCache]) {
      return Klass[requiredInstanceCache]
    }

    Klass[requiredInstanceCache] = new Klass(true)
    return Klass[requiredInstanceCache]
  }

  getDownloadURI = (config: ClientRequestConfig, accountId?: string) => {
    const params = config.params || {}

    const currentAccountId = accountId || getCurrentAccountId()
    if (currentAccountId) {
      params.accountId = currentAccountId
    }

    return this.axios.getUri({
      ...config,
      params,
    })
  }

  performRequest = async <T>(
    config: ClientRequestConfig,
    options?: PerformRequestOptions
  ): Promise<T> => {
    const requestPath: string = config.path || ''
    delete config.path

    const retries = options?.retries || 0
    delete options?.retries

    const headers: ClientRequestHeaders = options?.headers || {}
    if (Client.csrfToken && config.method !== 'get') {
      headers['X-CSRF-TOKEN'] = Client.csrfToken
    }

    const currentAccountId = getCurrentAccountId()
    if (currentAccountId) {
      headers['Account-ID'] = currentAccountId
    }

    if (config.data && !options?.skipRequestCasing) {
      config.data = snakeCased(config.data)
    }
    try {
      const response = await this.axios({
        ...config,
        headers,
        withCredentials: true,
        url: `${this.ADS_API_URL}${this.ADS_API_PATH}${requestPath}`,
      })
      return options?.skipResponseCasing ? response.data : camelCased(response.data)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as AxiosError<any, any>
      const errorCode = error?.response?.data?.meta?.error_code
      const loggedInAndUnauthorized = error?.response?.status === 401 && Client.csrfToken
      const notFound = error?.response?.status === 404
      const klass = this.constructor as typeof Client

      if (errorCode === ApiErrorCodes.UnconfirmedAccount) {
        clearCurrentAccountId()
      }

      if (retries > 0) {
        // retry if there are retries left
        // refresh auth context first
        // the session cookie might have expired (24 hrs TTL), but the auth cookie may still valid (30 days TTL).
        if (loggedInAndUnauthorized) {
          const { AuthClient } = require('service/auth')
          await AuthClient.getUserInfo()
        }
        return this.performRequest(
          {
            path: requestPath,
            ...config,
          },
          {
            retries: retries - 1,
            ...options,
          }
        )
      }
      if (this.SUCCESS_REQUIRED && currentAccountId && currentAccountId !== 'null') {
        // Make sure we have chosen an account as well.
        if ((loggedInAndUnauthorized || notFound) && !klass.pageExceptionSkip(error, config)) {
          Client.throwPageError(
            error?.response?.data?.meta?.errors,
            error?.response?.status as number
          )
        }
      }

      throw error
    }
  }
}

export default Client
