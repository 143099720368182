// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdGroupKeywordsBulkParamKeywords } from './PostAdGroupKeywordsBulkParamKeywords'
import {
  PostAdGroupKeywordsBulkParamKeywordsFromJSON,
  PostAdGroupKeywordsBulkParamKeywordsFromJSONTyped,
  PostAdGroupKeywordsBulkParamKeywordsToJSON,
} from './PostAdGroupKeywordsBulkParamKeywords'

/**
 *
 * @export
 * @interface ApiAdGroupKeywordsControllerBulkCreateInput
 */
export interface ApiAdGroupKeywordsControllerBulkCreateInput {
  /**
   * Array of ad group keywords to be added
   * @type {Array<PostAdGroupKeywordsBulkParamKeywords>}
   * @memberof ApiAdGroupKeywordsControllerBulkCreateInput
   */
  keywords: Array<PostAdGroupKeywordsBulkParamKeywords>
  /**
   * Context of keyword creation. API users will default to endpoint_call. Non-API users will default to unidentified if param not provided.
   * @type {string}
   * @memberof ApiAdGroupKeywordsControllerBulkCreateInput
   */
  clientContext?: ApiAdGroupKeywordsControllerBulkCreateInputClientContextEnum
}

/**
 * @export
 */
export const ApiAdGroupKeywordsControllerBulkCreateInputClientContextEnum = {
  AdGroupEdit: 'ad_group_edit',
  AutoTargetedKeywordBidOverride: 'auto_targeted_keyword_bid_override',
} as const
export type ApiAdGroupKeywordsControllerBulkCreateInputClientContextEnum =
  typeof ApiAdGroupKeywordsControllerBulkCreateInputClientContextEnum[keyof typeof ApiAdGroupKeywordsControllerBulkCreateInputClientContextEnum]

/**
 * Check if a given object implements the ApiAdGroupKeywordsControllerBulkCreateInput interface.
 */
export function instanceOfApiAdGroupKeywordsControllerBulkCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keywords' in value

  return isInstance
}

export function ApiAdGroupKeywordsControllerBulkCreateInputFromJSON(
  json: any
): ApiAdGroupKeywordsControllerBulkCreateInput {
  return ApiAdGroupKeywordsControllerBulkCreateInputFromJSONTyped(json, false)
}

export function ApiAdGroupKeywordsControllerBulkCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupKeywordsControllerBulkCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keywords: (json['keywords'] as Array<any>).map(PostAdGroupKeywordsBulkParamKeywordsFromJSON),
    clientContext: !exists(json, 'client_context') ? undefined : json['client_context'],
  }
}

export function ApiAdGroupKeywordsControllerBulkCreateInputToJSON(
  value?: ApiAdGroupKeywordsControllerBulkCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keywords: (value.keywords as Array<any>).map(PostAdGroupKeywordsBulkParamKeywordsToJSON),
    client_context: value.clientContext,
  }
}
