// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick } from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid } from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGrid } from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGrid'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGridToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGrid'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid } from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesCreativeActions
 */
export interface GetVideoAdGroupsParamDataAttributesCreativeActions {
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGrid}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActions
   */
  mainItemGrid?: GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActions
   */
  mainProductGroupGrid?: GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActions
   */
  mainCollectionGrid?: GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActions
   */
  bannerClick?: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesCreativeActions interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesCreativeActions {
  return GetVideoAdGroupsParamDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON(json['banner_click']),
  }
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsToJSON(
  value?: GetVideoAdGroupsParamDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: GetVideoAdGroupsParamDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      GetVideoAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      GetVideoAdGroupsParamDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
