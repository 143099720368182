// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesAnalyticsResponseData } from './GetBrandPagesAnalyticsResponseData'
import {
  GetBrandPagesAnalyticsResponseDataFromJSON,
  GetBrandPagesAnalyticsResponseDataFromJSONTyped,
  GetBrandPagesAnalyticsResponseDataToJSON,
} from './GetBrandPagesAnalyticsResponseData'
import type { GetBrandPagesAnalyticsResponseMeta } from './GetBrandPagesAnalyticsResponseMeta'
import {
  GetBrandPagesAnalyticsResponseMetaFromJSON,
  GetBrandPagesAnalyticsResponseMetaFromJSONTyped,
  GetBrandPagesAnalyticsResponseMetaToJSON,
} from './GetBrandPagesAnalyticsResponseMeta'

/**
 *
 * @export
 * @interface GetBrandPagesAnalyticsResponse
 */
export interface GetBrandPagesAnalyticsResponse {
  /**
   *
   * @type {GetBrandPagesAnalyticsResponseData}
   * @memberof GetBrandPagesAnalyticsResponse
   */
  data: GetBrandPagesAnalyticsResponseData
  /**
   *
   * @type {GetBrandPagesAnalyticsResponseMeta}
   * @memberof GetBrandPagesAnalyticsResponse
   */
  meta: GetBrandPagesAnalyticsResponseMeta
}

/**
 * Check if a given object implements the GetBrandPagesAnalyticsResponse interface.
 */
export function instanceOfGetBrandPagesAnalyticsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetBrandPagesAnalyticsResponseFromJSON(json: any): GetBrandPagesAnalyticsResponse {
  return GetBrandPagesAnalyticsResponseFromJSONTyped(json, false)
}

export function GetBrandPagesAnalyticsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesAnalyticsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetBrandPagesAnalyticsResponseDataFromJSON(json['data']),
    meta: GetBrandPagesAnalyticsResponseMetaFromJSON(json['meta']),
  }
}

export function GetBrandPagesAnalyticsResponseToJSON(
  value?: GetBrandPagesAnalyticsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetBrandPagesAnalyticsResponseDataToJSON(value.data),
    meta: GetBrandPagesAnalyticsResponseMetaToJSON(value.meta),
  }
}
