import { without } from 'lodash'
import { Children, cloneElement, ComponentPropsWithoutRef } from 'react'
import { OptionPillProps } from './OptionPill'

export interface PillMultiSelectProps<T> extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value?: T[]
  onChange(value: T[]): void
  children: React.ReactElement<OptionPillProps<T>> | React.ReactElement<OptionPillProps<T>>[]
}

function toggleItem<T>(current: T[] | undefined, item: T) {
  return current?.includes(item) ? without(current, item) : [...(current || []), item]
}

export function PillMultiSelect<T>({
  children,
  value: currentValue,
  onChange,
  ...props
}: PillMultiSelectProps<T>) {
  const handleToggle = (value: T) => onChange(toggleItem(currentValue, value))

  return (
    <div role="listbox" {...props}>
      {Children.map(children, child =>
        cloneElement(child, {
          selected: currentValue?.includes(child.props.value),
          onSelect: handleToggle,
        })
      )}
    </div>
  )
}
