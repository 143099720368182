// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayCampaignsIdParamBlockHeroBannerDisplay,
  instanceOfPutDisplayCampaignsIdParamBlockHeroBannerDisplay,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSON,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayToJSON,
} from './PutDisplayCampaignsIdParamBlockHeroBannerDisplay'
import {
  PutDisplayCampaignsIdParamBlockItemGridDisplay,
  instanceOfPutDisplayCampaignsIdParamBlockItemGridDisplay,
  PutDisplayCampaignsIdParamBlockItemGridDisplayFromJSON,
  PutDisplayCampaignsIdParamBlockItemGridDisplayFromJSONTyped,
  PutDisplayCampaignsIdParamBlockItemGridDisplayToJSON,
} from './PutDisplayCampaignsIdParamBlockItemGridDisplay'

/**
 * @type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks
 *
 * @export
 */
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & PutDisplayCampaignsIdParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & PutDisplayCampaignsIdParamBlockItemGridDisplay)

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PutDisplayCampaignsIdParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PutDisplayCampaignsIdParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PutDisplayCampaignsIdParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
