// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentText } from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentText'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentText'
import type { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitle } from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent
 */
export interface PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent
   */
  title?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent
   */
  text?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent {
  return PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
