// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabels } from './PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabels'
import {
  PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabelsFromJSON,
  PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabelsFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabelsToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabels'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy
 */
export interface PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy
   */
  decision?: PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabels>}
   * @memberof PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy
   */
  labels?: Array<PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabels>
}

/**
 * @export
 */
export const PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyDecisionEnum =
  typeof PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyDecisionEnum[keyof typeof PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyDecisionEnum]

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSON(
  json: any
): PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy {
  return PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabelsFromJSON
        ),
  }
}

export function PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyToJSON(
  value?: PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PutUniversalAdGroupsIdParamBrandPageFieldDecisionsHierarchyLabelsToJSON
          ),
  }
}
