// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes'

/**
 *
 * @export
 * @interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner
 */
export interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner {
  /**
   * Alcohol State Permission Id
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner
   */
  type: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerTypeEnum
  /**
   *
   * @type {GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner
   */
  attributes: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
}

/**
 * @export
 */
export const GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerTypeEnum = {
  AlcoholStatePermission: 'alcohol_state_permission',
} as const
export type GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerTypeEnum =
  typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerTypeEnum[keyof typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerTypeEnum]

/**
 * Check if a given object implements the GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner interface.
 */
export function instanceOfGetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSON(
  json: any
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner {
  return GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSONTyped(
    json,
    false
  )
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes:
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSON(
        json['attributes']
      ),
  }
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerToJSON(
  value?: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes:
      GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesToJSON(
        value.attributes
      ),
  }
}
