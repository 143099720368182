import styled from '@emotion/styled'
import { spacing } from '@instacart/ids-core'
import classNames from 'classnames'
import { ComponentProps } from 'react'

const Inner = styled.div({
  display: 'flex',
  alignItems: 'center',
  '&.right': {
    justifyContent: 'flex-end',
  },
  '> button, > a, > div': {
    marginLeft: spacing.s12,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  '&.wide': {
    '> button, > a, > div': {
      marginLeft: spacing.s16,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  '&.compact': {
    '> button, > a, > div': {
      marginLeft: spacing.s8,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
})

export interface ButtonRowProps extends ComponentProps<typeof Inner> {
  right?: boolean
  wide?: boolean
  compact?: boolean
}

export default function ButtonRow({ right, wide, compact, className, ...props }: ButtonRowProps) {
  return <Inner {...props} className={classNames(className, { right, wide, compact })} />
}
