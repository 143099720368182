// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayCampaignsIdParamProductCollection,
  instanceOfPutDisplayCampaignsIdParamProductCollection,
  PutDisplayCampaignsIdParamProductCollectionFromJSON,
  PutDisplayCampaignsIdParamProductCollectionFromJSONTyped,
  PutDisplayCampaignsIdParamProductCollectionToJSON,
} from './PutDisplayCampaignsIdParamProductCollection'
import {
  PutDisplayCampaignsIdParamSearchTermCollection,
  instanceOfPutDisplayCampaignsIdParamSearchTermCollection,
  PutDisplayCampaignsIdParamSearchTermCollectionFromJSON,
  PutDisplayCampaignsIdParamSearchTermCollectionFromJSONTyped,
  PutDisplayCampaignsIdParamSearchTermCollectionToJSON,
} from './PutDisplayCampaignsIdParamSearchTermCollection'

/**
 * @type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
 *
 * @export
 */
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties =

    | ({ type: 'product_collection' } & PutDisplayCampaignsIdParamProductCollection)
    | ({ type: 'search_term_collection' } & PutDisplayCampaignsIdParamSearchTermCollection)

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'product_collection':
      return {
        ...PutDisplayCampaignsIdParamProductCollectionFromJSONTyped(json, true),
        type: 'product_collection',
      }
    case 'search_term_collection':
      return {
        ...PutDisplayCampaignsIdParamSearchTermCollectionFromJSONTyped(json, true),
        type: 'search_term_collection',
      }
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'product_collection':
      return PutDisplayCampaignsIdParamProductCollectionToJSON(value)
    case 'search_term_collection':
      return PutDisplayCampaignsIdParamSearchTermCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${value['type']}'`
      )
  }
}
