// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBlockBrandLogoAssets } from './PutBrandPagesIdParamBlockBrandLogoAssets'
import {
  PutBrandPagesIdParamBlockBrandLogoAssetsFromJSON,
  PutBrandPagesIdParamBlockBrandLogoAssetsFromJSONTyped,
  PutBrandPagesIdParamBlockBrandLogoAssetsToJSON,
} from './PutBrandPagesIdParamBlockBrandLogoAssets'

/**
 *
 * @export
 * @interface PutBrandPagesIdParamBlockBrandLogo
 */
export interface PutBrandPagesIdParamBlockBrandLogo {
  /**
   * id of the block, required for updates
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockBrandLogo
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockBrandLogo
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockBrandLogo
   */
  type: PutBrandPagesIdParamBlockBrandLogoTypeEnum
  /**
   *
   * @type {PutBrandPagesIdParamBlockBrandLogoAssets}
   * @memberof PutBrandPagesIdParamBlockBrandLogo
   */
  assets: PutBrandPagesIdParamBlockBrandLogoAssets
}

/**
 * @export
 */
export const PutBrandPagesIdParamBlockBrandLogoTypeEnum = {
  BrandLogoEvergreenV1: 'brand_logo.evergreen.v1',
} as const
export type PutBrandPagesIdParamBlockBrandLogoTypeEnum =
  typeof PutBrandPagesIdParamBlockBrandLogoTypeEnum[keyof typeof PutBrandPagesIdParamBlockBrandLogoTypeEnum]

/**
 * Check if a given object implements the PutBrandPagesIdParamBlockBrandLogo interface.
 */
export function instanceOfPutBrandPagesIdParamBlockBrandLogo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PutBrandPagesIdParamBlockBrandLogoFromJSON(
  json: any
): PutBrandPagesIdParamBlockBrandLogo {
  return PutBrandPagesIdParamBlockBrandLogoFromJSONTyped(json, false)
}

export function PutBrandPagesIdParamBlockBrandLogoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBlockBrandLogo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PutBrandPagesIdParamBlockBrandLogoAssetsFromJSON(json['assets']),
  }
}

export function PutBrandPagesIdParamBlockBrandLogoToJSON(
  value?: PutBrandPagesIdParamBlockBrandLogo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PutBrandPagesIdParamBlockBrandLogoAssetsToJSON(value.assets),
  }
}
