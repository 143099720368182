// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostBrandPagesParamBlockBrandLogo,
  instanceOfPostBrandPagesParamBlockBrandLogo,
  PostBrandPagesParamBlockBrandLogoFromJSON,
  PostBrandPagesParamBlockBrandLogoFromJSONTyped,
  PostBrandPagesParamBlockBrandLogoToJSON,
} from './PostBrandPagesParamBlockBrandLogo'
import {
  PostBrandPagesParamBlockHeroBanner,
  instanceOfPostBrandPagesParamBlockHeroBanner,
  PostBrandPagesParamBlockHeroBannerFromJSON,
  PostBrandPagesParamBlockHeroBannerFromJSONTyped,
  PostBrandPagesParamBlockHeroBannerToJSON,
} from './PostBrandPagesParamBlockHeroBanner'
import {
  PostBrandPagesParamBlockImageAndText,
  instanceOfPostBrandPagesParamBlockImageAndText,
  PostBrandPagesParamBlockImageAndTextFromJSON,
  PostBrandPagesParamBlockImageAndTextFromJSONTyped,
  PostBrandPagesParamBlockImageAndTextToJSON,
} from './PostBrandPagesParamBlockImageAndText'
import {
  PostBrandPagesParamBlockProductCollection,
  instanceOfPostBrandPagesParamBlockProductCollection,
  PostBrandPagesParamBlockProductCollectionFromJSON,
  PostBrandPagesParamBlockProductCollectionFromJSONTyped,
  PostBrandPagesParamBlockProductCollectionToJSON,
} from './PostBrandPagesParamBlockProductCollection'
import {
  PostBrandPagesParamBlockProductGroupCollection,
  instanceOfPostBrandPagesParamBlockProductGroupCollection,
  PostBrandPagesParamBlockProductGroupCollectionFromJSON,
  PostBrandPagesParamBlockProductGroupCollectionFromJSONTyped,
  PostBrandPagesParamBlockProductGroupCollectionToJSON,
} from './PostBrandPagesParamBlockProductGroupCollection'
import {
  PostBrandPagesParamBlockSectionBanner,
  instanceOfPostBrandPagesParamBlockSectionBanner,
  PostBrandPagesParamBlockSectionBannerFromJSON,
  PostBrandPagesParamBlockSectionBannerFromJSONTyped,
  PostBrandPagesParamBlockSectionBannerToJSON,
} from './PostBrandPagesParamBlockSectionBanner'
import {
  PostBrandPagesParamBlockSpotlightProductCollection,
  instanceOfPostBrandPagesParamBlockSpotlightProductCollection,
  PostBrandPagesParamBlockSpotlightProductCollectionFromJSON,
  PostBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped,
  PostBrandPagesParamBlockSpotlightProductCollectionToJSON,
} from './PostBrandPagesParamBlockSpotlightProductCollection'

/**
 * @type PostBrandPagesParamBrandPageBlocks
 *
 * @export
 */
export type PostBrandPagesParamBrandPageBlocks =
  | ({ discriminator: 'brand_logo.evergreen.v1' } & PostBrandPagesParamBlockBrandLogo)
  | ({ discriminator: 'hero_banner.evergreen.v1' } & PostBrandPagesParamBlockHeroBanner)
  | ({ discriminator: 'image_and_text.evergreen.v1' } & PostBrandPagesParamBlockImageAndText)
  | ({
      discriminator: 'product_collection.evergreen.v1'
    } & PostBrandPagesParamBlockProductCollection)
  | ({
      discriminator: 'product_group_collection.evergreen.v1'
    } & PostBrandPagesParamBlockProductGroupCollection)
  | ({ discriminator: 'section_banner.evergreen.v1' } & PostBrandPagesParamBlockSectionBanner)
  | ({
      discriminator: 'spotlight_product_collection.evergreen.v1'
    } & PostBrandPagesParamBlockSpotlightProductCollection)

export function PostBrandPagesParamBrandPageBlocksFromJSON(
  json: any
): PostBrandPagesParamBrandPageBlocks {
  return PostBrandPagesParamBrandPageBlocksFromJSONTyped(json, false)
}

export function PostBrandPagesParamBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockBrandLogoFromJSONTyped(json, true),
        discriminator: 'brand_logo.evergreen.v1',
      }
    case 'hero_banner.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockHeroBannerFromJSONTyped(json, true),
        discriminator: 'hero_banner.evergreen.v1',
      }
    case 'image_and_text.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockImageAndTextFromJSONTyped(json, true),
        discriminator: 'image_and_text.evergreen.v1',
      }
    case 'product_collection.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockProductCollectionFromJSONTyped(json, true),
        discriminator: 'product_collection.evergreen.v1',
      }
    case 'product_group_collection.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockProductGroupCollectionFromJSONTyped(json, true),
        discriminator: 'product_group_collection.evergreen.v1',
      }
    case 'section_banner.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockSectionBannerFromJSONTyped(json, true),
        discriminator: 'section_banner.evergreen.v1',
      }
    case 'spotlight_product_collection.evergreen.v1':
      return {
        ...PostBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(json, true),
        discriminator: 'spotlight_product_collection.evergreen.v1',
      }
    default:
      throw new Error(
        `No variant of PostBrandPagesParamBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PostBrandPagesParamBrandPageBlocksToJSON(
  value?: PostBrandPagesParamBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return PostBrandPagesParamBlockBrandLogoToJSON(value)
    case 'hero_banner.evergreen.v1':
      return PostBrandPagesParamBlockHeroBannerToJSON(value)
    case 'image_and_text.evergreen.v1':
      return PostBrandPagesParamBlockImageAndTextToJSON(value)
    case 'product_collection.evergreen.v1':
      return PostBrandPagesParamBlockProductCollectionToJSON(value)
    case 'product_group_collection.evergreen.v1':
      return PostBrandPagesParamBlockProductGroupCollectionToJSON(value)
    case 'section_banner.evergreen.v1':
      return PostBrandPagesParamBlockSectionBannerToJSON(value)
    case 'spotlight_product_collection.evergreen.v1':
      return PostBrandPagesParamBlockSpotlightProductCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PostBrandPagesParamBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
