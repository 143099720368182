import { css, Global } from '@emotion/react'
import { addAlphaToHex, CloseIcon, elevation, spacing, useTheme } from '@instacart/ids-core'
import { uniqueId } from 'lodash'
import { useRef, useState } from 'react'
import layers from 'common/layers'
import useIntl from 'common/useIntl'
import { IconButtonInline } from 'components/molecules'
import { useModalCloseHandlers } from 'hooks/useModalCloseHandlers'
import { GenericMessageDescriptor } from 'locales/types'
import Portal from '../Portal'

const CLOSE_ICON_SIZE = 20

export interface ModalProps {
  children: React.ReactNode
  onClose?(): void
  title?: GenericMessageDescriptor
  width?: string | number
  fullPage?: boolean
  show?: boolean
  hideCloseIcon?: boolean
  /** Allow the modal to be closed and show the close button */
  allowClose?: boolean
  /** If true, will set overflowY to scroll - useful for modals with lots of content */
  allowContentToScroll?: boolean
  testId?: string
  titleTestId?: string
  id?: string
}

function useStyles({
  width,
  fullPage,
  hideCloseIcon,
}: Pick<ModalProps, 'width' | 'fullPage' | 'hideCloseIcon'>) {
  const theme = useTheme()

  return {
    root: css({
      background: addAlphaToHex(theme.colors.systemGrayscale70, 0.3),
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // We ideally would use IDS layers here, but in order to interact nicely with our legacy components we need to
      // use our custom layers object for now.
      zIndex: layers[10],
    }),
    modal: css({
      ...elevation.high.shadow,
      boxSizing: 'border-box',
      width,
      height: fullPage ? '100%' : 'auto',
      maxWidth: `calc(100vw - ${spacing.s24 * 2}px)`,
      maxHeight: fullPage ? `calc(100vh - ${spacing.s24 * 2}px)` : 'auto',
      background: theme.colors.systemGrayscale00,
      borderRadius: theme.radius.r12,
      padding: spacing.s24,
      color: theme.colors.systemGrayscale70,
    }),
    scrollableModal: css({
      borderRadius: 'unset',
      maxHeight: '95vh',
      maxWidth: '95vw',
      overflowY: 'scroll',
    }),
    title: css({
      ...theme.typography.bodyLarge1,
      flex: 1,
      paddingRight: hideCloseIcon ? 0 : CLOSE_ICON_SIZE,
      textAlign: 'center',
    }),
    header: css({
      display: 'flex',
      alignItems: 'center',
      padding: `${spacing.s8}px 0`,
    }),
    closeIcon: css({
      flex: '0 0 auto',
    }),
    content: css({
      ...theme.typography.bodyMedium2,
      marginTop: spacing.s16,
      height: fullPage ? '90%' : 'auto',
    }),
  }
}

/** @deprecated Use Modal from 'components/ids-ads' instead */
export default function Modal({
  children,
  onClose,
  title,
  width = 'auto',
  fullPage = false,
  show = true,
  hideCloseIcon = false,
  allowClose = true,
  allowContentToScroll = false,
  testId,
  titleTestId,
  id,
}: ModalProps) {
  const { genericFormatMessage, formatMessage } = useIntl()
  const styles = useStyles({ width, fullPage, hideCloseIcon })

  const [dialogTitleId] = useState(() => uniqueId('dialogTitle-'))
  const modalRef = useRef<HTMLDivElement>(null)

  useModalCloseHandlers({ ref: modalRef, closeModal: onClose })

  if (!show) return null

  return (
    <Portal>
      <div css={styles.root} id={id} data-testid={testId}>
        <div
          css={[styles.modal, allowContentToScroll && styles.scrollableModal]}
          ref={allowClose ? modalRef : null}
          role="dialog"
          aria-labelledby={dialogTitleId}
        >
          {title && (
            <div css={styles.header}>
              {hideCloseIcon || !allowClose || (
                <div css={styles.closeIcon}>
                  <IconButtonInline
                    icon={CloseIcon}
                    color="systemGrayscale70"
                    accessibleLabel={formatMessage({ id: 'common.close' })}
                    onClick={onClose}
                    size={CLOSE_ICON_SIZE}
                    data-testid="modal-close"
                  />
                </div>
              )}
              <h2 css={styles.title} id={dialogTitleId} data-testid={titleTestId}>
                {genericFormatMessage(title)}
              </h2>
            </div>
          )}
          <div css={styles.content}>{children}</div>
        </div>
      </div>
      <Global
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
    </Portal>
  )
}
