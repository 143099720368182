import { AccountFeatures } from 'common/accountFeatures'
import { FlipperIdType } from 'common/types'
import {
  AuthCtx,
  hasAnyAccountTypes,
  hasAnyCapability,
  hasAnyFeatureCapability,
  isFlipperEnabled,
  useAuthContext,
  useCapabilitiesContext,
} from 'context'
import { GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes } from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'
import { Capabilities } from 'service/types/capabilities'
import { SupportedCountryId } from '../service/auth'

interface CheckFlippersParams {
  flipper?: FlipperIdType
  disableFlipper?: FlipperIdType
}

export function checkFlippers(
  authContext: AuthCtx,
  { flipper, disableFlipper }: CheckFlippersParams
) {
  // If a flipper is provided check that the flipper is enabled for the user
  if (flipper && !isFlipperEnabled(authContext, flipper)) {
    return false
  }

  // If a disableFlipper is provided, check that this flipper is not enabled for the user
  if (disableFlipper && isFlipperEnabled(authContext, disableFlipper)) {
    return false
  }

  return true
}

export interface CheckCapabilitiesParams {
  capabilities?: string[] | string
  featureCapabilities?: AccountFeatures[] | AccountFeatures
}

export function checkCapabilities(
  capabilitiesContext: Capabilities,
  { capabilities, featureCapabilities }: CheckCapabilitiesParams
) {
  if (featureCapabilities) {
    const featureCapabilitiesArray = Array.isArray(featureCapabilities)
      ? featureCapabilities
      : [featureCapabilities]
    if (!hasAnyFeatureCapability(capabilitiesContext, featureCapabilitiesArray)) {
      return false
    }
  }

  // If capabilities are provided check that the user has at least one of the capabilities
  if (capabilities) {
    const capabilitiesArray = Array.isArray(capabilities) ? capabilities : [capabilities]
    if (!hasAnyCapability(capabilitiesContext, capabilitiesArray)) {
      return false
    }
  }

  return true
}

export function checkCountry(
  authContext: AuthCtx,
  { disableByCountries }: { disableByCountries?: SupportedCountryId[] }
) {
  if (
    authContext?.user?.currentAccount?.countryId &&
    disableByCountries?.includes(authContext.user.currentAccount.countryId)
  ) {
    return false
  }

  return true
}

interface CheckAccountTypeParams {
  disableByAccountTypes?: AccountTypes[]
}

export function checkAccountType(
  authContext: AuthCtx,
  { disableByAccountTypes }: CheckAccountTypeParams
) {
  if (disableByAccountTypes && hasAnyAccountTypes(authContext, disableByAccountTypes)) {
    return false
  }

  return true
}

export type IsUserAuthorizedParams = CheckFlippersParams &
  CheckAccountTypeParams &
  CheckCapabilitiesParams & {
    disable?: boolean
    disableByCountries?: SupportedCountryId[]
  }

export function isUserAuthorized(
  authContext: AuthCtx,
  capabilitiesContext: Capabilities,
  {
    capabilities,
    featureCapabilities,
    disableByAccountTypes,
    disable,
    flipper,
    disableFlipper,
    disableByCountries,
  }: IsUserAuthorizedParams
) {
  // If the user isn't logged in they can't access any restricted contents
  if (!authContext.authed) return false

  if (disable) {
    return false
  }

  return (
    checkFlippers(authContext, { flipper, disableFlipper }) &&
    checkAccountType(authContext, { disableByAccountTypes }) &&
    checkCountry(authContext, { disableByCountries }) &&
    checkCapabilities(capabilitiesContext, { capabilities, featureCapabilities })
  )
}

export const useIsUserAuthorized = (params: IsUserAuthorizedParams) => {
  const context = useAuthContext()
  const capabilitiesContext = useCapabilitiesContext()

  return isUserAuthorized(context, capabilitiesContext, params)
}
