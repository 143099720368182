import { combineStyles, spacing } from '@instacart/ids-core'
import {
  Hovercard as BaseHovercard,
  HovercardProps as BaseHovercardProps,
  HovercardStateProps,
  useHovercardState as useBaseHovercardState,
} from 'ariakit/hovercard'
import { TooltipContent } from 'components/ids-ads/molecules/tooltips/TooltipContent'
import { useTooltipStyles } from 'components/ids-ads/molecules/tooltips/utils/hooks'
import {
  TOOLTIP_TRANSITION_TIME_MS,
  TooltipStyles,
} from 'components/ids-ads/molecules/tooltips/utils/types'

export {
  HovercardAnchor,
  HovercardAnchorProps,
  HovercardArrow,
  HovercardState,
  HovercardStateProps,
} from 'ariakit/hovercard'

export const useHovercardState = ({
  animated = TOOLTIP_TRANSITION_TIME_MS,
  gutter = spacing.s8,
  placement = 'top',
  preventOverflow = true,
  timeout = 0,
  ...rest
}: HovercardStateProps = {}) =>
  useBaseHovercardState({
    animated,
    gutter,
    placement,
    preventOverflow,
    timeout,
    ...rest,
  })

export type HovercardProps = BaseHovercardProps & TooltipStyles

export function Hovercard({ styles, children, ...props }: HovercardProps) {
  const baseStyles = useTooltipStyles()
  const { tooltip, content } = combineStyles(baseStyles, styles)

  return (
    <BaseHovercard css={tooltip} {...props}>
      <TooltipContent styles={content}>{children}</TooltipContent>
    </BaseHovercard>
  )
}
