import { spacing } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { PrimaryButton } from 'components/ids-ads'
import LoginButton from 'components/Login/components/LoginButton'
import { SIGNUP_PATH } from '../utils/constants'
import { getImageUrl, useAdsLogoUrl } from '../utils/helpers'
import breakPoints from './breakPoints'

const styles: { [key: string]: CSSProperties } = {
  header: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#FFF',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing.s16}px ${spacing.s24}px`,
  },
  logo: {
    height: '30px',
    [breakPoints.mobile]: {
      display: 'none',
    },
  },
  logoMobile: {
    display: 'none',
    height: '30px',
    [breakPoints.mobile]: {
      display: 'inline',
    },
  },
  startNow: {
    height: '48px',
    marginLeft: `${spacing.s16}px`,
    display: 'inline',
    borderRadius: '4px',
    padding: `0 ${spacing.s24}px`,
    fontSize: '18px',
    [breakPoints.mobile]: {
      fontSize: '16px',
    },
  },
}

interface HeaderProps {
  heroVisible: boolean
  loginButtonVisible: boolean
}

const Header = ({ heroVisible, loginButtonVisible }: HeaderProps) => {
  const intl = useIntl()
  const adsLogoUrl = useAdsLogoUrl()

  return (
    <nav>
      <div style={styles.header}>
        <img
          src={adsLogoUrl}
          alt={intl.formatMessage({ id: 'landing.header.imgAlt' })}
          style={styles.logo}
        />
        <img
          src={
            heroVisible ? adsLogoUrl : `${getImageUrl('/images/logos/instacart-carrot-half.svg')}`
          }
          alt={intl.formatMessage({ id: 'landing.header.imgAlt' })}
          style={styles.logoMobile}
        />
        <div>
          {loginButtonVisible && <LoginButton legacyStyle />}
          {!heroVisible && (
            <PrimaryButton
              style={styles.startNow}
              onClick={() => window.location.assign(SIGNUP_PATH)}
            >
              <FormattedMessage id="common.startNow" />
            </PrimaryButton>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Radium(Header)
