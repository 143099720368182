import { createContext } from 'react'

export interface AdBlockAlertContext {
  adBlockDetected: boolean
  bannerHeight: number
  setBannerHeight(height: number): void
}

function notImplemented(description: string) {
  return () => {
    throw new Error(
      `${description} is not implemented. Ensure this component is rendered under a AdBlockAlertProvider.`
    )
  }
}

export const initialAdBlockAlertContext: AdBlockAlertContext = {
  adBlockDetected: false,
  bannerHeight: 0,
  setBannerHeight: notImplemented('setBannerHeight'),
}

export const AdBlockAlertContext = createContext(initialAdBlockAlertContext)
