import moment, { Moment } from 'moment'
import { DateRange } from '../components/frappuccino/types'
import { getPSTTimeOffset } from '../components/organisms/AnalyticsChart/utils/getPSTTimeOffset'
import { WeekStartEnum } from '../pages/Insight/common.enums'

export const RANGE_FORMAT = 'YYYY-MM-DD'
export const DATE_PICKER_DISPLAY_FORMAT = 'MM  /  DD  /  YYYY'
export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY'
export const DATE_TIME_DISPLAY_FORMAT = 'MM/DD/YYYY HH:mma'
export const SHORT_DATE_FORMAT = 'MM/DD/YY'
export const NATURAL_DATE_FORMAT = 'MMMM Do'
export const NATURAL_DATE_WITH_YEAR_FORMAT = 'MMMM Do YYYY'
export const MONTH_DAY_WITH_YEAR_FORMAT = 'MMM D YYYY'

export function getDateRange(dateRangeOption: string, fromDate?: Date | string) {
  const now = moment(fromDate || new Date()).format(RANGE_FORMAT)

  switch (dateRangeOption.toLowerCase()) {
    case 'since yesterday': {
      const yesterday = moment(fromDate).subtract(1, 'day').format(RANGE_FORMAT)
      return { startDate: yesterday, endDate: now }
    }
    case 'last 7 days': {
      const lastWeek = moment(fromDate).subtract(1, 'week').format(RANGE_FORMAT)
      return { startDate: lastWeek, endDate: now }
    }
    case 'last 30 days': {
      const lastMonth = moment(fromDate).subtract(1, 'month').format(RANGE_FORMAT)
      return { startDate: lastMonth, endDate: now }
    }
    case 'last 3 months': {
      const threeMonthsAgo = moment(fromDate).subtract(3, 'month').format(RANGE_FORMAT)
      return { startDate: threeMonthsAgo, endDate: now }
    }
    case 'last 12 months': {
      const oneYearAgo = moment(fromDate).subtract(12, 'month').format(RANGE_FORMAT)
      return { startDate: oneYearAgo, endDate: now }
    }
    case 'last 24 months': {
      const twoYearsAgo = moment(fromDate).subtract(24, 'month').format(RANGE_FORMAT)
      return { startDate: twoYearsAgo, endDate: now }
    }
    case 'lifetime': {
      return null
    }
    default: {
      return null
    }
  }
}

function getTo(defaultTo: Date, enableFutureDays?: boolean) {
  return enableFutureDays ? undefined : defaultTo
}

export function getPickerDateRange({
  dateRangeOption,
  fromDate,
  weekStart = WeekStartEnum.Sunday,
  enableFutureDays = false,
}: {
  dateRangeOption: string
  fromDate?: Date | string
  weekStart?: WeekStartEnum
  enableFutureDays?: boolean
}) {
  if (weekStart === WeekStartEnum.Monday) {
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    })
  } else {
    moment.updateLocale('en', {
      week: {
        dow: 0,
      },
    })
  }

  const now = moment(fromDate || new Date()).toDate()

  const defaultResult = { from: undefined, to: undefined }

  if (!dateRangeOption) {
    return defaultResult
  }

  switch (dateRangeOption.toLowerCase()) {
    case 'since yesterday': {
      const yesterday = moment(fromDate).subtract(1, 'day').toDate()
      return { from: yesterday, to: getTo(now, enableFutureDays) }
    }
    case 'last 7 days': {
      const from = moment(fromDate).subtract(6, 'day').toDate()
      const to = moment(fromDate).toDate()
      return { from, to: getTo(to, enableFutureDays) }
    }
    case 'last 30 days': {
      const from = moment(fromDate).subtract(29, 'day').toDate()
      const to = moment(fromDate).toDate()
      return { from, to: getTo(to, enableFutureDays) }
    }
    case 'last week': {
      const from = moment(fromDate).subtract(1, 'week').startOf('week').toDate()
      const to = moment(fromDate).subtract(1, 'week').endOf('week').toDate()
      return { from, to: getTo(to, enableFutureDays) }
    }
    case 'last month': {
      const from = moment(fromDate).subtract(1, 'month').startOf('month').toDate()
      const to = moment(fromDate).subtract(1, 'month').endOf('month').toDate()
      return { from, to: getTo(to, enableFutureDays) }
    }
    case 'last 3 months': {
      const threeMonthsAgo = moment(fromDate).subtract(3, 'month').toDate()
      return { from: threeMonthsAgo, to: getTo(now, enableFutureDays) }
    }
    case 'last 12 months': {
      const oneYearAgo = moment(fromDate).subtract(12, 'month').toDate()
      return { from: oneYearAgo, to: getTo(now, enableFutureDays) }
    }
    case 'last 24 months': {
      const twoYearsAgo = moment(fromDate).subtract(24, 'month').toDate()
      return { from: twoYearsAgo, to: now }
    }
    case 'last quarter': {
      const from = moment(fromDate).subtract(1, 'quarter').startOf('quarter').toDate()
      const to = moment(fromDate).subtract(1, 'quarter').endOf('quarter').toDate()
      return { from, to }
    }
    case 'last year': {
      const from = moment(fromDate).subtract(1, 'year').startOf('year').toDate()
      const to = moment(fromDate).subtract(1, 'year').endOf('year').toDate()
      return { from, to }
    }
    case 'month to date': {
      const from = moment(fromDate).startOf('month').toDate()
      const to = moment(fromDate).toDate()
      return { from, to }
    }
    case 'quarter to date': {
      const from = moment(fromDate).startOf('quarter').toDate()
      const to = moment(fromDate).toDate()
      return { from, to }
    }
    case 'year to date': {
      const from = moment(fromDate).startOf('year').toDate()
      const to = moment(fromDate).toDate()
      return { from, to }
    }
    case 'lifetime': {
      return defaultResult
    }
    default: {
      return defaultResult
    }
  }
}

export function formatDate(date?: Date | string, format: string = RANGE_FORMAT) {
  return date ? moment(date).format(format) : ''
}

export function formatMoment(date?: Moment, format: string = RANGE_FORMAT) {
  return date ? date.format(format) : ''
}

export function todaysLocalDateString() {
  return moment().format(RANGE_FORMAT)
}

export function formatPSTNumberToDate(date: number, format: string = RANGE_FORMAT) {
  const pstTimezoneOffset = getPSTTimeOffset()
  // minus offset because we are converting from number to string instead of the other way around
  return formatDate(new Date(date - pstTimezoneOffset), format)
}

export function getFormattedNowDate(format: string = RANGE_FORMAT, offset?: number) {
  const now = moment(new Date()).format(RANGE_FORMAT)
  const date = moment(now)

  if (offset) date.add(offset, 'd')

  return date.format(format)
}

export function convertToSeconds(amount: number, unit?: Parameters<typeof moment.duration>[1]) {
  return amount ? moment.duration(amount, unit).asSeconds() : 0
}

export function datesBetween(startDate: Date, endDate: Date) {
  const dates: Date[] = []
  let currentMoment = moment(startDate)
  const endMoment = moment(endDate)
  while (currentMoment <= endMoment) {
    dates.push(currentMoment.toDate())
    currentMoment = currentMoment.add(1, 'day')
  }
  return dates
}

export function getFirstDayOfMonth(date?: Date | string, monthOffset = 0) {
  return moment(date).add(monthOffset, 'M').startOf('month').toDate()
}

export function getDateWithOffset(date?: Date | string, dateOffset = 0) {
  return moment(date).add(dateOffset, 'd').toDate()
}

export const getDatesInDateRange = (dateRange: DateRange) => {
  const dates: string[] = []
  const currentDate = new Date(dateRange.startDate)
  const endDate = new Date(dateRange.endDate)
  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split('T')[0])
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

export const isoWeekdayMap = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}
