// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets } from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets'
import {
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSON,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsToJSON,
} from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBlockHeroBannerDisplay
 */
export interface PutDisplayCampaignsIdParamBlockHeroBannerDisplay {
  /**
   * id of the block
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplay
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplay
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplay
   */
  type: PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum
  /**
   *
   * @type {PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplay
   */
  assets: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
}

/**
 * @export
 */
export const PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum = {
  BlockHeroBannerV1: 'block_hero_banner.v1',
} as const
export type PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum =
  typeof PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum[keyof typeof PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum]

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBlockHeroBannerDisplay interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBlockHeroBannerDisplay(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSON(
  json: any
): PutDisplayCampaignsIdParamBlockHeroBannerDisplay {
  return PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBlockHeroBannerDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSON(json['assets']),
  }
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayToJSON(
  value?: PutDisplayCampaignsIdParamBlockHeroBannerDisplay | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsToJSON(value.assets),
  }
}
