import { useEffect, useState, useCallback } from 'react'
import { usePropRef } from 'common/usePropRef'
import { ClientError } from 'common/utils'

export type Unwrap<T> = T extends Promise<infer U> ? U : T

export type UseApiFnOptions<T> = {
  throwError?: boolean
  afterSuccess?: (response: T) => void
  afterFailure?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiResponse<T extends (...args: any) => Promise<any>> = ReturnType<T> extends Promise<
  infer U
>
  ? U
  : ReturnType<T>

/**
 * A null argument passed to the fn parameter signals that we're
 * intentionally skipping calling our async function. This is useful
 * in cases when we're waiting for user input, interaction, etc.
 */
export type CallbackFunction<T> = (() => Promise<T>) | null

/**
 * Generic hook to fetch data.
 */
export const useApiFn = <T, E extends Error = ClientError>(
  fn: CallbackFunction<T>,
  options: UseApiFnOptions<T> = {}
) => {
  const { throwError, afterSuccess, afterFailure } = options
  const afterSuccessRef = usePropRef(afterSuccess)
  const afterFailureRef = usePropRef(afterFailure)

  const [loading, setLoading] = useState(() => !!fn)
  const [error, setError] = useState<E | null>(null)
  const [response, setResponse] = useState<T | null>(null)

  useEffect(() => {
    if (!fn) return () => {}

    const controller = new AbortController()

    const fetch = async (signal: AbortSignal) => {
      setError(null)
      setLoading(true)
      try {
        const apiResponse = await fn()

        if (signal.aborted) return

        setResponse(apiResponse)

        afterSuccessRef.current?.(apiResponse)
      } catch (err) {
        if (signal.aborted) return

        if (throwError) throw err

        setError(err as E)

        afterFailureRef.current?.()
      } finally {
        if (!signal.aborted) setLoading(false)
      }
    }

    fetch(controller.signal)

    return () => controller.abort()
  }, [fn, afterSuccessRef, afterFailureRef, throwError])

  return { loading, response, error }
}

interface ApiCallStateFields<Response> {
  loading: boolean
  error: ClientError | undefined
  response: Response | undefined
}
interface ApiCallStateSetters<Response> {
  setLoading: (loading: boolean) => void
  setError: (error: ClientError | undefined) => void
  setResponse: (response: Response | undefined) => void
}
type ApiCallState<Response> = [
  (fn: () => Promise<Response>) => Promise<Response | undefined>,
  ApiCallStateFields<Response>,
  ApiCallStateSetters<Response>
]

/**
 * If you need to fetch data once on component render, use the `useApiFn` hook defined above.
 * If you need to fetch data based on an event (e.g. button click, input blur), you can use this hook to reduce
 * the boilerplate code in your component. It returns `callApi` method that you can use to make calls to the backend.
 * It sets loading, error, and response states automatically, which can be directly consumed in the component
 */
export function useApiCaller<Response>(): ApiCallState<Response> {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ClientError | undefined>(undefined)
  const [response, setResponse] = useState<Response | undefined>(undefined)

  const callApi = useCallback(
    async (fn: () => Promise<Response>): Promise<Response | undefined> => {
      setLoading(true)
      try {
        const apiResponse = await fn()
        setResponse(apiResponse)
        return apiResponse
      } catch (err) {
        setError(err as ClientError)
      } finally {
        setLoading(false)
      }
      return undefined
    },
    [setLoading, setError, setResponse]
  )

  return [
    callApi,
    {
      loading,
      error,
      response,
    },
    {
      setLoading,
      setError,
      setResponse,
    },
  ]
}

// ignore because it's a type param
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncCallbackState<Result, Params extends any[]> = [
  (...args: Params) => void,
  {
    loading: boolean
    result?: Result
    error?: Error
  }
]

// ignore because it's a type param
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAsyncCallback<Result, Params extends any[]>(
  fn: (...args: Params) => Promise<Result>,
  deps: React.DependencyList
): AsyncCallbackState<Result, Params> {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [result, setResult] = useState<Result | undefined>(undefined)

  const memoedCb = useCallback((...args: Params) => {
    setLoading(true)
    setError(undefined)
    setResult(undefined)
    ;(async () => {
      // this ensures we've returned with loading = true at least once even if fn isn't actually async
      await new Promise(resolve => setTimeout(resolve, 0))
      let res
      try {
        res = await fn(...args)
      } catch (err) {
        setError(err as Error)
      } finally {
        setResult(res)
        setLoading(false)
      }
    })()
    // ignore because we're getting the CB from the parent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return [memoedCb, { loading, result, error }]
}
