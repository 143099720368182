import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { spacing } from '@instacart/ids-core'
import { ComponentProps } from 'react'
import withTheme, { WithThemeProps } from 'common/ids/withTheme'
import toPx from 'common/toPx'

const TRANSITION_DURATION_MS = 100

const readOnlyOverrides = {
  '&:read-only:not(:disabled)': {
    paddingLeft: 0,
    boxShadow: 'none',
    borderRadius: 0,
    background: 'none',
  },
}

const TextArea = withTheme(
  styled.textarea(
    ({ theme, invalid, compact }: WithThemeProps & { invalid?: boolean; compact?: boolean }) =>
      css({
        border: 'none',
        WebkitAppearance: 'none',
        transition: `box-shadow ${TRANSITION_DURATION_MS}ms ease-in-out`,
        display: 'block',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        background: invalid ? theme.colors.systemDetrimentalLight : theme.colors.systemGrayscale00,
        boxShadow: `0 0 0 1px ${
          invalid ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale30
        } inset`,
        borderRadius: compact ? 6 : theme.radius.r8,
        padding: compact
          ? toPx`${spacing.s4} ${spacing.s8} ${spacing.s4 + 2}`
          : toPx`${spacing.s8} ${spacing.s12} ${spacing.s8 + 2}`,
        color: theme.colors.systemGrayscale70,
        resize: 'vertical',
        ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
        '&::-webkit-resizer': {
          display: 'none',
        },
        '&::placeholder': {
          color: theme.colors.systemGrayscale50,
          // Safari tries to explicitly set the height and line-height on the placeholder so we override
          // it here to match the input text. Otherwise the text appears to jump up when the user starts typing.
          height: 'auto',
          ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
        },
        '&:hover': {
          boxShadow: `0 0 0 1px ${
            invalid ? theme.colors.systemDetrimentalDark : theme.colors.systemGrayscale50
          } inset`,
        },
        '&:focus': {
          outline: 'none',
          boxShadow: `0 0 0 2px ${
            invalid ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale70
          } inset`,
        },
        '&:disabled': {
          boxShadow: `0 0 0 1px ${theme.colors.systemGrayscale20} inset`,
          background: theme.colors.systemGrayscale10,
          color: theme.colors.systemGrayscale50,
          '&::placeholder': {
            visibility: 'hidden',
          },
        },
        ...readOnlyOverrides,
      })
  )
)

export default TextArea

export type TextAreaProps = ComponentProps<typeof TextArea>
