import { css } from '@emotion/react'
import { HTMLProps, useEffect, useRef, useState } from 'react'
import {
  TooltipReference,
  Tooltip,
  useTooltipState,
  TooltipInitialState,
} from 'components/ids-ads/molecules'

const useStyles = (lines: number) => {
  return {
    text: css({
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: lines,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
    }),
    tooltipContent: css({
      overflowWrap: 'anywhere',
    }),
  }
}

export interface TruncateTextWithTooltipProps extends HTMLProps<HTMLDivElement> {
  lines?: number
  tooltipPlacement?: TooltipInitialState['placement']
  useWidth?: boolean
  testId?: string
}

export function TruncateTextWithTooltip({
  lines = 1,
  tooltipPlacement = 'right-start',
  useWidth = false,
  children,
  testId,
  ...props
}: TruncateTextWithTooltipProps) {
  const styles = useStyles(lines)
  const tooltipState = useTooltipState({ placement: tooltipPlacement })

  const divRef = useRef<HTMLDivElement>(null)
  const [allowTooltip, setAllowTooltip] = useState(false)

  useEffect(() => {
    if (divRef.current) {
      const scroll = useWidth ? divRef.current.scrollWidth : divRef.current.scrollHeight
      const offset = useWidth ? divRef.current.offsetWidth : divRef.current.offsetHeight

      // Should show tooltip only when text is truncated
      if (scroll > offset) {
        setAllowTooltip(true)
      }
    }
  }, [useWidth, setAllowTooltip])

  const TruncateText = () => (
    <div data-testid={testId} css={styles.text} {...props} ref={divRef}>
      {children}
    </div>
  )

  return allowTooltip ? (
    <>
      <TooltipReference {...tooltipState}>
        <TruncateText />
      </TooltipReference>
      <Tooltip {...tooltipState}>
        <div css={styles.tooltipContent}>{children}</div>
      </Tooltip>
    </>
  ) : (
    <TruncateText />
  )
}
