import { getExchangeConfig } from 'common/common-definitions/exchangeConfig'
import {
  useAuthContext,
  AuthCtx,
  isProviAccount,
  getExchangeName,
  isCanadianAccount,
} from 'context'
import { getEnv } from '../common/utils'

const spMinBid = (auth: AuthCtx) => {
  return getExchangeConfig(getExchangeName(auth)).featureOverrides.sponsoredProductMinBid()
}

export function useSpMinBid() {
  return spMinBid(useAuthContext())
}

const spMaxBid = (AuthContext: AuthCtx) => {
  if (isProviAccount(AuthContext)) {
    return undefined
  }

  if (isCanadianAccount(AuthContext)) {
    return 20.0 // Same as in AdGroupBid model
  }

  // Default max bid of 20 dollars if value is not set
  return parseInt(getEnv('MAX_CPC_BID') || 20, 10)
}

export function useSpMaxBid() {
  return spMaxBid(useAuthContext())
}
