// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValues } from './GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValues'
import {
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValuesFromJSON,
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValuesFromJSONTyped,
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValuesToJSON,
} from './GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValues'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDisplayAdGroupFieldDecisions
 */
export interface GetVideoAdGroupsParamDisplayAdGroupFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamDisplayAdGroupFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValues; }}
   * @memberof GetVideoAdGroupsParamDisplayAdGroupFieldDecisions
   */
  keywords?: { [key: string]: GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValues }
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDisplayAdGroupFieldDecisions interface.
 */
export function instanceOfGetVideoAdGroupsParamDisplayAdGroupFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamDisplayAdGroupFieldDecisions {
  return GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDisplayAdGroupFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    keywords: !exists(json, 'keywords')
      ? undefined
      : mapValues(
          json['keywords'],
          GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValuesFromJSON
        ),
  }
}

export function GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamDisplayAdGroupFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    keywords:
      value.keywords === undefined
        ? undefined
        : mapValues(
            value.keywords,
            GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsKeywordsValuesToJSON
          ),
  }
}
