// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayCampaignsIdParamAssetFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamAssetFieldDecisions,
  PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamAssetFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamAssetFieldDecisions'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamBrandPageBlockFieldDecisions,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions'
import {
  PutDisplayCampaignsIdParamBrandPageFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamBrandPageFieldDecisions,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamBrandPageFieldDecisions'
import {
  PutDisplayCampaignsIdParamCreativeFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamCreativeFieldDecisions,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamCreativeFieldDecisions'
import {
  PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamDisplayAdGroupFieldDecisions,
  PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisions'
import {
  PutDisplayCampaignsIdParamProductAssetFieldDecisions,
  instanceOfPutDisplayCampaignsIdParamProductAssetFieldDecisions,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamProductAssetFieldDecisions'

/**
 * @type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & PutDisplayCampaignsIdParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & PutDisplayCampaignsIdParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & PutDisplayCampaignsIdParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & PutDisplayCampaignsIdParamProductAssetFieldDecisions)

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutDisplayCampaignsIdParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PutDisplayCampaignsIdParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PutDisplayCampaignsIdParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PutDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
