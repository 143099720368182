import { useContext } from 'react'
import {
  EXTERNAL_MARKETPLACE_ALERT_HEIGHT,
  shouldShowExternalMarketplaceAlert,
} from 'components/ExternalMarketplaceAlert/utils'
import { useShowMfaAdoptionModal } from 'components/MultiFactorAuth/utils/multiFactorAuth.hooks'
import { AdBlockAlertContext } from 'context/adBlockAlert'
import { useAuthContext } from 'context/auth'
import { SystemAlertContext } from 'context/systemAlert'

export const useHeaderHeight = () => {
  const auth = useAuthContext()
  const { user } = auth
  const showMfaAdoptionModal = useShowMfaAdoptionModal()
  const { systemAlert, bannerHeight: systemAlertBannerHeight } = useContext(SystemAlertContext)
  const { adBlockDetected, bannerHeight: adBlockAlertBannerHeight } =
    useContext(AdBlockAlertContext)

  let pageHeaderHeight = 0

  if (!user?.currentAccount?.id || showMfaAdoptionModal) {
    return pageHeaderHeight
  }

  if (shouldShowExternalMarketplaceAlert(auth)) {
    pageHeaderHeight += EXTERNAL_MARKETPLACE_ALERT_HEIGHT
  }

  if (adBlockDetected) {
    pageHeaderHeight += adBlockAlertBannerHeight
  }

  if (systemAlert) {
    pageHeaderHeight += systemAlertBannerHeight
  }

  return pageHeaderHeight
}
