interface FormattedPercentageProps {
  children: number
}

const FormattedPercentage: React.FunctionComponent<
  React.PropsWithChildren<FormattedPercentageProps>
> = ({ children }) => {
  const percentage = Number(children).toFixed(1)
  return <>{`${percentage}%`}</>
}

export default FormattedPercentage
