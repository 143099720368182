import { css } from '@emotion/react'
import { Form, Formik, FormikConfig, FormikErrors, FormikValues } from 'formik'
import { ChangeEvent, FocusEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { ClientError } from 'common/utils'
import { Modal, ModalButtons, ModalContent, useModalState } from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'

export type DuplicateModalBaseChildrenPassedProps<T = { [key: string]: string }> = {
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => void
  onChange: (e?: ChangeEvent) => void
  setErrors: (errors: FormikErrors<T>) => void
  errors: FormikErrors<T>
  values: FormikValues
  serverErrors: ClientError | undefined
}

type DuplicateModalBaseProps<T> = {
  title: GenericMessageDescriptor
  onCancelClick: () => void
  onConfirmClick: (opts: T) => Promise<void>
  modalState: ReturnType<typeof useModalState>
  children: (helpers: DuplicateModalBaseChildrenPassedProps<T>) => ReactNode
  validate?: FormikConfig<T>['validate']
  initialValues: T
}

const useStyles = () => {
  return {
    form: css({
      width: '100%',
    }),
  }
}

function DuplicateModalBase<T extends FormikValues>({
  onCancelClick,
  onConfirmClick,
  modalState,
  children,
  validate,
  title,
  initialValues,
}: DuplicateModalBaseProps<T>) {
  const [serverErrors, setServerErrors] = useState<ClientError | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const mounted = useRef(false)
  const styles = useStyles()

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const confirm = async (opts: T) => {
    setLoading(true)

    await onConfirmClick(opts).catch(errors => {
      setServerErrors(errors)
    })
    if (mounted.current) {
      setLoading(false)
    }
  }

  return (
    <Modal
      allowClose={false}
      modalState={modalState}
      title={title}
      onClose={onCancelClick}
      width={800}
    >
      <Formik initialValues={initialValues} validate={validate} onSubmit={confirm}>
        {({ errors, values, handleBlur, handleChange, setErrors, ...rest }) => {
          const onBlur = (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => {
            handleBlur(e)
            setServerErrors(undefined)
          }

          const onChange = (e?: ChangeEvent) => {
            if (e) handleChange(e)
            setServerErrors(undefined)
          }

          return (
            <Form css={styles.form}>
              <ModalContent>
                <div data-testid="duplicate-modal-children-container">
                  {children({
                    serverErrors,
                    onBlur,
                    onChange,
                    errors,
                    values,
                    setErrors,
                    ...rest,
                  })}
                </div>
              </ModalContent>
              <ModalButtons
                onClose={onCancelClick}
                primaryButtonLabel="common.confirm"
                primaryButtonProps={{
                  type: 'submit',
                  disabled:
                    loading ||
                    !Object.keys(values).length ||
                    !!Object.keys(errors).length ||
                    !!serverErrors,
                }}
                primaryTestId="duplicate-modal-submit-button"
                secondaryTestId="duplicate-modal-cancel-button"
              />
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default DuplicateModalBase
