import { css } from '@emotion/react'
import {
  useTheme,
  Color,
  IssueIcon,
  InformationIcon,
  spacing,
  SVGIconProps,
} from '@instacart/ids-core'
import { ComponentType, CSSProperties } from 'react'
import { toPx, useIntl } from 'common'
import { genericMessageDescriptorExists, uc } from 'common/utils'
import { DefinitionText } from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'
import { StateMap, StateMapType, StateTooltipMap } from './StateMap'

export const DEFAULT_ICON_SIZE = 20

export type StatusPillProps = {
  color?: Color
  backgroundColor?: Color
  stateMap?: StateMapType
  tooltipContent?: JSX.Element
  tooltipMessageId?: GenericMessageDescriptor
  status?: string
  atRisk?: boolean
}

type StylesParams = {
  color?: CSSProperties['color']
  backgroundColor?: CSSProperties['backgroundColor']
  textTransform?: CSSProperties['textTransform']
}

const useStyles = (styles: StylesParams = {}, compact = false) => {
  const theme = useTheme()
  const { textTransform = 'uppercase', color, backgroundColor } = styles
  const compactStyles = compact
    ? {
        message: {
          fontSize: 10,
        },
        pill: {
          padding: toPx`${3} ${7}`,
          height: 16,
        },
      }
    : {}

  return {
    message: css({
      whiteSpace: 'nowrap',
      ...theme.typography.bodySmall1,
      ...compactStyles.message,
    }),
    pill: css({
      ...theme.typography.disclaimerMedium,
      display: 'inline-flex',
      padding: toPx`${2} ${8} ${3}`,
      borderRadius: 999,
      textTransform,
      marginRight: spacing.s4,
      color,
      background: backgroundColor,
      alignItems: 'center',
      gap: spacing.s4,
      ...compactStyles.pill,
    }),
    wrapper: css({
      display: 'flex',
      alignItems: 'center',
    }),
    anchor: {
      anchor: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
}

export type PillProps = StylesParams & {
  testId: string
  children: React.ReactNode
  icon?: ComponentType<Omit<SVGIconProps, 'component'>>
  compact?: boolean
}

export function Pill({ testId, children, icon, compact, ...stylesParams }: PillProps) {
  const styles = useStyles(stylesParams, compact)
  const Icon = icon

  return (
    <span data-testid="status-pill" css={styles.pill}>
      {Icon && <Icon size={16} fill={stylesParams.color} />}
      <div data-testid={testId} css={styles.message}>
        {children}
      </div>
    </span>
  )
}

export default function StatusPill({
  color,
  backgroundColor,
  status = 'UNKNOWN',
  stateMap = StateMap,
  tooltipContent,
  tooltipMessageId,
  atRisk,
}: StatusPillProps) {
  const intl = useIntl()
  const theme = useTheme()
  const { genericFormatMessage } = intl
  const ucStatus = uc(status)
  const mappedStatus = ucStatus in stateMap ? stateMap[ucStatus] : undefined
  const statusColor = mappedStatus ? mappedStatus.color : color
  const statusBackgroundColor = mappedStatus ? mappedStatus.backgroundColor : backgroundColor
  const styles = useStyles()

  const text = mappedStatus?.labelId || 'common.status.unknown' // If it exists in state map, use it. If not, status is unknown

  const Icon = atRisk ? IssueIcon : InformationIcon
  const iconColor = atRisk ? 'brandExpressRegular' : 'systemGrayscale50'
  const iconTestId = atRisk ? 'issue' : 'info'

  const defaultTooltip =
    ucStatus in StateTooltipMap ? genericFormatMessage(StateTooltipMap[ucStatus]) : tooltipContent

  const tooltip =
    tooltipMessageId && genericMessageDescriptorExists(tooltipMessageId, intl)
      ? genericFormatMessage(tooltipMessageId)
      : defaultTooltip

  const tooltipIcon = tooltip ? (
    <DefinitionText definitionContent={tooltip} styles={styles.anchor}>
      <Icon
        size={DEFAULT_ICON_SIZE}
        data-testid={`status-pill-${iconTestId}-icon`}
        color={iconColor}
      />
    </DefinitionText>
  ) : null

  return (
    <div css={styles.wrapper}>
      <Pill
        color={statusColor ? theme.colors[statusColor] : undefined}
        backgroundColor={statusBackgroundColor ? theme.colors[statusBackgroundColor] : undefined}
        testId={`status-pill-${status}`}
      >
        {genericFormatMessage(text)}
      </Pill>
      {tooltipIcon}
    </div>
  )
}
