import { UseStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

const styles: UseStylesOpts = {
  normalBackground: null,
  borderColor: null,
  textColor: 'systemGrayscale70',
  hoverBackground: 'systemGrayscale10',
  hoverBorderColor: 'systemGrayscale30',
  activeBackground: 'systemGrayscale20',
  activeBorderColor: 'systemGrayscale30',
}

export const BorderlessButton = makeButton('BorderlessButton', styles)
export const BorderlessLinkButton = makeLinkButton('BorderlessLinkButton', styles)
export const BorderlessAnchorButton = makeAnchorButton('BorderlessAnchorButton', styles)
