// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetPrototypeCampaignsParamBlockHeroBannerDisplay,
  instanceOfGetPrototypeCampaignsParamBlockHeroBannerDisplay,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayFromJSON,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayFromJSONTyped,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayToJSON,
} from './GetPrototypeCampaignsParamBlockHeroBannerDisplay'
import {
  GetPrototypeCampaignsParamBlockItemGridDisplay,
  instanceOfGetPrototypeCampaignsParamBlockItemGridDisplay,
  GetPrototypeCampaignsParamBlockItemGridDisplayFromJSON,
  GetPrototypeCampaignsParamBlockItemGridDisplayFromJSONTyped,
  GetPrototypeCampaignsParamBlockItemGridDisplayToJSON,
} from './GetPrototypeCampaignsParamBlockItemGridDisplay'

/**
 * @type GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks
 *
 * @export
 */
export type GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & GetPrototypeCampaignsParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & GetPrototypeCampaignsParamBlockItemGridDisplay)

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSONTyped(
    json,
    false
  )
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...GetPrototypeCampaignsParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...GetPrototypeCampaignsParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocksToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return GetPrototypeCampaignsParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return GetPrototypeCampaignsParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of GetPrototypeCampaignsParamDataAttributesAdGroupAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
