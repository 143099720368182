import { css } from '@emotion/react'
import { Text, AddIcon, useTheme } from '@instacart/ids-core'
import { FieldArray, useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { SecondaryButton } from 'components/ids-ads'
import {
  Condition,
  Subject,
  MAXIMUM_NUMBER_OF_SEGMENTS,
  Operator,
  SegmentType,
  getTargetingSegmentsFieldName,
} from '../constants'
import { TargetingRuleContext, TargetingSegmentContext } from '../hooks'
import { TargetingFormData, TargetingSegmentType } from '../types'
import TargetingSegment from './TargetingSegment'

const useStyles = () => {
  const theme = useTheme()
  return {
    newSegmentDivStyle: css({
      marginTop: 12,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-end',
    }),

    newSegmentButtonStyle: css({
      display: 'flex',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      backgroundColor: theme.colors.systemGrayscale00,
      border: `1px solid ${theme.colors.systemGrayscale30}`,
      borderRadius: 6,
      minWidth: 60,
    }),

    newSegmentTextStyle: {
      fontWeight: 600,
      marginLeft: 5,
    },

    emptySegmentsTextStyle: css({
      marginLeft: 5,
      marginTop: 16,
    }),
  }
}

export interface TargetingSegmentsProps {
  segmentType: SegmentType
  operator: Operator
}

export default function TargetingSegments({ segmentType, operator }: TargetingSegmentsProps) {
  const styles = useStyles()

  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const { values } = useFormikContext<TargetingFormData>()

  const segmentsFieldName = getTargetingSegmentsFieldName(segmentType)
  const segments: TargetingSegmentType[] = get(values, segmentsFieldName)

  if (!segments.length && (isReadOnly || isReviewing)) {
    return (
      <div css={styles.emptySegmentsTextStyle}>
        <FormattedMessage id="pages.displayProduct.common.targetingRule.segments.collapsed.noneSelected" />
      </div>
    )
  }

  return (
    <FieldArray name={segmentsFieldName}>
      {({ remove, push }) => {
        const newSegment = {
          key: segments.length && segments[segments.length - 1].key + 1,
          condition: Condition.POSITIVE,
          subject: Subject.CATEGORIES,
          timeline: '30',
          categories: [],
          brands: [],
          upcs: [],
        }
        return (
          <>
            {segments.map((segment, index) => (
              <TargetingSegmentContext.Provider
                key={segment.key}
                value={{
                  segmentType,
                  fieldsPrefix: `${segmentsFieldName}[${index}].`,
                }}
              >
                <TargetingSegment
                  operator={operator}
                  isFirstSegment={!index}
                  removeSegment={() => remove(index)}
                />
              </TargetingSegmentContext.Provider>
            ))}
            {!isReadOnly && !isReviewing && segments.length < MAXIMUM_NUMBER_OF_SEGMENTS && (
              <div css={styles.newSegmentDivStyle}>
                <SecondaryButton
                  type="button"
                  onClick={() => push(newSegment)}
                  css={styles.newSegmentButtonStyle}
                >
                  <AddIcon size={20} />
                  <Text typography="bodySmall2" style={styles.newSegmentTextStyle}>
                    <FormattedMessage
                      id={`pages.displayProduct.common.targetingRule.${
                        operator === Operator.OR ? 'expandAudience' : 'narrowAudience'
                      }`}
                    />
                  </Text>
                </SecondaryButton>
              </div>
            )}
          </>
        )
      }}
    </FieldArray>
  )
}
