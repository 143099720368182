// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayCampaignResponseDataAttributes } from './DisplayCampaignResponseDataAttributes'
import {
  DisplayCampaignResponseDataAttributesFromJSON,
  DisplayCampaignResponseDataAttributesFromJSONTyped,
  DisplayCampaignResponseDataAttributesToJSON,
} from './DisplayCampaignResponseDataAttributes'

/**
 *
 * @export
 * @interface DisplayCampaignResponseData
 */
export interface DisplayCampaignResponseData {
  /**
   * Campaign ID
   * @type {string}
   * @memberof DisplayCampaignResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DisplayCampaignResponseData
   */
  type: DisplayCampaignResponseDataTypeEnum
  /**
   *
   * @type {DisplayCampaignResponseDataAttributes}
   * @memberof DisplayCampaignResponseData
   */
  attributes: DisplayCampaignResponseDataAttributes
}

/**
 * @export
 */
export const DisplayCampaignResponseDataTypeEnum = {
  Campaign: 'campaign',
} as const
export type DisplayCampaignResponseDataTypeEnum =
  typeof DisplayCampaignResponseDataTypeEnum[keyof typeof DisplayCampaignResponseDataTypeEnum]

/**
 * Check if a given object implements the DisplayCampaignResponseData interface.
 */
export function instanceOfDisplayCampaignResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function DisplayCampaignResponseDataFromJSON(json: any): DisplayCampaignResponseData {
  return DisplayCampaignResponseDataFromJSONTyped(json, false)
}

export function DisplayCampaignResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayCampaignResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: DisplayCampaignResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function DisplayCampaignResponseDataToJSON(value?: DisplayCampaignResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: DisplayCampaignResponseDataAttributesToJSON(value.attributes),
  }
}
