// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabels } from './PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabels'
import {
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection
   */
  labels: Array<PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection
   */
  componentType: PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionComponentTypeEnum = {
  SeasonalTheme: 'SEASONAL_THEME',
  CustomCategory: 'CUSTOM_CATEGORY',
  BusinessUnit: 'BUSINESS_UNIT',
  BrandName: 'BRAND_NAME',
  SubBrandName: 'SUB_BRAND_NAME',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection {
  return PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageHierarchyLabelCollectionLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
