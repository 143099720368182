import { createContext, CSSProperties } from 'react'

export type SearchFilterStylesType = {
  icon: CSSProperties
  input: CSSProperties
}

interface SearchFilterContextType {
  styles: SearchFilterStylesType | undefined
  fieldName: string | undefined
}

const temporaryInitialValue: SearchFilterContextType = {
  styles: undefined,
  fieldName: undefined,
}

export const SearchFilterContext = createContext(temporaryInitialValue)
