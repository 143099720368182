// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktop } from './PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktop'
import {
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktop'
import type { PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobile } from './PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobile'
import {
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets
 */
export interface PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets
   */
  desktop: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets
   */
  mobile: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets {
  return PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktopFromJSON(
      json['desktop']
    ),
    mobile: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobileFromJSON(
      json['mobile']
    ),
  }
}

export function PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsToJSON(
  value?: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsDesktopToJSON(
      value.desktop
    ),
    mobile: PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
