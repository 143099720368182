// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollections } from './PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollections'
import {
  PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSON,
  PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSONTyped,
  PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollectionsToJSON,
} from './PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollections'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
 */
export interface ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid {
  /**
   * Collections
   * @type {Array<PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollections>}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
   */
  collections: Array<PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollections>
}

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'collections' in value

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid {
  return ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped(
    json,
    false
  )
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid {
  if (json === undefined || json === null) {
    return json
  }
  return {
    collections: (json['collections'] as Array<any>).map(
      PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSON
    ),
  }
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON(
  value?: ReservationAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    collections: (value.collections as Array<any>).map(
      PutReservationAdGroupsIdParamDataAttributesCreativeActionsMainCollectionGridCollectionsToJSON
    ),
  }
}
