// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostReportResponseData } from './PostReportResponseData'
import {
  PostReportResponseDataFromJSON,
  PostReportResponseDataFromJSONTyped,
  PostReportResponseDataToJSON,
} from './PostReportResponseData'
import type { PostReportResponseLinks } from './PostReportResponseLinks'
import {
  PostReportResponseLinksFromJSON,
  PostReportResponseLinksFromJSONTyped,
  PostReportResponseLinksToJSON,
} from './PostReportResponseLinks'
import type { PostReportResponseMeta } from './PostReportResponseMeta'
import {
  PostReportResponseMetaFromJSON,
  PostReportResponseMetaFromJSONTyped,
  PostReportResponseMetaToJSON,
} from './PostReportResponseMeta'

/**
 *
 * @export
 * @interface PostReportResponse
 */
export interface PostReportResponse {
  /**
   *
   * @type {PostReportResponseData}
   * @memberof PostReportResponse
   */
  data: PostReportResponseData
  /**
   *
   * @type {PostReportResponseMeta}
   * @memberof PostReportResponse
   */
  meta: PostReportResponseMeta
  /**
   *
   * @type {PostReportResponseLinks}
   * @memberof PostReportResponse
   */
  links: PostReportResponseLinks
}

/**
 * Check if a given object implements the PostReportResponse interface.
 */
export function instanceOfPostReportResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value
  isInstance = isInstance && 'links' in value

  return isInstance
}

export function PostReportResponseFromJSON(json: any): PostReportResponse {
  return PostReportResponseFromJSONTyped(json, false)
}

export function PostReportResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostReportResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: PostReportResponseDataFromJSON(json['data']),
    meta: PostReportResponseMetaFromJSON(json['meta']),
    links: PostReportResponseLinksFromJSON(json['links']),
  }
}

export function PostReportResponseToJSON(value?: PostReportResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: PostReportResponseDataToJSON(value.data),
    meta: PostReportResponseMetaToJSON(value.meta),
    links: PostReportResponseLinksToJSON(value.links),
  }
}
