import { getIn } from 'formik'
import { isObjectValueEmpty } from './utils'

export const areEveryFieldsMissing = <T>(values: T, fields: string[]) =>
  fields.every((fieldName: string) => {
    const value = getIn(values, fieldName)

    return isObjectValueEmpty(value)
  })

export const areSomeFieldsMissing = <T>(values: T, fields: string[] | undefined) =>
  fields?.some((fieldName: string) => {
    const value = getIn(values, fieldName)

    return isObjectValueEmpty(value)
  })

// If any of the conditionally required fields are filled,
// then all of the conditionally required fields need to be filled.
// Otherwise, we have fields missing.
export const areConditionallyRequiredFieldsMissing = <T>(values: T, fields: string[]) =>
  fields.some((fieldName: string) => {
    const value = getIn(values, fieldName)

    return isObjectValueEmpty(value)
  }) &&
  !fields.every((fieldName: string) => {
    const value = getIn(values, fieldName)

    return isObjectValueEmpty(value)
  })

export const areFormFieldsMissing = <T>(
  values: T,
  requiredFormFields: string[] | undefined,
  conditionallyRequiredFormFields: string[]
) =>
  areSomeFieldsMissing(values, requiredFormFields) ||
  areConditionallyRequiredFieldsMissing(values, conditionallyRequiredFormFields)
