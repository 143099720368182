import { IconButton, IconButtonProps } from '@instacart/ids-tooling'
import { Link, LinkProps } from 'react-router-dom'

export type IconLinkButtonProps = IconButtonProps & Pick<LinkProps, 'to' | 'href'>

/** @deprecated Use IconOnly BorderlessButton from 'components/ids-ads' instead */
export function IconLinkButton(props: IconLinkButtonProps) {
  const { disabled, to, href } = props

  if (disabled) return <IconButton {...props} disabled />

  return (
    <Link href={href} to={to}>
      <IconButton {...props} />
    </Link>
  )
}
