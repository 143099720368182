// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop } from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop'
import {
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop'
import type { PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile } from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile'
import {
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
 */
export interface PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
   */
  desktop: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
   */
  mobile: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  return PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSON(json['desktop']),
    mobile: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSON(json['mobile']),
  }
}

export function PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsToJSON(
  value?: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopToJSON(value.desktop),
    mobile: PutDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
