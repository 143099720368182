// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGrid } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGrid'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGridToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGrid'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGrid } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGrid'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGridFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGridFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGridToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGrid'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGrid } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGrid'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGridToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions
 */
export interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGrid}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions
   */
  mainItemGrid?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGrid}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions
   */
  mainProductGroupGrid?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGrid}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions
   */
  mainCollectionGrid?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions
   */
  bannerClick?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClick
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions {
  return ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
    main_product_group_grid:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickToJSON(
        value.bannerClick
      ),
  }
}
