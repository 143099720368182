// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogo } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogo'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogoToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogo'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImage } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImage'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImageFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImageFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImageToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImage'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobile } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobile'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobileFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobile'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWeb } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWeb'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWebFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWebToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWeb'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnail } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnail'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnailFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnailFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnailToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnail'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobile } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobile'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobile'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWeb } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWeb'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWebToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWeb'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobile } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobile'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobile'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWeb } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWeb'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWebToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWeb'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideo } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideo'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideoFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideoFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideoToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideo'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
 */
export interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWeb}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  topImageWeb?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobile}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  topImageMobile?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobile
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImage}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  heroImage?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImage
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogo}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  brandLogo?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogo
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWeb}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  imageWeb?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobile}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  imageMobile?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobile
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideo}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  video?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideo
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnail}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  thumbnail?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnail
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWeb}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  uvcImageWeb?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobile}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets
   */
  uvcImageMobile?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets {
  return ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    video: !exists(json, 'video')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    top_image_mobile:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    hero_image: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsHeroImageToJSON(
      value.heroImage
    ),
    brand_logo: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsBrandLogoToJSON(
      value.brandLogo
    ),
    image_web: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageWebToJSON(
      value.imageWeb
    ),
    image_mobile:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    video: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsThumbnailToJSON(
      value.thumbnail
    ),
    uvc_image_web:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageWebToJSON(
        value.uvcImageWeb
      ),
    uvc_image_mobile:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeAssetsUvcImageMobileToJSON(
        value.uvcImageMobile
      ),
  }
}
