/**
 * Returns the corresponding Enum value for a specified string
 * @param {Enum} sourceEnum - autogenerated Enum to look up the value within (e.g. GetCampaignsSortByEnum)
 *   Autogenerated OpenAPI Client enums have their values in snake_case.
 * @param {string} val - a snake_case value to lookup (e.g. start_date_asc)
 * @return value from the supplied Enum corresponding to val
 */
export const getEnumValueFromString = <T extends object>(sourceEnum: T, val: string) => {
  type EnumKey = keyof typeof sourceEnum
  const index = Object.values(sourceEnum).indexOf(val)
  const enumKey = Object.keys(sourceEnum)[index]
  const enumValue = sourceEnum[enumKey as EnumKey]
  if (!enumValue) console.error(`missing enum value for ${val}`)
  return enumValue
}

export const getEnumValuesFromStringArray = <T extends object>(sourceEnum: T, vals: string[]) =>
  vals.map(val => getEnumValueFromString(sourceEnum, val))
