// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot,
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot'
import {
  PostVideoCampaignsIdSubmitParamBrandPageSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamBrandPageSnapshot,
  PostVideoCampaignsIdSubmitParamBrandPageSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageSnapshot'
import {
  PostVideoCampaignsIdSubmitParamCreativeSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamCreativeSnapshot,
  PostVideoCampaignsIdSubmitParamCreativeSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamCreativeSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamCreativeSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamCreativeSnapshot'
import {
  PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshot,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshot'
import {
  PostVideoCampaignsIdSubmitParamDisplayAssetSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamDisplayAssetSnapshot,
  PostVideoCampaignsIdSubmitParamDisplayAssetSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamDisplayAssetSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDisplayAssetSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamDisplayAssetSnapshot'
import {
  PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshot,
  instanceOfPostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshot,
  PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshot'

/**
 * @type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot =

    | ({ type: 'ASSET' } & PostVideoCampaignsIdSubmitParamDisplayAssetSnapshot)
    | ({ type: 'BRAND_PAGE' } & PostVideoCampaignsIdSubmitParamBrandPageSnapshot)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshot)
    | ({ type: 'CREATIVE' } & PostVideoCampaignsIdSubmitParamCreativeSnapshot)
    | ({ type: 'DISPLAY_AD_GROUP' } & PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshot)
    | ({ type: 'PRODUCT_ASSET' } & PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshot)

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PostVideoCampaignsIdSubmitParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PostVideoCampaignsIdSubmitParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PostVideoCampaignsIdSubmitParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PostVideoCampaignsIdSubmitParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PostVideoCampaignsIdSubmitParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PostVideoCampaignsIdSubmitParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PostVideoCampaignsIdSubmitParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PostVideoCampaignsIdSubmitParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PostVideoCampaignsIdSubmitParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
