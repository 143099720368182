import { useCallback, useEffect, useRef, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { CountdownRenderProps } from 'react-countdown/dist/Countdown'
import { MfaVerificationForm } from 'components/MultiFactorAuth/components/MfaVerificationForm'
import { NotificationType, useNotifier } from 'context'
import { useGenerateAndSendOtp } from 'hooks/api/multiFactorAuth'
import useIsInitialRender from 'hooks/useIsInitialRender'
import { useBoolean } from 'landing/components/hooks'
import { MessageIdType } from 'locales/types'
import {
  MfaVerificationProps,
  MultiFactorAuthAction,
  MultiFactorAuthType,
} from '../utils/multiFactorAuth.types'

interface EmailVerificationProps extends MfaVerificationProps {
  resendCountDownSeconds?: number
  onSendOtp?: (mfaType: MultiFactorAuthType) => void
}

export const EmailMfaVerification = ({
  onSendOtp,
  resendCountDownSeconds = 10,
  ...props
}: EmailVerificationProps) => {
  const { action, mfaFactor } = props
  const { sendNotification } = useNotifier()
  const isInitialRender = useIsInitialRender()

  const [countdownActive, setCountdownActiveTrue, setCountdownActiveFalse] = useBoolean(true)
  const [countdownDate, setCountdownDate] = useState(Date.now() + resendCountDownSeconds * 1000)
  const countdownRef = useRef<Countdown | null>(null)
  const countdownApi = countdownRef.current?.getApi()

  const countdownRenderer = ({ seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      return <></>
    }
    return <>{` (0:${zeroPad(seconds)})`}</>
  }

  const startCountdown = useCallback(() => {
    if (countdownApi) {
      setCountdownDate(Date.now() + resendCountDownSeconds * 1000)
      countdownApi.start()
      setCountdownActiveTrue()
    }
  }, [countdownApi, resendCountDownSeconds, setCountdownActiveTrue])

  const onSuccess = useCallback(() => {
    startCountdown()
    sendNotification({
      type: NotificationType.SUCCESS,
      messageId: 'pages.multiFactorAuth.sendOtp.success',
    })
  }, [sendNotification, startCountdown])

  const onError = useCallback(
    (messageId: MessageIdType) => {
      sendNotification({
        type: NotificationType.ERROR,
        messageId,
      })
    },
    [sendNotification]
  )

  const { generateAndSendOtp, generateAndSendOtpLoading: loading } = useGenerateAndSendOtp({
    action,
    onSendOtp,
    onSuccess,
    onError,
  })

  const {
    generateAndSendOtp: generateAndSendOtpWithoutNotification,
    generateAndSendOtpLoading: loadingWithoutNotification,
  } = useGenerateAndSendOtp({
    action,
    onSendOtp,
    onError,
  })

  useEffect(() => {
    if (isInitialRender && action === MultiFactorAuthAction.Action) {
      generateAndSendOtpWithoutNotification(mfaFactor.mfaType)
    }
  }, [action, mfaFactor, generateAndSendOtpWithoutNotification, isInitialRender])

  const handleOnOptionClick = () => {
    generateAndSendOtp(mfaFactor.mfaType)
  }

  if (mfaFactor.mfaType !== MultiFactorAuthType.Email) {
    return <></>
  }

  return (
    <MfaVerificationForm
      {...props}
      onOptionClick={handleOnOptionClick}
      optionButtonDescriptorMessageId="pages.multiFactorAuth.option.buttonDescriptor.email"
      optionButtonProps={{
        disabled: loading || loadingWithoutNotification || countdownActive,
        children: (
          <Countdown
            date={countdownDate}
            ref={countdownRef}
            renderer={countdownRenderer}
            onComplete={setCountdownActiveFalse}
          />
        ),
      }}
    />
  )
}
