import { css } from '@emotion/react'
import { elevation as idsElevation, spacing, Color, useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { CSSProperties, HTMLProps } from 'react'
import { toPx } from 'common'

export interface CardProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  elevation?: 'primary' | 'low' | 'high'
  compact?: boolean
  backgroundColor?: Color
  marginless?: boolean
  padding?: CSSProperties['padding']
}

function useStyles(backgroundColor?: Color, padding?: CSSProperties['padding']) {
  const theme = useTheme()

  return css({
    color: theme.colors.systemGrayscale70,
    ...theme.typography.bodyMedium2,
    wordWrap: 'break-word',
    padding: padding || spacing.s24,
    borderRadius: theme.radius.r12,
    backgroundColor: theme.colors[backgroundColor || 'systemGrayscale00'],
    marginTop: spacing.s12,
    '&:first-child': {
      marginTop: 0,
    },
    '&.elevation-primary': idsElevation.primary.shadow,
    '&.elevation-low': idsElevation.low.shadow,
    '&.elevation-high': idsElevation.high.shadow,
    '&.padding-compact': {
      padding: `${spacing.s12}px ${spacing.s16}px`,
    },
    '&.marginless': {
      marginTop: 0,
      borderRadius: '0',
      '&.elevation-primary,&.elevation-low,&.elevation-high': {
        boxShadow: 'none',
      },
      '&:first-of-type': {
        borderTopLeftRadius: theme.radius.r12,
        borderTopRightRadius: theme.radius.r12,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: theme.radius.r12,
        borderBottomRightRadius: theme.radius.r12,
      },
    },
    p: {
      marginTop: spacing.s16,
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    'ul, ol': {
      margin: toPx`${spacing.s16} 0 0 ${spacing.s24}`,
      listStyleType: 'initial',
    },
  })
}

export function Card({
  children,
  elevation = 'primary',
  compact = false,
  marginless = false,
  padding = spacing.s24,
  backgroundColor,
  className,
  ...props
}: CardProps) {
  const styles = useStyles(backgroundColor, padding)

  return (
    <section
      css={styles}
      className={classNames(
        `elevation-${elevation}`,
        {
          'padding-compact': compact,
          marginless,
        },
        className
      )}
      {...props}
    >
      {children}
    </section>
  )
}
