import { css } from '@emotion/react'
import { Theme, useTheme, LoadingLockupTextBase } from '@instacart/ids-core'
import { FC, Children, Fragment } from 'react'

const getStyles = (theme: Theme) => ({
  nav: css({ color: theme.colors.systemGrayscale70 }),
  separator: css({
    padding: '0 0.2rem',
    '&:after': {
      color: theme.colors.systemGrayscale70,
      content: '"/"',
    },
  }),
  last: {
    letterSpacing: 0,
    color: theme.colors.systemGrayscale70,
    ...theme.typography.titleMedium,
  },
})

export type BreadcrumbsProps = React.PropsWithChildren<{
  loading?: boolean
}>

const loadingStyle = (numOfSections: number) => ({
  container: {
    height: '1rem',
    width: `${numOfSections * 10}%`,
    marginBottom: '0.5rem',
  },
})

const Breadcrumbs: FC<React.PropsWithChildren<BreadcrumbsProps>> = ({ children, loading }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  if (loading) {
    return (
      <div css={styles.nav}>
        {Children.count(children) > 1 && (
          <LoadingLockupTextBase styles={loadingStyle(Children.count(children) - 1)} />
        )}
        <LoadingLockupTextBase styles={{ container: { height: '2rem', width: '150px' } }} />
      </div>
    )
  }

  return (
    <nav css={styles.nav}>
      {Children.map(children, (child, index) => {
        const last = index === Children.count(children) - 1

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {last ? <span css={styles.last}>{child}</span> : <span>{child}</span>}
            {!last && <span css={styles.separator} />}
          </Fragment>
        )
      })}
    </nav>
  )
}

export default Breadcrumbs
