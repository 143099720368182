// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'

export interface PatchFeatureHintsIdDismissRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

/**
 *
 */
export class AdUsersFeatureHintsApi extends runtime.BaseAPI {
  /**
   * Use this method to dismiss a feature hint for the current user.
   * Dismiss feature hint for a user
   */
  async patchFeatureHintsIdDismissRaw(
    requestParameters: PatchFeatureHintsIdDismissRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling patchFeatureHintsIdDismiss.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/feature_hints/{id}/dismiss`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Use this method to dismiss a feature hint for the current user.
   * Dismiss feature hint for a user
   */
  async patchFeatureHintsIdDismiss(
    requestParameters: PatchFeatureHintsIdDismissRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.patchFeatureHintsIdDismissRaw(requestParameters, initOverrides)
  }
}
