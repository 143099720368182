// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SubmitBrandPagesResponseMeta } from './SubmitBrandPagesResponseMeta'
import {
  SubmitBrandPagesResponseMetaFromJSON,
  SubmitBrandPagesResponseMetaFromJSONTyped,
  SubmitBrandPagesResponseMetaToJSON,
} from './SubmitBrandPagesResponseMeta'

/**
 *
 * @export
 * @interface SubmitBrandPagesResponse
 */
export interface SubmitBrandPagesResponse {
  /**
   * N/A
   * @type {{ [key: string]: any; }}
   * @memberof SubmitBrandPagesResponse
   */
  data: { [key: string]: any }
  /**
   *
   * @type {SubmitBrandPagesResponseMeta}
   * @memberof SubmitBrandPagesResponse
   */
  meta: SubmitBrandPagesResponseMeta
}

/**
 * Check if a given object implements the SubmitBrandPagesResponse interface.
 */
export function instanceOfSubmitBrandPagesResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function SubmitBrandPagesResponseFromJSON(json: any): SubmitBrandPagesResponse {
  return SubmitBrandPagesResponseFromJSONTyped(json, false)
}

export function SubmitBrandPagesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitBrandPagesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: json['data'],
    meta: SubmitBrandPagesResponseMetaFromJSON(json['meta']),
  }
}

export function SubmitBrandPagesResponseToJSON(value?: SubmitBrandPagesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data,
    meta: SubmitBrandPagesResponseMetaToJSON(value.meta),
  }
}
