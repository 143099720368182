import { css } from '@emotion/react'
import { EditIcon, spacing, SVGIconProps, useTheme, Variant } from '@instacart/ids-core'
import { CellBase } from '@instacart/ids-tooling'
import { ComponentType, HTMLProps } from 'react'
import toPx from 'common/toPx'

const ICON_SIZE = 16

export interface InputCellProps extends HTMLProps<HTMLInputElement> {
  error?: boolean
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  cellProps?: Omit<Variant<typeof CellBase>, 'gutterPx'>
}

function useStyles({
  error,
  icon,
  disabled,
}: {
  error?: boolean
  icon?: boolean
  disabled?: boolean
}) {
  const theme = useTheme()

  const iconCommon: Parameters<typeof css>[0] = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    fill: disabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale70,
  }

  return {
    input: css({
      ...theme.typography.bodyMedium2,
      width: '100%',
      color: theme.colors.systemGrayscale70,
      background: error ? theme.colors.systemDetrimentalLight : theme.colors.systemGrayscale00,
      padding: toPx`${spacing.s8 - (error ? 1 : 0)} ${spacing.s48} ${spacing.s8} ${
        icon ? spacing.s16 + spacing.s4 : 0
      }`,
      boxSizing: 'border-box',
      boxShadow: error ? 'none' : `inset 0px 1px 0px ${theme.colors.systemGrayscale10}`,
      borderRadius: 0,
      borderWidth: 0,
      borderStyle: 'solid',
      borderTopWidth: error ? 1 : 0,
      borderColor: error ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale70,
      '&:focus': {
        outline: 'none',
        borderTopWidth: 2,
        paddingTop: spacing.s8 - 2,
        boxShadow: 'none',
      },
      '&:disabled': {
        background: theme.colors.systemGrayscale10,
        color: theme.colors.systemGrayscale30,
        '::placeholder': {
          color: theme.colors.systemGrayscale30,
        },
      },
      '::placeholder': {
        color: theme.colors.systemGrayscale50,
      },
      // Removes arrows from numeric inputs
      '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
      },
    }),
    userIcon: css({
      ...iconCommon,
      left: 0,
    }),
    editIcon: css({
      ...iconCommon,
      right: spacing.s24,
    }),
  }
}

export function InputCell({ error, icon, cellProps = {}, ...props }: InputCellProps) {
  const styles = useStyles({ error, icon: !!icon, disabled: props.disabled })

  const Icon = icon

  return (
    <CellBase
      gutterPx={0}
      styles={{ content: { padding: 0, position: 'relative' } }}
      {...cellProps}
    >
      {Icon && <Icon css={styles.userIcon} size={ICON_SIZE} />}
      <input css={styles.input} {...props} />
      <EditIcon css={styles.editIcon} size={ICON_SIZE} />
    </CellBase>
  )
}
