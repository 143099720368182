// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockSectionBannerAssets } from './PostBrandPagesIdStatusParamBlockSectionBannerAssets'
import {
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSON,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsToJSON,
} from './PostBrandPagesIdStatusParamBlockSectionBannerAssets'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockSectionBanner
 */
export interface PostBrandPagesIdStatusParamBlockSectionBanner {
  /**
   * id of the block
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBanner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBanner
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBanner
   */
  type: PostBrandPagesIdStatusParamBlockSectionBannerTypeEnum
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockSectionBannerAssets}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBanner
   */
  assets: PostBrandPagesIdStatusParamBlockSectionBannerAssets
}

/**
 * @export
 */
export const PostBrandPagesIdStatusParamBlockSectionBannerTypeEnum = {
  SectionBannerEvergreenV1: 'section_banner.evergreen.v1',
} as const
export type PostBrandPagesIdStatusParamBlockSectionBannerTypeEnum =
  typeof PostBrandPagesIdStatusParamBlockSectionBannerTypeEnum[keyof typeof PostBrandPagesIdStatusParamBlockSectionBannerTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockSectionBanner interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockSectionBanner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockSectionBannerFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockSectionBanner {
  return PostBrandPagesIdStatusParamBlockSectionBannerFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBlockSectionBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockSectionBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSON(json['assets']),
  }
}

export function PostBrandPagesIdStatusParamBlockSectionBannerToJSON(
  value?: PostBrandPagesIdStatusParamBlockSectionBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PostBrandPagesIdStatusParamBlockSectionBannerAssetsToJSON(value.assets),
  }
}
