// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouch } from './KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinear } from './KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData
 */
export interface KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData
   */
  linear: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfKeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData {
  return KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: KeywordsAnalyticsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
