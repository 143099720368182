import {
  ButtonBase,
  combineStyles,
  spacing,
  StylesOf,
  useTheme,
  Variant,
} from '@instacart/ids-core'
import { forwardRef } from 'react'
import toPx from 'common/toPx'
import { RotateAbleChevron } from '../../atoms/RotatingIcons/RotateAbleChevron'

function usePlaceholderStyles() {
  const theme = useTheme()
  return {
    color: theme.colors.systemGrayscale50,
  }
}

function useStyles(
  {
    empty,
    width,
  }: {
    empty?: boolean
    width?: number | string
  },
  styleOverrides?: StylesOf<typeof ButtonBase>
): StylesOf<typeof ButtonBase> {
  const theme = useTheme()

  return combineStyles(
    {
      button: {
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: width || 180,
        maxWidth: width || undefined,
        height: 32,
        backgroundColor: theme.colors.systemGrayscale00,
        color: empty ? theme.colors.systemGrayscale50 : theme.colors.systemGrayscale70,
        border: `1px solid ${theme.colors.systemGrayscale30}`,
        borderRadius: theme.radius.r4,
        padding: toPx`${spacing.s4} ${spacing.s12} ${spacing.s4} ${spacing.s8}`,
      },
      content: {
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...theme.typography.bodyMedium2,
        fontSize: 14,
        height: theme.typography.bodyMedium2.lineHeight,
        svg: {
          fill: theme.colors.systemGrayscale50,
        },
      },
      focusRing: {
        borderRadius: theme.radius.r4,
      },
      hover: {
        backgroundColor: theme.colors.systemGrayscale10,
      },
      active: {
        backgroundColor: theme.colors.systemGrayscale20,
      },
      disabled: {
        backgroundColor: theme.colors.systemGrayscale10,
        svg: {
          fill: theme.colors.systemGrayscale30,
        },
      },
      loading: {
        backgroundColor: theme.colors.systemGrayscale10,
        svg: {
          fill: theme.colors.systemGrayscale30,
        },
      },
    },
    styleOverrides
  ) as StylesOf<typeof ButtonBase>
}

export interface FilterDropdownButtonProps extends Variant<typeof ButtonBase> {
  children?: string | JSX.Element[]
  open?: boolean
  width?: number | string
  styleOverrides?: StylesOf<typeof ButtonBase>
  placeholder?: string
}

// TODO: should migrate to use the new filter button styles
export const FilterDropdownButton = forwardRef<HTMLButtonElement, FilterDropdownButtonProps>(
  ({ children, placeholder, open, width, styleOverrides, ...props }, ref) => {
    const empty = !children?.length
    const placeholderStyle = usePlaceholderStyles()
    const styles = useStyles({ empty: !children?.length, width }, styleOverrides)
    const content = empty ? (
      <span css={placeholderStyle}>{placeholder}</span>
    ) : (
      <span>{children}</span>
    )

    return (
      <ButtonBase {...props} styles={styles} ref={ref}>
        {content}
        <div css={{ marginLeft: spacing.s12 }}>
          <RotateAbleChevron size={18} position={open ? 'up' : 'down'} />
        </div>
      </ButtonBase>
    )
  }
)
