import { blendColors, ButtonBase, spacing, StylesOf, useTheme, Variant } from '@instacart/ids-core'

export interface OptionPillProps<T> extends Omit<Variant<typeof ButtonBase>, 'onSelect' | 'value'> {
  value: T
  onSelect?(value: T): void
  selected?: boolean
}

function useStyles(): StylesOf<typeof ButtonBase> {
  const theme = useTheme()

  return {
    button: {
      backgroundColor: theme.colors.systemGrayscale10,
      borderRadius: theme.radius.r24,
      padding: `2px ${spacing.s12}px`,
      margin: `${spacing.s4}px ${spacing.s8}px ${spacing.s4}px 0`,
      color: theme.colors.systemGrayscale70,
      border: '1px solid transparent',
      ...theme.typography.bodyMedium1,
      '&[aria-selected="true"]': {
        borderColor: theme.colors.systemGrayscale70,
        '&:disabled': {
          borderColor: theme.colors.systemGrayscale30,
        },
      },
      '&:last-of-type': {
        marginRight: 0,
      },
      '&[data-consensus="common"]': {
        backgroundColor: theme.colors.systemSuccessLight,
      },
      '&[data-consensus="one"]': {
        backgroundColor: theme.colors.brandExpressLight,
      },
      '&[data-consensus="two"]': {
        backgroundColor: theme.colors.brandHighlightLight,
      },
    },
    focusRing: {
      borderRadius: theme.radius.r8,
    },
    hover: {
      backgroundColor: blendColors(
        theme.colors.systemGrayscale10,
        theme.colors.systemGrayscale20,
        51
      ),
    },
    active: {
      backgroundColor: theme.colors.systemGrayscale20,
    },
    disabled: {
      backgroundColor: theme.colors.systemGrayscale10,
      color: theme.colors.systemGrayscale30,
    },
  }
}

export function OptionPill<T>({
  color,
  disabled,
  selected,
  value,
  onSelect,
  ...props
}: OptionPillProps<T>) {
  const styles = useStyles()
  const handleSelect = disabled ? undefined : () => onSelect?.(value)

  return (
    <ButtonBase
      {...props}
      disabled={disabled}
      styles={styles}
      role="option"
      type="button"
      aria-selected={selected}
      onClick={handleSelect}
    />
  )
}
