// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiAdUsersMultiFactorAuthControllerConfirmInput } from '../models/ApiAdUsersMultiFactorAuthControllerConfirmInput'
import {
  ApiAdUsersMultiFactorAuthControllerConfirmInputFromJSON,
  ApiAdUsersMultiFactorAuthControllerConfirmInputToJSON,
} from '../models/ApiAdUsersMultiFactorAuthControllerConfirmInput'
import type { ApiAdUsersMultiFactorAuthControllerCreateInput } from '../models/ApiAdUsersMultiFactorAuthControllerCreateInput'
import {
  ApiAdUsersMultiFactorAuthControllerCreateInputFromJSON,
  ApiAdUsersMultiFactorAuthControllerCreateInputToJSON,
} from '../models/ApiAdUsersMultiFactorAuthControllerCreateInput'
import type { ApiAdUsersMultiFactorAuthControllerDestroyInput } from '../models/ApiAdUsersMultiFactorAuthControllerDestroyInput'
import {
  ApiAdUsersMultiFactorAuthControllerDestroyInputFromJSON,
  ApiAdUsersMultiFactorAuthControllerDestroyInputToJSON,
} from '../models/ApiAdUsersMultiFactorAuthControllerDestroyInput'
import type { ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput } from '../models/ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput'
import {
  ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputFromJSON,
  ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputToJSON,
} from '../models/ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput'
import type { ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInput } from '../models/ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInput'
import {
  ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInputFromJSON,
  ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInputToJSON,
} from '../models/ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInput'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { MultiFactorAuthCreateResponse } from '../models/MultiFactorAuthCreateResponse'
import {
  MultiFactorAuthCreateResponseFromJSON,
  MultiFactorAuthCreateResponseToJSON,
} from '../models/MultiFactorAuthCreateResponse'
import type { SuccessResponse } from '../models/SuccessResponse'
import { SuccessResponseFromJSON, SuccessResponseToJSON } from '../models/SuccessResponse'

export interface DeleteMultiFactorAuthRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdUsersMultiFactorAuthControllerDestroyInput
}

export interface PostMultiFactorAuthRequest {
  xApiKey?: string
  xSecretKey?: string
  clientTimezone?: string
  body?: ApiAdUsersMultiFactorAuthControllerCreateInput
}

export interface PostMultiFactorAuthConfirmRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdUsersMultiFactorAuthControllerConfirmInput
}

export interface PostMultiFactorAuthGenerateAndSendOtpRequest {
  xApiKey?: string
  xSecretKey?: string
  clientTimezone?: string
  body?: ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput
}

export interface PostMultiFactorAuthRegenerateBackupCodesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInput
}

/**
 *
 */
export class AdUsersMultiFactorAuthApi extends runtime.BaseAPI {
  /**
   * Use this method to disable an MFA factor
   * Delete MFA factor
   */
  async deleteMultiFactorAuthRaw(
    requestParameters: DeleteMultiFactorAuthRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/multi_factor_auth`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersMultiFactorAuthControllerDestroyInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => SuccessResponseFromJSON(jsonValue))
  }

  /**
   * Use this method to disable an MFA factor
   * Delete MFA factor
   */
  async deleteMultiFactorAuth(
    requestParameters: DeleteMultiFactorAuthRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SuccessResponse> {
    const response = await this.deleteMultiFactorAuthRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to initialize an MFA factor.
   * Initializes MFA factor
   */
  async postMultiFactorAuthRaw(
    requestParameters: PostMultiFactorAuthRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MultiFactorAuthCreateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    if (
      requestParameters.clientTimezone !== undefined &&
      requestParameters.clientTimezone !== null
    ) {
      headerParameters['Client-Timezone'] = String(requestParameters.clientTimezone)
    }

    const response = await this.request(
      {
        path: `/multi_factor_auth`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersMultiFactorAuthControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      MultiFactorAuthCreateResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to initialize an MFA factor.
   * Initializes MFA factor
   */
  async postMultiFactorAuth(
    requestParameters: PostMultiFactorAuthRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MultiFactorAuthCreateResponse> {
    const response = await this.postMultiFactorAuthRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to confirm and activate an MFA factor
   * Confirm MFA factor
   */
  async postMultiFactorAuthConfirmRaw(
    requestParameters: PostMultiFactorAuthConfirmRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/multi_factor_auth/confirm`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersMultiFactorAuthControllerConfirmInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => SuccessResponseFromJSON(jsonValue))
  }

  /**
   * Use this method to confirm and activate an MFA factor
   * Confirm MFA factor
   */
  async postMultiFactorAuthConfirm(
    requestParameters: PostMultiFactorAuthConfirmRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SuccessResponse> {
    const response = await this.postMultiFactorAuthConfirmRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to generate and send a one-time password to the user\'s configured destination.
   * Generate and send MFA one-time password
   */
  async postMultiFactorAuthGenerateAndSendOtpRaw(
    requestParameters: PostMultiFactorAuthGenerateAndSendOtpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    if (
      requestParameters.clientTimezone !== undefined &&
      requestParameters.clientTimezone !== null
    ) {
      headerParameters['Client-Timezone'] = String(requestParameters.clientTimezone)
    }

    const response = await this.request(
      {
        path: `/multi_factor_auth/generate_and_send_otp`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputToJSON(
          requestParameters.body
        ),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => SuccessResponseFromJSON(jsonValue))
  }

  /**
   * Use this method to generate and send a one-time password to the user\'s configured destination.
   * Generate and send MFA one-time password
   */
  async postMultiFactorAuthGenerateAndSendOtp(
    requestParameters: PostMultiFactorAuthGenerateAndSendOtpRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SuccessResponse> {
    const response = await this.postMultiFactorAuthGenerateAndSendOtpRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Use this method to generate a new set of backup codes.
   * Regenerate backup codes
   */
  async postMultiFactorAuthRegenerateBackupCodesRaw(
    requestParameters: PostMultiFactorAuthRegenerateBackupCodesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MultiFactorAuthCreateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/multi_factor_auth/regenerate_backup_codes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInputToJSON(
          requestParameters.body
        ),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      MultiFactorAuthCreateResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to generate a new set of backup codes.
   * Regenerate backup codes
   */
  async postMultiFactorAuthRegenerateBackupCodes(
    requestParameters: PostMultiFactorAuthRegenerateBackupCodesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MultiFactorAuthCreateResponse> {
    const response = await this.postMultiFactorAuthRegenerateBackupCodesRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
