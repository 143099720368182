// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick
 */
export interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick {
  /**
   * URL for destination
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick
   */
  url: string
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'url' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick {
  return ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    url: json['url'],
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClickToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeActionsBannerClickUrlClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    url: value.url,
  }
}
