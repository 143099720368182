// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviews } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import type { PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReview } from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReview'
import {
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState
 */
export interface PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReview}
   * @memberof PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState
   */
  finalReview?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState {
  return PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateToJSON(
  value?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review:
      PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON(
        value.finalReview
      ),
  }
}
