// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGrid } from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGrid'
import {
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGridFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGridFromJSONTyped,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGridToJSON,
} from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGrid'

/**
 *
 * @export
 * @interface ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions
 */
export interface ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions {
  /**
   *
   * @type {ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGrid}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions
   */
  mainItemGrid?: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGrid
}

/**
 * Check if a given object implements the ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions interface.
 */
export function instanceOfApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsFromJSON(
  json: any
): ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions {
  return ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
  }
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsToJSON(
  value?: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
  }
}
