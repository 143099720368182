import { canUseDOM } from 'exenv'
import { BaseStyles } from 'components/BaseStyles'
import Portal from 'components/Portal'
import { useHeadContext } from 'context'

const Head = () => {
  throw new Error('Do not use <Head/> directly - instead, use a sub-component, like <Head.Title/>')
}

const Add = ({ children }: { children: JSX.Element }) => {
  const context = useHeadContext()

  if (canUseDOM) {
    return <Portal parent={document.head}>{children}</Portal>
  }

  const { additional = [] } = context
  const newAddition = Array.isArray(children) ? children : [children]

  context.set('additional', additional.concat(newAddition))
  return null
}

const Title = ({ children }: { children: string }) => {
  const context = useHeadContext()

  if (canUseDOM) {
    document.title = children
    return null
  }

  context.set('title', children)
  return null
}

// eslint-disable-next-line react/no-multi-comp
const Description = ({ children }: { children: string }) => {
  if (canUseDOM) {
    return null
  }

  return (
    <Add>
      <meta name="description" content={children} />
    </Add>
  )
}

// for internal rendering use only
// this is inteded for server usage
// eslint-disable-next-line
const Current = ({ children }: { children?: JSX.Element }) => {
  const { title, additional } = useHeadContext()

  /* eslint-disable */
  return (
    <>
      <title>{title}</title>
      <BaseStyles />
      {additional}
      {children}
    </>
  )
}

Head.Add = Add
Head.Title = Title
Head.Description = Description
Head.Current = Current

export default Head
