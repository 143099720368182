import { UseButtonStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

const styles: UseButtonStylesOpts = {
  normalBackground: null,
  borderColor: null,
  textColor: 'systemGrayscale70',
  hoverBackground: 'systemGrayscale10',
  activeBackground: 'systemGrayscale20',
  disabledBackground: null,
  disabledBorderColor: null,
  disabledTextColor: 'systemGrayscale30',
}

/** @deprecated Use TertiaryButton from 'components/ids-ads' instead */
export const TertiaryButton = makeButton('TertiaryButton', styles)
/** @deprecated Use TertiaryLinkButton from 'components/ids-ads' instead */
export const TertiaryLinkButton = makeLinkButton('TertiaryLinkButton', styles)
/** @deprecated Use TertiaryAnchorButton from 'components/ids-ads' instead */
export const TertiaryAnchorButton = makeAnchorButton('TertiaryAnchorButton', styles)
