import { ButtonBase, ButtonBaseProps } from '@instacart/ids-core'
import { ReactNode } from 'react'
import { Tooltip, TooltipReference, useTooltipState } from '../../tooltips'

export interface TooltipButtonBaseProps extends ButtonBaseProps {
  tooltip: ReactNode
}

export function TooltipButtonBase({ tooltip, children, ...props }: TooltipButtonBaseProps) {
  const state = useTooltipState()

  return (
    <>
      <TooltipReference as={ButtonBase} {...state} {...props}>
        {children}
      </TooltipReference>
      <Tooltip {...state}>{tooltip}</Tooltip>
    </>
  )
}
