import { useIntl } from 'common'
import {
  PrimaryButton,
  PrimaryLinkButton,
  SecondaryButton,
  SecondaryLinkButton,
} from 'components/ids-ads'
import { ToastActionButtonsProps } from 'context/notifications'

export function ToastActionButtons({
  primaryButtonLabel,
  primaryButtonProps,
  primaryLinkButtonProps,
  secondaryButtonLabel,
  secondaryButtonProps,
  secondaryLinkButtonProps,
}: ToastActionButtonsProps) {
  const { genericFormatMessage } = useIntl()
  const PrimaryCTA = () => {
    if (primaryButtonLabel) {
      if (primaryButtonProps) {
        return (
          <PrimaryButton compact {...primaryButtonProps}>
            {genericFormatMessage(primaryButtonLabel)}
          </PrimaryButton>
        )
      }
      if (primaryLinkButtonProps) {
        return (
          <PrimaryLinkButton compact {...primaryLinkButtonProps}>
            {genericFormatMessage(primaryButtonLabel)}
          </PrimaryLinkButton>
        )
      }
    }
    return null
  }
  const SecondaryCTA = () => {
    if (secondaryButtonLabel) {
      if (secondaryButtonProps) {
        return (
          <SecondaryButton compact {...secondaryButtonProps}>
            {genericFormatMessage(secondaryButtonLabel)}
          </SecondaryButton>
        )
      }
      if (secondaryLinkButtonProps) {
        return (
          <SecondaryLinkButton compact {...secondaryLinkButtonProps}>
            {genericFormatMessage(secondaryButtonLabel)}
          </SecondaryLinkButton>
        )
      }
    }
    return null
  }
  return (
    <>
      <SecondaryCTA />
      <PrimaryCTA />
    </>
  )
}
