export interface Range {
  rangeStart: number
  rangeEnd: number
}

export interface KeyRange {
  rangeStart: number | string
  rangeEnd: number | string
}

// Returns the indicies of keys in keysSorted. keys must be a subset of keysSorted
function keyIndices(keysSorted: (number | string)[], keys: (number | string)[]) {
  const keyToIndicies: Map<number | string, number> = new Map()
  keysSorted.forEach((key, index) => {
    keyToIndicies.set(key, index)
  })
  const indices: number[] = []
  keys.forEach((key: number | string) => {
    // key will exist in keyToIndicies
    if (keyToIndicies.has(key)) {
      indices.push(keyToIndicies.get(key) as number)
    }
  })
  return indices
}

// Returns an array of Ranges that represents numsSorted compressed
function mergeInterval(numsSorted: number[]): Range[] {
  const n = numsSorted.length
  const ranges: Range[] = []
  let i = 0
  while (i < n) {
    let j = i + 1
    while (j < n && numsSorted[j] === numsSorted[j - 1] + 1) {
      j += 1
    }
    ranges.push({ rangeStart: numsSorted[i], rangeEnd: numsSorted[j - 1] })
    i = j
  }
  return ranges
}

// Returns Range of keys that helps plotting Reference Areas in Rechart
function referenceAreaRanges(keys: (number | string)[], ranges: Range[]): KeyRange[] {
  const n = keys.length
  const keyRanges: KeyRange[] = []
  ranges.forEach(({ rangeStart, rangeEnd }) => {
    const rangeStartBounded = Math.max(0, rangeStart - 1)
    const rangeEndBounded = Math.min(n - 1, rangeEnd + 1)
    keyRanges.push({ rangeStart: keys[rangeStartBounded], rangeEnd: keys[rangeEndBounded] })
  })
  return keyRanges
}

// Returns reference area ranges given xAxis keys sorted and xAxis keys that should be obscured.
// xAxisObscuredKeysSorted must be a subset of xAxisKeysSorted.
export function getReferenceAreaRanges(
  xAxisKeysSorted: (number | string)[],
  xAxisObscuredKeysSorted: (number | string)[]
): KeyRange[] {
  const obscureKeyIndices = keyIndices(xAxisKeysSorted, xAxisObscuredKeysSorted)
  const indicesMerged = mergeInterval(obscureKeyIndices)
  return referenceAreaRanges(xAxisKeysSorted, indicesMerged)
}
