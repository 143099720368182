// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApplicableLabelsAdminBrandPageResponseDataAttributes } from './ApplicableLabelsAdminBrandPageResponseDataAttributes'
import {
  ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSON,
  ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseDataAttributesToJSON,
} from './ApplicableLabelsAdminBrandPageResponseDataAttributes'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponseData
 */
export interface ApplicableLabelsAdminBrandPageResponseData {
  /**
   *
   * @type {string}
   * @memberof ApplicableLabelsAdminBrandPageResponseData
   */
  id: ApplicableLabelsAdminBrandPageResponseDataIdEnum
  /**
   *
   * @type {string}
   * @memberof ApplicableLabelsAdminBrandPageResponseData
   */
  type: ApplicableLabelsAdminBrandPageResponseDataTypeEnum
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseDataAttributes}
   * @memberof ApplicableLabelsAdminBrandPageResponseData
   */
  attributes: ApplicableLabelsAdminBrandPageResponseDataAttributes
}

/**
 * @export
 */
export const ApplicableLabelsAdminBrandPageResponseDataIdEnum = {
  ApplicableLabels: 'applicable_labels',
} as const
export type ApplicableLabelsAdminBrandPageResponseDataIdEnum =
  typeof ApplicableLabelsAdminBrandPageResponseDataIdEnum[keyof typeof ApplicableLabelsAdminBrandPageResponseDataIdEnum]

/**
 * @export
 */
export const ApplicableLabelsAdminBrandPageResponseDataTypeEnum = {
  ApplicableLabels: 'applicable_labels',
} as const
export type ApplicableLabelsAdminBrandPageResponseDataTypeEnum =
  typeof ApplicableLabelsAdminBrandPageResponseDataTypeEnum[keyof typeof ApplicableLabelsAdminBrandPageResponseDataTypeEnum]

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponseData interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseDataFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponseData {
  return ApplicableLabelsAdminBrandPageResponseDataFromJSONTyped(json, false)
}

export function ApplicableLabelsAdminBrandPageResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function ApplicableLabelsAdminBrandPageResponseDataToJSON(
  value?: ApplicableLabelsAdminBrandPageResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: ApplicableLabelsAdminBrandPageResponseDataAttributesToJSON(value.attributes),
  }
}
