import { spacing, Text, useTheme } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import breakPoints from './breakPoints'

const useStyles = (): { [key: string]: CSSProperties } => {
  const theme = useTheme()
  return {
    feature: {
      display: 'flex',
      justifyContent: 'center',
    },
    lead: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [breakPoints.mobile]: {
        fontSize: '16px',
      },
    },
    text: {
      maxWidth: '260px',
      color: theme.colors.systemGrayscale50,
      ...{ marginTop: spacing.s16, marginBottom: spacing.s16 },
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    imgWrapper: {
      ...{ marginTop: spacing.s16, marginBottom: spacing.s16 },
    },
  }
}

interface FeatureProps {
  lead: string
  children: JSX.Element | string
  renderImage: () => JSX.Element
  style?: CSSProperties
}

const Feature = ({ lead, renderImage, children, style }: FeatureProps) => {
  const styles = useStyles()
  return (
    <div style={styles.feature}>
      <div>
        <div style={styles.imgWrapper}>{renderImage()}</div>
        <Text typography="bodyLarge2" style={styles.lead} as="h3" color="systemGrayscale20">
          {lead}
        </Text>
        <Text typography="bodyMedium2" style={{ ...styles.text, ...style }} as="div">
          {children}
        </Text>
      </div>
    </div>
  )
}

export default Radium(Feature)
