import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { MouseEvent, KeyboardEvent, FC } from 'react'

export interface SelectCardProps {
  title: string
  isSelected: boolean
  isDisabled?: boolean
  subtext: string
  name: string
  value: string
  onClick: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void
}

const useStyles = (isSelected: boolean, isDisabled?: boolean) => {
  const theme = useTheme()
  return {
    titleCss: css({
      color: theme.colors.systemGrayscale70,
      fontSize: '1.1rem',
    }),
    selectedTitleCss: css({
      color: theme.colors.systemGrayscale70,
      fontSize: '1.1rem',
      fontWeight: 600,
    }),
    textCss: css({
      fontSize: '0.75rem',
      letterSpacing: '0.5px',
      color: theme.colors.systemGrayscale70,
      lineHeight: '1rem',
    }),
    cardWrapperCss: css({
      backgroundColor: theme.colors.systemGrayscale00,
      border: `2px solid ${theme.colors.systemGrayscale00}`,
      borderRadius: 4,
      position: 'relative',
      maxWidth: 348,
      padding: '15px 15px 15px 15px',
      boxSizing: 'border-box',
      '&:focus': {
        outline: 0,
      },
    }),
    inputCss: css({
      display: 'none',
      '&:checked ~ label': {
        '&:before': {
          border: `1px solid ${theme.colors.brandPrimaryRegular}`,
        },
        '&:after': {
          display: 'initial',
        },
      },
    }),
    lineBreakCss: css({
      lineHeight: '55%',
    }),
    isSelectedCss: isSelected
      ? css({
          boxShadow: '1px 2px 8px 2px rgba(0, 0, 0, 0.1)',
          border: `2px solid ${theme.colors.brandPrimaryRegular}`,
        })
      : css({
          boxShadow: '1px 2px 8px 2px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            border: `2px solid ${theme.colors.systemGrayscale30}`,
          },
        }),
    isDisabledCss: isDisabled ? css({ filter: 'opacity(40%)' }) : css({}),
  }
}

const SelectCard: FC<React.PropsWithChildren<SelectCardProps>> = props => {
  const { isSelected, isDisabled, title, subtext, name, onClick, value } = props
  const styles = useStyles(isSelected, isDisabled)

  return (
    <div
      css={[styles.cardWrapperCss, styles.isSelectedCss, styles.isDisabledCss]}
      onClick={e => (isDisabled ? null : onClick(e))}
      onKeyPress={e => (isDisabled ? null : onClick(e))}
      role="button"
      tabIndex={0}
    >
      <input
        id={value}
        type="radio"
        name={name}
        value={value}
        checked={isSelected}
        css={styles.inputCss}
        data-testid={`select-${value}`}
        disabled={isDisabled}
      />
      <label htmlFor={value}>
        <span css={isSelected ? styles.selectedTitleCss : styles.titleCss}>{title}</span>
      </label>
      <div css={styles.lineBreakCss}>
        <br />
      </div>
      <span css={styles.textCss}>{subtext}</span>
    </div>
  )
}
export default SelectCard
