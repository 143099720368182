import { ToggleColumn } from '../components/frappuccino/types'

export const FLIPPER_DISABLE_RECOMMENDATIONS = 'self_service_ui_disable_bid_recommendations'
export const FLIPPER_ENABLE_RECOMMENDATIONS_THRESHOLD = 'self_service_ui_bid_rec_guard_rails'

export const FLIPPER_VISIBILITY_GROUP_CANADA = 'self_service_ui_visibility_group_canada'
export const FLIPPER_VISIBILITY_GROUP_ONE = 'self_service_ui_visibility_group_one'

// If you're updating this, please also update the one in cypress/e2e/shared/constants.ts
export const TABLE_SEARCH_CHANGE_TIMEOUT = 500

// eslint-disable-next-line no-restricted-syntax
export enum CampaignStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  DRAFT = 'draft',
  ENDED = 'ended',
  INCOMPLETE_AD_GROUP = 'incomplete_ad_group',
  INCOMPLETE_AD_GROUP_PRODUCTS = 'incomplete_ad_group_products',
  OUT_OF_BUDGET = 'out_of_budget',
  PAUSED = 'paused',
  SCHEDULED = 'scheduled',
  UNKNOWN = 'unknown',
  FAILED_PAYMENT = 'failed_payment', // This is a UI-only status derived from account.paymentStatus
  CONTAINS_REJECTED_AD_GROUPS = 'contains_rejected_ad_groups', // This is a UI-only status on Display and Email Campaigns
  ARCHIVED = 'archived', // This is a workround for https://instacart.atlassian.net/browse/ADS-219527 only
}

// eslint-disable-next-line no-restricted-syntax
export enum AdGroupStatus {
  INCOMPLETE_AD_GROUP_PRODUCTS = 'incomplete_ad_group_products',
  CAMPAIGN_ENDED = 'campaign_ended',
  CAMPAIGN_OUT_OF_BUDGET = 'campaign_out_of_budget',
  CAMPAIGN_PAUSED = 'campaign_paused',
  CAMPAIGN_DRAFT = 'campaign_draft',
  PAUSED = 'paused',
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  DELETED = 'deleted',
}

// eslint-disable-next-line no-restricted-syntax
export enum RecipePageStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  APPEALED = 'APPEALED',
}

// eslint-disable-next-line no-restricted-syntax
export enum CampaignType {
  AUCTION = 'auction',
  RESERVATION = 'reservation',
}

// eslint-disable-next-line no-restricted-syntax
export enum CampaignTypeStatus {
  EMAIL = 'email',
  DISPLAY = 'display',
  FEATURED_PRODUCT = 'featured_product',
  PROTOTYPE = 'prototype',
  COUPON = 'coupon',
  INSPIRATION = 'inspiration',
  /**
   * Even though video isn't a true campaign type, we need to be
   * able to differntiate it to display in some cases on the UI
   */
  VIDEO = 'video',
  RESERVATION = 'reservation',
  SMART = 'smart',
}

// eslint-disable-next-line no-restricted-syntax
export enum UnitsOfMeasurement {
  OUNCE = 'oz',
  OUNCES = 'oz',
  POUND = 'lb',
  POUNDS = 'lbs',
  PINT = 'pt',
  PINTS = 'pt',
  QUART = 'qt',
  QUARTS = 'qt',
  FLUID_OUNCE = 'fl oz',
  FLUID_OUNCES = 'fl oz',
  GALLON = 'gal',
  GALLONS = 'gal',
  MILLILITER = 'ml',
  MILLILITERS = 'ml',
  LITER = 'L',
  LITERS = 'L',
  COUNT = 'ct',
  PACK = 'pack',
  PACKS = 'pack',
  EACH = 'ea',
  BAG = 'bag',
  BAGS = 'bags',
  DOZEN = 'doz',
  METER = 'm',
  METERS = 'm',
  INCHES = 'in',
  FEET = 'ft',
  GRAM = 'g',
  GRAMS = 'g',
  SQUARE_FEET = 'sq ft',
}

// eslint-disable-next-line no-restricted-syntax
export enum AttributionModels {
  LINEAR = 'linear',
  LAST_TOUCH = 'last_touch',
}

// eslint-disable-next-line no-restricted-syntax
export enum AttributedColumnType {
  SALES = 'sales',
  QUANTITIES = 'quantities',
  ROAS = 'roas',
  NTB_ROAS = 'ntb_roas',
  DIRECT_SALES = 'direct_sales',
  HALO_SALES = 'halo_sales',
  HALO_ROAS = 'halo_roas',
  NTB_SALES = 'ntb_sales',
  NTB_QUANTITIES = 'ntb_quantities',
  NTB_PERCENTAGE_QUANTITIES = 'ntb_percentage_quantities',
  NTB_PERCENTAGE_SALES = 'ntb_precentage_sales',
  NTB_HALO_SALES = 'ntb_halo_sales',
  NTB_PERCENTAGE_HALO_SALES = 'ntb_percentage_halo_sales',
}

export const MIN_WIDTH_NAME_COLUMN = '30ch'

type EnabledColumn = Pick<
  ToggleColumn,
  'type' | 'displayName' | 'width' | 'annotations' | 'isOrderable'
>

export const enabledColumn: EnabledColumn = {
  type: 'toggle',
  displayName: 'common.enabled',
  width: {
    width: '80px',
  },
  annotations: [
    {
      type: 'tableHeader',
      key: 'enabledToggleTooltip',
      tableHeaderTooltip: () => ({
        tooltip: 'common.enabled.column.toolip',
      }),
    },
  ],
  isOrderable: false,
}
