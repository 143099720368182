// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick {
  /**
   * Brand page identifier for destination
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
   */
  brandPageId: string
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'brandPageId' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageId: json['brand_page_id'],
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_id: value.brandPageId,
  }
}
