// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabels } from './PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabels'
import {
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSON,
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSONTyped,
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabelsToJSON,
} from './PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabels'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamAssetFieldDecisionsImage
 */
export interface PutReservationAdGroupsIdParamAssetFieldDecisionsImage {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisionsImage
   */
  decision?: PutReservationAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabels>}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisionsImage
   */
  labels?: Array<PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabels>
}

/**
 * @export
 */
export const PutReservationAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PutReservationAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum =
  typeof PutReservationAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum[keyof typeof PutReservationAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum]

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamAssetFieldDecisionsImage interface.
 */
export function instanceOfPutReservationAdGroupsIdParamAssetFieldDecisionsImage(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSON(
  json: any
): PutReservationAdGroupsIdParamAssetFieldDecisionsImage {
  return PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamAssetFieldDecisionsImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSON
        ),
  }
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsImageToJSON(
  value?: PutReservationAdGroupsIdParamAssetFieldDecisionsImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PutReservationAdGroupsIdParamAssetFieldDecisionsImageLabelsToJSON
          ),
  }
}
