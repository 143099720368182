import Analytics, { AnalyticsParams } from 'common/analytics/Analytics'
import { AnalyticEvents } from 'common/analytics/events'
import { getTargetingRuleFromFormData } from 'components/organisms/TargetingRule/FormHelpers'
import { isTargetingV2Enabled } from 'components/organisms/TargetingRule/hooks'
import { AuthCtx } from 'context'
import { ProductsApi } from 'service/apis/ProductsApi'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import {
  DisplayAdGroupResponseData,
  DisplayAdGroupResponseDataTypeEnum,
} from 'service/openapi/__codegen__/models/DisplayAdGroupResponseData'
import { DisplayAdGroupResponseDataAttributesBrandPage } from 'service/openapi/__codegen__/models/DisplayAdGroupResponseDataAttributesBrandPage'
import { DisplayCampaignResponseData } from 'service/openapi/__codegen__/models/DisplayCampaignResponseData'
import { DisplayCampaignResponseDataAttributesCampaignTypeEnum } from 'service/openapi/__codegen__/models/DisplayCampaignResponseDataAttributes'
import { GetProductsResponse } from 'service/openapi/__codegen__/models/GetProductsResponse'
import { EventPageType, EventPageTypeValues } from 'service/types'
import { TargetingType } from '../../DisplayAdGroup/constants'
import { DisplayAdGroupFormData } from '../../DisplayAdGroup/displayAdGroup.types'
import { DisplayCampaignFormValues } from '../../DisplayCampaign/displayCampaign.types'
import { BrandPageFormFields } from '../components/BrandPage/brandPage.types'

type CampaignTypeEnum = DisplayCampaignResponseDataAttributesCampaignTypeEnum

export const CampaignTypeEnum = {
  ...DisplayCampaignResponseDataAttributesCampaignTypeEnum,
}

type AdGroupTypeEnum = DisplayAdGroupResponseDataTypeEnum

export const AdGroupTypeEnum = {
  ...DisplayAdGroupResponseDataTypeEnum,
}

function toEventPaymentMethod(paymentType?: string) {
  return paymentType === 'purchase_order'
    ? 'PO'
    : !paymentType || paymentType === 'payment_type_unknown'
    ? null
    : paymentType
}

function getCampaignPageType(isEdit: boolean): EventPageType {
  if (isEdit) return EventPageTypeValues.EDIT_DISPLAY_CAMPAIGN
  return EventPageTypeValues.NEW_DISPLAY_CAMPAIGN
}

function getAdGroupPageType(isEdit: boolean): EventPageType {
  if (isEdit) return EventPageTypeValues.EDIT_DISPLAY_AD_GROUP
  return EventPageTypeValues.NEW_DISPLAY_AD_GROUP
}

export function trackCampaignCancelEvent(
  campaignType: CampaignTypeEnum,
  formValues: DisplayCampaignFormValues,
  campaignId?: string
) {
  const isEdit = !!campaignId
  const event = isEdit ? AnalyticEvents.EDIT_CAMPAIGN_CANCEL : AnalyticEvents.NEW_CAMPAIGN_CANCEL

  let eventParams: AnalyticsParams = {
    campaignName: formValues.name,
    budget: formValues.budget,
    objective: formValues.objective,
    startDate: formValues.startsAt,
    memo: formValues.memo || null,
    goal: formValues.goal,
    goalFormat: formValues.goalFormat,
    paymentMethod: toEventPaymentMethod(formValues.paymentType),
    purchaseOrder: formValues.purchaseOrder || null,
    pageType: getCampaignPageType(isEdit),
  }
  if (campaignType === CampaignTypeEnum.Display) {
    eventParams = {
      ...eventParams,
      endDate: formValues.endsAt,
    }
  }
  if (isEdit) {
    eventParams = {
      ...eventParams,
      campaignUuid: campaignId,
    }
  }

  Analytics.track(event, eventParams)
}

export function trackCampaignSaveEvent(isEdit: boolean, responseData: DisplayCampaignResponseData) {
  const event = isEdit ? AnalyticEvents.EDIT_CAMPAIGN_SAVE : AnalyticEvents.NEW_CAMPAIGN_LAUNCH

  let eventParams: AnalyticsParams = {
    campaignUuid: responseData.id,
    campaignName: responseData.attributes.name,
    budget: responseData.attributes.budget,
    objective: responseData.attributes.objective,
    startDate: responseData.attributes.startsAt,
    goal: responseData.attributes.goal,
    goalFormat: responseData.attributes.goalFormat,
    memo: responseData.attributes.memo || null,
    paymentMethod: toEventPaymentMethod(responseData.attributes.paymentType),
    purchaseOrder: responseData.attributes.purchaseOrder || null,
    pageType: getCampaignPageType(isEdit),
  }
  if ((responseData.attributes.campaignType as string) === CampaignTypeEnum.Display) {
    eventParams = {
      ...eventParams,
      endDate: responseData.attributes.endsAt,
      pacing: (responseData as DisplayCampaignResponseData).attributes.pacingType,
    }
  }

  Analytics.track(event, eventParams)
}

const upcLists = async (
  highlightedProductIds: string[] = [],
  additionalProductIds: string[] = []
) => {
  if (!highlightedProductIds.length && !additionalProductIds.length)
    return {
      upcList: [],
      additionalUpcList: [],
    }

  function productIdsToUpcArray(products: GetProductsResponse, productIds: string[]) {
    return products.data
      .filter(product => productIds.includes(product.id))
      .map(product => product.attributes.upc)
  }

  const productsResponse = await ProductsApi.postProductsProductIds({
    body: {
      productIds: [...highlightedProductIds, ...additionalProductIds],
    },
  })

  return {
    upcList: productIdsToUpcArray(productsResponse, highlightedProductIds),
    additionalUpcList: productIdsToUpcArray(productsResponse, additionalProductIds),
  }
}

export async function trackAdGroupCancelEvent({
  adGroupType,
  campaignId,
  adGroupId,
  formValues,
  authContext,
  campaignGoal,
}: {
  adGroupType: AdGroupTypeEnum
  campaignId: string
  campaignGoal?: string
  adGroupId?: string
  formValues: DisplayAdGroupFormData
  authContext: AuthCtx
}) {
  const isEdit = !!adGroupId && adGroupId !== '0'
  const event = isEdit ? AnalyticEvents.EDIT_AD_GROUP_CANCEL : AnalyticEvents.NEW_AD_GROUP_CANCEL
  const { upcList, additionalUpcList } = await upcLists(
    formValues.productIds ||
      (formValues.brandPage as unknown as BrandPageFormFields).highlightedProductIds,
    formValues.brandPage.additionalProductIds
  )

  let eventParams: AnalyticsParams = {
    campaignUuid: campaignId,
    adGroupName: formValues.name,
    upcList,
    campaignGoal,
    landingPage: {
      pageTitle: formValues.brandPage.title,
      additionalUpcList,
    },
    pageType: getAdGroupPageType(isEdit),
  }
  if (adGroupType === AdGroupTypeEnum.DisplayAdGroup) {
    const displayValues = formValues as DisplayAdGroupFormData
    eventParams = {
      ...eventParams,
      defaultCpc: displayValues.defaultBid,
      adGroupFormat: displayValues.creativeType,
      targeting: {
        targetingType: displayValues.targetingStrategy.targetingType || null,
        targetingKeywords: displayValues.targetingStrategy.keywords || null,
        targetingBehavior:
          getTargetingRuleFromFormData({
            formData: displayValues,
            isTargetingV2Enabled: isTargetingV2Enabled(
              authContext,
              displayValues.creativeType as string as CreativeTypeEnum
            ),
          }) || null,
      },
    }
  }

  if (isEdit) {
    eventParams = {
      ...eventParams,
      adGroupUuid: adGroupId,
    }
  }

  Analytics.track(event, eventParams)
}

const productLists = (brandPage?: DisplayAdGroupResponseDataAttributesBrandPage) => {
  const products = {
    highlightedProductIds: [] as string[],
    additionalProductIds: [] as string[],
  }

  if (!brandPage) return products

  brandPage.blocks.forEach(block => {
    if (block.type === 'block_item_grid.display.v1') {
      products.highlightedProductIds = block.highlightedProductIds
      products.additionalProductIds = block.additionalProductIds || ([] as string[])
    }
  })

  return products
}

export async function trackAdGroupSaveEvent(
  isEdit: boolean,
  responseData: Partial<DisplayAdGroupResponseData>,
  campaignGoal?: string
) {
  const event = isEdit ? AnalyticEvents.EDIT_AD_GROUP_SAVE : AnalyticEvents.NEW_AD_GROUP_SAVE
  const { highlightedProductIds, additionalProductIds } = productLists(
    responseData.attributes?.brandPage
  )
  const { upcList, additionalUpcList } = await upcLists(highlightedProductIds, additionalProductIds)

  let eventParams: AnalyticsParams = {
    campaignUuid: responseData.attributes?.campaignId,
    adGroupUuid: responseData.id,
    campaignGoal,
    adGroupName: responseData.attributes?.name,
    upcList,
    landingPage: {
      pageTitle: responseData.attributes?.brandPage?.title,
      additionalUpcList,
    },
    pageType: getAdGroupPageType(isEdit),
  }
  const displayData = responseData as DisplayAdGroupResponseData
  const targetingType = displayData.attributes.targetingStrategy.keywords?.length
    ? TargetingType.KEYWORD_TARGETING
    : displayData.attributes.targetingStrategy.targetingRule
    ? TargetingType.USER_TARGETING
    : null
  eventParams = {
    ...eventParams,
    defaultCpc: displayData.attributes.defaultBid,
    adGroupFormat: displayData.attributes.creative.type,
    targeting: {
      targetingType,
      targetingKeywords: responseData.attributes?.targetingStrategy.keywords || null,
      targetingBehavior: responseData.attributes?.targetingStrategy.targetingRule || null,
    },
  }

  Analytics.track(event, eventParams)
}
