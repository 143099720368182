import { useState, useEffect } from 'react'

/**
 * Debounce a given value by a specified delay in milliseconds. The returned
 * value of this hook contains is the stable value.
 *
 * The debounced value can be used directly. Example:
 *   // query is bound to a text box
 *   const [query, setQuery] = useState('')
 *   const { products } = useGetProductsSearch(useDebounce(query, 250))
 *
 * To fire other effects upon debouncing the value, monitor the debounced value
 * with a `useEffect`. Example:
 *   // prebounceQuery is bound to a text box
 *   const [prebounceQuery, setPrebounceQuery] = useState('')
 *   const [query, setQuery] = useState('')
 *   const [currentPage, setCurrentPage] = useState(1)
 *   const { products, pagination } = useGetProductsSearch(query, currentPage)
 *   const debouncedQuery = useDebounce(prebounceQuery, 250)
 *
 *   useEffect(() => {
 *     // reset the current page to 1 when new query debounced
 *     setQuery(debouncedQuery)
 *     setCurrentPage(1)
 *   }, [debouncedQuery])
 *
 *  To debounce multiple values, pass in a hash, an array, or values bundled
 *  in a single parameter. Example:
 *    // query is bound to a text box
 *    const [query, setQuery] = useState('')
 *    const [currentPage, setCurrentPage] = useState(1)
 *    // pass an array to useDebounce, a hash would also work
 *    const [bouncedQuery, bouncedCurrentPage ] = useDebounce([ query, currentPage ], 250)
 *    const { products, pagination } = useGetProductsSearch(bouncedQuery, bouncedCurrentPage)
 *    // or spread them to the consuming function directly
 *    const { products, pagination } = useGetProductsSearch(
 *      ...useDebounce([query, currentPage] as [string, number], 250)
 *    )
 *    // or write the consuming function with the params bundled together
 *    const { products, pagination } = useGetProductsSearch(useDebounce({query, page: currentPage}, 250))
 *
 * @param value the target value which needs to become stable
 * @param delay the delay in milliseconds
 */
export default function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // clear the timeout if component is re-rendered/unmounted before handler fired
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
