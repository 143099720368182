import { css } from '@emotion/react'
import { Color, Text } from '@instacart/ids-core'
import { TypographyStyle } from '@instacart/ids-core/dist/types/atoms/typography'
import { HTMLProps } from 'react'

const useStyles = () => {
  return css({
    a: css({
      color: 'inherit',
      textDecoration: 'underline',
      '&:hover, &:visited': {
        color: 'inherit',
      },
    }),
  })
}

export type TermsAndConditionsProps = Pick<HTMLProps<HTMLDivElement>, 'css' | 'children'> & {
  color?: Color
  typography?: TypographyStyle
}

export function TermsAndConditions({
  children,
  color = 'systemGrayscale50',
  typography = 'bodySmall1',
  ...props
}: TermsAndConditionsProps) {
  const styles = useStyles()
  return (
    <Text typography={typography} color={color} css={styles} {...props}>
      {children}
    </Text>
  )
}
