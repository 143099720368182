import { ChevronDownIcon, ChevronUpIcon } from '@instacart/ids-core'
import { PropsWithChildren } from 'react'
import { CommonButtonProps, UseStylesOpts } from '.'
import { LoadingAnimation } from './LoadingAnimation'
import { ICON_TEST_ID } from './test'

export type ButtonContentProps = PropsWithChildren<
  Pick<
    CommonButtonProps,
    'icon' | 'iconSize' | 'iconPosition' | 'loading' | 'open' | 'openIndicator'
  >
> &
  Pick<UseStylesOpts, 'textColor'>

export function ButtonContent({
  children,
  icon: Icon,
  iconSize,
  iconPosition,
  loading,
  open,
  openIndicator,
  textColor,
}: ButtonContentProps) {
  const OpenIcon = open ? ChevronUpIcon : ChevronDownIcon

  const iconElement = Icon && (
    <Icon className="button-icon" size={iconSize} data-testid={ICON_TEST_ID} />
  )

  if (loading) return <LoadingAnimation color={textColor} />

  return (
    <>
      {iconPosition === 'left' && iconElement}
      {children}
      {iconPosition === 'right' && iconElement}
      {openIndicator && <OpenIcon className="open-indicator" size={16} />}
    </>
  )
}
