import { FormEvent, ReactNode } from 'react'
import { Modal, ModalProps, ModalButtons, ModalContent, ButtonProps } from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'

export interface FormSubmitModalProps extends ModalProps {
  modalTitleId: GenericMessageDescriptor
  modalPrimaryCtaId?: GenericMessageDescriptor
  modalPrimaryCtaProps?: ButtonProps
  modalButtons?: ReactNode
  onFormSubmit?: (e?: FormEvent<HTMLFormElement> | undefined) => void
}

export function FormSubmitModal({
  children,
  modalState,
  modalButtons,
  modalTitleId,
  modalPrimaryCtaId,
  modalPrimaryCtaProps,
  allowContentToScroll,
  onFormSubmit,
  onClose,
  ...rest
}: FormSubmitModalProps) {
  return (
    <Modal
      modalState={modalState}
      title={modalTitleId}
      onClose={onClose}
      allowContentToScroll={allowContentToScroll}
      {...rest}
    >
      <form onSubmit={onFormSubmit}>
        <ModalContent allowScroll={allowContentToScroll}>{children}</ModalContent>
        {modalButtons ?? (
          <ModalButtons
            onClose={onClose}
            primaryButtonLabel={modalPrimaryCtaId || 'common.save'}
            primaryButtonProps={{
              type: 'submit',
              ...modalPrimaryCtaProps,
            }}
          />
        )}
      </form>
    </Modal>
  )
}
