import { useCallback, useEffect, useRef } from 'react'
import { usePropRef } from 'common/usePropRef'

type Timeout = ReturnType<typeof setTimeout>

export default function useDebouncedCallback<Args extends unknown[]>(
  callback: (...args: Args) => void,
  delay: number
) {
  const callbackRef = usePropRef(callback)
  const timeout = useRef<Timeout>()

  useEffect(
    () => () => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = undefined
    },
    [delay]
  )

  return useCallback(
    (...args: Args) => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        timeout.current = undefined
        callbackRef.current.apply(null, args)
      }, delay)
    },
    [delay, callbackRef]
  )
}
