import { ThemeProvider } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import initAnalyticsProvider from 'common/analytics/AnalyticsProvider'
import { NotificationsProvider } from 'components'
import Page from 'landing/Page'
import messages from 'locales/en-US.json'

const Landing = () => {
  useEffect(initAnalyticsProvider, [])

  return (
    <Radium.StyleRoot>
      <IntlProvider locale="en-US" messages={messages}>
        <ThemeProvider>
          <NotificationsProvider>
            <Page />
          </NotificationsProvider>
        </ThemeProvider>
      </IntlProvider>
    </Radium.StyleRoot>
  )
}

export default Landing
