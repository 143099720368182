import { css } from '@emotion/react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import { TargetingRuleContext, TargetingSegmentContext } from '../hooks'
import BrandOptionsDropdown from './BrandOptionsDropdown'
import TargetedElementsLine from './TargetedElementsLine'

const brandsContainerStyle = css({
  width: '100%',
})

export default function BrandsView() {
  const { values, setFieldValue } = useFormikContext()
  const { isReadOnly, isReviewing, brands } = useContext(TargetingRuleContext)
  const { fieldsPrefix } = useContext(TargetingSegmentContext)
  const brandsFieldName = `${fieldsPrefix}brands`
  const selectedBrandIds: string[] = get(values, brandsFieldName, [])
  const brandNameById = brands.reduce((acc, { brandId, displayName }) => {
    acc[brandId] = displayName
    return acc
  }, {} as { [key: string]: string })

  return (
    <div css={brandsContainerStyle}>
      <TargetedElementsLine
        entities={selectedBrandIds.map(brandId => ({
          name: brands.find(brand => brand.brandId === brandId)?.displayName || '',
        }))}
        errorMessageId="pages.displayProduct.common.targetingRule.segment.brands.validation"
        fieldName={brandsFieldName}
        removeElementByName={brandNameToRemove =>
          setFieldValue(
            brandsFieldName,
            selectedBrandIds.filter(brandId => brandNameById[brandId] !== brandNameToRemove)
          )
        }
      />
      {!isReadOnly && !isReviewing && <BrandOptionsDropdown />}
    </div>
  )
}
