// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutPrototypeCampaignsIdParamBlockHeroBannerDisplay,
  instanceOfPutPrototypeCampaignsIdParamBlockHeroBannerDisplay,
  PutPrototypeCampaignsIdParamBlockHeroBannerDisplayFromJSON,
  PutPrototypeCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped,
  PutPrototypeCampaignsIdParamBlockHeroBannerDisplayToJSON,
} from './PutPrototypeCampaignsIdParamBlockHeroBannerDisplay'
import {
  PutPrototypeCampaignsIdParamBlockItemGridDisplay,
  instanceOfPutPrototypeCampaignsIdParamBlockItemGridDisplay,
  PutPrototypeCampaignsIdParamBlockItemGridDisplayFromJSON,
  PutPrototypeCampaignsIdParamBlockItemGridDisplayFromJSONTyped,
  PutPrototypeCampaignsIdParamBlockItemGridDisplayToJSON,
} from './PutPrototypeCampaignsIdParamBlockItemGridDisplay'

/**
 * @type PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks
 *
 * @export
 */
export type PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & PutPrototypeCampaignsIdParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & PutPrototypeCampaignsIdParamBlockItemGridDisplay)

export function PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocksFromJSON(
  json: any
): PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks {
  return PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocksFromJSONTyped(json, false)
}

export function PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PutPrototypeCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PutPrototypeCampaignsIdParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocksToJSON(
  value?: PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PutPrototypeCampaignsIdParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PutPrototypeCampaignsIdParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PutPrototypeCampaignsIdParamCampaignAdGroupBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
