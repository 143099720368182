// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterest } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterest'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterestFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterestFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterestToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterest'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchase } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchase'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchaseFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchaseFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchaseToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchase'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
 */
export interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting {
  /**
   * User purchase targeting
   * @type {Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchase>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  purchase?: Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchase>
  /**
   * User interest targeting
   * @type {Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterest>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  interest?: Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterest>
  /**
   * New to Instacart targeting, set to true to target new users
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  newToInstacart?: boolean
  /**
   * Targeting everyone, set to true to target everyone
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  targetingEveryone?: boolean
  /**
   * Target users based on license type.
   * @type {Array<string>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  buyerLicense?: Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingBuyerLicenseEnum>
  /**
   * Target users within specific US states
   * @type {Array<string>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting
   */
  geography?: Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingGeographyEnum>
}

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingBuyerLicenseEnum =
  {
    OnPremises: 'alcohol_on_premises',
    OffPremises: 'alcohol_off_premises',
    OnAndOffPremises: 'alcohol_on_and_off_premises',
  } as const
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingBuyerLicenseEnum =
  typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingBuyerLicenseEnum[keyof typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingBuyerLicenseEnum]

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingGeographyEnum =
  {
    Aa: 'AA',
    Ae: 'AE',
    Ak: 'AK',
    Al: 'AL',
    Ap: 'AP',
    Ar: 'AR',
    As: 'AS',
    Az: 'AZ',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    Dc: 'DC',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Gu: 'GU',
    Hi: 'HI',
    Ia: 'IA',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Ma: 'MA',
    Md: 'MD',
    Me: 'ME',
    Mi: 'MI',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Ms: 'MS',
    Mt: 'MT',
    Nc: 'NC',
    Nd: 'ND',
    Ne: 'NE',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Nv: 'NV',
    Ny: 'NY',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Pr: 'PR',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Um: 'UM',
    Ut: 'UT',
    Va: 'VA',
    Vi: 'VI',
    Vt: 'VT',
    Wa: 'WA',
    Wi: 'WI',
    Wv: 'WV',
    Wy: 'WY',
  } as const
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingGeographyEnum =
  typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingGeographyEnum[keyof typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingGeographyEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    purchase: !exists(json, 'purchase')
      ? undefined
      : (json['purchase'] as Array<any>).map(
          GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchaseFromJSON
        ),
    interest: !exists(json, 'interest')
      ? undefined
      : (json['interest'] as Array<any>).map(
          GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterestFromJSON
        ),
    newToInstacart: !exists(json, 'new_to_instacart') ? undefined : json['new_to_instacart'],
    targetingEveryone: !exists(json, 'targeting_everyone') ? undefined : json['targeting_everyone'],
    buyerLicense: !exists(json, 'buyer_license') ? undefined : json['buyer_license'],
    geography: !exists(json, 'geography') ? undefined : json['geography'],
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargeting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    purchase:
      value.purchase === undefined
        ? undefined
        : (value.purchase as Array<any>).map(
            GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingPurchaseToJSON
          ),
    interest:
      value.interest === undefined
        ? undefined
        : (value.interest as Array<any>).map(
            GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyUserTargetingInterestToJSON
          ),
    new_to_instacart: value.newToInstacart,
    targeting_everyone: value.targetingEveryone,
    buyer_license: value.buyerLicense,
    geography: value.geography,
  }
}
