import { FC, memo, useRef } from 'react'
import { ReCaptchaAction, useReCaptchaRenderer } from 'common/reCaptcha/reCaptcha.hooks'

interface ReCaptchaCheckBoxProps {
  action: ReCaptchaAction
  onLoad?: () => void
  onError?: () => void
  onChange: (value: string) => void
  setResetHandler?: (resetHandler: () => void) => void
}

export const ReCaptchaCheckBox: FC<React.PropsWithChildren<ReCaptchaCheckBoxProps>> = memo(
  ({ action, onLoad, onChange, onError, setResetHandler }) => {
    // Store a reference to the DOM node we want to render the reCaptcha inside
    const ref = useRef<HTMLDivElement>(null)

    // Render the reCaptcha inside the ref
    useReCaptchaRenderer({ ref, action, onLoad, onChange, onError, setResetHandler })

    return <div ref={ref} />
  }
)
