interface FormattedMultiplierProps {
  children: number
}

const FormattedMultiplier: React.FunctionComponent<
  React.PropsWithChildren<FormattedMultiplierProps>
> = ({ children }) => {
  const multiplier = children.toFixed(1)
  return <>{`${multiplier}x`}</>
}

export default FormattedMultiplier
