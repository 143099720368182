import { css } from '@emotion/react'
import { InformationIcon, IssueIcon, spacing, useTheme } from '@instacart/ids-core'
import { uniqBy } from 'lodash'
import { useIntl } from 'common'
import { MessageIdType } from 'locales/types'
import {
  INFO_ICON_TESTID,
  ISSUE_ICON_TESTID,
  REJECTED_DECISION,
} from 'pages/DisplayProduct/Review/utils'
import { Decision, ApprovalInfoMap, RejectionLabel } from 'pages/DisplayProduct/types'

interface FieldValueRejectionReasonsProps {
  approvalInfo: ApprovalInfoMap | undefined
  values: string[]
  decision: Decision
  noMarginTop?: boolean
}
export const useStyles = () => {
  const theme = useTheme()
  return {
    rejectionLabelRow: css({
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.systemDetrimentalRegular,
    }),
    rejectionLabelIcon: css({
      fill: theme.colors.systemDetrimentalRegular,
    }),
    informationLabelRow: css({
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.brandHighlightRegular,
    }),
    informationLabelIcon: css({
      fill: theme.colors.brandHighlightRegular,
    }),
    feedbackRow: css({
      display: 'flex',
      alignItems: 'center',
      marginTop: spacing.s8,
    }),
  }
}

export default function FieldValueRejectionReasons({
  approvalInfo,
  values,
  decision,
  noMarginTop,
}: FieldValueRejectionReasonsProps) {
  const styles = useStyles()
  const { formatMessage } = useIntl()

  if (!approvalInfo || values.length === 0) return null

  const labels: RejectionLabel[] = values.flatMap(v => approvalInfo?.[v]?.labels ?? [])
  const uniqueLabels = uniqBy(labels, label => label.label_id)

  if (uniqueLabels.length === 0) return null

  const icon =
    decision === REJECTED_DECISION ? (
      <IssueIcon css={styles.rejectionLabelIcon} data-testid={ISSUE_ICON_TESTID} />
    ) : (
      <InformationIcon css={styles.informationLabelIcon} data-testid={INFO_ICON_TESTID} />
    )

  return (
    <div
      css={[
        decision === REJECTED_DECISION ? styles.rejectionLabelRow : styles.informationLabelRow,
        styles.feedbackRow,
        noMarginTop &&
          css`
            margin-top: 0;
          `,
      ]}
    >
      {icon}
      {uniqueLabels
        .map((label: RejectionLabel) =>
          formatMessage({
            id: `integrity.rejectionLabels.${label?.label_id}` as MessageIdType,
          })
        )
        .join(', ')}
    </div>
  )
}
