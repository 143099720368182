import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import { Modal, ModalState, ModalHeader, PrimaryButton } from 'components/ids-ads'
import { MessageIdType } from 'locales/types'

export type SubmittedModalProps = {
  modalState: ModalState
  onCloseIconClick?: () => void
  onExit: () => void
  title?: MessageIdType
  description?: MessageIdType
  ctaButton?: MessageIdType
}

const useStyles = (hasDescription?: boolean) => {
  const theme = useTheme()
  return {
    modalContainer: css({
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    }),
    modalImage: css({
      width: 172,
      height: 172,
      marginBottom: spacing.s32,
    }),
    modalCloseIcon: css({
      display: 'flex',
      justifyContent: 'flex-end',
    }),
    modalTitle: css({
      ...theme.typography.bodyLarge1,
      color: theme.colors.systemGrayscale70,
      marginBottom: hasDescription ? spacing.s12 : spacing.s32,
    }),
    modalBody: css({
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemGrayscale50,
      marginBottom: spacing.s32,
    }),
  }
}

export const SubmittedModal = ({
  modalState,
  onExit,
  onCloseIconClick,
  title,
  description,
  ctaButton,
}: SubmittedModalProps) => {
  const styles = useStyles(!!description)
  const onClose = () => {
    modalState.hide()
    onExit?.()
  }

  return (
    <Modal allowClose={false} modalState={modalState}>
      {onCloseIconClick && (
        <div css={styles.modalCloseIcon}>
          <ModalHeader hideCloseIcon={false} onClose={onCloseIconClick} />
        </div>
      )}
      <div css={styles.modalContainer}>
        <img alt="submitted" css={styles.modalImage} src="/images/review/review_submitted.svg" />
        <div data-testid="modal-title" css={styles.modalTitle}>
          <FormattedMessage id={title ?? 'pages.reviewHeader.submittedModal.title'} />
        </div>
        {description && (
          <div data-testid="modal-description" css={styles.modalBody}>
            <FormattedMessage id={description} useBreakFormatting />
          </div>
        )}
        <PrimaryButton data-testid="go-to-campaigns" onClick={onClose}>
          <FormattedMessage id={ctaButton ?? 'common.goToCampaigns'} />
        </PrimaryButton>
      </div>
    </Modal>
  )
}
