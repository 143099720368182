import { css } from '@emotion/react'
import { Text, Theme, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import { TargetingSegmentContext } from '../hooks'
import { CategoryTreeNode, countVisibleLeafsSelected } from '../utils'

export interface SubcategoriesCountProps {
  node: CategoryTreeNode
}

const countContainerStyle = (theme: Theme) =>
  css({
    marginLeft: 8,
    backgroundColor: theme.colors.brandHighlightLight,
    borderRadius: 14,
    maxHeight: 22,
  })

const countTextStyle = (theme: Theme) => ({
  color: theme.colors.brandHighlightRegular,
  padding: '2px 8px',
})

export default function SubcategoriesCount({ node }: SubcategoriesCountProps) {
  const theme = useTheme()

  const { fieldsPrefix } = useContext(TargetingSegmentContext)

  const categoriesFieldName = `${fieldsPrefix}categories`
  const { values } = useFormikContext()

  if (!node.children.length) {
    return null
  }

  const selectedCategories: string[] = get(values, categoriesFieldName, [])
  const count = countVisibleLeafsSelected(node, selectedCategories)

  if (!count) {
    return null
  }
  return (
    <div css={countContainerStyle(theme)}>
      <Text style={countTextStyle(theme)} typography="bodySmall1">
        {count}
      </Text>
    </div>
  )
}
