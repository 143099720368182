import { ReactNode } from 'react'
import { MfaVerificationForm } from 'components/MultiFactorAuth/components/MfaVerificationForm'
import { useBoolean } from 'landing/components/hooks'
import AuthenticatorManualSetup from 'pages/Settings/MultiFactorAuth/components/AuthenticatorManualSetup'
import {
  MfaVerificationProps,
  MultiFactorAuthAction,
  MultiFactorAuthType,
} from '../utils/multiFactorAuth.types'

const BackupCodeVerificationForm = ({
  props,
  onOptionClick,
}: {
  props: MfaVerificationProps
  onOptionClick: () => void
}) => {
  return (
    <MfaVerificationForm
      {...props}
      standardCode={false}
      onBack={onOptionClick}
      descriptionMessageId="pages.multiFactorAuth.description.authenticatorBackupCode"
      inputMessageId="pages.multiFactorAuth.password.title.authenticatorBackupCode"
    />
  )
}

export const AuthenticatorMfaVerification = (props: MfaVerificationProps) => {
  const { action, mfaFactor } = props
  const [displayOptionContent, setDisplayOptionContentTrue, setDisplayOptionContentFalse] =
    useBoolean(false)

  const optionContent: Record<MultiFactorAuthAction, () => ReactNode> = {
    [MultiFactorAuthAction.Setup]: () => (
      <AuthenticatorManualSetup mfaFactor={mfaFactor} onClick={setDisplayOptionContentFalse} />
    ),
    [MultiFactorAuthAction.Login]: () => (
      <BackupCodeVerificationForm props={props} onOptionClick={setDisplayOptionContentFalse} />
    ),
    [MultiFactorAuthAction.Action]: () => (
      <BackupCodeVerificationForm props={props} onOptionClick={setDisplayOptionContentFalse} />
    ),
  }

  if (mfaFactor.mfaType !== MultiFactorAuthType.Authenticator) {
    return <></>
  }

  return (
    <>
      {!displayOptionContent && (
        <MfaVerificationForm {...props} onOptionClick={setDisplayOptionContentTrue} />
      )}
      {displayOptionContent && optionContent[action]()}
    </>
  )
}
