import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'

const useLinkStyles = () => {
  const theme = useTheme()

  return {
    a: css({
      color: theme.colors.systemGrayscale70,
      textDecoration: 'underline',
      '&:hover, &:visited': {
        color: 'inherit',
      },
    }),
  }
}

export default useLinkStyles
