// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplay,
  instanceOfPutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplay,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayFromJSON,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayToJSON,
} from './PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplay'
import {
  PutDisplayAdGroupsIdEnableParamBlockItemGridDisplay,
  instanceOfPutDisplayAdGroupsIdEnableParamBlockItemGridDisplay,
  PutDisplayAdGroupsIdEnableParamBlockItemGridDisplayFromJSON,
  PutDisplayAdGroupsIdEnableParamBlockItemGridDisplayFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBlockItemGridDisplayToJSON,
} from './PutDisplayAdGroupsIdEnableParamBlockItemGridDisplay'

/**
 * @type PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks
 *
 * @export
 */
export type PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks =
  | ({
      discriminator: 'block_hero_banner.v1'
    } & PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & PutDisplayAdGroupsIdEnableParamBlockItemGridDisplay)

export function PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocksFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks {
  return PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocksFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PutDisplayAdGroupsIdEnableParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocksToJSON(
  value?: PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PutDisplayAdGroupsIdEnableParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PutDisplayAdGroupsIdEnableParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
