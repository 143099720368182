import { ButtonBase, Color, spacing, StylesOf, SVGIconProps, Theme } from '@instacart/ids-core'
import { ComponentType, ReactNode } from 'react'
import toPx from 'common/toPx'

export const DEFAULT_ICON_SIZE = 24
export const DEFAULT_COMPACT_ICON_SIZE = 16

export const STATE_TRANSITION = 'border-color 0.1s linear, background-color 0.1s linear'

export type IconPosition = 'left' | 'right'

export interface UseStylesOpts {
  borderColor: Color | string | null
  textColor: Color | string
  normalBackground: Color | string | null
  hoverBackground: Color | string | null
  hoverBorderColor?: Color | string | null
  activeBackground: Color | string | null
  activeBorderColor?: Color | string | null
  baseButtonStyleOverrides?: StylesOf<typeof ButtonBase>
}

export interface CommonButtonProps {
  children?: ReactNode
  compact?: boolean
  loading?: boolean
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  iconSize?: number
  iconPosition?: IconPosition
  openIndicator?: boolean
  open?: boolean
  tooltip?: ReactNode
}

export interface UseStylesInternal {
  hasIcon?: boolean
  iconOnly?: boolean
  iconSize: number
  iconPosition: IconPosition
  compact?: boolean
  loading?: boolean
  openIndicator?: boolean
}

export function resolveColor(theme: Theme, color: Color | string | null): string {
  if (!color) return 'transparent'
  if (color in theme.colors) {
    return theme.colors[color as Color]
  }
  return color
}

export function defaultIconSize(compact?: boolean) {
  return compact ? DEFAULT_COMPACT_ICON_SIZE : DEFAULT_ICON_SIZE
}

export function buttonPadding({
  compact,
  hasIcon,
  iconOnly,
  iconPosition,
  loading,
  openIndicator,
}: Pick<
  UseStylesInternal,
  'compact' | 'hasIcon' | 'iconOnly' | 'iconPosition' | 'loading' | 'openIndicator'
>) {
  if (iconOnly) return compact ? 5 : 7

  const reducePaddingRight = !loading && (openIndicator || (hasIcon && iconPosition === 'right'))
  const rightPadding = reducePaddingRight ? spacing.s12 : spacing.s16

  const reducePaddingLeft = !loading && hasIcon && iconPosition === 'left'
  const leftPadding = reducePaddingLeft ? spacing.s12 : spacing.s16

  const verticalPaddingBase = compact ? spacing.s4 : spacing.s8

  return toPx`${verticalPaddingBase - 1} ${rightPadding} ${verticalPaddingBase + 1} ${leftPadding}`
}
