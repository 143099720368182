import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'

export interface CardSubtitleProps {
  children: React.ReactNode
}

function useStyles() {
  const theme = useTheme()

  return {
    root: css({
      color: theme.colors.systemGrayscale70,
      ...theme.typography.subtitleMedium,
      marginTop: '1rem',
      marginBottom: '0.5rem',
    }),
  }
}

export function CardSubtitle({ children }: CardSubtitleProps) {
  const styles = useStyles()

  return <h2 css={styles.root}>{children}</h2>
}
