import { Global } from '@emotion/react'
import { Variant, BackdropProps } from '@instacart/ids-core'
import {
  ModalBase as ModalBaseComp,
  ModalBaseProps as ModalBaseCompProps,
  useModalState as useModalBaseState,
} from '@instacart/ids-customers'
import { uniqueId } from 'lodash'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import { DialogInitialState, DialogStateReturn as ModalState } from 'reakit'
import { useIntl } from 'common'
import { GenericMessageDescriptor } from 'locales/types'
import { ModalHeader } from './ModalHeader'

export { ModalState }
export const useModalState = (initialState?: Omit<DialogInitialState, 'modal'>) =>
  useModalBaseState({ ...initialState, animated: true })

export interface CommonModalProps extends Omit<Variant<typeof ModalBaseComp>, 'title' | 'modal'> {
  children: React.ReactNode
  modalState: ModalState
  onClose?(): void
  title?: GenericMessageDescriptor
  width?: string | number
  hideCloseIcon?: boolean
  /** Allow the modal to be hideOnClickOutside and hideOnEsc */
  allowClose?: boolean
  allowContentToScroll?: boolean
  allowBackgroundToScroll?: boolean
  ariaLabel?: string
  testId?: string
  titleTestId?: string
  hideTitle?: boolean
}

export interface ModalBaseProps extends CommonModalProps {
  styles: { modal: ModalBaseCompProps['styles']; root: BackdropProps['styles'] }
}

export const ModalBase = forwardRef<HTMLDivElement, ModalBaseProps>(function ModalBase(
  {
    children,
    modalState,
    onClose,
    title,
    hideCloseIcon = false,
    hideTitle = false,
    allowClose = true,
    testId,
    titleTestId,
    styles,
    ariaLabel,
    allowBackgroundToScroll = false,
  },
  ref
) {
  const { genericFormatMessage } = useIntl()
  const dialogTitle = title ? genericFormatMessage(title) : uniqueId('dialogTitle-')

  return (
    <ModalBaseComp
      modal={modalState}
      ref={ref}
      styles={styles.modal}
      backdropStyles={styles.root}
      hideOnClickOutside={allowClose}
      hideOnEsc={allowClose}
      aria-label={ariaLabel || dialogTitle}
    >
      {
        // conditonally rendering modal content https://reakit.io/docs/disclosure/#conditionally-rendering
        ((modalProps: HTMLAttributes<HTMLDivElement>) =>
          modalState.visible && (
            <div {...modalProps} data-testid={testId || 'modal-container'}>
              {title && (
                <ModalHeader
                  onClose={onClose}
                  hideCloseIcon={hideCloseIcon}
                  titleTestId={titleTestId || 'modal-container-title'}
                >
                  {hideTitle ? <div /> : genericFormatMessage(title)}
                </ModalHeader>
              )}
              {children}
              {/* To prevent body from scrolling when modal shows up */}
              <Global
                styles={{
                  body: {
                    overflow: allowBackgroundToScroll ? 'auto' : 'hidden',
                  },
                }}
              />
            </div>
          )) as unknown as ReactNode
      }
    </ModalBaseComp>
  )
})
