import { matchPath } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

export const ACCOUNT_SCOPED_PATH = '/account/:accountId'

export interface AccountScopedUrlParams {
  accountId: string
}

export const accountPathPrefix = () => {
  const match = matchPath(window.location.pathname, ACCOUNT_SCOPED_PATH)
  return match ? match.url : ''
}

export const useAccountPathPrefix = () => {
  const accountPathMatch = useRouteMatch<AccountScopedUrlParams>(ACCOUNT_SCOPED_PATH)
  return accountPathMatch !== null ? accountPathMatch.url : ''
}

export const useAccountPathId = () => {
  const accountPathMatch = useRouteMatch<AccountScopedUrlParams>(ACCOUNT_SCOPED_PATH)
  return accountPathMatch?.params.accountId
}

export const useGetCampaignIdFromUrl = () =>
  useRouteMatch<{ campaignId: string }>()?.params?.campaignId

export const useGetAdGroupIdFromUrl = () =>
  useRouteMatch<{ adGroupId: string }>()?.params?.adGroupId

export const generateAccountSpecificPaths = (path: string) => [
  path,
  `${ACCOUNT_SCOPED_PATH}${path}`,
]
