// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MultiFactorAuthCreateResponseDataAttributes
 */
export interface MultiFactorAuthCreateResponseDataAttributes {
  /**
   * MFA Factor Type
   * @type {string}
   * @memberof MultiFactorAuthCreateResponseDataAttributes
   */
  mfaType: MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum
  /**
   * Authenticator app provisioning URL
   * @type {string}
   * @memberof MultiFactorAuthCreateResponseDataAttributes
   */
  provisioningUrl?: string
  /**
   * Authenticator MFA backup codes
   * @type {Array<string>}
   * @memberof MultiFactorAuthCreateResponseDataAttributes
   */
  backupCodes?: Array<string>
}

/**
 * @export
 */
export const MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum = {
  Authenticator: 'authenticator',
  Email: 'email',
} as const
export type MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum =
  typeof MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum[keyof typeof MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum]

/**
 * Check if a given object implements the MultiFactorAuthCreateResponseDataAttributes interface.
 */
export function instanceOfMultiFactorAuthCreateResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'mfaType' in value

  return isInstance
}

export function MultiFactorAuthCreateResponseDataAttributesFromJSON(
  json: any
): MultiFactorAuthCreateResponseDataAttributes {
  return MultiFactorAuthCreateResponseDataAttributesFromJSONTyped(json, false)
}

export function MultiFactorAuthCreateResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MultiFactorAuthCreateResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mfaType: json['mfa_type'],
    provisioningUrl: !exists(json, 'provisioning_url') ? undefined : json['provisioning_url'],
    backupCodes: !exists(json, 'backup_codes') ? undefined : json['backup_codes'],
  }
}

export function MultiFactorAuthCreateResponseDataAttributesToJSON(
  value?: MultiFactorAuthCreateResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mfa_type: value.mfaType,
    provisioning_url: value.provisioningUrl,
    backup_codes: value.backupCodes,
  }
}
