// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamCreativeSnapshot
 */
export interface PutDisplayAdGroupsIdEnableParamCreativeSnapshot {
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamCreativeSnapshot
   */
  type: string
  /**
   * Creative action main item grid product ids
   * @type {Array<string>}
   * @memberof PutDisplayAdGroupsIdEnableParamCreativeSnapshot
   */
  mainItemGridProductIds?: Array<string>
  /**
   * Creative tagline
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamCreativeSnapshot
   */
  tagline?: string
}

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamCreativeSnapshot interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamCreativeSnapshot(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamCreativeSnapshot {
  return PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamCreativeSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProductIds: !exists(json, 'main_item_grid_product_ids')
      ? undefined
      : json['main_item_grid_product_ids'],
    tagline: !exists(json, 'tagline') ? undefined : json['tagline'],
  }
}

export function PutDisplayAdGroupsIdEnableParamCreativeSnapshotToJSON(
  value?: PutDisplayAdGroupsIdEnableParamCreativeSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_product_ids: value.mainItemGridProductIds,
    tagline: value.tagline,
  }
}
