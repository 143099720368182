/* starting point for all js files that goes in landing page */

import * as ReactDOM from 'react-dom'
import { isWebpackDevServer } from 'common/utils'
import { BaseStyles } from 'components/BaseStyles'
import Landing from 'landing/Landing'
import { initAhoy } from '../common/ahoyInit'

const appRoot = window.document.getElementById('__ads_root')

ReactDOM.hydrate(
  <>
    {isWebpackDevServer() && <BaseStyles />}
    <Landing />
  </>,
  appRoot
)

initAhoy()

export default Landing
