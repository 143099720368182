// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AsyncTaskResponseLinks
 */
export interface AsyncTaskResponseLinks {
  /**
   * URL of the async task.
   * @type {string}
   * @memberof AsyncTaskResponseLinks
   */
  taskUrl: string
}

/**
 * Check if a given object implements the AsyncTaskResponseLinks interface.
 */
export function instanceOfAsyncTaskResponseLinks(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'taskUrl' in value

  return isInstance
}

export function AsyncTaskResponseLinksFromJSON(json: any): AsyncTaskResponseLinks {
  return AsyncTaskResponseLinksFromJSONTyped(json, false)
}

export function AsyncTaskResponseLinksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AsyncTaskResponseLinks {
  if (json === undefined || json === null) {
    return json
  }
  return {
    taskUrl: json['task_url'],
  }
}

export function AsyncTaskResponseLinksToJSON(value?: AsyncTaskResponseLinks | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    task_url: value.taskUrl,
  }
}
