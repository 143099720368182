import { useContext, createContext, useState, ReactNode, useEffect } from 'react'
import { NotificationType, useNotifier } from 'context'
import { ProductCache } from 'pages/BrandPage/BrandPageContext'
import { AdminBrandPagesApi } from 'service/apis/AdminBrandPagesApi'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { StandaloneBrandPageResponse } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponse'
import { StandaloneBrandPageResponseData } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseData'
import { DEFAULT_FIELD_NAMES } from './constants'

type BrandPageReviewContextState = {
  brandPage?: StandaloneBrandPageResponseData
  getBrandPage: (id: string) => Promise<void>
  expandBrandPageSections: boolean
  setExpandBrandPageSections: (expandBrandPageSections: boolean) => void
  setCurrentAdGroupName: undefined
  productCache: ProductCache
  handleAddtoProductCache: (products: GetProductsResponseData[]) => void
  fieldNames: string[]
  setFieldNames: (fieldNames: string[]) => void
}

export const BrandPageReviewContext = createContext<BrandPageReviewContextState | undefined>(
  undefined
)

export const useBrandPageReviewContext = () => {
  const contextState = useContext(BrandPageReviewContext)
  if (!contextState) {
    throw new Error(
      'useBrandPageReviewContext should be used in BrandPageReviewContextProvider component'
    )
  }
  return contextState
}

const getCustomFieldNames = (size: number) => [...Array(size).keys()].map(s => `blocks[${s + 2}]`)

interface BrandPageReviewContextProviderProps {
  children: ReactNode
}

function BrandPageReviewContextProvider({ children }: BrandPageReviewContextProviderProps) {
  const notifier = useNotifier()
  const [fieldNames, setFieldNames] = useState<string[]>([])
  const [brandPage, setBrandPage] = useState<StandaloneBrandPageResponseData | undefined>(undefined)
  const [expandBrandPageSections, setExpandBrandPageSections] = useState(false)
  const [productCache, setProductCache] = useState<ProductCache>({})

  useEffect(() => {
    if (brandPage && fieldNames.length === 0) {
      const customBlocks = brandPage?.attributes.blocks.slice(2)
      const customFieldNames = customBlocks ? getCustomFieldNames(customBlocks.length) : []
      const newFieldNames = DEFAULT_FIELD_NAMES.concat(customFieldNames)
      setFieldNames(newFieldNames)
    }
  }, [brandPage, fieldNames])
  // eslint-disable-next-line react-hooks/exhaustive-deps

  async function callApi<Response extends StandaloneBrandPageResponse>(
    fn: () => Promise<Response>
  ): Promise<Response['data'] | undefined> {
    try {
      const apiResponse = await fn()
      return apiResponse.data || apiResponse.meta.status
    } catch (err) {
      notifier.sendNotification({
        messageId: 'common.error.generic',
        type: NotificationType.ERROR,
      })
    }
    return undefined
  }

  async function getBrandPage(id: string) {
    const response = await callApi(() => AdminBrandPagesApi.getAdminBrandPagesId({ id }))
    setBrandPage(response)
  }

  const handleAddtoProductCache = (products: GetProductsResponseData[]) => {
    const newProducts: ProductCache = products.reduce(
      (agg, r) => ({
        ...agg,
        [r.id]: r,
      }),
      {}
    )

    setProductCache({
      ...productCache,
      ...newProducts,
    })
  }

  const value: BrandPageReviewContextState = {
    brandPage,
    getBrandPage,
    expandBrandPageSections,
    setExpandBrandPageSections,
    setCurrentAdGroupName: undefined,
    productCache,
    handleAddtoProductCache,
    fieldNames,
    setFieldNames,
  }

  return <BrandPageReviewContext.Provider value={value}>{children}</BrandPageReviewContext.Provider>
}

export default BrandPageReviewContextProvider
