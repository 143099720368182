// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAuthParamDataAttributesFeatureRemindersAttributes } from './GetAuthParamDataAttributesFeatureRemindersAttributes'
import {
  GetAuthParamDataAttributesFeatureRemindersAttributesFromJSON,
  GetAuthParamDataAttributesFeatureRemindersAttributesFromJSONTyped,
  GetAuthParamDataAttributesFeatureRemindersAttributesToJSON,
} from './GetAuthParamDataAttributesFeatureRemindersAttributes'

/**
 *
 * @export
 * @interface GetAuthParamDataAttributesFeatureReminders
 */
export interface GetAuthParamDataAttributesFeatureReminders {
  /**
   * Feature Reminder Id
   * @type {string}
   * @memberof GetAuthParamDataAttributesFeatureReminders
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAuthParamDataAttributesFeatureReminders
   */
  type: GetAuthParamDataAttributesFeatureRemindersTypeEnum
  /**
   *
   * @type {GetAuthParamDataAttributesFeatureRemindersAttributes}
   * @memberof GetAuthParamDataAttributesFeatureReminders
   */
  attributes: GetAuthParamDataAttributesFeatureRemindersAttributes
}

/**
 * @export
 */
export const GetAuthParamDataAttributesFeatureRemindersTypeEnum = {
  FeatureReminder: 'feature_reminder',
} as const
export type GetAuthParamDataAttributesFeatureRemindersTypeEnum =
  typeof GetAuthParamDataAttributesFeatureRemindersTypeEnum[keyof typeof GetAuthParamDataAttributesFeatureRemindersTypeEnum]

/**
 * Check if a given object implements the GetAuthParamDataAttributesFeatureReminders interface.
 */
export function instanceOfGetAuthParamDataAttributesFeatureReminders(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAuthParamDataAttributesFeatureRemindersFromJSON(
  json: any
): GetAuthParamDataAttributesFeatureReminders {
  return GetAuthParamDataAttributesFeatureRemindersFromJSONTyped(json, false)
}

export function GetAuthParamDataAttributesFeatureRemindersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAuthParamDataAttributesFeatureReminders {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAuthParamDataAttributesFeatureRemindersAttributesFromJSON(json['attributes']),
  }
}

export function GetAuthParamDataAttributesFeatureRemindersToJSON(
  value?: GetAuthParamDataAttributesFeatureReminders | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAuthParamDataAttributesFeatureRemindersAttributesToJSON(value.attributes),
  }
}
