// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetTargetingOptionsParamDataAttributesBrandsListBrands
 */
export interface GetTargetingOptionsParamDataAttributesBrandsListBrands {
  /**
   * Brand name
   * @type {string}
   * @memberof GetTargetingOptionsParamDataAttributesBrandsListBrands
   */
  brandName: string
  /**
   * Brand Id
   * @type {string}
   * @memberof GetTargetingOptionsParamDataAttributesBrandsListBrands
   */
  brandId: string
  /**
   * Brand product count
   * @type {string}
   * @memberof GetTargetingOptionsParamDataAttributesBrandsListBrands
   */
  brandProductCount: string
  /**
   * Brand display name
   * @type {string}
   * @memberof GetTargetingOptionsParamDataAttributesBrandsListBrands
   */
  displayName: string
}

/**
 * Check if a given object implements the GetTargetingOptionsParamDataAttributesBrandsListBrands interface.
 */
export function instanceOfGetTargetingOptionsParamDataAttributesBrandsListBrands(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'brandName' in value
  isInstance = isInstance && 'brandId' in value
  isInstance = isInstance && 'brandProductCount' in value
  isInstance = isInstance && 'displayName' in value

  return isInstance
}

export function GetTargetingOptionsParamDataAttributesBrandsListBrandsFromJSON(
  json: any
): GetTargetingOptionsParamDataAttributesBrandsListBrands {
  return GetTargetingOptionsParamDataAttributesBrandsListBrandsFromJSONTyped(json, false)
}

export function GetTargetingOptionsParamDataAttributesBrandsListBrandsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTargetingOptionsParamDataAttributesBrandsListBrands {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandName: json['brand_name'],
    brandId: json['brand_id'],
    brandProductCount: json['brand_product_count'],
    displayName: json['display_name'],
  }
}

export function GetTargetingOptionsParamDataAttributesBrandsListBrandsToJSON(
  value?: GetTargetingOptionsParamDataAttributesBrandsListBrands | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_name: value.brandName,
    brand_id: value.brandId,
    brand_product_count: value.brandProductCount,
    display_name: value.displayName,
  }
}
