// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentText } from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import type { PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle } from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent
 */
export interface PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  title?: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  text?: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  return PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
