// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBrandPageSnapshot
 */
export interface PostBrandPagesIdStatusParamBrandPageSnapshot {
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBrandPageSnapshot
   */
  type: string
  /**
   * Brand Page title
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBrandPageSnapshot
   */
  title?: string
  /**
   * Brand Page hierarchy
   * @type {Array<string>}
   * @memberof PostBrandPagesIdStatusParamBrandPageSnapshot
   */
  hierarchy?: Array<string>
  /**
   * Brand Page taxonomy ids
   * @type {Array<string>}
   * @memberof PostBrandPagesIdStatusParamBrandPageSnapshot
   */
  taxonomyIds?: Array<string>
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBrandPageSnapshot interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBrandPageSnapshot(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBrandPageSnapshotFromJSON(
  json: any
): PostBrandPagesIdStatusParamBrandPageSnapshot {
  return PostBrandPagesIdStatusParamBrandPageSnapshotFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBrandPageSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBrandPageSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title') ? undefined : json['title'],
    hierarchy: !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
    taxonomyIds: !exists(json, 'taxonomy_ids') ? undefined : json['taxonomy_ids'],
  }
}

export function PostBrandPagesIdStatusParamBrandPageSnapshotToJSON(
  value?: PostBrandPagesIdStatusParamBrandPageSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: value.title,
    hierarchy: value.hierarchy,
    taxonomy_ids: value.taxonomyIds,
  }
}
