import { css, SerializedStyles } from '@emotion/react'
import { layers, useTheme, spacing } from '@instacart/ids-core'
import { Placement } from '@popperjs/core'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { vendorOverrideAnimationCss, vendorOverrideSvgArrowCss } from './tooltip.styles'

type TooltipVariants = 'dark' | 'feature-hint' | 'light' | 'legacy'

const ROUND_ARROW =
  '<svg data-testid="arrow" width="16"height="6"xmlns="http://www.w3.org/2000/svg"><path d="M0 6s1.796-.013 4.67-3.615C5.851.9 6.93.006 8 0c1.07-.006 2.148.887 3.343 2.385C14.233 6.005 16 6 16 6H0z"/></svg>'

const ARROW =
  '<svg data-testid="arrow" width="16" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M8 0l8 8H0z"></path></svg>'

export interface TooltipProps {
  children: JSX.Element
  childrenWrapperCssProp?: SerializedStyles
  renderTooltip: () => ReactNode
  placement?: Placement
  isVisible?: boolean
  // Optional style to allow base Tooltip to take care of styles
  variant?: TooltipVariants
  arrow?: boolean
  roundArrow?: boolean
  disabled?: boolean
  onShow?: () => void
  onHide?: () => void
  className?: string
  appendToBody?: boolean
  zIndex?: number
}

function useStyles() {
  const theme = useTheme()
  return {
    contentWrapper: css({
      padding: spacing.s16,
      border: `1px solid ${theme.colors.systemGrayscale20}`,
      boxShadow: '4px -4px 16px rgba(0, 0, 0, 0.16)',
      borderRadius: theme.radius.r4,
      display: 'inline-block',
      textTransform: 'none',
      whiteSpace: 'break-spaces',
      ...theme.typography.bodyMedium1,

      '.dark &': {
        backgroundColor: theme.colors.brandSecondaryDark,
        color: theme.colors.systemGrayscale00,
        border: 'none',
      },

      '.light &': {
        backgroundColor: theme.colors.systemGrayscale00,
        border: 'none',
        paddingRight: spacing.s8,
      },

      '.feature-hint &': {
        backgroundColor: theme.colors.brandHighlightDark,
        border: 'none',
        color: theme.colors.systemGrayscale00,
        paddingRight: spacing.s8,
      },

      '.legacy &': {
        padding: `0px`,
        border: 'none',
        boxShadow: `none`,
        display: `block`,

        '.text-box': {
          padding: `10px`,
          boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.15)`,
          position: `relative`,
          bottom: `11px`,
        },
        '.status-indicator': {
          padding: `10px 0px 10px 0px`,
          position: `relative`,
          bottom: `11px`,
        },
      },
    }),
    arrow: css({
      svg: {
        fill: theme.colors.systemGrayscale30,
      },
      '&.dark svg': {
        fill: theme.colors.brandSecondaryDark,
      },
      '&.light svg': {
        fill: theme.colors.systemGrayscale00,
      },
      '&.feature-hint svg': {
        fill: theme.colors.brandHighlightDark,
      },
    }),
  }
}

const childrenWrapperCss = css({ display: 'inline-block' })
export const TOOL_TIP_TEST_ID = 'tooltip-visible'

/** @deprecated Use Tooltip/Hovercard/DefinitionText from 'components/ids-ads' instead */
const Tooltip = ({
  children,
  childrenWrapperCssProp,
  renderTooltip,
  arrow = true,
  roundArrow = true,
  placement = 'top',
  isVisible,
  className,
  variant,
  appendToBody,
  zIndex = layers.l2,
  ...rest
}: TooltipProps) => {
  const styles = useStyles()
  return (
    <Tippy
      zIndex={zIndex}
      {...rest}
      animation="shift-away"
      interactive
      css={[styles.arrow, vendorOverrideAnimationCss, vendorOverrideSvgArrowCss]}
      className={classNames(className, variant)}
      visible={isVisible}
      placement={placement}
      arrow={arrow && (roundArrow ? ROUND_ARROW : ARROW)}
      appendTo={appendToBody ? document?.body : 'parent'}
      content={
        renderTooltip && (
          <span data-testid={TOOL_TIP_TEST_ID} css={styles.contentWrapper}>
            {renderTooltip()}
          </span>
        )
      }
    >
      <span css={childrenWrapperCssProp || childrenWrapperCss}>{children}</span>
    </Tippy>
  )
}

export default Tooltip
