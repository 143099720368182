import { CSSObject } from '@emotion/react'
import { merge } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { HovercardAnchorProps, HovercardStateProps } from './Hovercard'
import { HovercardTooltip } from './HovercardTooltip'

const useStyles = () => {
  return {
    anchor: {
      cursor: 'help',
      textDecoration: 'underline dotted',
      textDecorationThickness: 2,
      textUnderlineOffset: 2,
    },
  }
}

export interface DefinitionTextProps extends Omit<HovercardAnchorProps, 'state'> {
  children: JSX.Element
  definitionContent: ReactNode
  hovercardStateProps?: HovercardStateProps
  styles?: Partial<Record<'anchor' | 'tooltip' | 'content', CSSObject>>
}

export function DefinitionText({
  children,
  definitionContent,
  styles = {},
  ...props
}: DefinitionTextProps) {
  const { anchor: anchorBaseStyles } = useStyles()
  const { anchor: anchorOverrideStyles, ...hovercardStyles } = styles
  const anchorStyles = { anchor: merge(anchorBaseStyles, anchorOverrideStyles) }
  const [tooltipShown, setTooltipShown] = useState(false)

  // Add a delay before setting tooltipShown to true
  useEffect(() => {
    const timer = setTimeout(() => setTooltipShown(true), 500)

    // Clean up on component unmount
    return () => clearTimeout(timer)
  }, [])

  return tooltipShown ? (
    <HovercardTooltip
      tooltipContent={definitionContent}
      styles={{ ...hovercardStyles, ...anchorStyles }}
      {...props}
    >
      {children}
    </HovercardTooltip>
  ) : (
    <>{children}</>
  )
}
