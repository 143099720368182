// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostVideoCampaignsIdSubmitParamProductCollection,
  instanceOfPostVideoCampaignsIdSubmitParamProductCollection,
  PostVideoCampaignsIdSubmitParamProductCollectionFromJSON,
  PostVideoCampaignsIdSubmitParamProductCollectionFromJSONTyped,
  PostVideoCampaignsIdSubmitParamProductCollectionToJSON,
} from './PostVideoCampaignsIdSubmitParamProductCollection'
import {
  PostVideoCampaignsIdSubmitParamSearchTermCollection,
  instanceOfPostVideoCampaignsIdSubmitParamSearchTermCollection,
  PostVideoCampaignsIdSubmitParamSearchTermCollectionFromJSON,
  PostVideoCampaignsIdSubmitParamSearchTermCollectionFromJSONTyped,
  PostVideoCampaignsIdSubmitParamSearchTermCollectionToJSON,
} from './PostVideoCampaignsIdSubmitParamSearchTermCollection'

/**
 * @type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
 *
 * @export
 */
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties =

    | ({ type: 'product_collection' } & PostVideoCampaignsIdSubmitParamProductCollection)
    | ({ type: 'search_term_collection' } & PostVideoCampaignsIdSubmitParamSearchTermCollection)

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'product_collection':
      return {
        ...PostVideoCampaignsIdSubmitParamProductCollectionFromJSONTyped(json, true),
        type: 'product_collection',
      }
    case 'search_term_collection':
      return {
        ...PostVideoCampaignsIdSubmitParamSearchTermCollectionFromJSONTyped(json, true),
        type: 'search_term_collection',
      }
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${json['type']}'`
      )
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'product_collection':
      return PostVideoCampaignsIdSubmitParamProductCollectionToJSON(value)
    case 'search_term_collection':
      return PostVideoCampaignsIdSubmitParamSearchTermCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${value['type']}'`
      )
  }
}
