// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockSectionBannerAssetsDesktop } from './GetBrandPagesParamBlockSectionBannerAssetsDesktop'
import {
  GetBrandPagesParamBlockSectionBannerAssetsDesktopFromJSON,
  GetBrandPagesParamBlockSectionBannerAssetsDesktopFromJSONTyped,
  GetBrandPagesParamBlockSectionBannerAssetsDesktopToJSON,
} from './GetBrandPagesParamBlockSectionBannerAssetsDesktop'
import type { GetBrandPagesParamBlockSectionBannerAssetsMobile } from './GetBrandPagesParamBlockSectionBannerAssetsMobile'
import {
  GetBrandPagesParamBlockSectionBannerAssetsMobileFromJSON,
  GetBrandPagesParamBlockSectionBannerAssetsMobileFromJSONTyped,
  GetBrandPagesParamBlockSectionBannerAssetsMobileToJSON,
} from './GetBrandPagesParamBlockSectionBannerAssetsMobile'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockSectionBannerAssets
 */
export interface GetBrandPagesParamBlockSectionBannerAssets {
  /**
   *
   * @type {GetBrandPagesParamBlockSectionBannerAssetsDesktop}
   * @memberof GetBrandPagesParamBlockSectionBannerAssets
   */
  desktop: GetBrandPagesParamBlockSectionBannerAssetsDesktop
  /**
   *
   * @type {GetBrandPagesParamBlockSectionBannerAssetsMobile}
   * @memberof GetBrandPagesParamBlockSectionBannerAssets
   */
  mobile: GetBrandPagesParamBlockSectionBannerAssetsMobile
}

/**
 * Check if a given object implements the GetBrandPagesParamBlockSectionBannerAssets interface.
 */
export function instanceOfGetBrandPagesParamBlockSectionBannerAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function GetBrandPagesParamBlockSectionBannerAssetsFromJSON(
  json: any
): GetBrandPagesParamBlockSectionBannerAssets {
  return GetBrandPagesParamBlockSectionBannerAssetsFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockSectionBannerAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockSectionBannerAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: GetBrandPagesParamBlockSectionBannerAssetsDesktopFromJSON(json['desktop']),
    mobile: GetBrandPagesParamBlockSectionBannerAssetsMobileFromJSON(json['mobile']),
  }
}

export function GetBrandPagesParamBlockSectionBannerAssetsToJSON(
  value?: GetBrandPagesParamBlockSectionBannerAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: GetBrandPagesParamBlockSectionBannerAssetsDesktopToJSON(value.desktop),
    mobile: GetBrandPagesParamBlockSectionBannerAssetsMobileToJSON(value.mobile),
  }
}
