// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdUsersMultiFactorAuthControllerDestroyInput
 */
export interface ApiAdUsersMultiFactorAuthControllerDestroyInput {
  /**
   * Multi-Factor Auth type
   * @type {string}
   * @memberof ApiAdUsersMultiFactorAuthControllerDestroyInput
   */
  mfaType: ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum
  /**
   * One-time password for MFA validation
   * @type {string}
   * @memberof ApiAdUsersMultiFactorAuthControllerDestroyInput
   */
  otp: string
}

/**
 * @export
 */
export const ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum = {
  Authenticator: 'authenticator',
  Email: 'email',
} as const
export type ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum =
  typeof ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum[keyof typeof ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum]

/**
 * Check if a given object implements the ApiAdUsersMultiFactorAuthControllerDestroyInput interface.
 */
export function instanceOfApiAdUsersMultiFactorAuthControllerDestroyInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'mfaType' in value
  isInstance = isInstance && 'otp' in value

  return isInstance
}

export function ApiAdUsersMultiFactorAuthControllerDestroyInputFromJSON(
  json: any
): ApiAdUsersMultiFactorAuthControllerDestroyInput {
  return ApiAdUsersMultiFactorAuthControllerDestroyInputFromJSONTyped(json, false)
}

export function ApiAdUsersMultiFactorAuthControllerDestroyInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdUsersMultiFactorAuthControllerDestroyInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mfaType: json['mfa_type'],
    otp: json['otp'],
  }
}

export function ApiAdUsersMultiFactorAuthControllerDestroyInputToJSON(
  value?: ApiAdUsersMultiFactorAuthControllerDestroyInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mfa_type: value.mfaType,
    otp: value.otp,
  }
}
