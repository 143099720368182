// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetProductsResponseDataAttributes
 */
export interface GetProductsResponseDataAttributes {
  /**
   * Returns the product UPC
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  upc: string
  /**
   * Returns the product name
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productName: string
  /**
   * Returns the brand name
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  brandName: string
  /**
   * Returns the URL link to the generic product details page from www.instacart.com
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productUrl: string
  /**
   * Returns the catalog product id
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productId: string
  /**
   * Returns the URL link to the product image
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productImageUrl: string
  /**
   * Returns the product size
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productSize: string
  /**
   * Returns the product unit count
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  productUnitCount: string
  /**
   * The classified type of the product, either 'universal' or 'commodity'.
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  classifiedType?: GetProductsResponseDataAttributesClassifiedTypeEnum
  /**
   * The commodity scope. Only applicable if classified_type is specified.
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  commodityScope?: GetProductsResponseDataAttributesCommodityScopeEnum
  /**
   * Returns the id of the associated ads_product_groups if one exists
   * @type {string}
   * @memberof GetProductsResponseDataAttributes
   */
  groupId?: string
  /**
   * Returns true if the product is mapped to a brand, false otherwise
   * @type {boolean}
   * @memberof GetProductsResponseDataAttributes
   */
  isMappedToBrand?: boolean
}

/**
 * @export
 */
export const GetProductsResponseDataAttributesClassifiedTypeEnum = {
  Universal: 'universal',
  Commodity: 'commodity',
  InHouse: 'in-house',
  Null: 'null',
} as const
export type GetProductsResponseDataAttributesClassifiedTypeEnum =
  typeof GetProductsResponseDataAttributesClassifiedTypeEnum[keyof typeof GetProductsResponseDataAttributesClassifiedTypeEnum]

/**
 * @export
 */
export const GetProductsResponseDataAttributesCommodityScopeEnum = {
  Brand: 'brand',
  Null: 'null',
} as const
export type GetProductsResponseDataAttributesCommodityScopeEnum =
  typeof GetProductsResponseDataAttributesCommodityScopeEnum[keyof typeof GetProductsResponseDataAttributesCommodityScopeEnum]

/**
 * Check if a given object implements the GetProductsResponseDataAttributes interface.
 */
export function instanceOfGetProductsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'upc' in value
  isInstance = isInstance && 'productName' in value
  isInstance = isInstance && 'brandName' in value
  isInstance = isInstance && 'productUrl' in value
  isInstance = isInstance && 'productId' in value
  isInstance = isInstance && 'productImageUrl' in value
  isInstance = isInstance && 'productSize' in value
  isInstance = isInstance && 'productUnitCount' in value

  return isInstance
}

export function GetProductsResponseDataAttributesFromJSON(
  json: any
): GetProductsResponseDataAttributes {
  return GetProductsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetProductsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetProductsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    upc: json['upc'],
    productName: json['product_name'],
    brandName: json['brand_name'],
    productUrl: json['product_url'],
    productId: json['product_id'],
    productImageUrl: json['product_image_url'],
    productSize: json['product_size'],
    productUnitCount: json['product_unit_count'],
    classifiedType: !exists(json, 'classified_type') ? undefined : json['classified_type'],
    commodityScope: !exists(json, 'commodity_scope') ? undefined : json['commodity_scope'],
    groupId: !exists(json, 'group_id') ? undefined : json['group_id'],
    isMappedToBrand: !exists(json, 'is_mapped_to_brand') ? undefined : json['is_mapped_to_brand'],
  }
}

export function GetProductsResponseDataAttributesToJSON(
  value?: GetProductsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    upc: value.upc,
    product_name: value.productName,
    brand_name: value.brandName,
    product_url: value.productUrl,
    product_id: value.productId,
    product_image_url: value.productImageUrl,
    product_size: value.productSize,
    product_unit_count: value.productUnitCount,
    classified_type: value.classifiedType,
    commodity_scope: value.commodityScope,
    group_id: value.groupId,
    is_mapped_to_brand: value.isMappedToBrand,
  }
}
