import { css } from '@emotion/react'
import { spacing, Text, useTheme } from '@instacart/ids-core'
import { FC } from 'react'
import FormattedMessage, { FormattedMessageProps } from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'

interface TableEmptyProps {
  titleMessageId?: MessageIdType
  subtitleMessageIds?: Array<MessageIdType>
  actionComponent?: JSX.Element
}

const useStyles = () => {
  const theme = useTheme()
  return {
    container: css({
      background: '#fff',
      border: '1px solid #d4d4d4',
      borderRadius: '0 0 6px 6px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: `${spacing.s24}px`,
      textAlign: 'center',
      borderTop: `1px solid ${theme.colors.systemGrayscale30}`,
      padding: '3.5rem',
    }),
    title: css({
      marginBottom: '0.8rem',
    }),
    div: css({
      marginBottom: `${spacing.s24}px`,
    }),
    text: { color: theme.colors.systemGrayscale70 },
  }
}

const TableEmptyMessageWithActions: FC<React.PropsWithChildren<TableEmptyProps>> = ({
  titleMessageId,
  subtitleMessageIds,
  actionComponent,
}) => {
  const styles = useStyles()
  const titleProps: FormattedMessageProps = { id: titleMessageId as MessageIdType }
  const subtitles = subtitleMessageIds?.map(messageId => {
    const messageProps: FormattedMessageProps = { id: messageId }
    return (
      <Text typography="bodyMedium2" style={styles.text}>
        <FormattedMessage {...messageProps} />
      </Text>
    )
  })

  return (
    <div css={styles.container}>
      <div css={styles.div} data-testid="empty-message">
        <div css={styles.title}>
          <Text typography="bodyMedium2" style={styles.text}>
            <FormattedMessage {...titleProps} />
          </Text>
        </div>
        {subtitles}
      </div>
      <div data-testid="empty-message-buttons">{actionComponent}</div>
    </div>
  )
}

export default TableEmptyMessageWithActions
