// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviews } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReview } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReview'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState
 */
export interface PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReview}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState
   */
  finalReview?: PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState interface.
 */
export function instanceOfPostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSON(
  json: any
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState {
  return PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
    json,
    false
  )
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateToJSON(
  value?: PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review:
      PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON(
        value.finalReview
      ),
  }
}
