import { getIn } from 'formik'
import { useHeaderHeight } from 'components/Header/header.utils'

export function scrollToSection(headerHeight: number, id?: string) {
  if (!id) return
  const element = document.getElementById(id)
  if (element) {
    const scrollPosition = element.offsetTop - headerHeight - 100
    window.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' })
  }
}

export default function useScrollToError(errorIds: Record<string, string>) {
  const headerHeight = useHeaderHeight()
  return (errors: Record<string, string>) => {
    const firstError = Object.keys(errorIds).find(error => getIn(errors, error) !== undefined)

    if (firstError) {
      scrollToSection(headerHeight, errorIds[firstError])
    }
  }
}
