import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import toPx from 'common/toPx'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'
import { ChartCardData } from 'service/shared'
import ChartBody, { ChartBodyProps } from './ChartBody'
import ChartCards from './ChartCards'
import ChartHeader from './ChartHeader'

export interface ChartProps<
  Unit extends string,
  XKey extends string = string,
  YKey extends string = string
> extends Omit<ChartBodyProps<XKey, YKey>, 'border' | 'fill' | 'lineColor'> {
  border?: boolean
  chartCardData: ChartCardData
  chartUnit: Unit
  chartTitle?: MessageIdType
  chartSubtitle?: MessageIdType
  loading: boolean
  onCardClick: (chartUnit: Unit) => () => void
  fill?: boolean
  lineColor?: string
}

const Chart = <Unit extends string, XKey extends string, YKey extends string>({
  chartCardData,
  chartUnit,
  chartTitle,
  chartSubtitle,
  loading,
  onCardClick,
  lineColor,
  fill,
  border = true,
  ...chartBodyProps
}: ChartProps<Unit, XKey, YKey>) => {
  const intl = useIntl()
  const theme = useTheme()
  const lineColorToUse = lineColor || theme.colors.brandPromotionalRegular

  // There is no data for the current filter
  const noData = Boolean(!loading && !chartBodyProps.data.length)

  const chartOverlayCss = css({
    position: 'relative',
  })

  const chartCss = css({
    marginBottom: 40,
  })

  const emptyChartMessageCss = css({
    display: 'flex',
    padding: spacing.s16,
    backgroundColor: theme.colors.systemGrayscale00,
    borderLeft: '4px solid #ff7e22',
    marginBottom: 20,
  })

  const chartHeadingCss = css({
    padding: toPx`${spacing.s16} ${spacing.s32}`,
  })

  return (
    <div css={chartOverlayCss}>
      <div css={chartCss} data-testid={loading ? 'chart-loading' : 'chart-loading-done'}>
        {noData ? (
          <div css={emptyChartMessageCss}>
            <FormattedMessage id="components.chart.noData" />
          </div>
        ) : (
          <>
            <div css={chartHeadingCss}>
              <ChartHeader
                title={chartTitle && intl.formatMessage({ id: chartTitle })}
                subtitle={chartSubtitle && intl.formatMessage({ id: chartSubtitle })}
                border={border}
              >
                <ChartCards
                  loading={loading}
                  lineColor={lineColorToUse}
                  chartCardData={chartCardData}
                  chartUnit={chartUnit}
                  onCardClick={onCardClick}
                />
              </ChartHeader>
            </div>
            <ChartBody
              {...chartBodyProps}
              border={border}
              loading={loading}
              lineColor={lineColorToUse}
              fill={false}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Chart
