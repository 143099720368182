// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamData } from './GetPrototypeCampaignsParamData'
import {
  GetPrototypeCampaignsParamDataFromJSON,
  GetPrototypeCampaignsParamDataFromJSONTyped,
  GetPrototypeCampaignsParamDataToJSON,
} from './GetPrototypeCampaignsParamData'
import type { PrototypeCampaignsResponseMeta } from './PrototypeCampaignsResponseMeta'
import {
  PrototypeCampaignsResponseMetaFromJSON,
  PrototypeCampaignsResponseMetaFromJSONTyped,
  PrototypeCampaignsResponseMetaToJSON,
} from './PrototypeCampaignsResponseMeta'

/**
 *
 * @export
 * @interface PrototypeCampaignsResponse
 */
export interface PrototypeCampaignsResponse {
  /**
   * Prototype Campaign collection
   * @type {Array<GetPrototypeCampaignsParamData>}
   * @memberof PrototypeCampaignsResponse
   */
  data: Array<GetPrototypeCampaignsParamData>
  /**
   *
   * @type {PrototypeCampaignsResponseMeta}
   * @memberof PrototypeCampaignsResponse
   */
  meta: PrototypeCampaignsResponseMeta
}

/**
 * Check if a given object implements the PrototypeCampaignsResponse interface.
 */
export function instanceOfPrototypeCampaignsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function PrototypeCampaignsResponseFromJSON(json: any): PrototypeCampaignsResponse {
  return PrototypeCampaignsResponseFromJSONTyped(json, false)
}

export function PrototypeCampaignsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PrototypeCampaignsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(GetPrototypeCampaignsParamDataFromJSON),
    meta: PrototypeCampaignsResponseMetaFromJSON(json['meta']),
  }
}

export function PrototypeCampaignsResponseToJSON(value?: PrototypeCampaignsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(GetPrototypeCampaignsParamDataToJSON),
    meta: PrototypeCampaignsResponseMetaToJSON(value.meta),
  }
}
