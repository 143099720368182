// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshot,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamBrandPageSnapshot,
  PutDisplayAdGroupsIdEnableParamBrandPageSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamCreativeSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamCreativeSnapshot,
  PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamCreativeSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamCreativeSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshot,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamDisplayAssetSnapshot,
  PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshot'
import {
  PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshot,
  instanceOfPutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshot,
  PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshotFromJSON,
  PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshotFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshotToJSON,
} from './PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshot'

/**
 * @type PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & PutDisplayAdGroupsIdEnableParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & PutDisplayAdGroupsIdEnableParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshot)

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot {
  return PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutDisplayAdGroupsIdEnableParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutDisplayAdGroupsIdEnableParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutDisplayAdGroupsIdEnableParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PutDisplayAdGroupsIdEnableParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutDisplayAdGroupsIdEnableParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PutDisplayAdGroupsIdEnableParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutDisplayAdGroupsIdEnableParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PutDisplayAdGroupsIdEnableParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
