import { MetricConfig } from 'components/Reporting'
import { MessageIdType } from 'locales/types'
import {
  BrandPageFormData,
  BannerType,
  ChartUnit,
  ChartUnitValues,
  BlockAssetTypeEnum,
  DurationEnum,
  LevelEnum,
  TypeEnum,
  StatusEnum,
} from './brandPage.types'

export const CREATE_PATH = '/brand_pages/new'
export const TRANSITION_DURATION_MS = 200
export const PLACEHOLDER_PRODUCT_IMAGE_PATH = '/images/placeholders/image_placeholder_product.png'
export const MAX_PRODUCTS_PC = 100
export const MAX_PRODUCTS_SPOTLIGHT_PC = 20
export const MIN_PRODUCTS = 3
export const MIN_PRODUCTS_PILOT = 1
export const MAX_TOTAL_PRODUCTS = 700
export const MAX_EXTRA_FIELD_LENGTH = 220
export const MIN_PRODUCT_GROUPS = 1
export const MAX_TOTAL_PRODUCTS_GROUPS = 700
export const MAX_PRODUCT_GROUPS_PC = 100

export const MIN_FIELD_LENGTH = 3
export const MAX_FIELD_LENGTH = 30
export const MAX_INTERNAL_NAME_FIELD_LENGTH = 100
export const MIN_CUSTOM_BLOCK_COUNT = 1
export const MIN_HIERARCHY_LENGTH = 2

export const DEFAULT_HIERARCHY_ARRAY_LENGTH = 3

export const LOGO_HEADER_TYPES = [BlockAssetTypeEnum.HERO_BANNER, BlockAssetTypeEnum.LOGO]
export const PRODUCT_COLLECTION_TYPES = [
  BlockAssetTypeEnum.PRODUCT_COLLECTION,
  BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION,
  BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION,
]

export const PAGE_HIERARCHY_VALIDATION_ERROR_FIELD_KEY = 'brand_page.page_hierarchy'
export const TAXONOMY_IDS_VALIDATION_ERROR_FIELD_KEY = 'brand_page.taxonomy_ids'
export const ENABLED_VALIDATION_ERROR_FIELD_KEY = 'brand_page.enabled'

export const FIELD_ERROR_TAXONOMY_COLLIDES_WITH_EXISTING_BRAND_PAGE =
  'taxonomy_id_collide_with_existing_brand_page'
export const FIELD_ERROR_LINKED_TO_ACTIVE_AD_GROUP = 'page_linked_to_active_ad_group'

export const availableMetrics: MetricConfig<ChartUnit>[] = [
  {
    metric: ChartUnitValues.PAGE_VIEWS,
    unit: 'number',
    labelId: 'pages.brandPages.columns.views',
  },
  {
    metric: ChartUnitValues.ITEM_DETAIL_PAGE_VIEWS,
    unit: 'number',
    labelId: 'pages.brandPages.columns.itemDetailViews',
  },
  {
    metric: ChartUnitValues.SALES,
    unit: 'dollar',
    labelId: 'pages.brandPages.columns.salesOnPage',
  },
  {
    metric: ChartUnitValues.ADD_TO_CARTS,
    unit: 'number',
    labelId: 'pages.brandPages.columns.addToCarts',
  },
]

export const durationOptions = [
  {
    labelId: 'pages.brandPages.field.duration.evergreen' as MessageIdType,
    value: DurationEnum.Evergreen,
  },
  {
    labelId: 'pages.brandPages.field.duration.seasonal' as MessageIdType,
    value: DurationEnum.Seasonal,
  },
]

export const pageLevelOptions = [
  {
    labelId: 'pages.brandPages.field.level.brand' as MessageIdType,
    value: LevelEnum.Brand,
  },
  {
    labelId: 'pages.brandPages.field.level.subBrand' as MessageIdType,
    value: LevelEnum.SubBrand,
  },
  {
    labelId: 'pages.brandPages.field.level.parent' as MessageIdType,
    value: LevelEnum.Parent,
  },
]

export const proviPageLevelOptions = [
  {
    labelId: 'pages.brandPages.field.level.parent' as MessageIdType,
    value: LevelEnum.Parent,
  },
]

export const initialAssetValues = {
  alt: '',
  desktop: {
    urls: {
      previewUrl: '',
      uploadedUri: '',
    },
    id: '',
  },
  mobile: {
    urls: {
      previewUrl: '',
      uploadedUri: '',
    },
    id: '',
  },
}

export const getInitialBannerValues = (type: BlockAssetTypeEnum): BannerType => ({
  type,
  assets: initialAssetValues,
})

export const initialLogoValues = {
  type: BlockAssetTypeEnum.LOGO,
  assets: {
    alt: '',
    urls: {
      previewUrl: '',
      uploadedUri: '',
    },
    id: '',
  },
}

export const initialSmallCarouselValues = {
  type: BlockAssetTypeEnum.PRODUCT_COLLECTION,
  content: {
    title: '',
  },
  productIds: [],
}

export const initialLargeCarouselValues = {
  type: BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION,
  content: {
    title: '',
  },
  productIds: [],
  productAssets: {},
}

export const initialProductGroupCarouselValues = {
  type: BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION,
  content: {
    title: '',
  },
  productGroupIds: [],
}

export const initialImageTextValues = {
  type: BlockAssetTypeEnum.IMAGE_TEXT,
  content: {
    title: '',
    text: '',
  },
  assets: initialAssetValues,
}

export const bannerStoryBlocks = [
  initialLogoValues,
  getInitialBannerValues(BlockAssetTypeEnum.HERO_BANNER),
  initialSmallCarouselValues,
  initialSmallCarouselValues,
  getInitialBannerValues(BlockAssetTypeEnum.IN_PAGE_BANNER),
]

export const productCollectionBlocks = [
  initialLogoValues,
  initialSmallCarouselValues,
  initialSmallCarouselValues,
  initialSmallCarouselValues,
]

export const initialFormValues: BrandPageFormData = {
  id: '1',
  name: '',
  duration: DurationEnum.Seasonal,
  level: LevelEnum.Brand,
  type: TypeEnum.EvergreenV1,
  status: StatusEnum.Draft,
  enabled: true,
  hierarchy: ['', '', ''],
  blocks: bannerStoryBlocks,
  linkedBrands: [],
}

export const blockToClassMap = {
  [BlockAssetTypeEnum.HERO_BANNER]: 'banner hero',
  [BlockAssetTypeEnum.IN_PAGE_BANNER]: 'banner in-page',
  [BlockAssetTypeEnum.LOGO]: 'logo',
  [BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION]: 'spotlightCollection',
  [BlockAssetTypeEnum.PRODUCT_COLLECTION]: 'productCollection',
  [BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION]: 'productGroupCollection',
  [BlockAssetTypeEnum.IMAGE_TEXT]: 'imageText',
}

export const blockToErrorMessage = {
  [BlockAssetTypeEnum.LOGO]: 'pages.displayProduct.displayAdGroup.creativeAssets.info.logo.title',
  [BlockAssetTypeEnum.HERO_BANNER]: 'common.heroBanner',
  [BlockAssetTypeEnum.IN_PAGE_BANNER]:
    'pages.brandPages.designPage.input.inPageBanner.common.inPageBanner',
  [BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION]:
    'pages.brandPages.designPage.common.spotlightProductCollection',
  [BlockAssetTypeEnum.PRODUCT_COLLECTION]: 'pages.brandPages.designPage.common.productCollection',
  [BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION]:
    'pages.brandPages.designPage.common.productGroupCollection',
  [BlockAssetTypeEnum.IMAGE_TEXT]: 'pages.brandPages.designPage.common.imageText',
}

export const ALT_FIELD = 'assets.alt'
export const LOGO_ASSET_FIELD = 'assets.urls'
export const DESKTOP_FIELD = 'assets.desktop.urls'
export const MOBILE_FIELD = 'assets.mobile.urls'
export const TITLE_FIELD = 'content.title'
export const TEXT_FIELD = 'content.text'
export const PRODUCT_ID_FIELD = 'productIds'
export const PRODUCT_GROUP_ID_FIELD = 'productGroupIds'
export const PRODUCT_ASSET_FIELD = 'productAssets'

// eslint-disable-next-line no-restricted-syntax
export enum BrandPageSectionIds {
  NAME = 'name',
  HIERARCHY = 'hierarchy',
  LINKED_BRANDS = 'linkedBrands',
  DESIGN_PAGE = 'blockValues',
}

export const DESIGN_PAGE_BLOC_FIELD_NAME = 'blocks'

export const LOGO_PREVIEW_TEST_ID = 'bp-preview-logo'
export const HERO_PREVIEW_TEST_ID = 'bp-preview-hero'
export const IN_PAGE_PREVIEW_TEST_ID = 'bp-preview-in-page'
export const SPOTLIGHT_PREVIEW_TEST_ID = 'bp-preview-spotlight'
export const PRODUCT_PREVIEW_TEST_ID = 'bp-preview-product'
export const IMAGE_TEXT_PREVIEW_TEST_ID = 'bp-preview-image-text'
export const ISSUE_ICON_TEST_ID = 'preview-block-issue-icon'
