// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AuthShowResponseDataAttributesCurrentAccount } from './AuthShowResponseDataAttributesCurrentAccount'
import {
  AuthShowResponseDataAttributesCurrentAccountFromJSON,
  AuthShowResponseDataAttributesCurrentAccountFromJSONTyped,
  AuthShowResponseDataAttributesCurrentAccountToJSON,
} from './AuthShowResponseDataAttributesCurrentAccount'
import type { GetAuthParamDataAttributesFeatureHints } from './GetAuthParamDataAttributesFeatureHints'
import {
  GetAuthParamDataAttributesFeatureHintsFromJSON,
  GetAuthParamDataAttributesFeatureHintsFromJSONTyped,
  GetAuthParamDataAttributesFeatureHintsToJSON,
} from './GetAuthParamDataAttributesFeatureHints'
import type { GetAuthParamDataAttributesFeatureReminders } from './GetAuthParamDataAttributesFeatureReminders'
import {
  GetAuthParamDataAttributesFeatureRemindersFromJSON,
  GetAuthParamDataAttributesFeatureRemindersFromJSONTyped,
  GetAuthParamDataAttributesFeatureRemindersToJSON,
} from './GetAuthParamDataAttributesFeatureReminders'
import type { GetAuthParamDataAttributesMfaFactors } from './GetAuthParamDataAttributesMfaFactors'
import {
  GetAuthParamDataAttributesMfaFactorsFromJSON,
  GetAuthParamDataAttributesMfaFactorsFromJSONTyped,
  GetAuthParamDataAttributesMfaFactorsToJSON,
} from './GetAuthParamDataAttributesMfaFactors'

/**
 *
 * @export
 * @interface AuthShowResponseDataAttributes
 */
export interface AuthShowResponseDataAttributes {
  /**
   * Whether the active account ID has changed
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributes
   */
  accountIdChanged: boolean
  /**
   * Currently active experiment variants enabled for this user
   * @type {{ [key: string]: any; }}
   * @memberof AuthShowResponseDataAttributes
   */
  activeVariants: { [key: string]: any }
  /**
   * User Email
   * @type {string}
   * @memberof AuthShowResponseDataAttributes
   */
  email: string
  /**
   * User First Name
   * @type {string}
   * @memberof AuthShowResponseDataAttributes
   */
  firstName: string
  /**
   * Flippers enabled for this user
   * @type {Array<string>}
   * @memberof AuthShowResponseDataAttributes
   */
  flippers: Array<string>
  /**
   * User Last Name
   * @type {string}
   * @memberof AuthShowResponseDataAttributes
   */
  lastName: string
  /**
   * User ID
   * @type {number}
   * @memberof AuthShowResponseDataAttributes
   */
  userId: number
  /**
   * User role type
   * @type {string}
   * @memberof AuthShowResponseDataAttributes
   */
  userType: string
  /**
   * User UUID
   * @type {string}
   * @memberof AuthShowResponseDataAttributes
   */
  userUuid: string
  /**
   *
   * @type {AuthShowResponseDataAttributesCurrentAccount}
   * @memberof AuthShowResponseDataAttributes
   */
  currentAccount?: AuthShowResponseDataAttributesCurrentAccount
  /**
   * MFA Factors
   * @type {Array<GetAuthParamDataAttributesMfaFactors>}
   * @memberof AuthShowResponseDataAttributes
   */
  mfaFactors?: Array<GetAuthParamDataAttributesMfaFactors>
  /**
   * Flag indicating if the user has single sign on authentication enabled
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributes
   */
  singleSignOnEnabled?: boolean
  /**
   * Number of accounts user has access to
   * @type {number}
   * @memberof AuthShowResponseDataAttributes
   */
  userAccounts: number
  /**
   * Feature hints for this user
   * @type {Array<GetAuthParamDataAttributesFeatureHints>}
   * @memberof AuthShowResponseDataAttributes
   */
  featureHints?: Array<GetAuthParamDataAttributesFeatureHints>
  /**
   * Feature reminders for this user
   * @type {Array<GetAuthParamDataAttributesFeatureReminders>}
   * @memberof AuthShowResponseDataAttributes
   */
  featureReminders?: Array<GetAuthParamDataAttributesFeatureReminders>
}

/**
 * Check if a given object implements the AuthShowResponseDataAttributes interface.
 */
export function instanceOfAuthShowResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'accountIdChanged' in value
  isInstance = isInstance && 'activeVariants' in value
  isInstance = isInstance && 'email' in value
  isInstance = isInstance && 'firstName' in value
  isInstance = isInstance && 'flippers' in value
  isInstance = isInstance && 'lastName' in value
  isInstance = isInstance && 'userId' in value
  isInstance = isInstance && 'userType' in value
  isInstance = isInstance && 'userUuid' in value
  isInstance = isInstance && 'userAccounts' in value

  return isInstance
}

export function AuthShowResponseDataAttributesFromJSON(json: any): AuthShowResponseDataAttributes {
  return AuthShowResponseDataAttributesFromJSONTyped(json, false)
}

export function AuthShowResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthShowResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    accountIdChanged: json['account_id_changed'],
    activeVariants: json['active_variants'],
    email: json['email'],
    firstName: json['first_name'],
    flippers: json['flippers'],
    lastName: json['last_name'],
    userId: json['user_id'],
    userType: json['user_type'],
    userUuid: json['user_uuid'],
    currentAccount: !exists(json, 'current_account')
      ? undefined
      : AuthShowResponseDataAttributesCurrentAccountFromJSON(json['current_account']),
    mfaFactors: !exists(json, 'mfa_factors')
      ? undefined
      : (json['mfa_factors'] as Array<any>).map(GetAuthParamDataAttributesMfaFactorsFromJSON),
    singleSignOnEnabled: !exists(json, 'single_sign_on_enabled')
      ? undefined
      : json['single_sign_on_enabled'],
    userAccounts: json['user_accounts'],
    featureHints: !exists(json, 'feature_hints')
      ? undefined
      : (json['feature_hints'] as Array<any>).map(GetAuthParamDataAttributesFeatureHintsFromJSON),
    featureReminders: !exists(json, 'feature_reminders')
      ? undefined
      : (json['feature_reminders'] as Array<any>).map(
          GetAuthParamDataAttributesFeatureRemindersFromJSON
        ),
  }
}

export function AuthShowResponseDataAttributesToJSON(
  value?: AuthShowResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    account_id_changed: value.accountIdChanged,
    active_variants: value.activeVariants,
    email: value.email,
    first_name: value.firstName,
    flippers: value.flippers,
    last_name: value.lastName,
    user_id: value.userId,
    user_type: value.userType,
    user_uuid: value.userUuid,
    current_account: AuthShowResponseDataAttributesCurrentAccountToJSON(value.currentAccount),
    mfa_factors:
      value.mfaFactors === undefined
        ? undefined
        : (value.mfaFactors as Array<any>).map(GetAuthParamDataAttributesMfaFactorsToJSON),
    single_sign_on_enabled: value.singleSignOnEnabled,
    user_accounts: value.userAccounts,
    feature_hints:
      value.featureHints === undefined
        ? undefined
        : (value.featureHints as Array<any>).map(GetAuthParamDataAttributesFeatureHintsToJSON),
    feature_reminders:
      value.featureReminders === undefined
        ? undefined
        : (value.featureReminders as Array<any>).map(
            GetAuthParamDataAttributesFeatureRemindersToJSON
          ),
  }
}
