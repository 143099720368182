import { isProd, getEnv, getEnvOrLocalStorageBool } from 'common/utils'

export function getImageUrl(path: string) {
  const assetBaseUrl = getEnv('ASSET_BASE_URL')

  if (assetBaseUrl && isProd()) {
    return `${assetBaseUrl}${path}`
  }
  return path
}

export const useAdsLogoUrl = () => {
  const useNewAdsLogo = () => getEnvOrLocalStorageBool('USE_NEW_ADS_LOGO')

  if (useNewAdsLogo()) {
    return getImageUrl('/images/logos/IC-ads.svg')
  }
  return getImageUrl('/images/logos/instacart-ads-image.svg')
}
