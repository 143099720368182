// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabels } from './GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabels'
import {
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabelsFromJSON,
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabelsFromJSONTyped,
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabelsToJSON,
} from './GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabels'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamProductAssetFieldDecisionsImage
 */
export interface GetDisplayCampaignsParamProductAssetFieldDecisionsImage {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisionsImage
   */
  decision?: GetDisplayCampaignsParamProductAssetFieldDecisionsImageDecisionEnum
  /**
   * List of labels applied
   * @type {Array<GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabels>}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisionsImage
   */
  labels?: Array<GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabels>
}

/**
 * @export
 */
export const GetDisplayCampaignsParamProductAssetFieldDecisionsImageDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type GetDisplayCampaignsParamProductAssetFieldDecisionsImageDecisionEnum =
  typeof GetDisplayCampaignsParamProductAssetFieldDecisionsImageDecisionEnum[keyof typeof GetDisplayCampaignsParamProductAssetFieldDecisionsImageDecisionEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamProductAssetFieldDecisionsImage interface.
 */
export function instanceOfGetDisplayCampaignsParamProductAssetFieldDecisionsImage(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSON(
  json: any
): GetDisplayCampaignsParamProductAssetFieldDecisionsImage {
  return GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamProductAssetFieldDecisionsImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabelsFromJSON
        ),
  }
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsImageToJSON(
  value?: GetDisplayCampaignsParamProductAssetFieldDecisionsImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            GetDisplayCampaignsParamProductAssetFieldDecisionsImageLabelsToJSON
          ),
  }
}
