import { ReactNode, useCallback, useMemo, useState } from 'react'
import { buildNotification, Notification, Notifier, NotifierContext } from 'context/notifications'
import NotificationsRenderer from './NotificationsRenderer'

export default function NotificationsProvider({ children }: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const sendNotification = useCallback<Notifier['sendNotification']>(options => {
    const notification = buildNotification(options)
    setNotifications(existing => [...existing, notification])
    return notification.id
  }, [])

  const clearNotification = useCallback<Notifier['clearNotification']>(id => {
    setNotifications(existing => {
      const ind = existing.findIndex(notification => notification.id === id)
      if (ind === -1) return existing

      existing[ind].onClear?.()

      return [...existing.slice(0, ind), ...existing.slice(ind + 1)]
    })
  }, [])

  const clearAll = useCallback<Notifier['clearAll']>(() => setNotifications([]), [])

  const providerValue = useMemo(
    () => ({
      sendNotification,
      clearNotification,
      clearAll,
    }),
    [sendNotification, clearNotification, clearAll]
  )

  return (
    <NotifierContext.Provider value={providerValue}>
      {children}
      <NotificationsRenderer
        notifications={notifications}
        onClearNotification={clearNotification}
      />
    </NotifierContext.Provider>
  )
}
