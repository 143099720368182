// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentText } from './PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentText'
import {
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentText'
import type { PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitle } from './PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent
 */
export interface PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent
   */
  title?: PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent
   */
  text?: PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent {
  return PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitleFromJSON(
          json['title']
        ),
    text: !exists(json, 'text')
      ? undefined
      : PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTextFromJSON(
          json['text']
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTitleToJSON(
      value.title
    ),
    text: PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
