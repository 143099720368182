import { Color, spacing, useTheme } from '@instacart/ids-core'
import toPx from 'common/toPx'
import { resolveColor } from '.'
import { LOADING_ANIMATION_TEST_ID } from './test'

export interface LoadingAnimationProps {
  color: Color | string | null
  compact?: boolean
}

function useStyles({ color, compact }: LoadingAnimationProps) {
  const theme = useTheme()

  return {
    root: {
      display: 'flex',
      gap: spacing.s8,
      padding: toPx`${compact ? 6 : 8} 0 ${compact ? 4 : 6}`,
      '.dot': {
        background: resolveColor(theme, color),
        width: spacing.s8,
        height: spacing.s8,
        borderRadius: theme.radius.r4,
        animation: 'opacity-cycle 1.2s ease-in-out infinite',
        '&:nth-of-type(1)': {
          animationDelay: '-0.32s',
        },
        '&:nth-of-type(2)': {
          animationDelay: '-0.16s',
        },
        '@keyframes opacity-cycle': {
          '0%, 80%, 100%': {
            opacity: 0.5,
          },
          '40%': {
            opacity: 1,
          },
        },
      },
    },
  }
}

export function LoadingAnimation(props: LoadingAnimationProps) {
  const styles = useStyles(props)

  return (
    <div css={styles.root} data-testid={LOADING_ANIMATION_TEST_ID}>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  )
}
