// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdminAccountsControllerCreateInputAccountCorporateAddress } from './ApiAdminAccountsControllerCreateInputAccountCorporateAddress'
import {
  ApiAdminAccountsControllerCreateInputAccountCorporateAddressFromJSON,
  ApiAdminAccountsControllerCreateInputAccountCorporateAddressFromJSONTyped,
  ApiAdminAccountsControllerCreateInputAccountCorporateAddressToJSON,
} from './ApiAdminAccountsControllerCreateInputAccountCorporateAddress'

/**
 *
 * @export
 * @interface ApiAdminAccountsControllerCreateInputAccount
 */
export interface ApiAdminAccountsControllerCreateInputAccount {
  /**
   * Account Name
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  name: string
  /**
   * Account advertiser Id
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  advertiserId: string
  /**
   * Default bill payer for the account
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  billPayer: string
  /**
   * Account agency Id
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  agencyId?: string
  /**
   * Account invoicing type, if provided must be one of: instacart_invoicing, credit_card
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  paymentOption?: ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum
  /**
   * Invoice option must be one of: email, portal
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  invoiceOption?: ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum
  /**
   * Payment term in days must be one of: 30, 45, 60, 75, 90, 120
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  paymentTermDays?: ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum
  /**
   * Comma separated list of emails for email invoicing
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  emailDistribution?: string
  /**
   * Account sells alcohol products
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  alcohol?: boolean
  /**
   * ISO3166 alpha-2 country code for Account's ads
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  countryCode?: string
  /**
   * Is test account
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  isTest?: boolean
  /**
   * Is sandbox account
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  isSandbox?: boolean
  /**
   * Is in house account
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  isInHouse?: boolean
  /**
   * Account Type
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  accountType?: ApiAdminAccountsControllerCreateInputAccountAccountTypeEnum
  /**
   * Serving Type: marketplace_only, syndication_network, marketplace_and_syndication(default)
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  servingType?: ApiAdminAccountsControllerCreateInputAccountServingTypeEnum
  /**
   * A list of Retailer Ids
   * @type {Array<number>}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  retailerIds?: Array<number>
  /**
   * An array of serving groups to target users for ads serving.
   * @type {Array<string>}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  servingGroups?: Array<ApiAdminAccountsControllerCreateInputAccountServingGroupsEnum>
  /**
   * The maximum daily budget configured for this account, in cents.
   * @type {number}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  dailyBudgetCapCents?: number
  /**
   * The maximum lifetime budget configured for this account, in cents.
   * @type {number}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  lifetimeBudgetCapCents?: number
  /**
   * Whether the account is non billable
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  nonBillable?: boolean
  /**
   * Whether internal users should be auto assigned Advertiser Admin role on this account
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  autoAssignInternalUsersRoles?: boolean
  /**
   * The external marketplace that the account belongs to
   * @type {string}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  externalMarketplace?: ApiAdminAccountsControllerCreateInputAccountExternalMarketplaceEnum
  /**
   *
   * @type {ApiAdminAccountsControllerCreateInputAccountCorporateAddress}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  corporateAddress: ApiAdminAccountsControllerCreateInputAccountCorporateAddress
  /**
   * Lock the credit threshold
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  lockCreditThreshold?: boolean
  /**
   * Whether the advertiser is required to have purchase orders on campaigns
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  mandatoryAdvertiserPos?: boolean
  /**
   * Whether the agency is required to have purchase orders on campaigns
   * @type {boolean}
   * @memberof ApiAdminAccountsControllerCreateInputAccount
   */
  mandatoryAgencyPos?: boolean
}

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum = {
  InstacartInvoicing: 'instacart_invoicing',
  CreditCard: 'credit_card',
} as const
export type ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountPaymentOptionEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum = {
  Email: 'email',
  Portal: 'portal',
} as const
export type ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountInvoiceOptionEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum = {
  _30: '30',
  _45: '45',
  _60: '60',
  _75: '75',
  _90: '90',
  _120: '120',
} as const
export type ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountPaymentTermDaysEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountAccountTypeEnum = {
  Default: 'default',
  Retailer: 'retailer',
  RetailerRsd: 'retailer_rsd',
  AdvertiserRtd: 'advertiser_rtd',
} as const
export type ApiAdminAccountsControllerCreateInputAccountAccountTypeEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountAccountTypeEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountAccountTypeEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountServingTypeEnum = {
  MarketplaceAndSyndication: 'marketplace_and_syndication',
  SyndicationNetwork: 'syndication_network',
  MarketplaceOnly: 'marketplace_only',
} as const
export type ApiAdminAccountsControllerCreateInputAccountServingTypeEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountServingTypeEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountServingTypeEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountServingGroupsEnum = {
  Admin: ':admin',
  AdsAdmin: 'Ads Admin',
  None: 'none',
  ConnectAdmin: 'connect_admin',
} as const
export type ApiAdminAccountsControllerCreateInputAccountServingGroupsEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountServingGroupsEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountServingGroupsEnum]

/**
 * @export
 */
export const ApiAdminAccountsControllerCreateInputAccountExternalMarketplaceEnum = {
  Provi: 'provi',
} as const
export type ApiAdminAccountsControllerCreateInputAccountExternalMarketplaceEnum =
  typeof ApiAdminAccountsControllerCreateInputAccountExternalMarketplaceEnum[keyof typeof ApiAdminAccountsControllerCreateInputAccountExternalMarketplaceEnum]

/**
 * Check if a given object implements the ApiAdminAccountsControllerCreateInputAccount interface.
 */
export function instanceOfApiAdminAccountsControllerCreateInputAccount(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'advertiserId' in value
  isInstance = isInstance && 'billPayer' in value
  isInstance = isInstance && 'corporateAddress' in value

  return isInstance
}

export function ApiAdminAccountsControllerCreateInputAccountFromJSON(
  json: any
): ApiAdminAccountsControllerCreateInputAccount {
  return ApiAdminAccountsControllerCreateInputAccountFromJSONTyped(json, false)
}

export function ApiAdminAccountsControllerCreateInputAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdminAccountsControllerCreateInputAccount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    advertiserId: json['advertiser_id'],
    billPayer: json['bill_payer'],
    agencyId: !exists(json, 'agency_id') ? undefined : json['agency_id'],
    paymentOption: !exists(json, 'payment_option') ? undefined : json['payment_option'],
    invoiceOption: !exists(json, 'invoice_option') ? undefined : json['invoice_option'],
    paymentTermDays: !exists(json, 'payment_term_days') ? undefined : json['payment_term_days'],
    emailDistribution: !exists(json, 'email_distribution') ? undefined : json['email_distribution'],
    alcohol: !exists(json, 'alcohol') ? undefined : json['alcohol'],
    countryCode: !exists(json, 'country_code') ? undefined : json['country_code'],
    isTest: !exists(json, 'is_test') ? undefined : json['is_test'],
    isSandbox: !exists(json, 'is_sandbox') ? undefined : json['is_sandbox'],
    isInHouse: !exists(json, 'is_in_house') ? undefined : json['is_in_house'],
    accountType: !exists(json, 'account_type') ? undefined : json['account_type'],
    servingType: !exists(json, 'serving_type') ? undefined : json['serving_type'],
    retailerIds: !exists(json, 'retailer_ids') ? undefined : json['retailer_ids'],
    servingGroups: !exists(json, 'serving_groups') ? undefined : json['serving_groups'],
    dailyBudgetCapCents: !exists(json, 'daily_budget_cap_cents')
      ? undefined
      : json['daily_budget_cap_cents'],
    lifetimeBudgetCapCents: !exists(json, 'lifetime_budget_cap_cents')
      ? undefined
      : json['lifetime_budget_cap_cents'],
    nonBillable: !exists(json, 'non_billable') ? undefined : json['non_billable'],
    autoAssignInternalUsersRoles: !exists(json, 'auto_assign_internal_users_roles')
      ? undefined
      : json['auto_assign_internal_users_roles'],
    externalMarketplace: !exists(json, 'external_marketplace')
      ? undefined
      : json['external_marketplace'],
    corporateAddress: ApiAdminAccountsControllerCreateInputAccountCorporateAddressFromJSON(
      json['corporate_address']
    ),
    lockCreditThreshold: !exists(json, 'lock_credit_threshold')
      ? undefined
      : json['lock_credit_threshold'],
    mandatoryAdvertiserPos: !exists(json, 'mandatory_advertiser_pos')
      ? undefined
      : json['mandatory_advertiser_pos'],
    mandatoryAgencyPos: !exists(json, 'mandatory_agency_pos')
      ? undefined
      : json['mandatory_agency_pos'],
  }
}

export function ApiAdminAccountsControllerCreateInputAccountToJSON(
  value?: ApiAdminAccountsControllerCreateInputAccount | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    advertiser_id: value.advertiserId,
    bill_payer: value.billPayer,
    agency_id: value.agencyId,
    payment_option: value.paymentOption,
    invoice_option: value.invoiceOption,
    payment_term_days: value.paymentTermDays,
    email_distribution: value.emailDistribution,
    alcohol: value.alcohol,
    country_code: value.countryCode,
    is_test: value.isTest,
    is_sandbox: value.isSandbox,
    is_in_house: value.isInHouse,
    account_type: value.accountType,
    serving_type: value.servingType,
    retailer_ids: value.retailerIds,
    serving_groups: value.servingGroups,
    daily_budget_cap_cents: value.dailyBudgetCapCents,
    lifetime_budget_cap_cents: value.lifetimeBudgetCapCents,
    non_billable: value.nonBillable,
    auto_assign_internal_users_roles: value.autoAssignInternalUsersRoles,
    external_marketplace: value.externalMarketplace,
    corporate_address: ApiAdminAccountsControllerCreateInputAccountCorporateAddressToJSON(
      value.corporateAddress
    ),
    lock_credit_threshold: value.lockCreditThreshold,
    mandatory_advertiser_pos: value.mandatoryAdvertiserPos,
    mandatory_agency_pos: value.mandatoryAgencyPos,
  }
}
