// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContent } from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContent'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContentFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContent'
import type { PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValues } from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions
 */
export interface PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [
      key: string
    ]: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions
   */
  products?: {
    [key: string]: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValues
  }
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContent}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions
   */
  content?: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions {
  return PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsToJSON(
  value?: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsContentToJSON(
      value.content
    ),
  }
}
