import { useDebugValue } from 'react'
import { FlipperIdType } from 'common/types'
import { useAuthContext, isFlipperEnabled } from 'context/auth'

/**
 *
 * @param flipper Flipper id that needs to be checked to enable flipper feature
 * @param disableFlipper Flipper id that needs to be checked to disable flipper feature.
 * Needed for when the feature is enabled by default and needs to be disabled for specific users
 * @returns true or false
 */
export default function useFlipper(flipper?: FlipperIdType, disableFlipper?: FlipperIdType) {
  const authContext = useAuthContext()

  // If flipper isn't provided, return true
  const enabled = !flipper || isFlipperEnabled(authContext, flipper)
  const disabled = disableFlipper && isFlipperEnabled(authContext, disableFlipper)

  const flipperValue = enabled && !disabled
  useDebugValue(flipperValue, boolVal => `${flipper} = ${boolVal}`)
  return flipperValue
}
