// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPage } from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPage'
import {
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPageFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPageFromJSONTyped,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPageToJSON,
} from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPage'
import type { ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreative } from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreative'
import {
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeFromJSONTyped,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeToJSON,
} from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreative'
import type { ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategy } from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategy'
import {
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategyFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategyFromJSONTyped,
  ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategyToJSON,
} from './ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategy'
import type { PutPrototypeCampaignsIdParamCampaignAdGroupAssets } from './PutPrototypeCampaignsIdParamCampaignAdGroupAssets'
import {
  PutPrototypeCampaignsIdParamCampaignAdGroupAssetsFromJSON,
  PutPrototypeCampaignsIdParamCampaignAdGroupAssetsFromJSONTyped,
  PutPrototypeCampaignsIdParamCampaignAdGroupAssetsToJSON,
} from './PutPrototypeCampaignsIdParamCampaignAdGroupAssets'

/**
 *
 * @export
 * @interface ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
 */
export interface ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup {
  /**
   * id of the ad group, required for update
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  id?: string
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  name: string
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  campaignId?: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  placementType: string
  /**
   *
   * @type {ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreative}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  creative: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreative
  /**
   * Assets for the creative
   * @type {Array<PutPrototypeCampaignsIdParamCampaignAdGroupAssets>}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  assets: Array<PutPrototypeCampaignsIdParamCampaignAdGroupAssets>
  /**
   *
   * @type {ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategy}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  targetingStrategy: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategy
  /**
   *
   * @type {ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPage}
   * @memberof ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup
   */
  brandPage?: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPage
}

/**
 * Check if a given object implements the ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup interface.
 */
export function instanceOfApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'assets' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupFromJSON(
  json: any
): ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup {
  return ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupFromJSONTyped(json, false)
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    placementType: json['placement_type'],
    creative: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeFromJSON(
      json['creative']
    ),
    assets: (json['assets'] as Array<any>).map(
      PutPrototypeCampaignsIdParamCampaignAdGroupAssetsFromJSON
    ),
    targetingStrategy:
      ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupToJSON(
  value?: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupCreativeToJSON(
      value.creative
    ),
    assets: (value.assets as Array<any>).map(
      PutPrototypeCampaignsIdParamCampaignAdGroupAssetsToJSON
    ),
    targeting_strategy:
      ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    brand_page: ApiPrototypeCampaignsControllerUpdateInputCampaignAdGroupBrandPageToJSON(
      value.brandPage
    ),
  }
}
