import { Color } from '@instacart/ids-core'
import { GenericMessageDescriptor, MessageIdType } from 'locales/types'

interface Colors {
  color: Color
  backgroundColor: Color
}

export interface StateMapValueType extends Colors {
  labelId: GenericMessageDescriptor
}

export type StateMapType = Record<string, StateMapValueType>

export const infoColors: Colors = {
  color: 'brandHighlightDark',
  backgroundColor: 'brandHighlightLight',
}

export const cautionColors: Colors = {
  color: 'brandExpressExtraDark',
  backgroundColor: 'brandExpressLight',
}

export const successColors: Colors = {
  color: 'systemSuccessDark',
  backgroundColor: 'systemSuccessLight',
}

export const errorColors: Colors = {
  color: 'systemDetrimentalRegular',
  backgroundColor: 'systemDetrimentalLight',
}

export const defaultColors: Colors = {
  color: 'systemGrayscale70',
  backgroundColor: 'systemGrayscale20',
}

const DRAFT: StateMapValueType = {
  labelId: 'common.status.draft',
  ...cautionColors,
}

const ENDED: StateMapValueType = {
  labelId: 'common.status.ended',
  ...defaultColors,
}

const OUT_OF_BUDGET: StateMapValueType = {
  labelId: 'common.status.out_of_budget',
  ...cautionColors,
}

const PAUSED: StateMapValueType = {
  labelId: 'common.status.paused',
  ...defaultColors,
}

const SCHEDULED: StateMapValueType = {
  labelId: 'common.status.scheduled',
  ...infoColors,
}

const UNAVAILABLE: StateMapValueType = {
  labelId: 'common.unavailable',
  ...defaultColors,
}

export const StateMap: StateMapType = {
  ACTIVE: {
    labelId: 'common.status.active',
    ...successColors,
  },
  APPEALED: {
    labelId: 'common.status.appealed',
    ...cautionColors,
  },
  APPROVED: {
    labelId: 'common.status.approved',
    ...defaultColors,
  },
  MERGEAPPRVOED: {
    labelId: 'common.status.mergeApproved',
    ...defaultColors,
  },
  HARDAPPRVOED: {
    labelId: 'common.status.hardApproved',
    ...defaultColors,
  },
  AD_GROUP_DRAFT: DRAFT,
  AD_GROUP_ENDED: ENDED,
  AD_GROUP_OUT_OF_BUDGET: OUT_OF_BUDGET,
  AD_GROUP_PAUSED: PAUSED,
  AD_GROUP_SCHEDULED: SCHEDULED,
  ARCHIVED: {
    labelId: 'brandPage.status.archived',
    ...defaultColors,
  },
  CAMPAIGN_DRAFT: DRAFT,
  CAMPAIGN_ENDED: ENDED,
  CAMPAIGN_OUT_OF_BUDGET: OUT_OF_BUDGET,
  CAMPAIGN_PAUSED: PAUSED,
  CAMPAIGN_SCHEDULED: SCHEDULED,
  CLOSED: {
    labelId: 'common.status.closed',
    ...defaultColors,
  },
  COMPLETED: {
    labelId: 'common.status.completed',
    ...defaultColors,
  },
  DELETED: {
    labelId: 'formattedStatus.deleted',
    ...defaultColors,
  },
  DRAFT,
  EDITABLE: {
    labelId: 'common.status.editable',
    ...defaultColors,
  },
  ENDED,
  ESCALATED: {
    labelId: 'common.status.escalated',
    ...errorColors,
  },
  FAILED_PAYMENT: {
    labelId: 'common.failedPayment',
    ...errorColors,
  },
  INACTIVE: {
    labelId: 'common.inactive',
    ...defaultColors,
  },
  INCOMPLETE_AD_GROUP: DRAFT,
  INCOMPLETE_AD_GROUP_PRODUCTS: DRAFT,
  IN_REVIEW: {
    labelId: 'common.status.in_review',
    ...infoColors,
  },
  MISSING_PRODUCT_IN_STORES: UNAVAILABLE,
  NOT_READY: {
    labelId: 'common.status.not_ready',
    ...defaultColors,
  },
  OUT_OF_BUDGET,
  PAUSED,
  PROMOTION_GROUP_PAUSED: PAUSED,
  PENDING: {
    labelId: 'common.status.pending',
    ...defaultColors,
  },
  REJECTED: {
    labelId: 'common.status.rejected',
    ...errorColors,
  },
  SCHEDULED,
  SENT: {
    labelId: 'common.status.scheduled',
    ...defaultColors,
  },
  STALE: {
    labelId: 'brandPage.status.stale',
    ...defaultColors,
  },
  TERMINATED: {
    labelId: 'pages.admin.liftTests.status.terminated',
    ...errorColors,
  },
  UNKNOWN: {
    labelId: 'common.status.unknown',
    ...defaultColors,
  },
  UNQUALIFIED: {
    labelId: 'pages.admin.waitlist.status.unqualified',
    ...errorColors,
  },
  PROCESSING_PRODUCT: {
    labelId: 'common.processing',
    ...defaultColors,
  },
  UNAVAILABLE_PRODUCT: UNAVAILABLE,
  UNAVAILABLE_PROVI_PRODUCT: UNAVAILABLE,
  UNAVAILABLE_IMAGE: {
    labelId: 'common.missingImage',
    ...defaultColors,
  },
}

export const StateTooltipMap: Record<string, MessageIdType> = {
  AD_GROUP_DRAFT: 'adGroup.skusTable.column.status.tooltips.adGroupDraft',
  AD_GROUP_ENDED: 'adGroup.skusTable.column.status.tooltips.adGroupEnded',
  AD_GROUP_PAUSED: 'adGroup.skusTable.column.status.tooltips.adGroupPaused',
  CAMPAIGN_DRAFT: 'adGroup.skusTable.column.status.tooltips.campaignDraft',
  CAMPAIGN_ENDED: 'adGroup.skusTable.column.status.tooltips.campaignEnded',
  CAMPAIGN_OUT_OF_BUDGET: 'adGroup.skusTable.column.status.tooltips.campaignOutOfBudget',
  CAMPAIGN_PAUSED: 'adGroup.skusTable.column.status.tooltips.campaignPaused',
  NOT_READY: 'adGroup.skusTable.column.status.tooltips.notReady',
  PROMOTION_GROUP_PAUSED: 'adGroup.skusTable.column.status.tooltips.promotionGroupPaused',
  UNAVAILABLE_PRODUCT: 'adGroup.skusTable.column.status.tooltips.missingProductInStore',
  UNAVAILABLE_PROVI_PRODUCT: 'adGroup.skusTable.column.status.tooltips.missingProviProductInStore',
}
