import { ADS_API_PATH } from 'common/constants'
import Client from 'service/client'
import { DisplayAssetPresignedUrlResponse } from 'service/openapi/__codegen__/models/DisplayAssetPresignedUrlResponse'
import { DisplayAssetResponse } from 'service/openapi/__codegen__/models/DisplayAssetResponse'

interface DisplayAssetRequestInterface {
  uploadDisplayAsset: (formData: FormData) => Promise<DisplayAssetResponse>
}

class DisplayAssetRequestClientClass extends Client implements DisplayAssetRequestInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/display/assets/`
  }

  uploadDisplayAsset = (formData: FormData): Promise<DisplayAssetResponse> => {
    return this.performRequest(
      {
        method: 'POST',
        path: `upload`,
        data: formData,
      },
      {
        skipRequestCasing: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  requestPresignedPostUrl = (formData: FormData): Promise<DisplayAssetPresignedUrlResponse> => {
    return this.performRequest(
      {
        method: 'POST',
        path: `presigned_url`,
        data: formData,
      },
      {
        skipRequestCasing: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }
}

export const displayAssetRequestClient = new DisplayAssetRequestClientClass()
