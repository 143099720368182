import { ClientCache } from 'service/client'
import OpenAPIAccountClient from 'service/openapi-client/account'
import { AccountClientInterface } from 'service/types'

/* A singleton is exported to prevent multiple instances
 * from existing.
 * Exporting AccountClient as a function to avoid hitting an undefined flipper when app initializes
 */
const clientCache: ClientCache<AccountClientInterface> = {}

export const getAccountClient = async () => {
  if (clientCache.current) {
    return clientCache.current
  }

  clientCache.current = new OpenAPIAccountClient()
  return clientCache.current
}
