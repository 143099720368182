import { ChevronDownIcon, Variant } from '@instacart/ids-core'
import { forwardRef } from 'react'

export type Position = number | ('up' | 'down' | 'left' | 'right')

const getYTranslate = (degrees: number) => {
  if (degrees < 180) {
    return degrees / 180
  }
  return 1 - (degrees - 180) / 180
}

function useStyles(position: Position) {
  let degrees = 0
  switch (position) {
    case 'up':
      degrees = 180
      break
    case 'down':
      degrees = 0
      break
    case 'left':
      degrees = 90
      break
    case 'right':
      degrees = 270
      break
    default:
      // number case
      degrees = position
  }
  const yTranslate = getYTranslate(degrees)
  return {
    icon: {
      display: 'block',
      transform: `rotate(${degrees}deg) translateY(${yTranslate}px)`,
      transition: 'transform 0.1s linear',
    },
  }
}

export interface RotateAbleChevronProps extends Variant<typeof ChevronDownIcon> {
  position: Position
}

// TODO: should migrate to use the new filter button styles
export const RotateAbleChevron = forwardRef<SVGSVGElement, RotateAbleChevronProps>(
  ({ position, ...props }, ref) => {
    const styles = useStyles(position)
    return <ChevronDownIcon ref={ref} {...props} css={styles.icon} />
  }
)
