// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiReportsControllerProviVideoAdGroupsInputDateRange
 */
export interface ApiReportsControllerProviVideoAdGroupsInputDateRange {
  /**
   * Defines the start of the date range of the data you want to report on. Send the start date in the ‘YYYY-MM-DD’ format. Do not send and the API includes data from every date on the report.
   * @type {string}
   * @memberof ApiReportsControllerProviVideoAdGroupsInputDateRange
   */
  startDate?: string
  /**
   * Defines the end of the date range of the data you want to report on. Send the end date in the ‘YYYY-MM-DD’ format. Do not send and Instacart Ads includes data from every date on the report.
   * @type {string}
   * @memberof ApiReportsControllerProviVideoAdGroupsInputDateRange
   */
  endDate?: string
}

/**
 * Check if a given object implements the ApiReportsControllerProviVideoAdGroupsInputDateRange interface.
 */
export function instanceOfApiReportsControllerProviVideoAdGroupsInputDateRange(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerProviVideoAdGroupsInputDateRangeFromJSON(
  json: any
): ApiReportsControllerProviVideoAdGroupsInputDateRange {
  return ApiReportsControllerProviVideoAdGroupsInputDateRangeFromJSONTyped(json, false)
}

export function ApiReportsControllerProviVideoAdGroupsInputDateRangeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerProviVideoAdGroupsInputDateRange {
  if (json === undefined || json === null) {
    return json
  }
  return {
    startDate: !exists(json, 'start_date') ? undefined : json['start_date'],
    endDate: !exists(json, 'end_date') ? undefined : json['end_date'],
  }
}

export function ApiReportsControllerProviVideoAdGroupsInputDateRangeToJSON(
  value?: ApiReportsControllerProviVideoAdGroupsInputDateRange | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    start_date: value.startDate,
    end_date: value.endDate,
  }
}
