import { useCallback } from 'react'
import { useAuthContext, isProviAccount } from 'context/auth'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'

export function useGetProductIdentifier() {
  const useGroupIdAsIdentifier = isProviAccount(useAuthContext())
  return useCallback(
    (product: GetProductsResponseData) => {
      if (useGroupIdAsIdentifier) {
        return product.attributes?.groupId
      }
      return product.id
    },
    [useGroupIdAsIdentifier]
  )
}
