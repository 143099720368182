// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltText } from './PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltText'
import {
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltTextFromJSON,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltTextToJSON,
} from './PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltText'
import type { PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImage } from './PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImage'
import {
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImageFromJSON,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImageFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImageToJSON,
} from './PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions
 */
export interface PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions
   */
  discriminator: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltText}
   * @memberof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions
   */
  altText?: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImage}
   * @memberof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions
   */
  image?: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions {
  return PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsToJSON(
  value?: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
