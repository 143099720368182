// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargeting } from './AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargeting'
import {
  AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargetingFromJSON,
  AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargetingFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargetingToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargeting'

/**
 *
 * @export
 * @interface AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
 */
export interface AdminDisplayAdGroupResponseDataAttributesTargetingStrategy {
  /**
   * Targeted keywords
   * @type {Array<string>}
   * @memberof AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
   */
  keywords?: Array<string>
  /**
   * Specify how broadly you would like the keywords to be matched against search terms. Default is broad if not provided.
   * @type {string}
   * @memberof AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
   */
  keywordMatchType?: AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum
  /**
   * Targeting shortcut, need to pass both this field and user_targeting
   * @type {string}
   * @memberof AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
   */
  targetingShortcut?: AdminDisplayAdGroupResponseDataAttributesTargetingStrategyTargetingShortcutEnum
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargeting}
   * @memberof AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
   */
  userTargeting?: AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargeting
  /**
   * Targeting rule
   * @type {{ [key: string]: any; }}
   * @memberof AdminDisplayAdGroupResponseDataAttributesTargetingStrategy
   */
  targetingRule?: { [key: string]: any }
}

/**
 * @export
 */
export const AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum = {
  Exact: 'exact',
  Narrow: 'narrow',
  Broad: 'broad',
} as const
export type AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum =
  typeof AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum[keyof typeof AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum]

/**
 * @export
 */
export const AdminDisplayAdGroupResponseDataAttributesTargetingStrategyTargetingShortcutEnum = {
  Unknown: 'TARGETING_SHORTCUT_UNKNOWN',
  None: 'TARGETING_SHORTCUT_NONE',
  Custom: 'TARGETING_SHORTCUT_CUSTOM',
  DraftAdgroup: 'TARGETING_SHORTCUT_DRAFT_ADGROUP',
  NonAudienceTargeting: 'TARGETING_SHORTCUT_NON_AUDIENCE_TARGETING',
  NewToInstacart: 'TARGETING_SHORTCUT_NEW_TO_INSTACART',
  NewToCategoryOrBrand: 'TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND',
  BuyMyCategoryNotMyBrand: 'TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND',
  BuyMyBrandNotMyProduct: 'TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT',
  InteractWithXNotBuyBrand: 'TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND',
  LapsedBuyer: 'TARGETING_SHORTCUT_LAPSED_BUYER',
  LoyalBuyer: 'TARGETING_SHORTCUT_LOYAL_BUYER',
} as const
export type AdminDisplayAdGroupResponseDataAttributesTargetingStrategyTargetingShortcutEnum =
  typeof AdminDisplayAdGroupResponseDataAttributesTargetingStrategyTargetingShortcutEnum[keyof typeof AdminDisplayAdGroupResponseDataAttributesTargetingStrategyTargetingShortcutEnum]

/**
 * Check if a given object implements the AdminDisplayAdGroupResponseDataAttributesTargetingStrategy interface.
 */
export function instanceOfAdminDisplayAdGroupResponseDataAttributesTargetingStrategy(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function AdminDisplayAdGroupResponseDataAttributesTargetingStrategyFromJSON(
  json: any
): AdminDisplayAdGroupResponseDataAttributesTargetingStrategy {
  return AdminDisplayAdGroupResponseDataAttributesTargetingStrategyFromJSONTyped(json, false)
}

export function AdminDisplayAdGroupResponseDataAttributesTargetingStrategyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminDisplayAdGroupResponseDataAttributesTargetingStrategy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keywords: !exists(json, 'keywords') ? undefined : json['keywords'],
    keywordMatchType: !exists(json, 'keyword_match_type') ? undefined : json['keyword_match_type'],
    targetingShortcut: !exists(json, 'targeting_shortcut') ? undefined : json['targeting_shortcut'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargetingFromJSON(
          json['user_targeting']
        ),
    targetingRule: !exists(json, 'targeting_rule') ? undefined : json['targeting_rule'],
  }
}

export function AdminDisplayAdGroupResponseDataAttributesTargetingStrategyToJSON(
  value?: AdminDisplayAdGroupResponseDataAttributesTargetingStrategy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keywords: value.keywords,
    keyword_match_type: value.keywordMatchType,
    targeting_shortcut: value.targetingShortcut,
    user_targeting: AdminDisplayAdGroupResponseDataAttributesTargetingStrategyUserTargetingToJSON(
      value.userTargeting
    ),
    targeting_rule: value.targetingRule,
  }
}
