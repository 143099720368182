// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApplicableLabelsAdminBrandPageResponseData } from './ApplicableLabelsAdminBrandPageResponseData'
import {
  ApplicableLabelsAdminBrandPageResponseDataFromJSON,
  ApplicableLabelsAdminBrandPageResponseDataFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseDataToJSON,
} from './ApplicableLabelsAdminBrandPageResponseData'
import type { ApplicableLabelsAdminBrandPageResponseMeta } from './ApplicableLabelsAdminBrandPageResponseMeta'
import {
  ApplicableLabelsAdminBrandPageResponseMetaFromJSON,
  ApplicableLabelsAdminBrandPageResponseMetaFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseMetaToJSON,
} from './ApplicableLabelsAdminBrandPageResponseMeta'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponse
 */
export interface ApplicableLabelsAdminBrandPageResponse {
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseData}
   * @memberof ApplicableLabelsAdminBrandPageResponse
   */
  data: ApplicableLabelsAdminBrandPageResponseData
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseMeta}
   * @memberof ApplicableLabelsAdminBrandPageResponse
   */
  meta: ApplicableLabelsAdminBrandPageResponseMeta
}

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponse interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponse {
  return ApplicableLabelsAdminBrandPageResponseFromJSONTyped(json, false)
}

export function ApplicableLabelsAdminBrandPageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: ApplicableLabelsAdminBrandPageResponseDataFromJSON(json['data']),
    meta: ApplicableLabelsAdminBrandPageResponseMetaFromJSON(json['meta']),
  }
}

export function ApplicableLabelsAdminBrandPageResponseToJSON(
  value?: ApplicableLabelsAdminBrandPageResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ApplicableLabelsAdminBrandPageResponseDataToJSON(value.data),
    meta: ApplicableLabelsAdminBrandPageResponseMetaToJSON(value.meta),
  }
}
