// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ProductsSuggestedBidResponseDataAttributes } from './ProductsSuggestedBidResponseDataAttributes'
import {
  ProductsSuggestedBidResponseDataAttributesFromJSON,
  ProductsSuggestedBidResponseDataAttributesFromJSONTyped,
  ProductsSuggestedBidResponseDataAttributesToJSON,
} from './ProductsSuggestedBidResponseDataAttributes'

/**
 *
 * @export
 * @interface ProductsSuggestedBidResponseData
 */
export interface ProductsSuggestedBidResponseData {
  /**
   * Suggested Bid Id
   * @type {string}
   * @memberof ProductsSuggestedBidResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ProductsSuggestedBidResponseData
   */
  type: ProductsSuggestedBidResponseDataTypeEnum
  /**
   *
   * @type {ProductsSuggestedBidResponseDataAttributes}
   * @memberof ProductsSuggestedBidResponseData
   */
  attributes: ProductsSuggestedBidResponseDataAttributes
}

/**
 * @export
 */
export const ProductsSuggestedBidResponseDataTypeEnum = {
  SuggestedBid: 'suggested_bid',
} as const
export type ProductsSuggestedBidResponseDataTypeEnum =
  typeof ProductsSuggestedBidResponseDataTypeEnum[keyof typeof ProductsSuggestedBidResponseDataTypeEnum]

/**
 * Check if a given object implements the ProductsSuggestedBidResponseData interface.
 */
export function instanceOfProductsSuggestedBidResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function ProductsSuggestedBidResponseDataFromJSON(
  json: any
): ProductsSuggestedBidResponseData {
  return ProductsSuggestedBidResponseDataFromJSONTyped(json, false)
}

export function ProductsSuggestedBidResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductsSuggestedBidResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: ProductsSuggestedBidResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function ProductsSuggestedBidResponseDataToJSON(
  value?: ProductsSuggestedBidResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: ProductsSuggestedBidResponseDataAttributesToJSON(value.attributes),
  }
}
