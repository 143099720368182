// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface AdminDisplayAdGroupResponseDataAttributesCreativeActions
 */
export interface AdminDisplayAdGroupResponseDataAttributesCreativeActions {
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainItemGrid?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainProductGroupGrid?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainCollectionGrid?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActions
   */
  bannerClick?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the AdminDisplayAdGroupResponseDataAttributesCreativeActions interface.
 */
export function instanceOfAdminDisplayAdGroupResponseDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON(
  json: any
): AdminDisplayAdGroupResponseDataAttributesCreativeActions {
  return AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminDisplayAdGroupResponseDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsToJSON(
  value?: AdminDisplayAdGroupResponseDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      AdminDisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
