// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssets } from './PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssets'
import {
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssetsFromJSON,
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped,
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssetsToJSON,
} from './PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssets'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdParamBlockHeroBannerDisplay
 */
export interface PutDisplayAdGroupsIdParamBlockHeroBannerDisplay {
  /**
   * id of the block
   * @type {string}
   * @memberof PutDisplayAdGroupsIdParamBlockHeroBannerDisplay
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdParamBlockHeroBannerDisplay
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdParamBlockHeroBannerDisplay
   */
  type: PutDisplayAdGroupsIdParamBlockHeroBannerDisplayTypeEnum
  /**
   *
   * @type {PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssets}
   * @memberof PutDisplayAdGroupsIdParamBlockHeroBannerDisplay
   */
  assets: PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssets
}

/**
 * @export
 */
export const PutDisplayAdGroupsIdParamBlockHeroBannerDisplayTypeEnum = {
  BlockHeroBannerV1: 'block_hero_banner.v1',
} as const
export type PutDisplayAdGroupsIdParamBlockHeroBannerDisplayTypeEnum =
  typeof PutDisplayAdGroupsIdParamBlockHeroBannerDisplayTypeEnum[keyof typeof PutDisplayAdGroupsIdParamBlockHeroBannerDisplayTypeEnum]

/**
 * Check if a given object implements the PutDisplayAdGroupsIdParamBlockHeroBannerDisplay interface.
 */
export function instanceOfPutDisplayAdGroupsIdParamBlockHeroBannerDisplay(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSON(
  json: any
): PutDisplayAdGroupsIdParamBlockHeroBannerDisplay {
  return PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdParamBlockHeroBannerDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssetsFromJSON(json['assets']),
  }
}

export function PutDisplayAdGroupsIdParamBlockHeroBannerDisplayToJSON(
  value?: PutDisplayAdGroupsIdParamBlockHeroBannerDisplay | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PutDisplayAdGroupsIdParamBlockHeroBannerDisplayAssetsToJSON(value.assets),
  }
}
