import { ADS_API_PATH } from 'common/constants'
import Client from '../client'

interface UserAttributes {
  email: string
  firstName: string
  lastName: string
  userType: UserType
  confirmedAt: string | null
  confirmationSentAt: string
  createdAt: string
  currentSignInAt: string | null
  apiKey?: string
  authenticationToken?: string
}

export interface User {
  id: string
  attributes: UserAttributes
}

// eslint-disable-next-line no-restricted-syntax
export enum UserType {
  InstacartEmployee = 'instacart_employee',
  AdvertiserUser = 'advertiser_user',
  AgencyUser = 'agency_user',
  ApiUser = 'api_user',
}

interface CreateNewUserBody {
  email: string
  firstName: string
  lastName: string
  userType?: UserType
}

class AdminUserClientClass extends Client {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/admin/users`
  }

  createNewUser = (requestBody: CreateNewUserBody) => {
    return this.performRequest({
      method: 'post',
      data: requestBody,
    })
  }

  resendConfirmation = (userId: string) => {
    return this.performRequest({
      method: 'post',
      path: `/${userId}/resend_confirmation`,
    })
  }
}

/* A singleton is exported to prevent multiple instances
 * from existing.
 */
export const AdminUserClient = new AdminUserClientClass()
