// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerInsightsInputDateRange } from './ApiReportsControllerInsightsInputDateRange'
import {
  ApiReportsControllerInsightsInputDateRangeFromJSON,
  ApiReportsControllerInsightsInputDateRangeFromJSONTyped,
  ApiReportsControllerInsightsInputDateRangeToJSON,
} from './ApiReportsControllerInsightsInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerInsightsInput
 */
export interface ApiReportsControllerInsightsInput {
  /**
   *
   * @type {ApiReportsControllerInsightsInputDateRange}
   * @memberof ApiReportsControllerInsightsInput
   */
  dateRange: ApiReportsControllerInsightsInputDateRange
  /**
   * Daily, weekly, or monthly data
   * @type {string}
   * @memberof ApiReportsControllerInsightsInput
   */
  timespan: ApiReportsControllerInsightsInputTimespanEnum
  /**
   * Dimensions to break the sales data down by
   * @type {Array<string>}
   * @memberof ApiReportsControllerInsightsInput
   */
  dimensions: Array<ApiReportsControllerInsightsInputDimensionsEnum>
  /**
   * Metrics based on dimensions
   * @type {Array<string>}
   * @memberof ApiReportsControllerInsightsInput
   */
  metrics: Array<ApiReportsControllerInsightsInputMetricsEnum>
}

/**
 * @export
 */
export const ApiReportsControllerInsightsInputTimespanEnum = {
  Day: 'day',
  Week: 'week',
  WeekSun: 'week_sun',
  Month: 'month',
} as const
export type ApiReportsControllerInsightsInputTimespanEnum =
  typeof ApiReportsControllerInsightsInputTimespanEnum[keyof typeof ApiReportsControllerInsightsInputTimespanEnum]

/**
 * @export
 */
export const ApiReportsControllerInsightsInputDimensionsEnum = {
  EntityLevel2Name: 'entity_level_2_name',
  EntityBrandName: 'entity_brand_name',
  Department: 'department',
  SuperCategory: 'super_category',
  Category: 'category',
  SubCategory: 'sub_category',
  ClassifiedCode: 'classified_code',
} as const
export type ApiReportsControllerInsightsInputDimensionsEnum =
  typeof ApiReportsControllerInsightsInputDimensionsEnum[keyof typeof ApiReportsControllerInsightsInputDimensionsEnum]

/**
 * @export
 */
export const ApiReportsControllerInsightsInputMetricsEnum = {
  DollarVolume: 'dollar_volume',
  Units: 'units',
  DollarVolumeYago: 'dollar_volume_yago',
  UnitsYago: 'units_yago',
  DollarVolumeYagoChange: 'dollar_volume_yago_change',
  UnitsYagoChange: 'units_yago_change',
  DollarVolumeShare: 'dollar_volume_share',
} as const
export type ApiReportsControllerInsightsInputMetricsEnum =
  typeof ApiReportsControllerInsightsInputMetricsEnum[keyof typeof ApiReportsControllerInsightsInputMetricsEnum]

/**
 * Check if a given object implements the ApiReportsControllerInsightsInput interface.
 */
export function instanceOfApiReportsControllerInsightsInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'dateRange' in value
  isInstance = isInstance && 'timespan' in value
  isInstance = isInstance && 'dimensions' in value
  isInstance = isInstance && 'metrics' in value

  return isInstance
}

export function ApiReportsControllerInsightsInputFromJSON(
  json: any
): ApiReportsControllerInsightsInput {
  return ApiReportsControllerInsightsInputFromJSONTyped(json, false)
}

export function ApiReportsControllerInsightsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerInsightsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: ApiReportsControllerInsightsInputDateRangeFromJSON(json['date_range']),
    timespan: json['timespan'],
    dimensions: json['dimensions'],
    metrics: json['metrics'],
  }
}

export function ApiReportsControllerInsightsInputToJSON(
  value?: ApiReportsControllerInsightsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerInsightsInputDateRangeToJSON(value.dateRange),
    timespan: value.timespan,
    dimensions: value.dimensions,
    metrics: value.metrics,
  }
}
