import { Dispatch, useCallback, useState } from 'react'
import { ApiErrorCodes, ClientError, getApiErrorCode } from 'common/utils'
import {
  MfaFactor,
  MultiFactorAuthAction,
  MultiFactorAuthAttempt,
  MultiFactorAuthError,
  MultiFactorAuthType,
} from 'components/MultiFactorAuth/utils/multiFactorAuth.types'
import useClientTimezone from 'hooks/useClientTimezone'
import { MessageIdType } from 'locales/types'
import { AdUsersMultiFactorAuthApi } from 'service/apis/AdUsersMultiFactorAuthApi'
import { AdUsersSessionsApi } from 'service/apis/AdUsersSessionsApi'
import { ApiAdUsersMultiFactorAuthControllerConfirmInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersMultiFactorAuthControllerConfirmInput'
import { ApiAdUsersMultiFactorAuthControllerCreateInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersMultiFactorAuthControllerCreateInput'
import { ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersMultiFactorAuthControllerDestroyInput'
import { ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInput'
import { ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInput'
import { ApiAdUsersSessionsControllerGenerateAndSendOtpInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersSessionsControllerGenerateAndSendOtpInput'

type CreateMultiFactorAuthOptions = {
  onSuccess?: (mfaFactor: MfaFactor) => void
  onError?: () => void
}

export const useCreateMultiFactorAuth = (options: CreateMultiFactorAuthOptions) => {
  const [loading, setLoading] = useState(false)
  const clientTimezone = useClientTimezone()

  const create = async (mfaType: MultiFactorAuthType) => {
    setLoading(true)
    try {
      const response = await AdUsersMultiFactorAuthApi.postMultiFactorAuth({
        body: {
          mfaType: mfaType as string as ApiAdUsersMultiFactorAuthControllerCreateInputMfaTypeEnum,
        },
        clientTimezone,
      })
      options.onSuccess?.(response?.data?.attributes as MfaFactor)
    } catch (err) {
      options.onError?.()
    } finally {
      setLoading(false)
    }
  }

  return {
    createMultiFactorAuth: create,
    createMultiFactorAuthLoading: loading,
  }
}

type GenerateAndSendOtpOptions = {
  action: MultiFactorAuthAction
  onSendOtp?: (mfaType: MultiFactorAuthType) => void
  onSuccess?: () => void
  onError?: (messageId: MessageIdType) => void
}

export const useGenerateAndSendOtp = ({
  action,
  onSendOtp,
  onSuccess,
  onError,
}: GenerateAndSendOtpOptions) => {
  const [loading, setLoading] = useState(false)
  const clientTimezone = useClientTimezone()

  const generateAndSendOtp = useCallback(
    async (mfaType: MultiFactorAuthType) => {
      setLoading(true)
      try {
        if (onSendOtp) {
          await onSendOtp(mfaType)
        } else if (action === MultiFactorAuthAction.Login) {
          await AdUsersSessionsApi.postAuthMultiFactorGenerateAndSendOtp({
            body: {
              mfaType:
                mfaType as string as ApiAdUsersSessionsControllerGenerateAndSendOtpInputMfaTypeEnum,
            },
            clientTimezone,
          })
        } else {
          await AdUsersMultiFactorAuthApi.postMultiFactorAuthGenerateAndSendOtp({
            body: {
              mfaType:
                mfaType as string as ApiAdUsersMultiFactorAuthControllerGenerateAndSendOtpInputMfaTypeEnum,
            },
            clientTimezone,
          })
        }

        onSuccess?.()
      } catch (error) {
        const errorCode = getApiErrorCode(error as ClientError)

        if (errorCode === ApiErrorCodes.RateLimited) {
          onError?.('pages.multiFactorAuth.sendOtp.rateLimitedError')
        } else {
          onError?.('pages.multiFactorAuth.sendOtp.error')
        }
      } finally {
        setLoading(false)
      }
    },
    [onSendOtp, action, onSuccess, clientTimezone, onError]
  )

  return {
    generateAndSendOtp,
    generateAndSendOtpLoading: loading,
  }
}

type MultiFactorAuthSubmitOptions = {
  onSubmit: (multiFactorAuthAttempt: MultiFactorAuthAttempt) => void
  setSubmitError: Dispatch<MultiFactorAuthError | undefined>
  onSuccess?: () => void
  onError?: (error?: ClientError) => void
}

export const useMultiFactorAuthSubmitCallback = (options: MultiFactorAuthSubmitOptions) => {
  const { onSubmit, setSubmitError, onSuccess, onError } = options
  const [loading, setLoading] = useState(false)
  const [accountLocked, setAccountLocked] = useState(false)

  const submit = async (multiFactorAuthAttempt: MultiFactorAuthAttempt) => {
    try {
      setLoading(true)
      await onSubmit(multiFactorAuthAttempt)
      onSuccess?.()
    } catch (err) {
      const error = err as ClientError
      const errorCode = getApiErrorCode(error)

      if (errorCode === ApiErrorCodes.AccountLocked) {
        setSubmitError(undefined)
        setAccountLocked(true)
      } else if (errorCode === ApiErrorCodes.InvalidMfaAttempt) {
        setSubmitError(MultiFactorAuthError.InvalidAttempt)
      } else {
        setSubmitError(MultiFactorAuthError.ServerError)
        onError?.(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    submit,
    submitLoading: loading,
    accountLocked,
  }
}

export const useConfirmMultiFactorAuth = () => {
  return async (multiFactorAuthAttempt: MultiFactorAuthAttempt) => {
    await AdUsersMultiFactorAuthApi.postMultiFactorAuthConfirm({
      body: {
        otp: multiFactorAuthAttempt.otp,
        mfaType:
          multiFactorAuthAttempt.mfaType as string as ApiAdUsersMultiFactorAuthControllerConfirmInputMfaTypeEnum,
      },
    })
  }
}

export const useRegenerateBackupCodes = () => {
  return async (multiFactorAuthAttempt: MultiFactorAuthAttempt) => {
    const response = await AdUsersMultiFactorAuthApi.postMultiFactorAuthRegenerateBackupCodes({
      body: {
        otp: multiFactorAuthAttempt.otp,
        mfaType:
          multiFactorAuthAttempt.mfaType as string as ApiAdUsersMultiFactorAuthControllerRegenerateBackupCodesInputMfaTypeEnum,
      },
    })
    return response.data.attributes as MfaFactor
  }
}

export const useDeleteMultiFactorAuth = () => {
  return async (multiFactorAuthAttempt: MultiFactorAuthAttempt) => {
    await AdUsersMultiFactorAuthApi.deleteMultiFactorAuth({
      body: {
        otp: multiFactorAuthAttempt.otp,
        mfaType:
          multiFactorAuthAttempt.mfaType as string as ApiAdUsersMultiFactorAuthControllerDestroyInputMfaTypeEnum,
      },
    })
  }
}
