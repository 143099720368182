// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostBrandPagesParamBlockHeroBannerAssetsMobile
 */
export interface PostBrandPagesParamBlockHeroBannerAssetsMobile {
  /**
   * The id of the asset. Only needed for update
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  id?: string
  /**
   * URL for previewing, may change over time
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  previewUrl: string
  /**
   * URI reference to the file used to determine if underlying asset has changed
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  uploadedUri: string
  /**
   * Alt text of asset
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  altText: string
  /**
   * The name of the file
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  uploadedFileName?: string
  /**
   * This is an opaque token for the asset, it must not be interpreted in any way. It's content and format are subject to change at any time without notice.
   * @type {string}
   * @memberof PostBrandPagesParamBlockHeroBannerAssetsMobile
   */
  token?: string
}

/**
 * Check if a given object implements the PostBrandPagesParamBlockHeroBannerAssetsMobile interface.
 */
export function instanceOfPostBrandPagesParamBlockHeroBannerAssetsMobile(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'previewUrl' in value
  isInstance = isInstance && 'uploadedUri' in value
  isInstance = isInstance && 'altText' in value

  return isInstance
}

export function PostBrandPagesParamBlockHeroBannerAssetsMobileFromJSON(
  json: any
): PostBrandPagesParamBlockHeroBannerAssetsMobile {
  return PostBrandPagesParamBlockHeroBannerAssetsMobileFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockHeroBannerAssetsMobileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockHeroBannerAssetsMobile {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    previewUrl: json['preview_url'],
    uploadedUri: json['uploaded_uri'],
    altText: json['alt_text'],
    uploadedFileName: !exists(json, 'uploaded_file_name') ? undefined : json['uploaded_file_name'],
    token: !exists(json, 'token') ? undefined : json['token'],
  }
}

export function PostBrandPagesParamBlockHeroBannerAssetsMobileToJSON(
  value?: PostBrandPagesParamBlockHeroBannerAssetsMobile | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    preview_url: value.previewUrl,
    uploaded_uri: value.uploadedUri,
    alt_text: value.altText,
    uploaded_file_name: value.uploadedFileName,
    token: value.token,
  }
}
