// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltText } from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltText'
import {
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSON,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextToJSON,
} from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltText'
import type { PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage } from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage'
import {
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSON,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSONTyped,
  PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageToJSON,
} from './PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamProductAssetFieldDecisions
 */
export interface PutDisplayCampaignsIdParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  discriminator: PutDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltText}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  altText?: PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage}
   * @memberof PutDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  image?: PutDisplayCampaignsIdParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const PutDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof PutDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof PutDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamProductAssetFieldDecisions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamProductAssetFieldDecisions {
  return PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function PutDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: PutDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutDisplayCampaignsIdParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
