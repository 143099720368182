import { CloseIcon, spacing, useTheme } from '@instacart/ids-core'
import { ComponentPropsWithoutRef } from 'react'
import { useIntl } from 'common'
import IconButtonInline from 'components/molecules/IconButtonInline'
import { DEFAULT_ICON_SIZE } from '../utils'

const useStyles = () => {
  const theme = useTheme()
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing.s12,
      ...theme.typography.subtitleLarge,
    },
    closeIcon: {
      flex: '0 0 auto',
    },
  }
}

export interface ModalHeaderProps extends ComponentPropsWithoutRef<'div'> {
  onClose?: () => void
  hideCloseIcon?: boolean
  titleTestId?: string
}

export const ModalHeader = ({
  children,
  onClose,
  hideCloseIcon,
  titleTestId,
}: ModalHeaderProps) => {
  const { formatMessage } = useIntl()
  const styles = useStyles()

  return (
    <div css={styles.header} data-testid={titleTestId}>
      {children}
      {hideCloseIcon || (
        <IconButtonInline
          icon={CloseIcon}
          color="systemGrayscale70"
          size={DEFAULT_ICON_SIZE}
          accessibleLabel={formatMessage({ id: 'common.close' })}
          onClick={onClose}
          data-testid="modal-close"
        />
      )}
    </div>
  )
}
