// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchy } from './GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchy'
import {
  GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchyFromJSON,
  GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchyToJSON,
} from './GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchy'
import type { GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValues } from './GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValues'
import {
  GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValues'
import type { GetVideoAdGroupsParamBrandPageFieldDecisionsTitle } from './GetVideoAdGroupsParamBrandPageFieldDecisionsTitle'
import {
  GetVideoAdGroupsParamBrandPageFieldDecisionsTitleFromJSON,
  GetVideoAdGroupsParamBrandPageFieldDecisionsTitleFromJSONTyped,
  GetVideoAdGroupsParamBrandPageFieldDecisionsTitleToJSON,
} from './GetVideoAdGroupsParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamBrandPageFieldDecisions
 */
export interface GetVideoAdGroupsParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetVideoAdGroupsParamBrandPageFieldDecisionsTitle}
   * @memberof GetVideoAdGroupsParamBrandPageFieldDecisions
   */
  title?: GetVideoAdGroupsParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchy>}
   * @memberof GetVideoAdGroupsParamBrandPageFieldDecisions
   */
  hierarchy?: Array<GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof GetVideoAdGroupsParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamBrandPageFieldDecisions interface.
 */
export function instanceOfGetVideoAdGroupsParamBrandPageFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamBrandPageFieldDecisions {
  return GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : GetVideoAdGroupsParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function GetVideoAdGroupsParamBrandPageFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: GetVideoAdGroupsParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            GetVideoAdGroupsParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(
            value.taxonomy,
            GetVideoAdGroupsParamBrandPageFieldDecisionsTaxonomyValuesToJSON
          ),
  }
}
