// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamProductAssetFieldDecisionsAltText } from './GetVideoAdGroupsParamProductAssetFieldDecisionsAltText'
import {
  GetVideoAdGroupsParamProductAssetFieldDecisionsAltTextFromJSON,
  GetVideoAdGroupsParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  GetVideoAdGroupsParamProductAssetFieldDecisionsAltTextToJSON,
} from './GetVideoAdGroupsParamProductAssetFieldDecisionsAltText'
import type { GetVideoAdGroupsParamProductAssetFieldDecisionsImage } from './GetVideoAdGroupsParamProductAssetFieldDecisionsImage'
import {
  GetVideoAdGroupsParamProductAssetFieldDecisionsImageFromJSON,
  GetVideoAdGroupsParamProductAssetFieldDecisionsImageFromJSONTyped,
  GetVideoAdGroupsParamProductAssetFieldDecisionsImageToJSON,
} from './GetVideoAdGroupsParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamProductAssetFieldDecisions
 */
export interface GetVideoAdGroupsParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamProductAssetFieldDecisions
   */
  discriminator: GetVideoAdGroupsParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {GetVideoAdGroupsParamProductAssetFieldDecisionsAltText}
   * @memberof GetVideoAdGroupsParamProductAssetFieldDecisions
   */
  altText?: GetVideoAdGroupsParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {GetVideoAdGroupsParamProductAssetFieldDecisionsImage}
   * @memberof GetVideoAdGroupsParamProductAssetFieldDecisions
   */
  image?: GetVideoAdGroupsParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const GetVideoAdGroupsParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetVideoAdGroupsParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof GetVideoAdGroupsParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof GetVideoAdGroupsParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the GetVideoAdGroupsParamProductAssetFieldDecisions interface.
 */
export function instanceOfGetVideoAdGroupsParamProductAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamProductAssetFieldDecisions {
  return GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetVideoAdGroupsParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetVideoAdGroupsParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function GetVideoAdGroupsParamProductAssetFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: GetVideoAdGroupsParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetVideoAdGroupsParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
