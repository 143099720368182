// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignAnalyticsResponseDataAttributesSeries } from './GetDisplayCampaignAnalyticsResponseDataAttributesSeries'
import {
  GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSON,
  GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSONTyped,
  GetDisplayCampaignAnalyticsResponseDataAttributesSeriesToJSON,
} from './GetDisplayCampaignAnalyticsResponseDataAttributesSeries'
import type { GetDisplayCampaignsIdAnalyticsParamDataPoint } from './GetDisplayCampaignsIdAnalyticsParamDataPoint'
import {
  GetDisplayCampaignsIdAnalyticsParamDataPointFromJSON,
  GetDisplayCampaignsIdAnalyticsParamDataPointFromJSONTyped,
  GetDisplayCampaignsIdAnalyticsParamDataPointToJSON,
} from './GetDisplayCampaignsIdAnalyticsParamDataPoint'

/**
 *
 * @export
 * @interface GetDisplayCampaignAnalyticsResponseDataAttributes
 */
export interface GetDisplayCampaignAnalyticsResponseDataAttributes {
  /**
   * Chart metric
   * @type {string}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributes
   */
  metric: string
  /**
   *
   * @type {Array<GetDisplayCampaignAnalyticsResponseDataAttributesSeries>}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributes
   */
  series: Array<GetDisplayCampaignAnalyticsResponseDataAttributesSeries>
  /**
   * Chart series data
   * @type {Array<Array<GetDisplayCampaignsIdAnalyticsParamDataPoint>>}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributes
   */
  data: Array<Array<GetDisplayCampaignsIdAnalyticsParamDataPoint>>
  /**
   * Keys to obscure
   * @type {Array<string>}
   * @memberof GetDisplayCampaignAnalyticsResponseDataAttributes
   */
  keysToObscure: Array<string>
}

/**
 * Check if a given object implements the GetDisplayCampaignAnalyticsResponseDataAttributes interface.
 */
export function instanceOfGetDisplayCampaignAnalyticsResponseDataAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'metric' in value
  isInstance = isInstance && 'series' in value
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'keysToObscure' in value

  return isInstance
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesFromJSON(
  json: any
): GetDisplayCampaignAnalyticsResponseDataAttributes {
  return GetDisplayCampaignAnalyticsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignAnalyticsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metric: json['metric'],
    series: (json['series'] as Array<any>).map(
      GetDisplayCampaignAnalyticsResponseDataAttributesSeriesFromJSON
    ),
    data: json['data'],
    keysToObscure: json['keys_to_obscure'],
  }
}

export function GetDisplayCampaignAnalyticsResponseDataAttributesToJSON(
  value?: GetDisplayCampaignAnalyticsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metric: value.metric,
    series: (value.series as Array<any>).map(
      GetDisplayCampaignAnalyticsResponseDataAttributesSeriesToJSON
    ),
    data: value.data,
    keys_to_obscure: value.keysToObscure,
  }
}
