// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamAssetAltTextLabels } from './PostAdminBrandPagesIdLabelParamAssetAltTextLabels'
import {
  PostAdminBrandPagesIdLabelParamAssetAltTextLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamAssetAltTextLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamAssetAltTextLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamAssetAltTextLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamAssetAltText
 */
export interface PostAdminBrandPagesIdLabelParamAssetAltText {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamAssetAltTextLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamAssetAltText
   */
  labels: Array<PostAdminBrandPagesIdLabelParamAssetAltTextLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamAssetAltText
   */
  componentType: PostAdminBrandPagesIdLabelParamAssetAltTextComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamAssetAltTextComponentTypeEnum = {
  LogoAltText: 'LOGO_ALT_TEXT',
  HeroImageAltText: 'HERO_IMAGE_ALT_TEXT',
  InPageBannerAltText: 'IN_PAGE_BANNER_ALT_TEXT',
  ImageAndTextAltText: 'IMAGE_AND_TEXT_ALT_TEXT',
} as const
export type PostAdminBrandPagesIdLabelParamAssetAltTextComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamAssetAltTextComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamAssetAltTextComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamAssetAltText interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamAssetAltText(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamAssetAltTextFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamAssetAltText {
  return PostAdminBrandPagesIdLabelParamAssetAltTextFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamAssetAltTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamAssetAltText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamAssetAltTextLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamAssetAltTextToJSON(
  value?: PostAdminBrandPagesIdLabelParamAssetAltText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamAssetAltTextLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
