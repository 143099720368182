// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClick } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClick'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClickFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClickFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClickToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClick'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGrid } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGrid'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGridToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGrid'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions
 */
export interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions
   */
  mainItemGrid?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions
   */
  mainProductGroupGrid?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGrid}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions
   */
  mainCollectionGrid?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGrid
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClick}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions
   */
  bannerClick?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClick
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions {
  return ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
    main_product_group_grid:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsBannerClickToJSON(
        value.bannerClick
      ),
  }
}
