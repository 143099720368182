// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch } from './EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear } from './EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData
 */
export interface EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData
   */
  linear: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  return EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: EnableDisplayAdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
