import { useRouteMatch } from 'react-router-dom'

export interface PrototypeCampaignFormRoutesMatchParams {
  campaignId: string
  schemaMachineName: string
}

export interface PrototypeCampaignFormRoutePaths {
  BASE_PATH: string
  CREATE_PATH: string
  EDIT_PATH: string
  DASHBOARD_PATH: string
}

export function usePrototypeCampaignFormRoutePaths(): PrototypeCampaignFormRoutePaths {
  const { path } = useRouteMatch()

  return {
    BASE_PATH: path,
    CREATE_PATH: `${path}/campaign/:schemaMachineName/new`,
    EDIT_PATH: `${path}/campaign/:campaignId/edit`,
    DASHBOARD_PATH: `${path}/dashboard`,
  }
}

export function useCampaignId() {
  const { EDIT_PATH } = usePrototypeCampaignFormRoutePaths()
  const campaignId =
    useRouteMatch<PrototypeCampaignFormRoutesMatchParams>(EDIT_PATH)?.params.campaignId
  return campaignId
}

export function useSchemaMachineName() {
  const { CREATE_PATH } = usePrototypeCampaignFormRoutePaths()
  const schemaMachineName =
    useRouteMatch<PrototypeCampaignFormRoutesMatchParams>(CREATE_PATH)?.params.schemaMachineName
  return schemaMachineName
}
