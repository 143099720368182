// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostDisplayAdGroupsParamBlockHeroBannerDisplay,
  instanceOfPostDisplayAdGroupsParamBlockHeroBannerDisplay,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayFromJSON,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayFromJSONTyped,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayToJSON,
} from './PostDisplayAdGroupsParamBlockHeroBannerDisplay'
import {
  PostDisplayAdGroupsParamBlockItemGridDisplay,
  instanceOfPostDisplayAdGroupsParamBlockItemGridDisplay,
  PostDisplayAdGroupsParamBlockItemGridDisplayFromJSON,
  PostDisplayAdGroupsParamBlockItemGridDisplayFromJSONTyped,
  PostDisplayAdGroupsParamBlockItemGridDisplayToJSON,
} from './PostDisplayAdGroupsParamBlockItemGridDisplay'

/**
 * @type PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks
 *
 * @export
 */
export type PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & PostDisplayAdGroupsParamBlockHeroBannerDisplay)
  | ({ discriminator: 'block_item_grid.display.v1' } & PostDisplayAdGroupsParamBlockItemGridDisplay)

export function PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSON(
  json: any
): PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks {
  return PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSONTyped(json, false)
}

export function PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PostDisplayAdGroupsParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PostDisplayAdGroupsParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocksToJSON(
  value?: PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PostDisplayAdGroupsParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PostDisplayAdGroupsParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PostDisplayAdGroupsParamDisplayAdGroupBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
