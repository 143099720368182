// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogo } from './SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsHeroImage } from './SmartAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsImageMobile } from './SmartAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsImageWeb } from './SmartAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsThumbnail } from './SmartAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobile } from './SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWeb } from './SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile } from './SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb } from './SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb'
import type { SmartAdGroupResponseDataAttributesCreativeAssetsVideo } from './SmartAdGroupResponseDataAttributesCreativeAssetsVideo'
import {
  SmartAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON,
  SmartAdGroupResponseDataAttributesCreativeAssetsVideoFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeAssetsVideoToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface SmartAdGroupResponseDataAttributesCreativeAssets
 */
export interface SmartAdGroupResponseDataAttributesCreativeAssets {
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWeb}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  topImageWeb?: SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobile}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  topImageMobile?: SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsHeroImage}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  heroImage?: SmartAdGroupResponseDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogo}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  brandLogo?: SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsImageWeb}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  imageWeb?: SmartAdGroupResponseDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsImageMobile}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  imageMobile?: SmartAdGroupResponseDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsVideo}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  video?: SmartAdGroupResponseDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsThumbnail}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  thumbnail?: SmartAdGroupResponseDataAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageWeb?: SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile}
   * @memberof SmartAdGroupResponseDataAttributesCreativeAssets
   */
  uvcImageMobile?: SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the SmartAdGroupResponseDataAttributesCreativeAssets interface.
 */
export function instanceOfSmartAdGroupResponseDataAttributesCreativeAssets(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function SmartAdGroupResponseDataAttributesCreativeAssetsFromJSON(
  json: any
): SmartAdGroupResponseDataAttributesCreativeAssets {
  return SmartAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function SmartAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartAdGroupResponseDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON(json['top_image_web']),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON(json['hero_image']),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON(json['brand_logo']),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON(json['image_mobile']),
    video: !exists(json, 'video')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWebFromJSON(json['uvc_image_web']),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function SmartAdGroupResponseDataAttributesCreativeAssetsToJSON(
  value?: SmartAdGroupResponseDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: SmartAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    top_image_mobile: SmartAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    hero_image: SmartAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON(value.heroImage),
    brand_logo: SmartAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON(value.brandLogo),
    image_web: SmartAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON(value.imageWeb),
    image_mobile: SmartAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    video: SmartAdGroupResponseDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: SmartAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON(value.thumbnail),
    uvc_image_web: SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageWebToJSON(
      value.uvcImageWeb
    ),
    uvc_image_mobile: SmartAdGroupResponseDataAttributesCreativeAssetsUvcImageMobileToJSON(
      value.uvcImageMobile
    ),
  }
}
