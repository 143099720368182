// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActions } from './EnableDisplayAdGroupResponseDataAttributesCreativeActions'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActions'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeAssets } from './EnableDisplayAdGroupResponseDataAttributesCreativeAssets'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeAssets'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeProperties } from './EnableDisplayAdGroupResponseDataAttributesCreativeProperties'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativePropertiesToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeProperties'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesCreative
 */
export interface EnableDisplayAdGroupResponseDataAttributesCreative {
  /**
   * The id of the creative. Only needed for update
   * @type {string}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  id?: string
  /**
   * The type of placement the creative will be placed in
   * @type {string}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  type: EnableDisplayAdGroupResponseDataAttributesCreativeTypeEnum
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeProperties}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  properties?: EnableDisplayAdGroupResponseDataAttributesCreativeProperties
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActions}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  actions?: EnableDisplayAdGroupResponseDataAttributesCreativeActions
  /**
   * Product IDs that are used for view through attribution
   * @type {Array<string>}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  trackedProductIds?: Array<string>
  /**
   * Read only field indicating whether creative is using new view through attribution
   * @type {boolean}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  useNewAttribution?: boolean
  /**
   * Product Group IDs that are used for view through attribution based on product group
   * @type {Array<string>}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  trackedProductGroupIds?: Array<string>
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeAssets}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreative
   */
  assets: EnableDisplayAdGroupResponseDataAttributesCreativeAssets
}

/**
 * @export
 */
export const EnableDisplayAdGroupResponseDataAttributesCreativeTypeEnum = {
  CaperImageBannerV1: 'caper_image_banner.v1',
  CaperSpotlightAdPrototypeV1: 'caper_spotlight_ad.prototype.v1',
  EmailV1: 'email.v1',
  ImageBannerV2: 'image_banner.v2',
  ImageBannerV3: 'image_banner.v3',
  MnmVideoPrototypeV1: 'mnm.video.prototype.v1',
  OccasionV1: 'occasion.v1',
  PromotedAisleV1: 'promoted_aisle.v1',
  PromotedAisleV2: 'promoted_aisle.v2',
  PromotedAisleV3: 'promoted_aisle.v3',
  PromotedAisleV4: 'promoted_aisle.v4',
  SearchBannerV1: 'search_banner.v1',
  ShoppableVideoV1: 'shoppable_video.v1',
  ShoppableVideoV2: 'shoppable_video.v2',
  ShoppableVideoV3: 'shoppable_video.v3',
  SmartV1: 'smart.v1',
  SponsoredRecipeV1: 'sponsored_recipe.v1',
  SponsoredRecipeVideoV1: 'sponsored_recipe_video.v1',
  UvcBannerV1: 'uvc_banner.v1',
} as const
export type EnableDisplayAdGroupResponseDataAttributesCreativeTypeEnum =
  typeof EnableDisplayAdGroupResponseDataAttributesCreativeTypeEnum[keyof typeof EnableDisplayAdGroupResponseDataAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesCreative interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesCreative(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesCreative {
  return EnableDisplayAdGroupResponseDataAttributesCreativeFromJSONTyped(json, false)
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    properties: !exists(json, 'properties')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSON(json['properties']),
    actions: !exists(json, 'actions')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON(json['actions']),
    trackedProductIds: !exists(json, 'tracked_product_ids')
      ? undefined
      : json['tracked_product_ids'],
    useNewAttribution: !exists(json, 'use_new_attribution')
      ? undefined
      : json['use_new_attribution'],
    trackedProductGroupIds: !exists(json, 'tracked_product_group_ids')
      ? undefined
      : json['tracked_product_group_ids'],
    assets: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON(json['assets']),
  }
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    properties: EnableDisplayAdGroupResponseDataAttributesCreativePropertiesToJSON(
      value.properties
    ),
    actions: EnableDisplayAdGroupResponseDataAttributesCreativeActionsToJSON(value.actions),
    tracked_product_ids: value.trackedProductIds,
    use_new_attribution: value.useNewAttribution,
    tracked_product_group_ids: value.trackedProductGroupIds,
    assets: EnableDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON(value.assets),
  }
}
