// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamDataAttributesEditorialStatesAppeals } from './GetBrandPagesParamDataAttributesEditorialStatesAppeals'
import {
  GetBrandPagesParamDataAttributesEditorialStatesAppealsFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesAppealsToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesAppeals'
import type { GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot } from './GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot'
import type { GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot } from './GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot'
import {
  GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot'
import type { GetBrandPagesParamDataAttributesEditorialStatesReviewState } from './GetBrandPagesParamDataAttributesEditorialStatesReviewState'
import {
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface GetBrandPagesParamDataAttributesEditorialStates
 */
export interface GetBrandPagesParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  type: GetBrandPagesParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesReviewState}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  reviewState?: GetBrandPagesParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  entitySnapshot: GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  decisionSnapshot: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesAppeals}
   * @memberof GetBrandPagesParamDataAttributesEditorialStates
   */
  appeals: GetBrandPagesParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetBrandPagesParamDataAttributesEditorialStatesTypeEnum =
  typeof GetBrandPagesParamDataAttributesEditorialStatesTypeEnum[keyof typeof GetBrandPagesParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the GetBrandPagesParamDataAttributesEditorialStates interface.
 */
export function instanceOfGetBrandPagesParamDataAttributesEditorialStates(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function GetBrandPagesParamDataAttributesEditorialStatesFromJSON(
  json: any
): GetBrandPagesParamDataAttributesEditorialStates {
  return GetBrandPagesParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSON(json['review_state']),
    entitySnapshot: GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
      json['decision_snapshot']
    ),
    appeals: GetBrandPagesParamDataAttributesEditorialStatesAppealsFromJSON(json['appeals']),
  }
}

export function GetBrandPagesParamDataAttributesEditorialStatesToJSON(
  value?: GetBrandPagesParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: GetBrandPagesParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: GetBrandPagesParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
      value.decisionSnapshot
    ),
    appeals: GetBrandPagesParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
