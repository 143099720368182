import { setIn } from 'formik'
import { Asserts, BaseSchema, setLocale, ValidationError } from 'yup'
import useIntl, { TypedIntlShape, TypedValueShape } from 'common/useIntl'
import { MessageIdType } from 'locales/types'

initYupLocale()

export const EMAIL_PATTERN = /@[a-z0-9._%+-]+[.][a-z]+$/i
export const TLD_PATTERN = /[.][A-Za-z]+$/

/**
 * Returns a validator for the given Yup Schema
 *
 * Notes:
 * - If you're using a yup validation not defined in {@link initYupLocale}, update it to make translations work
 * - field validations should have a corresponding translation in /src/client/locales file
 *     If you use `number().positive()` for budget field, you should have `${translationBaseKey}.positive.budget` in locales file
 *     If you use `array().min(1)` for keywords field, you should have `${translationBaseKey}.min.keywords` in locales file
 */
export const useSyncValidator = (schema: BaseSchema, translationBaseKey: string) => {
  const { formatMessage } = useIntl()
  return (
    values: Asserts<typeof schema>,
    skipNonEmptyValidation = false,
    excludeFromSkipValidationFieldNames: string[] = []
  ) => {
    try {
      schema.validateSync(values, { abortEarly: false })
    } catch (err) {
      if (err instanceof ValidationError) {
        return parseErrors(
          err,
          formatMessage,
          translationBaseKey,
          skipNonEmptyValidation,
          excludeFromSkipValidationFieldNames
        )
      }
      throw err
    }
    return {}
  }
}

const recognizedIntlOptionsParams = ['min', 'max']

function handleIntlOptions({ params }: ValidationError) {
  const options: TypedValueShape = {}

  if (!params) return options

  Object.keys(params).forEach(param => {
    if (recognizedIntlOptionsParams.includes(param)) {
      options[param] = `${params[param]}`
    }
  })

  return options
}

// eslint-disable-next-line max-params
function parseErrors(
  result: ValidationError,
  formatMessage: TypedIntlShape['formatMessage'],
  translationBaseKey: string,
  skipNonEmptyValidation = false,
  excludeFromSkipValidationFieldNames: string[] = []
) {
  return result.inner.reduce((acc: { [key: string]: string }, cur: ValidationError) => {
    let res = acc
    const isEmpty =
      !cur.value || (typeof cur.value === 'object' && Object.keys(cur.value).length === 0)
    if (
      skipNonEmptyValidation &&
      isEmpty &&
      !excludeFromSkipValidationFieldNames.includes(cur.path as string)
    ) {
      return res
    }
    if (cur.path) {
      // format array validation error message key to remove [0]
      const path = cur.path.replace(/\[.*\]/i, '')
      const options = handleIntlOptions(cur)
      const translatedError = formatMessage(
        {
          id: `${translationBaseKey}.${cur.message}.${path}` as MessageIdType,
        },
        options
      )
      res = setIn(acc, cur.path, translatedError)
    }
    return res
  }, {})
}

function initYupLocale() {
  const toYupLocale = (validations: string[]) =>
    validations.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {})

  setLocale({
    mixed: toYupLocale(['required', 'oneOf']),
    string: toYupLocale(['required', 'length', 'min', 'max', 'matches', 'email', 'uuid']),
    number: toYupLocale(['min', 'max', 'positive', 'negative']),
    array: toYupLocale(['min', 'max']),
    date: toYupLocale(['required', 'min', 'max']),
  })
}

// to make sure Locale is set before using yup exports
export {
  array,
  Asserts as SchemaType,
  number,
  object,
  string,
  mixed,
  boolean,
  date,
  ref,
} from 'yup'
