import { ReactNode, useMemo } from 'react'
// This is the only place we use the untyped useIntl to wrap it to a typed version
// eslint-disable-next-line no-restricted-imports
import { IntlShape, MessageDescriptor, useIntl as untypedUseIntl } from 'react-intl'
import {
  GenericMessageDescriptor,
  GenericExtendedMessageDescriptor,
  MessageIdType,
} from 'locales/types'

export type TypedMessageDescriptor = Omit<MessageDescriptor, 'id'> & {
  id: MessageIdType
}
export type TypedValueShape = Record<string, string | number | boolean | null | undefined | Date>
type ExtendedTypedValueShape = Record<
  string,
  | string
  | number
  | boolean
  | null
  | undefined
  | Date
  | React.ReactNode
  | ((chunks: ReactNode[]) => JSX.Element)
  | ((text: string) => string | ReactNode)
>

export type TypedIntlShape = Omit<IntlShape, 'formatMessage'> & {
  formatMessage(descriptor: TypedMessageDescriptor, values?: TypedValueShape): string
  formatMessage(
    descriptor: TypedMessageDescriptor,
    values?: ExtendedTypedValueShape
  ): string | React.ReactNode
}

export type GenericTypedIntlShape = TypedIntlShape & {
  genericFormatMessage(descriptor: GenericMessageDescriptor): string
  genericFormatMessage(descriptor: GenericExtendedMessageDescriptor): string | React.ReactNode
}

const buildGenericFormatMessage =
  (intl: IntlShape) =>
  (descriptor: GenericMessageDescriptor): string => {
    if (typeof descriptor === 'string') {
      return intl.formatMessage({ id: descriptor as MessageIdType })
    }
    if ('id' in descriptor) {
      const { id, values } = descriptor
      return intl.formatMessage({ id }, values)
    }
    return descriptor.message
  }

const useIntl = (): GenericTypedIntlShape => {
  const intlFormatters = untypedUseIntl() as TypedIntlShape
  return useMemo(() => {
    return { ...intlFormatters, genericFormatMessage: buildGenericFormatMessage(intlFormatters) }
  }, [intlFormatters])
}

export default useIntl
