import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { Tooltip, TooltipReference, useTooltipState } from '../../tooltips'

export interface TooltipLinkProps extends LinkProps {
  tooltip: ReactNode
}

export function TooltipLink({ tooltip, children, ...props }: TooltipLinkProps) {
  const state = useTooltipState()

  return (
    <>
      <TooltipReference as={Link} {...state} {...props}>
        {children}
      </TooltipReference>
      <Tooltip {...state}>{tooltip}</Tooltip>
    </>
  )
}
