import { boolean } from 'yup'
import { TypedSchema } from 'yup/lib/util/types'
import { ClientError } from 'common/utils'
import { string, object, SchemaType, useSyncValidator, mixed } from 'common/validation'
import { GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum } from 'service/openapi/__codegen__/models/GetUsersSettingsMultiFactorAuthParamDataMfaFactors'
import { MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum } from 'service/openapi/__codegen__/models/MultiFactorAuthCreateResponseDataAttributes'
import { PostAuthMultiFactorParamDataAttributesMfaFactorsMfaTypeEnum } from 'service/openapi/__codegen__/models/PostAuthMultiFactorParamDataAttributesMfaFactors'
import { PostAuthParamDataMfaFactorsMfaTypeEnum } from 'service/openapi/__codegen__/models/PostAuthParamDataMfaFactors'

export type MultiFactorAuthType =
  | MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum
  | GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum
  | PostAuthParamDataMfaFactorsMfaTypeEnum

export const MultiFactorAuthType = {
  ...MultiFactorAuthCreateResponseDataAttributesMfaTypeEnum,
  ...GetUsersSettingsMultiFactorAuthParamDataMfaFactorsMfaTypeEnum,
  ...PostAuthMultiFactorParamDataAttributesMfaFactorsMfaTypeEnum,
  ...PostAuthParamDataMfaFactorsMfaTypeEnum,
}

// eslint-disable-next-line no-restricted-syntax
export enum MultiFactorAuthAction {
  Action = 'action',
  Login = 'login',
  Setup = 'setup',
}

// eslint-disable-next-line no-restricted-syntax
export enum MultiFactorAuthError {
  InvalidAttempt = 'invalidAttempt',
  ServerError = 'serverError',
}

export interface MfaFactor {
  mfaType: MultiFactorAuthType
  provisioningUrl?: string
  backupCodes?: string[]
}

export interface MultiFactorAuthAttempt {
  mfaType: MultiFactorAuthType
  otp: string
  rememberDevice?: boolean
}

export interface MfaVerificationProps {
  action: MultiFactorAuthAction
  mfaFactor: MfaFactor
  onSubmit: (multiFactorAuthAttempt: MultiFactorAuthAttempt) => void
  onBack: () => void
  onSuccess?: () => void
  onError?: (error?: ClientError) => void
  onSendOtp?: (mfaType: MultiFactorAuthType) => void
}

const mfaVerificationFormSchemaObject = (): Record<string, SchemaType<TypedSchema>> => ({
  otp: string().required(),
  rememberDevice: boolean(),
})

const mfaValidationFormSchema = () => object(mfaVerificationFormSchemaObject())

export const getInitialValues = (): MfaVerificationFormValues => {
  return {
    otp: '',
    rememberDevice: false,
  }
}

export type MfaVerificationFormValues = SchemaType<ReturnType<typeof mfaValidationFormSchema>>

export const useMfaVerificationValidator = () => {
  return useSyncValidator(mfaValidationFormSchema(), 'pages.multiFactorAuth.validation')
}

const mfaSelectFormSchemaObject: Record<string, SchemaType<TypedSchema>> = {
  mfaType: mixed<MultiFactorAuthType>()
    .required()
    .oneOf(Object.values(MultiFactorAuthType))
    .default(MultiFactorAuthType.Email),
}

export const mfaSelectFormSchema = object(mfaSelectFormSchemaObject)

export const getMfaSelectInitialValues = () => {
  return {
    mfaType: MultiFactorAuthType.Email,
  }
}

export type MfaSelectFormValues = SchemaType<typeof mfaSelectFormSchema>

export const useMfaSelectValidator = () => {
  return useSyncValidator(mfaSelectFormSchema, 'pages.multiFactorAuth.select.validation')
}
