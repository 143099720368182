// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy
 */
export interface GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy {
  /**
   * Targeted keywords
   * @type {Array<string>}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy
   */
  keywords?: Array<string>
  /**
   * Specify how broadly you would like the keywords to be matched against search terms
   * @type {string}
   * @memberof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy
   */
  keywordMatchType?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyKeywordMatchTypeEnum
}

/**
 * @export
 */
export const GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyKeywordMatchTypeEnum =
  {
    Exact: 'exact',
    Narrow: 'narrow',
    Broad: 'broad',
  } as const
export type GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyKeywordMatchTypeEnum =
  typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyKeywordMatchTypeEnum[keyof typeof GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyKeywordMatchTypeEnum]

/**
 * Check if a given object implements the GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy interface.
 */
export function instanceOfGetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSON(
  json: any
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy {
  return GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSONTyped(
    json,
    false
  )
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keywords: !exists(json, 'keywords') ? undefined : json['keywords'],
    keywordMatchType: !exists(json, 'keyword_match_type') ? undefined : json['keyword_match_type'],
  }
}

export function GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategyToJSON(
  value?: GetPrototypeCampaignsParamDataAttributesAdGroupAttributesTargetingStrategy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keywords: value.keywords,
    keyword_match_type: value.keywordMatchType,
  }
}
