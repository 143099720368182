import { getSuggestedKeywordBidValue, isOverKeywordBidRecTreshold } from 'common/bidThresholds'
import useFlipper from 'common/useFlipper'
import FormattedDollar from 'components/FormattedDollar'
import FormattedMessage from 'components/FormattedMessage'
import { useSpMinBid } from 'hooks/useSpBidValues'
import { rumInvalidBidRec } from '../common/dataDog'
import { FLIPPER_ENABLE_RECOMMENDATIONS_THRESHOLD } from '../pages/constants'

interface SuggestedBidTextProps {
  suggestedBid: number
}

const SuggestedBidFormattedDollar = ({ suggestedBid }: SuggestedBidTextProps) => {
  const minBid = useSpMinBid()
  const isBidRecommendationsThresholdEnabled = useFlipper(FLIPPER_ENABLE_RECOMMENDATIONS_THRESHOLD)

  if (Number.isNaN(suggestedBid) || suggestedBid < minBid) {
    rumInvalidBidRec(suggestedBid)
    return <FormattedMessage id="adGroup.keywordTable.suggestedBid.unknown.row" />
  }

  return (
    <>
      {isOverKeywordBidRecTreshold(suggestedBid, isBidRecommendationsThresholdEnabled) && '> '}
      <FormattedDollar>
        {getSuggestedKeywordBidValue(suggestedBid, isBidRecommendationsThresholdEnabled)}
      </FormattedDollar>
    </>
  )
}

export default SuggestedBidFormattedDollar
