// eslint-disable-next-line no-restricted-syntax
export enum AccountFeatures {
  AUTO_BIDDING = 'auto_bidding',
  BID_LANDSCAPE = 'bid_landscape',
  BRAND_PAGES = 'brand_pages',
  BRAND_PROFILES = 'brand_profiles',
  CAMPAIGN_DIAGNOSIS = 'campaign_diagnosis',
  CLAIM_YOUR_PRODUCT = 'claim_your_product',
  DATA_EXPORT_REPORTS = 'data_export_reports',
  DEFAULT_BID_ACTIONS = 'default_bid_actions',
  DISPLAY_CAMPAIGNS = 'display_campaigns',
  ICIP_REPORTS = 'icip_reports',
  IMPRESSION_ESTIMATOR = 'impression_estimator',
  INSPIRATION_CAMPAIGNS = 'inspiration_campaigns',
  INTEGRITY_AUTO_APPROVAL = 'integrity_auto_approval',
  INTERACTION_USER_TARGETING = 'interaction_user_targeting',
  KEYWORD_RECOMMENDATIONS = 'keyword_recommendations',
  MULTI_PURPOSE_PRODUCT_SEARCH = 'multi_purpose_product_search',
  NEW_TO_INSTACART_USER_TARGETING = 'new_to_instacart_user_targeting',
  PRODUCT_LIBRARY = 'product_library',
  PROMOTIONS = 'promotions',
  PROMO_CODE_PROMOTIONS = 'promo_code_promotions',
  RECIPES = 'recipes',
  RECIPE_LANDING_PAGES = 'recipe_landing_pages',
  SHOPPABLE_DISPLAY_CAMPAIGNS = 'shoppable_display_campaigns',
  SMART_CAMPAIGNS = 'smart_campaigns',
  SODS = 'sods',
  SUGGESTED_KEYWORDS = 'suggested_keywords',
  TASK_PLATFORM = 'task_platform',
  VIDEO_CAMPAIGNS = 'video_campaigns',
  BROAD_MATCH_KEYWORD_TARGETING = 'broad_match_keyword_targeting',
}
