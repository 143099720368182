import { formatDollar } from 'common/utils'

interface FormattedDollarProps {
  children?: number
  useDashForZero?: boolean
}

const FormattedDollar: React.FunctionComponent<React.PropsWithChildren<FormattedDollarProps>> = ({
  children,
  useDashForZero,
}) => {
  if (!children) {
    return <>{useDashForZero ? '$--.--' : '$0.00'}</>
  }
  return <>{formatDollar(children)}</>
}

export default FormattedDollar
