import { spacing, Text, useTheme } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import useIntl from 'common/useIntl'
import { getImageUrl } from '../utils/helpers'
import breakPoints from './breakPoints'
import Feature from './Feature'

const useStyles = (): { [key: string]: CSSProperties } => {
  const theme = useTheme()
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    title: {
      ...{ padding: spacing.s48 },
      color: theme.colors.systemGrayscale70,
      [breakPoints.mobile]: {
        fontSize: '22px',
        ...{ padding: spacing.s24 },
        ...{ paddingBottom: spacing.s16 },
      },
    },
    row: {
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '1022px',
      [breakPoints.mobile]: {
        flexDirection: 'column',
        ...{ paddingLeft: spacing.s24, paddingRight: spacing.s24 },
      },
    },
    imgWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    img: {
      height: '130px',
      objectFit: 'cover',
    },
  }
}

interface ReasonProps {
  imgSrc: string
  lead: string
  children: string
}

const Reason = ({ imgSrc, lead, children }: ReasonProps) => {
  const styles = useStyles()
  return (
    <Feature
      lead={lead}
      renderImage={() => (
        <div style={styles.imgWrapper}>
          <img src={imgSrc} style={styles.img} alt={lead} />
        </div>
      )}
    >
      {children}
    </Feature>
  )
}

const PartnerWithUs = () => {
  const intl = useIntl()
  const styles = useStyles()
  return (
    <div style={styles.wrapper}>
      <Text typography="bodyLarge2" style={styles.title} as="h2" color="systemGrayscale20">
        {intl.formatMessage({ id: 'landing.partnerWithUs.header' })}
      </Text>
      <div style={styles.row}>
        <Reason
          imgSrc={getImageUrl('/images/landing/attract_customers.png')}
          lead={intl.formatMessage({ id: 'landing.partnerWithUs.lead1' })}
        >
          {intl.formatMessage({ id: 'landing.partnerWithUs.reason1' })}
        </Reason>
        <Reason
          imgSrc={getImageUrl('/images/landing/get_results.png')}
          lead={intl.formatMessage({ id: 'landing.partnerWithUs.lead2' })}
        >
          {intl.formatMessage({ id: 'landing.partnerWithUs.reason2' })}
        </Reason>
        <Reason
          imgSrc={getImageUrl('/images/landing/unmatched_scale.png')}
          lead={intl.formatMessage({ id: 'landing.partnerWithUs.lead3' })}
        >
          {intl.formatMessage({ id: 'landing.partnerWithUs.reason3' })}
        </Reason>
      </div>
    </div>
  )
}

export default Radium(PartnerWithUs)
