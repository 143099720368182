// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostVideoCampaignsIdSubmitParamAssetFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamAssetFieldDecisions,
  PostVideoCampaignsIdSubmitParamAssetFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamAssetFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamAssetFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamAssetFieldDecisions'
import {
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisions,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisions'
import {
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamBrandPageFieldDecisions,
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageFieldDecisions'
import {
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamCreativeFieldDecisions,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamCreativeFieldDecisions'
import {
  PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisions,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisions'
import {
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions,
  instanceOfPostVideoCampaignsIdSubmitParamProductAssetFieldDecisions,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSON,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsToJSON,
} from './PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions'

/**
 * @type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & PostVideoCampaignsIdSubmitParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & PostVideoCampaignsIdSubmitParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & PostVideoCampaignsIdSubmitParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & PostVideoCampaignsIdSubmitParamProductAssetFieldDecisions)

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PostVideoCampaignsIdSubmitParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PostVideoCampaignsIdSubmitParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PostVideoCampaignsIdSubmitParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PostVideoCampaignsIdSubmitParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PostVideoCampaignsIdSubmitParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PostVideoCampaignsIdSubmitParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
