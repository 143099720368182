// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick } from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick } from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import type { SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick } from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface SmartAdGroupResponseDataAttributesCreativeActionsBannerClick
 */
export interface SmartAdGroupResponseDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof SmartAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof SmartAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  urlClick?: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof SmartAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the SmartAdGroupResponseDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfSmartAdGroupResponseDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): SmartAdGroupResponseDataAttributesCreativeActionsBannerClick {
  return SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(json, false)
}

export function SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartAdGroupResponseDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function SmartAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
  value?: SmartAdGroupResponseDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      SmartAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
      value.recipeClick
    ),
  }
}
