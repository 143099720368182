// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributes } from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributes'
import {
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributesFromJSON,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributesFromJSONTyped,
  GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributesToJSON,
} from './GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributes'

/**
 *
 * @export
 * @interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon
 */
export interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon {
  /**
   * Alcohol State Permission Id
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon
   */
  type: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponTypeEnum
  /**
   *
   * @type {GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributes}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon
   */
  attributes: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributes
}

/**
 * @export
 */
export const GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponTypeEnum = {
  AlcoholStatePermission: 'alcohol_state_permission',
} as const
export type GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponTypeEnum =
  typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponTypeEnum[keyof typeof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponTypeEnum]

/**
 * Check if a given object implements the GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon interface.
 */
export function instanceOfGetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSON(
  json: any
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon {
  return GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSONTyped(json, false)
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributesFromJSON(
      json['attributes']
    ),
  }
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponToJSON(
  value?: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCoupon | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsCouponAttributesToJSON(
      value.attributes
    ),
  }
}
