// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetVideoAdGroupsParamBrandPageBlockSnapshotContent
 */
export interface GetVideoAdGroupsParamBrandPageBlockSnapshotContent {
  /**
   * Short title/blurb for the block
   * @type {string}
   * @memberof GetVideoAdGroupsParamBrandPageBlockSnapshotContent
   */
  title?: string
  /**
   * Paragraph(s) of text below the title
   * @type {string}
   * @memberof GetVideoAdGroupsParamBrandPageBlockSnapshotContent
   */
  text?: string
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamBrandPageBlockSnapshotContent interface.
 */
export function instanceOfGetVideoAdGroupsParamBrandPageBlockSnapshotContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamBrandPageBlockSnapshotContentFromJSON(
  json: any
): GetVideoAdGroupsParamBrandPageBlockSnapshotContent {
  return GetVideoAdGroupsParamBrandPageBlockSnapshotContentFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamBrandPageBlockSnapshotContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamBrandPageBlockSnapshotContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    text: !exists(json, 'text') ? undefined : json['text'],
  }
}

export function GetVideoAdGroupsParamBrandPageBlockSnapshotContentToJSON(
  value?: GetVideoAdGroupsParamBrandPageBlockSnapshotContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    text: value.text,
  }
}
