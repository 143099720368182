// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktop } from './PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktop'
import {
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktop'
import type { PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobile } from './PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobile'
import {
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets
 */
export interface PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets
   */
  desktop: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets
   */
  mobile: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfPostDisplayAdGroupsParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets {
  return PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktopFromJSON(json['desktop']),
    mobile: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobileFromJSON(json['mobile']),
  }
}

export function PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsToJSON(
  value?: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsDesktopToJSON(value.desktop),
    mobile: PostDisplayAdGroupsParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
