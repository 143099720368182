// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerKeywordsInputDateRange } from './ApiReportsControllerKeywordsInputDateRange'
import {
  ApiReportsControllerKeywordsInputDateRangeFromJSON,
  ApiReportsControllerKeywordsInputDateRangeFromJSONTyped,
  ApiReportsControllerKeywordsInputDateRangeToJSON,
} from './ApiReportsControllerKeywordsInputDateRange'
import type { ApiReportsControllerKeywordsInputFilters } from './ApiReportsControllerKeywordsInputFilters'
import {
  ApiReportsControllerKeywordsInputFiltersFromJSON,
  ApiReportsControllerKeywordsInputFiltersFromJSONTyped,
  ApiReportsControllerKeywordsInputFiltersToJSON,
} from './ApiReportsControllerKeywordsInputFilters'

/**
 *
 * @export
 * @interface ApiReportsControllerKeywordsInput
 */
export interface ApiReportsControllerKeywordsInput {
  /**
   * Send this parameter with the ID of a specific ad group to narrow the data returned on your report to just data from that specific ad group.
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  adGroupId?: string
  /**
   * Send this parameter with the ID of a specific campaign to narrow the data returned on your report to just data from that specific campaign. The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  campaignId?: string
  /**
   *
   * @type {ApiReportsControllerKeywordsInputDateRange}
   * @memberof ApiReportsControllerKeywordsInput
   */
  dateRange?: ApiReportsControllerKeywordsInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  segment?: ApiReportsControllerKeywordsInputSegmentEnum
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  name?: string
  /**
   * Sort keywords by column
   * @type {string}
   * @memberof ApiReportsControllerKeywordsInput
   */
  sortBy?: ApiReportsControllerKeywordsInputSortByEnum
  /**
   * Maximum number of rows
   * @type {number}
   * @memberof ApiReportsControllerKeywordsInput
   */
  limit?: number
  /**
   * Fields to be excluded from the report
   * @type {Array<string>}
   * @memberof ApiReportsControllerKeywordsInput
   */
  excludeFields?: Array<ApiReportsControllerKeywordsInputExcludeFieldsEnum>
  /**
   *
   * @type {ApiReportsControllerKeywordsInputFilters}
   * @memberof ApiReportsControllerKeywordsInput
   */
  filters?: ApiReportsControllerKeywordsInputFilters
}

/**
 * @export
 */
export const ApiReportsControllerKeywordsInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerKeywordsInputSegmentEnum =
  typeof ApiReportsControllerKeywordsInputSegmentEnum[keyof typeof ApiReportsControllerKeywordsInputSegmentEnum]

/**
 * @export
 */
export const ApiReportsControllerKeywordsInputSortByEnum = {
  KeywordAsc: 'keyword_asc',
  KeywordDesc: 'keyword_desc',
  EnabledAsc: 'enabled_asc',
  EnabledDesc: 'enabled_desc',
  MatchingTypeAsc: 'matching_type_asc',
  MatchingTypeDesc: 'matching_type_desc',
  CpcBidAsc: 'cpc_bid_asc',
  CpcBidDesc: 'cpc_bid_desc',
  BidTypeAsc: 'bid_type_asc',
  BidTypeDesc: 'bid_type_desc',
  SpendAsc: 'spend_asc',
  SpendDesc: 'spend_desc',
  AttributedSalesAsc: 'attributed_sales_asc',
  AttributedSalesDesc: 'attributed_sales_desc',
  AttributedQuantitiesAsc: 'attributed_quantities_asc',
  AttributedQuantitiesDesc: 'attributed_quantities_desc',
  RoasAsc: 'roas_asc',
  RoasDesc: 'roas_desc',
  ImpressionsAsc: 'impressions_asc',
  ImpressionsDesc: 'impressions_desc',
  CtrAsc: 'ctr_asc',
  CtrDesc: 'ctr_desc',
  ClicksAsc: 'clicks_asc',
  ClicksDesc: 'clicks_desc',
  CpcAsc: 'cpc_asc',
  CpcDesc: 'cpc_desc',
} as const
export type ApiReportsControllerKeywordsInputSortByEnum =
  typeof ApiReportsControllerKeywordsInputSortByEnum[keyof typeof ApiReportsControllerKeywordsInputSortByEnum]

/**
 * @export
 */
export const ApiReportsControllerKeywordsInputExcludeFieldsEnum = {
  CampaignName: 'campaign_name',
  CampaignUuid: 'campaign_uuid',
  AdGroupName: 'ad_group_name',
  AdGroupUuid: 'ad_group_uuid',
  Keyword: 'keyword',
  Source: 'source',
  BidType: 'bid_type',
  BidStrength: 'bid_strength',
  CurrentCpcBid: 'current_cpc_bid',
  SuggestedBid: 'suggested_bid',
  Spend: 'spend',
  AttributedSales: 'attributed_sales',
  AttributedQuantities: 'attributed_quantities',
  Roas: 'roas',
  Impressions: 'impressions',
  Clicks: 'clicks',
  Ctr: 'ctr',
  AverageCpc: 'average_cpc',
  NtbAttributedSales: 'ntb_attributed_sales',
  PercentNtbAttributedSales: 'percent_ntb_attributed_sales',
  KeywordUuid: 'keyword_uuid',
} as const
export type ApiReportsControllerKeywordsInputExcludeFieldsEnum =
  typeof ApiReportsControllerKeywordsInputExcludeFieldsEnum[keyof typeof ApiReportsControllerKeywordsInputExcludeFieldsEnum]

/**
 * Check if a given object implements the ApiReportsControllerKeywordsInput interface.
 */
export function instanceOfApiReportsControllerKeywordsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerKeywordsInputFromJSON(
  json: any
): ApiReportsControllerKeywordsInput {
  return ApiReportsControllerKeywordsInputFromJSONTyped(json, false)
}

export function ApiReportsControllerKeywordsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerKeywordsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroupId: !exists(json, 'ad_group_id') ? undefined : json['ad_group_id'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerKeywordsInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sortBy: !exists(json, 'sort_by') ? undefined : json['sort_by'],
    limit: !exists(json, 'limit') ? undefined : json['limit'],
    excludeFields: !exists(json, 'exclude_fields') ? undefined : json['exclude_fields'],
    filters: !exists(json, 'filters')
      ? undefined
      : ApiReportsControllerKeywordsInputFiltersFromJSON(json['filters']),
  }
}

export function ApiReportsControllerKeywordsInputToJSON(
  value?: ApiReportsControllerKeywordsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group_id: value.adGroupId,
    campaign_id: value.campaignId,
    date_range: ApiReportsControllerKeywordsInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    attribution_model: value.attributionModel,
    name: value.name,
    sort_by: value.sortBy,
    limit: value.limit,
    exclude_fields: value.excludeFields,
    filters: ApiReportsControllerKeywordsInputFiltersToJSON(value.filters),
  }
}
