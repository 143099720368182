// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent } from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import {
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent'
import type { PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues } from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions
 */
export interface PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [key: string]: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  products?: {
    [key: string]: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValues
  }
  /**
   *
   * @type {PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions
   */
  content?: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions {
  return PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON(
  value?: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(value.content),
  }
}
