// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentText } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent
 */
export interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  title?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  text?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  return PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON(
          json['title']
        ),
    text: !exists(json, 'text')
      ? undefined
      : PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
