// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AccountsSearchResponseData } from './AccountsSearchResponseData'
import {
  AccountsSearchResponseDataFromJSON,
  AccountsSearchResponseDataFromJSONTyped,
  AccountsSearchResponseDataToJSON,
} from './AccountsSearchResponseData'
import type { AccountsSearchResponseMeta } from './AccountsSearchResponseMeta'
import {
  AccountsSearchResponseMetaFromJSON,
  AccountsSearchResponseMetaFromJSONTyped,
  AccountsSearchResponseMetaToJSON,
} from './AccountsSearchResponseMeta'

/**
 *
 * @export
 * @interface AccountsSearchResponse
 */
export interface AccountsSearchResponse {
  /**
   *
   * @type {Array<AccountsSearchResponseData>}
   * @memberof AccountsSearchResponse
   */
  data: Array<AccountsSearchResponseData>
  /**
   *
   * @type {AccountsSearchResponseMeta}
   * @memberof AccountsSearchResponse
   */
  meta: AccountsSearchResponseMeta
}

/**
 * Check if a given object implements the AccountsSearchResponse interface.
 */
export function instanceOfAccountsSearchResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AccountsSearchResponseFromJSON(json: any): AccountsSearchResponse {
  return AccountsSearchResponseFromJSONTyped(json, false)
}

export function AccountsSearchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AccountsSearchResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(AccountsSearchResponseDataFromJSON),
    meta: AccountsSearchResponseMetaFromJSON(json['meta']),
  }
}

export function AccountsSearchResponseToJSON(value?: AccountsSearchResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(AccountsSearchResponseDataToJSON),
    meta: AccountsSearchResponseMetaToJSON(value.meta),
  }
}
