// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { IndexReportsResponseDataAttributes } from './IndexReportsResponseDataAttributes'
import {
  IndexReportsResponseDataAttributesFromJSON,
  IndexReportsResponseDataAttributesFromJSONTyped,
  IndexReportsResponseDataAttributesToJSON,
} from './IndexReportsResponseDataAttributes'

/**
 *
 * @export
 * @interface IndexReportsResponseData
 */
export interface IndexReportsResponseData {
  /**
   * Report Id
   * @type {string}
   * @memberof IndexReportsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof IndexReportsResponseData
   */
  type: IndexReportsResponseDataTypeEnum
  /**
   *
   * @type {IndexReportsResponseDataAttributes}
   * @memberof IndexReportsResponseData
   */
  attributes: IndexReportsResponseDataAttributes
}

/**
 * @export
 */
export const IndexReportsResponseDataTypeEnum = {
  Report: 'report',
} as const
export type IndexReportsResponseDataTypeEnum =
  typeof IndexReportsResponseDataTypeEnum[keyof typeof IndexReportsResponseDataTypeEnum]

/**
 * Check if a given object implements the IndexReportsResponseData interface.
 */
export function instanceOfIndexReportsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function IndexReportsResponseDataFromJSON(json: any): IndexReportsResponseData {
  return IndexReportsResponseDataFromJSONTyped(json, false)
}

export function IndexReportsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexReportsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: IndexReportsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function IndexReportsResponseDataToJSON(value?: IndexReportsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: IndexReportsResponseDataAttributesToJSON(value.attributes),
  }
}
