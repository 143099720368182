// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApplicableLabelsAdminBrandPageResponseDataAttributesAsset } from './ApplicableLabelsAdminBrandPageResponseDataAttributesAsset'
import {
  ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSON,
  ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseDataAttributesAssetToJSON,
} from './ApplicableLabelsAdminBrandPageResponseDataAttributesAsset'
import type { ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage } from './ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage'
import {
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSON,
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageToJSON,
} from './ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage'
import type { ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock } from './ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock'
import {
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSON,
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSONTyped,
  ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockToJSON,
} from './ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponseDataAttributes
 */
export interface ApplicableLabelsAdminBrandPageResponseDataAttributes {
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributes
   */
  brandPage: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPage
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributes
   */
  brandPageBlock: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock
  /**
   *
   * @type {ApplicableLabelsAdminBrandPageResponseDataAttributesAsset}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributes
   */
  asset: ApplicableLabelsAdminBrandPageResponseDataAttributesAsset
  /**
   * List of label definitions
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributes
   */
  labelDefinitions: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitions>
}

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponseDataAttributes interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponseDataAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'brandPage' in value
  isInstance = isInstance && 'brandPageBlock' in value
  isInstance = isInstance && 'asset' in value
  isInstance = isInstance && 'labelDefinitions' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponseDataAttributes {
  return ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSONTyped(json, false)
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPage: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageFromJSON(
      json['brand_page']
    ),
    brandPageBlock: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSON(
      json['brand_page_block']
    ),
    asset: ApplicableLabelsAdminBrandPageResponseDataAttributesAssetFromJSON(json['asset']),
    labelDefinitions: (json['label_definitions'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsFromJSON
    ),
  }
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesToJSON(
  value?: ApplicableLabelsAdminBrandPageResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageToJSON(
      value.brandPage
    ),
    brand_page_block: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockToJSON(
      value.brandPageBlock
    ),
    asset: ApplicableLabelsAdminBrandPageResponseDataAttributesAssetToJSON(value.asset),
    label_definitions: (value.labelDefinitions as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesLabelDefinitionsToJSON
    ),
  }
}
