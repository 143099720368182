// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamEntitiesEntityLabel } from './PostAdminBrandPagesIdLabelParamEntitiesEntityLabel'
import {
  PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSON,
  PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSONTyped,
  PostAdminBrandPagesIdLabelParamEntitiesEntityLabelToJSON,
} from './PostAdminBrandPagesIdLabelParamEntitiesEntityLabel'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamEntities
 */
export interface PostAdminBrandPagesIdLabelParamEntities {
  /**
   * The id of the entity
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamEntities
   */
  id: string
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamEntitiesEntityLabel}
   * @memberof PostAdminBrandPagesIdLabelParamEntities
   */
  entityLabel: PostAdminBrandPagesIdLabelParamEntitiesEntityLabel
}

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamEntities interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamEntities(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'entityLabel' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamEntitiesFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamEntities {
  return PostAdminBrandPagesIdLabelParamEntitiesFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamEntitiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamEntities {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    entityLabel: PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSON(json['entity_label']),
  }
}

export function PostAdminBrandPagesIdLabelParamEntitiesToJSON(
  value?: PostAdminBrandPagesIdLabelParamEntities | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    entity_label: PostAdminBrandPagesIdLabelParamEntitiesEntityLabelToJSON(value.entityLabel),
  }
}
