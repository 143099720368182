// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PatchVideoAdGroupsIdParamAssetFieldDecisionsAltText } from './PatchVideoAdGroupsIdParamAssetFieldDecisionsAltText'
import {
  PatchVideoAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsAltTextFromJSONTyped,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsAltTextToJSON,
} from './PatchVideoAdGroupsIdParamAssetFieldDecisionsAltText'
import type { PatchVideoAdGroupsIdParamAssetFieldDecisionsImage } from './PatchVideoAdGroupsIdParamAssetFieldDecisionsImage'
import {
  PatchVideoAdGroupsIdParamAssetFieldDecisionsImageFromJSON,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsImageToJSON,
} from './PatchVideoAdGroupsIdParamAssetFieldDecisionsImage'
import type { PatchVideoAdGroupsIdParamAssetFieldDecisionsVideo } from './PatchVideoAdGroupsIdParamAssetFieldDecisionsVideo'
import {
  PatchVideoAdGroupsIdParamAssetFieldDecisionsVideoFromJSON,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsVideoFromJSONTyped,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsVideoToJSON,
} from './PatchVideoAdGroupsIdParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface PatchVideoAdGroupsIdParamAssetFieldDecisions
 */
export interface PatchVideoAdGroupsIdParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {PatchVideoAdGroupsIdParamAssetFieldDecisionsAltText}
   * @memberof PatchVideoAdGroupsIdParamAssetFieldDecisions
   */
  altText?: PatchVideoAdGroupsIdParamAssetFieldDecisionsAltText
  /**
   *
   * @type {PatchVideoAdGroupsIdParamAssetFieldDecisionsImage}
   * @memberof PatchVideoAdGroupsIdParamAssetFieldDecisions
   */
  image?: PatchVideoAdGroupsIdParamAssetFieldDecisionsImage
  /**
   *
   * @type {PatchVideoAdGroupsIdParamAssetFieldDecisionsVideo}
   * @memberof PatchVideoAdGroupsIdParamAssetFieldDecisions
   */
  video?: PatchVideoAdGroupsIdParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the PatchVideoAdGroupsIdParamAssetFieldDecisions interface.
 */
export function instanceOfPatchVideoAdGroupsIdParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSON(
  json: any
): PatchVideoAdGroupsIdParamAssetFieldDecisions {
  return PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PatchVideoAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PatchVideoAdGroupsIdParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : PatchVideoAdGroupsIdParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function PatchVideoAdGroupsIdParamAssetFieldDecisionsToJSON(
  value?: PatchVideoAdGroupsIdParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: PatchVideoAdGroupsIdParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PatchVideoAdGroupsIdParamAssetFieldDecisionsImageToJSON(value.image),
    video: PatchVideoAdGroupsIdParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
