import { css } from '@emotion/react'
import { spacing, useTheme, addAlphaToHex, responsive } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { CommonModalProps, ModalBase } from './components/ModalBase'
import { DEFAULT_MODAL_WIDTH, TRANSITION_TIME_MS } from './utils'

type ModalStylesParams = {
  width?: string | number
  variant?: 'full-width' | 'full-page'
  allowContentToScroll?: boolean
  backgroundColor?: string
}

const useStyles = ({
  width,
  variant,
  allowContentToScroll,
  backgroundColor,
}: ModalStylesParams) => {
  const theme = useTheme()
  const fullWidth = variant === 'full-width'
  const fullPage = variant === 'full-page'
  const fullWidthWidth = `calc(100vw - ${spacing.s24 * 6}px)`
  const fullWidthHeight = `calc(100vh - ${spacing.s24 * 6}px)`
  const fullPageWidth = `calc(100vw - ${spacing.s24 * 2}px)`
  const fullPageHeight = `calc(100vh - ${spacing.s24 * 2}px)`

  return {
    root: {
      backdrop: {
        background: addAlphaToHex(theme.colors.systemGrayscale00, 0.3),
        opacity: 0,
        transition: `opacity ${TRANSITION_TIME_MS}ms ease-in-out`,
        '&[data-leave]': {
          opacity: 0,
        },
        '&[data-enter]': {
          opacity: 1,
        },
      },
    },
    modal: {
      modal: css({
        background: backgroundColor,
        borderRadius: 16,
        padding: spacing.s24,
        width: fullPage ? fullPageWidth : fullWidth ? fullWidthWidth : width,
        height: fullPage ? fullPageHeight : 'auto',
        maxWidth: fullPage ? fullPageWidth : fullWidthWidth,
        maxHeight: fullPage ? fullPageHeight : fullWidthHeight,
        '> form': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: allowContentToScroll ? 'hidden' : 'initial',
        },
        [responsive.up('r')]: { maxHeight: fullPage ? fullPageHeight : fullWidthHeight },
        opacity: 0,
        transition: `opacity ${TRANSITION_TIME_MS}ms ease-in-out`,
        '&[data-leave]': {
          opacity: 0,
        },
        '&[data-enter]': {
          opacity: 1,
        },
      }),
    },
  }
}

export interface ModalProps extends CommonModalProps {
  variant?: 'full-width' | 'full-page'
  backgroundColor?: ModalStylesParams['backgroundColor']
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(function Modal(
  {
    children,
    backgroundColor,
    width = DEFAULT_MODAL_WIDTH,
    variant,
    allowContentToScroll = false,
    ...props
  },
  ref
) {
  const styles = useStyles({ backgroundColor, width, variant, allowContentToScroll })

  return (
    <ModalBase {...props} ref={ref} styles={styles}>
      {children}
    </ModalBase>
  )
})
