// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamAssetFieldDecisionsAltText } from './GetBrandPagesParamAssetFieldDecisionsAltText'
import {
  GetBrandPagesParamAssetFieldDecisionsAltTextFromJSON,
  GetBrandPagesParamAssetFieldDecisionsAltTextFromJSONTyped,
  GetBrandPagesParamAssetFieldDecisionsAltTextToJSON,
} from './GetBrandPagesParamAssetFieldDecisionsAltText'
import type { GetBrandPagesParamAssetFieldDecisionsImage } from './GetBrandPagesParamAssetFieldDecisionsImage'
import {
  GetBrandPagesParamAssetFieldDecisionsImageFromJSON,
  GetBrandPagesParamAssetFieldDecisionsImageFromJSONTyped,
  GetBrandPagesParamAssetFieldDecisionsImageToJSON,
} from './GetBrandPagesParamAssetFieldDecisionsImage'
import type { GetBrandPagesParamAssetFieldDecisionsVideo } from './GetBrandPagesParamAssetFieldDecisionsVideo'
import {
  GetBrandPagesParamAssetFieldDecisionsVideoFromJSON,
  GetBrandPagesParamAssetFieldDecisionsVideoFromJSONTyped,
  GetBrandPagesParamAssetFieldDecisionsVideoToJSON,
} from './GetBrandPagesParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface GetBrandPagesParamAssetFieldDecisions
 */
export interface GetBrandPagesParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetBrandPagesParamAssetFieldDecisionsAltText}
   * @memberof GetBrandPagesParamAssetFieldDecisions
   */
  altText?: GetBrandPagesParamAssetFieldDecisionsAltText
  /**
   *
   * @type {GetBrandPagesParamAssetFieldDecisionsImage}
   * @memberof GetBrandPagesParamAssetFieldDecisions
   */
  image?: GetBrandPagesParamAssetFieldDecisionsImage
  /**
   *
   * @type {GetBrandPagesParamAssetFieldDecisionsVideo}
   * @memberof GetBrandPagesParamAssetFieldDecisions
   */
  video?: GetBrandPagesParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the GetBrandPagesParamAssetFieldDecisions interface.
 */
export function instanceOfGetBrandPagesParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetBrandPagesParamAssetFieldDecisionsFromJSON(
  json: any
): GetBrandPagesParamAssetFieldDecisions {
  return GetBrandPagesParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetBrandPagesParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetBrandPagesParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetBrandPagesParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : GetBrandPagesParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function GetBrandPagesParamAssetFieldDecisionsToJSON(
  value?: GetBrandPagesParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: GetBrandPagesParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetBrandPagesParamAssetFieldDecisionsImageToJSON(value.image),
    video: GetBrandPagesParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
