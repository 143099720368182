// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchy } from './GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchy'
import {
  GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchyFromJSON,
  GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchyToJSON,
} from './GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchy'
import type { GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValues } from './GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValues'
import {
  GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValues'
import type { GetDisplayCampaignsParamBrandPageFieldDecisionsTitle } from './GetDisplayCampaignsParamBrandPageFieldDecisionsTitle'
import {
  GetDisplayCampaignsParamBrandPageFieldDecisionsTitleFromJSON,
  GetDisplayCampaignsParamBrandPageFieldDecisionsTitleFromJSONTyped,
  GetDisplayCampaignsParamBrandPageFieldDecisionsTitleToJSON,
} from './GetDisplayCampaignsParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamBrandPageFieldDecisions
 */
export interface GetDisplayCampaignsParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetDisplayCampaignsParamBrandPageFieldDecisionsTitle}
   * @memberof GetDisplayCampaignsParamBrandPageFieldDecisions
   */
  title?: GetDisplayCampaignsParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchy>}
   * @memberof GetDisplayCampaignsParamBrandPageFieldDecisions
   */
  hierarchy?: Array<GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof GetDisplayCampaignsParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamBrandPageFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsParamBrandPageFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsParamBrandPageFieldDecisions {
  return GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : GetDisplayCampaignsParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function GetDisplayCampaignsParamBrandPageFieldDecisionsToJSON(
  value?: GetDisplayCampaignsParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: GetDisplayCampaignsParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            GetDisplayCampaignsParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(
            value.taxonomy,
            GetDisplayCampaignsParamBrandPageFieldDecisionsTaxonomyValuesToJSON
          ),
  }
}
