import Ajv from 'ajv'
import Schema from 'ajv/lib/refs/json-schema-draft-04.json'
import axios from 'axios'
import TagManager from 'react-gtm-module'
import initSegment from 'common/analytics/external_libraries/segment'
import { getEnv } from 'common/utils'
import Analytics, { AnalyticsParams } from './Analytics'
import getDefaultFontSize from './utils/getDefaultFontSize'

const LOG_CSS = 'color: #43B02A; font-style: italic; font-weight: normal'

class AnalyticProviderStub {
  ajv: Ajv.Ajv

  eventSchemas: { [eventName: string]: unknown }

  constructor() {
    this.ajv = new Ajv({ allErrors: true, schemaId: 'id' })
    this.ajv.addMetaSchema(Schema)
    this.eventSchemas = {}
  }

  async initialize() {
    try {
      // Code from https://github.com/instacart/carrot/blob/master/customers/store/server/layouts/analytics/Segment.js#L53
      const eventsStoreURL = getEnv('EVENTS_STORE_URL')
      const { data } = await axios.get(
        eventsStoreURL || 'https://events-store.instacart.tools/events.json?app=ads_tools'
      )
      const { events: eventSchemas } = data
      this.eventSchemas = eventSchemas

      Object.keys(eventSchemas).forEach(key => {
        this.ajv.addSchema(eventSchemas[key], key)
      })
      console.log('%c🥑 %s', 'color: #43B02A; font-style:italic', 'analytics validations ready')
    } catch (error) {
      console.log('Failed to load event schemas.')
    }
  }

  identify(id: string | number, traits: AnalyticsParams) {
    console.log('%c🌽 %s: %O', LOG_CSS, id, traits)
  }

  track(event: string, params: AnalyticsParams) {
    console.log('%c🌽 %s: %O', LOG_CSS, event, params)
    if (this.eventSchemas[event]) {
      if (!this.ajv.validate(event, params)) {
        console.error(`Analytics event data for ${event} not valid!`, params)
        console.error(this.ajv.errorsText())
      }
    }
  }
}

function initAnalyticsProvider() {
  const segmentWriteKey = getEnv('SEGMENT_WRITE_KEY')
  const mongooseBaseUrl = getEnv('MONGOOSE_V2_API_URL') || ''
  const isProduction = getEnv('NODE_ENV') === 'production'
  const tagManagerArgs = {
    gtmId: 'GTM-K64CDHX',
  }

  // send events only in production.
  if (!isProduction) {
    const analyticProviderStub = new AnalyticProviderStub()
    analyticProviderStub.initialize()
    Analytics.setAnalyticsProvider(analyticProviderStub)
    return
  }

  TagManager.initialize(tagManagerArgs)

  Analytics.setDebug(window.location.search.indexOf('analytics_debug=') !== -1)

  if (segmentWriteKey) {
    const segment = initSegment(segmentWriteKey, {
      integrations: {
        'Segment.io': {
          apiHost: mongooseBaseUrl.endsWith('/v2') ? mongooseBaseUrl : `${mongooseBaseUrl}/v2`,
        },
      },
    })
    if (segment) Analytics.setAnalyticsProvider(segment)
  }

  Analytics.track('self_service.environment', {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    screenWidth: window.screen?.width,
    screenHeight: window.screen?.height,
    defaultFontSize: getDefaultFontSize(),
    ahoyVisitId: window.__ADS?.ahoy?.getVisitId(),
  })
}

export default initAnalyticsProvider
