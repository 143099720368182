// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
 */
export interface PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch {
  /**
   * Attributed Quantities
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  attributedQuantities: number
  /**
   * Attributed Sales
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  attributedSales: number
  /**
   * Return on ad spend
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  roas: number
  /**
   * New to Brand Attributed Sales
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  ntbAttributedSales?: number
  /**
   * Percentage of Attributed Sales that is New to Brand
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  percentNtbAttributedSales?: number
  /**
   * NTB Return on ad spend, only available for NTB optimized bidding campaigns
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch
   */
  ntbRoas?: number
}

/**
 * Check if a given object implements the PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch interface.
 */
export function instanceOfPutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'attributedQuantities' in value
  isInstance = isInstance && 'attributedSales' in value
  isInstance = isInstance && 'roas' in value

  return isInstance
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
  json: any
): PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch {
  return PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped(
    json,
    false
  )
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch {
  if (json === undefined || json === null) {
    return json
  }
  return {
    attributedQuantities: json['attributed_quantities'],
    attributedSales: json['attributed_sales'],
    roas: json['roas'],
    ntbAttributedSales: !exists(json, 'ntb_attributed_sales')
      ? undefined
      : json['ntb_attributed_sales'],
    percentNtbAttributedSales: !exists(json, 'percent_ntb_attributed_sales')
      ? undefined
      : json['percent_ntb_attributed_sales'],
    ntbRoas: !exists(json, 'ntb_roas') ? undefined : json['ntb_roas'],
  }
}

export function PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
  value?: PutAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataLastTouch | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    attributed_quantities: value.attributedQuantities,
    attributed_sales: value.attributedSales,
    roas: value.roas,
    ntb_attributed_sales: value.ntbAttributedSales,
    percent_ntb_attributed_sales: value.percentNtbAttributedSales,
    ntb_roas: value.ntbRoas,
  }
}
