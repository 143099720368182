// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostBrandPagesIdStatusParamBrandPageBlockSnapshot,
  instanceOfPostBrandPagesIdStatusParamBrandPageBlockSnapshot,
  PostBrandPagesIdStatusParamBrandPageBlockSnapshotFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockSnapshotToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockSnapshot'
import {
  PostBrandPagesIdStatusParamBrandPageSnapshot,
  instanceOfPostBrandPagesIdStatusParamBrandPageSnapshot,
  PostBrandPagesIdStatusParamBrandPageSnapshotFromJSON,
  PostBrandPagesIdStatusParamBrandPageSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageSnapshotToJSON,
} from './PostBrandPagesIdStatusParamBrandPageSnapshot'
import {
  PostBrandPagesIdStatusParamCreativeSnapshot,
  instanceOfPostBrandPagesIdStatusParamCreativeSnapshot,
  PostBrandPagesIdStatusParamCreativeSnapshotFromJSON,
  PostBrandPagesIdStatusParamCreativeSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamCreativeSnapshotToJSON,
} from './PostBrandPagesIdStatusParamCreativeSnapshot'
import {
  PostBrandPagesIdStatusParamDisplayAdGroupSnapshot,
  instanceOfPostBrandPagesIdStatusParamDisplayAdGroupSnapshot,
  PostBrandPagesIdStatusParamDisplayAdGroupSnapshotFromJSON,
  PostBrandPagesIdStatusParamDisplayAdGroupSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamDisplayAdGroupSnapshotToJSON,
} from './PostBrandPagesIdStatusParamDisplayAdGroupSnapshot'
import {
  PostBrandPagesIdStatusParamDisplayAssetSnapshot,
  instanceOfPostBrandPagesIdStatusParamDisplayAssetSnapshot,
  PostBrandPagesIdStatusParamDisplayAssetSnapshotFromJSON,
  PostBrandPagesIdStatusParamDisplayAssetSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamDisplayAssetSnapshotToJSON,
} from './PostBrandPagesIdStatusParamDisplayAssetSnapshot'
import {
  PostBrandPagesIdStatusParamDisplayProductAssetSnapshot,
  instanceOfPostBrandPagesIdStatusParamDisplayProductAssetSnapshot,
  PostBrandPagesIdStatusParamDisplayProductAssetSnapshotFromJSON,
  PostBrandPagesIdStatusParamDisplayProductAssetSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamDisplayProductAssetSnapshotToJSON,
} from './PostBrandPagesIdStatusParamDisplayProductAssetSnapshot'

/**
 * @type PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & PostBrandPagesIdStatusParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & PostBrandPagesIdStatusParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PostBrandPagesIdStatusParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & PostBrandPagesIdStatusParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & PostBrandPagesIdStatusParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & PostBrandPagesIdStatusParamDisplayProductAssetSnapshot)

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot {
  return PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PostBrandPagesIdStatusParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PostBrandPagesIdStatusParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PostBrandPagesIdStatusParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PostBrandPagesIdStatusParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PostBrandPagesIdStatusParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PostBrandPagesIdStatusParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PostBrandPagesIdStatusParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PostBrandPagesIdStatusParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PostBrandPagesIdStatusParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PostBrandPagesIdStatusParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PostBrandPagesIdStatusParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PostBrandPagesIdStatusParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
