import { Theme, useTheme } from '@instacart/ids-core'
import {
  SecondaryButtonProps,
  UseButtonStylesOpts,
  makeButton,
} from 'components/ids-ads/deprecated'

const baseStyles: UseButtonStylesOpts = {
  normalBackground: null,
  borderColor: 'systemGrayscale50',
  textColor: 'systemGrayscale70',
  hoverBackground: 'systemGrayscale30',
  activeBackground: 'systemGrayscale50',
  disabledBackground: null,
  disabledBorderColor: 'systemGrayscale30',
  disabledTextColor: 'systemGrayscale30',
}

function useStyles(theme: Theme) {
  return {
    root: {
      padding: `2px 8px`,
      borderRadius: theme.radius.r8,
    },
    content: {
      ...theme.typography.bodySmall1,
    },
  }
}

/** @deprecated Use BannerActionButtons from 'components' instead */
export const BannerActionButton = (props: SecondaryButtonProps) => {
  const { children, ...restProps } = props

  const theme = useTheme()
  const styles = useStyles(theme)

  const ButtonComponent = makeButton('SecondaryButton', {
    ...baseStyles,
    baseButtonStyleOverrides: {
      content: {
        ...theme.typography.bodySmall1,
      },
    },
  })

  return (
    <ButtonComponent css={styles.root} {...restProps}>
      {children}
    </ButtonComponent>
  )
}
