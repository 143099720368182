// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick } from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick } from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick'
import type { GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick } from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick'
import {
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick
 */
export interface GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  urlClick?: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick {
  return GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON(
  value?: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click: GetVideoAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
      value.recipeClick
    ),
  }
}
