// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppeals } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppeals'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppealsToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppeals'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewState } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewState'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  type: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewState}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  reviewState?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  entitySnapshot: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  decisionSnapshot: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppeals}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates
   */
  appeals: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum =
  {
    Asset: 'ASSET',
    BrandPage: 'BRAND_PAGE',
    BrandPageBlock: 'BRAND_PAGE_BLOCK',
    Creative: 'CREATIVE',
    DisplayAdGroup: 'DISPLAY_AD_GROUP',
    EmailAdGroup: 'EMAIL_AD_GROUP',
    ProductAsset: 'PRODUCT_ASSET',
  } as const
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum =
  typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum[keyof typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
        json['entity_snapshot']
      ),
    decisionSnapshot:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSON(
        json['appeals']
      ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateToJSON(
        value.reviewState
      ),
    entity_snapshot:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
        value.entitySnapshot
      ),
    decision_snapshot:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotToJSON(
        value.decisionSnapshot
      ),
    appeals:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesEditorialStatesAppealsToJSON(
        value.appeals
      ),
  }
}
