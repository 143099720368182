// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBlockSpotlightProductCollectionContent } from './PutBrandPagesIdParamBlockSpotlightProductCollectionContent'
import {
  PutBrandPagesIdParamBlockSpotlightProductCollectionContentFromJSON,
  PutBrandPagesIdParamBlockSpotlightProductCollectionContentFromJSONTyped,
  PutBrandPagesIdParamBlockSpotlightProductCollectionContentToJSON,
} from './PutBrandPagesIdParamBlockSpotlightProductCollectionContent'
import type { PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues } from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues'
import {
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesToJSON,
} from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues'

/**
 *
 * @export
 * @interface PutBrandPagesIdParamBlockSpotlightProductCollection
 */
export interface PutBrandPagesIdParamBlockSpotlightProductCollection {
  /**
   * id of the block, required for updates
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  type: PutBrandPagesIdParamBlockSpotlightProductCollectionTypeEnum
  /**
   *
   * @type {PutBrandPagesIdParamBlockSpotlightProductCollectionContent}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  content: PutBrandPagesIdParamBlockSpotlightProductCollectionContent
  /**
   * Unordered collection of products for display
   * @type {Array<string>}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  productIds?: Array<string>
  /**
   * Map of product id to its corresponding assets
   * @type {{ [key: string]: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues; }}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollection
   */
  productAssets?: {
    [key: string]: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues
  }
}

/**
 * @export
 */
export const PutBrandPagesIdParamBlockSpotlightProductCollectionTypeEnum = {
  SpotlightProductCollectionEvergreenV1: 'spotlight_product_collection.evergreen.v1',
} as const
export type PutBrandPagesIdParamBlockSpotlightProductCollectionTypeEnum =
  typeof PutBrandPagesIdParamBlockSpotlightProductCollectionTypeEnum[keyof typeof PutBrandPagesIdParamBlockSpotlightProductCollectionTypeEnum]

/**
 * Check if a given object implements the PutBrandPagesIdParamBlockSpotlightProductCollection interface.
 */
export function instanceOfPutBrandPagesIdParamBlockSpotlightProductCollection(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value

  return isInstance
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSON(
  json: any
): PutBrandPagesIdParamBlockSpotlightProductCollection {
  return PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSONTyped(json, false)
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBlockSpotlightProductCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: PutBrandPagesIdParamBlockSpotlightProductCollectionContentFromJSON(json['content']),
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
    productAssets: !exists(json, 'product_assets')
      ? undefined
      : mapValues(
          json['product_assets'],
          PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON
        ),
  }
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionToJSON(
  value?: PutBrandPagesIdParamBlockSpotlightProductCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: PutBrandPagesIdParamBlockSpotlightProductCollectionContentToJSON(value.content),
    product_ids: value.productIds,
    product_assets:
      value.productAssets === undefined
        ? undefined
        : mapValues(
            value.productAssets,
            PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesToJSON
          ),
  }
}
