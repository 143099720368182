// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabels } from './PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabels'
import {
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSON,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSONTyped,
  PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabelsToJSON,
} from './PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabels'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamAssetFieldDecisionsImage
 */
export interface PutUniversalAdGroupsIdParamAssetFieldDecisionsImage {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisionsImage
   */
  decision?: PutUniversalAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabels>}
   * @memberof PutUniversalAdGroupsIdParamAssetFieldDecisionsImage
   */
  labels?: Array<PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabels>
}

/**
 * @export
 */
export const PutUniversalAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PutUniversalAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum =
  typeof PutUniversalAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum[keyof typeof PutUniversalAdGroupsIdParamAssetFieldDecisionsImageDecisionEnum]

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamAssetFieldDecisionsImage interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamAssetFieldDecisionsImage(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSON(
  json: any
): PutUniversalAdGroupsIdParamAssetFieldDecisionsImage {
  return PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamAssetFieldDecisionsImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabelsFromJSON
        ),
  }
}

export function PutUniversalAdGroupsIdParamAssetFieldDecisionsImageToJSON(
  value?: PutUniversalAdGroupsIdParamAssetFieldDecisionsImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PutUniversalAdGroupsIdParamAssetFieldDecisionsImageLabelsToJSON
          ),
  }
}
