import { css } from '@emotion/react'
import { useTheme, Color } from '@instacart/ids-core'
import classNames from 'classnames'
import { CSSProperties, HTMLProps } from 'react'

export interface CardTitleProps extends Omit<HTMLProps<HTMLHeadingElement>, 'title'> {
  children: React.ReactNode
  note?: string
  large?: boolean
  small?: boolean
  title?: boolean
  subtitle?: boolean
  subtitleAlt?: boolean
  cardTitle?: boolean
  tiny?: boolean
  color?: Color
  marginBottom?: CSSProperties['marginBottom']
}

function useStyles(color?: Color, marginBottom?: CSSProperties['marginBottom']) {
  const theme = useTheme()

  return {
    root: css({
      color: theme.colors[color || 'systemGrayscale70'],
      ...theme.typography.subtitleLarge,
      marginBottom: marginBottom || '1rem',
      '&.large': {
        ...theme.typography.titleMedium,
        marginBottom: marginBottom || '0.75rem',
      },
      '&.small': {
        ...theme.typography.bodyMedium1,
        marginBottom: marginBottom || '0.75rem',
      },
      '&.title': {
        ...theme.typography.subtitleLarge,
        marginBottom: marginBottom || '0.75rem',
      },
      '&.subtitle': {
        ...theme.typography.subtitleMedium,
        marginBottom: marginBottom || '0.75rem',
      },
      '&.subtitleAlt': {
        ...theme.typography.bodyMedium2,
        marginBottom: marginBottom || '0.75rem',
      },
      '&.cardTitle': {
        ...theme.typography.subtitleLarge,
        marginBottom: marginBottom || '0.5rem',
      },
      '&.tiny': {
        ...theme.typography.bodySmall1,
        marginBottom: marginBottom || '0.5rem',
      },
    }),
    note: css({
      fontWeight: 'normal',
    }),
  }
}

export function CardTitle({
  children,
  note,
  large,
  small,
  title,
  subtitle,
  subtitleAlt,
  cardTitle,
  tiny,
  color,
  marginBottom,
  ...props
}: CardTitleProps) {
  const styles = useStyles(color, marginBottom)

  return (
    <h2
      css={styles.root}
      className={classNames({ large, small, title, subtitle, subtitleAlt, tiny, cardTitle })}
      {...props}
    >
      {children}
      {note && <span css={styles.note}>{` (${note})`}</span>}
    </h2>
  )
}
