import { SVGIconProps, internalSVGIcon } from '@instacart/ids-core'

const { SVGIcon } = internalSVGIcon

function WarningIcon(props: Omit<SVGIconProps, 'component'>) {
  return (
    <SVGIcon
      component={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.47 21.0001H19.53C21.07 21.0001 22.03 19.3301 21.26 18.0001L13.73 4.99005C12.96 3.66005 11.04 3.66005 10.27 4.99005L2.74 18.0001C1.97 19.3301 2.93 21.0001 4.47 21.0001ZM12 15C11.45 15 11 14.55 11 14V10.5C11 9.95 11.45 9.5 12 9.5C12.55 9.5 13 9.95 13 10.5V14C13 14.55 12.55 15 12 15ZM13 17.0001C13 17.5523 12.5523 18.0001 12 18.0001C11.4477 18.0001 11 17.5523 11 17.0001C11 16.4478 11.4477 16.0001 12 16.0001C12.5523 16.0001 13 16.4478 13 17.0001Z"
          />
        </svg>
      }
      {...props}
    />
  )
}

export default WarningIcon
