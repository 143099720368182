// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInput
 */
export interface ApiDisplayAdGroupsControllerUpdateInput {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup}
   * @memberof ApiDisplayAdGroupsControllerUpdateInput
   */
  displayAdGroup: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
  /**
   * Specify whether or not to validate all the fields of an ad group. Used for saving an ad group without all the fields.
   * @type {boolean}
   * @memberof ApiDisplayAdGroupsControllerUpdateInput
   */
  skipValidation?: boolean
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInput interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'displayAdGroup' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInput {
  return ApiDisplayAdGroupsControllerUpdateInputFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    displayAdGroup: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSON(
      json['display_ad_group']
    ),
    skipValidation: !exists(json, 'skip_validation') ? undefined : json['skip_validation'],
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    display_ad_group: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupToJSON(
      value.displayAdGroup
    ),
    skip_validation: value.skipValidation,
  }
}
