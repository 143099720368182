// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick } from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick } from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import type { ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick } from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'
import {
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick
 */
export interface ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  urlClick?: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick {
  return ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
  value?: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click:
      ReservationAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
