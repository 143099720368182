// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentText } from './GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentText'
import {
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentText'
import type { GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitle } from './GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitle'
import {
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent
 */
export interface GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent
   */
  title?: GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentText}
   * @memberof GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent
   */
  text?: GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfGetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent {
  return GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: GetVideoAdGroupsParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
