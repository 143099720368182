import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext, useEffect } from 'react'
import useIsInitialRender from 'hooks/useIsInitialRender'
import TargetingSegments from '../../components/TargetingSegments'
import {
  getTargetingSegmentsFieldName,
  Operator,
  SegmentType,
  DEFAULT_TARGETING_SEGMENT,
} from '../../constants'
import { TargetingRuleContext } from '../../hooks'
import { TargetingFormData, TargetingSegmentType } from '../../types'

interface TargetingSegmentsExpandedSectionProps {
  segmentType: SegmentType
}

export default function TargetingSegmentsExpandedSection({
  segmentType,
}: TargetingSegmentsExpandedSectionProps) {
  const isInitialRender = useIsInitialRender()

  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)

  const { values, setFieldValue } = useFormikContext<TargetingFormData>()
  const segmentsFieldName = getTargetingSegmentsFieldName(segmentType)
  const segments: TargetingSegmentType[] = get(values, segmentsFieldName)

  useEffect(() => {
    if (isInitialRender && !segments.length && !isReadOnly && !isReviewing) {
      setFieldValue(segmentsFieldName, [DEFAULT_TARGETING_SEGMENT])
    }
  }, [isInitialRender, isReadOnly, isReviewing, setFieldValue, segments.length, segmentsFieldName])

  return <TargetingSegments segmentType={segmentType} operator={Operator.AND} />
}
