import { ADS_API_URL } from 'common/constants'
import { getEnv, isProd } from './utils'

function envOrLocalStorageBool(key: string): boolean {
  return getEnv(key) === 'true' || localStorage.getItem(key) === 'true'
}

export function isOktaLoginEnabled() {
  return isProd() || envOrLocalStorageBool('ADS_FORCE_OKTA_LOGIN')
}

export function buildOktaUrl(redirect: string) {
  return `${ADS_API_URL}/auth/okta/login?redirect_url=${window.location.origin}${redirect}`
}

export function oktaLogin(redirect = '/home') {
  window.location.assign(buildOktaUrl(redirect))
}
