import { IntlShape } from 'react-intl'
import { ColumnDefinition, RowBase } from 'components/organisms/TableV3'
import { AdGroupDetailFilterState } from 'components/Reporting/components/AdGroupDetailFilters'
import { ChartSectionProps } from 'components/Reporting/components/ChartSection'
import { MessageIdType } from 'locales/types'
import { AdGroupTabName } from 'pages/AdGroup/adGroup.constants'
import { ChartUnitValues } from 'service/shared'

export interface CampaignBase {
  id: string
  attributes: {
    name: string
    startsAt: string
    endsAt: string
    budget: number
    status: string
  }
}

export interface AdGroupBase {
  id: string
  attributes: {
    name?: string
    status?: string
  }
}

// eslint-disable-next-line no-restricted-syntax
export enum DisplayAggregatedReport {
  CAMPAIGN = 'display_campaigns_aggregated',
  AD_GROUP = 'display_ad_groups_aggregated',
  KEYWORD = 'display_keywords_aggregated',
  BRAND_PAGE = 'brand_pages_aggregated',
  BRAND_PAGE_TAG = 'brand_pages_tags_aggregated',
}

// eslint-disable-next-line no-restricted-syntax
export enum ReportStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
}

// eslint-disable-next-line no-restricted-syntax
export enum DisplayDailyReport {
  CAMPAIGN = 'display_campaigns_raw',
  AD_GROUP = 'display_ad_groups_raw',
  KEYWORD = 'display_keywords_raw',
  BRAND_PAGE = 'brand_pages_raw',
  BRAND_PAGE_TAG = 'brand_pages_tags_raw',
}

// eslint-disable-next-line no-restricted-syntax
export enum FeaturedProductAggregatedReport {
  PRODUCT = 'products_aggregated',
  KEYWORD = 'keywords_aggregated',
  TOP_100_KEYWORD = 'top100Keywords_aggregated',
  CAMPAIGN = 'campaigns_aggregated',
  AD_GROUP = 'adgroups_aggregated',
}

// eslint-disable-next-line no-restricted-syntax
export enum FeaturedProductDailyReport {
  PRODUCT = 'products_raw',
  KEYWORD = 'keywords_raw',
  CAMPAIGN = 'campaigns_raw',
  AD_GROUP = 'adgroups_raw',
}

// eslint-disable-next-line no-restricted-syntax
export enum SPImpressionAndClicksReport {
  CAMPAIGN = 'sp_impression_and_clicks',
}

// eslint-disable-next-line no-restricted-syntax
export enum DisplayImpressionAndClicksReport {
  CAMPAIGN = 'display_impression_and_clicks',
}

// eslint-disable-next-line no-restricted-syntax
export enum DisplaySearchTermsReport {
  CAMPAIGN = 'display_search_terms',
}

export type Report =
  | DisplayAggregatedReport
  | DisplayDailyReport
  | FeaturedProductAggregatedReport
  | FeaturedProductDailyReport
  | SPImpressionAndClicksReport
  | DisplayImpressionAndClicksReport
  | DisplaySearchTermsReport

// eslint-disable-next-line no-restricted-syntax
export enum PageLevel {
  DASHBOARD = 'dashboard',
  CAMPAIGN = 'campaign',
  AD_GROUP = 'ad_group',
  KEYWORD = 'keyword',
}

export type TabName = AdGroupTabName
export interface ReportOptions {
  dateRangeEndDate?: string | undefined
  dateRangeStartDate?: string | undefined
  campaignUuid?: string | undefined
  adGroupUuid?: string | undefined
  segment?: string | undefined
}

// eslint-disable-next-line no-restricted-syntax
export enum ExportCampaignType {
  FEATURED_PRODUCT = 'featured_product',
  DISPLAY = 'display',
}

export interface ExportDataDropdownOptionsProps {
  pageLevel?: PageLevel
  intl: IntlShape
  campaignType?: ExportCampaignType
  tabName?: TabName
  isAutomatedBiddingCampaign?: boolean
  isMRCReportingEnabled?: boolean
  isBroadMatchReportingEnabled?: boolean
}

export type ExportDataButtonAnalyticsInfo = {
  eventName: string
}

export function isDailyReport(report: Report) {
  return (
    Object.values(FeaturedProductDailyReport).includes(report as FeaturedProductDailyReport) ||
    Object.values(DisplayDailyReport).includes(report as DisplayDailyReport)
  )
}

export function isBrandPageTagReport(report: Report) {
  return [DisplayDailyReport.BRAND_PAGE_TAG, DisplayAggregatedReport.BRAND_PAGE_TAG].includes(
    report as DisplayAggregatedReport | DisplayDailyReport
  )
}

export function isFeaturedProductKeywordReport(report: Report) {
  return FeaturedProductAggregatedReport.TOP_100_KEYWORD === report
}

export function isMRCReport(report: Report) {
  return [SPImpressionAndClicksReport.CAMPAIGN, DisplayImpressionAndClicksReport.CAMPAIGN].includes(
    report as SPImpressionAndClicksReport | DisplayImpressionAndClicksReport
  )
}

export interface MetricGroup<
  C extends ChartUnitValues = ChartUnitValues,
  D extends RowBase = RowBase
> extends Pick<
    ChartSectionProps<C, AdGroupDetailFilterState>,
    'availableMetrics' | 'loadChartData' | 'loadAggregatedMetrics'
  > {
  key: string
  labelId: MessageIdType
  columns:
    | ColumnDefinition<D>[]
    | ((filterState: AdGroupDetailFilterState) => ColumnDefinition<D>[])
  loadTableData(filterState: AdGroupDetailFilterState): Promise<D[]>
  renderActions?(filterState: AdGroupDetailFilterState): JSX.Element
}
