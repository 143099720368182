// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch } from './DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinear } from './DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface DisplayCampaignResponseDataAttributesAnalyticsAttributionData
 */
export interface DisplayCampaignResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof DisplayCampaignResponseDataAttributesAnalyticsAttributionData
   */
  linear: DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof DisplayCampaignResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the DisplayCampaignResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfDisplayCampaignResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function DisplayCampaignResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): DisplayCampaignResponseDataAttributesAnalyticsAttributionData {
  return DisplayCampaignResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function DisplayCampaignResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayCampaignResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function DisplayCampaignResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: DisplayCampaignResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: DisplayCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
