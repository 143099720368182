import { useCallback } from 'react'
import { AdUsersSessionsApi } from 'service/apis/AdUsersSessionsApi'
import { Capabilities } from 'service/types/capabilities'
import { useApiFn, UseApiFnOptions } from './base'

type UseCapabilitiesOptions = UseApiFnOptions<Capabilities>

export function useCapabilities({ throwError = false }: UseCapabilitiesOptions = {}) {
  const fn = useCallback(() => AdUsersSessionsApi.getAuthCapabilities(), [])

  const { loading, response, error } = useApiFn(fn, { throwError })

  return {
    loadingCapabilities: loading,
    capabilities: response?.data?.attributes as Capabilities,
    error,
  }
}
