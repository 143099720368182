// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostBrandPagesIdStatusParamAssetFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamAssetFieldDecisions,
  PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamAssetFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamAssetFieldDecisions'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamBrandPageBlockFieldDecisions,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions'
import {
  PostBrandPagesIdStatusParamBrandPageFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamBrandPageFieldDecisions,
  PostBrandPagesIdStatusParamBrandPageFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamBrandPageFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamBrandPageFieldDecisions'
import {
  PostBrandPagesIdStatusParamCreativeFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamCreativeFieldDecisions,
  PostBrandPagesIdStatusParamCreativeFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamCreativeFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamCreativeFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamCreativeFieldDecisions'
import {
  PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamDisplayAdGroupFieldDecisions,
  PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisions'
import {
  PostBrandPagesIdStatusParamProductAssetFieldDecisions,
  instanceOfPostBrandPagesIdStatusParamProductAssetFieldDecisions,
  PostBrandPagesIdStatusParamProductAssetFieldDecisionsFromJSON,
  PostBrandPagesIdStatusParamProductAssetFieldDecisionsFromJSONTyped,
  PostBrandPagesIdStatusParamProductAssetFieldDecisionsToJSON,
} from './PostBrandPagesIdStatusParamProductAssetFieldDecisions'

/**
 * @type PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & PostBrandPagesIdStatusParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & PostBrandPagesIdStatusParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & PostBrandPagesIdStatusParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & PostBrandPagesIdStatusParamProductAssetFieldDecisions)

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PostBrandPagesIdStatusParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PostBrandPagesIdStatusParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PostBrandPagesIdStatusParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PostBrandPagesIdStatusParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PostBrandPagesIdStatusParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PostBrandPagesIdStatusParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PostBrandPagesIdStatusParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PostBrandPagesIdStatusParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PostBrandPagesIdStatusParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
