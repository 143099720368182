import { spacing, Text, useTheme } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import useIntl from 'common/useIntl'
import { getImageUrl } from '../utils/helpers'
import breakPoints from './breakPoints'

const useStyles = (): { [key: string]: CSSProperties } => {
  const theme = useTheme()
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.colors.systemGrayscale10,
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '1022px',
      ...{ paddingTop: spacing.s48 },
      [breakPoints.mobile]: {
        flexDirection: 'column',
        ...{ paddingTop: spacing.s24 },
      },
    },
    left: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      ...{ marginLeft: spacing.s32, marginRight: spacing.s32 },
      [breakPoints.mobile]: {
        justifyContent: 'flex-start',
        ...{ marginLeft: spacing.s24, marginRight: spacing.s24 },
        textAlign: 'center',
      },
    },
    title: {
      color: theme.colors.systemGrayscale70,
      [breakPoints.mobile]: {
        fontSize: '22px',
      },
    },
    right: {
      display: 'flex',
      flexGrow: 1,
      ...{ marginLeft: spacing.s32, marginRight: spacing.s32 },
      ...{ paddingRight: spacing.s32 },
      [breakPoints.mobile]: {
        justifyContent: 'center',
        ...{ paddingTop: spacing.s24 },
        ...{ marginLeft: spacing.s24, marginRight: spacing.s24 },
      },
    },
    world: {
      height: '300px',
      objectFit: 'contain',
      objectPosition: 'bottom',
      [breakPoints.mobile]: {
        height: '220px',
      },
    },
    text: {
      maxWidth: '400px',
      color: theme.colors.systemGrayscale50,
      marginTop: spacing.s16,
      [breakPoints.mobile]: {
        maxWidth: 'initial',
      },
    },
  }
}

const WhoWeAre = () => {
  const intl = useIntl()
  const styles = useStyles()
  return (
    <div style={styles.wrapper}>
      <div style={styles.row}>
        <div style={styles.left}>
          <div>
            <Text typography="bodyLarge2" style={styles.title} as="h2" color="systemGrayscale20">
              {intl.formatMessage({ id: 'landing.whoWeAre.header' })}
            </Text>
            <Text typography="bodyMedium2" style={styles.text}>
              {intl.formatMessage({ id: 'landing.whoWeAre.description' })}
            </Text>
          </div>
        </div>
        <div style={styles.right}>
          <img
            src={getImageUrl('/images/landing/instacart_world.svg')}
            alt={intl.formatMessage({ id: 'landing.whoWeAre.instacartAlt' })}
            style={styles.world}
          />
        </div>
      </div>
    </div>
  )
}

export default Radium(WhoWeAre)
