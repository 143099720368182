import { spacing, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import toPx from 'common/toPx'
import FilterSelect, { Option } from 'components/FilterSelect'
import { MessageIdType } from 'locales/types'
import { TargetingRuleContext } from '../hooks'

function useStyles(isDisabled: boolean) {
  const theme = useTheme()

  return {
    button: {
      background: theme.colors.systemGrayscale00,
      color: theme.colors.systemGrayscale70,
      height: 28,
      border: `1px solid ${theme.colors.systemGrayscale30}`,
      borderRadius: 6,
      ...(isDisabled
        ? {
            paddingRight: 0,
          }
        : { padding: 12 }),
    },
    content: {
      ...theme.typography.bodySmall1,
      color: theme.colors.systemGrayscale50,
      svg: {
        fill: theme.colors.systemGrayscale70,
        display: isDisabled ? 'none' : 'undefined',
      },
    },
  }
}

export interface CustomDropdownProps {
  options: Option<string>[]
  fieldName: string
  isMultiSelect?: boolean
  placeholderId?: MessageIdType
  width?: string
  required?: boolean
}

export default function CustomDropdown({
  options,
  fieldName,
  placeholderId = 'common.empty',
  width = 'auto',
  isMultiSelect = false,
  required = true,
}: CustomDropdownProps) {
  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const isDisabled = isReadOnly || isReviewing

  const styles = useStyles(isDisabled)

  const { values, setFieldValue } = useFormikContext()
  const fieldValue = get(values, fieldName)
  if (!fieldValue && required) {
    setFieldValue(fieldName, options[0].value)
  }

  return (
    <div css={{ margin: toPx`0 ${spacing.s4}` }}>
      <FilterSelect
        value={isMultiSelect ? fieldValue : [fieldValue]}
        onChange={updatedValues =>
          setFieldValue(fieldName, isMultiSelect ? updatedValues : updatedValues[0])
        }
        showClear={false}
        isMultiSelect={isMultiSelect}
        options={options}
        placeholderId={placeholderId}
        disabled={isDisabled}
        width={width}
        buttonStyles={styles}
      />
    </div>
  )
}
