// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import type { EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick } from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'
import {
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
 */
export interface EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  urlClick?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfEnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  return EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
  value?: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click:
      EnableDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
