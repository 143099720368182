import { GetAdGroupsResponseDataAttributesUserTargetingGeographyEnum as UsStates } from 'service/openapi/__codegen__/models/GetAdGroupsResponseDataAttributesUserTargeting'
import { GeographicTargetingOptions } from './GeographicTargeting'

export const PROVI_GEOGRAPHIC_TARGETING_OPTIONS: GeographicTargetingOptions[] = [
  {
    value: UsStates.Al,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ak,
    disabled: true,
  },
  {
    value: UsStates.Az,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ar,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ca,
    targetedByDefault: true,
  },
  {
    value: UsStates.Co,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ct,
    targetedByDefault: true,
  },
  {
    value: UsStates.Dc,
    targetedByDefault: true,
  },
  {
    value: UsStates.De,
    targetedByDefault: true,
  },
  {
    value: UsStates.Fl,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ga,
    targetedByDefault: true,
  },
  {
    value: UsStates.Hi,
    targetedByDefault: true,
  },
  {
    value: UsStates.Id,
    targetedByDefault: true,
  },
  {
    value: UsStates.Il,
    targetedByDefault: true,
  },
  {
    value: UsStates.In,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ia,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ks,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ky,
    targetedByDefault: true,
  },
  {
    value: UsStates.La,
    targetedByDefault: true,
  },
  {
    value: UsStates.Me,
    targetedByDefault: true,
  },
  {
    value: UsStates.Md,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ma,
    targetedByDefault: true,
  },
  {
    value: UsStates.Mi,
    targetedByDefault: true,
  },
  {
    value: UsStates.Mn,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ms,
    targetedByDefault: true,
  },
  {
    value: UsStates.Mo,
    targetedByDefault: true,
  },
  {
    value: UsStates.Mt,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ne,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nv,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nh,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nj,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nm,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ny,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nc,
    targetedByDefault: true,
  },
  {
    value: UsStates.Nd,
    targetedByDefault: true,
  },
  {
    value: UsStates.Oh,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ok,
    targetedByDefault: true,
  },
  {
    value: UsStates.Or,
    targetedByDefault: true,
  },
  {
    value: UsStates.Pa,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ri,
    targetedByDefault: true,
  },
  {
    value: UsStates.Sc,
    targetedByDefault: true,
  },
  {
    value: UsStates.Sd,
    targetedByDefault: true,
  },
  {
    value: UsStates.Tn,
    targetedByDefault: true,
  },
  {
    value: UsStates.Tx,
    targetedByDefault: true,
  },
  {
    value: UsStates.Ut,
    disabled: true,
  },
  {
    value: UsStates.Vt,
    targetedByDefault: true,
  },
  {
    value: UsStates.Va,
    targetedByDefault: true,
  },
  {
    value: UsStates.Wa,
    targetedByDefault: true,
  },
  {
    value: UsStates.Wv,
    targetedByDefault: true,
  },
  {
    value: UsStates.Wi,
    targetedByDefault: true,
  },
  {
    value: UsStates.Wy,
    targetedByDefault: true,
  },
]
