// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamAssetImageLabels } from './PostAdminBrandPagesIdLabelParamAssetImageLabels'
import {
  PostAdminBrandPagesIdLabelParamAssetImageLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamAssetImageLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamAssetImageLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamAssetImageLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamAssetImage
 */
export interface PostAdminBrandPagesIdLabelParamAssetImage {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamAssetImageLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamAssetImage
   */
  labels: Array<PostAdminBrandPagesIdLabelParamAssetImageLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamAssetImage
   */
  componentType: PostAdminBrandPagesIdLabelParamAssetImageComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamAssetImageComponentTypeEnum = {
  LogoImage: 'LOGO_IMAGE',
  HeroImage: 'HERO_IMAGE',
  InPageBannerImage: 'IN_PAGE_BANNER_IMAGE',
  ImageAndTextImage: 'IMAGE_AND_TEXT_IMAGE',
} as const
export type PostAdminBrandPagesIdLabelParamAssetImageComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamAssetImageComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamAssetImageComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamAssetImage interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamAssetImage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamAssetImageFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamAssetImage {
  return PostAdminBrandPagesIdLabelParamAssetImageFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamAssetImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamAssetImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamAssetImageLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamAssetImageToJSON(
  value?: PostAdminBrandPagesIdLabelParamAssetImage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(PostAdminBrandPagesIdLabelParamAssetImageLabelsToJSON),
    component_type: value.componentType,
  }
}
