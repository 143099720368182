// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostPrototypeCampaignsParamBlockItemGridDisplay
 */
export interface PostPrototypeCampaignsParamBlockItemGridDisplay {
  /**
   * id of the block
   * @type {string}
   * @memberof PostPrototypeCampaignsParamBlockItemGridDisplay
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PostPrototypeCampaignsParamBlockItemGridDisplay
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostPrototypeCampaignsParamBlockItemGridDisplay
   */
  type: PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum
  /**
   * Ordered list of products for item grid block
   * @type {Array<string>}
   * @memberof PostPrototypeCampaignsParamBlockItemGridDisplay
   */
  highlightedProductIds: Array<string>
  /**
   * List of additional products for item grid block
   * @type {Array<string>}
   * @memberof PostPrototypeCampaignsParamBlockItemGridDisplay
   */
  additionalProductIds: Array<string>
}

/**
 * @export
 */
export const PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum = {
  BlockItemGridDisplayV1: 'block_item_grid.display.v1',
} as const
export type PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum =
  typeof PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum[keyof typeof PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum]

/**
 * Check if a given object implements the PostPrototypeCampaignsParamBlockItemGridDisplay interface.
 */
export function instanceOfPostPrototypeCampaignsParamBlockItemGridDisplay(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'highlightedProductIds' in value
  isInstance = isInstance && 'additionalProductIds' in value

  return isInstance
}

export function PostPrototypeCampaignsParamBlockItemGridDisplayFromJSON(
  json: any
): PostPrototypeCampaignsParamBlockItemGridDisplay {
  return PostPrototypeCampaignsParamBlockItemGridDisplayFromJSONTyped(json, false)
}

export function PostPrototypeCampaignsParamBlockItemGridDisplayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPrototypeCampaignsParamBlockItemGridDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    highlightedProductIds: json['highlighted_product_ids'],
    additionalProductIds: json['additional_product_ids'],
  }
}

export function PostPrototypeCampaignsParamBlockItemGridDisplayToJSON(
  value?: PostPrototypeCampaignsParamBlockItemGridDisplay | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    highlighted_product_ids: value.highlightedProductIds,
    additional_product_ids: value.additionalProductIds,
  }
}
