// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutBrandPagesIdParamBlockBrandLogo,
  instanceOfPutBrandPagesIdParamBlockBrandLogo,
  PutBrandPagesIdParamBlockBrandLogoFromJSON,
  PutBrandPagesIdParamBlockBrandLogoFromJSONTyped,
  PutBrandPagesIdParamBlockBrandLogoToJSON,
} from './PutBrandPagesIdParamBlockBrandLogo'
import {
  PutBrandPagesIdParamBlockHeroBanner,
  instanceOfPutBrandPagesIdParamBlockHeroBanner,
  PutBrandPagesIdParamBlockHeroBannerFromJSON,
  PutBrandPagesIdParamBlockHeroBannerFromJSONTyped,
  PutBrandPagesIdParamBlockHeroBannerToJSON,
} from './PutBrandPagesIdParamBlockHeroBanner'
import {
  PutBrandPagesIdParamBlockImageAndText,
  instanceOfPutBrandPagesIdParamBlockImageAndText,
  PutBrandPagesIdParamBlockImageAndTextFromJSON,
  PutBrandPagesIdParamBlockImageAndTextFromJSONTyped,
  PutBrandPagesIdParamBlockImageAndTextToJSON,
} from './PutBrandPagesIdParamBlockImageAndText'
import {
  PutBrandPagesIdParamBlockProductCollection,
  instanceOfPutBrandPagesIdParamBlockProductCollection,
  PutBrandPagesIdParamBlockProductCollectionFromJSON,
  PutBrandPagesIdParamBlockProductCollectionFromJSONTyped,
  PutBrandPagesIdParamBlockProductCollectionToJSON,
} from './PutBrandPagesIdParamBlockProductCollection'
import {
  PutBrandPagesIdParamBlockProductGroupCollection,
  instanceOfPutBrandPagesIdParamBlockProductGroupCollection,
  PutBrandPagesIdParamBlockProductGroupCollectionFromJSON,
  PutBrandPagesIdParamBlockProductGroupCollectionFromJSONTyped,
  PutBrandPagesIdParamBlockProductGroupCollectionToJSON,
} from './PutBrandPagesIdParamBlockProductGroupCollection'
import {
  PutBrandPagesIdParamBlockSectionBanner,
  instanceOfPutBrandPagesIdParamBlockSectionBanner,
  PutBrandPagesIdParamBlockSectionBannerFromJSON,
  PutBrandPagesIdParamBlockSectionBannerFromJSONTyped,
  PutBrandPagesIdParamBlockSectionBannerToJSON,
} from './PutBrandPagesIdParamBlockSectionBanner'
import {
  PutBrandPagesIdParamBlockSpotlightProductCollection,
  instanceOfPutBrandPagesIdParamBlockSpotlightProductCollection,
  PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSON,
  PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSONTyped,
  PutBrandPagesIdParamBlockSpotlightProductCollectionToJSON,
} from './PutBrandPagesIdParamBlockSpotlightProductCollection'

/**
 * @type PutBrandPagesIdParamBrandPageBlocks
 *
 * @export
 */
export type PutBrandPagesIdParamBrandPageBlocks =
  | ({ discriminator: 'brand_logo.evergreen.v1' } & PutBrandPagesIdParamBlockBrandLogo)
  | ({ discriminator: 'hero_banner.evergreen.v1' } & PutBrandPagesIdParamBlockHeroBanner)
  | ({ discriminator: 'image_and_text.evergreen.v1' } & PutBrandPagesIdParamBlockImageAndText)
  | ({
      discriminator: 'product_collection.evergreen.v1'
    } & PutBrandPagesIdParamBlockProductCollection)
  | ({
      discriminator: 'product_group_collection.evergreen.v1'
    } & PutBrandPagesIdParamBlockProductGroupCollection)
  | ({ discriminator: 'section_banner.evergreen.v1' } & PutBrandPagesIdParamBlockSectionBanner)
  | ({
      discriminator: 'spotlight_product_collection.evergreen.v1'
    } & PutBrandPagesIdParamBlockSpotlightProductCollection)

export function PutBrandPagesIdParamBrandPageBlocksFromJSON(
  json: any
): PutBrandPagesIdParamBrandPageBlocks {
  return PutBrandPagesIdParamBrandPageBlocksFromJSONTyped(json, false)
}

export function PutBrandPagesIdParamBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockBrandLogoFromJSONTyped(json, true),
        discriminator: 'brand_logo.evergreen.v1',
      }
    case 'hero_banner.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockHeroBannerFromJSONTyped(json, true),
        discriminator: 'hero_banner.evergreen.v1',
      }
    case 'image_and_text.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockImageAndTextFromJSONTyped(json, true),
        discriminator: 'image_and_text.evergreen.v1',
      }
    case 'product_collection.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockProductCollectionFromJSONTyped(json, true),
        discriminator: 'product_collection.evergreen.v1',
      }
    case 'product_group_collection.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockProductGroupCollectionFromJSONTyped(json, true),
        discriminator: 'product_group_collection.evergreen.v1',
      }
    case 'section_banner.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockSectionBannerFromJSONTyped(json, true),
        discriminator: 'section_banner.evergreen.v1',
      }
    case 'spotlight_product_collection.evergreen.v1':
      return {
        ...PutBrandPagesIdParamBlockSpotlightProductCollectionFromJSONTyped(json, true),
        discriminator: 'spotlight_product_collection.evergreen.v1',
      }
    default:
      throw new Error(
        `No variant of PutBrandPagesIdParamBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PutBrandPagesIdParamBrandPageBlocksToJSON(
  value?: PutBrandPagesIdParamBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return PutBrandPagesIdParamBlockBrandLogoToJSON(value)
    case 'hero_banner.evergreen.v1':
      return PutBrandPagesIdParamBlockHeroBannerToJSON(value)
    case 'image_and_text.evergreen.v1':
      return PutBrandPagesIdParamBlockImageAndTextToJSON(value)
    case 'product_collection.evergreen.v1':
      return PutBrandPagesIdParamBlockProductCollectionToJSON(value)
    case 'product_group_collection.evergreen.v1':
      return PutBrandPagesIdParamBlockProductGroupCollectionToJSON(value)
    case 'section_banner.evergreen.v1':
      return PutBrandPagesIdParamBlockSectionBannerToJSON(value)
    case 'spotlight_product_collection.evergreen.v1':
      return PutBrandPagesIdParamBlockSpotlightProductCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PutBrandPagesIdParamBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
