import { rawObjAccess, snakeCased } from 'common/utils'
import { AnalyticEvents } from './events'

const EVENT_NAME_CSS = 'color: #43B02A; font-style: italic; font-weight: normal'

// Loosely based on
// https://github.com/instacart/carrot/blob/master/customers/instacart/app/assets/javascripts/analytics/Analytics.js
//

export type AnalyticsParams = Record<string, unknown>
export type AnalyticsEvent = string | keyof typeof AnalyticEvents

export type TrackFunctionType = (event: string, params: AnalyticsParams) => void
export type IdentifyFunctionType = (id: string, traits: AnalyticsParams) => void

export interface AnalyticsProviderImpl {
  track: TrackFunctionType
  identify?: IdentifyFunctionType
}

class Analytics {
  isDebug = false

  commonParams: AnalyticsParams = {}

  userParams: AnalyticsParams = {}

  pageParams: AnalyticsParams = {}

  analyticProviders: AnalyticsProviderImpl[] = []

  mergeParams(eventParams: AnalyticsParams): AnalyticsParams {
    // Remove the  rawObjAccess Symbol created by snakeCased() as segment
    // is not sending events when passed objects with circular dependency.
    const { [rawObjAccess]: _, ...mergedParams } = snakeCased({
      ...this.commonParams,
      ...this.userParams,
      ...this.pageParams,
      ...eventParams,
    })
    return mergedParams
  }

  setAnalyticsProvider(analytics: AnalyticsProviderImpl) {
    this.analyticProviders.push(analytics)
  }

  removeAnalyticsProvider(analytics: AnalyticsProviderImpl) {
    const i = this.analyticProviders.indexOf(analytics)
    if (i !== -1) {
      this.analyticProviders.slice(i, 1)
    }
  }

  track(event: AnalyticsEvent, eventParams: AnalyticsParams = {}) {
    const mergedParams = this.mergeParams(eventParams)

    if (this.isDebug) {
      console.log('%c🥑 %s: %O', EVENT_NAME_CSS, event, mergedParams)
    }

    this.analyticProviders.forEach((provider: AnalyticsProviderImpl) =>
      provider.track(event, mergedParams)
    )
  }

  identify(id: string, traits: AnalyticsParams = {}) {
    this.analyticProviders.forEach((provider: AnalyticsProviderImpl) => {
      if (provider.identify) {
        provider.identify(id, traits)
      }
    })
  }

  setCommonParams(params: AnalyticsParams) {
    this.commonParams = params
  }

  mergeCommonParams(params: AnalyticsParams) {
    this.commonParams = { ...this.commonParams, ...params }
  }

  setUserParams(params: AnalyticsParams) {
    this.userParams = params
  }

  setPageParams(params: AnalyticsParams) {
    this.pageParams = params
  }

  setDebug(isDebug: boolean) {
    this.isDebug = isDebug
  }

  getUserParams() {
    return this.userParams
  }

  getCommonParams() {
    return this.commonParams
  }
}

export default new Analytics()
