// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetTargetingOptionsParamDataAttributesCategoriesListSubcategories } from './GetTargetingOptionsParamDataAttributesCategoriesListSubcategories'
import {
  GetTargetingOptionsParamDataAttributesCategoriesListSubcategoriesFromJSON,
  GetTargetingOptionsParamDataAttributesCategoriesListSubcategoriesFromJSONTyped,
  GetTargetingOptionsParamDataAttributesCategoriesListSubcategoriesToJSON,
} from './GetTargetingOptionsParamDataAttributesCategoriesListSubcategories'

/**
 *
 * @export
 * @interface GetTargetingOptionsParamDataAttributesCategoriesList
 */
export interface GetTargetingOptionsParamDataAttributesCategoriesList {
  /**
   * Category option name
   * @type {string}
   * @memberof GetTargetingOptionsParamDataAttributesCategoriesList
   */
  categoryName: string
  /**
   * Sub category options
   * @type {Array<GetTargetingOptionsParamDataAttributesCategoriesListSubcategories>}
   * @memberof GetTargetingOptionsParamDataAttributesCategoriesList
   */
  subcategories?: Array<GetTargetingOptionsParamDataAttributesCategoriesListSubcategories>
}

/**
 * Check if a given object implements the GetTargetingOptionsParamDataAttributesCategoriesList interface.
 */
export function instanceOfGetTargetingOptionsParamDataAttributesCategoriesList(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'categoryName' in value

  return isInstance
}

export function GetTargetingOptionsParamDataAttributesCategoriesListFromJSON(
  json: any
): GetTargetingOptionsParamDataAttributesCategoriesList {
  return GetTargetingOptionsParamDataAttributesCategoriesListFromJSONTyped(json, false)
}

export function GetTargetingOptionsParamDataAttributesCategoriesListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTargetingOptionsParamDataAttributesCategoriesList {
  if (json === undefined || json === null) {
    return json
  }
  return {
    categoryName: json['category_name'],
    subcategories: !exists(json, 'subcategories')
      ? undefined
      : (json['subcategories'] as Array<any>).map(
          GetTargetingOptionsParamDataAttributesCategoriesListSubcategoriesFromJSON
        ),
  }
}

export function GetTargetingOptionsParamDataAttributesCategoriesListToJSON(
  value?: GetTargetingOptionsParamDataAttributesCategoriesList | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    category_name: value.categoryName,
    subcategories:
      value.subcategories === undefined
        ? undefined
        : (value.subcategories as Array<any>).map(
            GetTargetingOptionsParamDataAttributesCategoriesListSubcategoriesToJSON
          ),
  }
}
