import { useEffect, useRef } from 'react'

// usePropRef keeps track of the most recent value of its parameter in a Ref object.
// A common use case is for keeping an up to date reference of event handler/callback props without having to constantly
// recompute downstream dependencies when the event handler/callback is changed
export function usePropRef<T>(prop: T) {
  const ref = useRef<T>(prop)
  useEffect(() => {
    ref.current = prop
  }, [prop])
  return ref
}
