// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerLiftVariantInputDateRange } from './ApiReportsControllerLiftVariantInputDateRange'
import {
  ApiReportsControllerLiftVariantInputDateRangeFromJSON,
  ApiReportsControllerLiftVariantInputDateRangeFromJSONTyped,
  ApiReportsControllerLiftVariantInputDateRangeToJSON,
} from './ApiReportsControllerLiftVariantInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerLiftVariantInput
 */
export interface ApiReportsControllerLiftVariantInput {
  /**
   *
   * @type {ApiReportsControllerLiftVariantInputDateRange}
   * @memberof ApiReportsControllerLiftVariantInput
   */
  dateRange?: ApiReportsControllerLiftVariantInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerLiftVariantInput
   */
  segment?: ApiReportsControllerLiftVariantInputSegmentEnum
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerLiftVariantInput
   */
  name?: string
  /**
   * Send this parameter with the ID of the experiment to export data for.
   * @type {string}
   * @memberof ApiReportsControllerLiftVariantInput
   */
  experimentId?: string
}

/**
 * @export
 */
export const ApiReportsControllerLiftVariantInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerLiftVariantInputSegmentEnum =
  typeof ApiReportsControllerLiftVariantInputSegmentEnum[keyof typeof ApiReportsControllerLiftVariantInputSegmentEnum]

/**
 * Check if a given object implements the ApiReportsControllerLiftVariantInput interface.
 */
export function instanceOfApiReportsControllerLiftVariantInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerLiftVariantInputFromJSON(
  json: any
): ApiReportsControllerLiftVariantInput {
  return ApiReportsControllerLiftVariantInputFromJSONTyped(json, false)
}

export function ApiReportsControllerLiftVariantInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerLiftVariantInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerLiftVariantInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    name: !exists(json, 'name') ? undefined : json['name'],
    experimentId: !exists(json, 'experiment_id') ? undefined : json['experiment_id'],
  }
}

export function ApiReportsControllerLiftVariantInputToJSON(
  value?: ApiReportsControllerLiftVariantInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerLiftVariantInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    name: value.name,
    experiment_id: value.experimentId,
  }
}
