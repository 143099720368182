import { HTMLProps, ReactNode } from 'react'
import { ButtonProps, LinkButtonProps } from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'

type BannerVariants = 'system' | 'contextual' | 'contextual-marginless'
export type IconPosition = 'top' | 'middle'

// eslint-disable-next-line no-restricted-syntax
export enum BannerType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  NEW = 'new',
}

export interface BannerMessage {
  message?: ReactNode
  header?: ReactNode
}

interface ButtonTestId {
  'data-testid'?: string
}
export interface BannerActionButtonsProps {
  primaryButtonLabel?: GenericMessageDescriptor
  primaryButtonProps?: ButtonProps & ButtonTestId
  primaryLinkButtonProps?: LinkButtonProps & ButtonTestId
  secondaryButtonLabel?: GenericMessageDescriptor
  secondaryButtonProps?: ButtonProps & ButtonTestId
  secondaryLinkButtonProps?: LinkButtonProps & ButtonTestId
  isSecondary?: boolean
}

export interface BannerNotificationProps extends HTMLProps<HTMLDivElement> {
  type: BannerType
  message: BannerMessage
  actionButtons?: React.ReactNode
  actionButtonsProps?: BannerActionButtonsProps
  iconOverride?: ReactNode
  iconPosition?: IconPosition
  variant?: BannerVariants
  // uniq id saved in local storage to remember banner being dimissed
  dismissId?: string
  testId?: string
  dismiss?: () => void
  noBorder?: boolean
}

export interface GroupedBannerNotificationProps extends Pick<BannerNotificationProps, 'type'> {
  groupedBanners: BannerNotificationProps[]
}
