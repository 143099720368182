// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot
 */
export interface PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot {
  /**
   * The approval decision for the entity
   * @type {string}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot
   */
  decision?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum
  /**
   *
   * @type {PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions}
   * @memberof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot
   */
  fieldDecisions?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
}

/**
 * @export
 */
export const PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum =
  {
    Unknown: 'DECISION_UNKNOWN',
    Approved: 'DECISION_APPROVED',
    Rejected: 'DECISION_REJECTED',
    ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
  } as const
export type PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum =
  typeof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum[keyof typeof PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum]

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
  json: any
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot {
  return PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    fieldDecisions: !exists(json, 'field_decisions')
      ? undefined
      : PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
          json['field_decisions']
        ),
  }
}

export function PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
  value?: PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    field_decisions:
      PutUniversalAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
        value.fieldDecisions
      ),
  }
}
