import { CSSProperties, ComponentProps } from 'react'
import { BorderlessButton } from '../ids-ads'

const styles: CSSProperties = {
  padding: 0,
  fontWeight: 'normal',
  height: 'auto',
  border: 'none',
  lineHeight: 'inherit',
}

export type LinkButtonProps = Omit<ComponentProps<typeof BorderlessButton>, 'compact'>

/** @deprecated Use BorderlessButton from 'components/ids-ads' directly instead */
export default function LinkButton({ children, ...props }: LinkButtonProps) {
  return (
    <BorderlessButton type="button" compact {...props} styles={styles}>
      {children}
    </BorderlessButton>
  )
}
