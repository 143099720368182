import { cloneDeep } from 'lodash'
import { getTaskIdFromTaskUrl } from 'components/Reporting/utils'
import { BrandPagesApi } from 'service/apis/BrandPagesApi'
import { TasksApi } from 'service/apis/TasksApi'
import { ApiBrandPagesControllerGenerateInputRetrievalMethodEnum } from 'service/openapi/__codegen__/models/ApiBrandPagesControllerGenerateInput'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage,
} from 'service/openapi/__codegen__/models/ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage'
import {
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageTypeEnum,
} from 'service/openapi/__codegen__/models/ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage'
import { GetAdminBrandPagesIdParamBlockHeroBannerAssets } from 'service/openapi/__codegen__/models/GetAdminBrandPagesIdParamBlockHeroBannerAssets'
import { PostDisplayAdGroupsParamBlockHeroBannerDisplayTypeEnum } from 'service/openapi/__codegen__/models/PostDisplayAdGroupsParamBlockHeroBannerDisplay'
import { PostDisplayAdGroupsParamBlockItemGridDisplayTypeEnum } from 'service/openapi/__codegen__/models/PostDisplayAdGroupsParamBlockItemGridDisplay'
import { PostPrototypeCampaignsParamBlockHeroBannerDisplayTypeEnum } from 'service/openapi/__codegen__/models/PostPrototypeCampaignsParamBlockHeroBannerDisplay'
import { PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum } from 'service/openapi/__codegen__/models/PostPrototypeCampaignsParamBlockItemGridDisplay'
import { PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage } from 'service/openapi/__codegen__/models/PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage'
import { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage } from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage'
import { Product } from 'service/types'
import { bannerFieldToPayload, isBannerFieldEmpty } from '../../utils/utils'
import { BrandPageFormFields } from './brandPage.types'

const BLOCK_HERO_BANNER_DISPLAY_TYPES = {
  discriminator: 'block_hero_banner.v1',
  type: PostDisplayAdGroupsParamBlockHeroBannerDisplayTypeEnum.BlockHeroBannerV1,
} as const
const BLOCK_ITEM_GRID_DISPLAY_TYPES = {
  discriminator: 'block_item_grid.display.v1',
  type: PostDisplayAdGroupsParamBlockItemGridDisplayTypeEnum.BlockItemGridDisplayV1,
} as const
const BLOCK_HERO_BANNER_PROTOTYPE_TYPES = {
  discriminator: 'block_hero_banner.v1',
  type: PostPrototypeCampaignsParamBlockHeroBannerDisplayTypeEnum.BlockHeroBannerV1,
} as const
const BLOCK_ITEM_GRID_PROTOTYPE_TYPES = {
  discriminator: 'block_item_grid.display.v1',
  type: PostPrototypeCampaignsParamBlockItemGridDisplayTypeEnum.BlockItemGridDisplayV1,
} as const

export const initialFormState: BrandPageFormFields = {
  id: undefined,
  title: '',
  highlightedProductIds: [],
  additionalProductIds: [],
  blockTypeToIdMap: {},
  heroBanner: {
    alt: '',
    mobile: {
      id: '',
      urls: {
        previewUrl: undefined,
        uploadedUri: undefined,
      },
    },
    desktop: {
      id: '',
      urls: {
        previewUrl: undefined,
        uploadedUri: undefined,
      },
    },
  },
}

type DisplayBrandPageBlocks = Pick<
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage,
  'blocks'
>['blocks']

type PrototypeBrandPageBlocks = Pick<
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage,
  'blocks'
>['blocks']

function productFieldToItemGrid(
  { highlightedProductIds, additionalProductIds }: BrandPageFormFields,
  skipErrors = false
) {
  if (!skipErrors && highlightedProductIds.length === 0) {
    throw new Error('A non-empty list of highlighted product IDs must be provided')
  }

  return {
    highlightedProductIds,
    additionalProductIds,
  }
}

function fieldsToDisplayBlocks(
  fields: BrandPageFormFields,
  skipErrors = false
): DisplayBrandPageBlocks {
  const blocks: DisplayBrandPageBlocks = []

  const shouldCreateBanner = !isBannerFieldEmpty(fields.heroBanner)

  if (shouldCreateBanner) {
    try {
      const banner = bannerFieldToPayload(fields.heroBanner, skipErrors)
      blocks.push({
        ...BLOCK_HERO_BANNER_DISPLAY_TYPES,
        id: fields.blockTypeToIdMap[BLOCK_HERO_BANNER_DISPLAY_TYPES.discriminator],
        assets: {
          ...banner,
        },
      })
    } catch (e) {
      // TODO: https://instacart.atlassian.net/browse/ADS-13512
      throw e
    }
  }

  try {
    const itemGrid = productFieldToItemGrid(fields, skipErrors)

    blocks.push({
      ...BLOCK_ITEM_GRID_DISPLAY_TYPES,
      id: fields.blockTypeToIdMap[BLOCK_ITEM_GRID_DISPLAY_TYPES.discriminator],
      ...itemGrid,
    })
  } catch (e) {
    // TODO: https://instacart.atlassian.net/browse/ADS-13512
    throw e
  }

  return blocks
}

function fieldsToPrototypeBlocks(
  fields: BrandPageFormFields,
  skipErrors = false
): PrototypeBrandPageBlocks {
  const blocks: PrototypeBrandPageBlocks = []

  const shouldCreateBanner = fields.heroBanner && !isBannerFieldEmpty(fields.heroBanner)

  if (shouldCreateBanner) {
    try {
      const banner = bannerFieldToPayload(fields.heroBanner, skipErrors)
      blocks.push({
        ...BLOCK_HERO_BANNER_PROTOTYPE_TYPES,
        id: fields.blockTypeToIdMap[BLOCK_HERO_BANNER_PROTOTYPE_TYPES.discriminator],
        assets: {
          ...banner,
        },
      })
    } catch (e) {
      // TODO: https://instacart.atlassian.net/browse/ADS-13512
      throw e
    }
  }

  try {
    const itemGrid = productFieldToItemGrid(fields, skipErrors)

    blocks.push({
      ...BLOCK_ITEM_GRID_PROTOTYPE_TYPES,
      id: fields.blockTypeToIdMap[BLOCK_ITEM_GRID_PROTOTYPE_TYPES.discriminator],
      ...itemGrid,
    })
  } catch (e) {
    // TODO: https://instacart.atlassian.net/browse/ADS-13512
    throw e
  }

  return blocks
}

function brandPageToDisplayFields({
  id,
  title,
  blocks,
}: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage): BrandPageFormFields {
  const fields: BrandPageFormFields = cloneDeep(initialFormState)
  fields.id = id
  fields.title = title
  fields.blockTypeToIdMap = {}

  blocks.forEach(block => {
    fields.blockTypeToIdMap[block.type] = block.id

    if (block.type === BLOCK_ITEM_GRID_DISPLAY_TYPES.discriminator) {
      fields.highlightedProductIds = block.highlightedProductIds
      fields.additionalProductIds = block.additionalProductIds || []
    }

    if (block.type === BLOCK_HERO_BANNER_DISPLAY_TYPES.discriminator) {
      fields.heroBanner.alt = block.assets.desktop.altText ?? block.assets.mobile.altText
      fields.heroBanner.mobile = extractAssetInitialValue(block.assets.mobile)
      fields.heroBanner.desktop = extractAssetInitialValue(block.assets.desktop)
    }
  })

  return fields
}

export function toDisplayAdGroup(
  formPayload: BrandPageFormFields,
  skipErrors = false
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage {
  const blocks = fieldsToDisplayBlocks(formPayload, skipErrors)

  return {
    title: formPayload.title,
    type: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageTypeEnum.DisplayLandingPageV1,
    blocks,
  }
}

export function toPrototypeAdGroup(
  formPayload: BrandPageFormFields,
  skipErrors = false
): ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage {
  const blocks = fieldsToPrototypeBlocks(formPayload, skipErrors)

  return {
    title: formPayload.title,
    type: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageTypeEnum.DisplayLandingPageV1,
    blocks,
  }
}

export function fromDisplayAdGroup(
  brandPagePayload: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage | undefined
): BrandPageFormFields | undefined {
  if (!brandPagePayload) return

  return brandPageToDisplayFields(brandPagePayload)
}

export function fromPrototypeAdGroup(
  brandPagePayload: PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage | undefined
): BrandPageFormFields | undefined {
  if (!brandPagePayload) return

  const fields: BrandPageFormFields = cloneDeep(initialFormState)
  fields.id = brandPagePayload.id
  fields.title = brandPagePayload.title
  fields.blockTypeToIdMap = {}

  brandPagePayload.blocks.forEach(block => {
    fields.blockTypeToIdMap[block.type] = block.id

    if (block.type === BLOCK_ITEM_GRID_DISPLAY_TYPES.discriminator) {
      fields.highlightedProductIds = block.highlightedProductIds
      fields.additionalProductIds = block.additionalProductIds || []
    }

    if (block.type === BLOCK_HERO_BANNER_DISPLAY_TYPES.discriminator) {
      fields.heroBanner.alt = block.assets.desktop.altText ?? block.assets.mobile.altText
      fields.heroBanner.mobile = extractAssetInitialValue(block.assets.mobile)
      fields.heroBanner.desktop = extractAssetInitialValue(block.assets.desktop)
    }
  })

  return fields
}

export function extractAssetInitialValue(
  asset: GetAdminBrandPagesIdParamBlockHeroBannerAssets[keyof GetAdminBrandPagesIdParamBlockHeroBannerAssets]
) {
  return {
    id: asset?.id,
    alt: asset?.altText,
    urls: {
      previewUrl: asset?.previewUrl,
      uploadedUri: asset?.uploadedUri,
    },
  }
}

export interface GenerateBrandPageResult {
  status: string
  brandPageId?: string | null
}

export const waitForBrandPageGeneration = async (
  taskId: string
): Promise<GenerateBrandPageResult> => {
  return new Promise<GenerateBrandPageResult>(async (resolve, reject) => {
    try {
      const pollInterval = setInterval(async () => {
        const result = await TasksApi.getTasksId({ id: taskId })

        if (result.meta.status === 200) {
          if (result.data.attributes.taskStatus === 'failed') {
            clearInterval(pollInterval)
            if (result.data.attributes.error?.message === 'already_exists_in_previous') {
              resolve({
                status: 'failed',
              })
            } else {
              resolve({ status: 'failed' })
            }
          } else if (result.data.attributes.taskStatus === 'completed') {
            clearInterval(pollInterval)
            resolve({
              status: 'completed',
              brandPageId: result.data.attributes.payload?.result.brand_page_id,
            })
          }
        } else if (result.meta.status >= 400) {
          clearInterval(pollInterval)
          resolve({ status: 'failed' })
        }
      }, 1000)
    } catch (err) {
      return reject(err)
    }
  })
}

export const productsToProductIds = (products: Product[]) =>
  products.map(product => product.attributes.productId)

export const generateBrandPage = async (
  productIds: string[],
  retrievalMethod: ApiBrandPagesControllerGenerateInputRetrievalMethodEnum
): Promise<{ status: string; brandPageId?: string }> => {
  const { links } = await BrandPagesApi.postBrandPagesGenerate({
    body: {
      productIds,
      retrievalMethod,
    },
  })
  const taskId = getTaskIdFromTaskUrl(links?.taskUrl)
  if (taskId) {
    const result = await waitForBrandPageGeneration(taskId)
    if (result.status === 'completed' && result.brandPageId !== null) {
      return { brandPageId: result.brandPageId, status: result.status }
    }
    if (result.status === 'failed') {
      return { status: result.status }
    }
  }
  return { status: 'unkown' }
}
