import { css } from '@emotion/react'
import { ChevronLeftIcon, ChevronRightIcon, spacing, useTheme } from '@instacart/ids-core'
import { range } from 'lodash'
import { CSSProperties, FormEvent, useEffect, useMemo, useState } from 'react'
import useIntl from 'common/useIntl'
import FilterSelect, { Option } from 'components/FilterSelect'
import FormattedMessage from 'components/FormattedMessage'
import { SlimInput, makeButton } from 'components/ids-ads/deprecated'

const PAGE_DROPDOWN_MAX = 250

const ITEMS_PER_PAGE_RANGE = [25, 50]

export interface TablePaginationProps {
  currentPage: number
  totalPages: number
  totalItems: number
  itemsPerPage: number
  onPreviousPage(): void
  onNextPage(): void
  onSetPage(page: number): void
  customItemPerPageOptions?: number[]
  useCustomItemsPerPage?: boolean
  onSetItemsPerPage?(page: number): void
}

function useStyles() {
  const theme = useTheme()

  const sectionCommon: CSSProperties = {
    flex: 1,
    width: 'calc(100% / 3)',
  }

  return {
    root: css({
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: spacing.s12,
      padding: `${spacing.s12}px 0`,
      borderTop: `1px solid ${theme.colors.systemGrayscale10}`,
      color: theme.colors.systemGrayscale70,
      background: theme.colors.systemGrayscale00,
      ...theme.typography.bodyMedium2,
    }),
    leftSection: css({
      ...sectionCommon,
      display: 'flex',
      alignItems: 'center',
      gap: spacing.s8,
      width: 'calc(100% / 3)',
    }),
    centerSection: css({
      ...sectionCommon,
      textAlign: 'center',
    }),
    rightSection: css({
      ...sectionCommon,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: spacing.s8,
    }),
    buttons: css({
      whiteSpace: 'nowrap',
    }),
    splitButton: css({
      '&:not(:first-of-type):not(:last-of-type)': {
        borderRadius: 0,
      },
      '&:not(:first-of-type)': {
        borderLeft: 'none',
      },
      '&:first-of-type': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:last-of-type': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    }),
    previousPageButton: css({
      'svg > path': {
        transform: 'translateX(10%)',
      },
    }),
    nextPageButton: css({
      'svg > path': {
        transform: 'translateX(-10%)',
      },
    }),
  }
}

const SplitButton = makeButton('SplitButton', {
  normalBackground: 'systemGrayscale00',
  borderColor: 'systemGrayscale30',
  textColor: 'systemGrayscale50',
  hoverBackground: 'systemGrayscale10',
  activeBackground: 'systemGrayscale20',
  disabledBackground: 'systemGrayscale00',
  disabledBorderColor: 'systemGrayscale30',
  disabledTextColor: 'systemGrayscale30',
})

export function TablePagination({
  currentPage,
  itemsPerPage,
  totalItems,
  totalPages,
  onPreviousPage,
  onNextPage,
  onSetPage,
  onSetItemsPerPage,
  customItemPerPageOptions,
  useCustomItemsPerPage,
}: TablePaginationProps) {
  const intl = useIntl()
  const styles = useStyles()
  const [pageInputValue, setPageInputValue] = useState(() => currentPage.toString())

  const startOffset = itemsPerPage * (currentPage - 1)

  const shouldUsePageDropdown = totalPages <= PAGE_DROPDOWN_MAX

  const pageOptions: Option<number>[] = useMemo(
    () =>
      shouldUsePageDropdown
        ? range(1, totalPages + 1).map(p => ({ value: p, label: p.toString() }))
        : [],
    [totalPages, shouldUsePageDropdown]
  )

  useEffect(() => {
    setPageInputValue(currentPage.toString())
  }, [currentPage])

  const handlePageInputSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    const newPage = parseInt(pageInputValue, 10)
    if (Number.isNaN(newPage)) {
      setPageInputValue(currentPage.toString())
    } else {
      onSetPage(newPage)
    }
  }

  return (
    <div css={styles.root} data-testid="table-pagination-footer">
      <div css={styles.leftSection}>
        {useCustomItemsPerPage ? (
          <>
            <FormattedMessage id="components.tablePagination.numOfItemsPerPage" />
            <FilterSelect
              options={(customItemPerPageOptions || ITEMS_PER_PAGE_RANGE).map(p => ({
                value: p,
                label: p.toString(),
              }))}
              value={[itemsPerPage]}
              onChange={([num]) => {
                if (onSetItemsPerPage !== undefined) onSetItemsPerPage(num as number)
              }}
              placeholderId="common.select"
              isMultiSelect={false}
              showClear={false}
              buttonStyles={{ button: { minWidth: 'auto' } }}
              menuPosition="top"
            />
          </>
        ) : (
          <FormattedMessage
            id="components.tablePagination.itemsPerPage"
            values={{ itemsPerPage }}
          />
        )}
      </div>
      <div css={styles.centerSection}>
        <FormattedMessage
          id="components.tablePagination.items"
          values={{
            minItem: startOffset + 1,
            maxItem: Math.min(startOffset + itemsPerPage, totalItems),
            totalItems,
          }}
        />
      </div>
      <div css={styles.rightSection}>
        {shouldUsePageDropdown ? (
          <FilterSelect
            options={pageOptions}
            value={[Math.min(currentPage, totalPages)]}
            onChange={([page]) => onSetPage(page)}
            placeholderId="common.selectPage"
            isMultiSelect={false}
            showClear={false}
            buttonStyles={{ button: { minWidth: 'auto' } }}
            menuPosition="top"
          />
        ) : (
          <form onSubmit={handlePageInputSubmit}>
            <SlimInput
              type="number"
              value={pageInputValue}
              onChange={e => setPageInputValue(e.currentTarget?.value)}
              onBlur={() => handlePageInputSubmit()}
              min={1}
              max={totalPages}
            />
          </form>
        )}
        <p>
          <FormattedMessage
            id="components.tablePagination.totalPages"
            values={{
              totalPages,
            }}
          />
        </p>
        <div css={styles.buttons}>
          <SplitButton
            type="button"
            title={intl.formatMessage({ id: 'common.previousPage' })}
            icon={ChevronLeftIcon}
            onClick={onPreviousPage}
            disabled={currentPage === 1}
            iconSize={18}
            css={[styles.splitButton, styles.previousPageButton]}
          />
          <SplitButton
            type="button"
            title={intl.formatMessage({ id: 'common.nextPage' })}
            icon={ChevronRightIcon}
            onClick={onNextPage}
            disabled={currentPage >= totalPages}
            iconSize={18}
            css={[styles.splitButton, styles.nextPageButton]}
          />
        </div>
      </div>
    </div>
  )
}
