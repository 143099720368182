import { css } from '@emotion/react'
import { ChevronRightIcon, Text, Theme, useTheme } from '@instacart/ids-core'
import { capitalize } from 'lodash'
import { useContext } from 'react'
import Checkbox from 'components/CheckboxV2'
import { CategoriesViewContext, TargetingRuleContext, useCategoryTargetingCheckbox } from '../hooks'
import { CategoryTreeNode } from '../utils'
import SubcategoriesCount from './SubcategoriesCount'

const mainContainerStyle = (theme: Theme, isHighlighted: boolean, isLevel4: boolean) =>
  css({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    cursor: isLevel4 ? undefined : 'pointer',
    '&:hover': {
      backgroundColor: isLevel4
        ? undefined
        : isHighlighted
        ? theme.colors.systemGrayscale20
        : theme.colors.systemGrayscale10,
    },
    '&:hover > svg': {
      display: 'block',
    },
    backgroundColor: isHighlighted ? theme.colors.systemGrayscale20 : undefined,
  })

const childCategoryContainerStyle = (isLevel4: boolean) =>
  css({
    display: 'flex',
    alignContent: 'center',
    padding: isLevel4 ? undefined : 8,
  })

const clickableCheckboxContainerStyle = css({
  display: 'flex',
  alignContent: 'center',
  cursor: 'pointer',
})

const displayNameTextStyle = (theme: Theme, isHighlighted: boolean) => ({
  color: theme.colors.systemGrayscale70,
  fontWeight: isHighlighted ? 600 : 400,
  maxWidth: 150,
  cursor: 'pointer',
})

const expandIconStyle = (isHighlighted: boolean) =>
  css({
    marginRight: 12,
    marginTop: 12,
    display: isHighlighted ? 'block' : 'none',
  })

interface ChildCategoryProps {
  node: CategoryTreeNode
  isLevel4: boolean
}

export default function ChildCategory({ node, isLevel4 }: ChildCategoryProps) {
  const theme = useTheme()
  const { isReadOnly } = useContext(TargetingRuleContext)

  const { highlightedL2Node, setHighlightedL2Node } = useContext(CategoriesViewContext)

  const { checkboxState, handleCheckboxClick } = useCategoryTargetingCheckbox(node, isReadOnly)

  const isHighlighted = node.id === highlightedL2Node?.id
  const parentPrefix = `${capitalize(node.parent?.displayName)}: `

  return (
    <div
      css={mainContainerStyle(theme, isHighlighted, isLevel4)}
      role="button"
      tabIndex={0}
      onClick={e => {
        e.preventDefault()
        return !isLevel4 && setHighlightedL2Node(node)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault()
          return !isLevel4 && setHighlightedL2Node(node)
        }
      }}
    >
      <div css={childCategoryContainerStyle(isLevel4)}>
        <div
          css={clickableCheckboxContainerStyle}
          role="button"
          tabIndex={0}
          onClick={e => {
            e.preventDefault()
            handleCheckboxClick()
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleCheckboxClick()
            }
          }}
        >
          <Checkbox id="category" checked={checkboxState} disabled={isReadOnly}>
            <Text typography="bodyMedium2" style={displayNameTextStyle(theme, isHighlighted)}>
              {`${isLevel4 ? parentPrefix : ''}${capitalize(node.displayName)}`}
            </Text>
          </Checkbox>
        </div>
        <SubcategoriesCount node={node} />
      </div>
      {!isLevel4 && (
        <ChevronRightIcon
          size={16}
          css={expandIconStyle(isHighlighted)}
          color={isHighlighted ? 'systemGrayscale70' : 'systemGrayscale50'}
        />
      )}
    </div>
  )
}
