import { ButtonBase, Variant, spacing, StylesOf, useTheme, SVGIconProps } from '@instacart/ids-core'
import { ComponentType, ForwardedRef, forwardRef, ReactNode } from 'react'
import toPx from 'common/toPx'
import { DEFAULT_ICON_SIZE, UseStylesInternal, UseButtonStylesOpts } from '.'

function useButtonStyles({
  borderColor,
  textColor,
  normalBackground,
  hoverBackground,
  activeBackground,
  disabledBackground,
  disabledTextColor = textColor,
  disabledBorderColor,
  iconOnly,
  iconSize,
  baseButtonStyleOverrides,
}: UseButtonStylesOpts & UseStylesInternal): StylesOf<typeof ButtonBase> {
  const theme = useTheme()

  return {
    button: {
      display: 'inline-flex',
      backgroundColor: normalBackground ? theme.colors[normalBackground] : 'transparent',
      color: theme.colors[textColor],
      border: `1px solid ${borderColor ? theme.colors[borderColor] : 'transparent'}`,
      borderRadius: theme.radius.r4,
      padding: iconOnly ? spacing.s4 : toPx`${spacing.s4} ${spacing.s8}`,
    },
    content: {
      display: 'inline-flex',
      alignItems: 'center',
      ...theme.typography.bodyMedium1,
      svg: {
        fill: 'currentColor',
        marginLeft: iconOnly ? 0 : spacing.s12,
        padding: (DEFAULT_ICON_SIZE - iconSize) / 2,
      },
    },
    focusRing: {
      left: -1,
      top: -1,
      right: -1,
      bottom: -1,
      borderRadius: theme.radius.r4,
    },
    hover: {
      backgroundColor: hoverBackground ? theme.colors[hoverBackground] : 'transparent',
    },
    active: {
      backgroundColor: activeBackground ? theme.colors[activeBackground] : 'transparent',
    },
    disabled: {
      backgroundColor: disabledBackground ? theme.colors[disabledBackground] : 'transparent',
      borderColor: disabledBorderColor ? theme.colors[disabledBorderColor] : 'transparent',
      color: theme.colors[disabledTextColor],
    },
    ...(baseButtonStyleOverrides && baseButtonStyleOverrides),
  }
}

export interface ButtonProps extends Variant<typeof ButtonBase> {
  children?: ReactNode
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  iconSize?: number
}

/** @deprecated Use makeButton from 'components/ids-ads' instead */
export function makeButton(displayName: string, opts: UseButtonStylesOpts) {
  const Component = (
    { children, icon, iconSize = DEFAULT_ICON_SIZE, ...props }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const iconOnly = icon && !children
    const styles = useButtonStyles({ ...opts, iconOnly, iconSize })

    const Icon = icon

    return (
      <ButtonBase {...props} styles={styles} ref={ref}>
        {children}
        {Icon && <Icon size={iconSize} />}
      </ButtonBase>
    )
  }

  Component.displayName = displayName

  return forwardRef<HTMLButtonElement, ButtonProps>(Component)
}
