import { Global, css } from '@emotion/react'

export function DateRangePickerStyles() {
  return (
    <Global
      styles={css`
        /* DO NOT EDIT
         * This file is taken from https://github.com/gpbl/react-day-picker/blob/master/lib/style.css
         */

        /* DayPicker styles */

        .DayPicker {
          display: inline-block;
          font-size: 1rem;
        }

        .DayPicker-wrapper {
          position: relative;

          flex-direction: row;
          padding-bottom: 1em;

          -webkit-user-select: none;

          -moz-user-select: none;

          -ms-user-select: none;

          user-select: none;
        }

        .DayPicker-Months {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: column;
        }

        .DayPicker-Month {
          display: table;
          margin: 0 1em;
          margin-top: 1em;
          border-spacing: 0;
          border-collapse: collapse;

          -webkit-user-select: none;

          -moz-user-select: none;

          -ms-user-select: none;

          user-select: none;
        }

        .DayPicker-NavBar {
        }

        .DayPicker-NavButton {
          position: absolute;
          top: 1em;
          right: 1.5em;
          left: auto;

          display: inline-block;
          margin-top: 2px;
          width: 1.25em;
          height: 1.25em;
          background-position: center;
          background-size: 50%;
          background-repeat: no-repeat;
          color: #8b9898;
          cursor: pointer;
        }

        .DayPicker-NavButton:hover {
          opacity: 0.8;
        }

        .DayPicker-NavButton--prev {
          margin-right: 1.5em;
          background-size: contain;
          background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 24 24" fill="%23343538" xmlns="http://www.w3.org/2000/svg" size="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.121 12l5.94-5.94a1.5 1.5 0 00-2.122-2.12l-7 7a1.5 1.5 0 000 2.12l7 7a1.5 1.5 0 002.122-2.12L7.12 12z"></path></svg>');
        }

        .DayPicker-NavButton--next {
          background-size: contain;
          background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 24 24" fill="%23343538" xmlns="http://www.w3.org/2000/svg" size="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.879 12l-5.94-5.94a1.5 1.5 0 012.122-2.12l7 7a1.5 1.5 0 010 2.12l-7 7a1.5 1.5 0 01-2.122-2.12L16.88 12z"></path></svg>');
        }

        .DayPicker-NavButton--interactionDisabled {
          display: none;
        }

        .DayPicker-Caption {
          display: table-caption;
          margin-bottom: 0.5em;
          padding: 0 0.5em;
          text-align: left;
        }

        .DayPicker-Caption > div {
          font-weight: normal;
          font-size: 1.15em;
        }

        .DayPicker-Weekdays {
          display: table-header-group;
          margin-top: 1em;
        }

        .DayPicker-WeekdaysRow {
          display: table-row;
        }

        .DayPicker-Weekday {
          display: table-cell;
          padding: 0.5em;
          color: #8b9898;
          text-align: center;
          font-size: 0.875em;
        }

        .DayPicker-Weekday abbr[title] {
          border-bottom: none;
          text-decoration: none;
        }

        .DayPicker-Body {
          display: table-row-group;
        }

        .DayPicker-Week {
          display: table-row;
        }

        .DayPicker-Day {
          display: table-cell;
          padding: 0.5em;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          cursor: pointer;
        }

        .DayPicker-WeekNumber {
          display: table-cell;
          padding: 0.5em;
          min-width: 1em;
          border-right: 1px solid #eaecec;
          color: #8b9898;
          vertical-align: middle;
          text-align: right;
          font-size: 0.75em;
          cursor: pointer;
        }

        .DayPicker--interactionDisabled .DayPicker-Day {
          cursor: default;
        }

        .DayPicker-Footer {
          padding-top: 0.5em;
        }

        .DayPicker-TodayButton {
          border: none;
          background-color: transparent;
          background-image: none;
          box-shadow: none;
          color: #4a90e2;
          font-size: 0.875em;
          cursor: pointer;
        }

        /* Default modifiers */

        .DayPicker-Day--today {
          color: #d0021b;
          font-weight: bold;
        }

        .DayPicker-Day--outside {
          color: #8b9898;
          cursor: default;
        }

        .DayPicker-Day--disabled {
          color: #dce0e0;
          cursor: default;
          /* background-color: #eff1f1; */
        }

        /* Example modifiers */

        .DayPicker-Day--sunday {
          background-color: #f7f8f8;
        }

        .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
          color: #dce0e0;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          position: relative;

          background-color: #4a90e2;
          color: #f0f8ff;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
          background-color: #51a0fa;
        }

        .DayPicker:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: #f0f8ff;
        }

        /* DayPickerInput */

        .DayPickerInput {
          display: inline-block;
        }

        .DayPickerInput-OverlayWrapper {
          position: relative;
        }

        .DayPickerInput-Overlay {
          position: absolute;
          left: 0;
          z-index: 1;

          background: white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
      `}
    />
  )
}
