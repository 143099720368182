// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBlockBrandLogoAssetsLogo } from './PutBrandPagesIdParamBlockBrandLogoAssetsLogo'
import {
  PutBrandPagesIdParamBlockBrandLogoAssetsLogoFromJSON,
  PutBrandPagesIdParamBlockBrandLogoAssetsLogoFromJSONTyped,
  PutBrandPagesIdParamBlockBrandLogoAssetsLogoToJSON,
} from './PutBrandPagesIdParamBlockBrandLogoAssetsLogo'

/**
 *
 * @export
 * @interface PutBrandPagesIdParamBlockBrandLogoAssets
 */
export interface PutBrandPagesIdParamBlockBrandLogoAssets {
  /**
   *
   * @type {PutBrandPagesIdParamBlockBrandLogoAssetsLogo}
   * @memberof PutBrandPagesIdParamBlockBrandLogoAssets
   */
  logo: PutBrandPagesIdParamBlockBrandLogoAssetsLogo
}

/**
 * Check if a given object implements the PutBrandPagesIdParamBlockBrandLogoAssets interface.
 */
export function instanceOfPutBrandPagesIdParamBlockBrandLogoAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'logo' in value

  return isInstance
}

export function PutBrandPagesIdParamBlockBrandLogoAssetsFromJSON(
  json: any
): PutBrandPagesIdParamBlockBrandLogoAssets {
  return PutBrandPagesIdParamBlockBrandLogoAssetsFromJSONTyped(json, false)
}

export function PutBrandPagesIdParamBlockBrandLogoAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBlockBrandLogoAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    logo: PutBrandPagesIdParamBlockBrandLogoAssetsLogoFromJSON(json['logo']),
  }
}

export function PutBrandPagesIdParamBlockBrandLogoAssetsToJSON(
  value?: PutBrandPagesIdParamBlockBrandLogoAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    logo: PutBrandPagesIdParamBlockBrandLogoAssetsLogoToJSON(value.logo),
  }
}
