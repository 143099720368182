// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AsyncTaskResponseLinks } from './AsyncTaskResponseLinks'
import {
  AsyncTaskResponseLinksFromJSON,
  AsyncTaskResponseLinksFromJSONTyped,
  AsyncTaskResponseLinksToJSON,
} from './AsyncTaskResponseLinks'
import type { AsyncTaskResponseMeta } from './AsyncTaskResponseMeta'
import {
  AsyncTaskResponseMetaFromJSON,
  AsyncTaskResponseMetaFromJSONTyped,
  AsyncTaskResponseMetaToJSON,
} from './AsyncTaskResponseMeta'

/**
 *
 * @export
 * @interface AsyncTaskResponse
 */
export interface AsyncTaskResponse {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof AsyncTaskResponse
   */
  data?: { [key: string]: any }
  /**
   *
   * @type {AsyncTaskResponseMeta}
   * @memberof AsyncTaskResponse
   */
  meta: AsyncTaskResponseMeta
  /**
   *
   * @type {AsyncTaskResponseLinks}
   * @memberof AsyncTaskResponse
   */
  links?: AsyncTaskResponseLinks
}

/**
 * Check if a given object implements the AsyncTaskResponse interface.
 */
export function instanceOfAsyncTaskResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AsyncTaskResponseFromJSON(json: any): AsyncTaskResponse {
  return AsyncTaskResponseFromJSONTyped(json, false)
}

export function AsyncTaskResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AsyncTaskResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data') ? undefined : json['data'],
    meta: AsyncTaskResponseMetaFromJSON(json['meta']),
    links: !exists(json, 'links') ? undefined : AsyncTaskResponseLinksFromJSON(json['links']),
  }
}

export function AsyncTaskResponseToJSON(value?: AsyncTaskResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data,
    meta: AsyncTaskResponseMetaToJSON(value.meta),
    links: AsyncTaskResponseLinksToJSON(value.links),
  }
}
