import { ButtonBase, StylesOf, SVGIconProps, useTheme, Variant } from '@instacart/ids-core'
import { ComponentType, forwardRef } from 'react'

export interface IconButtonInlineProps
  extends Pick<SVGIconProps, 'color' | 'size'>,
    Omit<Variant<typeof ButtonBase>, 'aria-label' | 'color'> {
  icon: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  accessibleLabel: string
}

function useStyles({ size }: Pick<IconButtonInlineProps, 'size'>): StylesOf<typeof ButtonBase> {
  const theme = useTheme()

  return {
    button: {
      background: 'none',
      height: size,
      width: size,
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      lineHeight: 0,
    },
    disabled: {
      color: theme.colors.systemGrayscale30,
    },
  }
}

const IconButtonInline = forwardRef<HTMLButtonElement, IconButtonInlineProps>(
  function IconButtonInline(
    { icon: Icon, color, size = 24, disabled, accessibleLabel, onClick, ...props },
    ref
  ) {
    const styles = useStyles({ size })

    return (
      <ButtonBase
        type="button"
        aria-label={accessibleLabel}
        onClick={disabled ? undefined : onClick}
        {...props}
        styles={styles}
        ref={ref}
      >
        <Icon color={disabled ? 'systemGrayscale30' : color} size={size} />
      </ButtonBase>
    )
  }
)

export default IconButtonInline
