// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AuthCreateLegacyResponseDataAttributesCurrentAccount } from './AuthCreateLegacyResponseDataAttributesCurrentAccount'
import {
  AuthCreateLegacyResponseDataAttributesCurrentAccountFromJSON,
  AuthCreateLegacyResponseDataAttributesCurrentAccountFromJSONTyped,
  AuthCreateLegacyResponseDataAttributesCurrentAccountToJSON,
} from './AuthCreateLegacyResponseDataAttributesCurrentAccount'
import type { PostAuthMultiFactorParamDataAttributesMfaFactors } from './PostAuthMultiFactorParamDataAttributesMfaFactors'
import {
  PostAuthMultiFactorParamDataAttributesMfaFactorsFromJSON,
  PostAuthMultiFactorParamDataAttributesMfaFactorsFromJSONTyped,
  PostAuthMultiFactorParamDataAttributesMfaFactorsToJSON,
} from './PostAuthMultiFactorParamDataAttributesMfaFactors'

/**
 *
 * @export
 * @interface AuthCreateLegacyResponseDataAttributes
 */
export interface AuthCreateLegacyResponseDataAttributes {
  /**
   * Whether the active account ID has changed
   * @type {boolean}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  accountIdChanged: boolean
  /**
   * Currently active experiment variants enabled for this user
   * @type {{ [key: string]: any; }}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  activeVariants: { [key: string]: any }
  /**
   * User Email
   * @type {string}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  email: string
  /**
   * User First Name
   * @type {string}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  firstName: string
  /**
   * Flippers enabled for this user
   * @type {Array<string>}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  flippers: Array<string>
  /**
   * User Last Name
   * @type {string}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  lastName: string
  /**
   * User ID
   * @type {number}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  userId: number
  /**
   * User role type
   * @type {string}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  userType: string
  /**
   * User UUID
   * @type {string}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  userUuid: string
  /**
   *
   * @type {AuthCreateLegacyResponseDataAttributesCurrentAccount}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  currentAccount?: AuthCreateLegacyResponseDataAttributesCurrentAccount
  /**
   * MFA Factors
   * @type {Array<PostAuthMultiFactorParamDataAttributesMfaFactors>}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  mfaFactors?: Array<PostAuthMultiFactorParamDataAttributesMfaFactors>
  /**
   * Flag indicating if the user has single sign on authentication enabled
   * @type {boolean}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  singleSignOnEnabled?: boolean
  /**
   * Number of accounts user has access to
   * @type {number}
   * @memberof AuthCreateLegacyResponseDataAttributes
   */
  userAccounts: number
}

/**
 * Check if a given object implements the AuthCreateLegacyResponseDataAttributes interface.
 */
export function instanceOfAuthCreateLegacyResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'accountIdChanged' in value
  isInstance = isInstance && 'activeVariants' in value
  isInstance = isInstance && 'email' in value
  isInstance = isInstance && 'firstName' in value
  isInstance = isInstance && 'flippers' in value
  isInstance = isInstance && 'lastName' in value
  isInstance = isInstance && 'userId' in value
  isInstance = isInstance && 'userType' in value
  isInstance = isInstance && 'userUuid' in value
  isInstance = isInstance && 'userAccounts' in value

  return isInstance
}

export function AuthCreateLegacyResponseDataAttributesFromJSON(
  json: any
): AuthCreateLegacyResponseDataAttributes {
  return AuthCreateLegacyResponseDataAttributesFromJSONTyped(json, false)
}

export function AuthCreateLegacyResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthCreateLegacyResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    accountIdChanged: json['account_id_changed'],
    activeVariants: json['active_variants'],
    email: json['email'],
    firstName: json['first_name'],
    flippers: json['flippers'],
    lastName: json['last_name'],
    userId: json['user_id'],
    userType: json['user_type'],
    userUuid: json['user_uuid'],
    currentAccount: !exists(json, 'current_account')
      ? undefined
      : AuthCreateLegacyResponseDataAttributesCurrentAccountFromJSON(json['current_account']),
    mfaFactors: !exists(json, 'mfa_factors')
      ? undefined
      : (json['mfa_factors'] as Array<any>).map(
          PostAuthMultiFactorParamDataAttributesMfaFactorsFromJSON
        ),
    singleSignOnEnabled: !exists(json, 'single_sign_on_enabled')
      ? undefined
      : json['single_sign_on_enabled'],
    userAccounts: json['user_accounts'],
  }
}

export function AuthCreateLegacyResponseDataAttributesToJSON(
  value?: AuthCreateLegacyResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    account_id_changed: value.accountIdChanged,
    active_variants: value.activeVariants,
    email: value.email,
    first_name: value.firstName,
    flippers: value.flippers,
    last_name: value.lastName,
    user_id: value.userId,
    user_type: value.userType,
    user_uuid: value.userUuid,
    current_account: AuthCreateLegacyResponseDataAttributesCurrentAccountToJSON(
      value.currentAccount
    ),
    mfa_factors:
      value.mfaFactors === undefined
        ? undefined
        : (value.mfaFactors as Array<any>).map(
            PostAuthMultiFactorParamDataAttributesMfaFactorsToJSON
          ),
    single_sign_on_enabled: value.singleSignOnEnabled,
    user_accounts: value.userAccounts,
  }
}
