import { css } from '@emotion/react'
import { ApprovedIcon, spacing } from '@instacart/ids-core'
import { useState } from 'react'
import { FLIPPER_LYCHEE_EMAIL_FLOW } from 'common/flippers'
import useFlipper from 'common/useFlipper'
import useIntl from 'common/useIntl'
import Restricted from 'components/Auth/components/Restricted'
import FormattedMessage from 'components/FormattedMessage'
import StickyHeader from 'components/Header/StickyHeader'
import { useModalState, PrimaryButton, TertiaryButton } from 'components/ids-ads'
import { DefinitionText } from 'components/ids-ads/molecules/tooltips/DefinitionText'
import { SubmittedModal } from 'components/organisms/SubmittedModal/SubmittedModal'
import { GenericMessageDescriptor } from 'locales/types'
import { CampaignTypeStatus } from 'pages/constants'
import PageToolbar from '../PageToolbar'

export interface ReviewHeaderProps {
  title: string
  subtitle?: GenericMessageDescriptor
  isApproval: boolean
  isSubmitEnabled: boolean
  onSubmit: () => Promise<void>
  onExit: () => void
  capabilities: string[] | string
  campaignType: string
  hasSelectableAdGroups?: boolean
  hasIncompleteAdGroups?: boolean
  infoText?: GenericMessageDescriptor
}

const styles = {
  infoText: css({
    width: '100%',
  }),
  buttons: css({
    display: 'flex',
    gap: spacing.s16,
  }),
}

export const Actions = ({
  isApproval,
  isSubmitEnabled,
  hasSelectableAdGroups,
  hasIncompleteAdGroups,
  campaignType,
  capabilities,
  onSubmit,
  onExit,
  infoText,
}: Omit<ReviewHeaderProps, 'title' | 'subtitle'>) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const modalState = useModalState()
  const { genericFormatMessage } = useIntl()
  const isSubmitDisabled = isSubmitting || !isSubmitEnabled
  const isLycheeEmailFlowEnabled = useFlipper(FLIPPER_LYCHEE_EMAIL_FLOW)

  const submitButtonMessageId = isApproval
    ? 'pages.emailCampaign.review.actions.submitForReview'
    : 'pages.emailCampaign.review.actions.schedule'

  const lycheeEmailFlow = CampaignTypeStatus.EMAIL === campaignType && isLycheeEmailFlowEnabled

  const secondaryButtonMessage = lycheeEmailFlow
    ? 'pages.emailCampaign.setup.actions.exit'
    : 'common.saveAndExit'

  return (
    <>
      {infoText && <div css={styles.infoText}>{genericFormatMessage(infoText)}</div>}
      <div css={styles.buttons}>
        <TertiaryButton
          type="button"
          onClick={onExit}
          disabled={isSubmitting}
          data-testid="display-ad-group-review-save-and-exit"
        >
          <FormattedMessage id={secondaryButtonMessage} />
        </TertiaryButton>
        <Restricted capabilities={capabilities}>
          <>
            {!lycheeEmailFlow && (
              <>
                <DefinitionText
                  hovercardStateProps={{ visible: isSubmitDisabled ? undefined : false }}
                  styles={{ anchor: { textDecoration: 'none' } }}
                  definitionContent={
                    <FormattedMessage
                      id={
                        hasSelectableAdGroups
                          ? 'pages.displayProduct.displayCampaign.tooltip.selectAdGroups'
                          : hasIncompleteAdGroups
                          ? 'pages.displayProduct.displayCampaign.tooltip.adGroups'
                          : 'pages.displayProduct.displayCampaign.tooltip.reviewNotRequired'
                      }
                    />
                  }
                >
                  <PrimaryButton
                    type="submit"
                    onClick={async () => {
                      setIsSubmitting(true)
                      try {
                        await onSubmit()
                        if (isApproval) modalState.show()
                      } finally {
                        setIsSubmitting(false)
                      }
                    }}
                    disabled={isSubmitDisabled}
                    iconSize={18}
                    data-testid="display-ad-group-review-submit"
                    icon={isApproval ? ApprovedIcon : undefined}
                  >
                    <FormattedMessage id={submitButtonMessageId} />
                  </PrimaryButton>
                </DefinitionText>
                <SubmittedModal
                  modalState={modalState}
                  onExit={onExit}
                  title="pages.reviewHeader.submittedModal.title.display"
                  description="pages.reviewHeader.submittedModal.description.display"
                />
              </>
            )}
          </>
        </Restricted>
      </div>
    </>
  )
}

export default function ReviewHeader({
  title,
  subtitle,
  isApproval,
  isSubmitEnabled,
  onSubmit,
  onExit,
  capabilities,
  campaignType,
  hasIncompleteAdGroups,
}: ReviewHeaderProps) {
  const { formatMessage } = useIntl()

  return (
    <StickyHeader>
      <PageToolbar
        title={isApproval ? title : formatMessage({ id: 'common.review' })}
        subtitle={subtitle}
        actions={
          <>
            <Restricted capabilities={capabilities}>
              <Actions
                isApproval={isApproval}
                capabilities={capabilities}
                isSubmitEnabled={isSubmitEnabled}
                onSubmit={onSubmit}
                campaignType={campaignType}
                onExit={onExit}
                hasIncompleteAdGroups={hasIncompleteAdGroups}
              />
            </Restricted>
          </>
        }
      />
    </StickyHeader>
  )
}
