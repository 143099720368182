// eslint-disable-next-line no-restricted-syntax
export enum AnalyticEvents {
  AD_GROUP_DETAIL_EXPORT = 'ad_group_detail.export',
  CAMPAIGN_DETAIL_CLICK_EDIT_CAMPAIGN = 'campaign_detail.click_edit_campaign',
  CAMPAIGN_DETAIL_CLICK_METRIC = 'campaign_detail.click_metric',
  CAMPAIGN_DETAIL_EXPORT = 'campaign_detail.export',
  CAMPAIGN_DETAIL_SEARCH = 'campaign_detail.search',
  CAMPAIGN_DETAIL_VIEW = 'campaign_detail.view',
  CAMPAIGN_MANAGER_CLICK_CREATE_CAMPAIGN = 'campaign_manager.click_create_campaign',
  CAMPAIGN_MANAGER_CLICK_EDIT_CAMPAIGN = 'campaign_manager.click_edit_campaign',
  CAMPAIGN_MANAGER_EXPORT = 'campaign_manager.export',
  EDIT_AD_GROUP_CANCEL = 'edit_ad_group.cancel',
  EDIT_AD_GROUP_NAVIGATE = 'edit_ad_group.navigate',
  EDIT_AD_GROUP_SAVE = 'edit_ad_group.save',
  EDIT_CAMPAIGN_CANCEL = 'edit_campaign.cancel',
  EDIT_CAMPAIGN_NAVIGATE = 'edit_campaign.navigate',
  EDIT_CAMPAIGN_SAVE = 'edit_campaign.save',
  IMAGE_CROPPING_SAVE_CROP = 'image_cropping.save_crop',
  IMAGE_CROPPING_SELECT_IMAGE = 'image_cropping.select_image',
  NEW_AD_GROUP_CANCEL = 'new_ad_group.cancel',
  NEW_AD_GROUP_LAUNCH = 'new_ad_group.launch',
  NEW_AD_GROUP_NAVIGATE = 'new_ad_group.navigate',
  NEW_AD_GROUP_SAVE = 'new_ad_group.save',
  NEW_CAMPAIGN_CANCEL = 'new_campaign.cancel',
  NEW_CAMPAIGN_LAUNCH = 'new_campaign.launch',
  NEW_CAMPAIGN_NAVIGATE = 'new_campaign.navigate',
  NEW_CAMPAIGN_SAVE_DRAFT = 'new_campaign.save_draft',
}
