import { boolean } from 'yup'
import { object, SchemaType, string, useSyncValidator } from 'common/validation'

export const CREDENTIALS = 'credentials'
export const MULTI_FACTOR_AUTH = 'multi_factor_auth'

export type LoginStep = typeof CREDENTIALS | typeof MULTI_FACTOR_AUTH

export const ACCOUNT_LOCKED = 'account_locked'
export const ACCOUNT_UNCONFIRMED = 'account_unconfirmed'
export const USER_UNCONFIRMED = 'user_unconfirmed'
export const INVALID_CREDENTIALS = 'invalid_credentials'

export type LoginError =
  | typeof ACCOUNT_LOCKED
  | typeof ACCOUNT_UNCONFIRMED
  | typeof USER_UNCONFIRMED
  | typeof INVALID_CREDENTIALS

export const getLoginFormSchemaObject = (isSsoRequired?: (email: string) => void) => {
  return {
    email: string().trim().email().required().default(''),
    password: string()
      .default('')
      .when('email', {
        is: (val: string) => !isSsoRequired?.(val),
        then: string().required().default(''),
      }),
    rememberMe: boolean(),
  }
}

const loginFormSchemaObject = getLoginFormSchemaObject()

const loginFormSchema = object(loginFormSchemaObject)

export const getLoginInitialValues = (): LoginFormValues => loginFormSchema.cast({})

export type LoginFormValues = SchemaType<typeof loginFormSchema>

export const useLoginValidator = (isSsoRequired: (email: string) => void) => {
  return useSyncValidator(object(getLoginFormSchemaObject(isSsoRequired)), 'components.login.form')
}
