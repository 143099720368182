import FormattedMessage, { FormattedMessageProps } from 'components/FormattedMessage'
import { InfoInputIcon, Hovercard, useHovercardState } from 'components/ids-ads'

export interface InfoIconProps {
  info: string | FormattedMessageProps
  infoDescription: string
  compact?: boolean
}

export function InfoIcon({ info, infoDescription, compact }: InfoIconProps) {
  const hovercard = useHovercardState()
  const infoElem = typeof info === 'string' ? info : <FormattedMessage {...info} />

  return (
    <>
      <InfoInputIcon
        description={infoDescription}
        hovercard={hovercard}
        iconSize={compact ? 16 : 20}
      />
      <Hovercard state={hovercard}>{infoElem}</Hovercard>
    </>
  )
}
