import { css } from '@emotion/react'
import { useIntl } from 'common'
import {
  SecondaryButton,
  TertiaryButton,
  SecondaryLinkButton,
  TertiaryLinkButton,
  ButtonProps,
  LinkButtonProps,
} from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'
import { BannerActionButtonsProps } from '../utils'

const buttonCss = css({
  border: 'none',
})

export function BannerActionButtons({
  primaryButtonLabel,
  primaryButtonProps,
  primaryLinkButtonProps,
  secondaryButtonLabel,
  secondaryButtonProps,
  secondaryLinkButtonProps,
  isSecondary,
}: BannerActionButtonsProps) {
  const { genericFormatMessage } = useIntl()
  const Button = isSecondary ? SecondaryButton : TertiaryButton
  const LinkButton = isSecondary ? SecondaryLinkButton : TertiaryLinkButton
  const renderInlineButton = (
    buttonLabel?: GenericMessageDescriptor,
    buttonProps?: ButtonProps,
    buttonLinkProps?: LinkButtonProps
  ) => {
    if (buttonLabel) {
      if (buttonProps) {
        return (
          <Button compact css={buttonCss} {...buttonProps}>
            {genericFormatMessage(buttonLabel)}
          </Button>
        )
      }
      if (buttonLinkProps) {
        return (
          <LinkButton compact css={buttonCss} {...buttonLinkProps}>
            {genericFormatMessage(buttonLabel)}
          </LinkButton>
        )
      }
    }
    return null
  }

  return (
    <>
      {renderInlineButton(secondaryButtonLabel, secondaryButtonProps, secondaryLinkButtonProps)}
      {renderInlineButton(primaryButtonLabel, primaryButtonProps, primaryLinkButtonProps)}
    </>
  )
}
