import { css } from '@emotion/react'
import { spacing, useTheme, addAlphaToHex, responsive } from '@instacart/ids-core'
import { forwardRef } from 'react'
import { ModalBase, CommonModalProps, ModalBaseProps } from './components/ModalBase'
import { DEFAULT_DRAWER_WIDTH, TRANSITION_TIME_MS } from './utils'

type UseStyles = {
  width: string | number
  right: boolean
  allowContentToScroll?: boolean
  styles?: DrawerProps['styles']
}
const useStyles = ({ width, right, allowContentToScroll, styles }: UseStyles) => {
  const theme = useTheme()
  const maxWidth = `calc(100vw - ${spacing.s12 * 2}px)`
  const maxHeight = `calc(100vh - ${spacing.s12 * 2}px)`

  return {
    root: {
      backdrop: {
        background: addAlphaToHex(theme.colors.systemGrayscale70, 0.3),
        display: 'flex',
        justifyContent: right ? 'flex-end' : 'flex-start',
        opacity: 0,
        transition: `opacity ${TRANSITION_TIME_MS}ms ease-in-out`,
        '&[data-leave]': {
          opacity: 0,
        },
        '&[data-enter]': {
          opacity: 1,
        },
        ...(styles?.root?.backdrop as object),
      },
    },
    modal: {
      modal: css({
        borderRadius: 16,
        padding: spacing.s24,
        margin: spacing.s12,
        position: 'fixed',
        top: 'auto',
        left: 'auto',
        width,
        height: maxHeight,
        maxWidth,
        '> form': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: allowContentToScroll ? 'hidden' : 'initial',
        },
        [responsive.up('r')]: { maxHeight },
        ...(right
          ? {
              transform: 'translateX(100%)',
              transition: `transform ${TRANSITION_TIME_MS}ms ease-in-out`,
              '&[data-enter]': {
                transform: 'translateX(0)',
              },
              '&[data-leave]': {
                transform: 'translateX(100%)',
              },
            }
          : {
              transform: 'translateX(-100%)',
              transition: `transform ${TRANSITION_TIME_MS}ms ease-in-out`,
              '&[data-enter]': {
                transform: 'translateX(0)',
              },
              '&[data-leave]': {
                transform: 'translateX(-100%)',
              },
            }),
        ...styles?.modal,
      }),
    },
  }
}

export interface DrawerProps extends CommonModalProps {
  right?: boolean
  styles?: Partial<ModalBaseProps['styles']>
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(function Drawer(
  {
    children,
    right = true,
    width = DEFAULT_DRAWER_WIDTH,
    allowContentToScroll = false,
    styles,
    ...props
  },
  ref
) {
  const base = useStyles({ width, right, allowContentToScroll, styles })

  return (
    <ModalBase {...props} ref={ref} allowContentToScroll={allowContentToScroll} styles={base}>
      {children}
    </ModalBase>
  )
})
