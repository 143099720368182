// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent } from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent'
import type { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues } from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions
 */
export interface PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [key: string]: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions
   */
  products?: {
    [key: string]: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues
  }
  /**
   *
   * @type {PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent}
   * @memberof PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions
   */
  content?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions {
  return PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsToJSON(
  value?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContentToJSON(value.content),
  }
}
