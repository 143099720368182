// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick } from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
 */
export interface AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  urlClick?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfAdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  return AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
  value?: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click:
      AdminDisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
