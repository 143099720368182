// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockBrandLogoAssets } from './GetBrandPagesParamBlockBrandLogoAssets'
import {
  GetBrandPagesParamBlockBrandLogoAssetsFromJSON,
  GetBrandPagesParamBlockBrandLogoAssetsFromJSONTyped,
  GetBrandPagesParamBlockBrandLogoAssetsToJSON,
} from './GetBrandPagesParamBlockBrandLogoAssets'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockBrandLogo
 */
export interface GetBrandPagesParamBlockBrandLogo {
  /**
   * id of the block
   * @type {string}
   * @memberof GetBrandPagesParamBlockBrandLogo
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockBrandLogo
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockBrandLogo
   */
  type: GetBrandPagesParamBlockBrandLogoTypeEnum
  /**
   *
   * @type {GetBrandPagesParamBlockBrandLogoAssets}
   * @memberof GetBrandPagesParamBlockBrandLogo
   */
  assets: GetBrandPagesParamBlockBrandLogoAssets
}

/**
 * @export
 */
export const GetBrandPagesParamBlockBrandLogoTypeEnum = {
  BrandLogoEvergreenV1: 'brand_logo.evergreen.v1',
} as const
export type GetBrandPagesParamBlockBrandLogoTypeEnum =
  typeof GetBrandPagesParamBlockBrandLogoTypeEnum[keyof typeof GetBrandPagesParamBlockBrandLogoTypeEnum]

/**
 * Check if a given object implements the GetBrandPagesParamBlockBrandLogo interface.
 */
export function instanceOfGetBrandPagesParamBlockBrandLogo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function GetBrandPagesParamBlockBrandLogoFromJSON(
  json: any
): GetBrandPagesParamBlockBrandLogo {
  return GetBrandPagesParamBlockBrandLogoFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockBrandLogoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockBrandLogo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: GetBrandPagesParamBlockBrandLogoAssetsFromJSON(json['assets']),
  }
}

export function GetBrandPagesParamBlockBrandLogoToJSON(
  value?: GetBrandPagesParamBlockBrandLogo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: GetBrandPagesParamBlockBrandLogoAssetsToJSON(value.assets),
  }
}
