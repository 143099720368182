// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiProductsControllerIndexGroupIdsInput } from '../models/ApiProductsControllerIndexGroupIdsInput'
import {
  ApiProductsControllerIndexGroupIdsInputFromJSON,
  ApiProductsControllerIndexGroupIdsInputToJSON,
} from '../models/ApiProductsControllerIndexGroupIdsInput'
import type { ApiProductsControllerIndexInput } from '../models/ApiProductsControllerIndexInput'
import {
  ApiProductsControllerIndexInputFromJSON,
  ApiProductsControllerIndexInputToJSON,
} from '../models/ApiProductsControllerIndexInput'
import type { ApiProductsControllerIndexProductIdsInput } from '../models/ApiProductsControllerIndexProductIdsInput'
import {
  ApiProductsControllerIndexProductIdsInputFromJSON,
  ApiProductsControllerIndexProductIdsInputToJSON,
} from '../models/ApiProductsControllerIndexProductIdsInput'
import type { ApiProductsControllerSuggestedBidInput } from '../models/ApiProductsControllerSuggestedBidInput'
import {
  ApiProductsControllerSuggestedBidInputFromJSON,
  ApiProductsControllerSuggestedBidInputToJSON,
} from '../models/ApiProductsControllerSuggestedBidInput'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetProductsResponse } from '../models/GetProductsResponse'
import {
  GetProductsResponseFromJSON,
  GetProductsResponseToJSON,
} from '../models/GetProductsResponse'
import type { ProductsSearchAvailableResponse } from '../models/ProductsSearchAvailableResponse'
import {
  ProductsSearchAvailableResponseFromJSON,
  ProductsSearchAvailableResponseToJSON,
} from '../models/ProductsSearchAvailableResponse'
import type { ProductsSearchResponse } from '../models/ProductsSearchResponse'
import {
  ProductsSearchResponseFromJSON,
  ProductsSearchResponseToJSON,
} from '../models/ProductsSearchResponse'
import type { ProductsSuggestedBidResponse } from '../models/ProductsSuggestedBidResponse'
import {
  ProductsSuggestedBidResponseFromJSON,
  ProductsSuggestedBidResponseToJSON,
} from '../models/ProductsSuggestedBidResponse'

export interface GetProductsProductMappingRequest {
  page?: string
}

export interface GetProductsReportingEntitiesProductsRequest {
  adsTaxonomyReportingEntityId?: string
  page?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetProductsSearchRequest {
  adsTaxonomyReportingEntityId?: string
  query?: string
  page?: string
  classifiedType?: GetProductsSearchClassifiedTypeEnum
  isImpulse?: boolean
  commodityScope?: GetProductsSearchCommodityScopeEnum
  isPluSearch?: boolean
  inLibrary?: boolean
  includeBrandMappingStatus?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetProductsSearchAvailableRequest {
  xApiKey?: string
  xSecretKey?: string
}

export interface PostProductsGroupIdsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiProductsControllerIndexGroupIdsInput
}

export interface PostProductsProductIdsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiProductsControllerIndexProductIdsInput
}

export interface PostProductsSuggestedBidRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiProductsControllerSuggestedBidInput
}

export interface PostProductsUpcsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiProductsControllerIndexInput
}

/**
 *
 */
export class ProductsApi extends runtime.BaseAPI {
  /**
   * Use this method to Retrieve the product_catalog linked to your account. We’ll return a list of your products which are associated to the account.
   * Retrieve entire product catalog for an account
   */
  async getProductsProductMappingRaw(
    requestParameters: GetProductsProductMappingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsSearchResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/products/product_mapping`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductsSearchResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to Retrieve the product_catalog linked to your account. We’ll return a list of your products which are associated to the account.
   * Retrieve entire product catalog for an account
   */
  async getProductsProductMapping(
    requestParameters: GetProductsProductMappingRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsSearchResponse> {
    const response = await this.getProductsProductMappingRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return products with the given reporting entity id.
   */
  async getProductsReportingEntitiesProductsRaw(
    requestParameters: GetProductsReportingEntitiesProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsSearchResponse>> {
    const queryParameters: any = {}

    if (requestParameters.adsTaxonomyReportingEntityId !== undefined) {
      queryParameters['ads_taxonomy_reporting_entity_id'] =
        requestParameters.adsTaxonomyReportingEntityId
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/reporting_entities_products`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductsSearchResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return products with the given reporting entity id.
   */
  async getProductsReportingEntitiesProducts(
    requestParameters: GetProductsReportingEntitiesProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsSearchResponse> {
    const response = await this.getProductsReportingEntitiesProductsRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Use this method to search for products by name or brand. We’ll return a list of your products which match the search term sent as a parameter.  Rate limited to 1000 products per minute per access token.
   * Search for products by search term
   */
  async getProductsSearchRaw(
    requestParameters: GetProductsSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsSearchResponse>> {
    const queryParameters: any = {}

    if (requestParameters.adsTaxonomyReportingEntityId !== undefined) {
      queryParameters['ads_taxonomy_reporting_entity_id'] =
        requestParameters.adsTaxonomyReportingEntityId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.classifiedType !== undefined) {
      queryParameters['classified_type'] = requestParameters.classifiedType
    }

    if (requestParameters.isImpulse !== undefined) {
      queryParameters['is_impulse'] = requestParameters.isImpulse
    }

    if (requestParameters.commodityScope !== undefined) {
      queryParameters['commodity_scope'] = requestParameters.commodityScope
    }

    if (requestParameters.isPluSearch !== undefined) {
      queryParameters['is_plu_search'] = requestParameters.isPluSearch
    }

    if (requestParameters.inLibrary !== undefined) {
      queryParameters['in_library'] = requestParameters.inLibrary
    }

    if (requestParameters.includeBrandMappingStatus !== undefined) {
      queryParameters['include_brand_mapping_status'] = requestParameters.includeBrandMappingStatus
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductsSearchResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to search for products by name or brand. We’ll return a list of your products which match the search term sent as a parameter.  Rate limited to 1000 products per minute per access token.
   * Search for products by search term
   */
  async getProductsSearch(
    requestParameters: GetProductsSearchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsSearchResponse> {
    const response = await this.getProductsSearchRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to check that the ability to search for products by search term is available for your account. We’ll return either true or false. If we return ‘true’, you can use the GET /products/search method to search for products.
   * Is Product Search Available?
   */
  async getProductsSearchAvailableRaw(
    requestParameters: GetProductsSearchAvailableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsSearchAvailableResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/search/available`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductsSearchAvailableResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to check that the ability to search for products by search term is available for your account. We’ll return either true or false. If we return ‘true’, you can use the GET /products/search method to search for products.
   * Is Product Search Available?
   */
  async getProductsSearchAvailable(
    requestParameters: GetProductsSearchAvailableRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsSearchAvailableResponse> {
    const response = await this.getProductsSearchAvailableRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to search for products by their ads_product_group group_id. We’ll return a list of your representative products for those group ids
   * Search for representative products by group_id
   */
  async postProductsGroupIdsRaw(
    requestParameters: PostProductsGroupIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetProductsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/group_ids`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiProductsControllerIndexGroupIdsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetProductsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to search for products by their ads_product_group group_id. We’ll return a list of your representative products for those group ids
   * Search for representative products by group_id
   */
  async postProductsGroupIds(
    requestParameters: PostProductsGroupIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetProductsResponse> {
    const response = await this.postProductsGroupIdsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to search for products by their Instacart ID. We’ll return a list of your products with the product_ids you sent as a parameter.
   * Search for products by product_id
   */
  async postProductsProductIdsRaw(
    requestParameters: PostProductsProductIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetProductsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/product_ids`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiProductsControllerIndexProductIdsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetProductsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to search for products by their Instacart ID. We’ll return a list of your products with the product_ids you sent as a parameter.
   * Search for products by product_id
   */
  async postProductsProductIds(
    requestParameters: PostProductsProductIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetProductsResponse> {
    const response = await this.postProductsProductIdsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Get suggested bid by product ids
   */
  async postProductsSuggestedBidRaw(
    requestParameters: PostProductsSuggestedBidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsSuggestedBidResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/suggested_bid`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiProductsControllerSuggestedBidInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductsSuggestedBidResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get suggested bid by product ids
   */
  async postProductsSuggestedBid(
    requestParameters: PostProductsSuggestedBidRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsSuggestedBidResponse> {
    const response = await this.postProductsSuggestedBidRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to search for products by their UPC. We’ll return a list of your products with the UPC(s) you sent as a parameter.  Rate limited to 5000 upcs per minute per access token.
   * Search for products by UPC
   */
  async postProductsUpcsRaw(
    requestParameters: PostProductsUpcsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetProductsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/products/upcs`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiProductsControllerIndexInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetProductsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to search for products by their UPC. We’ll return a list of your products with the UPC(s) you sent as a parameter.  Rate limited to 5000 upcs per minute per access token.
   * Search for products by UPC
   */
  async postProductsUpcs(
    requestParameters: PostProductsUpcsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetProductsResponse> {
    const response = await this.postProductsUpcsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetProductsSearchClassifiedTypeEnum = {
  Universal: 'universal',
  Commodity: 'commodity',
  InHouse: 'in-house',
} as const
export type GetProductsSearchClassifiedTypeEnum =
  typeof GetProductsSearchClassifiedTypeEnum[keyof typeof GetProductsSearchClassifiedTypeEnum]
/**
 * @export
 */
export const GetProductsSearchCommodityScopeEnum = {
  Brand: 'brand',
} as const
export type GetProductsSearchCommodityScopeEnum =
  typeof GetProductsSearchCommodityScopeEnum[keyof typeof GetProductsSearchCommodityScopeEnum]
