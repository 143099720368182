// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdAppealParamEntities } from './PutDisplayAdGroupsIdAppealParamEntities'
import {
  PutDisplayAdGroupsIdAppealParamEntitiesFromJSON,
  PutDisplayAdGroupsIdAppealParamEntitiesFromJSONTyped,
  PutDisplayAdGroupsIdAppealParamEntitiesToJSON,
} from './PutDisplayAdGroupsIdAppealParamEntities'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerAppealInput
 */
export interface ApiDisplayAdGroupsControllerAppealInput {
  /**
   * The display ad group name
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerAppealInput
   */
  adGroupName?: string
  /**
   * List of entities to appeal
   * @type {Array<PutDisplayAdGroupsIdAppealParamEntities>}
   * @memberof ApiDisplayAdGroupsControllerAppealInput
   */
  entities?: Array<PutDisplayAdGroupsIdAppealParamEntities>
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerAppealInput interface.
 */
export function instanceOfApiDisplayAdGroupsControllerAppealInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerAppealInputFromJSON(
  json: any
): ApiDisplayAdGroupsControllerAppealInput {
  return ApiDisplayAdGroupsControllerAppealInputFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerAppealInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerAppealInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroupName: !exists(json, 'ad_group_name') ? undefined : json['ad_group_name'],
    entities: !exists(json, 'entities')
      ? undefined
      : (json['entities'] as Array<any>).map(PutDisplayAdGroupsIdAppealParamEntitiesFromJSON),
  }
}

export function ApiDisplayAdGroupsControllerAppealInputToJSON(
  value?: ApiDisplayAdGroupsControllerAppealInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group_name: value.adGroupName,
    entities:
      value.entities === undefined
        ? undefined
        : (value.entities as Array<any>).map(PutDisplayAdGroupsIdAppealParamEntitiesToJSON),
  }
}
