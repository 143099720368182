import { css } from '@emotion/react'
import { spacing } from '@instacart/ids-core'
import {
  breakFormattingHelper,
  listFormattingHelper,
  italicFormattingHelper,
} from 'common/intlUtils'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType, MessageExtendedValues } from 'locales/types'
import { Card } from './Card'
import { CardTitle } from './CardTitle'

export interface CardHintsProps {
  title: MessageIdType
  description: MessageIdType
  descriptionValues?: MessageExtendedValues
  imageUrl?: string
  imageWidth?: number
  button?: JSX.Element
  withMargin?: boolean
}

function useStyles() {
  return {
    button: css({
      button: {
        width: '100%',
      },
    }),
    card: css({
      display: 'flex !important',
      flexDirection: 'column',
    }),
    container: css({
      marginTop: spacing.s12,
    }),
  }
}

export const CardHints = ({
  title,
  description,
  imageUrl,
  imageWidth,
  button: buttonElement,
  withMargin = false,
  descriptionValues = {},
}: CardHintsProps) => {
  const styles = useStyles()

  const imageCss = css({
    width: imageWidth ? `${imageWidth}%` : '100%',
    marginBottom: 12,
  })

  const imageElement = imageUrl ? <img alt={title} src={imageUrl} css={imageCss} /> : null

  const containerCss = withMargin ? styles.container : undefined

  return (
    <div css={containerCss}>
      {buttonElement && <div css={styles.button}>{buttonElement}</div>}
      <Card>
        <div css={styles.card}>
          <CardTitle small>
            <FormattedMessage id={title} />
          </CardTitle>
          {imageElement}
          <FormattedMessage
            id={description}
            values={{
              ...breakFormattingHelper,
              ...listFormattingHelper(),
              ...italicFormattingHelper,
              ...descriptionValues,
            }}
          />
        </div>
      </Card>
    </div>
  )
}
