// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktop } from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import {
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSONTyped,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON,
} from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import type { PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobile } from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobile'
import {
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSONTyped,
  PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON,
} from './PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobile'

/**
 *
 * @export
 * @interface PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues
 */
export interface PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues {
  /**
   *
   * @type {PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktop}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues
   */
  desktop?: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktop
  /**
   *
   * @type {PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobile}
   * @memberof PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues
   */
  mobile?: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobile
}

/**
 * Check if a given object implements the PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues interface.
 */
export function instanceOfPutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON(
  json: any
): PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues {
  return PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
    json,
    false
  )
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: !exists(json, 'desktop')
      ? undefined
      : PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON(
          json['desktop']
        ),
    mobile: !exists(json, 'mobile')
      ? undefined
      : PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON(
          json['mobile']
        ),
  }
}

export function PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesToJSON(
  value?: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValues | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON(
      value.desktop
    ),
    mobile: PutBrandPagesIdParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON(
      value.mobile
    ),
  }
}
