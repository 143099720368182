// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockContentText } from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentText'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentText'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle } from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageBlockContent
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageBlockContent {
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContent
   */
  title: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageBlockContentText}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContent
   */
  text: PostAdminBrandPagesIdLabelParamBrandPageBlockContentText
}

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageBlockContent interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlockContent(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'text' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageBlockContent {
  return PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageBlockContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSON(json['title']),
    text: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextFromJSON(json['text']),
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageBlockContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleToJSON(value.title),
    text: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTextToJSON(value.text),
  }
}
