// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes
 */
export interface ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes
   */
  name?: string
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes
   */
  startsAt?: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Omit to automatically stop the campaign when the campaign’s budget has been spent.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes
   */
  endsAt?: string
}

/**
 * Check if a given object implements the ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes interface.
 */
export function instanceOfApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSON(
  json: any
): ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes {
  return ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    startsAt: !exists(json, 'starts_at') ? undefined : json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
  }
}

export function ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributesToJSON(
  value?: ApiDisplayCampaignsControllerDuplicateAsyncInputOverrideAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
  }
}
