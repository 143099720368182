// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent } from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent'
import type { PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues } from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues'
import type { PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValues } from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValues'
import {
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValues'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions
 */
export interface PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues; }}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions
   */
  highlightedProducts?: {
    [key: string]: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValues
  }
  /**
   *
   * @type {{ [key: string]: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValues; }}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions
   */
  products?: { [key: string]: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValues }
  /**
   *
   * @type {PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions
   */
  content?: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContent
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBrandPageBlockFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions {
  return PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProducts: !exists(json, 'highlighted_products')
      ? undefined
      : mapValues(
          json['highlighted_products'],
          PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesFromJSON
        ),
    products: !exists(json, 'products')
      ? undefined
      : mapValues(
          json['products'],
          PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValuesFromJSON
        ),
    content: !exists(json, 'content')
      ? undefined
      : PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentFromJSON(json['content']),
  }
}

export function PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_products:
      value.highlightedProducts === undefined
        ? undefined
        : mapValues(
            value.highlightedProducts,
            PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsHighlightedProductsValuesToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : mapValues(
            value.products,
            PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsProductsValuesToJSON
          ),
    content: PutDisplayCampaignsIdParamBrandPageBlockFieldDecisionsContentToJSON(value.content),
  }
}
