// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge
 */
export interface GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge {
  /**
   *
   * @type {number}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge
   */
  amountCents: number
  /**
   * Last updated at timestamp
   * @type {string}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge
   */
  updatedAt: string
  /**
   * The state that the Invoice Charge is in. Only successful or declined charges are shown.
   * @type {string}
   * @memberof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge
   */
  workflowState: GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeWorkflowStateEnum
}

/**
 * @export
 */
export const GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeWorkflowStateEnum =
  {
    Success: 'success',
    Declined: 'declined',
  } as const
export type GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeWorkflowStateEnum =
  typeof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeWorkflowStateEnum[keyof typeof GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeWorkflowStateEnum]

/**
 * Check if a given object implements the GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge interface.
 */
export function instanceOfGetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'amountCents' in value
  isInstance = isInstance && 'updatedAt' in value
  isInstance = isInstance && 'workflowState' in value

  return isInstance
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSON(
  json: any
): GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge {
  return GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSONTyped(
    json,
    false
  )
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge {
  if (json === undefined || json === null) {
    return json
  }
  return {
    amountCents: json['amount_cents'],
    updatedAt: json['updated_at'],
    workflowState: json['workflow_state'],
  }
}

export function GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountChargeToJSON(
  value?: GetAccountPaymentResponseDataAttributesCreditCardPaymentInformationLastAccountCharge | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    amount_cents: value.amountCents,
    updated_at: value.updatedAt,
    workflow_state: value.workflowState,
  }
}
