// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockImageAndTextAssets } from './GetBrandPagesParamBlockImageAndTextAssets'
import {
  GetBrandPagesParamBlockImageAndTextAssetsFromJSON,
  GetBrandPagesParamBlockImageAndTextAssetsFromJSONTyped,
  GetBrandPagesParamBlockImageAndTextAssetsToJSON,
} from './GetBrandPagesParamBlockImageAndTextAssets'
import type { GetBrandPagesParamBlockImageAndTextContent } from './GetBrandPagesParamBlockImageAndTextContent'
import {
  GetBrandPagesParamBlockImageAndTextContentFromJSON,
  GetBrandPagesParamBlockImageAndTextContentFromJSONTyped,
  GetBrandPagesParamBlockImageAndTextContentToJSON,
} from './GetBrandPagesParamBlockImageAndTextContent'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockImageAndText
 */
export interface GetBrandPagesParamBlockImageAndText {
  /**
   * id of the block
   * @type {string}
   * @memberof GetBrandPagesParamBlockImageAndText
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockImageAndText
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockImageAndText
   */
  type: GetBrandPagesParamBlockImageAndTextTypeEnum
  /**
   *
   * @type {GetBrandPagesParamBlockImageAndTextContent}
   * @memberof GetBrandPagesParamBlockImageAndText
   */
  content: GetBrandPagesParamBlockImageAndTextContent
  /**
   *
   * @type {GetBrandPagesParamBlockImageAndTextAssets}
   * @memberof GetBrandPagesParamBlockImageAndText
   */
  assets: GetBrandPagesParamBlockImageAndTextAssets
}

/**
 * @export
 */
export const GetBrandPagesParamBlockImageAndTextTypeEnum = {
  ImageAndTextEvergreenV1: 'image_and_text.evergreen.v1',
} as const
export type GetBrandPagesParamBlockImageAndTextTypeEnum =
  typeof GetBrandPagesParamBlockImageAndTextTypeEnum[keyof typeof GetBrandPagesParamBlockImageAndTextTypeEnum]

/**
 * Check if a given object implements the GetBrandPagesParamBlockImageAndText interface.
 */
export function instanceOfGetBrandPagesParamBlockImageAndText(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function GetBrandPagesParamBlockImageAndTextFromJSON(
  json: any
): GetBrandPagesParamBlockImageAndText {
  return GetBrandPagesParamBlockImageAndTextFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockImageAndTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockImageAndText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: GetBrandPagesParamBlockImageAndTextContentFromJSON(json['content']),
    assets: GetBrandPagesParamBlockImageAndTextAssetsFromJSON(json['assets']),
  }
}

export function GetBrandPagesParamBlockImageAndTextToJSON(
  value?: GetBrandPagesParamBlockImageAndText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: GetBrandPagesParamBlockImageAndTextContentToJSON(value.content),
    assets: GetBrandPagesParamBlockImageAndTextAssetsToJSON(value.assets),
  }
}
