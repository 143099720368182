import { css } from '@emotion/react'
import { spacing } from '@instacart/ids-core'
import { FunctionComponent, ReactNode } from 'react'
import { toPx } from 'common'
import { useIsPaymentStatusActive } from 'common/accountPaymentStatus'
import { SupplementaryStatuses } from 'common/supplementaryStatuses/types'
import useIntl from 'common/useIntl'
import { messageIdExists, uc } from 'common/utils'
import FormattedMessage from 'components/FormattedMessage'
import { StatusPill } from 'components/molecules/StatusPill'
import {
  cautionColors,
  defaultColors,
  StateMap as SM,
  StateMapType,
  StateTooltipMap as STM,
} from 'components/molecules/StatusPill/StateMap'
import { MessageIdType } from 'locales/types'
import { CampaignStatus } from 'pages/constants'

export interface Props {
  status?: string
  supplementaryStatuses?: SupplementaryStatuses
  atRiskMeta?: Record<string, unknown>
  tooltipLink?: ReactNode
}

const useStyles = () => {
  return {
    tooltipWithSupplementaryStatuses: css({
      listStyle: 'disc',
      margin: toPx`0 0 0 ${spacing.s16} !important`,

      // Avoid bullets if there's only 1 tooltip element
      '&:has(li:only-child)': {
        listStyleType: 'none',
        marginLeft: '0 !important',
      },
    }),
  }
}

export const StateMap: StateMapType = {
  ACTIVE: SM.ACTIVE,
  AD_GROUP_ENDED: SM.ENDED,
  AD_GROUP_PAUSED: SM.PAUSED,
  AD_GROUP_SCHEDULED: SM.SCHEDULED,
  CAMPAIGN_DRAFT: SM.DRAFT,
  CAMPAIGN_ENDED: SM.ENDED,
  CAMPAIGN_OUT_OF_BUDGET: SM.OUT_OF_BUDGET,
  CAMPAIGN_PAUSED: SM.PAUSED,
  CONTAINS_REJECTED_AD_GROUPS: {
    ...SM.REJECTED,
    ...cautionColors,
  },
  CAMPAIGN_SCHEDULED: SM.SCHEDULED,
  DELETED: SM.DELETED,
  DRAFT: SM.DRAFT,
  ENDED: SM.ENDED,
  PAUSED: SM.PAUSED,
  FAILED_PAYMENT: SM.FAILED_PAYMENT,
  INCOMPLETE_AD_GROUP: SM.DRAFT,
  INCOMPLETE_AD_GROUP_PRODUCTS: SM.DRAFT,
  NOT_READY: SM.NOT_READY,
  OUT_OF_BUDGET: SM.OUT_OF_BUDGET,
  PROCESSING_PRODUCT: {
    labelId: 'common.processing',
    ...defaultColors,
  },
  SCHEDULED: SM.SCHEDULED,
  UNAVAILABLE_PRODUCT: {
    labelId: 'common.unavailable',
    ...defaultColors,
  },
  UNAVAILABLE_IMAGE: {
    labelId: 'common.missingImage',
    ...defaultColors,
  },
  UNKNOWN: SM.UNKNOWN,
}

const StateTooltipMap: Record<string, MessageIdType> = {
  ...STM,
  UNAVAILABLE_IMAGE: 'adGroup.skusTable.column.status.tooltips.unavailableImage',
  UNAVAILABLE_PRODUCT: 'adGroup.skusTable.column.status.tooltips.unavailableProduct',
  CONTAINS_REJECTED_AD_GROUPS: 'pages.displayCampaign.status.containsRejectedAdGroups.tooltip',
}

const PaymentStatusRestrictedStatus: FunctionComponent<React.PropsWithChildren<Props>> = ({
  status,
  supplementaryStatuses = {},
  atRiskMeta,
  tooltipLink,
}) => {
  const intl = useIntl()
  const styles = useStyles()
  let statusToShow = status
  const outOfBudgetStatus = statusToShow === CampaignStatus.OUT_OF_BUDGET
  const isDailyAtRisk = atRiskMeta?.avg_missed_auctions_percentage !== undefined

  const isMaxImpsAtRisk = atRiskMeta?.percent_burnt_of_total_lifetime_budget !== undefined

  const notReadyStatus = statusToShow === 'not_ready'
  const atRiskStatus = isDailyAtRisk || isMaxImpsAtRisk
  const hasSupplementaryStatuses = Object.keys(supplementaryStatuses).length > 0

  let tooltipMessageIDToShow = statusToShow ? StateTooltipMap[uc(statusToShow)] : undefined
  if (atRiskStatus) {
    if (isDailyAtRisk) {
      tooltipMessageIDToShow = 'campaigns.atRiskMessage.missedOpportunities.daily'
    } else {
      tooltipMessageIDToShow = 'campaigns.atRiskMessage.maxImpressions'
    }
  }
  if (outOfBudgetStatus) {
    tooltipMessageIDToShow = 'campaigns.outOfBudgetMessage'
  }

  const isPaymentStatusActive = useIsPaymentStatusActive()

  if (statusToShow !== 'deleted' && !isPaymentStatusActive) {
    statusToShow = 'failed_payment'
    tooltipMessageIDToShow = undefined
  }

  // Fetch supplementary statuses for tooltip content
  // and append to any existing tooltip content
  const supplementaryStatusTooltipIdsRoot = 'common.supplementaryStatus.tooltip.'
  const supplementaryStatusTooltipTexts: JSX.Element[] = Object.keys(supplementaryStatuses).map(
    statusKey => (
      <FormattedMessage id={supplementaryStatusTooltipIdsRoot.concat(statusKey) as MessageIdType} />
    )
  )

  // Augments the existing tooltip text with any active supplementary status
  // tooltip texts
  const withSupplementaryStatusesTooltipText = (
    tooltipText: JSX.Element | null
  ): null | JSX.Element => {
    if (supplementaryStatusTooltipTexts.length > 0) {
      return (
        <ul css={styles.tooltipWithSupplementaryStatuses}>
          {tooltipText && <li>{tooltipText}</li>}
          {supplementaryStatusTooltipTexts.map(text => (
            <li>{text}</li>
          ))}
        </ul>
      )
    }

    return tooltipText
  }

  const tooltipText = withSupplementaryStatusesTooltipText(
    tooltipMessageIDToShow && messageIdExists(tooltipMessageIDToShow, intl) ? (
      <FormattedMessage id={tooltipMessageIDToShow} />
    ) : null
  )

  const ttLink = tooltipLink ? <div>{tooltipLink}</div> : null
  const tooltipContent =
    tooltipMessageIDToShow || ttLink || hasSupplementaryStatuses ? (
      <>
        {tooltipText}
        {ttLink}
      </>
    ) : undefined

  return (
    <StatusPill
      status={statusToShow}
      atRisk={atRiskStatus || hasSupplementaryStatuses || notReadyStatus}
      tooltipContent={tooltipContent}
      stateMap={StateMap}
    />
  )
}

export default PaymentStatusRestrictedStatus
