import * as Sentry from '@sentry/react'

// Sentry is being initialized on the index/landing HTML document

export const captureException = <T>(message: string, exception?: T) => {
  const exceptionToSend = exception || new Error(message)

  Sentry.captureException(exceptionToSend, {
    contexts: {
      message: { message },
    },
  })
}

export const setUser = (user: Sentry.User) => Sentry.setUser(user)

export const { withScope } = Sentry
