// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AuthShowCapabilitiesResponseDataAttributes
 */
export interface AuthShowCapabilitiesResponseDataAttributes {
  /**
   * Capabilities of this user
   * @type {{ [key: string]: boolean; }}
   * @memberof AuthShowCapabilitiesResponseDataAttributes
   */
  capabilities: { [key: string]: boolean }
  /**
   * Account feature capabilities
   * @type {{ [key: string]: boolean; }}
   * @memberof AuthShowCapabilitiesResponseDataAttributes
   */
  featureCapabilities: { [key: string]: boolean }
}

/**
 * Check if a given object implements the AuthShowCapabilitiesResponseDataAttributes interface.
 */
export function instanceOfAuthShowCapabilitiesResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'capabilities' in value
  isInstance = isInstance && 'featureCapabilities' in value

  return isInstance
}

export function AuthShowCapabilitiesResponseDataAttributesFromJSON(
  json: any
): AuthShowCapabilitiesResponseDataAttributes {
  return AuthShowCapabilitiesResponseDataAttributesFromJSONTyped(json, false)
}

export function AuthShowCapabilitiesResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthShowCapabilitiesResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    capabilities: json['capabilities'],
    featureCapabilities: json['feature_capabilities'],
  }
}

export function AuthShowCapabilitiesResponseDataAttributesToJSON(
  value?: AuthShowCapabilitiesResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    capabilities: value.capabilities,
    feature_capabilities: value.featureCapabilities,
  }
}
