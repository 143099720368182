// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostAdminBrandPagesIdLabelParamAsset,
  instanceOfPostAdminBrandPagesIdLabelParamAsset,
  PostAdminBrandPagesIdLabelParamAssetFromJSON,
  PostAdminBrandPagesIdLabelParamAssetFromJSONTyped,
  PostAdminBrandPagesIdLabelParamAssetToJSON,
} from './PostAdminBrandPagesIdLabelParamAsset'
import {
  PostAdminBrandPagesIdLabelParamBrandPage,
  instanceOfPostAdminBrandPagesIdLabelParamBrandPage,
  PostAdminBrandPagesIdLabelParamBrandPageFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPage'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlock,
  instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlock,
  PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlock'
import {
  PostAdminBrandPagesIdLabelParamProductAsset,
  instanceOfPostAdminBrandPagesIdLabelParamProductAsset,
  PostAdminBrandPagesIdLabelParamProductAssetFromJSON,
  PostAdminBrandPagesIdLabelParamProductAssetFromJSONTyped,
  PostAdminBrandPagesIdLabelParamProductAssetToJSON,
} from './PostAdminBrandPagesIdLabelParamProductAsset'

/**
 * @type PostAdminBrandPagesIdLabelParamEntitiesEntityLabel
 *
 * @export
 */
export type PostAdminBrandPagesIdLabelParamEntitiesEntityLabel =
  | ({ discriminator: 'ASSET' } & PostAdminBrandPagesIdLabelParamAsset)
  | ({ discriminator: 'BRAND_PAGE' } & PostAdminBrandPagesIdLabelParamBrandPage)
  | ({ discriminator: 'BRAND_PAGE_BLOCK' } & PostAdminBrandPagesIdLabelParamBrandPageBlock)
  | ({ discriminator: 'PRODUCT_ASSET' } & PostAdminBrandPagesIdLabelParamProductAsset)

export function PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamEntitiesEntityLabel {
  return PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamEntitiesEntityLabelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamEntitiesEntityLabel {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'ASSET':
      return {
        ...PostAdminBrandPagesIdLabelParamAssetFromJSONTyped(json, true),
        discriminator: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PostAdminBrandPagesIdLabelParamBrandPageFromJSONTyped(json, true),
        discriminator: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSONTyped(json, true),
        discriminator: 'BRAND_PAGE_BLOCK',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PostAdminBrandPagesIdLabelParamProductAssetFromJSONTyped(json, true),
        discriminator: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PostAdminBrandPagesIdLabelParamEntitiesEntityLabel exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PostAdminBrandPagesIdLabelParamEntitiesEntityLabelToJSON(
  value?: PostAdminBrandPagesIdLabelParamEntitiesEntityLabel | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'ASSET':
      return PostAdminBrandPagesIdLabelParamAssetToJSON(value)
    case 'BRAND_PAGE':
      return PostAdminBrandPagesIdLabelParamBrandPageToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PostAdminBrandPagesIdLabelParamBrandPageBlockToJSON(value)
    case 'PRODUCT_ASSET':
      return PostAdminBrandPagesIdLabelParamProductAssetToJSON(value)
    default:
      throw new Error(
        `No variant of PostAdminBrandPagesIdLabelParamEntitiesEntityLabel exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
