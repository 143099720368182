// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick } from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid } from './DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid } from './DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid } from './DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface DisplayAdGroupResponseDataAttributesCreativeActions
 */
export interface DisplayAdGroupResponseDataAttributesCreativeActions {
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainItemGrid?: DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainProductGroupGrid?: DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActions
   */
  mainCollectionGrid?: DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActions
   */
  bannerClick?: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the DisplayAdGroupResponseDataAttributesCreativeActions interface.
 */
export function instanceOfDisplayAdGroupResponseDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsFromJSON(
  json: any
): DisplayAdGroupResponseDataAttributesCreativeActions {
  return DisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayAdGroupResponseDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsToJSON(
  value?: DisplayAdGroupResponseDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: DisplayAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      DisplayAdGroupResponseDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      DisplayAdGroupResponseDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
