// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayCampaignCreateResponseData } from './DisplayCampaignCreateResponseData'
import {
  DisplayCampaignCreateResponseDataFromJSON,
  DisplayCampaignCreateResponseDataFromJSONTyped,
  DisplayCampaignCreateResponseDataToJSON,
} from './DisplayCampaignCreateResponseData'
import type { DisplayCampaignCreateResponseMeta } from './DisplayCampaignCreateResponseMeta'
import {
  DisplayCampaignCreateResponseMetaFromJSON,
  DisplayCampaignCreateResponseMetaFromJSONTyped,
  DisplayCampaignCreateResponseMetaToJSON,
} from './DisplayCampaignCreateResponseMeta'

/**
 *
 * @export
 * @interface DisplayCampaignCreateResponse
 */
export interface DisplayCampaignCreateResponse {
  /**
   *
   * @type {DisplayCampaignCreateResponseData}
   * @memberof DisplayCampaignCreateResponse
   */
  data: DisplayCampaignCreateResponseData
  /**
   *
   * @type {DisplayCampaignCreateResponseMeta}
   * @memberof DisplayCampaignCreateResponse
   */
  meta: DisplayCampaignCreateResponseMeta
}

/**
 * Check if a given object implements the DisplayCampaignCreateResponse interface.
 */
export function instanceOfDisplayCampaignCreateResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function DisplayCampaignCreateResponseFromJSON(json: any): DisplayCampaignCreateResponse {
  return DisplayCampaignCreateResponseFromJSONTyped(json, false)
}

export function DisplayCampaignCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayCampaignCreateResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: DisplayCampaignCreateResponseDataFromJSON(json['data']),
    meta: DisplayCampaignCreateResponseMetaFromJSON(json['meta']),
  }
}

export function DisplayCampaignCreateResponseToJSON(
  value?: DisplayCampaignCreateResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: DisplayCampaignCreateResponseDataToJSON(value.data),
    meta: DisplayCampaignCreateResponseMetaToJSON(value.meta),
  }
}
