// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsParamBrandPageBlockSnapshot,
  instanceOfGetDisplayCampaignsParamBrandPageBlockSnapshot,
  GetDisplayCampaignsParamBrandPageBlockSnapshotFromJSON,
  GetDisplayCampaignsParamBrandPageBlockSnapshotFromJSONTyped,
  GetDisplayCampaignsParamBrandPageBlockSnapshotToJSON,
} from './GetDisplayCampaignsParamBrandPageBlockSnapshot'
import {
  GetDisplayCampaignsParamBrandPageSnapshot,
  instanceOfGetDisplayCampaignsParamBrandPageSnapshot,
  GetDisplayCampaignsParamBrandPageSnapshotFromJSON,
  GetDisplayCampaignsParamBrandPageSnapshotFromJSONTyped,
  GetDisplayCampaignsParamBrandPageSnapshotToJSON,
} from './GetDisplayCampaignsParamBrandPageSnapshot'
import {
  GetDisplayCampaignsParamCreativeSnapshot,
  instanceOfGetDisplayCampaignsParamCreativeSnapshot,
  GetDisplayCampaignsParamCreativeSnapshotFromJSON,
  GetDisplayCampaignsParamCreativeSnapshotFromJSONTyped,
  GetDisplayCampaignsParamCreativeSnapshotToJSON,
} from './GetDisplayCampaignsParamCreativeSnapshot'
import {
  GetDisplayCampaignsParamDisplayAdGroupSnapshot,
  instanceOfGetDisplayCampaignsParamDisplayAdGroupSnapshot,
  GetDisplayCampaignsParamDisplayAdGroupSnapshotFromJSON,
  GetDisplayCampaignsParamDisplayAdGroupSnapshotFromJSONTyped,
  GetDisplayCampaignsParamDisplayAdGroupSnapshotToJSON,
} from './GetDisplayCampaignsParamDisplayAdGroupSnapshot'
import {
  GetDisplayCampaignsParamDisplayAssetSnapshot,
  instanceOfGetDisplayCampaignsParamDisplayAssetSnapshot,
  GetDisplayCampaignsParamDisplayAssetSnapshotFromJSON,
  GetDisplayCampaignsParamDisplayAssetSnapshotFromJSONTyped,
  GetDisplayCampaignsParamDisplayAssetSnapshotToJSON,
} from './GetDisplayCampaignsParamDisplayAssetSnapshot'
import {
  GetDisplayCampaignsParamDisplayProductAssetSnapshot,
  instanceOfGetDisplayCampaignsParamDisplayProductAssetSnapshot,
  GetDisplayCampaignsParamDisplayProductAssetSnapshotFromJSON,
  GetDisplayCampaignsParamDisplayProductAssetSnapshotFromJSONTyped,
  GetDisplayCampaignsParamDisplayProductAssetSnapshotToJSON,
} from './GetDisplayCampaignsParamDisplayProductAssetSnapshot'

/**
 * @type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & GetDisplayCampaignsParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & GetDisplayCampaignsParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayCampaignsParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & GetDisplayCampaignsParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayCampaignsParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & GetDisplayCampaignsParamDisplayProductAssetSnapshot)

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayCampaignsParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayCampaignsParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayCampaignsParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayCampaignsParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayCampaignsParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayCampaignsParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayCampaignsParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayCampaignsParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayCampaignsParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return GetDisplayCampaignsParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayCampaignsParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayCampaignsParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
