// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiBrandPagesControllerAppealInput } from '../models/ApiBrandPagesControllerAppealInput'
import {
  ApiBrandPagesControllerAppealInputFromJSON,
  ApiBrandPagesControllerAppealInputToJSON,
} from '../models/ApiBrandPagesControllerAppealInput'
import type { ApiBrandPagesControllerCreateInput } from '../models/ApiBrandPagesControllerCreateInput'
import {
  ApiBrandPagesControllerCreateInputFromJSON,
  ApiBrandPagesControllerCreateInputToJSON,
} from '../models/ApiBrandPagesControllerCreateInput'
import type { ApiBrandPagesControllerGenerateInput } from '../models/ApiBrandPagesControllerGenerateInput'
import {
  ApiBrandPagesControllerGenerateInputFromJSON,
  ApiBrandPagesControllerGenerateInputToJSON,
} from '../models/ApiBrandPagesControllerGenerateInput'
import type { ApiBrandPagesControllerStatusInput } from '../models/ApiBrandPagesControllerStatusInput'
import {
  ApiBrandPagesControllerStatusInputFromJSON,
  ApiBrandPagesControllerStatusInputToJSON,
} from '../models/ApiBrandPagesControllerStatusInput'
import type { ApiBrandPagesControllerUpdateInput } from '../models/ApiBrandPagesControllerUpdateInput'
import {
  ApiBrandPagesControllerUpdateInputFromJSON,
  ApiBrandPagesControllerUpdateInputToJSON,
} from '../models/ApiBrandPagesControllerUpdateInput'
import type { AppealBrandPagesResponse } from '../models/AppealBrandPagesResponse'
import {
  AppealBrandPagesResponseFromJSON,
  AppealBrandPagesResponseToJSON,
} from '../models/AppealBrandPagesResponse'
import type { AsyncTaskResponse } from '../models/AsyncTaskResponse'
import { AsyncTaskResponseFromJSON, AsyncTaskResponseToJSON } from '../models/AsyncTaskResponse'
import type { BrandPageAggregatesResponse } from '../models/BrandPageAggregatesResponse'
import {
  BrandPageAggregatesResponseFromJSON,
  BrandPageAggregatesResponseToJSON,
} from '../models/BrandPageAggregatesResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetBrandPagesAnalyticsResponse } from '../models/GetBrandPagesAnalyticsResponse'
import {
  GetBrandPagesAnalyticsResponseFromJSON,
  GetBrandPagesAnalyticsResponseToJSON,
} from '../models/GetBrandPagesAnalyticsResponse'
import type { StandaloneBrandPageResponse } from '../models/StandaloneBrandPageResponse'
import {
  StandaloneBrandPageResponseFromJSON,
  StandaloneBrandPageResponseToJSON,
} from '../models/StandaloneBrandPageResponse'
import type { StandaloneBrandPagesResponse } from '../models/StandaloneBrandPagesResponse'
import {
  StandaloneBrandPagesResponseFromJSON,
  StandaloneBrandPagesResponseToJSON,
} from '../models/StandaloneBrandPagesResponse'
import type { SubmitBrandPagesResponse } from '../models/SubmitBrandPagesResponse'
import {
  SubmitBrandPagesResponseFromJSON,
  SubmitBrandPagesResponseToJSON,
} from '../models/SubmitBrandPagesResponse'

export interface GetBrandPagesRequest {
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaQuery?: string
  searchCriteriaOnlyShowRejectedBrandPages?: boolean
  sortCriteriaField?: GetBrandPagesSortCriteriaFieldEnum
  sortCriteriaDirection?: GetBrandPagesSortCriteriaDirectionEnum
  pageCriteriaPage?: number
  pageCriteriaItemsPerPage?: number
  xApiKey?: string
  xSecretKey?: string
}

export interface GetBrandPagesAggregatesRequest {
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaQuery?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetBrandPagesAnalyticsRequest {
  metric: GetBrandPagesAnalyticsMetricEnum
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaQuery?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetBrandPagesIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostBrandPagesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiBrandPagesControllerCreateInput
}

export interface PostBrandPagesGenerateRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiBrandPagesControllerGenerateInput
}

export interface PostBrandPagesIdStatusRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiBrandPagesControllerStatusInput
}

export interface PostBrandPagesIdSubmitRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PutBrandPagesIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiBrandPagesControllerUpdateInput
}

export interface PutBrandPagesIdAppealRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiBrandPagesControllerAppealInput
}

/**
 *
 */
export class BrandPagesApi extends runtime.BaseAPI {
  /**
   *
   * Search or list all Brand Pages given criteria.
   */
  async getBrandPagesRaw(
    requestParameters: GetBrandPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPagesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    if (requestParameters.searchCriteriaOnlyShowRejectedBrandPages !== undefined) {
      queryParameters['search_criteria[only_show_rejected_brand_pages]'] =
        requestParameters.searchCriteriaOnlyShowRejectedBrandPages
    }

    if (requestParameters.sortCriteriaField !== undefined) {
      queryParameters['sort_criteria[field]'] = requestParameters.sortCriteriaField
    }

    if (requestParameters.sortCriteriaDirection !== undefined) {
      queryParameters['sort_criteria[direction]'] = requestParameters.sortCriteriaDirection
    }

    if (requestParameters.pageCriteriaPage !== undefined) {
      queryParameters['page_criteria[page]'] = requestParameters.pageCriteriaPage
    }

    if (requestParameters.pageCriteriaItemsPerPage !== undefined) {
      queryParameters['page_criteria[items_per_page]'] = requestParameters.pageCriteriaItemsPerPage
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPagesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Search or list all Brand Pages given criteria.
   */
  async getBrandPages(
    requestParameters: GetBrandPagesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPagesResponse> {
    const response = await this.getBrandPagesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Returns aggregates
   */
  async getBrandPagesAggregatesRaw(
    requestParameters: GetBrandPagesAggregatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BrandPageAggregatesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/aggregates`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      BrandPageAggregatesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Returns aggregates
   */
  async getBrandPagesAggregates(
    requestParameters: GetBrandPagesAggregatesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BrandPageAggregatesResponse> {
    const response = await this.getBrandPagesAggregatesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Returns analytics for brand pages
   */
  async getBrandPagesAnalyticsRaw(
    requestParameters: GetBrandPagesAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetBrandPagesAnalyticsResponse>> {
    if (requestParameters.metric === null || requestParameters.metric === undefined) {
      throw new runtime.RequiredError(
        'metric',
        'Required parameter requestParameters.metric was null or undefined when calling getBrandPagesAnalytics.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.metric !== undefined) {
      queryParameters['metric'] = requestParameters.metric
    }

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/analytics`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetBrandPagesAnalyticsResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Returns analytics for brand pages
   */
  async getBrandPagesAnalytics(
    requestParameters: GetBrandPagesAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetBrandPagesAnalyticsResponse> {
    const response = await this.getBrandPagesAnalyticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Get the Brand Page for the given id.
   */
  async getBrandPagesIdRaw(
    requestParameters: GetBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPageResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getBrandPagesId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get the Brand Page for the given id.
   */
  async getBrandPagesId(
    requestParameters: GetBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPageResponse> {
    const response = await this.getBrandPagesIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Endpoint for creating brand pages.
   * Create a new Brand Page
   */
  async postBrandPagesRaw(
    requestParameters: PostBrandPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPageResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiBrandPagesControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   * Endpoint for creating brand pages.
   * Create a new Brand Page
   */
  async postBrandPages(
    requestParameters: PostBrandPagesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPageResponse> {
    const response = await this.postBrandPagesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Generate brand page
   */
  async postBrandPagesGenerateRaw(
    requestParameters: PostBrandPagesGenerateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AsyncTaskResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/generate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiBrandPagesControllerGenerateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AsyncTaskResponseFromJSON(jsonValue))
  }

  /**
   *
   * Generate brand page
   */
  async postBrandPagesGenerate(
    requestParameters: PostBrandPagesGenerateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AsyncTaskResponse> {
    const response = await this.postBrandPagesGenerateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Enable or disable brand page.
   */
  async postBrandPagesIdStatusRaw(
    requestParameters: PostBrandPagesIdStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPageResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postBrandPagesIdStatus.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/{id}/status`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiBrandPagesControllerStatusInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Enable or disable brand page.
   */
  async postBrandPagesIdStatus(
    requestParameters: PostBrandPagesIdStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPageResponse> {
    const response = await this.postBrandPagesIdStatusRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Submit brand page for approval.
   */
  async postBrandPagesIdSubmitRaw(
    requestParameters: PostBrandPagesIdSubmitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SubmitBrandPagesResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postBrandPagesIdSubmit.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/{id}/submit`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      SubmitBrandPagesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Submit brand page for approval.
   */
  async postBrandPagesIdSubmit(
    requestParameters: PostBrandPagesIdSubmitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitBrandPagesResponse> {
    const response = await this.postBrandPagesIdSubmitRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Update the Brand Page for given the id.
   */
  async putBrandPagesIdRaw(
    requestParameters: PutBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StandaloneBrandPageResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putBrandPagesId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiBrandPagesControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      StandaloneBrandPageResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Update the Brand Page for given the id.
   */
  async putBrandPagesId(
    requestParameters: PutBrandPagesIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StandaloneBrandPageResponse> {
    const response = await this.putBrandPagesIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Appeal the brand page
   */
  async putBrandPagesIdAppealRaw(
    requestParameters: PutBrandPagesIdAppealRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppealBrandPagesResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putBrandPagesIdAppeal.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/brand_pages/{id}/appeal`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiBrandPagesControllerAppealInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AppealBrandPagesResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Appeal the brand page
   */
  async putBrandPagesIdAppeal(
    requestParameters: PutBrandPagesIdAppealRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppealBrandPagesResponse> {
    const response = await this.putBrandPagesIdAppealRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetBrandPagesSortCriteriaFieldEnum = {
  Name: 'name',
  Status: 'status',
  Level: 'level',
  Type: 'type',
  BrandPageUrl: 'brand_page_url',
  StartsAt: 'starts_at',
  SubmittedAt: 'submitted_at',
} as const
export type GetBrandPagesSortCriteriaFieldEnum =
  typeof GetBrandPagesSortCriteriaFieldEnum[keyof typeof GetBrandPagesSortCriteriaFieldEnum]
/**
 * @export
 */
export const GetBrandPagesSortCriteriaDirectionEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const
export type GetBrandPagesSortCriteriaDirectionEnum =
  typeof GetBrandPagesSortCriteriaDirectionEnum[keyof typeof GetBrandPagesSortCriteriaDirectionEnum]
/**
 * @export
 */
export const GetBrandPagesAnalyticsMetricEnum = {
  PageViews: 'page_views',
  ItemDetailPageViews: 'item_detail_page_views',
  AddToCarts: 'add_to_carts',
  Sales: 'sales',
} as const
export type GetBrandPagesAnalyticsMetricEnum =
  typeof GetBrandPagesAnalyticsMetricEnum[keyof typeof GetBrandPagesAnalyticsMetricEnum]
