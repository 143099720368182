// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
 */
export interface GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes {
  /**
   * Name of the state
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  stateName: string
  /**
   * Abbreviation for the state name
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  abbreviation: string
  /**
   * Name of the country
   * @type {string}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  country: string
  /**
   * Flag indicating if alcohol ads are allowed in the state
   * @type {boolean}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  alcoholAllowed?: boolean
  /**
   * Flag indicating if featured product ads are allowed in the state
   * @type {boolean}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  featuredProductEnabled?: boolean
  /**
   * Flag indicating if hero banner ads are allowed in the state
   * @type {boolean}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  heroEnabled?: boolean
  /**
   * Flag indicating if coupon ads are allowed in the state
   * @type {boolean}
   * @memberof GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes
   */
  couponEnabled?: boolean
}

/**
 * Check if a given object implements the GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes interface.
 */
export function instanceOfGetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'stateName' in value
  isInstance = isInstance && 'abbreviation' in value
  isInstance = isInstance && 'country' in value

  return isInstance
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSON(
  json: any
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes {
  return GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSONTyped(
    json,
    false
  )
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    stateName: json['state_name'],
    abbreviation: json['abbreviation'],
    country: json['country'],
    alcoholAllowed: !exists(json, 'alcohol_allowed') ? undefined : json['alcohol_allowed'],
    featuredProductEnabled: !exists(json, 'featured_product_enabled')
      ? undefined
      : json['featured_product_enabled'],
    heroEnabled: !exists(json, 'hero_enabled') ? undefined : json['hero_enabled'],
    couponEnabled: !exists(json, 'coupon_enabled') ? undefined : json['coupon_enabled'],
  }
}

export function GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributesToJSON(
  value?: GetAdminAccountsParamDataAttributesAlcoholStatePermissionsHeroBannerAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    state_name: value.stateName,
    abbreviation: value.abbreviation,
    country: value.country,
    alcohol_allowed: value.alcoholAllowed,
    featured_product_enabled: value.featuredProductEnabled,
    hero_enabled: value.heroEnabled,
    coupon_enabled: value.couponEnabled,
  }
}
