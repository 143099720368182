import { ButtonBase, Variant, spacing, StylesOf, useTheme } from '@instacart/ids-core'
import { ForwardedRef, forwardRef } from 'react'
import toPx from 'common/toPx'
import {
  CommonButtonProps,
  STATE_TRANSITION,
  UseStylesInternal,
  UseStylesOpts,
  buttonPadding,
  defaultIconSize,
  resolveColor,
} from '.'
import { ButtonContent } from './ButtonContent'
import { TooltipButtonBase } from './TooltipButtonBase'

export function useButtonStyles({
  borderColor,
  textColor,
  normalBackground,
  hoverBackground,
  hoverBorderColor = borderColor,
  activeBackground,
  activeBorderColor = borderColor,
  hasIcon,
  iconOnly,
  iconSize,
  iconPosition,
  baseButtonStyleOverrides,
  compact,
  loading,
  openIndicator,
}: UseStylesOpts & UseStylesInternal): NonNullable<StylesOf<typeof ButtonBase>> {
  const theme = useTheme()

  return {
    button: {
      display: 'inline-flex',
      boxSizing: 'border-box',
      backgroundColor: resolveColor(theme, loading ? activeBackground : normalBackground),
      color: resolveColor(theme, textColor),
      border: `1px solid ${resolveColor(theme, loading ? activeBorderColor : borderColor)}`,
      borderRadius: compact ? 6 : theme.radius.r8,
      padding: buttonPadding({ compact, hasIcon, iconOnly, iconPosition, loading, openIndicator }),
      transition: STATE_TRANSITION,
    },
    content: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: spacing.s4,
      ...(compact ? theme.typography.bodySmall1 : theme.typography.bodyMedium1),
      ...(iconOnly ? { lineHeight: 1 } : {}),
      '.button-icon, .open-indicator': {
        fill: 'currentColor',
      },
      '.button-icon': {
        margin: iconOnly ? 0 : toPx`0 0 ${-2}`,
        padding: (defaultIconSize(compact) - iconSize) / 2,
      },
      '.open-indicator': {
        margin: toPx`${compact ? 1 : 3} 0`,
        marginLeft: spacing.s8,
      },
    },
    focusRing: {
      left: -5,
      top: -5,
      right: -5,
      bottom: -5,
      borderRadius: compact ? 10 : theme.radius.r12,
    },
    hover: {
      backgroundColor: resolveColor(theme, hoverBackground),
      borderColor: resolveColor(theme, hoverBorderColor),
      transition: 'none',
    },
    active: {
      backgroundColor: resolveColor(theme, activeBackground),
      borderColor: resolveColor(theme, activeBorderColor),
      transition: 'none',
    },
    disabled: {
      backgroundColor: loading
        ? resolveColor(theme, activeBackground)
        : theme.colors.systemGrayscale10,
      borderColor: loading ? resolveColor(theme, activeBorderColor) : 'transparent',
      color: loading ? resolveColor(theme, textColor) : theme.colors.systemGrayscale30,
    },
    ...(baseButtonStyleOverrides && baseButtonStyleOverrides),
  }
}

export type ButtonProps = Variant<typeof ButtonBase> & CommonButtonProps

export function makeButton(displayName: string, opts: UseStylesOpts) {
  const Component = (
    {
      children,
      compact,
      disabled,
      loading,
      icon,
      iconSize = defaultIconSize(compact),
      iconPosition = 'left',
      openIndicator,
      open,
      tooltip,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const iconOnly = icon && !children

    const styles = useButtonStyles({
      ...opts,
      compact,
      loading,
      hasIcon: !!icon,
      iconOnly,
      iconSize,
      iconPosition,
      openIndicator,
    })

    const finalProps = {
      ...props,
      disabled: disabled || loading,
      styles,
      ref,
    }

    const content = (
      <ButtonContent
        icon={icon}
        iconSize={iconSize}
        iconPosition={iconPosition}
        loading={loading}
        open={open}
        openIndicator={openIndicator}
        textColor={opts.textColor}
      >
        {children}
      </ButtonContent>
    )

    if (tooltip) {
      return (
        <TooltipButtonBase tooltip={tooltip} {...finalProps}>
          {content}
        </TooltipButtonBase>
      )
    }

    return <ButtonBase {...finalProps}>{content}</ButtonBase>
  }

  const ForwardedComponent = forwardRef<HTMLButtonElement, ButtonProps>(Component)

  ForwardedComponent.displayName = displayName

  return ForwardedComponent
}
