// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetCampaignProductIdsResponseDataAttributes
 */
export interface GetCampaignProductIdsResponseDataAttributes {
  /**
   * Product ID
   * @type {string}
   * @memberof GetCampaignProductIdsResponseDataAttributes
   */
  productId: string
  /**
   * Ad Group UUID
   * @type {string}
   * @memberof GetCampaignProductIdsResponseDataAttributes
   */
  adGroupUuid?: string
  /**
   * Status of the ad group product
   * @type {string}
   * @memberof GetCampaignProductIdsResponseDataAttributes
   */
  adGroupProductStatus?: string
  /**
   * Indicates whether or not the product is enabled
   * @type {boolean}
   * @memberof GetCampaignProductIdsResponseDataAttributes
   */
  productEnabled?: boolean
}

/**
 * Check if a given object implements the GetCampaignProductIdsResponseDataAttributes interface.
 */
export function instanceOfGetCampaignProductIdsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'productId' in value

  return isInstance
}

export function GetCampaignProductIdsResponseDataAttributesFromJSON(
  json: any
): GetCampaignProductIdsResponseDataAttributes {
  return GetCampaignProductIdsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetCampaignProductIdsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignProductIdsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productId: json['product_id'],
    adGroupUuid: !exists(json, 'ad_group_uuid') ? undefined : json['ad_group_uuid'],
    adGroupProductStatus: !exists(json, 'ad_group_product_status')
      ? undefined
      : json['ad_group_product_status'],
    productEnabled: !exists(json, 'product_enabled') ? undefined : json['product_enabled'],
  }
}

export function GetCampaignProductIdsResponseDataAttributesToJSON(
  value?: GetCampaignProductIdsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_id: value.productId,
    ad_group_uuid: value.adGroupUuid,
    ad_group_product_status: value.adGroupProductStatus,
    product_enabled: value.productEnabled,
  }
}
