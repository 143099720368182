import { css, SerializedStyles } from '@emotion/react'
import {
  useTheme,
  InformationIcon,
  Color,
  IssueIcon,
  spacing,
  CloseIcon,
  SVGIconProps,
} from '@instacart/ids-core'
import classNames from 'classnames'
import { CSSProperties } from 'react'
import useIntl from 'common/useIntl'
import { BannerActionButton } from './BannerNotifications/components/BannerActionButton'
import IconButtonInline from './IconButtonInline'

export const NOTICE_MARGIN = spacing.s12

// eslint-disable-next-line no-restricted-syntax
export enum NoticeType {
  Info = 'info',
  Error = 'error',
}

interface NoticePropsBase {
  extraStyles?: SerializedStyles
  noticeText: string | React.ReactNode
  noticeType: NoticeType
  inline?: boolean
  // (optional) icon override
  icon?: React.FC<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
}

interface NoticePropsNotCloseable {
  closeable?: false
}

interface NoticePropsCloseable {
  closeable: true
  onClose: () => void
}

interface NoticePropsNoCTA {
  ctaMessage?: never
  ctaOnClick?: never
}

interface NoticePropsCTA {
  ctaMessage: string
  ctaOnClick: () => void
}

export type NoticeProps = NoticePropsBase &
  (NoticePropsNotCloseable | NoticePropsCloseable) &
  (NoticePropsNoCTA | NoticePropsCTA)

const noticeTypeToColor: Record<NoticeType, Color> = {
  [NoticeType.Info]: 'brandHighlightRegular',
  [NoticeType.Error]: 'systemDetrimentalRegular',
}

const noticeTypeToIcon: Record<NoticeType, typeof InformationIcon | typeof IssueIcon> = {
  [NoticeType.Info]: InformationIcon,
  [NoticeType.Error]: IssueIcon,
}

const noticeTypeToBackgroundColor: Record<NoticeType, Color> = {
  [NoticeType.Info]: 'brandHighlightLight',
  [NoticeType.Error]: 'brandMaxLight',
}

function useStyles({ noticeType }: { noticeType: NoticeType }) {
  const theme = useTheme()

  const sharedFlex: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  return {
    root: css({
      ...sharedFlex,
      padding: `${spacing.s12}px ${spacing.s16}px`,
      position: 'static',
      boxSizing: 'border-box',
      borderRadius: theme.radius.r4,
      background: theme.colors[noticeTypeToBackgroundColor[noticeType]],
      border: 'none',
      '&.inline': {
        border: 'none',
        background: theme.colors[noticeTypeToBackgroundColor[noticeType]],
        padding: `${spacing.s8} ${spacing.s12}`,
        marginBottom: NOTICE_MARGIN,
      },
    }),
    main: css({
      ...sharedFlex,
    }),
    icon: css({
      flex: '0 0 auto',
      alignSelf: 'flex-start',
    }),
    text: css({
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemGrayscale70,
      marginLeft: spacing.s8,
      marginRight: spacing.s16,
    }),
    closeButton: css({
      marginLeft: spacing.s8,
      verticalAlign: 'middle',
    }),
  }
}

/** @deprecated Use BannerNotification from 'components' instead */
export default function Notice({
  noticeText,
  extraStyles,
  noticeType,
  inline,
  ...optionalProps
}: NoticeProps) {
  const intl = useIntl()
  const styles = useStyles({ noticeType })

  const testId = 'notice-icon'
  const color = noticeTypeToColor[noticeType]
  const Icon = optionalProps.icon || noticeTypeToIcon[noticeType]

  return (
    <div css={[styles.root, extraStyles]} className={classNames({ inline })}>
      <div css={styles.main}>
        <Icon data-testid={testId} size={20} color={color} css={styles.icon} />
        <p css={styles.text}>{noticeText}</p>
      </div>
      <div>
        {optionalProps.ctaMessage && (
          <BannerActionButton onClick={optionalProps.ctaOnClick}>
            {optionalProps.ctaMessage}
          </BannerActionButton>
        )}
        {optionalProps.closeable && (
          <IconButtonInline
            accessibleLabel={intl.formatMessage({
              id: 'common.close',
            })}
            color={noticeTypeToColor[noticeType]}
            css={styles.closeButton}
            icon={CloseIcon}
            onClick={optionalProps.onClose}
          />
        )}
      </div>
    </div>
  )
}
