// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
 */
export interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  brandPageClick?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  urlClick?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  recipeClick?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON(
        value.urlClick
      ),
    recipe_click:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
