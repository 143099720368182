import { spacing, Text, useTheme } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { debounce } from 'lodash'
import { CSSProperties, useState, useEffect, useRef, FC } from 'react'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { PrimaryAnchorButton } from 'components/ids-ads'
import { SIGNUP_PATH } from '../utils/constants'
import { getImageUrl } from '../utils/helpers'
import breakPoints from './breakPoints'

const DEBOUNCE_MS = 100

const useStyles = (): { [key: string]: CSSProperties } => {
  const theme = useTheme()
  return {
    hero: {
      position: 'relative',
      marginTop: '80px',
    },
    heroImage: {
      height: '484px',
      width: '100%',
      objectFit: 'cover',
      display: 'block',
      [breakPoints.mobile]: {
        height: '150px',
      },
    },
    heroContent: {
      position: 'absolute',
      top: '92px',
      left: '114px',
      [breakPoints.mobile]: {
        position: 'static',
        top: 'auto',
        left: 'auto',
        textAlign: 'center',
        ...{ paddingLeft: spacing.s24, paddingRight: spacing.s24 },
        ...{ paddingTop: spacing.s16 },
      },
    },
    title: {
      color: theme.colors.systemGrayscale70,
      [breakPoints.mobile]: {
        fontSize: '28px',
        lineHeight: '35px',
        maxWidth: 'initial',
      },
    },
    subTitle: {
      maxWidth: '450px',
      color: theme.colors.systemGrayscale50,
      marginTop: spacing.s16,
    },
    startNow: {
      ...{ marginTop: spacing.s24, marginBottom: spacing.s24 },
      ...{ paddingLeft: spacing.s48, paddingRight: spacing.s48 },
      display: 'inline-flex',
      [breakPoints.mobile]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
  }
}

interface HeroProps {
  onVisible: (visible: boolean) => void
}

const Hero: FC<React.PropsWithChildren<HeroProps>> = ({ onVisible }) => {
  const styles = useStyles()
  const [visible, setVisible] = useState<boolean>(true)

  const heroRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = debounce(() => {
      const heroEl = heroRef.current
      if (!heroEl) {
        return
      }
      const rect = heroEl.getBoundingClientRect()
      const rectVisible = rect.bottom > 0

      if (visible !== rectVisible) {
        onVisible(rectVisible)
        setVisible(rectVisible)
      }
    }, DEBOUNCE_MS)

    window.addEventListener('scroll', handleScroll, true)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll.cancel()
    }
  })

  const intl = useIntl()
  return (
    <div style={styles.hero}>
      <picture>
        <source
          media="(min-width:1200px)"
          srcSet={`${getImageUrl('/images/landing/hero_image_1x.png')} 1x, ${getImageUrl(
            '/images/landing/hero_image_2x.png'
          )} 2x`}
        />
        <img
          src={getImageUrl('/images/landing/hero_image_1x.png')}
          srcSet={`${getImageUrl('/images/landing/hero_image_1x_small.png')} 1x, ${getImageUrl(
            '/images/landing/hero_image_2x_small.png'
          )} 2x`}
          style={styles.heroImage}
          alt={intl.formatMessage({ id: 'landing.hero.imgAlt' })}
        />
      </picture>
      <div style={styles.heroContent} ref={heroRef}>
        <Text typography="titleLarge" style={styles.title}>
          <span data-testid="hero-header">
            <FormattedMessage id="landing.hero.title" />
          </span>
        </Text>
        <Text typography="bodyMedium2" style={styles.subTitle}>
          <FormattedMessage id="landing.hero.subtitle" />
        </Text>
        <PrimaryAnchorButton style={styles.startNow} href={SIGNUP_PATH}>
          <FormattedMessage id="common.startNow" />
        </PrimaryAnchorButton>
      </div>
    </div>
  )
}

export default Radium(Hero)
