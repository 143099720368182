import { FormikConfig } from 'formik'
import {
  ReactNode,
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { useHistory } from 'react-router-dom'
import { boldSpanFormattingHelper, useIntl } from 'common'
import { ClientError, getNexusApiErrorMessagesForParams } from 'common/utils'
import { NotificationType, useNotifier } from 'context'
import { FormatSchemasApi } from 'service/apis/FormatSchemasApi'
import { PrototypeCampaignsApi } from 'service/apis/PrototypeCampaignsApi'
import { GetFormatSchemasTypeSchemaTypeEnum } from 'service/openapi/__codegen__/apis/FormatSchemasApi'
import { PrototypeCampaignResponse } from 'service/openapi/__codegen__/models/PrototypeCampaignResponse'
import { PrototypeCampaignResponseData } from 'service/openapi/__codegen__/models/PrototypeCampaignResponseData'
import { PrototypeCampaignFormData, FormatSchemaType } from '../types'
import { useCampaignId, useSchemaMachineName, usePrototypeCampaignFormRoutePaths } from './routes'
import { buildCreatePrototypeCampaignRequest, buildUpdatePrototypeCampaignRequest } from './utils'

type PrototypeCampaignFormikOnSubmit = FormikConfig<PrototypeCampaignFormData>['onSubmit']

type PrototypeCampaignsContextState = {
  campaignId: string | undefined
  campaign: PrototypeCampaignResponseData | undefined
  schemaMachineName: string | undefined
  schema: FormatSchemaType | undefined
  loading: boolean
  saveCampaign: PrototypeCampaignFormikOnSubmit
  goToDashboard: () => void
  setLoading: (loading: boolean) => void
  expandBrandPageSections: boolean
  setExpandBrandPageSections: Dispatch<SetStateAction<boolean>>
  setCurrentAdGroupName: undefined
  setSchemaMachineName: (schemaMachineName: string | undefined) => void
}

export const PrototypeCampaignsContext = createContext<PrototypeCampaignsContextState | undefined>(
  undefined
)

export const usePrototypeCampaignsContext = () => {
  const contextState = useContext(PrototypeCampaignsContext)
  if (!contextState) {
    throw new Error(
      'usePrototypeCampaignContextState should be used in PrototypeCampaignContextProvider component'
    )
  }

  return contextState
}

interface PrototypeCampaignsContextProviderProps {
  children: ReactNode
}

function PrototypeCampaignsContextProvider({ children }: PrototypeCampaignsContextProviderProps) {
  const [schema, setSchema] = useState<FormatSchemaType | undefined>(undefined)
  const [campaign, setCampaign] = useState<PrototypeCampaignResponseData | undefined>(undefined)
  const [schemaMachineName, setSchemaMachineName] = useState<string | undefined>(
    useSchemaMachineName()
  )
  const [loading, setLoading] = useState(false)
  const [expandBrandPageSections, setExpandBrandPageSections] = useState(false)

  const campaignId = useCampaignId()
  const prototypeCampaignFormRoutePaths = usePrototypeCampaignFormRoutePaths()
  const intl = useIntl()
  const notifier = useNotifier()
  const history = useHistory()

  const goToDashboard = () => {
    history.push(prototypeCampaignFormRoutePaths.DASHBOARD_PATH)
  }

  const getCampaign = useCallback(async () => {
    if (campaignId) {
      const campaignResponse = await PrototypeCampaignsApi.getPrototypeCampaignsId({
        id: campaignId,
      })
      setCampaign(campaignResponse.data)
      setSchemaMachineName(campaignResponse.data.attributes.adGroup?.attributes.creative.type)
    }
  }, [campaignId])

  const getSchema = useCallback(async () => {
    if (schemaMachineName) {
      const schemaResponseData = await FormatSchemasApi.getFormatSchemasTypeSchema({
        machineName: schemaMachineName,
        type: GetFormatSchemasTypeSchemaTypeEnum.Creatives,
        includeAssetDetails: true,
        includePlacementDetails: true,
      })

      const typedSchema = schemaResponseData?.data?.schema as FormatSchemaType
      setSchema(typedSchema)
    }
  }, [schemaMachineName])

  // Initialize the campaign
  useEffect(() => {
    if (campaignId && campaign?.id !== campaignId) {
      getCampaign()
    }
  }, [campaignId, campaign?.id, getCampaign])

  // Initialize the schema
  useEffect(() => {
    if (schemaMachineName && schema?.machine_name !== schemaMachineName) {
      getSchema()
    }
  }, [schemaMachineName, schema?.machine_name, getSchema])

  const saveCampaign: PrototypeCampaignFormikOnSubmit = async (
    formValues: PrototypeCampaignFormData,
    { setErrors }
  ) => {
    setLoading(true)
    let campaignResponse: PrototypeCampaignResponse | undefined
    try {
      if (campaignId) {
        const adGroupId = campaign?.attributes.adGroup?.id
        campaignResponse = await PrototypeCampaignsApi.putPrototypeCampaignsId(
          buildUpdatePrototypeCampaignRequest(campaignId, adGroupId, formValues, schema)
        )
      } else {
        campaignResponse = await PrototypeCampaignsApi.postPrototypeCampaigns(
          buildCreatePrototypeCampaignRequest(formValues, schema)
        )
      }
    } catch (err) {
      const { paramErrors, otherErrors } = getNexusApiErrorMessagesForParams(
        intl,
        err as ClientError,
        Object.keys(formValues)
      )

      if (paramErrors && Object.values(paramErrors).length) {
        setErrors(paramErrors)
      } else if (otherErrors) {
        notifier.sendNotification({
          message: otherErrors,
          type: NotificationType.ERROR,
        })
      } else {
        notifier.sendNotification({
          messageId: 'common.error.generic',
          type: NotificationType.ERROR,
        })
      }
    } finally {
      setLoading(false)
    }

    // Failed to save the campaign, do not redirect
    if (!campaignResponse) {
      return
    }

    setCampaign(campaignResponse.data)
    goToDashboard()
    notifier.sendNotification({
      messageId: 'pages.displayProduct.notification.save.success',
      messageValues: { name: formValues.name, ...boldSpanFormattingHelper },
      type: NotificationType.SUCCESS,
    })
  }

  const contextStateValue: PrototypeCampaignsContextState = {
    campaignId,
    campaign,
    schemaMachineName,
    schema,
    loading,
    saveCampaign,
    goToDashboard,
    setLoading,
    expandBrandPageSections,
    setExpandBrandPageSections,
    setCurrentAdGroupName: undefined,
    setSchemaMachineName,
  }
  return (
    <PrototypeCampaignsContext.Provider value={contextStateValue}>
      {children}
    </PrototypeCampaignsContext.Provider>
  )
}

export default PrototypeCampaignsContextProvider
