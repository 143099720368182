import { css } from '@emotion/react'
import { InformationIcon, LoadingLockupTextBase, useTheme } from '@instacart/ids-core'
import { uniqueId } from 'lodash'
import moment from 'moment'
import { useRef, useState, useMemo } from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  Area,
  ReferenceLineProps,
  ReferenceLine,
  ReferenceArea,
  TooltipPayload,
  ScaleType,
} from 'recharts'
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent'
import { isJestEnvironment } from 'common/utils'
import { formatDate, RANGE_FORMAT, SHORT_DATE_FORMAT } from 'common/utils-moment'
import { getReferenceAreaRanges } from 'components/utils/chartBodyHelper'

const pendingYKey = 'pending'
const obscureYKey = 'obscuring'
const lastUpdatedKey = 'lastUpdated'

const DEFAULT_Y_AXIS_WIDTH = 65
const Y_AXIS_SELECTOR = '.recharts-yAxis'

// Chart will not render in the testing env without a set numeric width
const defaultTestingWidth = isJestEnvironment() ? 200 : '100%'

export interface ReferenceLineWithKeyProps extends ReferenceLineProps {
  key: string
}

export interface ChartBodyProps<XKey extends string, YKey extends string> {
  border: boolean
  xAxisKey?: XKey
  yAxisKey?: YKey
  xAxisScale?: ScaleType
  yAxisScale?: ScaleType
  data: ({ [key in XKey | YKey]: string | number } | Record<string, string | number>)[]
  yAxisTooltipLabel?: string
  yAxisTooltipValue: (value: number) => string
  loading?: boolean
  lastUpdated?: string
  lineColor: string
  fill?: boolean
  padded?: boolean
  autoYAxisWidth?: boolean
  hideLastYAxisTick?: boolean
  referenceLines?: ReferenceLineWithKeyProps[]
  height?: string | number
  width?: string | number
  tooltipHelperText?: (value: string | number) => string | null
  obscureXKeys?: (string | number)[]
  obscureMetricMessage?: string
}

const loadingBoxStyle = {
  container: { height: '100%' },
}

function isNumberArray(values: unknown[]): values is number[] {
  for (let i = 0; i < values.length; i += 1) {
    if (typeof values[i] !== 'string') {
      return false
    }
  }
  return true
}

function useStyles({ padded }: { padded: boolean }) {
  const theme = useTheme()
  return {
    root: css({
      background: '#fff',
      borderTop: `1px solid ${theme.colors.systemGrayscale20}`,
      borderRadius: '0 0 6px 6px',
      padding: padded ? 30 : undefined,
    }),
    bordered: css({ border: `1px solid ${theme.colors.systemGrayscale20}` }),
    hideLastYAxisTick: css({
      '.recharts-yAxis .recharts-cartesian-axis-tick:last-of-type': {
        visibility: 'hidden',
      },
      '.recharts-cartesian-grid-horizontal line:last-of-type': {
        visibility: 'hidden',
      },
    }),
  }
}

export default function ChartBody<XKey extends string, YKey extends string>({
  border = true,
  data,
  xAxisKey = 'x' as XKey,
  yAxisKey = 'y' as YKey,
  xAxisScale,
  yAxisScale,
  loading,
  yAxisTooltipLabel,
  yAxisTooltipValue,
  lastUpdated,
  lineColor,
  fill,
  padded = true,
  autoYAxisWidth = false,
  hideLastYAxisTick = false,
  referenceLines = [],
  height = 360,
  width = defaultTestingWidth,
  tooltipHelperText,
  obscureXKeys = [],
  obscureMetricMessage,
}: ChartBodyProps<XKey, YKey>) {
  const obscureXKeysSorted = obscureXKeys.sort()

  const obscureData = (
    chartData: ChartBodyProps<XKey, YKey>['data'],
    chartObscureXKeys: (string | number)[]
  ): ChartBodyProps<XKey, YKey>['data'] => {
    return chartData.map(dataPoint => {
      if (chartObscureXKeys?.includes(dataPoint[xAxisKey])) {
        return {
          [xAxisKey]: dataPoint[xAxisKey],
          // Remove [yAxisKey] as it is obscured
          [obscureYKey]: 0,
        }
      }
      return dataPoint
    })
  }

  const dataObscured: ChartBodyProps<XKey, YKey>['data'] = obscureData(data, obscureXKeysSorted)

  const styles = useStyles({ padded })
  const [yAxisWidth, setYAxisWidth] = useState(DEFAULT_Y_AXIS_WIDTH)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const uniqeIdPrefix = useMemo(() => uniqueId('ChartBody-'), [])

  const yAxisWidthUpdate = () => {
    if (!containerRef.current) return

    const yAxis = containerRef.current.querySelector(Y_AXIS_SELECTOR)
    const newYAxisWidth = yAxis?.getClientRects()?.[0]?.width

    if (newYAxisWidth && newYAxisWidth > 0) {
      setYAxisWidth(Math.round(newYAxisWidth))
    }
  }

  const xAxisValues: (string | number)[] = dataObscured.map(dataPoint => dataPoint[xAxisKey]).sort()
  const referenceAreaRanges = getReferenceAreaRanges(xAxisValues, obscureXKeysSorted)

  const yAxisValues: (string | number)[] = dataObscured
    .filter(dataPoint => !!dataPoint[yAxisKey])
    .map(dataPoint => dataPoint[yAxisKey])

  // to distinguish significantly low values from 0
  let modifiedMin
  let modifiedMax

  if (isNumberArray(yAxisValues)) {
    const dataMin = Math.min(...yAxisValues)
    const dataMax = Math.max(...yAxisValues)
    modifiedMin = dataMin < dataMax * 0.15 ? 0 : dataMin * 0.9
    modifiedMax = dataMax
  }

  const todayRaw = moment()
  // TODO: update utc offsets when timezone is configured on BE
  const today = todayRaw.isDST() ? todayRaw : todayRaw.utcOffset('-01:00')

  const transformDataToMaskCurrentDay = (dataToTransform: ChartBodyProps<XKey, YKey>['data']) => {
    const latestDataPoint = dataToTransform.slice(-1)[0]
    const latestDataDate = moment(latestDataPoint[xAxisKey], RANGE_FORMAT)
    const showPending = today.isSame(latestDataDate, 'day') || today.isBefore(latestDataDate, 'day')
    if (showPending) {
      if (dataToTransform.length > 1) {
        const dataPrevious = dataToTransform.slice(0, -2)
        const dataPending = dataToTransform.slice(-2)
        dataPending[0] = {
          ...dataPending[0],
          [pendingYKey]: dataPending[0][yAxisKey],
        }
        dataPending[1] = {
          [xAxisKey]: dataPending[1][xAxisKey],
          [pendingYKey]: dataPending[1][yAxisKey],
        }
        return dataPrevious.concat(dataPending)
      }
      return [
        {
          [xAxisKey]: dataToTransform[0][xAxisKey],
          [pendingYKey]: dataToTransform[0][yAxisKey],
        },
      ]
    }
    return dataToTransform
  }

  const getKeyObscureText = (label: string) => {
    return (
      <div style={{ display: 'flex' }}>
        <InformationIcon size={20} color="brandExpressRegular" />
        <p style={{ color: '#343538', marginLeft: 5 }}> {label} </p>
      </div>
    )
  }

  // eslint-disable-next-line react/no-multi-comp
  const CustomTooltip = (tooltipProps: TooltipProps) => {
    // is the value a valid date in the past or invalid in the future
    const isLastUpdatedValid = moment(lastUpdated).isBefore()
    const lastUpdatedValue = isLastUpdatedValid
      ? moment(lastUpdated).from(today)
      : moment(lastUpdated).subtract(7, 'hours').from(today)
    const lastUpdatedPayload: TooltipPayload = {
      name: lastUpdatedKey,
      color: '#BDBDBD',
      value: lastUpdatedValue,
    }

    if (!tooltipProps.active || !tooltipProps.payload) {
      return null
    }

    const containsBothYKeys = tooltipProps.payload.length > 1
    const yAxisPayload = tooltipProps.payload.find(payload => payload.name === yAxisKey)
    const singlePayload = containsBothYKeys && yAxisPayload ? [yAxisPayload] : tooltipProps.payload
    const isPendingPayload = singlePayload[0] && singlePayload[0].name === pendingYKey
    const singlePayloadWUpdated =
      isPendingPayload && lastUpdated ? [...singlePayload, lastUpdatedPayload] : singlePayload

    let helperTextPayload: TooltipPayload[] | undefined
    if (tooltipHelperText && tooltipProps.label) {
      const helperText = tooltipHelperText(tooltipProps.label)
      if (helperText) {
        helperTextPayload = [
          {
            name: '',
            color: '#BDBDBD',
            value: '',
            formatter: () => [<hr style={{ border: 0, borderTop: '1px solid #ccc' }} />, null],
          },
          {
            name: '',
            value: '',
            formatter: () => [helperText, null],
          },
        ]
      }
    }
    const singlePayloadWUpdatedWithHelperText = helperTextPayload
      ? [...singlePayloadWUpdated, ...helperTextPayload]
      : singlePayloadWUpdated

    const formattedTooltipProps = {
      ...tooltipProps,
      label: formatDate(tooltipProps.label as string, SHORT_DATE_FORMAT),
    }

    return (
      <DefaultTooltipContent
        {...formattedTooltipProps}
        payload={singlePayloadWUpdatedWithHelperText}
        contentStyle={{ width: '260px', whiteSpace: 'wrap' }}
      />
    )
  }

  return (
    <div
      css={[styles.root, border && styles.bordered, hideLastYAxisTick && styles.hideLastYAxisTick]}
      data-testid="chart-body"
      ref={containerRef}
    >
      <ResponsiveContainer width={width} height={height}>
        {loading ? (
          <LoadingLockupTextBase styles={loadingBoxStyle} />
        ) : (
          <AreaChart
            data={transformDataToMaskCurrentDay(dataObscured)}
            margin={padded ? { top: 10, right: 30, left: 0, bottom: 0 } : undefined}
            ref={autoYAxisWidth ? yAxisWidthUpdate : undefined}
          >
            <defs>
              <linearGradient id={`${uniqeIdPrefix}-chart-line`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={lineColor} stopOpacity={0.25} />
                <stop offset="100%" stopColor={lineColor} stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id={`${uniqeIdPrefix}-chart-line-no-fill`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor="#fff" stopOpacity={0} />
                <stop offset="100%" stopColor="fff" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id={`${uniqeIdPrefix}-chart-line-pending`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor="#BDBDBD" stopOpacity={0.25} />
                <stop offset="100%" stopColor="#BDBDBD" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={xAxisKey}
              scale={xAxisScale}
              axisLine={false}
              interval="preserveStartEnd"
              tickSize={8}
              tickMargin={4}
              minTickGap={24}
              tickFormatter={date => formatDate(date, SHORT_DATE_FORMAT)}
            />
            <YAxis
              dataKey={yAxisKey}
              scale={yAxisScale}
              axisLine={false}
              tickSize={0}
              tickMargin={4}
              width={yAxisWidth}
              tickFormatter={yAxisTooltipValue}
              domain={
                modifiedMin !== undefined && modifiedMax !== undefined
                  ? [modifiedMin, modifiedMax]
                  : undefined
              }
            />
            <CartesianGrid vertical={false} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name) => {
                if (name === pendingYKey) {
                  return [
                    `${yAxisTooltipValue(value as number)} (pending)`,
                    yAxisTooltipLabel || name,
                  ]
                }
                if (name === lastUpdatedKey) {
                  return [value, 'last updated']
                }
                if (name === obscureYKey && obscureMetricMessage) {
                  return [getKeyObscureText(obscureMetricMessage), null]
                }
                return [yAxisTooltipValue(value as number), yAxisTooltipLabel || name]
              }}
              content={CustomTooltip}
            />
            <Area type="linear" dataKey={obscureYKey} stroke={lineColor} strokeWidth={0} />
            <Area
              type="linear"
              dataKey={yAxisKey}
              stroke={lineColor}
              strokeWidth={2}
              fillOpacity={1}
              fill={
                fill
                  ? `url(#${uniqeIdPrefix}-chart-line)`
                  : `url(#${uniqeIdPrefix}-chart-line-no-fill)`
              }
              isAnimationActive={false}
            />
            <Area
              type="linear"
              dataKey={pendingYKey}
              stroke={lineColor}
              fillOpacity={1}
              fill={`url(#${uniqeIdPrefix}-chart-line-pending)`}
              strokeDasharray="3 3"
              strokeWidth={2}
              isAnimationActive={false}
            />
            {referenceLines.map(({ key, ...referenceLineProps }) => (
              <ReferenceLine key={key} {...referenceLineProps} />
            ))}

            {referenceAreaRanges.map(({ rangeStart, rangeEnd }) => (
              <ReferenceArea x1={rangeStart} x2={rangeEnd} fill="#F6F7F8" />
            ))}
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  )
}
