import { css } from '@emotion/react'
import { ChevronDownIcon, Theme, useTheme } from '@instacart/ids-core'
import { InputDescriptionErrorText } from '@instacart/ids-tooling'
// eslint-disable-next-line import/no-duplicates
import Select, { createFilter, Props, StylesConfig } from 'react-select'
// eslint-disable-next-line import/no-duplicates
import Option from 'react-select'
// eslint-disable-next-line import/no-duplicates
import FilterOptionOption from 'react-select'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import layers from 'common/layers'

interface MultiSelectProps {
  isMultiSelect: boolean
  placeholder: string
  error?: string
  whiteBackground?: string
  stringify?: (option: FilterOptionOption<Option>) => string
}

function useStyles<
  OptionType extends OptionTypeBase,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(theme: Theme, error: string, whiteBackground: string) {
  const selectStyles: StylesConfig<OptionType, true, GroupType> = {
    option: base => ({
      ...base,
      ...theme.typography.bodyMedium1,
      ':first-child': {
        borderRadius: '12px 12px 0px 0px',
      },
      ':last-child': {
        borderRadius: '0px 0px 12px 12px',
      },
      ':only-child': {
        borderRadius: '12px 12px 12px 12px',
      },
    }),
    indicatorSeparator: base => ({ ...base, display: 'none' }),
    indicatorsContainer: base => ({
      ...base,
      svg: { fill: theme.colors.systemGrayscale70, padding: '8px' },
    }),
    multiValue: base => ({ ...base, backgroundColor: theme.colors.systemGrayscale20 }),
    menuList: base => ({
      ...base,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    control: base => ({
      ...base,
      backgroundColor: error
        ? theme.colors.systemDetrimentalLight
        : whiteBackground
        ? theme.colors.systemGrayscale00
        : theme.colors.systemGrayscale10,
      height: '40px',
      borderRadius: '8px',
      border: error ? `1px ${theme.colors.systemDetrimentalDark} solid` : 'none',
    }),
    placeholder: base => ({
      ...base,
      color: error ? theme.colors.systemDetrimentalDark : theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium1,
    }),
    input: base => ({
      ...base,
      color: theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium1,
    }),
    menu: base => ({
      ...base,
      zIndex: layers[2],
      borderRadius: '12px',
      marginTop: '34px',
      backgroundColor: theme.colors.systemGrayscale00,
    }),
    singleValue: base => ({ ...base, ...theme.typography.bodyMedium1 }),
  }

  return selectStyles
}

const MultiSelect = <
  OptionType extends OptionTypeBase = { label: string | JSX.Element; value: string },
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: Props<OptionType, true, GroupType> | MultiSelectProps
) => {
  const theme = useTheme()
  const { isMultiSelect, error, whiteBackground, stringify } = props
  const styles = useStyles<OptionType, GroupType>(theme, error, whiteBackground)
  const IndicatorStyles = css({
    display: 'flex',
    marginRight: '15px',
  })
  const IndicatorsContainer = () => (
    <div css={IndicatorStyles}>{<ChevronDownIcon size="24px" />}</div>
  )

  const defaultFilterOptions = { ignoreAccents: false }

  const filterOptions = stringify ? { ...defaultFilterOptions, stringify } : defaultFilterOptions
  return (
    <>
      <Select<OptionType, true, GroupType>
        styles={styles}
        isMulti={isMultiSelect}
        closeMenuOnSelect={!isMultiSelect}
        backspaceRemovesValue={false}
        filterOption={createFilter(filterOptions)}
        {...props}
        components={{ IndicatorsContainer }}
      />

      {error && <InputDescriptionErrorText id="input-desc-id">{error}</InputDescriptionErrorText>}
    </>
  )
}

export default MultiSelect
