// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamDataAttributesAnalytics } from './GetBrandPagesParamDataAttributesAnalytics'
import {
  GetBrandPagesParamDataAttributesAnalyticsFromJSON,
  GetBrandPagesParamDataAttributesAnalyticsFromJSONTyped,
  GetBrandPagesParamDataAttributesAnalyticsToJSON,
} from './GetBrandPagesParamDataAttributesAnalytics'
import type { GetBrandPagesParamDataAttributesBlocks } from './GetBrandPagesParamDataAttributesBlocks'
import {
  GetBrandPagesParamDataAttributesBlocksFromJSON,
  GetBrandPagesParamDataAttributesBlocksFromJSONTyped,
  GetBrandPagesParamDataAttributesBlocksToJSON,
} from './GetBrandPagesParamDataAttributesBlocks'
import type { GetBrandPagesParamDataAttributesEditorialStates } from './GetBrandPagesParamDataAttributesEditorialStates'
import {
  GetBrandPagesParamDataAttributesEditorialStatesFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStates'
import type { GetBrandPagesParamDataAttributesLinkedBrands } from './GetBrandPagesParamDataAttributesLinkedBrands'
import {
  GetBrandPagesParamDataAttributesLinkedBrandsFromJSON,
  GetBrandPagesParamDataAttributesLinkedBrandsFromJSONTyped,
  GetBrandPagesParamDataAttributesLinkedBrandsToJSON,
} from './GetBrandPagesParamDataAttributesLinkedBrands'

/**
 *
 * @export
 * @interface GetBrandPagesParamDataAttributes
 */
export interface GetBrandPagesParamDataAttributes {
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  type: GetBrandPagesParamDataAttributesTypeEnum
  /**
   * A descriptive name for the page, visible to advertiser only.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  name: string
  /**
   * The status of the page.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  status: GetBrandPagesParamDataAttributesStatusEnum
  /**
   * What purpose the page represents, must be one of EVERGREEN, SEASONAL. Depending on purpose, page hierarchy may require additional inputs.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  purpose: GetBrandPagesParamDataAttributesPurposeEnum
  /**
   * The URL of the page.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  brandPageUrl: string
  /**
   * Indicates whether the page is enabled. Page is disabled/paused if set to false.
   * @type {boolean}
   * @memberof GetBrandPagesParamDataAttributes
   */
  enabled: boolean
  /**
   * What level the page represents.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  level: GetBrandPagesParamDataAttributesLevelEnum
  /**
   * Input of brand names desired for the page in increasing specificity i.e. ['Brand', 'Sub-Brand', 'Custom theme/group']. Will be used to generate the path of the page.
   * @type {Array<string>}
   * @memberof GetBrandPagesParamDataAttributes
   */
  pageHierarchy: Array<string>
  /**
   * The page's latest approval submission date.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  submittedDate?: string
  /**
   * Whether or not the page has served. If true, this indicates that the page inputs may not be safe to change since it could break any URLs shared.
   * @type {boolean}
   * @memberof GetBrandPagesParamDataAttributes
   */
  hasServed?: boolean
  /**
   * Ordered collection of page blocks and their content.
   * @type {Array<GetBrandPagesParamDataAttributesBlocks>}
   * @memberof GetBrandPagesParamDataAttributes
   */
  blocks: Array<GetBrandPagesParamDataAttributesBlocks>
  /**
   * Ads Taxonomy entities linked to this Brand Page
   * @type {Array<GetBrandPagesParamDataAttributesLinkedBrands>}
   * @memberof GetBrandPagesParamDataAttributes
   */
  linkedBrands?: Array<GetBrandPagesParamDataAttributesLinkedBrands>
  /**
   * The admin ui state of the brand page.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributes
   */
  adminUiState?: GetBrandPagesParamDataAttributesAdminUiStateEnum
  /**
   *
   * @type {GetBrandPagesParamDataAttributesAnalytics}
   * @memberof GetBrandPagesParamDataAttributes
   */
  analytics?: GetBrandPagesParamDataAttributesAnalytics
  /**
   * Editorial states
   * @type {Array<GetBrandPagesParamDataAttributesEditorialStates>}
   * @memberof GetBrandPagesParamDataAttributes
   */
  editorialStates?: Array<GetBrandPagesParamDataAttributesEditorialStates>
  /**
   * Temporary flag to determine if a brand page is lychee.
   * @type {boolean}
   * @memberof GetBrandPagesParamDataAttributes
   */
  isLycheeEntity: boolean
}

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesTypeEnum = {
  EvergreenV1: 'evergreen.v1',
  ProductGroupEvergreenV1: 'product_group_evergreen.v1',
} as const
export type GetBrandPagesParamDataAttributesTypeEnum =
  typeof GetBrandPagesParamDataAttributesTypeEnum[keyof typeof GetBrandPagesParamDataAttributesTypeEnum]

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetBrandPagesParamDataAttributesStatusEnum =
  typeof GetBrandPagesParamDataAttributesStatusEnum[keyof typeof GetBrandPagesParamDataAttributesStatusEnum]

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesPurposeEnum = {
  Evergreen: 'EVERGREEN',
  Seasonal: 'SEASONAL',
} as const
export type GetBrandPagesParamDataAttributesPurposeEnum =
  typeof GetBrandPagesParamDataAttributesPurposeEnum[keyof typeof GetBrandPagesParamDataAttributesPurposeEnum]

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesLevelEnum = {
  Brand: 'BRAND',
  Parent: 'PARENT',
  SubBrand: 'SUB_BRAND',
} as const
export type GetBrandPagesParamDataAttributesLevelEnum =
  typeof GetBrandPagesParamDataAttributesLevelEnum[keyof typeof GetBrandPagesParamDataAttributesLevelEnum]

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesAdminUiStateEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetBrandPagesParamDataAttributesAdminUiStateEnum =
  typeof GetBrandPagesParamDataAttributesAdminUiStateEnum[keyof typeof GetBrandPagesParamDataAttributesAdminUiStateEnum]

/**
 * Check if a given object implements the GetBrandPagesParamDataAttributes interface.
 */
export function instanceOfGetBrandPagesParamDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'purpose' in value
  isInstance = isInstance && 'brandPageUrl' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'level' in value
  isInstance = isInstance && 'pageHierarchy' in value
  isInstance = isInstance && 'blocks' in value
  isInstance = isInstance && 'isLycheeEntity' in value

  return isInstance
}

export function GetBrandPagesParamDataAttributesFromJSON(
  json: any
): GetBrandPagesParamDataAttributes {
  return GetBrandPagesParamDataAttributesFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    name: json['name'],
    status: json['status'],
    purpose: json['purpose'],
    brandPageUrl: json['brand_page_url'],
    enabled: json['enabled'],
    level: json['level'],
    pageHierarchy: json['page_hierarchy'],
    submittedDate: !exists(json, 'submitted_date') ? undefined : json['submitted_date'],
    hasServed: !exists(json, 'has_served') ? undefined : json['has_served'],
    blocks: (json['blocks'] as Array<any>).map(GetBrandPagesParamDataAttributesBlocksFromJSON),
    linkedBrands: !exists(json, 'linked_brands')
      ? undefined
      : (json['linked_brands'] as Array<any>).map(
          GetBrandPagesParamDataAttributesLinkedBrandsFromJSON
        ),
    adminUiState: !exists(json, 'admin_ui_state') ? undefined : json['admin_ui_state'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetBrandPagesParamDataAttributesAnalyticsFromJSON(json['analytics']),
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          GetBrandPagesParamDataAttributesEditorialStatesFromJSON
        ),
    isLycheeEntity: json['is_lychee_entity'],
  }
}

export function GetBrandPagesParamDataAttributesToJSON(
  value?: GetBrandPagesParamDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    name: value.name,
    status: value.status,
    purpose: value.purpose,
    brand_page_url: value.brandPageUrl,
    enabled: value.enabled,
    level: value.level,
    page_hierarchy: value.pageHierarchy,
    submitted_date: value.submittedDate,
    has_served: value.hasServed,
    blocks: (value.blocks as Array<any>).map(GetBrandPagesParamDataAttributesBlocksToJSON),
    linked_brands:
      value.linkedBrands === undefined
        ? undefined
        : (value.linkedBrands as Array<any>).map(
            GetBrandPagesParamDataAttributesLinkedBrandsToJSON
          ),
    admin_ui_state: value.adminUiState,
    analytics: GetBrandPagesParamDataAttributesAnalyticsToJSON(value.analytics),
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            GetBrandPagesParamDataAttributesEditorialStatesToJSON
          ),
    is_lychee_entity: value.isLycheeEntity,
  }
}
