// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBrandPageFieldDecisionsHierarchy } from './GetBrandPagesParamBrandPageFieldDecisionsHierarchy'
import {
  GetBrandPagesParamBrandPageFieldDecisionsHierarchyFromJSON,
  GetBrandPagesParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  GetBrandPagesParamBrandPageFieldDecisionsHierarchyToJSON,
} from './GetBrandPagesParamBrandPageFieldDecisionsHierarchy'
import type { GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValues } from './GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValues'
import {
  GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValues'
import type { GetBrandPagesParamBrandPageFieldDecisionsTitle } from './GetBrandPagesParamBrandPageFieldDecisionsTitle'
import {
  GetBrandPagesParamBrandPageFieldDecisionsTitleFromJSON,
  GetBrandPagesParamBrandPageFieldDecisionsTitleFromJSONTyped,
  GetBrandPagesParamBrandPageFieldDecisionsTitleToJSON,
} from './GetBrandPagesParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface GetBrandPagesParamBrandPageFieldDecisions
 */
export interface GetBrandPagesParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetBrandPagesParamBrandPageFieldDecisionsTitle}
   * @memberof GetBrandPagesParamBrandPageFieldDecisions
   */
  title?: GetBrandPagesParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<GetBrandPagesParamBrandPageFieldDecisionsHierarchy>}
   * @memberof GetBrandPagesParamBrandPageFieldDecisions
   */
  hierarchy?: Array<GetBrandPagesParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof GetBrandPagesParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the GetBrandPagesParamBrandPageFieldDecisions interface.
 */
export function instanceOfGetBrandPagesParamBrandPageFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetBrandPagesParamBrandPageFieldDecisionsFromJSON(
  json: any
): GetBrandPagesParamBrandPageFieldDecisions {
  return GetBrandPagesParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function GetBrandPagesParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : GetBrandPagesParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          GetBrandPagesParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function GetBrandPagesParamBrandPageFieldDecisionsToJSON(
  value?: GetBrandPagesParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: GetBrandPagesParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            GetBrandPagesParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(value.taxonomy, GetBrandPagesParamBrandPageFieldDecisionsTaxonomyValuesToJSON),
  }
}
