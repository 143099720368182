// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MultiFactorAuthCreateResponseData } from './MultiFactorAuthCreateResponseData'
import {
  MultiFactorAuthCreateResponseDataFromJSON,
  MultiFactorAuthCreateResponseDataFromJSONTyped,
  MultiFactorAuthCreateResponseDataToJSON,
} from './MultiFactorAuthCreateResponseData'
import type { MultiFactorAuthCreateResponseMeta } from './MultiFactorAuthCreateResponseMeta'
import {
  MultiFactorAuthCreateResponseMetaFromJSON,
  MultiFactorAuthCreateResponseMetaFromJSONTyped,
  MultiFactorAuthCreateResponseMetaToJSON,
} from './MultiFactorAuthCreateResponseMeta'

/**
 *
 * @export
 * @interface MultiFactorAuthCreateResponse
 */
export interface MultiFactorAuthCreateResponse {
  /**
   *
   * @type {MultiFactorAuthCreateResponseData}
   * @memberof MultiFactorAuthCreateResponse
   */
  data: MultiFactorAuthCreateResponseData
  /**
   *
   * @type {MultiFactorAuthCreateResponseMeta}
   * @memberof MultiFactorAuthCreateResponse
   */
  meta: MultiFactorAuthCreateResponseMeta
}

/**
 * Check if a given object implements the MultiFactorAuthCreateResponse interface.
 */
export function instanceOfMultiFactorAuthCreateResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function MultiFactorAuthCreateResponseFromJSON(json: any): MultiFactorAuthCreateResponse {
  return MultiFactorAuthCreateResponseFromJSONTyped(json, false)
}

export function MultiFactorAuthCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MultiFactorAuthCreateResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: MultiFactorAuthCreateResponseDataFromJSON(json['data']),
    meta: MultiFactorAuthCreateResponseMetaFromJSON(json['meta']),
  }
}

export function MultiFactorAuthCreateResponseToJSON(
  value?: MultiFactorAuthCreateResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: MultiFactorAuthCreateResponseDataToJSON(value.data),
    meta: MultiFactorAuthCreateResponseMetaToJSON(value.meta),
  }
}
