// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { IndexReportsResponseDataAttributesReportMeta } from './IndexReportsResponseDataAttributesReportMeta'
import {
  IndexReportsResponseDataAttributesReportMetaFromJSON,
  IndexReportsResponseDataAttributesReportMetaFromJSONTyped,
  IndexReportsResponseDataAttributesReportMetaToJSON,
} from './IndexReportsResponseDataAttributesReportMeta'

/**
 *
 * @export
 * @interface IndexReportsResponseDataAttributes
 */
export interface IndexReportsResponseDataAttributes {
  /**
   * The name of the report
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  name: string
  /**
   * The type of report
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  reportType: string
  /**
   * Object of parameters used to request the report.
   * @type {{ [key: string]: any; }}
   * @memberof IndexReportsResponseDataAttributes
   */
  reportRequestParams: { [key: string]: any }
  /**
   * The status of the report.
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  status: IndexReportsResponseDataAttributesStatusEnum
  /**
   * Endpoint that can be used to download the report.
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  location: string
  /**
   * The location of the report in s3.
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  s3Location: string
  /**
   * Size of the report file.
   * @type {number}
   * @memberof IndexReportsResponseDataAttributes
   */
  fileSize: number
  /**
   * Timestamp for when the report was generated.
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  generatedAt: string
  /**
   * Timestamp for when the report was requested
   * @type {string}
   * @memberof IndexReportsResponseDataAttributes
   */
  createdAt: string
  /**
   * Report has metric obscured
   * @type {boolean}
   * @memberof IndexReportsResponseDataAttributes
   */
  hasObscuredMetrics?: boolean
  /**
   *
   * @type {IndexReportsResponseDataAttributesReportMeta}
   * @memberof IndexReportsResponseDataAttributes
   */
  reportMeta: IndexReportsResponseDataAttributesReportMeta
}

/**
 * @export
 */
export const IndexReportsResponseDataAttributesStatusEnum = {
  Pending: 'pending',
  Created: 'created',
  InProgress: 'in_progress',
  Completed: 'completed',
  Failed: 'failed',
} as const
export type IndexReportsResponseDataAttributesStatusEnum =
  typeof IndexReportsResponseDataAttributesStatusEnum[keyof typeof IndexReportsResponseDataAttributesStatusEnum]

/**
 * Check if a given object implements the IndexReportsResponseDataAttributes interface.
 */
export function instanceOfIndexReportsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'reportType' in value
  isInstance = isInstance && 'reportRequestParams' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'location' in value
  isInstance = isInstance && 's3Location' in value
  isInstance = isInstance && 'fileSize' in value
  isInstance = isInstance && 'generatedAt' in value
  isInstance = isInstance && 'createdAt' in value
  isInstance = isInstance && 'reportMeta' in value

  return isInstance
}

export function IndexReportsResponseDataAttributesFromJSON(
  json: any
): IndexReportsResponseDataAttributes {
  return IndexReportsResponseDataAttributesFromJSONTyped(json, false)
}

export function IndexReportsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexReportsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    reportType: json['report_type'],
    reportRequestParams: json['report_request_params'],
    status: json['status'],
    location: json['location'],
    s3Location: json['s3_location'],
    fileSize: json['file_size'],
    generatedAt: json['generated_at'],
    createdAt: json['created_at'],
    hasObscuredMetrics: !exists(json, 'has_obscured_metrics')
      ? undefined
      : json['has_obscured_metrics'],
    reportMeta: IndexReportsResponseDataAttributesReportMetaFromJSON(json['report_meta']),
  }
}

export function IndexReportsResponseDataAttributesToJSON(
  value?: IndexReportsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    report_type: value.reportType,
    report_request_params: value.reportRequestParams,
    status: value.status,
    location: value.location,
    s3_location: value.s3Location,
    file_size: value.fileSize,
    generated_at: value.generatedAt,
    created_at: value.createdAt,
    has_obscured_metrics: value.hasObscuredMetrics,
    report_meta: IndexReportsResponseDataAttributesReportMetaToJSON(value.reportMeta),
  }
}
