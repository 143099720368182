import { css } from '@emotion/react'
import { ChevronDownIcon, ChevronRightIcon, Text } from '@instacart/ids-core'
import { capitalize } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import Checkbox from 'components/CheckboxV2'
import { CategoriesViewContext, TargetingRuleContext, useCategoryTargetingCheckbox } from '../hooks'
import { CategoryTreeNode } from '../utils'
import ChildCategory from './ChildCategory'
import SubcategoriesCount from './SubcategoriesCount'

const parentCategoryContainerStyle = css({
  display: 'flex',
  alignContent: 'center',
  cursor: 'pointer',
  marginBottom: 1,
})

const childCategoryContainerStyle = (isLevel3: boolean) =>
  css({
    paddingLeft: isLevel3 ? 0 : 24,
  })

const chevronIconStyle = css({
  paddingLeft: 3,
  paddingTop: 3,
  marginRight: 14,
})

const displayNameTextStyle = (isExpanded: boolean) => ({
  fontWeight: isExpanded ? 600 : 400,
})

export interface ParentCategoryProps {
  node: CategoryTreeNode
  isFirstNode: boolean
  isLevel3: boolean
}

export default function ParentCategory({ node, isFirstNode, isLevel3 }: ParentCategoryProps) {
  const isInitiallyExpanded = isFirstNode && !isLevel3
  const [isExpanded, setIsExpanded] = useState<boolean>(isInitiallyExpanded)
  const { isReadOnly } = useContext(TargetingRuleContext)
  const { searchText, setHighlightedL2Node } = useContext(CategoriesViewContext)
  const childCategories = node.children.filter(childNode => childNode.isVisible)

  const { checkboxState, handleCheckboxClick } = useCategoryTargetingCheckbox(node, isReadOnly)

  useEffect(() => {
    /*
    when the user searches for something, by default we:
     - Expand the first category list.
     - Highlight the first item for displaying its aisles.
     - Expand the aisle if there's a search text.
     */
    setIsExpanded(isInitiallyExpanded)
    if (isInitiallyExpanded) {
      setHighlightedL2Node(childCategories[0])
    }
    if (searchText) {
      setIsExpanded(isFirstNode && !isLevel3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitiallyExpanded, searchText])

  const IconToShow = isExpanded ? ChevronDownIcon : ChevronRightIcon

  const parentCategoryDropdown = (
    <div css={parentCategoryContainerStyle}>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyPress={() => setIsExpanded(!isExpanded)}
        role="button"
        tabIndex={0}
      >
        <IconToShow size={15} color="systemGrayscale30" css={chevronIconStyle} />
      </div>
      <div
        onClick={() => handleCheckboxClick()}
        onKeyPress={() => handleCheckboxClick()}
        role="button"
        tabIndex={0}
      >
        <Checkbox id="category" checked={checkboxState} disabled={isReadOnly}>
          <Text typography="bodyMedium2" style={displayNameTextStyle(isExpanded)}>
            {capitalize(node.displayName)}
          </Text>
        </Checkbox>
      </div>
      <SubcategoriesCount node={node} />
    </div>
  )

  return (
    <>
      <>
        {isLevel3 ? (
          <div css={childCategoryContainerStyle(isLevel3)} key={node.id}>
            <ChildCategory node={node} isLevel4={isLevel3} />
          </div>
        ) : (
          parentCategoryDropdown
        )}
      </>

      {isExpanded &&
        childCategories.map(childNode => (
          <div css={childCategoryContainerStyle(isLevel3)} key={childNode.id}>
            <ChildCategory node={childNode} isLevel4={isLevel3} />
          </div>
        ))}
    </>
  )
}
