import { css } from '@emotion/react'
import { useTheme, Color } from '@instacart/ids-core'

export interface CardDescriptionProps {
  children: React.ReactNode
  color?: Color
}

function useStyles(color?: Color) {
  const theme = useTheme()

  return {
    root: css({
      color: theme.colors[color || 'systemGrayscale50'],
      ...theme.typography.bodyMedium2,
      marginBottom: '0.5rem',
      // Override global styles
      letterSpacing: 0,
    }),
  }
}

export function CardDescription({ children, color, ...props }: CardDescriptionProps) {
  const styles = useStyles(color)

  return (
    <h3 css={styles.root} {...props}>
      {children}
    </h3>
  )
}
