// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamProductAssetFieldDecisionsAltText } from './GetDisplayCampaignsParamProductAssetFieldDecisionsAltText'
import {
  GetDisplayCampaignsParamProductAssetFieldDecisionsAltTextFromJSON,
  GetDisplayCampaignsParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  GetDisplayCampaignsParamProductAssetFieldDecisionsAltTextToJSON,
} from './GetDisplayCampaignsParamProductAssetFieldDecisionsAltText'
import type { GetDisplayCampaignsParamProductAssetFieldDecisionsImage } from './GetDisplayCampaignsParamProductAssetFieldDecisionsImage'
import {
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSON,
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSONTyped,
  GetDisplayCampaignsParamProductAssetFieldDecisionsImageToJSON,
} from './GetDisplayCampaignsParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamProductAssetFieldDecisions
 */
export interface GetDisplayCampaignsParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisions
   */
  discriminator: GetDisplayCampaignsParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {GetDisplayCampaignsParamProductAssetFieldDecisionsAltText}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisions
   */
  altText?: GetDisplayCampaignsParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {GetDisplayCampaignsParamProductAssetFieldDecisionsImage}
   * @memberof GetDisplayCampaignsParamProductAssetFieldDecisions
   */
  image?: GetDisplayCampaignsParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const GetDisplayCampaignsParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetDisplayCampaignsParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof GetDisplayCampaignsParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof GetDisplayCampaignsParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamProductAssetFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsParamProductAssetFieldDecisions {
  return GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetDisplayCampaignsParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetDisplayCampaignsParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function GetDisplayCampaignsParamProductAssetFieldDecisionsToJSON(
  value?: GetDisplayCampaignsParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: GetDisplayCampaignsParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetDisplayCampaignsParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
