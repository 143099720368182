import { FlipperIdType } from 'common/types'
import useFlipper from 'common/useFlipper'

interface Props {
  name: FlipperIdType
  disableFlipper?: FlipperIdType
  enabled?: () => JSX.Element | null
  disabled?: () => JSX.Element | null
}

const Flipper = ({ name, disableFlipper, enabled, disabled }: Props) => {
  const isEnabled = useFlipper(name, disableFlipper)

  if (isEnabled && enabled) {
    return enabled()
  }

  if (!isEnabled && disabled) {
    return disabled()
  }

  return null
}

export default Flipper
