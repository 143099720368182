// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamTaxonomyIdLabels } from './PostAdminBrandPagesIdLabelParamTaxonomyIdLabels'
import {
  PostAdminBrandPagesIdLabelParamTaxonomyIdLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamTaxonomyIdLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamTaxonomyIdLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamTaxonomyIdLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamTaxonomyIdLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues
   */
  labels: Array<PostAdminBrandPagesIdLabelParamTaxonomyIdLabels>
}

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues {
  return PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamTaxonomyIdLabelsFromJSON
    ),
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValuesToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageTaxonomyLabelMapValues | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(PostAdminBrandPagesIdLabelParamTaxonomyIdLabelsToJSON),
  }
}
