// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdminBrandPagesParamDataAttributes
 */
export interface GetAdminBrandPagesParamDataAttributes {
  /**
   * The display name for the brand page.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  name: string
  /**
   * The ui state of the brand page.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  status: GetAdminBrandPagesParamDataAttributesStatusEnum
  /**
   * What level the brand page represents.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  level: GetAdminBrandPagesParamDataAttributesLevelEnum
  /**
   * What level the brand page represents.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  purpose: GetAdminBrandPagesParamDataAttributesPurposeEnum
  /**
   * The type of the brand page.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  type: string
  /**
   * The URL of the brand page.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  brandPageUrl: string
  /**
   * The brand page's latest approval submission date.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  submittedAt: string
  /**
   * Temporary flag to determine if a brand page is lychee.
   * @type {boolean}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  isLycheeEntity: boolean
  /**
   * The admin ui state of the brand page.
   * @type {string}
   * @memberof GetAdminBrandPagesParamDataAttributes
   */
  adminUiState: GetAdminBrandPagesParamDataAttributesAdminUiStateEnum
}

/**
 * @export
 */
export const GetAdminBrandPagesParamDataAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetAdminBrandPagesParamDataAttributesStatusEnum =
  typeof GetAdminBrandPagesParamDataAttributesStatusEnum[keyof typeof GetAdminBrandPagesParamDataAttributesStatusEnum]

/**
 * @export
 */
export const GetAdminBrandPagesParamDataAttributesLevelEnum = {
  Brand: 'BRAND',
  Parent: 'PARENT',
  SubBrand: 'SUB_BRAND',
} as const
export type GetAdminBrandPagesParamDataAttributesLevelEnum =
  typeof GetAdminBrandPagesParamDataAttributesLevelEnum[keyof typeof GetAdminBrandPagesParamDataAttributesLevelEnum]

/**
 * @export
 */
export const GetAdminBrandPagesParamDataAttributesPurposeEnum = {
  Evergreen: 'EVERGREEN',
  Seasonal: 'SEASONAL',
} as const
export type GetAdminBrandPagesParamDataAttributesPurposeEnum =
  typeof GetAdminBrandPagesParamDataAttributesPurposeEnum[keyof typeof GetAdminBrandPagesParamDataAttributesPurposeEnum]

/**
 * @export
 */
export const GetAdminBrandPagesParamDataAttributesAdminUiStateEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetAdminBrandPagesParamDataAttributesAdminUiStateEnum =
  typeof GetAdminBrandPagesParamDataAttributesAdminUiStateEnum[keyof typeof GetAdminBrandPagesParamDataAttributesAdminUiStateEnum]

/**
 * Check if a given object implements the GetAdminBrandPagesParamDataAttributes interface.
 */
export function instanceOfGetAdminBrandPagesParamDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'level' in value
  isInstance = isInstance && 'purpose' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'brandPageUrl' in value
  isInstance = isInstance && 'submittedAt' in value
  isInstance = isInstance && 'isLycheeEntity' in value
  isInstance = isInstance && 'adminUiState' in value

  return isInstance
}

export function GetAdminBrandPagesParamDataAttributesFromJSON(
  json: any
): GetAdminBrandPagesParamDataAttributes {
  return GetAdminBrandPagesParamDataAttributesFromJSONTyped(json, false)
}

export function GetAdminBrandPagesParamDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminBrandPagesParamDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    status: json['status'],
    level: json['level'],
    purpose: json['purpose'],
    type: json['type'],
    brandPageUrl: json['brand_page_url'],
    submittedAt: json['submitted_at'],
    isLycheeEntity: json['is_lychee_entity'],
    adminUiState: json['admin_ui_state'],
  }
}

export function GetAdminBrandPagesParamDataAttributesToJSON(
  value?: GetAdminBrandPagesParamDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    status: value.status,
    level: value.level,
    purpose: value.purpose,
    type: value.type,
    brand_page_url: value.brandPageUrl,
    submitted_at: value.submittedAt,
    is_lychee_entity: value.isLycheeEntity,
    admin_ui_state: value.adminUiState,
  }
}
