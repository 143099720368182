import { FC, ReactElement } from 'react'
import useIntl from 'common/useIntl'
import {
  PrimaryButton,
  PrimaryLinkButton,
  SecondaryButton,
  SecondaryLinkButton,
} from 'components/ids-ads'
import { ButtonRow } from 'components/molecules'
import { MessageIdType } from 'locales/types'

export interface FooterButtonsProps {
  onCancel?: () => void
  isCancelDisabled?: boolean
  isSubmitDisabled?: boolean
  submitTextId?: MessageIdType
  cancelTextId?: MessageIdType
  closeRedirectLink?: string
  submitRedirectLink?: string
  submitComponent?: ReactElement
}

// Default buttons component for FooterCta
export const FooterButtons: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  onCancel,
  isCancelDisabled = false,
  isSubmitDisabled = false,
  cancelTextId = 'common.cancel',
  submitTextId = 'common.send',
  closeRedirectLink,
  submitRedirectLink,
  submitComponent,
}) => {
  const { formatMessage } = useIntl()

  const closeButton = closeRedirectLink ? (
    <SecondaryLinkButton
      to={closeRedirectLink}
      disabled={isCancelDisabled}
      data-testid="cancel-link"
    >
      {formatMessage({ id: cancelTextId })}
    </SecondaryLinkButton>
  ) : (
    <SecondaryButton
      type="button"
      onClick={onCancel}
      disabled={isCancelDisabled}
      data-testid="cancel"
    >
      {formatMessage({ id: cancelTextId })}
    </SecondaryButton>
  )

  const submitButton =
    submitComponent ||
    (submitRedirectLink ? (
      <PrimaryLinkButton
        to={submitRedirectLink}
        disabled={isSubmitDisabled}
        data-testid="submit-link"
      >
        {formatMessage({ id: submitTextId })}
      </PrimaryLinkButton>
    ) : (
      <PrimaryButton type="submit" disabled={isSubmitDisabled} data-testid="submit">
        {formatMessage({ id: submitTextId })}
      </PrimaryButton>
    ))

  return (
    <div data-testid="footer-cta-buttons">
      <ButtonRow wide>
        {closeButton}
        {submitButton}
      </ButtonRow>
    </div>
  )
}
