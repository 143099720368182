// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerSearchTermVideoInputDateRange } from './ApiReportsControllerSearchTermVideoInputDateRange'
import {
  ApiReportsControllerSearchTermVideoInputDateRangeFromJSON,
  ApiReportsControllerSearchTermVideoInputDateRangeFromJSONTyped,
  ApiReportsControllerSearchTermVideoInputDateRangeToJSON,
} from './ApiReportsControllerSearchTermVideoInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerSearchTermVideoInput
 */
export interface ApiReportsControllerSearchTermVideoInput {
  /**
   *
   * @type {ApiReportsControllerSearchTermVideoInputDateRange}
   * @memberof ApiReportsControllerSearchTermVideoInput
   */
  dateRange?: ApiReportsControllerSearchTermVideoInputDateRange
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerSearchTermVideoInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerSearchTermVideoInput
   */
  name?: string
}

/**
 * Check if a given object implements the ApiReportsControllerSearchTermVideoInput interface.
 */
export function instanceOfApiReportsControllerSearchTermVideoInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerSearchTermVideoInputFromJSON(
  json: any
): ApiReportsControllerSearchTermVideoInput {
  return ApiReportsControllerSearchTermVideoInputFromJSONTyped(json, false)
}

export function ApiReportsControllerSearchTermVideoInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerSearchTermVideoInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerSearchTermVideoInputDateRangeFromJSON(json['date_range']),
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function ApiReportsControllerSearchTermVideoInputToJSON(
  value?: ApiReportsControllerSearchTermVideoInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerSearchTermVideoInputDateRangeToJSON(value.dateRange),
    attribution_model: value.attributionModel,
    name: value.name,
  }
}
