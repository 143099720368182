// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiBrandPagesControllerCreateInputBrandPage } from './ApiBrandPagesControllerCreateInputBrandPage'
import {
  ApiBrandPagesControllerCreateInputBrandPageFromJSON,
  ApiBrandPagesControllerCreateInputBrandPageFromJSONTyped,
  ApiBrandPagesControllerCreateInputBrandPageToJSON,
} from './ApiBrandPagesControllerCreateInputBrandPage'

/**
 *
 * @export
 * @interface ApiBrandPagesControllerCreateInput
 */
export interface ApiBrandPagesControllerCreateInput {
  /**
   *
   * @type {ApiBrandPagesControllerCreateInputBrandPage}
   * @memberof ApiBrandPagesControllerCreateInput
   */
  brandPage: ApiBrandPagesControllerCreateInputBrandPage
  /**
   * Whether to enforce full validations or not.
   * @type {boolean}
   * @memberof ApiBrandPagesControllerCreateInput
   */
  skipValidation?: boolean
}

/**
 * Check if a given object implements the ApiBrandPagesControllerCreateInput interface.
 */
export function instanceOfApiBrandPagesControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'brandPage' in value

  return isInstance
}

export function ApiBrandPagesControllerCreateInputFromJSON(
  json: any
): ApiBrandPagesControllerCreateInput {
  return ApiBrandPagesControllerCreateInputFromJSONTyped(json, false)
}

export function ApiBrandPagesControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiBrandPagesControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPage: ApiBrandPagesControllerCreateInputBrandPageFromJSON(json['brand_page']),
    skipValidation: !exists(json, 'skip_validation') ? undefined : json['skip_validation'],
  }
}

export function ApiBrandPagesControllerCreateInputToJSON(
  value?: ApiBrandPagesControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page: ApiBrandPagesControllerCreateInputBrandPageToJSON(value.brandPage),
    skip_validation: value.skipValidation,
  }
}
