import { useCallback, useEffect } from 'react'
import { getEnv } from 'common/utils'
import { BorderlessButton, TertiaryButton, useModalState } from 'components/ids-ads'
import LoginModal from 'components/Login/components/LoginModal'
import { useGetSession } from 'components/Login/utils/hooks'
import { useBoolean } from 'landing/components/hooks'
import FormattedMessage from '../../FormattedMessage'

const LOGIN_REDIRECT_URL = getEnv('LOGIN_REDIRECT_URL') || '/home'

interface LoginButtonProps {
  legacyStyle?: boolean
}

export default function LoginButton({ legacyStyle }: LoginButtonProps) {
  const [triggerShowModal, setTriggerShowModalTrue, setTriggerShowModalFalse] = useBoolean(false)
  const loginModalState = useModalState()
  const openLoginModal = loginModalState.show

  const onSuccess = useCallback(() => {
    // redirect if session already exists
    window.location.assign(LOGIN_REDIRECT_URL)
  }, [])

  const onError = useCallback(() => {
    // show login modal if session does not exist and modal has not been opened yet
    setTriggerShowModalTrue()
  }, [setTriggerShowModalTrue])

  const { getSession, loading } = useGetSession({ onSuccess, onError })

  const onClick = useCallback(async () => {
    // open login modal if getSession call has not resolved within 500ms
    setTimeout(setTriggerShowModalTrue, 500)
    setTriggerShowModalFalse()
    await getSession()
  }, [getSession, setTriggerShowModalFalse, setTriggerShowModalTrue])

  useEffect(() => {
    // use effect will only be triggered once from either
    // the 500ms timeout or when the getSession call resolves
    if (triggerShowModal) {
      openLoginModal()
    }
  }, [triggerShowModal, openLoginModal])

  const Button = legacyStyle ? BorderlessButton : TertiaryButton

  return (
    <>
      <Button
        onClick={e => {
          e.preventDefault()
          onClick?.()
        }}
        disabled={loading}
        data-testid="login-button"
      >
        <FormattedMessage id="common.login" />
      </Button>
      <LoginModal modalState={loginModalState} />
    </>
  )
}
