import {
  ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum,
  ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum,
} from 'service/openapi/__codegen__/models/ApiDisplayCampaignsControllerCreateInputCampaign'
import {
  DisplayCampaignResponseDataAttributesPacingTypeEnum,
  DisplayCampaignResponseDataAttributesObjectiveEnum,
  DisplayCampaignResponseDataAttributesCampaignTypeEnum,
} from 'service/openapi/__codegen__/models/DisplayCampaignResponseDataAttributes'

export type CampaignTypeEnum = DisplayCampaignResponseDataAttributesCampaignTypeEnum

export const CampaignTypeEnum = {
  ...DisplayCampaignResponseDataAttributesCampaignTypeEnum,
}

export type CampaignObjectiveEnum =
  | ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum
  | DisplayCampaignResponseDataAttributesObjectiveEnum

export const CampaignObjectiveEnum = {
  ...ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum,
  ...DisplayCampaignResponseDataAttributesObjectiveEnum,
}

export type CampaignPacingTypeEnum =
  | ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum
  | DisplayCampaignResponseDataAttributesPacingTypeEnum

export const CampaignPacingTypeEnum = {
  ...ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum,
  ...DisplayCampaignResponseDataAttributesPacingTypeEnum,
}
