// When KnowledgeOwl is initialized, it will attach an onclick event to the element with this ID.
// This must match the Element ID defined in the Widget Settings in KnowledgeOwl.
// Note that we create this element in the `initialize` function.
export const TRIGGER_LINK_ID = 'knowledge-owl-trigger'

// This wrapper element is added by the KnowledgeOwl embed.
// Note that it could change in theory, so do not assume that it will always exist.
export const WIDGET_WRAPPER_ID = '_ko16-widget-wrapper'

// Full site url for Instacart Knowledge Owl
export const FULL_SITE_URL = 'https://instacart-ads.knowledgeowl.com/help'

// Objective Based Buying / Shoppable Video Links
export const OBB_OBJECTIVES_URL =
  'https://instacart-ads.knowledgeowl.com/help/identifying-campaign-objectives'
export const OBB_ADS_FORMATS_URL = 'https://instacart-ads.knowledgeowl.com/help/overview'

// Full site url for Recommendations Knowledge OWL
export const KO_RECOMMENDATION_URL =
  'https://instacart-ads.knowledgeowl.com/help/reviewing-recommendations'

// Full site url for Auto Bidding Knowledge OWL
export const KO_AUTO_BIDDING_URL = 'https://instacart-ads.knowledgeowl.com/help/optimized-bidding'

export const KO_BID_LANDSCAPE_URL = 'https://instacart-ads.knowledgeowl.com/help/bid-landscapes'

// TODO - ADS-43351 - Update URL with actual content.
export const KO_KEYWORD_BID_LANDSCAPE_URL =
  'https://instacart-ads.knowledgeowl.com/help/bid-landscapes'

export const KO_POLICY_URL = 'https://instacart-ads.knowledgeowl.com/help/policy-page'

// Full site url for Sponsored Product Analytics OWL
export const KO_SPONSORED_PRODUCT_ANALYTICS_URL =
  'https://instacart-ads.knowledgeowl.com/help/advertising-on-instacart-analytics'

export const KO_SPONSORED_PRODUCT_IMPROVE_PERFORMANCE_URL =
  'https://instacart-ads.knowledgeowl.com/help/improving-sponsored-product-performance'

export const SP_HELP_URL =
  'https://instacart-ads.knowledgeowl.com/help/introduction-to-sponsored-products'

// ----------------------------------------------------------------------------
// The following URLs are Provi-specific
// ----------------------------------------------------------------------------

// Full site url for Provi Knowledge Owl
export const PROVI_FULL_SITE_URL = 'https://provi-ads.knowledgeowl.com/help'

export const PROVI_KO_SP_BID_MIN_URL = 'https://provi-ads.knowledgeowl.com/help/intro-to-carrot-ads'

export const PROVI_KO_BUDGET_RECOMMENDATIONS_URL =
  'https://provi-ads.knowledgeowl.com/help/budget-recommendations'

// ----------------------------------------------------------------------------
// Knowledge Owl DOM constants
// ----------------------------------------------------------------------------

// Property that knowledge owl requires us to push our config to
export const KO_WINDOW_PROP = '_ko16_p'

// Class identifier of Knowledge Owl's back button
export const KO_WIDGET_BACK_ELEMENT = '_ko16_widget_back'

// Class identifier of Knowledge Owl's all articles element
export const KO_WIDGET_CONTACT_US_ELEMENT = '_ko16_widget_btm_cntr'

// Class identifier of Knowledge Owl's all articles element
export const KO_WIDGET_OPEN_EXTERNAL_ELEMENT = '_ko16_widget_art_ext_cntr'

// Class identifier of Knowledge Owl's search field
export const KO_WIDGET_SEARCH_CONTAINER_ELEMENT = '_ko16_widget_search_cntr'

// Unique identifier of Knowledge Owl's search input
export const KO_WIDGET_SEARCH_ELEMENT = '_ko16_search_box'

// Unique identifier of Knowledge Owl's search results list
export const KO_WIDGET_SEARCH_ARTICLE_LIST_ELEMENT = '_ko16_article_list'

// Unique identifier of the contact form element we render
export const CONTACT_FORM_CONTAINER_ELEMENT = 'ko-contact-form'

// Unique identifier of the KO "app" element we render
export const CONTACT_FORM_CONTAINER_APP = 'ko-contact-form-app'

// Class identifier of for Knowledge Owl in browser window
export const KO_WINDOW = '__ko16'

// Identifier of for Knowledge Owl animation style
export const KO_ANIMATION_STYLE = '__ko16_animation_style'
