import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { useEffect } from 'react'
import { useIntl } from 'common'
import toPx from 'common/toPx'
import { TertiaryButton, Drawer, ModalContent, ModalState } from 'components/ids-ads'
import ButtonRow from 'components/molecules/ButtonRow'
import {
  BannerNotificationProps,
  bannerTypeToTitle,
  GroupedBannerNotificationProps,
  shouldShowDismissAll,
} from '../utils'
import { BannerActionButtons } from './BannerActionButtons'

const useStyles = () => {
  const theme = useTheme()
  return {
    item: css({
      padding: toPx`${spacing.s12} 0`,
      '& + &': css({
        borderTop: `1px solid ${theme.colors.systemGrayscale20}`,
      }),
    }),
    title: css({
      ...theme.typography.bodyMedium1,
    }),
    content: css({
      a: {
        color: theme.colors.systemGrayscale70,
        textDecoration: 'underline',
        '&:hover, &:visited': {
          color: 'inherit',
        },
      },
    }),
    buttons: css({
      paddingTop: spacing.s12,
    }),
  }
}

interface GroupedBannerDetailsDrawerProps {
  modalState: ModalState
  groupedBannerDetails: GroupedBannerNotificationProps
  dismissBanner: (dismissId?: string) => void
  dismissAll(): void
}

const BannerDetails = ({
  bannerDetails,
  dismissBanner,
}: {
  bannerDetails: BannerNotificationProps
  dismissBanner: (dismissId?: string) => void
}) => {
  const styles = useStyles()
  const { formatMessage } = useIntl()
  const { message, actionButtons, actionButtonsProps, dismissId, testId } = bannerDetails

  return (
    <div css={styles.item} data-testid={testId || 'banner-notification'}>
      <div css={styles.title}>{message.header}</div>
      <div css={styles.content}>{message.message}</div>
      <div css={styles.buttons}>
        <ButtonRow compact>
          {actionButtonsProps ? (
            <BannerActionButtons {...actionButtonsProps} isSecondary />
          ) : (
            actionButtons
          )}
          {dismissId && (
            <TertiaryButton
              compact
              onClick={() => dismissBanner(dismissId)}
              data-testid={`dismiss-${dismissId}`}
            >
              {formatMessage({ id: 'bannerNotification.grouped.drawer.dismiss' })}
            </TertiaryButton>
          )}
        </ButtonRow>
      </div>
    </div>
  )
}

export const GroupedBannerDetailsDrawer = ({
  modalState,
  groupedBannerDetails,
  dismissBanner,
  dismissAll,
}: GroupedBannerDetailsDrawerProps) => {
  const { formatMessage } = useIntl()

  const handleDrawerClose = modalState.hide
  const { type, groupedBanners: banners } = groupedBannerDetails

  useEffect(() => {
    if (banners.length === 0) handleDrawerClose()
  }, [banners, handleDrawerClose])

  return (
    <>
      <Drawer
        onClose={handleDrawerClose}
        title={{ message: formatMessage({ id: bannerTypeToTitle[type] }) }}
        modalState={modalState}
        allowClose={false}
        testId="grouped-banner-details-drawer"
        allowContentToScroll
      >
        <ModalContent allowScroll>
          {banners.map((bannerDetails, index) => (
            <BannerDetails
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              bannerDetails={bannerDetails}
              dismissBanner={dismissBanner}
            />
          ))}
        </ModalContent>
        <ButtonRow right>
          {shouldShowDismissAll(banners) && (
            <TertiaryButton compact onClick={dismissAll} data-testid="dismiss-all">
              {formatMessage({ id: 'bannerNotification.grouped.drawer.dismissAll' })}
            </TertiaryButton>
          )}
        </ButtonRow>
      </Drawer>
    </>
  )
}
