// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayAdGroupsIdParamProductCollection,
  instanceOfPutDisplayAdGroupsIdParamProductCollection,
  PutDisplayAdGroupsIdParamProductCollectionFromJSON,
  PutDisplayAdGroupsIdParamProductCollectionFromJSONTyped,
  PutDisplayAdGroupsIdParamProductCollectionToJSON,
} from './PutDisplayAdGroupsIdParamProductCollection'
import {
  PutDisplayAdGroupsIdParamSearchTermCollection,
  instanceOfPutDisplayAdGroupsIdParamSearchTermCollection,
  PutDisplayAdGroupsIdParamSearchTermCollectionFromJSON,
  PutDisplayAdGroupsIdParamSearchTermCollectionFromJSONTyped,
  PutDisplayAdGroupsIdParamSearchTermCollectionToJSON,
} from './PutDisplayAdGroupsIdParamSearchTermCollection'

/**
 * @type PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties
 *
 * @export
 */
export type PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties =

    | ({ type: 'product_collection' } & PutDisplayAdGroupsIdParamProductCollection)
    | ({ type: 'search_term_collection' } & PutDisplayAdGroupsIdParamSearchTermCollection)

export function PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
  json: any
): PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties {
  return PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'product_collection':
      return {
        ...PutDisplayAdGroupsIdParamProductCollectionFromJSONTyped(json, true),
        type: 'product_collection',
      }
    case 'search_term_collection':
      return {
        ...PutDisplayAdGroupsIdParamSearchTermCollectionFromJSONTyped(json, true),
        type: 'search_term_collection',
      }
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
  value?: PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'product_collection':
      return PutDisplayAdGroupsIdParamProductCollectionToJSON(value)
    case 'search_term_collection':
      return PutDisplayAdGroupsIdParamSearchTermCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdParamDisplayAdGroupCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${value['type']}'`
      )
  }
}
