import { FLIPPER_MULTI_FACTOR_AUTH_ADOPTION_MODAL } from 'common/flippers'
import { useFeatureReminder } from 'common/useFeatureReminder'
import useFlipper from 'common/useFlipper'
import { isSingleSignOnEnabled, useAuthContext } from 'context/auth'

export function useShowMfaAdoptionModal() {
  const authContext = useAuthContext()
  const mfaEnabled = authContext?.user?.mfaFactors?.[0]
  const singleSignOnEnabled = isSingleSignOnEnabled(authContext)
  const adoptionModalReminderActive = useFeatureReminder('multiFactorAuth.adoptionModal.reminder')
  const adoptionModalEnabled = useFlipper(FLIPPER_MULTI_FACTOR_AUTH_ADOPTION_MODAL)

  return adoptionModalEnabled && !mfaEnabled && !singleSignOnEnabled && adoptionModalReminderActive
}
