import { css } from '@emotion/react'
import { Theme, useTheme, spacing } from '@instacart/ids-core'
import { toPx } from 'common'
import FormattedMessage from 'components/FormattedMessage'

export interface BrandOptionProps {
  brandId: string
  brandProductCount: number
  name: string
  parentCompanyName: string
  isSelected: boolean
  onClick: (brandId: string) => void
  onPressUpArrow: () => void
  onPressDownArrow: () => void
}

const useStyles = (theme: Theme, isSelected: boolean) => ({
  row: css({
    outline: 'none',
    backgroundColor: theme.colors.systemGrayscale00,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px',
    opacity: isSelected ? 0.5 : 1,
    '&:hover': {
      borderColor: 'transparent',
      cursor: isSelected ? 'no-drop' : 'pointer',
      backgroundColor: theme.colors[isSelected ? 'systemGrayscale00' : 'brandHighlightLight'],
    },
    '&:focus': {
      backgroundColor: theme.colors[isSelected ? 'systemGrayscale00' : 'brandHighlightLight'],
    },
  }),

  brandDetails: css({
    color: theme.colors.systemGrayscale50,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    alignItems: 'flex-start',
    '& > *:not(:first-child)::before': {
      content: '"|"',
      margin: toPx`0 ${spacing.s8}`,
    },
  }),

  brandName: css({
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '22px',
  }),
})

const BrandOption = ({
  brandId,
  brandProductCount,
  name,
  parentCompanyName,
  isSelected,
  onClick,
  onPressUpArrow,
  onPressDownArrow,
}: BrandOptionProps) => {
  const theme = useTheme()

  const { row, brandName, brandDetails } = useStyles(theme, isSelected)

  return (
    <div
      role="button"
      data-testid={brandId}
      css={row}
      tabIndex={-1}
      onClick={() => onClick(brandId)}
      onKeyDown={e => {
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          onPressUpArrow()
        } else if (e.key === 'ArrowDown') {
          e.preventDefault()
          onPressDownArrow()
        } else if (e.key === 'Enter') {
          onClick(brandId)
        }
      }}
    >
      <div css={brandName}>{name}</div>
      <div css={brandDetails}>
        <FormattedMessage
          id="pages.displayProduct.common.targetingRule.segment.brands.parentCompany"
          values={{ parentCompany: parentCompanyName }}
          tagName="span"
        />
        <FormattedMessage
          id="pages.displayProduct.common.targetingRule.segment.brands.productCount"
          values={{ productCount: brandProductCount }}
          tagName="span"
        />
      </div>
    </div>
  )
}

export default BrandOption
