import { ADS_API_PATH } from 'common/constants'
import { ReportsApi } from 'service/apis/ReportsApi'
import Client from 'service/client'
import { ReportClientInterface, AsyncReportType } from 'service/types'

export default class OpenAPIReportClient extends Client implements ReportClientInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/reports`
  }

  downloadURI = (reportId: string): string =>
    this.getDownloadURI({
      url: `${this.ADS_API_URL}${this.ADS_API_PATH}/${reportId}/download`,
    })

  requestReport = async (reportType: AsyncReportType, options: Record<string, unknown>) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let reportRequest
        switch (reportType) {
          case 'keywords':
          case 'top100Keywords':
            reportRequest = await ReportsApi.postReportsKeywords({ body: options })
            break
          case 'products':
            reportRequest = await ReportsApi.postReportsProducts({ body: options })
            break
          case 'adgroups':
            reportRequest = await ReportsApi.postReportsAdGroups({ body: options })
            break
          case 'campaigns':
            reportRequest = await ReportsApi.postReportsCampaigns({ body: options })
            break
        }
        resolve(reportRequest.data.id)
      } catch (err) {
        return reject(err)
      }
    })
  }

  asyncExport = async (reportId: string, useS3Location = false) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const pollInterval = setInterval(async () => {
          const result = await ReportsApi.getReportsId({ id: reportId })

          if (result.meta.status === 200) {
            clearInterval(pollInterval)
            if (result.data.attributes.status === 'failed') {
              resolve('Report Failed')
            } else if (result.data.attributes.status === 'completed') {
              if (useS3Location && result.data.attributes.s3Location) {
                resolve(result.data.attributes.s3Location)
              } else {
                resolve(this.downloadURI(result.data.id))
              }
            }
          }
        }, 1000)
      } catch (err) {
        return reject(err)
      }
    })
  }
}

export const ReportClient = new OpenAPIReportClient()
