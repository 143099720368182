// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { IndexReportsResponseData } from './IndexReportsResponseData'
import {
  IndexReportsResponseDataFromJSON,
  IndexReportsResponseDataFromJSONTyped,
  IndexReportsResponseDataToJSON,
} from './IndexReportsResponseData'
import type { IndexReportsResponseMeta } from './IndexReportsResponseMeta'
import {
  IndexReportsResponseMetaFromJSON,
  IndexReportsResponseMetaFromJSONTyped,
  IndexReportsResponseMetaToJSON,
} from './IndexReportsResponseMeta'

/**
 *
 * @export
 * @interface IndexReportsResponse
 */
export interface IndexReportsResponse {
  /**
   *
   * @type {Array<IndexReportsResponseData>}
   * @memberof IndexReportsResponse
   */
  data: Array<IndexReportsResponseData>
  /**
   *
   * @type {IndexReportsResponseMeta}
   * @memberof IndexReportsResponse
   */
  meta: IndexReportsResponseMeta
}

/**
 * Check if a given object implements the IndexReportsResponse interface.
 */
export function instanceOfIndexReportsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function IndexReportsResponseFromJSON(json: any): IndexReportsResponse {
  return IndexReportsResponseFromJSONTyped(json, false)
}

export function IndexReportsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexReportsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(IndexReportsResponseDataFromJSON),
    meta: IndexReportsResponseMetaFromJSON(json['meta']),
  }
}

export function IndexReportsResponseToJSON(value?: IndexReportsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(IndexReportsResponseDataToJSON),
    meta: IndexReportsResponseMetaToJSON(value.meta),
  }
}
