// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordsResponseDataAttributesAnalytics } from './GetAdGroupKeywordsResponseDataAttributesAnalytics'
import {
  GetAdGroupKeywordsResponseDataAttributesAnalyticsFromJSON,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsFromJSONTyped,
  GetAdGroupKeywordsResponseDataAttributesAnalyticsToJSON,
} from './GetAdGroupKeywordsResponseDataAttributesAnalytics'
import type { GetAdGroupKeywordsResponseDataAttributesBidRecs } from './GetAdGroupKeywordsResponseDataAttributesBidRecs'
import {
  GetAdGroupKeywordsResponseDataAttributesBidRecsFromJSON,
  GetAdGroupKeywordsResponseDataAttributesBidRecsFromJSONTyped,
  GetAdGroupKeywordsResponseDataAttributesBidRecsToJSON,
} from './GetAdGroupKeywordsResponseDataAttributesBidRecs'

/**
 *
 * @export
 * @interface GetAdGroupKeywordsResponseDataAttributes
 */
export interface GetAdGroupKeywordsResponseDataAttributes {
  /**
   * The keyword.
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  keyword: string
  /**
   * CPC bid for the keyword in dollars.
   * @type {number}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  cpcBid: number
  /**
   * Keyword matching type
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  matchingType: string
  /**
   * Ad Group Keyword Status
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  adGroupKeywordStatus?: string
  /**
   *
   * @type {GetAdGroupKeywordsResponseDataAttributesAnalytics}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  analytics?: GetAdGroupKeywordsResponseDataAttributesAnalytics
  /**
   *
   * @type {GetAdGroupKeywordsResponseDataAttributesBidRecs}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  bidRecs?: GetAdGroupKeywordsResponseDataAttributesBidRecs
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  errors?: { [key: string]: any }
  /**
   * Is competitive bid?
   * @type {boolean}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  isConfidentBid?: boolean
  /**
   * AdGroupKeyword uuid
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  adGroupKeywordUuid?: string
  /**
   * Bid Strength value to select
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  bidStrength?: GetAdGroupKeywordsResponseDataAttributesBidStrengthEnum
  /**
   * Suggested Bid
   * @type {number}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  suggestedBid?: number
  /**
   * Uncompetitive Bid
   * @type {number}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  uncompetitiveBid?: number
  /**
   * Bid Type to filter by, should be default or override
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  bidType?: string
  /**
   * Bid trace info
   * @type {Array<string>}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  traceInfo?: Array<string>
  /**
   * Source the keyword was created through
   * @type {string}
   * @memberof GetAdGroupKeywordsResponseDataAttributes
   */
  source?: GetAdGroupKeywordsResponseDataAttributesSourceEnum
}

/**
 * @export
 */
export const GetAdGroupKeywordsResponseDataAttributesBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type GetAdGroupKeywordsResponseDataAttributesBidStrengthEnum =
  typeof GetAdGroupKeywordsResponseDataAttributesBidStrengthEnum[keyof typeof GetAdGroupKeywordsResponseDataAttributesBidStrengthEnum]

/**
 * @export
 */
export const GetAdGroupKeywordsResponseDataAttributesSourceEnum = {
  Manual: 'manual',
  Automated: 'automated',
} as const
export type GetAdGroupKeywordsResponseDataAttributesSourceEnum =
  typeof GetAdGroupKeywordsResponseDataAttributesSourceEnum[keyof typeof GetAdGroupKeywordsResponseDataAttributesSourceEnum]

/**
 * Check if a given object implements the GetAdGroupKeywordsResponseDataAttributes interface.
 */
export function instanceOfGetAdGroupKeywordsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keyword' in value
  isInstance = isInstance && 'cpcBid' in value
  isInstance = isInstance && 'matchingType' in value

  return isInstance
}

export function GetAdGroupKeywordsResponseDataAttributesFromJSON(
  json: any
): GetAdGroupKeywordsResponseDataAttributes {
  return GetAdGroupKeywordsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdGroupKeywordsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keyword: json['keyword'],
    cpcBid: json['cpc_bid'],
    matchingType: json['matching_type'],
    adGroupKeywordStatus: !exists(json, 'ad_group_keyword_status')
      ? undefined
      : json['ad_group_keyword_status'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetAdGroupKeywordsResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : GetAdGroupKeywordsResponseDataAttributesBidRecsFromJSON(json['bid_recs']),
    errors: !exists(json, 'errors') ? undefined : json['errors'],
    isConfidentBid: !exists(json, 'is_confident_bid') ? undefined : json['is_confident_bid'],
    adGroupKeywordUuid: !exists(json, 'ad_group_keyword_uuid')
      ? undefined
      : json['ad_group_keyword_uuid'],
    bidStrength: !exists(json, 'bid_strength') ? undefined : json['bid_strength'],
    suggestedBid: !exists(json, 'suggested_bid') ? undefined : json['suggested_bid'],
    uncompetitiveBid: !exists(json, 'uncompetitive_bid') ? undefined : json['uncompetitive_bid'],
    bidType: !exists(json, 'bid_type') ? undefined : json['bid_type'],
    traceInfo: !exists(json, 'trace_info') ? undefined : json['trace_info'],
    source: !exists(json, 'source') ? undefined : json['source'],
  }
}

export function GetAdGroupKeywordsResponseDataAttributesToJSON(
  value?: GetAdGroupKeywordsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keyword: value.keyword,
    cpc_bid: value.cpcBid,
    matching_type: value.matchingType,
    ad_group_keyword_status: value.adGroupKeywordStatus,
    analytics: GetAdGroupKeywordsResponseDataAttributesAnalyticsToJSON(value.analytics),
    bid_recs: GetAdGroupKeywordsResponseDataAttributesBidRecsToJSON(value.bidRecs),
    errors: value.errors,
    is_confident_bid: value.isConfidentBid,
    ad_group_keyword_uuid: value.adGroupKeywordUuid,
    bid_strength: value.bidStrength,
    suggested_bid: value.suggestedBid,
    uncompetitive_bid: value.uncompetitiveBid,
    bid_type: value.bidType,
    trace_info: value.traceInfo,
    source: value.source,
  }
}
