// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamProductAssetAltTextLabels } from './PostAdminBrandPagesIdLabelParamProductAssetAltTextLabels'
import {
  PostAdminBrandPagesIdLabelParamProductAssetAltTextLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamProductAssetAltTextLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamProductAssetAltTextLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamProductAssetAltTextLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamProductAssetAltText
 */
export interface PostAdminBrandPagesIdLabelParamProductAssetAltText {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamProductAssetAltTextLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamProductAssetAltText
   */
  labels: Array<PostAdminBrandPagesIdLabelParamProductAssetAltTextLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamProductAssetAltText
   */
  componentType: PostAdminBrandPagesIdLabelParamProductAssetAltTextComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamProductAssetAltTextComponentTypeEnum = {
  ProductAssetAltText: 'PRODUCT_ASSET_ALT_TEXT',
} as const
export type PostAdminBrandPagesIdLabelParamProductAssetAltTextComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamProductAssetAltTextComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamProductAssetAltTextComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamProductAssetAltText interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamProductAssetAltText(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamProductAssetAltText {
  return PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamProductAssetAltTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamProductAssetAltText {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamProductAssetAltTextLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamProductAssetAltTextToJSON(
  value?: PostAdminBrandPagesIdLabelParamProductAssetAltText | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamProductAssetAltTextLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
