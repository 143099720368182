import { spacing, Text, useTheme } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { BorderlessAnchorButton } from 'components/ids-ads'
import { externalLinkFormatter } from '../../common/intlUtils'
import { BLOG_URL, SIGNUP_PATH } from '../utils/constants'
import { getImageUrl } from '../utils/helpers'
import breakPoints from './breakPoints'
import Step, { StepImgFirst } from './Step'

const useStyles = (): { [key: string]: CSSProperties } => {
  const theme = useTheme()
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    title: {
      ...{ padding: spacing.s48 },
      color: theme.colors.systemGrayscale70,
      [breakPoints.mobile]: {
        fontSize: '22px',
        ...{ padding: spacing.s24 },
      },
    },
    getStarted: {
      display: 'block',
      padding: '8px 0',
    },
    blogSection: {
      padding: '41px 0 70px',
      fontSize: 16,
      [breakPoints.mobile]: {
        ...{ padding: spacing.s24 },
      },
    },
  }
}

const blogLinkFormatters = {
  'blog-link': externalLinkFormatter(BLOG_URL),
}

const HowItWorks = () => {
  const styles = useStyles()
  const intl = useIntl()
  return (
    <div style={styles.wrapper}>
      <Text typography="bodyLarge2" style={styles.title} as="h2" color="systemGrayscale20">
        <FormattedMessage id="landing.howItWorks.header" />
      </Text>
      <Step
        step={1}
        lead={intl.formatMessage({ id: 'landing.howItWorks.lead1' })}
        imgSrc={getImageUrl('/images/landing/sponsored_products.svg')}
      >
        <div>
          <div>
            <FormattedMessage id="landing.howItWorks.step1" />
          </div>
          <BorderlessAnchorButton style={styles.getStarted} href={SIGNUP_PATH}>
            <FormattedMessage id="landing.howItWorks.getStarted" />
          </BorderlessAnchorButton>
        </div>
      </Step>
      <StepImgFirst
        step={2}
        lead={intl.formatMessage({ id: 'landing.howItWorks.lead2' })}
        imgSrc={getImageUrl('/images/landing/campaign_budget.svg')}
      >
        <div>
          <div>
            <FormattedMessage id="landing.howItWorks.step2" />
          </div>
          <BorderlessAnchorButton style={styles.getStarted} href={SIGNUP_PATH}>
            <FormattedMessage id="landing.howItWorks.getStarted" />
          </BorderlessAnchorButton>
        </div>
      </StepImgFirst>
      <Step
        step={3}
        lead={intl.formatMessage({ id: 'landing.howItWorks.lead3' })}
        imgSrc={getImageUrl('/images/landing/analytics.svg')}
      >
        <div>
          <FormattedMessage id="landing.howItWorks.step3" />
          <BorderlessAnchorButton style={styles.getStarted} href={SIGNUP_PATH}>
            <FormattedMessage id="landing.howItWorks.getStarted" />
          </BorderlessAnchorButton>
        </div>
      </Step>
      <div style={styles.blogSection}>
        <FormattedMessage id="landing.howItWorks.blogLink" values={blogLinkFormatters} />
      </div>
    </div>
  )
}

export default Radium(HowItWorks)
