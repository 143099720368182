import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import classnames from 'classnames'
import { ReactElement, FC } from 'react'

interface TableBodyProps {
  children?: ReactElement | ReactElement[]
  isFooter?: boolean
  'data-testid'?: string
}

const useStyles = () => {
  const theme = useTheme()
  return css({
    backgroundColor: theme.colors.systemGrayscale00,
    color: theme.colors.systemGrayscale70,
    border: `1px solid ${theme.colors.systemGrayscale20}`,
    fontSize: 18,
    fontWeight: 'normal',
    padding: '20px 30px',

    '&.footer': {
      borderRadius: '0 0 6px 6px',
      marginBottom: 10,
    },
  })
}

const TableBody: FC<React.PropsWithChildren<TableBodyProps>> = ({
  children,
  isFooter: footer = true,
  'data-testid': testId,
}) => {
  const containerCss = useStyles()
  return (
    <div css={containerCss} className={classnames({ footer })} data-testid={testId}>
      {children}
    </div>
  )
}

export default TableBody
