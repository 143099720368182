import { InputBase, StylesOf, Theme, spacing } from '@instacart/ids-core'
import toPx from 'common/toPx'

const TRANSITION_DURATION_MS = 100

const readOnlyOverrides = {
  '&:read-only:not(:disabled)': {
    paddingLeft: 0,
    boxShadow: 'none',
    borderRadius: 0,
    background: 'none',
  },
}

export const adsInputStyles = ({
  theme,
  compact,
}: {
  theme: Theme
  compact?: boolean
}): StylesOf<typeof InputBase> => ({
  input: {
    padding: compact
      ? toPx`${spacing.s4} ${spacing.s8} ${spacing.s4 + 2}`
      : toPx`${spacing.s8} ${spacing.s12} ${spacing.s8 + 2}`,
    borderRadius: compact ? 6 : theme.radius.r8,
    transition: `box-shadow ${TRANSITION_DURATION_MS}ms ease-in-out`,
    boxShadow: `0 0 0 1px ${theme.colors.systemGrayscale30} inset`,
    ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
    '&::placeholder': {
      // Safari tries to explicitly set the height and line-height on the placeholder so we override
      // it here to match the input text. Otherwise the text appears to jump up when the user starts typing.
      height: 'auto',
      ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
    },
    ...readOnlyOverrides,
  },
  hover: {
    boxShadow: `0 0 0 1px ${theme.colors.systemGrayscale50} inset`,
    ...readOnlyOverrides,
  },
  focusRing: {
    boxShadow: `0 0 0 2px ${theme.colors.systemGrayscale70} inset`,
    ...readOnlyOverrides,
  },
  invalid: {
    boxShadow: `0 0 0 1px ${theme.colors.systemDetrimentalRegular} inset`,
    ...readOnlyOverrides,
  },
  invalidHover: {
    boxShadow: `0 0 0 1px ${theme.colors.systemDetrimentalDark} inset`,
    ...readOnlyOverrides,
  },
  invalidFocus: {
    boxShadow: `0 0 0 2px ${theme.colors.systemDetrimentalRegular} inset`,
    ...readOnlyOverrides,
  },
  disabled: {
    boxShadow: `0 0 0 1px ${theme.colors.systemGrayscale30}`,
    '&::placeholder': {
      color: theme.colors.systemGrayscale30,
    },
  },
})
