// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamAssetFieldDecisionsAltText } from './PutReservationAdGroupsIdParamAssetFieldDecisionsAltText'
import {
  PutReservationAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON,
  PutReservationAdGroupsIdParamAssetFieldDecisionsAltTextFromJSONTyped,
  PutReservationAdGroupsIdParamAssetFieldDecisionsAltTextToJSON,
} from './PutReservationAdGroupsIdParamAssetFieldDecisionsAltText'
import type { PutReservationAdGroupsIdParamAssetFieldDecisionsImage } from './PutReservationAdGroupsIdParamAssetFieldDecisionsImage'
import {
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSON,
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSONTyped,
  PutReservationAdGroupsIdParamAssetFieldDecisionsImageToJSON,
} from './PutReservationAdGroupsIdParamAssetFieldDecisionsImage'
import type { PutReservationAdGroupsIdParamAssetFieldDecisionsVideo } from './PutReservationAdGroupsIdParamAssetFieldDecisionsVideo'
import {
  PutReservationAdGroupsIdParamAssetFieldDecisionsVideoFromJSON,
  PutReservationAdGroupsIdParamAssetFieldDecisionsVideoFromJSONTyped,
  PutReservationAdGroupsIdParamAssetFieldDecisionsVideoToJSON,
} from './PutReservationAdGroupsIdParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamAssetFieldDecisions
 */
export interface PutReservationAdGroupsIdParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutReservationAdGroupsIdParamAssetFieldDecisionsAltText}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisions
   */
  altText?: PutReservationAdGroupsIdParamAssetFieldDecisionsAltText
  /**
   *
   * @type {PutReservationAdGroupsIdParamAssetFieldDecisionsImage}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisions
   */
  image?: PutReservationAdGroupsIdParamAssetFieldDecisionsImage
  /**
   *
   * @type {PutReservationAdGroupsIdParamAssetFieldDecisionsVideo}
   * @memberof PutReservationAdGroupsIdParamAssetFieldDecisions
   */
  video?: PutReservationAdGroupsIdParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamAssetFieldDecisions interface.
 */
export function instanceOfPutReservationAdGroupsIdParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamAssetFieldDecisions {
  return PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutReservationAdGroupsIdParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutReservationAdGroupsIdParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : PutReservationAdGroupsIdParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function PutReservationAdGroupsIdParamAssetFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: PutReservationAdGroupsIdParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutReservationAdGroupsIdParamAssetFieldDecisionsImageToJSON(value.image),
    video: PutReservationAdGroupsIdParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
