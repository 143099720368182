// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative'
import type { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy } from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy'
import {
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSON,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSONTyped,
  ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyToJSON,
} from './ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
 */
export interface ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  name: string
  /**
   * The maximum amount, in dollars, you want to pay per thousands views for advertised creatives in this ad group.
   * @type {number}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  defaultBid: number
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  campaignId: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  placementType?: string
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  creative: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  targetingStrategy: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategy
  /**
   *
   * @type {ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage}
   * @memberof ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup
   */
  brandPage?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPage
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup interface.
 */
export function instanceOfApiDisplayAdGroupsControllerCreateInputDisplayAdGroup(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'defaultBid' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSON(
  json: any
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup {
  return ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultBid: json['default_bid'],
    campaignId: json['campaign_id'],
    placementType: !exists(json, 'placement_type') ? undefined : json['placement_type'],
    creative: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeFromJSON(
      json['creative']
    ),
    targetingStrategy:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageFromJSON(json['brand_page']),
  }
}

export function ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupToJSON(
  value?: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_bid: value.defaultBid,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeToJSON(value.creative),
    targeting_strategy:
      ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    brand_page: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupBrandPageToJSON(
      value.brandPage
    ),
  }
}
