// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamEntities } from './PostAdminBrandPagesIdLabelParamEntities'
import {
  PostAdminBrandPagesIdLabelParamEntitiesFromJSON,
  PostAdminBrandPagesIdLabelParamEntitiesFromJSONTyped,
  PostAdminBrandPagesIdLabelParamEntitiesToJSON,
} from './PostAdminBrandPagesIdLabelParamEntities'

/**
 *
 * @export
 * @interface ApiAdminBrandPagesControllerLabelInput
 */
export interface ApiAdminBrandPagesControllerLabelInput {
  /**
   * Task type of the T2 review
   * @type {string}
   * @memberof ApiAdminBrandPagesControllerLabelInput
   */
  operation?: ApiAdminBrandPagesControllerLabelInputOperationEnum
  /**
   * List of entity labels
   * @type {Array<PostAdminBrandPagesIdLabelParamEntities>}
   * @memberof ApiAdminBrandPagesControllerLabelInput
   */
  entities?: Array<PostAdminBrandPagesIdLabelParamEntities>
}

/**
 * @export
 */
export const ApiAdminBrandPagesControllerLabelInputOperationEnum = {
  Appeal: 'appeal',
} as const
export type ApiAdminBrandPagesControllerLabelInputOperationEnum =
  typeof ApiAdminBrandPagesControllerLabelInputOperationEnum[keyof typeof ApiAdminBrandPagesControllerLabelInputOperationEnum]

/**
 * Check if a given object implements the ApiAdminBrandPagesControllerLabelInput interface.
 */
export function instanceOfApiAdminBrandPagesControllerLabelInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAdminBrandPagesControllerLabelInputFromJSON(
  json: any
): ApiAdminBrandPagesControllerLabelInput {
  return ApiAdminBrandPagesControllerLabelInputFromJSONTyped(json, false)
}

export function ApiAdminBrandPagesControllerLabelInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdminBrandPagesControllerLabelInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    operation: !exists(json, 'operation') ? undefined : json['operation'],
    entities: !exists(json, 'entities')
      ? undefined
      : (json['entities'] as Array<any>).map(PostAdminBrandPagesIdLabelParamEntitiesFromJSON),
  }
}

export function ApiAdminBrandPagesControllerLabelInputToJSON(
  value?: ApiAdminBrandPagesControllerLabelInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    operation: value.operation,
    entities:
      value.entities === undefined
        ? undefined
        : (value.entities as Array<any>).map(PostAdminBrandPagesIdLabelParamEntitiesToJSON),
  }
}
