import { AxiosError } from 'axios'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { clearCurrentAccountId } from 'common/currentAccountHelper'
import { isOktaLoginEnabled, oktaLogin } from 'common/login'
import { ApiErrorCodes, ClientError, getApiErrorCode } from 'common/utils'
import {
  ACCOUNT_LOCKED,
  ACCOUNT_UNCONFIRMED,
  USER_UNCONFIRMED,
  INVALID_CREDENTIALS,
  LoginError,
  LoginFormValues,
} from 'components/Login/utils/types'
import {
  MfaFactor,
  MultiFactorAuthAttempt,
} from 'components/MultiFactorAuth/utils/multiFactorAuth.types'
import { NotificationType, useNotifier } from 'context'
import useClientTimezone from 'hooks/useClientTimezone'
import { AdUsersSessionsApi } from 'service/apis/AdUsersSessionsApi'
import { AuthClient } from 'service/auth'
import Client from 'service/client'
import { ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum } from 'service/openapi/__codegen__/models/ApiAdUsersSessionsControllerCreateWithMfaInput'

const INTERNAL_EMAIL_PATTERN = /@instacart.com$/i

export const useSso = (redirectToWindow = false) => {
  const ssoEnabled = isOktaLoginEnabled() // || true

  const isSsoRequired = useCallback(
    (email: string) => {
      return ssoEnabled && INTERNAL_EMAIL_PATTERN.test(email)
    },
    [ssoEnabled]
  )

  const loginWithSso = useCallback(() => {
    oktaLogin(redirectToWindow ? window.location.pathname : '/home')
  }, [redirectToWindow])

  const attemptLoginWithSso = useCallback(
    (email: string) => {
      if (isSsoRequired(email)) {
        loginWithSso()
      }
    },
    [isSsoRequired, loginWithSso]
  )

  return {
    isSsoRequired,
    loginWithSso,
    attemptLoginWithSso,
  }
}

export const useHandleRedirect = (redirectUrl?: string, redirectToWindow?: boolean) => {
  return useCallback(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl)
    } else if (redirectToWindow) {
      window.location.assign(window.location.href)
    } else {
      window.location.assign('/home')
    }
  }, [redirectToWindow, redirectUrl])
}

interface CreateSessionParams extends LoginFormValues {
  reCaptchaToken?: string
}

export const useCreateSession = ({
  onSuccess,
  onError,
  setLoginError,
}: {
  onSuccess: (mfaFactor?: MfaFactor) => void
  onError: () => void
  setLoginError: Dispatch<SetStateAction<LoginError | undefined>>
}) => {
  const { sendNotification } = useNotifier()
  const clientTimezone = useClientTimezone()
  const [loading, setLoading] = useState(false)

  const createSession = useCallback(
    async ({ email, password, rememberMe, reCaptchaToken }: CreateSessionParams) => {
      setLoading(true)
      setLoginError(undefined)
      try {
        const response = await AuthClient.postAuth({
          body: { email, password, rememberMe, reCaptchaToken },
          clientTimezone,
        })
        const { mfaFactors } = response.data
        onSuccess(mfaFactors && mfaFactors[0])
      } catch (err) {
        const error = err as ClientError
        const errorCode = getApiErrorCode(error)

        onError()

        if (errorCode === ApiErrorCodes.AccountLocked) {
          setLoginError(ACCOUNT_LOCKED)
        } else if (errorCode === ApiErrorCodes.UnconfirmedUser) {
          setLoginError(USER_UNCONFIRMED)
        } else if (errorCode === ApiErrorCodes.InvalidCredentials) {
          setLoginError(INVALID_CREDENTIALS)
        } else if (errorCode === ApiErrorCodes.UnconfirmedAccount) {
          setLoginError(ACCOUNT_UNCONFIRMED)
          clearCurrentAccountId()
        } else {
          sendNotification({
            type: NotificationType.ERROR,
            messageId: 'components.login.form.error.genericServerError',
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [clientTimezone, onError, onSuccess, sendNotification, setLoginError]
  )

  return {
    createSession,
    loading,
  }
}

export const useCreateSessionWithMultiFactor = () => {
  return useCallback(
    async (multiFactorAuthAttempt: MultiFactorAuthAttempt, rememberMe?: boolean) => {
      await AdUsersSessionsApi.postAuthMultiFactor({
        body: {
          otp: multiFactorAuthAttempt.otp,
          rememberDevice: multiFactorAuthAttempt.rememberDevice,
          mfaType:
            multiFactorAuthAttempt.mfaType as string as ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum,
          rememberMe,
        },
      })
    },
    []
  )
}

interface GetSessionParams {
  onSuccess: () => void
  onError: () => void
}

export const useGetSession = ({ onSuccess, onError }: GetSessionParams) => {
  const [loading, setLoading] = useState(false)

  const forceLogout = async () => {
    try {
      Client.unsetCSRFToken()
      await AuthClient.logout()
    } catch (e) {
      // Fail silently when attempting to force logout
    }
  }

  const getSession = useCallback(async () => {
    setLoading(true)

    try {
      await AuthClient.getUserInfo()
      onSuccess()
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = err as AxiosError<any, any>
      if (error?.response?.status === 403) {
        // force async logout if account that user doesn't have access to is selected
        await forceLogout()
      }
      onError()
    } finally {
      setLoading(false)
    }
  }, [onError, onSuccess])

  return { getSession, loading }
}
