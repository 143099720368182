// eslint-disable-next-line no-restricted-imports
import { MaskedTextField } from 'ic-snacks'
import { FC, forwardRef, Ref, useRef, useEffect, RefObject } from 'react'
import { MaskedInputProps } from 'react-text-mask'
import createNumericMask from 'text-mask-addons/dist/createNumberMask'

type InputFieldRef = {
  FormComponent: { input: HTMLInputElement }
}

// ref: https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
interface NumberMaskOption {
  prefix: string
  suffix: string
  includeThousandsSeparator?: boolean
  thousandsSeparatorSymbol: string
  allowDecimal: boolean
  decimalSymbol: string
  decimalLimit: number
  integerLimit: number
  requireDecimal: boolean
  allowNegative: boolean
  allowLeadingZeroes: boolean
}

type AllMaskedTextFieldProps = React.ComponentPropsWithoutRef<typeof MaskedTextField>
interface MaskedTextFieldOptionalProps {
  type: string
  mask: MaskedInputProps['mask']
  maskHint: string
  placeholder: string
  ref: string | Ref<InputFieldRef>
  getValue: (rawValue: string) => string
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: ReturnType<AllMaskedTextFieldProps['getValue']>,
    rawValue: string
  ) => void
}

type MaskedTextFieldProps = Omit<AllMaskedTextFieldProps, keyof MaskedTextFieldOptionalProps> &
  Partial<MaskedTextFieldOptionalProps>

interface NumericFieldProps extends MaskedTextFieldProps {
  maskOptions?: Partial<NumberMaskOption>
}

const TypedMaskedTextField = MaskedTextField as FC<React.PropsWithChildren<MaskedTextFieldProps>>
const getValue = (value: string) => value

const NumericField: FC<React.PropsWithChildren<NumericFieldProps>> = forwardRef(
  (props, passedRef) => {
    const { maskOptions, mask, ...rest } = props
    const numericMask = mask || createNumericMask(maskOptions)

    // workaround because snacks doesn't support placeholders.
    let inputRef = useRef<InputFieldRef>(null)
    inputRef = (passedRef || inputRef) as RefObject<InputFieldRef>
    useEffect(() => {
      const input = inputRef?.current?.FormComponent.input
      if (input && props.placeholder) {
        input.placeholder = props.placeholder
      }
    }, [props.placeholder, inputRef])

    return (
      <TypedMaskedTextField
        type="text"
        mask={numericMask}
        getValue={getValue}
        ref={inputRef}
        {...rest}
      />
    )
  }
)

const NON_DIGIT_REGEX = /[^\d.]*/g
/** @deprecate This file is getting deprecated, use the parseFloat directly */
export const unmask = (input: string): number => parseFloat(input.replace(NON_DIGIT_REGEX, ''))

export default NumericField
