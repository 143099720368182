// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviews } from './GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import {
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import type { GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReview } from './GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReview'
import {
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface GetBrandPagesParamDataAttributesEditorialStatesReviewState
 */
export interface GetBrandPagesParamDataAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof GetBrandPagesParamDataAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof GetBrandPagesParamDataAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReview}
   * @memberof GetBrandPagesParamDataAttributesEditorialStatesReviewState
   */
  finalReview?: GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the GetBrandPagesParamDataAttributesEditorialStatesReviewState interface.
 */
export function instanceOfGetBrandPagesParamDataAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSON(
  json: any
): GetBrandPagesParamDataAttributesEditorialStatesReviewState {
  return GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function GetBrandPagesParamDataAttributesEditorialStatesReviewStateToJSON(
  value?: GetBrandPagesParamDataAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            GetBrandPagesParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review: GetBrandPagesParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON(
      value.finalReview
    ),
  }
}
