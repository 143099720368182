// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValues } from './PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { PutReservationAdGroupsIdParamCreativeFieldDecisionsTagline } from './PutReservationAdGroupsIdParamCreativeFieldDecisionsTagline'
import {
  PutReservationAdGroupsIdParamCreativeFieldDecisionsTaglineFromJSON,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsTaglineFromJSONTyped,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsTaglineToJSON,
} from './PutReservationAdGroupsIdParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamCreativeFieldDecisions
 */
export interface PutReservationAdGroupsIdParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof PutReservationAdGroupsIdParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {PutReservationAdGroupsIdParamCreativeFieldDecisionsTagline}
   * @memberof PutReservationAdGroupsIdParamCreativeFieldDecisions
   */
  tagline?: PutReservationAdGroupsIdParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamCreativeFieldDecisions interface.
 */
export function instanceOfPutReservationAdGroupsIdParamCreativeFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamCreativeFieldDecisions {
  return PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : PutReservationAdGroupsIdParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function PutReservationAdGroupsIdParamCreativeFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            PutReservationAdGroupsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: PutReservationAdGroupsIdParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
