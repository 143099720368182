// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainItemGrid?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainProductGroupGrid?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainCollectionGrid?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions
   */
  bannerClick?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
    main_product_group_grid:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
        value.bannerClick
      ),
  }
}
