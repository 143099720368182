// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  linear: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  lastTouch?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON(
        json['linear']
      ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON(
        value.linear
      ),
    last_touch:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON(
        value.lastTouch
      ),
  }
}
