// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBlockImageAndTextAssetsDesktop } from './PostBrandPagesParamBlockImageAndTextAssetsDesktop'
import {
  PostBrandPagesParamBlockImageAndTextAssetsDesktopFromJSON,
  PostBrandPagesParamBlockImageAndTextAssetsDesktopFromJSONTyped,
  PostBrandPagesParamBlockImageAndTextAssetsDesktopToJSON,
} from './PostBrandPagesParamBlockImageAndTextAssetsDesktop'
import type { PostBrandPagesParamBlockImageAndTextAssetsMobile } from './PostBrandPagesParamBlockImageAndTextAssetsMobile'
import {
  PostBrandPagesParamBlockImageAndTextAssetsMobileFromJSON,
  PostBrandPagesParamBlockImageAndTextAssetsMobileFromJSONTyped,
  PostBrandPagesParamBlockImageAndTextAssetsMobileToJSON,
} from './PostBrandPagesParamBlockImageAndTextAssetsMobile'

/**
 *
 * @export
 * @interface PostBrandPagesParamBlockImageAndTextAssets
 */
export interface PostBrandPagesParamBlockImageAndTextAssets {
  /**
   *
   * @type {PostBrandPagesParamBlockImageAndTextAssetsDesktop}
   * @memberof PostBrandPagesParamBlockImageAndTextAssets
   */
  desktop: PostBrandPagesParamBlockImageAndTextAssetsDesktop
  /**
   *
   * @type {PostBrandPagesParamBlockImageAndTextAssetsMobile}
   * @memberof PostBrandPagesParamBlockImageAndTextAssets
   */
  mobile?: PostBrandPagesParamBlockImageAndTextAssetsMobile
}

/**
 * Check if a given object implements the PostBrandPagesParamBlockImageAndTextAssets interface.
 */
export function instanceOfPostBrandPagesParamBlockImageAndTextAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value

  return isInstance
}

export function PostBrandPagesParamBlockImageAndTextAssetsFromJSON(
  json: any
): PostBrandPagesParamBlockImageAndTextAssets {
  return PostBrandPagesParamBlockImageAndTextAssetsFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockImageAndTextAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockImageAndTextAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostBrandPagesParamBlockImageAndTextAssetsDesktopFromJSON(json['desktop']),
    mobile: !exists(json, 'mobile')
      ? undefined
      : PostBrandPagesParamBlockImageAndTextAssetsMobileFromJSON(json['mobile']),
  }
}

export function PostBrandPagesParamBlockImageAndTextAssetsToJSON(
  value?: PostBrandPagesParamBlockImageAndTextAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostBrandPagesParamBlockImageAndTextAssetsDesktopToJSON(value.desktop),
    mobile: PostBrandPagesParamBlockImageAndTextAssetsMobileToJSON(value.mobile),
  }
}
