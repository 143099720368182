// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetTargetingOptionsParamDataAttributesCategoriesList } from './GetTargetingOptionsParamDataAttributesCategoriesList'
import {
  GetTargetingOptionsParamDataAttributesCategoriesListFromJSON,
  GetTargetingOptionsParamDataAttributesCategoriesListFromJSONTyped,
  GetTargetingOptionsParamDataAttributesCategoriesListToJSON,
} from './GetTargetingOptionsParamDataAttributesCategoriesList'
import type { TargetingOptionsResponseDataAttributesBrandsList } from './TargetingOptionsResponseDataAttributesBrandsList'
import {
  TargetingOptionsResponseDataAttributesBrandsListFromJSON,
  TargetingOptionsResponseDataAttributesBrandsListFromJSONTyped,
  TargetingOptionsResponseDataAttributesBrandsListToJSON,
} from './TargetingOptionsResponseDataAttributesBrandsList'

/**
 *
 * @export
 * @interface TargetingOptionsResponseDataAttributes
 */
export interface TargetingOptionsResponseDataAttributes {
  /**
   * Category options
   * @type {Array<GetTargetingOptionsParamDataAttributesCategoriesList>}
   * @memberof TargetingOptionsResponseDataAttributes
   */
  categoriesList: Array<GetTargetingOptionsParamDataAttributesCategoriesList>
  /**
   *
   * @type {TargetingOptionsResponseDataAttributesBrandsList}
   * @memberof TargetingOptionsResponseDataAttributes
   */
  brandsList: TargetingOptionsResponseDataAttributesBrandsList
  /**
   * Geo options
   * @type {{ [key: string]: any; }}
   * @memberof TargetingOptionsResponseDataAttributes
   */
  geosList: { [key: string]: any }
}

/**
 * Check if a given object implements the TargetingOptionsResponseDataAttributes interface.
 */
export function instanceOfTargetingOptionsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'categoriesList' in value
  isInstance = isInstance && 'brandsList' in value
  isInstance = isInstance && 'geosList' in value

  return isInstance
}

export function TargetingOptionsResponseDataAttributesFromJSON(
  json: any
): TargetingOptionsResponseDataAttributes {
  return TargetingOptionsResponseDataAttributesFromJSONTyped(json, false)
}

export function TargetingOptionsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TargetingOptionsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    categoriesList: (json['categories_list'] as Array<any>).map(
      GetTargetingOptionsParamDataAttributesCategoriesListFromJSON
    ),
    brandsList: TargetingOptionsResponseDataAttributesBrandsListFromJSON(json['brands_list']),
    geosList: json['geos_list'],
  }
}

export function TargetingOptionsResponseDataAttributesToJSON(
  value?: TargetingOptionsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    categories_list: (value.categoriesList as Array<any>).map(
      GetTargetingOptionsParamDataAttributesCategoriesListToJSON
    ),
    brands_list: TargetingOptionsResponseDataAttributesBrandsListToJSON(value.brandsList),
    geos_list: value.geosList,
  }
}
