// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamDataAttributesBlocks } from './PostBrandPagesIdStatusParamDataAttributesBlocks'
import {
  PostBrandPagesIdStatusParamDataAttributesBlocksFromJSON,
  PostBrandPagesIdStatusParamDataAttributesBlocksFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesBlocksToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesBlocks'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStates } from './PostBrandPagesIdStatusParamDataAttributesEditorialStates'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStates'
import type { PostBrandPagesIdStatusParamDataAttributesLinkedBrands } from './PostBrandPagesIdStatusParamDataAttributesLinkedBrands'
import {
  PostBrandPagesIdStatusParamDataAttributesLinkedBrandsFromJSON,
  PostBrandPagesIdStatusParamDataAttributesLinkedBrandsFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesLinkedBrandsToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesLinkedBrands'
import type { StandaloneBrandPageResponseDataAttributesAnalytics } from './StandaloneBrandPageResponseDataAttributesAnalytics'
import {
  StandaloneBrandPageResponseDataAttributesAnalyticsFromJSON,
  StandaloneBrandPageResponseDataAttributesAnalyticsFromJSONTyped,
  StandaloneBrandPageResponseDataAttributesAnalyticsToJSON,
} from './StandaloneBrandPageResponseDataAttributesAnalytics'

/**
 *
 * @export
 * @interface StandaloneBrandPageResponseDataAttributes
 */
export interface StandaloneBrandPageResponseDataAttributes {
  /**
   *
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  type: StandaloneBrandPageResponseDataAttributesTypeEnum
  /**
   * A descriptive name for the page, visible to advertiser only.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  name: string
  /**
   * The status of the page.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  status: StandaloneBrandPageResponseDataAttributesStatusEnum
  /**
   * What purpose the page represents, must be one of EVERGREEN, SEASONAL. Depending on purpose, page hierarchy may require additional inputs.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  purpose: StandaloneBrandPageResponseDataAttributesPurposeEnum
  /**
   * The URL of the page.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  brandPageUrl: string
  /**
   * Indicates whether the page is enabled. Page is disabled/paused if set to false.
   * @type {boolean}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  enabled: boolean
  /**
   * What level the page represents.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  level: StandaloneBrandPageResponseDataAttributesLevelEnum
  /**
   * Input of brand names desired for the page in increasing specificity i.e. ['Brand', 'Sub-Brand', 'Custom theme/group']. Will be used to generate the path of the page.
   * @type {Array<string>}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  pageHierarchy: Array<string>
  /**
   * The page's latest approval submission date.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  submittedDate?: string
  /**
   * Whether or not the page has served. If true, this indicates that the page inputs may not be safe to change since it could break any URLs shared.
   * @type {boolean}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  hasServed?: boolean
  /**
   * Ordered collection of page blocks and their content.
   * @type {Array<PostBrandPagesIdStatusParamDataAttributesBlocks>}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  blocks: Array<PostBrandPagesIdStatusParamDataAttributesBlocks>
  /**
   * Ads Taxonomy entities linked to this Brand Page
   * @type {Array<PostBrandPagesIdStatusParamDataAttributesLinkedBrands>}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  linkedBrands?: Array<PostBrandPagesIdStatusParamDataAttributesLinkedBrands>
  /**
   * The admin ui state of the brand page.
   * @type {string}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  adminUiState?: StandaloneBrandPageResponseDataAttributesAdminUiStateEnum
  /**
   *
   * @type {StandaloneBrandPageResponseDataAttributesAnalytics}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  analytics?: StandaloneBrandPageResponseDataAttributesAnalytics
  /**
   * Editorial states
   * @type {Array<PostBrandPagesIdStatusParamDataAttributesEditorialStates>}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  editorialStates?: Array<PostBrandPagesIdStatusParamDataAttributesEditorialStates>
  /**
   * Temporary flag to determine if a brand page is lychee.
   * @type {boolean}
   * @memberof StandaloneBrandPageResponseDataAttributes
   */
  isLycheeEntity: boolean
}

/**
 * @export
 */
export const StandaloneBrandPageResponseDataAttributesTypeEnum = {
  EvergreenV1: 'evergreen.v1',
  ProductGroupEvergreenV1: 'product_group_evergreen.v1',
} as const
export type StandaloneBrandPageResponseDataAttributesTypeEnum =
  typeof StandaloneBrandPageResponseDataAttributesTypeEnum[keyof typeof StandaloneBrandPageResponseDataAttributesTypeEnum]

/**
 * @export
 */
export const StandaloneBrandPageResponseDataAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type StandaloneBrandPageResponseDataAttributesStatusEnum =
  typeof StandaloneBrandPageResponseDataAttributesStatusEnum[keyof typeof StandaloneBrandPageResponseDataAttributesStatusEnum]

/**
 * @export
 */
export const StandaloneBrandPageResponseDataAttributesPurposeEnum = {
  Evergreen: 'EVERGREEN',
  Seasonal: 'SEASONAL',
} as const
export type StandaloneBrandPageResponseDataAttributesPurposeEnum =
  typeof StandaloneBrandPageResponseDataAttributesPurposeEnum[keyof typeof StandaloneBrandPageResponseDataAttributesPurposeEnum]

/**
 * @export
 */
export const StandaloneBrandPageResponseDataAttributesLevelEnum = {
  Brand: 'BRAND',
  Parent: 'PARENT',
  SubBrand: 'SUB_BRAND',
} as const
export type StandaloneBrandPageResponseDataAttributesLevelEnum =
  typeof StandaloneBrandPageResponseDataAttributesLevelEnum[keyof typeof StandaloneBrandPageResponseDataAttributesLevelEnum]

/**
 * @export
 */
export const StandaloneBrandPageResponseDataAttributesAdminUiStateEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type StandaloneBrandPageResponseDataAttributesAdminUiStateEnum =
  typeof StandaloneBrandPageResponseDataAttributesAdminUiStateEnum[keyof typeof StandaloneBrandPageResponseDataAttributesAdminUiStateEnum]

/**
 * Check if a given object implements the StandaloneBrandPageResponseDataAttributes interface.
 */
export function instanceOfStandaloneBrandPageResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'purpose' in value
  isInstance = isInstance && 'brandPageUrl' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'level' in value
  isInstance = isInstance && 'pageHierarchy' in value
  isInstance = isInstance && 'blocks' in value
  isInstance = isInstance && 'isLycheeEntity' in value

  return isInstance
}

export function StandaloneBrandPageResponseDataAttributesFromJSON(
  json: any
): StandaloneBrandPageResponseDataAttributes {
  return StandaloneBrandPageResponseDataAttributesFromJSONTyped(json, false)
}

export function StandaloneBrandPageResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StandaloneBrandPageResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    name: json['name'],
    status: json['status'],
    purpose: json['purpose'],
    brandPageUrl: json['brand_page_url'],
    enabled: json['enabled'],
    level: json['level'],
    pageHierarchy: json['page_hierarchy'],
    submittedDate: !exists(json, 'submitted_date') ? undefined : json['submitted_date'],
    hasServed: !exists(json, 'has_served') ? undefined : json['has_served'],
    blocks: (json['blocks'] as Array<any>).map(
      PostBrandPagesIdStatusParamDataAttributesBlocksFromJSON
    ),
    linkedBrands: !exists(json, 'linked_brands')
      ? undefined
      : (json['linked_brands'] as Array<any>).map(
          PostBrandPagesIdStatusParamDataAttributesLinkedBrandsFromJSON
        ),
    adminUiState: !exists(json, 'admin_ui_state') ? undefined : json['admin_ui_state'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : StandaloneBrandPageResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSON
        ),
    isLycheeEntity: json['is_lychee_entity'],
  }
}

export function StandaloneBrandPageResponseDataAttributesToJSON(
  value?: StandaloneBrandPageResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    name: value.name,
    status: value.status,
    purpose: value.purpose,
    brand_page_url: value.brandPageUrl,
    enabled: value.enabled,
    level: value.level,
    page_hierarchy: value.pageHierarchy,
    submitted_date: value.submittedDate,
    has_served: value.hasServed,
    blocks: (value.blocks as Array<any>).map(PostBrandPagesIdStatusParamDataAttributesBlocksToJSON),
    linked_brands:
      value.linkedBrands === undefined
        ? undefined
        : (value.linkedBrands as Array<any>).map(
            PostBrandPagesIdStatusParamDataAttributesLinkedBrandsToJSON
          ),
    admin_ui_state: value.adminUiState,
    analytics: StandaloneBrandPageResponseDataAttributesAnalyticsToJSON(value.analytics),
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            PostBrandPagesIdStatusParamDataAttributesEditorialStatesToJSON
          ),
    is_lychee_entity: value.isLycheeEntity,
  }
}
