// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplay,
  instanceOfPutDisplayAdGroupsIdParamBlockHeroBannerDisplay,
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSON,
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSONTyped,
  PutDisplayAdGroupsIdParamBlockHeroBannerDisplayToJSON,
} from './PutDisplayAdGroupsIdParamBlockHeroBannerDisplay'
import {
  PutDisplayAdGroupsIdParamBlockItemGridDisplay,
  instanceOfPutDisplayAdGroupsIdParamBlockItemGridDisplay,
  PutDisplayAdGroupsIdParamBlockItemGridDisplayFromJSON,
  PutDisplayAdGroupsIdParamBlockItemGridDisplayFromJSONTyped,
  PutDisplayAdGroupsIdParamBlockItemGridDisplayToJSON,
} from './PutDisplayAdGroupsIdParamBlockItemGridDisplay'

/**
 * @type PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks
 *
 * @export
 */
export type PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & PutDisplayAdGroupsIdParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & PutDisplayAdGroupsIdParamBlockItemGridDisplay)

export function PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocksFromJSON(
  json: any
): PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks {
  return PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocksFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...PutDisplayAdGroupsIdParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...PutDisplayAdGroupsIdParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocksToJSON(
  value?: PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return PutDisplayAdGroupsIdParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return PutDisplayAdGroupsIdParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdParamDisplayAdGroupBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
