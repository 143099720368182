// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TaskResponseDataAttributes
 */
export interface TaskResponseDataAttributes {
  /**
   * Type of task
   * @type {string}
   * @memberof TaskResponseDataAttributes
   */
  taskType: TaskResponseDataAttributesTaskTypeEnum
  /**
   * Task status
   * @type {string}
   * @memberof TaskResponseDataAttributes
   */
  taskStatus: TaskResponseDataAttributesTaskStatusEnum
  /**
   * If the task is completed, this field would include information on the request and the result of the task.
   * @type {{ [key: string]: any; }}
   * @memberof TaskResponseDataAttributes
   */
  payload?: { [key: string]: any }
  /**
   * If the task failed, this field would have information on why it failed.
   * @type {{ [key: string]: any; }}
   * @memberof TaskResponseDataAttributes
   */
  error?: { [key: string]: any }
}

/**
 * @export
 */
export const TaskResponseDataAttributesTaskTypeEnum = {
  ActivateCampaign: 'activate_campaign',
  DuplicateCampaign: 'duplicate_campaign',
  DuplicateAdGroup: 'duplicate_ad_group',
  CreateExperiment: 'create_experiment',
  GetViolatingBids: 'get_violating_bids',
  UpdateViolatingBids: 'update_violating_bids',
  GenerateBrandPage: 'generate_brand_page',
} as const
export type TaskResponseDataAttributesTaskTypeEnum =
  typeof TaskResponseDataAttributesTaskTypeEnum[keyof typeof TaskResponseDataAttributesTaskTypeEnum]

/**
 * @export
 */
export const TaskResponseDataAttributesTaskStatusEnum = {
  InProgress: 'in_progress',
  Completed: 'completed',
  Failed: 'failed',
} as const
export type TaskResponseDataAttributesTaskStatusEnum =
  typeof TaskResponseDataAttributesTaskStatusEnum[keyof typeof TaskResponseDataAttributesTaskStatusEnum]

/**
 * Check if a given object implements the TaskResponseDataAttributes interface.
 */
export function instanceOfTaskResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'taskType' in value
  isInstance = isInstance && 'taskStatus' in value

  return isInstance
}

export function TaskResponseDataAttributesFromJSON(json: any): TaskResponseDataAttributes {
  return TaskResponseDataAttributesFromJSONTyped(json, false)
}

export function TaskResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    taskType: json['task_type'],
    taskStatus: json['task_status'],
    payload: !exists(json, 'payload') ? undefined : json['payload'],
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function TaskResponseDataAttributesToJSON(value?: TaskResponseDataAttributes | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    task_type: value.taskType,
    task_status: value.taskStatus,
    payload: value.payload,
    error: value.error,
  }
}
