// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamBrandPageBlockSnapshotContent } from './PutDisplayCampaignsIdParamBrandPageBlockSnapshotContent'
import {
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotContentFromJSON,
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotContentFromJSONTyped,
  PutDisplayCampaignsIdParamBrandPageBlockSnapshotContentToJSON,
} from './PutDisplayCampaignsIdParamBrandPageBlockSnapshotContent'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamBrandPageBlockSnapshot
 */
export interface PutDisplayCampaignsIdParamBrandPageBlockSnapshot {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockSnapshot
   */
  type: string
  /**
   * Brand Page block highlight product ids
   * @type {Array<string>}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockSnapshot
   */
  highlightedProductIds?: Array<string>
  /**
   * Brand Page block product ids
   * @type {Array<string>}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockSnapshot
   */
  productIds?: Array<string>
  /**
   *
   * @type {PutDisplayCampaignsIdParamBrandPageBlockSnapshotContent}
   * @memberof PutDisplayCampaignsIdParamBrandPageBlockSnapshot
   */
  content?: PutDisplayCampaignsIdParamBrandPageBlockSnapshotContent
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamBrandPageBlockSnapshot interface.
 */
export function instanceOfPutDisplayCampaignsIdParamBrandPageBlockSnapshot(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSON(
  json: any
): PutDisplayCampaignsIdParamBrandPageBlockSnapshot {
  return PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamBrandPageBlockSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    highlightedProductIds: !exists(json, 'highlighted_product_ids')
      ? undefined
      : json['highlighted_product_ids'],
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
    content: !exists(json, 'content')
      ? undefined
      : PutDisplayCampaignsIdParamBrandPageBlockSnapshotContentFromJSON(json['content']),
  }
}

export function PutDisplayCampaignsIdParamBrandPageBlockSnapshotToJSON(
  value?: PutDisplayCampaignsIdParamBrandPageBlockSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    highlighted_product_ids: value.highlightedProductIds,
    product_ids: value.productIds,
    content: PutDisplayCampaignsIdParamBrandPageBlockSnapshotContentToJSON(value.content),
  }
}
