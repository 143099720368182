// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiDisplayCampaignsControllerCreateInput } from '../models/ApiDisplayCampaignsControllerCreateInput'
import {
  ApiDisplayCampaignsControllerCreateInputFromJSON,
  ApiDisplayCampaignsControllerCreateInputToJSON,
} from '../models/ApiDisplayCampaignsControllerCreateInput'
import type { ApiDisplayCampaignsControllerDuplicateAsyncInput } from '../models/ApiDisplayCampaignsControllerDuplicateAsyncInput'
import {
  ApiDisplayCampaignsControllerDuplicateAsyncInputFromJSON,
  ApiDisplayCampaignsControllerDuplicateAsyncInputToJSON,
} from '../models/ApiDisplayCampaignsControllerDuplicateAsyncInput'
import type { ApiDisplayCampaignsControllerUpdateInput } from '../models/ApiDisplayCampaignsControllerUpdateInput'
import {
  ApiDisplayCampaignsControllerUpdateInputFromJSON,
  ApiDisplayCampaignsControllerUpdateInputToJSON,
} from '../models/ApiDisplayCampaignsControllerUpdateInput'
import type { AsyncTaskResponse } from '../models/AsyncTaskResponse'
import { AsyncTaskResponseFromJSON, AsyncTaskResponseToJSON } from '../models/AsyncTaskResponse'
import type { DisplayCampaignCreateResponse } from '../models/DisplayCampaignCreateResponse'
import {
  DisplayCampaignCreateResponseFromJSON,
  DisplayCampaignCreateResponseToJSON,
} from '../models/DisplayCampaignCreateResponse'
import type { DisplayCampaignResponse } from '../models/DisplayCampaignResponse'
import {
  DisplayCampaignResponseFromJSON,
  DisplayCampaignResponseToJSON,
} from '../models/DisplayCampaignResponse'
import type { DisplayCampaignsResponse } from '../models/DisplayCampaignsResponse'
import {
  DisplayCampaignsResponseFromJSON,
  DisplayCampaignsResponseToJSON,
} from '../models/DisplayCampaignsResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetDisplayCampaignAnalyticsResponse } from '../models/GetDisplayCampaignAnalyticsResponse'
import {
  GetDisplayCampaignAnalyticsResponseFromJSON,
  GetDisplayCampaignAnalyticsResponseToJSON,
} from '../models/GetDisplayCampaignAnalyticsResponse'

export interface GetDisplayCampaignsRequest {
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaOnlyShowRejectedCampaigns?: boolean
  searchCriteriaStatus?: Array<GetDisplayCampaignsSearchCriteriaStatusEnum>
  searchCriteriaQuery?: string
  sortCriteriaField?: GetDisplayCampaignsSortCriteriaFieldEnum
  sortCriteriaDirection?: GetDisplayCampaignsSortCriteriaDirectionEnum
  pageCriteriaPage?: number
  pageCriteriaItemsPerPage?: number
  includeAnalytics?: boolean
  attributionModels?: Array<string>
  xApiKey?: string
  xSecretKey?: string
}

export interface GetDisplayCampaignsIdRequest {
  id: string
  includeDetails?: string
  includeAnalytics?: boolean
  attributionModels?: Array<string>
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaAdGroupQuery?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetDisplayCampaignsIdAnalyticsRequest {
  metric: GetDisplayCampaignsIdAnalyticsMetricEnum
  id: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  adGroupQuery?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostDisplayCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiDisplayCampaignsControllerCreateInput
}

export interface PostDisplayCampaignsIdDuplicateAsyncRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiDisplayCampaignsControllerDuplicateAsyncInput
}

export interface PostDisplayCampaignsIdSubmitRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PutDisplayCampaignsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiDisplayCampaignsControllerUpdateInput
}

/**
 *
 */
export class DisplayCampaignsApi extends runtime.BaseAPI {
  /**
   * This method returns a list of all your display ad campaigns. You can optionally search for a specific campaign name and this method returns only the corresponding campaigns. You can also send filter criteria as parameters and this method returns only the campaigns that match.  This method returns details for each campaign, including its attributes, and optionally performance analytics. In addition, it returns some metadata about the campaigns, including the number of pages of results that can be used for pagination.
   * Retrieve a list of display campaigns
   */
  async getDisplayCampaignsRaw(
    requestParameters: GetDisplayCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DisplayCampaignsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaOnlyShowRejectedCampaigns !== undefined) {
      queryParameters['search_criteria[only_show_rejected_campaigns]'] =
        requestParameters.searchCriteriaOnlyShowRejectedCampaigns
    }

    if (requestParameters.searchCriteriaStatus) {
      queryParameters['search_criteria[status]'] = requestParameters.searchCriteriaStatus.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    if (requestParameters.sortCriteriaField !== undefined) {
      queryParameters['sort_criteria[field]'] = requestParameters.sortCriteriaField
    }

    if (requestParameters.sortCriteriaDirection !== undefined) {
      queryParameters['sort_criteria[direction]'] = requestParameters.sortCriteriaDirection
    }

    if (requestParameters.pageCriteriaPage !== undefined) {
      queryParameters['page_criteria[page]'] = requestParameters.pageCriteriaPage
    }

    if (requestParameters.pageCriteriaItemsPerPage !== undefined) {
      queryParameters['page_criteria[items_per_page]'] = requestParameters.pageCriteriaItemsPerPage
    }

    if (requestParameters.includeAnalytics !== undefined) {
      queryParameters['include_analytics'] = requestParameters.includeAnalytics
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DisplayCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method returns a list of all your display ad campaigns. You can optionally search for a specific campaign name and this method returns only the corresponding campaigns. You can also send filter criteria as parameters and this method returns only the campaigns that match.  This method returns details for each campaign, including its attributes, and optionally performance analytics. In addition, it returns some metadata about the campaigns, including the number of pages of results that can be used for pagination.
   * Retrieve a list of display campaigns
   */
  async getDisplayCampaigns(
    requestParameters: GetDisplayCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DisplayCampaignsResponse> {
    const response = await this.getDisplayCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method returns details about a specific campaign, and optionally includes analytics for their corresponding ad groups. You must specify the display campaign ID and optional search criteria to limit the data returned in the analytics.
   * Retrieve a display campaign
   */
  async getDisplayCampaignsIdRaw(
    requestParameters: GetDisplayCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DisplayCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getDisplayCampaignsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.includeDetails !== undefined) {
      queryParameters['include_details'] = requestParameters.includeDetails
    }

    if (requestParameters.includeAnalytics !== undefined) {
      queryParameters['include_analytics'] = requestParameters.includeAnalytics
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaAdGroupQuery !== undefined) {
      queryParameters['search_criteria[ad_group_query]'] =
        requestParameters.searchCriteriaAdGroupQuery
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DisplayCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method returns details about a specific campaign, and optionally includes analytics for their corresponding ad groups. You must specify the display campaign ID and optional search criteria to limit the data returned in the analytics.
   * Retrieve a display campaign
   */
  async getDisplayCampaignsId(
    requestParameters: GetDisplayCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DisplayCampaignResponse> {
    const response = await this.getDisplayCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return detailed analytics information on a given Campaign. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Campaign
   */
  async getDisplayCampaignsIdAnalyticsRaw(
    requestParameters: GetDisplayCampaignsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetDisplayCampaignAnalyticsResponse>> {
    if (requestParameters.metric === null || requestParameters.metric === undefined) {
      throw new runtime.RequiredError(
        'metric',
        'Required parameter requestParameters.metric was null or undefined when calling getDisplayCampaignsIdAnalytics.'
      )
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getDisplayCampaignsIdAnalytics.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.metric !== undefined) {
      queryParameters['metric'] = requestParameters.metric
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.adGroupQuery !== undefined) {
      queryParameters['ad_group_query'] = requestParameters.adGroupQuery
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns/{id}/analytics`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetDisplayCampaignAnalyticsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return detailed analytics information on a given Campaign. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Campaign
   */
  async getDisplayCampaignsIdAnalytics(
    requestParameters: GetDisplayCampaignsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetDisplayCampaignAnalyticsResponse> {
    const response = await this.getDisplayCampaignsIdAnalyticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Basic endpoint for creating display campaigns.
   * Create a new Campaign
   */
  async postDisplayCampaignsRaw(
    requestParameters: PostDisplayCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DisplayCampaignCreateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiDisplayCampaignsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DisplayCampaignCreateResponseFromJSON(jsonValue)
    )
  }

  /**
   * Basic endpoint for creating display campaigns.
   * Create a new Campaign
   */
  async postDisplayCampaigns(
    requestParameters: PostDisplayCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DisplayCampaignCreateResponse> {
    const response = await this.postDisplayCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will take an existing display campaign, of any state, and duplicate it into a new draft campaign, with duplicated ad groups, products, and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing campaign into a draft state
   */
  async postDisplayCampaignsIdDuplicateAsyncRaw(
    requestParameters: PostDisplayCampaignsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AsyncTaskResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postDisplayCampaignsIdDuplicateAsync.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns/{id}/duplicate_async`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiDisplayCampaignsControllerDuplicateAsyncInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AsyncTaskResponseFromJSON(jsonValue))
  }

  /**
   * This method will take an existing display campaign, of any state, and duplicate it into a new draft campaign, with duplicated ad groups, products, and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing campaign into a draft state
   */
  async postDisplayCampaignsIdDuplicateAsync(
    requestParameters: PostDisplayCampaignsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AsyncTaskResponse> {
    const response = await this.postDisplayCampaignsIdDuplicateAsyncRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   *
   * Submit a campaign for approval
   */
  async postDisplayCampaignsIdSubmitRaw(
    requestParameters: PostDisplayCampaignsIdSubmitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DisplayCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postDisplayCampaignsIdSubmit.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns/{id}/submit`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DisplayCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Submit a campaign for approval
   */
  async postDisplayCampaignsIdSubmit(
    requestParameters: PostDisplayCampaignsIdSubmitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DisplayCampaignResponse> {
    const response = await this.postDisplayCampaignsIdSubmitRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Update the Campaign for given id.
   */
  async putDisplayCampaignsIdRaw(
    requestParameters: PutDisplayCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DisplayCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putDisplayCampaignsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/display/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiDisplayCampaignsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DisplayCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Update the Campaign for given id.
   */
  async putDisplayCampaignsId(
    requestParameters: PutDisplayCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DisplayCampaignResponse> {
    const response = await this.putDisplayCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetDisplayCampaignsSearchCriteriaStatusEnum = {
  Active: 'active',
  Ended: 'ended',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Unknown: 'unknown',
} as const
export type GetDisplayCampaignsSearchCriteriaStatusEnum =
  typeof GetDisplayCampaignsSearchCriteriaStatusEnum[keyof typeof GetDisplayCampaignsSearchCriteriaStatusEnum]
/**
 * @export
 */
export const GetDisplayCampaignsSortCriteriaFieldEnum = {
  Status: 'status',
  Name: 'name',
  StartsAt: 'starts_at',
  EndsAt: 'ends_at',
  Budget: 'budget',
  AnalyticsClicks: 'analytics.clicks',
  AnalyticsOpens: 'analytics.opens',
  AnalyticsSends: 'analytics.sends',
  AnalyticsCtr: 'analytics.ctr',
  AnalyticsClickToOpenRate: 'analytics.click_to_open_rate',
  AnalyticsDirectSales: 'analytics.direct_sales',
  AnalyticsHaloSales: 'analytics.halo_sales',
  AnalyticsSpend: 'analytics.spend',
  AnalyticsRoas: 'analytics.roas',
  AnalyticsHaloRoas: 'analytics.halo_roas',
  AnalyticsOpenRate: 'analytics.open_rate',
  AnalyticsAvgCpm: 'analytics.avg_cpm',
  AnalyticsImpressions: 'analytics.impressions',
  AnalyticsNtbDirectSales: 'analytics.ntb_direct_sales',
  AnalyticsNtbHaloSales: 'analytics.ntb_halo_sales',
  AnalyticsPercentNtbDirectSales: 'analytics.percent_ntb_direct_sales',
  AnalyticsPercentNtbHaloSales: 'analytics.percent_ntb_halo_sales',
} as const
export type GetDisplayCampaignsSortCriteriaFieldEnum =
  typeof GetDisplayCampaignsSortCriteriaFieldEnum[keyof typeof GetDisplayCampaignsSortCriteriaFieldEnum]
/**
 * @export
 */
export const GetDisplayCampaignsSortCriteriaDirectionEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const
export type GetDisplayCampaignsSortCriteriaDirectionEnum =
  typeof GetDisplayCampaignsSortCriteriaDirectionEnum[keyof typeof GetDisplayCampaignsSortCriteriaDirectionEnum]
/**
 * @export
 */
export const GetDisplayCampaignsIdAnalyticsMetricEnum = {
  Impressions: 'impressions',
  AvgCpm: 'avg_cpm',
  Clicks: 'clicks',
  Spend: 'spend',
} as const
export type GetDisplayCampaignsIdAnalyticsMetricEnum =
  typeof GetDisplayCampaignsIdAnalyticsMetricEnum[keyof typeof GetDisplayCampaignsIdAnalyticsMetricEnum]
