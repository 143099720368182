import { AnalyticsBrowser, InitOptions } from '@segment/analytics-next'
import { AnalyticsProviderImpl } from '../Analytics'

function initSegment(segmentWriteKey: string, config: InitOptions): AnalyticsProviderImpl {
  // @ts-ignore Ignoring any type
  const pageMiddleware = function ({ payload, next }) {
    payload.obj.context.page = {
      path: location.pathname,
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url: location.href,
    }
    next(payload)
  }

  const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey }, config)

  analytics.addSourceMiddleware(pageMiddleware)

  analytics.page()

  return analytics
}

export default initSegment
