// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BrandPageAggregatesResponseData } from './BrandPageAggregatesResponseData'
import {
  BrandPageAggregatesResponseDataFromJSON,
  BrandPageAggregatesResponseDataFromJSONTyped,
  BrandPageAggregatesResponseDataToJSON,
} from './BrandPageAggregatesResponseData'
import type { BrandPageAggregatesResponseMeta } from './BrandPageAggregatesResponseMeta'
import {
  BrandPageAggregatesResponseMetaFromJSON,
  BrandPageAggregatesResponseMetaFromJSONTyped,
  BrandPageAggregatesResponseMetaToJSON,
} from './BrandPageAggregatesResponseMeta'

/**
 *
 * @export
 * @interface BrandPageAggregatesResponse
 */
export interface BrandPageAggregatesResponse {
  /**
   *
   * @type {BrandPageAggregatesResponseData}
   * @memberof BrandPageAggregatesResponse
   */
  data: BrandPageAggregatesResponseData
  /**
   *
   * @type {BrandPageAggregatesResponseMeta}
   * @memberof BrandPageAggregatesResponse
   */
  meta: BrandPageAggregatesResponseMeta
}

/**
 * Check if a given object implements the BrandPageAggregatesResponse interface.
 */
export function instanceOfBrandPageAggregatesResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function BrandPageAggregatesResponseFromJSON(json: any): BrandPageAggregatesResponse {
  return BrandPageAggregatesResponseFromJSONTyped(json, false)
}

export function BrandPageAggregatesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BrandPageAggregatesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: BrandPageAggregatesResponseDataFromJSON(json['data']),
    meta: BrandPageAggregatesResponseMetaFromJSON(json['meta']),
  }
}

export function BrandPageAggregatesResponseToJSON(value?: BrandPageAggregatesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: BrandPageAggregatesResponseDataToJSON(value.data),
    meta: BrandPageAggregatesResponseMetaToJSON(value.meta),
  }
}
