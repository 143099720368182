// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAccountAnalyticsResponseDataAttributesSeries
 */
export interface GetAccountAnalyticsResponseDataAttributesSeries {
  /**
   * Chart object type
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  type: GetAccountAnalyticsResponseDataAttributesSeriesTypeEnum
  /**
   * Campaign ID
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  id: string
  /**
   * Chart x-axis label
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  x: string
  /**
   * Chart y-axis label
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  y: string
  /**
   * Chart start date
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  startDate: string
  /**
   * Chart end date
   * @type {string}
   * @memberof GetAccountAnalyticsResponseDataAttributesSeries
   */
  endDate: string
}

/**
 * @export
 */
export const GetAccountAnalyticsResponseDataAttributesSeriesTypeEnum = {
  Account: 'account',
} as const
export type GetAccountAnalyticsResponseDataAttributesSeriesTypeEnum =
  typeof GetAccountAnalyticsResponseDataAttributesSeriesTypeEnum[keyof typeof GetAccountAnalyticsResponseDataAttributesSeriesTypeEnum]

/**
 * Check if a given object implements the GetAccountAnalyticsResponseDataAttributesSeries interface.
 */
export function instanceOfGetAccountAnalyticsResponseDataAttributesSeries(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'x' in value
  isInstance = isInstance && 'y' in value
  isInstance = isInstance && 'startDate' in value
  isInstance = isInstance && 'endDate' in value

  return isInstance
}

export function GetAccountAnalyticsResponseDataAttributesSeriesFromJSON(
  json: any
): GetAccountAnalyticsResponseDataAttributesSeries {
  return GetAccountAnalyticsResponseDataAttributesSeriesFromJSONTyped(json, false)
}

export function GetAccountAnalyticsResponseDataAttributesSeriesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountAnalyticsResponseDataAttributesSeries {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    id: json['id'],
    x: json['x'],
    y: json['y'],
    startDate: json['start_date'],
    endDate: json['end_date'],
  }
}

export function GetAccountAnalyticsResponseDataAttributesSeriesToJSON(
  value?: GetAccountAnalyticsResponseDataAttributesSeries | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    id: value.id,
    x: value.x,
    y: value.y,
    start_date: value.startDate,
    end_date: value.endDate,
  }
}
