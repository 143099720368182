// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouch } from './GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinear } from './GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData
 */
export interface GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData
   */
  linear: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData {
  return GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: GetAdGroupKeywordsSumResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
