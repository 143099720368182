import { ButtonBase, Color, StylesOf } from '@instacart/ids-core'

export const DEFAULT_ICON_SIZE = 22

/** @deprecated Use UseButtonStylesOpts from 'components/ids-ads' instead */
export interface UseButtonStylesOpts {
  borderColor: Color | null
  textColor: Color
  normalBackground: Color | null
  hoverBackground: Color | null
  activeBackground: Color | null
  disabledBackground: Color | null
  disabledBorderColor: Color | null
  disabledTextColor?: Color
  baseButtonStyleOverrides?: StylesOf<typeof ButtonBase>
}

export interface UseStylesInternal {
  iconOnly?: boolean
  iconSize: number
}
