import { css } from '@emotion/react'
import { useTheme, spacing } from '@instacart/ids-core'
import { uniqueId } from 'lodash'
import { HTMLProps, useState } from 'react'
import toPx from 'common/toPx'
import useIntl from 'common/useIntl'
import { MessageIdType } from 'locales/types'

export interface SlimInputProps extends HTMLProps<HTMLInputElement> {
  placeholderId?: MessageIdType
  id?: string
  hasError?: boolean
}

function useStyles(hasError: boolean) {
  const theme = useTheme()

  return {
    input: css({
      boxSizing: 'border-box',
      border: `1px solid ${
        hasError ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale30
      }`,
      borderRadius: theme.radius.r4,
      padding: toPx`${spacing.s4} ${spacing.s8}`,
      color: theme.colors.systemGrayscale70,
      ...theme.typography.bodyMedium2,
      fontSize: 14,
      '&:hover:not(:disabled)': {
        boxShadow: `0 0 0 1px ${
          hasError ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale30
        }`,
      },
      '&:focus, &:focus:hover': {
        boxShadow: `0 0 0 1px ${
          hasError ? theme.colors.systemDetrimentalRegular : theme.colors.systemGrayscale70
        }`,
        borderColor: hasError
          ? theme.colors.systemDetrimentalRegular
          : theme.colors.systemGrayscale70,
        outline: 'none',
      },
      '&:disabled': {
        background: theme.colors.systemGrayscale10,
      },
      '::placeholder': {
        color: theme.colors.systemGrayscale50,
      },
      background: hasError ? theme.colors.systemDetrimentalLight : theme.colors.systemGrayscale00,
    }),
  }
}

/** @deprecated Use compact InputText from 'components/ids-ads' instead */
export function SlimInput({
  value,
  onChange = () => {},
  placeholder,
  placeholderId,
  id,
  hasError,
  ...props
}: SlimInputProps) {
  const intl = useIntl()
  const styles = useStyles(hasError || false)
  const [inputId] = useState(() => id || uniqueId('input-'))

  return (
    <input
      type="text"
      id={inputId}
      placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : placeholder}
      value={value}
      onChange={onChange}
      {...props}
      css={styles.input}
    />
  )
}
