import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import {
  FULL_SITE_URL,
  PROVI_FULL_SITE_URL,
} from 'common/knowledgeOwlWidget/knowledgeOwlWidget.constants'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import { useAuthContext, isProviAccount } from 'context/auth'
import { MessageIdType } from 'locales/types'

export interface HelpCenterLinkProps {
  // The intl message id for link's message. Should include a {helpCenterLink} tag.
  // (default: "Learn more in the {helpCenterLink}")
  messageId?: MessageIdType
  // The URL the link should point to (default: FULL_SITE_URL)
  href?: string
  // The intl message id for the href text.
  // (default: "Help Center")
  hrefTextMessageId?: MessageIdType
  color?: string
}

/**
 * A link to the Instacart Ads (KnowledgeOwl) Help Center
 */
export const HelpCenterLink = (props: HelpCenterLinkProps) => {
  const intl = useIntl()
  const theme = useTheme()

  const {
    messageId = 'components.helpCenterLink.learnMore',
    hrefTextMessageId = 'common.helpCenter',
    href = FULL_SITE_URL,
    color = theme.colors.systemGrayscale70,
  } = props

  const styles = {
    link: css({
      color,
      textDecoration: 'underline',
      '&:visited': {
        color,
      },
    }),
  }

  // Hide all help center links to the Instacart Help Center for provi accounts
  if (isProviAccount(useAuthContext()) && !href.startsWith(PROVI_FULL_SITE_URL)) {
    return null
  }

  return (
    <FormattedMessage
      id={messageId}
      values={{
        helpCenterLink: (
          <a css={styles.link} href={href} rel="noopener noreferrer" target="_blank">
            {intl.formatMessage({ id: hrefTextMessageId })}
          </a>
        ),
      }}
    />
  )
}
