// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { TargetingOptionsResponseData } from './TargetingOptionsResponseData'
import {
  TargetingOptionsResponseDataFromJSON,
  TargetingOptionsResponseDataFromJSONTyped,
  TargetingOptionsResponseDataToJSON,
} from './TargetingOptionsResponseData'
import type { TargetingOptionsResponseMeta } from './TargetingOptionsResponseMeta'
import {
  TargetingOptionsResponseMetaFromJSON,
  TargetingOptionsResponseMetaFromJSONTyped,
  TargetingOptionsResponseMetaToJSON,
} from './TargetingOptionsResponseMeta'

/**
 *
 * @export
 * @interface TargetingOptionsResponse
 */
export interface TargetingOptionsResponse {
  /**
   *
   * @type {TargetingOptionsResponseData}
   * @memberof TargetingOptionsResponse
   */
  data: TargetingOptionsResponseData
  /**
   *
   * @type {TargetingOptionsResponseMeta}
   * @memberof TargetingOptionsResponse
   */
  meta: TargetingOptionsResponseMeta
}

/**
 * Check if a given object implements the TargetingOptionsResponse interface.
 */
export function instanceOfTargetingOptionsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function TargetingOptionsResponseFromJSON(json: any): TargetingOptionsResponse {
  return TargetingOptionsResponseFromJSONTyped(json, false)
}

export function TargetingOptionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TargetingOptionsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: TargetingOptionsResponseDataFromJSON(json['data']),
    meta: TargetingOptionsResponseMetaFromJSON(json['meta']),
  }
}

export function TargetingOptionsResponseToJSON(value?: TargetingOptionsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: TargetingOptionsResponseDataToJSON(value.data),
    meta: TargetingOptionsResponseMetaToJSON(value.meta),
  }
}
