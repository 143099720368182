// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CoreIngredientsResponseDataAttributes
 */
export interface CoreIngredientsResponseDataAttributes {
  /**
   * Core ingredient term
   * @type {string}
   * @memberof CoreIngredientsResponseDataAttributes
   */
  term?: string
}

/**
 * Check if a given object implements the CoreIngredientsResponseDataAttributes interface.
 */
export function instanceOfCoreIngredientsResponseDataAttributes(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CoreIngredientsResponseDataAttributesFromJSON(
  json: any
): CoreIngredientsResponseDataAttributes {
  return CoreIngredientsResponseDataAttributesFromJSONTyped(json, false)
}

export function CoreIngredientsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CoreIngredientsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    term: !exists(json, 'term') ? undefined : json['term'],
  }
}

export function CoreIngredientsResponseDataAttributesToJSON(
  value?: CoreIngredientsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    term: value.term,
  }
}
