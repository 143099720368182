import { canUseDOM } from 'exenv'
import ReactDOM from 'react-dom'

function resolveParent(parent: React.ReactNode | Element | (() => Element)) {
  let resolvedParent: Element = document.body
  try {
    if (typeof parent === 'function') {
      resolvedParent = parent()
    } else if (parent !== undefined) {
      resolvedParent = parent as Element
    }
  } catch (_) {
    // do nothing
  }

  return resolvedParent
}

export default ({
  children,
  parent,
}: {
  children: React.ReactNode
  parent?: React.ReactNode | HTMLHeadElement
}) => {
  if (!canUseDOM) {
    return null
  }

  const resolvedParent = resolveParent(parent)
  return ReactDOM.createPortal(children, resolvedParent)
}
