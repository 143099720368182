import { Actions, makePolicyIdentifier, Resources } from 'common/rolePermissions'
import { hasCapability } from 'context'
import useCapabilities from 'hooks/useCapabilities'
import { Capabilities } from 'service/types/capabilities'

export function hasCampaignEditCapability(
  capabilitiesCtx: Capabilities,
  actions: Actions[],
  isDraft?: boolean
) {
  const editCapabilities = campaignEditCapabilities(actions, isDraft)
  return editCapabilities.some(capability => hasCapability(capabilitiesCtx, capability))
}

export function campaignEditCapabilities(actions: Actions[], isDraft?: boolean) {
  const editCapabilities: string[] = []
  actions.forEach(action => {
    editCapabilities.push(makePolicyIdentifier(Resources.CAMPAIGN, action))
    if (isDraft) editCapabilities.push(makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, action))
  })
  return editCapabilities
}

export function campaignCopyCapabilities(actions: Actions[]) {
  const copyCapabilities: string[] = []
  actions.forEach(action => {
    copyCapabilities.push(makePolicyIdentifier(Resources.CAMPAIGN, action))
    copyCapabilities.push(makePolicyIdentifier(Resources.CAMPAIGN_DRAFT, action))
  })
  return copyCapabilities
}

export const useCampaignCapability = (actions: Actions[], isDraft?: boolean): boolean => {
  return useCapabilities({
    capabilities: campaignEditCapabilities(actions, isDraft),
  })
}
