// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews
 */
export interface PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews {
  /**
   * The user who performed the review
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews
   */
  reviewer?: string
  /**
   * Any free form comments from the reviewer. The key allows navigating to the component under review, while the value is the comment itself
   * @type {{ [key: string]: string; }}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews
   */
  reviewComments?: { [key: string]: string }
}

/**
 * Check if a given object implements the PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews interface.
 */
export function instanceOfPatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON(
  json: any
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews {
  return PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped(
    json,
    false
  )
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews {
  if (json === undefined || json === null) {
    return json
  }
  return {
    reviewer: !exists(json, 'reviewer') ? undefined : json['reviewer'],
    reviewComments: !exists(json, 'review_comments') ? undefined : json['review_comments'],
  }
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON(
  value?: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateConsensusReviews | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    reviewer: value.reviewer,
    review_comments: value.reviewComments,
  }
}
