import { css } from '@emotion/react'
import { Text, Theme, useTheme } from '@instacart/ids-core'
import { FC, CSSProperties } from 'react'

export interface FormNotificationProps {
  status?: 'green' | 'yellow' | 'red' | 'gray' | 'blue'
  background?: string
  height?: string
  fontStyle?: CSSProperties
}

const getStyles = (theme: Theme, height?: string, background?: string) => css`
  .wrap {
    display: flex;
    align-self: center;
    height: ${height};
    animation: fadein 0.6s;
  }
  .status-indicator {
    width: 11px;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
  }
  .green {
    background-color: ${theme.colors.brandPrimaryRegular};
  }
  .yellow {
    background-color: #fec113;
  }
  .red {
    background-color: ${theme.colors.systemDetrimentalRegular};
  }
  .gray {
    background-color: ${theme.colors.systemGrayscale50};
  }
  .blue {
    background-color: ${theme.colors.brandHighlightRegular};
  }
  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${theme.colors.systemGrayscale70};
    background-color: ${background};
    padding-left: 10px;
    padding-right: 8px;
    height: 100%;
    width: 100%;
    border-radius: 2px 4px 4px 2px;
    white-space: pre-line;
  }
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
`

/** @deprecated */
const FormNotification: FC<React.PropsWithChildren<FormNotificationProps>> = props => {
  const theme = useTheme()
  const { children, status, background = theme.colors.systemGrayscale10, height, fontStyle } = props
  const styles = getStyles(theme, height, background)
  const selector = `status-indicator ${status}`

  return (
    <div css={styles} className="wrap">
      <div data-testid={selector} className={selector} />
      <div className="text-box">
        <Text
          className="text"
          typography="bodySmall2"
          as="div"
          style={{ color: theme.colors.systemGrayscale70, ...fontStyle }}
        >
          {children}
        </Text>
      </div>
    </div>
  )
}

FormNotification.defaultProps = {
  status: 'gray',
  height: '80px',
}

export default FormNotification
