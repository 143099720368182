// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { KeywordsAnalyticsResponseDataAttributesAnalytics } from './KeywordsAnalyticsResponseDataAttributesAnalytics'
import {
  KeywordsAnalyticsResponseDataAttributesAnalyticsFromJSON,
  KeywordsAnalyticsResponseDataAttributesAnalyticsFromJSONTyped,
  KeywordsAnalyticsResponseDataAttributesAnalyticsToJSON,
} from './KeywordsAnalyticsResponseDataAttributesAnalytics'
import type { KeywordsAnalyticsResponseDataAttributesBidRecs } from './KeywordsAnalyticsResponseDataAttributesBidRecs'
import {
  KeywordsAnalyticsResponseDataAttributesBidRecsFromJSON,
  KeywordsAnalyticsResponseDataAttributesBidRecsFromJSONTyped,
  KeywordsAnalyticsResponseDataAttributesBidRecsToJSON,
} from './KeywordsAnalyticsResponseDataAttributesBidRecs'

/**
 *
 * @export
 * @interface KeywordsAnalyticsResponseDataAttributes
 */
export interface KeywordsAnalyticsResponseDataAttributes {
  /**
   * Keyword
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  keyword: string
  /**
   * CPC Bid
   * @type {number}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  cpcBid?: number
  /**
   * Keyword matching type
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  matchingType: string
  /**
   * AdGroupKeyword bid type
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  bidType: string
  /**
   * AdGroupKeyword uuid
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  adGroupKeywordUuid?: string
  /**
   * Ad Group Keyword Status
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  adGroupKeywordStatus?: string
  /**
   * Suggested Bid
   * @type {number}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  suggestedBid?: number
  /**
   * Uncompetitive bid
   * @type {number}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  uncompetitiveBid?: number
  /**
   * Is competitive bid?
   * @type {boolean}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  isConfidentBid?: boolean
  /**
   * The bid strength Instacart has calculated, either green for strong, yellow for medium, and red for weak. A bid is considered strong if it is competitive the majority of the time across all eligible placements, when considering the bid you entered and each product's conversion probability. You can adjust your bid to be more competitive at any time. If we do not have enough information to calculate a suggested bid, this will return 'unknown'.
   * @type {string}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  bidStrength?: KeywordsAnalyticsResponseDataAttributesBidStrengthEnum
  /**
   * Bid trace info
   * @type {Array<string>}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  traceInfo?: Array<string>
  /**
   *
   * @type {KeywordsAnalyticsResponseDataAttributesBidRecs}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  bidRecs?: KeywordsAnalyticsResponseDataAttributesBidRecs
  /**
   *
   * @type {KeywordsAnalyticsResponseDataAttributesAnalytics}
   * @memberof KeywordsAnalyticsResponseDataAttributes
   */
  analytics?: KeywordsAnalyticsResponseDataAttributesAnalytics
}

/**
 * @export
 */
export const KeywordsAnalyticsResponseDataAttributesBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type KeywordsAnalyticsResponseDataAttributesBidStrengthEnum =
  typeof KeywordsAnalyticsResponseDataAttributesBidStrengthEnum[keyof typeof KeywordsAnalyticsResponseDataAttributesBidStrengthEnum]

/**
 * Check if a given object implements the KeywordsAnalyticsResponseDataAttributes interface.
 */
export function instanceOfKeywordsAnalyticsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keyword' in value
  isInstance = isInstance && 'matchingType' in value
  isInstance = isInstance && 'bidType' in value

  return isInstance
}

export function KeywordsAnalyticsResponseDataAttributesFromJSON(
  json: any
): KeywordsAnalyticsResponseDataAttributes {
  return KeywordsAnalyticsResponseDataAttributesFromJSONTyped(json, false)
}

export function KeywordsAnalyticsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): KeywordsAnalyticsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keyword: json['keyword'],
    cpcBid: !exists(json, 'cpc_bid') ? undefined : json['cpc_bid'],
    matchingType: json['matching_type'],
    bidType: json['bid_type'],
    adGroupKeywordUuid: !exists(json, 'ad_group_keyword_uuid')
      ? undefined
      : json['ad_group_keyword_uuid'],
    adGroupKeywordStatus: !exists(json, 'ad_group_keyword_status')
      ? undefined
      : json['ad_group_keyword_status'],
    suggestedBid: !exists(json, 'suggested_bid') ? undefined : json['suggested_bid'],
    uncompetitiveBid: !exists(json, 'uncompetitive_bid') ? undefined : json['uncompetitive_bid'],
    isConfidentBid: !exists(json, 'is_confident_bid') ? undefined : json['is_confident_bid'],
    bidStrength: !exists(json, 'bid_strength') ? undefined : json['bid_strength'],
    traceInfo: !exists(json, 'trace_info') ? undefined : json['trace_info'],
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : KeywordsAnalyticsResponseDataAttributesBidRecsFromJSON(json['bid_recs']),
    analytics: !exists(json, 'analytics')
      ? undefined
      : KeywordsAnalyticsResponseDataAttributesAnalyticsFromJSON(json['analytics']),
  }
}

export function KeywordsAnalyticsResponseDataAttributesToJSON(
  value?: KeywordsAnalyticsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keyword: value.keyword,
    cpc_bid: value.cpcBid,
    matching_type: value.matchingType,
    bid_type: value.bidType,
    ad_group_keyword_uuid: value.adGroupKeywordUuid,
    ad_group_keyword_status: value.adGroupKeywordStatus,
    suggested_bid: value.suggestedBid,
    uncompetitive_bid: value.uncompetitiveBid,
    is_confident_bid: value.isConfidentBid,
    bid_strength: value.bidStrength,
    trace_info: value.traceInfo,
    bid_recs: KeywordsAnalyticsResponseDataAttributesBidRecsToJSON(value.bidRecs),
    analytics: KeywordsAnalyticsResponseDataAttributesAnalyticsToJSON(value.analytics),
  }
}
