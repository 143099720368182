import { css } from '@emotion/react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import layers from 'common/layers'
import Portal from 'components/Portal'
import { Notification } from 'context/notifications'
import Toast from './Toast'
import { DEFAULT_NOTIFICATION_WIDTH } from './utils'

const TRANSITION_TIME_MS = 250

export interface NotificationsRendererProps {
  notifications: Notification[]
  onClearNotification(id: number): void
}

const styles = {
  notifications: css`
    position: fixed;
    left: 1.5rem;
    bottom: 1.5rem;
    width: ${DEFAULT_NOTIFICATION_WIDTH};
    max-width: calc(100vw - 3rem);
    z-index: ${layers[9]};
  `,
  notificationWrapper: css`
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }

    &.enter {
      opacity: 0;
      transform: translateX(-100%);
    }

    &.enter-active {
      opacity: 1;
      transform: translateX(0);
      transition-property: opacity, transform;
      transition-duration: ${TRANSITION_TIME_MS}ms;
      transition-timing-function: ease-out;
    }

    &.exit {
      opacity: 1;
      transform: translateX(0);
    }

    &.exit-active {
      opacity: 0;
      transform: translateX(-100%);
      transition-property: opacity, transform;
      transition-duration: ${TRANSITION_TIME_MS}ms;
      transition-timing-function: ease-in;
    }
  `,
}

export default function NotificationsRenderer({
  notifications,
  onClearNotification,
}: NotificationsRendererProps) {
  return (
    <Portal>
      <div css={styles.notifications}>
        <TransitionGroup>
          {notifications.map(({ id, ...notification }) => (
            <CSSTransition timeout={TRANSITION_TIME_MS} key={id}>
              <div css={styles.notificationWrapper} className="notification-wrapper">
                <Toast {...notification} onClear={() => onClearNotification(id)} />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </Portal>
  )
}
