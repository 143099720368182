// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections {
  /**
   * Collection name
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections
   */
  name: string
  /**
   * Whether the collection is sponsored
   * @type {boolean}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections
   */
  sponsored: boolean
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections
   */
  properties: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'sponsored' in value
  isInstance = isInstance && 'properties' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    sponsored: json['sponsored'],
    properties:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
        json['properties']
      ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollections | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    sponsored: value.sponsored,
    properties:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
        value.properties
      ),
  }
}
