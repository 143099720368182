// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PatchVideoAdGroupsIdParamBrandPageBlockSnapshot,
  instanceOfPatchVideoAdGroupsIdParamBrandPageBlockSnapshot,
  PatchVideoAdGroupsIdParamBrandPageBlockSnapshotFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockSnapshot'
import {
  PatchVideoAdGroupsIdParamBrandPageSnapshot,
  instanceOfPatchVideoAdGroupsIdParamBrandPageSnapshot,
  PatchVideoAdGroupsIdParamBrandPageSnapshotFromJSON,
  PatchVideoAdGroupsIdParamBrandPageSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageSnapshot'
import {
  PatchVideoAdGroupsIdParamCreativeSnapshot,
  instanceOfPatchVideoAdGroupsIdParamCreativeSnapshot,
  PatchVideoAdGroupsIdParamCreativeSnapshotFromJSON,
  PatchVideoAdGroupsIdParamCreativeSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamCreativeSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamCreativeSnapshot'
import {
  PatchVideoAdGroupsIdParamDisplayAdGroupSnapshot,
  instanceOfPatchVideoAdGroupsIdParamDisplayAdGroupSnapshot,
  PatchVideoAdGroupsIdParamDisplayAdGroupSnapshotFromJSON,
  PatchVideoAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamDisplayAdGroupSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamDisplayAdGroupSnapshot'
import {
  PatchVideoAdGroupsIdParamDisplayAssetSnapshot,
  instanceOfPatchVideoAdGroupsIdParamDisplayAssetSnapshot,
  PatchVideoAdGroupsIdParamDisplayAssetSnapshotFromJSON,
  PatchVideoAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamDisplayAssetSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamDisplayAssetSnapshot'
import {
  PatchVideoAdGroupsIdParamDisplayProductAssetSnapshot,
  instanceOfPatchVideoAdGroupsIdParamDisplayProductAssetSnapshot,
  PatchVideoAdGroupsIdParamDisplayProductAssetSnapshotFromJSON,
  PatchVideoAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamDisplayProductAssetSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamDisplayProductAssetSnapshot'

/**
 * @type PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & PatchVideoAdGroupsIdParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & PatchVideoAdGroupsIdParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PatchVideoAdGroupsIdParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & PatchVideoAdGroupsIdParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & PatchVideoAdGroupsIdParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & PatchVideoAdGroupsIdParamDisplayProductAssetSnapshot)

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  return PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PatchVideoAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PatchVideoAdGroupsIdParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PatchVideoAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PatchVideoAdGroupsIdParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PatchVideoAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PatchVideoAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PatchVideoAdGroupsIdParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PatchVideoAdGroupsIdParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PatchVideoAdGroupsIdParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PatchVideoAdGroupsIdParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PatchVideoAdGroupsIdParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PatchVideoAdGroupsIdParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
