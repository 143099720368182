// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutReservationAdGroupsIdParamAssetFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamAssetFieldDecisions,
  PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamAssetFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamAssetFieldDecisions'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamBrandPageBlockFieldDecisions,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions'
import {
  PutReservationAdGroupsIdParamBrandPageFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamBrandPageFieldDecisions,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamBrandPageFieldDecisions'
import {
  PutReservationAdGroupsIdParamCreativeFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamCreativeFieldDecisions,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamCreativeFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamCreativeFieldDecisions'
import {
  PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamDisplayAdGroupFieldDecisions,
  PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisions'
import {
  PutReservationAdGroupsIdParamProductAssetFieldDecisions,
  instanceOfPutReservationAdGroupsIdParamProductAssetFieldDecisions,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSON,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped,
  PutReservationAdGroupsIdParamProductAssetFieldDecisionsToJSON,
} from './PutReservationAdGroupsIdParamProductAssetFieldDecisions'

/**
 * @type PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & PutReservationAdGroupsIdParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & PutReservationAdGroupsIdParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PutReservationAdGroupsIdParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & PutReservationAdGroupsIdParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & PutReservationAdGroupsIdParamProductAssetFieldDecisions)

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutReservationAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutReservationAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutReservationAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutReservationAdGroupsIdParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PutReservationAdGroupsIdParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PutReservationAdGroupsIdParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutReservationAdGroupsIdParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PutReservationAdGroupsIdParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PutReservationAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
