// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogo } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogo'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogoToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogo'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImage } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImage'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImageFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImageFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImageToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImage'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobile } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobile'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobileFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobile'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWeb } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWeb'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWebFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWebToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWeb'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnail } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnail'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnailFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnailFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnailToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnail'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobile } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobile'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobile'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWeb } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWeb'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWebToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWeb'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobile } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobile'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobileToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobile'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWeb } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWeb'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWebToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWeb'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideo } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideo'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideoFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideoFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideoToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideo'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
 */
export interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets {
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWeb}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  topImageWeb?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobile}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  topImageMobile?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobile
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImage}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  heroImage?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImage
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogo}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  brandLogo?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogo
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWeb}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  imageWeb?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobile}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  imageMobile?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobile
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideo}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  video?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideo
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnail}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  thumbnail?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnail
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWeb}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  uvcImageWeb?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWeb
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobile}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets
   */
  uvcImageMobile?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets {
  return ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    video: !exists(json, 'video')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    top_image_mobile:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    hero_image: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsHeroImageToJSON(
      value.heroImage
    ),
    brand_logo: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsBrandLogoToJSON(
      value.brandLogo
    ),
    image_web: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageWebToJSON(
      value.imageWeb
    ),
    image_mobile:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    video: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsThumbnailToJSON(
      value.thumbnail
    ),
    uvc_image_web:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageWebToJSON(
        value.uvcImageWeb
      ),
    uvc_image_mobile:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeAssetsUvcImageMobileToJSON(
        value.uvcImageMobile
      ),
  }
}
