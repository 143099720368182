import { exchangeConfigs } from './schemas'

// The public type that represents an exchange config.
export interface ExchangeConfig {
  machineName: string
  featureOverrides: {
    sponsoredProductMinBid: () => number
    displayMinBid: () => number
  }
}

const buildExchangeConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exchangeSchema: { [key: string]: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  _baseSchema: { [key: string]: any }
): ExchangeConfig => {
  return {
    machineName: exchangeSchema.machine_name,
    featureOverrides: {
      /*
        For the next feature override that is added, you need to use the base schema if the requested exchange schema
        does not define the override. This isn't being done for the minimum bids because all configs must define a minimum bid.

        The logic would look like this:
          const moneyValue =
            exchangeSchema.feature_overrides?.sponsored_product_minimum_bid?.money_value ||
            baseSchema.feature_overrides?.sponsored_product_minimum_bid?.money_value
      */
      sponsoredProductMinBid: () => {
        // This is safe because all ads manager configs must define a sponsored_product_minimum_bid
        const moneyValue =
          exchangeSchema.feature_overrides?.sponsored_product_minimum_bid?.money_value
        return getBidNumber(moneyValue.units, moneyValue.nanos)
      },
      displayMinBid: () => {
        // This is safe because all ads manager configs must define a display_minimum_bid
        const moneyValue = exchangeSchema.feature_overrides?.display_minimum_bid?.money_value
        return getBidNumber(moneyValue.units, moneyValue.nanos)
      },
    },
  }
}

function getBidNumber(units: number, nanos: number): number {
  const nanosToCents = nanos / 1000000000
  return units + nanosToCents
}

export const getExchangeConfig = (machineName: string) => {
  const schema = exchangeConfigs[machineName]
  if (!schema) throw new Error(`No exchange config found for ${machineName}`)
  return buildExchangeConfig(schema, exchangeConfigs.base)
}
