import BaseSelect, { mergeStyles, Props as BaseSelectProps } from 'react-select'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'
import { useSelectComponentOverrides } from './components'
import { DefaultOptionType, CommonSelectProps } from './types'
import { useStyles } from './useStyles'

export type SelectProps<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
> = BaseSelectProps<OptionType, IsMulti, GroupType> & CommonSelectProps

export function Select<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  position = 'left',
  variant = 'secondary',
  compact,
  block,
  closeMenuOnSelect = false,
  onInputChange,
  components,
  maxWidth,
  allowSelectAll,
  allowClear,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType>) {
  const styles = useStyles<OptionType, IsMulti, GroupType>({
    position,
    variant,
    compact,
    block,
    maxWidth,
  })

  return (
    <BaseSelect
      hideSelectedOptions={false}
      components={{
        ...useSelectComponentOverrides({ allowSelectAll, allowClear }),
        ...components,
      }}
      closeMenuOnSelect={closeMenuOnSelect}
      {...props}
      onInputChange={onInputChange}
      styles={props.styles ? mergeStyles(styles, props.styles) : styles}
    />
  )
}
