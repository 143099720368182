// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutAccountPaymentResponseDataAttributesCreditCardPaymentInformation } from './PutAccountPaymentResponseDataAttributesCreditCardPaymentInformation'
import {
  PutAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSON,
  PutAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSONTyped,
  PutAccountPaymentResponseDataAttributesCreditCardPaymentInformationToJSON,
} from './PutAccountPaymentResponseDataAttributesCreditCardPaymentInformation'
import type { PutAccountPaymentResponseDataAttributesNextPaymentOption } from './PutAccountPaymentResponseDataAttributesNextPaymentOption'
import {
  PutAccountPaymentResponseDataAttributesNextPaymentOptionFromJSON,
  PutAccountPaymentResponseDataAttributesNextPaymentOptionFromJSONTyped,
  PutAccountPaymentResponseDataAttributesNextPaymentOptionToJSON,
} from './PutAccountPaymentResponseDataAttributesNextPaymentOption'

/**
 *
 * @export
 * @interface PutAccountPaymentResponseDataAttributes
 */
export interface PutAccountPaymentResponseDataAttributes {
  /**
   * Account Name
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  name: string
  /**
   * Default memo (attention to) for the account
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  defaultMemo: string
  /**
   * Default payment type for the account
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  defaultPaymentType: string
  /**
   * Default bill payer for the account
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  billPayer: string
  /**
   * Account invoicing type, if provided must be one of: instacart_invoicing, credit_card
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  paymentOption: PutAccountPaymentResponseDataAttributesPaymentOptionEnum
  /**
   * Invoice option must be one of: email, portal
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  invoiceOption: PutAccountPaymentResponseDataAttributesInvoiceOptionEnum
  /**
   * Payment term in days must be one of: 30, 45, 60, 75, 90, 120
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  paymentTermDays: PutAccountPaymentResponseDataAttributesPaymentTermDaysEnum
  /**
   * Comma separated list of emails for email invoicing
   * @type {string}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  emailDistribution: string
  /**
   *
   * @type {PutAccountPaymentResponseDataAttributesCreditCardPaymentInformation}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  creditCardPaymentInformation?: PutAccountPaymentResponseDataAttributesCreditCardPaymentInformation
  /**
   *
   * @type {PutAccountPaymentResponseDataAttributesNextPaymentOption}
   * @memberof PutAccountPaymentResponseDataAttributes
   */
  nextPaymentOption?: PutAccountPaymentResponseDataAttributesNextPaymentOption
}

/**
 * @export
 */
export const PutAccountPaymentResponseDataAttributesPaymentOptionEnum = {
  InstacartInvoicing: 'instacart_invoicing',
  CreditCard: 'credit_card',
} as const
export type PutAccountPaymentResponseDataAttributesPaymentOptionEnum =
  typeof PutAccountPaymentResponseDataAttributesPaymentOptionEnum[keyof typeof PutAccountPaymentResponseDataAttributesPaymentOptionEnum]

/**
 * @export
 */
export const PutAccountPaymentResponseDataAttributesInvoiceOptionEnum = {
  Email: 'email',
  Portal: 'portal',
} as const
export type PutAccountPaymentResponseDataAttributesInvoiceOptionEnum =
  typeof PutAccountPaymentResponseDataAttributesInvoiceOptionEnum[keyof typeof PutAccountPaymentResponseDataAttributesInvoiceOptionEnum]

/**
 * @export
 */
export const PutAccountPaymentResponseDataAttributesPaymentTermDaysEnum = {
  _30: '30',
  _45: '45',
  _60: '60',
  _75: '75',
  _90: '90',
  _120: '120',
} as const
export type PutAccountPaymentResponseDataAttributesPaymentTermDaysEnum =
  typeof PutAccountPaymentResponseDataAttributesPaymentTermDaysEnum[keyof typeof PutAccountPaymentResponseDataAttributesPaymentTermDaysEnum]

/**
 * Check if a given object implements the PutAccountPaymentResponseDataAttributes interface.
 */
export function instanceOfPutAccountPaymentResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'defaultMemo' in value
  isInstance = isInstance && 'defaultPaymentType' in value
  isInstance = isInstance && 'billPayer' in value
  isInstance = isInstance && 'paymentOption' in value
  isInstance = isInstance && 'invoiceOption' in value
  isInstance = isInstance && 'paymentTermDays' in value
  isInstance = isInstance && 'emailDistribution' in value

  return isInstance
}

export function PutAccountPaymentResponseDataAttributesFromJSON(
  json: any
): PutAccountPaymentResponseDataAttributes {
  return PutAccountPaymentResponseDataAttributesFromJSONTyped(json, false)
}

export function PutAccountPaymentResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAccountPaymentResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultMemo: json['default_memo'],
    defaultPaymentType: json['default_payment_type'],
    billPayer: json['bill_payer'],
    paymentOption: json['payment_option'],
    invoiceOption: json['invoice_option'],
    paymentTermDays: json['payment_term_days'],
    emailDistribution: json['email_distribution'],
    creditCardPaymentInformation: !exists(json, 'credit_card_payment_information')
      ? undefined
      : PutAccountPaymentResponseDataAttributesCreditCardPaymentInformationFromJSON(
          json['credit_card_payment_information']
        ),
    nextPaymentOption: !exists(json, 'next_payment_option')
      ? undefined
      : PutAccountPaymentResponseDataAttributesNextPaymentOptionFromJSON(
          json['next_payment_option']
        ),
  }
}

export function PutAccountPaymentResponseDataAttributesToJSON(
  value?: PutAccountPaymentResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_memo: value.defaultMemo,
    default_payment_type: value.defaultPaymentType,
    bill_payer: value.billPayer,
    payment_option: value.paymentOption,
    invoice_option: value.invoiceOption,
    payment_term_days: value.paymentTermDays,
    email_distribution: value.emailDistribution,
    credit_card_payment_information:
      PutAccountPaymentResponseDataAttributesCreditCardPaymentInformationToJSON(
        value.creditCardPaymentInformation
      ),
    next_payment_option: PutAccountPaymentResponseDataAttributesNextPaymentOptionToJSON(
      value.nextPaymentOption
    ),
  }
}
