import { getIn } from 'formik'
import { compact, omit } from 'lodash'
import { areNestedObjectsEmpty, isNestedObjectEmpty } from 'common/utils'
import {
  BlockAssetTypeEnum,
  BrandPageFormData,
  DurationEnum,
  BlocksType,
  BlockType,
  BannerType,
  LogoType,
  SmallCarouselType,
  LargeCarouselType,
  ProductGroupCarouselType,
  ImageTextType,
  LinkedBrandPageFormData,
} from 'pages/BrandPage/brandPage.types'
import { assetVal, brandInputFields } from 'pages/BrandPage/utils'
import { PostBrandPagesRequest } from 'service/openapi/__codegen__/apis/BrandPagesApi'
import {
  ApiBrandPagesControllerCreateInputBrandPageLevelEnum as LevelFieldEnum,
  ApiBrandPagesControllerCreateInputBrandPagePurposeEnum,
  ApiBrandPagesControllerCreateInputBrandPageTypeEnum,
} from 'service/openapi/__codegen__/models/ApiBrandPagesControllerCreateInputBrandPage'
import { PostBrandPagesIdStatusParamDataAttributesBlocks as PayloadType } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamDataAttributesBlocks'
import { PostBrandPagesIdStatusParamDataAttributesLinkedBrands as LinkedBrandType } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamDataAttributesLinkedBrands'
import { PostBrandPagesParamBlockBrandLogo } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockBrandLogo'
import { PostBrandPagesParamBlockHeroBanner } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockHeroBanner'
import { PostBrandPagesParamBlockImageAndText } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockImageAndText'
import { PostBrandPagesParamBlockProductCollection } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockProductCollection'
import { PostBrandPagesParamBlockProductGroupCollection } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockProductGroupCollection'
import { PostBrandPagesParamBlockSectionBanner } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockSectionBanner'
import { PostBrandPagesParamBlockSpotlightProductCollection } from 'service/openapi/__codegen__/models/PostBrandPagesParamBlockSpotlightProductCollection'
import { PostBrandPagesParamBrandPageBlocks } from 'service/openapi/__codegen__/models/PostBrandPagesParamBrandPageBlocks'
import { StandaloneBrandPageResponseData } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseData'
import {
  StandaloneBrandPageResponseDataAttributesLevelEnum as LevelPayloadEnum,
  StandaloneBrandPageResponseDataAttributesPurposeEnum as PurposeEnum,
} from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseDataAttributes'
import {
  ALT_FIELD,
  DESKTOP_FIELD,
  LOGO_ASSET_FIELD,
  MOBILE_FIELD,
  TEXT_FIELD,
  TITLE_FIELD,
  DEFAULT_HIERARCHY_ARRAY_LENGTH,
} from '../constants'

type AssetSizeType = 'desktop' | 'mobile' | 'logo'

export { PutBrandPagesIdParamDataAttributesBlocks as PayloadType } from 'service/openapi/__codegen__/models/PutBrandPagesIdParamDataAttributesBlocks'

type LogoPayloadType = {
  discriminator: 'brand_logo.evergreen.v1'
} & PostBrandPagesParamBlockBrandLogo
type HeroPayloadType = {
  discriminator: 'hero_banner.evergreen.v1'
} & PostBrandPagesParamBlockHeroBanner
type InPagePayloadType = {
  discriminator: 'section_banner.evergreen.v1'
} & PostBrandPagesParamBlockSectionBanner
type SmallCarouselPayloadType = {
  discriminator: 'product_collection.evergreen.v1'
} & PostBrandPagesParamBlockProductCollection
type ProductGroupPayloadType = {
  discriminator: 'product_group_collection.evergreen.v1'
} & PostBrandPagesParamBlockProductGroupCollection
type LargeCarouselPayloadType = {
  discriminator: 'spotlight_product_collection.evergreen.v1'
} & PostBrandPagesParamBlockSpotlightProductCollection
type ImageTextPayloadType = {
  discriminator: 'image_and_text.evergreen.v1'
} & PostBrandPagesParamBlockImageAndText

const getUrlPayload = (block: BlockType, type?: AssetSizeType) => {
  const typeKey = type ? `[${type}]` : ''
  return {
    altText: getIn(block, 'assets.alt'),
    previewUrl: getIn(block, `assets${typeKey}.urls.previewUrl`),
    uploadedUri: getIn(block, `assets${typeKey}.urls.uploadedUri`),
    id: getIn(block, `assets${typeKey}.id`),
  }
}

const getSizePayload = (block: BlockType) => {
  const isImageText = block.type === BlockAssetTypeEnum.IMAGE_TEXT
  const mobileAsset =
    !isImageText || !!getIn(block, 'assets.mobile.urls.previewUrl')
      ? { mobile: getUrlPayload(block, 'mobile') }
      : {}
  return {
    assets: {
      desktop: getUrlPayload(block, 'desktop'),
      ...mobileAsset,
    },
  }
}

const getTypePayload = (type: string) => ({
  type,
  discriminator: type,
})

const bannerToPayload = (block: BlockType) => ({
  ...getTypePayload(block.type),
  ...getSizePayload(block),
  id: block.id,
})

const logoToPayload = (block: BlockType) => ({
  ...getTypePayload(block.type),
  assets: {
    logo: getUrlPayload(block),
  },
  id: block.id,
})

const smallCarouselToPayload = (block: BlockType) => ({
  ...getTypePayload(block.type),
  ...block,
})

const largeCarouselToPayload = (block: BlockType) => {
  const productAssets = Object.fromEntries(
    Object.entries(block.productAssets || {}).map(([productId, assets]) => {
      const { alt, mobile, desktop } = assets

      const hasAsset = desktop.urls.uploadedUri || mobile.urls.uploadedUri
      if (!hasAsset) {
        return []
      }
      const productIdNum = Number(productId.replace(/'/g, ''))

      return [
        productIdNum,
        {
          ...(desktop.urls.uploadedUri && {
            desktop: {
              altText: alt,
              id: desktop.id,
              ...desktop.urls,
            },
          }),
          ...(mobile.urls.uploadedUri && {
            mobile: {
              altText: alt,
              id: mobile.id,
              ...mobile.urls,
            },
          }),
        },
      ]
    })
  )

  return {
    ...getTypePayload(block.type),
    ...block,
    productAssets,
  }
}

const productGroupToPayload = (block: BlockType) => ({
  ...getTypePayload(block.type),
  ...block,
})

const imageTextToPayload = (block: BlockType) => ({
  ...block,
  ...getTypePayload(block.type),
  ...getSizePayload(block),
})

const blockToPayload = (block: BlockType) => {
  const alt = getIn(block, ALT_FIELD)
  const desktop = getIn(block, assetVal(DESKTOP_FIELD))
  const mobile = getIn(block, assetVal(MOBILE_FIELD))
  const areImageAssetsEmpty = !(alt || desktop || mobile)

  switch (block.type) {
    case BlockAssetTypeEnum.HERO_BANNER: {
      if (areImageAssetsEmpty) return undefined
      return bannerToPayload(block) as HeroPayloadType
    }
    case BlockAssetTypeEnum.IN_PAGE_BANNER: {
      if (areImageAssetsEmpty) return undefined
      return bannerToPayload(block) as InPagePayloadType
    }
    case BlockAssetTypeEnum.LOGO: {
      if (isNestedObjectEmpty(getIn(block, LOGO_ASSET_FIELD))) return undefined
      return logoToPayload(block) as LogoPayloadType
    }
    case BlockAssetTypeEnum.PRODUCT_COLLECTION: {
      if (isNestedObjectEmpty(omit(block, ['type', 'id']))) return undefined
      return smallCarouselToPayload(block) as SmallCarouselPayloadType
    }
    case BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION: {
      if (isNestedObjectEmpty(omit(block, ['type', 'id']))) return undefined
      return productGroupToPayload(block) as ProductGroupPayloadType
    }
    case BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION: {
      if (isNestedObjectEmpty(omit(block, ['type', 'id']))) return undefined
      return largeCarouselToPayload(block) as LargeCarouselPayloadType
    }
    case BlockAssetTypeEnum.IMAGE_TEXT: {
      const title = getIn(block, TITLE_FIELD)
      const text = getIn(block, TEXT_FIELD)
      if (areImageAssetsEmpty && !(title && text)) return undefined
      return imageTextToPayload(block) as ImageTextPayloadType
    }
    default: {
      return undefined
    }
  }
}

const blocksToPayload = (blocks?: BlocksType) => {
  if (!blocks) return []
  const blockAssets = blocks.map(block => omit(block, ['type']))
  const empty = blocks ? areNestedObjectsEmpty(blockAssets) : false
  if (empty) return []

  return compact(blocks.map(block => blockToPayload(block)))
}

export const toCreateBrandPageRequest = (
  formData: BrandPageFormData,
  skipValidation = false
): PostBrandPagesRequest => {
  const isSeasonal = formData.duration === DurationEnum.Seasonal
  const numSegments = brandInputFields(formData.level, isSeasonal).length
  const hierarchy = formData.hierarchy.slice(0, numSegments)
  const taxonomyIds =
    formData.level !== LevelFieldEnum.Parent
      ? formData.linkedBrands.map(linkedBrand => linkedBrand.entityId)
      : []

  return {
    body: {
      brandPage: {
        type: formData.type as ApiBrandPagesControllerCreateInputBrandPageTypeEnum,
        name: formData.name,
        enabled: formData.enabled,
        level: formData.level,
        purpose:
          formData.duration.toUpperCase() as ApiBrandPagesControllerCreateInputBrandPagePurposeEnum,
        pageHierarchy: hierarchy,
        blocks: blocksToPayload(formData.blocks) as PostBrandPagesParamBrandPageBlocks[],
        taxonomyIds,
      },
      skipValidation,
    },
  }
}

const getTypeField = (block: PayloadType) => ({ type: block.type as string })

const getType = (type?: AssetSizeType) => (type ? `[${type}]` : '')

const getUrlFields = (block: PayloadType, type: AssetSizeType) => {
  return {
    urls: {
      previewUrl: getIn(block, `assets${getType(type)}.previewUrl`),
      uploadedUri: getIn(block, `assets${getType(type)}.uploadedUri`),
    },
  }
}

const getAssetId = (block: PayloadType, type: AssetSizeType) => {
  return {
    id: getIn(block, `assets${getType(type)}.id`),
  }
}

const getSizeFields = (block: PayloadType) => {
  return {
    assets: {
      alt: getIn(block, 'assets.desktop.altText') || getIn(block, 'assets.mobile.altText'),
      mobile: { ...getUrlFields(block, 'mobile'), ...getAssetId(block, 'mobile') },
      desktop: { ...getUrlFields(block, 'desktop'), ...getAssetId(block, 'desktop') },
    },
  }
}

const bannerToField = (block: PayloadType) => ({
  ...getTypeField(block),
  ...getSizeFields(block),
  id: block.id,
})

const logoToField = (block: PayloadType) => ({
  ...getTypeField(block),
  assets: {
    alt: getIn(block, 'assets.logo.altText'),
    ...getUrlFields(block, 'logo'),
    ...getAssetId(block, 'logo'),
  },
  id: block.id,
})

const smallCarouselToField = (block: PayloadType) => ({
  ...getTypeField(block),
  productIds: getIn(block, 'productIds'),
  content: getIn(block, 'content'),
  id: block.id,
})

const largeCarouselToField = (block: PayloadType) => {
  if (block.discriminator !== 'spotlight_product_collection.evergreen.v1') {
    return
  }

  const productAssets = Object.fromEntries(
    Object.entries(block.productAssets || {}).map(([productId, assets]) => {
      return [
        `${productId}`,
        {
          alt: assets.desktop?.altText || assets.mobile?.altText,
          mobile: {
            urls: {
              previewUrl: assets.mobile?.previewUrl,
              uploadedUri: assets.mobile?.uploadedUri,
            },
            id: assets.mobile?.id,
          },
          desktop: {
            urls: {
              previewUrl: assets.desktop?.previewUrl,
              uploadedUri: assets.desktop?.uploadedUri,
            },
            id: assets.desktop?.id,
          },
        },
      ]
    })
  )

  return {
    ...getTypeField(block),
    productIds: getIn(block, 'productIds'),
    content: getIn(block, 'content'),
    productAssets,
    id: block.id,
  }
}

const productGroupCarouselToField = (block: PayloadType) => ({
  ...getTypeField(block),
  productGroupIds: getIn(block, 'productGroupIds'),
  content: getIn(block, 'content'),
  id: block.id,
})

const imageTextToField = (block: PayloadType) => ({
  content: getIn(block, 'content'),
  id: block.id,
  ...getTypeField(block),
  ...getSizeFields(block),
})

export const payloadToField = (block: PayloadType) => {
  switch (block.discriminator) {
    case BlockAssetTypeEnum.HERO_BANNER: {
      return bannerToField(block) as BannerType
    }
    case BlockAssetTypeEnum.IN_PAGE_BANNER: {
      return bannerToField(block) as BannerType
    }
    case BlockAssetTypeEnum.LOGO: {
      return logoToField(block) as LogoType
    }
    case BlockAssetTypeEnum.PRODUCT_COLLECTION: {
      return smallCarouselToField(block) as SmallCarouselType
    }
    case BlockAssetTypeEnum.SPOTLIGHT_PRODUCT_COLLECTION: {
      return largeCarouselToField(block) as LargeCarouselType
    }
    case BlockAssetTypeEnum.PRODUCT_GROUP_COLLECTION: {
      return productGroupCarouselToField(block) as ProductGroupCarouselType
    }
    case BlockAssetTypeEnum.IMAGE_TEXT: {
      return imageTextToField(block) as ImageTextType
    }
    default: {
      return undefined
    }
  }
}

// Necessary since create and response level enums are the same but don't connect neatly.
const getLevel = (levelPayload: LevelPayloadEnum) => {
  switch (levelPayload) {
    case LevelPayloadEnum.Brand: {
      return LevelFieldEnum.Brand
    }
    case LevelPayloadEnum.Parent: {
      return LevelFieldEnum.Parent
    }
    case LevelPayloadEnum.SubBrand: {
      return LevelFieldEnum.SubBrand
    }
    default:
      return LevelFieldEnum.Brand
  }
}

const getLinkedBrands = (linkedBrands: LinkedBrandType[] | undefined) => {
  if (!linkedBrands) return []
  return linkedBrands.map(linkedBrand => {
    return { ...linkedBrand, entityId: Number(linkedBrand.entityId) }
  })
}

const formatBlocksForFields = (blocks: Array<PayloadType>): BlocksType => {
  if (!blocks) return []
  const blockAssets = blocks.map(block => omit(block, ['type']))
  const empty = blocks ? areNestedObjectsEmpty(blockAssets) : false
  if (empty) return []

  return compact(blocks.map(block => payloadToField(block)))
}

export const formatForFields = (response?: StandaloneBrandPageResponseData) => {
  if (!response) return undefined

  const emptyStrings = Array(
    Math.max(DEFAULT_HIERARCHY_ARRAY_LENGTH - response.attributes.pageHierarchy.length, 0)
  ).fill('')

  return {
    id: response.id,
    ...response.attributes,
    hierarchy: [...response.attributes.pageHierarchy, ...emptyStrings],
    duration:
      response.attributes.purpose === PurposeEnum.Seasonal
        ? DurationEnum.Seasonal
        : DurationEnum.Evergreen,
    blocks: formatBlocksForFields(response.attributes.blocks),
    level: getLevel(response.attributes.level),
    linkedBrands: getLinkedBrands(response.attributes.linkedBrands) as LinkedBrandPageFormData[],
  }
}
