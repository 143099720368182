// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouch } from './GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetAccountResponseDataAttributesAnalyticsAttributionDataLinear } from './GetAccountResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  GetAccountResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetAccountResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetAccountResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetAccountResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetAccountResponseDataAttributesAnalyticsAttributionData
 */
export interface GetAccountResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetAccountResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetAccountResponseDataAttributesAnalyticsAttributionData
   */
  linear: GetAccountResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetAccountResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetAccountResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetAccountResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetAccountResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetAccountResponseDataAttributesAnalyticsAttributionData {
  return GetAccountResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function GetAccountResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetAccountResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(json['linear']),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetAccountResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetAccountResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetAccountResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: GetAccountResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
