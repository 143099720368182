import { AnchorHTMLAttributes, ReactNode } from 'react'
import { Tooltip, TooltipReference, useTooltipState } from '../../tooltips'

export interface TooltipAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  tooltip: ReactNode
}

export function TooltipAnchor({ tooltip, children, ...props }: TooltipAnchorProps) {
  const state = useTooltipState()

  return (
    <>
      <TooltipReference as="a" {...state} {...props}>
        {children}
      </TooltipReference>
      <Tooltip {...state}>{tooltip}</Tooltip>
    </>
  )
}
