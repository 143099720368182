import { css } from '@emotion/react'
import { SearchIcon } from '@instacart/ids-core'
import { merge } from 'lodash'
import { forwardRef } from 'react'
import toPx from 'common/toPx'
import { IconInputBase, IconInputBaseProps } from './IconInputBase'

const iconSize = (compact?: boolean) => (compact ? 16 : 18)

export interface InputSearchProps extends Omit<IconInputBaseProps, 'component'> {
  inline?: boolean
}

const CLOSE_ICON_SVG_URL =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iY3VycmVudENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE5LjA2MDcgNC45MzkzNEMxOS42NDY0IDUuNTI1MTMgMTkuNjQ2NCA2LjQ3NDg3IDE5LjA2MDcgNy4wNjA2NkwxNC4xMjEgMTJMMTkuMDYwNyAxNi45MzkzQzE5LjYwOTggMTcuNDg4NSAxOS42NDQyIDE4LjM1NzYgMTkuMTYzNiAxOC45NDY3TDE5LjA2MDcgMTkuMDYwN0MxOC40NzQ5IDE5LjY0NjQgMTcuNTI1MSAxOS42NDY0IDE2LjkzOTMgMTkuMDYwN0wxMiAxNC4xMjFMNy4wNjA2NiAxOS4wNjA3QzYuNDc0ODcgMTkuNjQ2NCA1LjUyNTEzIDE5LjY0NjQgNC45MzkzNCAxOS4wNjA3QzQuMzUzNTUgMTguNDc0OSA0LjM1MzU1IDE3LjUyNTEgNC45MzkzNCAxNi45MzkzTDkuODc5IDEyTDQuOTM5MzQgNy4wNjA2NkM0LjM5MDE3IDYuNTExNDkgNC4zNTU4NCA1LjY0MjQyIDQuODM2MzcgNS4wNTMyNkw0LjkzOTM0IDQuOTM5MzRDNS41MjUxMyA0LjM1MzU1IDYuNDc0ODcgNC4zNTM1NSA3LjA2MDY2IDQuOTM5MzRMMTIgOS44NzlMMTYuOTM5MyA0LjkzOTM0QzE3LjUyNTEgNC4zNTM1NSAxOC40NzQ5IDQuMzUzNTUgMTkuMDYwNyA0LjkzOTM0WiIgLz4KPC9zdmc+Cg=='

function useStyles({ compact, inline }: Pick<InputSearchProps, 'compact' | 'inline'>) {
  const clearIconSize = iconSize(compact)
  const inputStyles: IconInputBaseProps['inputStyles'] = {
    input: {
      '&::-webkit-search-cancel-button': {
        appearance: 'none',
        height: clearIconSize,
        width: clearIconSize,
        backgroundImage: `url(${CLOSE_ICON_SVG_URL})`,
        backgroundSize: toPx`${clearIconSize}, ${clearIconSize}`,
        margin: 0,
        // Safari sets `align-self: flex-start` for this element by default so we override it here
        alignSelf: 'center',
      },
    },
  }

  return {
    container: css({
      position: 'relative',
      display: inline ? 'inline-block' : 'initial',
    }),
    input: inputStyles,
  }
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(function InputSearch(
  { id, value, compact, inline, inputStyles, ...rest },
  ref
) {
  const styles = useStyles({ compact, inline })

  return (
    <IconInputBase
      component={<SearchIcon />}
      iconSize={iconSize(compact)}
      id={id}
      ref={ref}
      type="search"
      inputStyles={merge(styles.input, inputStyles)}
      value={value}
      compact={compact}
      {...rest}
    />
  )
})
