import { spacing } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import LoginButton from 'components/Login/components/LoginButton'
import { useAdsLogoUrl } from 'landing/utils/helpers'

const useStyles = () => ({
  header: {
    display: 'flex',
    backgroundColor: '#faf1e5',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing.s16}px ${spacing.s24}px`,
  },
  logo: {
    height: '30px',
  },
})

interface UnauthenticatedHeaderProps {
  hideLoginButton?: boolean
  overrideBackgroundColor?: string
}

const UnauthenticatedHeader = ({
  hideLoginButton,
  overrideBackgroundColor,
}: UnauthenticatedHeaderProps) => {
  const styles = useStyles()
  const adsLogoUrl = useAdsLogoUrl()

  if (overrideBackgroundColor) {
    styles.header.backgroundColor = overrideBackgroundColor
  }

  return (
    <nav>
      <div style={styles.header}>
        <img src={adsLogoUrl} alt="Instacart logo" style={styles.logo} />{' '}
        {!hideLoginButton && (
          <div>
            {/* This will need to lead to the login page eventually. Deprecating the modal. */}
            <LoginButton />
          </div>
        )}
      </div>
    </nav>
  )
}

export default Radium(UnauthenticatedHeader)
