// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProducts } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProducts'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProductsFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProductsFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProductsToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProducts'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockText } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockText'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTextFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTextFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTextToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockText'
import type { GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitle } from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitle'
import {
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitleFromJSON,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitleFromJSONTyped,
  GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitleToJSON,
} from './GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitle'

/**
 *
 * @export
 * @interface ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock
 */
export interface ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock {
  /**
   * List of labels for block title
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitle>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock
   */
  title: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitle>
  /**
   * List of labels for block text
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockText>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock
   */
  text: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockText>
  /**
   * List of labels for block products
   * @type {Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProducts>}
   * @memberof ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock
   */
  products: Array<GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProducts>
}

/**
 * Check if a given object implements the ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock interface.
 */
export function instanceOfApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'text' in value
  isInstance = isInstance && 'products' in value

  return isInstance
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSON(
  json: any
): ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock {
  return ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSONTyped(
    json,
    false
  )
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: (json['title'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitleFromJSON
    ),
    text: (json['text'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTextFromJSON
    ),
    products: (json['products'] as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProductsFromJSON
    ),
  }
}

export function ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlockToJSON(
  value?: ApplicableLabelsAdminBrandPageResponseDataAttributesBrandPageBlock | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: (value.title as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTitleToJSON
    ),
    text: (value.text as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockTextToJSON
    ),
    products: (value.products as Array<any>).map(
      GetAdminBrandPagesIdApplicableLabelsParamDataAttributesBrandPageBlockProductsToJSON
    ),
  }
}
