import { css } from '@emotion/react'
import { Theme, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { PrimaryButton, SecondaryButton } from 'components/ids-ads'
import { CategoriesViewContext, TargetingSegmentContext } from '../hooks'
import { addNodeAndCompressIfApplicable, removeNodeAndDecompressIfApplicable } from '../utils'

const footerStyle = (theme: Theme) =>
  css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: `1px solid ${theme.colors.systemGrayscale20}`,
  })

export default function CategoriesViewFooter({ onDone }: { onDone: () => void }) {
  const theme = useTheme()
  const { fieldsPrefix } = useContext(TargetingSegmentContext)
  const { values, setFieldValue } = useFormikContext()
  const { searchText, categoryTree } = useContext(CategoriesViewContext)

  const categoriesFieldName = `${fieldsPrefix}categories`

  const addAisles = () => {
    const selectedCategories: string[] = get(values, categoriesFieldName, [])
    const updatedCategories = addNodeAndCompressIfApplicable(categoryTree.root, selectedCategories)
    setFieldValue(categoriesFieldName, updatedCategories)
  }

  const removeAisles = () => {
    const selectedCategories: string[] = get(values, categoriesFieldName, [])
    const updatedCategories = removeNodeAndDecompressIfApplicable(
      categoryTree.root,
      selectedCategories
    )
    setFieldValue(categoriesFieldName, updatedCategories)
  }

  return (
    <div css={footerStyle(theme)}>
      <div css={{ marginRight: 'auto' }}>
        <SecondaryButton
          type="button"
          onClick={addAisles}
          disabled={searchText === ''}
          style={{ minWidth: 60 }}
        >
          <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.categoriesView.aisles.select" />
        </SecondaryButton>
      </div>
      <SecondaryButton type="button" onClick={removeAisles} style={{ minWidth: 60 }}>
        <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.categoriesView.aisles.remove" />
      </SecondaryButton>
      <PrimaryButton type="button" onClick={onDone} style={{ minWidth: 60, marginLeft: 8 }}>
        <FormattedMessage id="common.done" />
      </PrimaryButton>
    </div>
  )
}
