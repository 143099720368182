import { captureException } from 'common/sentry'
import { BannerNotification, BannerType } from 'components/molecules/BannerNotifications'
import { useAuthContext, CapabilitiesContext } from 'context'
import { useCapabilities } from 'hooks/api/capabilities'

function InnerComponent({ children }: { children: JSX.Element }) {
  const { loadingCapabilities, capabilities, error } = useCapabilities()
  if (loadingCapabilities) {
    return null
  }
  if (error) {
    captureException('Error loading capabilities from auth/capabilities.', error)
    return (
      <BannerNotification
        type={BannerType.WARNING}
        message={{ message: 'Error loading capabilities from auth/capabilities' }}
      />
    )
  }
  return (
    <CapabilitiesContext.Provider value={capabilities}>{children}</CapabilitiesContext.Provider>
  )
}

export default function CapabilitiesProvider({ children }: { children: JSX.Element }) {
  const context = useAuthContext()
  if (!context.authed) return children
  return <InnerComponent>{children}</InnerComponent>
}
