// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiProductsControllerIndexProductIdsInput
 */
export interface ApiProductsControllerIndexProductIdsInput {
  /**
   * An array of product_ids you want to search for
   * @type {Array<string>}
   * @memberof ApiProductsControllerIndexProductIdsInput
   */
  productIds?: Array<string>
}

/**
 * Check if a given object implements the ApiProductsControllerIndexProductIdsInput interface.
 */
export function instanceOfApiProductsControllerIndexProductIdsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiProductsControllerIndexProductIdsInputFromJSON(
  json: any
): ApiProductsControllerIndexProductIdsInput {
  return ApiProductsControllerIndexProductIdsInputFromJSONTyped(json, false)
}

export function ApiProductsControllerIndexProductIdsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiProductsControllerIndexProductIdsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
  }
}

export function ApiProductsControllerIndexProductIdsInputToJSON(
  value?: ApiProductsControllerIndexProductIdsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_ids: value.productIds,
  }
}
