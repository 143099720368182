// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamAssetFieldDecisionsAltText } from './PutDisplayCampaignsIdParamAssetFieldDecisionsAltText'
import {
  PutDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSON,
  PutDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSONTyped,
  PutDisplayCampaignsIdParamAssetFieldDecisionsAltTextToJSON,
} from './PutDisplayCampaignsIdParamAssetFieldDecisionsAltText'
import type { PutDisplayCampaignsIdParamAssetFieldDecisionsImage } from './PutDisplayCampaignsIdParamAssetFieldDecisionsImage'
import {
  PutDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSON,
  PutDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSONTyped,
  PutDisplayCampaignsIdParamAssetFieldDecisionsImageToJSON,
} from './PutDisplayCampaignsIdParamAssetFieldDecisionsImage'
import type { PutDisplayCampaignsIdParamAssetFieldDecisionsVideo } from './PutDisplayCampaignsIdParamAssetFieldDecisionsVideo'
import {
  PutDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSON,
  PutDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSONTyped,
  PutDisplayCampaignsIdParamAssetFieldDecisionsVideoToJSON,
} from './PutDisplayCampaignsIdParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamAssetFieldDecisions
 */
export interface PutDisplayCampaignsIdParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutDisplayCampaignsIdParamAssetFieldDecisionsAltText}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisions
   */
  altText?: PutDisplayCampaignsIdParamAssetFieldDecisionsAltText
  /**
   *
   * @type {PutDisplayCampaignsIdParamAssetFieldDecisionsImage}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisions
   */
  image?: PutDisplayCampaignsIdParamAssetFieldDecisionsImage
  /**
   *
   * @type {PutDisplayCampaignsIdParamAssetFieldDecisionsVideo}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisions
   */
  video?: PutDisplayCampaignsIdParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamAssetFieldDecisions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamAssetFieldDecisions {
  return PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : PutDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : PutDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : PutDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: PutDisplayCampaignsIdParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: PutDisplayCampaignsIdParamAssetFieldDecisionsImageToJSON(value.image),
    video: PutDisplayCampaignsIdParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
