import { KnowledgeOwlWidget } from 'service/types'
import { KO_WINDOW_PROP, KO_WINDOW } from './knowledgeOwlWidget.constants'

export interface WindowKnowledgeOwl extends Window {
  [KO_WINDOW_PROP]: Array<Array<string>>
  [KO_WINDOW]: {
    openArticle: (href: string) => void
  }
}

class KnowledgeOwl {
  config: KnowledgeOwlWidget['attributes'] | null = null

  initialized = false

  searchCntr: HTMLDivElement | null = null

  searchSiblingEl: HTMLDivElement | null = null
}

const KoVars = new KnowledgeOwl()

export default KoVars
