// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabels } from './PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabels'
import {
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabelsFromJSON,
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabelsFromJSONTyped,
  PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabelsToJSON,
} from './PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabels'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle
 */
export interface PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle
   */
  decision?: PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabels>}
   * @memberof PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle
   */
  labels?: Array<PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabels>
}

/**
 * @export
 */
export const PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleDecisionEnum =
  typeof PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleDecisionEnum[keyof typeof PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleDecisionEnum]

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle {
  return PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleFromJSONTyped(json, false)
}

export function PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabelsFromJSON
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleToJSON(
  value?: PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitle | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PostVideoCampaignsIdSubmitParamBrandPageFieldDecisionsTitleLabelsToJSON
          ),
  }
}
