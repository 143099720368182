// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargeting } from './GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargeting'
import {
  GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargetingFromJSON,
  GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargetingFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargetingToJSON,
} from './GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargeting'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesTargetingStrategy
 */
export interface GetVideoAdGroupsParamDataAttributesTargetingStrategy {
  /**
   * Targeted keywords
   * @type {Array<string>}
   * @memberof GetVideoAdGroupsParamDataAttributesTargetingStrategy
   */
  keywords?: Array<string>
  /**
   * Specify how broadly you would like the keywords to be matched against search terms. Default is broad if not provided.
   * @type {string}
   * @memberof GetVideoAdGroupsParamDataAttributesTargetingStrategy
   */
  keywordMatchType?: GetVideoAdGroupsParamDataAttributesTargetingStrategyKeywordMatchTypeEnum
  /**
   * Targeting shortcut, need to pass both this field and user_targeting
   * @type {string}
   * @memberof GetVideoAdGroupsParamDataAttributesTargetingStrategy
   */
  targetingShortcut?: GetVideoAdGroupsParamDataAttributesTargetingStrategyTargetingShortcutEnum
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargeting}
   * @memberof GetVideoAdGroupsParamDataAttributesTargetingStrategy
   */
  userTargeting?: GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargeting
  /**
   * Targeting rule
   * @type {{ [key: string]: any; }}
   * @memberof GetVideoAdGroupsParamDataAttributesTargetingStrategy
   */
  targetingRule?: { [key: string]: any }
}

/**
 * @export
 */
export const GetVideoAdGroupsParamDataAttributesTargetingStrategyKeywordMatchTypeEnum = {
  Exact: 'exact',
  Narrow: 'narrow',
  Broad: 'broad',
} as const
export type GetVideoAdGroupsParamDataAttributesTargetingStrategyKeywordMatchTypeEnum =
  typeof GetVideoAdGroupsParamDataAttributesTargetingStrategyKeywordMatchTypeEnum[keyof typeof GetVideoAdGroupsParamDataAttributesTargetingStrategyKeywordMatchTypeEnum]

/**
 * @export
 */
export const GetVideoAdGroupsParamDataAttributesTargetingStrategyTargetingShortcutEnum = {
  Unknown: 'TARGETING_SHORTCUT_UNKNOWN',
  None: 'TARGETING_SHORTCUT_NONE',
  Custom: 'TARGETING_SHORTCUT_CUSTOM',
  DraftAdgroup: 'TARGETING_SHORTCUT_DRAFT_ADGROUP',
  NonAudienceTargeting: 'TARGETING_SHORTCUT_NON_AUDIENCE_TARGETING',
  NewToInstacart: 'TARGETING_SHORTCUT_NEW_TO_INSTACART',
  NewToCategoryOrBrand: 'TARGETING_SHORTCUT_NEW_TO_CATEGORY_OR_BRAND',
  BuyMyCategoryNotMyBrand: 'TARGETING_SHORTCUT_BUY_MY_CATEGORY_NOT_MY_BRAND',
  BuyMyBrandNotMyProduct: 'TARGETING_SHORTCUT_BUY_MY_BRAND_NOT_MY_PRODUCT',
  InteractWithXNotBuyBrand: 'TARGETING_SHORTCUT_INTERACT_WITH_X_NOT_BUY_BRAND',
  LapsedBuyer: 'TARGETING_SHORTCUT_LAPSED_BUYER',
  LoyalBuyer: 'TARGETING_SHORTCUT_LOYAL_BUYER',
} as const
export type GetVideoAdGroupsParamDataAttributesTargetingStrategyTargetingShortcutEnum =
  typeof GetVideoAdGroupsParamDataAttributesTargetingStrategyTargetingShortcutEnum[keyof typeof GetVideoAdGroupsParamDataAttributesTargetingStrategyTargetingShortcutEnum]

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesTargetingStrategy interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesTargetingStrategy(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesTargetingStrategyFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesTargetingStrategy {
  return GetVideoAdGroupsParamDataAttributesTargetingStrategyFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesTargetingStrategyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesTargetingStrategy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keywords: !exists(json, 'keywords') ? undefined : json['keywords'],
    keywordMatchType: !exists(json, 'keyword_match_type') ? undefined : json['keyword_match_type'],
    targetingShortcut: !exists(json, 'targeting_shortcut') ? undefined : json['targeting_shortcut'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargetingFromJSON(
          json['user_targeting']
        ),
    targetingRule: !exists(json, 'targeting_rule') ? undefined : json['targeting_rule'],
  }
}

export function GetVideoAdGroupsParamDataAttributesTargetingStrategyToJSON(
  value?: GetVideoAdGroupsParamDataAttributesTargetingStrategy | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keywords: value.keywords,
    keyword_match_type: value.keywordMatchType,
    targeting_shortcut: value.targetingShortcut,
    user_targeting: GetVideoAdGroupsParamDataAttributesTargetingStrategyUserTargetingToJSON(
      value.userTargeting
    ),
    targeting_rule: value.targetingRule,
  }
}
