// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutDisplayAdGroupsIdEnableParamAssetFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamAssetFieldDecisions,
  PutDisplayAdGroupsIdEnableParamAssetFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamAssetFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamAssetFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamAssetFieldDecisions'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions'
import {
  PutDisplayAdGroupsIdEnableParamCreativeFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamCreativeFieldDecisions,
  PutDisplayAdGroupsIdEnableParamCreativeFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamCreativeFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamCreativeFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamCreativeFieldDecisions'
import {
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisions,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisions'
import {
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions,
  instanceOfPutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSON,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsToJSON,
} from './PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions'

/**
 * @type PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & PutDisplayAdGroupsIdEnableParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & PutDisplayAdGroupsIdEnableParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisions)

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutDisplayAdGroupsIdEnableParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutDisplayAdGroupsIdEnableParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutDisplayAdGroupsIdEnableParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutDisplayAdGroupsIdEnableParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PutDisplayAdGroupsIdEnableParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutDisplayAdGroupsIdEnableParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PutDisplayAdGroupsIdEnableParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PutDisplayAdGroupsIdEnableParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
