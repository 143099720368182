// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop } from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import {
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSONTyped,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON,
} from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop'
import type { PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile } from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile'
import {
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSONTyped,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON,
} from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile'

/**
 *
 * @export
 * @interface PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
 */
export interface PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  /**
   *
   * @type {PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
   */
  desktop?: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktop
  /**
   *
   * @type {PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
   */
  mobile?: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobile
}

/**
 * Check if a given object implements the PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues interface.
 */
export function instanceOfPostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON(
  json: any
): PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  return PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
    json,
    false
  )
}

export function PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: !exists(json, 'desktop')
      ? undefined
      : PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopFromJSON(
          json['desktop']
        ),
    mobile: !exists(json, 'mobile')
      ? undefined
      : PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileFromJSON(
          json['mobile']
        ),
  }
}

export function PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON(
  value?: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesDesktopToJSON(
      value.desktop
    ),
    mobile: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesMobileToJSON(
      value.mobile
    ),
  }
}
