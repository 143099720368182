import {
  Base,
  Theme,
  useTheme,
  combineStyles,
  InformationIcon,
  useAriaLabel,
} from '@instacart/ids-core'
import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { HovercardAnchor, HovercardState } from './tooltips'

const DEFAULT_ICON_SIZE = 18
const TRANSITION_DURATION_MS = 200

const getStyles = ({ theme, iconSize }: { theme: Theme; iconSize: number }) =>
  ({
    disclosure: {
      border: 'none',
      padding: 0,
      margin: 0,
      lineHeight: 0,
      cursor: 'pointer',
      touchAction: 'manipulation',
      position: 'relative',
      backgroundColor: 'transparent',
    },
    focusRing: {
      top: -1,
      left: -1,
      width: iconSize - 2,
      height: iconSize - 2,
      border: `2px solid ${theme.colors.systemGrayscale70}`,
      borderRadius: '50%',
      content: '""',
      display: 'block',
      position: 'absolute',
    },
    icon: {
      fill: theme.colors.systemGrayscale50,
      transition: `all ${TRANSITION_DURATION_MS}ms ease-in-out`,
    },
    iconHover: {
      fill: theme.colors.systemGrayscale70,
    },
    hover: {},
    active: {},
    disabled: {
      cursor: 'default',
    },
  } as const)

export interface InfoInputIconProps extends ComponentPropsWithoutRef<'a'>, Base<typeof getStyles> {
  hovercard: HovercardState
  description: string
  iconSize?: number
}

export const InfoInputIcon = forwardRef<HTMLAnchorElement, InfoInputIconProps>(
  function InfoInputIcon(
    { styles, description, hovercard, iconSize = DEFAULT_ICON_SIZE, ...rest },
    ref
  ) {
    const theme = useTheme()

    const { icon, iconHover, disclosure, focusRing, active, disabled, hover } = combineStyles(
      getStyles({ theme, iconSize }),
      styles
    )

    const anchorCss = [
      disclosure,
      {
        '&:focus': {
          outline: 'none',
        },
        '&.focus-visible::after': focusRing,
        '&:hover': hover,
        '&:hover svg': iconHover,
        '&:active:not(:disabled)': active,
        '&:disabled': disabled,
      },
    ]

    const svgCss = [icon, { '&:hover': iconHover }]

    const ariaLabel = useAriaLabel({ description })

    return (
      <HovercardAnchor {...ariaLabel} ref={ref} css={anchorCss} state={hovercard} {...rest}>
        <InformationIcon size={iconSize} css={svgCss} data-testid="input-info-icon" />
      </HovercardAnchor>
    )
  }
)

InfoInputIcon.displayName = 'InfoInputIcon'
