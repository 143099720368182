import { css } from '@emotion/react'
import { Text, useTheme } from '@instacart/ids-core'
import TableBody from 'components/deprecated/TableBody'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'

interface NoActionNeededMessageProps {
  id: MessageIdType
}

const useStyles = () => {
  const theme = useTheme()
  return {
    noActionsNeeded: css`
      background: ${theme.colors.systemGrayscale10};
      text-align: center;
      padding: 10px;
    `,
  }
}

const NoActionNeededMessage = ({ id }: NoActionNeededMessageProps) => {
  const styles = useStyles()
  return (
    <TableBody>
      <div css={styles.noActionsNeeded}>
        <Text typography="bodySmall2">
          <FormattedMessage id={id} />
        </Text>
      </div>
    </TableBody>
  )
}

export default NoActionNeededMessage
