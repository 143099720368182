import { css } from '@emotion/react'
import { CSSInterpolation } from '@emotion/serialize'
import { useTheme } from '@instacart/ids-core'
import { merge } from 'lodash'
import { HTMLProps } from 'react'

export interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
  styles: CSSInterpolation | (CSSInterpolation | undefined)[]
}

function useStyles() {
  const theme = useTheme()

  return {
    content: css({
      ...theme.typography.bodySmall1,
      a: {
        color: 'inherit',
        textDecoration: 'underline',
        '&:visited': {
          color: 'inherit',
        },
        '&:hover, &:hover:visited, &:active': {
          color: theme.colors.systemGrayscale20,
        },
      },
    }),
  }
}

export function TooltipContent({ styles, ...props }: TooltipContentProps) {
  const baseStyles = useStyles()
  const mergedStyles = merge(baseStyles.content, styles)

  return <div css={mergedStyles} {...props} />
}
