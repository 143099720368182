import { AxiosError } from 'axios'
import { canUseDOM } from 'exenv'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  getCurrentAccountId,
  setCurrentAccountId,
  clearCurrentAccountId,
} from 'common/currentAccountHelper'
import { useAccountPathId } from 'common/route'
import { setUser } from 'common/sentry'
import { pick } from 'common/utils'
import { AuthCtx, initialAuthContext } from 'context'
import { NotificationType, useNotifier } from 'context/notifications'
import { AuthClient, AuthResponseWithCapabilities } from 'service/auth'
import { setAnalyticsParams } from './setAnalyticsParams'

function updateAuthContextFromResponse(
  context: AuthCtx,
  authResponse: AuthResponseWithCapabilities
) {
  const user = authResponse.data.attributes

  return {
    ...context,
    csrf: authResponse.meta.csrfToken || null,
    authed: true,
    checked: true,
    user: {
      ...pick(
        user,
        'currentAccount',
        'email',
        'firstName',
        'lastName',
        'userAccounts',
        'singleSignOnEnabled'
      ),
      type: user.userType,
      flippers: user.flippers || [],
      mfaFactors: user.mfaFactors || [],
      capabilities: user.capabilities || {},
      activeVariants: user.activeVariants || {},
      featureHints: user.featureHints || [],
      featureReminders: user.featureReminders || [],
    },
  }
}

export default function useAuthLoader() {
  const { sendNotification } = useNotifier()
  const [authContext, setAuthContext] = useState<AuthCtx>(initialAuthContext)
  const loading = useRef(false)
  const urlAccountId = useAccountPathId()

  const checkAuth = useCallback(
    async (isRetry?: boolean) => {
      // Do not run if SSR or already loading
      if (!canUseDOM || loading.current) return

      const originalAccountId = getCurrentAccountId()
      if (urlAccountId && !isRetry) {
        setCurrentAccountId(urlAccountId)
      }

      try {
        loading.current = true

        const authResponse = await AuthClient.getUserInfo()
        setAnalyticsParams(authResponse)
        setAuthContext(current => updateAuthContextFromResponse(current, authResponse))

        if (authResponse.data.attributes.accountIdChanged) {
          const { name } = authResponse.data.attributes.currentAccount
          sendNotification({
            messageId: 'auth.accountChangedMessage',
            messageValues: { newAccountName: name },
            type: NotificationType.INFO,
          })
        }

        // Add extra context to Sentry errors
        setUser({ id: authResponse.data.attributes.userId.toString() })
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = err as AxiosError<any, any>
        if ([401, 403, 404].includes(error?.response?.status as number)) {
          // attempts to login to invalid/unauthorized deeplink should restore the original selected account
          if (originalAccountId) {
            setCurrentAccountId(originalAccountId)
          }

          // User does not have access to this account
          // Reload their auth without the current account id
          if (error?.response?.status === 403 && !isRetry) {
            clearCurrentAccountId()
            loading.current = false
            checkAuth(true)
          } else {
            // User is logged out. Update context accordingly.
            setAuthContext(current => ({
              ...current,
              authed: false,
              checked: true,
              user: null,
              forbidden: error?.response?.status === 403,
            }))
          }
        } else {
          // Some other error occurred
          console.error(error)
        }
      } finally {
        loading.current = false
      }
    },
    [loading, sendNotification, urlAccountId]
  )

  useEffect(() => {
    // Do not hit API if already checked
    if (authContext.checked) return
    checkAuth()
  }, [authContext.checked, checkAuth])

  return {
    ...authContext,
    refresh: checkAuth,
  }
}
