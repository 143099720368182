import { createContext, useContext } from 'react'
import { Capabilities } from 'service/types/capabilities'
import {
  DELIMITER,
  Actions,
  ALL_RESOURCES,
  NAMESPACE_DELIMITER,
  Namespaces,
} from '../common/rolePermissions'

export const CapabilitiesContext = createContext<Capabilities>({
  capabilities: {},
  featureCapabilities: {},
})

export const useCapabilitiesContext = () => useContext(CapabilitiesContext)

/**
 * Check if the user has the required capability, the 'manage' action for this resource, the required action for all resources, or the 'manage' action for all resources.
 * If {resource}.{action}, {resource}.manage, *.{action} or *.manage exist in the capability context then return true, otherwise return false.
 * Capability format: "{resource}.{action}""
 * eg. "account.create"
 *
 * @param capabilitiesContext
 * @param capability
 */
export function hasCapability(capabilitiesContext: Capabilities, capability: string) {
  if (capability.indexOf(DELIMITER) < 1 || !capabilitiesContext) return false

  const [resource, action] = capability.split(DELIMITER)

  const [namespace, resourceWithoutNamespace] = resource.split(NAMESPACE_DELIMITER)

  let capabilities = []
  if (namespace === Namespaces.ADMIN) {
    const adminNamespace = `${Namespaces.ADMIN}${NAMESPACE_DELIMITER}`
    capabilities = [
      `${adminNamespace}${resourceWithoutNamespace}.${Actions.MANAGE}`,
      `${adminNamespace}${ALL_RESOURCES}.${action}`,
      `${adminNamespace}${ALL_RESOURCES}.${Actions.MANAGE}`,
      capability,
    ]
  } else {
    capabilities = [
      `${ALL_RESOURCES}.${Actions.MANAGE}`,
      `${resource}.${Actions.MANAGE}`,
      `${ALL_RESOURCES}.${action}`,
      capability,
    ]
  }

  // Only one of the many capabilities we check for is required to be true, so we can short-circuit once we see our first true.
  return capabilities.some(
    capabilityCandidate => !!capabilitiesContext.capabilities[capabilityCandidate]
  )
}

/**
 * Similar to `hasCapability`, but in this case, checks if the user has any of the provided capabilities.
 *
 * @param capabilitiesContext
 * @param capabilities
 */
export function hasAnyCapability(capabilitiesContext: Capabilities, capabilities: string[]) {
  return capabilities.some(capability => hasCapability(capabilitiesContext, capability))
}

export function hasAnyFeatureCapability(
  capabilitiesContext: Capabilities,
  featureCapabilities: string[]
) {
  return featureCapabilities.some(capability =>
    hasFeatureCapability(capabilitiesContext, capability)
  )
}

export function hasFeatureCapability(capabilitiesContext: Capabilities, featureCapability: string) {
  if (!capabilitiesContext) return false

  return !!capabilitiesContext.featureCapabilities[featureCapability]
}
