import { css } from '@emotion/react'
import { spacing, Text, useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { FC, CSSProperties, ReactNode } from 'react'
import { getBreakpoint as mq, Breakpoint } from 'common/breakpoints'
import toPx from 'common/toPx'
import FormNotification from './Forms/FormNotification'

const useStyles = () => {
  const theme = useTheme()
  return {
    inputGroup: css({
      padding: toPx`${spacing.s16} 0`,
      '&.full-width': {
        width: '100%',
      },
    }),
    inputRow: css({
      display: 'flex',
      padding: toPx`0 ${spacing.s32} ${spacing.s24} ${spacing.s32}`,
      '&.has-error': {
        flexWrap: 'wrap',
        paddingBottom: 0,
      },
      [mq(Breakpoint.HALF_MOBILE)]: {
        flexWrap: 'wrap',
      },
    }),
    inputRowError: css({
      display: 'block',
      padding: toPx`${spacing.s8} 0`,
    }),
    inputRowErrorText: { color: theme.colors.systemDetrimentalExtraDark },
    inputRowTitle: css({
      padding: toPx`0 ${spacing.s32}`,
      marginBottom: 28,
    }),
    inputRowSubtitle: css({
      height: '1rem',
      marginBottom: spacing.s16,
    }),
    inputRowSubtitleText: { color: theme.colors.systemGrayscale30 },
    actionButtonWrapper: css({
      paddingLeft: spacing.s32,

      [mq(Breakpoint.HALF_MOBILE)]: {
        paddingLeft: 0,
        paddingTop: 6,
      },
    }),
    formErrorsWrapper: css({
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }),
    formError: css({
      padding: spacing.s8,
      borderLeft: `4px solid ${theme.colors.systemDetrimentalRegular}`,
      backgroundColor: '#fde6eb',
      color: theme.colors.systemDetrimentalRegular,
    }),
    loginFormError: css({
      borderRadius: 8,
      backgroundColor: theme.colors.systemDetrimentalLight,
      color: theme.colors.systemGrayscale70,
      padding: spacing.s8,
    }),
    inputHelperText: css({
      width: 400,
      height: '100%',
      alignSelf: 'center',
      padding: spacing.s8,

      [mq(Breakpoint.HALF_MOBILE)]: {
        padding: toPx`${spacing.s16} 0`,
      },
    }),
  }
}

interface InputGroupProps {
  children: ReactNode
  fullWidth?: boolean
}

export const InputGroup: FC<React.PropsWithChildren<InputGroupProps>> = ({
  children,
  fullWidth = false,
}) => {
  const styles = useStyles()
  return (
    <div css={styles.inputGroup} className={fullWidth ? 'full-width' : ''}>
      {children}
    </div>
  )
}

interface InputRowProps {
  children: ReactNode
  error?: string | false
  style?: CSSProperties
  className?: string
  testId?: string
}

const InputRowError: FC<React.PropsWithChildren<InputRowProps>> = ({ children }) => {
  const styles = useStyles()
  return (
    <div css={styles.inputRowError}>
      <Text typography="bodySmall2" style={styles.inputRowErrorText}>
        {children}
      </Text>
    </div>
  )
}

export const InputRow: FC<React.PropsWithChildren<InputRowProps>> = ({
  children,
  error,
  style,
  testId,
  className,
}) => {
  const styles = useStyles()
  return (
    <div
      css={styles.inputRow}
      data-testid={testId}
      className={classNames(error && '.has-error', className)}
      style={style}
    >
      {children}
      {error && <InputRowError>{error}</InputRowError>}
    </div>
  )
}

interface InputRowTitleProps {
  children: ReactNode
  style?: CSSProperties
}

export const InputRowTitle: FC<React.PropsWithChildren<InputRowTitleProps>> = props => {
  const styles = useStyles()
  return (
    <div css={styles.inputRowTitle} style={props?.style}>
      {props.children}
    </div>
  )
}

export const InputRowSubtitle: FC<React.PropsWithChildren<unknown>> = props => {
  const styles = useStyles()
  return (
    <div css={styles.inputRowSubtitle}>
      <Text typography="bodySmall2" style={styles.inputRowSubtitleText}>
        {props.children}
      </Text>
    </div>
  )
}

export const InputHelperText: FC<React.PropsWithChildren<unknown>> = props => {
  const styles = useStyles()
  return (
    <div css={styles.inputHelperText}>
      <FormNotification status="gray">{props.children}</FormNotification>
    </div>
  )
}

export const FormError: FC<React.PropsWithChildren<unknown>> = props => {
  const styles = useStyles()
  return (
    <div css={styles.formError} className="form-error">
      {props.children}
    </div>
  )
}

export const FormErrorsWrapper: FC<React.PropsWithChildren<unknown>> = props => {
  const styles = useStyles()
  return (
    <div css={styles.formErrorsWrapper} className="form-errors-wrapper">
      {props.children}
    </div>
  )
}
