// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetBrandPagesParamDataAttributesLinkedBrands
 */
export interface GetBrandPagesParamDataAttributesLinkedBrands {
  /**
   * The id of the entity.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesLinkedBrands
   */
  entityId: string
  /**
   * The name of the level 1 on the entity.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesLinkedBrands
   */
  reportingLevel1: string
  /**
   * The name of the level 2 on the entity.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesLinkedBrands
   */
  reportingLevel2: string
  /**
   * The name of the brand on the entity.
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesLinkedBrands
   */
  reportingBrand: string
}

/**
 * Check if a given object implements the GetBrandPagesParamDataAttributesLinkedBrands interface.
 */
export function instanceOfGetBrandPagesParamDataAttributesLinkedBrands(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'entityId' in value
  isInstance = isInstance && 'reportingLevel1' in value
  isInstance = isInstance && 'reportingLevel2' in value
  isInstance = isInstance && 'reportingBrand' in value

  return isInstance
}

export function GetBrandPagesParamDataAttributesLinkedBrandsFromJSON(
  json: any
): GetBrandPagesParamDataAttributesLinkedBrands {
  return GetBrandPagesParamDataAttributesLinkedBrandsFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesLinkedBrandsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesLinkedBrands {
  if (json === undefined || json === null) {
    return json
  }
  return {
    entityId: json['entity_id'],
    reportingLevel1: json['reporting_level_1'],
    reportingLevel2: json['reporting_level_2'],
    reportingBrand: json['reporting_brand'],
  }
}

export function GetBrandPagesParamDataAttributesLinkedBrandsToJSON(
  value?: GetBrandPagesParamDataAttributesLinkedBrands | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    entity_id: value.entityId,
    reporting_level_1: value.reportingLevel1,
    reporting_level_2: value.reportingLevel2,
    reporting_brand: value.reportingBrand,
  }
}
