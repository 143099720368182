// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabels } from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabels'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabelsFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabelsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabelsToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabels'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle {
  /**
   * List of labels applied
   * @type {Array<PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabels>}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle
   */
  labels: Array<PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabels>
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle
   */
  componentType: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleComponentTypeEnum = {
  BlockTitle: 'BLOCK_TITLE',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labels' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle {
  return PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labels: (json['labels'] as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabelsFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitle | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    labels: (value.labels as Array<any>).map(
      PostAdminBrandPagesIdLabelParamBrandPageBlockContentTitleLabelsToJSON
    ),
    component_type: value.componentType,
  }
}
