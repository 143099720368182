import { InputText, InputTextProps } from 'components/ids-ads'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'
import { hintId } from './utils'

export type TextInputFieldProps = InputFieldProps<InputTextProps>

export function TextInputField({
  id,
  onChange,
  error,
  invalid,
  hideLabel,
  ...rest
}: TextInputFieldProps) {
  return (
    <InputFieldContainer id={id} error={error} hideLabel={hideLabel} {...rest}>
      <InputText
        id={id}
        onChange={onChange}
        invalid={invalid || !!error}
        instructionInputTextId={rest.hint && hintId(id)}
        {...rest}
      />
    </InputFieldContainer>
  )
}
