import { CheckboxBaseProps } from '@instacart/ids-core'
import { sortBy, sumBy } from 'lodash'
import { Category } from './types'

/*
Facts & Assumptions:
  - We have 3 levels of nested categories. This can increase to 6 in the future.
  - Number of categories are fairly large (low thousands).
  - The user will select only a very limited number of categories per segment.

For fast rendering, we need an efficient way to:
  - detect whether a category is selected on not. More specifically:
    - Either the category or one of its parents are selected.
    - We'll need to do up to 3 lookups for this.
  - detect the number of categories/subcategories selected.
  - Add a category.
  - Remove a category.
  - Potentially compress/decompress the form data when categories are added/removed (should be a rare operation UX-wise).

Data structure:
  - A tree of categories with each node storing:
    - An identifier that gets stored in formik data and passed to the backend.
    - A display name.
    - Array of children.
    - The total number of nested children (i.e leafs).
    - A reference to the parent (i.e. bi-directional).
  - A dictionary storing the mapping between the ids and the nodes.
 */

type NodeByIdType = {
  [key: string]: CategoryTreeNode
}

export type CategoryTree = {
  root: CategoryTreeNode
  nodeById: NodeByIdType
}

export class CategoryTreeNode {
  id: string

  displayName: string

  children: CategoryTreeNode[]

  // Is the node visible based on the search text
  isVisible: boolean

  parent?: CategoryTreeNode

  constructor(id: string, displayName: string, parent?: CategoryTreeNode) {
    this.id = id
    this.displayName = displayName
    this.parent = parent
    this.isVisible = true // Assume all nodes are visible on initial tree construction

    // assuming it's a leaf by default.
    this.children = []
  }
}

export function constructCategoryTree(categories: Category[], searchText = ''): CategoryTree {
  const nodeById = {}
  const root = constructCategoryTreeNode(
    {
      categoryName: '',
      subcategories: categories,
    },
    nodeById,
    searchText
  )

  return { root, nodeById }
}

// eslint-disable-next-line max-params
function constructCategoryTreeNode(
  category: Category,
  nodeById: NodeByIdType,
  searchText: string,
  parent?: CategoryTreeNode
): CategoryTreeNode {
  const node = new CategoryTreeNode(
    parent?.id ? `${parent.id}.${category.categoryName}` : category.categoryName,
    category.categoryName,
    parent
  )

  // eslint-disable-next-line no-param-reassign
  nodeById[node.id] = node

  if (category.subcategories?.length) {
    node.children = sortBy(
      category.subcategories
        .filter(subcategory => subcategory.categoryName)
        .map(subcategory => constructCategoryTreeNode(subcategory, nodeById, searchText, node)),
      child => child.displayName
    )
  }

  // Base visibility on if any leaf nodes under the category are visible
  node.isVisible = node.children.length
    ? node.children.some(child => child.isVisible)
    : doesNodeMatchSearchText(node, searchText)

  return node
}

function doesNodeMatchSearchText(node: CategoryTreeNode, searchText: string): boolean {
  return searchText === '' || node.id.toLowerCase().search(searchText.toLowerCase()) !== -1
}

function flattenAllIdsUnderNode(node: CategoryTreeNode): string[] {
  if (!node.children.length) {
    return [node.id]
  }

  let flattenedIds: string[] = [node.id]
  node.children.forEach(childNode => {
    flattenedIds = flattenedIds.concat(flattenAllIdsUnderNode(childNode))
  })

  return flattenedIds
}

function getSelectedIdsFromOutsideSubtree(node: CategoryTreeNode, selectedIds: string[]): string[] {
  const allIdsInSubtree = flattenAllIdsUnderNode(node)
  return selectedIds.filter(selectedId => !allIdsInSubtree.includes(selectedId))
}

function isEveryChildSelected(node: CategoryTreeNode, selectedIds: string[]): boolean {
  return node.children.every(childNode => selectedIds.includes(childNode.id))
}

function upwardCompressIfApplicable(
  node: CategoryTreeNode | undefined,
  selectedIds: string[]
): string[] {
  // If every child is selected, compress and try to upward compress
  if (node && isEveryChildSelected(node, selectedIds)) {
    const childIds = node.children.map(childNode => childNode.id)
    const newSelectedIds = selectedIds
      .filter(selectedId => !childIds.includes(selectedId))
      .concat([node.id])

    return upwardCompressIfApplicable(node.parent, newSelectedIds)
  }

  return selectedIds
}

function getNewSelectedIdsFromAddingNode(node: CategoryTreeNode, selectedIds: string[]): string[] {
  // If this node is already selected, keep it selected, we can stop searching this branch
  if (selectedIds.includes(node.id)) {
    return [node.id]
  }

  // If this node has no children, it is selected if it is visible
  if (!node.children.length) {
    return node.isVisible ? [node.id] : []
  }

  // If this node has children, search them and gather their selected nodes
  let selectedChildIds: string[] = []
  node.children.forEach(childNode => {
    selectedChildIds = selectedChildIds.concat(
      getNewSelectedIdsFromAddingNode(childNode, selectedIds)
    )
  })

  // If ever child node is selected, compress into the node itself is selected
  if (isEveryChildSelected(node, selectedChildIds)) {
    return [node.id]
  }

  // Return the selected children ids as the new selected ids subtree
  return selectedChildIds
}

export function addNodeAndCompressIfApplicable(
  addedNode: CategoryTreeNode,
  selectedIds: string[]
): string[] {
  const selectedIdsOutsideSubtree = getSelectedIdsFromOutsideSubtree(addedNode, selectedIds)
  const newSelectedIdsInsideSubtree = getNewSelectedIdsFromAddingNode(addedNode, selectedIds)
  const updatedIds = selectedIdsOutsideSubtree.concat(newSelectedIdsInsideSubtree)

  return upwardCompressIfApplicable(addedNode.parent, updatedIds)
}

function getNewSelectedIdsFromRemovingNode(
  node: CategoryTreeNode,
  originalSelectedIds: string[]
): string[] {
  // Base case, when the node has no children we can determine if it should be selected
  if (!node.children.length) {
    // If it was visible when the operation kicked off, it should be removed from the selected ids
    return node.isVisible
      ? []
      : // If it was not visible, we want to include it if it was originally selected, and not include it othersie
      isLeafNodeSelected(node, originalSelectedIds)
      ? [node.id]
      : []
  }

  // If this node has children, search them and gather their selected nodes
  let selectedChildIds: string[] = []
  node.children.forEach(childNode => {
    selectedChildIds = selectedChildIds.concat(
      getNewSelectedIdsFromRemovingNode(childNode, originalSelectedIds)
    )
  })

  // If exactly every child is selected, compress into the node itself is selected
  if (node.children.every(childNode => selectedChildIds.includes(childNode.id))) {
    return [node.id]
  }

  // Otherwise, return the selected children ids as the new selected ids subtree
  return selectedChildIds
}

// This function adds all siblings other than the node itself to selected ids
function addSiblingsToSelectedIds(node: CategoryTreeNode, selectedIds: string[]): string[] {
  const siblingIds =
    node.parent?.children.map(childNode => childNode.id).filter(childId => childId !== node.id) ||
    []
  const newSelectedIds = selectedIds.concat(siblingIds)
  return newSelectedIds
}

function upwardDecompressIfApplicable(
  removedNode: CategoryTreeNode | undefined,
  selectedIds: string[]
): string[] {
  if (!removedNode) {
    return selectedIds
  }

  // Once we find the node that is in selected ids, we remove it and are done back tracking
  if (selectedIds.includes(removedNode.id)) {
    return selectedIds.filter(selectedId => selectedId !== removedNode.id)
  }

  // Since we need to take another step back, add all of the removed nodes siblings to the selected ids
  const newSelectedIds = addSiblingsToSelectedIds(removedNode, selectedIds)
  return upwardDecompressIfApplicable(removedNode.parent, newSelectedIds)
}

export function removeNodeAndDecompressIfApplicable(
  removedNode: CategoryTreeNode,
  selectedIds: string[]
): string[] {
  const selectedIdsOutsideSubtree = getSelectedIdsFromOutsideSubtree(removedNode, selectedIds)

  const newSelectedIdsInsideSubtree = getNewSelectedIdsFromRemovingNode(removedNode, selectedIds)

  const updatedIds = selectedIdsOutsideSubtree.concat(newSelectedIdsInsideSubtree)

  // If the removed node is now considered unchecked, we dont need to backtrack
  if (getCheckboxState(removedNode, updatedIds) === false) {
    return updatedIds
  }

  return upwardDecompressIfApplicable(removedNode, updatedIds)
}

export function getNewSelectedCategoriesOnCheckboxClick(
  previousCheckboxState: CheckboxBaseProps['checked'],
  node: CategoryTreeNode,
  selectedIds: string[]
): string[] {
  return previousCheckboxState === false
    ? addNodeAndCompressIfApplicable(node, selectedIds)
    : removeNodeAndDecompressIfApplicable(node, selectedIds)
}

export function getCheckboxState(
  node: CategoryTreeNode,
  selectedIds: string[]
): CheckboxBaseProps['checked'] {
  return isEveryVisibleLeafSelected(node, selectedIds)
    ? true
    : isSomeVisibleLeafSelected(node, selectedIds)
    ? 'indeterminate'
    : false
}

function isLeafNodeSelected(node: CategoryTreeNode, selectedIds: string[]): boolean {
  if (!node.parent) {
    return false
  }

  if (selectedIds.includes(node.id)) {
    return true
  }

  return isLeafNodeSelected(node.parent, selectedIds)
}

function isEveryVisibleLeafSelected(node: CategoryTreeNode, selectedIds: string[]): boolean {
  // If the node is not visible, we can ignore exploring that branch.
  if (!node.isVisible) {
    return true
  }
  if (!node.children.length) {
    return isLeafNodeSelected(node, selectedIds)
  }

  return node.children.every(childNode => isEveryVisibleLeafSelected(childNode, selectedIds))
}

function isSomeVisibleLeafSelected(node: CategoryTreeNode, selectedIds: string[]): boolean {
  // If the node is not visible, we can ignore exploring that branch.
  if (!node.isVisible) {
    return false
  }
  if (!node.children.length) {
    return isLeafNodeSelected(node, selectedIds)
  }

  return node.children.some(childNode => isSomeVisibleLeafSelected(childNode, selectedIds))
}

export function countVisibleLeafsSelected(node: CategoryTreeNode, selectedIds: string[]): number {
  // If the node is not visible, we can ignore exploring that branch.
  if (!node.isVisible) {
    return 0
  }
  if (!node.children.length) {
    return isLeafNodeSelected(node, selectedIds) ? 1 : 0
  }

  return sumBy(node.children, childNode => countVisibleLeafsSelected(childNode, selectedIds))
}
