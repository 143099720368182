// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesAnalyticsAttributionData } from './GetVideoAdGroupsParamDataAttributesAnalyticsAttributionData'
import {
  GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSON,
  GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataToJSON,
} from './GetVideoAdGroupsParamDataAttributesAnalyticsAttributionData'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesAnalytics
 */
export interface GetVideoAdGroupsParamDataAttributesAnalytics {
  /**
   * Spend
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  spend?: number
  /**
   * Number of Impressions
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  impressions?: number
  /**
   *
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  clicks?: number
  /**
   * Average Clickthrough Rate
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  avgCtr?: number
  /**
   * Average Cost Per Mille
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  avgCpm?: number
  /**
   * New to Brand Direct Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  ntbDirectSales?: number
  /**
   * New to Brand Halo Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  ntbHaloSales?: number
  /**
   * Percentage of Direct Sales that is New to Brand
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  percentNtbDirectSales?: number
  /**
   * Percentage of Halo Sales that is New to Brand
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  percentNtbHaloSales?: number
  /**
   * Direct Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  directSales?: number
  /**
   * Halo Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  haloSales?: number
  /**
   * Return on Ad Spend
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  roas?: number
  /**
   * Halo Return on Ad Spend
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  haloRoas?: number
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesAnalyticsAttributionData}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  attributionData?: GetVideoAdGroupsParamDataAttributesAnalyticsAttributionData
  /**
   * Clickthrough Rate
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  ctr?: number
  /**
   * Number of Views
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  pageViews?: number
  /**
   *
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  itemDetailPageViews?: number
  /**
   * Amount of Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  sales?: number
  /**
   * Number of Ad to Carts
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  addToCarts?: number
  /**
   * Metrics we want to obscure in the FE
   * @type {Array<string>}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalytics
   */
  metricsToObscure?: Array<string>
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesAnalytics interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesAnalytics(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesAnalytics {
  return GetVideoAdGroupsParamDataAttributesAnalyticsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesAnalytics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    spend: !exists(json, 'spend') ? undefined : json['spend'],
    impressions: !exists(json, 'impressions') ? undefined : json['impressions'],
    clicks: !exists(json, 'clicks') ? undefined : json['clicks'],
    avgCtr: !exists(json, 'avg_ctr') ? undefined : json['avg_ctr'],
    avgCpm: !exists(json, 'avg_cpm') ? undefined : json['avg_cpm'],
    ntbDirectSales: !exists(json, 'ntb_direct_sales') ? undefined : json['ntb_direct_sales'],
    ntbHaloSales: !exists(json, 'ntb_halo_sales') ? undefined : json['ntb_halo_sales'],
    percentNtbDirectSales: !exists(json, 'percent_ntb_direct_sales')
      ? undefined
      : json['percent_ntb_direct_sales'],
    percentNtbHaloSales: !exists(json, 'percent_ntb_halo_sales')
      ? undefined
      : json['percent_ntb_halo_sales'],
    directSales: !exists(json, 'direct_sales') ? undefined : json['direct_sales'],
    haloSales: !exists(json, 'halo_sales') ? undefined : json['halo_sales'],
    roas: !exists(json, 'roas') ? undefined : json['roas'],
    haloRoas: !exists(json, 'halo_roas') ? undefined : json['halo_roas'],
    attributionData: !exists(json, 'attribution_data')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSON(
          json['attribution_data']
        ),
    ctr: !exists(json, 'ctr') ? undefined : json['ctr'],
    pageViews: !exists(json, 'page_views') ? undefined : json['page_views'],
    itemDetailPageViews: !exists(json, 'item_detail_page_views')
      ? undefined
      : json['item_detail_page_views'],
    sales: !exists(json, 'sales') ? undefined : json['sales'],
    addToCarts: !exists(json, 'add_to_carts') ? undefined : json['add_to_carts'],
    metricsToObscure: !exists(json, 'metrics_to_obscure') ? undefined : json['metrics_to_obscure'],
  }
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsToJSON(
  value?: GetVideoAdGroupsParamDataAttributesAnalytics | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    spend: value.spend,
    impressions: value.impressions,
    clicks: value.clicks,
    avg_ctr: value.avgCtr,
    avg_cpm: value.avgCpm,
    ntb_direct_sales: value.ntbDirectSales,
    ntb_halo_sales: value.ntbHaloSales,
    percent_ntb_direct_sales: value.percentNtbDirectSales,
    percent_ntb_halo_sales: value.percentNtbHaloSales,
    direct_sales: value.directSales,
    halo_sales: value.haloSales,
    roas: value.roas,
    halo_roas: value.haloRoas,
    attribution_data: GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataToJSON(
      value.attributionData
    ),
    ctr: value.ctr,
    page_views: value.pageViews,
    item_detail_page_views: value.itemDetailPageViews,
    sales: value.sales,
    add_to_carts: value.addToCarts,
    metrics_to_obscure: value.metricsToObscure,
  }
}
