// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchy } from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchy'
import {
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSON,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchyToJSON,
} from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchy'
import type { PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValues } from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValues'
import {
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValues'
import type { PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitle } from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitle'
import {
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitleFromJSON,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitleFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitleToJSON,
} from './PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamBrandPageFieldDecisions
 */
export interface PutReservationAdGroupsIdParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitle}
   * @memberof PutReservationAdGroupsIdParamBrandPageFieldDecisions
   */
  title?: PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchy>}
   * @memberof PutReservationAdGroupsIdParamBrandPageFieldDecisions
   */
  hierarchy?: Array<PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof PutReservationAdGroupsIdParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamBrandPageFieldDecisions interface.
 */
export function instanceOfPutReservationAdGroupsIdParamBrandPageFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSON(
  json: any
): PutReservationAdGroupsIdParamBrandPageFieldDecisions {
  return PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function PutReservationAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function PutReservationAdGroupsIdParamBrandPageFieldDecisionsToJSON(
  value?: PutReservationAdGroupsIdParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: PutReservationAdGroupsIdParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            PutReservationAdGroupsIdParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(
            value.taxonomy,
            PutReservationAdGroupsIdParamBrandPageFieldDecisionsTaxonomyValuesToJSON
          ),
  }
}
