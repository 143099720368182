import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { useContext, useState } from 'react'
import { AccountFeatures } from 'common/accountFeatures'
import useCapabilities from 'hooks/useCapabilities'
import { SegmentType } from '../constants'
import { TargetingRuleContext } from '../hooks'
import { TargetingFormData } from '../types'
import NewCustomersCollapsedSection from './components/NewCustomersCollapsedSection'
import NewCustomersExpandedSection from './components/NewCustomersExpandedSection'
import TargetingSectionLayout from './components/TargetingSectionLayout'
import TargetingSegmentsCollapsedSection from './components/TargetingSegmentsCollapsedSection'
import TargetingSegmentsExpandedSection from './components/TargetingSegmentsExpandedSection'

const useStyles = (isReviewing: boolean) => {
  const theme = useTheme()

  return {
    containerStyle: css(
      isReviewing
        ? {}
        : {
            paddingBottom: 24,
          }
    ),
    operatorContainerStyle: css({
      display: 'flex',
      marginBottom: 16,
      alignContent: 'center',
    }),
    operatorLabelTextStyle: css({
      color: theme.colors.systemGrayscale70,
      fontSize: 15,
      marginLeft: 4,
      marginRight: 4,
      fontWeight: 500,
    }),
    operatorTextStyle: css({
      color: theme.colors.systemSuccessRegular,
      fontSize: 15,
      marginTop: 12,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }),
  }
}

export default function TargetingRule() {
  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const showNewToInstacartUserTargeting = useCapabilities({
    featureCapabilities: AccountFeatures.NEW_TO_INSTACART_USER_TARGETING,
  })
  const showInteractionUserTargeting = useCapabilities({
    featureCapabilities: AccountFeatures.INTERACTION_USER_TARGETING,
  })
  const { containerStyle, operatorTextStyle } = useStyles(isReviewing)
  const {
    values: { operator, interestSegments, purchaseSegments, includeNewCustomers },
  } = useFormikContext<TargetingFormData>()

  const hasSetAnyTargetingField =
    includeNewCustomers || !!interestSegments.length || !!purchaseSegments.length

  const [showOperator, setShowOperator] = useState<boolean>(
    isReadOnly || isReviewing || hasSetAnyTargetingField
  ) // Or if the user expanded any of the sections.

  return (
    <div css={containerStyle} data-testid="targeting-section">
      {showNewToInstacartUserTargeting && (
        <TargetingSectionLayout
          titleMessageId="pages.displayProduct.common.targetingRule.sections.newCustomers.title"
          renderCollapsed={() => <NewCustomersCollapsedSection />}
          renderExpanded={() => <NewCustomersExpandedSection />}
          onExpanded={() => setShowOperator(true)}
          testId="targeting-new-to-instacart-section"
        />
      )}

      {showOperator && showNewToInstacartUserTargeting && (
        <div css={operatorTextStyle}>{operator}</div>
      )}

      {showInteractionUserTargeting && (
        <TargetingSectionLayout
          titleMessageId="pages.displayProduct.common.targetingRule.sections.interests.title"
          renderCollapsed={() => (
            <TargetingSegmentsCollapsedSection segmentType={SegmentType.INTEREST} />
          )}
          renderExpanded={() => (
            <TargetingSegmentsExpandedSection segmentType={SegmentType.INTEREST} />
          )}
          onExpanded={() => setShowOperator(true)}
          testId="targeting-interest-section"
        />
      )}

      {showOperator && showInteractionUserTargeting && (
        <div css={operatorTextStyle}>{operator}</div>
      )}

      <TargetingSectionLayout
        titleMessageId="pages.displayProduct.common.targetingRule.sections.purchaseBehaviors.title"
        renderCollapsed={() => (
          <TargetingSegmentsCollapsedSection segmentType={SegmentType.PURCHASE} />
        )}
        renderExpanded={() => (
          <TargetingSegmentsExpandedSection segmentType={SegmentType.PURCHASE} />
        )}
        onExpanded={() => setShowOperator(true)}
        testId="targeting-purchased-section"
      />
    </div>
  )
}
