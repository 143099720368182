// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionData } from './GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionData'
import {
  GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSON,
  GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSONTyped,
  GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataToJSON,
} from './GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionData'
import type { GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo } from './GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo'
import {
  GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSON,
  GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSONTyped,
  GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoToJSON,
} from './GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo'

/**
 *
 * @export
 * @interface GetAdGroupKeywordResponseDataAttributesAnalytics
 */
export interface GetAdGroupKeywordResponseDataAttributesAnalytics {
  /**
   * Number of impressions
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  impressions: number
  /**
   * Number of clicks
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  clicks: number
  /**
   * Product Spend
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  spend: number
  /**
   * NTB Ad Spend, only available for NTB optimized bidding campaigns
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  ntbSpend?: number
  /**
   * Clickthrough Rate
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  ctr: number
  /**
   * Cost Per Click
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  cpc: number
  /**
   * New to Brand Attributed Sales
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  ntbAttributedSales?: number
  /**
   * Percentage of Attributed Sales that is New to Brand
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  percentNtbAttributedSales?: number
  /**
   * NTB Return on ad spend, only available for NTB optimized bidding campaigns
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  ntbRoas?: number
  /**
   * Attributed Quantities
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  attributedQuantities: number
  /**
   * Attributed Sales
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  attributedSales: number
  /**
   * Return on ad spend
   * @type {number}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  roas: number
  /**
   *
   * @type {GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionData}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  attributionData?: GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionData
  /**
   *
   * @type {GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  auctionInfo?: GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfo
  /**
   * Metrics we want to obscure in the FE
   * @type {Array<string>}
   * @memberof GetAdGroupKeywordResponseDataAttributesAnalytics
   */
  metricsToObscure?: Array<string>
}

/**
 * Check if a given object implements the GetAdGroupKeywordResponseDataAttributesAnalytics interface.
 */
export function instanceOfGetAdGroupKeywordResponseDataAttributesAnalytics(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'impressions' in value
  isInstance = isInstance && 'clicks' in value
  isInstance = isInstance && 'spend' in value
  isInstance = isInstance && 'ctr' in value
  isInstance = isInstance && 'cpc' in value
  isInstance = isInstance && 'attributedQuantities' in value
  isInstance = isInstance && 'attributedSales' in value
  isInstance = isInstance && 'roas' in value

  return isInstance
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsFromJSON(
  json: any
): GetAdGroupKeywordResponseDataAttributesAnalytics {
  return GetAdGroupKeywordResponseDataAttributesAnalyticsFromJSONTyped(json, false)
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordResponseDataAttributesAnalytics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    impressions: json['impressions'],
    clicks: json['clicks'],
    spend: json['spend'],
    ntbSpend: !exists(json, 'ntb_spend') ? undefined : json['ntb_spend'],
    ctr: json['ctr'],
    cpc: json['cpc'],
    ntbAttributedSales: !exists(json, 'ntb_attributed_sales')
      ? undefined
      : json['ntb_attributed_sales'],
    percentNtbAttributedSales: !exists(json, 'percent_ntb_attributed_sales')
      ? undefined
      : json['percent_ntb_attributed_sales'],
    ntbRoas: !exists(json, 'ntb_roas') ? undefined : json['ntb_roas'],
    attributedQuantities: json['attributed_quantities'],
    attributedSales: json['attributed_sales'],
    roas: json['roas'],
    attributionData: !exists(json, 'attribution_data')
      ? undefined
      : GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataFromJSON(
          json['attribution_data']
        ),
    auctionInfo: !exists(json, 'auction_info')
      ? undefined
      : GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoFromJSON(json['auction_info']),
    metricsToObscure: !exists(json, 'metrics_to_obscure') ? undefined : json['metrics_to_obscure'],
  }
}

export function GetAdGroupKeywordResponseDataAttributesAnalyticsToJSON(
  value?: GetAdGroupKeywordResponseDataAttributesAnalytics | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    impressions: value.impressions,
    clicks: value.clicks,
    spend: value.spend,
    ntb_spend: value.ntbSpend,
    ctr: value.ctr,
    cpc: value.cpc,
    ntb_attributed_sales: value.ntbAttributedSales,
    percent_ntb_attributed_sales: value.percentNtbAttributedSales,
    ntb_roas: value.ntbRoas,
    attributed_quantities: value.attributedQuantities,
    attributed_sales: value.attributedSales,
    roas: value.roas,
    attribution_data: GetAdGroupKeywordResponseDataAttributesAnalyticsAttributionDataToJSON(
      value.attributionData
    ),
    auction_info: GetAdGroupKeywordResponseDataAttributesAnalyticsAuctionInfoToJSON(
      value.auctionInfo
    ),
    metrics_to_obscure: value.metricsToObscure,
  }
}
