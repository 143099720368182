import ahoy from 'ahoy.js'
import { getEnv, isProd } from './utils'

export function initAhoy() {
  const url = getEnv('ADS_API_BASE_URL').replace(/\/$/, '')
  ahoy.configure({
    urlPrefix: url,
    withCredentials: true,
    cookieDomain: getBaseDomain(url),
  })

  ahoy.trackAll()

  Object.assign(window.__ADS, {
    ahoy,
  })
}

function getBaseDomain(url: string) {
  const { hostname } = new URL(url)
  // uses 'localhost' for dev, 'instacart.team' for stating, 'ads.instacart.com' for prod
  // this should be in sync with ads-backend/config/initializers/ahoy.rb
  const tokens = hostname.split('.')
  if (tokens.length > 2) {
    const count = isProd() ? -3 : -2
    return tokens.slice(count).join('.')
  }
  return hostname
}
