// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostReportResponseDataAttributes } from './PostReportResponseDataAttributes'
import {
  PostReportResponseDataAttributesFromJSON,
  PostReportResponseDataAttributesFromJSONTyped,
  PostReportResponseDataAttributesToJSON,
} from './PostReportResponseDataAttributes'

/**
 *
 * @export
 * @interface PostReportResponseData
 */
export interface PostReportResponseData {
  /**
   * Report Id
   * @type {string}
   * @memberof PostReportResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostReportResponseData
   */
  type: PostReportResponseDataTypeEnum
  /**
   *
   * @type {PostReportResponseDataAttributes}
   * @memberof PostReportResponseData
   */
  attributes: PostReportResponseDataAttributes
}

/**
 * @export
 */
export const PostReportResponseDataTypeEnum = {
  Report: 'report',
} as const
export type PostReportResponseDataTypeEnum =
  typeof PostReportResponseDataTypeEnum[keyof typeof PostReportResponseDataTypeEnum]

/**
 * Check if a given object implements the PostReportResponseData interface.
 */
export function instanceOfPostReportResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function PostReportResponseDataFromJSON(json: any): PostReportResponseData {
  return PostReportResponseDataFromJSONTyped(json, false)
}

export function PostReportResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostReportResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: PostReportResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function PostReportResponseDataToJSON(value?: PostReportResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: PostReportResponseDataAttributesToJSON(value.attributes),
  }
}
