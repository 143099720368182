// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
 */
export interface GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch {
  /**
   * Direct Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  directSales?: number
  /**
   * Halo Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  haloSales?: number
  /**
   * Return on Ad Spend
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  roas?: number
  /**
   * Halo Return on Ad Spend
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  haloRoas?: number
  /**
   * New to Brand Direct Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  ntbDirectSales?: number
  /**
   * New to Brand Halo Sales
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  ntbHaloSales?: number
  /**
   * Percentage of Direct Sales that is New to Brand
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  percentNtbDirectSales?: number
  /**
   * Percentage of Halo Sales that is New to Brand
   * @type {number}
   * @memberof GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
   */
  percentNtbHaloSales?: number
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch {
  return GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped(
    json,
    false
  )
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch {
  if (json === undefined || json === null) {
    return json
  }
  return {
    directSales: !exists(json, 'direct_sales') ? undefined : json['direct_sales'],
    haloSales: !exists(json, 'halo_sales') ? undefined : json['halo_sales'],
    roas: !exists(json, 'roas') ? undefined : json['roas'],
    haloRoas: !exists(json, 'halo_roas') ? undefined : json['halo_roas'],
    ntbDirectSales: !exists(json, 'ntb_direct_sales') ? undefined : json['ntb_direct_sales'],
    ntbHaloSales: !exists(json, 'ntb_halo_sales') ? undefined : json['ntb_halo_sales'],
    percentNtbDirectSales: !exists(json, 'percent_ntb_direct_sales')
      ? undefined
      : json['percent_ntb_direct_sales'],
    percentNtbHaloSales: !exists(json, 'percent_ntb_halo_sales')
      ? undefined
      : json['percent_ntb_halo_sales'],
  }
}

export function GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchToJSON(
  value?: GetVideoAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    direct_sales: value.directSales,
    halo_sales: value.haloSales,
    roas: value.roas,
    halo_roas: value.haloRoas,
    ntb_direct_sales: value.ntbDirectSales,
    ntb_halo_sales: value.ntbHaloSales,
    percent_ntb_direct_sales: value.percentNtbDirectSales,
    percent_ntb_halo_sales: value.percentNtbHaloSales,
  }
}
