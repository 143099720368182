// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupProductsResponseDataAttributes } from './GetAdGroupProductsResponseDataAttributes'
import {
  GetAdGroupProductsResponseDataAttributesFromJSON,
  GetAdGroupProductsResponseDataAttributesFromJSONTyped,
  GetAdGroupProductsResponseDataAttributesToJSON,
} from './GetAdGroupProductsResponseDataAttributes'

/**
 *
 * @export
 * @interface GetAdGroupProductsResponseData
 */
export interface GetAdGroupProductsResponseData {
  /**
   * Ad Group Product ID
   * @type {string}
   * @memberof GetAdGroupProductsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdGroupProductsResponseData
   */
  type: GetAdGroupProductsResponseDataTypeEnum
  /**
   *
   * @type {GetAdGroupProductsResponseDataAttributes}
   * @memberof GetAdGroupProductsResponseData
   */
  attributes: GetAdGroupProductsResponseDataAttributes
}

/**
 * @export
 */
export const GetAdGroupProductsResponseDataTypeEnum = {
  AdGroupProduct: 'ad_group_product',
} as const
export type GetAdGroupProductsResponseDataTypeEnum =
  typeof GetAdGroupProductsResponseDataTypeEnum[keyof typeof GetAdGroupProductsResponseDataTypeEnum]

/**
 * Check if a given object implements the GetAdGroupProductsResponseData interface.
 */
export function instanceOfGetAdGroupProductsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdGroupProductsResponseDataFromJSON(json: any): GetAdGroupProductsResponseData {
  return GetAdGroupProductsResponseDataFromJSONTyped(json, false)
}

export function GetAdGroupProductsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupProductsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAdGroupProductsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetAdGroupProductsResponseDataToJSON(
  value?: GetAdGroupProductsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAdGroupProductsResponseDataAttributesToJSON(value.attributes),
  }
}
