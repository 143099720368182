import { css, SerializedStyles } from '@emotion/react'
import { ChevronDownIcon, ChevronUpIcon, spacing, ThemeColors, useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { HTMLProps, ReactNode, forwardRef, useEffect, useRef, useImperativeHandle } from 'react'
import { useDisclosureState, Disclosure, DisclosureContent, DisclosureStateReturn } from 'reakit'
import { useIntl } from 'common'
import { GenericMessageDescriptor } from 'locales/types'
import { CardDescription } from './CardDescription'
import { UnstyledButton } from './UnstyledButton'

export type CardCollapseRef = DisclosureStateReturn

export interface CardCollapseProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode
  trigger: ReactNode
  description?: GenericMessageDescriptor
  descriptionCollapsed?: JSX.Element
  expandOnMount?: boolean
  scrollIntoView?: boolean
  animated?: boolean
  triggerIconSize?: number
  triggerIconColor?: keyof ThemeColors
  triggerStyle?: SerializedStyles
  contentStyle?: SerializedStyles
  contentTestId?: string
  badgeMessage?: GenericMessageDescriptor
}

const TRANSITION_MS = 200
const CHEVRON_ICON_SIZE = 24

function useStyles({ animated }: { animated: boolean }) {
  const theme = useTheme()

  return {
    trigger: css({
      position: 'relative',
      width: '100%',
      textAlign: 'left',
      cursor: 'pointer',
      outline: 'none',
      '&:focus-visible::after': {
        border: `2px solid ${theme.colors.systemGrayscale70}`,
        content: '""',
        display: 'block',
        position: 'absolute',
        top: -spacing.s4,
        bottom: -spacing.s4,
        left: -spacing.s8,
        right: -spacing.s8,
        borderRadius: theme.radius.r8,
      },
    }),
    triggerMain: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    title: css({
      flex: 1,
    }),
    icon: css({
      flex: '0 0 auto',
    }),
    content: css({
      '&.animating': {
        overflow: 'hidden',
      },
    }),
    contentInner: css({
      paddingTop: spacing.s12,
      ...(animated
        ? {
            transitionProperty: 'opacity, margin-bottom',
            transitionDuration: `${TRANSITION_MS}ms`,
            transitionTimingFunction: 'ease-in-out',
            opacity: 0,
            marginBottom: '-100%',
            '[data-enter] &': {
              opacity: 1,
              marginBottom: 0,
            },
          }
        : {}),
    }),
  }
}

export const CardCollapse = forwardRef<CardCollapseRef, CardCollapseProps>(function CardCollapse(
  {
    children,
    trigger,
    description,
    descriptionCollapsed = description,
    expandOnMount = false,
    scrollIntoView = false,
    animated = true,
    triggerIconSize = CHEVRON_ICON_SIZE,
    triggerIconColor = 'systemGrayscale30',
    triggerStyle,
    contentStyle,
    contentTestId,
    badgeMessage,
    ...props
  },
  ref
) {
  const { genericFormatMessage } = useIntl()
  const styles = useStyles({ animated })
  const contentRef = useRef<HTMLDivElement>(null)
  const disclosure = useDisclosureState({
    visible: expandOnMount,
    animated: animated && TRANSITION_MS,
  })

  // This attaches the disclosure state the component ref (if any) which allows parent components
  // to expand/collapse the disclosure as needed.
  useImperativeHandle(ref, () => disclosure)

  // Scroll the disclosure content into view when it's expanded if `scrollIntoView` is true
  useEffect(() => {
    if (scrollIntoView && disclosure.visible) {
      // The height of the content element changes as it's transitioning, so we need to wait for the transition
      // to complete before calling scrollIntoView
      const timeout = setTimeout(
        () => {
          contentRef.current?.scrollIntoView({ behavior: 'smooth' })
        },
        animated ? TRANSITION_MS : 0
      )

      return () => clearTimeout(timeout)
    }

    return () => {}
  }, [disclosure.visible, scrollIntoView, animated])

  const ChevronIcon = disclosure.visible ? ChevronUpIcon : ChevronDownIcon

  const descriptionEl = disclosure.visible
    ? description && genericFormatMessage(description)
    : descriptionCollapsed

  return (
    <div {...props}>
      <Disclosure {...disclosure} css={[styles.trigger, triggerStyle]} as={UnstyledButton}>
        <div css={styles.triggerMain}>
          <div css={styles.title}>{trigger}</div>
          <ChevronIcon size={triggerIconSize} color={triggerIconColor} css={styles.icon} />
        </div>
        {descriptionEl && <CardDescription>{descriptionEl as React.ReactNode}</CardDescription>}
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        ref={contentRef}
        css={[styles.content, contentStyle]}
        className={classNames({ animating: disclosure.animating })}
        data-testid={contentTestId}
      >
        <div css={styles.contentInner}>{children}</div>
      </DisclosureContent>
    </div>
  )
})

CardCollapse.displayName = 'CardCollapse'
