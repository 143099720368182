import { spacing } from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { CSSProperties } from 'react'
import breakPoints from './breakPoints'
import Feature from './Feature'

const styles: { [key: string]: CSSProperties } = {
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    ...{ paddingLeft: 64, paddingRight: 64 },
    ...{ paddingBottom: spacing.s32 },
    [breakPoints.mobile]: {
      flexDirection: 'column',
      ...{ paddingLeft: spacing.s24, paddingRight: spacing.s24 },
      ...{ paddingBottom: spacing.s16 },
    },
  },
  img: {
    objectFit: 'contain',
    maxWidth: '100%',
    [breakPoints.mobile]: {
      objectPosition: 'center',
    },
  },
  imgWrapper: {
    maxHeight: '345px',
    maxWidth: '564px',
    display: 'flex',
    flexGrow: 2,
    ...{ marginLeft: spacing.s32, marginRight: spacing.s32 },
    [breakPoints.mobile]: {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
    },
  },
  firstImage: {
    display: 'flex',
    [breakPoints.mobile]: {
      display: 'none',
      margin: 0,
    },
  },
  lastImage: {
    display: 'none',
    [breakPoints.mobile]: {
      display: 'flex',
      margin: 0,
    },
  },
  stepWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  step: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    width: '64px',
    height: '64px',
    backgroundColor: '#f2f8ff',
    color: '#2b79c6',
    fontSize: '32px',
  },
  featureWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    ...{ marginLeft: spacing.s32, marginRight: spacing.s32 },
    [breakPoints.mobile]: {
      ...{ marginLeft: spacing.s24, marginRight: spacing.s24 },
      width: '100%',
    },
  },
  feature: {
    maxWidth: '330px',
    [breakPoints.mobile]: {
      maxWidth: 'initial',
    },
  },
}

interface StepFeatureProps {
  step: number
  lead: string
  children: JSX.Element
}

const StepFeature = ({ step, lead, children }: StepFeatureProps) => {
  return (
    <Feature
      style={styles.feature}
      renderImage={() => (
        <div style={styles.stepWrapper}>
          <div style={styles.step}>{step}</div>
        </div>
      )}
      lead={lead}
    >
      {children}
    </Feature>
  )
}

interface StepProps {
  step: number
  lead: string
  imgSrc: string
  children: JSX.Element
}

const Step = ({ step, lead, imgSrc, children }: StepProps) => {
  return (
    <div style={styles.row}>
      <div style={styles.featureWrapper}>
        <StepFeature lead={lead} step={step}>
          {children}
        </StepFeature>
      </div>
      <div style={styles.imgWrapper}>
        <img src={imgSrc} style={styles.img} alt={lead} />
      </div>
    </div>
  )
}

export const StepImgFirst = Radium(({ step, lead, imgSrc, children }: StepProps) => {
  return (
    <div style={styles.row}>
      <div style={{ ...styles.imgWrapper, ...styles.firstImage }}>
        <img src={imgSrc} style={styles.img} alt={lead} />
      </div>
      <div style={styles.featureWrapper}>
        <StepFeature lead={lead} step={step}>
          {children}
        </StepFeature>
      </div>
      <div style={{ ...styles.imgWrapper, ...styles.lastImage }}>
        <img src={imgSrc} style={styles.img} alt={lead} />
      </div>
    </div>
  )
})

export default Radium(Step)
