import { css } from '@emotion/react'
import { Switch } from 'ic-snacks' // eslint-disable-line no-restricted-imports
import { SwitchProps } from 'ic-snacks/dist/esm/components/Buttons/Switch'
import { FunctionComponent } from 'react'
import {
  useIsPaymentStatusInactive,
  useIsPaymentStatusRequiresPayment,
} from 'common/accountPaymentStatus'

interface Props extends SwitchProps {
  testId?: string
}

function useStyles({ disabled }: { disabled: boolean }) {
  return {
    wrapper: css({
      overflow: 'hidden',
      pointerEvents: disabled ? 'none' : undefined,
    }),
  }
}

const PaymentStatusRestrictedSwitch: FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const isPaymentStatusInvalid = useIsPaymentStatusInactive()
  const isPaymentStatusRequiresPayment = useIsPaymentStatusRequiresPayment()

  const disabled = props.isDisabled || isPaymentStatusInvalid || isPaymentStatusRequiresPayment
  const styles = useStyles({ disabled })

  return (
    <div css={styles.wrapper} data-testid={props.testId}>
      <Switch {...props} isDisabled={disabled} />
    </div>
  )
}

export default PaymentStatusRestrictedSwitch
