import { useFormikContext } from 'formik'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { TargetingRuleContext } from '../../hooks'
import { TargetingFormData } from '../../types'

export default function NewCustomersCollapsedSection() {
  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const {
    values: { includeNewCustomers },
  } = useFormikContext<TargetingFormData>()

  const messageId = includeNewCustomers
    ? 'pages.displayProduct.common.targetingRule.newCustomers.collapsed.checked'
    : isReadOnly || isReviewing
    ? 'pages.displayProduct.common.targetingRule.newCustomers.collapsed.unchecked'
    : 'pages.displayProduct.common.targetingRule.newCustomers.label'

  return <FormattedMessage id={messageId} />
}
