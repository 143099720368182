import { filter } from 'lodash'
import { getCreativeSchema } from 'common/common-definitions/creative'
import { getTargetingRuleFromFormData } from 'components/organisms/TargetingRule'
import { getUserTargetingRuleFromFormData } from 'components/organisms/TargetingRule/FormHelpers'
import { isTargetingV2Enabled } from 'components/organisms/TargetingRule/hooks'
import { isProviAccount, AuthCtx } from 'context'
import {
  isPromotedAisle as isPromotedAisleFn,
  PROVI_URL_PREFIX,
  TARGETING_SHORTCUT_KEYWORD_TARGETING,
  TargetingType,
} from 'pages/DisplayProduct/DisplayAdGroup/constants'
import {
  CollectionType,
  DisplayAdGroupFormData,
  PlacementType,
} from 'pages/DisplayProduct/DisplayAdGroup/displayAdGroup.types'
import { ReservationAdGroupFormData } from 'pages/ReservationCampaigns/types'
import { getEnumValueFromString } from 'service/mappings'
import {
  PostDisplayAdGroupsRequest,
  PutDisplayAdGroupsIdRequest,
} from 'service/openapi/__codegen__/apis/DisplayAdGroupsApi'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative } from 'service/openapi/__codegen__/models/ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative'
import { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridGridOrderEnum } from 'service/openapi/__codegen__/models/ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGrid'
import { ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridGridOrderEnum } from 'service/openapi/__codegen__/models/ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGrid'
import { SmartAdGroupFormValues } from 'utils/formik/adGroups/smart'
import { BrandPageFormFields } from '../../common/components/BrandPage/brandPage.types'
import { toDisplayAdGroup } from '../../common/components/BrandPage/brandPage.utils'

type ImageAsset = {
  id?: string
  alt?: string
  urls: {
    previewUrl?: string
    uploadedUri?: string
  }
  // Added for creative asset uploader which flattens the formik state
  previewUrl?: string
  uploadedUri?: string
  altText?: string
}

const serializeImageAsset = (asset: ImageAsset) => {
  return {
    id: asset.id || '',
    previewUrl: asset?.urls?.previewUrl || asset?.previewUrl || '',
    uploadedUri: asset?.urls?.uploadedUri || asset?.uploadedUri || '',
    altText: asset?.alt || asset?.altText || '',
  }
}

const buildTargetingStrategy = (formData: DisplayAdGroupFormData, authContext: AuthCtx) => {
  let targetingStrategy = {}
  if (
    (formData.targetingStrategy.targetingType === TargetingType.USER_TARGETING ||
      formData.targetingStrategy.targetingType === TargetingType.TARGETING_SHORTCUT) &&
    !isProviAccount(authContext)
  ) {
    targetingStrategy = {
      targetingRule: getTargetingRuleFromFormData({
        formData,
        isTargetingV2Enabled: isTargetingV2Enabled(
          authContext,
          formData.creativeType as CreativeTypeEnum
        ),
      }),
      targetingShortcut: formData.targetingStrategy.targetingShortcut,
    }
  } else {
    targetingStrategy = {
      targetingShortcut: TARGETING_SHORTCUT_KEYWORD_TARGETING,
      keywords: formData.targetingStrategy.keywords || [],
      keywordMatchType: formData.targetingStrategy.keywordMatchType,
    }
  }

  if (isProviAccount(authContext)) {
    const formDataTargetingStrategy = formData.targetingStrategy
    targetingStrategy = {
      ...targetingStrategy,
      userTargeting: {
        geography: formDataTargetingStrategy.userTargeting?.geography,
        buyerLicense: formDataTargetingStrategy.userTargeting?.buyerLicense,
        ...getUserTargetingRuleFromFormData(formData),
      },
      targetingShortcut: formData.targetingStrategy.targetingShortcut,
    }
  }

  return targetingStrategy
}

const buildPlacementType = (formData: DisplayAdGroupFormData) => {
  if (formData.creativeType === CreativeTypeEnum.ShoppableVideoV1) {
    const targetingType = formData.targetingStrategy?.targetingType

    switch (targetingType) {
      case TargetingType.USER_TARGETING:
        return PlacementType.STOREFRONT_INTERLEAVED_V3
      case TargetingType.KEYWORD_TARGETING:
        return PlacementType.SEARCH_INTERLEAVED_V4
    }
  }
  return formData.placementType
}

// This is needed to remove duplicate tracked ids if the products are already selected as part
// of the Shoppable ids
const dedupeSdTrackedIds = (
  shoppableIds: string[] | undefined = [],
  trackedIds: string[] | undefined = []
) => filter(trackedIds, id => !shoppableIds.includes(id))

// The Typings for the "creative" form fields work by using the "creativeType" field to determine
// which fields are avaible on the "creative" object. I want to stop using the "creativeType" field
// to determine which fields are available, so I need to bypass the typings here until I refactor
// the yup validations.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Make this agnostic of typing
export const buildCreativeRequest = (
  formData: DisplayAdGroupFormData | ReservationAdGroupFormData | SmartAdGroupFormValues
) => {
  // Get the schema for the creative we are trying to serialize
  const schema = getCreativeSchema(formData.creativeType)

  // Create the container for our request params
  const creative: ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreative & { id: string } = {
    id: formData.creative.id,
    type: formData.creativeType as CreativeTypeEnum,
    assets: {},
  }

  creative.trackedProductIds = dedupeSdTrackedIds(formData.productIds, formData.trackedProductIds)
  creative.trackedProductGroupIds = dedupeSdTrackedIds(
    formData.productGroupIds,
    formData.trackedProductGroupIds
  )

  // These fields are optional on the Type. Explicitly set them here to satisfy the type checker
  creative.actions = {}

  if (schema.actions.hasItemGrid()) {
    creative.actions.mainItemGrid = {
      itemGrid: formData.productIds as any,
      gridOrder: formData.gridOrder
        ? getEnumValueFromString(
            ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainItemGridGridOrderEnum,
            formData.gridOrder
          )
        : undefined,
    }
  }

  if (schema.actions.hasProductGroupGrid()) {
    creative.actions.mainProductGroupGrid = {
      productGroupGrid: formData.productGroupIds as any,
      gridOrder: formData.gridOrder
        ? getEnumValueFromString(
            ApiDisplayAdGroupsControllerCreateInputDisplayAdGroupCreativeActionsMainProductGroupGridGridOrderEnum,
            formData.gridOrder
          )
        : undefined,
    }
  }

  if (schema.actions.hasBrandPageClick()) {
    creative.actions.bannerClick = {
      brandPageClick: {
        brandPageId: formData.brandPageId,
      },
    }
  }

  if (schema.actions.hasUrlClick()) {
    creative.actions.bannerClick = {
      urlClick: {
        url: PROVI_URL_PREFIX + formData.urlClickUrl,
      },
    }
  }

  if (schema.actions.hasRecipeClick()) {
    creative.actions.bannerClick = {
      recipeClick: {
        id: formData.recipeId || '',
      },
    }
  }

  if (schema.actions.hasCollectionGrid()) {
    creative.actions.mainCollectionGrid = {
      collections: (formData.collections || [])
        .filter(collection => !collection.deleted) // Filter out deleted collections
        .map(({ title, sponsored, collectionType, keyword, productIds }) => ({
          name: title,
          sponsored,
          properties: {
            type: collectionType,
            itemGrid: collectionType !== CollectionType.searchTermCollection ? productIds : [],
            keywords: collectionType === CollectionType.searchTermCollection ? [keyword] : [],
          },
        })),
    }
  }

  if (schema.properties.hasTagline()) {
    creative.properties = {
      tagline: formData.tagline || '',
    }
  }

  if (schema.properties.hasUvcFields()) {
    if (!creative.properties) {
      creative.properties = {
        tagline: '', // Have to set this since tagline is a required field
      }
    }
    creative.properties.uvcFields = formData.uvcFields || {}
  }

  if (schema.assets.hasBrandLogo()) {
    const { brandLogo } = formData.creative
    if (brandLogo) {
      creative.assets.brandLogo = serializeImageAsset(brandLogo)
    }
  }

  if (schema.assets.hasThumbnail()) {
    const { thumbnail } = formData.creative
    if (thumbnail) {
      creative.assets.thumbnail = serializeImageAsset(thumbnail)
    }
  }

  if (schema.assets.hasHeroImage()) {
    const { heroImage } = formData.creative as any
    if (heroImage) {
      creative.assets.heroImage = serializeImageAsset(heroImage)
    }
  }

  if (schema.assets.hasTopImageWeb()) {
    const creativeFormData = formData.creative as any
    const { topImageWeb } = creativeFormData
    if (topImageWeb) {
      creative.assets.topImageWeb = serializeImageAsset(topImageWeb)

      // Alt text is stored on the creative itself for this asset type.
      if (!creative.assets.topImageWeb.altText) {
        creative.assets.topImageWeb.altText = creativeFormData.alt
      }
    }
  }

  if (schema.assets.hasTopImageMobile()) {
    const creativeFormData = formData.creative as any
    const { topImageMobile } = creativeFormData
    if (topImageMobile) {
      creative.assets.topImageMobile = serializeImageAsset(topImageMobile)

      // Alt text is stored on the creative itself for this asset type.
      if (!creative.assets.topImageMobile.altText) {
        creative.assets.topImageMobile.altText = creativeFormData.alt
      }
    }
  }

  if (schema.assets.hasUvcImageMobile()) {
    const creativeFormData = formData.creative as any
    const { uvcImageMobile } = creativeFormData
    if (uvcImageMobile) {
      creative.assets.uvcImageMobile = serializeImageAsset(uvcImageMobile)
    }
  }

  if (schema.assets.hasUvcImageWeb()) {
    const creativeFormData = formData.creative as any
    const { uvcImageWeb } = creativeFormData
    if (uvcImageWeb) {
      creative.assets.uvcImageWeb = serializeImageAsset(uvcImageWeb)
    }
  }

  if (schema.assets.hasImageWeb()) {
    const creativeFormData = formData.creative as any
    const { topImageWeb } = creativeFormData
    if (topImageWeb) {
      creative.assets.imageWeb = serializeImageAsset(topImageWeb)

      // Alt text is stored on the creative itself for this asset type.
      if (!creative.assets.imageWeb.altText) {
        creative.assets.imageWeb.altText = creativeFormData.alt
      }
    }
  }

  if (schema.assets.hasImageMobile()) {
    const creativeFormData = formData.creative as any
    const { topImageMobile } = creativeFormData
    if (topImageMobile) {
      creative.assets.imageMobile = serializeImageAsset(topImageMobile)

      // Alt text is stored on the creative itself for this asset type.
      if (!creative.assets.imageMobile.altText) {
        creative.assets.imageMobile.altText = creativeFormData.alt
      }
    }
  }

  if (schema.assets.hasVideo()) {
    const { video, thumbnail } = formData.creative as any
    if (video) {
      creative.assets.video = {
        id: video.id || '',
        previewUrl: video.urls.previewUrl || '',
        uploadedUri: video.urls.uploadedUri || '',
        altText: thumbnail.alt || '',
        videoSubtitlePreviewUrl: video.urls.videoSubtitlePreviewUrl || '',
        videoSubtitleUploadedUri: video.urls.videoSubtitleUploadedUri || '',
        videoSubtitleUploadedFileName: video.urls.videoSubtitleUploadedFileName || '',
      }
    }
  }

  return creative
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const toCreateDisplayAdGroupRequest = ({
  formData,
  additionalParams,
  authContext,
  skipNonEmptyValidation = false,
}: {
  formData: DisplayAdGroupFormData
  additionalParams: {
    campaignId: string
    hasCampaignLandingPage: boolean
  }
  authContext: AuthCtx
  skipNonEmptyValidation?: boolean
}): PostDisplayAdGroupsRequest => {
  const isPromotedAisle = isPromotedAisleFn(formData.creativeType)
  const shouldFormatCampaignLandingPage =
    !formData.brandPageId && additionalParams.hasCampaignLandingPage

  const formDataBrandPage = (
    isPromotedAisle
      ? {
          ...formData.brandPage,
          highlightedProductIds: formData.productIds,
        }
      : formData.brandPage
  ) as BrandPageFormFields

  const brandPage = shouldFormatCampaignLandingPage
    ? toDisplayAdGroup(formDataBrandPage, skipNonEmptyValidation)
    : undefined

  const creative = buildCreativeRequest(formData)

  const brandPageWithId = brandPage ? { ...brandPage, id: formData.brandPage.id } : undefined

  return {
    // TODO: change these as fields become available
    body: {
      displayAdGroup: {
        campaignId: additionalParams.campaignId,
        name: formData.name,
        defaultBid: formData.defaultBid || null,
        placementType: buildPlacementType(formData),
        targetingStrategy: buildTargetingStrategy(formData, authContext),
        creative,
        brandPage: brandPageWithId,
      },
      skipValidation: skipNonEmptyValidation,
    },
  } as unknown as PostDisplayAdGroupsRequest
}

export const toPutDisplayAdGroupsIdRequest = ({
  formData,
  additionalParams,
  authContext,
  skipNonEmptyValidation = false,
}: {
  formData: DisplayAdGroupFormData
  additionalParams:
    | {
        id: string
        campaignId: string
        hasCampaignLandingPage: boolean
      }
    | undefined
  authContext: AuthCtx
  skipNonEmptyValidation?: boolean
}): PutDisplayAdGroupsIdRequest => {
  if (!additionalParams) throw new Error('id is required to update DisplayAdGroup')

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    ...toCreateDisplayAdGroupRequest({
      formData,
      additionalParams: {
        campaignId: additionalParams.campaignId,
        hasCampaignLandingPage: additionalParams.hasCampaignLandingPage,
      },
      skipNonEmptyValidation,
      authContext,
    }),
    id: additionalParams.id,
  } as PutDisplayAdGroupsIdRequest // TODO: remove casting once status is removed from the request params
}
