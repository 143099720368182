// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  brandPageClick?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  urlClick?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  recipeClick?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON(
        value.urlClick
      ),
    recipe_click:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
