// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPage } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPage'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPageFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPageFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPageToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPage'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreative } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreative'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreative'
import type { ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategy } from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategy'
import {
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategyFromJSON,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategyFromJSONTyped,
  ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategyToJSON,
} from './ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategy'

/**
 *
 * @export
 * @interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
 */
export interface ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  name: string
  /**
   * The maximum amount, in dollars, you want to pay per thousands views for advertised creatives in this ad group.
   * @type {number}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  defaultBid: number
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  campaignId: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  placementType?: string
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreative}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  creative: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreative
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategy}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  targetingStrategy: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  lockKey?: string
  /**
   *
   * @type {ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPage}
   * @memberof ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup
   */
  brandPage?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPage
}

/**
 * Check if a given object implements the ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup interface.
 */
export function instanceOfApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'defaultBid' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSON(
  json: any
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup {
  return ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSONTyped(json, false)
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultBid: json['default_bid'],
    campaignId: json['campaign_id'],
    placementType: !exists(json, 'placement_type') ? undefined : json['placement_type'],
    creative: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeFromJSON(
      json['creative']
    ),
    targetingStrategy:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPageFromJSON(json['brand_page']),
  }
}

export function ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupToJSON(
  value?: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_bid: value.defaultBid,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupCreativeToJSON(value.creative),
    targeting_strategy:
      ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    lock_key: value.lockKey,
    brand_page: ApiDisplayAdGroupsControllerUpdateInputDisplayAdGroupBrandPageToJSON(
      value.brandPage
    ),
  }
}
