// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBlockSpotlightProductCollectionContent } from './PostBrandPagesParamBlockSpotlightProductCollectionContent'
import {
  PostBrandPagesParamBlockSpotlightProductCollectionContentFromJSON,
  PostBrandPagesParamBlockSpotlightProductCollectionContentFromJSONTyped,
  PostBrandPagesParamBlockSpotlightProductCollectionContentToJSON,
} from './PostBrandPagesParamBlockSpotlightProductCollectionContent'
import type { PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues } from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues'
import {
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped,
  PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON,
} from './PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues'

/**
 *
 * @export
 * @interface PostBrandPagesParamBlockSpotlightProductCollection
 */
export interface PostBrandPagesParamBlockSpotlightProductCollection {
  /**
   * id of the block, required for updates
   * @type {string}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  type: PostBrandPagesParamBlockSpotlightProductCollectionTypeEnum
  /**
   *
   * @type {PostBrandPagesParamBlockSpotlightProductCollectionContent}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  content: PostBrandPagesParamBlockSpotlightProductCollectionContent
  /**
   * Unordered collection of products for display
   * @type {Array<string>}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  productIds?: Array<string>
  /**
   * Map of product id to its corresponding assets
   * @type {{ [key: string]: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues; }}
   * @memberof PostBrandPagesParamBlockSpotlightProductCollection
   */
  productAssets?: {
    [key: string]: PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
  }
}

/**
 * @export
 */
export const PostBrandPagesParamBlockSpotlightProductCollectionTypeEnum = {
  SpotlightProductCollectionEvergreenV1: 'spotlight_product_collection.evergreen.v1',
} as const
export type PostBrandPagesParamBlockSpotlightProductCollectionTypeEnum =
  typeof PostBrandPagesParamBlockSpotlightProductCollectionTypeEnum[keyof typeof PostBrandPagesParamBlockSpotlightProductCollectionTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesParamBlockSpotlightProductCollection interface.
 */
export function instanceOfPostBrandPagesParamBlockSpotlightProductCollection(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value

  return isInstance
}

export function PostBrandPagesParamBlockSpotlightProductCollectionFromJSON(
  json: any
): PostBrandPagesParamBlockSpotlightProductCollection {
  return PostBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(json, false)
}

export function PostBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesParamBlockSpotlightProductCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: PostBrandPagesParamBlockSpotlightProductCollectionContentFromJSON(json['content']),
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
    productAssets: !exists(json, 'product_assets')
      ? undefined
      : mapValues(
          json['product_assets'],
          PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON
        ),
  }
}

export function PostBrandPagesParamBlockSpotlightProductCollectionToJSON(
  value?: PostBrandPagesParamBlockSpotlightProductCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: PostBrandPagesParamBlockSpotlightProductCollectionContentToJSON(value.content),
    product_ids: value.productIds,
    product_assets:
      value.productAssets === undefined
        ? undefined
        : mapValues(
            value.productAssets,
            PostBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON
          ),
  }
}
