import { css } from '@emotion/react'
import { Theme, useTheme, POSITION_X_CENTER } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import ClickOutside from 'react-click-outside'
import { useIntl } from 'common'
import { InputSearch } from 'components/ids-ads'
import { TargetingRuleContext, TargetingSegmentContext } from '../hooks'
import BrandOption from './BrandOption'

const useStyles = (theme: Theme) => ({
  wrapper: css({
    position: 'relative',
    width: '100%',
    marginTop: 16,
  }),
  menu: css({
    zIndex: 1,
    marginTop: '5px',
    width: '100%',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)',
    borderRadius: theme.radius.r8,
    position: 'absolute',
    maxHeight: '250px',
    overflowY: 'scroll',
    backgroundColor: theme.colors.systemGrayscale00,
    ...POSITION_X_CENTER,
  }),
  noMatch: css({
    opacity: 0.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px',
  }),
})

export default function BrandOptionsDropdown() {
  const { formatMessage } = useIntl()

  const theme = useTheme()
  const { wrapper, menu, noMatch } = useStyles(theme)

  const { brands } = useContext(TargetingRuleContext)
  const [brandsExpanded, setBrandsExpanded] = useState<boolean>(false)

  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1)
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const { values, setFieldValue } = useFormikContext()
  const { fieldsPrefix } = useContext(TargetingSegmentContext)

  const brandsFieldName = `${fieldsPrefix}brands`
  const selectedBrandIds: string[] = get(values, brandsFieldName, [])

  const [searchText, setSearchText] = useState<string>('')

  const brandOptions = brands.sort().map(brand => ({
    label: brand.displayName,
    name: brand.brandName,
    brandId: brand.brandId,
    brandProductCount: parseInt(brand.brandProductCount ?? '0', 10),
    parentCompanyName: brand.displayName.split(' - ')[0],
  }))

  const onBrandClick = (brandId: string) => {
    if (!selectedBrandIds.includes(brandId)) {
      setFieldValue(brandsFieldName, selectedBrandIds.concat([brandId]))
    }
  }
  useEffect(() => {
    if (focusedOptionIndex > -1) {
      ;(
        menuRef?.current?.querySelectorAll('[role=button]')[focusedOptionIndex] as HTMLElement
      )?.focus()
    }
  }, [focusedOptionIndex])

  const filteredBrandOptions = brandOptions.filter(brand => {
    return brand.label.toLowerCase().includes(searchText.toLowerCase())
  })

  return (
    <ClickOutside
      onClickOutside={() => {
        setBrandsExpanded(false)
        setFocusedOptionIndex(-1)
      }}
    >
      <div css={wrapper}>
        <InputSearch
          id="brand-input-search"
          ref={searchBoxRef}
          onChange={e => setSearchText(e.target.value)}
          value={searchText}
          placeholder={formatMessage({
            id: 'pages.displayProduct.common.targetingRule.segment.brands.search',
          })}
          autoComplete="off"
          onClick={() => setBrandsExpanded(true)}
          onFocus={() => setBrandsExpanded(true)}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (brandsExpanded && !!brands.length && e.key === 'ArrowDown') {
              e.preventDefault()
              setFocusedOptionIndex(0)
            }
          }}
          compact
        />
        {brandsExpanded && (
          <div css={menu} ref={menuRef}>
            {!filteredBrandOptions.length ? (
              <div css={noMatch}>
                {formatMessage({
                  id: 'pages.displayProduct.common.targetingRule.segment.brands.empty',
                })}
              </div>
            ) : (
              filteredBrandOptions.map(
                ({ brandId, brandProductCount, name, parentCompanyName }) => (
                  <BrandOption
                    key={brandId}
                    brandId={brandId}
                    brandProductCount={brandProductCount}
                    name={name}
                    parentCompanyName={parentCompanyName}
                    isSelected={selectedBrandIds.includes(brandId)}
                    onClick={onBrandClick}
                    onPressUpArrow={() => {
                      if (focusedOptionIndex === 0) {
                        searchBoxRef?.current?.focus()
                      }
                      setFocusedOptionIndex(Math.max(focusedOptionIndex - 1, -1))
                    }}
                    onPressDownArrow={() =>
                      setFocusedOptionIndex((focusedOptionIndex + 1) % filteredBrandOptions.length)
                    }
                  />
                )
              )
            )}
          </div>
        )}
      </div>
    </ClickOutside>
  )
}
