// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValues } from './GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { GetDisplayCampaignsParamCreativeFieldDecisionsTagline } from './GetDisplayCampaignsParamCreativeFieldDecisionsTagline'
import {
  GetDisplayCampaignsParamCreativeFieldDecisionsTaglineFromJSON,
  GetDisplayCampaignsParamCreativeFieldDecisionsTaglineFromJSONTyped,
  GetDisplayCampaignsParamCreativeFieldDecisionsTaglineToJSON,
} from './GetDisplayCampaignsParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamCreativeFieldDecisions
 */
export interface GetDisplayCampaignsParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof GetDisplayCampaignsParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {GetDisplayCampaignsParamCreativeFieldDecisionsTagline}
   * @memberof GetDisplayCampaignsParamCreativeFieldDecisions
   */
  tagline?: GetDisplayCampaignsParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamCreativeFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsParamCreativeFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsParamCreativeFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsParamCreativeFieldDecisions {
  return GetDisplayCampaignsParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : GetDisplayCampaignsParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function GetDisplayCampaignsParamCreativeFieldDecisionsToJSON(
  value?: GetDisplayCampaignsParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            GetDisplayCampaignsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: GetDisplayCampaignsParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
