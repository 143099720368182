import { css } from '@emotion/react'
import { Text, TrashIcon, Theme, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'
import {
  Subject,
  conditionOptions,
  subjectOptions,
  timelineOptions,
  SegmentType,
  Operator,
} from '../constants'
import { TargetingRuleContext, TargetingSegmentContext } from '../hooks'
import { TargetingFormData } from '../types'
import BrandsView from './BrandsView'
import CategoriesView from './CategoriesView'
import CustomDropdown from './CustomDropdown'
import ProductsView from './ProductsView'

export interface TargetingSegmentProps {
  isFirstSegment: boolean
  removeSegment: () => void
  operator: Operator
}

const segmentContainerStyle = (theme: Theme) =>
  css({
    borderBottom: `1px solid ${theme.colors.systemGrayscale20}`,
    paddingBottom: 12,
    paddingTop: 12,
    '&:first-of-type': {
      paddingTop: 0,
    },
  })

const segmentDivStyle = css({
  display: 'flex',
  alignContent: 'center',
})

const segmentChildDivStyle = css({
  paddingTop: 16,
  display: 'flex',
  alignContent: 'center',
})

const trashIconStyle = css({
  cursor: 'pointer',
  marginLeft: 24,
  marginTop: 48,
})

const segmentHeaderWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const segmentContentDivStyle = css({ width: '95%' })

const labelTextStyle = {
  paddingTop: 4,
  marginRight: 4,
  marginLeft: 4,
  display: 'block',
}

export default function TargetingSegment({
  removeSegment,
  isFirstSegment,
  operator,
}: TargetingSegmentProps) {
  const theme = useTheme()
  const { values } = useFormikContext<TargetingFormData>()
  const { isReadOnly, isReviewing, isProductsTargetingAllowed, brands } =
    useContext(TargetingRuleContext)
  const { fieldsPrefix, segmentType } = useContext(TargetingSegmentContext)

  const subject = get(values, `${fieldsPrefix}subject`) || Subject.CATEGORIES

  return (
    <div css={segmentContainerStyle(theme)} data-testid="targeting-segment">
      {!isFirstSegment && (
        <div css={segmentHeaderWrapper}>
          <Text typography="bodyMedium1">
            <FormattedMessage
              id={
                `pages.displayProduct.common.targetingRule.segment.${operator.toLowerCase()}.title` as MessageIdType
              }
            />
          </Text>
        </div>
      )}
      <div css={segmentDivStyle}>
        <div css={segmentContentDivStyle}>
          <div css={segmentChildDivStyle}>
            <Text typography="bodyMedium2" style={{ ...labelTextStyle, marginLeft: undefined }}>
              <FormattedMessage
                id={
                  `pages.displayProduct.common.targetingRule.segment.${
                    isFirstSegment ? 'first' : operator.toLowerCase()
                  }.inputsPrefix` as MessageIdType
                }
              />
            </Text>
            <CustomDropdown
              fieldName={`${fieldsPrefix}condition`}
              options={conditionOptions(segmentType)}
            />
            {segmentType === SegmentType.INTEREST && (
              <Text typography="bodyMedium2" style={labelTextStyle}>
                <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.interest.subjects.label" />
              </Text>
            )}

            {segmentType === SegmentType.PURCHASE && (
              <Text typography="bodyMedium2" style={labelTextStyle}>
                <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.purchased.subjects.label" />
              </Text>
            )}

            <CustomDropdown
              fieldName={`${fieldsPrefix}subject`}
              options={subjectOptions(!!brands.length, isProductsTargetingAllowed)}
            />

            <Text typography="bodyMedium2" style={labelTextStyle}>
              <FormattedMessage id="pages.displayProduct.common.targetingRule.segment.timeline.label" />
            </Text>

            <CustomDropdown fieldName={`${fieldsPrefix}timeline`} options={timelineOptions} />
          </div>
          <div css={segmentChildDivStyle}>
            {subject === Subject.CATEGORIES && <CategoriesView />}
            {subject === Subject.BRANDS && <BrandsView />}
            {subject === Subject.UPCS && <ProductsView />}
          </div>
        </div>
        {!isReadOnly && !isReviewing && (
          <TrashIcon
            data-testid="delete-targeting-segment-button"
            size={20}
            css={trashIconStyle}
            color="systemGrayscale50"
            onClick={removeSegment}
          />
        )}
      </div>
    </div>
  )
}
