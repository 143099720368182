// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdAppealParamEntities
 */
export interface PutDisplayAdGroupsIdAppealParamEntities {
  /**
   * The entity type
   * @type {string}
   * @memberof PutDisplayAdGroupsIdAppealParamEntities
   */
  entityType: PutDisplayAdGroupsIdAppealParamEntitiesEntityTypeEnum
  /**
   * The entity id
   * @type {string}
   * @memberof PutDisplayAdGroupsIdAppealParamEntities
   */
  entityId: string
  /**
   * A free form reason from the advertiser as to why the decision should be reversed
   * @type {string}
   * @memberof PutDisplayAdGroupsIdAppealParamEntities
   */
  appealReason?: string
}

/**
 * @export
 */
export const PutDisplayAdGroupsIdAppealParamEntitiesEntityTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PutDisplayAdGroupsIdAppealParamEntitiesEntityTypeEnum =
  typeof PutDisplayAdGroupsIdAppealParamEntitiesEntityTypeEnum[keyof typeof PutDisplayAdGroupsIdAppealParamEntitiesEntityTypeEnum]

/**
 * Check if a given object implements the PutDisplayAdGroupsIdAppealParamEntities interface.
 */
export function instanceOfPutDisplayAdGroupsIdAppealParamEntities(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'entityType' in value
  isInstance = isInstance && 'entityId' in value

  return isInstance
}

export function PutDisplayAdGroupsIdAppealParamEntitiesFromJSON(
  json: any
): PutDisplayAdGroupsIdAppealParamEntities {
  return PutDisplayAdGroupsIdAppealParamEntitiesFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdAppealParamEntitiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdAppealParamEntities {
  if (json === undefined || json === null) {
    return json
  }
  return {
    entityType: json['entity_type'],
    entityId: json['entity_id'],
    appealReason: !exists(json, 'appeal_reason') ? undefined : json['appeal_reason'],
  }
}

export function PutDisplayAdGroupsIdAppealParamEntitiesToJSON(
  value?: PutDisplayAdGroupsIdAppealParamEntities | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    entity_type: value.entityType,
    entity_id: value.entityId,
    appeal_reason: value.appealReason,
  }
}
