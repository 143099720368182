import { Color, useTheme } from '@instacart/ids-core'
import { HTMLProps } from 'react'
import toPx from 'common/toPx'
import useIntl from 'common/useIntl'
import { GenericMessageDescriptor } from 'locales/types'

export interface BadgeProps extends HTMLProps<HTMLSpanElement> {
  message: GenericMessageDescriptor
  backgroundColor?: Color
  fontColor?: Color
}

export const badgeTestId = 'badge'

function useStyles({
  backgroundColor = 'systemGrayscale70',
  fontColor = 'systemGrayscale00',
}: Pick<BadgeProps, 'backgroundColor' | 'fontColor'>) {
  const theme = useTheme()

  return {
    root: {
      ...theme.typography.disclaimerMedium,
      display: 'inline-block',
      padding: toPx`${2} ${8} ${3}`,
      borderRadius: 999,
      color: theme.colors[fontColor],
      background: theme.colors[backgroundColor],
    },
  }
}

export function Badge({ message, backgroundColor, fontColor, ...props }: BadgeProps) {
  const styles = useStyles({ backgroundColor, fontColor })
  const { genericFormatMessage } = useIntl()
  const content = genericFormatMessage(message)

  return (
    <span data-testid={badgeTestId} css={styles.root} {...props}>
      {content}
    </span>
  )
}
