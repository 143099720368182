import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useLinkStyles } from 'common'
import FormattedMessage from 'components/FormattedMessage'

interface Props {}

const PrivacyTerms: FunctionComponent<React.PropsWithChildren<Props>> = () => {
  const linkCssStyle = useLinkStyles()
  return (
    <>
      <FormattedMessage id="pages.campaignPayment.legal.privacy" />{' '}
      <Link to="/privacy" css={linkCssStyle}>
        <FormattedMessage id="common.privacyPolicy" />
      </Link>
      {'.'}
    </>
  )
}

export default PrivacyTerms
