import useIntl from 'common/useIntl'
import { useAuthContext, isProviAccount } from 'context'
import { GetAdGroupsResponseDataAttributesUserTargetingBuyerLicenseEnum as BuyerLicenses } from 'service/openapi/__codegen__/models/GetAdGroupsResponseDataAttributesUserTargeting'
import { BUYER_LICENSE_TARGETING_OPTIONS } from './BuyerLicenseTargeting'
import { PROVI_GEOGRAPHIC_TARGETING_OPTIONS } from './ProviGeographicTargetingOptions'

export const useIsAdvancedTargetingEnabledForAccount = () => {
  const auth = useAuthContext()
  return isProviAccount(auth)
}

export const useGetBuyerLicenseTargetingInitialValue = () => {
  const auth = useAuthContext()
  if (isProviAccount(auth)) {
    return [BuyerLicenses.OnPremises, BuyerLicenses.OffPremises, BuyerLicenses.OnAndOffPremises]
  }

  return []
}

export const useBuyerLicenseTargetingReviewValue = (licenses: BuyerLicenses[]) => {
  const { genericFormatMessage: format } = useIntl()
  return BUYER_LICENSE_TARGETING_OPTIONS.filter(option => licenses.includes(option.value))
    .map(option => format(option.labelId))
    .join(', ')
}

export const useGetGeographicTargetingOptions = () => {
  const auth = useAuthContext()
  if (isProviAccount(auth)) {
    return PROVI_GEOGRAPHIC_TARGETING_OPTIONS
  }

  return []
}
