import {
  PostProductImagesResponseDataAttributes,
  PostProductImagesResponseDataAttributesImageStateEnum,
} from 'service/openapi/__codegen__/models/PostProductImagesResponseDataAttributes'
import { Product } from 'service/types'

export type ProductImageStats = PostProductImagesResponseDataAttributes
export { PostProductImagesResponseDataAttributesImageStateEnum as ProductImageCoverage }

export type ProductEx = Product & {
  // Represents if a Product is enabled or paused or in an Ad Group
  enabled: boolean
  // Represents if a Product is saved to an Ad Group
  existing: boolean
  // Represents if an Product is a draft / committed draft to an Ad Group
  selected: boolean
  // Represents the first product in the list.
  isFirstProduct: boolean
  // Represents if an update request was submitted for this product.
  productUpdateSubmitted: boolean

  // If set user will be propted to update the image for this product
  imageState?: ProductImageStats
}

// eslint-disable-next-line no-restricted-syntax
export enum ProductsTableVariants {
  SEARCH = 'searchResultProducts',
  SELECTED = 'selectedProducts',
  PREVIEW = 'previewProducts',
}

// eslint-disable-next-line no-restricted-syntax
export enum DraftProductSources {
  VIA_UPC = 'add_upc',
  VIA_SEARCH = 'search_products',
}

// eslint-disable-next-line no-restricted-syntax
export enum ProductsTableGroupKeys {
  SELECTED = 'selected',
  ACTIVE = 'active',
  PAUSED = 'paused',
}
