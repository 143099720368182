// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogo } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogo'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogoToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogo'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImage } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImage'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImageToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImage'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobile } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobile'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobileToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobile'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWeb } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWeb'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWebToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWeb'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnail } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnail'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnailToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnail'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobile } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobile'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobile'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWeb } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWeb'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWebToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWeb'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobile } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobile'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobileFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobileToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobile'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWeb } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWeb'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWebFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWebToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWeb'
import type { GetVideoAdGroupsParamDataAttributesCreativeAssetsVideo } from './GetVideoAdGroupsParamDataAttributesCreativeAssetsVideo'
import {
  GetVideoAdGroupsParamDataAttributesCreativeAssetsVideoFromJSON,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsVideoFromJSONTyped,
  GetVideoAdGroupsParamDataAttributesCreativeAssetsVideoToJSON,
} from './GetVideoAdGroupsParamDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDataAttributesCreativeAssets
 */
export interface GetVideoAdGroupsParamDataAttributesCreativeAssets {
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWeb}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  topImageWeb?: GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWeb}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  imageWeb?: GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobile}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  imageMobile?: GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobile}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  topImageMobile?: GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  squareImageMobile?: GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImage}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  heroImage?: GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogo}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  brandLogo?: GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsVideo}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  video?: GetVideoAdGroupsParamDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnail}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  thumbnail?: GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWeb}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  uvcImageWeb?: GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobile}
   * @memberof GetVideoAdGroupsParamDataAttributesCreativeAssets
   */
  uvcImageMobile?: GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamDataAttributesCreativeAssets interface.
 */
export function instanceOfGetVideoAdGroupsParamDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetVideoAdGroupsParamDataAttributesCreativeAssetsFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesCreativeAssets {
  return GetVideoAdGroupsParamDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSON(json['top_image_web']),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSON(json['image_mobile']),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSON(json['hero_image']),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSON(json['brand_logo']),
    video: !exists(json, 'video')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWebFromJSON(json['uvc_image_web']),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function GetVideoAdGroupsParamDataAttributesCreativeAssetsToJSON(
  value?: GetVideoAdGroupsParamDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: GetVideoAdGroupsParamDataAttributesCreativeAssetsImageWebToJSON(value.imageWeb),
    image_mobile: GetVideoAdGroupsParamDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: GetVideoAdGroupsParamDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile: GetVideoAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileToJSON(
      value.squareImageMobile
    ),
    hero_image: GetVideoAdGroupsParamDataAttributesCreativeAssetsHeroImageToJSON(value.heroImage),
    brand_logo: GetVideoAdGroupsParamDataAttributesCreativeAssetsBrandLogoToJSON(value.brandLogo),
    video: GetVideoAdGroupsParamDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: GetVideoAdGroupsParamDataAttributesCreativeAssetsThumbnailToJSON(value.thumbnail),
    uvc_image_web: GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageWebToJSON(
      value.uvcImageWeb
    ),
    uvc_image_mobile: GetVideoAdGroupsParamDataAttributesCreativeAssetsUvcImageMobileToJSON(
      value.uvcImageMobile
    ),
  }
}
