// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabels } from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabels'
import {
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabelsFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabelsFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabelsToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabels'

/**
 *
 * @export
 * @interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
 */
export interface PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle {
  /**
   * The approval decision for the component
   * @type {string}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
   */
  decision?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleDecisionEnum
  /**
   * List of labels applied
   * @type {Array<PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabels>}
   * @memberof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
   */
  labels?: Array<PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabels>
}

/**
 * @export
 */
export const PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleDecisionEnum =
  typeof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleDecisionEnum[keyof typeof PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleDecisionEnum]

/**
 * Check if a given object implements the PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle interface.
 */
export function instanceOfPutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON(
  json: any
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle {
  return PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped(
    json,
    false
  )
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(
          PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabelsFromJSON
        ),
  }
}

export function PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON(
  value?: PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(
            PutReservationAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleLabelsToJSON
          ),
  }
}
