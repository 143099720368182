// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsParamBlockHeroBannerDisplay,
  instanceOfGetDisplayCampaignsParamBlockHeroBannerDisplay,
  GetDisplayCampaignsParamBlockHeroBannerDisplayFromJSON,
  GetDisplayCampaignsParamBlockHeroBannerDisplayFromJSONTyped,
  GetDisplayCampaignsParamBlockHeroBannerDisplayToJSON,
} from './GetDisplayCampaignsParamBlockHeroBannerDisplay'
import {
  GetDisplayCampaignsParamBlockItemGridDisplay,
  instanceOfGetDisplayCampaignsParamBlockItemGridDisplay,
  GetDisplayCampaignsParamBlockItemGridDisplayFromJSON,
  GetDisplayCampaignsParamBlockItemGridDisplayFromJSONTyped,
  GetDisplayCampaignsParamBlockItemGridDisplayToJSON,
} from './GetDisplayCampaignsParamBlockItemGridDisplay'

/**
 * @type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks
 *
 * @export
 */
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & GetDisplayCampaignsParamBlockHeroBannerDisplay)
  | ({ discriminator: 'block_item_grid.display.v1' } & GetDisplayCampaignsParamBlockItemGridDisplay)

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...GetDisplayCampaignsParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...GetDisplayCampaignsParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return GetDisplayCampaignsParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return GetDisplayCampaignsParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
