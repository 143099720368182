// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAccountsControllerUpdatePaymentInfoInput
 */
export interface ApiAccountsControllerUpdatePaymentInfoInput {
  /**
   * Default memo (attention to) for the account
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  defaultMemo?: string
  /**
   * Default payment type for the account
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  defaultPaymentType?: string
  /**
   * Default bill payer for the account
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  billPayer?: string
  /**
   * Account invoicing type, if provided must be one of: instacart_invoicing, credit_card
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  paymentOption?: ApiAccountsControllerUpdatePaymentInfoInputPaymentOptionEnum
  /**
   * Invoice option must be one of: email, portal
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  invoiceOption?: ApiAccountsControllerUpdatePaymentInfoInputInvoiceOptionEnum
  /**
   * Payment term in days must be one of: 30, 45, 60, 75, 90, 120
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  paymentTermDays?: ApiAccountsControllerUpdatePaymentInfoInputPaymentTermDaysEnum
  /**
   * Comma separated list of emails for email invoicing
   * @type {string}
   * @memberof ApiAccountsControllerUpdatePaymentInfoInput
   */
  emailDistribution?: string
}

/**
 * @export
 */
export const ApiAccountsControllerUpdatePaymentInfoInputPaymentOptionEnum = {
  InstacartInvoicing: 'instacart_invoicing',
  CreditCard: 'credit_card',
} as const
export type ApiAccountsControllerUpdatePaymentInfoInputPaymentOptionEnum =
  typeof ApiAccountsControllerUpdatePaymentInfoInputPaymentOptionEnum[keyof typeof ApiAccountsControllerUpdatePaymentInfoInputPaymentOptionEnum]

/**
 * @export
 */
export const ApiAccountsControllerUpdatePaymentInfoInputInvoiceOptionEnum = {
  Email: 'email',
  Portal: 'portal',
} as const
export type ApiAccountsControllerUpdatePaymentInfoInputInvoiceOptionEnum =
  typeof ApiAccountsControllerUpdatePaymentInfoInputInvoiceOptionEnum[keyof typeof ApiAccountsControllerUpdatePaymentInfoInputInvoiceOptionEnum]

/**
 * @export
 */
export const ApiAccountsControllerUpdatePaymentInfoInputPaymentTermDaysEnum = {
  _30: '30',
  _45: '45',
  _60: '60',
  _75: '75',
  _90: '90',
  _120: '120',
} as const
export type ApiAccountsControllerUpdatePaymentInfoInputPaymentTermDaysEnum =
  typeof ApiAccountsControllerUpdatePaymentInfoInputPaymentTermDaysEnum[keyof typeof ApiAccountsControllerUpdatePaymentInfoInputPaymentTermDaysEnum]

/**
 * Check if a given object implements the ApiAccountsControllerUpdatePaymentInfoInput interface.
 */
export function instanceOfApiAccountsControllerUpdatePaymentInfoInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAccountsControllerUpdatePaymentInfoInputFromJSON(
  json: any
): ApiAccountsControllerUpdatePaymentInfoInput {
  return ApiAccountsControllerUpdatePaymentInfoInputFromJSONTyped(json, false)
}

export function ApiAccountsControllerUpdatePaymentInfoInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAccountsControllerUpdatePaymentInfoInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    defaultMemo: !exists(json, 'default_memo') ? undefined : json['default_memo'],
    defaultPaymentType: !exists(json, 'default_payment_type')
      ? undefined
      : json['default_payment_type'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    paymentOption: !exists(json, 'payment_option') ? undefined : json['payment_option'],
    invoiceOption: !exists(json, 'invoice_option') ? undefined : json['invoice_option'],
    paymentTermDays: !exists(json, 'payment_term_days') ? undefined : json['payment_term_days'],
    emailDistribution: !exists(json, 'email_distribution') ? undefined : json['email_distribution'],
  }
}

export function ApiAccountsControllerUpdatePaymentInfoInputToJSON(
  value?: ApiAccountsControllerUpdatePaymentInfoInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    default_memo: value.defaultMemo,
    default_payment_type: value.defaultPaymentType,
    bill_payer: value.billPayer,
    payment_option: value.paymentOption,
    invoice_option: value.invoiceOption,
    payment_term_days: value.paymentTermDays,
    email_distribution: value.emailDistribution,
  }
}
