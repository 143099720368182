// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockSpotlightProductCollectionContent } from './GetBrandPagesParamBlockSpotlightProductCollectionContent'
import {
  GetBrandPagesParamBlockSpotlightProductCollectionContentFromJSON,
  GetBrandPagesParamBlockSpotlightProductCollectionContentFromJSONTyped,
  GetBrandPagesParamBlockSpotlightProductCollectionContentToJSON,
} from './GetBrandPagesParamBlockSpotlightProductCollectionContent'
import type { GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues } from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues'
import {
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSONTyped,
  GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON,
} from './GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockSpotlightProductCollection
 */
export interface GetBrandPagesParamBlockSpotlightProductCollection {
  /**
   * id of the block
   * @type {string}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  type: GetBrandPagesParamBlockSpotlightProductCollectionTypeEnum
  /**
   *
   * @type {GetBrandPagesParamBlockSpotlightProductCollectionContent}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  content: GetBrandPagesParamBlockSpotlightProductCollectionContent
  /**
   * Unordered collection of products for display
   * @type {Array<string>}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  productIds?: Array<string>
  /**
   * Map of product id to its corresponding assets
   * @type {{ [key: string]: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues; }}
   * @memberof GetBrandPagesParamBlockSpotlightProductCollection
   */
  productAssets?: {
    [key: string]: GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValues
  }
}

/**
 * @export
 */
export const GetBrandPagesParamBlockSpotlightProductCollectionTypeEnum = {
  SpotlightProductCollectionEvergreenV1: 'spotlight_product_collection.evergreen.v1',
} as const
export type GetBrandPagesParamBlockSpotlightProductCollectionTypeEnum =
  typeof GetBrandPagesParamBlockSpotlightProductCollectionTypeEnum[keyof typeof GetBrandPagesParamBlockSpotlightProductCollectionTypeEnum]

/**
 * Check if a given object implements the GetBrandPagesParamBlockSpotlightProductCollection interface.
 */
export function instanceOfGetBrandPagesParamBlockSpotlightProductCollection(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'content' in value

  return isInstance
}

export function GetBrandPagesParamBlockSpotlightProductCollectionFromJSON(
  json: any
): GetBrandPagesParamBlockSpotlightProductCollection {
  return GetBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockSpotlightProductCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    content: GetBrandPagesParamBlockSpotlightProductCollectionContentFromJSON(json['content']),
    productIds: !exists(json, 'product_ids') ? undefined : json['product_ids'],
    productAssets: !exists(json, 'product_assets')
      ? undefined
      : mapValues(
          json['product_assets'],
          GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesFromJSON
        ),
  }
}

export function GetBrandPagesParamBlockSpotlightProductCollectionToJSON(
  value?: GetBrandPagesParamBlockSpotlightProductCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    content: GetBrandPagesParamBlockSpotlightProductCollectionContentToJSON(value.content),
    product_ids: value.productIds,
    product_assets:
      value.productAssets === undefined
        ? undefined
        : mapValues(
            value.productAssets,
            GetBrandPagesParamBlockSpotlightProductCollectionProductAssetsValuesToJSON
          ),
  }
}
