// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetBrandPagesParamBlockBrandLogo,
  instanceOfGetBrandPagesParamBlockBrandLogo,
  GetBrandPagesParamBlockBrandLogoFromJSON,
  GetBrandPagesParamBlockBrandLogoFromJSONTyped,
  GetBrandPagesParamBlockBrandLogoToJSON,
} from './GetBrandPagesParamBlockBrandLogo'
import {
  GetBrandPagesParamBlockHeroBanner,
  instanceOfGetBrandPagesParamBlockHeroBanner,
  GetBrandPagesParamBlockHeroBannerFromJSON,
  GetBrandPagesParamBlockHeroBannerFromJSONTyped,
  GetBrandPagesParamBlockHeroBannerToJSON,
} from './GetBrandPagesParamBlockHeroBanner'
import {
  GetBrandPagesParamBlockImageAndText,
  instanceOfGetBrandPagesParamBlockImageAndText,
  GetBrandPagesParamBlockImageAndTextFromJSON,
  GetBrandPagesParamBlockImageAndTextFromJSONTyped,
  GetBrandPagesParamBlockImageAndTextToJSON,
} from './GetBrandPagesParamBlockImageAndText'
import {
  GetBrandPagesParamBlockProductCollection,
  instanceOfGetBrandPagesParamBlockProductCollection,
  GetBrandPagesParamBlockProductCollectionFromJSON,
  GetBrandPagesParamBlockProductCollectionFromJSONTyped,
  GetBrandPagesParamBlockProductCollectionToJSON,
} from './GetBrandPagesParamBlockProductCollection'
import {
  GetBrandPagesParamBlockProductGroupCollection,
  instanceOfGetBrandPagesParamBlockProductGroupCollection,
  GetBrandPagesParamBlockProductGroupCollectionFromJSON,
  GetBrandPagesParamBlockProductGroupCollectionFromJSONTyped,
  GetBrandPagesParamBlockProductGroupCollectionToJSON,
} from './GetBrandPagesParamBlockProductGroupCollection'
import {
  GetBrandPagesParamBlockSectionBanner,
  instanceOfGetBrandPagesParamBlockSectionBanner,
  GetBrandPagesParamBlockSectionBannerFromJSON,
  GetBrandPagesParamBlockSectionBannerFromJSONTyped,
  GetBrandPagesParamBlockSectionBannerToJSON,
} from './GetBrandPagesParamBlockSectionBanner'
import {
  GetBrandPagesParamBlockSpotlightProductCollection,
  instanceOfGetBrandPagesParamBlockSpotlightProductCollection,
  GetBrandPagesParamBlockSpotlightProductCollectionFromJSON,
  GetBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped,
  GetBrandPagesParamBlockSpotlightProductCollectionToJSON,
} from './GetBrandPagesParamBlockSpotlightProductCollection'

/**
 * @type GetBrandPagesParamDataAttributesBlocks
 *
 * @export
 */
export type GetBrandPagesParamDataAttributesBlocks =
  | ({ discriminator: 'brand_logo.evergreen.v1' } & GetBrandPagesParamBlockBrandLogo)
  | ({ discriminator: 'hero_banner.evergreen.v1' } & GetBrandPagesParamBlockHeroBanner)
  | ({ discriminator: 'image_and_text.evergreen.v1' } & GetBrandPagesParamBlockImageAndText)
  | ({
      discriminator: 'product_collection.evergreen.v1'
    } & GetBrandPagesParamBlockProductCollection)
  | ({
      discriminator: 'product_group_collection.evergreen.v1'
    } & GetBrandPagesParamBlockProductGroupCollection)
  | ({ discriminator: 'section_banner.evergreen.v1' } & GetBrandPagesParamBlockSectionBanner)
  | ({
      discriminator: 'spotlight_product_collection.evergreen.v1'
    } & GetBrandPagesParamBlockSpotlightProductCollection)

export function GetBrandPagesParamDataAttributesBlocksFromJSON(
  json: any
): GetBrandPagesParamDataAttributesBlocks {
  return GetBrandPagesParamDataAttributesBlocksFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockBrandLogoFromJSONTyped(json, true),
        discriminator: 'brand_logo.evergreen.v1',
      }
    case 'hero_banner.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockHeroBannerFromJSONTyped(json, true),
        discriminator: 'hero_banner.evergreen.v1',
      }
    case 'image_and_text.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockImageAndTextFromJSONTyped(json, true),
        discriminator: 'image_and_text.evergreen.v1',
      }
    case 'product_collection.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockProductCollectionFromJSONTyped(json, true),
        discriminator: 'product_collection.evergreen.v1',
      }
    case 'product_group_collection.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockProductGroupCollectionFromJSONTyped(json, true),
        discriminator: 'product_group_collection.evergreen.v1',
      }
    case 'section_banner.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockSectionBannerFromJSONTyped(json, true),
        discriminator: 'section_banner.evergreen.v1',
      }
    case 'spotlight_product_collection.evergreen.v1':
      return {
        ...GetBrandPagesParamBlockSpotlightProductCollectionFromJSONTyped(json, true),
        discriminator: 'spotlight_product_collection.evergreen.v1',
      }
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function GetBrandPagesParamDataAttributesBlocksToJSON(
  value?: GetBrandPagesParamDataAttributesBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return GetBrandPagesParamBlockBrandLogoToJSON(value)
    case 'hero_banner.evergreen.v1':
      return GetBrandPagesParamBlockHeroBannerToJSON(value)
    case 'image_and_text.evergreen.v1':
      return GetBrandPagesParamBlockImageAndTextToJSON(value)
    case 'product_collection.evergreen.v1':
      return GetBrandPagesParamBlockProductCollectionToJSON(value)
    case 'product_group_collection.evergreen.v1':
      return GetBrandPagesParamBlockProductGroupCollectionToJSON(value)
    case 'section_banner.evergreen.v1':
      return GetBrandPagesParamBlockSectionBannerToJSON(value)
    case 'spotlight_product_collection.evergreen.v1':
      return GetBrandPagesParamBlockSpotlightProductCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of GetBrandPagesParamDataAttributesBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
