// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions } from './GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions'
import {
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON,
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped,
  GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON,
} from './GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions'

/**
 *
 * @export
 * @interface GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot
 */
export interface GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot {
  /**
   * The approval decision for the entity
   * @type {string}
   * @memberof GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot
   */
  decision?: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum
  /**
   *
   * @type {GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions}
   * @memberof GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot
   */
  fieldDecisions?: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
}

/**
 * @export
 */
export const GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum = {
  Unknown: 'DECISION_UNKNOWN',
  Approved: 'DECISION_APPROVED',
  Rejected: 'DECISION_REJECTED',
  ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
} as const
export type GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum =
  typeof GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum[keyof typeof GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotDecisionEnum]

/**
 * Check if a given object implements the GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot interface.
 */
export function instanceOfGetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
  json: any
): GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot {
  return GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped(json, false)
}

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    fieldDecisions: !exists(json, 'field_decisions')
      ? undefined
      : GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
          json['field_decisions']
        ),
  }
}

export function GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
  value?: GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    field_decisions:
      GetBrandPagesParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
        value.fieldDecisions
      ),
  }
}
