// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AppealBrandPagesResponseMeta } from './AppealBrandPagesResponseMeta'
import {
  AppealBrandPagesResponseMetaFromJSON,
  AppealBrandPagesResponseMetaFromJSONTyped,
  AppealBrandPagesResponseMetaToJSON,
} from './AppealBrandPagesResponseMeta'

/**
 *
 * @export
 * @interface AppealBrandPagesResponse
 */
export interface AppealBrandPagesResponse {
  /**
   * N/A
   * @type {{ [key: string]: any; }}
   * @memberof AppealBrandPagesResponse
   */
  data: { [key: string]: any }
  /**
   *
   * @type {AppealBrandPagesResponseMeta}
   * @memberof AppealBrandPagesResponse
   */
  meta: AppealBrandPagesResponseMeta
}

/**
 * Check if a given object implements the AppealBrandPagesResponse interface.
 */
export function instanceOfAppealBrandPagesResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AppealBrandPagesResponseFromJSON(json: any): AppealBrandPagesResponse {
  return AppealBrandPagesResponseFromJSONTyped(json, false)
}

export function AppealBrandPagesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppealBrandPagesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: json['data'],
    meta: AppealBrandPagesResponseMetaFromJSON(json['meta']),
  }
}

export function AppealBrandPagesResponseToJSON(value?: AppealBrandPagesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data,
    meta: AppealBrandPagesResponseMetaToJSON(value.meta),
  }
}
