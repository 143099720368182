// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { TaskInProgressResponse } from '../models/TaskInProgressResponse'
import {
  TaskInProgressResponseFromJSON,
  TaskInProgressResponseToJSON,
} from '../models/TaskInProgressResponse'
import type { TaskResponse } from '../models/TaskResponse'
import { TaskResponseFromJSON, TaskResponseToJSON } from '../models/TaskResponse'

export interface GetTasksIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

/**
 *
 */
export class TasksApi extends runtime.BaseAPI {
  /**
   * This method returns details about a specific task, including its type, status and payload or error if it is completed or failed. You need to specify the id of the task — which was returned by endpoint that you called to initiate the task.
   * Retrieve a task
   */
  async getTasksIdRaw(
    requestParameters: GetTasksIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TaskResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getTasksId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/tasks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => TaskResponseFromJSON(jsonValue))
  }

  /**
   * This method returns details about a specific task, including its type, status and payload or error if it is completed or failed. You need to specify the id of the task — which was returned by endpoint that you called to initiate the task.
   * Retrieve a task
   */
  async getTasksId(
    requestParameters: GetTasksIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TaskResponse> {
    const response = await this.getTasksIdRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
