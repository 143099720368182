import { ADS_API_PATH } from 'common/constants'
import Client from 'service/client'
import { KnowledgeOwlWidgetClientInterface, GetKnowledgeOwlWidgetResponse } from 'service/types'

class KnowledgeOwlWidgetClientClass extends Client implements KnowledgeOwlWidgetClientInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/knowledge_owl`
  }

  getConfig = (): Promise<GetKnowledgeOwlWidgetResponse> => {
    return this.performRequest({
      method: 'get',
      path: `/authenticate`,
    })
  }
}

/* A singleton is exported to prevent multiple instances
 * from existing.
 *
 * If the code is being run in stroybook return empty object
 * to avoid errors that occur when initializing the class in
 * in the storybook environment.
 */

const shouldReturnMockClient = () => {
  try {
    if (window) {
      return Boolean(window.STORYBOOK_ENV)
    }
  } catch (e) {
    return false
  }
  return false
}

export const KnowledgeOwlWidgetClient = !shouldReturnMockClient()
  ? new KnowledgeOwlWidgetClientClass()
  : { getConfig: () => ({ data: { attributes: null } }) }
