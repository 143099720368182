import { ADS_API_PATH } from 'common/constants'
import { AccountsApi } from 'service/apis/AccountsApi'
import Client from 'service/client'
import { getEnumValueFromString } from 'service/mappings'
import { GetAccountsIdAnalyticsMetricEnum } from 'service/openapi/__codegen__/apis/AccountsApi'
import { GetAnalyticsOptions } from 'service/shared'
import { AccountClientInterface } from 'service/types'

export default class OpenAPIAccountClient extends Client implements AccountClientInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/accounts`
  }

  getAccount = () => {
    return AccountsApi.getAccount({})
  }

  getAccounts = () => {
    return AccountsApi.getAccounts({})
  }

  documentURI = (filename: string, accountId?: string): string => {
    return this.getDownloadURI(
      {
        url: `${this.ADS_API_URL}api/account/document`,
        params: { filename },
      },
      accountId
    )
  }

  getAccountAnalytics = (accountId: string, options: GetAnalyticsOptions) => {
    return AccountsApi.getAccountsIdAnalytics({
      dateRangeStartDate: options.dateRange.startDate,
      dateRangeEndDate: options.dateRange.endDate,
      metric: getEnumValueFromString(GetAccountsIdAnalyticsMetricEnum, options.metric),
      id: accountId,
    })
  }

  getAccountsSearch = (query: string, page?: string) => {
    return AccountsApi.getAccountsSearch({ query, page })
  }
}
