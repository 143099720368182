// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetVideoAdGroupsParamBrandPageBlockSnapshot,
  instanceOfGetVideoAdGroupsParamBrandPageBlockSnapshot,
  GetVideoAdGroupsParamBrandPageBlockSnapshotFromJSON,
  GetVideoAdGroupsParamBrandPageBlockSnapshotFromJSONTyped,
  GetVideoAdGroupsParamBrandPageBlockSnapshotToJSON,
} from './GetVideoAdGroupsParamBrandPageBlockSnapshot'
import {
  GetVideoAdGroupsParamBrandPageSnapshot,
  instanceOfGetVideoAdGroupsParamBrandPageSnapshot,
  GetVideoAdGroupsParamBrandPageSnapshotFromJSON,
  GetVideoAdGroupsParamBrandPageSnapshotFromJSONTyped,
  GetVideoAdGroupsParamBrandPageSnapshotToJSON,
} from './GetVideoAdGroupsParamBrandPageSnapshot'
import {
  GetVideoAdGroupsParamCreativeSnapshot,
  instanceOfGetVideoAdGroupsParamCreativeSnapshot,
  GetVideoAdGroupsParamCreativeSnapshotFromJSON,
  GetVideoAdGroupsParamCreativeSnapshotFromJSONTyped,
  GetVideoAdGroupsParamCreativeSnapshotToJSON,
} from './GetVideoAdGroupsParamCreativeSnapshot'
import {
  GetVideoAdGroupsParamDisplayAdGroupSnapshot,
  instanceOfGetVideoAdGroupsParamDisplayAdGroupSnapshot,
  GetVideoAdGroupsParamDisplayAdGroupSnapshotFromJSON,
  GetVideoAdGroupsParamDisplayAdGroupSnapshotFromJSONTyped,
  GetVideoAdGroupsParamDisplayAdGroupSnapshotToJSON,
} from './GetVideoAdGroupsParamDisplayAdGroupSnapshot'
import {
  GetVideoAdGroupsParamDisplayAssetSnapshot,
  instanceOfGetVideoAdGroupsParamDisplayAssetSnapshot,
  GetVideoAdGroupsParamDisplayAssetSnapshotFromJSON,
  GetVideoAdGroupsParamDisplayAssetSnapshotFromJSONTyped,
  GetVideoAdGroupsParamDisplayAssetSnapshotToJSON,
} from './GetVideoAdGroupsParamDisplayAssetSnapshot'
import {
  GetVideoAdGroupsParamDisplayProductAssetSnapshot,
  instanceOfGetVideoAdGroupsParamDisplayProductAssetSnapshot,
  GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSON,
  GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped,
  GetVideoAdGroupsParamDisplayProductAssetSnapshotToJSON,
} from './GetVideoAdGroupsParamDisplayProductAssetSnapshot'

/**
 * @type GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & GetVideoAdGroupsParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & GetVideoAdGroupsParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetVideoAdGroupsParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & GetVideoAdGroupsParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetVideoAdGroupsParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & GetVideoAdGroupsParamDisplayProductAssetSnapshot)

export function GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot {
  return GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetVideoAdGroupsParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetVideoAdGroupsParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetVideoAdGroupsParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return { ...GetVideoAdGroupsParamCreativeSnapshotFromJSONTyped(json, true), type: 'CREATIVE' }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetVideoAdGroupsParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetVideoAdGroupsParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return GetVideoAdGroupsParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetVideoAdGroupsParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return GetVideoAdGroupsParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetVideoAdGroupsParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return GetVideoAdGroupsParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of GetVideoAdGroupsParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
