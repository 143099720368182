// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PostBrandPagesIdStatusParamBlockBrandLogo,
  instanceOfPostBrandPagesIdStatusParamBlockBrandLogo,
  PostBrandPagesIdStatusParamBlockBrandLogoFromJSON,
  PostBrandPagesIdStatusParamBlockBrandLogoFromJSONTyped,
  PostBrandPagesIdStatusParamBlockBrandLogoToJSON,
} from './PostBrandPagesIdStatusParamBlockBrandLogo'
import {
  PostBrandPagesIdStatusParamBlockHeroBanner,
  instanceOfPostBrandPagesIdStatusParamBlockHeroBanner,
  PostBrandPagesIdStatusParamBlockHeroBannerFromJSON,
  PostBrandPagesIdStatusParamBlockHeroBannerFromJSONTyped,
  PostBrandPagesIdStatusParamBlockHeroBannerToJSON,
} from './PostBrandPagesIdStatusParamBlockHeroBanner'
import {
  PostBrandPagesIdStatusParamBlockImageAndText,
  instanceOfPostBrandPagesIdStatusParamBlockImageAndText,
  PostBrandPagesIdStatusParamBlockImageAndTextFromJSON,
  PostBrandPagesIdStatusParamBlockImageAndTextFromJSONTyped,
  PostBrandPagesIdStatusParamBlockImageAndTextToJSON,
} from './PostBrandPagesIdStatusParamBlockImageAndText'
import {
  PostBrandPagesIdStatusParamBlockProductCollection,
  instanceOfPostBrandPagesIdStatusParamBlockProductCollection,
  PostBrandPagesIdStatusParamBlockProductCollectionFromJSON,
  PostBrandPagesIdStatusParamBlockProductCollectionFromJSONTyped,
  PostBrandPagesIdStatusParamBlockProductCollectionToJSON,
} from './PostBrandPagesIdStatusParamBlockProductCollection'
import {
  PostBrandPagesIdStatusParamBlockProductGroupCollection,
  instanceOfPostBrandPagesIdStatusParamBlockProductGroupCollection,
  PostBrandPagesIdStatusParamBlockProductGroupCollectionFromJSON,
  PostBrandPagesIdStatusParamBlockProductGroupCollectionFromJSONTyped,
  PostBrandPagesIdStatusParamBlockProductGroupCollectionToJSON,
} from './PostBrandPagesIdStatusParamBlockProductGroupCollection'
import {
  PostBrandPagesIdStatusParamBlockSectionBanner,
  instanceOfPostBrandPagesIdStatusParamBlockSectionBanner,
  PostBrandPagesIdStatusParamBlockSectionBannerFromJSON,
  PostBrandPagesIdStatusParamBlockSectionBannerFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSectionBannerToJSON,
} from './PostBrandPagesIdStatusParamBlockSectionBanner'
import {
  PostBrandPagesIdStatusParamBlockSpotlightProductCollection,
  instanceOfPostBrandPagesIdStatusParamBlockSpotlightProductCollection,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionFromJSON,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSpotlightProductCollectionToJSON,
} from './PostBrandPagesIdStatusParamBlockSpotlightProductCollection'

/**
 * @type PostBrandPagesIdStatusParamDataAttributesBlocks
 *
 * @export
 */
export type PostBrandPagesIdStatusParamDataAttributesBlocks =
  | ({ discriminator: 'brand_logo.evergreen.v1' } & PostBrandPagesIdStatusParamBlockBrandLogo)
  | ({ discriminator: 'hero_banner.evergreen.v1' } & PostBrandPagesIdStatusParamBlockHeroBanner)
  | ({
      discriminator: 'image_and_text.evergreen.v1'
    } & PostBrandPagesIdStatusParamBlockImageAndText)
  | ({
      discriminator: 'product_collection.evergreen.v1'
    } & PostBrandPagesIdStatusParamBlockProductCollection)
  | ({
      discriminator: 'product_group_collection.evergreen.v1'
    } & PostBrandPagesIdStatusParamBlockProductGroupCollection)
  | ({
      discriminator: 'section_banner.evergreen.v1'
    } & PostBrandPagesIdStatusParamBlockSectionBanner)
  | ({
      discriminator: 'spotlight_product_collection.evergreen.v1'
    } & PostBrandPagesIdStatusParamBlockSpotlightProductCollection)

export function PostBrandPagesIdStatusParamDataAttributesBlocksFromJSON(
  json: any
): PostBrandPagesIdStatusParamDataAttributesBlocks {
  return PostBrandPagesIdStatusParamDataAttributesBlocksFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamDataAttributesBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamDataAttributesBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockBrandLogoFromJSONTyped(json, true),
        discriminator: 'brand_logo.evergreen.v1',
      }
    case 'hero_banner.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockHeroBannerFromJSONTyped(json, true),
        discriminator: 'hero_banner.evergreen.v1',
      }
    case 'image_and_text.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockImageAndTextFromJSONTyped(json, true),
        discriminator: 'image_and_text.evergreen.v1',
      }
    case 'product_collection.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockProductCollectionFromJSONTyped(json, true),
        discriminator: 'product_collection.evergreen.v1',
      }
    case 'product_group_collection.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockProductGroupCollectionFromJSONTyped(json, true),
        discriminator: 'product_group_collection.evergreen.v1',
      }
    case 'section_banner.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockSectionBannerFromJSONTyped(json, true),
        discriminator: 'section_banner.evergreen.v1',
      }
    case 'spotlight_product_collection.evergreen.v1':
      return {
        ...PostBrandPagesIdStatusParamBlockSpotlightProductCollectionFromJSONTyped(json, true),
        discriminator: 'spotlight_product_collection.evergreen.v1',
      }
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function PostBrandPagesIdStatusParamDataAttributesBlocksToJSON(
  value?: PostBrandPagesIdStatusParamDataAttributesBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'brand_logo.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockBrandLogoToJSON(value)
    case 'hero_banner.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockHeroBannerToJSON(value)
    case 'image_and_text.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockImageAndTextToJSON(value)
    case 'product_collection.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockProductCollectionToJSON(value)
    case 'product_group_collection.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockProductGroupCollectionToJSON(value)
    case 'section_banner.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockSectionBannerToJSON(value)
    case 'spotlight_product_collection.evergreen.v1':
      return PostBrandPagesIdStatusParamBlockSpotlightProductCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of PostBrandPagesIdStatusParamDataAttributesBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
