// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions } from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON,
} from './PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot
 */
export interface PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot {
  /**
   * The approval decision for the entity
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot
   */
  decision?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotDecisionEnum
  /**
   *
   * @type {PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions}
   * @memberof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot
   */
  fieldDecisions?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions
}

/**
 * @export
 */
export const PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotDecisionEnum =
  {
    Unknown: 'DECISION_UNKNOWN',
    Approved: 'DECISION_APPROVED',
    Rejected: 'DECISION_REJECTED',
    ApprovedWithRestriction: 'DECISION_APPROVED_WITH_RESTRICTION',
  } as const
export type PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotDecisionEnum =
  typeof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotDecisionEnum[keyof typeof PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotDecisionEnum]

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot interface.
 */
export function instanceOfPutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSON(
  json: any
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot {
  return PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    decision: !exists(json, 'decision') ? undefined : json['decision'],
    fieldDecisions: !exists(json, 'field_decisions')
      ? undefined
      : PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
          json['field_decisions']
        ),
  }
}

export function PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotToJSON(
  value?: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    decision: value.decision,
    field_decisions:
      PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
        value.fieldDecisions
      ),
  }
}
