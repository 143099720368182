// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop } from './GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop'
import {
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop'
import type { GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile } from './GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile'
import {
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets
 */
export interface GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets
   */
  desktop: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets
   */
  mobile: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfGetPrototypeCampaignsParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  return GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopFromJSON(json['desktop']),
    mobile: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileFromJSON(json['mobile']),
  }
}

export function GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsToJSON(
  value?: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsDesktopToJSON(value.desktop),
    mobile: GetPrototypeCampaignsParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
