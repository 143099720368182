import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { Form, Formik } from 'formik'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { useIntl } from 'common'
import { breakFormattingHelper } from 'common/intlUtils'
import toPx from 'common/toPx'
import Checkbox from 'components/CheckboxV2'
import FormattedMessage from 'components/FormattedMessage'
import { ButtonProps, PrimaryButton, SecondaryButton, TertiaryButton } from 'components/ids-ads'
import AccountLockedError from 'components/Login/components/AccountLockedError'
import ButtonRow from 'components/molecules/ButtonRow'
import FormField, { FormFieldType } from 'components/organisms/FormField'
import { useMultiFactorAuthSubmitCallback } from 'hooks/api/multiFactorAuth'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import {
  getInitialValues,
  MfaVerificationFormValues,
  MfaVerificationProps,
  MultiFactorAuthAction,
  MultiFactorAuthError,
  MultiFactorAuthType,
  useMfaVerificationValidator,
} from '../utils/multiFactorAuth.types'

const useStyles = ({ standardCode = true }: { standardCode?: boolean }) => {
  const theme = useTheme()

  return {
    bodyText: {
      ...theme.typography.bodyMedium2,
    },
    modalContent: css({
      margin: toPx`0 -${spacing.s24} ${spacing.s12}`,
      padding: toPx`0 ${spacing.s24}`,
    }),
    buttonText: css({ marginBottom: spacing.s12 }),
    buttonRow: css({ justifyContent: 'space-between' }),
    inputRow: css({
      width: standardCode ? 220 : 410,
    }),
    checkBox: css({ marginBottom: spacing.s32 }),
    error: css({
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemDetrimentalRegular,
    }),
    qrCode: css({
      margin: spacing.s32,
      marginBottom: spacing.s12,
    }),
    qrCodeDescription: css({
      marginBottom: spacing.s12,
    }),
  }
}

const AuthenticatorQrCode = ({ provisioningUrl }: { provisioningUrl: string }) => {
  const styles = useStyles({})

  return (
    <>
      {provisioningUrl && (
        <>
          <div css={styles.qrCode} data-testid="mfa-authenticator-qrcode">
            <QRCodeSVG value={provisioningUrl} />
          </div>
          <div css={styles.qrCodeDescription}>
            <FormattedMessage id="pages.multiFactorAuth.qrCode.description" />
          </div>
        </>
      )}
    </>
  )
}

interface MfaVerificationInputFieldProps {
  action: MultiFactorAuthAction
  mfaType: MultiFactorAuthType
  standardCode: boolean
  disabled: boolean
  submitError: MultiFactorAuthError | undefined
  clearSubmitError: () => void
  inputMessageId?: MessageIdType
}

const MfaVerificationInputField = ({
  action,
  mfaType,
  standardCode,
  disabled,
  submitError,
  clearSubmitError,
  inputMessageId,
}: MfaVerificationInputFieldProps) => {
  const styles = useStyles({ standardCode })
  const { genericFormatMessage } = useIntl()
  const [{ value }, { error }, { setValue }] = useFormikField('otp')

  const maxLength = standardCode ? 6 : 50

  const onChange = (updatedValue: string) => {
    const cleansedValue = standardCode ? updatedValue.replace(/\D/g, '') : updatedValue
    setValue(cleansedValue)
    clearSubmitError()
  }

  return (
    <>
      <div css={styles.inputRow}>
        <FormField
          type={FormFieldType.TEXT}
          label={inputMessageId || `pages.multiFactorAuth.password.title.${mfaType}`}
          id="otp"
          disabled={disabled}
          maxLength={maxLength}
          onChange={e => onChange(e.target.value)}
          value={value}
          error={
            error ||
            (submitError
              ? genericFormatMessage(`pages.multiFactorAuth.${submitError}.${action}`)
              : undefined)
          }
        />
      </div>
    </>
  )
}

const RememberDeviceField = () => {
  const styles = useStyles({})

  const [{ value: fieldValue, ...field }, , { setValue }] = useFormikField<boolean>({
    name: 'rememberDevice',
    defaultValue: 'true',
  })

  return (
    <div css={styles.checkBox}>
      <Checkbox
        id="rememberDevice"
        {...field}
        checked={fieldValue}
        onChange={({ target: { checked } }) => setValue(checked)}
      >
        <FormattedMessage id="pages.multiFactorAuth.rememberDevice.description" />
      </Checkbox>
    </div>
  )
}

interface MfaVerificationFormProps extends MfaVerificationProps {
  standardCode?: boolean
  onOptionClick?: () => void
  optionButtonProps?: ButtonProps
  descriptionMessageId?: MessageIdType
  inputMessageId?: MessageIdType
  optionButtonDescriptorMessageId?: MessageIdType
  optionButtonMessageId?: MessageIdType
}

export const MfaVerificationForm = ({
  action,
  mfaFactor,
  onSubmit,
  onBack,
  onSuccess,
  onError,
  onOptionClick,
  optionButtonProps,
  descriptionMessageId,
  inputMessageId,
  optionButtonDescriptorMessageId,
  optionButtonMessageId,
  standardCode = true,
}: MfaVerificationFormProps) => {
  const styles = useStyles({})
  const [submitError, setSubmitError] = useState<MultiFactorAuthError>()

  const { submit, submitLoading, accountLocked } = useMultiFactorAuthSubmitCallback({
    onSubmit,
    setSubmitError,
    onSuccess,
    onError,
  })

  const { mfaType, provisioningUrl } = mfaFactor
  const showQrCode =
    action === MultiFactorAuthAction.Setup && mfaType === MultiFactorAuthType.Authenticator

  const handleSubmit = async (formValues: MfaVerificationFormValues) => {
    await submit({ otp: formValues.otp, rememberDevice: formValues.rememberDevice, mfaType })
  }

  return (
    <Formik<MfaVerificationFormValues>
      validate={useMfaVerificationValidator()}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
    >
      <Form>
        <div css={styles.bodyText} data-testid="mfa-verification-form">
          <div css={styles.modalContent}>
            <FormattedMessage
              id={descriptionMessageId || `pages.multiFactorAuth.description.${action}.${mfaType}`}
              values={{
                ...breakFormattingHelper,
              }}
            />
            {showQrCode && provisioningUrl && (
              <AuthenticatorQrCode provisioningUrl={provisioningUrl} />
            )}
            <MfaVerificationInputField
              action={action}
              mfaType={mfaType}
              standardCode={standardCode}
              disabled={accountLocked}
              submitError={submitError}
              clearSubmitError={() => setSubmitError(undefined)}
              inputMessageId={inputMessageId}
            />
            {action === MultiFactorAuthAction.Login && <RememberDeviceField />}
          </div>
          {!accountLocked && (
            <>
              <div css={styles.buttonText}>
                {optionButtonDescriptorMessageId && (
                  <FormattedMessage id={optionButtonDescriptorMessageId} />
                )}
              </div>
              <ButtonRow css={styles.buttonRow}>
                {onOptionClick && (
                  <TertiaryButton
                    type="button"
                    onClick={onOptionClick}
                    {...optionButtonProps}
                    data-testid="mfa-verification-form-option-button"
                  >
                    <>
                      <FormattedMessage
                        id={
                          optionButtonMessageId ||
                          `pages.multiFactorAuth.option.${action}.${mfaType}`
                        }
                      />
                      {optionButtonProps &&
                        optionButtonProps.children &&
                        optionButtonProps.children}
                    </>
                  </TertiaryButton>
                )}
                {!onOptionClick && <div />}
                <ButtonRow>
                  <SecondaryButton
                    type="button"
                    onClick={onBack}
                    data-testid="mfa-verification-form-back-button"
                  >
                    <FormattedMessage id={`pages.multiFactorAuth.back.${action}`} />
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={submitLoading}
                    data-testid="mfa-verification-form-submit-button"
                  >
                    <FormattedMessage id="common.submit" />
                  </PrimaryButton>
                </ButtonRow>
              </ButtonRow>
            </>
          )}
          {accountLocked && <AccountLockedError />}
        </div>
      </Form>
    </Formik>
  )
}
