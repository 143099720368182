// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues } from './GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { GetVideoAdGroupsParamCreativeFieldDecisionsTagline } from './GetVideoAdGroupsParamCreativeFieldDecisionsTagline'
import {
  GetVideoAdGroupsParamCreativeFieldDecisionsTaglineFromJSON,
  GetVideoAdGroupsParamCreativeFieldDecisionsTaglineFromJSONTyped,
  GetVideoAdGroupsParamCreativeFieldDecisionsTaglineToJSON,
} from './GetVideoAdGroupsParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface GetVideoAdGroupsParamCreativeFieldDecisions
 */
export interface GetVideoAdGroupsParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof GetVideoAdGroupsParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {GetVideoAdGroupsParamCreativeFieldDecisionsTagline}
   * @memberof GetVideoAdGroupsParamCreativeFieldDecisions
   */
  tagline?: GetVideoAdGroupsParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the GetVideoAdGroupsParamCreativeFieldDecisions interface.
 */
export function instanceOfGetVideoAdGroupsParamCreativeFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetVideoAdGroupsParamCreativeFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamCreativeFieldDecisions {
  return GetVideoAdGroupsParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : GetVideoAdGroupsParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function GetVideoAdGroupsParamCreativeFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            GetVideoAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: GetVideoAdGroupsParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
