// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamDataAttributesEditorialStates } from './PutUniversalAdGroupsIdParamDataAttributesEditorialStates'
import {
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSON,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped,
  PutUniversalAdGroupsIdParamDataAttributesEditorialStatesToJSON,
} from './PutUniversalAdGroupsIdParamDataAttributesEditorialStates'
import type { SmartAdGroupResponseDataAttributesCreative } from './SmartAdGroupResponseDataAttributesCreative'
import {
  SmartAdGroupResponseDataAttributesCreativeFromJSON,
  SmartAdGroupResponseDataAttributesCreativeFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeToJSON,
} from './SmartAdGroupResponseDataAttributesCreative'

/**
 *
 * @export
 * @interface SmartAdGroupResponseDataAttributes
 */
export interface SmartAdGroupResponseDataAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the smart ad group. Set to false and the ad group is not active.
   * @type {boolean}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  enabled: boolean
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreative}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  creative: SmartAdGroupResponseDataAttributesCreative
  /**
   * Status for the ad group
   * @type {string}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  status: string
  /**
   * The campaign ID that the ad group belongs to.
   * @type {string}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  campaignId: string
  /**
   * Editorial states
   * @type {Array<PutUniversalAdGroupsIdParamDataAttributesEditorialStates>}
   * @memberof SmartAdGroupResponseDataAttributes
   */
  editorialStates?: Array<PutUniversalAdGroupsIdParamDataAttributesEditorialStates>
}

/**
 * Check if a given object implements the SmartAdGroupResponseDataAttributes interface.
 */
export function instanceOfSmartAdGroupResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'campaignId' in value

  return isInstance
}

export function SmartAdGroupResponseDataAttributesFromJSON(
  json: any
): SmartAdGroupResponseDataAttributes {
  return SmartAdGroupResponseDataAttributesFromJSONTyped(json, false)
}

export function SmartAdGroupResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartAdGroupResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    creative: SmartAdGroupResponseDataAttributesCreativeFromJSON(json['creative']),
    status: json['status'],
    campaignId: json['campaign_id'],
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          PutUniversalAdGroupsIdParamDataAttributesEditorialStatesFromJSON
        ),
  }
}

export function SmartAdGroupResponseDataAttributesToJSON(
  value?: SmartAdGroupResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    creative: SmartAdGroupResponseDataAttributesCreativeToJSON(value.creative),
    status: value.status,
    campaign_id: value.campaignId,
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            PutUniversalAdGroupsIdParamDataAttributesEditorialStatesToJSON
          ),
  }
}
