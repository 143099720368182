// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupKeywordsSumResponseDataAttributes } from './GetAdGroupKeywordsSumResponseDataAttributes'
import {
  GetAdGroupKeywordsSumResponseDataAttributesFromJSON,
  GetAdGroupKeywordsSumResponseDataAttributesFromJSONTyped,
  GetAdGroupKeywordsSumResponseDataAttributesToJSON,
} from './GetAdGroupKeywordsSumResponseDataAttributes'

/**
 *
 * @export
 * @interface GetAdGroupKeywordsSumResponseData
 */
export interface GetAdGroupKeywordsSumResponseData {
  /**
   * Ad Group Keyword ID
   * @type {string}
   * @memberof GetAdGroupKeywordsSumResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdGroupKeywordsSumResponseData
   */
  type: GetAdGroupKeywordsSumResponseDataTypeEnum
  /**
   *
   * @type {GetAdGroupKeywordsSumResponseDataAttributes}
   * @memberof GetAdGroupKeywordsSumResponseData
   */
  attributes: GetAdGroupKeywordsSumResponseDataAttributes
}

/**
 * @export
 */
export const GetAdGroupKeywordsSumResponseDataTypeEnum = {
  AdGroupKeywordSum: 'ad_group_keyword_sum',
} as const
export type GetAdGroupKeywordsSumResponseDataTypeEnum =
  typeof GetAdGroupKeywordsSumResponseDataTypeEnum[keyof typeof GetAdGroupKeywordsSumResponseDataTypeEnum]

/**
 * Check if a given object implements the GetAdGroupKeywordsSumResponseData interface.
 */
export function instanceOfGetAdGroupKeywordsSumResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdGroupKeywordsSumResponseDataFromJSON(
  json: any
): GetAdGroupKeywordsSumResponseData {
  return GetAdGroupKeywordsSumResponseDataFromJSONTyped(json, false)
}

export function GetAdGroupKeywordsSumResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupKeywordsSumResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAdGroupKeywordsSumResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetAdGroupKeywordsSumResponseDataToJSON(
  value?: GetAdGroupKeywordsSumResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAdGroupKeywordsSumResponseDataAttributesToJSON(value.attributes),
  }
}
