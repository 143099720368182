// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesParamBrandPageBlocks } from './PostBrandPagesParamBrandPageBlocks'
import {
  PostBrandPagesParamBrandPageBlocksFromJSON,
  PostBrandPagesParamBrandPageBlocksFromJSONTyped,
  PostBrandPagesParamBrandPageBlocksToJSON,
} from './PostBrandPagesParamBrandPageBlocks'

/**
 *
 * @export
 * @interface ApiBrandPagesControllerCreateInputBrandPage
 */
export interface ApiBrandPagesControllerCreateInputBrandPage {
  /**
   *
   * @type {string}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  type: ApiBrandPagesControllerCreateInputBrandPageTypeEnum
  /**
   * A descriptive name brand page, visible to advertiser only.
   * @type {string}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  name: string
  /**
   * Toggle to enable or disable the brand page.
   * @type {boolean}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  enabled: boolean
  /**
   * What level the brand page represents.
   * @type {string}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  level: ApiBrandPagesControllerCreateInputBrandPageLevelEnum
  /**
   * What purpose brand page represents, must be one of EVERGREEN, SEASONAL. Depending on purpose, page hierarchy may require additional inputs.
   * @type {string}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  purpose: ApiBrandPagesControllerCreateInputBrandPagePurposeEnum
  /**
   * Input of brand names desired for the brand page in increasing specificity i.e. ['Brand', 'Sub-Brand', 'Custom theme/group']. Will be used to generate the path of the brand page.
   * @type {Array<string>}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  pageHierarchy: Array<string>
  /**
   * Array of page blocks and their content.
   * @type {Array<PostBrandPagesParamBrandPageBlocks>}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  blocks: Array<PostBrandPagesParamBrandPageBlocks>
  /**
   * Array of Ads Taxonomy Entity IDs that are linked to this brand page
   * @type {Array<number>}
   * @memberof ApiBrandPagesControllerCreateInputBrandPage
   */
  taxonomyIds?: Array<number>
}

/**
 * @export
 */
export const ApiBrandPagesControllerCreateInputBrandPageTypeEnum = {
  EvergreenV1: 'evergreen.v1',
  ProductGroupEvergreenV1: 'product_group_evergreen.v1',
} as const
export type ApiBrandPagesControllerCreateInputBrandPageTypeEnum =
  typeof ApiBrandPagesControllerCreateInputBrandPageTypeEnum[keyof typeof ApiBrandPagesControllerCreateInputBrandPageTypeEnum]

/**
 * @export
 */
export const ApiBrandPagesControllerCreateInputBrandPageLevelEnum = {
  Brand: 'BRAND',
  Parent: 'PARENT',
  SubBrand: 'SUB_BRAND',
} as const
export type ApiBrandPagesControllerCreateInputBrandPageLevelEnum =
  typeof ApiBrandPagesControllerCreateInputBrandPageLevelEnum[keyof typeof ApiBrandPagesControllerCreateInputBrandPageLevelEnum]

/**
 * @export
 */
export const ApiBrandPagesControllerCreateInputBrandPagePurposeEnum = {
  Evergreen: 'EVERGREEN',
  Seasonal: 'SEASONAL',
} as const
export type ApiBrandPagesControllerCreateInputBrandPagePurposeEnum =
  typeof ApiBrandPagesControllerCreateInputBrandPagePurposeEnum[keyof typeof ApiBrandPagesControllerCreateInputBrandPagePurposeEnum]

/**
 * Check if a given object implements the ApiBrandPagesControllerCreateInputBrandPage interface.
 */
export function instanceOfApiBrandPagesControllerCreateInputBrandPage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'level' in value
  isInstance = isInstance && 'purpose' in value
  isInstance = isInstance && 'pageHierarchy' in value
  isInstance = isInstance && 'blocks' in value

  return isInstance
}

export function ApiBrandPagesControllerCreateInputBrandPageFromJSON(
  json: any
): ApiBrandPagesControllerCreateInputBrandPage {
  return ApiBrandPagesControllerCreateInputBrandPageFromJSONTyped(json, false)
}

export function ApiBrandPagesControllerCreateInputBrandPageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiBrandPagesControllerCreateInputBrandPage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    name: json['name'],
    enabled: json['enabled'],
    level: json['level'],
    purpose: json['purpose'],
    pageHierarchy: json['page_hierarchy'],
    blocks: (json['blocks'] as Array<any>).map(PostBrandPagesParamBrandPageBlocksFromJSON),
    taxonomyIds: !exists(json, 'taxonomy_ids') ? undefined : json['taxonomy_ids'],
  }
}

export function ApiBrandPagesControllerCreateInputBrandPageToJSON(
  value?: ApiBrandPagesControllerCreateInputBrandPage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    name: value.name,
    enabled: value.enabled,
    level: value.level,
    purpose: value.purpose,
    page_hierarchy: value.pageHierarchy,
    blocks: (value.blocks as Array<any>).map(PostBrandPagesParamBrandPageBlocksToJSON),
    taxonomy_ids: value.taxonomyIds,
  }
}
