import { css } from '@emotion/react'
import { Text, Theme, useTheme } from '@instacart/ids-core'
import { Link } from 'ic-snacks' // eslint-disable-line no-restricted-imports
import { formatTraceInfo } from 'common/formatTraceInfo'
import useIntl from 'common/useIntl'
import { Row } from 'components/deprecated/Table'
import TableColumnLabel from 'components/deprecated/TableColumnLabel'
import { Tooltip, BidStrengthStatusValues } from 'components/molecules'
import { useDebuggingContext } from 'context'
import { BidStrengthEnum, Keyword } from 'service/types'
import SuggestedBidFormattedDollar from './SuggestedBidFormattedDollar'

interface SuggestedBidTextProps {
  keyword: (Keyword & { attributes: { isConfidentBid: boolean; traceInfo: string[] } }) | Row
  bidStrength: BidStrengthStatusValues
  isAuthorizedToEdit?: boolean
  handleUpdate: () => void
}

const getStyles = (theme: Theme) => ({
  unknownSuggestedBid: css`
    display: flex;
    color: ${theme.colors.systemGrayscale70};
    align-content: center;
    font-weight: normal;
  `,
  suggestedBidLabel: css`
    display: flex;
    flex-direction: column;
  `,
})

const SuggestedBidText = ({
  keyword,
  bidStrength,
  isAuthorizedToEdit,
  handleUpdate,
}: SuggestedBidTextProps) => {
  const theme = useTheme()
  const intl = useIntl()
  const styles = getStyles(theme)
  const { isDebuggingOn } = useDebuggingContext()

  const suggestedBid =
    keyword?.attributes?.suggestedBid || keyword?.attributes?.bidRecs?.suggestedBid
  const isConfidentBid =
    keyword?.attributes?.isConfidentBid !== undefined ? keyword?.attributes?.isConfidentBid : true

  const showSuggestedBid =
    suggestedBid && isConfidentBid && bidStrength !== BidStrengthEnum.LowQuality

  const renderSuggestedBid = () => (
    <div css={styles.suggestedBidLabel} data-testid="suggested-bid-label">
      {!showSuggestedBid && (
        <div css={styles.unknownSuggestedBid}>
          <TableColumnLabel labelId="adGroup.keywordTable.suggestedBid.unknown.row.v2" />
        </div>
      )}
      {showSuggestedBid && (
        <>
          <SuggestedBidFormattedDollar suggestedBid={suggestedBid} />
          {isAuthorizedToEdit && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              data-testid="apply-suggested-bid-button"
              onClick={e => {
                e.preventDefault()
                if (handleUpdate) handleUpdate()
              }}
              style={{
                fontSize: '11px',
                marginTop: '-3px',
                width: 'max-content',
                color: theme.colors.systemGrayscale70,
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage({ id: 'common.apply' })}
            </Link>
          )}
        </>
      )}
    </div>
  )

  const renderTooltip = () => (
    <Tooltip
      variant="light"
      renderTooltip={() => (
        <Text typography="bodySmall2">
          <Text typography="bodySmall1">{`* For Engineering Purposes Only *`}</Text>
          <br />
          <pre>{formatTraceInfo(keyword?.attributes?.traceInfo)}</pre>
        </Text>
      )}
      isVisible={!keyword?.attributes?.traceInfo ? false : undefined}
    >
      {renderSuggestedBid()}
    </Tooltip>
  )

  return isDebuggingOn ? renderTooltip() : renderSuggestedBid()
}

export default SuggestedBidText
