// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiProductsControllerIndexInput
 */
export interface ApiProductsControllerIndexInput {
  /**
   * The UPCs you want to search for. You can send multiple UPCs separated by commas.
   * @type {Array<string>}
   * @memberof ApiProductsControllerIndexInput
   */
  upcs?: Array<string>
  /**
   * Filter for Impulse. When set to true only products eligible for impulse targeted ad groups are returned.
   * @type {boolean}
   * @memberof ApiProductsControllerIndexInput
   */
  isImpulse?: boolean
  /**
   * When set to true, only return products mapped to the account taxonomy.
   * @type {boolean}
   * @memberof ApiProductsControllerIndexInput
   */
  inLibrary?: boolean
  /**
   * When set to true, products results will include a flag indicating if they have not been mapped to a brand
   * @type {boolean}
   * @memberof ApiProductsControllerIndexInput
   */
  includeBrandMappingStatus?: boolean
}

/**
 * Check if a given object implements the ApiProductsControllerIndexInput interface.
 */
export function instanceOfApiProductsControllerIndexInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiProductsControllerIndexInputFromJSON(
  json: any
): ApiProductsControllerIndexInput {
  return ApiProductsControllerIndexInputFromJSONTyped(json, false)
}

export function ApiProductsControllerIndexInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiProductsControllerIndexInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    upcs: !exists(json, 'upcs') ? undefined : json['upcs'],
    isImpulse: !exists(json, 'is_impulse') ? undefined : json['is_impulse'],
    inLibrary: !exists(json, 'in_library') ? undefined : json['in_library'],
    includeBrandMappingStatus: !exists(json, 'include_brand_mapping_status')
      ? undefined
      : json['include_brand_mapping_status'],
  }
}

export function ApiProductsControllerIndexInputToJSON(
  value?: ApiProductsControllerIndexInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    upcs: value.upcs,
    is_impulse: value.isImpulse,
    in_library: value.inLibrary,
    include_brand_mapping_status: value.includeBrandMappingStatus,
  }
}
