import { css } from '@emotion/react'
import { Theme, useTheme } from '@instacart/ids-core'
import { useContext, useState, useMemo } from 'react'
import ClickOutside from 'react-click-outside'
import layers from 'common/layers'
import useIntl from 'common/useIntl'
import { InputSearch } from 'components/ids-ads'
import { CategoriesViewContext, TargetingRuleContext } from '../hooks'
import { CategoryTreeNode, constructCategoryTree } from '../utils'
import CategoriesList from './CategoriesList'
import CategoriesViewFooter from './CategoriesViewFooter'
import ParentCategory from './ParentCategory'
import TargetedCategoriesLine from './TargetedCategoriesLine'

const wrapperStyle = css({
  width: '100%',
})

const relativeContainerStyle = css({ position: 'relative', marginTop: 16 })

const absoluteContainerStyle = (theme: Theme) =>
  css({
    position: 'absolute',
    width: '100%',
    marginTop: 4,
    zIndex: layers[1],
    background: theme.colors.systemGrayscale00,
    boxSizing: 'border-box',
    borderRadius: '6px',
    boxShadow: `0px 2px 8px 0px ${theme.colors.systemGrayscale20}`,
  })

const categoriesContainerStyle = () =>
  css({
    display: 'flex',
    height: 415,
    paddingTop: 16,
  })

const level1CategoriesContainerStyle = (theme: Theme, hasHighlightedCategory: boolean) =>
  css({
    width: hasHighlightedCategory ? '55%' : '100%',
    borderRight: hasHighlightedCategory ? `1px solid ${theme.colors.systemGrayscale20}` : undefined,
    overflow: 'auto',
    boxSizing: 'border-box',
    paddingLeft: 16,
  })

const level3CategoriesContainerStyle = css({
  width: '45%',
  paddingLeft: 16,
  boxSizing: 'border-box',
  overflow: 'auto',
})

export default function CategoriesView() {
  const theme = useTheme()
  const { categories, isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const [searchText, setSearchText] = useState<string>('')
  const [categoryViewOpen, setCategoryViewOpen] = useState(false)

  const [highlightedL2Node, setHighlightedL2Node] = useState<CategoryTreeNode | undefined>(
    undefined
  )

  const categoryTree = useMemo(
    () => constructCategoryTree(categories, searchText),
    [categories, searchText]
  )

  const { formatMessage } = useIntl()

  return (
    <div css={wrapperStyle}>
      <CategoriesViewContext.Provider
        value={{ categoryTree, highlightedL2Node, setHighlightedL2Node, searchText }}
      >
        <TargetedCategoriesLine />
        {!isReviewing && !isReadOnly && (
          <ClickOutside onClickOutside={() => setCategoryViewOpen(false)}>
            <div css={relativeContainerStyle}>
              <InputSearch
                id="category-input-search"
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                placeholder={formatMessage({
                  id: 'pages.displayProduct.common.targetingRule.segment.categoriesView.searchPlaceholder',
                })}
                autoComplete="off"
                onClick={() => setCategoryViewOpen(true)}
                onFocus={() => setCategoryViewOpen(true)}
                compact
              />

              {categoryViewOpen && (
                <div css={absoluteContainerStyle(theme)}>
                  <div css={categoriesContainerStyle()}>
                    <div css={level1CategoriesContainerStyle(theme, !!highlightedL2Node)}>
                      <CategoriesList
                        titleMessageId="pages.displayProduct.common.targetingRule.segment.categoriesView.categories"
                        parentNode={categoryTree.root}
                        noMatchMessageId="pages.displayProduct.common.targetingRule.segment.categoriesView.categories.empty"
                        noChildrenMessageId="pages.displayProduct.common.targetingRule.segment.categoriesView.categories.error"
                        render={(node, idx) => (
                          <ParentCategory isFirstNode={idx === 0} node={node} isLevel3={false} />
                        )}
                      />
                    </div>
                    {highlightedL2Node && categoryTree.root.isVisible && (
                      <div css={level3CategoriesContainerStyle}>
                        <CategoriesList
                          titleMessageId="pages.displayProduct.common.targetingRule.segment.categoriesView.aisles.title"
                          parentNode={highlightedL2Node}
                          noMatchMessageId="common.empty"
                          noChildrenMessageId="pages.displayProduct.common.targetingRule.segment.categoriesView.aisles.empty"
                          render={(node, idx) => (
                            <ParentCategory isFirstNode={idx === 0} node={node} isLevel3 />
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <CategoriesViewFooter onDone={() => setCategoryViewOpen(false)} />
                </div>
              )}
            </div>
          </ClickOutside>
        )}
      </CategoriesViewContext.Provider>
    </div>
  )
}
