// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PatchVideoAdGroupsIdParamAssetFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamAssetFieldDecisions,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamAssetFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamAssetFieldDecisions'
import {
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions'
import {
  PatchVideoAdGroupsIdParamBrandPageFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamBrandPageFieldDecisions,
  PatchVideoAdGroupsIdParamBrandPageFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamBrandPageFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamBrandPageFieldDecisions'
import {
  PatchVideoAdGroupsIdParamCreativeFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamCreativeFieldDecisions,
  PatchVideoAdGroupsIdParamCreativeFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamCreativeFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamCreativeFieldDecisions'
import {
  PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisions,
  PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisions'
import {
  PatchVideoAdGroupsIdParamProductAssetFieldDecisions,
  instanceOfPatchVideoAdGroupsIdParamProductAssetFieldDecisions,
  PatchVideoAdGroupsIdParamProductAssetFieldDecisionsFromJSON,
  PatchVideoAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped,
  PatchVideoAdGroupsIdParamProductAssetFieldDecisionsToJSON,
} from './PatchVideoAdGroupsIdParamProductAssetFieldDecisions'

/**
 * @type PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =
  | ({ type: 'ASSET' } & PatchVideoAdGroupsIdParamAssetFieldDecisions)
  | ({ type: 'BRAND_PAGE' } & PatchVideoAdGroupsIdParamBrandPageFieldDecisions)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisions)
  | ({ type: 'CREATIVE' } & PatchVideoAdGroupsIdParamCreativeFieldDecisions)
  | ({ type: 'DISPLAY_AD_GROUP' } & PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisions)
  | ({ type: 'PRODUCT_ASSET' } & PatchVideoAdGroupsIdParamProductAssetFieldDecisions)

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PatchVideoAdGroupsIdParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PatchVideoAdGroupsIdParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PatchVideoAdGroupsIdParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PatchVideoAdGroupsIdParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PatchVideoAdGroupsIdParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return PatchVideoAdGroupsIdParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PatchVideoAdGroupsIdParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return PatchVideoAdGroupsIdParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PatchVideoAdGroupsIdParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return PatchVideoAdGroupsIdParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
