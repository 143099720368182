// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
 */
export interface ApiAccountsControllerUpdateCorpAddressInputCorporateAddress {
  /**
   * Phone number
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  phoneNumber?: string
  /**
   * Address line 1
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressLine1?: string
  /**
   * Address line 2
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressLine2?: string
  /**
   * City
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressCity?: string
  /**
   * State
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressState?: string
  /**
   * Country
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressCountry?: string
  /**
   * Zip code
   * @type {string}
   * @memberof ApiAccountsControllerUpdateCorpAddressInputCorporateAddress
   */
  addressZip?: string
}

/**
 * Check if a given object implements the ApiAccountsControllerUpdateCorpAddressInputCorporateAddress interface.
 */
export function instanceOfApiAccountsControllerUpdateCorpAddressInputCorporateAddress(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSON(
  json: any
): ApiAccountsControllerUpdateCorpAddressInputCorporateAddress {
  return ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSONTyped(json, false)
}

export function ApiAccountsControllerUpdateCorpAddressInputCorporateAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAccountsControllerUpdateCorpAddressInputCorporateAddress {
  if (json === undefined || json === null) {
    return json
  }
  return {
    phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
    addressLine1: !exists(json, 'address_line1') ? undefined : json['address_line1'],
    addressLine2: !exists(json, 'address_line2') ? undefined : json['address_line2'],
    addressCity: !exists(json, 'address_city') ? undefined : json['address_city'],
    addressState: !exists(json, 'address_state') ? undefined : json['address_state'],
    addressCountry: !exists(json, 'address_country') ? undefined : json['address_country'],
    addressZip: !exists(json, 'address_zip') ? undefined : json['address_zip'],
  }
}

export function ApiAccountsControllerUpdateCorpAddressInputCorporateAddressToJSON(
  value?: ApiAccountsControllerUpdateCorpAddressInputCorporateAddress | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    phone_number: value.phoneNumber,
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    address_city: value.addressCity,
    address_state: value.addressState,
    address_country: value.addressCountry,
    address_zip: value.addressZip,
  }
}
