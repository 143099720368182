import { css } from '@emotion/react'
import { layers } from '@instacart/ids-core'
import { useHeaderHeight } from 'components/Header/header.utils'

function useStyles() {
  const headerHeight = useHeaderHeight()

  return {
    header: css({
      position: 'sticky',
      top: headerHeight,
      zIndex: layers.l1,
    }),
  }
}

const StickyHeader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const styles = useStyles()

  return <div css={styles.header}>{children}</div>
}

export default StickyHeader
