// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetCampaignProductIdsResponse } from '../models/GetCampaignProductIdsResponse'
import {
  GetCampaignProductIdsResponseFromJSON,
  GetCampaignProductIdsResponseToJSON,
} from '../models/GetCampaignProductIdsResponse'

export interface GetCampaignProductsIdsRequest {
  campaignId: string
  dedupeProducts?: boolean
  xApiKey?: string
  xSecretKey?: string
}

/**
 *
 */
export class CampaignProductsApi extends runtime.BaseAPI {
  /**
   * This method will return a list of all Product IDs associated with a Campaign. You must specify the campaign_id of the Campaign you wish to target as a parameter.
   * Returns all Product IDs within an Campaign
   */
  async getCampaignProductsIdsRaw(
    requestParameters: GetCampaignProductsIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignProductIdsResponse>> {
    if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
      throw new runtime.RequiredError(
        'campaignId',
        'Required parameter requestParameters.campaignId was null or undefined when calling getCampaignProductsIds.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.campaignId !== undefined) {
      queryParameters['campaign_id'] = requestParameters.campaignId
    }

    if (requestParameters.dedupeProducts !== undefined) {
      queryParameters['dedupe_products'] = requestParameters.dedupeProducts
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaign_products/ids`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignProductIdsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return a list of all Product IDs associated with a Campaign. You must specify the campaign_id of the Campaign you wish to target as a parameter.
   * Returns all Product IDs within an Campaign
   */
  async getCampaignProductsIds(
    requestParameters: GetCampaignProductsIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignProductIdsResponse> {
    const response = await this.getCampaignProductsIdsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
