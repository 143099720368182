import { css } from '@emotion/react'
import { Text, Theme, useTheme } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import { MessageIdType } from 'locales/types'
import { CategoryTreeNode } from '../utils'

const categoryContainerStyle = css({
  marginTop: 16,
})

const emptyMessageContainerStyle = (theme: Theme) => ({
  color: theme.colors.systemGrayscale50,
  marginTop: 16,
})

interface CategoriesListProps {
  titleMessageId: MessageIdType
  parentNode: CategoryTreeNode
  noMatchMessageId: MessageIdType
  noChildrenMessageId: MessageIdType
  render: (node: CategoryTreeNode, idx: number) => JSX.Element
}

export default function CategoriesList({
  parentNode,
  titleMessageId,
  noMatchMessageId,
  noChildrenMessageId,
  render,
}: CategoriesListProps) {
  const theme = useTheme()
  const noContentMessageId = parentNode.children.length ? noMatchMessageId : noChildrenMessageId
  const filteredNodes = parentNode.children.filter(node => node.isVisible)

  return (
    <div>
      <Text typography="bodyMedium1">
        <FormattedMessage id={titleMessageId} />
      </Text>
      {filteredNodes.length ? (
        filteredNodes.map((node, idx) => (
          <div css={categoryContainerStyle} key={node.id}>
            {render(node, idx)}
          </div>
        ))
      ) : (
        <div>
          <Text typography="bodyMedium2" style={emptyMessageContainerStyle(theme)}>
            <FormattedMessage id={noContentMessageId} />
          </Text>
        </div>
      )}
    </div>
  )
}
