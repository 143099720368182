// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktop } from './PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktop'
import {
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktopFromJSON,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktopFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktopToJSON,
} from './PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktop'
import type { PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobile } from './PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobile'
import {
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobileFromJSON,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobileFromJSONTyped,
  PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobileToJSON,
} from './PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobile'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockSectionBannerAssets
 */
export interface PostBrandPagesIdStatusParamBlockSectionBannerAssets {
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktop}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBannerAssets
   */
  desktop: PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktop
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobile}
   * @memberof PostBrandPagesIdStatusParamBlockSectionBannerAssets
   */
  mobile: PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobile
}

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockSectionBannerAssets interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockSectionBannerAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockSectionBannerAssets {
  return PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBlockSectionBannerAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockSectionBannerAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktopFromJSON(json['desktop']),
    mobile: PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobileFromJSON(json['mobile']),
  }
}

export function PostBrandPagesIdStatusParamBlockSectionBannerAssetsToJSON(
  value?: PostBrandPagesIdStatusParamBlockSectionBannerAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: PostBrandPagesIdStatusParamBlockSectionBannerAssetsDesktopToJSON(value.desktop),
    mobile: PostBrandPagesIdStatusParamBlockSectionBannerAssetsMobileToJSON(value.mobile),
  }
}
