// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SmartAdGroupResponseDataAttributesCreativeActionsBannerClick } from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClick'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActionsBannerClick'
import type { SmartAdGroupResponseDataAttributesCreativeActionsMainItemGrid } from './SmartAdGroupResponseDataAttributesCreativeActionsMainItemGrid'
import {
  SmartAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON,
  SmartAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  SmartAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON,
} from './SmartAdGroupResponseDataAttributesCreativeActionsMainItemGrid'

/**
 *
 * @export
 * @interface SmartAdGroupResponseDataAttributesCreativeActions
 */
export interface SmartAdGroupResponseDataAttributesCreativeActions {
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActionsMainItemGrid}
   * @memberof SmartAdGroupResponseDataAttributesCreativeActions
   */
  mainItemGrid?: SmartAdGroupResponseDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {SmartAdGroupResponseDataAttributesCreativeActionsBannerClick}
   * @memberof SmartAdGroupResponseDataAttributesCreativeActions
   */
  bannerClick?: SmartAdGroupResponseDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the SmartAdGroupResponseDataAttributesCreativeActions interface.
 */
export function instanceOfSmartAdGroupResponseDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function SmartAdGroupResponseDataAttributesCreativeActionsFromJSON(
  json: any
): SmartAdGroupResponseDataAttributesCreativeActions {
  return SmartAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function SmartAdGroupResponseDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartAdGroupResponseDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : SmartAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(json['banner_click']),
  }
}

export function SmartAdGroupResponseDataAttributesCreativeActionsToJSON(
  value?: SmartAdGroupResponseDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: SmartAdGroupResponseDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    banner_click: SmartAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
