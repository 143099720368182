import moment from 'moment'
import { DisplayCampaignFormValues } from 'pages/DisplayProduct/DisplayCampaign/displayCampaign.types'
import {
  PutDisplayCampaignsIdRequest,
  PostDisplayCampaignsRequest,
} from 'service/openapi/__codegen__/apis/DisplayCampaignsApi'
import {
  ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum,
  ApiDisplayCampaignsControllerCreateInputCampaignPacingTypeEnum as CampaignPacingType,
  ApiDisplayCampaignsControllerCreateInputCampaignObjectiveEnum as CampaignObjective,
  ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum,
} from 'service/openapi/__codegen__/models/ApiDisplayCampaignsControllerCreateInputCampaign'

const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD'

export const toCreateDisplayCampaignRequest = (
  values: DisplayCampaignFormValues
): PostDisplayCampaignsRequest => ({
  body: {
    campaign: {
      name: values.name,
      objective: values.objective as CampaignObjective,
      goal: values.goal,
      goalFormat: values.goalFormat,
      budget: values.budget,
      pacingType: values.pacingType as CampaignPacingType,
      startsAt: moment(values.startsAt).format(DISPLAY_DATE_FORMAT),
      endsAt: values.endsAt ? moment(values.endsAt).format(DISPLAY_DATE_FORMAT) : undefined,
      enabled: true,
      memo: values.memo || undefined,
      paymentType:
        (values.paymentType as ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum) ||
        undefined,
      billPayer:
        (values.billPayer as ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum) ||
        undefined,
      purchaseOrder: values.purchaseOrder || undefined,
    },
  },
})

export const toUpdateDisplayCampaignsRequest = (
  values: DisplayCampaignFormValues,
  additionalParams: { id: string } | undefined
): PutDisplayCampaignsIdRequest => {
  if (!additionalParams) throw new Error('id is required to update DisplayCampaign')
  return {
    body: {
      campaign: {
        name: values.name,
        objective: values.objective as CampaignObjective,
        goal: values.goal,
        goalFormat: values.goalFormat,
        budget: values.budget,
        pacingType: values.pacingType as CampaignPacingType,
        startsAt: moment(values.startsAt).format(DISPLAY_DATE_FORMAT),
        endsAt: values.endsAt ? moment(values.endsAt).format(DISPLAY_DATE_FORMAT) : undefined,
        enabled: values.enabled,
        memo: values.memo || undefined,
        paymentType:
          (values.paymentType as ApiDisplayCampaignsControllerCreateInputCampaignPaymentTypeEnum) ||
          undefined,
        billPayer:
          (values.billPayer as ApiDisplayCampaignsControllerCreateInputCampaignBillPayerEnum) ||
          undefined,
        purchaseOrder: values.purchaseOrder || undefined,
      },
    },
    id: additionalParams.id,
  }
}
