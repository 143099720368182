import moment from 'moment'
import { FC } from 'react'
import { SHORT_DATE_FORMAT } from '../common/utils-moment'

type FormattedDateProps = {
  children: Date | string
  format?: string
}

const FormattedDate: FC<React.PropsWithChildren<FormattedDateProps>> = ({ children, format }) => {
  const formatted = moment(children).format(format)
  return <>{formatted === 'Invalid date' ? null : formatted}</>
}

FormattedDate.defaultProps = {
  // see https://momentjs.com/docs/#/displaying/format/
  format: SHORT_DATE_FORMAT,
}

export default FormattedDate
