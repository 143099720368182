import { InputBase, Variant } from '@instacart/ids-core'
import { OptionsType, OptionTypeBase, ValueType } from 'react-select'
import useIntl from 'common/useIntl'
import { AsyncSelect, AsyncSelectProps, DefaultOptionType } from 'components/ids-ads'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'

export type AsyncSelectInputFieldProps<OptionType, IsMulti extends boolean> = Omit<
  Variant<typeof InputBase>,
  'placeholder'
> &
  InputFieldProps<AsyncSelectProps<OptionTypeBase, IsMulti>> & {
    selectedOptions?: ValueType<OptionTypeBase, IsMulti>
    onOptionSelected?: (opt: OptionType | null) => void
  }

function AsyncSelectInput<IsMulti extends boolean>(
  props: AsyncSelectProps<OptionTypeBase, IsMulti>
) {
  return <AsyncSelect variant="tertiary" block {...props} />
}
AsyncSelectInput.displayName = 'InputText'

export function AsyncSelectInputField<
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false
>({
  id,
  label: labelId,
  info,
  infoDescription,
  selectedOptions,
  loadOptions,
  defaultOptions,
  placeholder,
  disabled = false,
  closeMenuOnSelect,
  onOptionSelected,
  formatOptionLabel,
  isMulti,
  isDisabled,
  error,
  styles,
  ...rest
}: AsyncSelectInputFieldProps<OptionType, IsMulti>) {
  const [, { touched, error: fieldError }, { setValue }] = useFormikField(id)
  const { formatMessage } = useIntl()
  const label = formatMessage({ id: labelId as MessageIdType })
  const infoProps = {
    info: typeof info === 'string' ? formatMessage({ id: info as MessageIdType }) : info,
    infoDescription: infoDescription
      ? formatMessage({ id: infoDescription as MessageIdType })
      : undefined,
  }
  const errorString = touched ? error || fieldError : undefined

  const handleOnChange = (opt: ValueType<OptionTypeBase, IsMulti>) => {
    if (isMulti) {
      const values = (opt as OptionsType<OptionTypeBase>).map(item => item.value)
      setValue(values)
    } else {
      const option = opt as OptionType | null
      if (option?.value) {
        setValue(option.value)
      }
      if (onOptionSelected) onOptionSelected(option)
    }
  }

  return (
    <InputFieldContainer {...rest} {...infoProps} id={id} label={label} error={errorString}>
      <AsyncSelectInput<IsMulti>
        value={selectedOptions}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions || undefined}
        placeholder={placeholder}
        disabled={disabled}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleOnChange}
        formatOptionLabel={formatOptionLabel}
        aria-label={label}
        isDisabled={isDisabled}
        styles={styles}
      />
    </InputFieldContainer>
  )
}

AsyncSelectInputField.displayName = 'AsyncSelectInputField'
