import { css } from '@emotion/react'
import { layers } from '@instacart/ids-core'
import { FC } from 'react'
import toPx from 'common/toPx'
import UnauthenticatedFooter from 'components/Unauthenticated/components/UnauthenticatedFooter'
import UnauthenticatedHeader from 'components/Unauthenticated/components/UnauthenticatedHeader'
import { UnauthenticatedLayoutStyles } from 'components/Unauthenticated/components/UnauthenticatedLayoutStyles'

const styles = {
  main: css({
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: layers.l2,
    padding: toPx`30 50 60 30`,
    position: 'relative',
  }),
  section: css({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
  }),
}

type LayoutProps = {
  children: React.ReactNode
  hideLoginButton?: boolean
}

// since the signup page is a CTA from the marketing site, we want it to have a white background
const backgroundColor =
  typeof window !== 'undefined' && window.location.pathname === '/signup' ? 'white' : '#faf1e5'

const UnauthenticatedLayout: FC<React.PropsWithChildren<LayoutProps>> = ({
  hideLoginButton,
  children,
}) => (
  <>
    <UnauthenticatedLayoutStyles color={backgroundColor} />
    <UnauthenticatedHeader
      hideLoginButton={hideLoginButton}
      overrideBackgroundColor={backgroundColor}
    />

    <section css={styles.section}>
      <main css={styles.main}>{children}</main>
    </section>

    <UnauthenticatedFooter />
  </>
)

export default UnauthenticatedLayout
