// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockContent } from './PostAdminBrandPagesIdLabelParamBrandPageBlockContent'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockContentToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockContent'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockProducts } from './PostAdminBrandPagesIdLabelParamBrandPageBlockProducts'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockProducts'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageBlock
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageBlock {
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlock
   */
  type: PostAdminBrandPagesIdLabelParamBrandPageBlockTypeEnum
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlock
   */
  discriminator: string
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageBlockProducts}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlock
   */
  products: PostAdminBrandPagesIdLabelParamBrandPageBlockProducts
  /**
   *
   * @type {PostAdminBrandPagesIdLabelParamBrandPageBlockContent}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlock
   */
  content: PostAdminBrandPagesIdLabelParamBrandPageBlockContent
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageBlockTypeEnum = {
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageBlockTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageBlockTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageBlockTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageBlock interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlock(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'products' in value
  isInstance = isInstance && 'content' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageBlock {
  return PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageBlock {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    products: PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSON(json['products']),
    content: PostAdminBrandPagesIdLabelParamBrandPageBlockContentFromJSON(json['content']),
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageBlock | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    products: PostAdminBrandPagesIdLabelParamBrandPageBlockProductsToJSON(value.products),
    content: PostAdminBrandPagesIdLabelParamBrandPageBlockContentToJSON(value.content),
  }
}
