import { layers, useTheme } from '@instacart/ids-core'
import toPx from 'common/toPx'
import { TOOLTIP_TRANSITION_TIME_MS } from 'components/ids-ads/molecules/tooltips/utils/types'

export function useTooltipStyles() {
  const theme = useTheme()

  return {
    tooltip: {
      // FIXME: The sidebar is already at layers.l2, so we use this to allow tooltips to overlap
      // the sidebar. We should either formally add an additional layer or drop the sidebar to
      // a lower layer.
      zIndex: layers.l2 + 50,
      backgroundColor: theme.colors.systemGrayscale70,
      color: theme.colors.systemGrayscale00,
      borderRadius: theme.radius.r8,
      padding: toPx`${6} ${12} ${8}`,
      boxSizing: 'border-box',
      maxWidth: 240,
    },
    content: {
      transition: `opacity ${TOOLTIP_TRANSITION_TIME_MS}ms ease-in-out`,
      opacity: 0,
      '[data-enter] &': {
        opacity: 1,
      },
    },
  } as const
}
