import moment from 'moment'
import { TypedSchema } from 'yup/lib/util/types'
import { RANGE_FORMAT } from 'common/utils-moment'
import { array, object, SchemaType, string, useSyncValidator } from 'common/validation'
import { brandPageFormSchemaPromotedAisle } from 'pages/DisplayProduct/DisplayAdGroup/displayAdGroup.types'
import {
  getSchemaProperties,
  getSchemaAssets,
  getSchemaProductPickerAction,
  getSchemaBrandPageAction,
} from '../formatSchemaAccessors'
import { FormatSchemaType } from '../types'

const assetSchemaObject = object({
  id: string().default(''),
  machineName: string().required().default(''),
  urls: object({
    previewUrl: string().default(''),
    uploadedUri: string().default(''),
  }),
  altText: string().default(''),
})

const requiredAssetSchemaObject = object({
  id: string().default(''),
  machineName: string().required().default(''),
  urls: object({
    previewUrl: string().default(''),
    uploadedUri: string().required().default(''),
  }),
  altText: string().default(''),
})

const prototypeCampaignBaseSchema: Record<string, SchemaType<TypedSchema>> = {
  name: string().required().default(''),
  startsAt: string()
    .default(() => moment().format(RANGE_FORMAT))
    .required(),
  endsAt: string().notRequired().nullable(),
  creativeType: string().required(),
}

export const generateFormSchema = (creativeSchema: FormatSchemaType | undefined) => {
  const schemaShape = prototypeCampaignBaseSchema

  const schemaProperties = getSchemaProperties(creativeSchema)
  if (schemaProperties) {
    Object.entries(schemaProperties).forEach(([propertyName]) => {
      schemaShape[propertyName] = string().required()
    })
  }

  const schemaAssets = getSchemaAssets(creativeSchema)
  if (schemaAssets) {
    Object.entries(schemaAssets).forEach(([assetIdentifier, assetObject]) => {
      schemaShape[assetIdentifier] = assetObject.required
        ? requiredAssetSchemaObject
        : assetSchemaObject
    })
  }

  const schemaProductPickerAction = getSchemaProductPickerAction(creativeSchema)
  if (schemaProductPickerAction) {
    schemaShape.productIds = array()
      .required()
      .of(string().required())
      .min(schemaProductPickerAction.min_items)
      .max(schemaProductPickerAction.max_items)
      .default([])
  }

  const schemaBrandPageAction = getSchemaBrandPageAction(creativeSchema)
  if (schemaBrandPageAction) {
    schemaShape.brandPage = brandPageFormSchemaPromotedAisle
  }

  return object(schemaShape)
}

export const usePrototypeCampaignValidator = (creativeSchema: FormatSchemaType | undefined) => {
  return useSyncValidator(
    generateFormSchema(creativeSchema),
    'pages.prototypeCampaigns.form.validation'
  )
}
