// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdGroupKeywordsBulkResponseDataAttributes } from './AdGroupKeywordsBulkResponseDataAttributes'
import {
  AdGroupKeywordsBulkResponseDataAttributesFromJSON,
  AdGroupKeywordsBulkResponseDataAttributesFromJSONTyped,
  AdGroupKeywordsBulkResponseDataAttributesToJSON,
} from './AdGroupKeywordsBulkResponseDataAttributes'

/**
 *
 * @export
 * @interface AdGroupKeywordsBulkResponseData
 */
export interface AdGroupKeywordsBulkResponseData {
  /**
   *
   * @type {string}
   * @memberof AdGroupKeywordsBulkResponseData
   */
  type: AdGroupKeywordsBulkResponseDataTypeEnum
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof AdGroupKeywordsBulkResponseData
   */
  status: number
  /**
   *
   * @type {Array<AdGroupKeywordsBulkResponseDataAttributes>}
   * @memberof AdGroupKeywordsBulkResponseData
   */
  attributes: Array<AdGroupKeywordsBulkResponseDataAttributes>
}

/**
 * @export
 */
export const AdGroupKeywordsBulkResponseDataTypeEnum = {
  Keywords: 'keywords',
} as const
export type AdGroupKeywordsBulkResponseDataTypeEnum =
  typeof AdGroupKeywordsBulkResponseDataTypeEnum[keyof typeof AdGroupKeywordsBulkResponseDataTypeEnum]

/**
 * Check if a given object implements the AdGroupKeywordsBulkResponseData interface.
 */
export function instanceOfAdGroupKeywordsBulkResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function AdGroupKeywordsBulkResponseDataFromJSON(
  json: any
): AdGroupKeywordsBulkResponseData {
  return AdGroupKeywordsBulkResponseDataFromJSONTyped(json, false)
}

export function AdGroupKeywordsBulkResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupKeywordsBulkResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    status: json['status'],
    attributes: (json['attributes'] as Array<any>).map(
      AdGroupKeywordsBulkResponseDataAttributesFromJSON
    ),
  }
}

export function AdGroupKeywordsBulkResponseDataToJSON(
  value?: AdGroupKeywordsBulkResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    status: value.status,
    attributes: (value.attributes as Array<any>).map(
      AdGroupKeywordsBulkResponseDataAttributesToJSON
    ),
  }
}
