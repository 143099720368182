import { createContext } from 'react'
import { SystemAlert } from 'service/types/systemAlert'

export interface SystemAlertContext {
  systemAlert: SystemAlert | null
  bannerHeight: number
  setBannerHeight(height: number): void
}

function notImplemented(description: string) {
  return () => {
    throw new Error(
      `${description} is not implemented. Maybe this component is not rendered under a SystemAlertProvider?`
    )
  }
}

export const initialSystemAlertContext: SystemAlertContext = {
  systemAlert: null,
  bannerHeight: 0,
  setBannerHeight: notImplemented('setBannerHeight'),
}

export const SystemAlertContext = createContext(initialSystemAlertContext)
