// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiReportsControllerAdGroupsInput } from '../models/ApiReportsControllerAdGroupsInput'
import {
  ApiReportsControllerAdGroupsInputFromJSON,
  ApiReportsControllerAdGroupsInputToJSON,
} from '../models/ApiReportsControllerAdGroupsInput'
import type { ApiReportsControllerBrandPagesInput } from '../models/ApiReportsControllerBrandPagesInput'
import {
  ApiReportsControllerBrandPagesInputFromJSON,
  ApiReportsControllerBrandPagesInputToJSON,
} from '../models/ApiReportsControllerBrandPagesInput'
import type { ApiReportsControllerCampaignsInput } from '../models/ApiReportsControllerCampaignsInput'
import {
  ApiReportsControllerCampaignsInputFromJSON,
  ApiReportsControllerCampaignsInputToJSON,
} from '../models/ApiReportsControllerCampaignsInput'
import type { ApiReportsControllerDisplayAdGroupsInput } from '../models/ApiReportsControllerDisplayAdGroupsInput'
import {
  ApiReportsControllerDisplayAdGroupsInputFromJSON,
  ApiReportsControllerDisplayAdGroupsInputToJSON,
} from '../models/ApiReportsControllerDisplayAdGroupsInput'
import type { ApiReportsControllerDisplayCampaignsInput } from '../models/ApiReportsControllerDisplayCampaignsInput'
import {
  ApiReportsControllerDisplayCampaignsInputFromJSON,
  ApiReportsControllerDisplayCampaignsInputToJSON,
} from '../models/ApiReportsControllerDisplayCampaignsInput'
import type { ApiReportsControllerDisplayKeywordsInput } from '../models/ApiReportsControllerDisplayKeywordsInput'
import {
  ApiReportsControllerDisplayKeywordsInputFromJSON,
  ApiReportsControllerDisplayKeywordsInputToJSON,
} from '../models/ApiReportsControllerDisplayKeywordsInput'
import type { ApiReportsControllerDisplayProductsInput } from '../models/ApiReportsControllerDisplayProductsInput'
import {
  ApiReportsControllerDisplayProductsInputFromJSON,
  ApiReportsControllerDisplayProductsInputToJSON,
} from '../models/ApiReportsControllerDisplayProductsInput'
import type { ApiReportsControllerImpressionAndClicksDisplayProductInput } from '../models/ApiReportsControllerImpressionAndClicksDisplayProductInput'
import {
  ApiReportsControllerImpressionAndClicksDisplayProductInputFromJSON,
  ApiReportsControllerImpressionAndClicksDisplayProductInputToJSON,
} from '../models/ApiReportsControllerImpressionAndClicksDisplayProductInput'
import type { ApiReportsControllerImpressionAndClicksSponsoredProductInput } from '../models/ApiReportsControllerImpressionAndClicksSponsoredProductInput'
import {
  ApiReportsControllerImpressionAndClicksSponsoredProductInputFromJSON,
  ApiReportsControllerImpressionAndClicksSponsoredProductInputToJSON,
} from '../models/ApiReportsControllerImpressionAndClicksSponsoredProductInput'
import type { ApiReportsControllerInsightsInput } from '../models/ApiReportsControllerInsightsInput'
import {
  ApiReportsControllerInsightsInputFromJSON,
  ApiReportsControllerInsightsInputToJSON,
} from '../models/ApiReportsControllerInsightsInput'
import type { ApiReportsControllerKeywordsInput } from '../models/ApiReportsControllerKeywordsInput'
import {
  ApiReportsControllerKeywordsInputFromJSON,
  ApiReportsControllerKeywordsInputToJSON,
} from '../models/ApiReportsControllerKeywordsInput'
import type { ApiReportsControllerLiftSummaryInput } from '../models/ApiReportsControllerLiftSummaryInput'
import {
  ApiReportsControllerLiftSummaryInputFromJSON,
  ApiReportsControllerLiftSummaryInputToJSON,
} from '../models/ApiReportsControllerLiftSummaryInput'
import type { ApiReportsControllerLiftVariantInput } from '../models/ApiReportsControllerLiftVariantInput'
import {
  ApiReportsControllerLiftVariantInputFromJSON,
  ApiReportsControllerLiftVariantInputToJSON,
} from '../models/ApiReportsControllerLiftVariantInput'
import type { ApiReportsControllerProductsInput } from '../models/ApiReportsControllerProductsInput'
import {
  ApiReportsControllerProductsInputFromJSON,
  ApiReportsControllerProductsInputToJSON,
} from '../models/ApiReportsControllerProductsInput'
import type { ApiReportsControllerPromotionCouponsInput } from '../models/ApiReportsControllerPromotionCouponsInput'
import {
  ApiReportsControllerPromotionCouponsInputFromJSON,
  ApiReportsControllerPromotionCouponsInputToJSON,
} from '../models/ApiReportsControllerPromotionCouponsInput'
import type { ApiReportsControllerPromotionGroupsInput } from '../models/ApiReportsControllerPromotionGroupsInput'
import {
  ApiReportsControllerPromotionGroupsInputFromJSON,
  ApiReportsControllerPromotionGroupsInputToJSON,
} from '../models/ApiReportsControllerPromotionGroupsInput'
import type { ApiReportsControllerPromotionProductsInput } from '../models/ApiReportsControllerPromotionProductsInput'
import {
  ApiReportsControllerPromotionProductsInputFromJSON,
  ApiReportsControllerPromotionProductsInputToJSON,
} from '../models/ApiReportsControllerPromotionProductsInput'
import type { ApiReportsControllerProviDisplayAdGroupsInput } from '../models/ApiReportsControllerProviDisplayAdGroupsInput'
import {
  ApiReportsControllerProviDisplayAdGroupsInputFromJSON,
  ApiReportsControllerProviDisplayAdGroupsInputToJSON,
} from '../models/ApiReportsControllerProviDisplayAdGroupsInput'
import type { ApiReportsControllerProviDisplayCampaignsInput } from '../models/ApiReportsControllerProviDisplayCampaignsInput'
import {
  ApiReportsControllerProviDisplayCampaignsInputFromJSON,
  ApiReportsControllerProviDisplayCampaignsInputToJSON,
} from '../models/ApiReportsControllerProviDisplayCampaignsInput'
import type { ApiReportsControllerProviDisplayKeywordsInput } from '../models/ApiReportsControllerProviDisplayKeywordsInput'
import {
  ApiReportsControllerProviDisplayKeywordsInputFromJSON,
  ApiReportsControllerProviDisplayKeywordsInputToJSON,
} from '../models/ApiReportsControllerProviDisplayKeywordsInput'
import type { ApiReportsControllerProviDisplayProductLinesInput } from '../models/ApiReportsControllerProviDisplayProductLinesInput'
import {
  ApiReportsControllerProviDisplayProductLinesInputFromJSON,
  ApiReportsControllerProviDisplayProductLinesInputToJSON,
} from '../models/ApiReportsControllerProviDisplayProductLinesInput'
import type { ApiReportsControllerProviDisplayProductsInput } from '../models/ApiReportsControllerProviDisplayProductsInput'
import {
  ApiReportsControllerProviDisplayProductsInputFromJSON,
  ApiReportsControllerProviDisplayProductsInputToJSON,
} from '../models/ApiReportsControllerProviDisplayProductsInput'
import type { ApiReportsControllerProviSpAdGroupsInput } from '../models/ApiReportsControllerProviSpAdGroupsInput'
import {
  ApiReportsControllerProviSpAdGroupsInputFromJSON,
  ApiReportsControllerProviSpAdGroupsInputToJSON,
} from '../models/ApiReportsControllerProviSpAdGroupsInput'
import type { ApiReportsControllerProviSpCampaignsInput } from '../models/ApiReportsControllerProviSpCampaignsInput'
import {
  ApiReportsControllerProviSpCampaignsInputFromJSON,
  ApiReportsControllerProviSpCampaignsInputToJSON,
} from '../models/ApiReportsControllerProviSpCampaignsInput'
import type { ApiReportsControllerProviSpKeywordsInput } from '../models/ApiReportsControllerProviSpKeywordsInput'
import {
  ApiReportsControllerProviSpKeywordsInputFromJSON,
  ApiReportsControllerProviSpKeywordsInputToJSON,
} from '../models/ApiReportsControllerProviSpKeywordsInput'
import type { ApiReportsControllerProviSpProductLinesInput } from '../models/ApiReportsControllerProviSpProductLinesInput'
import {
  ApiReportsControllerProviSpProductLinesInputFromJSON,
  ApiReportsControllerProviSpProductLinesInputToJSON,
} from '../models/ApiReportsControllerProviSpProductLinesInput'
import type { ApiReportsControllerProviSpProductsInput } from '../models/ApiReportsControllerProviSpProductsInput'
import {
  ApiReportsControllerProviSpProductsInputFromJSON,
  ApiReportsControllerProviSpProductsInputToJSON,
} from '../models/ApiReportsControllerProviSpProductsInput'
import type { ApiReportsControllerProviVideoAdGroupsInput } from '../models/ApiReportsControllerProviVideoAdGroupsInput'
import {
  ApiReportsControllerProviVideoAdGroupsInputFromJSON,
  ApiReportsControllerProviVideoAdGroupsInputToJSON,
} from '../models/ApiReportsControllerProviVideoAdGroupsInput'
import type { ApiReportsControllerProviVideoCampaignsInput } from '../models/ApiReportsControllerProviVideoCampaignsInput'
import {
  ApiReportsControllerProviVideoCampaignsInputFromJSON,
  ApiReportsControllerProviVideoCampaignsInputToJSON,
} from '../models/ApiReportsControllerProviVideoCampaignsInput'
import type { ApiReportsControllerProviVideoKeywordsInput } from '../models/ApiReportsControllerProviVideoKeywordsInput'
import {
  ApiReportsControllerProviVideoKeywordsInputFromJSON,
  ApiReportsControllerProviVideoKeywordsInputToJSON,
} from '../models/ApiReportsControllerProviVideoKeywordsInput'
import type { ApiReportsControllerProviVideoProductsInput } from '../models/ApiReportsControllerProviVideoProductsInput'
import {
  ApiReportsControllerProviVideoProductsInputFromJSON,
  ApiReportsControllerProviVideoProductsInputToJSON,
} from '../models/ApiReportsControllerProviVideoProductsInput'
import type { ApiReportsControllerReservationCampaignsInput } from '../models/ApiReportsControllerReservationCampaignsInput'
import {
  ApiReportsControllerReservationCampaignsInputFromJSON,
  ApiReportsControllerReservationCampaignsInputToJSON,
} from '../models/ApiReportsControllerReservationCampaignsInput'
import type { ApiReportsControllerReservationDatesInput } from '../models/ApiReportsControllerReservationDatesInput'
import {
  ApiReportsControllerReservationDatesInputFromJSON,
  ApiReportsControllerReservationDatesInputToJSON,
} from '../models/ApiReportsControllerReservationDatesInput'
import type { ApiReportsControllerReservationsInput } from '../models/ApiReportsControllerReservationsInput'
import {
  ApiReportsControllerReservationsInputFromJSON,
  ApiReportsControllerReservationsInputToJSON,
} from '../models/ApiReportsControllerReservationsInput'
import type { ApiReportsControllerSearchTermDisplayProductInput } from '../models/ApiReportsControllerSearchTermDisplayProductInput'
import {
  ApiReportsControllerSearchTermDisplayProductInputFromJSON,
  ApiReportsControllerSearchTermDisplayProductInputToJSON,
} from '../models/ApiReportsControllerSearchTermDisplayProductInput'
import type { ApiReportsControllerSearchTermVideoInput } from '../models/ApiReportsControllerSearchTermVideoInput'
import {
  ApiReportsControllerSearchTermVideoInputFromJSON,
  ApiReportsControllerSearchTermVideoInputToJSON,
} from '../models/ApiReportsControllerSearchTermVideoInput'
import type { ApiReportsControllerVideoAdGroupsInput } from '../models/ApiReportsControllerVideoAdGroupsInput'
import {
  ApiReportsControllerVideoAdGroupsInputFromJSON,
  ApiReportsControllerVideoAdGroupsInputToJSON,
} from '../models/ApiReportsControllerVideoAdGroupsInput'
import type { ApiReportsControllerVideoCampaignsInput } from '../models/ApiReportsControllerVideoCampaignsInput'
import {
  ApiReportsControllerVideoCampaignsInputFromJSON,
  ApiReportsControllerVideoCampaignsInputToJSON,
} from '../models/ApiReportsControllerVideoCampaignsInput'
import type { ApiReportsControllerVideoKeywordsInput } from '../models/ApiReportsControllerVideoKeywordsInput'
import {
  ApiReportsControllerVideoKeywordsInputFromJSON,
  ApiReportsControllerVideoKeywordsInputToJSON,
} from '../models/ApiReportsControllerVideoKeywordsInput'
import type { ApiReportsControllerVideoMrcCampaignsInput } from '../models/ApiReportsControllerVideoMrcCampaignsInput'
import {
  ApiReportsControllerVideoMrcCampaignsInputFromJSON,
  ApiReportsControllerVideoMrcCampaignsInputToJSON,
} from '../models/ApiReportsControllerVideoMrcCampaignsInput'
import type { ApiReportsControllerVideoProductsInput } from '../models/ApiReportsControllerVideoProductsInput'
import {
  ApiReportsControllerVideoProductsInputFromJSON,
  ApiReportsControllerVideoProductsInputToJSON,
} from '../models/ApiReportsControllerVideoProductsInput'
import type { DownloadReportsResponse } from '../models/DownloadReportsResponse'
import {
  DownloadReportsResponseFromJSON,
  DownloadReportsResponseToJSON,
} from '../models/DownloadReportsResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { IndexReportsResponse } from '../models/IndexReportsResponse'
import {
  IndexReportsResponseFromJSON,
  IndexReportsResponseToJSON,
} from '../models/IndexReportsResponse'
import type { PostReportResponse } from '../models/PostReportResponse'
import { PostReportResponseFromJSON, PostReportResponseToJSON } from '../models/PostReportResponse'
import type { ShowReportInProgressResponse } from '../models/ShowReportInProgressResponse'
import {
  ShowReportInProgressResponseFromJSON,
  ShowReportInProgressResponseToJSON,
} from '../models/ShowReportInProgressResponse'
import type { ShowReportResponse } from '../models/ShowReportResponse'
import { ShowReportResponseFromJSON, ShowReportResponseToJSON } from '../models/ShowReportResponse'

export interface GetReportsRequest {
  page?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetReportsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetReportsIdDownloadRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetReportsIdDownloadUrlRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostReportsAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerAdGroupsInput
}

export interface PostReportsBrandPagesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerBrandPagesInput
}

export interface PostReportsCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerCampaignsInput
}

export interface PostReportsDisplayAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerDisplayAdGroupsInput
}

export interface PostReportsDisplayCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerDisplayCampaignsInput
}

export interface PostReportsDisplayKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerDisplayKeywordsInput
}

export interface PostReportsDisplayProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerDisplayProductsInput
}

export interface PostReportsImpressionAndClicksDisplayProductRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerImpressionAndClicksDisplayProductInput
}

export interface PostReportsImpressionAndClicksSponsoredProductRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerImpressionAndClicksSponsoredProductInput
}

export interface PostReportsInsightsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerInsightsInput
}

export interface PostReportsKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerKeywordsInput
}

export interface PostReportsLiftSummaryRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerLiftSummaryInput
}

export interface PostReportsLiftVariantRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerLiftVariantInput
}

export interface PostReportsProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProductsInput
}

export interface PostReportsPromotionCouponsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerPromotionCouponsInput
}

export interface PostReportsPromotionGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerPromotionGroupsInput
}

export interface PostReportsPromotionProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerPromotionProductsInput
}

export interface PostReportsProviDisplayAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviDisplayAdGroupsInput
}

export interface PostReportsProviDisplayCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviDisplayCampaignsInput
}

export interface PostReportsProviDisplayKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviDisplayKeywordsInput
}

export interface PostReportsProviDisplayProductLinesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviDisplayProductLinesInput
}

export interface PostReportsProviDisplayProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviDisplayProductsInput
}

export interface PostReportsProviSpAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviSpAdGroupsInput
}

export interface PostReportsProviSpCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviSpCampaignsInput
}

export interface PostReportsProviSpKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviSpKeywordsInput
}

export interface PostReportsProviSpProductLinesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviSpProductLinesInput
}

export interface PostReportsProviSpProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviSpProductsInput
}

export interface PostReportsProviVideoAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviVideoAdGroupsInput
}

export interface PostReportsProviVideoCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviVideoCampaignsInput
}

export interface PostReportsProviVideoKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviVideoKeywordsInput
}

export interface PostReportsProviVideoProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerProviVideoProductsInput
}

export interface PostReportsReservationCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerReservationCampaignsInput
}

export interface PostReportsReservationDatesRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerReservationDatesInput
}

export interface PostReportsReservationsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerReservationsInput
}

export interface PostReportsSearchTermDisplayProductRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerSearchTermDisplayProductInput
}

export interface PostReportsSearchTermVideoRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerSearchTermVideoInput
}

export interface PostReportsVideoAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerVideoAdGroupsInput
}

export interface PostReportsVideoCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerVideoCampaignsInput
}

export interface PostReportsVideoKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerVideoKeywordsInput
}

export interface PostReportsVideoMrcCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerVideoMrcCampaignsInput
}

export interface PostReportsVideoProductsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiReportsControllerVideoProductsInput
}

/**
 *
 */
export class ReportsApi extends runtime.BaseAPI {
  /**
   * This method will return a list of completed reports tied to a user.
   * Returns all reports
   */
  async getReportsRaw(
    requestParameters: GetReportsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexReportsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      IndexReportsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return a list of completed reports tied to a user.
   * Returns all reports
   */
  async getReports(
    requestParameters: GetReportsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexReportsResponse> {
    const response = await this.getReportsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use GET /reports/:id to check the status of any report you requested, hitting the status_URL returned by POST /reports/keywords or POST /reports/products. When successfully called, this endpoint returns the status of the report — either a 202 for reports that are still being generated or a 200 for reports that have been completed.   When the report has been generated and this endpoint returns a 200 status, it will also return the “location” field with a URL to where the report can be downloaded, and several other pieces of metadata. You’ll need this value for GET /reports/download.
   * Returns status of a report
   */
  async getReportsIdRaw(
    requestParameters: GetReportsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowReportResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getReportsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ShowReportResponseFromJSON(jsonValue))
  }

  /**
   * Use GET /reports/:id to check the status of any report you requested, hitting the status_URL returned by POST /reports/keywords or POST /reports/products. When successfully called, this endpoint returns the status of the report — either a 202 for reports that are still being generated or a 200 for reports that have been completed.   When the report has been generated and this endpoint returns a 200 status, it will also return the “location” field with a URL to where the report can be downloaded, and several other pieces of metadata. You’ll need this value for GET /reports/download.
   * Returns status of a report
   */
  async getReportsId(
    requestParameters: GetReportsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowReportResponse> {
    const response = await this.getReportsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use GET /reports/download to download your generated report, hitting the URL returned in the “location” field by GET /reports/:id. When successfully called, it returns the generated report as a series of comma-separated values.
   * Download a report
   */
  async getReportsIdDownloadRaw(
    requestParameters: GetReportsIdDownloadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DownloadReportsResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getReportsIdDownload.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/{id}/download`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DownloadReportsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use GET /reports/download to download your generated report, hitting the URL returned in the “location” field by GET /reports/:id. When successfully called, it returns the generated report as a series of comma-separated values.
   * Download a report
   */
  async getReportsIdDownload(
    requestParameters: GetReportsIdDownloadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DownloadReportsResponse> {
    const response = await this.getReportsIdDownloadRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use GET /reports/:id/download_url to get the URL to download your generated report. When successfully called it redirects the client to the s3 presigned url to download the report.
   * Redirect the client to the report download URL
   */
  async getReportsIdDownloadUrlRaw(
    requestParameters: GetReportsIdDownloadUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getReportsIdDownloadUrl.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/{id}/download_url`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Use GET /reports/:id/download_url to get the URL to download your generated report. When successfully called it redirects the client to the s3 presigned url to download the report.
   * Redirect the client to the report download URL
   */
  async getReportsIdDownloadUrl(
    requestParameters: GetReportsIdDownloadUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.getReportsIdDownloadUrlRaw(requestParameters, initOverrides)
  }

  /**
   * Use POST /reports/ad_groups to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request an ad groups report
   */
  async postReportsAdGroupsRaw(
    requestParameters: PostReportsAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/ad_groups to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request an ad groups report
   */
  async postReportsAdGroups(
    requestParameters: PostReportsAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a brand pages report
   */
  async postReportsBrandPagesRaw(
    requestParameters: PostReportsBrandPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/brand_pages`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerBrandPagesInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a brand pages report
   */
  async postReportsBrandPages(
    requestParameters: PostReportsBrandPagesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsBrandPagesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/campaigns to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a campaigns report
   */
  async postReportsCampaignsRaw(
    requestParameters: PostReportsCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/campaigns to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a campaigns report
   */
  async postReportsCampaigns(
    requestParameters: PostReportsCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/display_ad_groups to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request an display ad groups report
   */
  async postReportsDisplayAdGroupsRaw(
    requestParameters: PostReportsDisplayAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/display_ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerDisplayAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/display_ad_groups to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request an display ad groups report
   */
  async postReportsDisplayAdGroups(
    requestParameters: PostReportsDisplayAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsDisplayAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/display_campaigns to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/display_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display campaigns report
   */
  async postReportsDisplayCampaignsRaw(
    requestParameters: PostReportsDisplayCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/display_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerDisplayCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/display_campaigns to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/display_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display campaigns report
   */
  async postReportsDisplayCampaigns(
    requestParameters: PostReportsDisplayCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsDisplayCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/display_keywords to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display keywords report
   */
  async postReportsDisplayKeywordsRaw(
    requestParameters: PostReportsDisplayKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/display_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerDisplayKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/display_keywords to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display keywords report
   */
  async postReportsDisplayKeywords(
    requestParameters: PostReportsDisplayKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsDisplayKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/display_products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display products report
   */
  async postReportsDisplayProductsRaw(
    requestParameters: PostReportsDisplayProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/display_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerDisplayProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/display_products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/display_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a display products report
   */
  async postReportsDisplayProducts(
    requestParameters: PostReportsDisplayProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsDisplayProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request an Impression & Clicks report for Display Products
   */
  async postReportsImpressionAndClicksDisplayProductRaw(
    requestParameters: PostReportsImpressionAndClicksDisplayProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/impression_and_clicks_display_product`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerImpressionAndClicksDisplayProductInputToJSON(
          requestParameters.body
        ),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request an Impression & Clicks report for Display Products
   */
  async postReportsImpressionAndClicksDisplayProduct(
    requestParameters: PostReportsImpressionAndClicksDisplayProductRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsImpressionAndClicksDisplayProductRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   *
   * Request an Impression & Clicks report for Sponsored Products
   */
  async postReportsImpressionAndClicksSponsoredProductRaw(
    requestParameters: PostReportsImpressionAndClicksSponsoredProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/impression_and_clicks_sponsored_product`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerImpressionAndClicksSponsoredProductInputToJSON(
          requestParameters.body
        ),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request an Impression & Clicks report for Sponsored Products
   */
  async postReportsImpressionAndClicksSponsoredProduct(
    requestParameters: PostReportsImpressionAndClicksSponsoredProductRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsImpressionAndClicksSponsoredProductRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Use POST /reports/insights to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report.
   * Request an insights report
   */
  async postReportsInsightsRaw(
    requestParameters: PostReportsInsightsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/insights`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerInsightsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/insights to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report.
   * Request an insights report
   */
  async postReportsInsights(
    requestParameters: PostReportsInsightsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsInsightsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/keywords to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a keywords report
   */
  async postReportsKeywordsRaw(
    requestParameters: PostReportsKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/keywords to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a keywords report
   */
  async postReportsKeywords(
    requestParameters: PostReportsKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a lift summary report
   */
  async postReportsLiftSummaryRaw(
    requestParameters: PostReportsLiftSummaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/lift_summary`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerLiftSummaryInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a lift summary report
   */
  async postReportsLiftSummary(
    requestParameters: PostReportsLiftSummaryRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsLiftSummaryRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a lift variant report
   */
  async postReportsLiftVariantRaw(
    requestParameters: PostReportsLiftVariantRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/lift_variant`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerLiftVariantInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a lift variant report
   */
  async postReportsLiftVariant(
    requestParameters: PostReportsLiftVariantRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsLiftVariantRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a products report
   */
  async postReportsProductsRaw(
    requestParameters: PostReportsProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a products report
   */
  async postReportsProducts(
    requestParameters: PostReportsProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a promotion coupon report
   */
  async postReportsPromotionCouponsRaw(
    requestParameters: PostReportsPromotionCouponsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/promotion_coupons`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerPromotionCouponsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a promotion coupon report
   */
  async postReportsPromotionCoupons(
    requestParameters: PostReportsPromotionCouponsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsPromotionCouponsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a promotion group report
   */
  async postReportsPromotionGroupsRaw(
    requestParameters: PostReportsPromotionGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/promotion_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerPromotionGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a promotion group report
   */
  async postReportsPromotionGroups(
    requestParameters: PostReportsPromotionGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsPromotionGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a promotion product report
   */
  async postReportsPromotionProductsRaw(
    requestParameters: PostReportsPromotionProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/promotion_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerPromotionProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a promotion product report
   */
  async postReportsPromotionProducts(
    requestParameters: PostReportsPromotionProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsPromotionProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display ad groups report
   */
  async postReportsProviDisplayAdGroupsRaw(
    requestParameters: PostReportsProviDisplayAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_display_ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviDisplayAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display ad groups report
   */
  async postReportsProviDisplayAdGroups(
    requestParameters: PostReportsProviDisplayAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviDisplayAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/provi_display_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_display_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display campaigns report
   */
  async postReportsProviDisplayCampaignsRaw(
    requestParameters: PostReportsProviDisplayCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_display_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviDisplayCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/provi_display_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_display_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display campaigns report
   */
  async postReportsProviDisplayCampaigns(
    requestParameters: PostReportsProviDisplayCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviDisplayCampaignsRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display keywords report
   */
  async postReportsProviDisplayKeywordsRaw(
    requestParameters: PostReportsProviDisplayKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_display_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviDisplayKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display keywords report
   */
  async postReportsProviDisplayKeywords(
    requestParameters: PostReportsProviDisplayKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviDisplayKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_product_line returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display Ad Group Product Line report
   */
  async postReportsProviDisplayProductLinesRaw(
    requestParameters: PostReportsProviDisplayProductLinesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_display_product_lines`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviDisplayProductLinesInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_product_line returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display Ad Group Product Line report
   */
  async postReportsProviDisplayProductLines(
    requestParameters: PostReportsProviDisplayProductLinesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviDisplayProductLinesRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display products report
   */
  async postReportsProviDisplayProductsRaw(
    requestParameters: PostReportsProviDisplayProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_display_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviDisplayProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_display_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi Display products report
   */
  async postReportsProviDisplayProducts(
    requestParameters: PostReportsProviDisplayProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviDisplayProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad groups report
   */
  async postReportsProviSpAdGroupsRaw(
    requestParameters: PostReportsProviSpAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_sp_ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviSpAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad groups report
   */
  async postReportsProviSpAdGroups(
    requestParameters: PostReportsProviSpAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviSpAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/provi_sp_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_sp_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP campaigns report
   */
  async postReportsProviSpCampaignsRaw(
    requestParameters: PostReportsProviSpCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_sp_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviSpCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/provi_sp_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_sp_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP campaigns report
   */
  async postReportsProviSpCampaigns(
    requestParameters: PostReportsProviSpCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviSpCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad groups keywords report
   */
  async postReportsProviSpKeywordsRaw(
    requestParameters: PostReportsProviSpKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_sp_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviSpKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad groups keywords report
   */
  async postReportsProviSpKeywords(
    requestParameters: PostReportsProviSpKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviSpKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_product_lines returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP Ad Group Product Line report
   */
  async postReportsProviSpProductLinesRaw(
    requestParameters: PostReportsProviSpProductLinesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_sp_product_lines`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviSpProductLinesInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_product_lines returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP Ad Group Product Line report
   */
  async postReportsProviSpProductLines(
    requestParameters: PostReportsProviSpProductLinesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviSpProductLinesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad group products report
   */
  async postReportsProviSpProductsRaw(
    requestParameters: PostReportsProviSpProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_sp_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviSpProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_sp_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a Provi SP ad group products report
   */
  async postReportsProviSpProducts(
    requestParameters: PostReportsProviSpProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviSpProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_ad_groups returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video ad groups report
   */
  async postReportsProviVideoAdGroupsRaw(
    requestParameters: PostReportsProviVideoAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_video_ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviVideoAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_ad_groups returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video ad groups report
   */
  async postReportsProviVideoAdGroups(
    requestParameters: PostReportsProviVideoAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviVideoAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/provi_video_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_video_campaigns returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video campaigns report
   */
  async postReportsProviVideoCampaignsRaw(
    requestParameters: PostReportsProviVideoCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_video_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviVideoCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/provi_video_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/provi_video_campaigns returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video campaigns report
   */
  async postReportsProviVideoCampaigns(
    requestParameters: PostReportsProviVideoCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviVideoCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_keywords returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video keywords report
   */
  async postReportsProviVideoKeywordsRaw(
    requestParameters: PostReportsProviVideoKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_video_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviVideoKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_keywords returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video keywords report
   */
  async postReportsProviVideoKeywords(
    requestParameters: PostReportsProviVideoKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviVideoKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_products returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video products report
   */
  async postReportsProviVideoProductsRaw(
    requestParameters: PostReportsProviVideoProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/provi_video_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerProviVideoProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API r eturns all the report data Instacart Ads has for your account.   When successfully called, /reports/provi_video_products returns the URL you\'ll need to hit to retrieve the status of your report, as well as your request\'s ID and other metadata.
   * Request a Provi Video products report
   */
  async postReportsProviVideoProducts(
    requestParameters: PostReportsProviVideoProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsProviVideoProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a reservation campaigns report
   */
  async postReportsReservationCampaignsRaw(
    requestParameters: PostReportsReservationCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/reservation_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerReservationCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a reservation campaigns report
   */
  async postReportsReservationCampaigns(
    requestParameters: PostReportsReservationCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsReservationCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a reservation dates report
   */
  async postReportsReservationDatesRaw(
    requestParameters: PostReportsReservationDatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/reservation_dates`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerReservationDatesInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a reservation dates report
   */
  async postReportsReservationDates(
    requestParameters: PostReportsReservationDatesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsReservationDatesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a reservations report
   */
  async postReportsReservationsRaw(
    requestParameters: PostReportsReservationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/reservations`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerReservationsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a reservations report
   */
  async postReportsReservations(
    requestParameters: PostReportsReservationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsReservationsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request a Search Term report for Display Products
   */
  async postReportsSearchTermDisplayProductRaw(
    requestParameters: PostReportsSearchTermDisplayProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/search_term_display_product`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerSearchTermDisplayProductInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a Search Term report for Display Products
   */
  async postReportsSearchTermDisplayProduct(
    requestParameters: PostReportsSearchTermDisplayProductRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsSearchTermDisplayProductRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   *
   * Request a Search Term report for video
   */
  async postReportsSearchTermVideoRaw(
    requestParameters: PostReportsSearchTermVideoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/search_term_video`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerSearchTermVideoInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request a Search Term report for video
   */
  async postReportsSearchTermVideo(
    requestParameters: PostReportsSearchTermVideoRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsSearchTermVideoRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video ad groups report
   */
  async postReportsVideoAdGroupsRaw(
    requestParameters: PostReportsVideoAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/video_ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerVideoAdGroupsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_ad_groups returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video ad groups report
   */
  async postReportsVideoAdGroups(
    requestParameters: PostReportsVideoAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsVideoAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/video_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/video_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video campaigns report
   */
  async postReportsVideoCampaignsRaw(
    requestParameters: PostReportsVideoCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/video_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerVideoCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/video_campaigns to request a new report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.  When successfully called, /reports/video_campaigns returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video campaigns report
   */
  async postReportsVideoCampaigns(
    requestParameters: PostReportsVideoCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsVideoCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video keywords report
   */
  async postReportsVideoKeywordsRaw(
    requestParameters: PostReportsVideoKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/video_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerVideoKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_keywords returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video keywords report
   */
  async postReportsVideoKeywords(
    requestParameters: PostReportsVideoKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsVideoKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Request an Impression & Clicks report for video
   */
  async postReportsVideoMrcCampaignsRaw(
    requestParameters: PostReportsVideoMrcCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/video_mrc_campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerVideoMrcCampaignsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   *
   * Request an Impression & Clicks report for video
   */
  async postReportsVideoMrcCampaigns(
    requestParameters: PostReportsVideoMrcCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsVideoMrcCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /reports/video_products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video products report
   */
  async postReportsVideoProductsRaw(
    requestParameters: PostReportsVideoProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostReportResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/reports/video_products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiReportsControllerVideoProductsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PostReportResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /reports/video_products to request a new report. There are several optional parameters that you can send to specify what data you want returned on the report. If you do not specify any parameters, the API returns all the report data Instacart Ads has for your account.   When successfully called, /reports/video_products returns the URL you’ll need to hit to retrieve the status of your report, as well as your request’s ID and other metadata.
   * Request a video products report
   */
  async postReportsVideoProducts(
    requestParameters: PostReportsVideoProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostReportResponse> {
    const response = await this.postReportsVideoProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
