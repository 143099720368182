// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AdminBrandPagesResponseMeta
 */
export interface AdminBrandPagesResponseMeta {
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof AdminBrandPagesResponseMeta
   */
  status: number
  /**
   * Request UUID
   * @type {string}
   * @memberof AdminBrandPagesResponseMeta
   */
  requestUuid: string
  /**
   * Number of total pages
   * @type {number}
   * @memberof AdminBrandPagesResponseMeta
   */
  totalPages: number
  /**
   * Current page
   * @type {number}
   * @memberof AdminBrandPagesResponseMeta
   */
  currentPage: number
  /**
   * Total count
   * @type {number}
   * @memberof AdminBrandPagesResponseMeta
   */
  totalCount: number
}

/**
 * Check if a given object implements the AdminBrandPagesResponseMeta interface.
 */
export function instanceOfAdminBrandPagesResponseMeta(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'requestUuid' in value
  isInstance = isInstance && 'totalPages' in value
  isInstance = isInstance && 'currentPage' in value
  isInstance = isInstance && 'totalCount' in value

  return isInstance
}

export function AdminBrandPagesResponseMetaFromJSON(json: any): AdminBrandPagesResponseMeta {
  return AdminBrandPagesResponseMetaFromJSONTyped(json, false)
}

export function AdminBrandPagesResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminBrandPagesResponseMeta {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    requestUuid: json['request_uuid'],
    totalPages: json['total_pages'],
    currentPage: json['current_page'],
    totalCount: json['total_count'],
  }
}

export function AdminBrandPagesResponseMetaToJSON(value?: AdminBrandPagesResponseMeta | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    request_uuid: value.requestUuid,
    total_pages: value.totalPages,
    current_page: value.currentPage,
    total_count: value.totalCount,
  }
}
