// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues } from './PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { PutDisplayCampaignsIdParamCreativeFieldDecisionsTagline } from './PutDisplayCampaignsIdParamCreativeFieldDecisionsTagline'
import {
  PutDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSON,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSONTyped,
  PutDisplayCampaignsIdParamCreativeFieldDecisionsTaglineToJSON,
} from './PutDisplayCampaignsIdParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamCreativeFieldDecisions
 */
export interface PutDisplayCampaignsIdParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof PutDisplayCampaignsIdParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {PutDisplayCampaignsIdParamCreativeFieldDecisionsTagline}
   * @memberof PutDisplayCampaignsIdParamCreativeFieldDecisions
   */
  tagline?: PutDisplayCampaignsIdParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamCreativeFieldDecisions interface.
 */
export function instanceOfPutDisplayCampaignsIdParamCreativeFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSON(
  json: any
): PutDisplayCampaignsIdParamCreativeFieldDecisions {
  return PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : PutDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function PutDisplayCampaignsIdParamCreativeFieldDecisionsToJSON(
  value?: PutDisplayCampaignsIdParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            PutDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: PutDisplayCampaignsIdParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
