// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick } from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick } from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick'
import type { DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick } from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'
import {
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
 */
export interface DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  urlClick?: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfDisplayAdGroupResponseDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  return DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(json, false)
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickToJSON(
  value?: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click: DisplayAdGroupResponseDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
      value.recipeClick
    ),
  }
}
