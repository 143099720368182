// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostDisplayAdGroupsParamProductCollection
 */
export interface PostDisplayAdGroupsParamProductCollection {
  /**
   *
   * @type {string}
   * @memberof PostDisplayAdGroupsParamProductCollection
   */
  type: PostDisplayAdGroupsParamProductCollectionTypeEnum
  /**
   * Products IDs
   * @type {Array<string>}
   * @memberof PostDisplayAdGroupsParamProductCollection
   */
  itemGrid: Array<string>
}

/**
 * @export
 */
export const PostDisplayAdGroupsParamProductCollectionTypeEnum = {
  ProductCollection: 'product_collection',
} as const
export type PostDisplayAdGroupsParamProductCollectionTypeEnum =
  typeof PostDisplayAdGroupsParamProductCollectionTypeEnum[keyof typeof PostDisplayAdGroupsParamProductCollectionTypeEnum]

/**
 * Check if a given object implements the PostDisplayAdGroupsParamProductCollection interface.
 */
export function instanceOfPostDisplayAdGroupsParamProductCollection(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'itemGrid' in value

  return isInstance
}

export function PostDisplayAdGroupsParamProductCollectionFromJSON(
  json: any
): PostDisplayAdGroupsParamProductCollection {
  return PostDisplayAdGroupsParamProductCollectionFromJSONTyped(json, false)
}

export function PostDisplayAdGroupsParamProductCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostDisplayAdGroupsParamProductCollection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    itemGrid: json['item_grid'],
  }
}

export function PostDisplayAdGroupsParamProductCollectionToJSON(
  value?: PostDisplayAdGroupsParamProductCollection | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    item_grid: value.itemGrid,
  }
}
