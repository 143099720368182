import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import { ModalButtons, ModalContent } from 'components/ids-ads'
import { MfaFactor } from 'components/MultiFactorAuth/utils/multiFactorAuth.types'

const useStyles = () => {
  const theme = useTheme()

  return {
    bodyText: css({
      ...theme.typography.bodyMedium2,
      color: theme.colors.systemGrayscale50,
    }),
    secretText: css({
      ...theme.typography.bodyMedium1,
      paddingTop: spacing.s16,
    }),
  }
}

interface AuthenticatorManualSetupProps {
  mfaFactor: MfaFactor
  onClick: () => void
}

export default function AuthenticatorManualSetup({
  mfaFactor,
  onClick,
}: AuthenticatorManualSetupProps) {
  const styles = useStyles()
  const { provisioningUrl } = mfaFactor

  return (
    <>
      {provisioningUrl && (
        <>
          <ModalContent>
            <div css={styles.bodyText}>
              <FormattedMessage id="pages.multiFactorAuth.setup.authenticator.description" />
            </div>
            <div css={styles.secretText} data-testid="mfa-authenticator-secret">
              <p>
                {provisioningUrl.substring(
                  provisioningUrl.indexOf('secret=') + 7,
                  provisioningUrl.lastIndexOf('&issuer')
                )}
              </p>
            </div>
          </ModalContent>
          <ModalButtons
            onClose={onClick}
            primaryButtonProps={{ type: 'submit', onClick }}
            primaryButtonLabel="common.next"
            secondaryButtonLabel="common.back"
            primaryTestId="mfa-authenticator-manual-setup-confirm-button"
            secondaryTestId="mfa-authenticator-manual-setup-cancel-button"
          />
        </>
      )}
    </>
  )
}
