// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdUsersSessionsControllerCreateWithMfaInput
 */
export interface ApiAdUsersSessionsControllerCreateWithMfaInput {
  /**
   * Multi-Factor Auth type
   * @type {string}
   * @memberof ApiAdUsersSessionsControllerCreateWithMfaInput
   */
  mfaType: ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum
  /**
   * One-time password for MFA validation
   * @type {string}
   * @memberof ApiAdUsersSessionsControllerCreateWithMfaInput
   */
  otp: string
  /**
   * Remember me
   * @type {boolean}
   * @memberof ApiAdUsersSessionsControllerCreateWithMfaInput
   */
  rememberMe?: boolean
  /**
   * Remember device for MFA verification
   * @type {boolean}
   * @memberof ApiAdUsersSessionsControllerCreateWithMfaInput
   */
  rememberDevice?: boolean
}

/**
 * @export
 */
export const ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum = {
  Authenticator: 'authenticator',
  Email: 'email',
} as const
export type ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum =
  typeof ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum[keyof typeof ApiAdUsersSessionsControllerCreateWithMfaInputMfaTypeEnum]

/**
 * Check if a given object implements the ApiAdUsersSessionsControllerCreateWithMfaInput interface.
 */
export function instanceOfApiAdUsersSessionsControllerCreateWithMfaInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'mfaType' in value
  isInstance = isInstance && 'otp' in value

  return isInstance
}

export function ApiAdUsersSessionsControllerCreateWithMfaInputFromJSON(
  json: any
): ApiAdUsersSessionsControllerCreateWithMfaInput {
  return ApiAdUsersSessionsControllerCreateWithMfaInputFromJSONTyped(json, false)
}

export function ApiAdUsersSessionsControllerCreateWithMfaInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdUsersSessionsControllerCreateWithMfaInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mfaType: json['mfa_type'],
    otp: json['otp'],
    rememberMe: !exists(json, 'remember_me') ? undefined : json['remember_me'],
    rememberDevice: !exists(json, 'remember_device') ? undefined : json['remember_device'],
  }
}

export function ApiAdUsersSessionsControllerCreateWithMfaInputToJSON(
  value?: ApiAdUsersSessionsControllerCreateWithMfaInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mfa_type: value.mfaType,
    otp: value.otp,
    remember_me: value.rememberMe,
    remember_device: value.rememberDevice,
  }
}
