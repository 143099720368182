import { Placement } from '@popperjs/core'
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useIsPaymentStatusInactive } from 'common/accountPaymentStatus'
import { InputFieldRef } from 'common/types'
import { ClientError, formatDollar, getApiErrorMessages } from 'common/utils'
import FormattedDollar from 'components/FormattedDollar'
import NumericField, { unmask } from 'components/Forms/NumericField'
import { Tooltip } from 'components/molecules'
import { ShouldRevertFieldState, useRevertField } from 'hooks/useRevertField'
import { UpsertAdGroupKeywordsResponse } from 'service/types'
import FormNotification from './Forms/FormNotification'

interface BidFieldProps {
  name: string
  isAuthorizedToEdit: boolean
  handleUpdate: (
    updatedValue: number | string,
    // reference to input field that trigerred the update
    inputFieldRef?: RefObject<InputFieldRef>
  ) => Promise<void | UpsertAdGroupKeywordsResponse>
  shouldRevertFieldState?: ShouldRevertFieldState
  defaultBid: number
  disabled?: boolean
  fullWidth?: boolean
  enableOnChange?: boolean
  bidInputStyles?: React.CSSProperties
  toolTipPosition?: Placement
  tooltipError?: string
}

type EditableBidFieldProps = Omit<BidFieldProps, 'isAuthorizedToEdit'>

const EditableBidField: FunctionComponent<React.PropsWithChildren<EditableBidFieldProps>> = ({
  name,
  defaultBid = 0,
  handleUpdate,
  shouldRevertFieldState,
  enableOnChange,
  disabled,
  fullWidth,
  bidInputStyles = {},
  tooltipError,
  toolTipPosition = 'top',
}) => {
  const isPaymentStatusInactive = useIsPaymentStatusInactive()
  const cpc = formatDollar(defaultBid)
  const [loading, setLoading] = useState(false)
  const [previousBid, setPreviousBid] = useState<number>()
  const [error, setError] = useState<string | null>(null)
  const mounted = useRef(false)
  const bidInputRef = useRef<InputFieldRef | null>(null)

  const defaultInputStyle = {
    height: '35px',
    padding: '2px 3px',
    fontSize: '12px',
    textAlign: 'center' as AlignSetting,
    ...bidInputStyles,
  }
  const inputStyle = error
    ? {
        ...defaultInputStyle,
        backgroundColor: '#fde6eb',
        border: '1px solid #b30029',
      }
    : defaultInputStyle

  // If the bid value gets updated via some external source we should clear any errors
  useEffect(() => {
    setError(null)
  }, [cpc])

  useEffect(() => {
    if (error) {
      const bidInput = bidInputRef.current
      if (bidInput?.FormComponent?.input) bidInput.FormComponent.input.focus()
    }
  }, [error])

  useEffect(() => {
    if (tooltipError) {
      setError(tooltipError)
    }
  }, [tooltipError])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // handle bid revert
  useRevertField({
    previousValue: String(previousBid),
    field: bidInputRef,
    shouldRevertFieldState,
    formatValue: val => formatDollar(val as number),
  })

  useEffect(() => {
    if (defaultBid !== previousBid) {
      setPreviousBid(defaultBid)
    }
  }, [defaultBid, previousBid])

  const updateValue = async (value: string, inputFieldRef?: RefObject<InputFieldRef>) => {
    if (value === '') {
      return
    }

    setLoading(true)
    setError(null)
    const formattedBid = formatDollar(unmask(value))

    try {
      await handleUpdate(unmask(formattedBid), inputFieldRef)
      setPreviousBid(unmask(formattedBid))
    } catch (err) {
      const errMsg = getApiErrorMessages(err as ClientError)
      setError(errMsg.join('. '))
    } finally {
      if (mounted.current) {
        setLoading(false)
      }
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (enableOnChange && !disabled) updateValue(e.currentTarget.value, bidInputRef)
  }

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      updateValue(e.currentTarget.value, bidInputRef)
    }
  }

  const handleOnKeyDown = async (key: KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEY = 'Enter'

    if (key.key === ENTER_KEY && !disabled) {
      key.preventDefault()
      updateValue(key.currentTarget.value, bidInputRef)
    }
  }

  return (
    <Tooltip
      variant="legacy"
      renderTooltip={() => (
        <FormNotification status="red" height="50px">
          {error}
        </FormNotification>
      )}
      placement={toolTipPosition}
      isVisible={!!error}
      arrow={false}
    >
      <div className="bid-input" data-testid="bid-input">
        <NumericField
          disabled={isPaymentStatusInactive || loading || disabled}
          name={name}
          maskHint=""
          defaultValue={cpc}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          key={`keywordBid:${cpc}`}
          maskOptions={{ allowDecimal: true, decimalLimit: 2 }}
          style={{ width: fullWidth ? '100%' : '85px', padding: '7px 0px' }}
          ref={bidInputRef}
          inputStyle={inputStyle}
        />
      </div>
    </Tooltip>
  )
}

const BidField: FunctionComponent<React.PropsWithChildren<BidFieldProps>> = ({
  isAuthorizedToEdit,
  ...props
}) => {
  if (!isAuthorizedToEdit) {
    return (
      <div style={{ padding: '20px 0px' }}>
        <FormattedDollar>{props.defaultBid}</FormattedDollar>
      </div>
    )
  }

  return <EditableBidField {...props} />
}

export default BidField
