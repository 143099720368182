import { Global, css } from '@emotion/react'

interface UnauthenticatedLayoutStylesProps {
  color: string
}

export function UnauthenticatedLayoutStyles({ color }: UnauthenticatedLayoutStylesProps) {
  return (
    <Global
      styles={css`
        html,
        body,
        #__ads_root {
          background-color: ${color};
        }
      `}
    />
  )
}
