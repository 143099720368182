import { ReactNode } from 'react'
import { AuthenticatorMfaVerification } from 'components/MultiFactorAuth/components/AuthenticatorMfaVerification'
import { EmailMfaVerification } from 'components/MultiFactorAuth/components/EmailMfaVerification'
import { MfaVerificationProps, MultiFactorAuthType } from '../utils/multiFactorAuth.types'

export const MultiFactorAuthVerification = (props: MfaVerificationProps) => {
  const { mfaFactor } = props

  const multiFactorAuthVerificationContent: Record<MultiFactorAuthType, () => ReactNode> = {
    [MultiFactorAuthType.Email]: () => <EmailMfaVerification {...props} />,
    [MultiFactorAuthType.Authenticator]: () => <AuthenticatorMfaVerification {...props} />,
  }

  return <>{multiFactorAuthVerificationContent[mfaFactor.mfaType]()}</>
}
