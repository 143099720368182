// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchy } from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchy'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchyFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchyFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchyToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchy'
import type { PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValues } from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValues'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValuesFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValuesFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValuesToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValues'
import type { PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitle } from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitle'
import {
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitleFromJSON,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitleFromJSONTyped,
  PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitleToJSON,
} from './PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitle'

/**
 *
 * @export
 * @interface PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions
 */
export interface PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions
   */
  type: string
  /**
   *
   * @type {PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitle}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions
   */
  title?: PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitle
  /**
   * Brand page block hierarch
   * @type {Array<PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchy>}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions
   */
  hierarchy?: Array<PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchy>
  /**
   *
   * @type {{ [key: string]: PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValues; }}
   * @memberof PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions
   */
  taxonomy?: { [key: string]: PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValues }
}

/**
 * Check if a given object implements the PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions interface.
 */
export function instanceOfPutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSON(
  json: any
): PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions {
  return PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSONTyped(json, false)
}

export function PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    title: !exists(json, 'title')
      ? undefined
      : PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitleFromJSON(json['title']),
    hierarchy: !exists(json, 'hierarchy')
      ? undefined
      : (json['hierarchy'] as Array<any>).map(
          PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchyFromJSON
        ),
    taxonomy: !exists(json, 'taxonomy')
      ? undefined
      : mapValues(
          json['taxonomy'],
          PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValuesFromJSON
        ),
  }
}

export function PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsToJSON(
  value?: PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    title: PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTitleToJSON(value.title),
    hierarchy:
      value.hierarchy === undefined
        ? undefined
        : (value.hierarchy as Array<any>).map(
            PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsHierarchyToJSON
          ),
    taxonomy:
      value.taxonomy === undefined
        ? undefined
        : mapValues(
            value.taxonomy,
            PutDisplayAdGroupsIdEnableParamBrandPageFieldDecisionsTaxonomyValuesToJSON
          ),
  }
}
