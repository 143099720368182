// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdminAccountsParamDataAttributesRetailers } from './GetAdminAccountsParamDataAttributesRetailers'
import {
  GetAdminAccountsParamDataAttributesRetailersFromJSON,
  GetAdminAccountsParamDataAttributesRetailersFromJSONTyped,
  GetAdminAccountsParamDataAttributesRetailersToJSON,
} from './GetAdminAccountsParamDataAttributesRetailers'
import type { GetAdminAccountsResponseDataAttributesAlcoholStatePermissions } from './GetAdminAccountsResponseDataAttributesAlcoholStatePermissions'
import {
  GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSON,
  GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSONTyped,
  GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsToJSON,
} from './GetAdminAccountsResponseDataAttributesAlcoholStatePermissions'
import type { GetAdminAccountsResponseDataAttributesCorporateAddress } from './GetAdminAccountsResponseDataAttributesCorporateAddress'
import {
  GetAdminAccountsResponseDataAttributesCorporateAddressFromJSON,
  GetAdminAccountsResponseDataAttributesCorporateAddressFromJSONTyped,
  GetAdminAccountsResponseDataAttributesCorporateAddressToJSON,
} from './GetAdminAccountsResponseDataAttributesCorporateAddress'

/**
 *
 * @export
 * @interface GetAdminAccountsResponseDataAttributes
 */
export interface GetAdminAccountsResponseDataAttributes {
  /**
   * Account Name
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  name: string
  /**
   * Default bill payer for the account
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  billPayer: string
  /**
   * Account agency Id
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  agencyId: string
  /**
   * Account agency numeric Id
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  agencyNumericId: number
  /**
   * Account agency name
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  agencyName: string
  /**
   * Account advertiser Id
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  advertiserId: string
  /**
   * Account advertiser numeric Id
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  advertiserNumericId: number
  /**
   * Account advertiser name
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  advertiserName: string
  /**
   * Ads taxonomy reporting entity id
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityId: number
  /**
   * Ads taxonomy reporting entity name
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityName: string
  /**
   * Ads taxonomy reporting entity level
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityLevel: string
  /**
   * Whether to dissociate a taxonomy from an account
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  dissociateAdsTaxonomy?: boolean
  /**
   * Product search strategy
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  productSearchStrategy?: string
  /**
   * Account Invoicing type aka payment type: instacart_invoicing or credit_card
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  paymentOption: GetAdminAccountsResponseDataAttributesPaymentOptionEnum
  /**
   * Account invoice option: portal or email
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  invoiceOption: GetAdminAccountsResponseDataAttributesInvoiceOptionEnum
  /**
   * Payment term in days: 30, 45 or 60
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  paymentTermDays: GetAdminAccountsResponseDataAttributesPaymentTermDaysEnum
  /**
   * Comma separated list of emails for email invoicing
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  emailDistribution: string
  /**
   *
   * @type {GetAdminAccountsResponseDataAttributesCorporateAddress}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  corporateAddress: GetAdminAccountsResponseDataAttributesCorporateAddress
  /**
   * Retailers linked to this account if the account is a retailer account
   * @type {Array<GetAdminAccountsParamDataAttributesRetailers>}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  retailers?: Array<GetAdminAccountsParamDataAttributesRetailers>
  /**
   * Updated account id
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  accountId?: number
  /**
   * Url to agency service agreement document
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  agencyMsa?: string
  /**
   * Url to advertiser service agreement document
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  advertiserMsa?: string
  /**
   * Account sells alcohol products
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  alcohol?: boolean
  /**
   * Brand identifies as black-owned
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  blackOwnedBusiness?: boolean
  /**
   * Brand identifies as woman-owned
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  womanOwnedBusiness?: boolean
  /**
   * Country code for Account's ads
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  countryCode?: string
  /**
   * Country id for account
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  countryId?: number
  /**
   * Currency for Account
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  currency?: string
  /**
   * Is test account
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  isTest?: boolean
  /**
   * Is sandbox account
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  isSandbox?: boolean
  /**
   * Is in house account
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  isInHouse?: boolean
  /**
   *
   * @type {GetAdminAccountsResponseDataAttributesAlcoholStatePermissions}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  alcoholStatePermissions?: GetAdminAccountsResponseDataAttributesAlcoholStatePermissions
  /**
   * Account credit threshold cents
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  creditThresholdCents?: number
  /**
   * Scheduled payment option information, if exists
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  nextPaymentOption?: string
  /**
   * Lock the credit threshold
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  lockCreditThreshold?: boolean
  /**
   * Account Type
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  accountType?: GetAdminAccountsResponseDataAttributesAccountTypeEnum
  /**
   * Serving Type: marketplace_only, syndication_network, marketplace_and_syndication(default)
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  servingType?: GetAdminAccountsResponseDataAttributesServingTypeEnum
  /**
   * An array of serving groups to target users for ads serving.
   * @type {Array<string>}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  servingGroups?: Array<GetAdminAccountsResponseDataAttributesServingGroupsEnum>
  /**
   * The maximum daily budget configured for this account, in cents.
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  dailyBudgetCapCents?: number
  /**
   * The maximum lifetime budget configured for this account, in cents.
   * @type {number}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  lifetimeBudgetCapCents?: number
  /**
   * Whether the account is non billable
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  nonBillable?: boolean
  /**
   * Whether internal users should be auto assigned Account Admin role on this account
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  autoAssignInternalUsersRoles?: boolean
  /**
   * The external marketplace that the account belongs to
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  externalMarketplace?: GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum
  /**
   * Instacart internal team who manages this account
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  team?: string
  /**
   * Partner who manages this account
   * @type {string}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  partnerManager?: string
  /**
   * Whether or not this account has one or more advertiser admins with access to all external capabilities.
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  selfManaged: boolean
  /**
   * Whether the advertiser is required to have purchase orders on campaigns
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  mandatoryAdvertiserPos?: boolean
  /**
   * Whether the agency is required to have purchase orders on campaigns
   * @type {boolean}
   * @memberof GetAdminAccountsResponseDataAttributes
   */
  mandatoryAgencyPos?: boolean
}

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesPaymentOptionEnum = {
  InstacartInvoicing: 'instacart_invoicing',
  CreditCard: 'credit_card',
} as const
export type GetAdminAccountsResponseDataAttributesPaymentOptionEnum =
  typeof GetAdminAccountsResponseDataAttributesPaymentOptionEnum[keyof typeof GetAdminAccountsResponseDataAttributesPaymentOptionEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesInvoiceOptionEnum = {
  Email: 'email',
  Portal: 'portal',
} as const
export type GetAdminAccountsResponseDataAttributesInvoiceOptionEnum =
  typeof GetAdminAccountsResponseDataAttributesInvoiceOptionEnum[keyof typeof GetAdminAccountsResponseDataAttributesInvoiceOptionEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesPaymentTermDaysEnum = {
  _30: '30',
  _45: '45',
  _60: '60',
  _75: '75',
  _90: '90',
  _120: '120',
} as const
export type GetAdminAccountsResponseDataAttributesPaymentTermDaysEnum =
  typeof GetAdminAccountsResponseDataAttributesPaymentTermDaysEnum[keyof typeof GetAdminAccountsResponseDataAttributesPaymentTermDaysEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesAccountTypeEnum = {
  Default: 'default',
  Retailer: 'retailer',
  RetailerRsd: 'retailer_rsd',
  AdvertiserRtd: 'advertiser_rtd',
} as const
export type GetAdminAccountsResponseDataAttributesAccountTypeEnum =
  typeof GetAdminAccountsResponseDataAttributesAccountTypeEnum[keyof typeof GetAdminAccountsResponseDataAttributesAccountTypeEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesServingTypeEnum = {
  MarketplaceAndSyndication: 'marketplace_and_syndication',
  SyndicationNetwork: 'syndication_network',
  MarketplaceOnly: 'marketplace_only',
} as const
export type GetAdminAccountsResponseDataAttributesServingTypeEnum =
  typeof GetAdminAccountsResponseDataAttributesServingTypeEnum[keyof typeof GetAdminAccountsResponseDataAttributesServingTypeEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesServingGroupsEnum = {
  Admin: ':admin',
  AdsAdmin: 'Ads Admin',
  None: 'none',
  ConnectAdmin: 'connect_admin',
} as const
export type GetAdminAccountsResponseDataAttributesServingGroupsEnum =
  typeof GetAdminAccountsResponseDataAttributesServingGroupsEnum[keyof typeof GetAdminAccountsResponseDataAttributesServingGroupsEnum]

/**
 * @export
 */
export const GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum = {
  Provi: 'provi',
} as const
export type GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum =
  typeof GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum[keyof typeof GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum]

/**
 * Check if a given object implements the GetAdminAccountsResponseDataAttributes interface.
 */
export function instanceOfGetAdminAccountsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'billPayer' in value
  isInstance = isInstance && 'agencyId' in value
  isInstance = isInstance && 'agencyNumericId' in value
  isInstance = isInstance && 'agencyName' in value
  isInstance = isInstance && 'advertiserId' in value
  isInstance = isInstance && 'advertiserNumericId' in value
  isInstance = isInstance && 'advertiserName' in value
  isInstance = isInstance && 'adsTaxonomyReportingEntityId' in value
  isInstance = isInstance && 'adsTaxonomyReportingEntityName' in value
  isInstance = isInstance && 'adsTaxonomyReportingEntityLevel' in value
  isInstance = isInstance && 'paymentOption' in value
  isInstance = isInstance && 'invoiceOption' in value
  isInstance = isInstance && 'paymentTermDays' in value
  isInstance = isInstance && 'emailDistribution' in value
  isInstance = isInstance && 'corporateAddress' in value
  isInstance = isInstance && 'selfManaged' in value

  return isInstance
}

export function GetAdminAccountsResponseDataAttributesFromJSON(
  json: any
): GetAdminAccountsResponseDataAttributes {
  return GetAdminAccountsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdminAccountsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdminAccountsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    billPayer: json['bill_payer'],
    agencyId: json['agency_id'],
    agencyNumericId: json['agency_numeric_id'],
    agencyName: json['agency_name'],
    advertiserId: json['advertiser_id'],
    advertiserNumericId: json['advertiser_numeric_id'],
    advertiserName: json['advertiser_name'],
    adsTaxonomyReportingEntityId: json['ads_taxonomy_reporting_entity_id'],
    adsTaxonomyReportingEntityName: json['ads_taxonomy_reporting_entity_name'],
    adsTaxonomyReportingEntityLevel: json['ads_taxonomy_reporting_entity_level'],
    dissociateAdsTaxonomy: !exists(json, 'dissociate_ads_taxonomy')
      ? undefined
      : json['dissociate_ads_taxonomy'],
    productSearchStrategy: !exists(json, 'product_search_strategy')
      ? undefined
      : json['product_search_strategy'],
    paymentOption: json['payment_option'],
    invoiceOption: json['invoice_option'],
    paymentTermDays: json['payment_term_days'],
    emailDistribution: json['email_distribution'],
    corporateAddress: GetAdminAccountsResponseDataAttributesCorporateAddressFromJSON(
      json['corporate_address']
    ),
    retailers: !exists(json, 'retailers')
      ? undefined
      : (json['retailers'] as Array<any>).map(GetAdminAccountsParamDataAttributesRetailersFromJSON),
    accountId: !exists(json, 'account_id') ? undefined : json['account_id'],
    agencyMsa: !exists(json, 'agency_msa') ? undefined : json['agency_msa'],
    advertiserMsa: !exists(json, 'advertiser_msa') ? undefined : json['advertiser_msa'],
    alcohol: !exists(json, 'alcohol') ? undefined : json['alcohol'],
    blackOwnedBusiness: !exists(json, 'black_owned_business')
      ? undefined
      : json['black_owned_business'],
    womanOwnedBusiness: !exists(json, 'woman_owned_business')
      ? undefined
      : json['woman_owned_business'],
    countryCode: !exists(json, 'country_code') ? undefined : json['country_code'],
    countryId: !exists(json, 'country_id') ? undefined : json['country_id'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    isTest: !exists(json, 'is_test') ? undefined : json['is_test'],
    isSandbox: !exists(json, 'is_sandbox') ? undefined : json['is_sandbox'],
    isInHouse: !exists(json, 'is_in_house') ? undefined : json['is_in_house'],
    alcoholStatePermissions: !exists(json, 'alcohol_state_permissions')
      ? undefined
      : GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsFromJSON(
          json['alcohol_state_permissions']
        ),
    creditThresholdCents: !exists(json, 'credit_threshold_cents')
      ? undefined
      : json['credit_threshold_cents'],
    nextPaymentOption: !exists(json, 'next_payment_option')
      ? undefined
      : json['next_payment_option'],
    lockCreditThreshold: !exists(json, 'lock_credit_threshold')
      ? undefined
      : json['lock_credit_threshold'],
    accountType: !exists(json, 'account_type') ? undefined : json['account_type'],
    servingType: !exists(json, 'serving_type') ? undefined : json['serving_type'],
    servingGroups: !exists(json, 'serving_groups') ? undefined : json['serving_groups'],
    dailyBudgetCapCents: !exists(json, 'daily_budget_cap_cents')
      ? undefined
      : json['daily_budget_cap_cents'],
    lifetimeBudgetCapCents: !exists(json, 'lifetime_budget_cap_cents')
      ? undefined
      : json['lifetime_budget_cap_cents'],
    nonBillable: !exists(json, 'non_billable') ? undefined : json['non_billable'],
    autoAssignInternalUsersRoles: !exists(json, 'auto_assign_internal_users_roles')
      ? undefined
      : json['auto_assign_internal_users_roles'],
    externalMarketplace: !exists(json, 'external_marketplace')
      ? undefined
      : json['external_marketplace'],
    team: !exists(json, 'team') ? undefined : json['team'],
    partnerManager: !exists(json, 'partner_manager') ? undefined : json['partner_manager'],
    selfManaged: json['self_managed'],
    mandatoryAdvertiserPos: !exists(json, 'mandatory_advertiser_pos')
      ? undefined
      : json['mandatory_advertiser_pos'],
    mandatoryAgencyPos: !exists(json, 'mandatory_agency_pos')
      ? undefined
      : json['mandatory_agency_pos'],
  }
}

export function GetAdminAccountsResponseDataAttributesToJSON(
  value?: GetAdminAccountsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    bill_payer: value.billPayer,
    agency_id: value.agencyId,
    agency_numeric_id: value.agencyNumericId,
    agency_name: value.agencyName,
    advertiser_id: value.advertiserId,
    advertiser_numeric_id: value.advertiserNumericId,
    advertiser_name: value.advertiserName,
    ads_taxonomy_reporting_entity_id: value.adsTaxonomyReportingEntityId,
    ads_taxonomy_reporting_entity_name: value.adsTaxonomyReportingEntityName,
    ads_taxonomy_reporting_entity_level: value.adsTaxonomyReportingEntityLevel,
    dissociate_ads_taxonomy: value.dissociateAdsTaxonomy,
    product_search_strategy: value.productSearchStrategy,
    payment_option: value.paymentOption,
    invoice_option: value.invoiceOption,
    payment_term_days: value.paymentTermDays,
    email_distribution: value.emailDistribution,
    corporate_address: GetAdminAccountsResponseDataAttributesCorporateAddressToJSON(
      value.corporateAddress
    ),
    retailers:
      value.retailers === undefined
        ? undefined
        : (value.retailers as Array<any>).map(GetAdminAccountsParamDataAttributesRetailersToJSON),
    account_id: value.accountId,
    agency_msa: value.agencyMsa,
    advertiser_msa: value.advertiserMsa,
    alcohol: value.alcohol,
    black_owned_business: value.blackOwnedBusiness,
    woman_owned_business: value.womanOwnedBusiness,
    country_code: value.countryCode,
    country_id: value.countryId,
    currency: value.currency,
    is_test: value.isTest,
    is_sandbox: value.isSandbox,
    is_in_house: value.isInHouse,
    alcohol_state_permissions: GetAdminAccountsResponseDataAttributesAlcoholStatePermissionsToJSON(
      value.alcoholStatePermissions
    ),
    credit_threshold_cents: value.creditThresholdCents,
    next_payment_option: value.nextPaymentOption,
    lock_credit_threshold: value.lockCreditThreshold,
    account_type: value.accountType,
    serving_type: value.servingType,
    serving_groups: value.servingGroups,
    daily_budget_cap_cents: value.dailyBudgetCapCents,
    lifetime_budget_cap_cents: value.lifetimeBudgetCapCents,
    non_billable: value.nonBillable,
    auto_assign_internal_users_roles: value.autoAssignInternalUsersRoles,
    external_marketplace: value.externalMarketplace,
    team: value.team,
    partner_manager: value.partnerManager,
    self_managed: value.selfManaged,
    mandatory_advertiser_pos: value.mandatoryAdvertiserPos,
    mandatory_agency_pos: value.mandatoryAgencyPos,
  }
}
