// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerReservationDatesInputDateRange } from './ApiReportsControllerReservationDatesInputDateRange'
import {
  ApiReportsControllerReservationDatesInputDateRangeFromJSON,
  ApiReportsControllerReservationDatesInputDateRangeFromJSONTyped,
  ApiReportsControllerReservationDatesInputDateRangeToJSON,
} from './ApiReportsControllerReservationDatesInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerReservationDatesInput
 */
export interface ApiReportsControllerReservationDatesInput {
  /**
   *
   * @type {ApiReportsControllerReservationDatesInputDateRange}
   * @memberof ApiReportsControllerReservationDatesInput
   */
  dateRange?: ApiReportsControllerReservationDatesInputDateRange
  /**
   * Attribution Model for which data is requested
   * @type {string}
   * @memberof ApiReportsControllerReservationDatesInput
   */
  attributionModel?: string
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerReservationDatesInput
   */
  name?: string
  /**
   * the ID of a specific reservation. Empty id means all reservations
   * @type {string}
   * @memberof ApiReportsControllerReservationDatesInput
   */
  reservationId?: string
  /**
   * Send this parameter with the ID of a specific campaign to narrow the data returned on your report to just data from that specific campaign.
   * @type {string}
   * @memberof ApiReportsControllerReservationDatesInput
   */
  campaignId?: string
}

/**
 * Check if a given object implements the ApiReportsControllerReservationDatesInput interface.
 */
export function instanceOfApiReportsControllerReservationDatesInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerReservationDatesInputFromJSON(
  json: any
): ApiReportsControllerReservationDatesInput {
  return ApiReportsControllerReservationDatesInputFromJSONTyped(json, false)
}

export function ApiReportsControllerReservationDatesInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerReservationDatesInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerReservationDatesInputDateRangeFromJSON(json['date_range']),
    attributionModel: !exists(json, 'attribution_model') ? undefined : json['attribution_model'],
    name: !exists(json, 'name') ? undefined : json['name'],
    reservationId: !exists(json, 'reservation_id') ? undefined : json['reservation_id'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
  }
}

export function ApiReportsControllerReservationDatesInputToJSON(
  value?: ApiReportsControllerReservationDatesInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerReservationDatesInputDateRangeToJSON(value.dateRange),
    attribution_model: value.attributionModel,
    name: value.name,
    reservation_id: value.reservationId,
    campaign_id: value.campaignId,
  }
}
