import { css } from '@emotion/react'
import { Text, Theme, useTheme } from '@instacart/ids-core'
import { useFormikContext } from 'formik'
import { useContext } from 'react'
import FormattedMessage from 'components/FormattedMessage'
import CustomDropdown from '../components/CustomDropdown'
import TargetingSegments from '../components/TargetingSegments'
import { Operator, operatorOptions, SegmentType, TARGETING_OPERATOR_FIELD_NAME } from '../constants'
import { TargetingRuleContext } from '../hooks'

const selectorsDivStyle = (isReviewing?: boolean) =>
  css({
    display: 'flex',
    marginTop: isReviewing ? 0 : 24,
    alignContent: 'center',
  })

const descriptionTextStyle = (theme: Theme) => ({
  color: theme.colors.systemGrayscale70,
})

const selectorLabelTextStyle = (theme: Theme) => ({
  color: theme.colors.systemGrayscale70,
  paddingTop: 4,
  marginLeft: 4,
  marginRight: 4,
})

const backgroundStyle = (theme: Theme, isReviewing: boolean) =>
  css({
    padding: 24,
    backgroundColor: isReviewing ? 'inherit' : theme.colors.systemGrayscale10,
    borderRadius: 8,
  })

export default function TargetingRule() {
  const theme = useTheme()

  const {
    values: { operator },
  } = useFormikContext() as { values: { operator: Operator } }

  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)

  return (
    <div id="targeting" css={backgroundStyle(theme, isReviewing)}>
      {!isReadOnly && (
        <Text typography="bodyMedium2" style={descriptionTextStyle(theme)}>
          <FormattedMessage id="pages.displayProduct.common.targetingRule.description" />
        </Text>
      )}
      <div css={selectorsDivStyle(isReviewing)}>
        <Text typography="bodyMedium2" style={selectorLabelTextStyle(theme)}>
          <FormattedMessage id="pages.displayProduct.displayAdGroup.targetingType.operator.label" />
        </Text>
        <CustomDropdown fieldName={TARGETING_OPERATOR_FIELD_NAME} options={operatorOptions} />
        <Text typography="bodyMedium2" style={selectorLabelTextStyle(theme)}>
          <FormattedMessage id="pages.displayProduct.displayAdGroup.targetingType.operator.label2" />
        </Text>
      </div>
      <TargetingSegments segmentType={SegmentType.PURCHASE} operator={operator} />
    </div>
  )
}
