// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiPrototypeCampaignsControllerCreateInput } from '../models/ApiPrototypeCampaignsControllerCreateInput'
import {
  ApiPrototypeCampaignsControllerCreateInputFromJSON,
  ApiPrototypeCampaignsControllerCreateInputToJSON,
} from '../models/ApiPrototypeCampaignsControllerCreateInput'
import type { ApiPrototypeCampaignsControllerUpdateInput } from '../models/ApiPrototypeCampaignsControllerUpdateInput'
import {
  ApiPrototypeCampaignsControllerUpdateInputFromJSON,
  ApiPrototypeCampaignsControllerUpdateInputToJSON,
} from '../models/ApiPrototypeCampaignsControllerUpdateInput'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { PrototypeCampaignResponse } from '../models/PrototypeCampaignResponse'
import {
  PrototypeCampaignResponseFromJSON,
  PrototypeCampaignResponseToJSON,
} from '../models/PrototypeCampaignResponse'
import type { PrototypeCampaignsResponse } from '../models/PrototypeCampaignsResponse'
import {
  PrototypeCampaignsResponseFromJSON,
  PrototypeCampaignsResponseToJSON,
} from '../models/PrototypeCampaignsResponse'

export interface GetPrototypeCampaignsRequest {
  searchCriteriaDateRangeStartDate?: string
  searchCriteriaDateRangeEndDate?: string
  searchCriteriaStatus?: Array<GetPrototypeCampaignsSearchCriteriaStatusEnum>
  searchCriteriaQuery?: string
  sortCriteriaField?: GetPrototypeCampaignsSortCriteriaFieldEnum
  sortCriteriaDirection?: GetPrototypeCampaignsSortCriteriaDirectionEnum
  pageCriteriaPage?: number
  pageCriteriaItemsPerPage?: number
  xApiKey?: string
  xSecretKey?: string
}

export interface GetPrototypeCampaignsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostPrototypeCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiPrototypeCampaignsControllerCreateInput
}

export interface PutPrototypeCampaignsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiPrototypeCampaignsControllerUpdateInput
}

/**
 *
 */
export class PrototypeCampaignsApi extends runtime.BaseAPI {
  /**
   * Basic endpoint for listing prototype campaigns.
   * list prototype campaign
   */
  async getPrototypeCampaignsRaw(
    requestParameters: GetPrototypeCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PrototypeCampaignsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.searchCriteriaDateRangeStartDate !== undefined) {
      queryParameters['search_criteria[date_range][start_date]'] =
        requestParameters.searchCriteriaDateRangeStartDate
    }

    if (requestParameters.searchCriteriaDateRangeEndDate !== undefined) {
      queryParameters['search_criteria[date_range][end_date]'] =
        requestParameters.searchCriteriaDateRangeEndDate
    }

    if (requestParameters.searchCriteriaStatus) {
      queryParameters['search_criteria[status]'] = requestParameters.searchCriteriaStatus.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.searchCriteriaQuery !== undefined) {
      queryParameters['search_criteria[query]'] = requestParameters.searchCriteriaQuery
    }

    if (requestParameters.sortCriteriaField !== undefined) {
      queryParameters['sort_criteria[field]'] = requestParameters.sortCriteriaField
    }

    if (requestParameters.sortCriteriaDirection !== undefined) {
      queryParameters['sort_criteria[direction]'] = requestParameters.sortCriteriaDirection
    }

    if (requestParameters.pageCriteriaPage !== undefined) {
      queryParameters['page_criteria[page]'] = requestParameters.pageCriteriaPage
    }

    if (requestParameters.pageCriteriaItemsPerPage !== undefined) {
      queryParameters['page_criteria[items_per_page]'] = requestParameters.pageCriteriaItemsPerPage
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/prototype/campaigns`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PrototypeCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Basic endpoint for listing prototype campaigns.
   * list prototype campaign
   */
  async getPrototypeCampaigns(
    requestParameters: GetPrototypeCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PrototypeCampaignsResponse> {
    const response = await this.getPrototypeCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Basic endpoint for retrieving a prototype campaign by id.
   * Retrieve a prototype campaign
   */
  async getPrototypeCampaignsIdRaw(
    requestParameters: GetPrototypeCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PrototypeCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getPrototypeCampaignsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/prototype/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PrototypeCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * Basic endpoint for retrieving a prototype campaign by id.
   * Retrieve a prototype campaign
   */
  async getPrototypeCampaignsId(
    requestParameters: GetPrototypeCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PrototypeCampaignResponse> {
    const response = await this.getPrototypeCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Basic endpoint for creating prototype campaigns.
   * Create a new prototype campaign
   */
  async postPrototypeCampaignsRaw(
    requestParameters: PostPrototypeCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PrototypeCampaignResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/prototype/campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiPrototypeCampaignsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PrototypeCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * Basic endpoint for creating prototype campaigns.
   * Create a new prototype campaign
   */
  async postPrototypeCampaigns(
    requestParameters: PostPrototypeCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PrototypeCampaignResponse> {
    const response = await this.postPrototypeCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Basic endpoint for updating prototype campaigns.
   * Update an existing prototype campaign
   */
  async putPrototypeCampaignsIdRaw(
    requestParameters: PutPrototypeCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PrototypeCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putPrototypeCampaignsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/prototype/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiPrototypeCampaignsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PrototypeCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * Basic endpoint for updating prototype campaigns.
   * Update an existing prototype campaign
   */
  async putPrototypeCampaignsId(
    requestParameters: PutPrototypeCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PrototypeCampaignResponse> {
    const response = await this.putPrototypeCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetPrototypeCampaignsSearchCriteriaStatusEnum = {
  Active: 'active',
  Ended: 'ended',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Unknown: 'unknown',
} as const
export type GetPrototypeCampaignsSearchCriteriaStatusEnum =
  typeof GetPrototypeCampaignsSearchCriteriaStatusEnum[keyof typeof GetPrototypeCampaignsSearchCriteriaStatusEnum]
/**
 * @export
 */
export const GetPrototypeCampaignsSortCriteriaFieldEnum = {
  Status: 'status',
  Name: 'name',
  StartsAt: 'starts_at',
  EndsAt: 'ends_at',
} as const
export type GetPrototypeCampaignsSortCriteriaFieldEnum =
  typeof GetPrototypeCampaignsSortCriteriaFieldEnum[keyof typeof GetPrototypeCampaignsSortCriteriaFieldEnum]
/**
 * @export
 */
export const GetPrototypeCampaignsSortCriteriaDirectionEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const
export type GetPrototypeCampaignsSortCriteriaDirectionEnum =
  typeof GetPrototypeCampaignsSortCriteriaDirectionEnum[keyof typeof GetPrototypeCampaignsSortCriteriaDirectionEnum]
