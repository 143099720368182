import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { InputDescriptionHintText } from '@instacart/ids-tooling'
import { HTMLProps } from 'react'
import { hintId } from './utils'

export interface InputHintProps extends HTMLProps<HTMLDivElement> {
  inputId: string
  hint: string
  compact?: boolean
}

function useStyles({ compact }: { compact?: boolean }) {
  const theme = useTheme()

  return {
    inputDescriptionHint: css({
      ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
      color: theme.colors.systemGrayscale70,
      margin: 0,
    }),
  }
}

export function InputHint({ inputId, hint, compact }: InputHintProps) {
  const styles = useStyles({ compact })

  return (
    <InputDescriptionHintText id={hintId(inputId)} css={styles.inputDescriptionHint}>
      {hint}
    </InputDescriptionHintText>
  )
}
