// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PutReservationAdGroupsIdParamBrandPageBlockSnapshot,
  instanceOfPutReservationAdGroupsIdParamBrandPageBlockSnapshot,
  PutReservationAdGroupsIdParamBrandPageBlockSnapshotFromJSON,
  PutReservationAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageBlockSnapshotToJSON,
} from './PutReservationAdGroupsIdParamBrandPageBlockSnapshot'
import {
  PutReservationAdGroupsIdParamBrandPageSnapshot,
  instanceOfPutReservationAdGroupsIdParamBrandPageSnapshot,
  PutReservationAdGroupsIdParamBrandPageSnapshotFromJSON,
  PutReservationAdGroupsIdParamBrandPageSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamBrandPageSnapshotToJSON,
} from './PutReservationAdGroupsIdParamBrandPageSnapshot'
import {
  PutReservationAdGroupsIdParamCreativeSnapshot,
  instanceOfPutReservationAdGroupsIdParamCreativeSnapshot,
  PutReservationAdGroupsIdParamCreativeSnapshotFromJSON,
  PutReservationAdGroupsIdParamCreativeSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamCreativeSnapshotToJSON,
} from './PutReservationAdGroupsIdParamCreativeSnapshot'
import {
  PutReservationAdGroupsIdParamDisplayAdGroupSnapshot,
  instanceOfPutReservationAdGroupsIdParamDisplayAdGroupSnapshot,
  PutReservationAdGroupsIdParamDisplayAdGroupSnapshotFromJSON,
  PutReservationAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamDisplayAdGroupSnapshotToJSON,
} from './PutReservationAdGroupsIdParamDisplayAdGroupSnapshot'
import {
  PutReservationAdGroupsIdParamDisplayAssetSnapshot,
  instanceOfPutReservationAdGroupsIdParamDisplayAssetSnapshot,
  PutReservationAdGroupsIdParamDisplayAssetSnapshotFromJSON,
  PutReservationAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamDisplayAssetSnapshotToJSON,
} from './PutReservationAdGroupsIdParamDisplayAssetSnapshot'
import {
  PutReservationAdGroupsIdParamDisplayProductAssetSnapshot,
  instanceOfPutReservationAdGroupsIdParamDisplayProductAssetSnapshot,
  PutReservationAdGroupsIdParamDisplayProductAssetSnapshotFromJSON,
  PutReservationAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped,
  PutReservationAdGroupsIdParamDisplayProductAssetSnapshotToJSON,
} from './PutReservationAdGroupsIdParamDisplayProductAssetSnapshot'

/**
 * @type PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & PutReservationAdGroupsIdParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & PutReservationAdGroupsIdParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & PutReservationAdGroupsIdParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & PutReservationAdGroupsIdParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & PutReservationAdGroupsIdParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & PutReservationAdGroupsIdParamDisplayProductAssetSnapshot)

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  return PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...PutReservationAdGroupsIdParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...PutReservationAdGroupsIdParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...PutReservationAdGroupsIdParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...PutReservationAdGroupsIdParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...PutReservationAdGroupsIdParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...PutReservationAdGroupsIdParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return PutReservationAdGroupsIdParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return PutReservationAdGroupsIdParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return PutReservationAdGroupsIdParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return PutReservationAdGroupsIdParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return PutReservationAdGroupsIdParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return PutReservationAdGroupsIdParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of PutReservationAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
