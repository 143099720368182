// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdGroupKeywordsParamKeywords } from './PostAdGroupKeywordsParamKeywords'
import {
  PostAdGroupKeywordsParamKeywordsFromJSON,
  PostAdGroupKeywordsParamKeywordsFromJSONTyped,
  PostAdGroupKeywordsParamKeywordsToJSON,
} from './PostAdGroupKeywordsParamKeywords'

/**
 *
 * @export
 * @interface ApiAdGroupKeywordsControllerCreateInput
 */
export interface ApiAdGroupKeywordsControllerCreateInput {
  /**
   * The ad group ID that you want to add the keyword to.
   * @type {string}
   * @memberof ApiAdGroupKeywordsControllerCreateInput
   */
  adGroupId: string
  /**
   * Array of ad group keywords to be added
   * @type {Array<PostAdGroupKeywordsParamKeywords>}
   * @memberof ApiAdGroupKeywordsControllerCreateInput
   */
  keywords: Array<PostAdGroupKeywordsParamKeywords>
  /**
   * Context of keyword creation. API users will default to endpoint_call. Non-API users will default to unidentified if param not provided.
   * @type {string}
   * @memberof ApiAdGroupKeywordsControllerCreateInput
   */
  clientContext?: ApiAdGroupKeywordsControllerCreateInputClientContextEnum
}

/**
 * @export
 */
export const ApiAdGroupKeywordsControllerCreateInputClientContextEnum = {
  AdGroupEdit: 'ad_group_edit',
  AutoTargetedKeywordBidOverride: 'auto_targeted_keyword_bid_override',
} as const
export type ApiAdGroupKeywordsControllerCreateInputClientContextEnum =
  typeof ApiAdGroupKeywordsControllerCreateInputClientContextEnum[keyof typeof ApiAdGroupKeywordsControllerCreateInputClientContextEnum]

/**
 * Check if a given object implements the ApiAdGroupKeywordsControllerCreateInput interface.
 */
export function instanceOfApiAdGroupKeywordsControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'adGroupId' in value
  isInstance = isInstance && 'keywords' in value

  return isInstance
}

export function ApiAdGroupKeywordsControllerCreateInputFromJSON(
  json: any
): ApiAdGroupKeywordsControllerCreateInput {
  return ApiAdGroupKeywordsControllerCreateInputFromJSONTyped(json, false)
}

export function ApiAdGroupKeywordsControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupKeywordsControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroupId: json['ad_group_id'],
    keywords: (json['keywords'] as Array<any>).map(PostAdGroupKeywordsParamKeywordsFromJSON),
    clientContext: !exists(json, 'client_context') ? undefined : json['client_context'],
  }
}

export function ApiAdGroupKeywordsControllerCreateInputToJSON(
  value?: ApiAdGroupKeywordsControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group_id: value.adGroupId,
    keywords: (value.keywords as Array<any>).map(PostAdGroupKeywordsParamKeywordsToJSON),
    client_context: value.clientContext,
  }
}
