import { LoadingGenericBase } from '@instacart/ids-core'
import { useEffect, useState } from 'react'
import { useModalState } from 'components/ids-ads'
import LoginModal from 'components/Login/components/LoginModal'
import { AuthContext, hasNoAccessToAccounts } from 'context'
import Client, { PageError } from 'service/client'
import useAuthLoader from '../utils/useAuthLoader'
import AccessRevoked from './AccessRevoked'

interface AuthProviderProps {
  children: React.ReactNode
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [isUnauthenticated, setIsUnauthenticated] = useState(false)
  const authContext = useAuthLoader()
  const modalState = useModalState({ visible: true })

  const onPageError = ({ status }: PageError) => {
    if (status === 401) {
      setIsUnauthenticated(true)
    }
  }

  useEffect(() => {
    Client.addPageErrorListener(onPageError)
    return () => Client.removePageErrorListener(onPageError)
  })

  if (!authContext.checked) {
    return <LoadingGenericBase />
  }

  if (authContext.user && hasNoAccessToAccounts(authContext.user)) {
    return <AccessRevoked userEmail={authContext.user.email} />
  }

  if (isUnauthenticated) {
    return (
      <>
        {children}
        <LoginModal
          onClose={() => {}}
          subHeadingId="pages.login.session.expired"
          redirectToWindow
          hideCloseIcon
          modalState={modalState}
        />
      </>
    )
  }

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}
