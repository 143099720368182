import { ADS_API_URL } from 'common/constants'
import { clearCurrentAccountId, getCurrentAccountId } from 'common/currentAccountHelper'
import {
  ApiErrorCodes,
  camelToSnakeCase,
  ClientError,
  compactObject,
  isFormData,
  isURLSearchParams,
  snakeToCamelCase,
} from 'common/utils'
import Client from 'service/client'
import { Configuration, Middleware, RequestContext, ResponseContext } from 'service/openapi/runtime'

let csrfToken: string
export const setCsrfToken = (token: string) => (csrfToken = token)

export const preMiddleware = async (params: RequestContext) => {
  let parsed: string
  let body = params?.init?.body

  if ((body && isFormData(body)) || isURLSearchParams(body)) {
    const modified: FormData | URLSearchParams = isFormData(body)
      ? new FormData()
      : new URLSearchParams()

    // Convert camelcase FormData or URLSearchParams keys to snake case
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of body.entries()) {
      const camelCaseKey = camelToSnakeCase(key)
      modified.append(camelCaseKey, value as string)
    }

    body = modified
  } else {
    // body is JSON.stringified at this point.
    if (!body || typeof body !== 'string') {
      return
    }
    try {
      parsed = JSON.parse(body)
    } catch (ex) {
      return
    }

    body = JSON.stringify(camelToSnakeCase(parsed))
  }

  return {
    ...params,
    init: {
      ...params.init,
      body,
    },
  }
}

export const postMiddleware = async ({ response }: ResponseContext) => {
  let json
  let errors
  let data

  try {
    json = await response.json()
    errors = json?.meta?.errors
    data = json?.data
  } catch (ex) {
    return
  }

  // if there's data, we still consider the call partial success
  if (errors && !data && !(Array.isArray(data) && data.length === 0)) {
    const loggedInAndUnauthorized = json?.meta?.status === 401
    const notFound = json?.meta?.status === 404
    const errorCode = json?.meta?.error_code
    const currentAccountId = getCurrentAccountId()

    if (errorCode === ApiErrorCodes.UnconfirmedAccount) {
      clearCurrentAccountId()
    }

    if (currentAccountId && currentAccountId !== 'null') {
      // Make sure we have chosen an account as well.
      if (loggedInAndUnauthorized || notFound) {
        Client.throwPageError(errors, json?.meta?.status)
      }
    }
    throw new ClientError(errors, json?.meta?.error_code)
  } else {
    return new Response(JSON.stringify(snakeToCamelCase(json)), {
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
    })
  }
}

export interface GetConfigurationParams {
  skipCasing?: boolean
}

export const getConfiguration = (options?: GetConfigurationParams): Configuration => {
  const middleware: Middleware[] = [
    {
      post: postMiddleware,
      ...(!options?.skipCasing && { pre: preMiddleware }),
    },
  ]

  return new Configuration({
    basePath: ADS_API_URL,
    credentials: 'include',
    middleware,
    get headers() {
      const headers = {
        'X-CSRF-TOKEN': csrfToken,
        'Account-ID': getCurrentAccountId(),
      }

      return compactObject(headers)
    },
  })
}
