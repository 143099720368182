import { useEffect, useRef } from 'react'
import { CardCollapse, CardCollapseProps, CardCollapseRef } from 'components/ids-ads'
import useCorrectContext from 'hooks/useCorrectContext'

export type BrandPageCardCollapseProps = CardCollapseProps

export function BrandPageCardCollapse(props: BrandPageCardCollapseProps) {
  const ref = useRef<CardCollapseRef>(null)

  const useContext = useCorrectContext()
  const { expandBrandPageSections, setExpandBrandPageSections } = useContext()

  // FIXME: This is a convoluted system for expanding groups of CardCollapse components. We should
  // work on a more generalized and easy-to-understand system in the future
  useEffect(() => {
    // When expandBrandPageSections is true, expand all sections
    if (expandBrandPageSections) {
      ref.current?.show()
    }
    // Reset expandBrandPageSections so it fires correctly when the user submits again
    if (setExpandBrandPageSections) {
      setExpandBrandPageSections(false)
    }
  }, [expandBrandPageSections, setExpandBrandPageSections])

  return <CardCollapse {...props} ref={ref} />
}
