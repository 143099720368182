import { UseStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

const styles: UseStylesOpts = {
  normalBackground: 'systemGrayscale00',
  borderColor: 'systemGrayscale30',
  textColor: 'systemGrayscale70',
  hoverBackground: 'systemGrayscale10',
  activeBackground: 'systemGrayscale20',
}

export const TertiaryButton = makeButton('TertiaryButton', styles)
export const TertiaryLinkButton = makeLinkButton('TertiaryLinkButton', styles)
export const TertiaryAnchorButton = makeAnchorButton('TertiaryAnchorButton', styles)
