// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValues } from './PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValues'
import {
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValuesFromJSON,
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValuesFromJSONTyped,
  PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValuesToJSON,
} from './PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValues'

/**
 *
 * @export
 * @interface PostAdminBrandPagesIdLabelParamBrandPageBlockProducts
 */
export interface PostAdminBrandPagesIdLabelParamBrandPageBlockProducts {
  /**
   * Map of labels applied. For example product_id -> labels
   * @type {{ [key: string]: PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValues; }}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockProducts
   */
  labelMap: { [key: string]: PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValues }
  /**
   *
   * @type {string}
   * @memberof PostAdminBrandPagesIdLabelParamBrandPageBlockProducts
   */
  componentType: PostAdminBrandPagesIdLabelParamBrandPageBlockProductsComponentTypeEnum
}

/**
 * @export
 */
export const PostAdminBrandPagesIdLabelParamBrandPageBlockProductsComponentTypeEnum = {
  ProductCollectionProducts: 'PRODUCT_COLLECTION_PRODUCTS',
} as const
export type PostAdminBrandPagesIdLabelParamBrandPageBlockProductsComponentTypeEnum =
  typeof PostAdminBrandPagesIdLabelParamBrandPageBlockProductsComponentTypeEnum[keyof typeof PostAdminBrandPagesIdLabelParamBrandPageBlockProductsComponentTypeEnum]

/**
 * Check if a given object implements the PostAdminBrandPagesIdLabelParamBrandPageBlockProducts interface.
 */
export function instanceOfPostAdminBrandPagesIdLabelParamBrandPageBlockProducts(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'labelMap' in value
  isInstance = isInstance && 'componentType' in value

  return isInstance
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSON(
  json: any
): PostAdminBrandPagesIdLabelParamBrandPageBlockProducts {
  return PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSONTyped(json, false)
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockProductsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdminBrandPagesIdLabelParamBrandPageBlockProducts {
  if (json === undefined || json === null) {
    return json
  }
  return {
    labelMap: mapValues(
      json['label_map'],
      PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValuesFromJSON
    ),
    componentType: json['component_type'],
  }
}

export function PostAdminBrandPagesIdLabelParamBrandPageBlockProductsToJSON(
  value?: PostAdminBrandPagesIdLabelParamBrandPageBlockProducts | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    label_map: mapValues(
      value.labelMap,
      PostAdminBrandPagesIdLabelParamBrandPageBlockProductsLabelMapValuesToJSON
    ),
    component_type: value.componentType,
  }
}
