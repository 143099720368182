import { FC } from 'react'
import TextArea, { TextAreaProps } from 'components/molecules/TextArea'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'

export type TextAreaInputFieldProps = InputFieldProps<TextAreaInputProps>

interface TextAreaInputProps
  extends Pick<TextAreaProps, 'rows' | 'placeholder' | 'value' | 'compact'> {
  id: string
  error?: string
  onChange: (text: string) => void
  onBlur?: () => void
}

const TextAreaInput: FC<React.PropsWithChildren<TextAreaInputProps>> = ({
  id,
  error,
  onChange,
  onBlur,
  ...props
}) => (
  <TextArea
    id={id}
    invalid={!!error}
    onChange={e => onChange(e.target.value)}
    onBlur={() => onBlur?.()}
    {...props}
  />
)
TextAreaInput.displayName = 'InputText'

export function TextAreaInputField({
  id,
  additionalInfo,
  error,
  hint,
  info,
  infoDescription,
  isOptional,
  label,
  compact,
  rows,
  ...rest
}: TextAreaInputFieldProps) {
  return (
    <InputFieldContainer
      id={id}
      additionalInfo={additionalInfo}
      error={error}
      hint={hint}
      info={info}
      infoDescription={infoDescription}
      isOptional={isOptional}
      label={label}
      compact={compact}
    >
      <TextAreaInput
        id={id}
        error={error}
        compact={compact}
        rows={rest.readOnly ? undefined : rows}
        {...rest}
      />
    </InputFieldContainer>
  )
}

TextAreaInputField.displayName = 'TextAreaInputField'
