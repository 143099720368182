import { PropsOf } from '@emotion/react'
import { FC } from 'react'
import ReactCurrencyInput from 'react-currency-input-field'
import { InputCurrency } from 'components/ids-ads'
import { InputFieldContainer, InputFieldProps } from './InputFieldContainer'

export type CurrencyInputFieldProps = InputFieldProps<CurrencyInputProps>

interface CurrencyInputProps
  extends Pick<
    PropsOf<typeof ReactCurrencyInput>,
    'onBlur' | 'defaultValue' | 'disabled' | 'style' | 'decimalScale' | 'tabIndex' | 'allowDecimals'
  > {
  id: string
  value?: number
  error?: string
  onChange: (value: string | undefined) => void
  showZeroValue?: boolean
}

const CurrencyInput: FC<React.PropsWithChildren<CurrencyInputProps>> = ({
  id,
  value,
  error,
  onChange,
  showZeroValue = false,
  ...rest
}) => (
  <ReactCurrencyInput
    {...rest}
    id={id}
    customInput={InputCurrency}
    allowNegativeValue={false}
    value={value || (showZeroValue ? value : '')}
    disableAbbreviations
    className={error && 'invalid'}
    onValueChange={onChange}
  />
)

// Currently, @instacart/ids-tooling throws a composition error when rendering <CurrencyInput /> within <InputWrapper />
// This is being investigated but at this moment we spoof the component name to a valid child to proceed
CurrencyInput.displayName = 'InputCurrency'

export const CurrencyInputField: FC<React.PropsWithChildren<CurrencyInputFieldProps>> = ({
  id,
  onChange,
  error,
  hideLabel,
  ...rest
}) => (
  <InputFieldContainer id={id} error={error} hideLabel={hideLabel} {...rest}>
    <CurrencyInput id={id} error={error} onChange={onChange} {...rest} />
  </InputFieldContainer>
)

CurrencyInputField.displayName = 'CurrencyInputField'
