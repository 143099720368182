import { getExchangeConfig } from 'common/common-definitions/exchangeConfig'
import { useAuthContext, AuthCtx, getExchangeName } from 'context'

const displayMinBid = (authContext: AuthCtx) => {
  if (authContext.user?.currentAccount?.isInHouse) {
    return 0.01
  }

  return getExchangeConfig(getExchangeName(authContext)).featureOverrides.displayMinBid()
}

export function useDisplayMinBid() {
  return displayMinBid(useAuthContext())
}
