// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets } from './GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets'
import {
  GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSON,
  GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSONTyped,
  GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsToJSON,
} from './GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets'
import type { PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage } from './PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage'
import {
  PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPageFromJSON,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPageFromJSONTyped,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPageToJSON,
} from './PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage'
import type { PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative } from './PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative'
import {
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeFromJSON,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeFromJSONTyped,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeToJSON,
} from './PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative'
import type { PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategy } from './PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategy'
import {
  PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategyFromJSON,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategyFromJSONTyped,
  PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategyToJSON,
} from './PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategy'

/**
 *
 * @export
 * @interface PrototypeCampaignResponseDataAttributesAdGroupAttributes
 */
export interface PrototypeCampaignResponseDataAttributesAdGroupAttributes {
  /**
   * id of the ad group, required for update
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  id?: string
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  name: string
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  campaignId?: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  placementType: string
  /**
   *
   * @type {PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  creative: PrototypeCampaignResponseDataAttributesAdGroupAttributesCreative
  /**
   * Assets for the creative
   * @type {Array<GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets>}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  assets: Array<GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets>
  /**
   *
   * @type {PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategy}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  targetingStrategy: PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  lockKey?: string
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  updatedAt?: string
  /**
   *
   * @type {PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage}
   * @memberof PrototypeCampaignResponseDataAttributesAdGroupAttributes
   */
  brandPage?: PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPage
}

/**
 * Check if a given object implements the PrototypeCampaignResponseDataAttributesAdGroupAttributes interface.
 */
export function instanceOfPrototypeCampaignResponseDataAttributesAdGroupAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'assets' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesFromJSON(
  json: any
): PrototypeCampaignResponseDataAttributesAdGroupAttributes {
  return PrototypeCampaignResponseDataAttributesAdGroupAttributesFromJSONTyped(json, false)
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PrototypeCampaignResponseDataAttributesAdGroupAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    placementType: json['placement_type'],
    creative: PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeFromJSON(
      json['creative']
    ),
    assets: (json['assets'] as Array<any>).map(
      GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSON
    ),
    targetingStrategy:
      PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function PrototypeCampaignResponseDataAttributesAdGroupAttributesToJSON(
  value?: PrototypeCampaignResponseDataAttributesAdGroupAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: PrototypeCampaignResponseDataAttributesAdGroupAttributesCreativeToJSON(
      value.creative
    ),
    assets: (value.assets as Array<any>).map(
      GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsToJSON
    ),
    targeting_strategy:
      PrototypeCampaignResponseDataAttributesAdGroupAttributesTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    lock_key: value.lockKey,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    brand_page: PrototypeCampaignResponseDataAttributesAdGroupAttributesBrandPageToJSON(
      value.brandPage
    ),
  }
}
