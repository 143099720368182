// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch } from './PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear } from './PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
 */
export interface PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
   */
  linear: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfPostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  return PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLinearToJSON(
      value.linear
    ),
    last_touch: PostAdGroupKeywordsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
