// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamBlockHeroBannerAssets } from './PostBrandPagesIdStatusParamBlockHeroBannerAssets'
import {
  PostBrandPagesIdStatusParamBlockHeroBannerAssetsFromJSON,
  PostBrandPagesIdStatusParamBlockHeroBannerAssetsFromJSONTyped,
  PostBrandPagesIdStatusParamBlockHeroBannerAssetsToJSON,
} from './PostBrandPagesIdStatusParamBlockHeroBannerAssets'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamBlockHeroBanner
 */
export interface PostBrandPagesIdStatusParamBlockHeroBanner {
  /**
   * id of the block
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockHeroBanner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockHeroBanner
   */
  discriminator: string
  /**
   *
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamBlockHeroBanner
   */
  type: PostBrandPagesIdStatusParamBlockHeroBannerTypeEnum
  /**
   *
   * @type {PostBrandPagesIdStatusParamBlockHeroBannerAssets}
   * @memberof PostBrandPagesIdStatusParamBlockHeroBanner
   */
  assets: PostBrandPagesIdStatusParamBlockHeroBannerAssets
}

/**
 * @export
 */
export const PostBrandPagesIdStatusParamBlockHeroBannerTypeEnum = {
  HeroBannerEvergreenV1: 'hero_banner.evergreen.v1',
} as const
export type PostBrandPagesIdStatusParamBlockHeroBannerTypeEnum =
  typeof PostBrandPagesIdStatusParamBlockHeroBannerTypeEnum[keyof typeof PostBrandPagesIdStatusParamBlockHeroBannerTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamBlockHeroBanner interface.
 */
export function instanceOfPostBrandPagesIdStatusParamBlockHeroBanner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'discriminator' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamBlockHeroBannerFromJSON(
  json: any
): PostBrandPagesIdStatusParamBlockHeroBanner {
  return PostBrandPagesIdStatusParamBlockHeroBannerFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamBlockHeroBannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamBlockHeroBanner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    discriminator: json['discriminator'],
    type: json['type'],
    assets: PostBrandPagesIdStatusParamBlockHeroBannerAssetsFromJSON(json['assets']),
  }
}

export function PostBrandPagesIdStatusParamBlockHeroBannerToJSON(
  value?: PostBrandPagesIdStatusParamBlockHeroBanner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    discriminator: value.discriminator,
    type: value.type,
    assets: PostBrandPagesIdStatusParamBlockHeroBannerAssetsToJSON(value.assets),
  }
}
