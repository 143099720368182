// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetVideoAdGroupsParamAssetFieldDecisions,
  instanceOfGetVideoAdGroupsParamAssetFieldDecisions,
  GetVideoAdGroupsParamAssetFieldDecisionsFromJSON,
  GetVideoAdGroupsParamAssetFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamAssetFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamAssetFieldDecisions'
import {
  GetVideoAdGroupsParamBrandPageBlockFieldDecisions,
  instanceOfGetVideoAdGroupsParamBrandPageBlockFieldDecisions,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsFromJSON,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamBrandPageBlockFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamBrandPageBlockFieldDecisions'
import {
  GetVideoAdGroupsParamBrandPageFieldDecisions,
  instanceOfGetVideoAdGroupsParamBrandPageFieldDecisions,
  GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSON,
  GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamBrandPageFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamBrandPageFieldDecisions'
import {
  GetVideoAdGroupsParamCreativeFieldDecisions,
  instanceOfGetVideoAdGroupsParamCreativeFieldDecisions,
  GetVideoAdGroupsParamCreativeFieldDecisionsFromJSON,
  GetVideoAdGroupsParamCreativeFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamCreativeFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamCreativeFieldDecisions'
import {
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisions,
  instanceOfGetVideoAdGroupsParamDisplayAdGroupFieldDecisions,
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSON,
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamDisplayAdGroupFieldDecisions'
import {
  GetVideoAdGroupsParamProductAssetFieldDecisions,
  instanceOfGetVideoAdGroupsParamProductAssetFieldDecisions,
  GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSON,
  GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSONTyped,
  GetVideoAdGroupsParamProductAssetFieldDecisionsToJSON,
} from './GetVideoAdGroupsParamProductAssetFieldDecisions'

/**
 * @type GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =
  | ({ type: 'ASSET' } & GetVideoAdGroupsParamAssetFieldDecisions)
  | ({ type: 'BRAND_PAGE' } & GetVideoAdGroupsParamBrandPageFieldDecisions)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetVideoAdGroupsParamBrandPageBlockFieldDecisions)
  | ({ type: 'CREATIVE' } & GetVideoAdGroupsParamCreativeFieldDecisions)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetVideoAdGroupsParamDisplayAdGroupFieldDecisions)
  | ({ type: 'PRODUCT_ASSET' } & GetVideoAdGroupsParamProductAssetFieldDecisions)

export function GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return { ...GetVideoAdGroupsParamAssetFieldDecisionsFromJSONTyped(json, true), type: 'ASSET' }
    case 'BRAND_PAGE':
      return {
        ...GetVideoAdGroupsParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetVideoAdGroupsParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetVideoAdGroupsParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetVideoAdGroupsParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetVideoAdGroupsParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return GetVideoAdGroupsParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetVideoAdGroupsParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return GetVideoAdGroupsParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetVideoAdGroupsParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return GetVideoAdGroupsParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of GetVideoAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
