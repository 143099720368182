// CSAT Constants
import { canUseDOM } from 'exenv'
import { BrandPageTemplate } from '../pages/BrandPage/template.types'

// Help Center Constants
const HELP_CENTER_TOOLTIP_DISMISSED = 'dismissed_help_tooltip'

// Feature hint
const FEATURE_HINT_TOOLTIP_DISMISSALS = 'dismissed_feature_hint_tooltips'

// Debugging Mode
const DEBUGGING_MODE_ON = 'debugging_mode_on'

// Current account id
const CURRENT_ACCOUNT_ID = 'current_account_id'

// Brand page preview
export const BRAND_PAGE_PREVIEW_DATA = 'brand_page_preview_data'

// // Brand pages' design page's description for each template
export const DISMISS_BRAND_PAGE_BRAND_STORY_TEMPLATE = 'dismiss_brand_page_brand_story_template'
export const DISMISS_BRAND_PAGE_PRODUCT_COLLECTIONS_TEMPLATE =
  'dismiss_brand_page_product_collections_template'
export const DISMISS_BRAND_PAGE_BLANK_PAGE_TEMPLATE = 'dismiss_brand_page_blank_page_template'

// Notification banner dismissals
const NOTIFICATION_BANNER_DISMISSALS = 'dismissed_notification_banners'

// Bid Landscape modal/drawer selected table metrics
export const BID_LANDSCAPE_SELECTED_METRICS_DATA = 'bid_landscape_selected_metrics_data'

// Generic methods

// Get `key` from local storage. Will always return null if server-side rendering.
export function safeGetLocalStorage(key: string) {
  return canUseDOM ? localStorage.getItem(key) : null
}

// Get boolean value from local storage
export function getLocalStorageBool(key: string) {
  return localStorage.getItem(key) === 'true'
}

// Store boolean value in local storage
export function setLocalStorageBool(key: string, value = 'true') {
  localStorage.setItem(key, value)
}

// Store list in local storage
const setLocalStorageList = (key: string, list: string | string[]) =>
  localStorage.setItem(key, JSON.stringify(Array.isArray(list) ? list : [list]))

// Check local storage list contains
export function ifLocalStorageListContains(key: string, id: string) {
  const list = localStorage.getItem(key)
  if (!list) return false

  try {
    return JSON.parse(list).includes(id)
  } catch {
    return false
  }
}

// Add to local storage list
export function addToLocalStorageList(key: string, id: string) {
  if (ifLocalStorageListContains(key, id)) return

  const list = localStorage.getItem(key)

  if (!list) return setLocalStorageList(key, id)

  try {
    const currentList = JSON.parse(list)
    currentList.push(id)

    setLocalStorageList(key, currentList)
  } catch {
    setLocalStorageList(key, id)
  }
}

// Help Center methods
export const isHelpCenterTooltipDismissed = () => {
  return getLocalStorageBool(HELP_CENTER_TOOLTIP_DISMISSED)
}

export const setHelpCenterTooltipDismissed = () => {
  setLocalStorageBool(HELP_CENTER_TOOLTIP_DISMISSED)
}

// Feature hint
export const isFeatureHintDismissed = (id: string) => {
  return ifLocalStorageListContains(FEATURE_HINT_TOOLTIP_DISMISSALS, id)
}

export const setFeatureHintDismissed = (id: string) => {
  addToLocalStorageList(FEATURE_HINT_TOOLTIP_DISMISSALS, id)
}

// Debugging mode
export const isDebuggingModeOn = () => {
  return getLocalStorageBool(DEBUGGING_MODE_ON)
}

export const setDebuggingMode = (on: boolean) => {
  setLocalStorageBool(DEBUGGING_MODE_ON, JSON.stringify(on))
}

// Current Account Id
export const setCurrentAccountId = (id: number | string) => {
  localStorage.setItem(CURRENT_ACCOUNT_ID, String(id))
}

export const getCurrentAccountId = () => {
  return localStorage.getItem(CURRENT_ACCOUNT_ID)
}

export const clearCurrentAccountId = () => {
  localStorage.removeItem(CURRENT_ACCOUNT_ID)
}

// Brand page preview
export const setBrandPagePreviewData = <T>(data: T) => {
  localStorage.setItem(BRAND_PAGE_PREVIEW_DATA, JSON.stringify(data))
}

export const getBrandPagePreviewData = <T>() => {
  const brandPagePreviewData = localStorage.getItem(BRAND_PAGE_PREVIEW_DATA)
  if (!brandPagePreviewData) {
    return undefined
  }

  const parsedBrandPagePreviewData: T = JSON.parse(brandPagePreviewData)
  return parsedBrandPagePreviewData
}

// Brand page template descriptions
const getTemplateKey = (template: BrandPageTemplate) => {
  switch (template) {
    case 'brandStory':
      return DISMISS_BRAND_PAGE_BRAND_STORY_TEMPLATE
    case 'productCollections':
      return DISMISS_BRAND_PAGE_PRODUCT_COLLECTIONS_TEMPLATE
    case 'blankPage':
    default:
      return DISMISS_BRAND_PAGE_BLANK_PAGE_TEMPLATE
  }
}

export const setTemplateDismissed = (template: BrandPageTemplate) => {
  const key = getTemplateKey(template)
  setLocalStorageBool(key)
}

export const getTemplateDismissed = (template: BrandPageTemplate) => {
  const key = getTemplateKey(template)
  return getLocalStorageBool(key)
}

// Banner notification
export const isBannerNotificationDismissed = (id: string) => {
  return ifLocalStorageListContains(NOTIFICATION_BANNER_DISMISSALS, id)
}

export const setBannerNotificationDismissed = (id: string) => {
  addToLocalStorageList(NOTIFICATION_BANNER_DISMISSALS, id)
}

// Bid Landscape modal/drawer selected table metrics
export const setBidLandscapeSelectedMetricsData = <T>(data: T) => {
  localStorage.setItem(BID_LANDSCAPE_SELECTED_METRICS_DATA, JSON.stringify(data))
}

export const getBidLandscapeSelectedMetricsData = <T>() => {
  const bidLandscapeSelectedMetricsData = safeGetLocalStorage(BID_LANDSCAPE_SELECTED_METRICS_DATA)

  if (!bidLandscapeSelectedMetricsData) {
    return undefined
  }

  const parsedBidLandscapeSelectedMetricsData: T = JSON.parse(bidLandscapeSelectedMetricsData)
  return parsedBidLandscapeSelectedMetricsData
}
