// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppeals } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppeals'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppealsFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppealsToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppeals'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshot } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshot'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot'
import type { PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState } from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState'
import {
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSON,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateToJSON,
} from './PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface PostBrandPagesIdStatusParamDataAttributesEditorialStates
 */
export interface PostBrandPagesIdStatusParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  type: PostBrandPagesIdStatusParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  reviewState?: PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  entitySnapshot: PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  decisionSnapshot: PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppeals}
   * @memberof PostBrandPagesIdStatusParamDataAttributesEditorialStates
   */
  appeals: PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const PostBrandPagesIdStatusParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PostBrandPagesIdStatusParamDataAttributesEditorialStatesTypeEnum =
  typeof PostBrandPagesIdStatusParamDataAttributesEditorialStatesTypeEnum[keyof typeof PostBrandPagesIdStatusParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the PostBrandPagesIdStatusParamDataAttributesEditorialStates interface.
 */
export function instanceOfPostBrandPagesIdStatusParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSON(
  json: any
): PostBrandPagesIdStatusParamDataAttributesEditorialStates {
  return PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostBrandPagesIdStatusParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot: PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot:
      PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals: PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppealsFromJSON(
      json['appeals']
    ),
  }
}

export function PostBrandPagesIdStatusParamDataAttributesEditorialStatesToJSON(
  value?: PostBrandPagesIdStatusParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: PostBrandPagesIdStatusParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: PostBrandPagesIdStatusParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot:
      PostBrandPagesIdStatusParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
        value.decisionSnapshot
      ),
    appeals: PostBrandPagesIdStatusParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
