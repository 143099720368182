/* eslint-disable no-param-reassign */

import { createContext, useContext } from 'react'

type HeadContextSetType = 'title' | 'additional' | 'set'

interface HeadContext {
  title: string
  additional: JSX.Element[]
  set: (type: HeadContextSetType, children: JSX.Element[] | string) => void
  ready?: boolean
}

const defaultHeadContext = {
  title: 'Ads on Instacart',
  additional: [],
}

const HeadContext = createContext<HeadContext>({
  ...defaultHeadContext,
  set: () => {},
})
HeadContext.displayName = 'HeadContext'

// used to persist header context throughout a page view
// meant specifically for server rendering
// `context` should be an empty `{}` which will be hydrated
export const WithHeadContext = ({
  children,
  context,
}: {
  children: JSX.Element
  context: Partial<HeadContext>
}) => {
  if (!context.ready) {
    Object.assign(context, defaultHeadContext)
    context.set = (type, value) => {
      if (type === 'set' || defaultHeadContext[type] === undefined) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      context[type] = value as any
    }
    context.ready = true
  }

  return <HeadContext.Provider value={context as HeadContext}>{children}</HeadContext.Provider>
}

export const useHeadContext = () => useContext(HeadContext)
