// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage } from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage'
import {
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageFromJSON,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageFromJSONTyped,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageToJSON,
} from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage'
import type { ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreative } from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreative'
import {
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreativeFromJSON,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreativeFromJSONTyped,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreativeToJSON,
} from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreative'
import type { ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategy } from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategy'
import {
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategyFromJSON,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategyFromJSONTyped,
  ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategyToJSON,
} from './ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategy'
import type { PostPrototypeCampaignsParamCampaignAdGroupAssets } from './PostPrototypeCampaignsParamCampaignAdGroupAssets'
import {
  PostPrototypeCampaignsParamCampaignAdGroupAssetsFromJSON,
  PostPrototypeCampaignsParamCampaignAdGroupAssetsFromJSONTyped,
  PostPrototypeCampaignsParamCampaignAdGroupAssetsToJSON,
} from './PostPrototypeCampaignsParamCampaignAdGroupAssets'

/**
 *
 * @export
 * @interface ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
 */
export interface ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup {
  /**
   * id of the ad group, required for update
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  id?: string
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  name: string
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  campaignId?: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  placementType: string
  /**
   *
   * @type {ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreative}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  creative: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreative
  /**
   * Assets for the creative
   * @type {Array<PostPrototypeCampaignsParamCampaignAdGroupAssets>}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  assets: Array<PostPrototypeCampaignsParamCampaignAdGroupAssets>
  /**
   *
   * @type {ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategy}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  targetingStrategy: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategy
  /**
   *
   * @type {ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage}
   * @memberof ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup
   */
  brandPage?: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPage
}

/**
 * Check if a given object implements the ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup interface.
 */
export function instanceOfApiPrototypeCampaignsControllerCreateInputCampaignAdGroup(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'assets' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupFromJSON(
  json: any
): ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup {
  return ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupFromJSONTyped(json, false)
}

export function ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    placementType: json['placement_type'],
    creative: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreativeFromJSON(
      json['creative']
    ),
    assets: (json['assets'] as Array<any>).map(
      PostPrototypeCampaignsParamCampaignAdGroupAssetsFromJSON
    ),
    targetingStrategy:
      ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupToJSON(
  value?: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupCreativeToJSON(
      value.creative
    ),
    assets: (value.assets as Array<any>).map(
      PostPrototypeCampaignsParamCampaignAdGroupAssetsToJSON
    ),
    targeting_strategy:
      ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    brand_page: ApiPrototypeCampaignsControllerCreateInputCampaignAdGroupBrandPageToJSON(
      value.brandPage
    ),
  }
}
