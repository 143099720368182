import { css } from '@emotion/react'
import { Text, spacing, useTheme } from '@instacart/ids-core'
import { HovercardStateProps } from 'ariakit/hovercard'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import Analytics from 'common/analytics/Analytics'
import knowledgeOwl from 'common/knowledgeOwlWidget/knowledgeOwlWidget'
import { FlipperIdType } from 'common/types'
import useFeatureHint from 'common/useFeatureHint'
import useFlipper from 'common/useFlipper'
import FormattedMessage from 'components/FormattedMessage'
import { HovercardTooltip, SecondaryButton, PrimaryButton } from 'components/ids-ads'
import { ButtonRow } from 'components/molecules'
import { MessageIdType } from 'locales/types'

export interface FeatureHintTooltipProps {
  children?: JSX.Element
  placement?: HovercardStateProps['placement']
  id: string
  showOnItemDismissed?: string
  message: MessageIdType
  feature: string
  flipper?: FlipperIdType
  messageVariant?: string
  knowledgeOwlLink?: string
  showOnScroll?: boolean
  trigger?: boolean
  delay?: number
  ctaAlign?: CtaAlign
  dismissCtaText?: MessageIdType
  shift?: number
  onGotIt?: () => void
  onVisible?: () => void
}

// eslint-disable-next-line no-restricted-syntax
export enum CtaAlignValues {
  LEFT = 'left',
  RIGHT = 'right',
}

type CtaAlign = CtaAlignValues.LEFT | CtaAlignValues.RIGHT

type DivWithTippy = HTMLDivElement & {
  _tippy?: {
    show: () => void
    state: {
      isVisible: boolean
    }
  }
}

// eslint-disable-next-line no-restricted-syntax
enum ActionClickTypes {
  READ_MORE = 'read_more',
  DISMISS = 'dismiss',
}
interface TooltipContentProps {
  message: MessageIdType
  handleOnGotIt?: () => void
  handleOnReadMore?: () => void
  ctaAlign: CtaAlign
  dismissCtaText?: MessageIdType
  disableButtons?: boolean
}
interface ButtonProps {
  onClick: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  buttonText?: MessageIdType
  disabled?: boolean
}

const useStyles = () => {
  const theme = useTheme()

  return {
    contentCss: css({
      width: 296,
      pointerEvents: 'auto',
      overflow: 'hidden',
      padding: spacing.s4,
    }),
    textCss: css({
      marginBottom: spacing.s16,
    }),
    buttonCss: css({
      marginRight: spacing.s12,
    }),
    tooltip: {
      maxWidth: 310,
      backgroundColor: theme.colors.systemGrayscale00,
      color: theme.colors.systemGrayscale70,
      boxShadow: `0 0.5em 3em ${theme.colors.systemGrayscale30}`,
    },
  }
}

const GotItButton = ({ onClick, buttonText, disabled = false }: ButtonProps) => (
  <PrimaryButton compact onClick={onClick} disabled={disabled}>
    <FormattedMessage id={buttonText || 'common.tooltip.dismissCta'} />
  </PrimaryButton>
)

const ReadMoreButton = ({ onClick, disabled = false }: ButtonProps) => (
  <SecondaryButton compact onClick={onClick} disabled={disabled}>
    <FormattedMessage id="common.tooltip.readMore" />
  </SecondaryButton>
)

const TooltipContent = ({
  message,
  handleOnGotIt,
  handleOnReadMore,
  ctaAlign,
  disableButtons = false,
  dismissCtaText,
}: TooltipContentProps) => {
  const styles = useStyles()
  const alignRight = ctaAlign === CtaAlignValues.RIGHT

  return (
    <div css={styles.contentCss}>
      <div css={styles.textCss}>
        <Text typography="bodyMedium1">
          <FormattedMessage id={message} />
        </Text>
      </div>
      <ButtonRow right={alignRight} css={styles.buttonCss}>
        {alignRight && handleOnReadMore && (
          <ReadMoreButton onClick={handleOnReadMore} disabled={disableButtons} />
        )}
        {handleOnGotIt && (
          <GotItButton
            onClick={handleOnGotIt}
            buttonText={dismissCtaText}
            disabled={disableButtons}
          />
        )}
        {!alignRight && handleOnReadMore && (
          <ReadMoreButton onClick={handleOnReadMore} disabled={disableButtons} />
        )}
      </ButtonRow>
    </div>
  )
}

const FeatureHintTooltip: FC<React.PropsWithChildren<FeatureHintTooltipProps>> = ({
  id,
  showOnItemDismissed,
  message,
  messageVariant: variant,
  children,
  placement,
  feature,
  flipper,
  delay = 1000,
  showOnScroll = false,
  knowledgeOwlLink,
  trigger,
  ctaAlign = CtaAlignValues.LEFT,
  dismissCtaText,
  shift,
  onGotIt,
  onVisible,
}) => {
  const childElem = useRef<DivWithTippy>(null)
  const flipperEnabled = useFlipper(flipper)
  const { validHint, dismissHint } = useFeatureHint(id, showOnItemDismissed)
  const [isProcessing, setIsProcessing] = useState(false)
  const [visible, setVisible] = useState<boolean | undefined>(false)
  const styles = useStyles()

  const triggerTooltip = useCallback(() => {
    const SCROLL_OFFSET = 450
    // Check if the page is even scrollable
    const hasScrollReachedBottom = window.innerHeight + window.scrollY >= document.body.scrollHeight
    const childBottomPosition = childElem?.current?.getBoundingClientRect?.()?.bottom
    const childTippy = childElem?.current?._tippy

    if (
      hasScrollReachedBottom ||
      (childBottomPosition && childBottomPosition <= SCROLL_OFFSET && !childTippy?.state?.isVisible)
    ) {
      if (childTippy?.show) setTimeout(childTippy?.show, 0)
      window.removeEventListener('scroll', triggerTooltip)
    }
  }, [])

  useEffect(() => {
    setVisible(validHint)
  }, [validHint])

  useEffect(() => {
    if (visible) onVisible?.()
  }, [visible, onVisible])

  useEffect(() => {
    setTimeout(() => {
      if (showOnScroll) {
        window.addEventListener('scroll', triggerTooltip)
      }

      triggerTooltip()
    }, delay)

    return () => {
      if (showOnScroll) window.removeEventListener('scroll', triggerTooltip)
    }
  }, [showOnScroll, triggerTooltip, delay, trigger])

  if (!id) throw Error('No id present')

  const trackView = useCallback(() => {
    Analytics.track('feature_hint.view', {
      feature,
    })
  }, [feature])

  const trackClick = (action: string) => {
    Analytics.track('feature_hint.click', {
      feature: feature || id,
      action,
    })
  }

  const handleOnChange = useCallback(() => {
    trackView()
  }, [trackView])

  const handleDismiss = async (triggerAnalytics = true) => {
    setIsProcessing(true)
    await dismissHint()
    setIsProcessing(false)

    setVisible(false)
    if (triggerAnalytics) trackClick(ActionClickTypes.DISMISS)
    onGotIt?.()
  }

  const onReadMore = () => {
    if (knowledgeOwlLink) knowledgeOwl.openKnowledgeOwlArticle(knowledgeOwlLink)
    handleDismiss(false)
    trackClick(ActionClickTypes.READ_MORE)
  }

  let messageId = message
  if (variant) messageId += `.${variant}`

  if (!flipperEnabled) return children || null

  const childTippy = childElem?.current?._tippy

  return (
    <div css={{ whiteSpace: 'normal', textAlign: 'left' }}>
      <HovercardTooltip
        arrow
        hovercardStateProps={{
          visible: !showOnScroll || childTippy?.state?.isVisible,
          placement,
          shift,
        }}
        disabled={!visible}
        onShow={handleOnChange}
        styles={{ tooltip: styles.tooltip }}
        css={{ pointerEvents: 'auto' }}
        tooltipContent={
          <TooltipContent
            handleOnReadMore={knowledgeOwlLink ? onReadMore : undefined}
            handleOnGotIt={handleDismiss}
            message={messageId as MessageIdType}
            ctaAlign={ctaAlign}
            dismissCtaText={dismissCtaText}
            disableButtons={isProcessing}
          />
        }
      >
        <div ref={childElem} css={{ display: 'inline-block' }}>
          {children}
        </div>
      </HovercardTooltip>
    </div>
  )
}

export default FeatureHintTooltip
