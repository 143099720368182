import { FormikErrors } from 'formik'
import { IntlShape } from 'react-intl'
import {
  ClientError,
  FieldError,
  getApiErrorMessagesForParams,
  getNexusApiErrorMessagesForParams,
} from 'common/utils'
import { NotificationType, useNotifier } from 'context'
import useScrollToError from '../hooks/useScrollToError'
import useIntl from './useIntl'

export interface HandleApiErrorProps<T> {
  error: unknown
  params?: string[]
  notifier: ReturnType<typeof useNotifier>
  intl: IntlShape
  setFormikErrors?: (errors: FormikErrors<T> | FieldError) => void
  scrollToError?: ReturnType<typeof useScrollToError>
  isNexusRequest?: boolean
  errorMapping?: Record<string, string>
}

export const handleApiError = <T>({
  error,
  params = [],
  notifier,
  setFormikErrors,
  scrollToError,
  intl,
  isNexusRequest = true,
  errorMapping = {},
}: HandleApiErrorProps<T>) => {
  const { paramErrors, otherErrors } = isNexusRequest
    ? getNexusApiErrorMessagesForParams(intl, error as ClientError, params)
    : getApiErrorMessagesForParams(error as ClientError, params, '. ', errorMapping)

  // Note: checking meta.errors seems to catch everything other than paramErrors
  // @TODO: need to figure out other error cases so that non-railsErrors
  // (i.e. otherErrors) are being caught.
  const railsErrors = (error as ClientError)?.response?.data?.meta?.errors

  const sendErrorNotifierMessage = (errors: { [param: string]: string[] | string }) => {
    for (const [key, value] of Object.entries(errors)) {
      const errMsg = Array.isArray(value) ? Object.values(value).join(',') : value
      const message = intl.formatMessage(
        { id: 'common.error.whileSubmitting' },
        { error: `${key}: ${errMsg}` }
      )

      notifier.sendNotification({ message, type: NotificationType.ERROR })
    }
  }

  const hasParamErrors = paramErrors && Object.values(paramErrors).length
  const hasRailsErrors = railsErrors && Object.values(railsErrors).length

  if (hasParamErrors) scrollToError?.(paramErrors)

  if (hasParamErrors && setFormikErrors) {
    setFormikErrors(paramErrors)
  } else if (hasParamErrors && !setFormikErrors) {
    // When we are trying to show param errors but there is no formik form present, use notifier
    sendErrorNotifierMessage(paramErrors)
  } else if (otherErrors) {
    notifier.sendNotification({
      message: otherErrors,
      type: NotificationType.ERROR,
    })
  } else if (hasRailsErrors) {
    sendErrorNotifierMessage(railsErrors)
    throw error
  } else {
    notifier.sendNotification({
      messageId: 'common.error.generic',
      type: NotificationType.ERROR,
    })
  }

  return {
    paramErrors,
    otherErrors,
    railsErrors,
  }
}

export const useHandleApiError = (errorIds?: Record<string, string>) => {
  const scrollToError = useScrollToError(errorIds || {})
  const intl = useIntl()
  const notifier = useNotifier()
  return <T>(props: Omit<HandleApiErrorProps<T>, 'intl' | 'notifier'>) =>
    handleApiError({ ...props, intl, notifier, scrollToError })
}
