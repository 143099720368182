// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetVideoAdGroupsParamDisplayProductAssetSnapshot
 */
export interface GetVideoAdGroupsParamDisplayProductAssetSnapshot {
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamDisplayProductAssetSnapshot
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof GetVideoAdGroupsParamDisplayProductAssetSnapshot
   */
  discriminator: GetVideoAdGroupsParamDisplayProductAssetSnapshotDiscriminatorEnum
  /**
   * Product Asset preview url
   * @type {string}
   * @memberof GetVideoAdGroupsParamDisplayProductAssetSnapshot
   */
  previewUrl?: string
  /**
   * Product Asset alt text
   * @type {string}
   * @memberof GetVideoAdGroupsParamDisplayProductAssetSnapshot
   */
  altText?: string
}

/**
 * @export
 */
export const GetVideoAdGroupsParamDisplayProductAssetSnapshotDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetVideoAdGroupsParamDisplayProductAssetSnapshotDiscriminatorEnum =
  typeof GetVideoAdGroupsParamDisplayProductAssetSnapshotDiscriminatorEnum[keyof typeof GetVideoAdGroupsParamDisplayProductAssetSnapshotDiscriminatorEnum]

/**
 * Check if a given object implements the GetVideoAdGroupsParamDisplayProductAssetSnapshot interface.
 */
export function instanceOfGetVideoAdGroupsParamDisplayProductAssetSnapshot(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSON(
  json: any
): GetVideoAdGroupsParamDisplayProductAssetSnapshot {
  return GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped(json, false)
}

export function GetVideoAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVideoAdGroupsParamDisplayProductAssetSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    previewUrl: !exists(json, 'preview_url') ? undefined : json['preview_url'],
    altText: !exists(json, 'alt_text') ? undefined : json['alt_text'],
  }
}

export function GetVideoAdGroupsParamDisplayProductAssetSnapshotToJSON(
  value?: GetVideoAdGroupsParamDisplayProductAssetSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    preview_url: value.previewUrl,
    alt_text: value.altText,
  }
}
