export const printLines = (lines?: string[]) =>
  lines && lines.map((line, ind) => `(${ind + 1}) ${line}`).join('\n')

export const formatTraceInfo = (lines?: string[]) => {
  if (!lines) return

  // DS and ML are separated by an empty string
  const splitIndex = lines.indexOf('')

  // trace info only includes either DS/ML
  if (splitIndex === -1) {
    return printLines(lines)
  }

  // trace info includes both DS + ML
  let output = ''

  const a = lines.slice(0, splitIndex)
  const b = lines.slice(splitIndex + 1)

  output += `${a.shift()?.toUpperCase()}\n`
  output += printLines(a)
  output += '\n\n'
  output += `${b.shift()?.toUpperCase()}\n`
  output += printLines(b)

  return output
}
