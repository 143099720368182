import { CSSInterpolation } from '@emotion/serialize'
import {
  Base,
  InputBase,
  POSITION_Y_CENTER,
  StylesOf,
  Theme,
  Variant,
  combineStyles,
  internalSVGIcon,
  spacing,
  useTheme,
} from '@instacart/ids-core'
import { merge } from 'lodash'
import { forwardRef } from 'react'
import { InputProps } from 'reakit'
import { adsInputStyles } from './adsInputStyles'

const { SVGIcon } = internalSVGIcon

const getIconSize = (compact?: boolean) => (compact ? 12 : 16)
const getInputPadding = (compact?: boolean) => (compact ? 28 : 38)

const getIconStyles = ({ alignRight, compact }: { alignRight: boolean; compact?: boolean }) => {
  const iconPos = compact ? spacing.s8 : spacing.s12

  return {
    icon: {
      position: 'absolute',
      ...(alignRight ? { right: iconPos } : { left: iconPos }),
      ...POSITION_Y_CENTER,
    },
    iconInput: {
      position: 'relative',
    },
  } as const
}

const getInputStyles = ({
  theme,
  alignRight,
  compact,
}: {
  theme: Theme
  alignRight: boolean
  compact?: boolean
}): StylesOf<typeof InputBase> => {
  const iconPadding = getInputPadding(compact)

  const styles = adsInputStyles({ theme, compact }) as { input?: CSSInterpolation }

  return {
    ...adsInputStyles({ theme, compact }),
    input: {
      ...(styles?.input as object),
      ...(alignRight ? { paddingRight: iconPadding } : { paddingLeft: iconPadding }),
      '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  }
}

export interface IconInputBaseProps extends Variant<typeof InputBase>, Base<typeof getIconStyles> {
  component: JSX.Element
  type?: InputProps['type']
  alignRight?: boolean
  iconSize?: number
  compact?: boolean
  inputStyles?: StylesOf<typeof InputBase>
}

export const IconInputBase = forwardRef<HTMLInputElement, IconInputBaseProps>(
  function IconInputBase(
    {
      id,
      component,
      styles,
      inputStyles: inputStyleOverrides,
      disabled,
      alignRight = false,
      compact = false,
      iconSize = getIconSize(compact),
      ...rest
    },
    ref
  ) {
    const theme = useTheme()
    const { icon, iconInput } = combineStyles(
      getIconStyles({ alignRight, compact }),
      styles
    ) as Record<string, CSSInterpolation>
    const inputStyles = merge(getInputStyles({ theme, alignRight, compact }), inputStyleOverrides)
    const color = disabled ? 'systemGrayscale30' : 'systemGrayscale70'

    return (
      <div css={iconInput}>
        <InputBase
          id={id}
          styles={inputStyles}
          ref={ref}
          data-testid="icon-input-base"
          disabled={disabled}
          {...rest}
        />
        {!rest.readOnly && (
          <SVGIcon aria-hidden size={iconSize} component={component} css={icon} color={color} />
        )}
      </div>
    )
  }
)

IconInputBase.displayName = 'IconInputBase'
