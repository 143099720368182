// Taken from @tippy/core for arrow/animation styling
import { css } from '@emotion/react'

export const vendorOverrideAnimationCss = css`
  &[data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
  }
  &[data-animation='shift-away'][data-state='hidden'][data-placement^='top'] {
    transform: translateY(10px);
  }
  &[data-animation='shift-away'][data-state='hidden'][data-placement^='bottom'] {
    transform: translateY(-10px);
  }
  &[data-animation='shift-away'][data-state='hidden'][data-placement^='left'] {
    transform: translateX(10px);
  }
  &[data-animation='shift-away'][data-state='hidden'][data-placement^='right'] {
    transform: translateX(-10px);
  }
`

export const vendorOverrideSvgArrowCss = css`
  &[data-placement^='top'] > .tippy-svg-arrow {
    bottom: 0;
  }
  &[data-placement^='top'] > .tippy-svg-arrow:after,
  &[data-placement^='top'] > .tippy-svg-arrow > svg {
    top: 16px;
    transform: rotate(180deg);
  }
  &[data-placement^='bottom'] > .tippy-svg-arrow {
    top: 0;
  }
  &[data-placement^='bottom'] > .tippy-svg-arrow > svg {
    bottom: 16px;
  }
  &[data-placement^='left'] > .tippy-svg-arrow {
    right: 0;
  }
  &[data-placement^='left'] > .tippy-svg-arrow:after,
  &[data-placement^='left'] > .tippy-svg-arrow > svg {
    transform: rotate(90deg);
    top: calc(50% - 3px);
    left: 11px;
  }
  &[data-placement^='right'] > .tippy-svg-arrow {
    left: 0;
  }
  &[data-placement^='right'] > .tippy-svg-arrow:after,
  &[data-placement^='right'] > .tippy-svg-arrow > svg {
    transform: rotate(-90deg);
    top: calc(50% - 3px);
    right: 11px;
  }
  .tippy-svg-arrow {
    width: 16px;
    height: 16px;
    fill: #333;
    text-align: initial;
  }
  .tippy-svg-arrow,
  .tippy-svg-arrow > svg {
    position: absolute;
  }
`
