import { merge } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { useIntl } from 'common'
import useDebouncedCallback from 'hooks/useDebouncedCallback'
import { MessageIdType } from 'locales/types'
import { InputSearch, InputSearchProps } from './InputSearch'

export interface SearchFilterProps extends Omit<InputSearchProps, 'onChange'> {
  onChange?(query: string): void
  initialValue?: string
  debounced?: boolean
  debounceDelay?: number
  placeholderId?: MessageIdType
  block?: boolean
  minWidth?: boolean
}

export function SearchFilter({
  onChange = () => {},
  initialValue = '',
  debounced = false,
  debounceDelay = 250,
  placeholderId,
  block = false,
  compact = true,
  minWidth = true,
  ...props
}: SearchFilterProps) {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState(initialValue)
  const updateSearchQuery = useDebouncedCallback(onChange, debounced ? debounceDelay : 0)

  // Update internal search query state if initialValue changes.
  // Useful for clearing the input from a parent component.
  // Note that we don't call updateSearchQuery here because we expect the value of initialValue
  // to be consistent with the parent component's state.
  useEffect(() => {
    setSearchQuery(initialValue)
  }, [initialValue])

  const handleSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target) return
    setSearchQuery(target.value)
    updateSearchQuery(target.value)
  }

  const placeholder = placeholderId ? intl.formatMessage({ id: placeholderId }) : props.placeholder

  const inputStyles = merge(
    {
      input: block
        ? {
            display: 'block',
          }
        : minWidth
        ? {
            width: 'auto',
            minWidth: 250,
          }
        : {
            width: 'auto',
          },
    },
    props.inputStyles || {}
  )

  const styles = merge(
    block
      ? {
          iconInput: {
            display: 'block',
            width: '100%',
          },
        }
      : {},
    props.styles || {}
  )

  return (
    <InputSearch
      inline
      data-testid="search-input-wrapper"
      {...props}
      compact={compact}
      inputStyles={inputStyles}
      styles={styles}
      placeholder={placeholder}
      value={searchQuery}
      onChange={handleSearchChange}
    />
  )
}
