import { SYSTEM_DETRIMENTAL_MEDIUM } from 'components/ids-ads/atoms'
import { UseStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

const styles: UseStylesOpts = {
  normalBackground: 'systemDetrimentalRegular',
  borderColor: null,
  textColor: 'systemGrayscale00',
  hoverBackground: SYSTEM_DETRIMENTAL_MEDIUM,
  activeBackground: 'systemDetrimentalDark',
}

export const DetrimentalButton = makeButton('DetrimentalButton', styles)
export const DetrimentalLinkButton = makeLinkButton('DetrimentalLinkButton', styles)
export const DetrimentalAnchorButton = makeAnchorButton('DetrimentalAnchorButton', styles)
