import { css, SerializedStyles } from '@emotion/react'
import { SecondaryButton } from '@instacart/ids-tooling'
import { useIntl } from 'common'
import { MessageIdType } from 'locales/types'
import { CardTitle, CardTitleProps } from './CardTitle'

export interface CardTitleWithButtonProps extends CardTitleProps {
  children: React.ReactNode
  buttonId?: MessageIdType
  buttonStyles?: SerializedStyles
  onClick?: () => void
  testId?: string
}

function useStyles() {
  return {
    title: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }),
    button: css({
      minWidth: 'unset',
    }),
  }
}
export function CardTitleWithButton({
  children,
  buttonId = 'common.edit',
  buttonStyles,
  onClick,
  testId,
  ...rest
}: CardTitleWithButtonProps) {
  const styles = useStyles()
  const { formatMessage } = useIntl()

  return (
    <CardTitle {...rest} data-testid={testId}>
      <div css={styles.title}>
        {children}
        {onClick && (
          <SecondaryButton
            data-testid={`${testId}-title-button`}
            type="button"
            css={[styles.button, buttonStyles]}
            onClick={onClick}
          >
            {formatMessage({ id: buttonId })}
          </SecondaryButton>
        )}
      </div>
    </CardTitle>
  )
}
