// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels
 */
export interface PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels {
  /**
   * Id of the label
   * @type {string}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels
   */
  id?: string
  /**
   * Version of the label
   * @type {number}
   * @memberof PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels
   */
  version?: number
}

/**
 * Check if a given object implements the PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels interface.
 */
export function instanceOfPutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabelsFromJSON(
  json: any
): PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels {
  return PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabelsFromJSONTyped(json, false)
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabelsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
  }
}

export function PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabelsToJSON(
  value?: PutDisplayCampaignsIdParamAssetFieldDecisionsVideoLabels | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
  }
}
