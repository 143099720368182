import { RefObject, useCallback, useState } from 'react'
import { ReCaptchaWindow } from './reCaptcha.types'
import { useReCaptchaScript } from './reCaptchaScript.hooks'

// eslint-disable-next-line no-restricted-syntax
export enum ReCaptchaAction {
  ADS_LOGIN = 'ADS_LOGIN',
  ADS_FORGOT_PASSWORD = 'ADS_FORGOT_PASSWORD',
  ADS_SIGN_UP = 'ADS_SIGN_UP',
}

export interface UseRenderReCaptchaProps {
  ref: RefObject<HTMLDivElement>
  action: ReCaptchaAction
  onLoad?: () => void
  onError?: () => void
  onChange?: (token: string) => void
  setResetHandler?: (resetHandler: () => void) => void
}

export const useReCaptchaRenderer = ({
  ref,
  action,
  onLoad = () => null,
  onError = () => null,
  onChange = () => null,
  setResetHandler = () => null,
}: UseRenderReCaptchaProps) => {
  const [hasRendered, setHasRendered] = useState(false)

  const renderCaptcha = useCallback(() => {
    const reCaptchaHandler = (window as ReCaptchaWindow).grecaptcha?.enterprise
    const node = ref.current

    // if recaptcha cannot be displayed, send an error
    if (!reCaptchaHandler || !node) {
      onError()
      return
    }

    reCaptchaHandler.ready(() => {
      reCaptchaHandler.render(node, {
        action,
        sitekey: window.__ADS.env.GOOGLE_RECAPTCHA_SITE_KEY,
        callback: onChange,
        'error-callback': onError,
      })

      setResetHandler(reCaptchaHandler.reset)
    })
  }, [ref, action, onChange, onError, setResetHandler])

  // Once we've loaded the script, render the captcha, then trigger the onLoad callback
  const handleScriptLoad = useCallback(() => {
    if (!hasRendered) {
      renderCaptcha()
      onLoad()
      setHasRendered(true)
    }
  }, [renderCaptcha, onLoad, hasRendered])

  useReCaptchaScript(handleScriptLoad)
}
