import { useIntl } from 'common'
import {
  PrimaryButton,
  SecondaryButton,
  DetrimentalButton,
  ButtonProps,
} from 'components/ids-ads/molecules'
import ButtonRow from 'components/molecules/ButtonRow'
import { GenericMessageDescriptor } from 'locales/types'

export interface ModalButtonsProps {
  onClose?(): void
  primaryButtonProps?: ButtonProps
  primaryButtonLabel?: GenericMessageDescriptor
  primaryTestId?: string
  secondaryButtonProps?: ButtonProps
  secondaryButtonLabel?: GenericMessageDescriptor
  secondaryTestId?: string
  additonalButtons?: React.ReactNode
}

export function ModalButtons({
  onClose,
  primaryButtonProps,
  primaryButtonLabel = 'common.submit',
  primaryTestId,
  secondaryButtonProps,
  secondaryButtonLabel = 'common.cancel',
  secondaryTestId,
  isDetrimental,
  additonalButtons,
}: ModalButtonsProps & { isDetrimental?: boolean }) {
  const { genericFormatMessage } = useIntl()

  const ConfirmButton = isDetrimental ? DetrimentalButton : PrimaryButton
  return (
    <ButtonRow right>
      {additonalButtons}
      <SecondaryButton
        type="button"
        {...secondaryButtonProps}
        onClick={onClose}
        data-testid={secondaryTestId ?? 'secondary-cta-button'}
      >
        {genericFormatMessage(secondaryButtonLabel)}
      </SecondaryButton>
      <ConfirmButton
        type="button"
        {...primaryButtonProps}
        data-testid={primaryTestId ?? 'primary-cta-button'}
      >
        {genericFormatMessage(primaryButtonLabel)}
      </ConfirmButton>
    </ButtonRow>
  )
}
