// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetProductsResponseData } from './GetProductsResponseData'
import {
  GetProductsResponseDataFromJSON,
  GetProductsResponseDataFromJSONTyped,
  GetProductsResponseDataToJSON,
} from './GetProductsResponseData'
import type { GetProductsResponseMeta } from './GetProductsResponseMeta'
import {
  GetProductsResponseMetaFromJSON,
  GetProductsResponseMetaFromJSONTyped,
  GetProductsResponseMetaToJSON,
} from './GetProductsResponseMeta'

/**
 *
 * @export
 * @interface GetProductsResponse
 */
export interface GetProductsResponse {
  /**
   *
   * @type {Array<GetProductsResponseData>}
   * @memberof GetProductsResponse
   */
  data: Array<GetProductsResponseData>
  /**
   *
   * @type {GetProductsResponseMeta}
   * @memberof GetProductsResponse
   */
  meta: GetProductsResponseMeta
}

/**
 * Check if a given object implements the GetProductsResponse interface.
 */
export function instanceOfGetProductsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetProductsResponseFromJSON(json: any): GetProductsResponse {
  return GetProductsResponseFromJSONTyped(json, false)
}

export function GetProductsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetProductsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(GetProductsResponseDataFromJSON),
    meta: GetProductsResponseMetaFromJSON(json['meta']),
  }
}

export function GetProductsResponseToJSON(value?: GetProductsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(GetProductsResponseDataToJSON),
    meta: GetProductsResponseMetaToJSON(value.meta),
  }
}
