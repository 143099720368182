const CURRENT_ACCOUNT_ID = 'CurrentAccountId'
const FLIPPERS = 'flippers'
export const PRODUCT_SEARCH_UPC_HINT_KEY = 'pages.adGroupEdit.productsTable.hasSeenSearchHintToast'

export const setCurrentAccountId = (id: number | string | null = null) => {
  sessionStorage.setItem(CURRENT_ACCOUNT_ID, String(id))
}

export const getCurrentAccountId = () => {
  return sessionStorage.getItem(CURRENT_ACCOUNT_ID)
}

export const clearCurrentAccountId = () => {
  sessionStorage.removeItem(CURRENT_ACCOUNT_ID)
}

export const setFlippers = (flippers: string[]) => {
  sessionStorage.setItem(FLIPPERS, JSON.stringify(flippers))
}

export const setHasSeenProductSearchHint = () => {
  sessionStorage.setItem(PRODUCT_SEARCH_UPC_HINT_KEY, 'true')
}

export const getHasSeenProductSearchHint = () => {
  return sessionStorage.getItem(PRODUCT_SEARCH_UPC_HINT_KEY) === 'true'
}
