// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentText } from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import {
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentText'
import type { PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle } from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'
import {
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent
 */
export interface PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  title?: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentText}
   * @memberof PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent
   */
  text?: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfPutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  return PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: PutUniversalAdGroupsIdParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
