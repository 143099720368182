import { css, SerializedStyles } from '@emotion/react'
import { useTheme, spacing, SearchIcon } from '@instacart/ids-core'
import { InputDescriptionErrorText } from '@instacart/ids-tooling'
import { ChangeEvent, CSSProperties, useEffect, useState, useContext } from 'react'
import toPx from 'common/toPx'
import { SearchFilterContext, SearchFilterStylesType } from 'context/searchFilterContext'
import useDebouncedCallback from 'hooks/useDebouncedCallback'
import { useFormikField } from 'hooks/useFormikField'
import { SlimInput, SlimInputProps } from './SlimInput'

export interface SearchFilterProps extends Omit<SlimInputProps, 'onChange'> {
  onChange?(query: string): void
  initialValue?: string
  debounced?: boolean
  debounceDelay?: number
  containerCss?: SerializedStyles
}

function useStyles(styleOverrides?: SearchFilterStylesType) {
  const theme = useTheme()

  const icon: CSSProperties = {
    position: 'absolute',
    left: spacing.s4,
    top: '50%',
    transform: 'translateY(-50%)',
    fill: theme.colors.systemGrayscale50,
    ...styleOverrides?.icon,
  }

  return {
    container: css({
      position: 'relative',
    }),
    input: css({
      width: 260,
      padding: toPx`${spacing.s4} ${spacing.s8} ${spacing.s4} ${spacing.s32}`,
      ...styleOverrides?.input,
    }),
    icon,
  }
}

/** @deprecated Use SearchFilter from 'components/ids-ads' instead */
export function SearchFilter({
  onChange = () => {},
  initialValue = '',
  debounced = false,
  debounceDelay = 250,
  id,
  containerCss,
  ...props
}: SearchFilterProps) {
  const { styles: styleOverrides, fieldName } = useContext(SearchFilterContext)
  const styles = useStyles(styleOverrides)

  const [searchQuery, setSearchQuery] = useState(initialValue)
  const updateSearchQuery = useDebouncedCallback(onChange, debounced ? debounceDelay : 0)

  let hasError = false
  let errorText

  if (fieldName) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [field, { error, initialError, touched }] = useFormikField(fieldName || '')
    hasError = (!!initialError && field.value === initialValue) || (touched && !!error)
    errorText = error || initialError
  }

  // Update internal search query state if initialValue changes.
  // Useful for clearing the input from a parent component.
  // Note that we don't call updateSearchQuery here because we expect the value of initialValue
  // to be consistent with the parent component's state.
  useEffect(() => {
    setSearchQuery(initialValue)
  }, [initialValue])

  const handleSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target) return
    setSearchQuery(target.value)
    updateSearchQuery(target.value)
  }

  return (
    <div>
      <div css={[styles.container, containerCss]} data-testid="search-input-wrapper">
        <SlimInput
          value={searchQuery}
          onChange={handleSearchChange}
          {...props}
          css={styles.input}
          hasError={hasError}
        />
        <SearchIcon size={24} style={styles.icon} />
      </div>
      <div>
        {hasError && (
          <InputDescriptionErrorText id="some-id">{errorText || ''}</InputDescriptionErrorText> // TODO RANDOM ID
        )}
      </div>
    </div>
  )
}
