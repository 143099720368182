import { css, CSSObject, PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import { elevation, layers, spacing } from '@instacart/ids-core'
import {
  CSSProperties,
  MouseEvent,
  ReactNode,
  cloneElement,
  forwardRef,
  MouseEventHandler,
  ComponentType,
  PropsWithChildren,
} from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuButton, MenuItem } from 'reakit'
import withTheme, { WithThemeProps } from 'common/ids/withTheme'
import { MIN_WIDTH, Position } from '../common'
import {
  DropdownMenuContext,
  DropdownMenuInitialState,
  DropdownMenuState,
  useDropdownMenuContext,
  useDropdownMenuState,
} from './utils'

export interface DropdownMenuProps {
  trigger: JSX.Element | ((menuState: DropdownMenuState) => JSX.Element)
  children: ReactNode
  position?: Position
  innerCss?: CSSProperties
  dropdownMenuInitialState?: DropdownMenuInitialState
}

export const MenuItemsComponent = withTheme(
  styled.div(
    ({
      theme,
      position = 'left',
      innerCss,
    }: WithThemeProps & Pick<DropdownMenuProps, 'position' | 'innerCss'>) =>
      css({
        background: theme.colors.systemGrayscale00,
        padding: spacing.s8,
        borderRadius: theme.radius.r12,
        boxSizing: 'border-box',
        minWidth: MIN_WIDTH,
        position: 'absolute',
        right: position === 'right' ? 0 : undefined,
        whiteSpace: 'nowrap',
        zIndex: layers.l1,
        ...elevation.low.shadow,
        '&:focus-visible': {
          outline: 'none',
        },
        ...innerCss,
      })
  )
)

function withMenuItem<P extends { onClick?: MouseEventHandler }>(
  Component: ComponentType<React.PropsWithChildren<PropsWithChildren<P>>>
) {
  return function WrappedComponent({ children, onClick, ...props }: PropsWithChildren<P>) {
    const dropdownMenuState = useDropdownMenuContext()

    if (!dropdownMenuState) {
      throw new Error('Tried to render a dropdown menu item without DropdownMenuContext')
    }

    const handleClick = (e: MouseEvent) => {
      onClick?.(e)
      dropdownMenuState.hide()
    }

    return (
      <MenuItem {...dropdownMenuState} {...props} onClick={handleClick}>
        {
          ((itemProps: P) => (
            <Component {...itemProps}>{children}</Component>
          )) as unknown as ReactNode
        }
      </MenuItem>
    )
  }
}

const menuItemCommonStyles = ({ theme }: WithThemeProps): CSSObject => ({
  ...theme.typography.bodyMedium1,
  position: 'relative',
  display: 'block',
  padding: spacing.s8,
  borderRadius: theme.radius.r8,
  background: 'transparent',
  textAlign: 'left',
  cursor: 'pointer',
  '&:focus-visible': { outline: 'none' },
  '&:not(:hover):focus-visible::after': {
    border: `2px solid ${theme.colors.systemGrayscale70}`,
    content: '""',
    display: 'block',
    position: 'absolute',
    top: -1,
    bottom: -1,
    left: -1,
    right: -1,
    borderRadius: theme.radius.r8,
    zIndex: layers.l1,
  },
  '&:hover': {
    background: theme.colors.systemGrayscale10,
  },
  '&:active': {
    background: theme.colors.systemGrayscale20,
  },
})

export const DropdownMenuItem = withMenuItem(
  withTheme(
    styled.button(({ theme }: WithThemeProps) => ({
      ...menuItemCommonStyles({ theme }),
      border: 'none',
      width: '100%',
      '&:disabled': {
        color: theme.colors.systemGrayscale30,
        cursor: 'initial',
      },
    }))
  )
)

const MenuAnchorItemBase = styled.a(({ theme }: WithThemeProps & { disabled?: boolean }) => ({
  ...menuItemCommonStyles({ theme }),
  '&, &:active, &:hover, &:visited': {
    color: theme.colors.systemGrayscale70,
  },
  '&[aria-disabled="true"]': {
    color: theme.colors.systemGrayscale30,
  },
}))

export const DropdownMenuAnchorItem = withMenuItem(withTheme(MenuAnchorItemBase))

export const DropdownMenuLinkItem = withMenuItem(withTheme(MenuAnchorItemBase.withComponent(Link)))

export const DropdownSectionHeading = withTheme(
  styled.div(({ theme }: WithThemeProps) => ({
    ...theme.typography.bodyMedium2,
    color: theme.colors.systemGrayscale70,
    padding: spacing.s8,
  }))
)

export interface DropdownMenuItemsProps extends PropsOf<typeof MenuItemsComponent> {
  dropdownMenuState: DropdownMenuState
}

export function DropdownMenuItems({
  dropdownMenuState,
  children,
  ...props
}: DropdownMenuItemsProps) {
  return (
    <Menu {...dropdownMenuState} as={MenuItemsComponent} {...props}>
      <DropdownMenuContext.Provider value={dropdownMenuState}>
        {children}
      </DropdownMenuContext.Provider>
    </Menu>
  )
}

export const DropdownMenu = forwardRef<HTMLButtonElement, DropdownMenuProps>(
  ({ trigger, children, position = 'left', dropdownMenuInitialState, innerCss, ...props }, ref) => {
    const dropdownMenuState = useDropdownMenuState({
      position,
      ...dropdownMenuInitialState,
    })

    const triggerElement = typeof trigger === 'function' ? trigger(dropdownMenuState) : trigger

    return (
      <>
        <MenuButton ref={ref} {...dropdownMenuState} {...props} {...triggerElement.props}>
          {triggerProps => cloneElement(triggerElement, triggerProps)}
        </MenuButton>
        <DropdownMenuItems dropdownMenuState={dropdownMenuState} innerCss={innerCss}>
          {children}
        </DropdownMenuItems>
      </>
    )
  }
)
