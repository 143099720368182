// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RecipeProductsResponseDataAttributes
 */
export interface RecipeProductsResponseDataAttributes {
  /**
   * List of product ids in the recipe.
   * @type {Array<string>}
   * @memberof RecipeProductsResponseDataAttributes
   */
  productIds: Array<string>
}

/**
 * Check if a given object implements the RecipeProductsResponseDataAttributes interface.
 */
export function instanceOfRecipeProductsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'productIds' in value

  return isInstance
}

export function RecipeProductsResponseDataAttributesFromJSON(
  json: any
): RecipeProductsResponseDataAttributes {
  return RecipeProductsResponseDataAttributesFromJSONTyped(json, false)
}

export function RecipeProductsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecipeProductsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productIds: json['product_ids'],
  }
}

export function RecipeProductsResponseDataAttributesToJSON(
  value?: RecipeProductsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_ids: value.productIds,
  }
}
