import { css } from '@emotion/react'
import { useFormikContext } from 'formik'
import { get, isEqual } from 'lodash'
import { useContext, useEffect } from 'react'
import { IntlShape } from 'react-intl'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import useIsInitialRender from 'hooks/useIsInitialRender'
import { MessageIdType } from 'locales/types'
import {
  getTargetingSegmentsFieldName,
  SegmentType,
  DEFAULT_TARGETING_SEGMENT,
} from '../../constants'
import { TargetingRuleContext } from '../../hooks'
import { TargetingFormData, TargetingSegmentType } from '../../types'

interface TargetingSegmentsCollapsedSectionProps {
  segmentType: SegmentType
}

const getSegmentsWrittenSummary = ({
  intl,
  segmentType,
  segments,
  isReviewing,
}: {
  intl: IntlShape
  segmentType: SegmentType
  segments: TargetingSegmentType[]
  isReviewing: boolean
}) => {
  return segments.reduce((accumulatedText, segment, index) => {
    let text = accumulatedText
    if (index) {
      text = `${text}${isReviewing ? '\n' : ' '}${intl.formatMessage({
        id: 'pages.displayProduct.common.targetingRule.segment.and.title',
      })} `
    }

    text = `${text}${intl.formatMessage({
      id: `pages.displayProduct.common.targetingRule.segment.${
        index ? 'and' : 'first'
      }.inputsPrefix`,
    })} ${intl.formatMessage({
      id: `pages.displayProduct.common.targetingRule.segment.condition.${segmentType.toLowerCase()}.${segment.condition.toLowerCase()}` as MessageIdType,
    })}`

    if (segmentType === SegmentType.INTEREST) {
      text = `${text} ${intl.formatMessage({
        id: 'pages.displayProduct.common.targetingRule.segment.interest.subjects.label',
      })}`
    }

    if (segmentType === SegmentType.PURCHASE) {
      text = `${text} ${intl.formatMessage({
        id: 'pages.displayProduct.common.targetingRule.segment.purchased.subjects.label',
      })}`
    }

    const subjects = get(segment, segment.subject.toLowerCase())
    text = `${text} ${intl.formatMessage(
      {
        id: `pages.displayProduct.common.targetingRule.segments.collapsed.subject.${segment.subject.toLowerCase()}` as MessageIdType,
      },
      {
        count: subjects.length,
      }
    )} ${intl.formatMessage({
      id: 'pages.displayProduct.common.targetingRule.segment.timeline.label',
    })} ${intl.formatMessage({
      id: `pages.displayProduct.common.targetingRule.segment.timeline.${segment.timeline}Days` as MessageIdType,
    })}`

    if (index === segments.length - 1) {
      return `${text}.`
    }
    return text
  }, '')
}

export default function TargetingSegmentsCollapsedSection({
  segmentType,
}: TargetingSegmentsCollapsedSectionProps) {
  const isInitialRender = useIsInitialRender()
  const intl = useIntl()

  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)

  const { values, setFieldValue } = useFormikContext<TargetingFormData>()
  const segmentsFieldName = getTargetingSegmentsFieldName(segmentType)
  const segments: TargetingSegmentType[] = get(values, segmentsFieldName)

  useEffect(() => {
    if (
      isInitialRender &&
      segments.length === 1 &&
      isEqual(segments[0], DEFAULT_TARGETING_SEGMENT) &&
      !isReadOnly &&
      !isReviewing
    ) {
      setFieldValue(segmentsFieldName, [])
    }
  }, [
    isInitialRender,
    isReadOnly,
    isReviewing,
    setFieldValue,
    segments,
    segments.length,
    segmentsFieldName,
  ])

  const nonEmptySegments = segments.filter(
    segment => get(segment, segment.subject.toLowerCase())?.length
  )

  if (nonEmptySegments.length) {
    return (
      <div css={css({ whiteSpace: 'pre-wrap' })}>
        {getSegmentsWrittenSummary({ intl, segmentType, isReviewing, segments: nonEmptySegments })}
      </div>
    )
  }

  if (isReadOnly || isReviewing) {
    return (
      <FormattedMessage id="pages.displayProduct.common.targetingRule.segments.collapsed.noneSelected" />
    )
  }

  return (
    <FormattedMessage
      id={
        `pages.displayProduct.common.targetingRule.segments.collapsed.${segmentType.toLowerCase()}.label` as MessageIdType
      }
    />
  )
}
