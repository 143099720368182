// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { RecipeProductsResponseData } from './RecipeProductsResponseData'
import {
  RecipeProductsResponseDataFromJSON,
  RecipeProductsResponseDataFromJSONTyped,
  RecipeProductsResponseDataToJSON,
} from './RecipeProductsResponseData'
import type { RecipeProductsResponseMeta } from './RecipeProductsResponseMeta'
import {
  RecipeProductsResponseMetaFromJSON,
  RecipeProductsResponseMetaFromJSONTyped,
  RecipeProductsResponseMetaToJSON,
} from './RecipeProductsResponseMeta'

/**
 *
 * @export
 * @interface RecipeProductsResponse
 */
export interface RecipeProductsResponse {
  /**
   *
   * @type {RecipeProductsResponseData}
   * @memberof RecipeProductsResponse
   */
  data: RecipeProductsResponseData
  /**
   *
   * @type {RecipeProductsResponseMeta}
   * @memberof RecipeProductsResponse
   */
  meta: RecipeProductsResponseMeta
}

/**
 * Check if a given object implements the RecipeProductsResponse interface.
 */
export function instanceOfRecipeProductsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function RecipeProductsResponseFromJSON(json: any): RecipeProductsResponse {
  return RecipeProductsResponseFromJSONTyped(json, false)
}

export function RecipeProductsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecipeProductsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: RecipeProductsResponseDataFromJSON(json['data']),
    meta: RecipeProductsResponseMetaFromJSON(json['meta']),
  }
}

export function RecipeProductsResponseToJSON(value?: RecipeProductsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: RecipeProductsResponseDataToJSON(value.data),
    meta: RecipeProductsResponseMetaToJSON(value.meta),
  }
}
