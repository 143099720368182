import { css } from '@emotion/react'
import { Theme, useTheme, POSITION_X_CENTER } from '@instacart/ids-core'
import {
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  MutableRefObject,
  useState,
  FC,
  useRef,
  useCallback,
} from 'react'
import { useIntl } from 'common'
import Loader from 'components/AssetManager/Loader'
import { InputSearch, InputSearchProps } from 'components/ids-ads'
import useDebounce from 'hooks/useDebounce'
import { useGetProductIdentifier } from 'hooks/useGetProductIdentifier'
import { useModalCloseHandlers } from 'hooks/useModalCloseHandlers'
import { MessageIdType } from 'locales/types'
import { GetProductsSearchClassifiedTypeEnum } from 'service/openapi/__codegen__/apis/ProductsApi'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { removeLeadingString } from '../../pages/AdGroupEdit/V2/adGroupEdit.utils'
import ProductLabel from './ProductLabel'
import { ProductSearchItemsTypes } from './types'

const ENTER_KEY = 'Enter'
const ARROW_UP = 'ArrowUp'
const ARROW_DOWN = 'ArrowDown'

export interface ProductSearchDropdownProps extends Pick<InputSearchProps, 'invalid'> {
  productOptions: GetProductsResponseData[]
  onProductClick: (product: GetProductsResponseData) => void
  onSearch: (newQuery: string) => void
  productLimitReached?: boolean
  loading: boolean
  itemsType?: ProductSearchItemsTypes
  error?: string
  disabled?: boolean
  placeholder?: MessageIdType
  onBlurOrDropdownClose?: () => void
  selectedProductIds: (string | undefined)[]
  compact?: boolean
  classifiedType?: GetProductsSearchClassifiedTypeEnum
}

interface OptionProps {
  inputRef: MutableRefObject<HTMLElement | null | undefined>
  onClick: () => void
  optionsLength: number
  focusedOptionIndex: number
  setFocusedOptionIndex: (index: number) => void
  isSelected: boolean
}

export interface ProductOption {
  label: JSX.Element
  value: string | undefined
}

// TODO: Replace when IDS layer is available.
const Z_INDEX = 1

const useStyles = (theme: Theme, hasError: boolean) => ({
  menu: css({
    zIndex: Z_INDEX,
    marginTop: '5px',
    width: '100%',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)',
    borderRadius: theme.radius.r8,
    position: 'absolute',
    maxHeight: '250px',
    overflow: 'scroll',
    ...POSITION_X_CENTER,
  }),
  table: css({ width: '100%' }),
  wrapper: css({ position: 'relative' }),
  dropdownMessage: css({
    minHeight: 82,
    padding: `0 21px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.systemGrayscale00,
    color: theme.colors[hasError ? 'systemDetrimentalRegular' : 'systemGrayscale50'],
    ...theme.typography.bodyMedium2,
  }),
})

const useOptionStyles = (theme: Theme, isSelected: boolean) => ({
  row: css({
    width: '100%',
    outline: 'none',
    backgroundColor: theme.colors.systemGrayscale00,
    '> td': {
      opacity: isSelected ? 0.5 : 1,
    },
    '.selected': {
      opacity: isSelected ? 1 : 0,
    },
    '&:hover > td': {
      cursor: isSelected ? 'no-drop' : 'pointer',
      backgroundColor: theme.colors[isSelected ? 'systemGrayscale00' : 'brandHighlightLight'],
    },
    '&.focused > td, &:focus > td': {
      backgroundColor: theme.colors[isSelected ? 'systemGrayscale00' : 'brandHighlightLight'],
    },
  }),
})

const Option: FC<React.PropsWithChildren<OptionProps>> = ({
  children,
  inputRef,
  onClick,
  optionsLength,
  focusedOptionIndex,
  setFocusedOptionIndex,
  isSelected,
}) => {
  const theme = useTheme()
  const { row } = useOptionStyles(theme, isSelected)

  const handleOptionKeyDown = (clickHandler: () => void) => (e: KeyboardEvent) => {
    if (e.key === ARROW_UP) {
      e.preventDefault()
      const atTop = focusedOptionIndex === 0 || focusedOptionIndex === -1
      if (atTop && inputRef.current) {
        inputRef.current.focus()
        setFocusedOptionIndex(-1)
      } else {
        setFocusedOptionIndex(focusedOptionIndex - 1)
      }
    } else if (e.key === ARROW_DOWN) {
      e.preventDefault()
      const atBottom = focusedOptionIndex + 1 === optionsLength
      if (atBottom) {
        setFocusedOptionIndex(0)
      } else {
        setFocusedOptionIndex(focusedOptionIndex + 1)
      }
    } else if (e.key === ENTER_KEY) {
      clickHandler()
    }
  }

  return (
    <tr
      role="button"
      onClick={onClick}
      onKeyDown={handleOptionKeyDown(onClick)}
      data-testid="product-search-option"
      css={row}
      tabIndex={-1}
    >
      {children}
    </tr>
  )
}

const renderProductOption = (
  {
    id,
    attributes: { brandName, productImageUrl, productName, productSize, upc },
  }: GetProductsResponseData,
  itemType: ProductSearchItemsTypes
) => (
  <ProductLabel
    id={id}
    productImageUrl={productImageUrl}
    brandName={brandName}
    productName={removeLeadingString(productName, brandName) || productName}
    productSize={productSize}
    upc={upc}
    itemType={itemType}
  />
)

const ProductSearchDropdown = ({
  productOptions,
  placeholder,
  onProductClick,
  onSearch,
  invalid,
  disabled,
  productLimitReached = false,
  loading,
  itemsType = 'product',
  error,
  onBlurOrDropdownClose,
  selectedProductIds = [],
  compact = false,
}: ProductSearchDropdownProps) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const [prebounceQuery, setPrebounceQuery] = useState('')
  const debouncedQuery = useDebounce(prebounceQuery, 250)
  const [options, setOptions] = useState<ProductOption[]>([])
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false)
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1)
  const [interacted, setInteracted] = useState<boolean>(false)
  const placeholderText = formatMessage({
    id: placeholder || `components.${itemsType}Search.placeholder.default`,
  })

  const getProductIdentifier = useGetProductIdentifier()

  useEffect(() => {
    if (productOptions.length && interacted) {
      setOptionsExpanded(true)
      setOptions(
        productOptions.map(product => ({
          label: renderProductOption(product, itemsType),
          value: getProductIdentifier(product),
        }))
      )
    } else {
      setOptions([])
    }
  }, [productOptions, interacted, getProductIdentifier, itemsType])

  useEffect(() => {
    onSearch(debouncedQuery)
  }, [debouncedQuery, onSearch])

  const bodyRef = useRef<HTMLTableSectionElement>(null)

  useEffect(() => {
    if (optionsExpanded && focusedOptionIndex > -1 && bodyRef.current) {
      const pots = bodyRef.current.querySelectorAll(['[role=button]'].join(','))
      const el = pots[focusedOptionIndex] as HTMLElement
      el.focus()
    }
  }, [optionsExpanded, focusedOptionIndex])

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onDropdownBlur = useCallback(() => {
    if (optionsExpanded && onBlurOrDropdownClose) {
      onBlurOrDropdownClose()
    }
  }, [optionsExpanded, onBlurOrDropdownClose])

  const onFieldBlur = useCallback(() => {
    if (!optionsExpanded && onBlurOrDropdownClose) {
      onBlurOrDropdownClose()
    }
  }, [optionsExpanded, onBlurOrDropdownClose])

  const closeMenu = useCallback(() => {
    setOptionsExpanded(false)
    onDropdownBlur()
  }, [onDropdownBlur])

  useModalCloseHandlers({ ref: containerRef, closeModal: closeMenu })

  const { menu, table, wrapper, dropdownMessage } = useStyles(theme, !!error)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setOptions([])
      setOptionsExpanded(false)
    }
    setPrebounceQuery(e.target.value)
  }

  const toggleMenu = () => {
    if (options.length) {
      setOptionsExpanded(!optionsExpanded)
      setFocusedOptionIndex(-1)
    }
    if (!interacted) {
      setInteracted(true)
    }
  }

  const handleInputArrowDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const { key } = e
    if (!options.length || !optionsExpanded) return
    if (key === 'ArrowDown') {
      e.preventDefault()
      setFocusedOptionIndex(0)
    }
  }

  const handleOptionClickToCallback = useCallback(
    (productIdentifier: string | undefined) => {
      const productAlreadySelected = selectedProductIds.includes(productIdentifier)
      const foundProduct = productOptions.find(
        product => getProductIdentifier(product) === productIdentifier
      )

      if (foundProduct && !productAlreadySelected) {
        onProductClick(foundProduct)
      }
    },
    [productOptions, onProductClick, selectedProductIds, getProductIdentifier]
  )

  const showQueryError = useDebounce(!!(debouncedQuery && !options.length && !loading), 300)

  const showDropdown = !productLimitReached && optionsExpanded && !!options.length && !loading

  return (
    <div css={wrapper} ref={containerRef}>
      <InputSearch
        id="product-input-search"
        data-testid="product-input-search"
        ref={inputRef}
        onChange={onInputChange}
        value={prebounceQuery}
        placeholder={placeholderText}
        autoComplete="off"
        invalid={invalid}
        disabled={disabled}
        onClick={toggleMenu}
        onKeyDown={handleInputArrowDown}
        onBlur={onFieldBlur}
        compact={compact}
      />
      {loading && interacted && (
        <div css={menu}>
          <div css={dropdownMessage}>
            <Loader />
          </div>
        </div>
      )}
      {showQueryError && (
        <div css={menu}>
          <div css={dropdownMessage}>
            {formatMessage({
              id: `pages.adGroupEdit.${itemsType}SearchField.error.queryTooSpecific`,
            })}
          </div>
        </div>
      )}
      {error && (
        <div css={menu}>
          <div css={dropdownMessage}>
            {formatMessage(
              {
                id: `pages.adGroupEdit.${itemsType}SearchField.error.defaultError`,
              },
              { err: error }
            )}
          </div>
        </div>
      )}
      {showDropdown && (
        <div css={menu}>
          <table css={table}>
            <tbody ref={bodyRef}>
              {options.length &&
                options.map(({ label, value }) => (
                  <Option
                    inputRef={inputRef}
                    optionsLength={options.length}
                    key={value}
                    focusedOptionIndex={focusedOptionIndex}
                    setFocusedOptionIndex={setFocusedOptionIndex}
                    onClick={() => handleOptionClickToCallback(value)}
                    isSelected={selectedProductIds.includes(value)}
                  >
                    {label}
                  </Option>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ProductSearchDropdown
