import { useFormikContext } from 'formik'
import { useContext } from 'react'
import Checkbox from 'components/CheckboxV2'
import FormattedMessage from 'components/FormattedMessage'
import { TargetingRuleContext } from '../../hooks'
import { TargetingFormData } from '../../types'

export default function NewCustomersExpandedSection() {
  const { isReadOnly, isReviewing } = useContext(TargetingRuleContext)
  const { values, setFieldValue } = useFormikContext<TargetingFormData>()

  return (
    <Checkbox
      id="includeNewCustomers"
      name="includeNewCustomers"
      checked={values.includeNewCustomers}
      disabled={isReadOnly || isReviewing}
      onChange={e => setFieldValue('includeNewCustomers', e.target.checked)}
    >
      <FormattedMessage id="pages.displayProduct.common.targetingRule.newCustomers.label" />
    </Checkbox>
  )
}
