import { css } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import FormattedMessage from 'components/FormattedMessage'
import UnauthenticatedLayout from 'components/Unauthenticated/components/UnauthenticatedLayout'
import { SecondaryLinkButton } from '../../ids-ads'

interface AccessRevokedProps {
  userEmail: string
}
export default function AccessRevoked({ userEmail }: AccessRevokedProps) {
  const theme = useTheme()
  const containerCss = css({
    maxWidth: '26rem',
    margin: '1rem auto',
    marginTop: '15rem',
    padding: '1rem',
    background: theme.colors.systemGrayscale00,
    borderRadius: '0.5rem',
    border: `1px solid ${theme.colors.systemGrayscale20}`,
    overflow: 'auto',
  })

  const headingCss = css({
    fontWeight: 'bold',
    marginBottom: '1rem',
  })

  const buttonCss = css({
    marginTop: '2rem',
    float: 'right',
  })

  return (
    <UnauthenticatedLayout hideLoginButton>
      <div css={containerCss}>
        <div css={headingCss}>
          <FormattedMessage id="common.noAccess" />
        </div>

        <FormattedMessage values={{ user: userEmail }} id="auth.accessRevoked" />

        <div css={buttonCss}>
          <SecondaryLinkButton to="https://blog.instacart.com/" compact>
            <FormattedMessage id="pages.notFound.blogLink" />
          </SecondaryLinkButton>
        </div>
      </div>
    </UnauthenticatedLayout>
  )
}
