// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutAccountPaymentResponseData } from './PutAccountPaymentResponseData'
import {
  PutAccountPaymentResponseDataFromJSON,
  PutAccountPaymentResponseDataFromJSONTyped,
  PutAccountPaymentResponseDataToJSON,
} from './PutAccountPaymentResponseData'
import type { PutAccountPaymentResponseMeta } from './PutAccountPaymentResponseMeta'
import {
  PutAccountPaymentResponseMetaFromJSON,
  PutAccountPaymentResponseMetaFromJSONTyped,
  PutAccountPaymentResponseMetaToJSON,
} from './PutAccountPaymentResponseMeta'

/**
 *
 * @export
 * @interface PutAccountPaymentResponse
 */
export interface PutAccountPaymentResponse {
  /**
   *
   * @type {PutAccountPaymentResponseData}
   * @memberof PutAccountPaymentResponse
   */
  data: PutAccountPaymentResponseData
  /**
   *
   * @type {PutAccountPaymentResponseMeta}
   * @memberof PutAccountPaymentResponse
   */
  meta: PutAccountPaymentResponseMeta
}

/**
 * Check if a given object implements the PutAccountPaymentResponse interface.
 */
export function instanceOfPutAccountPaymentResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function PutAccountPaymentResponseFromJSON(json: any): PutAccountPaymentResponse {
  return PutAccountPaymentResponseFromJSONTyped(json, false)
}

export function PutAccountPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAccountPaymentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: PutAccountPaymentResponseDataFromJSON(json['data']),
    meta: PutAccountPaymentResponseMetaFromJSON(json['meta']),
  }
}

export function PutAccountPaymentResponseToJSON(value?: PutAccountPaymentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: PutAccountPaymentResponseDataToJSON(value.data),
    meta: PutAccountPaymentResponseMetaToJSON(value.meta),
  }
}
