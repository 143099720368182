// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutReservationAdGroupsIdParamDataAttributesEditorialStates } from './PutReservationAdGroupsIdParamDataAttributesEditorialStates'
import {
  PutReservationAdGroupsIdParamDataAttributesEditorialStatesFromJSON,
  PutReservationAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped,
  PutReservationAdGroupsIdParamDataAttributesEditorialStatesToJSON,
} from './PutReservationAdGroupsIdParamDataAttributesEditorialStates'
import type { PutReservationAdGroupsIdParamDataAttributesReservationSchedules } from './PutReservationAdGroupsIdParamDataAttributesReservationSchedules'
import {
  PutReservationAdGroupsIdParamDataAttributesReservationSchedulesFromJSON,
  PutReservationAdGroupsIdParamDataAttributesReservationSchedulesFromJSONTyped,
  PutReservationAdGroupsIdParamDataAttributesReservationSchedulesToJSON,
} from './PutReservationAdGroupsIdParamDataAttributesReservationSchedules'
import type { ReservationAdGroupResponseDataAttributesCreative } from './ReservationAdGroupResponseDataAttributesCreative'
import {
  ReservationAdGroupResponseDataAttributesCreativeFromJSON,
  ReservationAdGroupResponseDataAttributesCreativeFromJSONTyped,
  ReservationAdGroupResponseDataAttributesCreativeToJSON,
} from './ReservationAdGroupResponseDataAttributesCreative'

/**
 *
 * @export
 * @interface ReservationAdGroupResponseDataAttributes
 */
export interface ReservationAdGroupResponseDataAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  name: string
  /**
   * Placement type
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  placementType: string
  /**
   * Enabled flag for the ad group. Ad group is disabled/paused if set to false.
   * @type {boolean}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  enabled?: boolean
  /**
   * Specify the ID of the campaign you want to create the ad group under. Ad groups must belong to a campaign.
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  campaignId: string
  /**
   * Whether targeting includes all states (includes alcohol states), or non-alcohol states only
   * @type {boolean}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  includesAlcohol: boolean
  /**
   *
   * @type {ReservationAdGroupResponseDataAttributesCreative}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  creative: ReservationAdGroupResponseDataAttributesCreative
  /**
   * Reservation Schedules data
   * @type {Array<PutReservationAdGroupsIdParamDataAttributesReservationSchedules>}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  reservationSchedules: Array<PutReservationAdGroupsIdParamDataAttributesReservationSchedules>
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  updatedAt?: string
  /**
   * Ad group status
   * @type {string}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  status?: ReservationAdGroupResponseDataAttributesStatusEnum
  /**
   * Editorial states
   * @type {Array<PutReservationAdGroupsIdParamDataAttributesEditorialStates>}
   * @memberof ReservationAdGroupResponseDataAttributes
   */
  editorialStates?: Array<PutReservationAdGroupsIdParamDataAttributesEditorialStates>
}

/**
 * @export
 */
export const ReservationAdGroupResponseDataAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Editable: 'EDITABLE',
  Ended: 'ENDED',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  OutOfBudget: 'OUT_OF_BUDGET',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type ReservationAdGroupResponseDataAttributesStatusEnum =
  typeof ReservationAdGroupResponseDataAttributesStatusEnum[keyof typeof ReservationAdGroupResponseDataAttributesStatusEnum]

/**
 * Check if a given object implements the ReservationAdGroupResponseDataAttributes interface.
 */
export function instanceOfReservationAdGroupResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'includesAlcohol' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'reservationSchedules' in value

  return isInstance
}

export function ReservationAdGroupResponseDataAttributesFromJSON(
  json: any
): ReservationAdGroupResponseDataAttributes {
  return ReservationAdGroupResponseDataAttributesFromJSONTyped(json, false)
}

export function ReservationAdGroupResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReservationAdGroupResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    placementType: json['placement_type'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    campaignId: json['campaign_id'],
    includesAlcohol: json['includes_alcohol'],
    creative: ReservationAdGroupResponseDataAttributesCreativeFromJSON(json['creative']),
    reservationSchedules: (json['reservation_schedules'] as Array<any>).map(
      PutReservationAdGroupsIdParamDataAttributesReservationSchedulesFromJSON
    ),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    status: !exists(json, 'status') ? undefined : json['status'],
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          PutReservationAdGroupsIdParamDataAttributesEditorialStatesFromJSON
        ),
  }
}

export function ReservationAdGroupResponseDataAttributesToJSON(
  value?: ReservationAdGroupResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    placement_type: value.placementType,
    enabled: value.enabled,
    campaign_id: value.campaignId,
    includes_alcohol: value.includesAlcohol,
    creative: ReservationAdGroupResponseDataAttributesCreativeToJSON(value.creative),
    reservation_schedules: (value.reservationSchedules as Array<any>).map(
      PutReservationAdGroupsIdParamDataAttributesReservationSchedulesToJSON
    ),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    status: value.status,
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            PutReservationAdGroupsIdParamDataAttributesEditorialStatesToJSON
          ),
  }
}
