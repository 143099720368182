import { datadogRum } from '@datadog/browser-rum'
import { getEnv } from 'common/utils'

export function initDataDogRUM() {
  datadogRum.init({
    applicationId: getEnv('ADS_WEB_DD_RUM_APPLICATION_ID'),
    clientToken: getEnv('ADS_WEB_DD_RUM_CLIENT_TOKEN'),
    site: 'datadoghq.com',
    service: 'ads-web',
    env: getEnv('NODE_ENV'),
    sampleRate: 100,
  })
}

export function rumInvalidBidRec(suggestedBid: number) {
  datadogRum.addAction('bid_rec_below_threshold', {
    suggestedBid,
  })
}
