import { useState, useMemo, useCallback } from 'react'
import { ClientError, getApiErrorMessagesHash } from 'common/utils'
import { useGetProductsSearch } from 'hooks/api/products'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import ProductSearchDropdown, { ProductSearchDropdownProps } from './ProductSearchDropdown'
import ProductSearchTable from './ProductSearchTable'

export interface ProductSearchWrapperProps
  extends Omit<
    ProductSearchDropdownProps,
    'onProductClick' | 'productOptions' | 'onSearch' | 'loading' | 'error'
  > {
  onProductClick: (product: GetProductsResponseData, isProductNewlySelected?: boolean) => void
  isImpulse?: boolean
  variant?: 'dropdown' | 'table'
  newSelectedProductsIds?: string[]
  inLibrary?: boolean
  includeBrandMappingStatus?: boolean
}

const getError = (apiError: ClientError) => {
  const errorsHash = getApiErrorMessagesHash(apiError)
  return `Error: ${errorsHash[Object.keys(errorsHash)[0]][0]}`
}

const ProductSearchWrapper = (props: ProductSearchWrapperProps) => {
  const [query, setQuery] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const {
    products,
    loading,
    pagination,
    error: apiError,
  } = useGetProductsSearch({
    query,
    isImpulse: props.isImpulse,
    classifiedType: props.classifiedType,
    page,
    inLibrary: props.inLibrary,
    includeBrandMappingStatus: props.includeBrandMappingStatus,
  })

  const productOptions: GetProductsResponseData[] = useMemo(() => {
    return products.length ? products : []
  }, [products])

  const handleOnSearch = useCallback((newQuery: string) => setQuery(newQuery.trim()), [])

  const error = apiError ? getError(apiError) : undefined

  if (props.variant === 'table') {
    return (
      <ProductSearchTable
        productOptions={productOptions}
        onSearch={handleOnSearch}
        loading={loading}
        error={error}
        newSelectedProductsIds={props.newSelectedProductsIds || []}
        setPage={value => setPage(value)}
        pagination={pagination}
        {...props}
      />
    )
  }

  return (
    <ProductSearchDropdown
      productOptions={productOptions}
      onSearch={handleOnSearch}
      loading={loading}
      error={error}
      {...props}
    />
  )
}

export default ProductSearchWrapper
