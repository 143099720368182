// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetBrandPagesParamBlockBrandLogoAssetsLogo } from './GetBrandPagesParamBlockBrandLogoAssetsLogo'
import {
  GetBrandPagesParamBlockBrandLogoAssetsLogoFromJSON,
  GetBrandPagesParamBlockBrandLogoAssetsLogoFromJSONTyped,
  GetBrandPagesParamBlockBrandLogoAssetsLogoToJSON,
} from './GetBrandPagesParamBlockBrandLogoAssetsLogo'

/**
 *
 * @export
 * @interface GetBrandPagesParamBlockBrandLogoAssets
 */
export interface GetBrandPagesParamBlockBrandLogoAssets {
  /**
   *
   * @type {GetBrandPagesParamBlockBrandLogoAssetsLogo}
   * @memberof GetBrandPagesParamBlockBrandLogoAssets
   */
  logo: GetBrandPagesParamBlockBrandLogoAssetsLogo
}

/**
 * Check if a given object implements the GetBrandPagesParamBlockBrandLogoAssets interface.
 */
export function instanceOfGetBrandPagesParamBlockBrandLogoAssets(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'logo' in value

  return isInstance
}

export function GetBrandPagesParamBlockBrandLogoAssetsFromJSON(
  json: any
): GetBrandPagesParamBlockBrandLogoAssets {
  return GetBrandPagesParamBlockBrandLogoAssetsFromJSONTyped(json, false)
}

export function GetBrandPagesParamBlockBrandLogoAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBrandPagesParamBlockBrandLogoAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    logo: GetBrandPagesParamBlockBrandLogoAssetsLogoFromJSON(json['logo']),
  }
}

export function GetBrandPagesParamBlockBrandLogoAssetsToJSON(
  value?: GetBrandPagesParamBlockBrandLogoAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    logo: GetBrandPagesParamBlockBrandLogoAssetsLogoToJSON(value.logo),
  }
}
