// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiReportsControllerPromotionProductsInputDateRange } from './ApiReportsControllerPromotionProductsInputDateRange'
import {
  ApiReportsControllerPromotionProductsInputDateRangeFromJSON,
  ApiReportsControllerPromotionProductsInputDateRangeFromJSONTyped,
  ApiReportsControllerPromotionProductsInputDateRangeToJSON,
} from './ApiReportsControllerPromotionProductsInputDateRange'

/**
 *
 * @export
 * @interface ApiReportsControllerPromotionProductsInput
 */
export interface ApiReportsControllerPromotionProductsInput {
  /**
   *
   * @type {ApiReportsControllerPromotionProductsInputDateRange}
   * @memberof ApiReportsControllerPromotionProductsInput
   */
  dateRange?: ApiReportsControllerPromotionProductsInputDateRange
  /**
   * Send "day" to request a report with the data broken out day by day. Do not send and Instacart Ads summarizes the data for all dates on one line.
   * @type {string}
   * @memberof ApiReportsControllerPromotionProductsInput
   */
  segment?: ApiReportsControllerPromotionProductsInputSegmentEnum
  /**
   * Optionally, provide a descriptive name for the report.
   * @type {string}
   * @memberof ApiReportsControllerPromotionProductsInput
   */
  name?: string
  /**
   * the ID of a specific coupon. Empty id means all coupons
   * @type {string}
   * @memberof ApiReportsControllerPromotionProductsInput
   */
  couponId?: string
  /**
   * Send this parameter with the ID of a specific campaign to narrow the data returned on your report to just data from that specific campaign.
   * @type {string}
   * @memberof ApiReportsControllerPromotionProductsInput
   */
  campaignId?: string
}

/**
 * @export
 */
export const ApiReportsControllerPromotionProductsInputSegmentEnum = {
  Day: 'day',
} as const
export type ApiReportsControllerPromotionProductsInputSegmentEnum =
  typeof ApiReportsControllerPromotionProductsInputSegmentEnum[keyof typeof ApiReportsControllerPromotionProductsInputSegmentEnum]

/**
 * Check if a given object implements the ApiReportsControllerPromotionProductsInput interface.
 */
export function instanceOfApiReportsControllerPromotionProductsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiReportsControllerPromotionProductsInputFromJSON(
  json: any
): ApiReportsControllerPromotionProductsInput {
  return ApiReportsControllerPromotionProductsInputFromJSONTyped(json, false)
}

export function ApiReportsControllerPromotionProductsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiReportsControllerPromotionProductsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dateRange: !exists(json, 'date_range')
      ? undefined
      : ApiReportsControllerPromotionProductsInputDateRangeFromJSON(json['date_range']),
    segment: !exists(json, 'segment') ? undefined : json['segment'],
    name: !exists(json, 'name') ? undefined : json['name'],
    couponId: !exists(json, 'coupon_id') ? undefined : json['coupon_id'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
  }
}

export function ApiReportsControllerPromotionProductsInputToJSON(
  value?: ApiReportsControllerPromotionProductsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    date_range: ApiReportsControllerPromotionProductsInputDateRangeToJSON(value.dateRange),
    segment: value.segment,
    name: value.name,
    coupon_id: value.couponId,
    campaign_id: value.campaignId,
  }
}
