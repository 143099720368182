import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { HTMLProps } from 'react'
import layers from 'common/layers'
import toPx from 'common/toPx'
import { useHeaderHeight } from '../Header/header.utils'

interface CommonProps extends HTMLProps<HTMLDivElement> {
  backgroundColor?: string
  footerHeight?: number
}

const useStyles = (backgroundColor?: string) => {
  const theme = useTheme()
  const pageHeaderHeight = useHeaderHeight()

  return {
    layout: css({
      background: backgroundColor || theme.colors.systemGrayscale00,
      flex: 1,
      minWidth: 0,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '12px',
      // handle cases when header/content/footer is wrapped in a form
      '> form': {
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
      },
    }),
    header: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // We want the heading to always be 24px from the top of the screen. Since the minHeight is 40 and
      // the line-height of the heading text is 28px we already have 6px baked in. We compensate for the
      // remaining 18px with margin
      minHeight: 40,
      margin: toPx`${spacing.s24} 0 ${spacing.s16}`,
      padding: toPx`0 ${spacing.s16}`,
    }),
    tdsHeader: css({
      minHeight: 40,
      padding: toPx`${spacing.s24} ${spacing.s8 * 10} ${spacing.s24} ${spacing.s8 * 10}`,
    }),
    content: css({
      padding: toPx`${spacing.s4} ${spacing.s16} 0 ${spacing.s16}`,
      background: backgroundColor || theme.colors.systemGrayscale00,
      flex: 1,
      borderRadius: '12px',
    }),
    tdsContent: css({
      padding: toPx`0 ${spacing.s8 * 10} ${spacing.s48} ${spacing.s8 * 10}`,
      background: backgroundColor || theme.colors.systemGrayscale00,
      flex: 1,
      borderRadius: '12px',
    }),
    sectionedContent: {
      minHeight: `calc(100vh - ${pageHeaderHeight}px)`,
      padding: toPx`${spacing.s24}`,
      background: backgroundColor || theme.colors.systemGrayscale10,
      flex: 1,
    },
    fullLayout: css({
      position: 'fixed',
      top: `${pageHeaderHeight}px`,
      left: 0,
      right: 0,
      bottom: 0,
      background: backgroundColor || theme.colors.systemGrayscale00,
      zIndex: layers[10],
      overflowY: 'auto',
    }),
  }
}

export function PageLayout({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)
  return (
    <div css={styles.layout} {...props}>
      {children}
    </div>
  )
}

export function PageHeader({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)
  return (
    <header css={styles.header} {...props}>
      {children}
    </header>
  )
}

export function TdsPageHeader({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)
  return (
    <header css={styles.tdsHeader} {...props}>
      {children}
    </header>
  )
}

export function PageContent({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)

  return (
    <main css={styles.content} {...props}>
      {children}
    </main>
  )
}

export function TdsPageContent({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)

  return (
    <main css={styles.tdsContent} {...props}>
      {children}
    </main>
  )
}

export function SectionedPageContent({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)

  return (
    <main css={styles.sectionedContent} {...props}>
      {children}
    </main>
  )
}

export function FullPageLayout({ children, backgroundColor, ...props }: CommonProps) {
  const styles = useStyles(backgroundColor)
  return (
    <div css={styles.fullLayout} {...props}>
      {children}
    </div>
  )
}
