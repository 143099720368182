// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppeals } from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppeals'
import {
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSON,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppealsToJSON,
} from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppeals'
import type { PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot } from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot'
import type { PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot } from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot'
import {
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot'
import type { PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewState } from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewState'
import {
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSON,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateToJSON,
} from './PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface PatchVideoAdGroupsIdParamDataAttributesEditorialStates
 */
export interface PatchVideoAdGroupsIdParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  type: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewState}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  reviewState?: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  entitySnapshot: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  decisionSnapshot: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppeals}
   * @memberof PatchVideoAdGroupsIdParamDataAttributesEditorialStates
   */
  appeals: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const PatchVideoAdGroupsIdParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type PatchVideoAdGroupsIdParamDataAttributesEditorialStatesTypeEnum =
  typeof PatchVideoAdGroupsIdParamDataAttributesEditorialStatesTypeEnum[keyof typeof PatchVideoAdGroupsIdParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the PatchVideoAdGroupsIdParamDataAttributesEditorialStates interface.
 */
export function instanceOfPatchVideoAdGroupsIdParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesFromJSON(
  json: any
): PatchVideoAdGroupsIdParamDataAttributesEditorialStates {
  return PatchVideoAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchVideoAdGroupsIdParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot:
      PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppealsFromJSON(json['appeals']),
  }
}

export function PatchVideoAdGroupsIdParamDataAttributesEditorialStatesToJSON(
  value?: PatchVideoAdGroupsIdParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
      value.decisionSnapshot
    ),
    appeals: PatchVideoAdGroupsIdParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
