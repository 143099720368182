// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutAdGroupKeywordResponseDataAttributesAnalytics } from './PutAdGroupKeywordResponseDataAttributesAnalytics'
import {
  PutAdGroupKeywordResponseDataAttributesAnalyticsFromJSON,
  PutAdGroupKeywordResponseDataAttributesAnalyticsFromJSONTyped,
  PutAdGroupKeywordResponseDataAttributesAnalyticsToJSON,
} from './PutAdGroupKeywordResponseDataAttributesAnalytics'
import type { PutAdGroupKeywordResponseDataAttributesBidRecs } from './PutAdGroupKeywordResponseDataAttributesBidRecs'
import {
  PutAdGroupKeywordResponseDataAttributesBidRecsFromJSON,
  PutAdGroupKeywordResponseDataAttributesBidRecsFromJSONTyped,
  PutAdGroupKeywordResponseDataAttributesBidRecsToJSON,
} from './PutAdGroupKeywordResponseDataAttributesBidRecs'

/**
 *
 * @export
 * @interface PutAdGroupKeywordResponseDataAttributes
 */
export interface PutAdGroupKeywordResponseDataAttributes {
  /**
   * The keyword.
   * @type {string}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  keyword: string
  /**
   * CPC bid for the keyword in dollars.
   * @type {number}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  cpcBid: number
  /**
   * The type of match Instacart supports for the keyword. We currently only support exact matches so this currently will only return "exact".
   * @type {string}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  matchingType: string
  /**
   * Ad Group Keyword Status
   * @type {string}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  adGroupKeywordStatus?: string
  /**
   *
   * @type {PutAdGroupKeywordResponseDataAttributesAnalytics}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  analytics?: PutAdGroupKeywordResponseDataAttributesAnalytics
  /**
   *
   * @type {PutAdGroupKeywordResponseDataAttributesBidRecs}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  bidRecs?: PutAdGroupKeywordResponseDataAttributesBidRecs
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PutAdGroupKeywordResponseDataAttributes
   */
  errors?: { [key: string]: any }
}

/**
 * Check if a given object implements the PutAdGroupKeywordResponseDataAttributes interface.
 */
export function instanceOfPutAdGroupKeywordResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'keyword' in value
  isInstance = isInstance && 'cpcBid' in value
  isInstance = isInstance && 'matchingType' in value

  return isInstance
}

export function PutAdGroupKeywordResponseDataAttributesFromJSON(
  json: any
): PutAdGroupKeywordResponseDataAttributes {
  return PutAdGroupKeywordResponseDataAttributesFromJSONTyped(json, false)
}

export function PutAdGroupKeywordResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAdGroupKeywordResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    keyword: json['keyword'],
    cpcBid: json['cpc_bid'],
    matchingType: json['matching_type'],
    adGroupKeywordStatus: !exists(json, 'ad_group_keyword_status')
      ? undefined
      : json['ad_group_keyword_status'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : PutAdGroupKeywordResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : PutAdGroupKeywordResponseDataAttributesBidRecsFromJSON(json['bid_recs']),
    errors: !exists(json, 'errors') ? undefined : json['errors'],
  }
}

export function PutAdGroupKeywordResponseDataAttributesToJSON(
  value?: PutAdGroupKeywordResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    keyword: value.keyword,
    cpc_bid: value.cpcBid,
    matching_type: value.matchingType,
    ad_group_keyword_status: value.adGroupKeywordStatus,
    analytics: PutAdGroupKeywordResponseDataAttributesAnalyticsToJSON(value.analytics),
    bid_recs: PutAdGroupKeywordResponseDataAttributesBidRecsToJSON(value.bidRecs),
    errors: value.errors,
  }
}
