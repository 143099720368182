import { spacing, Text } from '@instacart/ids-core'
import Checkbox from 'components/CheckboxV2'
import FormattedMessage from 'components/FormattedMessage'
import { CardTitle } from 'components/ids-ads'
import { BannerNotification, BannerType } from 'components/molecules'
import { useFormikField } from 'hooks/useFormikField'
import { MessageIdType } from 'locales/types'
import { GetAdGroupsResponseDataAttributesUserTargetingBuyerLicenseEnum as BuyerLicenses } from 'service/openapi/__codegen__/models/GetAdGroupsResponseDataAttributesUserTargeting'

export type BuyerLicenseTargetingProps = {
  name: string
}

export const BUYER_LICENSE_TARGETING_OPTIONS: { labelId: MessageIdType; value: BuyerLicenses }[] = [
  {
    labelId:
      'components.advancedTargetingCardCollapse.components.buyerLicenseTargeting.option.onPremise.label',
    value: BuyerLicenses.OnPremises,
  },
  {
    labelId:
      'components.advancedTargetingCardCollapse.components.buyerLicenseTargeting.option.offPremise.label',
    value: BuyerLicenses.OffPremises,
  },
  {
    labelId:
      'components.advancedTargetingCardCollapse.components.buyerLicenseTargeting.option.onAndOffPremise.label',
    value: BuyerLicenses.OnAndOffPremises,
  },
]

export default function BuyerLicenseTargeting({ name }: BuyerLicenseTargetingProps) {
  const [{ value: licenses }, { error }, { setValue }] = useFormikField<string[]>(name)

  const handleCheckboxChange = (checkboxValue: string, checked: boolean) => {
    if (checked) {
      setValue([...licenses, checkboxValue])
    } else {
      setValue(licenses.filter(license => license !== checkboxValue))
    }
  }

  return (
    <div>
      <CardTitle small>
        <FormattedMessage id="components.advancedTargetingCardCollapse.components.buyerLicenseTargeting.title" />
      </CardTitle>
      <Text typography="bodyMedium2" css={{ marginBottom: spacing.s8 }}>
        <FormattedMessage id="components.advancedTargetingCardCollapse.components.buyerLicenseTargeting.description" />
      </Text>
      {error && (
        <BannerNotification
          testId="buyer-license-targeting-error-banner"
          message={{ message: error }}
          type={BannerType.ERROR}
        />
      )}
      {BUYER_LICENSE_TARGETING_OPTIONS.map(option => (
        <Checkbox
          data-testId={`buyer-license-${option.value}`}
          id={`buyer-license-${option.value}`}
          checked={licenses.includes(option.value)}
          onChange={({ target: { checked } }) => handleCheckboxChange(option.value, checked)}
        >
          <FormattedMessage id={option.labelId} />
        </Checkbox>
      ))}
    </div>
  )
}
