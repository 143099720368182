import { SYSTEM_GRAYSCALE_25 } from 'components/ids-ads/atoms'
import { UseStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

export { ButtonProps as SecondaryButtonProps } from './utils/makeButton'

const styles: UseStylesOpts = {
  normalBackground: 'systemGrayscale20',
  borderColor: null,
  textColor: 'systemGrayscale70',
  hoverBackground: SYSTEM_GRAYSCALE_25,
  activeBackground: 'systemGrayscale30',
}

export const SecondaryButton = makeButton('SecondaryButton', styles)
export const SecondaryLinkButton = makeLinkButton('SecondaryLinkButton', styles)
export const SecondaryAnchorButton = makeAnchorButton('SecondaryAnchorButton', styles)
