import { css } from '@emotion/react'
import { IssueIcon, spacing, useTheme } from '@instacart/ids-core'
import { InputDescriptionErrorText } from '@instacart/ids-tooling'
import { useField } from 'formik'
import { HTMLProps } from 'react'
import { errorId } from './utils'

export interface InputErrorProps extends HTMLProps<HTMLDivElement> {
  inputId: string
  error: string
  compact?: boolean
}

function useStyles({ compact }: { compact?: boolean }) {
  const theme = useTheme()

  return {
    container: css({
      display: 'flex',
      alignItems: 'center',
      gap: compact ? 2 : spacing.s4,
    }),
    icon: css({
      flex: 'none',
    }),
    inputDescriptionError: css({
      ...(compact ? theme.typography.bodySmall2 : theme.typography.bodyMedium2),
      color: theme.colors.systemDetrimentalRegular,
      margin: 0,
      wordBreak: 'break-word',
    }),
  }
}

export function InputError({ inputId, error, compact }: InputErrorProps) {
  const styles = useStyles({ compact })

  return (
    <div css={styles.container}>
      <IssueIcon size={compact ? 14 : 16} color="systemDetrimentalRegular" css={styles.icon} />
      <InputDescriptionErrorText id={errorId(inputId)} css={styles.inputDescriptionError}>
        {error}
      </InputDescriptionErrorText>
    </div>
  )
}

export function FormikWrappedInputError(props: Omit<InputErrorProps, 'error'>) {
  const [, { error }] = useField(props.inputId)
  return error ? <InputError {...props} error={error} /> : null
}
