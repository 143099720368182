import { useState } from 'react'
import LoadError from 'components/LoadError'
import MaintenanceBoundary from '../components/MaintenanceBoundary'
import Header from './components/Header'
import Hero from './components/Hero'
import HowItWorks from './components/HowItWorks'
import PartnerWithUs from './components/PartnerWithUs'
import WhoWeAre from './components/WhoWeAre'

const Page = () => {
  const [heroVisible, setHeroVisible] = useState(true)

  return (
    <div>
      <MaintenanceBoundary>
        <Hero onVisible={setHeroVisible} />
        <Header heroVisible={heroVisible} loginButtonVisible />
        <WhoWeAre />
        <PartnerWithUs />
        <HowItWorks />
        <LoadError bundleName="landing" />
      </MaintenanceBoundary>
    </div>
  )
}

export default Page
