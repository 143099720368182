import { css } from '@emotion/react'
import { spacing, SVGIconProps, useTheme } from '@instacart/ids-core'
import classNames from 'classnames'
import { ForwardedRef, forwardRef, AnchorHTMLAttributes, ComponentType } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import toPx from 'common/toPx'
import { DEFAULT_ICON_SIZE, UseStylesInternal, UseButtonStylesOpts } from '.'

function useLinkButtonStyles({
  borderColor,
  textColor,
  normalBackground,
  hoverBackground,
  activeBackground,
  disabledBackground,
  disabledTextColor = textColor,
  disabledBorderColor,
  iconOnly,
  iconSize,
}: UseButtonStylesOpts & UseStylesInternal) {
  const theme = useTheme()

  return css({
    display: 'inline-flex',
    cursor: 'pointer',
    userSelect: 'none',
    ...theme.typography.bodyMedium1,
    ...(iconOnly ? { lineHeight: 1 } : {}),
    backgroundColor: normalBackground ? theme.colors[normalBackground] : 'transparent',
    color: theme.colors[textColor],
    border: `1px solid ${borderColor ? theme.colors[borderColor] : 'transparent'}`,
    borderRadius: theme.radius.r4,
    padding: iconOnly ? spacing.s4 : toPx`${spacing.s4} ${spacing.s8}`,
    '&:hover, &:focus, &:active, &:visited': {
      color: theme.colors[textColor],
    },
    '&:hover, &:focus': {
      backgroundColor: hoverBackground ? theme.colors[hoverBackground] : 'transparent',
    },
    '&:active': {
      backgroundColor: activeBackground ? theme.colors[activeBackground] : 'transparent',
    },
    '&.disabled': {
      cursor: 'default',
      backgroundColor: disabledBackground ? theme.colors[disabledBackground] : 'transparent',
      borderColor: disabledBorderColor ? theme.colors[disabledBorderColor] : 'transparent',
      color: theme.colors[disabledTextColor],
    },
    svg: {
      fill: 'currentColor',
      marginLeft: iconOnly ? 0 : spacing.s12,
      padding: (DEFAULT_ICON_SIZE - iconSize) / 2,
    },
  })
}

export interface LinkButtonProps extends LinkProps {
  disabled?: boolean
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  iconSize?: number
}

export function makeLinkButton(displayName: string, opts: UseButtonStylesOpts) {
  const Component = (
    {
      children,
      disabled,
      href,
      onClick,
      className,
      icon,
      iconSize = DEFAULT_ICON_SIZE,
      ...props
    }: LinkButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const iconOnly = icon && !children
    const styles = useLinkButtonStyles({ ...opts, iconOnly, iconSize })

    const Icon = icon

    return (
      <Link
        {...props}
        className={classNames({ disabled }, className)}
        href={disabled ? undefined : href}
        css={styles}
        ref={ref}
      >
        {children}
        {Icon && <Icon size={iconSize} />}
      </Link>
    )
  }

  Component.displayName = displayName

  return forwardRef<HTMLAnchorElement, LinkButtonProps>(Component)
}

export interface AnchorButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
  iconSize?: number
}

export function makeAnchorButton(displayName: string, opts: UseButtonStylesOpts) {
  const Component = (
    {
      children,
      disabled,
      href,
      onClick,
      className,
      icon,
      iconSize = DEFAULT_ICON_SIZE,
      ...props
    }: AnchorButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const iconOnly = icon && !children
    const styles = useLinkButtonStyles({ ...opts, iconOnly, iconSize })

    const Icon = icon

    return (
      <a
        {...props}
        className={classNames({ disabled }, className)}
        href={disabled ? undefined : href}
        onClick={disabled ? undefined : onClick}
        css={styles}
        ref={ref}
      >
        {children}
        {Icon && <Icon size={iconSize} />}
      </a>
    )
  }

  Component.displayName = displayName

  return forwardRef<HTMLAnchorElement, AnchorButtonProps>(Component)
}
