import { BRAND_PRIMARY_MEDIUM } from 'components/ids-ads/atoms'
import { UseStylesOpts } from './utils'
import { makeButton } from './utils/makeButton'
import { makeAnchorButton, makeLinkButton } from './utils/makeLinkButton'

export { ButtonProps as PrimaryButtonProps } from './utils/makeButton'

const styles: UseStylesOpts = {
  normalBackground: 'brandPrimaryRegular',
  borderColor: null,
  textColor: 'systemGrayscale00',
  hoverBackground: BRAND_PRIMARY_MEDIUM,
  activeBackground: 'brandPrimaryDark',
}

export const PrimaryButton = makeButton('PrimaryButton', styles)
export const PrimaryLinkButton = makeLinkButton('PrimaryLinkButton', styles)
export const PrimaryAnchorButton = makeAnchorButton('PrimaryAnchorButton', styles)
